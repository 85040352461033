import React, { useState, useEffect, Key } from 'react';
import api from "../../../../services/api";

import { Row, Col, InputGroup, Modal } from "react-bootstrap";
import { Controller, useForm } from "react-hook-form";
import { ErrorMessage } from "@hookform/error-message";
import { Form } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import SingleCalendarEditable from '../../../Calendar/SingleCalendarEditable';
import GetCostCenterPay from '../../../C2Points/GetCostCenterPay';
import GetSuppliersFilter from '../../../C2Points/GetSuppliersInsert';
import GetAccountingAccountsFilter from '../../../C2Points/GetAccountingAccountsFilter';
import GetAgents from '../../../C2Points/GetAgentsById';

import ModalConfirm from '../../../../components/Modal/ModalConfirm/ModalConfirm';

import './EditPayment.scss';
import ModalQuestion from '../../ModalQuestion/ModalQuestion';
import FormBillPayableLog from '../../../Forms/FormBillPayableLog/FormBillPayableLog';

export interface propInsert {
    info: any,
    onSuccess?: any,
    erp?: any
    t?: any
}

const EditPayment: React.FC<propInsert> = ({
    info, onSuccess, erp = 2, t
}: propInsert) => {
    //function EditPayment() {

    const formatDate = (data: any) => {
        if (!data) return '';

        //2022-05-05T00:00:00
        var aux = data.split('T');
        aux = aux[0].split('-');

        return (`${aux[2]}/${aux[1]}/${aux[0]}`);
    }
    function formatValue(cell: any) {
        //console.log(Math.sign(cell))
        if (cell && cell !== '') {
            return cell.toFixed(2).replace(".", ",");
        } else {
            return '0,00';
        }
    }
    //const today = new Date();
    //const todayStr = `${today.getDate()}/${today.getMonth() + 1}/${today.getFullYear()}`;

    const [modalSuccess, setModalSuccess] = useState<any>(false);
    const [responseText, setResponseText] = useState<any>();
    const [modalResponse, setModalResponse] = useState<any>();
    const [modalContent, setModalContent] = useState<any>();
    const [log, setLog] = useState<any>();

    //const [modalShow, setModalShow] = useState<any>(false);
    //const [modalMessage, setModalMessage] = useState<any>('');
    const pagRecorrente: any = false; //const [pagRecorrente, setPagRecorrente] = useState<any>(false);

    const [fileId, setFileId] = useState<any>(info && info.files ? info.files.map((a: any) => a.id) : []);
    const [file, setFile] = useState<any>(info && info.files ? info.files.map((a: any) => '') : []);
    const [fileName, setFileName] = useState<any>(info && info.files ? info.files.map((a: any) => a.fileName) : []);
    const [filesDeleted, setFilesDeleted] = useState<any>([]);

    const [errorRateio, setErrorRateio] = useState<any>(false);

    const [date, setDate] = useState<any>(info ? formatDate(info.dataOperacao) : '');
    const [vencimento, setVencimento] = useState<any>(info ? formatDate(info.vencimento) : '');
    const [descricao, setDescricao] = useState<any>(info ? info.descricao : '');
    const [categoria, setCategoria] = useState<any>(info ? info.contaContabilId : null);
    const [valor, setValor] = useState<any>(info ? formatValue(info.valor) : '');
    const [fornecedor, setFornecedor] = useState<any>(info ? info.fornecedorId : null);
    const [supplierType, /* setSupplierType */] = useState<any>(info ? `${info.supplierType}` : "1");
    const [observacao, setObservacao] = useState<any>(info ? info.observacao : '');
    const [empresa, setEmpresa] = useState<any>(info ? info.empresaId : null);

    const maxRateio: number = 100;//const [maxRateio, setMaxRateio] = useState<number>(100);
    const [value, setValue] = useState<any>(info && info.centrosCusto && info.centrosCusto.length > 0 ? info.centrosCusto.map((item: any) => item.rateio) : [0]);
    const [controlObject, setControlObject] = useState<any>(info && info.centrosCusto && info.centrosCusto.length > 0 ? info.centrosCusto.map((item: any) => 1) : [1]);
    //const [somaRateio, setSomaRateio] = useState<any>(0);
    //const [test, setTest] = useState<any>();
    const [rateioBlocked, setRateioBlocked] = useState<any>(false);
    //const [coastId, setCoastId] = useState<any>([]);
    const [arrayCoast, setArrayCoast] = useState<any>(info && info.centrosCusto && info.centrosCusto.length > 0 ? info.centrosCusto.map((item: any) => `${item.centroCustoId}`) : [""]);

    const [dateError, setDateError] = useState<any>(false);
    const [dueError, setDueError] = useState<any>(false);

    const [billExportErpModalShow, setBillExportErpModalShow] = useState<any>(false);

    const [logModalShow, setLogModalShow] = useState<any>(false);

    const validated = false;

    const tourAuth: any = JSON.parse(localStorage.getItem('c2tourAuth') || '{}');

    const userName: any = tourAuth.user.nome;
    const userLastName: any = tourAuth.user.lastName;
    const uId: any = tourAuth.user.uId;

    const fileHandler = (e: any) => {
        const reader = new FileReader();
        reader.onload = () => {
            if (reader.readyState === 2) {
                setFile([...file, reader.result])
            }
        }
        reader.readAsDataURL(e.target.files[0]);
        setFileName([...fileName, e.target.files[0].name]);
        setFileId([...fileId, -1]);
    }

    function closeModal() {
        var closeBtn: any = document.getElementsByClassName("btn-close")[0];
        if (closeBtn !== undefined) {
            closeBtn.click();
        }
    }

    function downloadBase64(file: any, filetype: any, filename: any) { // file: bytes/string, name: string
        let link = document.createElement('a');
        link.href = 'data:' + filetype + ';base64,' + file;
        link.download = filename;
        document.body.appendChild(link);
        link.dispatchEvent(new MouseEvent('click', { bubbles: true, cancelable: true, view: window }));
        link.remove();
        window.URL.revokeObjectURL(link.href);
    }

    function downloadFile(index: any) {

        const insertConta = async () => {
            const token = localStorage.getItem('GroupId') || '{}';
            const config = {
                headers: { 'Authorization': `Bearer ${token}`, 'Content-Type': 'application/json' },
            };
            try {
                const res = await api.post(`/BillsPayable/GetBillAttachment`,
                    {
                        // "manifestId": info.manifestId,
                        // "tipo":  info.tipo,
                        "fileId": fileId[index]
                    }, config);
                if (res.status !== 400) {
                    const dados = res.data.data;

                    downloadBase64(dados.content, dados.fileFormat, dados.fileName);
                } else {
                    //setResponseText(res.data.data.texto);
                    //setModalResponse('error');
                    //setLog(res.data.data.log);
                }
            } catch (error: any) {
                //setResponseText("Adicione um centro de custo");
                //setModalResponse('error');
                //setLog(1);
            }
        };

        insertConta();
    }

    const {
        control,
        handleSubmit,
        formState: { errors },
    } = useForm();



    const fixDate = (data: any) => {
        var aux = data.split('/');

        return (`${aux[2]}-${aux[1]}-${aux[0]}`);
    }

    // function verifyDate(now: any, due: any) {
    //     var nowArray: any = now.split('-');
    //     var dueArray: any = due.split('-');

    //     // if (parseInt(dueArray[0]) < parseInt(nowArray[0])) {
    //     //     return false;
    //     // }
    //     // if (parseInt(dueArray[1]) < parseInt(nowArray[1])) {
    //     //     return false;
    //     // }
    //     // if (parseInt(dueArray[2]) < parseInt(nowArray[2])) {
    //     //     return false;
    //     // }

    //     const data1 = new Date(parseInt(nowArray[0]), parseInt(nowArray[1])-1, parseInt(nowArray[2]));
    //     const data2 = new Date(parseInt(dueArray[0]), parseInt(dueArray[1])-1, parseInt(dueArray[2]));

    //     if(data2 < data1) {
    //         return false;
    //     }
    //     return true;
    // }

    const onSubmit = (data: any) => {
        //

        if (!date) {
            setDateError(true);
            return;
        }
        setDateError(false);

        if (pagRecorrente === 1 && !vencimento) {
            setDueError(true);
            return;
        }
        setDueError(false);


        let auxCenter: any = [];

        for (let i: any = 0; i < controlObject.length; i++) {

            if (arrayCoast[i] && arrayCoast[i] !== '' && arrayCoast[i] !== '0') {
                auxCenter.push(
                    {
                        "centroCustoId": arrayCoast[i],
                        "rateio": value[i]
                    }
                )
            } else {
                setLog(1);
                setModalResponse('error');
                return;
            }
        }

        let auxFiles: any = [];

        for (let i: any = 0; i < file.length; i++) {
            if (fileId[i] <= 0) {
                auxFiles.push(
                    {
                        "file": file[i],
                        "fileName": fileName[i]
                    }
                )
            }
        }

        setErrorRateio(false);
        data.data = fixDate(date);
        data.vencimento = fixDate(vencimento);
        //data.arquivo = file;  
        data.filesToUpload = auxFiles;
        data.filesDeleted = filesDeleted;
        data.fornecedor = fornecedor;//data.fornecedor?.supplierId;
        data.supplierType = supplierType;
        data.categoria = categoria;//data.categoria?.id;
        data.centroCusto = auxCenter;

        data.observacao = observacao ? observacao : '';
        data.descricao = descricao ? descricao : '';
        data.valor = parseFloat(valor ? valor.replace(',', '.') : 0);

        //data.recorrente = pagRecorrente;
        //data.qtdeRecorrencia = data.qtdeRecorrencia === undefined ? "" : data.qtdeRecorrencia
        //data.tipoRecorrencia = data.tipoRecorrencia === undefined ? "" : data.tipoRecorrencia

        //console.log(data, "nootnoot");return;

        //if (verifyDate(data.data, data.vencimento)) {


        const insertConta = async () => {
            setModalResponse('loading');

            const token = localStorage.getItem('GroupId') || '{}';
            const config = {
                headers: { 'Authorization': `Bearer ${token}`, 'Content-Type': 'application/json' },
            };
            try {
                const res = await api.post(`/BillsPayable/UpdateBill`,
                    {
                        "manifestId": info.manifestId,
                        "tipo": info.tipo,
                        "centrosCusto": data.centroCusto,
                        "contaContabil": data.categoria,
                        "data": data.data,
                        "descricao": data.descricao,
                        "fornecedor": data.fornecedor,
                        "supplierType": data.supplierType,
                        "observacao": data.observacao,
                        "empresa": data.empresa,

                        "uid": uId,
                        "usuario": `${userName} ${userLastName}`,
                        "valor": data.valor,
                        "vencimento": data.vencimento,
                        //"arquivo": data.arquivo  //arquivo em base64
                        "filesToUpload": data.filesToUpload,
                        "filesDeleted": data.filesDeleted
                    }, config);
                if (res.status !== 400) {
                    setResponseText(res.data.data.texto);
                    setModalResponse('success');
                    setLog(res.data.data.log)
                } else {
                    setResponseText(res.data.data.texto);
                    setModalResponse('error');
                    setLog(res.data.data.log);
                }
            } catch (error: any) {
                setResponseText(t("billsToPay.table.paymentAndDebitNotes.error"));
                setModalResponse('error');
                setLog(1);
            }
        };

        //const hoje = today.toISOString().substring(0,10);

        //if (verifyDate(hoje, data.vencimento)) {
        insertConta();
        //} else {
        // modal question first
        //}
        //} else {
        //setModalShow(true);
        //setModalMessage("Data de vencimento antes da data atual.");
        //}
    }

    const addRow = () => {
        if (rateioBlocked === false) {
            var aux: any = [];

            if (value.length > 0) {
                for (var i: any = 0; i < value.length; i++) {
                    aux.push(value[i]);
                }
                aux.push(0)
            } else {
                aux.push(0)
            }

            setValue(aux)
            setArrayCoast([...arrayCoast, ""])
            setControlObject([...controlObject, 1]);
        }

    };

    function removeRow(e: any, index: any) {
        setRateioBlocked(false);
        let aux: any = [];
        let auxValue: any = [];
        let auxCoast: any = [];

        for (let i: any = 0; i < controlObject.length; i++) {
            if (index !== i) {
                aux.push(controlObject[i]);
            }
        }

        for (let i: any = 0; i < arrayCoast.length; i++) {
            if (index !== i) {
                auxCoast.push(arrayCoast[i]);
            }
        }

        for (let i: any = 0; i < value.length; i++) {
            if (index !== i) {
                auxValue.push(value[i]);
            }
        }
        setControlObject(aux);
        setValue(auxValue);
        setArrayCoast(auxCoast);

        //let somaValue: any = 0
        for (let i = 0; i < auxValue.length; i++) {
            //somaValue += auxValue[i]

            //setSomaRateio(somaValue);
            //setTest(maxRateio - somaRateio)
        }
    }

    function removeFile(index: any) {
        //setRateioBlocked(false);
        let auxFileId: any = [];
        let auxFile: any = [];
        let auxFileName: any = [];

        const fileIdDeleted = fileId[index];

        for (let i: any = 0; i < fileId.length; i++) {
            if (index !== i) {
                auxFileId.push(fileId[i]);
            }
        }

        for (let i: any = 0; i < file.length; i++) {
            if (index !== i) {
                auxFile.push(file[i]);
            }
        }

        for (let i: any = 0; i < fileName.length; i++) {
            if (index !== i) {
                auxFileName.push(fileName[i]);
            }
        }
        setFileId(auxFileId);
        setFile(auxFile);
        setFileName(auxFileName);

        if (fileIdDeleted > 0) setFilesDeleted([...filesDeleted, fileIdDeleted]);
    }

    function handleChangeValue(valueRateio: any, index: any) {
        var auxRateio: any = [];
        var soma: any = 0;

        if (value.length > 1) {
            for (var i: any = 0; i < value.length; i++) {
                auxRateio.push(value[i]);

                console.log(value.length)
                if (i !== index) {
                    soma += value[i];
                }
            }

            if (soma + Number(valueRateio) >= 100) {
                auxRateio[index] = 100 - soma;
                setRateioBlocked(true);
            } else {
                auxRateio[index] = Number(valueRateio);
                setRateioBlocked(false);
            }

            setValue(auxRateio);
        } else {
            if (Number(valueRateio) >= 100) {
                setValue([100]);
                setRateioBlocked(true);
            } else {
                setValue([Number(valueRateio)]);
                setRateioBlocked(false);
            }
        }
    }

    function handleChangeCoast(auxCoastId: any, index: any) {
        let auxCoast: any = arrayCoast;
        for (let i: any = 0; i < arrayCoast.length; i++) {
            if (i === index) {
                auxCoast[i] = auxCoastId;
            }
        }

        setArrayCoast(auxCoast);
        console.log(auxCoast);
    }

    function handleDescricaoChange(evt: any) {
        const newValue = evt.target.value;
        setDescricao(newValue);
    }

    function handleValorChange(evt: any) {
        const newValue = evt.target.value;
        setValor(newValue);
    }

    function handleObservacaoChange(evt: any) {
        const newValue = evt.target.value;
        setObservacao(newValue);
    }

    function handleChangeEmpresaMethod(evt: any, propsField: any) {
        const newValue = evt.target.value;
        setEmpresa(newValue);

        propsField.onChange(evt);
    }

    /* const handleChangeSupplierType = (e: React.ChangeEvent<HTMLInputElement>) => {
        const newValue = e.target.value;
        setSupplierType(newValue);

        setFornecedor(null);
    } */

    /* function exportErpClick() {
        setBillExportErpModalShow(true);
    } */

    function handleActionBillExportErpModal() {
        setBillExportErpModalShow(false);
        setModalContent("loading");
        setModalSuccess(true);
        //setModalSuccess(true);

        const token = localStorage.getItem('GroupId') || '{}';
        const config = {
            headers: { 'Authorization': `Bearer ${token}`, 'Content-Type': 'application/json' },
        };

        async function settleQuery() {
            try {
                const res = await api.post(`/Checkout/EnvioContasPagarRM`,
                    {
                        "id": info.manifestId,
                        "tipo": info.tipo
                        // "paymentId": billEdit.id, // array com os itens a serem liquidados
                        // "uid": uId,
                        // "usuario": (userName !== null && userLastName !== null) ? `${userName} ${userLastName}` : userName
                    }, config
                );

                if (res.status !== 400) {
                    //const dados = res.data.data;
                    const dados = res.data;

                    setLog(dados.log);

                    if (dados.log === 0) {
                        setModalContent('success');
                        setResponseText(t("billsToPay.table.paymentAndDebitNotes.send"));
                    }
                    else if (dados.log === 1) {
                        setModalContent('error');
                        setResponseText(dados.texto);
                    }
                }
            } catch (error: any) {
                setModalContent("error");
                setResponseText(t("billsToPay.table.paymentAndDebitNotes.error"));
                setLog(1);
            }
        }

        settleQuery();

    }

    useEffect(() => {
        if (errors) {
            setResponseText(t("billsToPay.table.paymentAndDebitNotes.fillUpTheFields"));
            setModalResponse('error');
            setLog(1);
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [errors])

    function handleReload() {
        window.location.reload();
    }

    if (info !== null) {
        return (
            <>
                <Form className="h-100 form-inserir" noValidate validated={validated} onSubmit={handleSubmit(onSubmit)} autoComplete="false">
                    <div className='d-flex flex-column justify-content-between h-100'>
                        <div>
                            <Row className="mb-3">
                                <Form.Group as={Col} controlId="formGridCat">
                                    <Form.Label>{t("billsToPay.table.paymentAndDebitNotes.company")}</Form.Label>
                                    <Controller
                                        control={control}
                                        name="empresa"
                                        rules={{ required: { value: true, message: t("billsToPay.table.paymentAndDebitNotes.companyError") }, }}
                                        defaultValue={empresa}
                                        render={({ field }: any) => (
                                            <div className={errors[field.name] !== undefined ? "endpoint-error" : "endpoint"}>
                                                <Form.Select
                                                    {...field}
                                                    as="select"
                                                    variant="standard"
                                                    margin="normal"
                                                    onChange={(evt: any) => handleChangeEmpresaMethod(evt, field)}
                                                >
                                                    <option value='0' selected disabled>{t("billsToPay.table.paymentAndDebitNotes.select")}</option>
                                                    <option value='1'>C2RIO</option>
                                                    <option value='2'>DESTINOW</option>
                                                    <option value='3'>DESTITECH</option>
                                                    <option value='4'>TAH</option> 
                                                </Form.Select>
                                            </div>
                                        )}
                                    />
                                    <ErrorMessage
                                        errors={errors}
                                        name="empresa"
                                        render={({ message }) => <small style={{ color: "red" }}>{message}</small>}
                                    />
                                </Form.Group>
                            </Row>
                            <Row className="mb-3">
                                <Form.Group as={Col} controlId="formGridCat">
                                    <Form.Label>{t("billsToPay.table.paymentAndDebitNotes.category")}</Form.Label>
                                    <Controller
                                        control={control}
                                        name="categoria"
                                        rules={{ required: { value: true, message: t("billsToPay.table.paymentAndDebitNotes.categoryError") }, }}
                                        defaultValue={categoria}
                                        render={({ field }: any) => (
                                            <GetAccountingAccountsFilter propsField={field} propsErrors={errors} propsLabel={t("billsToPay.table.paymentAndDebitNotes.category")} setFilterAccount={setCategoria} />
                                        )}
                                    />
                                    <ErrorMessage
                                        errors={errors}
                                        name="categoria"
                                        render={({ message }) => <small style={{ color: "red" }}>{message}</small>}
                                    />
                                </Form.Group>

                                <Form.Group as={Col} controlId="formGridDesc">
                                    <Form.Label>{t("billsToPay.table.paymentAndDebitNotes.description")}</Form.Label>
                                    <Controller
                                        control={control}
                                        name="descricao"
                                        rules={{ required: { value: descricao === '', message: t("billsToPay.table.paymentAndDebitNotes.descriptionError") }, }}
                                        render={({ field }: any) => (
                                            <Form.Control
                                                {...field}
                                                aria-invalid={errors?.descricao ? "true" : ""}
                                                variant="standard"
                                                margin="normal"
                                                autoComplete="off"
                                                value={descricao}
                                                onChange={handleDescricaoChange}
                                            />
                                        )}
                                    />
                                    <ErrorMessage
                                        errors={errors}
                                        name="descricao"
                                        render={({ message }) => <small style={{ color: "red" }}>{message}</small>}
                                    />
                                </Form.Group>
                            </Row>

                            <Row>
                                <Form.Group as={Col} className="mb-3" controlId="formGridDate">
                                    <Form.Label>{t("billsToPay.table.paymentAndDebitNotes.date")}</Form.Label>
                                    <InputGroup
                                        hasValidation
                                        className={` ${dateError === true ? "endpoint-error" : ""}`}
                                        size="sm"
                                        placeholder={t("billsToPay.table.paymentAndDebitNotes.when")}
                                    >
                                        <SingleCalendarEditable setValue={setDate} date={date} />
                                    </InputGroup>
                                    {dateError === true ?
                                        <small style={{ color: 'red' }}>{t("billsToPay.table.paymentAndDebitNotes.dateError")}</small>
                                        : <></>}
                                </Form.Group>

                                <Form.Group as={Col} className="mb-3" controlId="formGridVenc">
                                    <Form.Label>{t("billsToPay.table.paymentAndDebitNotes.expiration")}</Form.Label>
                                    <InputGroup
                                        hasValidation
                                        className={` ${dueError === true ? "endpoint-error" : ""}`}
                                        size="sm"
                                        placeholder={t("billsToPay.table.paymentAndDebitNotes.when")}
                                    >
                                        <SingleCalendarEditable setValue={setVencimento} date={vencimento} />
                                    </InputGroup>
                                    {dueError === true ?
                                        <small style={{ color: 'red' }}>{t("billsToPay.table.paymentAndDebitNotes.expirationError")}</small>
                                        : <></>}
                                </Form.Group>
                            </Row>

                            <Row className="mb-3 d-flex align-items-center">
                                <Form.Group as={Col} md={6} controlId="formGridCat">
                                    <Form.Label>{t("billsToPay.table.paymentAndDebitNotes.value")}</Form.Label>
                                    {/* <Form.Control autoComplete='off' type="text" /> */}
                                    <Controller
                                        control={control}
                                        name="valor"
                                        rules={{ required: { value: valor === '', message: "required" }, }}
                                        render={({ field }: any) => (
                                            <Form.Control
                                                {...field}
                                                aria-invalid={errors?.valor ? "true" : ""}
                                                variant="standard"
                                                margin="normal"
                                                autoComplete="off"
                                                //onKeyPress={(e) => !/[0-9]/.test(e.key) && e.preventDefault()}
                                                value={valor}
                                                onChange={handleValorChange}
                                            />
                                        )}
                                    />
                                    <ErrorMessage
                                        errors={errors}
                                        name="valor"
                                        render={({ message }) => <small style={{ color: "red" }}>{message}</small>}
                                    />
                                </Form.Group>
                                {/* <Form.Group as={Col} md="6">
                                    <Form.Label>Fornecedor/Agente</Form.Label>
                                    <div className="endpoint">
                                        <InputGroup hasValidation>
                                            <Form.Select
                                            autoComplete='off'
                                                aria-describedby="inputGroupPrepend"
                                                onChange={(e: any) => handleChangeSupplierType(e)}
                                                value={supplierType}
                                            >
                                                <option value="1">Fornecedor</option>
                                                <option value="2">Agente</option>
                                            </Form.Select>
                                        </InputGroup>
                                    </div>
                                </Form.Group> */}
                            </Row>

                            <hr style={{ margin: "30px 0" }} />

                            <Row className="mb-3">
                                {supplierType === "1" ?
                                <Form.Group as={Col} md={6} controlId="formGridSupplier">
                                    <Form.Label>{t("billsToPay.table.paymentAndDebitNotes.supplier")}</Form.Label>
                                    <Controller
                                        control={control}
                                        name="fornecedor"
                                        rules={{ required: { value: fornecedor ? false : true, message: t("billsToPay.table.paymentAndDebitNotes.supplierError") }, }}
                                        defaultValue={fornecedor}
                                        render={({ field }: any) => (
                                            <GetSuppliersFilter
                                                propsField={field}
                                                propsErrors={errors}
                                                propsLabel={t("billsToPay.table.paymentAndDebitNotes.supplier")}
                                                setSupplier={setFornecedor}
                                            //supplier={info ? info.fornecedor : "Selecione"}
                                            />

                                        )}
                                    />
                                    <ErrorMessage
                                        errors={errors}
                                        name="fornecedor"
                                        render={({ message }) => <small style={{ color: "red" }}>{message}</small>}
                                    />
                                </Form.Group>
                                :
                                <Form.Group as={Col} md={6} controlId="formGridSupplier">
                                    <Form.Label>Agente</Form.Label>
                                    <Controller
                                        control={control}
                                        name="fornecedor"
                                        rules={{ required: { value: fornecedor ? false : true, message: "required" }, }}
                                        defaultValue={fornecedor}
                                        render={({ field }: any) => (
                                            <GetAgents
                                                propsField={field}
                                                propsErrors={errors}
                                                propsLabel="Agente"
                                                setSupplier={setFornecedor}
                                            />

                                        )}
                                    />
                                    <ErrorMessage
                                        errors={errors}
                                        name="fornecedor"
                                        render={({ message }) => <small style={{ color: "red" }}>{message}</small>}
                                    />
                                </Form.Group>
                                }

                                <Form.Group as={Col} md={6} controlId="formGridFile" className="d-flex">
                                    <Form.Group as={Col} md={4} controlId="formGridFile">
                                        <Form.Label>
                                            <div className="d-flex align-middle">
                                            {t("billsToPay.table.paymentAndDebitNotes.attachDocument")}
                                                {/* {info.temArquivoAnexo === 1 ?
                                                <button className="btn btn-default p-0 ml-2" style={{width:"25px"}} onClick={(evt:any) => { evt.preventDefault(); downloadFile(); }}>
                                                    <FontAwesomeIcon
                                                        size="sm"
                                                        icon={["fal", "paperclip"]}
                                                        style={{ color: "#707070", margin: "0px", padding: "0px"}}
                                                    />
                                                </button>
                                                : <></>} */}
                                            </div>
                                        </Form.Label>
                                        <Controller
                                            control={control}
                                            name="arquivo"
                                            render={({ field }: any) => (
                                                <Form.Control type="file"
                                                    {...field}
                                                    label={t("billsToPay.table.paymentAndDebitNotes.file")}
                                                    aria-invalid={errors?.arquivo ? "true" : ""}
                                                    fullWidth
                                                    variant="standard"
                                                    margin="normal"
                                                    id="arquivo"
                                                    accept=".jpg"
                                                    autoComplete='off'
                                                    onChange={fileHandler}
                                                    required />
                                            )}
                                        />
                                        <label className="btn-file w-100" htmlFor="arquivo">{t("billsToPay.table.paymentAndDebitNotes.chooseFile")}</label>
                                    </Form.Group>
                                    <div className="col-12 col-md-8 ml-2">
                                        {fileName.map((obj: any, index: Key) => {
                                            return (<div className="row m-0 mx-2" style={{ minHeight: "40px" }}>
                                                <div className="col-10 px-2 py-1">
                                                    {fileId[index] > 0 ?
                                                        <span className="btn btn-att p-0" style={{ minHeight: "35px", wordBreak: "break-word", textAlign: "left" }} onClick={(e: any) => downloadFile(index)}>
                                                            {obj}
                                                        </span>
                                                        :
                                                        <span className="" style={{ wordBreak: "break-word", textAlign: "left", lineHeight: 1.5 }}>
                                                            {obj}
                                                        </span>
                                                    }
                                                </div>
                                                <div className="col-2 p-0 pl-2 d-flex align-items-center">
                                                    <span className="btn py-1 px-2" style={{ height: "35px" }} onClick={(e: any) => removeFile(index)}>
                                                        <FontAwesomeIcon
                                                            size="lg"
                                                            icon={["fal", "trash-alt"]}
                                                            style={{ color: "#707070" }}
                                                        />
                                                    </span>
                                                </div>
                                            </div>)
                                        })
                                        }
                                    </div>
                                </Form.Group>
                            </Row>

                            <Row>
                                <Form.Group as={Col} md={6} className="mb-3" controlId="formGridCenter">
                                    <Form.Label className="text-primary">{t("billsToPay.table.paymentAndDebitNotes.costCenter")}</Form.Label>
                                    {
                                        controlObject.map((obj: any, index: Key) => {
                                            return (
                                                <div className='mb-3'>
                                                    <Controller
                                                        control={control}
                                                        name={`centroCusto${index}`}
                                                        //rules={{ required: { value: true, message: "required" }, }}
                                                        render={({ field }: any) => (
                                                            <GetCostCenterPay
                                                                propsField={field}
                                                                propsErrors={errors}
                                                                propsLabel={t("billsToPay.table.paymentAndDebitNotes.costCenters")}
                                                                handleChangeCoast={handleChangeCoast}
                                                                index={index}
                                                                arrayCoast={arrayCoast}
                                                            />
                                                        )}
                                                    />
                                                </div>
                                            )
                                        })
                                    }

                                </Form.Group>

                                <Form.Group as={Col} md={3} className="mb-3 input-rateio" controlId="formGridRat">
                                    <Form.Label className="text-primary">{t("billsToPay.table.paymentAndDebitNotes.apportionment")} <span style={{ color: errorRateio === true ? "#F00" : "#6731FF" }}> (MAX: {maxRateio})</span></Form.Label>
                                    {
                                        controlObject.map((obj: any, index: Key) => {
                                            return (
                                                <div className="mb-3">
                                                    <Controller
                                                        control={control}
                                                        name={`rateio${index}`}
                                                        //rules={{ required: { value: true, message: "required" }, }}
                                                        render={({ field }: any) => (
                                                            <Form.Control
                                                                {...field}
                                                                type="text"
                                                                aria-invalid={errors?.rateio ? "true" : ""}
                                                                variant="standard"
                                                                margin="normal"
                                                                autoComplete="off"
                                                                onKeyPress={(e) => !/[0-9]/.test(e.key) && e.preventDefault()}
                                                                onChange={(e: any) => handleChangeValue(e.target.value, index)}
                                                                value={value[index]}
                                                            />
                                                        )}
                                                    />
                                                    <ErrorMessage
                                                        errors={errors}
                                                        name={`rateio-${index}`}
                                                        render={({ message }) => <small style={{ color: "red" }}>{message}</small>}
                                                    />
                                                </div>
                                            )
                                        })
                                    }
                                </Form.Group>

                                <div className="mb-3 action-btn col-md-3" >
                                    <label className="form-label text-primary d-flex justify-content-end">{t("billsToPay.table.paymentAndDebitNotes.actions")}</label>
                                    {
                                        controlObject.map((obj: any, index: Key) => {
                                            return (
                                                /* (controlObject.length - 1) !== index
                                                    ? */
                                                (controlObject.length > 1)
                                                    ?
                                                    <>
                                                        <div className="px-2 mb-3">
                                                            <div className="d-flex justify-content-end">
                                                                <span className='btn' style={{ height: "40px" }} onClick={(e: any) => removeRow(e, index)}>
                                                                    <FontAwesomeIcon
                                                                        size="lg"
                                                                        icon={["fal", "trash-alt"]}
                                                                        style={{ color: "#707070" }}
                                                                    />
                                                                </span>
                                                            </div>
                                                        </div>
                                                    </>
                                                    :
                                                    <></>
                                                /* :
                                                <>
                                                    <div className="px-2 mb-3">
                                                        <div className="d-flex justify-content-end">
                                                            <span className='btn' style={{ height: "40px" }} onClick={(e: any) => addRow()}>
                                                                <FontAwesomeIcon
                                                                    size="lg"
                                                                    icon={["fal", "plus"]}
                                                                    style={{ color: "#707070" }}
                                                                />
                                                            </span>
                                                        </div>
                                                    </div>
                                                </> */
                                            )
                                        })
                                    }
                                </div>
                            </Row>

                            <Row>
                                <>
                                    <div className="px-2 mb-3">
                                        <div className="d-flex justify-content-center">
                                            <span className='btn' style={{ height: "40px" }} onClick={(e: any) => addRow()}>
                                                <FontAwesomeIcon
                                                    size="lg"
                                                    icon={["fal", "plus"]}
                                                    style={{ color: "#707070", margin: "0 12px" }}
                                                />
                                            </span>
                                        </div>
                                    </div>
                                </>
                            </Row>

                            <Row className="mb-3">
                                <Form.Group as={Col} controlId="formGridCity">
                                    <Form.Label>{t("billsToPay.table.paymentAndDebitNotes.observation")}</Form.Label>
                                    <Controller
                                        control={control}
                                        name="observacao"
                                        //rules={{ required: { value: true, message: "required" }, }}
                                        render={({ field }: any) => (
                                            <Form.Control
                                                {...field}
                                                aria-invalid={errors?.observacao ? "true" : ""}
                                                variant="standard"
                                                margin="normal"
                                                autoComplete="off"
                                                as="textarea"
                                                rows={4}
                                                value={observacao}
                                                onChange={handleObservacaoChange}
                                            />
                                        )}
                                    />
                                    <ErrorMessage
                                        errors={errors}
                                        name="observacao"
                                        render={({ message }) => <small style={{ color: "red" }}>{message}</small>}
                                    />
                                </Form.Group>
                            </Row>
                        </div>
                        {/* {erp !== 1 ?
                            <ModalConfirm
                                handleSubmit={handleSubmit}
                                onSubmit={onSubmit}
                                payment="yes"
                                confirm={responseText}
                                modalContent={modalResponse}
                                log={log}
                                closeModal={closeModal}
                                customReload={() => { if (onSuccess) onSuccess(); }}
                            />
                            : */}
                            <div className='d-flex justify-content-between'>
                                {/* <div className="mt-4">
                                    <button className="btn btn-default btn-primary px-4 text-uppercase" disabled={info && info.statusErp && info.statusErp === 2} onClick={() => exportErpClick()}>
                                        Exportar ERP
                                    </button>
                                </div> */}
                                <div className="mt-4">
                                    <button className="btn btn-default btn-primary px-4 text-uppercase" onClick={() => setLogModalShow(true)}>
                                        <FontAwesomeIcon
                                            icon={["fal", "history"]}
                                            size="1x"
                                            style={{ marginRight: "5px" }}
                                            /> {t("billsToPay.table.paymentAndDebitNotes.seeLog")}
                                    </button>
                                </div>
                                {info.statusErp && info.statusErp === 2 ?
                                    <div className='mt-4 d-flex justify-content-end'>
                                        <div>
                                            <button className="btn btn-default btn-outline-primary px-4 mr-3 text-uppercase" onClick={() => closeModal()}>
                                            {t("billsToPay.table.paymentAndDebitNotes.cancel")}
                                            </button>
                                        </div>
                                        <div>
                                            <button className="btn btn-default btn-primary px-4 text-uppercase" disabled={true}>
                                            {t("billsToPay.table.paymentAndDebitNotes.confirm")}
                                            </button>
                                        </div>
                                    </div>
                                    :
                                    <ModalConfirm
                                        handleSubmit={handleSubmit}
                                        onSubmit={onSubmit}
                                        payment="yes"
                                        confirm={responseText}
                                        modalContent={modalResponse}
                                        log={log}
                                        closeModal={closeModal}
                                        customReload={() => { if (onSuccess) onSuccess(); }}
                                    />}
                            </div>
                        
                    </div>
                </Form>

                <ModalQuestion
                    modalShow={billExportErpModalShow}
                    setModalShow={setBillExportErpModalShow}
                    titulo={t("billsToPay.table.paymentAndDebitNotes.export")}
                    mensagem={t("billsToPay.table.paymentAndDebitNotes.valuesToBeExport")}
                    handleActionConfirmModal={handleActionBillExportErpModal}
                //handleActionCancelModal={handleActionCancelDeleteClick}
                />

                {/* Modal de Success */}
                <Modal
                    className={"modal-confirm loading-modal"}
                    show={modalSuccess}
                    onHide={() => setModalSuccess(false)}
                    size="lg"
                    aria-labelledby="contained-modal-title-vcenter"
                >
                    <>
                        <Modal.Body className='modal-body text-center sucess-pos'>
                            {
                                modalContent !== "loading"
                                    ?
                                    <>
                                        <div>
                                            {
                                                log === 2 || log === "2"
                                                    ?
                                                    <FontAwesomeIcon
                                                        icon={["fal", "exclamation-circle"]}
                                                        size="5x"
                                                        className="text-primary"
                                                        style={{ fontSize: "7.5em"
                                                    }}
                                                    />
                                                    :
                                                    log === 1 || log === "1"
                                                        ?
                                                        <FontAwesomeIcon
                                                            icon={["fal", "times-circle"]}
                                                            size="5x"
                                                            className="text-primary"
                                                            style={{ fontSize: "7.5em"
                                                        }}
                                                        />
                                                        :
                                                        log === 0 || log === "0"
                                                            ?
                                                            <FontAwesomeIcon
                                                                icon={["fal", "check-circle"]}
                                                                size="5x"
                                                                className="text-primary"
                                                                style={{ fontSize: "7.5em"
                                                            }}
                                                            />
                                                            :
                                                            <FontAwesomeIcon
                                                                icon={["fal", "question-circle"]}
                                                                size="5x"
                                                                className="text-primary"
                                                                style={{ fontSize: "7.5em"
                                                            }}
                                                            />
                                            }
                                        </div>
                                        <div>
                                            {responseText}
                                        </div>
                                        <div className="d-flex justify-content-center">
                                            {
                                                modalContent === 'success'
                                                    ?
                                                    <button
                                                        onClick={handleReload}
                                                        className="btn btn-primary mx-2 w-25"
                                                    >
                                                        {t("billsToPay.table.paymentAndDebitNotes.confirm")}
                                                    </button>
                                                    :
                                                    modalContent === 'warning'
                                                        ?
                                                        <button
                                                            onClick={() => setModalSuccess(false)}
                                                            className="btn btn-primary mx-2 w-25"
                                                        >
                                                            {t("billsToPay.table.paymentAndDebitNotes.confirm")}
                                                        </button>
                                                        :
                                                        <button
                                                            onClick={() => setModalSuccess(false)}
                                                            className="btn btn-primary mx-2 w-25"
                                                        >
                                                            {t("billsToPay.table.paymentAndDebitNotes.confirm")}
                                                        </button>
                                            }
                                        </div>
                                    </>
                                    :
                                    <div className="modal-body">
                                        <div className="text-center">
                                            <div className="load"></div>
                                        </div>
                                        <div>
                                            <span>{t("billsToPay.table.paymentAndDebitNotes.loading")}</span>
                                        </div>
                                        <div></div>
                                    </div>
                            }
                        </Modal.Body>
                    </>
                </Modal>
                {/* END - Modal de Success */}

                <Modal
                    className="modalReceber"
                    show={logModalShow}
                    onHide={() => setLogModalShow(false)}
                    size="lg"
                    aria-labelledby="contained-modal-title-vcenter"
                >
                    <>
                    <Modal.Header className="p-3" closeButton></Modal.Header>
                    <FormBillPayableLog contasaPagarId={info.contasaPagarId ? info.contasaPagarId : 0} />
                    </>
                </Modal>
            </>
        )
    } else {
        return (<></>);
    }

}

export default EditPayment;