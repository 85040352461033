import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useState } from "react";
import Breadcrumb from "../../components/Breadcrumb/Breadcrumb";
import ModalShow from "../../components/Modal/ModalShow/ModalShow";
import api from "../../services/api";
import DesktopDefault from "../../templates/DesktopDefault";
import BlackoutComponent from "./components/BlackoutComponent";

function EditBlackout() {
    const [blackout, setBlackout] = useState<any>(null);
    const [modalShow, setModalShow] = useState<any>(false);
    const [modalLog, setModalLog] = useState<any>(null);
    const [, setSalesChannels] = useState<any>(null);
    const [modalMessage, setModalMessage] = useState<any>("Carregando");

    const idBlackout = window.location.href.split('id=')[1];

    useEffect(() => {
        const token = localStorage.getItem('GroupId') || '{}';
        const config = {
            headers: { 'Authorization': `Bearer ${token}` },
        };

        async function getBlackoutById() {
            try {
                const { data } = await api.get(`/Blackout/GetBlackoutByIdAsync/${idBlackout}`, config);

                if (data.status !== 400) {
                    setBlackout(data.data.data);
                }
            } catch (error: any) {

            }
        }
        getBlackoutById();

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const updateBlackout: any = async (obj: any) => {
        setModalShow(true);
        let fullProduct: any = [];
        let canais: any = [];
        let horarios: any = [];
        let suppliers: any = [];

        let auxObj: any = {}

        if (Number(obj.blackoutType?.value || 0) === 1) { //full
            canais = obj.salesChannel.map((elem: any) => { return ({ "id": elem.value }) });
            horarios = obj.schedules.map((elem: any) => { return (elem.value) });
            let allProducts: string[] = obj.modalities.map((elem: any) => { return ({ "productCode": elem.productCode, "modalityCode": elem.value, "salesChannelList": canais, "horarioList": horarios }) })

            auxObj = {
                "productList": allProducts
            }
        }

        if (Number(obj.blackoutType?.value || 0) === 2) { //por produto
            fullProduct = obj.products.map((elem: any) => { return ({ "productCode": elem.code }) });

            auxObj = {
                "productList": fullProduct
            }
        }

        if (Number(obj.blackoutType?.value || 0) === 3) { //por fornecedor
            suppliers = obj.suppliers.map((elem: any) => { return ({ id: elem.supplierId }) });

            auxObj = {
                "productList": [],
                "supplierList": suppliers
            }
        }

        if (Number(obj.blackoutType?.value || 0) === 4) { //por canal
            canais = obj.salesChannel.map((elem: any) => { return ({ "id": elem.value }) });

            auxObj = {
                "productList": [{
                    "salesChannelList": canais
                }],

            }
        }

        const token = localStorage.getItem('GroupId') || '{}';
        const config = {
            headers: { 'Authorization': `Bearer ${token}`, 'Content-Type': 'application/json' },
        };
        try {
            const { data } = await api.post('/Blackout/UpdateBlackoutProductAsync', {
                "blackoutId": idBlackout,
                "blackoutDateStart": obj.blackoutDateStart + "T00:00:00",
                "blackoutDateEnd": obj.blackoutDateEnd + "T00:00:00",
                "blackoutStatus": obj.status,
                "blackoutName": obj.name,
                "blackoutDescription": obj.description,
                "blackoutType": obj.blackoutType.value,
                ...auxObj
            }, config);
            if (data.statusCode !== 400) {
                setModalLog(data.data.log);
                setModalMessage(data.data.texto);

                if (data.data.log === 0) {
                    handleRevokeCache();
                }
            } else {
                setModalLog(1);
                setModalMessage(data?.data?.texto || data?.errorMessage);
            }

        } catch (error: any) {
            if (error?.response?.status === 400) {

            }
        }
    }

    const handleRevokeCache = async () => {
        try {
            const { data } = await api.get(`${process.env.REACT_APP_SERVER_URL_API}/products/revokecache`);
            if (data.status !== 400) {

            }
        } catch (error: any) {

        }
    }

    return (
        <>
            <DesktopDefault>
                <div className="container-fluid content-dashboard">
                    <Breadcrumb title={"Blackout / Editar Blackout"} />

                    <div className="mt-4 d-flex justify-content-between align-items-center">
                        <h2 className="title" style={{ color: "#707070" }}>Editar Blackout</h2>
                    </div>

                    <div className="d-flex align-items-center my-3">
                        <span
                            className="back m-0"
                            onClick={() => {
                                window.location.href = "/blackout/listar-blackouts";
                            }}
                        >
                            <FontAwesomeIcon
                                icon={["fal", "angle-double-left"]}
                                style={{ margin: "0 5px 0 0", fontSize: '16px' }}
                            />
                            Voltar
                        </span>
                    </div>

                    {
                        blackout !== null &&
                        <BlackoutComponent action={updateBlackout} isEdit={true} infoEdit={blackout} setSalesChannels={setSalesChannels} />
                    }
                </div>
                <ModalShow
                    modalShow={modalShow}
                    setModalShow={setModalShow}
                    modalLog={modalLog}
                    setModalLog={setModalLog}
                    modalMessage={modalMessage}
                    setModalMessage={setModalMessage}
                    redirect={'/blackout/listar-blackouts'}
                />
            </DesktopDefault>
        </>
    )
}

export default EditBlackout;