import React, { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Col, Dropdown, Form, Modal } from "react-bootstrap";

import BootstrapTable from "react-bootstrap-table-next";
import Pagination from "../../../../components/Pagination/Pagination";

import { useTranslation } from "react-i18next";

import "react-bootstrap-table-next/dist/react-bootstrap-table2.css";
import "./ResourcesTable.scss";
import { ErrorMessage } from "@hookform/error-message";
import { Controller, useForm } from "react-hook-form";
import api from "../../../../services/api";

export interface propTable {
  resources: any;
  loading: any;
  totalRows: any;
  pageCount: any;
  setPageCount: any;
  rowsPerPage: any;
  setRowsPerPage: any;
  reloadTable: any;
}

const ResourcesTable: React.FC<propTable> = ({
  resources,
  loading,
  totalRows,
  pageCount,
  setPageCount,
  rowsPerPage,
  setRowsPerPage,
  reloadTable
}) => {
  const { t } = useTranslation();
  const [resourceId, setResourceId] = useState<any>(null);
  const [resourceTypeId, setResourceTypeId] = useState<any>(null);


  // modal
  const [modalConfirmStatus, setModalConfirmStatus] = useState<any>(false);
  const [modalConfirmStatusLoading, setModalConfirmStatusLoading] = useState<any>(false);
  const [modalConfirmStatusLog, setModalConfirmStatusLog] = useState<any>(0);
  const [modalConfirmStatusMessage, setModalConfirmStatusMessage] = useState<any>('');
  const [modalUpdateStatus, setModalUpdateStatus] = useState<any>(false);
  // modal


  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const onSubmit = (data: any) => {
    setModalConfirmStatus(true);
    setModalConfirmStatusMessage("Carregando");
    setModalConfirmStatusLoading(true);
    const token = localStorage.getItem('GroupId') || '{}';
    const config = {
      headers: { 'Authorization': `Bearer ${token}` },
    };

    async function updateStatus() {

      try {
        const res = await api.post(`/Resources/ChangeResourceStatusAsync`, {
          resourceId: resourceId,
          resourceTypeId: resourceTypeId
        }, config);

        if (res.status !== 400) {
          setModalConfirmStatusLoading(false);
          if (res.data.data.log === 0) {
            setModalConfirmStatusLog(0);
            setModalUpdateStatus(false);
            setModalConfirmStatus(true);
            setModalConfirmStatusMessage(res.data.data.texto)
            //buscar(actualFirstname, actualLastname, actualEmail, actualStatus);
          } else {
            setModalConfirmStatusLog(1);
            setModalConfirmStatus(true);
            setModalConfirmStatusMessage("Error ao processar operação");
          }
        }
      } catch (error: any) {
        
      }
    }
    updateStatus();
  }

  const handleClickStatus = (row: any) => {
    setResourceId(row.resourceId);
    setResourceTypeId(row.resourceTypeId);
    console.log(row.resourceId)
    setModalUpdateStatus(true)
  }

  const columns = [
    { dataField: "resourceName", text: "Descrição", formatter: addDescription },
    { dataField: "supplier", text: "Fornecedor" },
    { dataField: "resourceType", text: "Tipo do Resource" },
    { dataField: "vehicleType", text: "Tipo do Veículo" },
    { dataField: "seats", text: "Assentos" },
    { dataField: "paymentType", text: "Tipo de Pagamento" },
    { dataField: "status", text: "Status", formatter: addStatus },
    { dataField: "resourceId", text: "Ações", formatter: addActionButton },
  ];

  // const [, setEditClick] = useState<any>(false);
  // const [, setDetailsModal] = useState<any>(false);
  // const [, setCurrentResource] = useState<any>();

  // function seeDetailsEdit(cell: any) {
  //   setEditClick(true);
  //   setCurrentResource(cell);
  //   setDetailsModal(true);
  // }

  /* loading table */

  function loadingFunc() {
    return (
      <>
        <div className="animated-background row-loading"></div>
      </>
    );
  }

  const loadingColumns = [
    {
      dataField: "resources",
      text: "Carregando Recursos",
      formatter: loadingFunc,
    },
  ];

  const loadingResources = [
    { resources: "" },
    { resources: "" },
    { resources: "" },
    { resources: "" },
    { resources: "" },
  ];
  /* [END] loading table */

  /* In case of empty table*/
  const notFoundColumns = [{ dataField: "resources", text: "Recursos" }];

  const notFoundResources = [{ resources: "Nenhuma categoria encontrada" }];
  /* [END] In case of empty table*/

  // formatter de status
  function addStatus(cell: any, row: any) {
    return (
      <div
        className={
          cell === "Em análise"
            ? "bg-analise"
            : cell === "Ativo"
              ? "bg-ativo "
              : cell === "Inativo"
                ? "bg-inativo"
                : ""
        }
      >
        {cell}
      </div>
    );
  }

  function addDescription(cell: any, row: any) {
    return (
      row.vehicleIdentification ?
      `${cell} – ${row.vehicleIdentification}`
      :
      `${cell}`
    );
  }

  const rowClasses = (row: any, rowIndex: any) => {
    if (row.status === "Em Análise") {
      return "border-analise";
    } else if (row.status === "Ativo") {
      return "border-ativo";
    } else {
      return "border-inativo";
    }
  };




  //botao de ação
  function addActionButton(cell: any, row: any) {
    return (
      <>
        <div className="d-flex justify-content-center">
          <Dropdown drop="start">
            <Dropdown.Toggle variant="light ">
              <FontAwesomeIcon size="lg"  icon={["fal", "ellipsis-h"]} />
            </Dropdown.Toggle>

            <Dropdown.Menu>
              {/* <Dropdown.Item eventKey="1" disabled={true} ><FontAwesomeIcon icon={['fal', 'comment-alt-dollar']} />{t('suppliersTable.account')}</Dropdown.Item> */}
              <Dropdown.Item eventKey="1" href={"/editar-resource?id=" + cell + "&type=" + row.resourceTypeId}><FontAwesomeIcon icon={['fal', 'edit']} />{t('suppliersTable.edit')}</Dropdown.Item>
              <Dropdown.Item eventKey="2" onClick={() => { handleClickStatus(row); }}><FontAwesomeIcon icon={['fal', 'circle']} />{t('suppliersTable.status')}</Dropdown.Item>
              {/* <Dropdown.Item eventKey="3" href={"/adicionar-resource?id=" + cell + "&type=" + row.resourceTypeId}><FontAwesomeIcon icon={['fal', 'copy']} />{t('suppliersTable.duplicate')}</Dropdown.Item> */}
              {/* <Dropdown.Item eventKey="5" disabled={true}><FontAwesomeIcon icon={['fal', 'file-contract']} />{t('suppliersTable.contract')}</Dropdown.Item>
              <Dropdown.Item eventKey="4" disabled={true} className="text-danger remove"><FontAwesomeIcon icon={['fal', 'trash-alt']} />{t('suppliersTable.remove')}</Dropdown.Item> */}
            </Dropdown.Menu>
          </Dropdown>
        </div>
      </>
    );
  }

  if (resources !== null) {
    return (
      <>
        <div className="table-default resources-table ">
          <div className="table-container">
            <div className="table-title">
              <h3 className="">{t("Resources")}</h3>
            </div>
            <BootstrapTable
              bootstrap4
              keyField="id"
              data={resources}
              hover={true}
              columns={columns}
              striped={true}
              rowClasses={rowClasses}
            />

            <Pagination
              totalRows={totalRows}
              pageCount={pageCount}
              setPageCount={setPageCount}
              rowsPerPage={rowsPerPage}
              setRowsPerPage={setRowsPerPage}
              selectNumberRows="yes"
            />

            <Modal
              className="modalAuth modal-resource"
              show={modalUpdateStatus}
              onHide={() => { setResourceId(null); setResourceTypeId(null); setModalUpdateStatus(false); }}
              size="lg"
              aria-labelledby="contained-modal-title-vcenter"
            >
              <>
                <div className='status-modal'>
                  <Form className="modal-content modal-resource" noValidate validated={false} onSubmit={handleSubmit(onSubmit)} autoComplete="false">
                    <Modal.Body>

                      <div className="modal-header rounded-0">
                        <div>
                          <h4 className="pt-1 text-primary title-reservation">Editar Status</h4>
                          <small>

                          </small>
                        </div>

                        <div className='buttons-options'>
                          <div>

                          </div>
                          <div>
                            <FontAwesomeIcon
                              icon={['fal', 'times']}
                              className="mx-3 text-info"
                              size="lg"
                              data-dismiss="modal"
                              aria-label="Close"
                              onClick={() => { setResourceId(null); setResourceTypeId(null); setModalUpdateStatus(false) }}
                            />
                          </div>
                        </div>
                      </div>

                      <hr className='w-100 my-1' style={{ borderBottom: '1px solid' }} />

                      <div>
                        <Form.Group as={Col} md="5" controlId="formInfoCompanySubscription">
                          <Form.Label>Tem certeza que deseja alterar o status?</Form.Label>
                          <Controller
                            control={control}
                            name="updateStatus"
                            rules={{ required: { value: true, message: 'Por favor, escolha uma opção.' } }}
                            //defaultValue={'2'}
                            render={({ field }: any) => (
                              <Form.Select
                                {...field}
                                as="select"
                                variant="standard"
                                margin="normal"
                                style={{ border: "1px solid #ced4da" }}
                                required
                              >
                                <option value='0' selected disabled>Selecione</option>
                                <option value='1'>Ativar</option>
                                <option value='2'>Desativar</option>
                              </Form.Select>
                            )}
                          />
                          <ErrorMessage
                            errors={errors}
                            name="updateStatus"
                            render={({ message }) => <small style={{ color: "red" }}>{message}</small>}
                          />
                        </Form.Group>
                      </div>
                    </Modal.Body>
                    <Modal.Footer>
                      <div className="modal-footer rounded-0 border-0">
                        <button type="button" className="btn-default mr-2 text-uppercase btn btn-outline-primary" data-dismiss="modal" onClick={() => { setResourceId(null); setResourceTypeId(null); setModalUpdateStatus(false) }}><i className="fas fa-times fa-fw"></i> <span data-vouchercampo="close">Cancelar</span></button>
                        <button type="submit" className="btn-default text-uppercase btn btn-primary">
                          {loading === true ? <div className="load"></div> : "Confirmar"}
                        </button>
                      </div>
                    </Modal.Footer>
                  </Form>
                </div>
              </>
            </Modal>

            <Modal
              className="modalAuth"
              show={modalConfirmStatus}
              onHide={() => { reloadTable(); setModalConfirmStatus(false); }}
              aria-labelledby="contained-modal-title-vcenter"
            >
              <>
                <Modal.Body className='modal-body text-center sucess-pos d-flex justify-content-evenly' style={{ height: "600px" }}>
                  {modalConfirmStatusLoading === true
                    ?
                    <>
                    <div className="loading-modal">

                      <div className="load"></div>
                    </div>
                    </>
                    :
                    <div>
                      {modalConfirmStatusLog === 0
                        ?
                        <FontAwesomeIcon
                          icon={["fal", "check-circle"]}
                          size="5x"
                          className="text-primary"
                          style={{ fontSize: "7.5em" }}
                        />
                        :
                        <FontAwesomeIcon
                          icon={["fal", "times-circle"]}
                          size="5x"
                          className="text-primary"
                          style={{ fontSize: "7.5em" }}
                        />
                      }
                    </div>
                  }
                  <div>
                    {modalConfirmStatusMessage}
                  </div>
                  <div className="d-flex justify-content-center pt-3">
                    <button
                      onClick={() => { reloadTable(); setModalConfirmStatus(false); }}
                      className="btn btn-primary mx-2 w-25"
                    >
                      Confirmar
                    </button>
                  </div>

                </Modal.Body>
              </>
            </Modal>

          </div>
        </div>
      </>
    );
  } else if (resources === null && loading === true) {
    return (
      <>
        <div className="table-default resources-table loading">
          <div className="table-container">

            <BootstrapTable
              bootstrap4
              keyField="id"
              data={loadingResources}
              hover={true}
              columns={loadingColumns}
              striped={true}
            />

            <Pagination
              totalRows={totalRows}
              pageCount={pageCount}
              setPageCount={setPageCount}
              rowsPerPage={rowsPerPage}
              setRowsPerPage={setRowsPerPage}
              selectNumberRows="yes"
            />

          </div>
        </div>
      </>
    );
  } else {
    return (
      <>
        <div className="table-default resources-table loading not-found">
          <div className="table-container">

            <BootstrapTable
              bootstrap4
              keyField="id"
              data={notFoundResources}
              hover={true}
              columns={notFoundColumns}
              striped={true}
            />

          </div>
        </div>
      </>
    );
  }
};

export default ResourcesTable;
