import React, { useEffect, useState } from "react";

import { Row, Col, Form } from "react-bootstrap";
import { Controller, useForm } from "react-hook-form";
import { ErrorMessage } from '@hookform/error-message';

import GetSuppliersFilter from "../../C2Points/GetSuppliersFilter";

import "react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css";
import "react-bootstrap-table-next/dist/react-bootstrap-table2.css";
import SingleCalendarEditable from "../../Calendar/SingleCalendarEditable";
import api from "../../../services/api";
import { useTranslation } from "react-i18next";

export interface propTable {
    billEdit: any;
    validaFornLiquidacao?: any;
    accountId: any;
    accountName: any;
    refreshData: any;
    modalShow: any;
    setModalShow: any;
    show: any;
    setModalContent: any;
    setResponseText: any;
    setLog: any;
}

const FormBillsToPayExtract: React.FC<propTable> = ({
    billEdit,
    accountId,
    accountName,
    refreshData,
    modalShow,
    setModalShow,
    show,
    setModalContent,
    setResponseText,
    setLog
}: propTable) => {
    const { t } = useTranslation();

    //const validated = false;
    
    const [datapgto, setDatapgto] = useState<any>(undefined);

    const tourAuth: any = JSON.parse(localStorage.getItem('c2tourAuth') || '{}');

    const userName: any = tourAuth.user.nome;
    const userLastName: any = tourAuth.user.lastName;
    const uId: any = tourAuth.user.uId;

    const {
        control,
        handleSubmit,
        reset,
        formState: { errors },
    } = useForm();

    useEffect(() => {
    if (modalShow === false) {
        reset({
        data: undefined
        })
    }
    }, [reset, /*  */modalShow])

    const onSubmit = (data: any) => {
        show(true);
        setModalContent('loading');

        var fixDate = datapgto.split('/');
        data.dataPgto = fixDate.length === 3 ? `${fixDate[2]}-${fixDate[1]}-${fixDate[0]}` : undefined;
        data.fornLiquidacao = data.fornLiquidacao ? data.fornLiquidacao.supplierId : 0;
        data.contaLiquidacao = accountId;

        //console.log(selectedSingleRow.length > 0);
        //return;

        async function settle() {
            const token = localStorage.getItem('GroupId') || '{}';
            const config = {
            headers: { 'Authorization': `Bearer ${token}`, 'Content-Type': 'application/json' },
            };
            try {

            const res = await api.post('/BillsPayable/SettleBills',
                {
                "parametros": null, // objeto com os parametros da consulta
                "items": 
                    [{
                        "contasaPagarId": billEdit.contasaPagarId,
                        "id": billEdit.manifestId,
                        "tipo": billEdit.tipo
                    }], // array com os itens a serem liquidados
                "fornLiquidacao": data.fornLiquidacao ? parseInt(data.fornLiquidacao) : 0, //
                "descTaxa": data.descTaxa ? parseInt(data.descTaxa) : 0, // valor taxa desconto
                "jurosMulta": data.jurosMulta ? parseInt(data.jurosMulta) : 0, // valor taxa juros
                "contaLiquidacao": data.contaLiquidacao ? parseInt(data.contaLiquidacao) : 0, // conta liquidação
                "dataPgto": data.dataPgto, // data do pagamento
                "uid": uId,
                "usuario": (userName !== null && userLastName !== null) ? `${userName} ${userLastName}` : userName
                }, config
            );
            if (res.status !== 400) {
                const dados = res.data.data;
                setResponseText(dados.texto);
                setLog(dados.log);

                if (dados.log === 0) {
                    setModalShow(false);
                    setModalContent('successNoRefresh');
                    refreshData();
                }
                else if (dados.log === 1) {
                    setModalContent('error');
                }
            }
            } catch (error: any) {
                if (error?.response?.status === 401) {
                }
            }
        }
        settle();
    }

    /* RENDER */
    return (
        <>
            <Form className="h-100" noValidate autoComplete="false">
                <div className='d-flex flex-column justify-content-between h-100'>
                    <div>
                        <Row className="mb-3">
                            <Form.Group as={Col} md={6} /* controlId="formGridDesc" */>
                                <Form.Label>{t("billsToPay.table.paymentAndDebitNotes.payItTo")}</Form.Label>
                                <Controller
                                    control={control}
                                    name="fornLiquidacao"
                                    //rules={{ required: { value: true, message: 'Campo obrigatório' } }}
                                    render={({ field }: any) => (
                                        <GetSuppliersFilter propsField={field} propsLabel={t("billsToPay.table.paymentAndDebitNotes.payItTo")} propsErrors={errors} />
                                    )}
                                />
                                <ErrorMessage
                                    errors={errors}
                                    name="fornLiquidacao"
                                    render={({ message }) => <small style={{ color: "red" }}>{message}</small>}
                                />
                            </Form.Group>
                        </Row>
                        <Row className="mb-3">
                            <Form.Group as={Col} md={3} /* controlId="formGridDesc" */>
                                <Form.Label>{t("billsToPay.table.paymentAndDebitNotes.interestFine")}</Form.Label>
                                <Controller
                                    control={control}
                                    name="jurosMulta"
                                    render={({ field }: any) => (
                                        <Form.Control
                                            {...field}
                                            aria-invalid={errors?.jurosMulta ? "true" : ""}
                                            variant="standard"
                                            margin="normal"
                                            id="juros"
                                            autoComplete="off"
                                        />
                                    )}
                                />
                            </Form.Group>
                            <Form.Group as={Col} md={3} /* controlId="formGridDesc" */>
                                <Form.Label>{t("billsToPay.table.paymentAndDebitNotes.dicount")}</Form.Label>
                                <Controller
                                    control={control}
                                    name="descTaxa"
                                    render={({ field }: any) => (
                                        <Form.Control
                                            {...field}
                                            aria-invalid={errors?.descTaxa ? "true" : ""}
                                            variant="standard"
                                            margin="normal"
                                            id="desconto"
                                            autoComplete="off"
                                        />
                                    )}
                                />
                            </Form.Group>
                            <Form.Group as={Col} md={3} /* controlId="formGridDesc" */>
                                <Form.Label>{t("billsToPay.table.paymentAndDebitNotes.paymentDate")}</Form.Label>
                                <Controller
                                    control={control}
                                    name="dataPgto"
                                    rules={{ required: { value: datapgto ? false : true, message: t("billsToPay.table.paymentAndDebitNotes.requiredField") } }}
                                    render={({ field }: any) => (
                                        // <SingleCalendar setValue={setDatapgto} field={field} errors={errors} />
                                        <SingleCalendarEditable setValue={setDatapgto} date={datapgto} errors={errors} />
                                    )}
                                />
                                <ErrorMessage
                                    errors={errors}
                                    name="dataPgto"
                                    render={({ message }) => <small style={{ color: "red" }}>{message}</small>}
                                />
                            </Form.Group>
                            <Form.Group as={Col} md={3} /* controlId="formGridDesc" */>
                                <Form.Label>{t("billsToPay.table.paymentAndDebitNotes.settlementBill")}</Form.Label>
                                <input
                                    type="text"
                                    className="form-control"
                                    disabled={true}
                                    value={accountName}
                                    autoComplete='off'
                                />
                                <ErrorMessage
                                    errors={errors}
                                    name="contaLiquidacao"
                                    render={({ message }) => <small style={{ color: "red" }}>{message}</small>}
                                />
                            </Form.Group>
                        </Row>
                    </div>
                </div>
            
                <div className="d-flex justify-content-end">
                    <button type="submit" className="btn-default text-uppercase btn btn-primary mt-3" onClick={handleSubmit(onSubmit)}>{t("billsToPay.table.paymentAndDebitNotes.settlement")}</button>
                </div>
            </Form>
        </>
    );
}


export default FormBillsToPayExtract;
