import React, { useContext, useEffect, useState } from "react";
import api from '../../../services/api';
import {
  AccordionContext,
  Accordion,
  Container,
  Row,
  useAccordionButton,
} from "react-bootstrap";

import "./AddSuppliers.scss";

import { useTranslation } from "react-i18next";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import Breadcrumb from "../../../components/Breadcrumb/Breadcrumb";
import AccordionInfoCompany from "../components/AccordionInfoCompany/AccordionInfoCompany";
import AccordionAccount from "../components/AccordionAccount/AccordionAccount";
import AccordionUsers from "../components/AccordionUsers/AccordionUsers";
import AccordionInfoAdd from "../components/AccordionInfoAdd/AccordionInfoAdd";
import AccordionResources from "../components/AccordionResources/AccordionResources";
import AccordionFinancial from "../components/AccordionFinancial/AccordionFinancial";

import RequestResponseModal from "../../../components/Modal/RequestResponseModal/RequestResponseModal";
import Modal from "react-bootstrap/Modal";
import DesktopDefault from "../../../templates/DesktopDefault";


const AddSuppliers = React.memo(() => {
  const { t } = useTranslation();
  const [stepCtrl, setStepCtrl] = useState<number>(0);
  const [supplierControlObject, setSupplierControlObject] = useState<any>();
  const [supplierObject, setSupplierObject] = useState<any>();
  const [errors, setErrors] = React.useState({});
  const [modalResponse, setModalResponse] = useState<any>(null); /// ["loading","error","sucess"] para abrir o modal pós envio
  const [modalText, setModalText] = useState<any>(null);
  const [allowGoToList, setAllowGoToList] = useState<any>(false);
  const [log, setLog] = useState<any>();

  const [savedSteps, setSavedSteps] = useState<any>(0);

  const groupId: any = localStorage.getItem("GroupId");

  function ScrollTop() {
    window.scrollTo(0, 0);
  }

  const handleNext = (newObject: any) => {
    if (stepCtrl === savedSteps) {
      setSavedSteps(savedSteps + 1);
    }
    setSupplierObject(newObject);
    setStepCtrl(stepCtrl + 1);
  };

  const handleFinished = (objectFinished: any) => {
    objectFinished.zipCode = objectFinished.zipCode.replace(/-/g, '')
    objectFinished.groupId = parseInt(groupId);
    sendSupplier(objectFinished);
  }

  const sendSupplier = async (objectSupplier: object) => {
    setModalResponse("loading");
    const token = localStorage.getItem('GroupId') || '{}';
    const config = {
      headers: { 'Authorization': `Bearer ${token}`, 'Content-Type': 'application/json' },
    };
    try {
      const str = JSON.stringify(objectSupplier);
      const { data } = await api.post('Supplier/CreateSupplierAsync', str, config);
      if (data.statusCode !== 400) {
        if (data.data.log === 0) {
          setAllowGoToList(true);
        }else{
          setAllowGoToList(false);
        }
        setModalResponse("success");
        setModalText(data.data.texto);
        setLog(data.data.log);
      } else {
        setModalText("Erro ao cadastrar fornecedor. Entre em contato com o suporte.");
        //setModalText(data.data.texto);
        setModalResponse("error");
        setLog(data.data.log);
      }

    } catch (error: any) {
      if (error?.response?.status === 400) {
        setModalResponse("error");
        setErrors(error.response.data.errors)
      }
    }
  }

  useEffect(() => {
    setSupplierControlObject((prevState: any) => {
      return { ...prevState, ...supplierObject };
    });
  }, [supplierObject]);


  useEffect(() => {
    if (stepCtrl >= 6) {
      handleFinished(supplierControlObject);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [stepCtrl]);

  const ContextAwareToggle = ({
    children,
    eventKey,
    callback,
    status,
  }: any) => {
    const { activeEventKey } = useContext(AccordionContext);

    const decoratedOnClick = useAccordionButton(
      eventKey,
      () => callback && callback(eventKey)
    );

    const isCurrentEventKey = activeEventKey === eventKey;

    return (
      <h2 className="accordion-custom accordion-header">
        <button
          type="button"
          aria-expanded="false"
          className={
            isCurrentEventKey
              ? "accordion-button"
              : "accordion-button collapsed"
          }
          onClick={decoratedOnClick}
          disabled={status}
        >
          {children}
        </button>
      </h2>
    );
  };

  useEffect(() => {
    if (allowGoToList === true && modalResponse === null) {
      window.location.href = '/suppliers';
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [modalResponse])

  return (
    <>
      <DesktopDefault>
        <div className="container-fluid content-dashboard">
          <Breadcrumb title={t("addSuppliers.subTitle") + " / " + t("addSuppliers.title")} />
          <div className="suppliers mt-4">
            <div className="d-flex align-items-center">
              <span className='back' onClick={() => { window.location.href = '/suppliers' }}>
                <FontAwesomeIcon
                  icon={["fal", "angle-double-left"]}
                  style={{ margin: "0 5px 0 0", fontSize: '16px' }}
                />
                Voltar</span>
            </div>
            <h2 className="title">{t("addSuppliers.title")}</h2>
            <Container className="suppliers-guide steps-guide">
              <div className="row">
                <div className="steps d-flex px-3 pb-1 px-md-0 col">
                  <div className="step complete">
                    1
                    <br />
                    <span className="complete">{t("addSuppliers.infoCompany")}</span>
                  </div>
                  <div
                    className={
                      stepCtrl > 0 ? "stepline complete" : "stepline "
                    }
                  ></div>
                  <div className={stepCtrl > 0 ? "step complete" : "step "}>
                    2
                    <br />
                    <span className={stepCtrl > 0 ? "complete" : ""}>
                      {t("addSuppliers.financial")}
                    </span>
                  </div>
                  <div
                    className={
                      stepCtrl > 1 ? "stepline complete" : "stepline "
                    }
                  ></div>
                  <div className={stepCtrl > 1 ? "step complete" : "step "}>
                    3
                    <br />
                    <span className={stepCtrl > 1 ? "complete" : ""}>
                      {t("addSuppliers.account")}
                    </span>
                  </div>
                  <div
                    className={
                      stepCtrl > 2 ? "stepline complete" : "stepline "
                    }
                  ></div>
                  <div className={stepCtrl > 2 ? "step complete" : "step "}>
                    4
                    <br />
                    <span className={stepCtrl > 2 ? "complete" : ""}>
                      {t("addSuppliers.resources")}
                    </span>
                  </div>
                  <div
                    className={
                      stepCtrl > 3 ? "stepline complete" : "stepline "
                    }
                  ></div>
                  <div className={stepCtrl > 3 ? "step complete" : "step "}>
                    5
                    <br />
                    <span className={stepCtrl > 3 ? "complete" : ""}>
                      {t("addSuppliers.users")}
                    </span>
                  </div>
                  <div
                    className={
                      stepCtrl > 4 ? "stepline complete" : "stepline "
                    }
                  ></div>
                  <div className={stepCtrl > 4 ? "step complete" : "step "}>
                    6
                    <br />
                    <span className={stepCtrl > 4 ? "complete" : ""}>
                      {t("addSuppliers.infoAdd")}
                    </span>
                  </div>
                </div>
              </div>
              <div className="step-labels row">
                <div className="d-flex justify-content-between px-3 px-md-2 pb-3 pb-md-5 col"></div>
              </div>
            </Container>
          </div>
          <Accordion defaultActiveKey="0" flush>
            {stepCtrl === 0 ? (
              <AccordionInfoCompany
                action={handleNext}
                ContextAwareToggle={ContextAwareToggle}
                isEdit={false}
                supplierControlObject={supplierControlObject}
                editSuppliers={() => { }}
                scrollTop={ScrollTop}
                hasSavedInputs={savedSteps > 0 ? true : false}
                infoEdit={supplierControlObject}
              />
            ) : (
              ""
            )}
            {stepCtrl === 1 ? (
              <AccordionFinancial
                setChangeEdit={false}
                action={handleNext}
                ContextAwareToggle={ContextAwareToggle}
                isEdit={false}
                supplierControlObject={supplierControlObject}
                setStepCtrl={setStepCtrl}
                editSuppliers={() => { }}
                scrollTop={ScrollTop}
                hasSavedInputs={savedSteps > 1 ? true : false}
                infoEdit={supplierControlObject}
              />
            ) : (
              ""
            )}
            {stepCtrl === 2 ? (
              <AccordionAccount
                setChangeEdit={false}
                action={handleNext}
                ContextAwareToggle={ContextAwareToggle}
                supplierControlObject={supplierControlObject}
                setStepCtrl={setStepCtrl}
                editSuppliers={() => { }}
                scrollTop={ScrollTop}
                hasSavedInputs={savedSteps > 2 ? true : false}
                infoEdit={supplierControlObject}
              />
            ) : (
              ""
            )}
            {stepCtrl === 3 ? (
              <AccordionResources
                setChangeEdit={false}
                action={handleNext}
                ContextAwareToggle={ContextAwareToggle}
                supplierControlObject={supplierControlObject}
                setStepCtrl={setStepCtrl}
                editSuppliers={() => { }}
                scrollTop={ScrollTop}
                hasSavedInputs={savedSteps > 3 ? true : false}
                infoEdit={supplierControlObject}
              />
            ) : (
              ""
            )}
            {stepCtrl === 4 ? (
              <AccordionUsers
                setChangeEdit={false}
                action={handleNext}
                ContextAwareToggle={ContextAwareToggle}
                infoEdit={supplierControlObject}
                supplierControlObject={supplierControlObject}
                setStepCtrl={setStepCtrl}
                editSuppliers={() => { }}
                hasSavedInputs={savedSteps > 4 ? true : false}
                scrollTop={ScrollTop}
              />
            ) : (
              ""
            )}
            {stepCtrl >= 5 ? (
              <AccordionInfoAdd
                setChangeEdit={false}
                action={handleNext}
                ContextAwareToggle={ContextAwareToggle}
                infoEdit={false}
                setStepCtrl={setStepCtrl}
                editSuppliers={() => { }}
                scrollTop={ScrollTop}
              />
            ) : (
              ""
            )}
          </Accordion>
          {/* <Row className="d-flex justify-content-end mx-0 mt-5 mb-4">
              <div className="btn-sucess">
                <ModalDefault>
                  <SighUpSucess name={"Fornecedor"}  onHide={setModalSucessShow}/>
                </ModalDefault>
              </div>
            </Row> */}
        </div>
      </ DesktopDefault>

      <Row className="d-flex justify-content-end mx-0 mt-5 mb-4">
        <div className="btn-sucess">
          <Modal
            className="modal-custom modalAuth"
            show={modalResponse !== null}
            onHide={() => setModalResponse(null)}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
          >
            <>
              {/* <Modal.Header closeButton></Modal.Header> */}
              <RequestResponseModal
                name={"Fornecedor"}
                modalResponse={modalResponse}
                setModalResponse={setModalResponse}
                errors={errors}
                modalText={modalText}
                log={log}
              />
            </>
          </Modal>
        </div>
      </Row>
    </>
  );
});

export default AddSuppliers;
