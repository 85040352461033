/* eslint-disable @typescript-eslint/no-unused-vars */
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ErrorMessage } from "@hookform/error-message";
import React, { useState, Key, useEffect } from "react";
import { Button, Col, Container, Form, Row } from "react-bootstrap";
//import Draggable from "react-draggable";
import { Controller, useForm } from "react-hook-form";
import Breadcrumb from "../../../../components/Breadcrumb/Breadcrumb";
import api from "../../../../services/api";
import DesktopDefault from "../../../../templates/DesktopDefault";
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';

import { Modal, OverlayTrigger, Dropdown, Tooltip } from 'react-bootstrap';

import AutoComplete, { usePlacesWidget } from "react-google-autocomplete";
import { geocodeByAddress, getLatLng } from 'react-google-places-autocomplete';

import './AddPickup.scss';
import GetSupplierSupplierIdentity from "../../../../components/C2Points/GetSupplierSupplierIdentity";
import GetSupplierGroupIdentity from "../../../../components/C2Points/GetSupplierGroupIdentity ";

export interface propEdit {
  //     (
  //      isEdit?: any;
  //      pickupInfo?: any;
  //      setIsEdit?: any;
  // )
}

const AddPickup: React.FC<propEdit> = () =>
// {isEdit = false, pickupInfo, setIsEdit = () => { }}: propEdit
{
  const tourAuth: any = JSON.parse(localStorage.getItem('c2tourAuth') || '{}');

  const userName: any = tourAuth.user.nome;
  const userLastName: any = tourAuth.user.lastName;
  const groupId: any = tourAuth.user.perfil.groupId;

  const [latitude, setLat] = useState<any>([]);
  const [longitude, setLong] = useState<any>([]);
  const [pickupAddresses, setPickupAddresses] = useState<any>(['']);
  const [addLocation, setAddLocation] = useState<any>([
    {
      "id": 0,
      "name": "",
      "group_id": groupId,
      "minutesPrior": "",
      "additionalInstructions": "",
      "pickup_id_stranger": 10,
      "address": 0,
      "latitude": latitude,
      "longitude": longitude,
      "status_edit": 0,
      "posicao": 0
    }
  ]);
  const [checked, setChecked] = useState<any>(false);

  const [local, setLocal] = useState<any>([]);
  const [excludedItems, setExcludedItems] = useState<any>([]);

  const [pickupNames, setPickupNames] = useState<any>(['']);

  const [modalShow, setModalShow] = useState<any>(false)
  const [modalLog, setModalLog] = useState<any>(null)
  const [modalMessage, setModalMessage] = useState<any>('Carregando');

  useEffect(() => {
    if (modalShow === false && modalLog === 0) {
      window.location.href = '/pickup';
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [modalShow])

  const validated = false;

  const {
    control,
    handleSubmit,
    formState: { errors },
    watch,
  } = useForm();

  const onSubmit = (data: any) => {   

    setModalShow(true);
    let auxLocation: any = [];
    let controlPosition: any = 1;
    for (let i: any = 0; i < addLocation.length; i++) {
      if (!(excludedItems.includes(addLocation[i].id))) {
        const pickupName = watch(`pickupName${addLocation[i].id}`, "");
        const pickupMinutes = watch(`search${addLocation[i].id}`, "");
        const pickupInstructions = watch(`instruction${addLocation[i].id}`, "");        
        // const pickupInstructions = watch(`address${addLocation[i].id}`, "");        
        
        auxLocation.push(
          {
            "id": addLocation[i].id,
            "name": pickupName === undefined ? "" : pickupName,
            "groupId": groupId,
            "minutesPrior": pickupMinutes === undefined ? 0 : Number(pickupMinutes),
            "additionalInstructions": pickupInstructions === undefined ? "" : pickupInstructions,
            "pickupIdStranger": 10,
            "address": pickupAddresses[addLocation[i].id] === undefined ? data[`address${i}`] : pickupAddresses[addLocation[i].id],
            "latitude": latitude[i],
            "longitude": longitude[i],
            "statusEdit": 0,
            "posicao": controlPosition
          }
        );
        controlPosition += 1;
      }  
    }

    data.comp_name = data.comp_name === undefined ? "" : data.comp_name;
    data.comp_fantasia = data.comp_fantasia === undefined ? "" : data.comp_fantasia;
    data.adcionalnotes = data.adcionalnotes === undefined ? "" : data.adcionalnotes;
    data.pickupclient = data.pickupclient === true ? 1 : 0;    


    console.log(auxLocation)
    //setLoading(true)

    //setModalContent("loading");

    const InsertPickup = async () => {
      try {
        const res = await api.post(`/Pickup/InsertPickup`,
          {
            "id": 0,
            "compName": data.comp_name,
            "compFantasia": /*parseInt(data.comp_fantasia.id)*/ data.comp_fantasia.description,
            "adcionalNotes": data.adcionalnotes,
            "groupId": parseInt(data.comp_fantasia.id),
            "pickupClient": data.pickupclient,
            "user": `${userName} ${userLastName}`,
            "list": auxLocation,
          });
        if (res.status !== 400) {
          setModalLog(res.data.data.log);
          setModalMessage(res.data.data.texto);
        }
      } catch (error: any) {
        setModalLog(1);
        setModalMessage(error?.response?.data.errorMessage || "Erro ao processar operação");
      }
    };

    InsertPickup();   
  }
  

  function Location(place: any, index: any) {
    //local[index] = place;
    pickupAddresses[index] = place;
  }

  function LocationLat(lat: any, index: any) {
    let aux:any = [...latitude] 
    latitude[index] = lat;
    aux.push(lat)
    setLat(aux)
    return latitude[index]
  }

  function LocationLng(lng: any, index: any) {
    let aux:any = [...longitude] 
    longitude[index] = lng;    
    aux.push(lng)
    setLong(aux)
    return longitude[index]
  }

  function pushLocation() {
    let aux: any = [
      {
        id: addLocation.length,
        name: "",
        groupId: groupId,
        minutesPrior: "",
        additionalInstructions: "",
        pickup_id_stranger: 10,
        address: "",
        latitude: 0,
        longitude: 0,        
        status_edit: 0,
        posicao: addLocation.length,
      },
      ...addLocation,
    ];

    /*  */
    /* let aux: any = [...addLocation, {
      "id": addLocation.length,
      "name": "",
      "group_id": groupId,
      "minutesPrior": "",
      "additionalInstructions": "",
      "pickup_id_stranger": 10,
      "address": '',
      "latitude": 0,
      "longitude": 0,
      "status_edit": 0,
      "posicao": addLocation.length
    }];

    setPickupNames([...pickupNames, '']);
    setPickupAddresses([...pickupAddresses, '']); */

    /*  */

    /* let aux: any = [];
    for (let i: any = 0; i < addLocation.length; i++) {
      const pickupName = watch(`pickupName${i}`, "");
      const pickupMinutes = watch(`search${i}`, "");
      const pickupInstructions = watch(`instruction${i}`, "");

      aux.push({
        "id": i,
        "name": pickupName === undefined ? "" : pickupName,
        "group_id": groupId,
        "minutesPrior": pickupMinutes === undefined ? "" : Number(pickupMinutes),
        "additionalInstructions": pickupInstructions === undefined ? "" : pickupInstructions,
        "pickup_id_stranger": 10,
        "address": local[i] === undefined ? "" : local[i],
        "latitude": latitude[i] === undefined ? "" : latitude[i],
        "longitude": longitude[i] === undefined ? "" : longitude[i],
        "status_edit": 0,
        "posicao": (i + 1)
      });
    }

    aux.push({
      "id": addLocation.length,
      "name": "",
      "group_id": groupId,
      "minutesPrior": "",
      "additionalInstructions": "",
      "pickup_id_stranger": 10,
      "address": 0,
      "latitude": 0,
      "longitude": 0,
      "status_edit": 0,
      "posicao": 1
    }); */

    setAddLocation(aux);
  }

  function handleChecked() {
    if (checked === false) {
      setChecked(true);
    } else {
      setChecked(false);
    }
  }

  /*   function handleChangeName() {
  
    } */


  const handleRemoveItem = (index: any) => {
    setExcludedItems([...excludedItems, index]);
    let remove: any = (document.querySelector(`#draggable-list-item-${index}`) || '');
    remove.style.display = 'none';
    //let aux: any = [];

    /* for (let i: any = 0; i < addLocation.length; i++) {

      if (index !== i) {
        const pickupName = watch(`pickupName${i}`, "");
        const pickupMinutes = watch(`search${i}`, "");
        const pickupInstructions = watch(`instruction${i}`, "");

        aux.push({
          "id": i,
          "name": pickupName === undefined ? "" : pickupName,
          "group_id": groupId,
          "minutesPrior": pickupMinutes === undefined ? "" : Number(pickupMinutes),
          "additionalInstructions": pickupInstructions === undefined ? "" : pickupInstructions,
          "pickup_id_stranger": 10,
          "address": local[i] === undefined ? "" : local[i],
          "latitude": latitude[i] === undefined ? "" : latitude[i],
          "longitude": longitude[i] === undefined ? "" : longitude[i],
          "status_edit": 0,
          "posicao": (i + 1)
        });
      }
    }
    setAddLocation(aux); */
  };

  /* const handleChangePickupNames = (event: any, index: any) => {
    console.log('não era pra entrar')
    let aux: any = [...pickupNames];
    aux[index] = event.target.value;
    setPickupNames(aux);
  }

  const handleChangePickupAddresses = (event: any, index: any) => {
    let aux: any = [...pickupAddresses];
    aux[index] = event;
    setPickupAddresses(aux);
  } */

  function handleOnDragEnd(result: any) {
    if (!result.destination) return;

    const items = Array.from(addLocation);
    const [reorderedItem] = items.splice(result.source.index, 1);
    items.splice(result.destination.index, 0, reorderedItem);

    setAddLocation(items);
  }

  const goToList = () => {
    if (modalLog === 0) {
      window.location.href = '/pickup';
    }
  }

  return (
    <>
      <DesktopDefault>
        <div className="container-fluid content-dashboard">
          <Breadcrumb title={"Pickup / Adicionar Pickup"} />
          <div className="mt-4">

            <div className="d-flex align-items-center">
              <span className='back' onClick={() => { window.location.href = '/pickup' }}>
                <FontAwesomeIcon
                  icon={["fal", "angle-double-left"]}
                  style={{ margin: "0 5px 0 0", fontSize: '16px' }}
                />
                Voltar</span>
            </div>
            <h2 className="title" style={{ color: "#707070" }}>Adicionar Pickup</h2>
          </div>

          <div className="accordion bg-white mt-4">
            <Container fluid>
              <Form noValidate validated={validated} onSubmit={handleSubmit(onSubmit)} autoComplete="false">
                <Row className="mb-3">
                  <Form.Group as={Col} lg="6" md="6">
                    <Form.Label>Nome do pickup:</Form.Label>
                    <Controller
                      control={control}
                      name="comp_name"
                      rules={{ required: { value: true, message: "Por favor, adicione o nome do pickup." }, }}
                      render={({ field }: any) => (
                        <Form.Control
                          {...field}
                          type="text"
                          aria-invalid={errors?.comp_name ? "true" : ""}
                          variant="standard"
                          margin="normal"
                          autoComplete="off"
                        />
                      )}
                    />
                    <ErrorMessage
                      errors={errors}
                      name="comp_name"
                      render={({ message }) => <small style={{ color: "red" }}>{message}</small>}
                    />
                  </Form.Group>

                  <Form.Group as={Col} lg="6" md="6">
                    <Form.Label>Fornecedor responsável:</Form.Label>
                    <Controller
                      control={control}
                      name="comp_fantasia"
                      rules={{ required: { value: true, message: "Por favor, selecione o fornecedor responsável." }, }}
                      render={({ field }: any) => (
                        <div className="input-select-custom">
                          <GetSupplierGroupIdentity
                            propsField={field}
                            propsLabel={"supplier"}
                            propsErrors={errors}
                          //setFilterSupplier={setFilterSupplier}
                          />
                        </div>
                      )}
                    />
                    {/* <div className="endpoint">
                          <Form.Select
                            {...field}
                            as="select"
                            variant="standard"
                            margin="normal"
                            aria-invalid={errors?.comp_fantasia ? "true" : ""}
                          >
                            <option value="" selected disabled>
                              Selecione
                            </option>
                            <option value="1">Fornecedor 1</option>
                            <option value="2">Fornecedor 2</option>
                          </Form.Select>
                        </div> */}
                    <ErrorMessage
                      errors={errors}
                      name="comp_fantasia"
                      render={({ message }) => <small style={{ color: "red" }}>{message}</small>}
                    />
                  </Form.Group>
                </Row>

                <Row className="mb-3">
                  <Form.Group as={Col} lg="6" md="6">
                    <Form.Label>Nota adicional (Opcional):</Form.Label>
                    <Controller
                      control={control}
                      name="adcionalnotes"
                      render={({ field }: any) => (
                        <Form.Control
                          {...field}
                          type="text"
                          variant="standard"
                          margin="normal"
                          autoComplete="off"
                        />
                      )}
                    />
                  </Form.Group>

                  {/* <Form.Group as={Col} lg="6" md="6">
                    <div className="d-flex">
                      <Controller
                        control={control}
                        name="pickupclient"
                        render={({ field }: any) => (
                          <Form.Check
                            {...field}
                            type="checkbox"
                            variant="standard"
                            margin="normal"
                            required
                            className="mr-2"
                            onClick={handleChecked}
                          />
                        )}
                      />
                      <Form.Label>
                        Permitir solicitações de pickup do cliente
                      </Form.Label>
                    </div>
                    {checked === true ? (
                      <Controller
                        control={control}
                        name="note"
                        render={({ field }: any) => (
                          <Form.Control
                            {...field}
                            type="text"
                            variant="standard"
                            margin="normal"
                            autoComplete="off"
                          />
                        )}
                      />
                    ) : (
                      <></>
                    )}
                  </Form.Group> */}
                </Row>

                <div className="border-bottom py-3"></div>

                <Row>
                  <Form.Group
                    as={Col}
                    md="12"
                    lg="12"
                    className="d-flex justify-content-md-start mt-5 mb-3"
                  >
                    <Button className="btn-default" onClick={pushLocation}>
                      Adicionar Localização
                    </Button>
                  </Form.Group>

                </Row>

                <DragDropContext onDragEnd={handleOnDragEnd}>
                  <Droppable droppableId="addLocation">
                    {(provided: any) => (
                      <ul className="addLocation react-draggable" {...provided.droppableProps} ref={provided.innerRef}>
                        {addLocation.filter((e: any) => !excludedItems.includes(e.id)).map((elem: any, index: any) => {
                          return (
                            <Draggable key={elem.posicao} draggableId={elem.posicao.toString()} index={index}>
                              {(provided: any) => (
                                <li ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps} id={`draggable-list-item-${elem.posicao}`}>
                                  <Container className="px-0" fluid>
                                    <Row className='justify-content-evenly bg-white'>
                                      <Form.Group as={Col} lg="1" md="1" style={{ padding: '0px', maxWidth: '55px' }}>
                                        <div
                                          className="d-flex justify-content-center align-items-center h-100"
                                          style={{ backgroundColor: "#f2f2f2", cursor: "move", position: 'relative' }}
                                        >
                                          <span style={{ position: 'absolute', top: '25%' }}>{index + 1}</span>
                                          <FontAwesomeIcon icon={["fal", "bars"]} />
                                        </div>
                                      </Form.Group>

                                      <Form.Group as={Col} lg="10" md="10">
                                        <Row className="mb-3">
                                          <Form.Group as={Col} lg="4" md="4">
                                            <Form.Label>Local
                                              <OverlayTrigger
                                                overlay={
                                                  <Tooltip id="tooltip">
                                                    Digite o nome do hotel ou da localização
                                                  </Tooltip>
                                                }>
                                                <span>
                                                  <FontAwesomeIcon
                                                    className="ml-2"
                                                    icon={["fal", "question-circle"]}
                                                  />
                                                </span>
                                              </OverlayTrigger>
                                            </Form.Label>
                                            <Controller
                                              control={control}
                                              name={`pickupName${elem.posicao}`}
                                              render={({ field }: any) => (
                                                <Form.Control
                                                  {...field}
                                                  type="text"
                                                  variant="standard"
                                                  margin="normal"
                                                  autoComplete="off"
                                                />
                                              )}
                                            />
                                          </Form.Group>

                                          <Form.Group as={Col} lg="4" md="4">
                                            <Form.Label>Endereço
                                              <OverlayTrigger
                                                overlay={
                                                  <Tooltip id="tooltip">
                                                    Pesquise e selecione usando os resultados do endereço do Google.
                                                  </Tooltip>
                                                }>
                                                <span>
                                                  <FontAwesomeIcon
                                                    className="ml-2"
                                                    icon={["fal", "question-circle"]}
                                                  />
                                                </span>
                                              </OverlayTrigger>
                                            </Form.Label>
                                            <Controller
                                              control={control}
                                              name={`address${elem.posicao}`}
                                              render={({ field }: any) => (
                                                <AutoComplete
                                                  {...field}
                                                  options={{
                                                    types: ["establishment"],
                                                    fields: ["name"]
                                                  }}
                                                  //inputAutocompleteValue={pickupAddresses[elem.id]}
                                                  placeholder=""
                                                  apiKey="AIzaSyBv2aZ2YO_aW4PIEmXoxHgxC8Ps8DB0o-s"
                                                  onChange={((e: any) => { pickupAddresses[elem.posicao] = e.target.value })}
                                                  onPlaceSelected={(place: any) => {                                                    
                                                    Location(place.name, elem.posicao);
                                                    /* handleChangePickupAddresses(place.name, elem.id) */
                                                    // eslint-disable-next-line no-lone-blocks
                                                    {
                                                      geocodeByAddress(`${place.name}`)
                                                        .then(results => getLatLng(results[0]))
                                                        .then(({ lat, lng }) => {
                                                          LocationLat(lat, elem.posicao);
                                                          LocationLng(lng, elem.posicao);
                                                        }
                                                        );
                                                    }
                                                  }}
                                                />
                                              )}
                                            />
                                            <ErrorMessage
                                              errors={errors}
                                              name={`address${elem.posicao}`}
                                              render={({ message }) => <small style={{ color: "red" }}>{message}</small>}
                                            />
                                          </Form.Group>

                                          <Form.Group as={Col} lg="4" md="4">
                                            <Form.Label>Antecedência de busca
                                              <OverlayTrigger
                                                overlay={
                                                  <Tooltip id="tooltip">
                                                    Digite quando seus clientes serão apanhados. Exemplo:30 minutos antes do horário de início do tour.
                                                  </Tooltip>
                                                }>
                                                <span>
                                                  <FontAwesomeIcon
                                                    className="ml-2"
                                                    icon={["fal", "question-circle"]}
                                                  />
                                                </span>
                                              </OverlayTrigger>
                                            </Form.Label>
                                            <Controller
                                              control={control}
                                              name={`search${elem.posicao}`}
                                              render={({ field }: any) => (
                                                <Form.Control
                                                  {...field}
                                                  type="text"
                                                  variant="standard"
                                                  margin="normal"
                                                  autoComplete="off"
                                                  onKeyPress={(e) => !/[0-9]/.test(e.key) && e.preventDefault()}
                                                />
                                              )}
                                            />
                                          </Form.Group>
                                        </Row>

                                        <Row>
                                          <Form.Group as={Col} lg="12" md="12">
                                            <Form.Label>Instruções Adicionais
                                              <OverlayTrigger
                                                overlay={
                                                  <Tooltip id="tooltip">
                                                    Adicione instruções do cliente para este local de retirada. Exemplo: encontre-se no foyer do hotel 15 minutos antes do horário de embarque.
                                                  </Tooltip>
                                                }>
                                                <span>
                                                  <FontAwesomeIcon
                                                    className="ml-2"
                                                    icon={["fal", "question-circle"]}
                                                  />
                                                </span>
                                              </OverlayTrigger>
                                            </Form.Label>
                                            <Controller
                                              control={control}
                                              name={`instruction${elem.posicao}`}
                                              render={({ field }: any) => (
                                                <Form.Control
                                                  {...field}
                                                  type="text"
                                                  variant="standard"
                                                  margin="normal"
                                                  autoComplete="off"
                                                />
                                              )}
                                            />
                                          </Form.Group>
                                        </Row>
                                      </Form.Group>

                                      <Form.Group as={Col} lg="1" md="1">
                                        {
                                          index !== 0
                                            ?
                                            <div
                                              className="d-flex justify-content-center pt-2 h-100 btn bg-transparent border-0 text-primary"
                                              onClick={() => handleRemoveItem(elem.id)}
                                            >
                                              <FontAwesomeIcon icon={["fal", "trash-alt"]} />
                                            </div>
                                            :
                                            <></>
                                        }
                                      </Form.Group>
                                    </Row>
                                  </Container>
                                </li>
                              )}
                            </Draggable>
                          );
                        })}
                        {provided.placeholder}
                      </ul>
                    )}
                  </Droppable>
                </DragDropContext>
                <Row>
                  <Form.Group
                    as={Col}
                    md="12"
                    lg="12"
                    className="d-flex justify-content-md-end mt-5 mb-3"
                  >
                    <Button
                      variant="outline-primary"
                      className="btn-default mr-2 text-uppercase"
                    >
                      Cancelar
                    </Button>
                    <Button
                      type="submit"
                      className="btn-default text-uppercase">
                      Salvar
                    </Button>
                  </Form.Group>
                </Row>
              </Form>
            </Container>

            <Modal
              className="modalAuth"
              show={modalShow}
              onHide={() => { /* setModalLog(null); */ setModalMessage('Carregando'); setModalShow(false); }}
              aria-labelledby="contained-modal-title-vcenter"
            >
              <>
                <Modal.Body className='modal-body text-center sucess-pos d-flex justify-content-evenly' style={{ height: "600px" }}>
                  {modalLog === null
                    ?
                    <>
                      <div className="loading-modal">

                        <div className="load"></div>
                      </div>
                    </>
                    :
                    <div>
                      {modalLog === 0
                        ?
                        <FontAwesomeIcon
                          icon={["fal", "check-circle"]}
                          size="5x"
                          className="text-primary"
                          style={{ fontSize: "7.5em" }}
                        />
                        :
                        <FontAwesomeIcon
                          icon={["fal", "times-circle"]}
                          size="5x"
                          className="text-primary"
                          style={{ fontSize: "7.5em" }}
                        />
                      }
                    </div>
                  }
                  <div>
                    {modalMessage}
                  </div>
                  <div className="d-flex justify-content-center pt-3">
                    <button
                      onClick={() => { goToList(); setModalLog(null); setModalMessage('Carregando'); setModalShow(false); }}
                      className="btn btn-primary mx-2 w-25"
                    >
                      Confirmar
                    </button>
                  </div>

                </Modal.Body>
              </>
            </Modal>

          </div>
        </div>
      </DesktopDefault>
    </>
  );
};

export default AddPickup;
