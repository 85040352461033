import React, { useState, useEffect, useRef } from "react";
import api from "../../services/api";
//import i18next from "i18next";

export interface propProduct {
    setCode?: any;
}

const SeachAllProduct: React.FC<propProduct> = ({setCode}: propProduct) => {

    //const lng = i18next.language === "pt" ? "BR" : i18next.language === "en" ? "EN" : i18next.language === "es" ? "ES" : 'BR';

    const [searchTerm, setSearchTerm] = useState<string>("");
    const [tours, setTours] = useState<any>("");
    //const [locations, setLocations] = useState<any>("");
    const [isOpen, setIsOpen] = useState<any>(false);
    const [searchValue, setSearchValue] = useState<any>("");
    const [valueSelect, setValueSelect] = useState<any>(null);
    //const [productCode, setProductCode] = useState<any>(null);

    /* Detecta clique fora da div#wrapper para fechar*/
    const wrapperRef = useRef(null);
    useOutsideAlerter(wrapperRef);

    function useOutsideAlerter(ref: any) {
        useEffect(() => {
            function handleClickOutside(event: any) {
                if (ref.current && !ref.current.contains(event.target)) {
                    setIsOpen(false);
                }
            }
            document.addEventListener("mousedown", handleClickOutside);
            return () => {
                document.removeEventListener("mousedown", handleClickOutside);
            };
        }, [ref]);
    }

    /* Search Tour */
    // function getLocations(data: any) {
    //     var aux: any = [];

    //     data.forEach((element: any) => {
    //         if (!aux.includes(element.eventLocation)) {
    //             aux.push(element.eventLocation);
    //         }
    //     });

    //     //setLocations(aux);
    // }

    const searchTours = async () => {

        const response = await api.post(
            `${process.env.REACT_APP_SERVER_URL_API}/Products/GetAllToursFilter`,
            {
                term: searchTerm,
                // channel: 3,
                // lang: lng,
                // ProductType: 0,
            }
        );

        if (response.data.data !== null) {
            setTours(response.data.data);
            //getLocations(response.data.data);
            setIsOpen(true);
            // isOpen === false ? setIsOpen(true) : setIsOpen(false);
        }
    };

    const handleClickTour = (event: React.MouseEvent<HTMLElement>) => {
        var element = (event.target as HTMLButtonElement).dataset.tour;
        var typeE = (event.target as HTMLButtonElement).dataset.busca;
        var codeE =
            (event.target as HTMLButtonElement).dataset.busca === "tour"
                ? (event.target as HTMLButtonElement).dataset.code
                : null;
        setSearchValue(element);
        const tourSelect = {
            type: typeE,
            code: codeE
        };
        setValueSelect(tourSelect);
        setIsOpen(false);
        setCode(tourSelect.code);
    };

    useEffect(() => {
        if ( searchValue.length > 2 && valueSelect === null) {
            searchTours();
        } else if (searchValue.length <= 2) {
            setIsOpen(false)
            setValueSelect(null)
        } else {
            ///momento do click
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [searchValue]);

    const handleFocus = () => {
        if (searchValue.length >= 2) {
            setIsOpen(true);
        }
    };

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const newValue = e.target.value;
        setSearchValue(newValue);
        setSearchTerm(newValue);
    };

    /* END - Search Tour */

    if (tours !== null) {
        return (
            <>
                {/* Search Tour */}
                <div
                    ref={wrapperRef}
                    className="search-product"
                    id="nav-1"
                    role="tabpanel"
                    aria-labelledby="nav-1-tab"
                >
                    <div className="input-group">
                        <input
                            type="text"
                            value={searchValue}
                            className="form-control py-1 py-md-2"
                            placeholder="Qual produto?"
                            data-input="search"
                            aria-label="Destino"
                            aria-describedby="tourDestino"
                            autoComplete="off"
                            id="tourDestino"
                            onChange={handleChange}
                            onFocus={handleFocus}
                        />
                        <div
                            id="search-menu"
                            style={{
                                position: "absolute",
                                top: "100%",
                                left: "0px",
                                zIndex: 10,
                                backgroundColor: "#fff",
                                padding: "10px",
                                width: "100%",
                                color: "#000",
                            }}
                            className={isOpen === true ? "d-block" : "d-none"}
                        >
                            <div id="search-dataset-allTourNames">
                                {tours.length > 0
                                    ? tours.map((tours: any, index: any) => (
                                        <p
                                            key={index}
                                            onClick={handleClickTour}
                                            style={{ cursor: "pointer" }}
                                            className="chooice tt-suggestion tt-selectable"
                                            data-busca="tour"
                                            data-input="search"
                                            data-code={tours.productCode}
                                            data-tour={tours.name}
                                        >
                                            <span>
                                                <span>
                                                    {tours.name}
                                                </span>
                                            </span>
                                        </p>
                                    ))
                                    : ""}
                            </div>
                        </div>
                    </div>
                </div>
                {/* END - Search Tour */}
            </>
        )
    } else {
        return (
            <></>
        )
    }
}

export default SeachAllProduct;