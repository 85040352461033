import { configureStore } from '@reduxjs/toolkit'
import counterSlice from './cart';
import productSlice from './product';
import comboSlice from './combo';

const store = configureStore({
    reducer: {
        cart: counterSlice.reducer,
        tour: productSlice.reducer,
        combo: comboSlice.reducer
    }
  })

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch

export default store;