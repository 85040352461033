import React, { useState } from "react";
import { Container, Row, Col, Card } from 'react-bootstrap';
import Form from 'react-bootstrap/Form';
//import InputGroup from 'react-bootstrap/InputGroup';
//mport Select from 'react-select';
import Button from 'react-bootstrap/Button';
import Accordion from 'react-bootstrap/Accordion';
import { useTranslation } from "react-i18next";

import InputGroup from 'react-bootstrap/InputGroup';

import './FilterReportRioTour.scss';
//import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

//Components
import GetAgents from "../../../../components/C2Points/GetAgentsFilter";
import GetSuppliersIdentity from "../../../../components/C2Points/GetSuppliersIdentity";
import RangeCalendar from "../../../../components/Calendar/RangeCalendar";
import { Controller, useForm } from "react-hook-form";
import SearchProductModalityMulti from "../../../../components/C2Points/SearchProductModalityMulti";
//import GlobalAlert from "../../../Alert/GlobalAlert/GlobalAlert";

export interface propFilter {
    exportReport: any,
    error: any,
    clearActualValues: any;
    defaultDate: any;
}

const FilterReportRioTour: React.FC<propFilter> = ({
    exportReport, error, clearActualValues, defaultDate
}) => {

    const { t } = useTranslation();

    //const [filterTypeDate, setFilterTypeDate] = useState<any>(1);
    const [filterDate, setFilterDate] = useState<any>(defaultDate);
    const [filterSupplier, setFilterSupplier] = useState<any>("");
    const [filterAgent, setFilterAgent] = useState<any>("");
    const [filterFormaPagamento, setFilterFormaPagamento] = useState<any>('');
    const [filterProducts, setFilterProducts] = useState<any>([]);

    // const [alert, setAlert] = useState<any>();
    // const [checked, setChecked] = useState<boolean>(true);
    const [dateValidation, setDateValidation] = useState<boolean>(false);
    const [productValidation, setProductValidation] = useState<boolean>(false);

    const handleFilterClick = (data: any) => {
        if(filterDate.length === 0) {
            setDateValidation(true);
            return;
        }
        if(filterProducts.length === 0) {
            setProductValidation(true);
            return;
        }
        
        //console.log(filterLocal, filterStatus, filterType, filterSupplier, filterTypeDate, filterDate);
        //exportReport(filterAccounts, filterStatusPagamento, filterStatusReserva, filterTypeDate, filterDate);
        //exportReport(data.accounts, data.paymentStatus, data.reservationStatus, filterTypeDate, filterDate);
        const productsSelected = filterProducts.map((a:any) => (a.modality) ? `${a.modality}` : a.code );

        exportReport(/*filterTypeDate,*/ filterDate, filterSupplier, filterAgent, filterFormaPagamento, productsSelected);
    };

    const handleClearClick = () => {

        // let inputValue = document.querySelectorAll('input');
        // for (let i = 0; i < inputValue.length; i++) {
        //     inputValue[i].value = "";
        // }

        // let selectValue = document.querySelectorAll('select');
        // for (let i = 0; i < selectValue.length; i++) {
        //     if(selectValue[i].id === "rowsPerPage"){
        //         selectValue[i].value = "10";
        //     }else{
        //         selectValue[i].value = "";
        //     }
        // }

        //setFilterTypeDate(1);
        setFilterDate(defaultDate);
        setFilterAgent("");
        setFilterSupplier("");
        setFilterFormaPagamento("");
        setFilterProducts([]);
        
        clearActualValues();
    };

    const {
        control,
        formState: { errors },
    } = useForm({});

    const handleChangeAgent = (newValue: any) => {
        setFilterAgent(newValue);
    }

    const handleChangeFormaPagamento = (e: React.ChangeEvent<HTMLInputElement>) => {
        const newValue = e.target.value;
        setFilterFormaPagamento(newValue);
    }

    const handleChangeProducts = (newValue: any) => {
        if(productValidation === true) setProductValidation(false);
        setFilterProducts(newValue);
    }


    return (
            <Card className="bg-white mt-4">
                <Accordion >
                    <Card.Body>
                        <Container className="p-0" fluid>
                            <Row>
                                <div>
                                    <Form >
                                        <Row className="mb-3">
                                            <Form.Group as={Col} md="2">
                                                <Form.Label>Fornecedor</Form.Label>
                                                <Controller
                                                    control={control}
                                                    name="fornecedor"
                                                    render={({ field }: any) => (
                                                        <GetSuppliersIdentity propsField={field} propsErrors={errors} propsLabel="Fornecedores" setSupplier={setFilterSupplier} t={t} />
                                                    )}
                                                />
                                            </Form.Group>
                                            <Form.Group as={Col} md="2">
                                                <Form.Label>Agente</Form.Label>
                                                <Controller
                                                    control={control}
                                                    name="agente"
                                                    render={({ field }: any) => (
                                                        <GetAgents propsField={field} propsErrors={errors} propsLabel="Agentes" actionAgents={handleChangeAgent} />
                                                    )}
                                                />
                                            </Form.Group>
                                            <Form.Group as={Col} md="2">
                                                <Form.Label>Forma de Pagamento</Form.Label>
                                                <div className="endpoint">
                                                    <InputGroup hasValidation>
                                                        <Form.Select
                                                        autoComplete='off'
                                                            aria-describedby="inputGroupPrepend"
                                                            onChange={(e: any) => handleChangeFormaPagamento(e)}
                                                            value={filterFormaPagamento}
                                                        >
                                                            <option value="">Todos</option>
                                                            <option value="1">Cash</option>
                                                            <option value="2">PIX</option>
                                                            <option value="3">Transferência</option>
                                                            <option value="4">Cartão</option>
                                                            <option value="5">Faturado</option>
                                                            <option value="6">Comissão a Receber</option>
                                                            <option value="7">PicPay</option>
                                                        </Form.Select>
                                                        {/* <Form.Control.Feedback type="invalid">
                                                        Please choose a username.
                                                    </Form.Control.Feedback> */}
                                                    </InputGroup>
                                                </div>
                                            </Form.Group>
                                            <Form.Group as={Col} md="4">
                                                <Form.Label>Produtos</Form.Label>
                                                <div className="endpoint">
                                                    <Controller
                                                        control={control}
                                                        name="products"
                                                        //rules={{ required: { value: true, message: 'Por favor, selecione o(s) produtos(s)' } }}
                                                        render={({ field }: any) => (
                                                            <SearchProductModalityMulti error={productValidation}// propsField={field}  propsErrors={errors} 
                                                            propsLabel={"Produtos"} value={filterProducts} setValue={handleChangeProducts} />
                                                        )}
                                                    />
                                                </div>
                                                {productValidation === true ?
                                                    <small style={{ color: 'red' }}>campo obrigatório</small>
                                                    : <></>}
                                            </Form.Group>
                                            {/* <Form.Group as={Col} md="2">
                                                <Form.Label>Tipo de Data</Form.Label>
                                                <div className="endpoint">
                                                    <InputGroup hasValidation>
                                                        <Form.Select
                                                            aria-describedby="inputGroupPrepend"
                                                            onChange={(e: any) => handleChangeTypeDate(e)}
                                                            value={filterTypeDate}
                                                        >
                                                            <option value="1">Operação</option>
                                                            <option value="2">Vencimento</option>
                                                            <option value="3">Emissão</option>
                                                        </Form.Select>
                                                    </InputGroup>
                                                </div>
                                            </Form.Group> */}
                                            <Form.Group as={Col} md="2">
                                                <Form.Label>Data</Form.Label>
                                                <InputGroup hasValidation className={`ranger-calendar${dateValidation === true ? " endpoint-error" : ""}`}>
                                                    <RangeCalendar date={filterDate} setDate={setFilterDate} />
                                                </InputGroup>
                                                {dateValidation === true ?
                                                    <small style={{ color: 'red' }}>campo obrigatório</small>
                                                    : <></>}
                                            </Form.Group>
                                        </Row>
                                        <Row as={Col} md="12" className="mt-5 justify-content-md-end">
                                            <Col xs lg="12" className="d-flex justify-content-md-end">
                                                <Button variant="outline-primary" className="btn-default mr-2 text-uppercase" onClick={handleClearClick}>{t('partners.partnersfilter.btnClear')}</Button>
                                                <Button className="btn-default text-uppercase" onClick={handleFilterClick}>Exportar</Button>
                                            </Col>
                                        </Row>
                                    </Form>
                                </div>
                            </Row>
                        </Container>
                    </Card.Body>
                </Accordion>
            </Card>
      
    )
}

export default FilterReportRioTour;