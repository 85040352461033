import React, { useEffect, useState } from 'react';
import api from '../../services/api';

import Helmet from 'react-helmet';

//COMPONENTS
import DesktopDefault from '../../templates/DesktopDefault';
import Breadcrumb from '../../components/Breadcrumb/Breadcrumb';
import SessionInfo from './components/SessionInfo/SessionInfo';

import './SingleSession.scss';

function SingleSession() {

    const sessionId = window.location.href.split('?')[1].split("id=")[1];

    const [sessionInfo, setSessionInfo] = useState<any>(undefined);

    const GetSessonInfo = async () => {
        const token = localStorage.getItem('GroupId') || '{}';
        const config = {
            headers: { 'Authorization': `Bearer ${token}` },
        };
        try {
            const { data } = await api.get(`/Manifest/GetSession/${sessionId}`, config);
            if (data.status !== 400) {
                setSessionInfo(data.data)
            }
        } catch (error: any) {
            if (error?.response?.status === 401) { }
            if (error?.response?.status === 400) { }
        }
    }

    useEffect(() => {
        GetSessonInfo();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    if (sessionInfo) {
        return (
            <>
                <Helmet>
                    <title>Session - {JSON.stringify(sessionInfo?.sessionId)} - {JSON.stringify(sessionInfo?.descricao)} </title>
                    <meta name="description" content={sessionInfo?.descricao} />
                </Helmet>
                <DesktopDefault>
                    <div className="container-fluid content-dashboard single-session">
                        <Breadcrumb title={'Operações / Manifesto / Session'} />

                        <SessionInfo
                            sessionInfo={sessionInfo}
                            refreshData={GetSessonInfo}
                        />

                    </div>
                </DesktopDefault>
            </>
        );
    } else {
        return (
            <>
                <DesktopDefault>
                    <div className="container-fluid content-dashboard">
                        <Breadcrumb title={'Operações / Manifesto / Session'} />
                        <div className="suppliers mt-4 d-flex justify-content-between align-items-center">
                            <h2 className="title" style={{ color: "#707070" }}>Session</h2>
                        </div>

                    </div>
                </DesktopDefault>
            </>
        );
    }
}

export default SingleSession;