import React from "react";

import Breadcrumb from "../../components/Breadcrumb/Breadcrumb";
import DesktopDefault from "../../templates/DesktopDefault";
import './StockList.scss';
//import FilterStock from "../StockList/components/FilterStock/FilterStock";
import TableStock from "../StockList/components/TableStock/TableStock";
//import api from "../../services/api";

function StockList() {
  return (
    <>
      <DesktopDefault>
        <div className="container-fluid content-dashboard stock">
          <Breadcrumb title={"Estoque"} />

          <div className="mt-4 d-flex justify-content-between align-items-center">
            <h2 className="title" style={{ color: "#707070" }}>
              Estoque
            </h2>
          </div>

          <TableStock />


        </div>
      </DesktopDefault>
    </>
  );
}

export default StockList;
