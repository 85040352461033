import React, { useState } from 'react';
import api from "../../../../../../services/api";

import { Row, Col } from "react-bootstrap";
import { Controller, useForm } from "react-hook-form";
import { ErrorMessage } from "@hookform/error-message";

import Form from "react-bootstrap/Form";

import GetTransfers from '../../../../../../components/C2Points/GetTransfers';


export interface propInsert {
    show?: any;
    setResponseText?: any,
    setModalContent?: any,
    setLog?: any
}

const InsertTransferRef: React.FC<propInsert> = ({
    show, setResponseText, setModalContent, setLog
}: propInsert) => {

    const [loading, setLoading] = useState<any>(false);
    
    const [product, setProduct] = useState<any>(null);

    const validated = false;

    function closeModal() {
        var closeBtn: any = document.getElementsByClassName("btn-close")[0];
        if (closeBtn !== undefined) {
            closeBtn.click();
        }
    }

    const {
        control,
        handleSubmit,
        formState: { errors },
    } = useForm();


    const onSubmit = (data: any) => {
        setLoading(true)

        setModalContent("loading");

        const insertConta = async () => {
            const token = localStorage.getItem('GroupId') || '{}';
            const config = {
                headers: { 'Authorization': `Bearer ${token}`, 'Content-Type': 'application/json' },
            };
            try {
                const res = await api.post(`/Decolar/InsertTransferAsync`,
                    {
                        "decolarProductId": data.idDecolar,
                        "decolarModality": data.modDecolar,
                        "productCode": product.productCode,
                        "modalityCode": '' // não tem quando é transfer
                        //"uid": uId,
                        //"usuario": `${userName} ${userLastName}`
                    }, config);
                if (res.status !== 400) {
                    setResponseText(res.data.data.texto);
                    setLog(res.data.data.log);
                    setModalContent("success");
                    console.log(res)
                } else {
                    setResponseText(res.data.data.texto);
                    setLog(res.data.data.log);
                    setModalContent("error");
                }
                setLoading(false);
                closeModal();
                show(true);
            } catch (error: any) {
                setLoading(false);
                setModalContent("error");
                
            }
        };

        insertConta();
        
    }

    return (
        <>
            <Form className="h-100" noValidate validated={validated} onSubmit={handleSubmit(onSubmit)} autoComplete="false">
                <div className='d-flex flex-column justify-content-between h-100'>
                    <div>
                        <Row className="mb-3">
                            <Form.Group as={Col} controlId="formGridCat">
                                <Form.Label>Produto</Form.Label>
                                <Controller
                                    control={control}
                                    name="product"
                                    rules={{ required: { value: product ? false : true, message: "required" }, }}
                                    render={({ field }: any) => (
                                        <GetTransfers propsField={field} propsErrors={errors} propsLabel="Produto" setTransfer={setProduct} />
                                    )}
                                />
                                <ErrorMessage
                                    errors={errors}
                                    name="product"
                                    render={({ message }) => <small style={{ color: "red" }}>{message}</small>}
                                />
                            </Form.Group>
                        </Row>

                        <hr style={{ margin: "30px 0" }} />

                        <Row className="mb-3">
                            <Form.Group as={Col} controlId="formGridDesc">
                                <Form.Label>Id Produto da Decolar</Form.Label>
                                <Controller
                                    control={control}
                                    name="idDecolar"
                                    rules={{ required: { value: true, message: "required" }, }}
                                    render={({ field }: any) => (
                                        <Form.Control
                                            {...field}
                                            aria-invalid={errors?.descricao ? "true" : ""}
                                            variant="standard"
                                            margin="normal"
                                            autoComplete="off"
                                        />
                                    )}
                                />
                                <ErrorMessage
                                    errors={errors}
                                    name="idDecolar"
                                    render={({ message }) => <small style={{ color: "red" }}>{message}</small>}
                                />
                            </Form.Group>
                            <Form.Group as={Col} controlId="formGridDesc">
                                <Form.Label>Modalidade da Decolar</Form.Label>
                                <Controller
                                    control={control}
                                    name="modDecolar"
                                    rules={{ required: { value: true, message: "required" }, }}
                                    render={({ field }: any) => (
                                        <Form.Control
                                            {...field}
                                            aria-invalid={errors?.descricao ? "true" : ""}
                                            variant="standard"
                                            margin="normal"
                                            autoComplete="off"
                                        />
                                    )}
                                />
                                <ErrorMessage
                                    errors={errors}
                                    name="modDecolar"
                                    render={({ message }) => <small style={{ color: "red" }}>{message}</small>}
                                />
                            </Form.Group>
                        </Row>
                    </div>
                    <div className='mt-4 d-flex justify-content-end'>
                        <button
                            className="btn btn-default btn-outline-primary px-4 mr-3 text-uppercase"
                            onClick={closeModal}
                        >
                            Cancelar
                        </button>
                        <button
                            className="btn btn-default btn-primary px-4 text-uppercase"
                            type="submit"
/*                             onClick={() => {
                                setLoading(true)
                            }} */
                        >
                            {
                                loading === true
                                    ?
                                    <div className="load"></div>
                                    :
                                    "Cadastrar"
                            }
                        </button>
                    </div>
                </div>
            </Form>
        </>
    )
}

export default InsertTransferRef;