import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useState } from "react";
import { Form, Modal } from "react-bootstrap";
import { useForm } from "react-hook-form";
import Breadcrumb from "../../../../components/Breadcrumb/Breadcrumb";
//import ModalConfirm from "../../../../components/Modal/ModalConfirm/ModalConfirm";
import api from "../../../../services/api";
import DesktopDefault from "../../../../templates/DesktopDefault";
import AddOrEditResourceType from "../AddOrEditResourceType/AddOrEditResourceType";

export interface propsField {

}

// eslint-disable-next-line no-empty-pattern
const AddResourceType: React.FC<propsField> = ({

}) => {


    const [modalShow, setModalShow] = useState<any>(false);
    const [modalLog, setModalLog] = useState<any>(null);
    const [modalMessage, setModalMessage] = useState<any>("Carregando");



    const onSubmit = (obj: any) => {

        setModalShow(true);
        console.log(obj)


        const token = localStorage.getItem("GroupId") || "{}";
        const config = {
            headers: { Authorization: `Bearer ${token}` },
        };
        async function createResourceType() {
            try {
                const { data } = await api.post(
                    "Resources/CreateResourceTypeAsync",
                    {
                        resourceTypeName: obj.resourceTypeName,
                        vehicle: obj.vehicle,
                        ticket: obj.ticket,
                        people: obj.people,
                        guide: obj.guide,
                        restaurant: obj.restaurant,
                        driver: obj.driver,
                        chd: obj.chd
                    },
                    config
                );

                if (data.status !== 400) {
                    setModalLog(data.data.log);
                    setModalMessage(data.data.texto);
                }
            } catch (error: any) {
                ;
            }
        }
        createResourceType();
    };

    const {
        control,
        handleSubmit,
        setValue,
    } = useForm({});

    const reload = () => {
        if (modalLog === 0) {
            window.location.href = "/resource-type";
        }
    };
    return (
        <>
            <DesktopDefault>
                <div className="container-fluid content-dashboard">
                    <Breadcrumb title={"Produtos / Tipo de Resource"} />
                    <div className="mt-4 ">
                        <div className="d-flex align-items-center">
                            <span
                                className="back"
                                onClick={() => {
                                    window.location.href = "/resource-type";
                                }}
                            >
                                <FontAwesomeIcon
                                    icon={["fal", "angle-double-left"]}
                                    style={{ margin: "0 5px 0 0", fontSize: "16px" }}
                                />
                                Voltar
                            </span>
                        </div>

                        <h2 className="title" style={{ color: "#707070" }}>
                            Novo Tipo de Resource{""}
                        </h2>
                    </div>
                    <Form className="my-4" noValidate validated={false}
                        onSubmit={handleSubmit(onSubmit)}
                        autoComplete="false">
                        <AddOrEditResourceType control={control}
                            setValue={setValue}
                        />
                    </Form>
                </div>
                <Modal
                    className="modalAuth"
                    show={modalShow}
                    onHide={() => {
                        reload();
                        setModalLog(null);
                        setModalMessage("Carregando");
                        setModalShow(false);
                    }}
                    aria-labelledby="contained-modal-title-vcenter"
                >
                    <>
                        <Modal.Body
                            className="modal-body text-center sucess-pos d-flex justify-content-evenly"
                            style={{ height: "600px" }}
                        >
                            {modalLog === null ? (
                                <>
                                    <div className="loading-modal">
                                        <div className="load"></div>
                                    </div>
                                </>
                            ) : (
                                <div>
                                    {modalLog === 0 ? (
                                        <FontAwesomeIcon
                                            icon={["fal", "check-circle"]}
                                            size="5x"
                                            className="text-primary"
                                            style={{ fontSize: "7.5em" }}
                                        />
                                    ) : (
                                        <FontAwesomeIcon
                                            icon={["fal", "times-circle"]}
                                            size="5x"
                                            className="text-primary"
                                            style={{ fontSize: "7.5em" }}
                                        />
                                    )}
                                </div>
                            )}
                            <div>{modalMessage}</div>
                            <div className="d-flex justify-content-center pt-3">
                                <button
                                    onClick={() => {
                                        reload();
                                        setModalLog(null);
                                        setModalMessage("Carregando");
                                        setModalShow(false);
                                    }}
                                    className="btn btn-primary mx-2 w-25"
                                >
                                    Confirmar
                                </button>
                            </div>
                        </Modal.Body>
                    </>
                </Modal>
            </DesktopDefault>
        </>
    )
}

export default AddResourceType;