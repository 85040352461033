import React, { useEffect, useState } from "react";
import { Container, Row, Col, Card } from "react-bootstrap";
//import api from '../../../../services/api';

import ImageDefault from "../../../../assets/img/image-default.png";

import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import Accordion from "react-bootstrap/Accordion";

import { useTranslation } from "react-i18next";

import SuppliersTableCompany from "./SuppliersTableCompany/SuppliersTableCompany";

import "../../../../assets/sass/accordion.scss";
import { Controller, useForm } from "react-hook-form";
import InputMask from "react-input-mask";
import { ErrorMessage } from "@hookform/error-message";

//Components EndPoints
import GetPromoters from "../../../../components/C2Points/GetPromoters";
import GetCompanyType from "../../../../components/C2Points/GetCompanyType";
import GetCountrys from "../../../../components/C2Points/GetCountrys";
//import GetCitys from "../../../../components/C2Points/GetCitys";
//import { inputCSS } from "react-select/dist/declarations/src/components/Input";
import GetStates from "../../../../components/C2Points/GetStates";
import GetSuppliersType from "../../../../components/C2Points/GetSuppliersType";
import GetCitysByState from "../../../../components/C2Points/GetCitysByState";
//import { conformToMask } from "react-text-mask";
//import { valueTernary } from "react-select/dist/declarations/src/utils";

export interface propAccordion {
    action: any,
    ContextAwareToggle: any,
    editSuppliers?: any;
    supplierControlObject?: any,
    scrollTop?: any
    isEdit?: any;
    infoEdit: any;
    hasSavedInputs?: any;
};

const AccordionInfoCompany: React.FC<propAccordion> = React.memo(({
    action, ContextAwareToggle, supplierControlObject, infoEdit, editSuppliers, scrollTop, isEdit = false, hasSavedInputs = false
}: propAccordion) => {
    const { t } = useTranslation();

    const [profileImg, setProfileImg] = useState<any>((isEdit === true || hasSavedInputs === true) && infoEdit.compLogoBase64 !== null ? infoEdit.compLogoBase64 : ImageDefault);
    const [profileNameImg, setProfileNameImg] = useState<any>("None");

    const [messageError, setMessageError] = useState<boolean>(false);
    const [idCompType, setIdCompType] = useState<string>((isEdit === true || hasSavedInputs === true) ? infoEdit.compType?.toString() : "1");
    const idSup = window.location.href.split('id=')[1]?.replace(/#/g, '');
    /* const idSup = window.location.href.split('id=')[1]?.replace(/\#/g, ''); */

    const [contact, setContact] = useState<any>('');
    const [country, setCountry] = useState<any>((isEdit === true || hasSavedInputs === true) ? { description: infoEdit.countryDesc, id: `${infoEdit.country}` } : '');

    const [addressError, setAddressError] = useState<any>(false);
    const [countryError, setCountryError] = useState<any>(false);
    const [cpfError, setCpfError] = useState<any>(false);
    const [controlZipCode, setControlZipCode] = useState<any>(0);
    //const [modalResponse, setModalResponse] = useState<any>(null); /// ["loading","error","sucess"] para abrir o modal pós envio

    const imageHandler = (e: any) => {
        const reader = new FileReader();
        reader.onload = () => {
            if (reader.readyState === 2) {
                setProfileImg(reader.result)
            }
        }
        reader.readAsDataURL(e.target.files[0]);
        setProfileNameImg(e.target.files[0].name);
    }

    const [ctrlEventKey, setCtrlEventKey] = useState<boolean>(false);
    const validated = false;

    const getChangeCompType = (idCT: any) => {
        setIdCompType(idCT);
    };

    const SetCompType = () => {
        if (idCompType === "1") {
            return (
                <Row className="mb-3">
                    <Form.Group as={Col} md="4" controlId="formInfoCompanyCPF">
                        <Form.Label>{t('accordionInfoCompany.cpf')}:</Form.Label>
                        <Controller
                            control={control}
                            name="cpf"
                            rules={{ required: { value: true, message: t('accordionInfoCompany.validation.cpf') } }}
                            render={({ field }: any) => (
                                <InputMask mask="999.999.999-99" value={field?.value} onChange={field?.onChange}>
                                    {(inputProps: any) => (
                                        <Form.Control
                                            {...inputProps}
                                            aria-invalid={errors?.cpf ? "true" : ""}
                                            label={t("accordionInfoCompany.cpf")}
                                            variant="standard"
                                            margin="normal"
                                            type="text"
                                            required
                                            autoComplete='off'
                                        />
                                    )}
                                </InputMask>
                            )}
                        />
                        {cpfError === true
                            ? <small style={{ color: "red" }}>Por favor, informe um CPF válido.</small>
                            : <></>
                        }
                        <ErrorMessage
                            errors={errors}
                            name="cpf"
                            render={({ message }) => <small style={{ color: "red" }}>{message}</small>}
                        />
                    </Form.Group>
                </Row>
            );
        } else if (idCompType === "2") {
            return (
                <Row className="mb-3">
                    <Form.Group as={Col} md="4" controlId="formInfoCompanyCNPJ">
                        <Form.Label>{t('accordionInfoCompany.cnpj')}:</Form.Label>
                        <Controller
                            control={control}
                            name="cnpj"
                            rules={{ required: { value: true, message: t('accordionInfoCompany.validation.cnpj') } }}
                            render={({ field }: any) => (
                                <InputMask mask="99.999.999/9999-99" value={field?.value} onChange={field?.onChange}>
                                    {(inputProps: any) => (
                                        <Form.Control
                                            {...inputProps}
                                            aria-invalid={errors?.cnpj ? "true" : ""}
                                            label={t('accordionInfoCompany.cnpj')}
                                            variant="standard"
                                            margin="normal"
                                            required
                                            autoComplete='off'
                                        />
                                    )}
                                </InputMask>
                            )}
                        />
                        <ErrorMessage
                            errors={errors}
                            name="cnpj"
                            render={({ message }) => <small style={{ color: "red" }}>{message}</small>}
                        />
                    </Form.Group>
                    <Form.Group as={Col} md="4" controlId="formInfoCompanySubscription">
                        <Form.Label>{t('accordionInfoCompany.subM')}</Form.Label>
                        <Controller
                            control={control}
                            name="inscricaoMunicipal"
                            rules={{ required: { value: true, message: t('accordionInfoCompany.validation.subM') } }}
                            render={({ field }: any) => (
                                <Form.Control
                                    {...field}
                                    aria-invalid={errors?.inscricaoMunicipal ? "true" : ""}
                                    label={t('accordionInfoCompany.subM')}
                                    variant="standard"
                                    margin="normal"
                                    onKeyPress={(e) => !/[0-9\-.]/.test(e.key) && e.preventDefault()}
                                    required
                                    autoComplete='off'
                                />
                            )}
                        />
                        <ErrorMessage
                            errors={errors}
                            name="inscricaoMunicipal"
                            render={({ message }) => <small style={{ color: "red" }}>{message}</small>}
                        />
                    </Form.Group>
                    <Form.Group as={Col} md="4" controlId="formInfoCompanySubscription">
                        <Form.Label>{t('accordionInfoCompany.subE')}</Form.Label>
                        <Controller
                            control={control}
                            name="inscricaoEstadual"
                            rules={{ required: { value: false, message: t('accordionInfoCompany.validation.subE') } }}
                            render={({ field }: any) => (
                                <Form.Control
                                    {...field}
                                    aria-invalid={errors?.inscricaoEstadual ? "true" : ""}
                                    label={t('accordionInfoCompany.subE')}
                                    variant="standard"
                                    margin="normal"
                                    type="text"
                                    onKeyPress={(e) => !/[0-9\-.]/.test(e.key) && e.preventDefault()}
                                    required
                                    autoComplete='off'
                                />
                            )}
                        />
                        <ErrorMessage
                            errors={errors}
                            name="inscricaoEstadual"
                            render={({ message }) => <small style={{ color: "red" }}>{message}</small>}
                        />
                    </Form.Group>
                </Row>
            )
        } else if (idCompType === "3") {
            return (
                <Row className="mb-3">
                    <Form.Group as={Col} md="4" controlId="formInfoCompanyID">
                        <Form.Label>{t('accordionInfoCompany.id')}:</Form.Label>
                        <Controller
                            control={control}
                            name="code"
                            rules={{ required: { value: true, message: t('accordionInfoCompany.validation.id') } }}
                            render={({ field }: any) => (
                                <Form.Control
                                    {...field}
                                    aria-invalid={errors?.code ? "true" : ""}
                                    label={t('accordionInfoCompany.id')}
                                    variant="standard"
                                    margin="normal"
                                    onKeyPress={(e) => !/[0-9]/.test(e.key) && e.preventDefault()}
                                    required
                                    autoComplete='off'
                                />
                            )}
                        />
                        <ErrorMessage
                            errors={errors}
                            name="code"
                            render={({ message }) => <small style={{ color: "red" }}>{message}</small>}
                        />
                    </Form.Group>
                </Row>
            );
        } else {
            return (<></>);
        }
    };

    const {
        control,
        handleSubmit,
        watch,
        setValue,
        formState: { errors },
    } = useForm({
        defaultValues: {
            /// info === true -> edit page. info !== true -> add page
            compName: (isEdit === true || hasSavedInputs === true) ? infoEdit.compName : supplierControlObject ? supplierControlObject.compName : '',
            compFantasia: (isEdit === true || hasSavedInputs === true) ? infoEdit.compFantasia : supplierControlObject ? supplierControlObject.compFantasia : '',
            website: (isEdit === true || hasSavedInputs === true) ? infoEdit.website : supplierControlObject ? supplierControlObject.website : '',
            supplierType: (isEdit === true || hasSavedInputs === true) ? { description: infoEdit.supplierTypeDesc, id: infoEdit.supplierType } : '',
            compEmail: (isEdit === true || hasSavedInputs === true) ? infoEdit.compEmail : '',
            compType: (isEdit === true || hasSavedInputs === true) ? infoEdit.compType : '1',
            compLogoBase64: (isEdit === true || hasSavedInputs === true) ? infoEdit.compLogoBase64 : '',
            compLogoName: (isEdit === true || hasSavedInputs === true) ? infoEdit.compLogoName : supplierControlObject ? supplierControlObject.compLogoName : '',
            cpf: (isEdit === true || hasSavedInputs === true) ? infoEdit.cpf : supplierControlObject ? supplierControlObject.cpf : '',
            cnpj: (isEdit === true || hasSavedInputs === true) ? infoEdit.cnpj : supplierControlObject ? supplierControlObject.cnpj : '',
            code: (isEdit === true || hasSavedInputs === true) ? infoEdit.code : supplierControlObject ? supplierControlObject.code : '',
            promotor: (isEdit === true || hasSavedInputs === true) ? infoEdit.promotor : '',
            country: (isEdit === true || hasSavedInputs === true) ? { description: infoEdit.countryDesc, id: infoEdit.country } : '',
            city: (isEdit === true || hasSavedInputs === true) ? infoEdit.country === 30 ? { description: infoEdit.municipioDesc, id: infoEdit.municipioId } : infoEdit.city : '',
            state: (isEdit === true || hasSavedInputs === true) ? infoEdit.country === 30 ? { description: infoEdit.stateDesc, id: infoEdit.state } : infoEdit.state : '',
            address: (isEdit === true || hasSavedInputs === true) ? infoEdit.address : supplierControlObject ? supplierControlObject.address : '',
            numero: (isEdit === true || hasSavedInputs === true) ? infoEdit.numero : supplierControlObject ? supplierControlObject.numero : '',
            compComplem: (isEdit === true || hasSavedInputs === true) ? infoEdit.compComplem : supplierControlObject ? supplierControlObject.compComplem : '',
            compBairro: (isEdit === true || hasSavedInputs === true) ? infoEdit.compBairro : supplierControlObject ? supplierControlObject.compBairro : '',
            zipCode: (isEdit === true || hasSavedInputs === true) ? infoEdit.zipCode : supplierControlObject ? supplierControlObject.zipCode : '',
            municipioId: (isEdit === true || hasSavedInputs === true) ? infoEdit.municipioId : supplierControlObject ? supplierControlObject.municipioId : '',
            inscricaoEstadual: (isEdit === true || hasSavedInputs === true) ? infoEdit.inscricaoEstadual : supplierControlObject ? supplierControlObject.inscricaoEstadual : '',
            inscricaoMunicipal: (isEdit === true || hasSavedInputs === true) ? infoEdit.inscricaoMunicipal : supplierControlObject ? supplierControlObject.inscricaoMunicipal : ''
        },
    });

    const watchZipCode = watch(`zipCode`, false);
    const watchState = watch(`state`, '');

    useEffect(() => {
        setControlZipCode(watchZipCode?.length)
        if (watchZipCode?.length === 5) {
            if (watchZipCode.length > controlZipCode) {
                setValue('zipCode', `${watchZipCode}-`)
            }
        }

        if (watchZipCode?.length > 9) {
            var controlLength: any = watchZipCode;
            controlLength = controlLength.split('');
            controlLength.pop();
            controlLength = controlLength.join('');
            setValue('zipCode', `${controlLength}`)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [watchZipCode])

    const getContacts = (objContact: any) => {
        setContact(objContact);
    }

    function cpfValidator(strCPF: any) {
        var Soma;
        var Resto;
        Soma = 0;
        if (strCPF === "00000000000") return false;

        for (var i: any = 1; i <= 9; i++) Soma = Soma + parseInt(strCPF.substring(i - 1, i)) * (11 - i);
        Resto = (Soma * 10) % 11;

        if ((Resto === 10) || (Resto === 11)) Resto = 0;
        if (Resto !== parseInt(strCPF.substring(9, 10))) return false;

        Soma = 0;
        for (i = 1; i <= 10; i++) Soma = Soma + parseInt(strCPF.substring(i - 1, i)) * (12 - i);
        Resto = (Soma * 10) % 11;

        if ((Resto === 10) || (Resto === 11)) Resto = 0;
        if (Resto !== parseInt(strCPF.substring(10, 11))) return false;
        return true;
    }

    function validateSubmit(data: any) {
        var cpf: any = data.cpf.replace(/\./g, '').replace(/-/g, '').replace(/\//g, '');
        /* var cpf: any = data.cpf.replace(/\./g, '').replace(/\-/g, '').replace(/\//g, ''); */

        if (country == null || contact.length === 0 || (idCompType === '1' ? cpfValidator(cpf) === false : false) || data.address.length < 5) {
            if (country == null) {
                setCountryError(true);
            } else {
                setCountryError(false);
            }
            //
            if (contact.length === 0) {
                setMessageError(true);
            } else {
                setMessageError(false);
            }
            //
            if (data.address.length < 5) {
                setAddressError(true);
            } else {
                setAddressError(false);
            }
            //
            if (cpfValidator(cpf) === false) {
                setCpfError(true);
            } else {
                setCpfError(false);
            }

            return false;
        } else {
            setCountryError(false);
            setMessageError(false);
            setCpfError(false);
            return true;
        }
    }

    const onSubmit = (data: any) => {
        if (isEdit === false) {
            /// ADD SUPPLIERS PAGE
            if (validateSubmit(data)) {

                //data.supplierId = parseInt(idSup);
                // eslint-disable-next-line no-self-assign
                idCompType === "2" ? data.compFantasia = data.compFantasia : data.compFantasia = data.compName;
                data.compLogoBase64 = profileImg;
                data.compLogoName = profileImg === ImageDefault ? "no-company-logo.png" : profileNameImg;
                data.compType = Number(idCompType);
                data.country = country.id;
                data.countryDesc = country.description;

                if (country?.id === '30' || country?.id === 30) { //// BRAZIL
                    data.stateDesc = data.state.description;
                    data.state = data.state.id;
                    const cityT = data.city;
                    data.city = cityT.description;
                    data.municipioId = cityT.id;
                    data.cityCode = cityT.id;
                    data.municipioDesc = cityT.description;
                } else {
                    data.municipioId = '0';
                    data.cityCode = '0';
                }
                data.supplierTypeDesc = data.supplierType.description;
                data.supplierType = data.supplierType.id;

                data.supplierContactModel = contact;
                data.supplierContact = contact;

                /* contact.forEach((element: any) => {
                    if (element.isMain === 1) {
                        data.compEmail = element.email;
                    }
                }); */

                data.cnpj = idCompType !== "2" ? "" : data.cnpj.replace(/\./g, '').replace(/-/g, '').replace(/\//g, '');
                data.cpf = idCompType !== "1" ? "" : data.cpf.replace(/\./g, '').replace(/-/g, '').replace(/\//g, '');
                /* data.cnpj = idCompType !== "2" ? "" : data.cnpj.replace(/\./g, '').replace(/\-/g, '').replace(/\//g, '');
                data.cpf = idCompType !== "1" ? "" : data.cpf.replace(/\./g, '').replace(/\-/g, '').replace(/\//g, ''); */
                data.code = idCompType !== "3" ? "" : data.code;
                data.inscricaoMunicipal = idCompType !== "2" ? 0 : Number(data.inscricaoMunicipal.split('.').join('').split('-').join(''));
                data.inscricaoEstadual = idCompType !== "2" ? 0 : Number(data.inscricaoEstadual.split('.').join('').split('-').join(''));

                //data.zipCode = data.zipCode.replace(/-/g, '');
                /* data.zipCode = data.zipCode.replace(/\-/g, '');*/
                if (contact.length === 0) {
                    setMessageError(true);
                } else {
                    setMessageError(false);
                    setCtrlEventKey(true);
                    action(data);
                }
            }
        } else {
            /// EDIT SUPPLIERS PAGE
            let edit: any = { ...infoEdit, ...data };

            edit.supplierId = parseInt(idSup);
            edit.supplierType = edit.supplierType.id;

            edit.country = country.id;

            if (country.id === '30' || country.id === 30) { //// BRAZIL
                edit.state = edit.state.id;
                const cityT = edit.city;
                edit.city = cityT.description;
                edit.municipioId = cityT.id;
                data.cityCode = cityT.id;
            } else {
                edit.municipioId = '0';
                data.cityCode = '0';
            }

            edit.compType = Number(idCompType);

            edit.inscricaoMunicipal = idCompType !== "2" ? 0 : Number(edit.inscricaoMunicipal.split('.').join('').split('-').join(''));
            edit.inscricaoEstadual = idCompType !== "2" ? 0 : Number(edit.inscricaoEstadual.split('.').join('').split('-').join(''));

            edit.supplierContactModel = contact;
            edit.usersSupplierModel = edit.usersSupplierDTO?.map((elem: any) => { return { "userId": elem.userId } });
            edit.costCenterSupplierModel = edit.costCenterSupplierDTO;
            edit.supplierByResourceModel = edit.supplierByResourceDTO;
            edit.supplierVehicleTypeModel = edit.supplierVehicleTypeDTO;

            //remover
            edit.tarTipo = edit.tarTipo === null ? 1 : edit.tarTipo
            edit.masterFilho = edit.masterFilho === null ? 1 : edit.masterFilho
            //[end] remover

            editSuppliers(edit);
        }
    };

    function handleCancel() {
        window.location.reload();
        //scrollTop();
    }

    useEffect(() => {
        if (country?.id === '30') {
            setValue('city', '');
            setValue('state', '');
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [country])

    useEffect(() => {
        setTimeout(() => {
            console.log(country?.id === '30')
            if (country?.id === '30' && (isEdit === true || hasSavedInputs === true)) {
                setValue('city', { description: infoEdit.municipioDesc, id: infoEdit.municipioId });
                setValue('state', { description: infoEdit.stateDesc, id: infoEdit.state });
            }
        }, 500);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return (
        <Card className="bg-white mt-4" id="editInfoCompanyForms">
            <Card.Header id="accordionPersonal">
                <ContextAwareToggle eventKey="0" status={ctrlEventKey}>{t('accordionInfoCompany.title')}</ContextAwareToggle>
            </Card.Header>
            <Accordion.Collapse eventKey="0">
                <Card.Body>
                    <Container className="p-0" fluid>
                        <Row>
                            <div>
                                <Form noValidate validated={validated} onSubmit={handleSubmit(onSubmit)} autoComplete="off">
                                    <Row>
                                        <div className="label">
                                            <label className="image-upload" htmlFor="input">
                                                <div className="logo-info-company mx-3 mb-5 mt-2"
                                                    style={{ backgroundImage: `url(${profileImg})`, backgroundSize: 'cover', cursor: 'pointer' }}>
                                                    {/* <img src={profileImg} alt="Logo" /> */}
                                                </div>
                                            </label>
                                            <input autoComplete='off' type="file" accept="image/*" name="image-upload" id="input" onChange={imageHandler} />
                                        </div>
                                    </Row>
                                    <Row className="mb-3">
                                        <Form.Group as={Col} md="4" controlId="formInfoCompanyName">
                                            <Form.Label>{idCompType === "2" ? t("accordionInfoCompany.nameCompany") : t("accordionInfoCompany.name")}</Form.Label>
                                            <Controller
                                                control={control}
                                                name="compName"
                                                rules={{
                                                    required: {
                                                        value: true, message:
                                                            idCompType === "2"
                                                                ?
                                                                t("accordionInfoCompany.validation.nameCompany")
                                                                :
                                                                t("accordionInfoCompany.validation.name")
                                                    }
                                                }}
                                                render={({ field }: any) => (
                                                    <Form.Control
                                                        {...field}
                                                        aria-invalid={errors?.compName ? "true" : ""}
                                                        label={t("accordionInfoCompany.name")}
                                                        variant="standard"
                                                        margin="normal"
                                                        autoComplete="off"
                                                        onKeyPress={(e) => !/[a-zA-z\d(\s)]+/.test(e.key) && e.preventDefault()}
                                                        required
                                                        
                                                    />
                                                )}
                                            />
                                            <ErrorMessage
                                                errors={errors}
                                                name="compName"
                                                render={({ message }) => <small style={{ color: "red" }}>{message}</small>}
                                            />
                                        </Form.Group>
                                        {idCompType === "2"
                                            ?
                                            <Form.Group as={Col} md="4" controlId="formInfoCompanyNameFantasy">
                                                <Form.Label>{t('accordionInfoCompany.nameFant')}</Form.Label>
                                                <Controller
                                                    control={control}
                                                    name="compFantasia"
                                                    rules={{ required: { value: true, message: t("accordionInfoCompany.validation.nameFant") } }}
                                                    render={({ field }: any) => (
                                                        <Form.Control
                                                            {...field}
                                                            aria-invalid={errors?.compFantasia ? "true" : ""}
                                                            label={t("accordionInfoCompany.nameFant")}
                                                            variant="standard"
                                                            margin="normal"
                                                            autoComplete="off"
                                                            onKeyPress={(e) => !/[a-zA-z\d(\s)]+/.test(e.key) && e.preventDefault()}
                                                            required
                                                        />
                                                    )}
                                                />
                                                <ErrorMessage
                                                    errors={errors}
                                                    name="compFantasia"
                                                    render={({ message }) => (
                                                        <small style={{ color: "red" }}>{message}</small>
                                                    )}
                                                />
                                            </Form.Group>
                                            : ''
                                        }
                                        <Form.Group
                                            as={Col}
                                            md="4"
                                            controlId="formInfoCompanyWebsite"
                                        >
                                            <Form.Label>{t("accordionInfoCompany.web")}</Form.Label>
                                            <Controller
                                                control={control}
                                                name="website"
                                                rules={{
                                                    required: {
                                                        value: true,
                                                        message: t("accordionInfoCompany.validation.web"),
                                                    },
                                                }}
                                                render={({ field }: any) => (
                                                    <Form.Control
                                                        {...field}
                                                        aria-invalid={errors?.website ? "true" : ""}
                                                        label={t("accordionInfoCompany.web")}
                                                        variant="standard"
                                                        margin="normal"
                                                        autoComplete="off"
                                                        required
                                                    />
                                                )}
                                            />
                                            <ErrorMessage
                                                errors={errors}
                                                name="website"
                                                render={({ message }) => <small style={{ color: "red" }}>{message}</small>}
                                            />
                                        </Form.Group>
                                    </Row>
                                    <Row className="mb-3">
                                        <Form.Group as={Col} md="4" controlId="formInfoCompanyPromoter">
                                            <Form.Label>{t('accordionInfoCompany.promo')}</Form.Label>
                                            <Controller
                                                control={control}
                                                name="promotor"
                                                rules={{ required: { value: true, message: t("accordionInfoCompany.validation.promo") } }}
                                                render={({ field }: any) => (
                                                    <GetPromoters
                                                        propsField={field}
                                                        propsErrors={errors}
                                                        propsLabel={t('accordionInfoCompany.promo')}
                                                        isEdit={isEdit}
                                                        infoEdit={infoEdit}
                                                    />
                                                )}
                                            />
                                            <ErrorMessage
                                                errors={errors}
                                                name="promotor"
                                                render={({ message }) => <small style={{ color: "red" }}>{message}</small>}
                                            />
                                        </Form.Group>

                                        <Form.Group as={Col} md="4" controlId="formInfoCompanyPromoter">
                                            <Form.Label>{t('accordionInfoCompany.typeSupplier')}</Form.Label>
                                            <Controller
                                                control={control}
                                                name="supplierType"
                                                rules={{ required: { value: true, message: t("accordionInfoCompany.validation.typeSupplier") } }}
                                                render={({ field }: any) => (
                                                    <GetSuppliersType propsField={field} propsErrors={errors} propsLabel={t('accordionInfoCompany.typeSupplier')} />
                                                )}
                                            />
                                            <ErrorMessage
                                                errors={errors}
                                                name="supplierType"
                                                render={({ message }) => <small style={{ color: "red" }}>{message}</small>}
                                            />
                                        </Form.Group>

                                        <Form.Group as={Col} md="4" controlId="formInfoCompanyPromoter">
                                            <Form.Label>{t('accordionInfoCompany.email')}</Form.Label>
                                            <Controller
                                                control={control}
                                                name="compEmail"
                                                rules={{ required: { value: true, message: t("accordionInfoCompany.validation.email") } }}
                                                render={({ field }: any) => (
                                                    <Form.Control
                                                        {...field}
                                                        aria-invalid={errors?.website ? "true" : ""}
                                                        label={t("accordionInfoCompany.email")}
                                                        variant="standard"
                                                        margin="normal"
                                                        autoComplete="off"
                                                        required
                                                    />
                                                )}
                                            />
                                            <ErrorMessage
                                                errors={errors}
                                                name="compEmail"
                                                render={({ message }) => <small style={{ color: "red" }}>{message}</small>}
                                            />
                                        </Form.Group>
                                    </Row>
                                    <small>{t('accordionInfoCompany.text')}</small>
                                    <Row>
                                        <Controller
                                            control={control}
                                            name="compType"
                                            rules={{ required: { value: true, message: '' } }}
                                            render={({ field }: any) => (
                                                <GetCompanyType propsField={field} getChangeCompType={getChangeCompType} compType={(isEdit === true || hasSavedInputs === true) ? infoEdit.compType : "1"} />
                                            )}
                                        />
                                    </Row>

                                    <SetCompType />

                                    <hr className="my-5" />
                                    <h5 className="text-uppercase mb-3">{t('accordionInfoCompany.address')}</h5>
                                    <Row className="mb-3">
                                        <Form.Group as={Col} md="4" controlId="formInfoCompanyCountry">
                                            <Form.Label>{t('accordionInfoCompany.country')}</Form.Label>
                                            <Controller
                                                control={control}
                                                name="country"
                                                rules={{ required: { value: false, message: t('accordionInfoCompany.validation.country') } }}
                                                render={({ field }: any) => (
                                                    <GetCountrys propsField={field} propsLabel={t('accordionInfoCompany.country')} propsErrors={errors} watchCountry={setCountry} />
                                                )}
                                            />

                                            {countryError === true
                                                ? <small style={{ color: "red" }}>{t('accordionInfoCompany.validation.country')}</small>
                                                : <></>
                                            }

                                            <ErrorMessage
                                                errors={errors}
                                                name="country"
                                                render={({ message }) => <small style={{ color: "red" }}>{message}</small>}
                                            />
                                        </Form.Group>
                                        <Form.Group as={Col} md="4" controlId="formInfoCompanyCEP" autoComplete="off">
                                            <Form.Label>{t('accordionInfoCompany.cep')}</Form.Label>
                                            <Controller
                                                control={control}
                                                name="zipCode"
                                                rules={{ required: { value: true, message: t('accordionInfoCompany.validation.cep') } }}
                                                render={({ field }: any) => (
                                                    <Form.Control
                                                        {...field}
                                                        aria-invalid={errors?.zipCode ? "true" : ""}
                                                        variant="standard"
                                                        margin="normal"
                                                        autoComplete="off"
                                                        onKeyPress={(e) => !/[0-9]/.test(e.key) && e.preventDefault()}
                                                        required
                                                    />
                                                )}
                                            />
                                            {/* <InputMask mask="99999-999" value={field?.value} onChange={field?.onChange}>
                                                        {(inputProps: any) => (
                                                            <Form.Control
                                                                {...inputProps}
                                                                aria-invalid={errors?.zipCode ? "true" : ""}
                                                                variant="standard"
                                                                margin="normal"
                                                                autoComplete="off"
                                                                required
                                                            />
                                                        )}
                                                    </InputMask> */}
                                            <ErrorMessage
                                                errors={errors}
                                                name="zipCode"
                                                render={({ message }) => <small style={{ color: "red" }}>{message}</small>}
                                            />
                                        </Form.Group>
                                        {country?.id === '30' || country?.id === 30
                                            ?
                                            <Form.Group as={Col} md="4" controlId="formInfoCompanyState">
                                                <Form.Label>{t('accordionInfoCompany.state')}</Form.Label>
                                                <Controller
                                                    control={control}
                                                    name="state"
                                                    rules={{ required: { value: true, message: t('accordionInfoCompany.validation.state') } }}
                                                    render={({ field }: any) => (
                                                        <GetStates propsField={field} propsLabel={t('accordionInfoCompany.state')} propsErrors={errors} />
                                                    )}
                                                />
                                                <ErrorMessage
                                                    errors={errors}
                                                    name="state"
                                                    render={({ message }) => <small style={{ color: "red" }}>{message}</small>}
                                                />
                                            </Form.Group>
                                            :
                                            <Form.Group as={Col} md="4" controlId="formInfoCompanyState">
                                                <Form.Label>{t('accordionInfoCompany.state')}</Form.Label>
                                                <Controller
                                                    control={control}
                                                    name="state"
                                                    rules={{ required: { value: true, message: t('accordionInfoCompany.validation.state') } }}
                                                    render={({ field }: any) => (
                                                        <Form.Control
                                                            {...field}
                                                            variant="standard"
                                                            margin="normal"
                                                            autoComplete="off"
                                                            required
                                                        />
                                                    )}
                                                />
                                                <ErrorMessage
                                                    errors={errors}
                                                    name="state"
                                                    render={({ message }) => <small style={{ color: "red" }}>{message}</small>}
                                                />
                                            </Form.Group>
                                        }
                                    </Row>
                                    <Row className="mb-3">
                                        {country?.id === '30' || country?.id === 30
                                            ?
                                            <Form.Group as={Col} md="4" controlId="formInfoCompanyCity">
                                                <Form.Label>{t('accordionInfoCompany.city')}</Form.Label>
                                                <Controller
                                                    control={control}
                                                    name="city"
                                                    rules={{ required: { value: true, message: t('accordionInfoCompany.validation.city') } }}
                                                    render={({ field }: any) => (
                                                        <GetCitysByState propsField={field} propsErrors={errors} uf={watchState.id} />
                                                        )}
                                                />
                                                        {/* <GetCitys propsField={field} propsLabel={t('accordionInfoCompany.city')} propsErrors={errors} /> */}
                                                <ErrorMessage
                                                    errors={errors}
                                                    name="city"
                                                    render={({ message }) => <small style={{ color: "red" }}>{message}</small>}
                                                />
                                            </Form.Group>
                                            :
                                            <Form.Group as={Col} md="4" controlId="formInfoCompanyCity">
                                                <Form.Label>{t('accordionInfoCompany.city')}</Form.Label>
                                                <Controller
                                                    control={control}
                                                    name="city"
                                                    rules={{ required: { value: true, message: t('accordionInfoCompany.validation.city') } }}
                                                    render={({ field }: any) => (
                                                        <Form.Control
                                                            {...field}
                                                            variant="standard"
                                                            margin="normal"
                                                            autoComplete="off"
                                                            required
                                                        />
                                                    )}
                                                />
                                                <ErrorMessage
                                                    errors={errors}
                                                    name="city"
                                                    render={({ message }) => <small style={{ color: "red" }}>{message}</small>}
                                                />
                                            </Form.Group>
                                        }
                                        <Form.Group as={Col} md="4" controlId="formInfoCompanyDistrict">
                                            <Form.Label>{t('accordionInfoCompany.distr')}</Form.Label>
                                            <Controller
                                                control={control}
                                                name="compBairro"
                                                rules={{ required: { value: true, message: t('accordionInfoCompany.validation.distr') } }}
                                                render={({ field }: any) => (
                                                    <Form.Control
                                                        {...field}
                                                        aria-invalid={errors?.compBairro ? "true" : ""}
                                                        label={t('accordionInfoCompany.distr')}
                                                        variant="standard"
                                                        margin="normal"
                                                        autoComplete="off"
                                                        required
                                                    />
                                                )}
                                            />
                                            <ErrorMessage
                                                errors={errors}
                                                name="compBairro"
                                                render={({ message }) => <small style={{ color: "red" }}>{message}</small>}
                                            />
                                            {/* <Form.Control.Feedback type="invalid">
                                                Por favor, informe o bairro.
                                            </Form.Control.Feedback> */}
                                        </Form.Group>
                                        <Form.Group as={Col} md="4" controlId="formInfoCompanyAddress">
                                            <Form.Label>{t('accordionInfoCompany.address')}:</Form.Label>
                                            <Controller
                                                control={control}
                                                name="address"
                                                rules={{ required: { value: true, message: t('accordionInfoCompany.validation.address') } }}
                                                render={({ field }: any) => (
                                                    <Form.Control
                                                        {...field}
                                                        label={t("accordionInfoCompany.address")}
                                                        variant="standard"
                                                        margin="normal"
                                                        autoComplete="off"
                                                        required
                                                    />
                                                )}
                                            />
                                            {addressError === true
                                                ? <small style={{ color: "red" }}>Por favor, informe um endereço com pelo menos 5 caracteres.</small>
                                                : <></>
                                            }
                                            <ErrorMessage
                                                errors={errors}
                                                name="address"
                                                render={({ message }) => <small style={{ color: "red" }}>{message}</small>}
                                            />
                                            {/* <Form.Control.Feedback type="invalid">Por favor, informe o endereço.</Form.Control.Feedback> */}
                                        </Form.Group>
                                    </Row>
                                    <Row className="mb-3">

                                        <Form.Group as={Col} md="4" controlId="formInfoCompanyNumber">
                                            <Form.Label>{t('accordionInfoCompany.num')}</Form.Label>
                                            <Controller
                                                control={control}
                                                name="numero"
                                                rules={{ required: { value: true, message: t('accordionInfoCompany.validation.num') } }}
                                                render={({ field }: any) => (
                                                    <Form.Control
                                                        {...field}
                                                        aria-invalid={errors?.numero ? "true" : ""}
                                                        label={t("accordionInfoCompany.num")}
                                                        variant="standard"
                                                        margin="normal"
                                                        autoComplete="off"
                                                        onKeyPress={(e) => !/[0-9]/.test(e.key) && e.preventDefault()}
                                                        required
                                                    />
                                                )}
                                            />
                                            <ErrorMessage
                                                errors={errors}
                                                name="numero"
                                                render={({ message }) => <small style={{ color: "red" }}>{message}</small>}
                                            />
                                            {/* <Form.Control.Feedback type="invalid">Por favor, informe o número.</Form.Control.Feedback> */}
                                        </Form.Group>
                                        <Form.Group as={Col} md="4" controlId="formInfoCompanyComplement">
                                            <Form.Label>{t('accordionInfoCompany.comp')}</Form.Label>
                                            <Controller
                                                control={control}
                                                name="compComplem"
                                                /* rules={{ required: { value: true, message: 'Por favor, informe o nome fantasia.' } }} */
                                                render={({ field }: any) => (
                                                    <Form.Control
                                                        {...field}
                                                        label={t("accordionInfoCompany.comp")}
                                                        variant="standard"
                                                        margin="normal"
                                                        autoComplete="off"
                                                    />
                                                )}
                                            />
                                            {/* <ErrorMessage
                                                errors={errors}
                                                name="compComplem"
                                                render={({ message }) => <small style={{ color: "red" }}>{message}</small>}
                                            /> */}
                                        </Form.Group>
                                    </Row>
                                    <hr className="my-5" />
                                    <h5 className="text-uppercase mb-3">{t('accordionInfoCompany.contact')}</h5>
                                    <Row>
                                        <SuppliersTableCompany
                                            isEdit={isEdit}
                                            info={infoEdit}
                                            setMessageError={setMessageError}
                                            messageError={messageError}
                                            action={getContacts}
                                            hasSavedInputs={hasSavedInputs}
                                        />
                                    </Row>
                                    <Row className="d-flex justify-content-end mx-0 mt-5">
                                        {
                                            isEdit === true
                                                ?
                                                <Button
                                                    className="form-button cancel"
                                                    onClick={handleCancel}
                                                >
                                                    Cancelar
                                                </Button>
                                                :
                                                <></>
                                        }
                                        <Button
                                            type="submit"
                                            className="form-button"
                                            onClick={scrollTop}
                                        >
                                            {t('accordionInfoCompany.buttonContinue')}
                                        </Button>
                                    </Row>
                                </Form>
                            </div>
                        </Row>
                    </Container>
                </Card.Body>
            </Accordion.Collapse>
        </Card>
    )
});

export default AccordionInfoCompany;
