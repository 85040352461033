import React, { useEffect, useState } from "react";
import api from "../../services/api";
import SortableList, { SortableItem, SortableKnob } from "react-easy-sort";
import arrayMove from "array-move";
import { Col, Form, Row } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Controller } from "react-hook-form";
import { ErrorMessage } from "@hookform/error-message";
import './DraggableProductImputs.scss';
import { useAppSelector } from "../../store/hooks";

export interface operation {
  items: any;
  setItems: any;
  tourObject?: any;
  portuguese?: any;
  english?: any;
  spanish?: any;
  allowEditItems: any;
  address: any;
  control: any;
  setValue: any;
  errors: any;
  minLength: any;
  maxLength: any;
  required?: any;
  setError?: any;
  clearErrors?: any;
  numberOfCaracters?: any;
}

const DraggableProductImputs: React.FC<operation> = React.memo(
  ({ items, setItems, tourObject = {}, portuguese, english, spanish, allowEditItems, address, control, setValue, errors, minLength, maxLength, required = true, numberOfCaracters, setError, clearErrors }: operation) => {
    const tourSIG: any = JSON.parse(
      JSON.stringify(useAppSelector((state: any) => state.tour))
    );

    const [isDrag, setIsDrag] = useState<boolean>(tourSIG?.tour[`${address}`]?.length > 1 ? true : false);
    const [reRender, setReRender] = useState<boolean>(false);

    const handleInputItems = (
      value: string,
      language: string,
      index: number
    ) => {
      //adiciona ao Items os valores digitados nos inputs
      let aux: any = items;
      aux[index][`${language}`] = value;
      let field: string = '';

      if (required === true) {
        field = language === "PT" ? portuguese : language === "EN" ? english : spanish;
        if (value.length < minLength) {
          setError(`${field}-${index || 0}`, { type: 'custom', message: `Insira ao menos ${minLength} caracteres` });
        } else if (value.length > maxLength) {
          setError(`${field}-${index || 0}`, { type: 'custom', message: `Insira no máximo ${maxLength} caracteres` });
        } else {
          clearErrors(`${field}-${index || 0}`);
        }
      }

      setItems(() => aux);
      setReRender(() => !reRender);
    };


    const handleBlurItems = (e: any, index: number) => {
      if (tourObject.isTranslate === 1 && allowEditItems === false) {
        TranslateTextItemsEN(e.target.value, index);
        TranslateTextItemsES(e.target.value, index);
      }
    };

    const handleClickItems = () => {
      setItems(() => [
        ...items,
        {
          PT: "",
          EN: "",
          ES: "",
        },
      ]);
      setIsDrag(true);
    };


    useEffect(() => {
      if (tourSIG.tour[`${address}`] !== undefined) {
        let aux: any = [];

        for (let i: number = 0; i < tourSIG.tour[`${address}`]?.length; i++) {
          aux.push({
            PT: tourSIG.tour[`${address}`][i][0].text,
            EN: tourSIG.tour[`${address}`][i][1].text,
            ES: tourSIG.tour[`${address}`][i][2].text,
          });
        }

        setItems(() => aux);

        for (let i: number = 0; i < tourSIG.tour[`${address}`].length; i++) {
          setValue(`${portuguese}-${i}`, tourSIG.tour[`${address}`][i][0].text);
          setValue(`${english}-${i}`, tourSIG.tour[`${address}`][i][1].text);
          setValue(`${spanish}-${i}`, tourSIG.tour[`${address}`][i][2].text);
        }
      }

      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    //  useEffect(()=>{
    //   let aux: any = [];

    //   setItems(()=> aux);
    //  },[items, setItems]);

    const handleDeleteItems = (index: number) => {
      // 'deleta' um item do Items, transformando em null
      let aux: any = [];

      for (let i: number = 0; i < items.length; i++) {
        if (i !== index) {
          aux.push(items[i]);
        } else {
          aux.splice(index, 1);
          //aux.push(null);
        }
      }

      setItems(() => aux);

      if (items.length === 2) {
        setIsDrag(false)
      }
    };

    const TranslateTextItemsEN = async (name: string, index: number) => {
      if (name !== undefined && name !== "") {
        try {
          const res = await api.post(`https://translation.googleapis.com/language/translate/v2?key=${process.env.REACT_APP_GOOGLE_CLOUD_TRANSLATE_API}`, {
            "q": name,
            "source": "pt-BR",
            "target": "en",
            "format": "text"
          });
          if (res.status !== 400) {
            setValue(`${english}-${index}`, res?.data?.data?.translations[0]?.translatedText);
            handleInputItems(res?.data?.data?.translations[0]?.translatedText, "EN", index);
          }
        } catch (error: any) { }
      } else {
        setValue(`${english}-${index}`, "");
        handleInputItems("", "EN", index);
      }
    };

    const TranslateTextItemsES = async (name: string, index: number) => {
      if (name !== undefined && name !== "") {
        try {
          const res = await api.post(`https://translation.googleapis.com/language/translate/v2?key=${process.env.REACT_APP_GOOGLE_CLOUD_TRANSLATE_API}`, {
            "q": name,
            "source": "pt-BR",
            "target": "es",
            "format": "text"
          });
          if (res.status !== 400) {
            setValue(`${spanish}-${index}`, res?.data?.data?.translations[0]?.translatedText);
            handleInputItems(res?.data?.data?.translations[0]?.translatedText, "ES", index);
          }
        } catch (error: any) { }
      } else {
        setValue(`${spanish}-${index}`, "");
        handleInputItems("", "ES", index);
      }
    };

    const onSortEnd = (oldIndex: number, newIndex: number) => {
      setItems((array: any) => arrayMove(array, oldIndex, newIndex));
    };

    useEffect(() => {
      for (let i: number = 0; i < items.length; i++) {
        setValue(`${portuguese}-${i}`, items[i].PT);
        setValue(`${english}-${i}`, items[i].EN);
        setValue(`${spanish}-${i}`, items[i].ES);
      }

      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [items]);

    return (
      <Form>
        {items.length > 1 ? (
          <SortableList
            onSortEnd={onSortEnd}
            className="list"
            draggedItemClassName="dragged"
            lockAxis="y"
          >
            {items.map((elem: any, index: number) => {
              if (elem !== null) {
                return (
                  <>
                    <SortableItem key={index}>
                      <div className="d-flex index">
                        {isDrag === true && (
                          <SortableKnob>
                            <Col md="1">
                              <div className="sortable-knob">
                                <FontAwesomeIcon
                                  size="2x"
                                  icon={["fal", "bars"]}
                                />
                              </div>
                            </Col>
                          </SortableKnob>
                        )}
                        <Col md={isDrag === true ? "10" : "11"}>
                          <Row>
                            <Form.Group
                              as={Col}
                              md="4"
                              controlId=""
                              className="mt-4"
                            >
                              <Form.Label className="label-small d-flex justify-content-between">
                                <span>Português</span>
                                <span
                                  style={{ fontWeight: 400 }}
                                >{`${items[index].PT.length}/${maxLength} caracteres`}</span>
                              </Form.Label>

                              <Controller
                                control={control}
                                name={`${portuguese}-${index}`}
                                rules={{
                                  required: {
                                    value: required,
                                    message:
                                      "Por favor, informe o detalhe do produto",
                                  },
                                  minLength: {
                                    value: minLength,
                                    message: `Por favor, insira ao menos ${minLength} caracteres`,
                                  },
                                  maxLength: {
                                    value: maxLength,
                                    message: `Por favor, insira no máximo ${maxLength} caracteres`,
                                  },
                                }}
                                render={({ field }: any) => (
                                  <Form.Control
                                    {...field}
                                    type="text"
                                    aria-invalid={errors?.itemsPT ? "true" : ""}
                                    variant="standard"
                                    margin="normal"
                                    onInput={(e: any) => {
                                      handleInputItems(
                                        e.target.value,
                                        "PT",
                                        index
                                      );
                                    }}
                                    onBlur={(e: any) => {
                                      handleBlurItems(e, index);
                                    }}
                                    required
                                    autoComplete='off'
                                  />
                                )}
                              />
                              <ErrorMessage
                                errors={errors}
                                name={`${portuguese}-${index}`}
                                render={({ message }) => (
                                  <small style={{ color: "red" }}>
                                    {message}
                                  </small>
                                )}
                              />
                            </Form.Group>

                            <Form.Group
                              as={Col}
                              md="4"
                              controlId=""
                              className="mt-4"
                            >
                              <Form.Label className="label-small d-flex justify-content-between">
                                <span>Inglês</span>
                                <span style={{ fontWeight: 400 }}>{`${items[index].EN.length}/${maxLength} caracteres`}</span>
                              </Form.Label>

                              <Controller
                                control={control}
                                name={`${english}-${index}`}
                                rules={{
                                  required: {
                                    value: required,
                                    message:
                                      "Por favor, informe o detalhe do produto",
                                  },
                                  minLength: {
                                    value: minLength,
                                    message: `Por favor, insira ao menos ${minLength} caracteres`,
                                  },
                                  maxLength: {
                                    value: maxLength,
                                    message: `Por favor, insira no máximo ${maxLength} caracteres`,
                                  },
                                }}
                                render={({ field }: any) => (
                                  <Form.Control
                                    {...field}
                                    type="text"
                                    aria-invalid={errors?.itemsEN ? "true" : ""}
                                    variant="standard"
                                    margin="normal"
                                    disabled={
                                      tourObject.isTranslate === 1 &&
                                      allowEditItems === false
                                    }
                                    onInput={(e: any) => {
                                      handleInputItems(
                                        e.target.value,
                                        "EN",
                                        index
                                      );
                                    }}
                                    required
                                    autoComplete='off'
                                  />
                                )}
                              />
                              <ErrorMessage
                                errors={errors}
                                name={`${english}-${index}`}
                                render={({ message }) => (
                                  <small style={{ color: "red" }}>
                                    {message}
                                  </small>
                                )}
                              />
                            </Form.Group>

                            <Form.Group
                              as={Col}
                              md="4"
                              controlId=""
                              className="mt-4"
                            >
                              <Form.Label className="label-small d-flex justify-content-between">
                                <span>Espanhol</span>
                                <span style={{ fontWeight: 400 }}>{`${items[index].ES.length}/${maxLength} caracteres`}</span>
                              </Form.Label>
                              <Controller
                                control={control}
                                name={`${spanish}-${index}`}
                                rules={{
                                  required: {
                                    value: required,
                                    message:
                                      "Por favor, informe o detalhe do produto",
                                  },
                                  minLength: {
                                    value: minLength,
                                    message: `Por favor, insira ao menos ${minLength} caracteres`,
                                  },
                                  maxLength: {
                                    value: maxLength,
                                    message: `Por favor, insira no máximo ${maxLength} caracteres`,
                                  },
                                }}
                                render={({ field }: any) => (
                                  <Form.Control
                                    {...field}
                                    type="text"
                                    aria-invalid={errors?.itemsES ? "true" : ""}
                                    variant="standard"
                                    margin="normal"
                                    disabled={
                                      tourObject.isTranslate === 1 &&
                                      allowEditItems === false
                                    }
                                    onInput={(e: any) => {
                                      handleInputItems(
                                        e.target.value,
                                        "ES",
                                        index
                                      );
                                    }}
                                    required
                                    autoComplete='off'
                                  />
                                )}
                              />
                              <ErrorMessage
                                errors={errors}
                                name={`${spanish}-${index}`}
                                render={({ message }) => (
                                  <small style={{ color: "red" }}>
                                    {message}
                                  </small>
                                )}
                              />
                            </Form.Group>
                          </Row>
                        </Col>
                        {items.filter((e: any) => e !== null).length > 1 ? (
                          <Col md="1">
                            <div className="d-flex align-items-end justify-content-center h-100">
                              <FontAwesomeIcon
                                icon={["fal", "trash-alt"]}
                                className="mb-3"
                                style={{ cursor: "pointer" }}
                                onClick={() => {
                                  handleDeleteItems(index);
                                }}
                              />
                            </div>
                          </Col>
                        ) : (
                          <></>
                        )}
                      </div>
                    </SortableItem>
                  </>
                );
              } else {
                return <></>;
              }
            })}
          </SortableList>
        ) : (
          <>
            <Col md="12">
              <Row>
                <Form.Group as={Col} md="4" controlId="" className="mt-4">
                  <Form.Label className="label-small d-flex justify-content-between">
                    <span>Português</span>
                    <span style={{ fontWeight: 400 }}>{`${items[0]?.PT?.length} /${maxLength} caracteres`}</span>
                  </Form.Label>
                  <Controller
                    control={control}
                    name={`${portuguese}-0`}
                    rules={{
                      required: {
                        value: required,
                        message: "Por favor, informe o detalhe do produto",
                      },
                      minLength: {
                        value: minLength,
                        message: `Por favor, insira ao menos ${minLength} caracteres`,
                      },
                      maxLength: {
                        value: maxLength,
                        message: `Por favor, insira no máximo ${maxLength} caracteres`,
                      },
                    }}
                    render={({ field }: any) => (
                      <Form.Control
                        {...field}
                        type="text"
                        aria-invalid={errors.itemsPT ? "true" : ""}
                        variant="standard"
                        margin="normal"
                        onInput={(e: any) => {
                          handleInputItems(e.target.value, "PT", 0);
                        }}
                        onBlur={(e: any) => {
                          handleBlurItems(e, 0);
                        }}
                        required
                        autoComplete='off'
                      />
                    )}
                  />
                  {/* <div className="d-flex justify-content-between"> */}
                  <ErrorMessage
                    errors={errors}
                    name={`${portuguese}-0`}
                    render={({ message }) => (
                      <small style={{ color: "red" }}>{message}</small>
                    )}
                  />
                  {/* <small>Máximo {maxLength} caracteres</small>
                  </div> */}
                </Form.Group>

                <Form.Group as={Col} md="4" controlId="" className="mt-4">
                  <Form.Label className="label-small d-flex justify-content-between">
                    <span>Inglês</span>
                    <span style={{ fontWeight: 400 }}>{`${items[0]?.EN?.length}/${maxLength} caracteres`}</span>
                  </Form.Label>
                  <Controller
                    control={control}
                    name={`${english}-0`}
                    rules={{
                      required: {
                        value: required,
                        message: "Por favor, informe o detalhe do produto",
                      },
                      minLength: {
                        value: minLength,
                        message: `Por favor, insira ao menos ${minLength} caracteres`,
                      },
                      maxLength: {
                        value: maxLength,
                        message: `Por favor, insira no máximo ${maxLength} caracteres`,
                      },
                    }}
                    render={({ field }: any) => (
                      <Form.Control
                        {...field}
                        type="text"
                        aria-invalid={errors?.itemsEN ? "true" : ""}
                        variant="standard"
                        margin="normal"
                        disabled={
                          tourObject.isTranslate === 1 &&
                          allowEditItems === false
                        }
                        onInput={(e: any) => {
                          handleInputItems(e.target.value, "EN", 0);
                        }}
                        required
                        autoComplete='off'
                      />
                    )}
                  />
                  <ErrorMessage
                    errors={errors}
                    name={`${english}-0`}
                    render={({ message }) => (
                      <small style={{ color: "red" }}>{message}</small>
                    )}
                  />
                </Form.Group>

                <Form.Group as={Col} md="4" controlId="" className="mt-4">
                  <Form.Label className="label-small d-flex justify-content-between">
                    <span>Espanhol</span>
                    <span style={{ fontWeight: 400 }}>{`${items[0]?.ES?.length}/${maxLength} caracteres`}</span>
                  </Form.Label>
                  <Controller
                    control={control}
                    name={`${spanish}-0`}
                    rules={{
                      required: {
                        value: required,
                        message: "Por favor, informe o detalhe do produto",
                      },
                      minLength: {
                        value: minLength,
                        message: `Por favor, insira ao menos ${minLength} caracteres`,
                      },
                      maxLength: {
                        value: maxLength,
                        message: `Por favor, insira no máximo ${maxLength} caracteres`,
                      },
                    }}
                    render={({ field }: any) => (
                      <Form.Control
                        {...field}
                        type="text"
                        aria-invalid={errors?.ItemsES ? "true" : ""}
                        variant="standard"
                        margin="normal"
                        disabled={
                          tourObject.isTranslate === 1 &&
                          allowEditItems === false
                        }
                        onInput={(e: any) => {
                          handleInputItems(e.target.value, "ES", 0);
                        }}
                        required
                        autoComplete='off'
                      />
                    )}
                  />
                  <ErrorMessage
                    errors={errors}
                    name={`${spanish}-0`}
                    render={({ message }) => (
                      <small style={{ color: "red" }}>{message}</small>
                    )}
                  />
                </Form.Group>
              </Row>
            </Col>
          </>
        )}

        <div className="d-flex justify-content-center mt-5">
          <div className="btn-add-details" onClick={handleClickItems}>
            <FontAwesomeIcon
              icon={["fal", "plus"]}
              size="1x"
              className="mr-2"
            />
            Adicionar
          </div>
        </div>
      </Form>
    );
  }
);

export default DraggableProductImputs;
