import React, { useEffect, useState } from 'react';
import api from '../../../services/api';

//COMPONENTS
import Breadcrumb from '../../../components/Breadcrumb/Breadcrumb';

import AccountsForm from '../components/AccountsForm/AccountsForm';
import { useTranslation } from 'react-i18next';
import DesktopDefault from '../../../templates/DesktopDefault';


function EditAccounts() {
    const { t } = useTranslation();
    const [editAccounts,setEditAccounts] = useState(null);

  

    useEffect(() => {
        const idSup = window.location.href.split('id=')[1];

        const token = localStorage.getItem('GroupId') || '{}';
        const config = {
            headers: { 'Authorization': `Bearer ${token}` },
        };
        const EditAccounts = async () => {
            try {
                const { data } = await api.get(`MerchantAccount/GetAccountsByIdAsync/${idSup}`, config);
                /* console.log(data.data); */
                if (data.status !== 400) {
                    setEditAccounts(data.data);
                }   
            }catch(error: any) {
                if (error?.response?.status === 401) {
                    window.location.href = window.location.origin + '/';              
                }
                if (error?.response?.status === 400) {
                    window.location.href = window.location.origin + '/list-accounts';              
                }
            }
        }
        EditAccounts();
    }, [])

    if(editAccounts !== null) {
        return (
            <>
                <DesktopDefault>
                    <div className="container-fluid content-dashboard">

                        <Breadcrumb title={'Contas Correntes / ' + t('editAccounts.title')} />

                        <div className="register-users mt-4">
                            <h2 className="title">{t('editAccounts.title')}</h2>
                        </div>

                        <AccountsForm action={2} info={editAccounts} />

                    </div>

                </DesktopDefault>
            </>

        );
    } else {
        return (
            <></>
        )
    }
}

export default EditAccounts;