import React, { Key, useEffect, useState } from 'react';
import Form from "react-bootstrap/Form";
import { useForm } from "react-hook-form";
import Button from "@restart/ui/esm/Button";
import { Helmet } from "react-helmet";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

//COMPONENTS
import DesktopDefault from '../../templates/DesktopDefault';
import Breadcrumb from '../../components/Breadcrumb/Breadcrumb';

//STYLES
import './Checkout.scss';

import { useAppSelector } from "../../store/hooks";

//ICONS
import BookingInformation from '../../components/Checkout/BookingInformation/BookingInformation';
import CartCheckout from '../../components/Checkout/CartCheckout/CartCheckout';
import DetailsCkeckout from '../../components/Checkout/DetailsCkeckout/DetailsCkeckout';
import PaymentCheckout from '../../components/Checkout/PaymentCheckout/PaymentCheckout';
import CupomCheckout from '../../components/Checkout/CupomCheckout/CupomCheckout';
//import CancelPolicyCheckout from '../../components/Checkout/CancelPolicyCheckout/CancelPolicyCheckout';
import { Col, Modal, Row } from 'react-bootstrap';
import ModalContentPayment from '../../components/Checkout/ModalContentPayment/ModalContentPayment';
import CartHeader from '../Sales/components/CartHeader/CartHeader';
import api from '../../services/api';
import { useTranslation } from 'react-i18next';

declare module 'react' {
    interface HTMLAttributes<T> extends AriaAttributes, DOMAttributes<T> {
        // extends React's HTMLAttributes
        org_id?: string;
    }
}

declare global {
    interface Window {
        koinSessionId?: any;
        getSessionID?: any;
        //SESSION_COOKIE_NAME?: any;
    }
}


function Checkout() {
    const { t } = useTranslation();
    //const tempCart = localStorage.getItem('cartSIG') || '{}';
    //const cart: any = JSON.parse(tempCart).dados;
    const cartSIG: any = JSON.parse(JSON.stringify(useAppSelector((state: any) => state.cart)));

    const [alertBool, setAlertBool] = useState<any>(false);
    const [modalResponse, setModalResponse] = useState<any>(null);
    const [checkoutResponse, setCheckoutResponse] = useState<any>('');
    const [modalContent, setModalContent] = useState<any>('loading');
    const alertMessage: any = ''; //const [alertMessage, setAlertMessage] = useState<any>("");

    const [paymentMethod, setPaymentMethod] = useState<any>('1');
    const [merchantPayMethod, setMerchantPayMethod] = useState<any>(process.env.REACT_APP_MERCHANT_PAYMENT || '3')
    //const [allowResetCart, setAllowResetCart] = useState<boolean>(false);

    const [mainContactObj, setMainContactObj] = useState<any>(null);
    const [isForeign, setIsForeign] = useState<boolean>(false);

    const [disableButton, setDisableButton] = useState<boolean>(false);

    //const [loading, setLoading] = useState<any>(false);

    const resetCart = () => {
        //setAllowResetCart(true);
        localStorage.removeItem("cartSIG");
        window.location.href = "/sale";
    }

    const controlMethod = (newMethod: string, mPayMethod: string) => {
        setPaymentMethod(newMethod);
        setMerchantPayMethod(mPayMethod)
    }

    const {
        control,
        handleSubmit,
        formState: { errors },
        setValue,
        getValues,
        watch,
    } = useForm();

    useEffect(() => {
        if (isForeign === true) {
            setValue('city', '');
            setValue('state', '');
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isForeign])

    const watchIsMain = watch(`isMain`, false);
    const watchFirstName = watch(`name`, false);
    const watchLastName = watch(`lastName`, false);
    const watchEmail = watch(`email`, false);
    const watchPhone = watch(`phone`, false);
    const watchDdi = watch(`ddi`, false);
    const watchState = watch(`state`, '');
    const watchLanguage = watch('language', false);
    const watchCountry = watch(`country`, '');

    useEffect(() => {
        if (isForeign === false) {
            setValue('city', '');
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [watchState])

    useEffect(() => {
        if (watchIsMain === true) {
            setMainContactObj(
                {
                    "name": watchFirstName,
                    "lastName": watchLastName,
                    "email": watchEmail,
                    "phone": watchPhone,
                    "ddi": watchDdi,
                }
            )
        } else if (watchIsMain === false) {
            setMainContactObj(
                {
                    "name": '',
                    "lastName": '',
                    "email": '',
                    "phone": '',
                    "ddi": '',
                }
            )
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [watchIsMain])

    const onSubmit = (data: any) => {
        setDisableButton(true);
        setModalResponse(true);
        setModalContent('loading');

        if (process.env.REACT_APP_CLIENT_NAME === 'Destinow') {
            /* KOIN */
            if (window.koinSessionId !== undefined) {
                createObjectToPayment(data, window.koinSessionId);
                //setDone(true);
            } else {
                if (!!window?.getSessionID) {
                    window.getSessionID(function (sessionId: any) {
                        // Capture sessionId to later send in API calls
                        window.koinSessionId = sessionId;
                        createObjectToPayment(data, sessionId);

                        return sessionId;
                    })
                }
            }
            /* [END] KOIN */
        } else {
            createObjectToPayment(data, '');
        }
    };

    const createObjectToPayment = (data: any, koinSessionId: any) => {
        data.foreign = isForeign === false ? 2 : 1;

        let today = new Date();
        let dd = String(today.getDate()).padStart(2, '0');
        let mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
        let yyyy = today.getFullYear();

        let todayFoPayment = yyyy + '-' + mm + '-' + dd;

        let documentNumber: any = isForeign === true ? data.id : data.cpf;

        let payments = [{
            brand: data.paymentOptions === 'credit' || data.paymentOptions === undefined ? data.brand : undefined,
            cardNumber: data.paymentOptions === 'credit' || data.paymentOptions === undefined ? data.number : undefined,
            codeVerifier: data.paymentOptions === 'credit' || data.paymentOptions === undefined ? data.cvc : undefined,
            DocumentNumber: data.paymentOptions === 'credit' || data.paymentOptions === undefined ? (data.cpfTitular || documentNumber) : documentNumber,
            SessionIdKoin: koinSessionId,
            datePayment: todayFoPayment,
            expiration: data.paymentOptions === 'credit' || data.paymentOptions === undefined ? data.expiry : undefined,
            holder: data.paymentOptions === 'credit' || data.paymentOptions === undefined ? data.titular : undefined,
            installments: data.paymentOptions === "cOffiline" || data.paymentOptions === 'credit' || data.paymentOptions === undefined ? parseInt(data.parcelas?.split('x')[0] || 1) : 1,
            MerchantPayMethod: merchantPayMethod, // Passar 0 para faturado
            // value: data.valor,
            payMethod: paymentMethod, //faturado = 85
            merchant: data.merchant,
            refCode: data.refCode, //NSU 
            CodAutorizacaoOffline: data.paymentOptions === 'cOffiline' ? data.CodAutorizacaoOffline : undefined,
        }];
        // data.payments = payments;
        cartSIG.payments = payments;

        let totalCart = 0;
        cartSIG.dados.map((info: any, index: Key) => {
            let priceProduct = (
                (Number(info.adults) * Number(info.priceAdults))
                + (Number(info.childs) * Number(info.priceChilds))
                + (Number(info.infants) * Number(info.priceInfants))
                + (Number(info.elders) * Number(info.priceElders))
                + (Number(info.student) * Number(info.priceStudent))
                + (Number(info.priceGlobalPeople))
            );
            totalCart = Number(totalCart) + (priceProduct - (priceProduct * (info.discount / 100)));

            if (index === 0) {
                data.dados[index].passengers[0].DocumentNumber = String(documentNumber).replaceAll('.', '').replaceAll('-', '');
            }

            /*  */
            // if(cartSIG.dados[index].isIntegration){
            //     cartSIG.dados[index].IdPickupDestino = obj.idPickupLocation;
            //     cartSIG.dados[index].AntecedenciaPickupDestino = obj.minutesPrior;
            //     cartSIG.dados[index].NomeListaPickupDestino = obj.nomeListaPickup;
            //     cartSIG.dados[index].ListaPickupDestino = obj.pickupIdStranger;
            //     cartSIG.dados[index].NomePickupDestino = obj.nomePickup;
            // }
            /*  */

            cartSIG.dados[index].passengers = data.dados[index].passengers;
            cartSIG.dados[index].pickup = data.dados[index].pickup;
            cartSIG.dados[index].internalNotes = data.dados[index].internalNotes;

            if (info.productType === 2) {
                cartSIG.dados[index].cia = data.cia;
                cartSIG.dados[index].voo = data.flight;
                cartSIG.dados[index].bigSuitcase = data.dados[index].luggage.luggage;
                cartSIG.dados[index].smallSuitcase = data.dados[index].luggage.handLuggage;
            }
            return true;/*  */
        })

        // cartSIG.agent = data.agent;
        cartSIG.foreign = isForeign === false ? 2 : 1;
        cartSIG.agent = data.agent;
        cartSIG.seller = data.seller;
        cartSIG.street = data.street;
        cartSIG.Country = isForeign === false ? 'BR' : data.country;
        cartSIG.city = isForeign === false && (data.paymentOptions === 'credit' || data.paymentOptions === undefined)/* && paymentMethod === '1' && merchantPayMethod === '1' */ ? data.city.description : (data.city || 'Rio de Janeiro');
        cartSIG.state = isForeign === false && (data.paymentOptions === 'credit' || data.paymentOptions === undefined)/* && paymentMethod === '1' && merchantPayMethod === '1' */ ? data.state.id : (data.state || 'RJ');
        cartSIG.source = "sig";
        cartSIG.resellerReference = data.reference;

        cartSIG.payments[0].value = totalCart;

        cartSIG.language = data.language;

        if (paymentMethod === '1' && merchantPayMethod === process.env.REACT_APP_MERCHANT_PAYMENT) {
            cartSIG.street = data.street;
            cartSIG.city = isForeign === false ? data.city.description : data.city;
            cartSIG.state = isForeign === false ? data.state.id : data.state;

            if (data.brand === undefined) {
                alert(t('checkout.invalidCard'));
            } else {
                createPayment();
            }
        } else {
            createPayment();
        }
    }

    useEffect(() => {
        //setValue('id', getValues().cpf?.replaceAll('.','').replaceAll('-',''));
        setValue('id', '');
        setValue('cpf', '');
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isForeign])

    const createPayment = async () => {
        setModalResponse(true);
        setModalContent('loading');
        const token = localStorage.getItem('GroupId') || '{}';
        const config = {
            headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${token}` },
        };
        try {
            const { data } = await api.post('/Checkout/EnviaCheckoutAuth', JSON.stringify(cartSIG), config);
            if (data.status !== 400) {

                if (data.data.log === '1' || data.data.log === 1) {
                    setCheckoutResponse(data);
                    setModalContent('success');
                } else {
                    setCheckoutResponse(data);
                    setModalContent('success');

                }
                //setLoading(false);
            }
            setDisableButton(false);

        } catch (error: any) {
            if (error?.response?.status === 400) {
                setCheckoutResponse(error.response);
                setModalContent('error');
                //setLoading(false);
            }
            setDisableButton(false);
        }
    }


    return (
        <>
            <DesktopDefault>
                <Helmet>
                    <script src="https://securegtm.despegar.com/risk/fingerprint/statics/track-min.js" type="text/javascript" org_id={process.env.REACT_APP_ORG_ID} id="deviceId_fp" async={true} ></script>
                </Helmet>
                <div className="container-fluid container-checkout">
                    <Breadcrumb title="Checkout" />

                    <div className="checkout-title d-flex flex-column align-items-between">
                        <div className="d-flex justify-content-between w-100">
                            <div className="d-flex align-items-center">

                                <span className='back' onClick={() => { window.location.href = '/sale' }}>
                                    <FontAwesomeIcon
                                        icon={["fal", "angle-double-left"]}
                                        style={{ margin: "0 5px 0 0", fontSize: '16px' }}
                                    />
                                    {t("checkout.checkout.back")}</span>
                            </div>
                            <div className="icon-tp">

                                <CartHeader></CartHeader>
                            </div>
                        </div>
                        <div className="d-flex justify-content-start w-100">
                            <h1>
                                {/* <FontAwesomeIcon
                                className='icon-primary'
                                icon={["fal", "shopping-cart"]}
                                size="1x"
                                style={{ marginRight: "10px", width: "30px", fontSize: "2rem", color: "#707070" }}
                                /> */}
                                {t("checkout.checkout.title")}
                            </h1>
                        </div>
                    </div>

                    <Row>
                        <Col md={9} className="container-cards">
                            <Form noValidate onSubmit={handleSubmit(onSubmit)} autoComplete="false">
                                <BookingInformation
                                    control={control}
                                    errors={errors}
                                    setValue={setValue}
                                    watch={watch}
                                    isForeign={isForeign}
                                    setIsForeign={setIsForeign}
                                    t={t}
                                />
                                <CartCheckout
                                    control={control}
                                    errors={errors}
                                    cart={cartSIG.dados}
                                    setValue={setValue}
                                    mainContactObj={mainContactObj}
                                    t={t}
                                />
                                <PaymentCheckout
                                    control={control}
                                    errors={errors}
                                    setValue={setValue}
                                    action={controlMethod}
                                    isForeign={isForeign}
                                    getValues={getValues}
                                    watchCountry={watchCountry}
                                    t={t}
                                />

                                <div className="d-flex justify-content-end">
                                    <Button className="btn btn-primary btn-payment" disabled={disableButton} type='submit'>
                                        {t("checkout.checkout.makePayment")}
                                    </Button>
                                </div>
                            </Form>
                        </Col>
                        <Col md={3} className="container-details">
                            <div>
                                <DetailsCkeckout
                                    cart={cartSIG.dados}
                                    t={t}
                                />
                                <CupomCheckout t={t} />
                                {/* <CancelPolicyCheckout /> */}
                            </div>
                        </Col>
                    </Row>

                    <Modal
                        className="modal-custom modalCheckout modalPayment"
                        show={modalResponse !== null}
                        onHide={() => {
                            // setCheckoutResponse('');
                            setModalContent('loading');
                            setModalResponse(null)
                            setDisableButton(false);
                            if (paymentMethod !== '111') {
                                resetCart();
                            }
                        }}
                        size="lg"
                        aria-labelledby="contained-modal-title-vcenter"
                        backdrop="static"
                    >
                        <>
                            {modalContent === "success" && <Modal.Header closeButton></Modal.Header>}
                            <Modal.Body>
                                <ModalContentPayment
                                    pix={['96', '100', '100', '111'].includes(paymentMethod)}
                                    modalContent={modalContent}
                                    checkoutResponse={checkoutResponse}
                                    resetCart={resetCart}
                                    setModalResponse={setModalResponse}
                                    getValues={getValues}
                                    watchLanguage={watchLanguage}
                                />

                            </Modal.Body>
                        </>
                    </Modal>

                    <Modal
                        className="modal-confirm"
                        show={alertBool}
                        onHide={() => {
                            setAlertBool(false)
                        }}
                        size="lg"
                        aria-labelledby="contained-modal-title-vcenter"
                    >
                        <div className='modal-body text-center'>
                            <div>
                                <FontAwesomeIcon
                                    icon={["fal", "times-circle"]}
                                    size="5x"
                                    style={{ fontSize: "7.5em", color: "#FF6600" }}
                                />

                            </div>
                            <div className="px-md-5" style={{ padding: "0 3rem" }}>
                                {alertMessage}
                            </div>
                            <div>
                                <Button type="submit" className="btn btn-primary form-button px-4 py-2" onClick={() => setAlertBool(false)}>{t("checkout.checkout.close")}</Button>
                            </div>
                        </div>
                    </Modal>

                </div>
            </DesktopDefault>
        </>
    );
}

export default Checkout;