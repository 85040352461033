import React, { useEffect, useState } from "react";
import { Card, Col, Form, Row } from "react-bootstrap";
import BootstrapTable, { SelectRowProps } from "react-bootstrap-table-next";
import { Controller, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import Pagination from "../../../../components/Pagination/Pagination";
import api from "../../../../services/api";

import "./LinkProduct.scss";

export interface propFilter {
    setCategoryList: any; setModalShow: any; categoryList: any;
}

const LinkProduct: React.FC<propFilter> = ({
    setCategoryList, setModalShow, categoryList
}) => {

    const [t] = useTranslation();
    const [foundProducts, setFoundProducts] = useState<any>([]);
    const [actualName, setActualName] = useState<any>("");
    const [actualCity, setActualCity] = useState<any>("");
    const [actualSupplier, setActualSupplier] = useState<any>(0);
    const [filterProduct, setFilterProduct] = useState<any>("");
    const [filterCity, setFilterCity] = useState<any>("");
    const [filterSupplier, setFilterSupplier] = useState<any>(0);
    const [listOfSelected, setListOfSelected] = useState<any>([]);

    // const [selectedCheck, setSelectedCheck] = useState<any>([]);
    const [itemToBeListed, setItemToBeListed] = useState<any>(categoryList === null ? [] : categoryList);

    /* Pagination */
    const [totalRows, setTotalRows] = useState<any>();
    const [pageCount, setPageCount] = useState<any>(1);
    const [rowsPerPage, setRowsPerPage] = useState<any>(10);
    /* END - Pagination */

    function clearActualValues() {
        setActualName("");
        setActualCity("");
        setActualSupplier(0);
        setFilterProduct('');
        setFilterCity('');
        setFilterSupplier(0);

        buscar('', '', 0);
    };

    const handleToLink = () => {
        console.log(itemToBeListed)
        setCategoryList(itemToBeListed);
        setModalShow(false);
    }

    useEffect(() => {
        var aux: any = [];
        itemToBeListed.forEach((elem: any) => {
            aux.push(elem.productCode);
        });
        setListOfSelected(aux);
    }, [itemToBeListed])

    useEffect(() => {
        async function filter() {
            try {
                const res = await api.post(`/Products/GetTourTicketProductAsync`,
                    {
                        "page": pageCount,
                        "rowsPerPage": rowsPerPage,
                        "codProd": actualName,
                        "cadProd": 0,
                        "statProd": 0,
                        "typeProd": 0,
                        "nomeCidade": actualCity,
                        "fornecedor": actualSupplier,
                        "idioma": 1
                    }
                );
                if (res.status !== 400) {
                    setFoundProducts(res.data.data.rows);
                    setTotalRows(res.data.data.rowsCount);
                }

            } catch (error) { }
        }
        filter();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [pageCount, rowsPerPage]);


    async function buscar(name: any, city: any, supplier: any) {
        // setFoundProducts(null);
        setActualName(name);
        setActualCity(city);
        setActualSupplier(supplier);
        setPageCount(1);

        const token = localStorage.getItem('GroupId') || '{}';
        const config = {
            headers: { 'Authorization': `Bearer ${token}` },
        };
        try {
            const res = await api.post('/Products/GetTourTicketProductAsync',
                {
                    "page": pageCount,
                    "rowsPerPage": rowsPerPage,
                    "codProd": name,
                    "cadProd": 0,
                    "statProd": 0,
                    "typeProd": 0,
                    "nomeCidade": city,
                    "fornecedor": parseInt(supplier),
                    "idioma": 1
                }
                , config
            );

            if (res.status !== 400) {
                //setLoading(false);
                setFoundProducts(res.data.data.rows);
                setTotalRows(res.data.data.rowsCount);
            }
        } catch (error: any) {
            //setLoading(false);
            if (error.response.status === 401) {
                window.location.href = window.location.origin + '/';
            }

        }
    };

    const handleChangeName = (e: React.ChangeEvent<HTMLInputElement>) => {
        const newValue = e.target.value;
        setFilterProduct(newValue);
    };

    const handleChangeCity = (e: React.ChangeEvent<HTMLInputElement>) => {
        const newValue = e.target.value;
        setFilterCity(newValue);
    };

    // const handleChangeSupplier = (e: React.ChangeEvent<HTMLInputElement>) => {
    //     const newValue = e.target.value;
    //     setFilterSupplier(newValue);
    // };


    const {
        control,
    } = useForm();

    const handleFilterClick = () => {
        buscar(filterProduct, filterCity, filterSupplier)
    }


    const selectRow: SelectRowProps<any> = {
        mode: 'checkbox',
        clickToSelect: true,
        selected: listOfSelected,
        onSelect: (row, isSelect, rowIndex, e) => {
            if (isSelect === true) {
                var add: any;
                add = [...itemToBeListed, row];
                setItemToBeListed(add);
            } else {
                var exclude: any = [];
                for (var i: any = 0; i < itemToBeListed.length; i++) {
                    if (itemToBeListed[i].productCode !== row.productCode) {
                        exclude.push(itemToBeListed[i]);
                    }
                }

                setItemToBeListed(exclude);
            }
        },
        onSelectAll: (isSelect, rows, e) => {
            if (isSelect === true) {
                setItemToBeListed(rows);
            } else {
                setItemToBeListed([]);
            }
        }
    }

   /*  const addTyprProduct = (cell: any, row: any) => {
        if(cell === 1){
            return 'Tour';
        }else if(cell === 2){
            return 'Transfer';
        }else{
            return 'Ticket';
        }
    } */

    const columns = [
        //{ dataField: "product_code", text: "" },
        { dataField: "productName", text: "Nome do produto" }/* ,
        { dataField: "tipoProduto", text: "Tipo do produto", formatter: addTyprProduct } */
    ];


    return (
        <>
            <div>
                <Card className="p-4">
                    <Form className="h-100">
                        <div className='d-flex flex-column justify-content-between h-100'>
                            <div>
                                <Row className="mb-3">
                                    <Form.Group as={Col}>
                                        <Form.Label>Buscar pelo produto ou SKU</Form.Label>
                                        <Controller
                                            control={control}
                                            name="searchProduct"
                                            //rules={{ required: { value: true, message: "required" }, }}
                                            render={({ field }: any) => (
                                                <div className="input-select-custom">
                                                    <Form.Control
                                                        {...field}
                                                        label="product"
                                                        variant="standard"
                                                        margin="normal"
                                                        autoComplete="off"
                                                        value={filterProduct}
                                                        onChange={(e: any) => handleChangeName(e)}
                                                    />
                                                </div>
                                            )}
                                        />
                                    </Form.Group>

                                    <Form.Group as={Col}>
                                        <Form.Label>Buscar por cidade</Form.Label>
                                        <Controller
                                            control={control}
                                            name="searchCity"
                                            //rules={{ required: { value: true, message: "required" }, }}
                                            render={({ field }: any) => (
                                                <div className="input-select-custom">
                                                    <Form.Control
                                                        {...field}
                                                        label="city"
                                                        variant="standard"
                                                        margin="normal"
                                                        autoComplete="off"
                                                        value={filterCity}
                                                        onChange={(e: any) => handleChangeCity(e)}
                                                    />
                                                </div>
                                            )}
                                        />

                                    </Form.Group>
                                    {/* <Form.Group as={Col}>
                                        <Form.Label>Buscar por fornecedor</Form.Label>
                                        <Controller
                                            control={control}
                                            name="searchSupplier"
                                            //rules={{ required: { value: true, message: "required" }, }}
                                            render={({ field }: any) => (
                                                <div className="input-select-custom">
                                                    <Form.Control
                                                        {...field}
                                                        label="supplier"
                                                        variant="standard"
                                                        margin="normal"
                                                        autoComplete="off"
                                                        value={filterSupplier}
                                                        onChange={(e: any) => handleChangeSupplier(e)}
                                                        onKeyPress={(e) => !/[0-9]/.test(e.key) && e.preventDefault()}
                                                    />
                                                </div>

                                            )}
                                        />
                                    </Form.Group> */}
                                </Row>
                                <Row
                                    as={Col}
                                    md="12"
                                    className="mt-5 justify-content-md-end"
                                >
                                    <Col xs lg="12" className="d-flex justify-content-md-end">

                                        <button className="btn btn-default btn-outline-primary px-4 mr-3" type='button'
                                            onClick={clearActualValues}
                                        >
                                            {t("reservations.filter.btnClear")}
                                        </button>

                                        <button className="btn btn-default btn-primary" type='button'
                                            onClick={handleFilterClick}
                                        >
                                            {t("reservations.filter.btnSearch")}
                                        </button>
                                    </Col>
                                </Row>
                            </div>
                        </div>
                    </Form>
                </Card>

            </div>

            {/* TABELA */}
            <div className="mt-4">
                <div className="table-default LinkProductFound-table">
                    <div className="table-container">
                        <div>
                            <BootstrapTable
                                bootstrap4
                                keyField="productCode"
                                data={foundProducts}
                                columns={columns}
                                selectRow={selectRow}
                            />
                            <Pagination
                                totalRows={totalRows}
                                pageCount={pageCount}
                                setPageCount={setPageCount}
                                rowsPerPage={rowsPerPage}
                                setRowsPerPage={setRowsPerPage}
                                selectNumberRows={"yes"}
                            />
                        </div>
                        <div className="d-flex justify-content-end mt-3">
                            <div className="btn btn-default btn-primary" onClick={handleToLink}>Vincular</div>
                        </div>
                    </div>
                </div>
            </div>

        </>
    )
};

export default LinkProduct;