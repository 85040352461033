import React, { useState } from "react";
import { Container, Row, Col, Card } from 'react-bootstrap';
import Form from 'react-bootstrap/Form';
//import InputGroup from 'react-bootstrap/InputGroup';
//mport Select from 'react-select';
import Button from 'react-bootstrap/Button';
import Accordion from 'react-bootstrap/Accordion';
import { useTranslation } from "react-i18next";

import InputGroup from 'react-bootstrap/InputGroup';

import './FilterReportERPConference.scss';
//import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

//Components
import RangeCalendar from "../../../../components/Calendar/RangeCalendar";
//import { useForm } from "react-hook-form";
//import GlobalAlert from "../../../Alert/GlobalAlert/GlobalAlert";

export interface propFilter {
    exportReport: any,
    error: any,
    clearActualValues: any;
    defaultDate: any;
}

const FilterReportERPConference: React.FC<propFilter> = ({
    exportReport, error, clearActualValues, defaultDate
}) => {

    const { t } = useTranslation();

    //const [filterTypeDate, setFilterTypeDate] = useState<any>(1);
    const [filterDate, setFilterDate] = useState<any>(defaultDate);

    // const [alert, setAlert] = useState<any>();
    // const [checked, setChecked] = useState<boolean>(true);
    const [dateValidation, setDateValidation] = useState<boolean>(false);
    //const [productValidation, setProductValidation] = useState<boolean>(false);

    const handleFilterClick = (data: any) => {
        if(filterDate.length === 0) {
            setDateValidation(true);
            return;
        }

        exportReport(/*filterTypeDate,*/ filterDate);
    };

    const handleClearClick = () => {

        // let inputValue = document.querySelectorAll('input');
        // for (let i = 0; i < inputValue.length; i++) {
        //     inputValue[i].value = "";
        // }

        // let selectValue = document.querySelectorAll('select');
        // for (let i = 0; i < selectValue.length; i++) {
        //     if(selectValue[i].id === "rowsPerPage"){
        //         selectValue[i].value = "10";
        //     }else{
        //         selectValue[i].value = "";
        //     }
        // }

        //setFilterTypeDate(1);
        setFilterDate(defaultDate);
        
        clearActualValues();
    };   

    return (
            <Card className="bg-white mt-4">
                <Accordion >
                    <Card.Body>
                        <Container className="p-0" fluid>
                            <Row>
                                <div>
                                    <Form >
                                        <Row className="mb-3">
                                            {/* <Form.Group as={Col} md="2">
                                                <Form.Label>Tipo de Data</Form.Label>
                                                <div className="endpoint">
                                                    <InputGroup hasValidation>
                                                        <Form.Select
                                                            aria-describedby="inputGroupPrepend"
                                                            onChange={(e: any) => handleChangeTypeDate(e)}
                                                            value={filterTypeDate}
                                                        >
                                                            <option value="1">Operação</option>
                                                            <option value="2">Vencimento</option>
                                                            <option value="3">Emissão</option>
                                                        </Form.Select>
                                                    </InputGroup>
                                                </div>
                                            </Form.Group> */}
                                            <Form.Group as={Col} md="2">
                                                <Form.Label>Data</Form.Label>
                                                <InputGroup hasValidation className={`ranger-calendar${dateValidation === true ? " endpoint-error" : ""}`}>
                                                    <RangeCalendar date={filterDate} setDate={setFilterDate} />
                                                </InputGroup>
                                                {dateValidation === true ?
                                                    <small style={{ color: 'red' }}>campo obrigatório</small>
                                                    : <></>}
                                            </Form.Group>
                                        </Row>
                                        <Row as={Col} md="12" className="mt-5 justify-content-md-end">
                                            <Col xs lg="12" className="d-flex justify-content-md-end">
                                                <Button variant="outline-primary" className="btn-default mr-2 text-uppercase" onClick={handleClearClick}>{t('partners.partnersfilter.btnClear')}</Button>
                                                <Button className="btn-default text-uppercase" onClick={handleFilterClick}>Exportar</Button>
                                            </Col>
                                        </Row>
                                    </Form>
                                </div>
                            </Row>
                        </Container>
                    </Card.Body>
                </Accordion>
            </Card>
      
    )
}

export default FilterReportERPConference;