import React from "react";
import { Row, Card } from "react-bootstrap";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import "./InvoicedSalesCards.scss";

export interface propInsert {
  cardsInfo: any
  t?:any
}

const InvoicedSalesCards: React.FC<propInsert> = ({
  cardsInfo, t
}: propInsert) => {
  
  return (
    <>
      <div className="card-report">
        <Row className="cards">
          <div className="col-md-2 card-unit">
            <Card>
              <div className="col-md-10">
                <Row>
                  <span className="card-title">{t("invoiced.cards.creditLimit")}</span>
                </Row>
                <Row>
                  <span className="cart-content">{cardsInfo?.limiteCredito.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })}</span>
                </Row>
              </div>
              <div className="col-md-2 d-flex justify-content-center align-items-center">
              <FontAwesomeIcon
                  size="lg"
                  icon={["fad", "coins"]}
                  className="text-primary"
                  />
              </div>
            </Card>
          </div>
          <div className="col-md-2 card-unit">
            <Card>
              <div className="col-md-10">
                <Row>
                  <span className="card-title">{t("invoiced.cards.salesTotal")}</span>
                </Row>
                <Row>
                  <span className="cart-content">{cardsInfo?.totalAmount.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })}</span>
                </Row>
              </div>
              <div className="col-md-2 d-flex justify-content-center align-items-center">
              <FontAwesomeIcon
                  size="lg"
                  icon={["fad", "hand-holding-usd"]}
                  className="text-primary"
                  />
              </div>
            </Card>
          </div>
          <div className="col-md-2 card-unit">
            <Card>
              <div className="col-md-10">
                <Row>
                  <span className="card-title">{t("invoiced.cards.paidAmount")}</span>
                </Row>
                <Row>
                  <span className="cart-content">{cardsInfo?.totalPago.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })}</span>
                </Row>
              </div>
              <div className="col-md-2 d-flex justify-content-center align-items-center">
              <FontAwesomeIcon
                  size="lg"
                  icon={["fad", "receipt"]}
                  className="text-primary"
                  />
              </div>
            </Card>
          </div>
          <div className="col-md-2 card-unit">
            <Card>
              <div className="col-md-10">
                <Row>
                  <span className="card-title">{t("invoiced.cards.amountToPay")}</span>
                </Row>
                <Row>
                  <span className="cart-content">{cardsInfo?.totalPagar.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })}</span>
                </Row>
              </div>
              <div className="col-md-2 d-flex justify-content-center align-items-center">
              <FontAwesomeIcon
                  size="lg"
                  icon={["fad", "credit-card-front"]}
                  className="text-primary"
                  />
              </div>
            </Card>
          </div>
        </Row>
      </div>
    </>
  );
};

export default InvoicedSalesCards;
