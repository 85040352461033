import React from "react";
import { ErrorMessage } from "@hookform/error-message";
import { Button, Col, Form, Row } from "react-bootstrap";


import { Controller, useForm } from "react-hook-form";
import GetTime30MinInterval from "../../C2Points/GetTime30MinInterval";

export interface operation {
    startTimeFillFunction: any;
}

const FillActivityStartModal: React.FC<operation> = React.memo(({
    startTimeFillFunction
}: operation) => {

    const {
        control,
        handleSubmit,
        formState: { errors },
    } = useForm({});

    const onSubmit = (data: any) => {
        let start: number = parseInt(data.timeStart.value.split(':')[0]);
        let end: number = parseInt(data.timeEnd.value.split(':')[0]);
        var aux: any = [];
        if (end < start) {
            alert('horário inicial e final incompatíveis');
            return;
        }

        if (end === start) {
            if (parseInt(data.timeEnd.value.split(':')[1]) < parseInt(data.timeStart.value.split(':')[1])) {
                alert('horário inicial e final incompatíveis');
                return;
            }
        }

        for (var i: number = start; i <= end; i++) {
            if (data.interval === '01:00') {
                if (i !== end || parseInt(data.timeEnd.value.split(':')[1]) >= parseInt(data.timeStart.value.split(':')[1])) {
                    aux.push({ value: `${i < 10 ? "0" + i : "" + i}:${data.timeStart.value.split(':')[1]}`, label: `${i < 10 ? "0" + i : "" + i}:${data.timeStart.value.split(':')[1]}` });
                }
            } else if (data.interval === '00:30') {
                let firstStep: any = [0, 30].includes(parseInt(data.timeStart.value.split(':')[1])) ? "00" : "15";
                let secondStep: any = [0, 30].includes(parseInt(data.timeStart.value.split(':')[1])) ? "30" : "45";

                if (i !== start || parseInt(data.timeStart.value.split(':')[1]) <= 15) {
                    //na primeira iteração só adiciona com final "00" caso a data de inicio termine com "00"
                    aux.push({ value: `${i < 10 ? "0" + i : "" + i}:${firstStep}`, label: `${i < 10 ? "0" + i : "" + i}:${firstStep}` });
                }

                if (i !== end || parseInt(data.timeEnd.value.split(':')[1]) >= 30) {
                    //na última iteração só adiciona com final "30" caso a data de final termine com "30"
                    if (i !== start) {
                        aux.push({ value: `${i < 10 ? "0" + i : "" + i}:${secondStep}`, label: `${i < 10 ? "0" + i : "" + i}:${secondStep}` });
                    } else {
                        parseInt(data.timeStart.value.split(':')[1]) <= 30 && aux.push({ value: `${i < 10 ? "0" + i : "" + i}:${secondStep}`, label: `${i < 10 ? "0" + i : "" + i}:${secondStep}` });
                    }
                }
            } else {
                if (i !== start || parseInt(data.timeStart.value.split(':')[1]) === 0) {
                    //na primeira iteração só adiciona com final "00" caso a data de inicio termine com "00"
                    aux.push({ value: `${i < 10 ? "0" + i : "" + i}:00`, label: `${i < 10 ? "0" + i : "" + i}:00` });
                }

                if (i !== end || parseInt(data.timeEnd.value.split(':')[1]) === 45) {
                    if (i !== start) {
                        aux.push({ value: `${i < 10 ? "0" + i : "" + i}:15`, label: `${i < 10 ? "0" + i : "" + i}:15` });
                        aux.push({ value: `${i < 10 ? "0" + i : "" + i}:30`, label: `${i < 10 ? "0" + i : "" + i}:30` });
                        aux.push({ value: `${i < 10 ? "0" + i : "" + i}:45`, label: `${i < 10 ? "0" + i : "" + i}:45` });
                    } else {
                        parseInt(data.timeStart.value.split(':')[1]) <= 15 && aux.push({ value: `${i < 10 ? "0" + i : "" + i}:15`, label: `${i < 10 ? "0" + i : "" + i}:15` });
                        parseInt(data.timeStart.value.split(':')[1]) <= 30 && aux.push({ value: `${i < 10 ? "0" + i : "" + i}:30`, label: `${i < 10 ? "0" + i : "" + i}:30` });
                        parseInt(data.timeStart.value.split(':')[1]) <= 45 && aux.push({ value: `${i < 10 ? "0" + i : "" + i}:45`, label: `${i < 10 ? "0" + i : "" + i}:45` });
                    }
                } else if (i !== end || parseInt(data.timeEnd.value.split(':')[1]) === 30) {
                    aux.push({ value: `${i < 10 ? "0" + i : "" + i}:15`, label: `${i < 10 ? "0" + i : "" + i}:15` });
                    aux.push({ value: `${i < 10 ? "0" + i : "" + i}:30`, label: `${i < 10 ? "0" + i : "" + i}:30` });
                } else if (i !== end || parseInt(data.timeEnd.value.split(':')[1]) === 15) {
                    aux.push({ value: `${i < 10 ? "0" + i : "" + i}:15`, label: `${i < 10 ? "0" + i : "" + i}:15` });
                }
            }
        }

        startTimeFillFunction(aux)
    }

    return (
        <>
            <Row>
                <h4>Início da atividade</h4>
            </Row>
            <Form
                className="h-100"
                noValidate
                validated={false}
                onSubmit={handleSubmit(onSubmit)}
            >
                <Row>
                    <Form.Group as={Col} md="4" className="mt-4">
                        <Form.Label className="label-small" style={{ width: '100%', textAlign: 'left' }}>
                            Hora de início
                        </Form.Label>

                        <Controller
                            control={control}
                            name="timeStart"
                            rules={{
                                required: {
                                    value: true,
                                    message: "Por favor, informe um horário de início",
                                },
                            }}
                            render={({ field }: any) => (
                                <div className="input-text-custom endpoint">
                                    <GetTime30MinInterval
                                        propsField={field}
                                        propsLabel="activityStart"
                                        add15Interval={true}
                                        isMult={false}
                                    />
                                </div>
                            )}
                        />
                        <ErrorMessage
                            errors={errors}
                            name="timeStart"
                            render={({ message }) => (
                                <small style={{ color: "red" }}>{message}</small>
                            )}
                        />
                    </Form.Group>

                    <Form.Group as={Col} md="4" className="mt-4">
                        <Form.Label className="label-small" style={{ width: '100%', textAlign: 'left' }}>
                            Último horário
                        </Form.Label>

                        <Controller
                            control={control}
                            name="timeEnd"
                            rules={{
                                required: {
                                    value: true,
                                    message: "Por favor, informe um horário final",
                                },
                            }}
                            render={({ field }: any) => (
                                <div className="input-text-custom endpoint">
                                    <GetTime30MinInterval
                                        propsField={field}
                                        propsLabel="activityStart"
                                        add15Interval={true}
                                        isMult={false}
                                    />
                                </div>
                            )}
                        />
                        <ErrorMessage
                            errors={errors}
                            name="timeEnd"
                            render={({ message }) => (
                                <small style={{ color: "red" }}>{message}</small>
                            )}
                        />
                    </Form.Group>

                    <Form.Group as={Col} md="4" className="mt-4">
                        <Form.Label className="label-small" style={{ width: '100%', textAlign: 'left' }}>
                            Intervalo
                        </Form.Label>
                        <Controller
                            control={control}
                            name="interval"
                            rules={{ required: { value: true, message: "Por favor, informe um intervalo" } }}
                            render={({ field }: any) => (
                                <div className="endpoint">
                                    <Form.Select
                                        {...field}
                                        as="select"
                                        variant="standard"
                                        margin="normal"
                                        required
                                    >
                                        <option value="">Selecione</option>
                                        <option value="01:00">01:00</option>
                                        <option value="00:30">00:30</option>
                                        <option value="00:15">00:15</option>

                                    </Form.Select>
                                </div>
                            )}
                        />
                        <ErrorMessage
                            errors={errors}
                            name="interval"
                            render={({ message }) => (
                                <small style={{ color: "red" }}>{message}</small>
                            )}
                        />
                    </Form.Group>
                </Row>

                <div className="w-100 d-flex justify-content-end mt-3">
                    <Button type="submit" className="form-button">Preencher</Button>
                </div>
            </Form>
        </>
    );
}
);

export default FillActivityStartModal;
