import React, { useEffect, useState } from "react";

import { useForm } from "react-hook-form";
import { Link } from "react-router-dom";

import Breadcrumb from "../../components/Breadcrumb/Breadcrumb";

import api from "../../services/api";
import DesktopDefault from "../../templates/DesktopDefault";


import ResourceTypeFilter from "./components/ResourceTypeFilter/ResourceTypeFilter";
import ResourceTypeTable from "./components/ResourceTypeTable/ResourceTypeTable";

function ResourceType() {


  const [resourceType, setResourceType] = useState<any>([]);
  //const [error, setError] = useState<any>();

  /* Filtragem */
  const [, setActualName] = useState<any>("");
  /* END - Filtragem */

  //loading
  const [loading, setLoading] = useState<any>(false);
  //loading

  /* Pagination */
  const [totalRows, setTotalRows] = useState<any>();
  const [pageCount, setPageCount] = useState<any>(1);
  const [rowsPerPage, setRowsPerPage] = useState<any>(10);
  /* END - Pagination */

 



  useEffect(() => {
    setLoading(true);
    setResourceType(null);

    const token = localStorage.getItem("GroupId") || "{}";
    const config = {
      headers: { Authorization: `Bearer ${token}` },
    };
    async function listResourcesType() {
      try {
        const { data } = await api.post(
          "Resources/GetResourcesTypeByFilterAsync",
          {
            page: pageCount,
            rowsPerPage: rowsPerPage,
            resourceTypeNameFind: "",
            orderByField: ""
          },
          config
        );

        if (data.status !== 400) {
          setLoading(false);
          setResourceType(data.data.data.rows);
          setTotalRows(data.data.data.rowsCount);
        }
      } catch (error: any) {
        setLoading(false);
        // if (error.response.status === 401) {
        //   window.location.href = window.location.origin + "/";
        // }
      }
    }
    listResourcesType();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pageCount, rowsPerPage]);

  function clearActualValues() {
    setActualName("");

  }

  async function buscar(name: any, type: any, vehicle: any) {
    setResourceType(null);
    setActualName(name);
    setPageCount(1);

    const token = localStorage.getItem('GroupId') || '{}';
    const config = {
      headers: { 'Authorization': `Bearer ${token}` },
    };
    try {
      const res = await api.post('Resources/GetResourcesTypeByFilterAsync',
        {
          page: pageCount,
          rowsPerPage: rowsPerPage,
          resourceTypeNameFind: name,
          orderByField: ""
        }
        , config
      );

      if (res.status !== 400) {
        setLoading(false);
        setResourceType(res.data.data.data.rows);
        setTotalRows(res.data.data.data.rowsCount);
      }
    } catch (error: any) {
      setLoading(false);
      //   if (error.response.status === 401) {
      //     window.location.href = window.location.origin + '/';
      //   }

    }
  };









  async function exportResourceType() {
    console.log("exportando")

    try {
      const exp = await api.post('/ResourceType/ExportReport',
        {
          "orderByField": ""
          //"resourceTypeNameFind": ""
          //"page": pageCount,
          //"rowsPerPage": 0
          // "resourceTypeName",
          // "vehicle":,
          // "driver": ,
          // "guide": ,
          // "ticket": ,
          // "restaurant": ,
          // "people": ,
          // "chd"
        },
        {
          headers: {
            "Content-Type": "application/json",
            "Accept": "*/*"
            //"Access-Control-Allow-Origin": "*"

          },
          responseType: 'blob'
        },

      );
      if (exp.status !== 400) {
        console.log(exp)
        const blob = new Blob([exp.data], { type: exp.headers["Content-Type"] });
        const objectUrl = window.URL.createObjectURL(blob);

        const link = document.createElement('a');
        link.href = objectUrl;
        link.setAttribute(
          'download',
          'tipo_de_resource.xlsx',
        );

        // Append to html link element page
        document.body.appendChild(link);

        // Start download
        link.click();

        // // Clean up and remove the link
        link?.parentNode?.removeChild(link);
      }
    } catch (error: any) {
      if (error?.response?.status === 401) {
        window.location.href = window.location.origin + '/';
      }
    }
  };

const {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  control,
  //handleSubmit,
  //setValue,
} = useForm({});

  return (
    <>
      <DesktopDefault>
        <div className="container-fluid content-dashboard">
          <Breadcrumb title={"Produtos / Tipo de Resource"} />

          <div className="mt-4 d-flex justify-content-between align-items-center">
            <h2 className="title" style={{ color: "#707070" }}>
              Tipo Resource{""}
            </h2>

            <Link to={"/add-resource-type"} className="btn btn-primary">Novo Tipo</Link>

          </div>
          <ResourceTypeFilter
            buscar={buscar}
            resourceType={resourceType}
            clearActualValues={clearActualValues}
          />

          <ResourceTypeTable
            resourceType={resourceType}
            loading={loading}
            totalRows={totalRows}
            pageCount={pageCount}
            setPageCount={setPageCount}
            rowsPerPage={rowsPerPage}
            setRowsPerPage={setRowsPerPage}
            exportResourceType={exportResourceType}
          />

        </div>
      </DesktopDefault>
    </>
  )
}

export default ResourceType;