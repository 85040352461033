import React, { useEffect, useState } from 'react';
import api from '../../services/api';

import './SessionlessOrders.scss'

//COMPONENTS
import Breadcrumb from '../../components/Breadcrumb/Breadcrumb';
import FilterSessionlessOrders from './components/FilterSessionlessOrders/FilterSessionlessOrders';
import TableSessionlessOrders from './components/TableSessionlessOrders/TableSessionlessOrders';
import DesktopDefault from '../../templates/DesktopDefault';


function SessionlessOrders() {
    //const { t } = useTranslation();
    const [bills, setBills] = useState<any>(null);
    //const [error, setError] = useState<any>();

    const today = new Date();
    const todayStr = `${today.getDate()}/${today.getMonth() + 1}/${today.getFullYear()}`;

    /* Pagination */
    const [totalRows, setTotalRows] = useState<any>();
    const [pageCount, setPageCount] = useState<any>(1);
    const [rowsPerPage, setRowsPerPage] = useState<any>(10);
    /* END - Pagination */

    /* Filter */
    const [actualLocalizador, setActualLocalizador] = useState<any>("");
    const [actualTypeDate, setActualTypeDate] = useState<any>(2);
    const [actualDate, setActualDate] = useState<any>([todayStr]);
    /* END - Filter */
    const [loading, setLoading] = useState<any>(false);

    useEffect(() => {

        let body: any = document.querySelector("body");
        if (body.className !== "modal-open") {
            setBills(null);
        }
        let beginDate: any = []
        let endDate: any = []

        if (actualDate.length === 1) {
            beginDate = actualDate[0].split('/')
        } else if (actualDate.length === 2) {
            beginDate = actualDate[0].split('/')
            endDate = actualDate[1].split('/')
        }

        let today: any = new Date();
        
        const token = localStorage.getItem('GroupId') || '{}';
        const config = {
            headers: { 'Authorization': `Bearer ${token}` },
        };

        async function listSuppliers() {
            try {
                const { data } = await api.post('SessionClosure/GetSessionlessOrdersAsync',
                    {
                        "page": pageCount,
                        "rowsPerPage": rowsPerPage,
                        "item": actualLocalizador ? actualLocalizador : '',
                        "tipoData": actualTypeDate ? parseInt(actualTypeDate) : 2,
                        "dataInicial": (actualDate.length > 0) ? `${beginDate[2]}-${beginDate[1]}-${beginDate[0]}` : `${today.getFullYear()}-${today.getMonth() + 1}-${today.getDate()}`,
                        "dataFinal": (actualDate.length === 1) ? `${beginDate[2]}-${beginDate[1]}-${parseInt(beginDate[0])}` : (actualDate !== null && actualDate.length === 2) ? `${endDate[2]}-${endDate[1]}-${endDate[0]}` : `${today.getFullYear()}-${today.getMonth() + 1}-${today.getDate()}`
                    }, config
                );

                if (data.status !== 400) {
                    const dados = data.data;

                    setBills(dados.rows);
                    setTotalRows(dados.rowsCount);
                }
            } catch (error: any) {
                if (error.response.status === 401) {
                    window.location.href = window.location.origin + '/';
                }
            }
        }
        listSuppliers();

        // eslint-disable-next-line
    }, [pageCount, rowsPerPage]);

    function download(file: any, filetype: any, filename: any) { // file: bytes/string, name: string
        //const blob = new Blob([file], { type: filetype });
        const blob = `data:${filetype};base64,${file}`;
//console.log(blob);
        //if(navigator.msSaveBlob){ // For ie and Edge
        //  return navigator.msSaveBlob(blob, filename);
        //}
        //else{
          let link = document.createElement('a');
          link.href = blob;//window.URL.createObjectURL(blob);
          link.download = filename;
          document.body.appendChild(link);
          link.dispatchEvent(new MouseEvent('click', {bubbles: true, cancelable: true, view: window}));
          link.remove();
          window.URL.revokeObjectURL(link.href);
        //}
    };

    async function refreshData() {
        let body: any = document.querySelector("body");
        if (body.className !== "modal-open") {
            setBills(null);
        }
        let beginDate: any = []
        let endDate: any = []

        if (actualDate.length === 1) {
            beginDate = actualDate[0].split('/')
        } else if (actualDate.length === 2) {
            beginDate = actualDate[0].split('/')
            endDate = actualDate[1].split('/')
        }

        let today: any = new Date();

        const token = localStorage.getItem('GroupId') || '{}';
        const config = {
            headers: { 'Authorization': `Bearer ${token}` },
        };

        setLoading(true);
        try {
            const { data } = await api.post('SessionClosure/GetSessionlessOrdersAsync',
                {
                    "page": pageCount,
                    "rowsPerPage": rowsPerPage,
                    "item": actualLocalizador ? actualLocalizador : '',
                    "tipoData": actualTypeDate ? parseInt(actualTypeDate) : 2,
                    "dataInicial": (actualDate.length > 0) ? `${beginDate[2]}-${beginDate[1]}-${beginDate[0]}` : `${today.getFullYear()}-${today.getMonth() + 1}-${today.getDate()}`,
                    "dataFinal": (actualDate.length === 1) ? `${beginDate[2]}-${beginDate[1]}-${parseInt(beginDate[0])}` : (actualDate !== null && actualDate.length === 2) ? `${endDate[2]}-${endDate[1]}-${endDate[0]}` : `${today.getFullYear()}-${today.getMonth() + 1}-${today.getDate()}`
                }, config
            );

            if (data.status !== 400) {
                setBills(data.data.rows);
                setTotalRows(data.data.rowsCount);
            }
        } catch (error: any) {
            if (error.response.status === 401) {
                window.location.href = window.location.origin + '/';
            }
        }
        setLoading(false);
    }

    async function buscar(
        localizador: any,
        typeDate: any,
        date: any,
    ) {
        setActualLocalizador(localizador);
        setActualTypeDate(typeDate);
        setActualDate(date);
        setPageCount(1)

        let body: any = document.querySelector("body");
        if (body.className !== "modal-open") {
            setBills(null);
        }
        let beginDate: any = []
        let endDate: any = []

        if (date.length === 1) {
            beginDate = date[0].split('/')
        } else if (date.length === 2) {
            beginDate = date[0].split('/')
            endDate = date[1].split('/')
        }

        let today: any = new Date();

        const token = localStorage.getItem('GroupId') || '{}';
        const config = {
            headers: { 'Authorization': `Bearer ${token}` },
        };

        setLoading(true);
        try {
            const { data } = await api.post('SessionClosure/GetSessionlessOrdersAsync',
                {
                    "page": pageCount,
                    "rowsPerPage": rowsPerPage,
                    "item": localizador ? localizador : '',
                    "tipoData": typeDate ? parseInt(typeDate) : 2,
                    "dataInicial": (date.length > 0) ? `${beginDate[2]}-${beginDate[1]}-${beginDate[0]}` : `${today.getFullYear()}-${today.getMonth() + 1}-${today.getDate()}`,
                    "dataFinal": (date.length === 1) ? `${beginDate[2]}-${beginDate[1]}-${parseInt(beginDate[0])}` : (date !== null && date.length === 2) ? `${endDate[2]}-${endDate[1]}-${endDate[0]}` : `${today.getFullYear()}-${today.getMonth() + 1}-${today.getDate()}`
                }, config
            );

            if (data.status !== 400) {
                setBills(data.data.rows);
                setTotalRows(data.data.rowsCount);
            }
        } catch (error: any) {
            if (error.response.status === 401) {
                window.location.href = window.location.origin + '/';
            }
        }
        setLoading(false);
    }

    async function exportReport() {
        let beginDate: any = []
        let endDate: any = []

        if (actualDate.length === 1) {
            beginDate = actualDate[0].split('/')
        } else if (actualDate.length === 2) {
            beginDate = actualDate[0].split('/')
            endDate = actualDate[1].split('/')
        }

        let today: any = new Date();

        const token = localStorage.getItem('GroupId') || '{}';
        const config = {
            headers: { 'Authorization': `Bearer ${token}` },
        };

        try {
            const res = await api.post('SessionClosure/ExportSessionBillsToReceive',
                {
                    "page": 1,
                    "rowsPerPage": 0,
                    "item": actualLocalizador ? actualLocalizador : '',
                    "dataInicial": (actualDate.length > 0) ? `${beginDate[2]}-${beginDate[1]}-${beginDate[0]}` : `${today.getFullYear()}-${today.getMonth() + 1}-${today.getDate()}`,
                    "dataFinal": (actualDate.length === 1) ? `${beginDate[2]}-${beginDate[1]}-${parseInt(beginDate[0])}` : (actualDate !== null && actualDate.length === 2) ? `${endDate[2]}-${endDate[1]}-${endDate[0]}` : `${today.getFullYear()}-${today.getMonth() + 1}-${today.getDate()}`
                }, config
            );
            if (res.status !== 400) {
                //download file
                const dados = res.data.data;
                download(dados.file, dados.fileType, dados.fileName);
            }
        } catch (error: any) {
            //setError(true)
            if (error?.response?.status === 401) {
                window.location.href = window.location.origin + '/';
            }
        }
    };

    async function printReport() {
        let beginDate: any = []
        let endDate: any = []

        if (actualDate.length === 1) {
            beginDate = actualDate[0].split('/')
        } else if (actualDate.length === 2) {
            beginDate = actualDate[0].split('/')
            endDate = actualDate[1].split('/')
        }

        let today: any = new Date();

        const token = localStorage.getItem('GroupId') || '{}';
        const config = {
            headers: { 'Authorization': `Bearer ${token}` },
        };

        try {
            const res = await api.post('SessionClosure/PrintSessionBillsToReceive',
                {
                    "page": 1,
                    "rowsPerPage": 0,
                    "item": actualLocalizador ? actualLocalizador : '',
                    "dataInicial": (actualDate.length > 0) ? `${beginDate[2]}-${beginDate[1]}-${beginDate[0]}` : `${today.getFullYear()}-${today.getMonth() + 1}-${today.getDate()}`,
                    "dataFinal": (actualDate.length === 1) ? `${beginDate[2]}-${beginDate[1]}-${parseInt(beginDate[0])}` : (actualDate !== null && actualDate.length === 2) ? `${endDate[2]}-${endDate[1]}-${endDate[0]}` : `${today.getFullYear()}-${today.getMonth() + 1}-${today.getDate()}`
                }, config
            );
            if (res.status !== 400) {
                //download file
                //console.log(res.data.data);
                const dados = res.data.data;
                download(dados.file, dados.fileType, dados.fileName);
            }
        } catch (error: any) {
            //setError(true)
            if (error?.response?.status === 401) {
                window.location.href = window.location.origin + '/';
            }
        }
    };


    return (
        <>
            <DesktopDefault>
                    <div className="container-fluid content-dashboard">
                        <Breadcrumb title={'Operações / Reservas sem session'} />
                        <div className="suppliers mt-4 d-flex justify-content-between align-items-center">
                            <h2 className="title" style={{ color: "#707070" }}>Reservas sem session</h2>
                        </div>
                        <FilterSessionlessOrders
                            buscar={buscar}
                            billsReceive={bills}
                        />
                        <TableSessionlessOrders
                            bills={bills}
                            totalRows={totalRows}
                            pageCount={pageCount}
                            setPageCount={setPageCount}
                            rowsPerPage={rowsPerPage}
                            setRowsPerPage={setRowsPerPage}
                            exportReport={exportReport}
                            printReport={printReport}
                            refreshData={refreshData}
                            loading={loading}
                        />
                    </div>
            </DesktopDefault>
        </>
    );
}

export default SessionlessOrders;