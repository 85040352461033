import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

export interface propPlus {
    tourDetails: any,

};

const RefundRules: React.FC<propPlus> = ({
    tourDetails
}: propPlus) => {
    const { t } = useTranslation();

    const [refundText, setRefundText] = useState<any>("");

    useEffect(() => {
        let aux: any = [];

        tourDetails?.refundPolicies?.forEach((item: any, i: any) => {
            if (item?.rules === 1) {
                aux.push(`${t("sales.refundRules.text01")}`);
            } else if (item?.rules === 2) {
                aux.push(`${t("sales.refundRules.text02")}`);
            } else {
                if (item?.percentage === 0) {
                    aux.push(`${t("sales.refundRules.text03")} ${item?.priorDays * 24} h${t("sales.refundRules.text04")}`);
                } else {
                    aux.push(`${t("sales.refundRules.text03")} ${item?.priorDays * 24} ${t("sales.refundRules.text05")} ${item?.percentage}${t("sales.refundRules.text06")}`);
                }
            }
        })

        setRefundText(aux);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [tourDetails]);

    if (tourDetails !== null && refundText?.length >= 1) {
        return (
            <ul>
                <h6>{t('sales.refundRules.title')}</h6>
                <li>{refundText}</li>
            </ul>
        )
    } else {
        return (
            <></>
        )
    }
}

export default RefundRules;