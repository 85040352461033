import React, { useState } from 'react';
import api from '../../services/api';

import DesktopDefault from '../../templates/DesktopDefault';

import './MarketingSalesReport.scss'

//COMPONENTS
import Breadcrumb from '../../components/Breadcrumb/Breadcrumb';
import FilterMarketingSalesReport from './components/FilterMarketingSalesReport/FilterMarketingSalesReport';
import ModalShow from '../../components/Modal/ModalShow/ModalShow';

function MarketingSalesReport() {

    /* ModalControl */
    const [modalShow, setModalShow] = useState<boolean>(false);
    const [modalMessage, setModalMessage] = useState<string>("");
    const [modalLog, setModalLog] = useState<any>(null);

    async function buscar(date: any, typeDate: any) {
        setModalLog(null);
        setModalMessage("Carregando");
        setModalShow(true);

        const token = localStorage.getItem('GroupId') || '{}';
        const config = {
            headers: { 'Authorization': `Bearer ${token}` },
        };

        let beginDate: string = '';
        let endDate: string = '';

        if (date.length === 1) {
            beginDate = date[0].split('/').reverse().join('-') + "T00:00:00";
            endDate = date[0].split('/').reverse().join('-') + "T23:59:59";
        } else if (date.length === 2) {
            beginDate = date[0].split('/').reverse().join('-') + "T00:00:00";
            endDate = date[1].split('/').reverse().join('-') + "T23:59:59";
        }

        try {
            const res = await api.get(`Report/GenerateMarketingSalesTAH?dateIni=${beginDate}&dateFim=${endDate}&type=${typeDate}`, config);

            if (res.status !== 400) {
                setModalLog(res.data.log);
                setModalMessage(res.data.texto);
            } else {
                console.log(res)
                setModalLog(1);
                setModalMessage("Erro ao processar operação");
            }
        } catch (error: any) {
            setModalLog(1);
            setModalMessage("Erro ao processar operação");
        }
    };

    return (
        <>
            <DesktopDefault>
                <div className="container-fluid content-dashboard export-erp">
                    <Breadcrumb title={'Vendas / Relatório de Marketing'} />
                    <div className="suppliers mt-4 d-flex justify-content-between align-items-center">
                        <h2 className="title" style={{ color: "#707070" }}>Relatório de Marketing</h2>
                    </div>
                    <small>*Relatório será enviado para o email do usuario que está conectado.</small>
                    <FilterMarketingSalesReport
                        buscar={buscar}
                    />
                </div>
                <ModalShow
                    modalShow={modalShow}
                    setModalShow={setModalShow}
                    modalMessage={modalMessage}
                    setModalMessage={setModalMessage}
                    modalLog={modalLog}
                    setModalLog={setModalLog}
                />
            </DesktopDefault>
        </>
    );

}

export default MarketingSalesReport;