import React, { useState, useEffect, useRef } from "react";

import "../DropdownNumberPeople/DropdownNumberPeople.scss";
import { useTranslation } from "react-i18next";

export interface propsR {
    index: any;
    setValue: any;
}

const DropdownNumberLuggage: React.FC<propsR> = ({ index, setValue }: propsR) => {
    const { t } = useTranslation();
    const [numberLuggage, setNumberLuggage] = useState<number>(0);
    const [numberHandLuggage, setNumberHandLuggage] = useState<number>(0);
    const [numberTotal, setNumberTotal] = useState<number>(0);

    const [isOpen, setIsOpen] = useState<any>(false);

    /* Detecta clique fora da div#wrapper para fechar*/
    const wrapperRef = useRef(null);
    useOutsideAlerter(wrapperRef);

    function useOutsideAlerter(ref: any) {
        useEffect(() => {
            function handleClickOutside(event: any) {
                if (ref.current && !ref.current.contains(event.target)) {
                    setIsOpen(false);
                }
            }
            document.addEventListener("mousedown", handleClickOutside);
            return () => {
                document.removeEventListener("mousedown", handleClickOutside);
            };
        }, [ref]);
    }

    /* //Detecta clique fora da div#wrapper para fechar\\ */

    useEffect(() => {
        setNumberTotal(numberLuggage + numberHandLuggage);
    }, [numberLuggage, numberHandLuggage]);

    const handleClick = () => {
        isOpen === false ? setIsOpen(true) : setIsOpen(false);
    };

    const handleClickLuggage = (controll: string) => {
        let newNumber = numberLuggage;
        if (controll === "sub") {
            if (numberLuggage === 0) {
                return false;
            } else {
                newNumber = numberLuggage - 1;
            }
        }
        if (controll === "plus") {
            newNumber = numberLuggage + 1;
        }
        return setNumberLuggage(newNumber);
    };

    const handleClickHandLuggage = (controll: string) => {
        let newNumber = numberHandLuggage;
        if (controll === "sub") {
            if (numberHandLuggage === 0) {
                return false;
            } else {
                newNumber = numberHandLuggage - 1;
            }
        }
        if (controll === "plus") {
            newNumber = numberHandLuggage + 1;
        }
        return setNumberHandLuggage(newNumber);
    };

    useEffect(()=>{
        setValue(`dados[${index}].luggage`, {
            "luggage": numberLuggage,
            "handLuggage": numberHandLuggage
        });
    },[setValue, index, numberLuggage, numberHandLuggage, numberTotal])

    const RenderPeople = () => {
            return (
                <>
                    <div className="qtyButtons">
                        <label>
                        {t("checkout.cartCheckout.dropdownLuggage.suitcase")}
                        </label>
                        <button type="button" className="qtyDec" onClick={() => handleClickLuggage("sub")}>
                            -
                        </button>
                        <input
                            type="text"
                            name="qtyInput"
                            value={numberLuggage}
                            autoComplete='off'
                        />
                        <button type="button" className="qtyInc" onClick={() => handleClickLuggage("plus")}>
                            +
                        </button>
                    </div>
                    <div className="qtyButtons">
                        <label>
                        {t("checkout.cartCheckout.dropdownLuggage.handbag")}
                        </label>
                        <button type="button" className="qtyDec" onClick={() => handleClickHandLuggage("sub")}>
                            -
                        </button>
                        <input
                            type="text"
                            name="qtyInput"
                            value={numberHandLuggage}
                            autoComplete='off'
                        />
                        <button type="button" className="qtyInc" onClick={() => handleClickHandLuggage("plus")}>
                            +
                        </button>
                    </div>
                </>
            );
    };

    if (isOpen === true) {
        return (
            <div id="wrapper" ref={wrapperRef}>
                <div style={{ cursor: "pointer", color: "#666" }} onClick={() => handleClick()}>
                    {t("checkout.cartCheckout.suitcases")}{" "}
                    <span className="qtyTotal" data-min="2" data-max="19">
                        {numberTotal}
                    </span>
                </div>
                <div className="panel-dropdown-content right active">
                    <RenderPeople />
                    <p id="alertNumber"></p>
                </div>
            </div>
        );
    } else {
        return (
            <div style={{ cursor: "pointer", color: "#666" }} onClick={() => handleClick()}>
                {t("checkout.cartCheckout.suitcases")}{" "}
                <span className="qtyTotal" data-min="2" data-max="19">
                    {numberTotal}
                </span>
            </div>
        );
    }
};

export default DropdownNumberLuggage;
