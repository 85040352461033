import React, { Key, useEffect, useState } from "react";
//import Table from "react-bootstrap/Table";
//import Button from "react-bootstrap/Button";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
//import Dropdown from 'react-bootstrap/Dropdown';
//import { useTranslation } from 'react-i18next';

//import Modal from "react-bootstrap/Modal";

//STYLES
import "./SupplierTariffBlocks.scss";

//JSON
// import objectSuppliers from "../SuppliersTableCompany/locales/suppliers.json";
//import ModalDefault from "../../../../components/Modal/ModalDefault/ModalDefault";
//import FormAddPosDevice from "../../../../components/Forms/FormAddPosDevice/FormAddPosDevice";
import { Col, Form, Row } from "react-bootstrap";
//import SingleCalendarEditable from "../../../../components/Calendar/SingleCalendarEditable";
import { Controller } from "react-hook-form";
import { ErrorMessage } from "@hookform/error-message";
import SupplierTariffItems from "../SupplierTariffItems/SupplierTariffItems";


export interface propModal {
  action: any
  info: any
  control: any
  errors: any
  tariffType: any
  blockBlocked: any
  setItemsDeleted: any
};

const SupplierTariffBlocks: React.FC<propModal> = ({
  action, info, control, errors, tariffType, blockBlocked, setItemsDeleted
}: propModal) => {
  //const { t } = useTranslation();

  /* const formatDate = (data: any) => {
    if (!data) return '';

    //2022-05-05T00:00:00
    var aux = data.split('T');
    aux = aux[0].split('-');

    return (`${aux[2]}/${aux[1]}/${aux[0]}`);
  } */

  // const suppliers = objectSuppliers.suppliers.default[0];
  const [b, setB] = useState<any>(1);

  const [arrayDueStart, setArrayDueStart] = useState<any>(info ? info.map((obj: any) => obj.validadeInicio?.substring(0, 10)) : ['']);
  const [arrayDueEnd, setArrayDueEnd] = useState<any>(info ? info.map((obj: any) => obj.validadeFim?.substring(0, 10)) : ['']);
  const [arrayUniqueDiff, setArrayUniqueDiff] = useState<any>(info ? info.map((obj: any) => `${obj.unicoDiferenciado}`) : ['']);
  const [arrayByWeekDay, setArrayByWeekDay] = useState<any>(info ? info.map((obj: any) => `${obj.porDiaSemana}`) : ['']);
  const [arrayByPax, setArrayByPax] = useState<any>(info ? info.map((obj: any) => `${obj.porPax}`) : ['']);
  const [arrayValue, setArrayValue] = useState<any>(info ? info.map((obj: any) => obj.valor) : ['']);
  const [arrayItems, setArrayItems] = useState<any>(info ? info.map((obj: any) => obj.items) : [[]]);

  const getItems = (objPosDevices: any, index: any, deletedObjects: any) => {

    handleChangeItems(objPosDevices, index);

    setItemsDeleted(deletedObjects);
  }

  const [controlObject, setControlObject] = useState<any>(info ? info.map((obj: any) => obj.id) : [-1]);
  const [deletedObjects, setDeletedObjects] = useState<any>([]);

  /* const handleActionModal = (objectModal: any) => {
    setControlObject([...controlObject, objectModal],);
  } */

  function refreshBlocks() {
    const blocks: any = [];

    for (let i: any = 0; i < controlObject.length; i++) {
      const block = {
        id: controlObject[i],
        validadeInicio: arrayDueStart[i],
        validadeFim: arrayDueEnd[i],
        unicoDiferenciado: arrayUniqueDiff[i],
        porDiaSemana: arrayByWeekDay[i],
        porPax: arrayByPax[i],
        valor: arrayValue[i] ? arrayValue[i] : null,
        items: arrayItems[i]
      };
      blocks.push(block);
    }

    action(blocks, deletedObjects);
  }

  function handleChangeDueStart(dueStart: any, index: any) {
    let auxCoast: any = arrayDueStart;
    for (let i: any = 0; i < arrayDueStart.length; i++) {
      if (i === index) {
        auxCoast[i] = dueStart;
      }
    }
    setArrayDueStart(auxCoast);
    //console.log(auxCoast);
    refreshBlocks();
  }
  function handleChangeDueEnd(dueEnd: any, index: any) {
    let auxCoast: any = arrayDueEnd;
    for (let i: any = 0; i < arrayDueEnd.length; i++) {
      if (i === index) {
        auxCoast[i] = dueEnd;
      }
    }
    setArrayDueEnd(auxCoast);
    //console.log(auxCoast);
    refreshBlocks();
  }
  function handleChangeUniqueDiff(uniqueDiff: any, index: any) {
    let auxCoast: any = arrayUniqueDiff;
    for (let i: any = 0; i < arrayUniqueDiff.length; i++) {
      if (i === index) {
        auxCoast[i] = uniqueDiff;
      }
    }
    setArrayUniqueDiff(auxCoast);
    //console.log(auxCoast);
    setB(b + 1);
    refreshBlocks();
  }
  function handleChangeByWeekDay(byWeekDay: any, index: any) {
    let auxCoast: any = arrayByWeekDay;
    for (let i: any = 0; i < arrayByWeekDay.length; i++) {
      if (i === index) {
        auxCoast[i] = byWeekDay;
      }
    }
    setArrayByWeekDay(auxCoast);
    //console.log(auxCoast);
    refreshBlocks();
  }
  function handleChangeByPax(byPax: any, index: any) {
    let auxCoast: any = arrayByPax;
    for (let i: any = 0; i < arrayByPax.length; i++) {
      if (i === index) {
        auxCoast[i] = byPax;
      }
    }
    setArrayByPax(auxCoast);
    //console.log(auxCoast);
    refreshBlocks();
  }
  function handleChangeValue(value: any, index: any) {
    let auxCoast: any = arrayValue;
    for (let i: any = 0; i < arrayValue.length; i++) {
      if (i === index) {
        auxCoast[i] = value;
      }
    }
    setArrayValue(auxCoast);
    //console.log(auxCoast);
    refreshBlocks();
  }

  function handleChangeItems(items: any, index: any) {
    let auxCoast: any = arrayItems;
    for (let i: any = 0; i < arrayItems.length; i++) {
      if (i === index) {
        auxCoast[i] = items;
      }
    }
    setArrayItems(auxCoast);
    //console.log(auxCoast);
    refreshBlocks();
  }

  useEffect(() => {
    refreshBlocks();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [controlObject, deletedObjects])


  function removeRow(index: any) {

    //console.log(controlObject)

    var aux: any = [];
    var auxDueStart: any = [];
    var auxDueEnd: any = [];
    var auxUniqueDiff: any = [];
    var auxByWeekDay: any = [];
    var auxByPax: any = [];
    var auxValue: any = [];
    var auxItems: any = [];

    for (var i: any = 0; i < controlObject.length; i++) {
      if (i !== index) {
        aux.push(controlObject[i]);
        auxDueStart.push(arrayDueStart[i]);
        auxDueEnd.push(arrayDueEnd[i]);
        auxUniqueDiff.push(arrayUniqueDiff[i]);
        auxByWeekDay.push(arrayByWeekDay[i]);
        auxByPax.push(arrayByPax[i]);
        auxValue.push(arrayValue[i]);
        auxItems.push(arrayItems[i]);
      } else {
        if (controlObject[i] > 0) setDeletedObjects([...deletedObjects, controlObject[i]]);
      }
    }

    setArrayDueStart(auxDueStart);
    setArrayDueEnd(auxDueEnd);
    setArrayUniqueDiff(auxUniqueDiff);
    setArrayByWeekDay(auxByWeekDay);
    setArrayByPax(auxByPax);
    setArrayValue(auxValue);
    setArrayItems(auxItems);
    setControlObject(aux);
  }



  const addRow = () => {
    if (blockBlocked === false) {
      setArrayDueStart([...arrayDueStart, '']);
      setArrayDueEnd([...arrayDueEnd, '']);
      setArrayUniqueDiff([...arrayUniqueDiff, '']);
      setArrayByWeekDay([...arrayByWeekDay, '']);
      setArrayByPax([...arrayByPax, '']);
      setArrayValue([...arrayValue, '']);
      setArrayItems([...arrayValue, []]);
      setControlObject([...controlObject, -1]);
    }
  };

  return (
    <>
      <Form.Group as={Col} md={12}>
        {
          controlObject.map((obj: any, index: Key) => {
            return (
              <>
                <Form.Group as={Col} md={12} className="mb-3" controlId={`block${index}`}>
                  <Row>
                    <Form.Group as={Col} md={2} className="mb-3" controlId={`validadeInicioGroup${index}`}>
                      <Form.Label>Validade Inicio</Form.Label>
                      {/* <InputGroup
                        hasValidation
                        className={` ${arrayDueStart[index] === '' ? "endpoint-error" : ""}`} 
                        size="sm"
                        placeholder="Quando?"
                    > */}
                      {/* <SingleCalendarEditable setValue={(data: any) => handleChangeDueStart(data, index)} date={arrayDueStart[index]} /> */}
                      <Controller
                        control={control}
                        name={`validadeInicio${index}`}
                        rules={{ required: { value: arrayDueStart[index] ? false : true, message: 'Por favor, preencher esse campo' } }}
                        render={({ field }: any) => (
                          <Form.Control type="date"
                            {...field}
                            aria-invalid={errors?.validadeInicio ? "true" : ""}
                            autoComplete='off'
                            variant="standard"

                            margin="normal"
                            required
                            onChange={(e: any) => { handleChangeDueStart(e.target.value, index); field.onChange(e); }}
                            value={arrayDueStart[index]}
                          />
                        )}
                      />
                      {/* </InputGroup> */}
                      {/* {arrayDueStart[index] === '' ?
                        <small style={{color: 'red'}}>required</small>
                    :<></>} */}
                      <ErrorMessage
                        errors={errors}
                        name={`validadeInicio${index}`}
                        render={({ message }) => <small style={{ color: "red" }}>{message}</small>}
                      />
                    </Form.Group>
                    <Form.Group as={Col} md={2} className="mb-3" controlId={`validadeFimGroup${index}`}>
                      <Form.Label>Validade Fim</Form.Label>
                      {/* <InputGroup
                        hasValidation
                        className={` ${arrayDueEnd[index] === '' ? "endpoint-error" : ""}`} 
                        size="sm"
                        placeholder="Quando?"
                    > */}
                      {/* <SingleCalendarEditable setValue={(data: any) => handleChangeDueEnd(data, index)} date={arrayDueEnd[index]} /> */}
                      <Controller
                        control={control}
                        name={`validadeFim${index}`}
                        rules={{ required: { value: arrayDueEnd[index] ? false : true, message: 'Por favor, preencher esse campo' } }}
                        render={({ field }: any) => (
                          <Form.Control type="date"
                            {...field}
                            aria-invalid={errors?.validadeFim ? "true" : ""}
                            autoComplete='off'
                            variant="standard"

                            margin="normal"
                            required
                            onChange={(e: any) => { handleChangeDueEnd(e.target.value, index); field.onChange(e); }}
                            value={arrayDueEnd[index]}
                          />
                        )}
                      />
                      {/* </InputGroup> */}
                      {/* {arrayDueEnd[index] === '' ?
                        <small style={{color: 'red'}}>required</small>
                    :<></>} */}
                      <ErrorMessage
                        errors={errors}
                        name={`validadeFim${index}`}
                        render={({ message }) => <small style={{ color: "red" }}>{message}</small>}
                      />
                    </Form.Group>
                    <Form.Group as={Col} md={2} className="mb-3" controlId={`unicoDiferenciadoGroup${index}`}>
                      <Form.Label>Valor único ou diferenciado ?</Form.Label>
                      <Controller
                        control={control}
                        name={`unicoDiferenciado${index}`}
                        rules={{ required: { value: arrayUniqueDiff[index] ? false : true, message: 'obrigatorio' } }}
                        render={({ field }: any) => (
                          <div className={errors?.unicoDiferenciado !== undefined ? "endpoint-error" : "endpoint"}>
                            <Form.Select
                              aria-labelledby="aria-label"
                              inputId="aria-example-input"
                              name="aria-live-color"
                              {...field}
                              as="select"
                              variant="standard"
                              margin="normal"
                              onChange={(e: any) => { handleChangeUniqueDiff(e.target.value, index); field.onChange(e); }}
                              value={arrayUniqueDiff[index]}
                            >
                              <option value="">Selecione</option>
                              <option value="1">Unico</option>
                              <option value="2">Diferenciado</option>
                            </Form.Select>
                          </div>
                        )}
                      />
                      <ErrorMessage
                        errors={errors}
                        name={`unicoDiferenciado${index}`}
                        render={({ message }) => <small style={{ color: "red" }}>{message}</small>}
                      />
                    </Form.Group>
                    {b > 0 && arrayUniqueDiff[index] === '1' ?
                      <Form.Group as={Col} md={2} className="mb-3" controlId={`valorGroup${index}`}>
                        <Form.Label>Valor</Form.Label>
                        <Controller
                          control={control}
                          name={`valor${index}`}
                          rules={{ required: { value: arrayValue[index] === '', message: "required" }, }}
                          render={({ field }: any) => (
                            <Form.Control
                              {...field}
                              aria-invalid={errors?.valor ? "true" : ""}
                              variant="standard"
                              margin="normal"
                              autoComplete="off"
                              //onKeyPress={(e) => !/[0-9]/.test(e.key) && e.preventDefault()}
                              value={arrayValue[index]}
                              onChange={(e: any) => { handleChangeValue(e.target.value, index); field.onChange(e); }}
                            />
                          )}
                        />
                        <ErrorMessage
                          errors={errors}
                          name={`valor${index}`}
                          render={({ message }) => <small style={{ color: "red" }}>{message}</small>}
                        />
                      </Form.Group>
                      : <></>}
                    {b > 0 && arrayUniqueDiff[index] === '2' && tariffType !== '2' && tariffType !== '3' ?
                      <Form.Group as={Col} md={2} className="mb-3" controlId={`porDiaSemanaGroup${index}`}>
                        <Form.Label>Varia conforme dia semana ?</Form.Label>
                        <Controller
                          control={control}
                          name={`porDiaSemana${index}`}
                          rules={{ required: { value: arrayByWeekDay[index] ? false : true, message: 'obrigatorio' } }}
                          render={({ field }: any) => (
                            <div className={errors?.porDiaSemana !== undefined ? "endpoint-error" : "endpoint"}>
                              <Form.Select
                                aria-labelledby="aria-label"
                                inputId="aria-example-input"
                                name="aria-live-color"
                                {...field}
                                as="select"
                                variant="standard"
                                margin="normal"
                                onChange={(e: any) => { handleChangeByWeekDay(e.target.value, index); field.onChange(e); }}
                                value={arrayByWeekDay[index]}
                              >
                                <option value="">Selecione</option>
                                <option value="1">Sim</option>
                                <option value="2">Não</option>
                              </Form.Select>
                            </div>
                          )}
                        />
                        <ErrorMessage
                          errors={errors}
                          name={`porDiaSemana${index}`}
                          render={({ message }) => <small style={{ color: "red" }}>{message}</small>}
                        />
                      </Form.Group>
                      : <></>}
                    {b > 0 && arrayUniqueDiff[index] === '2' && tariffType === '1' ?
                      <Form.Group as={Col} md={2} className="mb-3" controlId={`porPaxGroup${index}`}>
                        <Form.Label>Varia conforme numero pax ?</Form.Label>
                        <Controller
                          control={control}
                          name={`porPax${index}`}
                          rules={{ required: { value: arrayByPax[index] ? false : true, message: 'obrigatorio' } }}
                          render={({ field }: any) => (
                            <div className={errors?.porPax !== undefined ? "endpoint-error" : "endpoint"}>
                              <Form.Select
                                aria-labelledby="aria-label"
                                inputId="aria-example-input"
                                name="aria-live-color"
                                {...field}
                                as="select"
                                variant="standard"
                                margin="normal"
                                onChange={(e: any) => { handleChangeByPax(e.target.value, index); field.onChange(e); }}
                                value={arrayByPax[index]}
                              >
                                <option value="">Selecione</option>
                                <option value="1">Sim</option>
                                <option value="2">Não</option>
                              </Form.Select>
                            </div>
                          )}
                        />
                        <ErrorMessage
                          errors={errors}
                          name={`porPax${index}`}
                          render={({ message }) => <small style={{ color: "red" }}>{message}</small>}
                        />
                      </Form.Group>
                      : <></>}
                    <Form.Group as={Col} className="mb-3 d-flex justify-content-end" controlId={`actionsGroup${index}`}>
                      <div className="px-2 mb-auto mt-auto">
                        <div className="d-flex justify-content-end">
                          <span className='btn' style={{ height: "40px" }} onClick={(e: any) => removeRow(index)}>
                            <FontAwesomeIcon
                              size="lg"
                              icon={["fal", "trash-alt"]}
                              style={{ color: "#707070" }}
                            />
                          </span>
                        </div>
                      </div>
                    </Form.Group>
                  </Row>
                  {b > 0 && arrayUniqueDiff[index] === '2' ?
                    <Row>
                      {/* tabela de items */}
                      <SupplierTariffItems
                        action={getItems}
                        info={arrayItems[index]}
                        blockIndex={index}
                        byWeekDay={arrayByWeekDay[index]}
                        maxLimitVal={(tariffType === '1' || tariffType === '2' || tariffType === '3') ? 50 : 99}
                        limitMinTxt={(tariffType === '1' || tariffType === '2' || tariffType === '3') ? 'Pax Min' : 'Idade Min'}
                        limitMaxTxt={(tariffType === '1' || tariffType === '2' || tariffType === '3') ? 'Pax Max' : 'Idade Max'}
                      />
                    </Row>
                    : <></>}
                  {/* {index < controlObject.length-1 ? */}

                  {/* : <></>} */}
                </Form.Group>
                <hr style={{ margin: "30px 0" }} />
              </>
            )
          })
        }
      </Form.Group>
      <Form.Group as={Col} md={12}>
        <>
          <div className="px-2 mb-3">
            <div className="d-flex justify-content-center">
              <span className='btn' style={{ height: "40px" }} onClick={(e: any) => addRow()}>
                <FontAwesomeIcon
                  size="lg"
                  icon={["fal", "plus"]}
                  style={{ color: "#707070", margin: "0 12px" }}
                />
              </span>
            </div>
          </div>
        </>
      </Form.Group>
    </>
  );
}

export default SupplierTariffBlocks;
