import  React, { useState, useEffect } from "react";

import { Col, Form } from "react-bootstrap";
import { Controller } from "react-hook-form";
import Select from "react-select";

import CA from './locales/CA.json';
import US from './locales/US.json'


export interface PropStates {
  control?: any;
  errors?: any;
  setValue?: any;
  isForeign?: any;
  getValues?: any;
  watchCountry?:any;
  actionState: any;
  t?: any;
}

const DropdownStatesAndProvinces: React.FC<PropStates> = ({
  control,
  errors,
  setValue,
  isForeign,
  getValues,
  actionState,
  watchCountry,
  t,
}: PropStates) => {
    const [country, setCountry] = useState<any>("BR");   
    const [options, setOptions] = useState<any>("BR");   
    const [check, setCheck] = useState<any>(true);    

    useEffect(() => {
      setCountry(watchCountry);      
    }, [watchCountry]);

    useEffect(() => {
      
     if(country === "CA") {
      setOptions(CA.states);
     }else if (country === "US") {
      setOptions(US.states)
     }else{
      setOptions("BR")
     }      
    }, [country]);

    function handleChange(options: any) {
      if (actionState) {
          actionState(options);
          setCheck(false)
      }     
    }
    
  if(country === "US" || "CA"){
    return (
    <>
     
        <Form.Group
          as={Col}
          md="4"
          controlId="formCheckoutCountey"
          className="mb-3"
        >
          <Form.Label>
            {/* {t("checkout.paymentCheckout.credit.state")} */}
            {country === "CA" ? t("checkout.paymentCheckout.credit.province") : t("checkout.paymentCheckout.credit.state") }
          </Form.Label>
          <Controller
            control={control}
            name="state"            
            render={({ field }: any) => (
              <div className={check !== true ? "endpoint" : "endpoint-error"}>
                 
                <Select
                    placeholder={t("checkout.paymentCheckout.credit.select")}
                    options={Array.from(options)}
                    getOptionLabel={(option: any) => `${option.description}`}
                    getOptionValue={(option: any) => option.id}
                    onChange={handleChange}
                />

            </div> 
            )}
          />   
          {check === true  ? <small style={{ color: "red", marginTop: "3px" }}>{t("checkout.paymentCheckout.credit.stateError")}</small> : <></>}       
        </Form.Group>       
      
    </>
    );
  }else{
    return (
      <></>
    )
  }
};

export default DropdownStatesAndProvinces;
