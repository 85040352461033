/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useEffect, useState } from "react";
import api from '../../../../services/api';

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Modal, Dropdown } from "react-bootstrap";

import "react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css";
import "react-bootstrap-table-next/dist/react-bootstrap-table2.css";
import BootstrapTable from "react-bootstrap-table-next";

//STYLES
import "./TableCashierExtract.scss";
import Pagination from "../../../../components/Pagination/Pagination";
import ModalDefault from "../../../../components/Modal/ModalDefault/ModalDefault";
import InsertReceivable from "../InsertReceivable/InsertReceivable";
import InsertPayable from "../InsertPayable/InsertPayable";
import InsertAccountTransfer from "../InsertAccountTransfer/InsertAccountTransfer";
import BillsReceivableSettle from "../BillsReceivableSettle/BillsReceivableSettle";
import BillsPayableSettle from "../BillsPayableSettle/BillsPayableSettle";

export interface propTable {
  billsPay: any;
  totalRows: any;
  pageCount: any;
  setPageCount: any;
  rowsPerPage: any;
  setRowsPerPage: any;
  exportBills: any;
  printBills: any;
  loading: any;
  
  accountId: any;
  accountName: any;

  refreshData?: any;
}

const TableCashierExtract: React.FC<propTable> = ({
  billsPay, totalRows, pageCount, setPageCount, rowsPerPage, setRowsPerPage, exportBills, printBills, loading, refreshData
  , accountId, accountName
}: propTable) => {
  /* VARIÁVEIS */
  //const { t } = useTranslation();

  const [modalResponse, setModalResponse] = useState<any>(null);
  const [allowReload, setAllowReload] = useState<any>(false);

  /* Success Modal (Inserir) */
  const [modalSuccess, setModalSuccess] = useState<any>(false);
  const [responseText, setResponseText] = useState<any>();
  const [modalContent, setModalContent] = useState<any>();
  const [log, setLog] = useState<any>();
  /* END - Success Modal (Inserir) */

  //const [selectedCheck, setSelectedCheck] = useState<any>([]);
  //const [itemsToSettle, setItemsToSettle] = useState<any>([]);

  const [singleNoteInfo, setSingleNoteInfo] = useState<any>(undefined);
  const [billsToPay, setBillsToPay] = useState<any>(undefined);
  const [billsToPayTotalRows, setBillsToPayTotalRows] = useState<any>(0);
  const [billsToPayLoading, setBillsToPayLoading] = useState<any>(false);
  const [sessionLocalation, setSessionLocalation] = useState<any>("");

  const searchSingleNoteReceive = (localizador: any = '') => {
    const token = localStorage.getItem('GroupId') || '{}';
    const config = {
      headers: { 'Authorization': `Bearer ${token}` },
    };
    async function getSingleSearch() {
      try {
        const { data } = await api.get(`/BillsReceivable/GetOrderInfo/${localizador}`, config);
        if (data.statusCode !== 400) {
          //;
          setSingleNoteInfo(data.data);
        }
      } catch (error: any) {
        console.log(error);
        //if (error.response.status === 401) {
          //
        //}
      }
    }
    getSingleSearch();
  }

  const searchBillsToPay = (localizador: any = '', page: any = 1, rowsPerPage: any = 10) => {
    
    const token = localStorage.getItem('GroupId') || '{}';
    const config = {
      headers: { 'Authorization': `Bearer ${token}` },
    };
    async function getSingleSearch() {
      setBillsToPayLoading(true);
      try {
        const { data } = await api.post(`/BillsPayable/GetReport`, {
          "page": page,
          "rowsPerPage": rowsPerPage,
          "item": localizador,
          "statusPagamento": null,
          "fornecedor": null,
          "tipoConta": null,
          "tipoData": 3,
          "dataInicial": "2022-8-23",
          "dataFinal": "2022-8-23"
        }, config);
        if (data.statusCode !== 400) {
          const dados = data.data;

          setBillsToPay(dados.rows);
          setBillsToPayTotalRows(dados.rowsCount);
          setSessionLocalation(localizador);
        }
      } catch (error: any) {
        console.log(error);
        //if (error.response.status === 401) {
          //
        //}
      }
      setBillsToPayLoading(false);
    }
    if(localizador) getSingleSearch();
  }

  /* FUNÇÕES */
  function refreshDataWrap() {
    refreshData();
  }


  function convertValue(cell: any, row: any) {
    //console.log(Math.sign(cell))
    return (
      <>
        {cell !== 0 ?
          (row.pago === 1 ?
          <span className={row.valorPago > 0 ? "negative" : "positive"}>{cell.toFixed(2).replace(".", ",")} <FontAwesomeIcon size="sm" icon={["fal", "check"]} color="navy" /></span>
          :<span className={"not-paid"}>{cell.toFixed(2).replace(".", ",")} </span>)
        : <></>}
      </>
    )
  }

  function convertValueSaldo(cell: any, row: any) {
    //console.log(Math.sign(cell))
    return (
      <>
        <span className={Math.sign(cell) === -1 ? "negative" : "positive"}>{cell.toFixed(2).replace(".", ",")}</span>
      </>
    )
  }

  /*converte a data UTC para a data local do usuário*/
  /* function convertUTCDateToLocalDate(date: any) {
    var newDate = new Date(date.getTime() + date.getTimezoneOffset() * 60 * 1000);

    var offset = date.getTimezoneOffset() / 60;
    var hours = date.getHours();

    newDate.setHours(hours - offset);

    return newDate.toLocaleString().split(' ')[0];
  } */

  function convertDate(cell: any, row: any) {
    if (cell !== null) {
      //var date = convertUTCDateToLocalDate(new Date(cell));
      var aux: any = cell.split("T")[0].split("-");
      var date: any = `${aux[2]}/${aux[1]}/${aux[0]}`;
      return (
        <>
          {date}
        </>
      );
    } else {
      return (
        <>
        </>
      );
    }
  }

  /* SOBRE A TABELA */

  const columns = [
    {
      dataField: "data",
      text: "Data",
      formatter: convertDate,
    },
    { dataField: "descricao", text: "Descrição" },
    { dataField: "clienteFornecedor", text: "Cliente/Fornecedor" },
    { dataField: "pagoA", text: "Pago A" },
    {
      dataField: "valorRecebido",
      text: "Valor Recebido",
      formatter: convertValue,
    },
    {
      dataField: "valorPago",
      text: "Valor Pago",
      formatter: convertValue,
    },
    {
      dataField: "saldo",
      text: "Saldo",
      formatter: convertValueSaldo,
    },
  ];

  /* loading table */

  function loadingFunc() {
    return (
      <>
        <div className="animated-background row-loading"></div>
      </>
    )
  }

  const loadingColumns = [{ dataField: "contasd", text: "Carregando Extratos", formatter: loadingFunc }];

  const loadingProducts = [
    { contas: "" }, { contas: "" }, { contas: "" }, { contas: "" }, { contas: "" },
    { contas: "" }, { contas: "" }, { contas: "" }, { contas: "" }, { contas: "" }
  ];
  /* [END] loading table */

  /* In case of empty table*/
  const notFoundColumns = [{ dataField: "extratos", text: "Extratos" }];

  const notFoundProducts = [{ extratos: "Nenhum extato encontrado" }];
  /* [END] In case of empty table*/

  const rowClasses = (row: any, rowIndex: any) => {
    /* console.log(row, rowIndex) */
    if (row.pago === 1) {
      return 'border-ativo';
    } else {
      return 'border-inativo';
    }
  };

  /* FORMULÁRIO */


  useEffect(() => {
    if (modalResponse === null && allowReload === true) {
      window.location.reload();
    }
  }, [allowReload, /*  */modalResponse])

  /* RENDER */
  if (billsPay !== null) {
    /* console.log(props.suppliers) */
    return (
      <>
        <div className="table-default cashier-extract-table">
          <div className="table-container">
            <div>
              <div className="d-flex justify-content-between">
                <div className="d-flex flex-wrap align-items-center" style={{ gap: "15px" }}>
                  <div>
                    <Dropdown drop="down" >
                      <Dropdown.Toggle className="menu-profile p-0">
                        <button className="btn btn-bills">
                          <FontAwesomeIcon
                            icon={["fal", "plus-circle"]}
                            size="1x"
                            style={{ marginRight: "5px" }}
                          />
                          Incluir Recebimento
                        </button>
                      </Dropdown.Toggle>

                      <Dropdown.Menu id="dropdown-menu-liquidar">
                        <Dropdown.Item eventKey="1" disabled={false}>
                          <ModalDefault
                            title="Incluir Recebimento"
                            name="Reserva"
                            classBtn="btn btn-bills"
                            classModal="modal-large"
                            menuItem="yes" 
                            localation="yes"
                            getLocalizador={searchSingleNoteReceive}
                            closeAction={() => setSingleNoteInfo(undefined)}
                          >
                            <BillsReceivableSettle 
                              singleNoteInfo={singleNoteInfo}
                              searchSingleNote={searchSingleNoteReceive}
                              accountId={accountId}
                              accountName={accountName}
                              show={setModalSuccess}
                              setModalContent={setModalContent}
                              setResponseText={setResponseText}
                              setLog={setLog}
                            />
                          </ModalDefault>
                        </Dropdown.Item>
                        <Dropdown.Item eventKey="2" disabled={false}>
                          <ModalDefault
                            title="Incluir Recebimento"
                            name="Avulso"
                            classBtn="btn btn-bills"
                            classModal="modal-large"
                            menuItem="yes" 
                          >
                            <InsertReceivable
                              show={setModalSuccess}
                              setModalContent={setModalContent}
                              setResponseText={setResponseText}
                              setLog={setLog}
                              fixAccountId={accountId}
                              fixAccountName={accountName}
                            />
                          </ModalDefault>
                        </Dropdown.Item>
                      </Dropdown.Menu>
                    </Dropdown>
                  </div>
                  <div>
                    <Dropdown drop="down" >
                      <Dropdown.Toggle className="menu-profile p-0">
                        <button className="btn btn-bills">
                          <FontAwesomeIcon
                            icon={["fal", "plus-circle"]}
                            size="1x"
                            style={{ marginRight: "5px" }}
                          />
                          Incluir Pagamento
                        </button>
                      </Dropdown.Toggle>

                      <Dropdown.Menu id="dropdown-menu-liquidar">
                        <Dropdown.Item eventKey="1" disabled={false}>
                          <ModalDefault
                            title="Incluir Pagamento"
                            name="Reserva/Session" 
                            classBtn="btn btn-bills"
                            classModal="modal-large"
                            menuItem="yes"
                            localation="yes"
                            localationLabel="Localizador/Session"
                            getLocalizador={searchBillsToPay}
                            closeAction={() => setBillsToPay(undefined)}
                          >
                            <BillsPayableSettle 
                              billsToPay={billsToPay}
                              totalRows={billsToPayTotalRows}
                              sessionLocalation={sessionLocalation}
                              loading={billsToPayLoading}
                              searchBillsToPay={searchBillsToPay}
                              accountId={accountId}
                              accountName={accountName}
                              show={setModalSuccess}
                              setModalContent={setModalContent}
                              setResponseText={setResponseText}
                              setLog={setLog}
                            /> 
                          </ModalDefault>
                        </Dropdown.Item>
                        <Dropdown.Item eventKey="2" disabled={false}>
                          <ModalDefault
                            title="Incluir Pagamento"
                            name="Avulso"
                            classBtn="btn btn-bills"
                            classModal="modal-large"
                            menuItem="yes" 
                          >
                            <InsertPayable 
                              show={setModalSuccess}
                              setModalContent={setModalContent}
                              setResponseText={setResponseText}
                              setLog={setLog}
                              fixAccountId={accountId}
                              fixAccountName={accountName}
                            />
                          </ModalDefault>
                        </Dropdown.Item>
                      </Dropdown.Menu>
                    </Dropdown>
                  </div>
                  <div>
                    <ModalDefault
                      title="Nova Trânferência Entre Contas"
                      name="Nova Transferência"
                      icon="yes"
                      typeIcon="plus-circle"
                      classBtn="btn btn-bills"
                      classModal="modal-large"
                    >
                      <InsertAccountTransfer 
                        show={setModalSuccess}
                        setModalContent={setModalContent}
                        setResponseText={setResponseText}
                        setLog={setLog}
                        fixAccountId={accountId}
                        fixAccountName={accountName}
                      />
                    </ModalDefault>
                  </div>
                  <div>
                    <div style={{ cursor: 'pointer' }} className="btn btn-bills" onClick={exportBills}>
                      <FontAwesomeIcon
                        icon={["fal", "share"]}
                        size="1x"
                        style={{ marginRight: "5px" }}
                      />
                      Exportar
                    </div>
                  </div>
                  <div>
                    <div style={{ cursor: 'pointer' }} className="btn btn-bills" onClick={printBills}>
                      <FontAwesomeIcon
                        file-invoice
                        icon={["fal", "print"]}
                        size="1x"
                        style={{ marginRight: "5px" }}
                      />
                      Imprimir
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <BootstrapTable
              bootstrap4
              keyField="id"
              data={billsPay}
              columns={columns}
              rowClasses={rowClasses}
            />
            <Pagination
              totalRows={totalRows}
              pageCount={pageCount}
              setPageCount={setPageCount}
              rowsPerPage={rowsPerPage}
              setRowsPerPage={setRowsPerPage}
              selectNumberRows="yes"
            />
          </div>
        </div>
        
        {/* Modal de Success */}
        <Modal
          className={"modal-confirm loading-modal"}
          show={modalSuccess}
          onHide={() => setModalSuccess(false)}
          size="lg"
          aria-labelledby="contained-modal-title-vcenter"
        >
          <>
            <Modal.Body className='modal-body text-center sucess-pos'>
              {
                modalContent !== "loading"
                  ?
                  <>
                    <div>
                      {
                        log === 2 || log === "2"
                          ?
                          <FontAwesomeIcon
                            icon={["fal", "exclamation-circle"]}
                            size="5x"
                            className="text-primary"
                            style={{ fontSize: "7.5em" }}
                          />
                          :
                          log === 1 || log === "1"
                            ?
                            <FontAwesomeIcon
                              icon={["fal", "times-circle"]}
                              size="5x"
                              className="text-primary"
                              style={{ fontSize: "7.5em" }}
                            />
                            :
                            log === 0 || log === "0"
                              ?
                              <FontAwesomeIcon
                                icon={["fal", "check-circle"]}
                                size="5x"
                                className="text-primary"
                                style={{ fontSize: "7.5em" }}
                              />
                              :
                              <FontAwesomeIcon
                                icon={["fal", "question-circle"]}
                                size="5x"
                                className="text-primary"
                                style={{ fontSize: "7.5em" }}
                              />
                      }
                    </div>
                    <div>
                      {responseText}
                    </div>
                    <div className="d-flex justify-content-center">
                      {
                        modalContent === 'success'
                          ?
                          <button
                            onClick={() => {refreshDataWrap(); setModalSuccess(false);}}
                            className="btn btn-primary mx-2 w-25"
                          >
                            Confirmar
                          </button>
                          :
                          modalContent === 'warning'
                            ?
                            <button
                              onClick={() => setModalSuccess(false)}
                              className="btn btn-primary mx-2 w-25"
                            >
                              Confirmar
                            </button>
                            :
                            <button
                              onClick={() => setModalSuccess(false)}
                              className="btn btn-primary mx-2 w-25"
                            >
                              Confirmar
                            </button>
                      }
                    </div>
                  </>
                  :
                  <div className="modal-body">
                    <div className="text-center">
                      <div className="load"></div>
                    </div>
                    <div>
                      <span>Processando</span>
                    </div>
                    <div></div>
                  </div>
              }
            </Modal.Body>
          </>
        </Modal>
        {/* END - Modal de Success */}
      </>
    );
  } else if (billsPay === null && loading === true) {
    return (
      <>
        <div className="table-default cashier-extract-table loading not-found">
          <div className="table-container">
            <div>
              <div className="d-flex justify-content-between">
                <div className="d-flex flex-wrap align-items-center" style={{ gap: "15px" }}>
                  <div>
                    <button style={{ cursor: 'pointer' }} className="btn btn-bills" disabled={true}>
                      <FontAwesomeIcon
                        icon={["fal", "plus-circle"]}
                        size="1x"
                        style={{ marginRight: "5px" }}
                      />
                      Novo Recebimento
                    </button>
                  </div>
                  <div>
                    <button style={{ cursor: 'pointer' }} className="btn btn-bills" disabled={true}>
                      <FontAwesomeIcon
                        icon={["fal", "plus-circle"]}
                        size="1x"
                        style={{ marginRight: "5px" }}
                      />
                      Novo Pagamento
                    </button>
                  </div>
                  <div>
                    <button style={{ cursor: 'pointer' }} className="btn btn-bills" disabled={true}>
                      <FontAwesomeIcon
                        icon={["fal", "plus-circle"]}
                        size="1x"
                        style={{ marginRight: "5px" }}
                      />
                      Nova Transferência
                    </button>
                  </div>
                  <div>
                    <button style={{ cursor: 'pointer' }} className="btn btn-bills" disabled={true}>
                      <FontAwesomeIcon
                        icon={["fal", "share"]}
                        size="1x"
                        style={{ marginRight: "5px" }}
                      />
                      Exportar
                    </button>
                  </div>
                  <div>
                    <button style={{ cursor: 'pointer' }} className="btn btn-bills" disabled={true}>
                      <FontAwesomeIcon
                        file-invoice
                        icon={["fal", "print"]}
                        size="1x"
                        style={{ marginRight: "5px" }}
                      />
                      Imprimir
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <BootstrapTable
              bootstrap4
              keyField="id"
              data={loadingProducts}
              hover={true}
              columns={loadingColumns}
            />
          </div>
        </div>
      </>
    )
  } else {
    return (
      <>
        <div className="table-default cashier-extract-table not-found">
          <div className="table-container">
            {/*             <div className="table-title">
              <h3 className="">Fornecedores</h3>
            </div> */}

            <BootstrapTable
              bootstrap4
              keyField="id"
              data={notFoundProducts}
              hover={true}
              columns={notFoundColumns}
            />
          </div>
        </div>
      </>
    );
  }
}

export default TableCashierExtract;
