/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useState } from "react";
import api from '../../../../services/api';

import { Form, Modal, Dropdown, Button } from "react-bootstrap";
import { useForm } from "react-hook-form";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useTranslation } from 'react-i18next';

import "react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css";
import "react-bootstrap-table-next/dist/react-bootstrap-table2.css";
import BootstrapTable from "react-bootstrap-table-next";
//import paginationFactory from "react-bootstrap-table2-paginator";

//STYLES
import "./TableExportERPSuppliers.scss";
import Pagination from "../../../../components/Pagination/Pagination";
import FormBillReceiveEdit from "../../../../components/Forms/FormBillReceiveEdit/FormBillReceiveEdit";
import DetailsExportERM from "./components/DetailsExportERM/DetailsExportERM";
import ModalShow from "../../../../components/Modal/ModalShow/ModalShow";

export interface propTable {
  listERPSuppliers: any;
  totalRows: any;
  pageCount: any;
  setPageCount: any;
  rowsPerPage: any;
  setRowsPerPage: any;
  loading: any;
  exportListERM: any;
  parameters?: any;
  refreshData?: any;
}

const TableBillsReceive: React.FC<propTable> = ({
  listERPSuppliers, totalRows, pageCount, setPageCount, rowsPerPage, setRowsPerPage, loading, exportListERM, parameters,
  refreshData
}: propTable) => {
  const { t } = useTranslation();

  /* Success Modal (Inserir) */
  const [modalSuccess, setModalSuccess] = useState<any>(false);
  const [responseText, setResponseText] = useState<any>();
  const [modalContent, setModalContent] = useState<any>();
  const [log, setLog] = useState<any>();
  /* END - Success Modal (Inserir) */

  const [selectedSingleRow, setSelectedSingleRow] = useState<any>(null);
  const [modalShowConfirmation, setModalShowConfirmation] = useState<any>(false);
  const [modalShow, setModalShow] = useState<any>(false);
  const [modalMessage, setModalMessage] = useState<string>("Carregando");
  const [modalLog, setModalLog] = useState<any>(null);

  const [modalShowErrors, setModalShowErrors] = useState<boolean>(false);
  const [modalErrorsMessage, setModalErrorsMessage] = useState<string>('');

  const [editShow, setEditShow] = useState<any>(false);
  const [editDetachedShow, setEditDetachedShow] = useState<any>(false);
  const [detailsModal, setDetailsModal] = useState<any>(false);
  const [currentIdentity, setCurrentIdentity] = useState<any>(null);

  const [undoSettlementModalShow, setUndoSettlementModalShow] = useState<any>(false);
  const [undoSettlementText, setUndoSettlementText] = useState<any>("");

  const tourAuth: any = JSON.parse(localStorage.getItem('c2tourAuth') || '{}');

  const userName: any = tourAuth.user.nome;
  const userLastName: any = tourAuth.user.lastName;
  const uId: any = tourAuth.user.uId;

  async function editDetachedBill(row: any) {
    const token = localStorage.getItem('GroupId') || '{}';
    const config = {
      headers: { 'Authorization': `Bearer ${token}`, 'Content-Type': 'application/json' },
    };
    try {
      const res = await api.post(`/BillsReceivable/GetBill`,
        {
          "id": row.newPaymentsId,
          "tipo": 1,

        }, config);
      if (res.status !== 400) {
        var dados = res.data.data;
        //console.log(dados.centrosCusto);
        setSelectedSingleRow(dados);
        setEditDetachedShow(true);
      }
    } catch (error: any) {

    }
  }

  async function handleActionUndoSettlementModal() {
    setUndoSettlementModalShow(false);

    setModalContent("loading");
    setModalSuccess(true);

    const token = localStorage.getItem('GroupId') || '{}';
    const config = {
      headers: { 'Authorization': `Bearer ${token}`, 'Content-Type': 'application/json' },
    };

    var manifestId = selectedSingleRow.newPaymentsId;
    var tipo = 1;

    if (selectedSingleRow.nParcela !== 1) {
      manifestId = selectedSingleRow.installmentsId;
      tipo = 2;
    }

    setSelectedSingleRow(null);

    try {
      const res = await api.post(`/BillsReceivable/UndoBillSettlement`,
        {
          "manifestId": manifestId,
          "tipo": tipo,
          "uid": uId,
          "usuario": (userName !== null && userLastName !== null) ? `${userName} ${userLastName}` : userName
        }, config);
      if (res.status !== 400) {
        var dados = res.data.data;
        //console.log(dados.centrosCusto);
        setResponseText(dados.texto);
        setLog(dados.log);

        if (dados.log !== 1) {
          setModalContent("success");
        } else {
          setModalContent("error");
        }

      } else {
        setModalContent("error");
        setResponseText("Erro, tente novamente mais tarde");
        setLog(1);
      }
    } catch (error: any) {
      console.log(error.response)
    }
  }

  async function editRow(row: any) {

    if (row.notaAvulsa === 5) {
      await editDetachedBill(row);
    } else {
      setSelectedSingleRow(row);
      setEditShow(true);
    }
  }

  function seeDetails(row: any) {
    setCurrentIdentity(row.identity);
    setDetailsModal(true);
  }

  function actionExport(row: any) {
    setCurrentIdentity(row.identity);
    setModalShowConfirmation(true);
  }

  const exportByUid = () => {
    setModalShow(true);
    async function exportFunction() {
      const token = localStorage.getItem('GroupId') || '{}';
      const config: any = {
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json',
          "Accept": "*/*"
          //"Access-Control-Allow-Origin": "*"
        },
        responseType: 'blob'
      };
      try {
        const exp = await api.get(`Manifest/SendFornecedorRm?uid=${currentIdentity}`, config);
        if (exp.status === 200) {
          setModalLog(0);
          setModalMessage("Envio agendado com sucesso.");
        } else {
          setModalLog(1);
          setModalMessage("Erro ao processar operação");
        }
      } catch (error: any) {
        setModalLog(1);
        setModalMessage("Erro ao processar operação");
        console.log(error)
      }

    };

    exportFunction();
  }

  const handleActionBillEditModal = () => {
    setSelectedSingleRow(null);
    setEditDetachedShow(false);
    setEditShow(false);
    //refreshData();
  }

  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm();

  function addActionButton(cell: any, row: any) {
    return (
      <>
        <div className="d-flex justify-content-center">
          <Dropdown drop="start">
            <Dropdown.Toggle variant="light ">
              <FontAwesomeIcon size="lg" icon={["fal", "ellipsis-h"]} />
            </Dropdown.Toggle>

            <Dropdown.Menu>
              {/* <Dropdown.Item
                eventKey="3"
                onClick={() => { seeDetails(row) }}>
                <FontAwesomeIcon
                  icon={['fal', 'file-alt']}
                />
                Preview envio RM
              </Dropdown.Item> */}

              <Dropdown.Item
                eventKey="3"
                onClick={() => { actionExport(row) }}>
                <FontAwesomeIcon
                  icon={['fal', 'share']}
                />
                Exportar
              </Dropdown.Item>

              {/* <Dropdown.Item
                eventKey="3"
                onClick={() => { editRow(row) }}>
                <FontAwesomeIcon
                  icon={['fal', 'edit']}
                //className="mr-2"
                />
                {t('suppliersTable.edit')}
              </Dropdown.Item> */}
            </Dropdown.Menu>
          </Dropdown>
        </div>
      </>
    );
  }

  function convertDate(cell: any, row: any) {
    if (!cell) return '';
    //var date = convertUTCDateToLocalDate(new Date(cell));
    var aux: any = cell.split("T")[0].split("-");
    var date: any = `${aux[2]}/${aux[1]}/${aux[0]}`;
    return (
      <>
        {date}
      </>
    );
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  function convertValue(cell: any) {
    return (
      <>
        <span className={Math.sign(cell) === -1 ? "negative" : ""}>{cell}</span>
      </>
    )
  }

  function formatValue(cell: any, row: any) {
    if (cell) {
      return (
        <>
          <span className={Math.sign(cell) === -1 ? "negative" : ""}>{cell.toFixed(2).replace(".", ",")} {row.sufixovalor}</span>
        </>
      )
    } else {
      return (
        <>
          <span className=""></span>
        </>
      )
    }
  }

  function addStatus(cell: any, row: any) {
    return (
      <div
        className={
          cell === "Em Análise"
            ? "bg-analise"
            : cell === "Ativo" || cell === "Enviado"
              ? "bg-ativo"
              : cell === "Inativo" || cell === "Não enviado"
                ? "bg-inativo"
                : ""
        }
      >
        {cell}
      </div>
    );
  }

  function addErrors(cell: any, row: any) {
    let listOfErrors: any = row.erros?.split(';');

    return (
      <div className="d-flex ">
        {
          cell === 0
            ?
            <>
              <div>{cell}</div>
            </>
            :
            <>
              <button onClick={() => {
                setModalMessage(listOfErrors);
                setModalLog(1);
                setModalShow(true);
              }} type='button' className="btn btn-outline-primary errors-btn" style={{ border: '1px solid #076fa2' }}>{cell}</button>
            </>
        }
      </div>
    );
  }

  function addPayment(cell: any, row: any) {
    return (
      <div
        className={
          cell === "Pendente"
            ? "bg-inativo"
            : cell === "Recebido"
              ? "bg-ativo"
              : "bg-analise"
        }
      >
        {cell}
      </div>
    );
  }

  function addErp(cell: any, row: any) {
    return (
      <div
        className={
          cell === "Pendente"
            ? "bg-inativo"
            : //cell === "Exportado"
            "bg-analise"
        }
      >
        {cell}
      </div>
    );
  }

  const columns = [
    { dataField: "nomeFantasia", text: `${t("listSuppliers.tableName")}` },
    { dataField: "state", text: `${t("listSuppliers.tableLocation")}` },
    { dataField: "dataCadastro", text: `${t("listSuppliers.tableDate")}`, formatter: convertDate, },
    { dataField: "typeDesc", text: `${t("listSuppliers.filterClientType")}` },
    { dataField: "quantidadesErros", text: "Erros", formatter: addErrors },
    { dataField: "statusErp", text: `Status ERP`, formatter: addStatus, },
    { dataField: "statusDesc", text: `${t("listSuppliers.tableStatus")}`, formatter: addStatus, },
    { dataField: "identity", text: `${t("listSuppliers.tableAction")}`, formatter: addActionButton },
  ];

  /*--------------------------------------------------*/

  /* In case of empty table*/
  const notFoundColumns = [{ dataField: "contas", text: "Fornecedores ERP" }];

  const notFoundProducts = [{ contas: "Nenhum fornecedor encontrado" }];
  /* [END] In case of empty table*/

  /* loading table */

  function loadingFunc() {
    return (
      <>
        <div className="animated-background row-loading"></div>
      </>
    )
  }

  const loadingColumns = [{ dataField: "contasd", text: "Carregando", formatter: loadingFunc }];

  const loadingProducts = [
    { contas: "" }, { contas: "" }, { contas: "" }, { contas: "" }, { contas: "" },
    { contas: "" }, { contas: "" }, { contas: "" }, { contas: "" }, { contas: "" }
  ];
  /* [END] loading table */

  const rowClasses = (row: any, rowIndex: any) => {
    if (row.statusDesc === "Em Análise") {
      return 'border-analise';
    } else if (row.statusDesc === "Ativo") {
      return 'border-ativo';
    } else {
      return 'border-inativo';
    }
  };

  function handleReload() {
    window.location.reload();
  }

  if (listERPSuppliers !== null) {
    return (
      <>
        <div className="table-default bills-receive-table">
          <div className="table-container">
            <BootstrapTable
              bootstrap4
              keyField="id"
              data={listERPSuppliers}
              columns={columns}
              rowClasses={rowClasses}
              striped={true}
            />
            <Pagination
              totalRows={totalRows}
              pageCount={pageCount}
              setPageCount={setPageCount}
              rowsPerPage={rowsPerPage}
              setRowsPerPage={setRowsPerPage}
              selectNumberRows="yes"
            />
          </div>
        </div>

        {/* Modal de Editar */}
        <Modal
          className="modalReceber"
          show={editShow}
          onHide={handleActionBillEditModal}
          size="xl"
          aria-labelledby="contained-modal-title-vcenter"
        >
          <>
            <Modal.Header className="p-3" closeButton></Modal.Header>
            <FormBillReceiveEdit action={handleActionBillEditModal} info={selectedSingleRow} />
          </>
        </Modal>
        {/* END - Modal de Editar */}

        {/* Modal de Success */}
        <Modal
          className={"modal-confirm loading-modal"}
          show={modalSuccess}
          onHide={() => setModalSuccess(false)}
          size="lg"
          aria-labelledby="contained-modal-title-vcenter"
        >
          <>
            <Modal.Body className='modal-body text-center sucess-pos'>
              {
                modalContent !== "loading"
                  ?
                  <>
                    <div>
                      {
                        log === 2 || log === "2"
                          ?
                          <FontAwesomeIcon
                            icon={["fal", "exclamation-circle"]}
                            size="5x"
                            className="text-primary"
                            style={{ fontSize: "7.5em" }}
                          />
                          :
                          log === 1 || log === "1"
                            ?
                            <FontAwesomeIcon
                              icon={["fal", "times-circle"]}
                              size="5x"
                              className="text-primary"
                              style={{ fontSize: "7.5em" }}
                            />
                            :
                            log === 0 || log === "0"
                              ?
                              <FontAwesomeIcon
                                icon={["fal", "check-circle"]}
                                size="5x"
                                className="text-primary"
                                style={{ fontSize: "7.5em" }}
                              />
                              :
                              <FontAwesomeIcon
                                icon={["fal", "question-circle"]}
                                size="5x"
                                className="text-primary"
                                style={{ fontSize: "7.5em" }}
                              />
                      }
                    </div>
                    <div>
                      {responseText}
                    </div>
                    <div className="d-flex justify-content-center">
                      {
                        modalContent === 'success'
                          ?
                          <button
                            onClick={handleReload}
                            className="btn btn-primary mx-2 w-25"
                          >
                            Confirmar
                          </button>
                          :
                          modalContent === 'warning'
                            ?
                            <button
                              onClick={() => setModalSuccess(false)}
                              className="btn btn-primary mx-2 w-25"
                            >
                              Confirmar
                            </button>
                            :
                            <button
                              onClick={() => setModalShow(false)}
                              className="btn btn-primary mx-2 w-25"
                            >
                              Confirmar
                            </button>
                      }
                    </div>
                  </>
                  :
                  <div className="modal-body">
                    <div className="text-center">
                      <div className="load"></div>
                    </div>
                    <div>
                      <span>Processando</span>
                    </div>
                    <div></div>
                  </div>
              }
            </Modal.Body>
          </>
        </Modal>
        {/* END - Modal de Success */}

        {/* Modal de detalhes */}
        <Modal
          className="modal-custom exportERM-details"
          show={detailsModal !== false}
          onHide={() => {
            setCurrentIdentity(null);
            setDetailsModal(false);
          }}
          size="lg"
          aria-labelledby="contained-modal-title-vcenter"
        >
          <>
            <DetailsExportERM
              id={currentIdentity}
              setDetailsModal={setDetailsModal}
            />
          </>
        </Modal>
        {/* END - Modal de detalhes */}

        {/* Modal de confirmação para o exportar */}
        <Modal
          size="lg"
          show={modalShowConfirmation}
          onHide={() => { setModalShowConfirmation(false) }}
          aria-labelledby="contained-modal-title-vcenter"
          className="modal-confirm loading-modal"
        >
          <Modal.Body className='modal-body text-center sucess-pos'>
            <>
              <div>
                <FontAwesomeIcon
                  icon={["fal", "question-circle"]}
                  size="5x"
                  style={{ fontSize: "7.5em", color: "#163264" }}
                />
              </div>
              <div>
                Tem certeza que deseja agendar a exportação do Fornecedor {currentIdentity}
              </div>
              <div className='space-modal'>
                <button className="btn btn-outline-primary mx-2"
                  onClick={() => {
                    setModalShowConfirmation(false);
                  }}>
                  Recusar
                </button>
                <button
                  onClick={() => {
                    exportByUid()
                    setModalShowConfirmation(false);
                  }}
                  data-btn="excludeItem"
                  className="btn btn-primary mx-2"
                >
                  Confirmar
                </button>

              </div>
            </>
          </Modal.Body>
        </Modal>
        {/* END - Modal de confirmação para o exportar */}

        <ModalShow
          modalShow={modalShow}
          setModalShow={setModalShow}
          modalMessage={modalMessage}
          setModalMessage={setModalMessage}
          modalLog={modalLog}
          setModalLog={setModalLog}
        />
      </>
    );
  } else if ((listERPSuppliers === null || listERPSuppliers.length === 0) && loading === true) {
    return (
      <>
        <div className="table-default bills-receive-table loading not-found">
          <div className="table-container">
            <BootstrapTable
              bootstrap4
              keyField="id"
              data={loadingProducts}
              hover={true}
              columns={loadingColumns}
              striped={true}
            />
          </div>
        </div>
      </>
    )
  } else {
    return (
      <>
        <div className="table-default bills-receive-table not-found">
          <div className="table-container">
            {/*             <div className="table-title">
              <h3 className="">Fornecedores</h3>
            </div> */}

            <BootstrapTable
              bootstrap4
              keyField="id"
              data={notFoundProducts}
              hover={true}
              columns={notFoundColumns}
              striped={true}
            />
          </div>
        </div>
      </>
    );
  }
}

export default TableBillsReceive;
