import React from 'react';

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css";
import "react-bootstrap-table-next/dist/react-bootstrap-table2.css";
import BootstrapTable from "react-bootstrap-table-next";
//import paginationFactory from 'react-bootstrap-table2-paginator';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import Pagination from '../../../../components/Pagination/Pagination';

export interface propInfo {
    info: any;
    loading: any;
    printReport: any;
    exportReport: any;
    totalRows: any;
    pageCount: any;
    setPageCount: any;
    rowsPerPage: any;
    setRowsPerPage: any;
    loadButton: any;
    t?: any;
}

const TableSalesReport: React.FC<propInfo> = ({
    info, loading, printReport, exportReport, totalRows, pageCount, setPageCount, rowsPerPage, setRowsPerPage, loadButton, t
}) => {
    const printBills = (e: any) => {

    }

    /* dados e funções da coluna  */

    function addReservation(cell: any, row: any) {
        return (
            <div className='d-flex align-items-center flex-column' style={{ maxWidth: '100px' }}>
                <div className="d-flex">
                    {cell}
                </div>
                <div className={
                    row.status === "Confirmado"
                        ? "bg-ativo" :
                        row.status === "Pendente" || row.status === "Cancelado"
                            ? "bg-inativo"
                            : "bg-analise"
                }>
                    {row.status}
                </div>

            </div>
        );
    }

    function addDate(cell: any, row: any) {
        return (
            <div>
                <div className="d-flex">
                    {cell?.split(' ')[0]}
                </div>
            </div>
        );
    }

    function addClient(cell: any, row: any) {
        return (
            <div>
                {textReduce(cell)}
                <div>                    
                    <span style={{ fontSize: "12px" }}>
                        {textReduce(`${t("salesReport.promoter")}: ${row.promotor}`)}
                    </span>
                </div>
            </div>
        );
    }
    
    function addMethod(cell: any, row: any) {
        return (
            <div>
                <div className="d-flex" style={{ fontSize: "14px" }}>
                    {textReduce(cell)}
                </div>
                {row.bandeira !== null
                    ?
                    <div>
                        {row.formapag === 'PIX' ||  row.formapag === 'Faturado'
                         ?
                        <span style={{ fontSize: "12px" }}>
                            {textReduce(`${row.bandeira}`)}                            
                        </span>
                        :
                        <span style={{ fontSize: "12px" }}>
                            {textReduce(`${t("salesReport.flag")}: ${row.bandeira}`)}                            
                        </span>
                        }
                    </div>
                    :
                    <></>
                }
            </div>
        );
    }

    function addPayment(cell: any, row: any) { 
        return (
            <div className='d-flex align-items-center flex-column' style={{ maxWidth: '100px' }}>
                <div className="d-flex" style={{ fontSize: "14px" }}>
                    {parseFloat(cell).toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })}
                </div>
                <div className={
                    row.pagamento === 'Concluído'
                        ? "bg-inativo"
                        : "bg-ativo"
                }>
                    {row.pagamento}
                </div>
            </div>
        );
    }

    function addService(cell: any, row: any) {

        return (
            <div>
                <div className="d-flex" style={{ fontSize: "14px" }}>
                    {cell?.split(' ')[0]}
                </div>
                <div className=''>
                    <span style={{ fontSize: "12px" }}>
                    {t("salesReport.service")}:
                        <strong>
                            {
                                row.type
                            }
                        </strong>
                    </span>
                </div>
            </div>
        );
    }

    function textReduce(cell: any) {
        return (
            <>
                <OverlayTrigger
                    placement="top-start"
                    overlay={
                        <Tooltip id="tooltip">{cell}</Tooltip>
                    }>
                    <div className="d-flex" style={{ fontSize: "14px" }}>
                        <span className='text-short'>
                            <span className="text-nowrap-custom">
                                {cell}
                            </span>
                        </span>
                    </div>
                </OverlayTrigger>
            </>
        )
    }

    const columns = [
        { dataField: "reserva", text: t("salesReport.reservation"), formatter: addReservation },
        { dataField: "dataReserva", text: t("salesReport.reservationDate"), formatter: addDate },
        { dataField: "dataServico", text: t("salesReport.service"), formatter: addService },
        { dataField: "productNome", text: t("salesReport.product"), formatter: textReduce },
        { dataField: "nomeClient", text: t("salesReport.client")/* , formatter: addClient */ },
        { dataField: "agentName", text: t("salesReport.agent"), formatter: addClient },
        { dataField: "formapag", text: t("salesReport.paymentType"), formatter: addMethod },
        { dataField: "valor", text: t("salesReport.payment"), formatter: addPayment }
    ];

    /*--------------------------------------------------*/

    /* In case of empty table*/
    const notFoundColumns = [{ dataField: "contas", text: t("salesReport.reports") }];

    const notFoundProducts = [{ contas: t("salesReport.reportNotFound") }];
    /* [END] In case of empty table*/

    /* loading table */

    function loadingFunc() {
        return (
            <>
                <div className="animated-background row-loading"></div>
            </>
        )
    }

    const loadingColumns = [{ dataField: "contasd", text: t("salesReport.loadingReports"), formatter: loadingFunc }];

    const loadingProducts = [
        { contas: "" }, { contas: "" }, { contas: "" }, { contas: "" }, { contas: "" },
        { contas: "" }, { contas: "" }, { contas: "" }, { contas: "" }, { contas: "" }
    ];
    /* [END] loading table */
    /*[END] dados da coluna */

    /* const customTotal = (from: any, to: any, size: any) => (
        <span className="react-bootstrap-table-pagination-total">
            Showing {from} to {to} of {size} Results
        </span>
    ); */

    /* const paginationOption = {
        paginationSize: 4,
        pageStartIndex: 0,
        firstPageText: 'First',
        prePageText: 'Back',
        nextPageText: 'Next',
        lastPageText: 'Last',
        nextPageTitle: 'First page',
        prePageTitle: 'Pre page',
        firstPageTitle: 'Next page',
        lastPageTitle: 'Last page',
        showTotal: true,
        paginationTotalRenderer: customTotal,
        disablePageTitle: true,
        sizePerPageList: [{
            text: '10', value: 10
        }, {
            text: '20', value: 20
        }, {
            text: '30', value: 30
        }] // A numeric array is also available. the purpose of above example is custom the text
    }; */

    const rowClasses = (row: any, rowIndex: any) => {
        return 'border-normal'
    };

    if (info !== null && (info?.length > 0) && loading === false) {
        return (
            <>
                <div className="table-default sales-report-table">
                    <div className="table-container">
                        <div>
                            <div className="d-flex justify-content-between">
                                <div className="d-flex flex-wrap align-items-center" style={{ gap: "15px" }}>
                                    <div>
                                        <button className="d-flex align-items-center btn btn-bills" onClick={printReport}>
                                            {
                                                loadButton[0] === true
                                                    ?
                                                    <div className="load" style={{ marginRight: "5px" }}></div>
                                                    :
                                                    <FontAwesomeIcon
                                                        file-invoice
                                                        icon={["fal", "print"]}
                                                        size="1x"
                                                        style={{ marginRight: "5px" }}
                                                    />
                                            }
                                            {t("salesReport.print")}
                                        </button>
                                    </div>
                                    <div>
                                        <button className="d-flex align-items-center btn btn-bills" onClick={exportReport}>
                                            {
                                                loadButton[1] === true
                                                    ?
                                                    <div className="load" style={{ marginRight: "5px" }}></div>
                                                    :
                                                    <FontAwesomeIcon
                                                        icon={["fal", "share"]}
                                                        size="1x"
                                                        style={{ marginRight: "5px" }}
                                                    />
                                            }
                                            {t("salesReport.export")}
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <BootstrapTable
                            bootstrap4
                            keyField="id"
                            data={info}
                            columns={columns}
                            striped={true}
                            //pagination={paginationFactory(paginationOption)}
                            rowClasses={rowClasses}
                        />
                        <Pagination
                            totalRows={totalRows}
                            pageCount={pageCount}
                            setPageCount={setPageCount}
                            rowsPerPage={rowsPerPage}
                            setRowsPerPage={setRowsPerPage}
                            selectNumberRows="yes"
                        />

                    </div>
                </div>
            </>
        );
    } else if (info === null || loading === true) {
        return (
            <>
                <div className="table-default bills-receive-table loading not-found">
                    <div className="table-container">
                        <div className="d-flex justify-content-between">
                            <div className="d-flex flex-wrap align-items-center" style={{ gap: "15px" }}>
                                <div>
                                    <button className="btn btn-bills" onClick={printBills}>
                                        <FontAwesomeIcon
                                            file-invoice
                                            icon={["fal", "print"]}
                                            size="1x"
                                            style={{ marginRight: "5px" }}
                                        />
                                        {t("salesReport.print")}
                                    </button>
                                </div>
                                <div>
                                    <button className="btn btn-bills">
                                        <FontAwesomeIcon
                                            icon={["fal", "share"]}
                                            size="1x"
                                            style={{ marginRight: "5px" }}
                                        />
                                        {t("salesReport.export")}
                                    </button>
                                </div>
                            </div>
                        </div>
                        <BootstrapTable
                            bootstrap4
                            keyField="id"
                            data={loadingProducts}
                            hover={true}
                            columns={loadingColumns}
                            striped={true}
                            rowClasses={rowClasses}
                        />
                    </div>
                </div>
            </>
        )
    } else {
        return (
            <>
                <div className="table-default bills-receive-table not-found">
                    <div className="table-container">
                        <div className="table-title">
                            <h3 className="">{t("salesReport.report")}</h3>
                        </div>

                        <BootstrapTable
                            bootstrap4
                            keyField="id"
                            data={notFoundProducts}
                            hover={true}
                            columns={notFoundColumns}
                            striped={true}
                        />
                    </div>
                </div>
            </>
        );
    }
}

export default TableSalesReport;