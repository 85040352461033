import React, { Key } from 'react';
import { Row, Table } from 'react-bootstrap';

import "react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css";
import "react-bootstrap-table-next/dist/react-bootstrap-table2.css";

export interface propInfo {
    info: any;
    loading: any;
    typeDate: any;
}

const TableDailyReceipt: React.FC<propInfo> = ({
    info, loading, typeDate
}) => {

    // useEffect(() => {
 
    // }, [])

    /* function formatValue(cell: any) {
        //console.log(Math.sign(cell))
        if(cell && cell !== '') {
          return cell.toFixed(2).replace(".", ",");
        } else {
          return '0,00';
        }
    } */

    /* dados e funções da coluna  */

    /*converte a data UTC para a data local do usuário*/
    /* function convertUTCDateToLocalDate(date: any) {
        var newDate = new Date(date.getTime() + date.getTimezoneOffset() * 60 * 1000);

        //var offset = date.getTimezoneOffset() / 60;
        //var hours = date.getHours();

        //newDate.setHours(hours - offset);

        return newDate.toLocaleString().split(' ')[0];
    } */

    /* function convertDate(cell: any, row: any) {
        var date = (cell) ? convertUTCDateToLocalDate(new Date(cell)) : '';
        return (
          <>
            {date}
          </>
        );
    } */
    
    function convertValue(cell: any) {
        //console.log(Math.sign(cell))
        return (
          <>
            <span className={Math.sign(cell) === -1 ? "negative" : ""}>{cell.toFixed(0).replace(".", ",")}</span>
          </>
        )
    }

    function convertPercentage(cell: any) {
        //console.log(Math.sign(cell))
        return (
          <>
            <span className={Math.sign(cell) === -1 ? "negative" : ""}>{cell.toFixed(2).replace(".", ",")} %</span>
          </>
        )
    }

    // const columns = [
    //     { dataField: "descricao", text: "R$" },
    //     { dataField: "realizado", text: "Realizado", formatter: convertValue },
    //     { dataField: "projetado", text: "Projetado", formatter: convertValue },
    // ];

    /*--------------------------------------------------*/

    /* In case of empty table*/
   /*  const notFoundColumns = [{ dataField: "contas", text: "Relatórios" }];

    const notFoundProducts = [{ contas: "Nenhum relatório encontrado" }]; */
    /* [END] In case of empty table*/

    /* loading table */

   /*  function loadingFunc() {
        return (
            <>
                <div className="animated-background row-loading"></div>
            </>
        )
    } */

    //const loadingColumns = [{ dataField: "contasd", text: "Carregando relatórios", formatter: loadingFunc }];

    const loadingProducts = [
        { contas: "" }, { contas: "" }, { contas: "" }, { contas: "" }, { contas: "" },
        { contas: "" }, { contas: "" }, { contas: "" }, { contas: "" }, { contas: "" }
    ];
    /* [END] loading table */
    /*[END] dados da coluna */

    const rowClasses = (row: any, index: any) => {
        return index === 1 ? 'table-total' : index === 2 ? 'table-subtotal' : 'border-normal'
    };

    return (
        <>
            <Row >
                <div className="table-default dailyreceipt-report-table">
                    <div className="table-container">
                        {/* <BootstrapTable
                            bootstrap4
                            keyField="id"
                            data={salesInfo}
                            columns={columns}
                            striped={true}
                            //pagination={paginationFactory(paginationOption)}
                            rowClasses={rowClasses}
                        /> */}
                        
                        <Table bordered>
                            <thead>
                            <tr>
                                <th>R$</th>
                                <th>Realizado</th>
                                <th>{typeDate === '3' ? 'Projetado' : ''}</th>
                            </tr>
                            </thead>
                            <tbody>
                            {info !== null && loading !== true ?
                                <>
                                    <tr className={rowClasses(info,1)}>
                                        <th>(=) Total de Vendas</th>
                                        <td>{convertValue(info.realizado.totalReceber)}</td>
                                        <td>{typeDate === '3' ? convertValue(info.projetado.totalReceber) : ''}</td>
                                    </tr>
                                    <tr className={rowClasses(info,1)}>
                                        <th>Pax</th>
                                        <td>{info.totalPax}</td>
                                        <td></td>
                                    </tr>
                                    <tr className={rowClasses(info,2)}>
                                        <td>Ticket Médio da Reserva</td>
                                        <td>{convertValue(info.ticketMedio)}</td>
                                        <td></td>
                                    </tr>
                                    <tr className={rowClasses(info,1)}>
                                        <th>(-) Fornecedores</th>
                                        <td>{convertValue(info.realizado.totalPagar)}</td>
                                        <td>{typeDate === '3' ? convertValue(info.projetado.totalPagar) : ''}</td>
                                    </tr>
                                    <tr className={rowClasses(info,1)}>
                                        <th>(=) Receita Bruta</th>
                                        <td>{convertValue(info.realizado.totalBruto)}</td>
                                        <td>{typeDate === '3' ? convertValue(info.projetado.totalBruto) : ''}</td>
                                    </tr>
                                    <tr className={rowClasses(info,1)}>
                                        <th>Margem Bruta %</th>
                                        <td>{convertPercentage(info.realizado.taxaBruto)}</td>
                                        <td>{typeDate === '3' ? convertPercentage(info.projetado.taxaBruto) : ''}</td>
                                    </tr>
                                    <tr className={rowClasses(info,2)}>
                                        {/* <td>(-) Taxa Cartão</td> 
                                        <td>{convertValue(info.realizado.totalMdr)}</td>
                                        <td>{typeDate === '3' ? convertValue(info.projetado.totalMdr) : ''}</td> */}
                                        <td>(-) ISS ({convertPercentage(info.issTax)})</td>
                                        <td>{convertValue(info.realizado.totalIss)}</td>
                                        <td>{typeDate === '3' ? convertValue(info.projetado.totalIss) : ''}</td>
                                    </tr>
                                    <tr className={rowClasses(info,2)}>
                                        {/* <td>(-) DAS Simples</td> 
                                        <td>{convertValue(info.realizado.totalDas)}</td>
                                        <td>{typeDate === '3' ? convertValue(info.projetado.totalDas) : ''}</td> */}
                                        <td>(-) PIS COFINS ({convertPercentage(info.pisCofinsTax)})</td>
                                        <td>{convertValue(info.realizado.totalPisCofins)}</td>
                                        <td>{typeDate === '3' ? convertValue(info.projetado.totalPisCofins) : ''}</td>
                                    </tr>
                                    {/* <tr className={rowClasses(info,2)}>
                                        <td>(-) Impostos</td>
                                        <td>{convertValue(info.realizado.totalImposto)}</td>
                                        <td>{typeDate === '3' ? convertValue(info.projetado.totalImposto) : ''}</td>
                                    </tr> */}
                                    <tr className={rowClasses(info,1)}>
                                        <th>(=) Receita Líquida</th>
                                        <td>{convertValue(info.realizado.totalLiquido)}</td>
                                        <td>{typeDate === '3' ? convertValue(info.projetado.totalLiquido) : ''}</td>
                                    </tr>
                                    <tr className={rowClasses(info,1)}>
                                        <th>Margem Líquida %</th>
                                        <td>{convertPercentage(info.realizado.taxaLiquido)}</td>
                                        <td>{typeDate === '3' ? convertPercentage(info.projetado.taxaLiquido) : ''}</td>
                                    </tr>
                                </>
                            : <>
                            {loadingProducts.map((info: any, index: Key) => (
                                <tr key={index}>
                                    <td colSpan={3} className="text-primary animated-background"></td>
                                </tr>
                                ))}
                            </>}
                            </tbody>
                        </Table>
                    </div>
                </div>
                
            </Row>
        </>

    );
}

export default TableDailyReceipt;