/* eslint-disable @typescript-eslint/no-unused-vars */
import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Dropdown, OverlayTrigger, Tooltip } from "react-bootstrap";
import Pagination from "../../../../components/Pagination/Pagination";
import BootstrapTable from "react-bootstrap-table-next";

import './CommissionsTable.scss';

export interface propInsert {
  commissions: any;
  totalRows: any;
  pageCount: any;
  setPageCount: any;
  rowsPerPage: any;
  setRowsPerPage: any;
  actualDate: any; 
  loading: any;
  printCommissions: any;
  exportCommissions: any;
  t?: any;
}

const CommissionsTable: React.FC<propInsert> = ({
  commissions, totalRows, pageCount, setPageCount, rowsPerPage, setRowsPerPage, actualDate, loading, printCommissions, exportCommissions, t
}: propInsert) => { 

  //const [editClick, setEditClick] = useState<any>(false);
  //const [detailsModal, setDetailsModal] = useState<any>(false);
  //const [currentCoupom, setCurrentCoupom] = useState<any>();

  /* function seeDetailsEdit(cell: any) {
    setEditClick(true);
    setCurrentCoupom(cell);
    setDetailsModal(true);
  } */

  const columns = [
    { dataField: "reservaC2", text: `${t("commissions.table.reservationNumber")}`},
    { dataField: "productName", text: `${t("commissions.table.product")}`, formatter: textReduce},
    { dataField: "status", text: `${t("commissions.table.serviceStatus")}`, formatter: addStatus},
    { dataField: "dataCheckout", text: `${t("commissions.table.date")}`, formatter: convertDate},
    { dataField: "nomeTodoRevelado", text: `${t("commissions.table.mainPax")}`},
    { dataField: "quantidadePax", text: `${t("commissions.table.quantity")}`},
    { dataField: "amount", text: `${t("commissions.table.value")}`, formatter: convertValue},
    { dataField: "comission", text: `${t("commissions.table.commission")}`, formatter: convertValue},
    { dataField: "datavencimento", text: `${t("commissions.table.expiration")}`, formatter: convertDate},
    //{ dataField: "idNewItems", text: `${t("commissions.table.actions")}`, formatter: addActionButton}
  ];

  /* loading table */

  function textReduce(cell: any) {
    return (
      <>
        <OverlayTrigger
          placement="top-start"
          overlay={
            <Tooltip id="tooltip">{cell}</Tooltip>
          }>
          <div className="d-flex" style={{ fontSize: "14px" }}>
            <span className='text-short'>
              <span className="text-nowrap-custom">
                {cell}
              </span>
            </span>
          </div>
        </OverlayTrigger>
      </>
    )
  }

  function loadingFunc() {
    return (
      <>
        <div className="animated-background row-loading"></div>
      </>
    )
  }

  const loadingColumns = [{ dataField: "contasd", text: `${t("commissions.table.loadCommissions")}`, formatter: loadingFunc }];

  const loadingProducts = [
    { commission: "" }, { commission: "" }, { commission: "" }, { commission: "" }, { commission: "" },
    { commission: "" }, { commission: "" }, { commission: "" }, { commission: "" }, { commission: "" }
  ];
  /* [END] loading table */

  /* In case of empty table*/
  const notFoundColumns = [{ dataField: "contas", text: `${t("commissions.table.commissions")}` }];

  const notFoundProducts = [{ commission: `${t("commissions.table.noCommissionFound")}` }];
  /* [END] In case of empty table*/

  //converção de data
  function convertDate(cell: any, row: any) {
    if (cell !== null) {
      //var date = convertUTCDateToLocalDate(new Date(cell));
      var aux: any = cell.split("T")[0].split("-");
      var date: any = `${aux[2]}/${aux[1]}/${aux[0]}`;

      return <>{date}</>;
    } else {
      return <></>;
    }
  }

  //converter valor
  function convertValue(cell: any) {
    //console.log(Math.sign(cell))
    return (
      <>
        <span className={Math.sign(cell) === -1 ? "negative" : ""}>{cell.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })}</span>
      </>
    )
  }


  // formatter de status
  function addStatus(cell: any, row: any) {
    
    return (
      <div
        className={
          cell === 1
              ? "bg-ativo m-auto"
              : cell === 2
                ? "bg-inativo m-auto"
                : "m-auto"
        }
      >
        {cell === 1 ? `${t("commissions.table.confirmed")}` : `${t("commissions.table.canceled")}`}
      </div>
    );
  }

  const rowClasses = (row: any, rowIndex: any) => {
    if (row.confirmed === "Em Análise") {
      return 'border-analise';
    } else if (row.confirmed === "Confirmado" || "Confirmed") {
      return 'border-ativo';
    } else {
      return 'border-inativo';
    }
  };

  //botao de ação
  function addActionButton(cell: any, row: any) {
    return (
      <>
        <div className="d-flex justify-content-center">
          <Dropdown drop="start">
            <Dropdown.Toggle variant="light ">
              <FontAwesomeIcon size="lg" icon={["fal", "ellipsis-h"]} />
            </Dropdown.Toggle>

            <Dropdown.Menu>
              <Dropdown.Item eventKey="1" disabled={true}>
                <FontAwesomeIcon
                  icon={["fal", "comment-alt-dollar"]}
                  className="mr-2"
                />{" "}
                {t("commissions.table.dropdown.account")}
              </Dropdown.Item>
             
              <Dropdown.Item
                eventKey="2"
                onClick={() => {
                  /* seeDetailsEdit(cell) */;
                }}
              >
                <FontAwesomeIcon icon={["fal", "edit"]} className="mr-2" />{" "}
                {t("commissions.table.dropdown.edit")}{" "}
              </Dropdown.Item>

              <Dropdown.Item eventKey="3" disabled={true}>
                <FontAwesomeIcon icon={["fal", "circle"]} className="mr-2" />{" "}
                {t("commissions.table.dropdown.status")}
              </Dropdown.Item>
              <Dropdown.Item eventKey="5" disabled={true}>
                <FontAwesomeIcon
                  icon={["fal", "file-contract"]}
                  className="mr-2"
                />{" "}
                {t("commissions.table.dropdown.contract")}
              </Dropdown.Item>
              <Dropdown.Item
                eventKey="4"
                disabled={true}
                className="text-danger remove"
              >
                <FontAwesomeIcon icon={["fal", "trash-alt"]} className="mr-2" />{" "}
                {t("commissions.table.dropdown.remove")}
              </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
        </div>
      </>
    );
  }


  /*RENDER*/
if(commissions !== null) {
  return (
    <>
      <div className="table-default commission-table coupons-table">
        <div className="table-container">
          <div>
              <div className="d-flex justify-content-between">
                <div className="d-flex flex-wrap align-items-center" style={{ gap: "15px" }}>
                <div>
                    <div style={{cursor: 'pointer'}} className="btn btn-bills" onClick={printCommissions}>
                      <FontAwesomeIcon
                        file-invoice
                        icon={["fal", "print"]}
                        size="1x"
                        style={{ marginRight: "5px" }}
                      />
                      {t("commissions.table.print")}
                    </div>
                  </div>
                   <div>
                    <div style={{cursor: 'pointer'}} className="btn btn-bills" onClick={exportCommissions} >
                      <FontAwesomeIcon
                        icon={["fal", "share"]}
                        size="1x"
                        style={{ marginRight: "5px" }}
                      />
                      {t("commissions.table.export")}
                    </div>
                  </div>                                
                </div>
              </div>
            </div>
            <BootstrapTable
              bootstrap4
              keyField="id"
              data={commissions}
              hover={true}
              columns={columns}
              striped={true}
              rowClasses={rowClasses}
            /> 

            <Pagination
            totalRows={totalRows}
            pageCount={pageCount}
            setPageCount={setPageCount}
            rowsPerPage={rowsPerPage}
            setRowsPerPage={setRowsPerPage}
            selectNumberRows="yes"
          />           
         </div>
      </div>
    </>
  );
} else if (commissions === null && loading === true){
  return (
    <>
      <div className="table-default commission-table loading not-found">
        <div className="table-container">
          <div>
              <div className="d-flex justify-content-between">
                <div className="d-flex flex-wrap align-items-center" style={{ gap: "15px" }}>
                <div>
                    <div style={{cursor: 'pointer'}} className="btn btn-bills">
                      <FontAwesomeIcon
                        file-invoice
                        icon={["fal", "print"]}
                        size="1x"
                        style={{ marginRight: "5px" }}
                      />
                      {t("commissions.table.print")}
                    </div>
                  </div>
                   <div>
                    <div style={{cursor: 'pointer'}} className="btn btn-bills"                     
                     >
                      <FontAwesomeIcon
                        icon={["fal", "share"]}
                        size="1x"
                        style={{ marginRight: "5px" }}
                      />
                      {t("commissions.table.export")}
                    </div>
                  </div>                                
                </div>
              </div>
            </div>
            <BootstrapTable
              bootstrap4
              keyField="id"
              data={loadingProducts}
              hover={true}
              columns={loadingColumns}
              striped={true}
            /> 

            <Pagination
            totalRows={totalRows}
            pageCount={pageCount}
            setPageCount={setPageCount}
            rowsPerPage={rowsPerPage}
            setRowsPerPage={setRowsPerPage}
            selectNumberRows="yes"
          />           
         </div>
      </div>
    </>
  ); 
} else{
  return(
    <>
    <div className="table-default commission-table coupons-table not-found">
      <div className="table-container">
        {/*             <div className="table-title">
          <h3 className="">Fornecedores</h3>
        </div> */}

        <BootstrapTable
          bootstrap4
          keyField="id"
          data={notFoundProducts}
          hover={true}
          columns={notFoundColumns}
        />
      </div>
    </div>
  </>
  )
}
  
}
export default CommissionsTable;
