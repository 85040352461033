import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Dropdown from "react-bootstrap/Dropdown";

import "react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css";
import "react-bootstrap-table-next/dist/react-bootstrap-table2.css";
import BootstrapTable from "react-bootstrap-table-next";
//import paginationFactory from "react-bootstrap-table2-paginator";

import Pagination from "../../../../components/Pagination/Pagination";

//STYLES
import "./AccountsTable.scss";
import { OverlayTrigger, Tooltip } from "react-bootstrap";

export interface propTable {
  accounts: any;
  totalRows: any;
  pageCount: any;
  setPageCount: any;
  rowsPerPage: any;
  setRowsPerPage: any;
  loading: any;
}

const AccountsTable: React.FC<propTable> = ({
  accounts, totalRows, pageCount, setPageCount, rowsPerPage, setRowsPerPage, loading
}: propTable) => {
  function addActionButton(cell: any, row: any) {
    return (
      <>
        <div className="d-flex justify-content-center">
          <Dropdown drop="start">
            <Dropdown.Toggle variant="light ">
              <FontAwesomeIcon
                size="lg"
                icon={["fal", "ellipsis-h"]}
              />
            </Dropdown.Toggle>

            <Dropdown.Menu>
              <Dropdown.Item
                eventKey="1"
                href={"/accounts/edit-accounts?id=" + cell}
              >
                <FontAwesomeIcon
                  icon={['fal', 'edit']}
                />
                Editar
              </Dropdown.Item>
              <Dropdown.Item
                eventKey="2"
                disabled={true}
              >
                <FontAwesomeIcon
                  icon={['fal', 'trash-alt']}
                />
                Remover
              </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
        </div>
      </>
    );
  }

  function addStatus(cell: any, row: any) {
    return (
      <div
        className={
          cell === "Ativo"
            ? "bg-ativo"
            : "bg-inativo"
        }
      >
        {cell}
      </div>
    );
  }

  function textReduce(cell: any, row: any) {
    if (cell !== null) {
      var aux = cell.split(' ')
      if (aux.length >= 4) {
        aux = aux[0] + ' ' + aux[1] + ' ' + aux[2] + ' ' + aux[3] + '...';
      } else {
        aux = cell;
      }

      return (
        <>
          <OverlayTrigger
            overlay={
              <Tooltip id="tooltip">{cell}</Tooltip>
            }>
            <span className="accounts-text-wrap-custom">
              {cell}
            </span>
          </OverlayTrigger>
        </>
      )
    } else {
      return (
        <>

        </>
      )
    }
  }

  function textReduce2(cell: any, row: any) {
    if (cell !== null) {
      var aux = cell.split(' ')
      if (aux.length >= 4) {
        aux = aux[0] + ' ' + aux[1] + ' ' + aux[2] + ' ' + aux[3] + '...';
      } else {
        aux = cell;
      }

      return (
        <>
          <OverlayTrigger
            overlay={
              <Tooltip id="tooltip">{cell}</Tooltip>
            }>
            <span className="accounts2-text-wrap-custom">
              {cell}
            </span>
          </OverlayTrigger>
        </>
      )
    } else {
      return (
        <>

        </>
      )
    }
  }

  function textReduceEmail(cell: any, row: any) {
    if (cell !== null) {
      var aux = cell.split(' ')
      if (aux.length >= 4) {
        aux = aux[0] + ' ' + aux[1] + ' ' + aux[2] + ' ' + aux[3] + '...';
      } else {
        aux = cell;
      }

      return (
        <>
          <OverlayTrigger
            overlay={
              <Tooltip id="tooltip">{cell}</Tooltip>
            }>
            <span className="accounts3-text-wrap-custom">
              {cell}
            </span>
          </OverlayTrigger>
        </>
      )
    } else {
      return (
        <>

        </>
      )
    }
  }

  /* ALTERAR OS DATAFIELDS QUANDO A API CHEGAR */

  const columns = [
    { dataField: "nomeFantasia", text: "Conta", sort: true, formatter: textReduce2 },
    { dataField: "razaoSocial", text: "Razão Social", sort: true, formatter: textReduce2 },
    { dataField: "cnpj", text: "CNPJ", sort: true, formatter: textReduce },
    { dataField: "numeroConta", text: "Nº Conta", sort: true, formatter: textReduce },
    { dataField: "agenciaConta", text: "Agencia", sort: true, formatter: textReduce },
    { dataField: "nomeGerente", text: "Gerente", sort: true, formatter: textReduce },
    { dataField: "email", text: "E-Mail", sort: true, formatter: textReduceEmail },
    { dataField: "telefone", text: "Telefone", sort: true, formatter: textReduce },
    { dataField: "tipo", text: "Tipo", sort: true },
    {
      dataField: "status",
      text: "Status",
      sort: true,
      formatter: addStatus,
    },
    { dataField: "id", text: "Ação", formatter: addActionButton },
  ];

  /* [END] ALTERAR OS DATAFIELDS QUANDO A API CHEGAR */

  /* loading table */

  function loadingFunc() {
    return (
      <>
        <div className="animated-background row-loading"></div>
      </>
    )
  }

  const loadingColumns = [{ dataField: "contasd", text: "Carregando Contas", formatter: loadingFunc }];

  const loadingProducts = [
    { contas: "" }, { contas: "" }, { contas: "" }, { contas: "" }, { contas: "" },
    { contas: "" }, { contas: "" }, { contas: "" }, { contas: "" }, { contas: "" }
  ];
  /* [END] loading table */

  /* In case of empty table*/
  const notFoundColumns = [{ dataField: "clientes", text: "Clientes" }];

  const notFoundProducts = [{ clientes: "Nenhuma conta cadastrada" }];
  /* [END] In case of empty table*/

  /*   const pagination = paginationFactory({
      page: 1,
      sizePerPage: 5,
      lastPageText: ">>",
      firstPageText: "<<",
      nextPageText: ">",
      prePageText: "<",
      //showTotal: true,
      alwaysShowAllBtns: true,
    }); */

  const rowClasses = (row: any, rowIndex: any) => {
    //console.log(row, rowIndex)
    if (row.status === "Ativo") {
      return 'border-ativo';
    } else {
      return 'border-inativo';
    }
  };

  if (accounts !== null) {
    //console.log(accounts)
    return (
      <>
        <div className="table-default accounts-table">
          <div className="table-container">
            <div className="table-title">
              <h3 className="">Contas Correntes</h3>
            </div>

            <BootstrapTable
              bootstrap4
              keyField="id"
              data={accounts}
              columns={columns}
              rowClasses={rowClasses}
              striped={true}
            />

            <Pagination
              totalRows={totalRows}
              pageCount={pageCount}
              setPageCount={setPageCount}
              rowsPerPage={rowsPerPage}
              setRowsPerPage={setRowsPerPage}
              selectNumberRows="yes"
            />
          </div>
        </div>
      </>
    );
  } else if (accounts === null && loading === true) {
    return (
      <>
        <div className="table-default accounts-table loading not-found">
          <div className="table-container">
            <BootstrapTable
              bootstrap4
              keyField="id"
              data={loadingProducts}
              hover={true}
              columns={loadingColumns}
              striped={true}
            />
          </div>
        </div>
      </>
    )
  } else {
    return (
      <>
        <div className="table-default accounts-table not-found">
          <div className="table-container">
            <div className="table-title">
              <h3 className="">Contas Correntes</h3>
            </div>

            <BootstrapTable
              bootstrap4
              keyField="id"
              data={notFoundProducts}
              hover={true}
              columns={notFoundColumns}
              striped={true}
            />
          </div>
        </div>
      </>
    );
  }
}

export default AccountsTable;
