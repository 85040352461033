import React, { useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button, Card, Col, Form, Row } from "react-bootstrap";
import { useForm } from "react-hook-form";
import api from "../../../../services/api";

import DraggableProductImputs from "../../../../components/DraggableProductImputs/DraggableProductImputs";
import ReactQuillComponent from "../../../../components/ReactQuillComponent/ReactQuillComponent";

import { useAppSelector, useAppDispatch } from "../../../../store/hooks";
import { addToComboObject } from "../../../../store/combo";

export interface operation {
    action: any;
    isEdit: any;
    editFunction?: any;
}

const SummaryAndDetails: React.FC<operation> = React.memo(({
    action, isEdit = false, editFunction = () => { }
}: operation) => {
    /* Controle Redux  */
    const dispatch = useAppDispatch();
    const reduxComboObject: any = JSON.parse(JSON.stringify(useAppSelector((state: any) => state.combo))).combo;
    /* Controle Redux  */

    const [summaryPT, setSummaryPT] = useState<string>("");
    const [summaryEN, setSummaryEN] = useState<string>("");
    const [summaryES, setSummaryES] = useState<string>("");

    const [allowEditSummary, setAllowEditSummary] = useState<boolean>(false);
    const [allowEditDetails, setAllowEditDetails] = useState<boolean>(false);

    const [detailsArray, setDetailsArray] = useState<any>([
        {
            PT: "",
            EN: "",
            ES: "",
        },
    ]);

    const {
        control,
        handleSubmit,
        setValue,
        getValues,
        setError,
        clearErrors,
        formState: { errors },
    } = useForm({});

    useEffect(() => {
        window.scrollTo(0, 0);
        if (/* reduxComboObject?.summary !== undefined &&  */reduxComboObject?.summary?.length > 0) {
            setSummaryPT(reduxComboObject.summary[0].summary[0].text);
            setValue("summaryPT", reduxComboObject.summary[0].summary[0].text);
            setSummaryEN(reduxComboObject.summary[0].summary[1].text);
            setValue("summaryEN", reduxComboObject.summary[0].summary[1].text);
            setSummaryES(reduxComboObject.summary[0].summary[2].text);
            setValue("summaryES", reduxComboObject.summary[0].summary[2].text);
        }

        if (reduxComboObject?.details !== undefined) {
            let aux: any = [];

            for (let i: number = 0; i < reduxComboObject[`details`].length; i++) {
                aux.push({
                    PT: reduxComboObject[`details`][i][0].detail.text,
                    EN: reduxComboObject[`details`][i][1].detail.text,
                    ES: reduxComboObject[`details`][i][2].detail.text,
                });
            }

            setDetailsArray(() => aux);

            for (let i: number = 0; i < reduxComboObject[`details`].length; i++) {
                setValue(`detailsPT-${i}`, reduxComboObject[`details`][i][0].detail.text);
                setValue(`detailsEN-${i}`, reduxComboObject[`details`][i][1].detail.text);
                setValue(`detailsES-${i}`, reduxComboObject[`details`][i][2].detail.text);
            }
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const handleBlurSummary = (e: any) => {
        if (allowEditSummary === false) {
            let ptText: any = getValues().summaryPT;
            TranslateTextEN(ptText);
            TranslateTextES(ptText);
        }
    };

    const TranslateTextEN = async (name: string) => {
        if (name !== undefined) {
            try {
                const res = await api.post(`https://translation.googleapis.com/language/translate/v2?key=${process.env.REACT_APP_GOOGLE_CLOUD_TRANSLATE_API}`, {
                    "q": name,
                    "source": "pt-BR",
                    "target": "en",
                    "format": "text"
                });
                if (res.status !== 400) {
                    setSummaryEN(res?.data?.data?.translations[0]?.translatedText);
                    setValue("summaryEN", res?.data?.data?.translations[0]?.translatedText);
                }
            } catch (error: any) { }
        }
    };

    const TranslateTextES = async (name: string) => {
        if (name !== undefined) {
            try {
                const res = await api.post(`https://translation.googleapis.com/language/translate/v2?key=${process.env.REACT_APP_GOOGLE_CLOUD_TRANSLATE_API}`, {
                    "q": name,
                    "source": "pt-BR",
                    "target": "es",
                    "format": "text"
                });
                if (res.status !== 400) {
                    setSummaryES(res?.data?.data?.translations[0]?.translatedText);
                    setValue("summaryES", res?.data?.data?.translations[0]?.translatedText);
                }
            } catch (error: any) { }
        }
    };

    let numberPT: any = summaryPT?.replaceAll("<p>", "").replaceAll("</p>", "").replaceAll("<br>", "").replaceAll("<strong>", "").replaceAll("</strong>", "").length;
    let numberES: any = summaryES?.replaceAll("<p>", "").replaceAll("</p>", "").replaceAll("<br>", "").replaceAll("<strong>", "").replaceAll("</strong>", "").length;
    let numberEN: any = summaryEN?.replaceAll("<p>", "").replaceAll("</p>", "").replaceAll("<br>", "").replaceAll("<strong>", "").replaceAll("</strong>", "").length;

    const onSubmit = (data: any) => {
        const comboCode = isEdit ? window.location.href.split('comboCode=')[1] : undefined;
        let dataToInsert: any = {};

        dataToInsert.summary = [
            {
                comboCode: comboCode,
                summary: [
                    {
                        text: summaryPT,
                        lang: 1
                    },
                    {
                        text: summaryEN,
                        lang: 2
                    },
                    {
                        text: summaryES,
                        lang: 3
                    }
                ]
            }
        ]

        dataToInsert.details = detailsArray.filter((e: any) => e !== null).map((e: any) => [
            { comboCode: comboCode, detail: { text: e.PT, lang: 1 } },
            { comboCode: comboCode, detail: { text: e.EN, lang: 2 } },
            { comboCode: comboCode, detail: { text: e.ES, lang: 3 } },
        ])

        if (isEdit) {
            editFunction(dataToInsert);
        } else {
            dispatch(addToComboObject(JSON.stringify(dataToInsert)));
            action("descriptionsAndHigh");
        }
    };

    return (
        <>
            {
                (isEdit === false || reduxComboObject?.name) ?
                    <Col md={9} lg={10}>
                        <Card className="pt-content summary">
                            <Form
                                className="h-100"
                                noValidate
                                validated={false}
                                onSubmit={handleSubmit(onSubmit)}
                            >
                                <h3 className="text-primary pt-title mb-0">Resumo e Detalhes</h3>
                                <div className="pt-content-info">
                                    <Row>
                                        <div>
                                            <div className="d-flex justify-content-between">
                                                <h5 className="mb-2 pt-subtitle">Resumo</h5>
                                                <div
                                                    className="btn-edit ml-2"
                                                    style={{ position: "unset" }}
                                                    onClick={() =>
                                                        setAllowEditSummary(!allowEditSummary)
                                                    }
                                                >
                                                    <FontAwesomeIcon
                                                        icon={["fal", "edit"]}
                                                        size="1x"
                                                        className="mr-2"
                                                    />
                                                    Editar
                                                </div>
                                            </div>
                                            <p className="small mb-0">
                                                Crie um texto curto para contar a atividade de forma clara
                                                e atrativa.
                                            </p>
                                            <p className="small mb-0">
                                                Sugerimos que adicione as seguintes palavras mais buscadas
                                                pelos passageiros no Google: ingresso, excursão, passeio e
                                                atração.
                                            </p>
                                        </div>

                                        <ReactQuillComponent
                                            setValue={setValue}
                                            control={control}
                                            handleBlur={handleBlurSummary}
                                            name="summaryPT"
                                            title="Resumo do Combo (Português)"
                                            errors={errors}
                                            setState={setSummaryPT}
                                            state={summaryPT}
                                            isEdit={isEdit}
                                            size={12}
                                            numberOfCaracters={numberPT}
                                            maxLength={240}
                                            minLength={100}
                                            id="portugueseText"
                                        />

                                        <ReactQuillComponent
                                            setValue={setValue}
                                            control={control}
                                            name="summaryEN"
                                            title="Resumo do Combo (Inglês)"
                                            errors={errors}
                                            setState={setSummaryEN}
                                            state={summaryEN}
                                            isEdit={isEdit}
                                            size={6}
                                            numberOfCaracters={numberEN}
                                            maxLength={240}
                                            minLength={100}
                                            id="englishText"
                                            style={allowEditSummary === false ? { backgroundColor: "#e9ecef", opacity: "1" } : {}}
                                        />

                                        <ReactQuillComponent
                                            setValue={setValue}
                                            control={control}
                                            name="summaryES"
                                            title="Resumo do Combo (Espanhol)"
                                            errors={errors}
                                            setState={setSummaryES}
                                            state={summaryES}
                                            isEdit={isEdit}
                                            size={6}
                                            numberOfCaracters={numberES}
                                            maxLength={240}
                                            minLength={100}
                                            id="spanishText"
                                            style={allowEditSummary === false ? { backgroundColor: "#e9ecef", opacity: "1" } : {}}
                                        />
                                    </Row>
                                </div>

                                <div className="pt-content-info" style={{ border: "none" }}>
                                    <Row>
                                        <div>
                                            <div className="d-flex justify-content-between">
                                                <h5 className="mb-2 pt-subtitle">Detalhes</h5>
                                                <div
                                                    className="btn-edit ml-2"
                                                    style={{ position: "unset" }}
                                                    onClick={() =>
                                                        setAllowEditDetails(!allowEditDetails)
                                                    }
                                                >
                                                    <FontAwesomeIcon
                                                        icon={["fal", "edit"]}
                                                        size="1x"
                                                        className="mr-2"
                                                    />
                                                    Editar
                                                </div>
                                            </div>

                                            <p className="small mb-0">
                                                Insira tudo que um viajante precisa saber antes de comprar
                                                seu produto.
                                            </p>
                                            <p className="small mb-0">
                                                Pense em logística, restições e requisitos.
                                            </p>
                                        </div>

                                        <DraggableProductImputs
                                            items={detailsArray}
                                            setItems={setDetailsArray}
                                            tourObject={{ isTranslate: 1 }}
                                            allowEditItems={allowEditDetails}
                                            portuguese={`detailsPT`}
                                            english={"detailsEN"}
                                            spanish={"detailsES"}
                                            address={"details"}
                                            control={control}
                                            setValue={setValue}
                                            errors={errors}
                                            minLength={1}
                                            maxLength={100}
                                            setError={setError}
                                            clearErrors={clearErrors}
                                        />
                                    </Row>
                                </div>

                                <div className="h-100 d-flex justify-content-end align-items-end">
                                    {isEdit !== true ? (
                                        <Row className="mx-0 mt-5">
                                            <Button
                                                variant="outline-primary"
                                                className="form-button mr-3"
                                                onClick={() => action("operation")}
                                            >
                                                Voltar
                                            </Button>
                                            <Button type="submit" className="form-button">
                                                Continuar
                                            </Button>
                                        </Row>
                                    ) : (
                                        <Row className=" mx-0 mt-5">
                                            <Button type="submit" className="form-button">
                                                Salvar
                                            </Button>
                                        </Row>
                                    )}
                                </div>
                            </Form>
                        </Card>
                    </Col>
                    :
                    <>
                        <Col md={9} lg={10}>
                            <Card className="pt-content h-100">
                                <div className="loading-modal h-100 d-flex flex-column justify-content-center align-items-center">
                                    <div className="load mb-4"></div>
                                    <div>Carregando dados do produto</div>
                                </div>
                            </Card>
                        </Col>
                    </>
            }
        </>
    );
}
);

export default SummaryAndDetails;
