import React from "react";
import api from "../../services/api";
import DropdownSearchMulti from "./DropdownSearchMulti/DropdownSearchMulti";

export interface propProduct {
    //setCode?: any;
    //propsField: any;
    //propsErrors: any;
    error: any;
    propsLabel: any;
    value: any;
    setValue: any;
}

const SearchProductModalityMulti: React.FC<propProduct> = ({error,propsLabel,value,setValue}: propProduct) => {

    const searchTours = async (searchTerm: any) => {

        const response = await api.post(
            `${process.env.REACT_APP_SERVER_URL_API}/Products/GetAllToursFilter`,
            //`${process.env.REACT_APP_SERVER_URL_API}/Products/GetToursWithModalityFilter`,
            {
                term: searchTerm,
                // channel: 3,
                // lang: lng,
                // ProductType: 0,
            }
        );

        if (response.data.data !== null) {
            var data = response.data.data;

            data = data.map((a: any) => ({code: a.productCode, name: a.name}));
            
            return data;
        }
    };

    /* END - Search Tour */

        return (
            <>
                {/* Search Tour */}
                <DropdownSearchMulti error={error} propsLabel={propsLabel} value={value} setValue={setValue} search={searchTours} />
                {/* END - Search Tour */}
            </>
        );
    
}

export default SearchProductModalityMulti;