import React from 'react';

//COMPONENTS
import Breadcrumb from '../../components/Breadcrumb/Breadcrumb';

import RegisterUsers from './components/RegisterUsers/RegisterUsers';
import DesktopDefault from '../../templates/DesktopDefault';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useTranslation } from 'react-i18next';

function NewUser() {
    const { t } = useTranslation();

    return (
        <>
            <DesktopDefault>
                <div className="container-fluid content-dashboard">

                    <Breadcrumb title={`${t("editUser.newUser.path")}`} />

                    <div className="register-users mt-4">
                        <div className="d-flex align-items-center">
                            <span className='back' onClick={() => { window.location.href = '/users' }}>
                                <FontAwesomeIcon
                                    icon={["fal", "angle-double-left"]}
                                    style={{ margin: "0 5px 0 0", fontSize: '16px' }}
                                />
                                {t("editUser.newUser.back")}</span>
                        </div>
                        <h2 className="title">{t("editUser.newUser.title")}</h2>
                    </div>

                    <RegisterUsers />

                </div>

            </DesktopDefault>
        </>

    );
}

export default NewUser;