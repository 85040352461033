import React from "react";

import Modal from 'react-bootstrap/Modal';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import "./ModalConfirm.scss";

function ModalConfirm(props: any) {
    
    const [modalShow, setModalShow] = React.useState(false);

    function handleReload() {
        if(props.customReload) {
            props.customReload();
            setModalShow(false);
        } else {
            window.location.reload();
        }
    }

    return (
        <>
            <Modal
                {...props}
                size="lg"
                show={modalShow}
                onHide={() => setModalShow(false)}
                aria-labelledby="contained-modal-title-vcenter"
                className="modal-confirm loading-modal"
            >
                <Modal.Body className='modal-body text-center sucess-pos'>
                    {
                        props.modalContent !== "loading"
                            ?
                            <>
                                <div>
                                    {
                                        props.log === 1 || props.log === "1"
                                            ?
                                            <FontAwesomeIcon
                                                icon={["fal", "times-circle"]}
                                                size="5x"
                                                className="text-primary"
                                                style={{ fontSize: "7.5em" }}
                                            />
                                            :
                                            props.log === 0 || props.log === "0"
                                                ?
                                                <FontAwesomeIcon
                                                    icon={["fal", "check-circle"]}
                                                    size="5x"
                                                    className="text-primary"
                                                    style={{ fontSize: "7.5em" }}
                                                />
                                                :
                                                <FontAwesomeIcon
                                                    icon={["fal", "question-circle"]}
                                                    size="5x"
                                                    className="text-primary"
                                                    style={{ fontSize: "7.5em" }}
                                                />
                                    }
                                </div>
                                <div>
                                    {props.confirm}
                                </div>
                                <div className="d-flex justify-content-center">
                                    {
                                        props.modalContent === 'success'
                                            ?
                                            <button
                                                onClick={handleReload}
                                                className="btn btn-primary mx-2 w-25"
                                            >
                                                Confirmar
                                            </button>
                                            :
                                            props.modalContent === 'question'
                                                ?
                                                <>
                                                    <button
                                                        onClick={() => setModalShow(false)}
                                                        className="btn btn-primary mx-2 w-25"
                                                    >
                                                        Não
                                                    </button>
                                                    <button
                                                        onClick={() => props.confirmSubmit(props.elem)}
                                                        className="btn btn-primary mx-2 w-25"
                                                    >
                                                        Sim
                                                    </button>
                                                </>
                                                :
                                                <button
                                                    onClick={() => setModalShow(false)}
                                                    className="btn btn-primary mx-2 w-25"
                                                >
                                                    Confirmar
                                                </button>
                                    }
                                </div>
                            </>
                            :
                            <div className="modal-body">
                                <div className="text-center">
                                    <div className="load"></div>
                                </div>
                                <div>
                                    <span>Processando</span>
                                </div>
                                <div></div>
                            </div>
                    }
                </Modal.Body>
            </Modal>
            {
                props.expiration === "yes"
                    ?
                    <div className='btn-expiration' onClick={() => setModalShow(true)}>
                        <button className="btn btn-primary" onClick={props.handleSubmit(props.onSubmit)}>
                            Confirmar
                        </button>
                    </div>
                    :
                    props.createSession === "yes"
                        ?
                        <div className='mt-4 d-flex justify-content-end'>
                            <div>
                                <button className="btn btn-default btn-outline-primary px-4 mr-3 text-uppercase" onClick={props.closeModal}>
                                    Cancelar
                                </button>
                            </div>
                            <div onClick={() => setModalShow(true)}>
                                <button className="btn btn-default btn-primary px-4 text-uppercase" type="submit" onClick={props.onSubmit}>
                                    Confirmar
                                </button>
                            </div>
                        </div>
                        :
                        props.payment === "yes"
                            ?
                            <div className='mt-4 d-flex justify-content-end'>
                                <div>
                                    <button className="btn btn-default btn-outline-primary px-4 mr-3 text-uppercase" onClick={props.closeModal}>
                                        Cancelar
                                    </button>
                                </div>
                                <div onClick={() => setModalShow(true)}>
                                    <button className="btn btn-default btn-primary px-4 text-uppercase" type="submit">
                                        Confirmar
                                    </button>
                                </div>
                            </div>
                            :
                            props.manifestInsert === "yes"
                                ?
                                <div className="w-100" onClick={() => setModalShow(true)}>
                                    {/* <button className="btn btn-primary" style={{ width: "100px" }} onClick={props.handleSubmit((data: any) => props.onSubmit(data, props.index, props.elem))}>
                                        Salvar
                                    </button> */}
                                    <div className="w-100 btn btn-outline-primary" onClick={props.handleSubmit((data: any) => props.onSubmit(data, props.index, props.elem))}>
                                        Salvar
                                    </div>
                                </div>
                                :
                                props.manifestEdit === "yes"
                                    ?
                                    <div className="w-100" onClick={() => setModalShow(true)}>
                                        <div className="w-100" onClick={props.handleSubmit((data: any) => props.onSubmit(data, props.index, props.elem))}>
                                            Editar
                                        </div>
                                    </div>
                                    :
                                    
                                        
                                            props.textDropdown === "yes"
                                                ?
                                                <div className="d-flex justify-content-start w-100" onClick={() => setModalShow(true)}>
                                                    <div className="w-100" onClick={() => props.onSubmit(props.elem)}>
                                                        Notificar Fornecedor
                                                    </div>
                                                </div>
                                                :
                                                props.manifestRemove === "yes"
                                                    ?
                                                    <div className="d-flex justify-content-start w-100"  onClick={() => setModalShow(true)}>
                                                        <div className="w-100" onClick={() => props.onSubmit(props.elem)}>
                                                            Remover
                                                        </div>
                                                    </div>
                                                    :
                                                    props.isEdit === true
                                                    ?
                                                    <div className="d-flex justify-content-end" style={{ width: "unset" }} onClick={() => setModalShow(true)}>
                                                        <button className="btn btn-default btn-primary px-4 text-uppercase " type="submit">
                                                            Salvar
                                                        </button>
                                                    </div>
                                                    :
                                                    <div className="d-flex justify-content-end" style={{ width: "unset" }} /* onClick={() => setModalShow(true)} */>
                                                        <button className="btn btn-default btn-primary px-4 text-uppercase " type="submit">
                                                            Cadastrar
                                                        </button>
                                                    </div>
                                    
            }
        </>
    )
}

export default ModalConfirm;