import React, { useState } from "react";
import { Card, Container, Row, Col } from 'react-bootstrap'
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import Accordion from 'react-bootstrap/Accordion';

import { Controller, useForm } from "react-hook-form";
import { ErrorMessage } from '@hookform/error-message';

import { useTranslation } from "react-i18next";

import '../../../../assets/sass/accordion.scss';

export interface propAccordion {
    action: any,
    ContextAwareToggle: any,
    compType: any,
    setStepCtrl: any
    isEdit?: any;
    infoEdit?: any;
    editPartners?: any;
    hasSavedInputs?: any;
};

const AccordionDocuments: React.FC<propAccordion> = ({
    action, ContextAwareToggle, compType, setStepCtrl, infoEdit, isEdit = false, editPartners, hasSavedInputs
}: propAccordion) => {

    const [ctrlEventKey, setCtrlEventKey] = useState<boolean>(true);
    // const [hasContractControl, setHasContractControl] = useState({ value: true, message: 'Por favor, preencher esse campo.' });
    const { t } = useTranslation();
    const validated = false;

    const [cpf, setCpf] = useState<any>((isEdit === true || hasSavedInputs === true) ? infoEdit.cpfBase64 : '');
    const [cpfName, setCpfName] = useState<any>((isEdit === true || hasSavedInputs === true) ? infoEdit.cpfName : '');

    const [rg, setRg] = useState<any>((isEdit === true || hasSavedInputs === true) ? infoEdit.rgBase64 : '');
    const [rgName, setRgName] = useState<any>((isEdit === true || hasSavedInputs === true) ? infoEdit.rgName : '');

    const [compRes, setCompRes] = useState<any>((isEdit === true || hasSavedInputs === true) ? infoEdit.compResBase64 : '');
    const [compResName, setCompResName] = useState<any>((isEdit === true || hasSavedInputs === true) ? infoEdit.compResName : '');

    const [docSerasa, setDocSerasa] = useState<any>((isEdit === true || hasSavedInputs === true) ? infoEdit.docSerasaBase64 : '');
    const [docSerasaName, setDocSerasaName] = useState<any>((isEdit === true || hasSavedInputs === true) ? infoEdit.docSerasaName : '');

    const [cartHab, setCartHab] = useState<any>((isEdit === true || hasSavedInputs === true) ? infoEdit.cartHabBase64 : '');
    const [cartHabName, setCartHabName] = useState<any>((isEdit === true || hasSavedInputs === true) ? infoEdit.cartHabName : '');

    const [socialContract, setsocialContract] = useState<any>((isEdit === true || hasSavedInputs === true) ? infoEdit.socialContractBase64 : '');
    const [socialContractName, setsocialContractName] = useState<any>((isEdit === true || hasSavedInputs === true) ? infoEdit.socialContractName : '');

    const [rgRepr, setrgRepr] = useState<any>((hasSavedInputs === true) ? infoEdit.rgReprBase64 : '');
    const [rgReprName, setrgReprName] = useState<any>((isEdit === true || hasSavedInputs === true) ? infoEdit.rgReprName : '');

    const [minTur, setMinTur] = useState<any>((hasSavedInputs === true) ? infoEdit.minTurBase64 : '');
    const [minTurName, setMinTurName] = useState<any>((isEdit === true || hasSavedInputs === true) ? infoEdit.minTurName : '');

    const [cnpj, setCnpj] = useState<any>((hasSavedInputs === true) ? infoEdit.cnpjBase64 : '');
    const [cnpjName, setCnpjName] = useState<any>((isEdit === true || hasSavedInputs === true) ? infoEdit.cnpjName : '');

    const [insMun, setInsMun] = useState<any>((hasSavedInputs === true) ? infoEdit.insMunBase64 : '');
    const [insMunName, setInsMunName] = useState<any>((isEdit === true || hasSavedInputs === true) ? infoEdit.insMunName : '');

    const [certNeg, setCertNeg] = useState<any>((hasSavedInputs === true) ? infoEdit.certNegBase64 : '');
    const [certNegName, setCertNegName] = useState<any>((isEdit === true || hasSavedInputs === true) ? infoEdit.insMunName : '');

    const idPartner = window.location.href.split('id=')[1];

    const {
        control,
        handleSubmit,
        formState: { errors },
    } = useForm(/* {
        defaultValues: {
            rgBase64: isEdit === true ? infoEdit.rgBase64 : '',
            cpfBase64: isEdit === true ? infoEdit.cpfBase64 : '',
            compResBase64: isEdit === true ? infoEdit.compResBase64 : '',
            docSerasaBase64: isEdit === true ? infoEdit.docSerasaBase64 : '',
            cartHabBase64: isEdit === true ? infoEdit.cartHabBase64 : '',
            socialContractBase64: isEdit === true ? infoEdit.socialContractBase64 : '',
            rgReprBase64: isEdit === true ? infoEdit.rgReprBase64 : ''
        },
    } */)

    const cpfHandler = (e: any) => {

        const reader = new FileReader();
        reader.onload = () => {
            if (reader.readyState === 2) {
                setCpf(reader.result)
            }
        }
        reader.readAsDataURL(e.target.files[0]);
        setCpfName(e.target.files[0].name);
    }

    const rgHandler = (e: any) => {

        const reader = new FileReader();
        reader.onload = () => {
            if (reader.readyState === 2) {
                setRg(reader.result)
            }
        }
        reader.readAsDataURL(e.target.files[0]);
        setRgName(e.target.files[0].name);
    }

    const compResHandler = (e: any) => {

        const reader = new FileReader();
        reader.onload = () => {
            if (reader.readyState === 2) {
                setCompRes(reader.result)
            }
        }
        reader.readAsDataURL(e.target.files[0]);
        setCompResName(e.target.files[0].name);
    }

    const docSerasaHandler = (e: any) => {

        const reader = new FileReader();
        reader.onload = () => {
            if (reader.readyState === 2) {
                setDocSerasa(reader.result)
            }
        }
        reader.readAsDataURL(e.target.files[0]);
        setDocSerasaName(e.target.files[0].name);
    }

    const cartHabHandler = (e: any) => {

        const reader = new FileReader();
        reader.onload = () => {
            if (reader.readyState === 2) {
                setCartHab(reader.result)
            }
        }
        reader.readAsDataURL(e.target.files[0]);
        setCartHabName(e.target.files[0].name);
    }

    const socialContractHandler = (e: any) => {
        if (e.target.files[0].type !== undefined) {
            if (e.target.files[0].type === "application/pdf") {
                const reader = new FileReader();
                reader.onload = () => {
                    if (reader.readyState === 2) {
                        setsocialContract(reader.result)
                    }
                }
                reader.readAsDataURL(e.target.files[0]);
                setsocialContractName(e.target.files[0].name);
            } else {
                alert("Anexe um arquivo no formato solicitado!");
            }
        }
    }

    const rgReprHandler = (e: any) => {
        if (e.target.files[0].type !== undefined) {
            if (e.target.files[0].type === "image/jpeg") {
                const reader = new FileReader();
                reader.onload = () => {
                    if (reader.readyState === 2) {
                        setrgRepr(reader.result)
                    }
                }
                reader.readAsDataURL(e.target.files[0]);
                setrgReprName(e.target.files[0].name);
            } else {
                alert("Anexe um arquivo no formato solicitado!");
            }
        }
    }
    const minTurHandler = (e: any) => {
        if (e.target.files[0].type !== undefined) {
            if (e.target.files[0].type === "image/jpeg") {
                const reader = new FileReader();
                reader.onload = () => {
                    if (reader.readyState === 2) {
                        setMinTur(reader.result)
                    }
                }
                reader.readAsDataURL(e.target.files[0]);
                setMinTurName(e.target.files[0].name);
            } else {
                alert("Anexe um arquivo no formato solicitado!");
            }
        }
    }
    
    const cnpjHandler = (e: any) => {
        if (e.target.files[0].type !== undefined) {
            if (e.target.files[0].type === "image/jpeg") {
                const reader = new FileReader();
                reader.onload = () => {
                    if (reader.readyState === 2) {
                        setCnpj(reader.result)
                    }
                }
                reader.readAsDataURL(e.target.files[0]);
                setCnpjName(e.target.files[0].name);
            } else {
                alert("Anexe um arquivo no formato solicitado!");
            }
        }
    }

    const insMunHandler = (e: any) => {
        if (e.target.files[0].type !== undefined) {
            if (e.target.files[0].type === "image/jpeg") {
                const reader = new FileReader();
                reader.onload = () => {
                    if (reader.readyState === 2) {
                        setInsMun(reader.result)
                    }
                }
                reader.readAsDataURL(e.target.files[0]);
                setInsMunName(e.target.files[0].name);
            } else {
                alert("Anexe um arquivo no formato solicitado!");
            }
        }
    }

    const certNegHandler = (e: any) => {
        if (e.target.files[0].type !== undefined) {
            if (e.target.files[0].type === "image/jpeg") {
                const reader = new FileReader();
                reader.onload = () => {
                    if (reader.readyState === 2) {
                        setCertNeg(reader.result)
                    }
                }
                reader.readAsDataURL(e.target.files[0]);
                setCertNegName(e.target.files[0].name);
            } else {
                alert("Anexe um arquivo no formato solicitado!");
            }
        }
    }

    //console.log(contratName)

    const onSubmit = (data: any) => {

        data.rgBase64 = rg === undefined ? '' : rg;

        data.cpfBase64 = cpf === undefined ? '' : cpf;

        data.compResBase64 = compRes === undefined ? '' : compRes;

        data.docSerasaBase64 = docSerasa === undefined ? '' : docSerasa;

        data.cartHabBase64 = cartHab === undefined ? '' : cartHab;

        data.socialContractBase64 = socialContract === undefined ? '' : socialContract;

        data.rgReprBase64 = rgRepr === undefined ? '' : rgRepr;

        data.minTurBase64 = minTur === undefined ? '' : minTur;

        data.cnpjBase64 = cnpj === undefined ? '' : cnpj;

        data.insMunBase64 = insMun === undefined ? '' : insMun;

        data.certNegBase64 = certNeg === undefined ? '' : certNeg;

        data.rgName = rgName;
        data.cpfName = cpfName;
        data.compResName = compResName;
        data.docSerasaName = docSerasaName;
        data.cartHabName = cartHabName;
        data.socialContractName = socialContractName;
        data.rgReprName = rgReprName;
        data.minTurName = minTurName;
        data.cnpjName = cnpjName;
        data.insMunName = insMunName;
        data.certNegName = certNegName;

        if (isEdit === false) {
            /// ADD SUPPLIERS PAGE
            setCtrlEventKey(true);
            action(data);
        } else {
            /// EDIT SUPPLIERS PAGE
            let edit: any = { ...infoEdit, ...data };
            edit.affiliateId = parseInt(idPartner);

            edit.rgName = rgName;
            edit.cpfName = cpfName;
            edit.compResName = compResName;
            edit.docSerasaName = docSerasaName;
            edit.cartHabName = cartHabName;
            edit.socialContractName = socialContractName;
            edit.rgReprName = rgReprName;
            edit.minTurName = minTurName;
            edit.cnpjName = cnpjName;
            edit.insMunName = insMunName;
            edit.certNegName = certNegName;

            edit.supplierContactModel = edit.affiliateContact;
            edit.costCenterSupplierModel = edit.costCenterSupplierDTO;
            edit.usersSupplierModel = edit.usersSupplierDTO;

            if (edit.masterFilho === null) {
                edit.masterFilho = '0';
            }

            editPartners(edit);

        }
    };

    function handleCancel() {
        window.location.reload();
    }

    return (
        <Card className="bg-white mt-4">
            <Card.Header id="accordionDocuments">
                <ContextAwareToggle eventKey="0" status={ctrlEventKey}>Documentos</ContextAwareToggle>
            </Card.Header>
            <Accordion.Collapse eventKey="0">
                <Card.Body>
                    <Container className="p-0 mt-3" fluid>
                        <Form noValidate validated={validated} onSubmit={handleSubmit(onSubmit)}>
                            <Row>
                                {compType === "1"
                                    ?
                                    <Row className="mb-3">
                                        <Form.Group as={Col} md="3">
                                            <Form.Label>CPF</Form.Label><br />
                                            <Controller
                                                control={control}
                                                name="cpfBase64"
                                                render={({ field }: any) => (
                                                    <Form.Control type="file"
                                                        {...field}
                                                        label="CPF"
                                                        aria-invalid={errors?.cpfBase64 ? "true" : ""}
                                                        fullWidth
                                                        variant="standard"
                                                        margin="normal"
                                                        id="cpf"
                                                        accept=".jpg"
                                                        onChange={cpfHandler}
                                                        autoComplete='off'
                                                        required />
                                                )}
                                            />
                                            <Row>
                                                <Col md={4}>
                                                    <label className="btn-file" htmlFor="cpf">{t('accordionAccount.anex')}</label>
                                                    <small>{cpfName}</small>
                                                </Col>
                                                <Col md={8}>
                                                    {cpf !== null &&
                                                        <div className="w-100"
                                                            style={{ backgroundImage: `url(${cpf})`, backgroundRepeat: 'no-repeat', backgroundSize: 'contain', backgroundPosition: 'center', height: '150px' }}>
                                                        </div>
                                                    }
                                                </Col>
                                            </Row>
                                            <ErrorMessage
                                                errors={errors}
                                                name="cpfBase64"
                                                render={({ message }) => <small style={{ color: "red" }}>{message}</small>}
                                            />
                                        </Form.Group>
                                        <Form.Group as={Col} md="3">
                                            <Form.Label>RG</Form.Label><br />
                                            <Controller
                                                control={control}
                                                name="rgBase64"
                                                render={({ field }: any) => (
                                                    <Form.Control type="file"
                                                        {...field}
                                                        label="RG"
                                                        aria-invalid={errors?.rgBase64 ? "true" : ""}
                                                        fullWidth
                                                        variant="standard"
                                                        margin="normal"
                                                        id="rg"
                                                        accept=".jpg"
                                                        onChange={rgHandler}
                                                        autoComplete='off'
                                                        required />
                                                )}
                                            />
                                            <Row>
                                                <Col md={4}>
                                                    <label className="btn-file" htmlFor="rg">{t('accordionAccount.anex')}</label>
                                                    <small>{rgName}</small>
                                                </Col>
                                                <Col md={8}>
                                                    {rg !== null &&
                                                        <div className="w-100"
                                                            style={{ backgroundImage: `url(${rg})`, backgroundRepeat: 'no-repeat', backgroundSize: 'contain', backgroundPosition: 'center', height: '150px' }}>
                                                        </div>
                                                    }
                                                </Col>
                                            </Row>
                                            <ErrorMessage
                                                errors={errors}
                                                name="rgBase64"
                                                render={({ message }) => <small style={{ color: "red" }}>{message}</small>}
                                            />
                                        </Form.Group>
                                        <Form.Group as={Col} md="3">
                                            <Form.Label>Comprovante de residência</Form.Label><br />
                                            <Controller
                                                control={control}
                                                name="compResBase64"
                                                render={({ field }: any) => (
                                                    <Form.Control type="file"
                                                        {...field}
                                                        label="Comprovante de residência"
                                                        aria-invalid={errors?.compResBase64 ? "true" : ""}
                                                        fullWidth
                                                        variant="standard"
                                                        margin="normal"
                                                        id="compRes"
                                                        accept=".jpg"
                                                        onChange={compResHandler}
                                                        autoComplete='off'
                                                        required />
                                                )}
                                            />
                                            <Row>
                                                <Col md={4}>
                                                    <label className="btn-file" htmlFor="compRes">{t('accordionAccount.anex')}</label>
                                                    <small>{compResName}</small>
                                                </Col>
                                                <Col md={8}>
                                                    {compRes !== null &&
                                                        <div className="w-100"
                                                            style={{ backgroundImage: `url(${compRes})`, backgroundRepeat: 'no-repeat', backgroundSize: 'contain', backgroundPosition: 'center', height: '150px' }}>
                                                        </div>
                                                    }
                                                </Col>
                                            </Row>

                                            <ErrorMessage
                                                errors={errors}
                                                name="compResBase64"
                                                render={({ message }) => <small style={{ color: "red" }}>{message}</small>}
                                            />
                                        </Form.Group>
                                        <Form.Group as={Col} md="3">
                                            <Form.Label>Documentação Serasa</Form.Label><br />
                                            <Controller
                                                control={control}
                                                name="docSerasaBase64"
                                                render={({ field }: any) => (
                                                    <Form.Control type="file"
                                                        {...field}
                                                        label="Documentação Serasa"
                                                        aria-invalid={errors?.docSerasaBase64 ? "true" : ""}
                                                        fullWidth
                                                        variant="standard"
                                                        margin="normal"
                                                        id="serasa"
                                                        accept=".jpg"
                                                        onChange={docSerasaHandler}
                                                        autoComplete='off'
                                                        required />
                                                )}
                                            />
                                            <Row>
                                                <Col md={4}>
                                                    <label className="btn-file" htmlFor="serasa">{t('accordionAccount.anex')}</label>
                                                    <small>{docSerasaName}</small>
                                                </Col>
                                                <Col md={8}>
                                                    {docSerasa !== null &&
                                                        <div className="w-100"
                                                            style={{ backgroundImage: `url(${docSerasa})`, backgroundRepeat: 'no-repeat', backgroundSize: 'contain', backgroundPosition: 'center', height: '150px' }}>
                                                        </div>
                                                    }
                                                </Col>
                                            </Row>

                                            <ErrorMessage
                                                errors={errors}
                                                name="docSerasaBase64"
                                                render={({ message }) => <small style={{ color: "red" }}>{message}</small>}
                                            />
                                        </Form.Group>
                                        <Form.Group as={Col} md="3" className="mt-4">
                                            <Form.Label>Carteira de Habilitação</Form.Label><br />
                                            <Controller
                                                control={control}
                                                name="cartHabBase64"
                                                render={({ field }: any) => (
                                                    <Form.Control type="file"
                                                        {...field}
                                                        label="Anexar CPF (no formato JPG)"
                                                        aria-invalid={errors?.cartHabBase64 ? "true" : ""}
                                                        fullWidth
                                                        variant="standard"
                                                        margin="normal"
                                                        id="cartHab"
                                                        accept=".jpg"
                                                        onChange={cartHabHandler}
                                                        autoComplete='off'
                                                        required />
                                                )}
                                            />
                                            <Row>
                                                <Col md={4}>
                                                    <label className="btn-file" htmlFor="cartHab">{t('accordionAccount.anex')}</label>
                                                    <small>{cartHabName}</small>
                                                </Col>
                                                <Col md={8}>
                                                    {cartHab !== null &&
                                                        <div className="w-100"
                                                            style={{ backgroundImage: `url(${cartHab})`, backgroundRepeat: 'no-repeat', backgroundSize: 'contain', backgroundPosition: 'center', height: '150px' }}>
                                                        </div>
                                                    }
                                                </Col>
                                            </Row>

                                            <ErrorMessage
                                                errors={errors}
                                                name="cartHabBase64"
                                                render={({ message }) => <small style={{ color: "red" }}>{message}</small>}
                                            />
                                        </Form.Group>
                                    </Row>
                                    : compType === "2" || compType === "3"
                                    //PESSOA JURÍDICA
                                        ?<>
                                        <Row className="mb-3">
                                            <Form.Group as={Col} md="3">
                                                <Form.Label>Anexar Contrato Social (PDF)*</Form.Label><br />
                                                <Controller
                                                    control={control}
                                                    name="socialContractBase64"
                                                    //rules={{ required: { value: true, message: 'Por favor, anexar cópia do Contrato Social' } }}
                                                    render={({ field }: any) => (
                                                        <Form.Control type="file"
                                                            {...field}
                                                            label="Anexar Contrato Social (no formato PDF)"
                                                            aria-invalid={errors?.socialContractBase64 ? "true" : ""}
                                                            fullWidth
                                                            variant="standard"
                                                            margin="normal"
                                                            id="social"
                                                            accept=".pdf"
                                                            onChange={socialContractHandler}
                                                            autoComplete='off'
                                                            required />
                                                    )}
                                                />
                                                <Row>
                                                    <Col md={4}>
                                                        <label className="btn-file" htmlFor="social">{t('accordionAccount.anex')}</label>
                                                        <small>{socialContractName}</small>
                                                    </Col>
                                                    <Col md={8}>
                                                        {socialContract !== null &&
                                                            <div className="w-100"
                                                                style={{ backgroundImage: `url(${socialContract})`, backgroundRepeat: 'no-repeat', backgroundSize: 'contain', backgroundPosition: 'center', height: '150px' }}>
                                                            </div>
                                                        }
                                                    </Col>
                                                </Row>
                                                <ErrorMessage
                                                    errors={errors}
                                                    name="socialContractBase64"
                                                    render={({ message }) => <small style={{ color: "red" }}>{message}</small>}
                                                />
                                            </Form.Group>
                                            <Form.Group as={Col} md="3">
                                                <Form.Label>Anexar RG do representante da empresa (JPG)*</Form.Label><br />
                                                <Controller
                                                    control={control}
                                                    name="rgReprBase64"
                                                    //rules={{ required: { value: true, message: 'Por favor, anexar cópia do RG do representante da empresa' } }}
                                                    render={({ field }: any) => (
                                                        <Form.Control type="file"
                                                            {...field}
                                                            label="Anexar CPF (no formato JPG)"
                                                            aria-invalid={errors?.rgReprBase64 ? "true" : ""}
                                                            fullWidth
                                                            variant="standard"
                                                            margin="normal"
                                                            id="rgRepr"
                                                            accept=".jpg"
                                                            onChange={rgReprHandler}
                                                            autoComplete='off'
                                                            required />
                                                    )}
                                                />
                                                <Row>
                                                    <Col md={4}>
                                                        <label className="btn-file" htmlFor="rgRepr">{t('accordionAccount.anex')}</label>
                                                        <small>{rgReprName}</small>
                                                    </Col>
                                                    <Col md={8}>
                                                        {rgRepr !== null &&
                                                            <div className="w-100"
                                                                style={{ backgroundImage: `url(${rgRepr})`, backgroundRepeat: 'no-repeat', backgroundSize: 'contain', backgroundPosition: 'center', height: '150px' }}>
                                                            </div>
                                                        }
                                                    </Col>
                                                </Row>

                                                <ErrorMessage
                                                    errors={errors}
                                                    name="rgReprBase64"
                                                    render={({ message }) => <small style={{ color: "red" }}>{message}</small>}
                                                />
                                            </Form.Group>

                                            <Form.Group as={Col} md="3">
                                                <Form.Label>Anexar cópia do Registro do Minitério do Turismo (JPG)*</Form.Label><br />
                                                <Controller
                                                    control={control}
                                                    name="minTurBase64"
                                                    //rules={{ required: { value: true, message: 'Por favor, anexar cópia do Registro do Minitério do Turismo' } }}
                                                    render={({ field }: any) => (
                                                        <Form.Control type="file"
                                                            {...field}
                                                            label="Anexar RMT (no formato JPG)"
                                                            aria-invalid={errors?.minTurBase64 ? "true" : ""}
                                                            fullWidth
                                                            variant="standard"
                                                            margin="normal"
                                                            id="minTur"
                                                            accept=".jpg"
                                                            onChange={minTurHandler}
                                                            autoComplete='off'
                                                            required />
                                                    )}
                                                />
                                                <Row>
                                                    <Col md={4}>
                                                        <label className="btn-file" htmlFor="minTur">{t('accordionAccount.anex')}</label>
                                                        <small>{minTurName}</small>
                                                    </Col>
                                                    <Col md={8}>
                                                        {minTur !== null &&
                                                            <div className="w-100"
                                                                style={{ backgroundImage: `url(${minTur})`, backgroundRepeat: 'no-repeat', backgroundSize: 'contain', backgroundPosition: 'center', height: '150px' }}>
                                                            </div>
                                                        }
                                                    </Col>
                                                </Row>

                                                <ErrorMessage
                                                    errors={errors}
                                                    name="minTurBase64"
                                                    render={({ message }) => <small style={{ color: "red" }}>{message}</small>}
                                                />
                                            </Form.Group>
                                        </Row>

                                        <Row className="mb-3">
                                            <Form.Group as={Col} md="3">
                                                    <Form.Label>Anexar cópia do CNPJ da Agênciade Turismo (JPG)*</Form.Label><br />
                                                    <Controller
                                                        control={control}
                                                        name="cnpjBase64"
                                                        //rules={{ required: { value: true, message: 'Por favor, anexar cópia do CNPJ da Agênciade Turismo' } }}
                                                        render={({ field }: any) => (
                                                            <Form.Control type="file"
                                                                {...field}
                                                                label="Anexar CNPJ (no formato JPG)"
                                                                aria-invalid={errors?.cnpjBase64 ? "true" : ""}
                                                                fullWidth
                                                                variant="standard"
                                                                margin="normal"
                                                                id="cnpj"
                                                                accept=".jpg"
                                                                onChange={cnpjHandler}
                                                                autoComplete='off'
                                                                required />
                                                        )}
                                                    />
                                                    <Row>
                                                        <Col md={4}>
                                                            <label className="btn-file" htmlFor="cnpj">{t('accordionAccount.anex')}</label>
                                                            <small>{cnpjName}</small>
                                                        </Col>
                                                        <Col md={8}>
                                                            {cnpj !== null &&
                                                                <div className="w-100"
                                                                    style={{ backgroundImage: `url(${cnpj})`, backgroundRepeat: 'no-repeat', backgroundSize: 'contain', backgroundPosition: 'center', height: '150px' }}>
                                                                </div>
                                                            }
                                                        </Col>
                                                    </Row>

                                                    <ErrorMessage
                                                        errors={errors}
                                                        name="cnpjBase64"
                                                        render={({ message }) => <small style={{ color: "red" }}>{message}</small>}
                                                    />
                                            </Form.Group>

                                            <Form.Group as={Col} md="3">
                                                    <Form.Label>Anexar cópia da Inscrição Municipal (JPG)*</Form.Label><br />
                                                    <Controller
                                                        control={control}
                                                        name="insMunBase64"
                                                        //rules={{ required: { value: true, message: 'Por favor, anexar cópia da Inscrição Municipal' } }}
                                                        render={({ field }: any) => (
                                                            <Form.Control type="file"
                                                                {...field}
                                                                label="Anexar IM (no formato JPG)"
                                                                aria-invalid={errors?.insMunBase64 ? "true" : ""}
                                                                fullWidth
                                                                variant="standard"
                                                                margin="normal"
                                                                id="insMun"
                                                                accept=".jpg"
                                                                onChange={insMunHandler}
                                                                autoComplete='off'
                                                                required />
                                                        )}
                                                    />
                                                    <Row>
                                                        <Col md={4}>
                                                            <label className="btn-file" htmlFor="insMun">{t('accordionAccount.anex')}</label>
                                                            <small>{insMunName}</small>
                                                        </Col>
                                                        <Col md={8}>
                                                            {insMun !== null &&
                                                                <div className="w-100"
                                                                    style={{ backgroundImage: `url(${insMun})`, backgroundRepeat: 'no-repeat', backgroundSize: 'contain', backgroundPosition: 'center', height: '150px' }}>
                                                                </div>
                                                            }
                                                        </Col>
                                                    </Row>

                                                    <ErrorMessage
                                                        errors={errors}
                                                        name="insMunBase64"
                                                        render={({ message }) => <small style={{ color: "red" }}>{message}</small>}
                                                    />
                                            </Form.Group>

                                            <Form.Group as={Col} md="3">
                                                    <Form.Label>Anexar cópia da Certidão Negativa (JPG)</Form.Label><br />
                                                    <Controller
                                                        control={control}
                                                        name="certNegBase64"                                                        
                                                        render={({ field }: any) => (
                                                            <Form.Control type="file"
                                                                {...field}
                                                                label="Anexar CN (no formato JPG)"
                                                                aria-invalid={errors?.certNegBase64 ? "true" : ""}
                                                                fullWidth
                                                                variant="standard"
                                                                margin="normal"
                                                                id="certNeg"
                                                                accept=".jpg"
                                                                onChange={certNegHandler}
                                                                autoComplete='off'
                                                                required />
                                                        )}
                                                    />
                                                    <Row>
                                                        <Col md={4}>
                                                            <label className="btn-file" htmlFor="certNeg">{t('accordionAccount.anex')}</label>
                                                            <small>{certNegName}</small>
                                                        </Col>
                                                        <Col md={8}>
                                                            {certNeg !== null &&
                                                                <div className="w-100"
                                                                    style={{ backgroundImage: `url(${certNeg})`, backgroundRepeat: 'no-repeat', backgroundSize: 'contain', backgroundPosition: 'center', height: '150px' }}>
                                                                </div>
                                                            }
                                                        </Col>
                                                    </Row>

                                                    <ErrorMessage
                                                        errors={errors}
                                                        name="certNegBase64"
                                                        render={({ message }) => <small style={{ color: "red" }}>{message}</small>}
                                                    />
                                            </Form.Group>
                                        </Row>
                                        </>
                                        : ""
                                }
                            </Row>
                            <Row className="d-flex justify-content-end mx-0 mt-5 mb-4">
                                {
                                    isEdit === true
                                        ?
                                        <Button
                                            className="form-button cancel"
                                            onClick={handleCancel}
                                        >
                                            Cancelar
                                        </Button>
                                        :
                                        <Button
                                            type="button"
                                            variant="outline"
                                            className="form-button back-btn btn-outline-primary"
                                            onClick={() => {
                                                setStepCtrl(0)
                                            }}
                                        >
                                            voltar
                                        </Button>
                                }
                                <Button
                                    type="submit"
                                    className="form-button"
                                >
                                    {t('accordionDocuments.buttonContinue')}
                                </Button>
                            </Row>
                        </Form>
                    </Container>
                </Card.Body>
            </Accordion.Collapse>
        </Card>
    )
}

export default AccordionDocuments;