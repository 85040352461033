import React, { Key, useEffect, useState } from 'react';
import api from '../../services/api';

import Form from 'react-bootstrap/Form';

export interface propPlus {
  propsField: any,
  propsLabel: any,
  propsErrors: any,
  contaAdquirente: any,
  tipoConta: any,
  setAccount: any
};

const GetAccountsByTypeFilter: React.FC<propPlus> = ({
  propsField, propsLabel, propsErrors, contaAdquirente, tipoConta, setAccount
}: propPlus) => {

  const [info, setInfo] = useState<any>(null);

  const handleChangeMethod = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = e.target.value;
    if(setAccount){
      setAccount(newValue);
    }
  }

  useEffect(() => {
    const token = localStorage.getItem('GroupId') || '{}';
    const config = {
      headers: { 'Authorization': `Bearer ${token}` },
    };
    async function listSuppliers() {
      try {
        const { data } = await api.post(`/BillsReceivable/GetAccountsByType`, {
          contaAdquirente: contaAdquirente, // 1 = Conta, 2 = Adquirente
          tipoConta: tipoConta // 1 = Conta Corrente, 2 = Caixa, 3 = Conta Aplicação, 4 = Outros
        }, config);
        if (data.status !== 400) {
            setInfo(data.data)
        }
      } catch (error: any) {
        if (error.response.status === 401) {
          window.location.href = window.location.origin + '/';
        }
      }
    }
    listSuppliers();
  }, [contaAdquirente,tipoConta]);

  if (info !== null) {
    return (
      <>
        <div className={propsErrors.contaLiquidacao ? "endpoint-error" : "endpoint"}>
          <Form.Select
            {...propsField}
            aria-invalid={propsErrors[propsField.name] ? "true" : ""}
            label={propsLabel}
            as="select"
            variant="standard"
            margin="normal"
            required
            onChange={handleChangeMethod}
            //defaultValue={propsField.value}
            //value={value}
          >
            <option value=''>Todos</option>
            {info.length > 0 ? info.map((info: any, index: Key) => (
              <option key={index} value={info.id}>{`${info.descricao}`}</option>
            )) : ''}
          </Form.Select>
        </div>
      </>
    );
  } else {
    return (
      <>
        <Form.Select>
            <option value='' disabled></option>
        </Form.Select>
      </>
    )
  }
}

export default GetAccountsByTypeFilter;