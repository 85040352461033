/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useState } from "react";
import { Container, Row, Col, InputGroup } from "react-bootstrap";

import "../../../../../assets/sass/modal.scss";

//import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import { Controller, useForm } from "react-hook-form";
import { ErrorMessage } from "@hookform/error-message";
import Modal from "react-bootstrap/Modal";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import InputMask from "react-input-mask";

import api from '../../../../../services/api';

import SingleCalendar from '../../../../../components/Calendar/SingleCalendar';
import ModalQuestion from "../../../../Modal/ModalQuestion/ModalQuestion";
import GetCountrysWithShortName from "../../../../C2Points/GetCountrysWithShortName";
//import infoProducts from "../../../../InfoProducts/InfoProducts";

//import IconSucess from "../../../assets/icons/modal-sucess.svg";

export interface propModal {
  action: any,
  paymentId: any,
  dataOperacao: any,
  docRequired: any, 
  countryRequired: any,
  setModalSuccess: any,
  setModalResponse: any,
  setModalMessage: any,
  setLog: any
};

const FormRegisterBillCreditCard: React.FC<propModal> = ({
  action, paymentId, dataOperacao, docRequired, countryRequired
  , setModalSuccess, setModalResponse, setModalMessage, setLog
}: propModal, props: any) => {
  const formatDate = (data: any) => {
    if(!data) return '';
    
    //2022-05-05T00:00:00
    var aux = data.split('T');
    aux = aux[0].split('-');

    return (`${aux[2]}/${aux[1]}/${aux[0]}`);
  }
  const formatCreditDue = (data: any) => {
    if(!data) return '';
    
    //2022-05-05T00:00:00
    var aux = data.split('T');
    aux = aux[0].split('-');

    return (`${aux[1]}/${aux[0].substring(2)}`);
  }
  function incDate(data: any, days: any) {
    if(data) {
      const ano  = parseInt( data.substring(0,4) );
      const mes  = parseInt( data.substring(5,7) );
      const dia  = parseInt( data.substring(8,10) );
        
      var data1 = new Date(ano,mes-1,dia,0,0,0);
      data1 = new Date(data1.getFullYear(),data1.getMonth(),data1.getDate()+days,0,0,0);

      return data1.toISOString().substring(0,10);
    } else {
      return '';
    }
  }

  function incMonth(data: any, months: any) {
    if(data) {
      const ano  = parseInt( data.substring(0,4) );
      const mes  = parseInt( data.substring(5,7) );
      const dia  = parseInt( data.substring(8,10) );
        
      var data1 = new Date(ano,mes-1,dia,0,0,0);
      data1 = new Date(data1.getFullYear(),data1.getMonth()+months,data1.getDate(),0,0,0);

      return data1.toISOString().substring(0,10);
    } else {
      return '';
    }
  }
  
  const [activationDate, setActivationDate] = useState<any>(formatDate(incDate(dataOperacao,2)));
  const [erroActivationDate, setErroActivationDate] = useState<any>(false);

  const [loading, setLoading] = useState<any>(false);

  const [pais, setPais] = useState<any>(null);

  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm({
    defaultValues: {
      numero: '',
      vencimento: formatCreditDue(incMonth(incDate(dataOperacao,2),6)),
      codSeguranca: '',
      numDocumento: '',
      country: 'BR'
    },
  });

  const fixDate = (data: any) => {
    var aux = data.split('/');

    return (`${aux[2]}-${aux[1]}-${aux[0]}`);
  }

  const onSubmitForm = (dataObj: any) => {
//console.log(pais);return;
    if(!activationDate) {
      setErroActivationDate(true);
      return;
    }
    
    var data = activationDate;

    if(typeof data !== 'string') {
      if(data.length > 0) {
        data = data[0];
      } else {
        setErroActivationDate(true);
        return;
      }
    }

    setErroActivationDate(false);


    dataObj.id = paymentId;
    dataObj.dataAtivacao = fixDate(data);
    dataObj.pais = pais ? pais.id : "BR";

    let userData = JSON.parse(localStorage.getItem('c2tourAuth') || '{}');
    const user = userData.user;

    dataObj.uid = user.uId;
    dataObj.usuario = `${user.nome} ${user.lastName}`;

    setLoading(true);
    setModalResponse("loading");
    setModalSuccess(true);


    const salvar = async () => {
      try {
        const token = localStorage.getItem('GroupId') || '';

        const config = {
            headers: { 'Authorization': `Bearer ${token}` },
        };

        const { data } = await api.post('BillsReceivable/RegisterBillCreditCardAsync', dataObj, config);
        if (data.status !== 400) {
          var response = data.data;

          //alert(response.texto);
          setModalMessage(response.texto);
          setLog(response.log);

          if(response.log !== 1) {
            setModalResponse("success");
            action(data);
          } else {
            setModalResponse("error");
          }
        }   
      }catch(error: any) {
        setModalMessage("Erro, tente novamente mais tarde");
        setLog(1);
        setModalResponse("error");
        if (error?.response?.status === 401) {
          window.location.href = window.location.origin + '/';              
        }
        if (error?.response?.status === 400) {
          //window.location.href = window.location.origin + '/session-closure';              
        }
      }

      setLoading(false);
    }
    salvar();
  };

  // useEffect(() => {
    
  // }, []);

  /*converte a data UTC para a data local do usuário*/
  function convertUTCDateToLocalDate(date: any) {
    var newDate = new Date(date.getTime() + date.getTimezoneOffset() * 60 * 1000);

    var offset = date.getTimezoneOffset() / 60;
    var hours = date.getHours();

    //newDate.setHours(hours - offset);

    return newDate.toLocaleString().split(' ')[0];
  }

  function convertDate(cell: any) {
    var date = (cell) ? convertUTCDateToLocalDate(new Date(cell)) : '';
    return (
      <>
        {date}
      </>
    );
  }

  //if(info !== null) {
    return (
      <>
        <Modal.Body>
          <Container className="bg-modal-contact p-0" fluid>
            <Row>
              <h5 className="contact-title mb-5">Informe os dados do Cartão</h5>
              <Form
                className="form-add-contact"
                noValidate
              >
                <div className="">
                  <Row className="mb-3">
                    <Form.Group as={Col} className="mb-2" controlId="formGridDate">
                      <Form.Label>Data Ativação</Form.Label>
                      <InputGroup
                          hasValidation
                          className={` ${erroActivationDate === true ? "endpoint-error" : ""}`} 
                          size="sm"
                          placeholder="Quando?"
                      >
                          <SingleCalendar date={activationDate} setValue={setActivationDate} />
                      </InputGroup>
                      {erroActivationDate === true ?
                          <small style={{color: 'red'}}>required</small>
                      :<></>}
                    </Form.Group>
                    <Form.Group as={Col} controlId="formGridDesc">
                      <Form.Label>Num. Cartão</Form.Label>
                      <Controller
                          control={control}
                          name="numero"
                          rules={{ required: { value: true, message: "required" }, }}
                          render={({ field }: any) => (
                              <Form.Control
                                  {...field}
                                  aria-invalid={errors?.numero ? "true" : ""}
                                  variant="standard"
                                  margin="normal"
                                  autoComplete="off"
                              />
                          )}
                      />
                      <ErrorMessage
                          errors={errors}
                          name="numero"
                          render={({ message }) => <small style={{ color: "red" }}>{message}</small>}
                      />
                  </Form.Group>
                  </Row>
                  <Row className="mb-3">
                    <Form.Group as={Col} controlId="formGridDesc">
                      <Form.Label>Vencimento</Form.Label>
                      <Controller
                        control={control}
                        name="vencimento"
                        rules={{ required: { value: true, message: 'Required' } }}
                        render={({ field }: any) => (
                            <InputMask mask="99/99" value={field?.value} onChange={field?.onChange}>
                                {(field: any) => (
                                    <Form.Control
                                        {...field}
                                        aria-invalid={errors?.vencimento ? "true" : ""}
                                        label="Vencimento"
                                        variant="standard"
                                        margin="normal"
                                        required
                                        autoComplete='off'
                                    />
                                )}
                            </InputMask>
                        )}
                      />
                      <ErrorMessage
                          errors={errors}
                          name="vencimento"
                          render={({ message }) => <small style={{ color: "red" }}>{message}</small>}
                      />
                    </Form.Group>
                    <Form.Group as={Col} controlId="formGridDesc">
                      <Form.Label>Cod. Seg.</Form.Label>
                      <Controller
                          control={control}
                          name="codSeguranca"
                          rules={{ required: { value: true, message: "required" }, }}
                          render={({ field }: any) => (
                              <Form.Control
                                  {...field}
                                  aria-invalid={errors?.codSeguranca ? "true" : ""}
                                  variant="standard"
                                  margin="normal"
                                  autoComplete="off"
                              />
                          )}
                      />
                      <ErrorMessage
                          errors={errors}
                          name="codSeguranca"
                          render={({ message }) => <small style={{ color: "red" }}>{message}</small>}
                      />
                    </Form.Group>
                  </Row>
                  <Row className="mb-3">
                    {docRequired === true ?
                    <Form.Group as={Col} controlId="formGridDesc">
                      <Form.Label>Num. Documento</Form.Label>
                      <Controller
                          control={control}
                          name="numDocumento"
                          //rules={{ required: { value: true, message: "required" }, }}
                          render={({ field }: any) => (
                              <Form.Control
                                  {...field}
                                  aria-invalid={errors?.numDocumento ? "true" : ""}
                                  variant="standard"
                                  margin="normal"
                                  autoComplete="off"
                              />
                          )}
                      />
                      <ErrorMessage
                          errors={errors}
                          name="numDocumento"
                          render={({ message }) => <small style={{ color: "red" }}>{message}</small>}
                      />
                    </Form.Group>
                    : <></>}
                    {countryRequired === true ?
                    <Form.Group as={Col} controlId="formGridDesc">
                      <Form.Label>País</Form.Label>
                      <Controller
                          control={control}
                          name="country"
                          //rules={{ required: { value: pais === null, message: "required" }, }}
                          render={({ field }: any) => (
                            <GetCountrysWithShortName propsField={field} propsLabel="País" propsErrors={errors} watchCountry={setPais} />
                          )}
                      />
                      <ErrorMessage
                          errors={errors}
                          name="country"
                          render={({ message }) => <small style={{ color: "red" }}>{message}</small>}
                      />
                    </Form.Group>
                    : <></>}
                  </Row>
                </div>
              </Form>
            </Row>
          </Container>
        </Modal.Body>
  
        <Modal.Footer>
          <Row className="buttons-modal d-flex justify-content-end mx-0">
            <button
                className="btn btn-default btn-outline-primary px-4 mr-3 text-uppercase"
                onClick={(evt:any) => action(null)}
            >
              <FontAwesomeIcon
                icon={["fal", "times"]}
                size="1x"
                style={{ marginRight: "5px" }}
              />  Cancelar
            </button>
            {/* <Button
              type="button"
              className="form-button mr-2"
              onClick={action(null)}
              disabled={loading==true}
            >
              <FontAwesomeIcon
                icon={["fal", "times"]}
                size="1x"
                style={{ marginRight: "5px" }}
              /> Cancelar
            </Button> */}
            <Button
              type="button"
              className="btn btn-primary px-4 mr-3 text-uppercase"
              onClick={handleSubmit(onSubmitForm)}
              disabled={loading === true}
            >
              <FontAwesomeIcon
                icon={["fal", "save"]}
                size="1x"
                style={{ marginRight: "5px" }}
              /> Salvar 
            </Button>
          </Row>
        </Modal.Footer>
  
  
      </>
    );
  //} else {
  //  return (<></>);
  //}
  
}

export default FormRegisterBillCreditCard;
