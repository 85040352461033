import React from 'react';
//COMPONENTS
import Breadcrumb from '../../../components/Breadcrumb/Breadcrumb';

import MerchantsForm from '../components/MerchantsForm/MerchantsForm';
import { useTranslation } from 'react-i18next';
import DesktopDefault from '../../../templates/DesktopDefault';


function AddMerchants() {
  const { t } = useTranslation();
    return (
        <>
            <DesktopDefault>
                    <div className="container-fluid content-dashboard">

                        <Breadcrumb title={'Contas Correntes / ' + t('addMerchants.title')} />

                        <div className="register-users mt-4">
                            <h2 className="title">{t('addMerchants.title')}</h2>
                        </div>

                        <MerchantsForm action="1" info={null} />

                    </div>

            </DesktopDefault>
        </>

    );
}

export default AddMerchants;