import React, { Key, useEffect, useState } from 'react';
import api from '../../services/api';
import Form from 'react-bootstrap/Form';

import { useTranslation } from "react-i18next";

export interface propPlus {
  propsField: any,
  propsLabel: any,
  propsErrors: any,
};

const GetCurrency: React.FC<propPlus> = ({
  propsField, propsLabel, propsErrors
}: propPlus) => {
  const { t } = useTranslation();

  const [info, setInfo] = useState<any>(null);

  useEffect(() => {
    const token = localStorage.getItem('GroupId') || '{}';
    const config = {
      headers: { 'Authorization': `Bearer ${token}` },
    };
    async function listCurrency() {
      try {
        const { data } = await api.get(`/DropDown/GetCurrency`, config);
        if (data.status !== 400) {
          setInfo(data.data)
        }
      } catch (error: any) {
        if (error.response.status === 401) {
          window.location.href = window.location.origin + '/';
        }
      }
    }
    listCurrency();
  }, []);

  if (info !== null) {
    return (
      <>
        <div className={propsErrors.currency !== undefined ? "endpoint-error" : "endpoint"}>
          <Form.Select
            {...propsField}
            aria-invalid={propsErrors?.currency ? "true" : ""}
            label={propsLabel}
            as="select"
            variant="standard"
            margin="normal"
            required
          >
            <option value=''>{t('accordionFinancial.select.select')}</option>
            {info.length > 0 ? info.map((info: any, index: Key) => (
              <option key={index} value={info.id}>{info.description}</option>
            )) : ''}
          </Form.Select>
        </div>
      </>
    );
  } else {
    return (
      <>
        <Form.Select>
          <option value='' disabled></option>
        </Form.Select>
      </>
    )
  }
}

export default GetCurrency;