/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useRef } from 'react';
import { Row, Col } from "react-bootstrap";

import { Modal } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import './DetailsLogERM.scss';

export interface propInfo {
    currentLog: any,
    setDetailsModal: any,
};

const DetailsLogERM: React.FC<propInfo> = ({
    currentLog, setDetailsModal
}: propInfo) => {
    const [titmmov, setTitmmov] = useState<any>(null);
    const [tmov, setTmov] = useState<any>(null);
    const [tmovcompl, setTmovcompl] = useState<any>(null);
    const [info, setInfo] = useState<any>(null);
    const [title, setTitle] = useState<any>(null);
    const [subtitle, setSubtitle] = useState<any>(null);

    useEffect(() => {
        let auxMatrix: any = [];
        let auxVector: any = [];
        let subtitle: string[] = [];
        console.log(currentLog)

        if (currentLog !== null) {
            for (const property in currentLog) {
                setTitle(property);
                auxMatrix.push(currentLog[property])
            }

            for (let i: number = 0; i < auxMatrix.length; i++) {
                for (const property in auxMatrix[i]) {
                    subtitle.push(property)
                    auxVector.push(auxMatrix[i][property])
                }
            }

            setSubtitle(subtitle);
            setInfo(auxVector);



            /* for (const property in currentLog?.MovMovimento.TITMMOV) {
                aux.push({ value: currentLog?.MovMovimento.TITMMOV[property], label: property });
            }

            setTitmmov(aux);
            aux = [];
            for (const property in currentLog?.MovMovimento.TMOV) {
                aux.push({ value: currentLog?.MovMovimento.TMOV[property], label: property });
            }

            setTmov(aux);
            aux = [];
            for (const property in currentLog?.MovMovimento.TMOVCOMPL) {
                aux.push({ value: currentLog?.MovMovimento.TMOVCOMPL[property], label: property });
            }

            setTmovcompl(aux);
            aux = []; */
        }
    }, [currentLog])



    if (currentLog !== null) {
        return (
            <>
                <div>
                    <Modal.Body>
                        <div className="modal-header rounded-0">
                            <div>
                                <h4 className="pt-1 text-primary title-reservation">
                                    {title}
                                </h4>
                            </div>
                        </div>
                        <hr
                            className="w-100 my-4"
                            style={{ borderBottom: "1px solid" }}
                        />
                        <div className="w-100 p-0">
                            {/*  */}
                            {info?.map((elem: any, index: number) => {
                                console.log("aux")
                                let aux: any = []
                                for (const property in elem) {
                                    aux.push({ value: elem[property], label: property });
                                }
                                return (
                                    <>
                                        <h6 className="mb-3 ">{subtitle[index]}</h6>
                                        <Row className="m-0 mt-3" style={{ backgroundColor: "#f5f5f5", padding: "20px", borderRadius: '16px' }}>
                                            {aux?.map((e: any) => {
                                                return (
                                                    <>
                                                        <Col md={3} className="mb-4 ">
                                                            <span className="subtitle-modal">{e.label}</span>
                                                            <span className="response-modal">
                                                                <b>{e.label.includes("DATA") ? new Date(e.value).toLocaleDateString() : e.value}</b>
                                                            </span>
                                                        </Col>
                                                    </>
                                                )
                                            })}
                                        </Row>
                                        <hr className="w-100 my-4" style={{ borderBottom: "1px solid" }} />
                                    </>
                                )
                            })}
                            
                            {/*  */}
                            {/* <h6 className="mb-3 ">TITMMOV</h6>
                            <Row className="m-0 mt-3" style={{ backgroundColor: "#f5f5f5", padding: "20px", borderRadius: '16px' }}>
                                {titmmov?.map((elem: any) => {
                                    return (
                                        <>
                                            <Col md={3} className="mb-4 ">
                                                <span className="subtitle-modal">{elem.label}</span>
                                                <span className="response-modal">
                                                    <b>{elem.label.includes("DATA") ? new Date(elem.value).toLocaleDateString() : elem.value}</b>
                                                </span>
                                            </Col>
                                        </>
                                    )
                                })}
                            </Row>
                            <hr className="w-100 my-4" style={{ borderBottom: "1px solid" }} />
                            <h6 className="mb-3 ">TMOV</h6>
                            <Row className="m-0 mt-3" style={{ backgroundColor: "#f5f5f5", padding: "20px", borderRadius: '16px' }}>
                                {tmov?.map((elem: any) => {
                                    return (
                                        <>
                                            <Col md={3} className="mb-4 ">
                                                <span className="subtitle-modal">{elem.label}</span>
                                                <span className="response-modal">
                                                    <b>{elem.label.includes("DATA") ? new Date(elem.value).toLocaleDateString() : elem.value}</b>
                                                </span>
                                            </Col>
                                        </>
                                    )
                                })}
                            </Row>
                            <hr className="w-100 my-4" style={{ borderBottom: "1px solid" }} />
                            <h6 className="mb-3 ">TMOVCOMPL</h6>
                            <Row className="m-0 mt-3" style={{ backgroundColor: "#f5f5f5", padding: "20px", borderRadius: '16px' }}>
                                {tmovcompl?.map((elem: any) => {
                                    return (
                                        <>
                                            <Col md={3} className="mb-4 ">
                                                <span className="subtitle-modal">{elem.label}</span>
                                                <span className="response-modal">
                                                    <b>{elem.label.includes("DATA") ? new Date(elem.value).toLocaleDateString() : elem.value}</b>
                                                </span>
                                            </Col>
                                        </>
                                    )
                                })}
                            </Row> */}
                        </div>
                    </Modal.Body>
                    <Modal.Footer>
                        <div className="modal-footer rounded-0 border-0">
                            <button
                                type="button"
                                className="btn btn-outline-primary rounded-0"
                                data-dismiss="modal"
                                onClick={() => {
                                    setDetailsModal(false);
                                }}
                            >
                                <i className="fas fa-times fa-fw"></i>{" "}
                                <span data-vouchercampo="close">Fechar</span>
                            </button>
                        </div>
                    </Modal.Footer>
                </div>
            </>
        );
    } else {
        return (
            <>
                <Modal.Body>
                    <div className="modal-header rounded-0">
                        <div>
                            <div className="pt-1 text-primary title-reservation animated-background" style={{ height: "30px", width: "500px" }}></div>
                            <small></small>
                        </div>

                        <div className='buttons-options'>
                            <div>

                            </div>
                            <div>
                                <FontAwesomeIcon
                                    icon={['fal', 'times']}
                                    className="mx-3 text-info"
                                    size="lg"
                                    data-dismiss="modal"
                                    aria-label="Close"
                                    onClick={() => { setDetailsModal(false) }}
                                />
                            </div>
                        </div>
                    </div>

                    <hr className='w-100 my-4' style={{ borderBottom: '1px solid' }} />

                    <div className='w-100 p-0'>

                        <Row className='p-0 m-0'>
                            <Col md={2}>
                                <div className=" animated-background" style={{ height: "18px", width: "80%" }}></div>
                            </Col>
                            <Col md={3} >
                                <div className=" animated-background" style={{ height: "18px", width: "80%" }}></div>
                            </Col>
                            <div className='divider'></div>
                            <Col md={6} className="p-0 m-0 pl-4">
                                <div className=" animated-background" style={{ height: "18px", width: "80%" }}></div>
                            </Col>
                        </Row>

                        <Row className='p-0 m-0 mt-5'>
                            <div style={{ display: "flex", flexDirection: "column" }}>
                                <div className="mb-3 animated-background" style={{ height: "24px", width: "200px" }}></div>
                                <div className='d-flex'>
                                    <Col md={3} style={{ display: "flex", flexDirection: "column" }}>
                                        <span className="subtitle-modal animated-background" style={{ marginBottom: "10px", height: "18px", width: "60px" }}></span>
                                        <div className=" animated-background" style={{ height: "12px", width: "100px" }}></div>
                                    </Col>
                                    <Col md={3} >
                                        <span className="subtitle-modal animated-background" style={{ marginBottom: "10px", height: "18px", width: "60px" }}></span>
                                        <div className=" animated-background" style={{ height: "12px", width: "100px" }}></div>
                                    </Col>
                                    <Col md={3} >
                                        <span className="subtitle-modal animated-background" style={{ marginBottom: "10px", height: "18px", width: "60px" }}></span>
                                        <div className=" animated-background" style={{ height: "12px", width: "100px" }}></div>
                                    </Col>
                                </div>
                            </div>
                        </Row>

                        <Row className='p-0 m-0 mt-5'>
                            <div style={{ display: "flex", flexDirection: "column" }}>
                                <div className="mb-3 animated-background" style={{ height: "24px", width: "200px" }}></div>
                                <div className='d-flex'>
                                    <Col md={3} style={{ display: "flex", flexDirection: "column" }}>
                                        <span className="subtitle-modal animated-background" style={{ marginBottom: "10px", height: "18px", width: "60px" }}></span>
                                        <div className=" animated-background" style={{ height: "12px", width: "100px" }}></div>
                                    </Col>
                                    <Col md={3} >
                                        <span className="subtitle-modal animated-background" style={{ marginBottom: "10px", height: "18px", width: "60px" }}></span>
                                        <div className=" animated-background" style={{ height: "12px", width: "100px" }}></div>
                                    </Col>
                                    <Col md={3} >
                                        <span className="subtitle-modal animated-background" style={{ marginBottom: "10px", height: "18px", width: "60px" }}></span>
                                        <div className=" animated-background" style={{ height: "12px", width: "100px" }}></div>
                                    </Col>
                                    <Col md={3} >
                                        <span className="subtitle-modal animated-background" style={{ marginBottom: "10px", height: "18px", width: "60px" }}></span>
                                        <div className=" animated-background" style={{ height: "12px", width: "100px" }}></div>
                                    </Col>
                                </div>
                            </div>
                        </Row>
                    </div>

                </Modal.Body>

            </>
        );
    }
}

export default DetailsLogERM;