import React, { Key, useEffect, useState } from 'react';
import api from '../../services/api';

//import { useTranslation } from "react-i18next";

import Form from 'react-bootstrap/Form';

export interface propPlus {
  propsField: any,
  propsLabel: any,
  propsErrors: any,
  contaAdquirente: any,
  setAccountOperator: any
};

const GetAccountOperatorsFilter: React.FC<propPlus> = ({
  propsField, propsLabel, propsErrors, contaAdquirente, setAccountOperator
}: propPlus) => {
  //const { t } = useTranslation();

  const [info, setInfo] = useState<any>(null);
  //const [isMenuOpen, setIsMenuOpen] = useState<boolean>(false);
  //const [value, setValue] = useState<any>(propsField.defaultValue);

  /* const onMenuOpen = () => {
    if (isMenuOpen === false) {
      setIsMenuOpen(true);
    } else {
      setIsMenuOpen(false);
    }
  } */

  const handleChangeMethod = (e: any) => {
    
    const newValue = parseInt(e.target.value);
    setAccountOperator(info.find((item: any) => item.id === newValue));
    //setAccountOperator(newValue);
    //setValue(newValue);
    propsField.onChange(e);
  }

  useEffect(() => {
    const token = localStorage.getItem('GroupId') || '{}';
    const config = {
      headers: { 'Authorization': `Bearer ${token}` },
    };
    async function listAccountingAccounts() {
      try {
        const { data } = await api.get(`/BillsReceivable/GetAccountOperators`, config);
        if (data.status !== 400) {
          const dados = data.data.filter((e: any) => e.contaAdquirente === contaAdquirente);

          setInfo(dados);
        }
      } catch (error: any) {
        if (error.response.status === 401) {
          window.location.href = window.location.origin + '/';
        }
      }
    }
    listAccountingAccounts();
  }, [contaAdquirente]);

  if (info !== null) {
    return (
      <>
        <div className={propsErrors[propsField.name] !== undefined ? "endpoint-error" : "endpoint"}>
          {/* <Select
            aria-labelledby="aria-label"
            inputId="aria-example-input"
            name="aria-live-color"
            onMenuOpen={onMenuOpen}
            onMenuClose={onMenuOpen}
            {...propsField}
            aria-invalid={propsErrors[propsField.name] ? "true" : ""}
            label={propsLabel}
            as="select"
            variant="standard"
            margin="normal"
            placeholder="Selecione"
            options={Array.from(info)}
            getOptionValue={(option: any) => `${option.id}`}
            getOptionLabel={(option: any) => `${option.merchant.substring(0,10)} - ${option.cardType}`}
            value={info.find((a:any) => a.id == propsField.value)}
          /> */}
          <Form.Select
            {...propsField}
            aria-invalid={propsErrors[propsField.name] ? "true" : ""}
            label={propsLabel}
            as="select"
            variant="standard"
            margin="normal"
            required
            onChange={handleChangeMethod}
            //defaultValue={propsField.value}
            //value={value}
          >
            <option value=''>Selecione</option>
            {info.length > 0 ? info.map((info: any, index: Key) => (
              <option key={index} value={info.id}>{`${info.merchant.substring(0,10)} - ${info.cardType}`}</option>
            )) : ''}
          </Form.Select>
        </div>
      </>
    );
  } else {
    return (
      <>
        <Form.Select>
          <option value='' disabled></option>
        </Form.Select>
      </>
    )

  }
}

export default GetAccountOperatorsFilter;