import React, { Key } from "react";

import Table from "react-bootstrap/Table";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Dropdown from 'react-bootstrap/Dropdown';
import Modal from "react-bootstrap/Modal";

import FormBillReceivableItemEdit from "./FormBillReceivableItemEdit/FormBillReceivableItemEdit";

import BootstrapTable from "react-bootstrap-table-next";

//STYLES
import "./TableBillReceiveEditItems.scss";

export interface propModal {
  //action: any
  info: any,
  loading: any,
  statusErp: any,
  setModalSuccess: any,
  setModalResponse: any,
  setModalMessage: any,
  setLog: any
};

const TableBillReceiveEditItems: React.FC<propModal> = ({
  //action, 
  info, loading, statusErp
  , setModalSuccess, setModalResponse, setModalMessage, setLog
}: propModal) => {
  const [itemEditModalShow, setItemEditModalShow] = React.useState(false);
  const [itemEdit, setItemEdit] = React.useState(null);

  //const [controlObject, setControlObject] = useState<any>(info ? info : []);


  function handleItemEditClick(row: any) {

    // chama modal
    setItemEdit(row);
    setItemEditModalShow(true);
  }

  function handleActionItemEditModal(data: any) {
    setItemEdit(null);
  }

  function handleActionItemEditHideModal() {
    //() => setBillEditModalShow(false)
    setItemEditModalShow(false);
    setItemEdit(null);
  }

  /*converte a data UTC para a data local do usuário*/
  /* function convertUTCDateToLocalDate(date: any) {
    var newDate = new Date(date.getTime() + date.getTimezoneOffset() * 60 * 1000);

    //var offset = date.getTimezoneOffset() / 60;
    //var hours = date.getHours();

    //newDate.setHours(hours - offset);

    return newDate.toLocaleString().split(' ')[0];
  } */

  function convertDate(cell: any) {
    //var date = (cell) ? convertUTCDateToLocalDate(new Date(cell)) : '';
    var aux: any = cell.split("T")[0].split("-");
    var date: any = `${aux[2]}/${aux[1]}/${aux[0]}`;
    return (
      <>
        {date}
      </>
    );
  }

  function convertValue(cell: any) {
    return (
      <>
        <span className={Math.sign(cell) === -1 ? "negative" : ""}>{cell.toFixed(2).replace(".", ",")}</span>
      </>
    )
  }

  const rowClasses = (row: any, rowIndex: any) => {
    if(row.status === 2) {
      return 'canceled';
    }
    return '';
  };

  const columnsQuantities = [
    {
      dataField: "description",
      text: "Descrição",
    },
    {
      dataField: "quantity",
      text: "Qtde.",
    },
    {
      dataField: "value",
      text: "Valor",
      formatter: convertValue,
    },
  ];

  const loadingInfo = [{id:1},{id:2}];

  return (
    <>
      <div className="table-default bill-receive-items-table mt-0">
        <div className="table-container">
          <div className="table-title mb-3">
            <h3>Produtos</h3>
          </div>
          <Table striped bordered>
            <thead>
              <tr>
                <th>Data</th>
                <th>Serviço</th>
                <th>Valor</th>
                <th>Nº</th>
                <th>Centro Custo</th>
                <th>Ações</th>
              </tr>
            </thead>
            <tbody>
              {loading !== true ?
                (info.length > 0
                  ? info.map((info: any, index: Key) => (
                    <tr key={index} className={rowClasses(info,index)}>
                      <td>{convertDate(info.dataOperacao)}</td>
                      <td>{info.tour}</td>
                      <td>{convertValue(info.valor)}</td>
                      <td>
                        <div
                            className={"quantities-car"}
                          >
                            {info.qtdePax}
                            <div className="quantities-car-container">
                              <h6 className="">Quantidades</h6>
                              <div className="">
                                <BootstrapTable
                                  bootstrap4
                                  keyField="id"
                                  data={info.quantities ? info.quantities : []}
                                  columns={columnsQuantities}
                                  //rowClasses={rowClasses}
                                  striped={true}
                                />
                              </div>
                            </div>
                          </div>
                      </td>
                      <td>{info.centroCusto}</td>
                      <td>{
                        <div className="center-table options-table">
                          <Dropdown drop="start" >
                            <Dropdown.Toggle variant="light ">
                              <FontAwesomeIcon size="lg" icon={['fal', 'ellipsis-h']} />
                            </Dropdown.Toggle> 

                            <Dropdown.Menu>
                              <Dropdown.Item onClick={() => handleItemEditClick(info)} eventKey="1" disabled={statusErp === 2}><FontAwesomeIcon icon={['fal', 'edit']} className="mr-2" />Editar</Dropdown.Item>
                            </Dropdown.Menu>
                          </Dropdown>
                        </div>
                      }</td>
                    </tr>
                  ))
                  : <tr style={{ border: "none" }}><td colSpan={6} className="text-center">Nenhum item encontrado</td></tr>)
              : <>
                {loadingInfo.map((info: any, index: Key) => (
                    <tr key={index}>
                      <td colSpan={6} className="text-primary animated-background"></td>
                    </tr>
                  ))}
              </>}
            </tbody>
          </Table>

          {itemEdit !== null ?
            <Modal
              className="modalReceber"
              show={itemEditModalShow}
              onHide={handleActionItemEditHideModal}
              size="lg"
              aria-labelledby="contained-modal-title-vcenter"
            >
              <>
                <Modal.Header className="p-3" closeButton></Modal.Header>
                <FormBillReceivableItemEdit 
                  action={handleActionItemEditModal} 
                  info={itemEdit} 
                  setModalSuccess={setModalSuccess} 
                  setModalResponse={setModalResponse} 
                  setModalMessage={setModalMessage} 
                  setLog={setLog}  
                />
              </>
            </Modal> : <></>}
        </div>
      </div>
    </>
  );
}

export default TableBillReceiveEditItems;
