import React, { useEffect, useState } from 'react';
import Breadcrumb from '../../components/Breadcrumb/Breadcrumb';
import DesktopDefault from '../../templates/DesktopDefault';
import api from '../../services/api';
import ChannelTable from './components/ChannelTable';

import './ListChannels.scss';
import ModalDefault from '../../components/Modal/ModalDefault/ModalDefault';
import ChannelForm from './components/ChannelForm';
import ChannelFilter from './components/ChannelFilter';
import { Modal } from 'react-bootstrap';
import { useForm } from 'react-hook-form';

const ListChannels = () => {
    const [channels, setChannels] = useState<any>(null);
    const [loading, setLoading] = useState<boolean>(false);
    const [closeModal, setCloseModal] = useState<boolean>(false);

    const [totalRows, setTotalRows] = useState<any>();
    const [pageCount, setPageCount] = useState<any>(1);
    const [rowsPerPage, setRowsPerPage] = useState<any>(10);

    const [idSalesChannel, setIdSalesChannel] = useState<any>(null);

    const {
        control,
        getValues,
        setValue
    } = useForm({});

    function search(resetPagination: boolean = false, obj: any = {}) {
        setChannels(null);
        setLoading(true);

        const channelInfos = async () => {
            const token = localStorage.getItem('GroupId') || '{}';
            const config = {
                headers: { Authorization: `Bearer ${token}` },
            };
            try {
                const { data } = await api.post(`${process.env.REACT_APP_SERVER_URL_API}/SalesChannel/GetAllSalesChannelFilterByPaginate`, {
                    page: pageCount,
                    rowsPerPage,
                    createdBy: obj.createdBy || "",
                    salesChannelName: obj.salesChannelName || "",
                    statusSalesChannel: obj.statusSalesChannel || 0,
                }, config);

                if (data.statusCode === 200) {
                    setLoading(false);
                    setChannels(data.data.data.rows);
                    setTotalRows(data.data.data.rowsCount);

                    if (resetPagination) {
                        setPageCount(1);
                    }
                }
            } catch (error: any) { }
        };
        channelInfos();
    }

    useEffect(() => {
        let data: any = getValues();
        setChannels(null);
        setLoading(true);
        search(false, data);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [pageCount, rowsPerPage]);

    const reloadTable: any = () => {
        let data: any = getValues();
        setChannels(null);
        setLoading(true);
        search(false, data);
    }

    useEffect(() => {
        if (idSalesChannel === null) {
            reloadTable();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [idSalesChannel]);

    return (
        <>
            <DesktopDefault>
                <div className="container-fluid content-dashboard">
                    <Breadcrumb title={'Listar Canais'} />

                    <div className="mt-4 d-flex justify-content-between align-items-center">
                        <h2 className="title" style={{ color: "#707070" }}>Listar Canais </h2>

                        <ModalDefault
                            title="Inserir Novo Canal"
                            name="Novo Canal"
                            icon="no"
                            typeIcon="plus-circle"
                            classBtn="btn btn-primary"
                            classModal="modal-large"
                            closeAction={reloadTable}
                            closeModal={closeModal}
                            setCloseModal={setCloseModal}
                        >
                            <ChannelForm setCloseModal={setCloseModal} />
                        </ModalDefault>
                    </div>

                    <ChannelFilter
                        buscar={search}
                        control={control}
                        getValues={getValues}
                        setValue={setValue}
                    />
                    <ChannelTable
                        totalRows={totalRows}
                        pageCount={pageCount}
                        setPageCount={setPageCount}
                        rowsPerPage={rowsPerPage}
                        setRowsPerPage={setRowsPerPage}
                        channels={channels}
                        loading={loading}
                        setIdSalesChannel={setIdSalesChannel}
                    />

                    <Modal
                        className="modal-custom modalAuth modal-large"
                        show={idSalesChannel !== null}
                        onHide={() => {
                            setIdSalesChannel(null);
                        }}
                        size="lg"
                        aria-labelledby="contained-modal-title-vcenter"
                    >
                        <>
                            <Modal.Header closeButton>
                                <div className="d-flex justify-content-between w-100">
                                    <h3>Editar Canal</h3>
                                </div>
                            </Modal.Header>
                            <hr style={{ margin: "30px 0" }} />
                            <ChannelForm isEdit={true} idSalesChannel={idSalesChannel} setIdSalesChannel={setIdSalesChannel} />
                        </>
                    </Modal>

                </div>
            </DesktopDefault>
        </>
    );
};

export default ListChannels;