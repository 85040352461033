import React from "react";

import "react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css";
import "react-bootstrap-table-next/dist/react-bootstrap-table2.css";
import BootstrapTable from "react-bootstrap-table-next";

//STYLES
import "./TableSessionBillingBillsPay.scss";
import { OverlayTrigger, Tooltip } from "react-bootstrap";

export interface propTable {
  bills: any;
  loading: any;
}

const TableSessionBillingBillsPay: React.FC<propTable> = ({
  bills, loading
}: propTable) => {

  /*converte a data UTC para a data local do usuário*/
  function convertUTCDateToLocalDate(date: any) {
    var newDate = new Date(date.getTime() + date.getTimezoneOffset() * 60 * 1000);

    //var offset = date.getTimezoneOffset() / 60;
    //var hours = date.getHours();

    //newDate.setHours(hours - offset);

    return newDate.toLocaleString().split(' ')[0];
  }

  function convertDate(cell: any, row: any) {
    var date = (cell) ? convertUTCDateToLocalDate(new Date(cell)) : '';
    /* console.log(date) */
    return (
      <>
        {date}
      </>
    );
  }

  function convertValue(cell: any) {
    //console.log(Math.sign(cell))
    return (
      <>
        <span className={Math.sign(cell) === -1 ? "negative" : ""}>{cell.toFixed(2).replace(".", ",")}</span>
      </>
    )
  }

  function addGuideNote(cell: any, row: any) {
    return (
      <>
        <OverlayTrigger
          overlay={
            <Tooltip id="tooltip">{cell}</Tooltip>
          }>
          <span className="text-wrap-custom">
            {cell}
          </span>
        </OverlayTrigger>
        {row.supplierApproval === 2 ?
          <OverlayTrigger
            overlay={
              <Tooltip id="tooltip">{row.denialMessage}</Tooltip>
            }>
            <span className="text-wrap-custom">
              O pagamento foi rejeitado, justificativa: {row.denialMessage}
            </span>
          </OverlayTrigger>
        : <></>}
      </>

    )
  }
  
  function addNote(cell: any, row: any) {
    return (
      <>
        <OverlayTrigger
          overlay={
            <Tooltip id="tooltip">{cell}</Tooltip>
          }>
          <span className="text-wrap-custom">
            {cell}
          </span>
        </OverlayTrigger>
      </>

    )
  }

  /* function addStatus(cell: any, row: any) {
    return (
      cell !== 1 ?
        <FontAwesomeIcon
          icon={["fal", "check"]}
          size="1x"
          style={{ marginRight: "1px" }}
        />
      : <></>
    );
  } */

  function addTotalPax(cell: any, row: any) {
    return (
      <div
        className={"quantities"}
      >
        {cell}
        <div className="quantities-container">
          <h6 className="">Quantidades</h6>
          <div className="table">
            <BootstrapTable
              bootstrap4
              keyField="id"
              data={row.quantities ? row.quantities : []}
              columns={columnsQuantities}
              //rowClasses={rowClasses}
              striped={true}
            />
          </div>
        </div>
      </div>
    );
  }

  /* ALTERAR OS DATAFIELDS QUANDO A API CHEGAR */

  const columns = [
    { dataField: "descricao", text: "Descrição", sort: true },
    { dataField: "contaContabil", text: "Categoria", sort: true },
    { dataField: "fornecedor", text: "Fornecedor", sort: true },
    { dataField: "totalPax", text: "QTD", sort: true, formatter: addTotalPax },
    //{ dataField: "totalAdult", text: "ADT", sort: true },
    //{ dataField: "totalChild", text: "CHD", sort: true },
    //{ dataField: "totalInfant", text: "INF", sort: true },
    {
      dataField: "valor",
      text: "Valor",
      sort: true,
      formatter: convertValue,
    },
    {
      dataField: "vencimento",
      text: "Vencimento*",
      sort: true,
      formatter: convertDate,
    },
    { dataField: "observacaoGuia", text: "Obs. do Guia", sort: true, formatter: addGuideNote },
    { dataField: "observacao", text: "Observação*", sort: true, formatter: addNote },
  ];


  const columnsQuantities = [
    {
      dataField: "description",
      text: "Descrição",
    },
    {
      dataField: "quantity",
      text: "Qtde.",
    },
    {
      dataField: "value",
      text: "Valor",
      formatter: convertValue,
    },
  ];

  /* [END] ALTERAR OS DATAFIELDS QUANDO A API CHEGAR */

  /*--------------------------------------------------*/


  /* loading table */

  function loadingFunc() {
    return (
      <>
        <div className="animated-background row-loading"></div>
      </>
    )
  }

  const loadingColumns = [{ dataField: "contasd", text: "Carregando pagamentos", formatter: loadingFunc }];

  const loadingProducts = [
    { contas: "" }, { contas: "" }, { contas: "" }, { contas: "" }, { contas: "" }
    //,{ contas: "" }, { contas: "" }, { contas: "" }, { contas: "" }, { contas: "" }
  ];
  /* [END] loading table */


  /* In case of empty table*/
  const notFoundColumns = [{ dataField: "clientes", text: "Pagamentos" }];

  const notFoundProducts = [{ clientes: "Nenhum pagamento encontrado" }];
  /* [END] In case of empty table*/

  const rowClasses = (row: any, rowIndex: any) => {
    /* console.log(row, rowIndex) */
    if (row.statusPagamento === 2) {
      return 'border-analise';
    } else if (row.statusPagamento === 5) {
      return 'border-ativo';
    } else {
      return 'border-inativo';
    }
  };

  if(loading === true) {
    return (
      <>
        <div className="table-default session-b-bills-pay-table loading not-found">
          <div className="table-container">
            <div>
              <h4 className="p-0 m-0" style={{ color: "#707070" }}>Contas a Pagar</h4>
            </div>
            <BootstrapTable
              bootstrap4
              keyField="id"
              data={loadingProducts}
              hover={true}
              columns={loadingColumns}
              striped={true}
            />
          </div>
        </div>
      </>
    );
  } else if (bills !== null) {
    return (
      <>
        <div className="table-default session-b-bills-pay-table">
          <div className="table-container">
            <div>
              <h4 className="p-0 m-0" style={{ color: "#707070" }}>Contas a Pagar</h4>
            </div>
            <BootstrapTable
              bootstrap4
              keyField="id"
              data={bills}
              columns={columns}
              rowClasses={rowClasses}
              striped={true}
            />
          </div>
        </div>
      </>
    );
  } else {
    return (
      <>
        <div className="table-default session-b-bills-pay-table loading not-found">
          <div className="table-container">
            <div>
              <h4 className="p-0 m-0" style={{ color: "#707070" }}>Contas a Pagar</h4>
            </div>
            <BootstrapTable
              bootstrap4
              keyField="id"
              data={notFoundProducts}
              hover={true}
              columns={notFoundColumns}
              striped={true}
            />
          </div>
        </div>
      </>
    );
  }
}

export default TableSessionBillingBillsPay;
