import React, { useEffect, useState } from "react";
import { Container, Row, Col } from "react-bootstrap";

import "../../../../../../assets/sass/modal.scss";
import "./ImportWarns.scss";

//import Modal from "react-bootstrap/Modal";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Form from "react-bootstrap/Form";
import Modal from "react-bootstrap/Modal";

//import IconSucess from "../../../assets/icons/modal-sucess.svg";

import api from '../../../../../../services/api';

export interface propModal {
  importId: any,
  setModalShow: any
  // show: any,
  // setResponseText: any,
  // setModalContent: any,
  // setLog: any,
};

const ImportWarns: React.FC<propModal> = ({
  importId, setModalShow //show, setResponseText, setModalContent, setLog
}: propModal, props: any) => {
  const [messageList, setMessageList] = useState<any>(null);

  //const [messageToSend, setMessageToSend] = useState<any>('');

  // const {
  //   control,
  //   handleSubmit,
  //   formState: { errors },
  // } = useForm();


  const getSessionMessages = async () => {
    const token = localStorage.getItem('GroupId') || '';

    const config = {
      headers: { 'Authorization': `Bearer ${token}` },
    };
    try {

        const { data } = await api.get(`/Decolar/GetImportLogWarnsAsync/${importId}`, config);
        /* console.log(data.data); */
        if (data.status !== 400) {
            var response = data.data;

            setMessageList(response);
        }   
    }catch(error: any) {
        if (error?.response?.status === 401) {
            window.location.href = window.location.origin + '/';              
        }
        if (error?.response?.status === 400) {
            //window.location.href = window.location.origin + '/session-closure';              
        }
        //;
    }
  }

  useEffect(() => {
    
    if(importId) getSessionMessages();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [importId]);

  if(importId !== null && messageList !== null) {
    return (
      <>
        <Modal.Body>
          <Container className="bg-modal-contact p-0" fluid>
            <Row className="h-100 flex-column">
              <h5 className="contact-title mb-5">Linhas Rejeitadas</h5>
              <div className="" style={{overflow: 'auto', maxHeight: '500px'}}>
                <div className="table-container">   
                  {messageList.length > 0 ? messageList.map((item: any, index: any) => (
                  <Row className="mb-2" key={index}>
                    <Form.Group as={Col} md="12">
                      <Form.Group as={Col} md="12" className="p-2 message-border">{item.texto}</Form.Group>
                    </Form.Group>
                  </Row>
                  ))
                  :
                  <Row className="mb-2 text-center"><span>Nenhum histórico de rejeitados!</span></Row>}
                </div>
              </div> 
              <div className="mt-auto">
                <Form
                  className="form-add-contact"
                  noValidate
                >
                  <Row className="d-flex justify-content-center">
                    <Form.Group as={Col} md="4">
                      <button
                        type="button"
                        className="btn btn-primary w-100"
                        onClick={() => setModalShow(false)}
                      >
                        <FontAwesomeIcon
                          icon={["fal", "check"]}
                          size="1x"
                          style={{ marginRight: "5px" }}
                        /> Confirmar
                      </button>
                    </Form.Group>
                  </Row>
                </Form>
              </div>
            </Row>
          </Container>
        </Modal.Body>
  
      </>
    );
  } else {
    return (<></>);
  }
  
}

export default ImportWarns;
