import React, { Key, useEffect } from 'react';
import Table from "react-bootstrap/Table";

import { Row, Col} from "react-bootstrap";
import { Form } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import './GroupFileDetails.scss';

export interface propInsert {
    info: any,
    onSuccess?: any,
    t?: any
}

const GroupFileDetails: React.FC<propInsert> = ({
    info, onSuccess, t
}: propInsert) => {
    //function EditPayment() {

    const formatDate = (data: any) => {
        if (!data) return '';

        //2022-05-05T00:00:00
        var aux = data.split('T');
        aux = aux[0].split('-');

        return (`${aux[2]}/${aux[1]}/${aux[0]}`);
    }
    function formatValue(cell: any) {
        //console.log(Math.sign(cell))
        if (cell && cell !== '') {
            return cell.toFixed(2).replace(".", ",");
        } else {
            return '0,00';
        }
    }
   

    function closeModal() {
        var closeBtn: any = document.getElementsByClassName("btn-close")[0];
        if (closeBtn !== undefined) {
            closeBtn.click();
        }
    }

    // const {
    //     control,
    //     handleSubmit,
    //     formState: { errors },
    // } = useForm();

    const fixDate = (data: any) => {
        var aux = data.split('/');

        return (`${aux[2]}-${aux[1]}-${aux[0]}`);
    }

    function calcTotals(services: any) {
        var totalR = 0;
        var totalP = 0;
        var totalPax = info.passengers.length;

        for(var i = 0; i < services.length; i++) {
            var multi = 1;
            if(services[i].globalPrice === '2') {
                multi = totalPax;
            }
            totalP += parseFloat(services[i].netAmount ? services[i].netAmount : 0) * multi;
            totalR += parseFloat(services[i].saleAmount ? services[i].saleAmount : 0) * multi;
        }
        info.totalReceive = totalR;
        info.totalPay = totalP;
        info.profitAmount = (totalR - totalP);
        info.profitTax = (totalR ? ((totalR - totalP) / totalR) * 100 : 0);
    }

    // useEffect(() => {
    //     if (errors) {
    //         setResponseText(t("billsToPay.table.paymentAndDebitNotes.fillUpTheFields"));
    //         setModalResponse('error');
    //         setLog(1);
    //     }
    // // eslint-disable-next-line react-hooks/exhaustive-deps
    // }, [errors])

    function handleReload() {
        window.location.reload();
    }

    if (info !== null) {
        calcTotals(info.services);

        return (
            <>
                {/* <Form className="h-100 form-inserir" noValidate validated={validated} onSubmit={handleSubmit(onSubmit)} autoComplete="false"> */}
                {/* <div className="h-100 form-inserir"> */}
                    <div className='d-flex flex-column justify-content-between h-100'>
                        <div>
                        <Row className="mb-3">
                            {/* <Form.Group as={Col} md={6}>
                                <div className='d-flex align-items-center' style={{ height: "86px" }}>
                                    <Form.Check
                                        name="customerType"
                                        type="radio"
                                        label={t("groupsAndCustomized.customerType.registeredCustomer")}
                                        // name="customerType"
                                        id="customerTypeRadios1"
                                        checked={info.customerType === 1}
                                        className="col-md-6"
                                    />
                                    <Form.Check
                                        name="customerType"
                                        type="radio"
                                        label={t("groupsAndCustomized.customerType.quickFile")}
                                        // name="customerType"
                                        id="customerTypeRadios3"
                                        checked={info.customerType === 3}
                                        className="col-md-6"
                                    />
                                </div>
                            </Form.Group> */}
                            <Form.Group as={Col} md={6}>
                                <Form.Label>{t("groupsAndCustomized.operator")}</Form.Label>
                                <p>{info.operatorUser.name} {info.operatorUser.lastName}</p>
                            </Form.Group>
                        </Row>    
                        <Row className="mb-3">
                            <Form.Group as={Col} md={6} controlId="formGridSupplier">
                                <Form.Label>{t("groupsAndCustomized.client")}</Form.Label>
                                <p>{info.clientName}</p>
                            </Form.Group>
                            <Form.Group as={Col} md={6} controlId="formGridDesc">
                                <Form.Label>{t("groupsAndCustomized.groupName")}</Form.Label>
                                <p>{info.groupName}</p>
                            </Form.Group>
                        </Row>
                        <Row className="mb-3">    
                            <Form.Group as={Col} md={6}>
                                <Form.Label>{t("groupsAndCustomized.tarType")}</Form.Label>
                                <p>{info.tarTypeDesc ? info.tarTypeDesc : t("groupsAndCustomized.tarTypeOpts.uninformed")}</p>
                                {/* <div className='d-flex align-items-center' style={{ height: "86px" }}>
                                    <Form.Check
                                        name="tarType"
                                        type="radio"
                                        label={t("groupsAndCustomized.tarTypeOpts.clientCommissioned")}
                                        // name="customerType"
                                        id="tarTypeRadios1"
                                        checked={info.tarType === 1}
                                        className="col-md-6"
                                    />
                                    <Form.Check
                                        name="tarType"
                                        type="radio"
                                        label={t("groupsAndCustomized.tarTypeOpts.clientNet")}
                                        // name="customerType"
                                        id="tarTypeRadios2"
                                        checked={info.tarType === 2}
                                        className="col-md-6"
                                    />
                                </div> */}
                            </Form.Group>
                            <Form.Group as={Col} md={3} controlId="formGridCat">
                                <Form.Label>{t("groupsAndCustomized.comission")}</Form.Label>
                                <p>{formatValue(info.comission)}</p>
                            </Form.Group>
                            <Form.Group as={Col} md={3} controlId="formGridCat">
                                <Form.Label>{t("groupsAndCustomized.markup")}</Form.Label>
                                <p>{formatValue(info.markup)}</p>
                            </Form.Group>
                        </Row>
                        <Row className="mb-3">
                            {/* Data da Abertura    Data do 1o Serviço    Moeda INVOICE(so "Real - BRL" por enquanto) */}
                            <Form.Group as={Col} md={4}>
                                <Form.Label>{t("groupsAndCustomized.dateCreated")}</Form.Label>
                                <p>{formatDate(info.dateCreated)}</p>
                            </Form.Group>
                            <Form.Group as={Col} md={4}>
                                <Form.Label>{t("groupsAndCustomized.firstServiceDate")}</Form.Label>
                                <p>{formatDate(info.firstServiceDate)}</p>
                            </Form.Group>
                            <Form.Group as={Col} md={4}>
                                <Form.Label>{t("groupsAndCustomized.currency")}</Form.Label>
                                <p>{info.currency === "BRL" ? "Real - BRL" 
                                : info.currency === "USD" ? "Dolar - USD" 
                                : info.currency === "EUR" ? "Euro - EUR"
                                : ""}</p>
                            </Form.Group>
                        </Row>
                        <Row className="mb-3">
                            <Form.Group as={Col} md={4}>
                                <Form.Label>{t("groupsAndCustomized.fileNo")}</Form.Label>
                                <p>{info.fileNo}</p>
                            </Form.Group>
                            <Form.Group as={Col} md={4}>
                                <Form.Label>{t("groupsAndCustomized.operationStatus")}</Form.Label>
                                <p>{info.operationStatusDesc}</p>
                            </Form.Group>
                            <Form.Group as={Col} md={4}>
                                <Form.Label>{t("groupsAndCustomized.financialStatus")}</Form.Label>
                                <p>{info.financialStatusDesc}</p>
                            </Form.Group>
                        </Row>
                        <hr className="my-5" />
                        <h5 className="text-uppercase mb-3">{t("groupsAndCustomized.passengers.tableTitle")}</h5>
                        <Row>
                            {/* tabela de passageiros */}
                            <div className="table-add-default groupfile-passenger-table">
                                <div className="table-container">
                                    <Table striped bordered>
                                        <thead>
                                        <tr>
                                            <th>{t("groupsAndCustomized.passengers.name")}</th>
                                            <th>{t("groupsAndCustomized.passengers.passportId")}</th>
                                            <th>{t("groupsAndCustomized.passengers.email")}</th>
                                            <th>{t("groupsAndCustomized.passengers.phone")}</th>
                                        </tr>
                                        </thead>
                                        <tbody>
                                        {info.passengers.length > 0
                                            ? info.passengers.map((item: any, index: Key) => (
                                            <tr key={index}>
                                                <td>
                                                    {/* Nome */}
                                                    {item.name}
                                                </td>
                                                <td>
                                                    {/* Passaporte/ID */}
                                                    {item.passportId}
                                                </td>
                                                <td>
                                                    {/* E-Mail */}
                                                    {item.email}
                                                </td>
                                                <td>
                                                    {/* Telefone */}
                                                    {item.phone}
                                                </td>
                                            </tr>
                                            ))
                                            : ""
                                        }
                                        </tbody>
                                    </Table>
                                </div>
                            </div>
                        </Row>
                        <hr className="my-5" />
                        <h5 className="text-uppercase mb-3">{t("groupsAndCustomized.services.tableTitle")}</h5>
                        <Row>
                            {/* tabela de serviços */}
                            <div className="table-add-default groupfile-service-table">
                                <div className="table-container">
                                    <Table striped bordered>
                                        <thead>
                                        <tr>
                                            <th>{t("groupsAndCustomized.services.type")}</th>
                                            <th>{t("groupsAndCustomized.services.resourceProduct")}</th>
                                            <th>{t("groupsAndCustomized.services.supplier")}</th>
                                            <th>{t("groupsAndCustomized.services.globalPrice")}</th>
                                            <th>{t("groupsAndCustomized.services.netAmount")}</th>
                                            <th>{t("groupsAndCustomized.services.saleAmount")}</th>
                                            <th>{t("groupsAndCustomized.services.serviceDate")}</th>
                                            <th>{t("groupsAndCustomized.services.city")}</th>
                                            <th>{t("groupsAndCustomized.services.language")}</th>
                                            <th>{t("groupsAndCustomized.services.status")}</th>
                                        </tr>
                                        </thead>
                                        <tbody>
                                        {info.services.length > 0
                                            ? info.services.map((item: any, index: Key) => (
                                            <tr key={index}>
                                                <td>
                                                    {/* type */}
                                                    {item.serviceTypeName}
                                                </td>
                                                <td>
                                                    {/* resource/product */}
                                                    {item.serviceName}
                                                </td>
                                                <td>
                                                    {/* supplier */}
                                                    {item.supplierName}
                                                </td>
                                                <td>
                                                    {/* global price */}
                                                    {item.globalPriceDesc}
                                                </td>
                                                <td>
                                                    {/* net amount */}
                                                    {formatValue(item.netAmount)}
                                                </td>
                                                <td>
                                                    {/* sale amount */}
                                                    {formatValue(item.saleAmount)}
                                                </td>
                                                <td>
                                                    {/* service date */}
                                                    {formatDate(item.serviceDate)}
                                                </td>
                                                <td>
                                                    {/* city */}
                                                    {item.city}
                                                </td>
                                                <td>
                                                    {/* language */}
                                                    {item.languageDesc}
                                                </td>
                                                <td>
                                                    {/* status */}
                                                    {item.statusDesc}
                                                </td>
                                            </tr>
                                            ))
                                            : ""
                                        }
                                        </tbody>
                                    </Table>
                                </div>
                            </div>
                        </Row>
                        <hr className="my-5" />
                        <Row className="mb-3">
                            <Form.Group as={Col} md={3}>
                                <Form.Label>{t("groupsAndCustomized.totalReceive")}</Form.Label>
                                <p>{formatValue(info.totalReceive)}</p>
                            </Form.Group>
                            <Form.Group as={Col} md={3}>
                                <Form.Label>{t("groupsAndCustomized.totalPay")}</Form.Label>
                                <p>{formatValue(info.totalPay)}</p>
                            </Form.Group>
                            <Form.Group as={Col} md={3}>
                                <Form.Label>{t("groupsAndCustomized.profitAmount")}</Form.Label>
                                <p>{formatValue(info.profitAmount)}</p>
                            </Form.Group>
                            <Form.Group as={Col} md={3}>
                                <Form.Label>{t("groupsAndCustomized.profitTax")}</Form.Label>
                                <p>{formatValue(info.profitTax)}</p>
                            </Form.Group>
                        </Row>
                        <Row className="mb-3">
                            {/* A fatura será paga por? Valor Total; Serviço Discriminado. */}
                            <Form.Group as={Col} md={6}>
                                <Form.Label>{t("groupsAndCustomized.invoiceType")}</Form.Label>
                                <p>{info.invoiceTypeDesc}</p>
                                {/* <div className='d-flex align-items-center' style={{ height: "86px" }}>
                                    <Form.Check
                                        type="radio"
                                        label={t("groupsAndCustomized.invoiceTypeOpts.totalValue")}
                                        name="invoiceType"
                                        id="invoiceTypeRadios1"
                                        checked={info.invoiceType === 1}
                                        className="col-md-6"
                                    />
                                    <Form.Check
                                        type="radio"
                                        label={t("groupsAndCustomized.invoiceTypeOpts.serviceItemized")}
                                        name="invoiceType"
                                        id="invoiceTypeRadios2"
                                        checked={info.invoiceType === 2}
                                        className="col-md-6"
                                    />
                                </div> */}
                            </Form.Group>
                            {info.invoiceType === 1?  
                            <Form.Group as={Col} md={2}>
                                <Form.Label>{t("groupsAndCustomized.totalAmount")}</Form.Label>
                                <p>{formatValue(info.totalAmount)}</p>
                            </Form.Group>
                            : <></>}
                        </Row>
                        </div>

                        <div className='d-flex justify-content-between'>
                            <div className='mt-4 d-flex justify-content-end'>
                                <div>
                                    <button className="btn btn-default btn-outline-primary px-4 mr-3 text-uppercase" onClick={() => closeModal()}>
                                    {t("billsToPay.table.paymentAndDebitNotes.confirm")}
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                {/* </Form> */}
                {/* </div> */}
            </>
        )
    } else {
        return (<></>);
    }

}

export default GroupFileDetails;