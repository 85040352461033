/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useEffect, useState, useRef } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ErrorMessage } from "@hookform/error-message";
import { Accordion, Button, Card, Col, Dropdown, Form, InputGroup, Modal, Row, } from "react-bootstrap";
import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
import Select from 'react-select';
import { Controller, useForm } from "react-hook-form";
import SingleCalendarWithDefaultValue from "../../Calendar/SingleCalendarWithDefaultValue";
import ModalShow from "../../Modal/ModalShow/ModalShow";
import BootstrapTable from "react-bootstrap-table-next";
import { useAppSelector, useAppDispatch } from "../../../store/hooks";
import { addTariffs, removeTariff, editTariff, changeTariffStatus, ticketRemoveTariff } from "../../../store/product";
import TariffPromoByHour from "../../../pages/ProductTour/TariffPromoByHour";
import FillActivityStartModal from "../FillActivityStartModal/FillActivityStartModal";
import GetTime15MinInterval from "../../C2Points/GetTime15MinInterval";
import api from "../../../services/api";
import "./Tariff.scss";
import GetSalesChannel from "../../C2Points/GetSalesChannel";

export interface operation {
  action: any;
  receiveObject: any;
  tourObject: any;
  isEdit: any;
  isTicket?: boolean;
  editFunction?: any;
}

const Tariffs: React.FC<operation> = React.memo(({
  action, receiveObject, tourObject, isEdit = false, isTicket = false, editFunction = () => { }
}: operation) => {
  /* Controle Redux  */
  const dispatch = useAppDispatch();
  const tourSIG: any = JSON.parse(
    JSON.stringify(useAppSelector((state: any) => state.tour))
  );
  /* Controle Redux  */

  /* ModalControl */
  const [modalShow, setModalShow] = useState<boolean>(false);
  const [modalMessage, setModalMessage] = useState<string>("");
  const [modalLog, setModalLog] = useState<any>(0);
  /* end ModalControl */

  const [modalShowTimeAutomaticCalc, setModalShowTimeAutomaticCalc] = useState<boolean>(false);
  const [modalTimeIndexSelected, setModalTimeIndexSelected] = useState<number>(0);

  const [beginDate, setBeginDate] = useState<any>("");
  const [endDate, setEndDate] = useState<any>("");
  const [tariffs, setTariffs] = useState<any>([[]]);
  const [dateConflictModalShow, setDateConflictModalShow] = useState<boolean>(false);
  const [promotionByHourModalShow, setPromotionByHourModalShow] = useState<boolean>(false);
  const [isEditing, setIsEditing] = useState<any>([null, null]);

  const [promoObject, setPromoObject] = useState<any>([]);
  const [promoIdToDelete, setPromoIdToDelete] = useState<any>(null);
  const [promoIdToActive, setPromoIdToActive] = useState<any>(null);
  const [promoIdToEdit, setPromoIdToEdit] = useState<any>(null);

  const [activityStartObj, setActivityStartObj] = useState<any>(null);
  const [salesChannelObj, setSalesChannelObj] = useState<any>(null);
  const [salesChannelInfoEdit, setSalesChannelInfoEdit] = useState<any>(null);

  const [actualTarcode, setActualTarcode] = useState<string | null>(null);

  const [infoChange, setInfoChange] = useState<any>(undefined);

  let actualModCode: string | null = null;

  const [days, setDays] = useState<any>([
    {
      day: "dom",
      value: isEdit === true ? tourObject.dom : false,
      index: [],
      disabled: false,
      controlAvailable: false,
    },
    {
      day: "seg",
      value: isEdit === true ? tourObject.seg : false,
      index: [],
      disabled: false,
      controlAvailable: false,
    },
    {
      day: "ter",
      value: isEdit === true ? tourObject.ter : false,
      index: [],
      disabled: false,
      controlAvailable: false,
    },
    {
      day: "qua",
      value: isEdit === true ? tourObject.qua : false,
      index: [],
      disabled: false,
      controlAvailable: false,
    },
    {
      day: "qui",
      value: isEdit === true ? tourObject.qui : false,
      index: [],
      disabled: false,
      controlAvailable: false,
    },
    {
      day: "sex",
      value: isEdit === true ? tourObject.sex : false,
      index: [],
      disabled: false,
      controlAvailable: false,
    },
    {
      day: "sab",
      value: isEdit === true ? tourObject.sab : false,
      index: [],
      disabled: false,
      controlAvailable: false,
    },
  ]);

  /* Detecta clique fora da div#wrapper para fechar*/
  const wrapperRef = useRef(null);
  useOutsideAlerter(wrapperRef);

  function useOutsideAlerter(ref: any) {
    useEffect(() => {
      function handleClickOutside(event: any) {
        if (ref.current && !ref.current.contains(event.target)) {
          const aux: any = document.querySelectorAll<HTMLElement>(`.panels`);
          aux.forEach((elem: any) => {
            elem.style.display = 'none'
          });
        }
      }
      document.addEventListener("mousedown", handleClickOutside);
      return () => {
        document.removeEventListener("mousedown", handleClickOutside);
      };
    }, [ref]);
  }

  const handleClickAntecedencia = (salesId: number) => {
    const removeAll: any = document.querySelectorAll<HTMLElement>(`.panels`);
    removeAll.forEach((elem: any) => {
      if (![...elem.classList].includes(`panel-${salesId}`)) {
        elem.style.display = 'none'
      }
    });

    const aux: any = document.querySelectorAll<HTMLElement>(`.panel-${salesId}`);

    if (aux[0].style.display !== 'block') {
      aux[0].style.display = 'block';
    } else {
      aux[0].style.display = 'none';
    }
  }

  /* GETTIME15MINUTESINTERVAL */
  useEffect(() => {
    const token = localStorage.getItem('GroupId') || '{}';
    const config = {
      headers: { 'Authorization': `Bearer ${token}` },
    };
    async function listTimes() {
      try {
        const { data } = await api.post(`/Domains/GetDomainHours`, {}, config);
        if (data.status !== 400) {
          let aux: any = data.data.filter((elem: any) => elem.flagQuaterHour === 1)
          setActivityStartObj(() => aux)
        }
      } catch (error: any) {
        setTimeout(() => {
          listTimes();
        }, 5000);
      }
    }
    listTimes();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  /* GETTIME15MINUTESINTERVAL */

  const {
    control,
    handleSubmit,
    getValues,
    setValue,
    watch,
    formState: { errors },
  } = useForm({});

  const watchComission = watch(`commission`, "");
  const watchModality = watch(`modality`, null);

  const today = new Date();

  const todayStr = `${today.getDate() < 10 ? "0" + today.getDate() : today.getDate()
    }/${today.getMonth() < 10
      ? "0" + (today.getMonth() + 1)
      : "" + (today.getMonth() + 1)
    }/${today.getFullYear()}`;

  useEffect(() => {
    if (watchComission === "0") {
      //apaga venda
      for (let i: number = 0; i < tariffs.length; i++) {
        if (tariffs[i] !== null) {
          setValue(`tariffInfantAlterado-${i}`, "");
          setValue(`tariffChildAlterado-${i}`, "");
          setValue(`tariffAdultAlterado-${i}`, "");
          setValue(`tariffGlobalAlterado-${i}`, "");
        }
      }
    } else if (watchComission === "1") {
      //apaga percent
      for (let i: number = 0; i < tariffs.length; i++) {
        if (tariffs[i] !== null) {
          setValue(`tariffPercent-${i}`, "");
          setValue(`tariffPercent-${i}`, "");
          setValue(`tariffPercent-${i}`, "");
          setValue(`tariffPercent-${i}`, "");
        }
      }
    }

    /* handleChangeComission(); */
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [watchComission]);

  const nextStep = () => {
    let data: any = getValues();
    action("location", data);
  };

  const verifyDate = () => {
    let data: any = getValues();

    if (tourSIG.tour.modalidade[watchModality?.value][`${isTicket ? "ticketTariff" : "tariff"}`].length === 0) {
      return true;
    }

    let tariffDate1: any;
    let tariffDate2: any;
    let begin: any;
    let end: any;
    let checkActivityStart: boolean = false;
    let checkDaysOfWeek: boolean = false;
    let validator: boolean = true;

    tourSIG.tour.modalidade[watchModality?.value][`${isTicket ? "ticketTariff" : "tariff"}`].forEach((elem: any, index: number) => {
      tariffDate1 = parseInt(elem.tariffStart.replaceAll("-", ""));
      tariffDate2 = parseInt(elem.tariffEnd.replaceAll("-", ""));
      begin = parseInt(beginDate.split("/").reverse().join(""));
      end = parseInt(endDate.split("/").reverse().join(""));

      if (index !== isEditing[1]) {

        if (tariffDate1 <= end && tariffDate2 >= begin) {

          //Validação para quando tiver canais de venda
          data[`salesChannel`].forEach((salesElem: any) => {
            if (elem.canaisTarifario.map((canal: any) => canal.value || canal.idSalesChannel)?.includes(salesElem.value)) {
              checkDaysOfWeek = true;
            }
          });

          if (checkDaysOfWeek) {
            for (let i: number = 0; i < elem[`${isTicket ? "ticketDailyTariff" : "daysTariff"}`].length; i++) {
              for (let j: number = 0; j < tariffs.length; j++) {
                if (tariffs[j] !== null) {

                  //daysOfWeek só funciona no tour
                  // eslint-disable-next-line no-loop-func
                  days.forEach((daysElement: any, index: number) => {
                    if (daysElement.controlAvailable === false && daysElement.index.includes(j)) {
                      if (!isTicket && elem[`${isTicket ? "ticketDailyTariff" : "daysTariff"}`][i].daysOfWeek[`${daysElement.day}`]) {
                        checkActivityStart = true;
                      }
                    }
                  });

                  if (checkActivityStart) {
                    // eslint-disable-next-line no-loop-func
                    data[`activityStart-${j}`].forEach((activityElem: any) => {
                      if (elem[`${isTicket ? "ticketDailyTariff" : "daysTariff"}`][i].horariosTarifario.map((elem: any) => elem['hora' || 'hour'].slice(0, 5))?.includes(activityElem.label)) {
                        validator = false;
                      }
                    });
                    checkActivityStart = false;
                  }
                }
              }
            }
          }
        }
      }
    });

    return validator;
  };

  const verifyWeekDay = () => {
    let data: any = getValues();
    let validate: boolean = true; //true -> sem problemas
    let auxDays: any = days.filter((elem: any) => elem.index.length > 0);
    let gabarito: any = [];

    const verifyConflict = (day: string, index1: number, index2: number) => {

      data[`activityStart-${index1}`].forEach((elemIndex1: any) => {
        data[`activityStart-${index2}`].forEach((elemIndex2: any) => {
          if (elemIndex1.value === elemIndex2.value && validate) {
            validate = false
            setModalMessage(`dia ${day} com horário ${elemIndex1.value} em conflito`);

            return false;
          }
        });
      });

      return true;
    }

    for (let i: any = 0; i < auxDays.length; i++) {
      if (auxDays[i].index.length > 0) {
        for (let j: any = 0; j < auxDays[i].index.length; j++) {
          for (let k: any = j; k < auxDays[i].index.length; k++) {
            if (j !== k && (!gabarito.map((e: any) => e.gabarito1).includes(auxDays[i].index[j]) && !gabarito.map((e: any) => e.gabarito2).includes(auxDays[i].index[k]))) {
              if (verifyConflict(auxDays[i].day, auxDays[i].index[j], auxDays[i].index[k])) {
                gabarito.push({
                  "gabarito1": auxDays[i].index[j],
                  "gabarito2": auxDays[i].index[k]
                })
              }
            }
          }
        }
      }
    }

    //pelo menos 1 dia selecionado
    let aux: number = 0;
    for (let i: number = 0; i < tariffs.length; i++) {
      if (tariffs[i] !== null) {
        aux = 0;
        // eslint-disable-next-line no-loop-func
        days.filter((elem: any) => elem.index.includes(i)).forEach((elem: any) => {
          if (isTicket) {
            if (tourSIG.tour.modalidade[watchModality.value || 0][elem.day] === 1) {
              aux += 1;
            }
          } else {
            if (tourSIG.tour.modalidade[watchModality.value || 0].daysOfWeek[elem.day]) {
              aux += 1;
            }
          }
        });

        if (aux === 0) {
          validate = false;
          setModalMessage("Todas as tarifas devem ter pelo menos 1 dia da semana selecionado");
        }
      }
    }

    return validate;
  }

  const createTicketObject = (data: any) => {
    data.isTicket = true

    data.tarCode = actualTarcode !== null ? {
      "ticketTariffCode": actualTarcode
    } : undefined

    let modCode: any = actualModCode !== null ? {
      "modCode": actualModCode,
      "ticketModCode": actualModCode
    } : {}

    let productCode: any = tourSIG.tour.productCode ? {
      "productCode": tourSIG.tour.productCode
    } : {}

    let aux = days.map((elem: any) => {
      return elem.index;
    });

    let auxDays: any = [];

    aux.forEach((element: any) => {
      auxDays.push(...element);
    });

    let allDaysUnit: any = auxDays.filter((elem: any, index: number) => {
      return auxDays.indexOf(elem) === index && elem !== null;
    });

    data.ticketDailyTariff = [];

    let activityStartFullObject: any[] = [];
    for (let i: number = 0; i < allDaysUnit.length; i++) {
      let activityStart: string[] = [];
      let horariosTarifario: any[] = [];

      for (let j: number = 0; j < data[`activityStart-${allDaysUnit[i]}`].length; j++) {
        activityStart.push(data[`activityStart-${allDaysUnit[i]}`][j].value);
      }

      activityStart.forEach((hour: string) => {
        horariosTarifario.push({
          ...data.tarCode,
          ...modCode,
          ...productCode,

          "daysTarCode": isEditing[0] !== null ? (tourSIG.tour.modalidade[isEditing[0]].ticketTariff[isEditing[1]].ticketDailyTariff.length < i ? tourSIG.tour.modalidade[isEditing[0]].ticketTariff[isEditing[1]].ticketDailyTariff[i].daysTarCode : undefined) : undefined,
          "daysUniqueTarCode": isEditing[0] !== null ? (tourSIG.tour.modalidade[isEditing[0]].ticketTariff[isEditing[1]].ticketDailyTariff.length < i ? tourSIG.tour.modalidade[isEditing[0]].ticketTariff[isEditing[1]].ticketDailyTariff[i].daysUniqueTarCode : undefined) : undefined,
          "hora": hour,
          "percentTarifInfant": parseInt(data[`tariffPercentInfant-${allDaysUnit[i]}`]) || 0,
          "percentTarifChild": parseInt(data[`tariffPercentChild-${allDaysUnit[i]}`]) || 0,
          "percentTarifAdult": parseInt(data[`tariffPercentAdult-${allDaysUnit[i]}`]) || 0,
          "percentTarifElders": parseInt(data[`tariffPercentElders-${allDaysUnit[i]}`]) || 0,
          "percentTarifStudent": parseInt(data[`tariffPercentStudent-${allDaysUnit[i]}`]) || 0,
          "percentTarifGlobal": parseInt(data[`tariffPercentGlobal-${allDaysUnit[i]}`]) || 0,
          "costTarifInfant": Number(data[`tariffInfantCost-${allDaysUnit[i]}`]) || 0,
          "sellingTarifInfant": Number(data[`tariffInfantAlterado-${allDaysUnit[i]}`]) || 0,
          "costTarifChild": Number(data[`tariffChildCost-${allDaysUnit[i]}`]) || 0,
          "sellingTarifChild": Number(data[`tariffChildAlterado-${allDaysUnit[i]}`]) || 0,
          "costTarifAdult": Number(data[`tariffAdultCost-${allDaysUnit[i]}`]) || 0,
          "sellingTarifAdult": Number(data[`tariffAdultAlterado-${allDaysUnit[i]}`]) || 0,
          "costTarifElders": Number(data[`tariffEldersCost-${allDaysUnit[i]}`]) || 0,
          "sellingTarifElders": Number(data[`tariffEldersAlterado-${allDaysUnit[i]}`]) || 0,
          "costTarifStudent": Number(data[`tariffStudentCost-${allDaysUnit[i]}`]) || 0,
          "sellingTarifStudent": Number(data[`tariffStudentAlterado-${allDaysUnit[i]}`]) || 0,
          "costTarifGlobal": Number(data[`tariffGlobalCost-${allDaysUnit[i]}`]) || 0,
          "sellingTarifGlobal": Number(data[`tariffGlobalAlterado-${allDaysUnit[i]}`]) || 0,
          "productType": isTicket ? 4 : 1,
          "status": 1,
          "statusDesc": "Ativo",
        })
        activityStartFullObject.push(hour);
      });

      data.ticketDailyTariff.push({
        daysTarCode: isEditing[0] !== null ? (tourSIG.tour.modalidade[isEditing[0]].ticketTariff[isEditing[1]].ticketDailyTariff.length < i ? tourSIG.tour.modalidade[isEditing[0]].ticketTariff[isEditing[1]].ticketDailyTariff[i].daysTarCode : undefined) : undefined,
        daysUniqueTarCode: isEditing[0] !== null ? (tourSIG.tour.modalidade[isEditing[0]].ticketTariff[isEditing[1]].ticketDailyTariff.length < i ? tourSIG.tour.modalidade[isEditing[0]].ticketTariff[isEditing[1]].ticketDailyTariff[i].daysUniqueTarCode : undefined) : undefined,

        dom: days[0].index.includes(i) ? 1 : 2,
        seg: days[1].index.includes(i) ? 1 : 2,
        ter: days[2].index.includes(i) ? 1 : 2,
        qua: days[3].index.includes(i) ? 1 : 2,
        qui: days[4].index.includes(i) ? 1 : 2,
        sex: days[5].index.includes(i) ? 1 : 2,
        sab: days[6].index.includes(i) ? 1 : 2,

        horariosTarifario: horariosTarifario,

        status: 1,
        statusDesc: "Ativo",
      });
    }

    //pega todas as horas da tarifa
    let activityStartFullObjectUnit: any = activityStartFullObject.filter((elem: any, index: number) => {
      return activityStartFullObject.indexOf(elem) === index && elem !== null;
    })

    let activityStartTariff: any[] = [];

    activityStartFullObjectUnit.forEach((elem: any) => {
      if (isTicket) {
        activityStartTariff.push({
          "hour": elem,
          "productType": isTicket ? 4 : 1,
          "status": 1
        })
      } else {
        activityStartTariff.push({
          "hora": elem,
          "productType": isTicket ? 4 : 1,
          "status": 1
        })
      }
    })

    data.activityStartTariff = activityStartTariff;

    data.ticketHourlyPromotions = promoObject;

    if (data.salesChannel) {
      data.canaisTarifario = data.salesChannel.map((elem: any) => {
        let days: number = Number(data[`antecedenciaDias-${elem.value}`] || 0);
        let hours: number = Number(data[`antecedenciaHoras-${elem.value}`] || 0);
        let minutes: number = Number(data[`antecedenciaMinutos-${elem.value}`] || 0);

        return (
          {
            ...data.tarCode,
            ...modCode,
            ...productCode,
            "idSalesChannel": elem.value,
            "productType": isTicket ? 4 : 1,
            "status": 1,
            "minutosAntecedencia": (days * 1440) + (hours * 60) + minutes
          }
        )
      });
    }

    return data;
  }

  const createTourObject = (data: any) => {
    data.tarCode = actualTarcode !== null ? {
      "tarCode": actualTarcode
    } : undefined

    let modCode: any = actualModCode !== null ? {
      "modCode": actualModCode
    } : {}

    let productCode: any = tourSIG.tour.productCode ? {
      "productCode": tourSIG.tour.productCode
    } : {}

    if (data.endDate.includes('/')) {
      data.endDate = data.endDate.split('/').reverse().join('-');
    }
    if (data.beginDate.includes('/')) {
      data.beginDate = data.beginDate.split('/').reverse().join('-');
    }

    let aux = days.map((elem: any) => {
      return elem.index;
    });

    let auxDays: any = [];

    aux.forEach((element: any) => {
      auxDays.push(...element);
    });

    let allDaysUnit: any = auxDays.filter((elem: any, index: number) => {
      return auxDays.indexOf(elem) === index && elem !== null;
    });

    console.log(allDaysUnit)

    data.daysTariff = [];

    let activityStartFullObject: any[] = [];
    for (let i: number = 0; i < allDaysUnit.length; i++) {
      let activityStart: string[] = [];
      let horariosTarifario: any[] = [];

      for (let j: number = 0; j < data[`activityStart-${allDaysUnit[i]}`].length; j++) {
        activityStart.push(data[`activityStart-${allDaysUnit[i]}`][j].value);
      }


      activityStart.forEach((hour: string) => {
        horariosTarifario.push({
          ...data.tarCode,
          ...modCode,
          ...productCode,

          "daysTarCode": isEditing[0] !== null ? (tourSIG.tour.modalidade[isEditing[0]].tariff[isEditing[1]].daysTariff.length < i ? tourSIG.tour.modalidade[isEditing[0]].tariff[isEditing[1]].daysTariff[i].daysTarCode : undefined) : undefined,
          "daysUniqueTarCode": isEditing[0] !== null ? (tourSIG.tour.modalidade[isEditing[0]].tariff[isEditing[1]].daysTariff.length < i ? tourSIG.tour.modalidade[isEditing[0]].tariff[isEditing[1]].daysTariff[i].daysUniqueTarCode : undefined) : undefined,


          //"daysTarCode": isEditing[0] !== null ? tourSIG.tour.modalidade[isEditing[0]].tariff[isEditing[1]].daysTariff[i].daysTarCode : undefined,
          //"daysUniqueTarCode": isEditing[0] !== null ? tourSIG.tour.modalidade[isEditing[0]].tariff[isEditing[1]].daysTariff[i].daysUniqueTarCode : undefined,
          "hora": hour,
          "percentTarifInfant": parseInt(data[`tariffPercentInfant-${allDaysUnit[i]}`]) || 0,
          "percentTarifChild": parseInt(data[`tariffPercentChild-${allDaysUnit[i]}`]) || 0,
          "percentTarifAdult": parseInt(data[`tariffPercentAdult-${allDaysUnit[i]}`]) || 0,
          "percentTarifGlobal": parseInt(data[`tariffPercentGlobal-${allDaysUnit[i]}`]) || 0,
          "costTarifInfant": Number(data[`tariffInfantCost-${allDaysUnit[i]}`]) || 0,
          "sellingTarifInfant": Number(data[`tariffInfantAlterado-${allDaysUnit[i]}`]) || 0,
          "costTarifChild": Number(data[`tariffChildCost-${allDaysUnit[i]}`]) || 0,
          "sellingTarifChild": Number(data[`tariffChildAlterado-${allDaysUnit[i]}`]) || 0,
          "costTarifAdult": Number(data[`tariffAdultCost-${allDaysUnit[i]}`]) || 0,
          "sellingTarifAdult": Number(data[`tariffAdultAlterado-${allDaysUnit[i]}`]) || 0,
          "costTarifGlobal": Number(data[`tariffGlobalCost-${allDaysUnit[i]}`]) || 0,
          "sellingTarifGlobal": Number(data[`tariffGlobalAlterado-${allDaysUnit[i]}`]) || 0,
          "productType": isTicket ? 4 : 1,
          "status": 1,
          "statusDesc": "Ativo",
        })
        activityStartFullObject.push(hour);
      });

      data.daysTariff.push({
        daysTarCode: isEditing[0] !== null ? (tourSIG.tour.modalidade[isEditing[0]].tariff[isEditing[1]].daysTariff.length < i ? tourSIG.tour.modalidade[isEditing[0]].tariff[isEditing[1]].daysTariff[i].daysTarCode : undefined) : undefined,
        daysUniqueTarCode: isEditing[0] !== null ? (tourSIG.tour.modalidade[isEditing[0]].tariff[isEditing[1]].daysTariff.length < i ? tourSIG.tour.modalidade[isEditing[0]].tariff[isEditing[1]].daysTariff[i].daysUniqueTarCode : undefined) : undefined,

        //"daysTarCode": isEditing[0] !== null ? tourSIG.tour.modalidade[isEditing[0]].tariff[isEditing[1]].daysTariff[i].daysTarCode : undefined,
        //"daysUniqueTarCode": isEditing[0] !== null ? tourSIG.tour.modalidade[isEditing[0]].tariff[isEditing[1]].daysTariff[i].daysUniqueTarCode : undefined,
        daysOfWeek: {
          dom: days[0].index.includes(i),// === allDaysUnit[i],
          seg: days[1].index.includes(i),// === allDaysUnit[i],
          ter: days[2].index.includes(i),// === allDaysUnit[i],
          qua: days[3].index.includes(i),// === allDaysUnit[i],
          qui: days[4].index.includes(i),// === allDaysUnit[i],
          sex: days[5].index.includes(i),// === allDaysUnit[i],
          sab: days[6].index.includes(i),// === allDaysUnit[i],
        },
        horariosTarifario: horariosTarifario,

        status: 1,
        statusDesc: "Ativo",
      });
    }

    //pega todas as horas da tarifa
    let activityStartFullObjectUnit: any = activityStartFullObject.filter((elem: any, index: number) => {
      return activityStartFullObject.indexOf(elem) === index && elem !== null;
    })

    let activityStartTariff: any[] = [];

    activityStartFullObjectUnit.forEach((elem: any) => {
      activityStartTariff.push({
        /* "id": 0,
        "tarCode": "string",
        "productCode": "string", */
        "hora": elem,
        "productType": isTicket ? 4 : 1,
        "status": 1
      })
    })

    data.activityStartTariff = activityStartTariff;

    data.promoHourTariff = promoObject;

    if (data.salesChannel) {
      data.canaisTarifario = data.salesChannel.map((elem: any) => {
        let days: number = Number(data[`antecedenciaDias-${elem.value}`] || 0);
        let hours: number = Number(data[`antecedenciaHoras-${elem.value}`] || 0);
        let minutes: number = Number(data[`antecedenciaMinutos-${elem.value}`] || 0);

        return (
          {
            ...data.tarCode,
            ...modCode,
            ...productCode,
            "idSalesChannel": elem.value,
            "productType": isTicket ? 4 : 1,
            "status": 1,
            "minutosAntecedencia": (days * 1440) + (hours * 60) + minutes
          }
        )
      });
    }

    return data;
  }

  const onSubmit = (data: any) => {
    if (!verifyWeekDay()) {
      setModalLog(1);
      //setModalMessage("Todas as tarifas devem ter pelo menos 1 dia da semana selecionado");
      setModalShow(true);
      return;
    }

    if (!verifyDate()/*  && isEditing[0] === null */) {
      setDateConflictModalShow(true);
      return;
    } else {
      setModalShow(true);
      setModalLog(0);
      setModalMessage(
        isEditing[0] === null
          ? "Tarifário inserido com sucesso!"
          : "Tarifário editado com sucesso!"
      );
    }

    let dataToInsert: any;

    if (isTicket) {
      dataToInsert = createTicketObject(data);
    } else {
      dataToInsert = createTourObject(data);
    }

    if (isEditing[0] === null) {
      dispatch(addTariffs(dataToInsert));
    } else {
      if (isEditing[0] !== parseInt(dataToInsert.modality.value)) {
        let tariffToExclude: any = { modalityIndexToRemove: parseInt(isEditing[0]), tariffIndexToRemove: isEditing[1] };
        dispatch(isTicket ? ticketRemoveTariff(tariffToExclude) : removeTariff(tariffToExclude));

        dataToInsert.bloqueado = tourSIG.tour.modalidade[isEditing[0]][`${isTicket ? 'ticketTariff' : 'tariff'}`][isEditing[1]].bloqueado;
        dataToInsert.ticketTariffCode = tourSIG.tour.modalidade[isEditing[0]][`${isTicket ? 'ticketTariff' : 'tariff'}`][isEditing[1]].ticketTariffCode;
        dataToInsert.ticketModCode = tourSIG.tour.modalidade[isEditing[0]][`${isTicket ? 'ticketTariff' : 'tariff'}`][isEditing[1]].ticketModCode;
        dataToInsert.ticketCode = tourSIG.tour.modalidade[isEditing[0]][`${isTicket ? 'ticketTariff' : 'tariff'}`][isEditing[1]].ticketCode;
        dispatch(addTariffs(dataToInsert));

      } else {
        dataToInsert.modalityIndexToEdit = isEditing[0];
        dataToInsert.tariffIndexToEdit = isEditing[1];
        dataToInsert.bloqueado = tourSIG.tour.modalidade[isEditing[0]][`${isTicket ? 'ticketTariff' : 'tariff'}`][isEditing[1]].bloqueado;
        dispatch(editTariff(dataToInsert));
      }

    }

    setIsEditing([null, null]);

    resetForm(tariffs.length);

  };

  function changeDays(index: number, indexContainer: number) {
    if (/* (days[index].disabled === true && !days[index].index.includes(indexContainer)) ||  */days[index].controlAvailable === true) {
      return;
    }

    let aux: any = days;

    if (/* aux[index].value === true */aux[index].index.includes(indexContainer)) {
      //aux[index].value = false;
      //aux[index].disabled = false;
      aux[index].index.splice(aux[index].index.indexOf(indexContainer), 1);
    } else {
      //aux[index].value = true;
      //aux[index].disabled = true;
      aux[index].index.push(indexContainer);
    }



    setDays(() => [...aux]);
  }

  // function changePromoDays(index: number, indexContainer: number) {
  //   if (
  //     (promoDays[index].disabled === true &&
  //       promoDays[index].index !== indexContainer) ||
  //     promoDays.controlAvailable === true
  //   ) {
  //     return;
  //   }

  //   let aux: any = promoDays;

  //   if (aux[index].value === true) {
  //     aux[index].value = false;
  //     aux[index].disabled = false;
  //     aux[index].index = null;
  //   } else {
  //     aux[index].value = true;
  //     aux[index].disabled = true;
  //     aux[index].index = indexContainer;
  //   }

  //   setPromoDays(() => [...aux]);
  // }

  const selectAll = (event: any, indexContainer: number) => {
    let aux: any = days;

    for (let i: number = 0; i < aux.length; i++) {
      if (aux[i].controlAvailable === false) {
        if (!aux[i].index.includes(indexContainer) && event.target.checked === true) {
          aux[i].index.push(indexContainer);
        } else if (aux[i].index.includes(indexContainer) && event.target.checked === false) {
          aux[i].index.splice(aux[i].index.indexOf(indexContainer), 1)
        }
      }
    }

    setDays(() => [...aux]);
    //setReRender(!reRender);
  };

  const deleteTariff = (indexContainer: number) => {
    let aux: any = days;
    let auxTariff: any = tariffs;

    for (let i: number = 0; i < aux.length; i++) {
      if (aux[i].index.includes(indexContainer)) {
        //aux[i].value = false;
        aux[i].index.splice(aux[i].index.indexOf(indexContainer), 1)
        //aux[i].index = null;
        //aux[i].disabled = false;
      }
    }

    auxTariff[indexContainer] = null;

    setDays(() => [...aux]);
    setTariffs(() => [...auxTariff]);
  };

  const handleClickTariffs = () => {
    setTariffs((prev: any) => [...prev, []]);
  };

  useEffect(() => {
    if (typeof beginDate === typeof "" && beginDate.includes('/')) {
      setValue("beginDate", beginDate.split("/").reverse().join("-"));

      /* if((typeof endDate === typeof "" && endDate.includes('/')) && (Number(getValues().beginDate?.replaceAll('-', '')) > Number(getValues().endDate?.replaceAll('-', '')))){
        setValue("endDate", "");
        setEndDate("");
      } */
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [beginDate]);

  useEffect(() => {
    if (typeof endDate === typeof "" && endDate.includes('/')) {
      setValue("endDate", endDate.split("/").reverse().join("-"));

      /* if((typeof beginDate === typeof "" && beginDate.includes('/')) && (Number(getValues().beginDate?.replaceAll('-', '')) > Number(getValues().endDate?.replaceAll('-', '')))){
        setValue("beginDate", "");
        setBeginDate("");
      } */
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [endDate]);

  const calcTariffCost = (value: string, label: string, indexContainer: number) => {
    let data: any = getValues();

    /* if(parseInt(value) === parseInt(data[`tariff${label}Cost-${indexContainer}`])){
        return;
    } */

    if (value === "" || value === undefined) {
      value = "0";
    }

    if (watchComission === "0") {
      //POR PERCENTUAL
      if (data[`tariff${label}Cost-${indexContainer}`] !== "" && data[`tariff${label}Cost-${indexContainer}`] !== undefined && Number(data[`tariff${label}Cost-${indexContainer}`] || 0) > 0 && data[`tariffPercent-${indexContainer}`] !== "" && data[`tariffPercent-${indexContainer}`] !== undefined) {
        let valorVenda: number = parseInt(value) / (1 - data[`tariffPercent-${indexContainer}`] / 100);
        setValue(`tariff${label}Alterado-${indexContainer}`, Math.ceil(valorVenda));
      } else if (Number(data[`tariff${label}Cost-${indexContainer}`] || 0) === 0) {
        setValue(`tariff${label}Alterado-${indexContainer}`, 0);
      }
    } else if (watchComission === "1") {
      //POR VENDA
      let valorVenda: number;

      //Verifica se já possui algum percentual digitado nos campos de "%"
      if (data[`tariffPercent-${indexContainer}`] !== "" && data[`tariffPercent-${indexContainer}`] !== undefined) {
        //SIM, já existe um percentual definido
        if (data[`tariff${label}Cost-${indexContainer}`] !== "" && data[`tariff${label}Cost-${indexContainer}`] !== undefined && Number(data[`tariff${label}Cost-${indexContainer}`] || 0) > 0) {
          valorVenda = data[`tariff${label}Cost-${indexContainer}`] / (1 - data[`tariffPercent-${indexContainer}`] / 100);
          setValue(`tariff${label}Alterado-${indexContainer}`, Math.ceil(valorVenda));
        } else if (Number(data[`tariff${label}Cost-${indexContainer}`] || 0) === 0) {
          setValue(`tariff${label}Alterado-${indexContainer}`, 0);
        }
      } else {
        //NÂO, ainda não há percentual definido
        if (data[`tariff${label}Cost-${indexContainer}`] !== "" && data[`tariff${label}Cost-${indexContainer}`] !== undefined && Number(data[`tariff${label}Cost-${indexContainer}`] || 0) > 0 && data[`tariff${label}Alterado-${indexContainer}`] !== "" && data[`tariff${label}Alterado-${indexContainer}`] !== undefined) {
          if (data[`tariff${label}Cost-${indexContainer}`] <= data[`tariff${label}Alterado-${indexContainer}`]) { //verifica se o valor de custo é menor que o valor de venda
            valorVenda = Math.ceil((1 - parseInt(value) / data[`tariff${label}Alterado-${indexContainer}`]) * 100);
            setValue(`tariffPercent-${indexContainer}`, valorVenda);

            calcTariffPercent(label, indexContainer);
          }
        } else if (Number(data[`tariff${label}Cost-${indexContainer}`] || 0) === 0) {
          setValue(`tariff${label}Alterado-${indexContainer}`, 0);
        }
      }
    }
  };

  const calcTariffPercent = (name: string, indexContainer: number) => {
    let data: any = getValues();

    //POR PERCENTUAL
    /* ["Infant", "Child", "Adult", "Elders", "Student", "Global"].forEach((label: any) => {
      if (data[`tariff${label}Cost-${indexContainer}`] !== "" && data[`tariff${label}Cost-${indexContainer}`] !== undefined && data[`tariffPercent-${indexContainer}`] !== "" && data[`tariffPercent-${indexContainer}`] !== undefined) {
        let valorVenda: number = data[`tariff${label}Cost-${indexContainer}`] / (1 - data[`tariffPercent-${indexContainer}`] / 100);

        setValue(`tariff${label}Alterado-${indexContainer}`, Math.ceil(valorVenda));
      }
    }); */

    if (data[`tariff${name}Cost-${indexContainer}`] !== "" && data[`tariff${name}Cost-${indexContainer}`] !== undefined && data[`tariffPercent${name}-${indexContainer}`] !== "" && data[`tariffPercent${name}-${indexContainer}`] !== undefined) {
      let valorVenda: number = data[`tariff${name}Cost-${indexContainer}`] / (1 - data[`tariffPercent${name}-${indexContainer}`] / 100);

      setValue(`tariff${name}Alterado-${indexContainer}`, Math.ceil(valorVenda));
    }
  };

  const calcTariffAlterado = (value: string, label: string, indexContainer: number) => {
    let data: any = getValues();

    if (parseInt(value) === data[`tariff${label}Alterado-${indexContainer}`]) {
      return;
    }

    if (data[`tariff${label}Cost-${indexContainer}`] !== "" && data[`tariff${label}Cost-${indexContainer}`] !== undefined && data[`tariff${label}Alterado-${indexContainer}`] !== "" && data[`tariff${label}Alterado-${indexContainer}`] !== undefined) {
      if (data[`tariff${label}Cost-${indexContainer}`] <= data[`tariff${label}Alterado-${indexContainer}`]) { //verifica se o valor de custo é menor que o valor de venda
        let valorVenda: number = Math.ceil((1 - data[`tariff${label}Cost-${indexContainer}`] / data[`tariff${label}Alterado-${indexContainer}`].replace(',', '.')) * 100);
        setValue(`tariffPercent${label}-${indexContainer}`, valorVenda);

        calcTariffPercent(label, indexContainer);
      } else {
        setValue(`tariff${label}Alterado-${indexContainer}`, '');
      }
    }
  };

  useEffect(() => {
    if (watchModality !== undefined && watchModality !== null && watchModality !== "") {
      let aux: any = days;

      if (isTicket) {
        aux[0].controlAvailable = !(tourSIG.tour.modalidade[watchModality.value || 0].dom === 1);
        aux[1].controlAvailable = !(tourSIG.tour.modalidade[watchModality.value || 0].seg === 1);
        aux[2].controlAvailable = !(tourSIG.tour.modalidade[watchModality.value || 0].ter === 1);
        aux[3].controlAvailable = !(tourSIG.tour.modalidade[watchModality.value || 0].qua === 1);
        aux[4].controlAvailable = !(tourSIG.tour.modalidade[watchModality.value || 0].qui === 1);
        aux[5].controlAvailable = !(tourSIG.tour.modalidade[watchModality.value || 0].sex === 1);
        aux[6].controlAvailable = !(tourSIG.tour.modalidade[watchModality.value || 0].sab === 1);
      } else {
        aux[0].controlAvailable = !tourSIG.tour.modalidade[watchModality.value || 0].daysOfWeek.dom;
        aux[1].controlAvailable = !tourSIG.tour.modalidade[watchModality.value || 0].daysOfWeek.seg;
        aux[2].controlAvailable = !tourSIG.tour.modalidade[watchModality.value || 0].daysOfWeek.ter;
        aux[3].controlAvailable = !tourSIG.tour.modalidade[watchModality.value || 0].daysOfWeek.qua;
        aux[4].controlAvailable = !tourSIG.tour.modalidade[watchModality.value || 0].daysOfWeek.qui;
        aux[5].controlAvailable = !tourSIG.tour.modalidade[watchModality.value || 0].daysOfWeek.sex;
        aux[6].controlAvailable = !tourSIG.tour.modalidade[watchModality.value || 0].daysOfWeek.sab;
      }
      setDays(() => [...aux]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [watchModality]);

  const resetForm = (numberOfTariffsContainers: number) => {
    setActualTarcode(null);

    setValue("tariffName", "");
    setValue("modality", "");
    setValue("commission", "");

    setValue("beginDate", "");
    setBeginDate("");
    setValue("endDate", "");
    setEndDate("");

    for (let i: number = 0; i < numberOfTariffsContainers; i++) {
      setValue(`tariffAdultCost-${i}`, "");
      setValue(`tariffChildCost-${i}`, "");
      setValue(`tariffInfantCost-${i}`, "");
      setValue(`tariffEldersCost-${i}`, "");
      setValue(`tariffStudentCost-${i}`, "");
      setValue(`tariffGlobalCost-${i}`, "");

      setValue(`tariffPercent-${i}`, "");

      setValue(`tariffAdultAlterado-${i}`, "");
      setValue(`tariffChildAlterado-${i}`, "");
      setValue(`tariffInfantAlterado-${i}`, "");
      setValue(`tariffEldersAlterado-${i}`, "");
      setValue(`tariffStudentAlterado-${i}`, "");
      setValue(`tariffGlobalAlterado-${i}`, "");

      setValue(`activityStart-${i}`, []);
    }

    setPromoObject([]);

    setTariffs([[]]);

    setDays([
      {
        day: "dom",
        value: false,
        index: [],
        disabled: false,
        controlAvailable: false,
      },
      {
        day: "seg",
        value: false,
        index: [],
        disabled: false,
        controlAvailable: false,
      },
      {
        day: "ter",
        value: false,
        index: [],
        disabled: false,
        controlAvailable: false,
      },
      {
        day: "qua",
        value: false,
        index: [],
        disabled: false,
        controlAvailable: false,
      },
      {
        day: "qui",
        value: false,
        index: [],
        disabled: false,
        controlAvailable: false,
      },
      {
        day: "sex",
        value: false,
        index: [],
        disabled: false,
        controlAvailable: false,
      },
      {
        day: "sab",
        value: false,
        index: [],
        disabled: false,
        controlAvailable: false,
      },
    ]);
    //setValue("activityStart", "00:00:00");
    setValue("salesChannel", []);
    setSalesChannelInfoEdit([]);

    salesChannelObj.forEach((element: any) => {
      setValue(`antecedenciaDias-${element.idSalesChannel}`, 0);
      setValue(`antecedenciaHoras-${element.idSalesChannel}`, 0);
      setValue(`antecedenciaMinutos-${element.idSalesChannel}`, 0);
      setValue(`antecedencias-${element.idSalesChannel}`, `0 Minutos`);
    });
  };

  const editTariffFunc = (elem: any, index: number, indexTariff: number) => {
    if (isEditing[0] === index && isEditing[1] === indexTariff) {
      setIsEditing([null, null]);
      resetForm(elem[`${isTicket ? 'ticketTariff' : "tariff"}`][indexTariff][`${isTicket ? "ticketDailyTariff" : "daysTariff"}`].length);
      return;
    } else {
      setIsEditing([index, indexTariff]);
    }

    setValue('tarCode', elem[`${isTicket ? 'ticketTariff' : "tariff"}`][indexTariff]?.tarCode || null);
    setActualTarcode(elem[`${isTicket ? 'ticketTariff' : "tariff"}`][indexTariff][`${isTicket ? 'ticketTariffCode' : "tarCode"}`] || null);
    actualModCode = elem[`${isTicket ? 'ticketTariff' : "tariff"}`][indexTariff]?.canaisTarifario[0]?.modCode/* [`${isTicket ? 'ticketModCode' : "modCode"}`] */ || null;

    let newBeginDate = new Date(elem[`${isTicket ? 'ticketTariff' : "tariff"}`][indexTariff].tariffStart).toLocaleDateString();
    let newEndDate = new Date(elem[`${isTicket ? 'ticketTariff' : "tariff"}`][indexTariff].tariffEnd).toLocaleDateString();

    setValue("tariffName", elem[`${isTicket ? 'ticketTariff' : "tariff"}`][indexTariff].tariffName);
    setValue("modality", { value: index, label: tourSIG.tour.modalidade[index].modalityName[0].text });
    setValue("beginDate", newBeginDate);
    setBeginDate(newBeginDate);
    setValue("endDate", newEndDate);
    setEndDate(newEndDate);
    setValue("commission", "0");

    setTariffs([]);

    for (let i: number = 0; i < elem[`${isTicket ? 'ticketTariff' : "tariff"}`][indexTariff][`${isTicket ? "ticketDailyTariff" : "daysTariff"}`].length; i++) {
      setTariffs((prev: any) => [...prev, []]);
    }

    let aux: any = days;
    for (let i: number = 0; i < aux.length; i++) {
      aux[i].value = false;
      aux[i].disabled = false;
      aux[i].index = [];
    }

    for (let i: number = 0; i < elem[`${isTicket ? 'ticketTariff' : "tariff"}`][indexTariff][`${isTicket ? "ticketDailyTariff" : "daysTariff"}`]?.length; i++) {
      let activityStart: any = [];
      /* activityStartdaysTariff (antigo iddomainhours) */
      for (let j: number = 0; j < elem[`${isTicket ? 'ticketTariff' : "tariff"}`][indexTariff][`${isTicket ? "ticketDailyTariff" : "daysTariff"}`][i]?.idDomainHours?.length; j++) {
        activityStart.push({ label: activityStartObj.find((e: any) => e.idDomainHours === elem[`${isTicket ? 'ticketTariff' : "tariff"}`][indexTariff][`${isTicket ? "ticketDailyTariff" : "daysTariff"}`][i].idDomainHours[j]).hours.slice(0, 5), value: elem[`${isTicket ? 'ticketTariff' : "tariff"}`][indexTariff][`${isTicket ? "ticketDailyTariff" : "daysTariff"}`][i].idDomainHours[j] });
      }

      setValue(`tariffAdultCost-${i}`, elem[`${isTicket ? 'ticketTariff' : "tariff"}`][indexTariff][`${isTicket ? "ticketDailyTariff" : "daysTariff"}`][i].horariosTarifario[0].costTarifAdult);
      setValue(`tariffChildCost-${i}`, elem[`${isTicket ? 'ticketTariff' : "tariff"}`][indexTariff][`${isTicket ? "ticketDailyTariff" : "daysTariff"}`][i].horariosTarifario[0].costTarifChild);
      setValue(`tariffInfantCost-${i}`, elem[`${isTicket ? 'ticketTariff' : "tariff"}`][indexTariff][`${isTicket ? "ticketDailyTariff" : "daysTariff"}`][i].horariosTarifario[0].costTarifInfant);
      setValue(`tariffGlobalCost-${i}`, elem[`${isTicket ? 'ticketTariff' : "tariff"}`][indexTariff][`${isTicket ? "ticketDailyTariff" : "daysTariff"}`][i].horariosTarifario[0].costTarifGlobal);

      if (isTicket) {
        setValue(`tariffEldersCost-${i}`, elem[`${isTicket ? 'ticketTariff' : "tariff"}`][indexTariff][`${isTicket ? "ticketDailyTariff" : "daysTariff"}`][i].horariosTarifario[0].costTarifElders);
        setValue(`tariffStudentCost-${i}`, elem[`${isTicket ? 'ticketTariff' : "tariff"}`][indexTariff][`${isTicket ? "ticketDailyTariff" : "daysTariff"}`][i].horariosTarifario[0].costTarifStudent);
      }

      setTimeout(() => {
        setValue(`tariffPercentAdult-${i}`, elem[`${isTicket ? 'ticketTariff' : "tariff"}`][indexTariff][`${isTicket ? "ticketDailyTariff" : "daysTariff"}`][i].horariosTarifario[0].percentTarifAdult);
        setValue(`tariffPercentChild-${i}`, elem[`${isTicket ? 'ticketTariff' : "tariff"}`][indexTariff][`${isTicket ? "ticketDailyTariff" : "daysTariff"}`][i].horariosTarifario[0].percentTarifChild);
        setValue(`tariffPercentInfant-${i}`, elem[`${isTicket ? 'ticketTariff' : "tariff"}`][indexTariff][`${isTicket ? "ticketDailyTariff" : "daysTariff"}`][i].horariosTarifario[0].percentTarifInfant);
        setValue(`tariffPercentGlobal-${i}`, elem[`${isTicket ? 'ticketTariff' : "tariff"}`][indexTariff][`${isTicket ? "ticketDailyTariff" : "daysTariff"}`][i].horariosTarifario[0].percentTarifGlobal);
        setValue(`tariffAdultAlterado-${i}`, elem[`${isTicket ? 'ticketTariff' : "tariff"}`][indexTariff][`${isTicket ? "ticketDailyTariff" : "daysTariff"}`][i].horariosTarifario[0].sellingTarifAdult);
        setValue(`tariffChildAlterado-${i}`, elem[`${isTicket ? 'ticketTariff' : "tariff"}`][indexTariff][`${isTicket ? "ticketDailyTariff" : "daysTariff"}`][i].horariosTarifario[0].sellingTarifChild);
        setValue(`tariffInfantAlterado-${i}`, elem[`${isTicket ? 'ticketTariff' : "tariff"}`][indexTariff][`${isTicket ? "ticketDailyTariff" : "daysTariff"}`][i].horariosTarifario[0].sellingTarifInfant);
        setValue(`tariffGlobalAlterado-${i}`, elem[`${isTicket ? 'ticketTariff' : "tariff"}`][indexTariff][`${isTicket ? "ticketDailyTariff" : "daysTariff"}`][i].horariosTarifario[0].sellingTarifGlobal);
        
        if (isTicket) {
          setValue(`tariffPercentElders-${i}`, elem[`${isTicket ? 'ticketTariff' : "tariff"}`][indexTariff][`${isTicket ? "ticketDailyTariff" : "daysTariff"}`][i].horariosTarifario[0].percentTarifElders);
          setValue(`tariffPercentStudent-${i}`, elem[`${isTicket ? 'ticketTariff' : "tariff"}`][indexTariff][`${isTicket ? "ticketDailyTariff" : "daysTariff"}`][i].horariosTarifario[0].percentTarifStudent);
          setValue(`tariffEldersAlterado-${i}`, elem[`${isTicket ? 'ticketTariff' : "tariff"}`][indexTariff][`${isTicket ? "ticketDailyTariff" : "daysTariff"}`][i].horariosTarifario[0].sellingTarifElders);
          setValue(`tariffStudentAlterado-${i}`, elem[`${isTicket ? 'ticketTariff' : "tariff"}`][indexTariff][`${isTicket ? "ticketDailyTariff" : "daysTariff"}`][i].horariosTarifario[0].sellingTarifStudent);
        }
      }, 500);

      if (isTicket) { // ticket
        if (elem.ticketTariff[indexTariff].ticketDailyTariff[i].dom === 1) {
          aux[0].index.push(i);
        }
        if (elem.ticketTariff[indexTariff].ticketDailyTariff[i].seg === 1) {
          aux[1].index.push(i);
        }
        if (elem.ticketTariff[indexTariff].ticketDailyTariff[i].ter === 1) {
          aux[2].index.push(i);
        }
        if (elem.ticketTariff[indexTariff].ticketDailyTariff[i].qua === 1) {
          aux[3].index.push(i);
        }
        if (elem.ticketTariff[indexTariff].ticketDailyTariff[i].qui === 1) {
          aux[4].index.push(i);
        }
        if (elem.ticketTariff[indexTariff].ticketDailyTariff[i].sex === 1) {
          aux[5].index.push(i);
        }
        if (elem.ticketTariff[indexTariff].ticketDailyTariff[i].sab === 1) {
          aux[6].index.push(i);
        }

      } else { // tour

        if (elem.tariff[indexTariff].daysTariff[i]?.daysOfWeek.dom === true) {
          aux[0].index.push(i);
        }
        if (elem.tariff[indexTariff].daysTariff[i]?.daysOfWeek.seg === true) {
          aux[1].index.push(i);
        }
        if (elem.tariff[indexTariff].daysTariff[i]?.daysOfWeek.ter === true) {
          aux[2].index.push(i);
        }
        if (elem.tariff[indexTariff].daysTariff[i]?.daysOfWeek.qua === true) {
          aux[3].index.push(i);
        }
        if (elem.tariff[indexTariff].daysTariff[i]?.daysOfWeek.qui === true) {
          aux[4].index.push(i);
        }
        if (elem.tariff[indexTariff].daysTariff[i]?.daysOfWeek.sex === true) {
          aux[5].index.push(i);
        }
        if (elem.tariff[indexTariff].daysTariff[i]?.daysOfWeek.sab === true) {
          aux[6].index.push(i);
        }
      }
    }

    setDays(() => [...aux]);

    setPromoObject(elem[`${isTicket ? 'ticketTariff' : "tariff"}`][indexTariff][`${isTicket ? 'ticketHourlyPromotions' : 'promoHourTariff'}`]);

    startTimeFillFunction(elem[`${isTicket ? 'ticketTariff' : "tariff"}`][indexTariff][`${isTicket ? "ticketDailyTariff" : "daysTariff"}`], true);

    let salesChannelAux: any = [];

    elem[`${isTicket ? 'ticketTariff' : "tariff"}`][indexTariff].canaisTarifario.forEach((element: any) => {
      salesChannelAux.push(salesChannelObj.find((e: any) => e.idSalesChannel === element.idSalesChannel || e.idSalesChannel === element.value))
    })

    elem[`${isTicket ? 'ticketTariff' : "tariff"}`][indexTariff].canaisTarifario.forEach((element: any) => {
      let days: number = Math.floor(element.minutosAntecedencia / 1440);
      let hours: number = Math.floor((element.minutosAntecedencia % 1440) / 60);
      let minutes: number = Math.floor((element.minutosAntecedencia % 1440) % 60);

      setTimeout(() => {

        setValue(`antecedenciaDias-${element.idSalesChannel}`, days);
        setValue(`antecedenciaHoras-${element.idSalesChannel}`, hours);
        setValue(`antecedenciaMinutos-${element.idSalesChannel}`, minutes);
        setValue(`antecedencias-${element.idSalesChannel}`, `${element.minutosAntecedencia} Minutos`);
        //salesChannelAux.push(salesChannelObj.find((e: any) => e.idSalesChannel === element.idSalesChannel || e.idSalesChannel === element.value))
      }, 300);
    });

    setValue("salesChannel", salesChannelAux.map((e: any) => { return { value: e.idSalesChannel, label: e.salesChannelName } }))
    setSalesChannelInfoEdit(salesChannelAux.map((e: any) => { return { value: e.idSalesChannel, label: e.salesChannelName } }))
  };

  const changeTariffStatusFunc = (elem: any, index: number, indexTariff: number) => {
    let data: any = {
      indexModality: index,
      indexTariff,
      status: !elem[`${isTicket ? 'ticketTariff' : "tariff"}`][indexTariff].bloqueado
    }

    dispatch(changeTariffStatus(data));
  }

  const duplicateTariffFunc = (elem: any, index: number, indexTariff: number) => {
    setValue("tariffName", elem[`${isTicket ? 'ticketTariff' : "tariff"}`][indexTariff].tariffName);
    setValue("modality", { value: index, label: tourSIG.tour.modalidade[index].modalityName[0].text });
    setValue("beginDate", "");
    setBeginDate("");
    setValue("endDate", "");
    setEndDate("");
    setValue("commission", "0"/* elem.tariff[indexTariff].commission */);

    setTariffs([]);

    for (let i: number = 0; i < elem[`${isTicket ? 'ticketTariff' : "tariff"}`][indexTariff][`${isTicket ? "ticketDailyTariff" : "daysTariff"}`].length; i++) {
      setTariffs((prev: any) => [...prev, []]);
    }

    let aux: any = days;
    for (let i: number = 0; i < aux.length; i++) {
      aux[i].value = false;
      aux[i].disabled = false;
      aux[i].index = [];
    }

    for (let i: number = 0; i < elem[`${isTicket ? 'ticketTariff' : "tariff"}`][indexTariff][`${isTicket ? "ticketDailyTariff" : "daysTariff"}`].length; i++) {
      let activityStart: any = [];

      for (let j: number = 0; j < elem[`${isTicket ? 'ticketTariff' : "tariff"}`][indexTariff][`${isTicket ? "ticketDailyTariff" : "daysTariff"}`]?.idDomainHours?.length; j++) {
        activityStart.push({ label: activityStartObj.find((e: any) => e.idDomainHours === elem[`${isTicket ? 'ticketTariff' : "tariff"}`][indexTariff][`${isTicket ? "ticketDailyTariff" : "daysTariff"}`][i].idDomainHours[j]).hours, value: elem[`${isTicket ? 'ticketTariff' : "tariff"}`][indexTariff][`${isTicket ? "ticketDailyTariff" : "daysTariff"}`][i].idDomainHours[j] });
      }

      setValue(`tariffAdultCost-${i}`, elem[`${isTicket ? 'ticketTariff' : "tariff"}`][indexTariff][`${isTicket ? "ticketDailyTariff" : "daysTariff"}`][i].horariosTarifario[0].costTarifAdult);
      setValue(`tariffChildCost-${i}`, elem[`${isTicket ? 'ticketTariff' : "tariff"}`][indexTariff][`${isTicket ? "ticketDailyTariff" : "daysTariff"}`][i].horariosTarifario[0].costTarifChild);
      setValue(`tariffInfantCost-${i}`, elem[`${isTicket ? 'ticketTariff' : "tariff"}`][indexTariff][`${isTicket ? "ticketDailyTariff" : "daysTariff"}`][i].horariosTarifario[0].costTarifInfant);
      setValue(`tariffGlobalCost-${i}`, elem[`${isTicket ? 'ticketTariff' : "tariff"}`][indexTariff][`${isTicket ? "ticketDailyTariff" : "daysTariff"}`][i].horariosTarifario[0].costTarifGlobal);

      setTimeout(() => {
        setValue(`tariffPercentAdult-${i}`, elem[`${isTicket ? 'ticketTariff' : "tariff"}`][indexTariff][`${isTicket ? "ticketDailyTariff" : "daysTariff"}`][i].horariosTarifario[0].percentTarifAdult);
        setValue(`tariffPercentChild-${i}`, elem[`${isTicket ? 'ticketTariff' : "tariff"}`][indexTariff][`${isTicket ? "ticketDailyTariff" : "daysTariff"}`][i].horariosTarifario[0].percentTarifChild);
        setValue(`tariffPercentInfant-${i}`, elem[`${isTicket ? 'ticketTariff' : "tariff"}`][indexTariff][`${isTicket ? "ticketDailyTariff" : "daysTariff"}`][i].horariosTarifario[0].percentTarifInfant);
        setValue(`tariffPercentElders-${i}`, elem[`${isTicket ? 'ticketTariff' : "tariff"}`][indexTariff][`${isTicket ? "ticketDailyTariff" : "daysTariff"}`][i].horariosTarifario[0].percentTarifElders);
        setValue(`tariffPercentStudent-${i}`, elem[`${isTicket ? 'ticketTariff' : "tariff"}`][indexTariff][`${isTicket ? "ticketDailyTariff" : "daysTariff"}`][i].horariosTarifario[0].percentTarifStudente);
        setValue(`tariffAdultAlterado-${i}`, elem[`${isTicket ? 'ticketTariff' : "tariff"}`][indexTariff][`${isTicket ? "ticketDailyTariff" : "daysTariff"}`][i].horariosTarifario[0].sellingTarifAdult);
        setValue(`tariffChildAlterado-${i}`, elem[`${isTicket ? 'ticketTariff' : "tariff"}`][indexTariff][`${isTicket ? "ticketDailyTariff" : "daysTariff"}`][i].horariosTarifario[0].sellingTarifChild);
        setValue(`tariffInfantAlterado-${i}`, elem[`${isTicket ? 'ticketTariff' : "tariff"}`][indexTariff][`${isTicket ? "ticketDailyTariff" : "daysTariff"}`][i].horariosTarifario[0].sellingTarifInfant);
        setValue(`tariffGlobalAlterado-${i}`, elem[`${isTicket ? 'ticketTariff' : "tariff"}`][indexTariff][`${isTicket ? "ticketDailyTariff" : "daysTariff"}`][i].horariosTarifario[0].sellingTarifGlobal);
      }, 500);

      if (isTicket) { // ticket
        if (elem.ticketTariff[indexTariff].ticketDailyTariff[i].dom === 1) {
          aux[0].index.push(i);
        }
        if (elem.ticketTariff[indexTariff].ticketDailyTariff[i].seg === 1) {
          aux[1].index.push(i);
        }
        if (elem.ticketTariff[indexTariff].ticketDailyTariff[i].ter === 1) {
          aux[2].index.push(i);
        }
        if (elem.ticketTariff[indexTariff].ticketDailyTariff[i].qua === 1) {
          aux[3].index.push(i);
        }
        if (elem.ticketTariff[indexTariff].ticketDailyTariff[i].qui === 1) {
          aux[4].index.push(i);
        }
        if (elem.ticketTariff[indexTariff].ticketDailyTariff[i].sex === 1) {
          aux[5].index.push(i);
        }
        if (elem.ticketTariff[indexTariff].ticketDailyTariff[i].sab === 1) {
          aux[6].index.push(i);
        }

      } else { // tour
        if (elem.tariff[indexTariff].daysTariff[i]?.daysOfWeek.dom === true) {
          aux[0].index.push(i);
        }
        if (elem.tariff[indexTariff].daysTariff[i]?.daysOfWeek.seg === true) {
          aux[1].index.push(i);
        }
        if (elem.tariff[indexTariff].daysTariff[i]?.daysOfWeek.ter === true) {
          aux[2].index.push(i);
        }
        if (elem.tariff[indexTariff].daysTariff[i]?.daysOfWeek.qua === true) {
          aux[3].index.push(i);
        }
        if (elem.tariff[indexTariff].daysTariff[i]?.daysOfWeek.qui === true) {
          aux[4].index.push(i);
        }
        if (elem.tariff[indexTariff].daysTariff[i]?.daysOfWeek.sex === true) {
          aux[5].index.push(i);
        }
        if (elem.tariff[indexTariff].daysTariff[i]?.daysOfWeek.sab === true) {
          aux[6].index.push(i);
        }
      }
    }

    setDays(() => [...aux]);

    setPromoObject(elem[`${isTicket ? 'ticketTariff' : "tariff"}`][indexTariff][`${isTicket ? 'ticketHourlyPromotions' : 'promoHourTariff'}`]);

    startTimeFillFunction(elem[`${isTicket ? 'ticketTariff' : "tariff"}`][indexTariff][`${isTicket ? "ticketDailyTariff" : "daysTariff"}`], true);

    let salesChannelAux: any = [];

    elem[`${isTicket ? 'ticketTariff' : "tariff"}`][indexTariff].canaisTarifario.forEach((element: any) => {
      salesChannelAux.push(salesChannelObj.find((e: any) => e.idSalesChannel === element.idSalesChannel || e.idSalesChannel === element.value))
    })

    elem[`${isTicket ? 'ticketTariff' : "tariff"}`][indexTariff].canaisTarifario.forEach((element: any) => {
      let days: number = Math.floor(element.minutosAntecedencia / 1440);
      let hours: number = Math.floor((element.minutosAntecedencia % 1440) / 60);
      let minutes: number = Math.floor((element.minutosAntecedencia % 1440) % 60);

      setTimeout(() => {

        setValue(`antecedenciaDias-${element.idSalesChannel}`, days);
        setValue(`antecedenciaHoras-${element.idSalesChannel}`, hours);
        setValue(`antecedenciaMinutos-${element.idSalesChannel}`, minutes);
        setValue(`antecedencias-${element.idSalesChannel}`, `${element.minutosAntecedencia} Minutos`);
        //salesChannelAux.push(salesChannelObj.find((e: any) => e.idSalesChannel === element.idSalesChannel || e.idSalesChannel === element.value))
      }, 300);
    });

    setValue("salesChannel", salesChannelAux.map((e: any) => { return { value: e.idSalesChannel, label: e.salesChannelName } }))
    setSalesChannelInfoEdit(salesChannelAux.map((e: any) => { return { value: e.idSalesChannel, label: e.salesChannelName } }))
  };

  const removeTariffFunc = (index: number, indexTariff: number) => {
    if (isEditing[0] === index && isEditing[1] === indexTariff) {
      setIsEditing([null, null]);
    }
    let data: any = {
      modalityIndexToRemove: index,
      tariffIndexToRemove: indexTariff,
    };

    dispatch(isTicket ? ticketRemoveTariff(data) : removeTariff(data));
  };

  //modal inicio da atividade
  const startTimeAutomaticCalc = (indexContainer: number) => {
    setModalTimeIndexSelected(indexContainer);
    setModalShowTimeAutomaticCalc(true);
  };

  const startTimeFillFunction = (data: any, isArray: boolean = false) => {
    let array: any = []
    let aux: any;

    if (isArray) {
      data?.forEach((element: any, index: number) => {
        element.horariosTarifario?.forEach((hourUnit: any) => {
          array.push({ "value": hourUnit['hora' || 'hour'].slice(0, 5), "label": hourUnit['hora' || 'hour'].slice(0, 5) })
        })

        setValue(`activityStart-${index}`, array);
        array = [];
      });
    } else {
      data?.forEach((element: any) => {
        array.push({ "value": element.label.slice(0, 5), "label": element.label.slice(0, 5) })
      })

      setValue(`activityStart-${modalTimeIndexSelected}`, array);
    }
  };

  const checkKeyDown = (e: any) => {
    if (e.code === "Enter" || e.code === "NumpadEnter") e.preventDefault();
  };

  const addPromotion = (promotion: any) => {
    setPromoIdToEdit(null);
    setPromoObject((prev: any) => [...prev, promotion]);
  };

  const actionEditPromotion = (promotion: any) => {
    let aux: any = [];

    promoObject.forEach((element: any) => {
      if (element.id === promotion.id) {
        aux.push(promotion);
      } else {
        aux.push(element);
      }
    });

    setPromoObject(aux);
    setPromoIdToEdit(null);
  };

  useEffect(() => {
    if (promoIdToDelete !== null) {
      let aux: any = [];

      for (let i: number = 0; i < promoObject.length; i++) {
        if (promoObject[i].id !== promoIdToDelete) {
          aux.push(promoObject[i]);
        }
      }

      setPromoObject(() => aux);
    }

    setPromoIdToDelete(null);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [promoIdToDelete]);

  useEffect(() => {
    if (promoIdToActive !== null) {
      let aux: any = promoObject;

      aux.forEach((element: any) => {
        if (element.id === promoIdToActive) {
          if (element.statusDesc === "Ativo") {
            element.statusDesc = "Inativo";
            element.status = 2;
          } else {
            element.statusDesc = "Ativo";
            element.status = 1;
          }
        }
      });

      setPromoObject(() => aux);
    }

    setPromoIdToActive(null);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [promoIdToActive]);

  const deletePromotion = (id: number) => {
    setPromoIdToDelete(id);
  };

  const activePromotion = (id: any) => {
    setPromoIdToActive(id);
  };

  const editPromotion = (id: any) => {
    promoObject.find((elem: any) => elem.id === id);
    setPromoIdToEdit(promoObject.find((elem: any) => elem.id === id));

    setPromotionByHourModalShow(true);
  };

  useEffect(() => {
    if (promotionByHourModalShow === false) {
      setPromoIdToEdit(null);
    }
  }, [promotionByHourModalShow]);

  const putPromotionDaysOnTable = (cell: any, row: any) => {
    let aux: string = "";
    let days: string[] = ["dom", "seg", "ter", "qua", "qui", "sex", "sab"];

    days.forEach((element: any) => {
      if (row.daysOfWeek[element] === true) {
        aux = aux + element + ", ";
      }
    });

    aux = aux.substring(0, aux.length - 2);

    return <>{`${aux}`}</>;
    //return <></>;
  };

  const putBuyPeriodOnTable = (cell: any, row: any) => {
    return (
      <>
        {row.purchaseStartDate ?
          <>
            <div className="d-flex flex-column">
              <div>
                {`${new Date(
                  row.purchaseStartDate + "T00:00:00"
                ).toLocaleDateString()} até ${new Date(
                  row.purchaseEndDate + "T00:00:00"
                ).toLocaleDateString()}`}
              </div>
              {/* <div>{`${row.purchaseHourStart} até ${row.purchaseHourEnd}`}</div> */}
            </div>
          </>
          :
          <>
            Período de compra não informado
          </>
        }
      </>
    );
  };

  const putUsagePeriodOnTable = (cell: any, row: any) => {
    return (
      <div className="d-flex flex-column">
        <div>
          {`${new Date(row.startDate.includes("T") ? row.startDate : row.startDate + "T00:00:00").toLocaleDateString()} até ${new Date(row.endDate.includes("T") ? row.endDate : row.endDate + "T00:00:00").toLocaleDateString()}`}
        </div>
        {/* <div>{`${row.hourStart} até ${row.hourEnd}`}</div> */}
      </div>
    );
  };

  const addActionButton = (cell: any, row: any) => {
    return (
      <>
        <div className="d-flex justify-content-center">
          <Dropdown drop="start">
            <Dropdown.Toggle variant="light ">
              <FontAwesomeIcon size="lg" icon={["fal", "ellipsis-h"]} />
            </Dropdown.Toggle>

            <Dropdown.Menu>
              <Dropdown.Item
                eventKey="1"
                onClick={(e: any) => {
                  editPromotion(cell);
                }}
              >
                <FontAwesomeIcon
                  icon={["fal", "edit"]}
                  size="1x"
                  style={{ marginRight: "1px" }}
                />{" "}
                Editar
              </Dropdown.Item>
              <Dropdown.Item
                eventKey="2"
                onClick={(e: any) => {
                  activePromotion(cell);
                }}
              >
                <FontAwesomeIcon
                  icon={["fal", "circle"]}
                  size="1x"
                  style={{ marginRight: "1px" }}
                />{" "}
                Ativar/Desativar
              </Dropdown.Item>
              <Dropdown.Item
                eventKey="2"
                onClick={(e: any) => {
                  deletePromotion(cell);
                }}
              >
                <FontAwesomeIcon
                  icon={["fal", "trash"]}
                  size="1x"
                  style={{ marginRight: "1px" }}
                />{" "}
                Excluir
              </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
        </div>
      </>
    );
  };

  const putStatusOnTable = (cell: any, row: any) => {
    return (
      <>
        <div
          className={
            cell === 1 ? "bg-emitido w-100" : "bg-pendente w-100"
          }
        >
          {cell === 1 ? "Ativo" : "Inativo"}
        </div>
      </>
    );
  };

  const putDiscountOnTable = (cell: any, row: any) => {
    let aux: any = promoObject;
    return <>{`${cell}`}</>;
  };

  const columns = [
    {
      dataField: "percentDiscount",
      text: "Dias da promoção",
      formatter: putPromotionDaysOnTable,
    },
    {
      dataField: "hourStart",
      text: "Período da compra",
      formatter: putBuyPeriodOnTable,
    },
    {
      dataField: "hourStart",
      text: "Período da utilização",
      formatter: putUsagePeriodOnTable,
    },
    {
      dataField: "percentDiscount",
      text: "Desconto",
      formatter: putDiscountOnTable,
    },
    { dataField: "status", text: "Status", formatter: putStatusOnTable },
    { dataField: "id", text: "Ações", formatter: addActionButton },
  ];

  const updateTariff = () => {
    editFunction({ modalidade: tourSIG.tour.modalidade });
  }

  useEffect(() => {
    getSalesChannel();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [infoChange])


  const getSalesChannel = () => {
    const data = getValues();
    if (infoChange !== undefined) {
      return (
        <Row id="wrapper" ref={wrapperRef}>
          {
            infoChange.map((channel: any) => {
              return (
                <>
                  <Col md="4" className="antecedencia-compra">
                    <Form.Label className="label-small mt-3">
                      {channel.label}
                    </Form.Label>
                    <div style={{ position: 'relative' }}>
                      <div className="inputAntecedencia form-control" onClick={() => handleClickAntecedencia(channel.value)}>
                        <Form.Group as={Col} md="12" controlId="" className="">
                          <Controller
                            control={control}
                            name={`antecedencias-${channel.value}`}
                            render={({ field }: any) => (
                              <Form.Control
                                {...field}
                                type="text"
                                variant="standard"
                                margin="normal"
                                className="antecedencias"
                                style={{ cursor: 'pointer' }}
                                defaultValue={'0 Minutos'}
                                autoComplete='off'
                                // eslint-disable-next-line no-empty-character-class
                                onKeyPress={(e) => !/[]/.test(e.key) && e.preventDefault()}
                              />
                            )}
                          />
                        </Form.Group>
                      </div>
                      <div className={`col-6 col-sm-6 col-md-12 my-2 absolute panels panel-${channel.value}`}>
                        <div className="panel-dropdown-content right active">
                          <Row className='w-100'>
                            <Form.Group as={Col} md="4" controlId="" className="mt-4 d-flex flex-column align-items-center">
                              <div className="d-flex">
                                <Button onClick={() => {
                                  setValue(`antecedenciaDias-${channel.value}`, Number(getValues()[`antecedenciaDias-${channel.value}`]) > 0 ? Number(getValues()[`antecedenciaDias-${channel.value}`]) - 1 : 0)
                                  setValue(`antecedencias-${channel.value}`, (Number(getValues()[`antecedenciaDias-${channel.value}`] || 0) * 1440) + (Number(getValues()[`antecedenciaHoras-${channel.value}`] || 0) * 60) + Number(getValues()[`antecedenciaMinutos-${channel.value}`] || 0) + ' Minutos')
                                }}>-</Button>

                                <Controller
                                  control={control}
                                  name={`antecedenciaDias-${channel.value}`}
                                  render={({ field }: any) => (
                                    <Form.Control
                                      {...field}
                                      className="mx-2 text-center"
                                      type="text"
                                      aria-invalid={errors?.minPeople ? "true" : ""}
                                      variant="standard"
                                      margin="normal"
                                      defaultValue={0}
                                      onBlur={() => {
                                        if ((Number(getValues()[`antecedenciaDias-${channel.value}`]) + 1 || 0) >= 7) {
                                          setValue(`antecedenciaDias-${channel.value}`, 7)
                                        }
                                        setValue(`antecedencias-${channel.value}`, (Number(getValues()[`antecedenciaDias-${channel.value}`] || 0) * 1440) + (Number(getValues()[`antecedenciaHoras-${channel.value}`] || 0) * 60) + Number(getValues()[`antecedenciaMinutos-${channel.value}`] || 0) + ' Minutos')
                                      }}
                                      onKeyPress={(e) => !/[0-9]/.test(e.key) && e.preventDefault()}
                                      required
                                      autoComplete='off'
                                    />
                                  )}
                                />

                                <Button onClick={() => {
                                  setValue(`antecedenciaDias-${channel.value}`, Number((getValues()[`antecedenciaDias-${channel.value}`] || 0)) < 7 ? Number((getValues()[`antecedenciaDias-${channel.value}`] || 0)) + 1 : 7)
                                  setValue(`antecedencias-${channel.value}`, (Number(getValues()[`antecedenciaDias-${channel.value}`] || 0) * 1440) + (Number(getValues()[`antecedenciaHoras-${channel.value}`] || 0) * 60) + Number(getValues()[`antecedenciaMinutos-${channel.value}`] || 0) + ' Minutos')
                                }}>+</Button>
                              </div>
                              <Form.Label className="label-small pt-2 text-primary">
                                DIAS
                              </Form.Label>
                            </Form.Group>

                            <Form.Group as={Col} md="4" controlId="" className="mt-4 d-flex flex-column align-items-center">
                              <div className="d-flex">
                                <Button onClick={() => {
                                  setValue(`antecedenciaHoras-${channel.value}`, Number(getValues()[`antecedenciaHoras-${channel.value}`]) > 0 ? Number(getValues()[`antecedenciaHoras-${channel.value}`]) - 1 : 0)
                                  setValue(`antecedencias-${channel.value}`, (Number(getValues()[`antecedenciaDias-${channel.value}`] || 0) * 1440) + (Number(getValues()[`antecedenciaHoras-${channel.value}`] || 0) * 60) + Number(getValues()[`antecedenciaMinutos-${channel.value}`] || 0) + ' Minutos')
                                }}>-</Button>

                                <Controller
                                  control={control}
                                  name={`antecedenciaHoras-${channel.value}`}
                                  render={({ field }: any) => (
                                    <Form.Control
                                      {...field}
                                      className="mx-2 text-center"

                                      type="text"
                                      aria-invalid={errors?.minPeople ? "true" : ""}
                                      variant="standard"
                                      margin="normal"
                                      defaultValue={0}
                                      onBlur={() => {
                                        if ((Number(getValues()[`antecedenciaHoras-${channel.value}`]) + 1 || 0) >= 23) {
                                          setValue(`antecedenciaHoras-${channel.value}`, 23)
                                        }
                                        setValue(`antecedencias-${channel.value}`, (Number(getValues()[`antecedenciaDias-${channel.value}`] || 0) * 1440) + (Number(getValues()[`antecedenciaHoras-${channel.value}`] || 0) * 60) + Number(getValues()[`antecedenciaMinutos-${channel.value}`] || 0) + ' Minutos')
                                      }}
                                      onKeyPress={(e) => !/[0-9]/.test(e.key) && e.preventDefault()}
                                      required
                                      autoComplete='off'
                                    />
                                  )}
                                />

                                <Button onClick={() => {
                                  setValue(`antecedenciaHoras-${channel.value}`, Number((getValues()[`antecedenciaHoras-${channel.value}`] || 0)) < 23 ? Number((getValues()[`antecedenciaHoras-${channel.value}`] || 0)) + 1 : 23)
                                  setValue(`antecedencias-${channel.value}`, (Number(getValues()[`antecedenciaDias-${channel.value}`] || 0) * 1440) + (Number(getValues()[`antecedenciaHoras-${channel.value}`] || 0) * 60) + Number(getValues()[`antecedenciaMinutos-${channel.value}`] || 0) + ' Minutos')
                                }
                                }>+</Button>
                              </div>
                              <Form.Label className="label-small pt-2 text-primary">
                                HORAS
                              </Form.Label>
                            </Form.Group>

                            <Form.Group as={Col} md="4" controlId="" className="mt-4 d-flex flex-column align-items-center">
                              <div className="d-flex">
                                <Button onClick={() => {
                                  setValue(`antecedenciaMinutos-${channel.value}`, Number(getValues()[`antecedenciaMinutos-${channel.value}`]) > 0 ? Number(getValues()[`antecedenciaMinutos-${channel.value}`]) - 1 : 0)
                                  setValue(`antecedencias-${channel.value}`, (Number(getValues()[`antecedenciaDias-${channel.value}`] || 0) * 1440) + (Number(getValues()[`antecedenciaHoras-${channel.value}`] || 0) * 60) + Number(getValues()[`antecedenciaMinutos-${channel.value}`] || 0) + ' Minutos')
                                }}>-</Button>

                                <Controller
                                  control={control}
                                  name={`antecedenciaMinutos-${channel.value}`}
                                  render={({ field }: any) => (
                                    <Form.Control
                                      {...field}
                                      className="mx-2 text-center"
                                      type="text"
                                      aria-invalid={errors?.minPeople ? "true" : ""}
                                      variant="standard"
                                      margin="normal"
                                      defaultValue={0}
                                      onBlur={() => {
                                        if ((Number(getValues()[`antecedenciaMinutos-${channel.value}`]) + 1 || 1) >= 59) {
                                          setValue(`antecedenciaMinutos-${channel.value}`, 59)
                                        }
                                        setValue(`antecedencias-${channel.value}`, (Number(getValues()[`antecedenciaDias-${channel.value}`] || 0) * 1440) + (Number(getValues()[`antecedenciaHoras-${channel.value}`] || 0) * 60) + Number(getValues()[`antecedenciaMinutos-${channel.value}`] || 0) + ' Minutos')
                                      }}
                                      onKeyPress={(e) => !/[0-9]/.test(e.key) && e.preventDefault()}
                                      required
                                      autoComplete='off'
                                    />
                                  )}
                                />

                                <Button onClick={() => {
                                  setValue(`antecedenciaMinutos-${channel.value}`, Number((getValues()[`antecedenciaMinutos-${channel.value}`] || 0)) < 59 ? Number((getValues()[`antecedenciaMinutos-${channel.value}`] || 0)) + 1 : 59)
                                  setValue(`antecedencias-${channel.value}`, (Number(getValues()[`antecedenciaDias-${channel.value}`] || 0) * 1440) + (Number(getValues()[`antecedenciaHoras-${channel.value}`] || 0) * 60) + Number(getValues()[`antecedenciaMinutos-${channel.value}`] || 0) + ' Minutos')
                                }}>+</Button>
                              </div>
                              <Form.Label className="label-small pt-2 text-primary">
                                MINUTOS
                              </Form.Label>
                            </Form.Group>
                          </Row>
                        </div>
                      </div>
                    </div>
                  </Col>
                </>
              )
            })
          }
        </Row>
      )
    }
  }

  return (
    <>
      {
        (isEdit === false || tourSIG?.tour?.productName) ?
          <Col md={9} lg={10}>
            <Card className="pt-content">
              <Form
                className="h-100"
                noValidate
                validated={false}
                onSubmit={handleSubmit(onSubmit)}
                onKeyDown={(e: any) => {
                  if (e.code === "Enter" || e.code === "NumpadEnter")
                    e.preventDefault();
                }}
              >
                <h3 className="text-primary pt-title mb-0" onClick={() => { console.log(tourSIG?.tour?.modalidade) }}>Tarifários</h3>

                <div className="pt-content-info">
                  <Row>
                    <div className="d-flex justify-content-between">
                      <h5 className="mb-0 pt-subtitle mb-4">
                        Tarifários cadastrados
                      </h5>
                    </div>
                    <div className="novo-tarifario-cadastrado d-flex flex-column gap-2">
                      {tourSIG?.tour?.modalidade?.map((elem: any, index: number) => {
                        return (
                          <>
                            {(isTicket ? elem.ticketTariff : elem.tariff).length > 0 && (
                              <>
                                {(isTicket ? elem.ticketTariff : elem.tariff).map((tariff: any, indexTariff: number) => {
                                  return (
                                    <>
                                      <Col md={12} className='new-card'>
                                        <div className={`d-flex justify-content-between align-items-center w-100 mb-1`}>
                                          <span>
                                            {`${tariff.tariffName}`}
                                          </span>
                                          <div>
                                            <Dropdown drop="start">
                                              <Dropdown.Toggle variant="light ">
                                                <FontAwesomeIcon
                                                  size="lg"
                                                  icon={[
                                                    "fal",
                                                    "ellipsis-h",
                                                  ]}
                                                />
                                              </Dropdown.Toggle>

                                              <Dropdown.Menu>
                                                <Dropdown.Item
                                                  onClick={() => {
                                                    editTariffFunc(
                                                      elem,
                                                      index,
                                                      indexTariff
                                                    );
                                                  }}
                                                  eventKey="0"
                                                >
                                                  <FontAwesomeIcon
                                                    icon={["fal", "edit"]}
                                                  />
                                                  {isEditing[0] === index && isEditing[1] === indexTariff ? "Cancelar edição" : "Editar"}
                                                </Dropdown.Item>

                                                {
                                                  isEditing[0] === null &&
                                                  <>
                                                    <Dropdown.Item
                                                      onClick={() => {
                                                        duplicateTariffFunc(
                                                          elem,
                                                          index,
                                                          indexTariff
                                                        );
                                                      }}
                                                      eventKey="1"
                                                    >
                                                      <FontAwesomeIcon
                                                        icon={["fal", "copy"]}
                                                      />
                                                      Duplicar
                                                    </Dropdown.Item>

                                                    <Dropdown.Item
                                                      onClick={() => {
                                                        changeTariffStatusFunc(
                                                          elem,
                                                          index,
                                                          indexTariff
                                                        );
                                                      }}
                                                      eventKey="2"
                                                    >
                                                      <FontAwesomeIcon
                                                        icon={[
                                                          "fal",
                                                          "circle",
                                                        ]}
                                                      />
                                                      {tariff.bloqueado ? "Ativar" : "Desativar"}
                                                    </Dropdown.Item>
                                                    <Dropdown.Item
                                                      onClick={() => {
                                                        removeTariffFunc(
                                                          index,
                                                          indexTariff
                                                        );
                                                      }}
                                                      eventKey="3"
                                                      className="text-danger"
                                                    >
                                                      <FontAwesomeIcon
                                                        icon={[
                                                          "fal",
                                                          "trash-alt",
                                                        ]}
                                                      />
                                                      Remover
                                                    </Dropdown.Item>
                                                  </>
                                                }
                                              </Dropdown.Menu>
                                            </Dropdown>
                                          </div>
                                        </div>
                                        <div className='d-flex w-100 info bordered'>
                                          <span className="pr-5" style={{ marginRight: '20px' }}>
                                            Período:{' '}
                                            <b>{`${new Date(tariff.tariffStart).toLocaleDateString()} à ${new Date(tariff.tariffEnd).toLocaleDateString()}`}</b>
                                            {tariff.bloqueado === true ? <span style={{ color: 'red' }}> (Bloqueado)</span> :
                                              <>
                                                {tariff.tariffEnd.replaceAll("-", "") < todayStr.split("/").reverse().join("") && <span style={{ color: 'red' }}> (Expirado)</span>}
                                              </>
                                            }
                                          </span>
                                          <span className="pr-5" style={{ marginRight: '20px' }}>
                                            Modalidade: <b>{`${elem.modalityName[0].text}`}</b>
                                          </span>
                                          {
                                            tariff?.canaisTarifario.length > 0 &&
                                            <span className="pr-5" style={{ marginRight: '20px' }}>
                                              Canais:{' '}
                                              <b>{tariff.canaisTarifario.map((e: any, eIndex: number) => {
                                                return (
                                                  `${eIndex === 0 ? '' : ', '}${salesChannelObj?.find((channel: any) => { return channel.idSalesChannel === e.idSalesChannel || channel.idSalesChannel === e.value })?.salesChannelName}`
                                                  //salesChannelObj.find((channel: any) => {return channel.idSalesChannel === e.idSalesChannel})?.salesChannelName;
                                                )
                                              })}</b>
                                            </span>
                                          }
                                        </div>
                                        <div>
                                          {(isTicket ? tariff.ticketDailyTariff : tariff.daysTariff).map((tariffUnit: any, indexDaysTariff: number) => {
                                            let weekDaysAvailable: any = [];
                                            let hoursAvailable: string[] = [];
                                            let tourDaysOfWeek: any = {
                                              "dom": tariffUnit.daysOfWeek?.dom,
                                              "seg": tariffUnit.daysOfWeek?.seg,
                                              "ter": tariffUnit.daysOfWeek?.ter,
                                              "qua": tariffUnit.daysOfWeek?.qua,
                                              "qui": tariffUnit.daysOfWeek?.qui,
                                              "sex": tariffUnit.daysOfWeek?.sex,
                                              "sab": tariffUnit.daysOfWeek?.sab,
                                            };
                                            let ticketDaysOfWeek: any = {
                                              "dom": tariffUnit?.dom === 1,
                                              "seg": tariffUnit?.seg === 1,
                                              "ter": tariffUnit?.ter === 1,
                                              "qua": tariffUnit?.qua === 1,
                                              "qui": tariffUnit?.qui === 1,
                                              "sex": tariffUnit?.sex === 1,
                                              "sab": tariffUnit?.sab === 1,
                                            };
                                            for (const weekDayLabel in (isTicket ? ticketDaysOfWeek : tourDaysOfWeek)) {
                                              weekDaysAvailable.push({ "day": weekDayLabel, "status": (isTicket ? ticketDaysOfWeek : tourDaysOfWeek)[weekDayLabel] });
                                            }
                                            tariffUnit.horariosTarifario.forEach((horarioElem: any) => {
                                              hoursAvailable.push(activityStartObj?.find((elem: any) => elem.hours === horarioElem.hora.slice(0, 5)).hours)
                                            });

                                            let minValue: number = tourSIG.tour.sellingType === 1
                                              ? Math.min(...tariffUnit.horariosTarifario.map((hour: any) => hour.sellingTarifGlobal))
                                              : Math.min(...tariffUnit.horariosTarifario.map((hour: any) => hour.sellingTarifAdult));

                                            let prices: any = {
                                              Adult: tourSIG.tour.sellingType === 1 ? Math.min(...tariffUnit.horariosTarifario.map((hour: any) => hour.sellingTarifGlobal)) : Math.min(...tariffUnit.horariosTarifario.map((hour: any) => hour.sellingTarifAdult)),
                                              Child: Math.min(...tariffUnit.horariosTarifario.map((hour: any) => hour.sellingTarifChild)) || 0,
                                              Infant: Math.min(...tariffUnit.horariosTarifario.map((hour: any) => hour.sellingTarifInfant)) || 0,
                                              Elders: Math.min(...tariffUnit.horariosTarifario.map((hour: any) => hour.sellingTarifElders)) || 0,
                                              Student: Math.min(...tariffUnit.horariosTarifario.map((hour: any) => hour.sellingTarifStudent)) || 0
                                            }
                                            return (
                                              <>
                                                {index > 0 && <hr />}
                                                <div>
                                                  <span className="d-flex weekDays mt-3 gap-2">
                                                    {weekDaysAvailable.map(
                                                      (e: any, key: number) => {
                                                        return (
                                                          <div
                                                            className={key === 0 ? "m-0" : ''}
                                                          >
                                                            <div
                                                              key={key}
                                                              className={`btn label-small ${e.status ? 'btn-primary' : 'btn-disabled'}`}
                                                            >
                                                              <span className="text-capitalize">
                                                                {e.day}
                                                              </span>
                                                            </div>
                                                          </div>
                                                        );
                                                      }
                                                    )}
                                                  </span>
                                                </div>
                                                <div className="mt-2 info">
                                                  <span>
                                                    {hoursAvailable.map((elem: any, index: number) => { return `${index > 0 ? " | " : ""}${elem}` })}
                                                  </span>
                                                </div>

                                                <div className="mt-2 info">
                                                  <span className="my-1">
                                                    {tourSIG.tour.modalidade[index]?.productModalityRange.map((rangeElem: any, rangeIndex: number) => {
                                                      return (
                                                        <>
                                                          {rangeElem.rangeStatus === 1 && `${rangeElem.rangeLabel[0].text}: ${(prices[`${rangeElem.rangeId}`] || 0).toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })} ${rangeIndex !== tourSIG.tour.modalidade[index]?.productModalityRange.length - 1 ? " | " : " "}`}
                                                        </>
                                                      )
                                                    })}
                                                  </span>
                                                </div>
                                              </>
                                            )
                                          })}

                                        </div>

                                      </Col>
                                    </>
                                  )
                                })}
                              </>
                            )}
                          </>
                        )
                      })}
                    </div >

                    <div className="d-flex justify-content-between pt-5">
                      <h5 className="mb-0 pt-subtitle">Cadastrar nova tarifa</h5>
                    </div>

                    <Form.Group as={Col} md="6" className="mt-4">
                      <Form.Label className="label-small">
                        Nome do tarifário
                      </Form.Label>

                      <Controller
                        control={control}
                        name="tariffName"
                        rules={{
                          required: {
                            value: true,
                            message: "Por favor, informe o nome da tarifa",
                          },
                        }}
                        render={({ field }: any) => (
                          <Form.Control
                            {...field}
                            type="text"
                            aria-invalid={errors?.tariffName ? "true" : ""}
                            variant="standard"
                            margin="normal"
                            required
                            autoComplete='off'
                          />
                        )}
                      />
                      <ErrorMessage
                        errors={errors}
                        name="tariffName"
                        render={({ message }) => (
                          <small style={{ color: "red" }}>{message}</small>
                        )}
                      />
                    </Form.Group>

                    <Form.Group as={Col} md="6" className="mt-4">
                      <Form.Label className="label-small">Modalidade</Form.Label>

                      <Controller
                        control={control}
                        name="modality"
                        rules={{
                          required: {
                            value: true,
                            message: "Por favor, informe a modalidade",
                          },
                        }}
                        render={({ field }: any) => (
                          <div className={"endpoint"}>
                            <Select
                              {...field}
                              aria-invalid={errors?.modality ? "true" : ""}
                              as="select"
                              className="basic-multi-select"
                              classNamePrefix="select"
                              margin="normal"
                              placeholder={"Selecione"}
                              options={tourSIG.tour.modalidade.map((e: any, index: any) => { return { "value": index, "label": e.modalityName[0].text } })}
                              getOptionLabel={(option: any) => `${option.label}`}
                            />
                          </div>
                        )}
                      />
                      <ErrorMessage
                        errors={errors}
                        name="modality"
                        render={({ message }) => (
                          <small style={{ color: "red" }}>{message}</small>
                        )}
                      />
                    </Form.Group>

                    <Form.Group as={Col} md="2" className="mt-4">
                      <Form.Label className="label-small">Data Início</Form.Label>

                      <Controller
                        control={control}
                        name="beginDate"
                        rules={{
                          required: {
                            value: true,
                            message: "Por favor, informe a data",
                          },
                        }}
                        render={({ field }: any) => (
                          <InputGroup hasValidation className="" size="sm">
                            <SingleCalendarWithDefaultValue
                              date={beginDate}
                              setValue={setBeginDate}
                            />
                          </InputGroup>
                        )}
                      />
                      <ErrorMessage
                        errors={errors}
                        name="beginDate"
                        render={({ message }) => (
                          <small style={{ color: "red" }}>{message}</small>
                        )}
                      />
                    </Form.Group>

                    <Form.Group as={Col} md="2" className="mt-4">
                      <Form.Label className="label-small">Data Fim</Form.Label>

                      <Controller
                        control={control}
                        name="endDate"
                        rules={{
                          required: {
                            value: true,
                            message: "Por favor, informe a data",
                          },
                        }}
                        render={({ field }: any) => (

                          <InputGroup hasValidation className="" size="sm">
                            <SingleCalendarWithDefaultValue
                              {...field}
                              date={endDate}
                              setValue={setEndDate}
                              pastDateToDisable={beginDate}
                            />
                          </InputGroup>
                        )}
                      />
                      <ErrorMessage
                        errors={errors}
                        name="endDate"
                        render={({ message }) => (
                          <small style={{ color: "red" }}>{message}</small>
                        )}
                      />
                    </Form.Group>

                    <Form.Group as={Col} md="2" className="mt-4">
                      <Form.Label className="label-small">Markup{/* Comissões */}</Form.Label>

                      <Controller
                        control={control}
                        name="commission"
                        defaultValue={""}
                        rules={{
                          required: {
                            value: true,
                            message: "Por favor, informe o tipo de comissão",
                          },
                        }}
                        render={({ field }: any) => (
                          <div
                            className={
                              errors.commission !== undefined
                                ? "endpoint-error"
                                : "endpoint"
                            }
                          >
                            <Form.Select
                              {...field}
                              aria-invalid={errors?.commission ? "true" : ""}
                              as="select"
                              variant="standard"
                              margin="normal"
                              required
                            >
                              <option value="">Selecione</option>
                              <option value="0">Por percentual</option>
                              <option value="1">Por venda</option>
                            </Form.Select>
                          </div>
                        )}
                      />
                      {/* <GetPercentSupplierComission propsField={field} propsErrors={errors} propsLabel={'Comissões'} /> */}
                      <ErrorMessage
                        errors={errors}
                        name="commission"
                        render={({ message }) => (
                          <small style={{ color: "red" }}>{message}</small>
                        )}
                      />
                    </Form.Group>

                    <Form.Group as={Col} md="6" controlId="" className="mt-4">
                      <Form.Label className="label-small">
                        Canais de venda
                      </Form.Label>

                      <Controller
                        control={control}
                        name="salesChannel"
                        rules={{ required: { value: true, message: "Por favor, informe esse campo" } }}
                        render={({ field }: any) => (
                          <GetSalesChannel propsField={field} propsErrors={errors} setInfoChange={setInfoChange} setValue={setValue} setSalesChannelObj={setSalesChannelObj} infoEdit={salesChannelInfoEdit} />
                        )}
                      />

                      <ErrorMessage
                        errors={errors}
                        name="salesChannel"
                        render={({ message }) => (
                          <small style={{ color: "red" }}>{message}</small>
                        )}
                      />
                    </Form.Group>
                  </Row>
                </div>

                <div className="pt-content-info">
                  <div className="d-flex justify-content-between pt-3">
                    <h5 className="mb-0 pt-subtitle">Antecedência de compra</h5>
                  </div>
                  <>
                    {
                      salesChannelObj !== null &&
                      <>{getSalesChannel()}</>
                    }
                  </>
                </div>

                {/* {getValues().beginDate && getValues().endDate && (
                  <>
                    <div className="pt-content-info">
                      <Row>
                        <div className="d-flex justify-content-between pt-3">
                          <small>
                            <span
                              onClick={() => setPromotionByHourModalShow(true)}
                              style={{ fontWeight: 600, cursor: "pointer" }}
                            >
                              Cadastrar promoções por horários
                            </span>{" "}
                            (Opcional)
                          </small>
                        </div>
                      </Row>
                      {promoObject?.length > 0 && (
                        <Row>
                          <div className="d-flex justify-content-between pt-3 promocoes-cadastradas">
                            <div className="table-default ">
                              <div className="table-container">
                                <h6
                                  className="mb-0 pt-subtitle mb-4"
                                  style={{ color: "#707070" }}
                                >
                                  Promoções cadastradas
                                </h6>
                                <BootstrapTable
                                  bootstrap4
                                  keyField="id"
                                  data={promoObject}
                                  hover={true}
                                  columns={columns}
                                  striped={true}
                                />
                              </div>
                            </div>
                          </div>
                        </Row>
                      )}
                    </div>
                  </>
                )} */}

                {watchModality !== undefined &&
                  watchModality !== null &&
                  watchModality !== "" && (
                    <>
                      <div className="pt-content-info" style={{ border: "none" }}>
                        <div className="d-flex justify-content-between pt-3">
                          <h5 className="mb-0 pt-subtitle">Tarifas</h5>
                        </div>

                        {tariffs.length > 0 ? (
                          tariffs /* .filter((e: any) => e !== null) */
                            .map((elem: any, indexContainer: number) => {
                              return (
                                elem !== null && (
                                  <>
                                    <Row className="mb-4" key={indexContainer}>
                                      <Form.Group
                                        as={Col}
                                        md="6"
                                        className="mt-4"
                                      >
                                        <div className="d-flex justify-content-between mb-2">
                                          <Form.Label className="label-small">
                                            Dias
                                          </Form.Label>

                                          <div className="d-flex">
                                            <Controller
                                              control={control}
                                              name="allDays"
                                              render={({ field }: any) => (
                                                <Form.Check
                                                  {...field}
                                                  id={`allDays`}
                                                  type="checkbox"
                                                  variant="standard"
                                                  margin="normal"
                                                  onClick={(e: any) =>
                                                    selectAll(e, indexContainer)
                                                  }
                                                  required
                                                  className="mx-2"
                                                />
                                              )}
                                            />
                                            <Form.Label
                                              className="label-small"
                                              htmlFor="allDays"
                                            >
                                              Selecionar tudo
                                            </Form.Label>
                                          </div>
                                        </div>

                                        <div className="d-flex justify-content-between">
                                          {days.map((elem: any, index: any) => {
                                            return (
                                              <>
                                                <div key={index}>
                                                  <div className="d-flex w-auto">
                                                    <Controller
                                                      control={control}
                                                      name={`days${elem.day}-${indexContainer}`}
                                                      render={({
                                                        field,
                                                      }: any) => (
                                                        <Form.Check
                                                          {...field}
                                                          id={`days${elem.day}-${indexContainer}`}
                                                          type="checkbox"
                                                          variant="standard"
                                                          margin="normal"
                                                          onChange={(e: any) =>
                                                            changeDays(
                                                              index,
                                                              indexContainer
                                                            )
                                                          }
                                                          required
                                                          className="d-none mx-2"
                                                        />
                                                      )}
                                                    />
                                                    <div>
                                                      <Form.Label
                                                        className={
                                                      /* (elem.index !== indexContainer && elem.disabled === true) || */ elem.controlAvailable === true
                                                            ? "btn label-small btn-disabled"
                                                            : elem.index.includes(indexContainer)/*  && elem.disabled === true */
                                                              ? "btn btn-primary label-small"
                                                              : "btn btn-outline-primary label-small"
                                                        }
                                                        htmlFor={`days${elem.day}-${indexContainer}`}
                                                      >
                                                        <span className="text-capitalize">
                                                          {elem.day}
                                                        </span>
                                                      </Form.Label>
                                                    </div>
                                                  </div>
                                                </div>
                                              </>
                                            );
                                          })}
                                        </div>
                                        <Row>
                                          <Col md={3} className="mt-4">
                                            <Form.Label className="label-small">
                                              Labels
                                            </Form.Label>

                                            {tourSIG.tour?.sellingType !== 1 ? (
                                              <>
                                                <div>
                                                  <small
                                                    className="d-flex align-items-center my-4"
                                                    style={{
                                                      height: "38px",
                                                    }}
                                                  >
                                                    {tourSIG.tour.modalidade[watchModality?.value || 0]?.productModalityRange[0].rangeLabel[0].text}
                                                  </small>
                                                </div>

                                                {tourSIG.tour.modalidade[watchModality?.value || 0].productModalityRange.length > 1 && tourSIG.tour.modalidade[watchModality?.value || 0].productModalityRange[1]?.rangeStatus === 1 ? (
                                                  <div>
                                                    <small
                                                      className="d-flex align-items-center mb-4"
                                                      style={{
                                                        height: "38px",
                                                      }}
                                                    >
                                                      {tourSIG.tour.modalidade[watchModality?.value || 0]?.productModalityRange[1].rangeLabel[0].text}
                                                    </small>
                                                  </div>
                                                ) : (
                                                  <></>
                                                )}

                                                {tourSIG.tour.modalidade[watchModality?.value || 0].productModalityRange.length > 2 && tourSIG.tour.modalidade[watchModality?.value || 0].productModalityRange[2]?.rangeStatus === 1 ? (
                                                  <div>
                                                    <small
                                                      className="d-flex align-items-center mb-4"
                                                      style={{
                                                        height: "38px",
                                                      }}
                                                    >
                                                      {tourSIG.tour.modalidade[watchModality?.value || 0]?.productModalityRange[2].rangeLabel[0].text}
                                                    </small>
                                                  </div>
                                                ) : (
                                                  <></>
                                                )}
                                                {tourSIG.tour.modalidade[watchModality?.value || 0].productModalityRange.length > 3 && tourSIG.tour.modalidade[watchModality?.value || 0].productModalityRange[3]?.rangeStatus === 1 ? (
                                                  <div>
                                                    <small
                                                      className="d-flex align-items-center mb-4"
                                                      style={{
                                                        height: "38px",
                                                      }}
                                                    >
                                                      {tourSIG.tour.modalidade[watchModality?.value || 0]?.productModalityRange[3].rangeLabel[0].text}
                                                    </small>
                                                  </div>
                                                ) : (
                                                  <></>
                                                )}
                                                {tourSIG.tour.modalidade[watchModality?.value || 0].productModalityRange.length > 4 && tourSIG.tour.modalidade[watchModality?.value || 0].productModalityRange[4]?.rangeStatus === 1 ? (
                                                  <div>
                                                    <small
                                                      className="d-flex align-items-center mb-0"
                                                      style={{
                                                        height: "38px",
                                                      }}
                                                    >
                                                      {tourSIG.tour.modalidade[watchModality?.value || 0]?.productModalityRange[4].rangeLabel[0].text}
                                                    </small>
                                                  </div>
                                                ) : (
                                                  <></>
                                                )}
                                              </>
                                            ) : (
                                              <div>
                                                <small
                                                  className="d-flex align-items-center mb-0"
                                                  style={{
                                                    height: "38px",
                                                  }}
                                                >
                                                  {tourSIG.tour.modalidade[watchModality?.value || 0]?.productModalityRange[0]?.rangeLabel[0].text}
                                                </small>
                                              </div>
                                            )}
                                          </Col>

                                          <Col
                                            md={3}
                                            className="mt-4 text-center"
                                          >
                                            <Form.Label className="label-small">
                                              Valor de custo
                                            </Form.Label>

                                            {tourSIG.tour?.sellingType !== 1 ? (
                                              <>
                                                <div className="mt-4">
                                                  <Controller
                                                    control={control}
                                                    name={`tariffAdultCost-${indexContainer}`}
                                                    rules={{
                                                      required: {
                                                        value: true,
                                                        message:
                                                          "Por favor, informe o tipo de operação",
                                                      },
                                                    }}
                                                    render={({ field }: any) => (
                                                      <Form.Control
                                                        {...field}
                                                        type="number"
                                                        variant="standard"
                                                        margin="normal"
                                                        onKeyPress={(e) =>
                                                          !/(?:\.|,|[0-9])*/.test(
                                                            e.key
                                                          ) && e.preventDefault()
                                                        }
                                                        onBlur={(e: any) => {
                                                          calcTariffCost(
                                                            e?.target?.value,
                                                            "Adult",
                                                            indexContainer
                                                          );
                                                        }}
                                                        required
                                                        autoComplete='off'
                                                      />
                                                    )}
                                                  />
                                                </div>

                                                {tourSIG.tour.modalidade[watchModality?.value || 0].productModalityRange.length > 1 && tourSIG.tour.modalidade[watchModality?.value || 0].productModalityRange[1].rangeStatus === 1 ? (
                                                  <div className="mt-4">
                                                    <Controller
                                                      control={control}
                                                      name={`tariffChildCost-${indexContainer}`}
                                                      rules={{
                                                        required: {
                                                          value: true,
                                                          message:
                                                            "Por favor, informe o tipo de operação",
                                                        },
                                                      }}
                                                      render={({
                                                        field,
                                                      }: any) => (
                                                        <Form.Control
                                                          {...field}
                                                          type="number"
                                                          variant="standard"
                                                          margin="normal"
                                                          onKeyPress={(e) =>
                                                            !/(?:\.|,|[0-9])*/.test(
                                                              e.key
                                                            ) &&
                                                            e.preventDefault()
                                                          }
                                                          onBlur={(e: any) => {
                                                            calcTariffCost(
                                                              e?.target?.value,
                                                              "Child",
                                                              indexContainer
                                                            );
                                                          }}
                                                          required
                                                          autoComplete='off'
                                                        />
                                                      )}
                                                    />
                                                  </div>
                                                ) : (
                                                  <></>
                                                )}

                                                {tourSIG.tour.modalidade[watchModality?.value || 0].productModalityRange.length > 2 && tourSIG.tour.modalidade[watchModality?.value || 0].productModalityRange[2].rangeStatus === 1 ? (
                                                  <div className="mt-4">
                                                    <Controller
                                                      control={control}
                                                      name={`tariffInfantCost-${indexContainer}`}
                                                      rules={{
                                                        required: {
                                                          value: true,
                                                          message:
                                                            "Por favor, informe o tipo de operação",
                                                        },
                                                      }}
                                                      render={({
                                                        field,
                                                      }: any) => (
                                                        <Form.Control
                                                          {...field}
                                                          type="number"
                                                          variant="standard"
                                                          margin="normal"
                                                          onKeyPress={(e) =>
                                                            !/(?:\.|,|[0-9])*/.test(
                                                              e.key
                                                            ) &&
                                                            e.preventDefault()
                                                          }
                                                          onBlur={(e: any) => {
                                                            calcTariffCost(
                                                              e?.target?.value,
                                                              "Infant",
                                                              indexContainer
                                                            );
                                                          }}
                                                          required
                                                          autoComplete='off'
                                                        />
                                                      )}
                                                    />
                                                  </div>
                                                ) : (
                                                  <></>
                                                )}
                                                {tourSIG.tour.modalidade[watchModality?.value || 0].productModalityRange.length > 3 && tourSIG.tour.modalidade[watchModality?.value || 0].productModalityRange[3]?.rangeStatus === 1 ? (
                                                  <div className="mt-4">
                                                    <Controller
                                                      control={control}
                                                      name={`tariffEldersCost-${indexContainer}`}
                                                      rules={{
                                                        required: {
                                                          value: true,
                                                          message:
                                                            "Por favor, informe o tipo de operação",
                                                        },
                                                      }}
                                                      render={({
                                                        field,
                                                      }: any) => (
                                                        <Form.Control
                                                          {...field}
                                                          type="number"
                                                          variant="standard"
                                                          margin="normal"
                                                          onKeyPress={(e) => !/(?:\.|,|[0-9])*/.test(e.key) && e.preventDefault()}
                                                          onBlur={(e: any) => { calcTariffCost(e?.target?.value, "Elders", indexContainer); }}
                                                          required
                                                          autoComplete='off'
                                                        />
                                                      )}
                                                    />
                                                  </div>
                                                ) : (
                                                  <></>
                                                )}
                                                {tourSIG.tour.modalidade[watchModality?.value || 0].productModalityRange.length > 4 && tourSIG.tour.modalidade[watchModality?.value || 0].productModalityRange[4]?.rangeStatus === 1 ? (
                                                  <div className="mt-4">
                                                    <Controller
                                                      control={control}
                                                      name={`tariffStudentCost-${indexContainer}`}
                                                      rules={{
                                                        required: {
                                                          value: true,
                                                          message:
                                                            "Por favor, informe o tipo de operação",
                                                        },
                                                      }}
                                                      render={({
                                                        field,
                                                      }: any) => (
                                                        <Form.Control
                                                          {...field}
                                                          type="number"
                                                          variant="standard"
                                                          margin="normal"
                                                          onKeyPress={(e) => !/(?:\.|,|[0-9])*/.test(e.key) && e.preventDefault()}
                                                          onBlur={(e: any) => { calcTariffCost(e?.target?.value, "Student", indexContainer); }}
                                                          required
                                                          autoComplete='off'
                                                        />
                                                      )}
                                                    />
                                                  </div>
                                                ) : (
                                                  <></>
                                                )}
                                              </>
                                            ) : (
                                              <div className="mt-4">
                                                <Controller
                                                  control={control}
                                                  name={`tariffGlobalCost-${indexContainer}`}
                                                  rules={{
                                                    required: {
                                                      value: true,
                                                      message:
                                                        "Por favor, informe o tipo de operação",
                                                    },
                                                  }}
                                                  render={({ field }: any) => (
                                                    <Form.Control
                                                      {...field}
                                                      type="number"
                                                      variant="standard"
                                                      margin="normal"
                                                      onKeyPress={(e) => !/(?:\.|,|[0-9])*/.test(e.key) && e.preventDefault()}
                                                      onBlur={(e: any) => { calcTariffCost(e?.target?.value, "Global", indexContainer); }}
                                                      required
                                                      autoComplete='off'
                                                    />
                                                  )}
                                                />
                                              </div>
                                            )}
                                          </Col>

                                          <Col
                                            md={3}
                                            className="mt-4 text-center"
                                          >
                                            <Form.Label className="label-small">
                                              %
                                            </Form.Label>

                                            {tourSIG.tour?.sellingType !== 1 ? (
                                              <>
                                                <div className="mt-4">
                                                  <Controller
                                                    control={control}
                                                    name={`tariffPercentAdult-${indexContainer}`}
                                                    rules={{
                                                      required: {
                                                        value: false,
                                                        message:
                                                          "Por favor, informe o tipo de operação",
                                                      },
                                                    }}
                                                    render={({ field }: any) => (
                                                      <Form.Control
                                                        {...field}
                                                        disabled={watchComission === "" || watchComission === "1"}
                                                        type="text"
                                                        variant="standard"
                                                        margin="normal"
                                                        required
                                                        maxLength={2}
                                                        autoComplete='off'
                                                        onKeyPress={(e) => !/(?:\.|,|[0-9])*/.test(e.key) && e.preventDefault()}
                                                        onBlur={(e: any) => { calcTariffPercent("Adult", indexContainer); }}
                                                      />
                                                    )}
                                                  />
                                                </div>

                                                {tourSIG.tour.modalidade[watchModality?.value || 0].productModalityRange.length > 1 && tourSIG.tour.modalidade[watchModality?.value || 0].productModalityRange[1].rangeStatus === 1 ? (
                                                  <div className="mt-4">
                                                    <Controller
                                                      control={control}
                                                      name={`tariffPercentChild-${indexContainer}`}
                                                      render={({
                                                        field,
                                                      }: any) => (
                                                        <Form.Control
                                                          {...field}
                                                          disabled={watchComission === "" || watchComission === "1"}
                                                          type="text"
                                                          variant="standard"
                                                          margin="normal"
                                                          required
                                                          maxLength={2}
                                                          autoComplete='off'
                                                          onKeyPress={(e) => !/(?:\.|,|[0-9])*/.test(e.key) && e.preventDefault()}
                                                          onBlur={(e: any) => { calcTariffPercent("Child", indexContainer); }}
                                                        />
                                                      )}
                                                    />
                                                  </div>
                                                ) : (
                                                  <></>
                                                )}

                                                {tourSIG.tour.modalidade[watchModality?.value || 0].productModalityRange.length > 2 && tourSIG.tour.modalidade[watchModality?.value || 0].productModalityRange[2].rangeStatus === 1 ? (
                                                  <div className="mt-4">
                                                    <Controller
                                                      control={control}
                                                      name={`tariffPercentInfant-${indexContainer}`}
                                                      render={({
                                                        field,
                                                      }: any) => (
                                                        <Form.Control
                                                          {...field}
                                                          disabled={watchComission === "" || watchComission === "1"}
                                                          type="text"
                                                          variant="standard"
                                                          margin="normal"
                                                          required
                                                          autoComplete='off'
                                                          maxLength={2}
                                                          onKeyPress={(e) => !/(?:\.|,|[0-9])*/.test(e.key) && e.preventDefault()}
                                                          onBlur={(e: any) => { calcTariffPercent("Infant", indexContainer); }}
                                                        />
                                                      )}
                                                    />
                                                  </div>
                                                ) : (
                                                  <></>
                                                )}
                                                {tourSIG.tour.modalidade[watchModality?.value || 0].productModalityRange.length > 3 && tourSIG.tour.modalidade[watchModality?.value || 0].productModalityRange[3]?.rangeStatus === 1 ? (
                                                  <div className="mt-4">
                                                    <Controller
                                                      control={control}
                                                      name={`tariffPercentElders-${indexContainer}`}
                                                      render={({
                                                        field,
                                                      }: any) => (
                                                        <Form.Control
                                                          {...field}
                                                          disabled={watchComission === "" || watchComission === "1"}
                                                          type="text"
                                                          variant="standard"
                                                          margin="normal"
                                                          required
                                                          autoComplete='off'
                                                          maxLength={2}
                                                          onKeyPress={(e) => !/(?:\.|,|[0-9])*/.test(e.key) && e.preventDefault()}
                                                          onBlur={(e: any) => { calcTariffPercent("Elders", indexContainer); }}
                                                        />
                                                      )}
                                                    />
                                                  </div>
                                                ) : (
                                                  <></>
                                                )}
                                                {tourSIG.tour.modalidade[watchModality?.value || 0].productModalityRange.length > 4 && tourSIG.tour.modalidade[watchModality?.value || 0].productModalityRange[4]?.rangeStatus === 1 ? (
                                                  <div className="mt-4">
                                                    <Controller
                                                      control={control}
                                                      name={`tariffPercentStudent-${indexContainer}`}
                                                      render={({
                                                        field,
                                                      }: any) => (
                                                        <Form.Control
                                                          {...field}
                                                          disabled={watchComission === "" || watchComission === "1"}
                                                          type="text"
                                                          variant="standard"
                                                          margin="normal"
                                                          required
                                                          autoComplete='off'
                                                          maxLength={2}
                                                          onKeyPress={(e) => !/(?:\.|,|[0-9])*/.test(e.key) && e.preventDefault()}
                                                          onBlur={(e: any) => { calcTariffPercent("Student", indexContainer); }}
                                                        />
                                                      )}
                                                    />
                                                  </div>
                                                ) : (
                                                  <></>
                                                )}
                                              </>
                                            ) : (
                                              <div className="mt-4">
                                                <Controller
                                                  control={control}
                                                  name={`tariffPercentGlobal-${indexContainer}`}
                                                  rules={{
                                                    required: {
                                                      value: false,
                                                      message:
                                                        "Por favor, informe o tipo de operação",
                                                    },
                                                  }}
                                                  render={({ field }: any) => (
                                                    <Form.Control
                                                      {...field}
                                                      disabled={
                                                        watchComission === "" ||
                                                        watchComission === "1"
                                                      }
                                                      type="text"
                                                      variant="standard"
                                                      margin="normal"
                                                      required
                                                      autoComplete='off'
                                                      maxLength={2}
                                                      onKeyPress={(e) =>!/(?:\.|,|[0-9])*/.test(e.key) && e.preventDefault()}
                                                      onBlur={(e: any) => {calcTariffPercent("Global", indexContainer);}}
                                                    />
                                                  )}
                                                />
                                              </div>
                                            )}
                                          </Col>

                                          <Col
                                            md={3}
                                            className="mt-4 text-center"
                                          >
                                            <Form.Label className="label-small">
                                              Venda
                                            </Form.Label>

                                            {tourSIG.tour?.sellingType !== 1 ? (
                                              <>
                                                <div className="mt-4">
                                                  <Controller
                                                    control={control}
                                                    name={`tariffAdultAlterado-${indexContainer}`}
                                                    rules={{ required: { value: true, message: "Por favor, informe o campo", }, }}
                                                    render={({ field }: any) => (
                                                      <Form.Control
                                                        {...field}
                                                        type="text"
                                                        disabled={watchComission === "" || watchComission === "0"}
                                                        aria-invalid={errors[`tariffAdultAlterado-${indexContainer}`] ? "true" : ""}
                                                        variant="standard"
                                                        margin="normal"
                                                        required
                                                        autoComplete='off'
                                                        onBlur={(e: any) => { calcTariffAlterado(e?.target?.value, "Adult", indexContainer); }}
                                                      />
                                                    )}
                                                  />
                                                </div>

                                                {tourSIG.tour.modalidade[watchModality?.value || 0].productModalityRange.length > 1 && tourSIG.tour.modalidade[watchModality?.value || 0].productModalityRange[1].rangeStatus === 1 ? (
                                                  <div className="mt-4">
                                                    <Controller
                                                      control={control}
                                                      name={`tariffChildAlterado-${indexContainer}`}
                                                      rules={{ required: { value: true, message: "Por favor, informe o campo", }, }}
                                                      render={({ field, }: any) => (
                                                        <Form.Control
                                                          {...field}
                                                          type="text"
                                                          disabled={watchComission === "" || watchComission === "0"}
                                                          aria-invalid={errors[`tariffChildAlterado-${indexContainer}`] ? "true" : ""}
                                                          variant="standard"
                                                          margin="normal"
                                                          required
                                                          autoComplete='off'
                                                          onBlur={(e: any) => { calcTariffAlterado(e?.target?.value, "Child", indexContainer); }}
                                                        />
                                                      )}
                                                    />
                                                  </div>
                                                ) : (
                                                  <></>
                                                )}

                                                {tourSIG.tour.modalidade[watchModality?.value || 0].productModalityRange.length > 2 && tourSIG.tour.modalidade[watchModality?.value || 0].productModalityRange[2].rangeStatus === 1 ? (
                                                  <div className="mt-4">
                                                    <Controller
                                                      control={control}
                                                      name={`tariffInfantAlterado-${indexContainer}`}
                                                      rules={{ required: { value: true, message: "Por favor, informe o campo", }, }}
                                                      render={({ field, }: any) => (
                                                        <Form.Control
                                                          {...field}
                                                          disabled={watchComission === "" || watchComission === "0"}
                                                          aria-invalid={errors[`tariffInfantAlterado-${indexContainer}`] ? "true" : ""}
                                                          type="text"
                                                          variant="standard"
                                                          margin="normal"
                                                          required
                                                          autoComplete='off'
                                                          onBlur={(e: any) => { calcTariffAlterado(e?.target?.value, "Infant", indexContainer); }}
                                                        />
                                                      )}
                                                    />
                                                  </div>
                                                ) : (
                                                  <></>
                                                )}
                                                {tourSIG.tour.modalidade[watchModality?.value || 0].productModalityRange.length > 3 && tourSIG.tour.modalidade[watchModality?.value || 0].productModalityRange[3]?.rangeStatus === 1 ? (
                                                  <div className="mt-4">
                                                    <Controller
                                                      control={control}
                                                      name={`tariffEldersAlterado-${indexContainer}`}
                                                      rules={{ required: { value: true, message: "Por favor, informe o campo", }, }}
                                                      render={({ field, }: any) => (
                                                        <Form.Control
                                                          {...field}
                                                          disabled={watchComission === "" || watchComission === "0"}
                                                          aria-invalid={errors[`tariffEldersAlterado-${indexContainer}`] ? "true" : ""}
                                                          type="text"
                                                          variant="standard"
                                                          margin="normal"
                                                          required
                                                          autoComplete='off'
                                                          onBlur={(e: any) => { calcTariffAlterado(e?.target?.value, "Elders", indexContainer); }}
                                                        />
                                                      )}
                                                    />
                                                  </div>
                                                ) : (
                                                  <></>
                                                )}

                                                {tourSIG.tour.modalidade[watchModality?.value || 0].productModalityRange.length > 4 && tourSIG.tour.modalidade[watchModality?.value || 0].productModalityRange[4]?.rangeStatus === 1 ? (
                                                  <div className="mt-4">
                                                    <Controller
                                                      control={control}
                                                      name={`tariffStudentAlterado-${indexContainer}`}
                                                      rules={{ required: { value: true, message: "Por favor, informe o campo", }, }}
                                                      render={({ field, }: any) => (
                                                        <Form.Control
                                                          {...field}
                                                          disabled={watchComission === "" || watchComission === "0"}
                                                          aria-invalid={errors[`tariffStudentAlterado-${indexContainer}`] ? "true" : ""}
                                                          type="text"
                                                          variant="standard"
                                                          margin="normal"
                                                          required
                                                          autoComplete='off'
                                                          onBlur={(e: any) => { calcTariffAlterado(e?.target?.value, "Student", indexContainer); }}
                                                        />
                                                      )}
                                                    />
                                                  </div>
                                                ) : (
                                                  <></>
                                                )}
                                              </>
                                            ) : (
                                              <div className="mt-4">
                                                <Controller
                                                  control={control}
                                                  name={`tariffGlobalAlterado-${indexContainer}`}
                                                  rules={{ required: { value: true, message: "Por favor, informe o campo", }, }}
                                                  render={({ field }: any) => (
                                                    <Form.Control
                                                      {...field}
                                                      type="text"
                                                      disabled={watchComission === "" || watchComission === "0"}
                                                      aria-invalid={errors[`tariffGlobalAlterado-${indexContainer}`] ? "true" : ""}
                                                      variant="standard"
                                                      margin="normal"
                                                      required
                                                      autoComplete='off'
                                                      onBlur={(e: any) => { calcTariffAlterado(e?.target?.value, "Global", indexContainer); }}
                                                    />
                                                  )}
                                                />
                                              </div>
                                            )}
                                          </Col>
                                        </Row>

                                        <Row>
                                          <Form.Group
                                            as={Col}
                                            md="12"
                                            controlId=""
                                            className="mt-4"
                                          >
                                            <div className="d-flex justify-content-between">
                                              <Form.Label className="label-small">
                                                Início da atividade
                                              </Form.Label>

                                              <small className="mb-0 cursor-pointer" onClick={() => { startTimeAutomaticCalc(indexContainer); }}>
                                                <span style={{ fontWeight: 600, border: "1px solid #707070", borderRadius: "4px", padding: "3px 10px", }}>
                                                  Preenchimento automático
                                                </span>
                                              </small>
                                            </div>

                                            <Controller
                                              control={control}
                                              name={`activityStart-${indexContainer}`}
                                              rules={{ required: { value: true, message: "Por favor, preencha esse campo", }, }}
                                              render={({ field }: any) => (
                                                <div className="input-text-custom endpoint">
                                                  <GetTime15MinInterval
                                                    propsField={field}
                                                    info={activityStartObj}
                                                  />
                                                </div>
                                              )}
                                            />

                                            <ErrorMessage
                                              errors={errors}
                                              name={`activityStart-${indexContainer}`}
                                              render={({ message }) => (
                                                <small style={{ color: "red" }}>
                                                  {message}
                                                </small>
                                              )}
                                            />

                                            <ErrorMessage
                                              errors={errors}
                                              name="activityStart"
                                              render={({ message }) => (
                                                <small style={{ color: "red", }}>
                                                  {message}
                                                </small>
                                              )}
                                            />
                                          </Form.Group>
                                        </Row>
                                      </Form.Group>

                                      {tariffs.filter((e: any) => e !== null).length > 1 && (
                                        <Col md="2">
                                          <div className="d-flex justify-content-center align-items-center h-100">
                                            <FontAwesomeIcon
                                              icon={["fal", "trash-alt"]}
                                              className="mb-3"
                                              style={{ cursor: "pointer", }}
                                              onClick={() => { deleteTariff(indexContainer); }}
                                            />
                                          </div>
                                        </Col>
                                      )}
                                    </Row>
                                    <hr />
                                  </>
                                )
                              );
                            })
                        ) : (
                          <></>
                        )}
                        <Row>
                          <Col md={6}>
                            <div className="d-flex justify-content-end mt-5">
                              <div className="btn-add-details" onClick={handleClickTariffs}>
                                <FontAwesomeIcon
                                  icon={["fal", "plus"]}
                                  size="1x"
                                  className="mr-2"
                                />
                                Adicionar
                              </div>
                            </div>
                          </Col>
                        </Row>
                      </div>
                    </>
                  )}

                <Button
                  type="submit"
                  className="mt-2"
                  onKeyPress={(event: any) => { return event.keyCode !== 13; }}
                >
                  {isEditing[0] === null ? "Cadastrar Tarifário" : "Editar Tarifário"}
                </Button>

                <div className="h-100 d-flex justify-content-end align-items-end">
                  {isEdit !== true ? (
                    <Row className="mx-0 mt-5">
                      <Button
                        variant="outline-primary"
                        className="form-button mr-3"
                        onClick={() => action("modalities")}
                      >
                        Voltar
                      </Button>
                      <Button
                        type="button"
                        className="form-button"
                        onClick={nextStep}
                      >
                        Continuar
                      </Button>
                    </Row>
                  ) : (
                    <Row className=" mx-0 mt-5">
                      <Button type="button" className="form-button" onClick={() => { updateTariff() }}>
                        Salvar
                      </Button>
                    </Row>
                  )}
                </div>
              </Form>

              <Modal
                className="modalAuth"
                show={dateConflictModalShow}
                onHide={() => {
                  setDateConflictModalShow(false);
                }}
                aria-labelledby="contained-modal-title-vcenter"
              >
                <>
                  <Modal.Body
                    className="modal-body text-center sucess-pos d-flex justify-content-evenly"
                    style={{ height: "600px" }}
                  >
                    <div>
                      <FontAwesomeIcon
                        icon={["fal", "times-circle"]}
                        size="5x"
                        className="text-primary"
                        style={{ fontSize: "7.5em" }}
                      />
                    </div>
                    <div>
                      {`A modalidade "${tourSIG.tour.modalidade[watchModality?.value || 0]?.modalityName[0]
                        .text
                        }" já possui um tarifário com a data e horários escolhidos`}
                    </div>
                    <div className="d-flex justify-content-center pt-3">
                      <button
                        onClick={() => {
                          setDateConflictModalShow(false);
                        }}
                        className="btn btn-primary mx-2 w-25"
                      >
                        Confirmar
                      </button>
                    </div>
                  </Modal.Body>
                </>
              </Modal>

              <Modal
                className="modalAuth"
                show={modalShowTimeAutomaticCalc}
                onHide={() => {
                  setModalTimeIndexSelected(0);
                  setModalShowTimeAutomaticCalc(false);
                }}
                aria-labelledby="contained-modal-title-vcenter"
              >
                <>
                  <Modal.Body className="modal-body text-center sucess-pos d-flex justify-content-evenly">
                    <FillActivityStartModal
                      startTimeFillFunction={startTimeFillFunction}
                    />
                  </Modal.Body>
                </>
              </Modal>

              <ModalShow
                modalShow={modalShow}
                setModalShow={setModalShow}
                modalMessage={modalMessage}
                setModalMessage={setModalMessage}
                modalLog={modalLog}
                setModalLog={setModalLog}
              />

              <TariffPromoByHour
                promotionByHourModalShow={promotionByHourModalShow}
                setPromotionByHourModalShow={setPromotionByHourModalShow}
                action={addPromotion}
                actionEdit={actionEditPromotion}
                infoEdit={promoIdToEdit}
                isEdit={promoIdToEdit !== null}
                getDateValues={getValues}
                promoObject={promoObject}
                promoIdToEdit={promoIdToEdit}
                isTicket={isTicket}
              />
            </Card>
          </Col>
          :
          <>
            <Col md={9} lg={10}>
              <Card className="pt-content h-100">
                <div className="loading-modal h-100 d-flex flex-column justify-content-center align-items-center">
                  <div className="load mb-4"></div>
                  <div>Carregando dados do produto</div>
                </div>
              </Card>
            </Col>
          </>
      }
    </>
  );
}
);

export default Tariffs;
