import React, { useEffect, useState } from "react";
import { Card, Container, Row } from 'react-bootstrap';

import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import Accordion from 'react-bootstrap/Accordion';

import { useTranslation } from "react-i18next";

import SuppliersTableUsers from './SuppliersTableUsers/SuppliersTableUsers';

import '../../../../assets/sass/accordion.scss';
import { useForm } from "react-hook-form";

export interface propAccordion {
    setChangeEdit: any,
    action: any,
    ContextAwareToggle: any,
    supplierControlObject: any,
    infoEdit: any,
    setStepCtrl: any,
    scrollTop?: any,
    isEdit?: any,
    editSuppliers: any
    hasSavedInputs?: any
};

const AccordionUsers: React.FC<propAccordion> = ({
    setChangeEdit,
    action,
    ContextAwareToggle,
    supplierControlObject,
    infoEdit,
    setStepCtrl,
    scrollTop,
    isEdit = false,
    editSuppliers,
    hasSavedInputs = false
}: propAccordion) => {

    const [ctrlEventKey, setCtrlEventKey] = useState<boolean>(false);
    const [emptyUserTable, setEmptyUserTable] = useState<boolean>(false);
    const [userObject, setUserObject] = useState<any>([]);
    const [userControlObject, setUserControlObject] = useState<any>([]);


    const { t } = useTranslation();
    const [validated, setValidated] = useState(false);

    const idSup = window.location.href.split('id=')[1]?.replace(/#/g, '');

    const onSubmit = (data: any) => {

        if (isEdit === false) {
            /// ADD SUPPLIERS PAGE
            if (userObject.length > 0) {
                setEmptyUserTable(false);
                setCtrlEventKey(true);
                action(userControlObject);
            } else {
                setEmptyUserTable(true);
            }

        } else {
            /// EDIT SUPPLIERS PAGE
            let edit: any = { ...infoEdit, ...data };
            edit.supplierId = parseInt(idSup);

            let contactObg = [];

            for (var i: any = 0; i < userObject.length; i++) {
                if (userObject[i].userId) {
                    contactObg[i] = {
                        userId: userObject[i].userId,
                        nivel: userObject[i].nivel
                    };
                } else {
                    contactObg[i] = {
                        userId: userObject[i].id,
                        nivel: userObject[i].nivel
                    };
                }
            }

            edit.usersSupplierModel = contactObg;
            edit.usersSupplierDTO = contactObg;
            edit.supplierContactModel = edit.affiliateContact;
            edit.costCenterSupplierModel = edit.costCenterSupplierDTO;
            edit.supplierContactModel = edit.supplierContact;
            edit.costCenterSupplierModel = edit.costCenterSupplierDTO;
            edit.supplierByResourceModel = edit.supplierByResourceDTO;
            edit.supplierVehicleTypeModel = edit.supplierVehicleTypeDTO;

            //remover
            edit.tarTipo = edit.tarTipo === null ? 1 : edit.tarTipo
            edit.masterFilho = edit.masterFilho === null ? 1 : edit.masterFilho
            //[end] remover

            editSuppliers(edit);
        }

        setValidated(true);
    }



    useEffect(() => {
        const ctrlUsersSupplierModel = {
            usersSupplierDTO: userObject,
            usersSupplierModel: userObject.map(({ id: userId, nivelInfo: nivel }: any) => ({ userId, nivel }))
        }
        setUserControlObject(ctrlUsersSupplierModel);
    }, [userObject]);


    const handleObjectUser = (infoObject: any) => {
        if (infoObject?.length > 0) {
            setEmptyUserTable(false);
        } else {
            infoObject?.forEach((element: any) => {
                if (!element.userId) {
                    element.userId = element.id;
                }
            })
        }
        setUserObject(infoObject);
    }

    function handleCancel() {
        window.location.reload();
        /* setChangeEdit(true) */
    }

    const {
        handleSubmit,
    } = useForm();

    return (
        <Card className="bg-white mt-4">
            <Card.Header id="accordionPersonal">
                <ContextAwareToggle eventKey="0" status={ctrlEventKey}>{t('accordionUsers.title')}</ContextAwareToggle>
            </Card.Header>
            <Accordion.Collapse eventKey="0">
                <Card.Body>
                    <Container className="p-0 mt-3" fluid>
                        <Row>
                            <div>
                                <Form noValidate validated={validated} onSubmit={handleSubmit(onSubmit)}>
                                    <Row style={{ border: emptyUserTable === true ? "1px solid red" : '' }}>
                                        <SuppliersTableUsers
                                            isEdit={false}
                                            infoEdit={infoEdit}
                                            actionTableUser={handleObjectUser}
                                        />
                                    </Row>
                                    {
                                        emptyUserTable === true
                                            ?
                                            <div style={{ color: "red", textAlign: "center" }}>
                                                Favor vincular algum usuário
                                            </div>
                                            :
                                            ''
                                    }
                                    <Row className="d-flex justify-content-end mx-0 mt-5">
                                        {
                                            isEdit === false
                                                ?
                                                <Button
                                                    type="button"
                                                    className="form-button back-btn btn btn-outline-primary"
                                                    variant='outline'
                                                    onClick={() => {
                                                        setStepCtrl(3)
                                                        scrollTop()
                                                    }}
                                                >
                                                    voltar
                                                </Button>
                                                :
                                                <Button
                                                    className="form-button cancel"
                                                    onClick={handleCancel}
                                                >
                                                    Cancelar
                                                </Button>
                                        }
                                        <Button
                                            type="submit"
                                            className="form-button"
                                            onClick={scrollTop}
                                        >
                                            {t('accordionInfoCompany.buttonContinue')}
                                        </Button>
                                    </Row>
                                </Form>
                            </div>
                        </Row>
                    </Container>
                </Card.Body>
            </Accordion.Collapse>
        </Card>
    )
}

export default AccordionUsers;