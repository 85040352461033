import React, { useEffect, useState, Key } from 'react';
import api from '../../../../services/api';

import { Container, Row, Card, Col, Modal } from 'react-bootstrap';
import Accordion from 'react-bootstrap/Accordion';
import Form from 'react-bootstrap/Form';
import { Controller, useForm } from "react-hook-form";
import { ErrorMessage } from '@hookform/error-message';

import ImageDefault from "../../../../../src/assets/img/image-default.png";
// import ModalConfirm from '../../../../components/Modal/ModalConfirm/ModalConfirm';
import '../../../../assets/sass/accordion.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { VerticalTimeline, VerticalTimelineElement } from 'react-vertical-timeline-component';
import 'react-vertical-timeline-component/style.min.css';
import { useTranslation } from 'react-i18next';

export interface propInfo {
    userInfo: any,
    setIsEdit: any,
    userLevel?: any,
}

const SingleUser: React.FC<propInfo> = ({
    userInfo, setIsEdit, userLevel
}: propInfo) => {
    const { t } = useTranslation();
    const [profileImg, setProfileImg] = useState<any>(null);
    const [allowChengePassword, setAllowChangePassword] = useState<any>(window.location.href.split('&editPassword=')[1] === 'true' ? true : false);
    const [modalMessage, setModalMessage] = useState<any>(`${t('editUser.editUser.singleUser.fillInTheFields')}`);
    const [openLog, setOpenLog] = useState<boolean>(false);
    const [modalShow, setModalShow] = useState<any>(false);
    const [modalLog, setModalLog] = useState<any>(null);
    const [passwordStr, setPasswordStr] = useState<any>(0);
    const [passwordStrColor, setPasswordStrColor] = useState<any>('#ccc');
    const [, setPasswordStrText] = useState<any>('');
    const [, setPasswordValidation] = useState<boolean>(false);

    var regex = /^(?=.*[a-z]{1})(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/;
    var regexLetter = /^(?=.*[A-Za-z]{1})/;
    var regexNumber = /^(?=.*\d)/;
    var regexSymble = /^(?=.*[@$!%*#?&])/;

    useEffect(() => {
        setProfileImg(userInfo?.photoUrl);
    }, [userInfo])

    const {
        control,
        handleSubmit,
        formState: { errors },
    } = useForm();

    const imageHandler = (e: any) => {
        const reader = new FileReader();
        reader.onload = () => {
            if (reader.readyState === 2) {
                setProfileImg(reader.result)
            }
        }
        reader.readAsDataURL(e.target.files[0]);
        //setProfileNameImg(e.target.files[0].name);
    }

    function convertDate(cell: any) {
        if (cell !== null && cell !== undefined) {
            var aux: any = cell.split("T")[0].split("-");
            var date: any = `${aux[2]}/${aux[1]}/${aux[0]}`;
            return (
                <>
                    {date}
                </>
            );
        } else {
            return (
                <>
                </>
            );
        }
    }

    function cpfMask(strCpf: string = '') {
        var mascara: any;

        mascara = strCpf.replace(/\D/g, '').match(/(\d{0,3})(\d{0,3})(\d{0,3})(\d{0,4})(\d{0,2})/);
        strCpf = !mascara[2] ? mascara[1] : mascara[1] + '.' + mascara[2] + '.' + mascara[3] + '-' + mascara[4] + (mascara[5] ? '-' + mascara[5] : '');

        return (strCpf);
    }

    function Validation() {
        var strength: any = 0;
        var password = (document.getElementById('password') as HTMLInputElement)?.value;
        console.log(password)

        if (regexNumber.exec(password)) {
            strength += 1;
        }

        if (regexSymble.exec(password)) {
            strength += 1;
        }

        if (regexLetter.exec(password)) {
            strength += 1;
        }

        if (!regex.exec(password)) {
            setPasswordValidation(false);
        } else {
            strength = 4;
            setPasswordValidation(true);
        }

        if (strength === 0) {
            setPasswordStrColor('#ccc');
            setPasswordStrText('');
        } else if (strength === 1) {
            setPasswordStrColor('red');
            setPasswordStrText(`${t('editUser.editUser.singleUser.weakPassword')}`);
        } else if (strength === 2 || strength === 3) {
            setPasswordStrColor('#e0e00d');
            setPasswordStrText(`${t('editUser.editUser.singleUser.mediumPassword')}`);
        } else {
            setPasswordStrColor('green');
            setPasswordStrText(`${t('editUser.editUser.singleUser.strongPassword')}`);
        }

        setPasswordStr(strength);

        return true;

    }

    function onSubmit(data: any) {
        setModalShow(true);
        setModalMessage(`${t('editUser.editUser.singleUser.loading')}`);

        if (data.password !== data.confirmPassword) {
            setModalLog(1);
            setModalMessage(`${t('editUser.editUser.singleUser.diferentPasswords')}`);

            return;
        }

        if (!regex.exec(data.password)) {
            setModalLog(1);
            setModalMessage(`${t('editUser.editUser.singleUser.passwordRuleError')}`);

            return;
        }

        const updatePassword = async () => {
            const token = localStorage.getItem('GroupId') || '{}';
            const config = {
                headers: { 'Authorization': `Bearer ${token}`, 'Content-Type': 'application/json' },
            };
            try {
                const res = await api.post(`/UserPermission/UpdatePassword`,
                    {
                        "oldPassword": data.actualPassword,
                        "password": data.password,
                    }, config);
                if (res.status !== 400) {
                    setModalLog(0);
                    setModalMessage(res.data.data.texto);
                    setModalLog(res.data.data.log);
                } else {
                    setModalLog(1);
                    setModalMessage(res.data.data.texto);
                    setModalLog(res.data.data.log);
                }
            } catch (error: any) {
                setModalLog(1);
                setModalMessage(`${t('editUser.editUser.singleUser.processError')}`);
            }
        };
        updatePassword();
    }

    console.log(userInfo)

    if (allowChengePassword === false) {
        return (
            <>
                <Card className="bg-white my-4">
                    <Accordion >
                        <Card.Body>
                            <Container className="p-0 mt-3 edit-page" fluid>
                                <div className="mb-3 d-flex justify-content-between w-100">
                                    <div className="label" style={{ width: "unset" }}>
                                        <label className="image-upload" htmlFor=''>
                                            <h5 className="text-center">{t('editUser.editUser.singleUser.profilePicture')}</h5>
                                            {profileImg !== null ?
                                                <div className="logo-user mx-3 mb-2 mt-2"
                                                    style={{ backgroundImage: `url(${profileImg})`, backgroundSize: 'cover', cursor: '' }}>
                                                </div>
                                                :
                                                <div className="logo-user mx-3 mb-2 mt-2"
                                                    style={{ backgroundImage: `url(${ImageDefault})`, backgroundSize: 'cover', cursor: '' }}>
                                                </div>
                                            }
                                        </label>
                                        <input
                                            type="file"
                                            accept="image/*"
                                            name="photoUrl"
                                            id="input"
                                            autoComplete='off'
                                            onChange={imageHandler}
                                        />
                                    </div>
                                    <div className="d-flex justify-content-end">
                                        <div style={{ width: "unset" }}>
                                            <button onClick={() => { setOpenLog(true) }} className='btn-default mr-2 text-uppercase btn btn-outline-primary'>{t('editUser.editUser.singleUser.log')}</button>
                                        </div>
                                        <div style={{ width: "unset" }}>
                                            <button onClick={() => { setIsEdit(true) }} className='btn-default mr-2 text-uppercase btn btn-outline-primary'>{t('editUser.editUser.singleUser.edit')}</button>
                                        </div>
                                    </div>
                                </div>

                                <Row className="mb-1">
                                    <div className="data">
                                        <div className="data-unit">
                                            <p>{t('editUser.editUser.singleUser.name')}</p>
                                            <p>{userInfo?.firstName}</p>
                                        </div>
                                        <div className="data-unit">

                                            <p>{t('editUser.editUser.singleUser.lastName')}</p>
                                            <p>{userInfo?.lastName}</p>
                                        </div>
                                        <div className="data-unit">
                                            <p>{t('editUser.editUser.singleUser.email')}</p>
                                            <p>{userInfo?.email}</p>
                                        </div>
                                    </div>
                                </Row>

                                <Row className="mb-3">
                                    <div className="data">
                                        <div className="data-unit">
                                            <p>{t('editUser.editUser.singleUser.birthday')}</p>
                                            <p>{convertDate(userInfo?.birthdayDate)}</p>
                                        </div>
                                        <div className="data-unit">
                                            <p>{t('editUser.editUser.singleUser.telephone')}</p>
                                            <p>{userInfo?.phone}</p>
                                        </div>
                                        <div className="data-unit">
                                            <p>{t('editUser.editUser.singleUser.brazilian')}</p>
                                            <p>{userInfo?.isForeign === 2 ? `${t('editUser.editUser.singleUser.yes')}` : `${t('editUser.editUser.singleUser.not')}`}</p>
                                        </div>
                                    </div>
                                </Row>

                                <Row className="mb-3">
                                    <div className="data">
                                        {userInfo?.isForeign === 1
                                            ?
                                            <div className="data-unit">
                                                <p>{t('editUser.editUser.singleUser.passport')}</p>
                                                <p>{userInfo?.passport}</p>
                                            </div>
                                            :
                                            <div className="data-unit">
                                                <p>{t('editUser.editUser.singleUser.cpf')}</p>
                                                <p>{cpfMask(userInfo?.cpf)}</p>
                                            </div>
                                        }
                                        <div className="data-unit">
                                            <p>{t('editUser.editUser.singleUser.level')}</p>
                                            <p>{userInfo?.level}</p>
                                        </div>
                                        <div className="data-unit">
                                            <p>{t('editUser.editUser.singleUser.status')}</p>
                                            <p>{userInfo?.status}</p>
                                        </div>
                                    </div>

                                </Row>

                                {/* <Row className="mb-3">
                                    <div className="data">
                                        <button className="btn-default mr-2 text-uppercase btn btn-outline-primary" onClick={() => { setAllowChangePassword(true) }}>Redefinir Senha</button>
                                    </div>
                                </Row> */}
                            </Container>
                        </Card.Body>
                    </Accordion>
                </Card>
                {/* MODAL */}
                <Modal
                    className="modal-custom reservation-logs users-log-modal"
                    show={openLog}
                    onHide={() => { setOpenLog(false) }}
                    size="lg"
                    aria-labelledby="contained-modal-title-vcenter"
                >
                    <div className="modal-body d-flex flex-column align-items-center justify-content-evenly">
                        <div className="modal-header rounded-0">
                            <div>
                                <h4 className="pt-1 text-primary title-reservation">{t('editUser.editUser.singleUser.operationHistoric')}</h4>
                            </div>

                            <div className='buttons-options'>
                                <div>
                                    <FontAwesomeIcon
                                        icon={['fal', 'times']}
                                        className="mx-3 text-info"
                                        size="lg"
                                        data-dismiss="modal"
                                        aria-label="Close"
                                        onClick={() => { setOpenLog(false); }}
                                    />
                                </div>
                            </div>
                        </div>
                        <hr className='w-100 my-4' style={{ borderBottom: '1px solid' }} />
                        <div className="w-100">
                            <VerticalTimeline
                                layout={'1-column-left'}
                                lineColor={'#076fa2'}
                            >
                                {userInfo?.userLogDTO.length > 0 ?
                                    userInfo.userLogDTO.map((elem: any, index: Key) => {
                                        return (
                                            <>
                                                <VerticalTimelineElement
                                                    className="vertical-timeline-element--work mb-40px"
                                                    iconStyle={{ background: '#076fa2', color: '#fff', boxShadow: 'none' }}
                                                    contentStyle={{ background: '#F2F2F2', borderRadius: '15px' }}
                                                    contentArrowStyle={{ borderRight: '7px solid #F2F2F2' }}
                                                    icon={<FontAwesomeIcon
                                                        icon={["fal", "file-alt"]}
                                                        className="w-100"
                                                        style={{ marginLeft: '-20px' }}
                                                    />}
                                                    key={index}
                                                >
                                                    <>
                                                        <div className="row pt-3 mb-20px">
                                                            <div className="col-6">
                                                                <p className="p-0 mb-10px text-primary"><strong>{t('editUser.editUser.singleUser.user')}</strong></p>
                                                                <p className='m-0'>{elem.userName}</p>
                                                            </div>
                                                            <div className="col-6">
                                                                <p className="p-0 mb-10px text-primary"><strong>{t('editUser.editUser.singleUser.activityDate')}</strong></p>
                                                                <p className='m-0'>{elem.date.split('T').join(' - ')}</p>
                                                            </div>

                                                        </div>
                                                        <div className="row">
                                                            <div className="col-12 mb-20px">
                                                                <p className="p-0 mb-10px text-primary"><strong>{t('editUser.editUser.singleUser.action')}</strong></p>
                                                                <p className='m-0'>{elem.action}</p>
                                                            </div>
                                                        </div>
                                                    </>

                                                </VerticalTimelineElement>
                                            </>
                                        )
                                    })
                                    :
                                    <>
                                        <div className="d-flex justify-content-center text-center">
                                            <h5>{t('editUser.editUser.singleUser.noActivitiesFound')}</h5>
                                        </div>
                                    </>
                                }
                            </VerticalTimeline>
                        </div>
                    </div>
                </Modal>
            </>
        );
    } else {
        return (
            <>
                <Card className="bg-white mt-4">
                    <Accordion >
                        <Card.Body>
                            <Container className="p-0 mt-3 edit-page" fluid>

                                <div className="mb-3 d-flex justify-content-between w-100">
                                    <Form noValidate validated={false} onSubmit={handleSubmit(onSubmit)} className="w-100">
                                        <Row>
                                            <div>
                                                <h3 className="mb-4">{t('editUser.editUser.singleUser.changePassword')}</h3>
                                            </div>
                                        </Row>
                                        <Row className="mb-3">
                                            <Form.Group as={Col} md="4" className="mb-3" controlId="">
                                                <Form.Label>
                                                {t('editUser.editUser.singleUser.currentPassword')}
                                                </Form.Label>
                                                <Controller
                                                    control={control}
                                                    name="actualPassword"
                                                    rules={{ required: { value: true, message: `${t('editUser.editUser.singleUser.currentPasswordError')}` } }}
                                                    render={({ field }: any) => (
                                                        <Form.Control
                                                            {...field}
                                                            type="password"
                                                            variant="standard"
                                                            margin="normal"
                                                            required
                                                            autoComplete='off'
                                                        />
                                                    )}
                                                />
                                                <ErrorMessage
                                                    errors={errors}
                                                    name="actualPassword"
                                                    render={({ message }) => <div style={{ width: "100%", backgroundColor: "#f8d7da", padding: "0 0.75rem" }}><small style={{ color: "red" }}>{message}</small></div>}
                                                />
                                            </Form.Group>
                                        </Row>
                                        <Row>
                                            <Form.Group as={Col} md="4" className="mb-3" controlId="password">
                                                <Form.Label>
                                                {t('editUser.editUser.singleUser.newPassword')}
                                                </Form.Label>
                                                <Controller
                                                    control={control}
                                                    name="password"
                                                    rules={{ required: { value: true, message: `${t('editUser.editUser.singleUser.newPasswordError')}` } }}
                                                    render={({ field }: any) => (
                                                        <Form.Control
                                                            {...field}
                                                            type="password"
                                                            variant="standard"
                                                            margin="normal"
                                                            required
                                                            autoComplete='off'
                                                            onInput={() => Validation()}
                                                        />
                                                    )}
                                                />
                                                <ErrorMessage
                                                    errors={errors}
                                                    name="password"
                                                    render={({ message }) => <div style={{ width: "100%", backgroundColor: "#f8d7da", padding: "0 0.75rem" }}><small style={{ color: "red" }}>{message}</small></div>}
                                                />
                                                <div className="password-strength-container row d-flex justify-content-evenly">
                                                    <div className="password-strength col-3" style={{ borderColor: `${passwordStr >= 1 ? passwordStrColor : ''}`, backgroundColor: `${passwordStr >= 1 ? passwordStrColor : ''}` }}></div>
                                                    <div className="password-strength col-3" style={{ borderColor: `${passwordStr >= 2 ? passwordStrColor : ''}`, backgroundColor: `${passwordStr >= 2 ? passwordStrColor : ''}` }}></div>
                                                    <div className="password-strength col-3" style={{ borderColor: `${passwordStr >= 3 ? passwordStrColor : ''}`, backgroundColor: `${passwordStr >= 3 ? passwordStrColor : ''}` }}></div>
                                                    <div className="password-strength col-3" style={{ borderColor: `${passwordStr >= 4 ? passwordStrColor : ''}`, backgroundColor: `${passwordStr >= 4 ? passwordStrColor : ''}` }}></div>
                                                </div>
                                                {/* <div className="d-flex justify-content-center" style={{ color: `${passwordStrColor}` }}>
                                                {passwordStrText}
                                            </div> */}
                                            </Form.Group>
                                            <Form.Group as={Col} md="4" className="mb-3" controlId="">
                                                <Form.Label>
                                                {t('editUser.editUser.singleUser.confirmPassword')}
                                                </Form.Label>
                                                <Controller
                                                    control={control}
                                                    name="confirmPassword"
                                                    rules={{ required: { value: true, message: `${t('editUser.editUser.singleUser.confirmPasswordError')}` } }}
                                                    render={({ field }: any) => (
                                                        <Form.Control
                                                            {...field}
                                                            type="password"
                                                            variant="standard"
                                                            margin="normal"
                                                            required
                                                            autoComplete='off'
                                                        />
                                                    )}
                                                />
                                                <ErrorMessage
                                                    errors={errors}
                                                    name="confirmPassword"
                                                    render={({ message }) => <div style={{ width: "100%", backgroundColor: "#f8d7da", padding: "0 0.75rem" }}><small style={{ color: "red" }}>{message}</small></div>}
                                                />
                                            </Form.Group>
                                            {/* <Form.Group as={Col} md="12" controlId="">
                                                <small>Use oito ou mais caracteres com uma combinação de letras, números e símbolos</small>
                                            </Form.Group> */}
                                        </Row>
                                        <small style={{ fontSize: ".8rem", opacity: ".7" }}>{t('editUser.editUser.singleUser.passwordRule')}</small>
                                        <Row className="d-flex justify-content-end mx-0 mt-5 mb-4">
                                            <div className="data" style={{ width: 'unset' }}>
                                                <div className="btn-default mr-2 text-uppercase btn btn-outline-primary" onClick={() => { setAllowChangePassword(false) }}>{t('editUser.editUser.singleUser.cancel')}</div>
                                            </div>
                                            <div className="d-flex justify-content-end" style={{ width: "unset" }} /* onClick={() => setModalShow(true)} */>
                                                <button className="btn btn-default btn-primary px-4 text-uppercase " type="submit">
                                                {t('editUser.editUser.singleUser.save')}
                                                </button>
                                            </div>
                                            {/* <ModalConfirm
                                            button="Salvar"
                                            confirm={responseText}
                                            modalContent={modalResponse}
                                            log={log}
                                        /> */}
                                        </Row>
                                    </Form>

                                    <Modal
                                        className="modalAuth"
                                        show={modalShow}
                                        onHide={() => {
                                            setModalLog(null);
                                            setModalMessage(`${t('editUser.editUser.singleUser.loading')}`);
                                            setModalShow(false);
                                        }}
                                        aria-labelledby="contained-modal-title-vcenter"
                                    >
                                        <>
                                            <Modal.Body
                                                className="modal-body text-center sucess-pos d-flex justify-content-evenly"
                                                style={{ height: "600px" }}
                                            >
                                                {modalLog === null ? (
                                                    <>
                                                        <div className="loading-modal">
                                                            <div className="load"></div>
                                                        </div>
                                                    </>
                                                ) : (
                                                    <div>
                                                        {modalLog === 0 ? (
                                                            <FontAwesomeIcon
                                                                icon={["fal", "check-circle"]}
                                                                size="5x"
                                                                className="text-primary"
                                                                style={{
                                                                    fontSize: "7.5em"
                                                                }}
                                                            />
                                                        ) : (
                                                            <FontAwesomeIcon
                                                                icon={["fal", "times-circle"]}
                                                                size="5x"
                                                                className="text-primary"
                                                                style={{
                                                                    fontSize: "7.5em"
                                                                }}
                                                            />
                                                        )}
                                                    </div>
                                                )}
                                                <div>{modalMessage}</div>
                                                <div className="d-flex justify-content-center pt-3">
                                                    <button
                                                        onClick={() => {
                                                            setModalLog(null);
                                                            setModalMessage(`${t('editUser.editUser.singleUser.loading')}`);
                                                            setModalShow(false);
                                                        }}
                                                        className="btn btn-primary mx-2 w-25"
                                                    >
                                                        {t('editUser.editUser.singleUser.confirm')}
                                                    </button>
                                                </div>
                                            </Modal.Body>
                                        </>
                                    </Modal>
                                </div>
                            </Container>
                        </Card.Body>
                    </Accordion>
                </Card>
            </>
        )
    }
}

export default SingleUser;