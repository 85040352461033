import React, { useState } from "react";

import { Button, Card, Col, Form, Row } from "react-bootstrap";
import { Controller, useForm } from "react-hook-form";
import { ErrorMessage } from "@hookform/error-message";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import api from "../../../../services/api";
import GetProductsListSubCategoryMult from "../../../../components/C2Points/GetProductsListSubCategoryMult";

import { useAppSelector, useAppDispatch } from '../../../../store/hooks';
import { addToComboObject } from "../../../../store/combo";

export interface productTour {
    action: any,
    isEdit: any,
    editFunction?: any;
};

const DataTour: React.FC<productTour> = React.memo(({
    action, isEdit, editFunction
}: productTour) => {

    /* Controle Redux  */
    const dispatch = useAppDispatch();
    const reduxComboObject: any = JSON.parse(JSON.stringify(useAppSelector((state: any) => state.combo))).combo;
    /* Controle Redux  */

    const [editName, setEditName] = useState<boolean>(true);

    const {
        control,
        handleSubmit,
        setValue,
        formState: { errors },
    } = useForm({});

    const handleBlurItems = (e: any) => {
        if (editName === true) {
            TranslateTextItemsEN(e.target.value);
            TranslateTextItemsES(e.target.value);
        }
    };

    const TranslateTextItemsEN = async (name: string) => {
        if (name !== undefined && name !== "") {
            try {
                const res = await api.post(`https://translation.googleapis.com/language/translate/v2?key=${process.env.REACT_APP_GOOGLE_CLOUD_TRANSLATE_API}`, {
                    "q": name,
                    "source": "pt-BR",
                    "target": "en",
                    "format": "text"
                });
                if (res.status !== 400) {
                    setValue('name[1].text', res?.data?.data?.translations[0]?.translatedText);
                }
            } catch (error: any) { }
        } else {
            setValue(`name[1].text`, "");
        }
    };

    const TranslateTextItemsES = async (name: string) => {
        if (name !== undefined && name !== "") {
            try {
                const res = await api.post(`https://translation.googleapis.com/language/translate/v2?key=${process.env.REACT_APP_GOOGLE_CLOUD_TRANSLATE_API}`, {
                    "q": name,
                    "source": "pt-BR",
                    "target": "es",
                    "format": "text"
                });
                if (res.status !== 400) {
                    setValue('name[2].text', res?.data?.data?.translations[0]?.translatedText);
                }
            } catch (error: any) { }
        } else {
            setValue(`name[2].text`, "");
        }
    };

    const onSubmit = (data: any) => {
        const comboCode = isEdit ? window.location.href.split('comboCode=')[1] : undefined;

        let dataToInsert: any = data;

        dataToInsert.subCategory = data.subcategory.map((elem: any) => { return { idSubCategory: elem.value, comboCode: comboCode } });
        delete data.subcategory

        // forçando no objeto v
        dataToInsert.isAccessibilityNeeded = 2;
        dataToInsert.accessibilities = [];

        if (isEdit) {
            editFunction(dataToInsert);
        } else {
            dispatch(addToComboObject(JSON.stringify(dataToInsert)));
            action("operation");
        }
    }

    return (
        <>
            {/* CONTENT */}
            {
                (isEdit === false || reduxComboObject?.name) ?
                    <Col md={9} lg={10}>
                        <Card className="pt-content">
                            <Form className="h-100" noValidate validated={false} onSubmit={handleSubmit(onSubmit)}>
                                <h3 className="text-primary pt-title mb-0">{`Dados do Combo`}</h3>

                                <div className="pt-content-info">
                                    <Row>
                                        <div className="d-flex justify-content-between">
                                            <h5 className="mb-0 pt-subtitle">Nome do Combo</h5>
                                            <div className="btn-edit" onClick={() => setEditName(!editName)}>
                                                <FontAwesomeIcon
                                                    icon={["fal", "edit"]}
                                                    size="1x"
                                                    className="mr-2"
                                                />
                                                Editar
                                            </div>
                                        </div>

                                        <Form.Group as={Col} md="12" controlId="" className="mt-4">
                                            <Form.Label className="label-small">
                                                Nome em Português
                                            </Form.Label>

                                            <Controller
                                                control={control}
                                                name={`name[0].text`}
                                                defaultValue={reduxComboObject?.name && reduxComboObject.name[0].text}
                                                rules={{
                                                    required: {
                                                        value: true,
                                                        message:
                                                            'Por favor, informe o nome do combo',
                                                    },
                                                    minLength: {
                                                        value: 4,
                                                        message: `Por favor, insira ao menos 4 caracteres`,
                                                    },
                                                    maxLength: {
                                                        value: 134,
                                                        message: `Por favor, insira no máximo 134 caracteres`,
                                                    },
                                                }}
                                                render={({ field }: any) => (
                                                    <Form.Control
                                                        {...field}
                                                        type="text"
                                                        variant="standard"
                                                        margin="normal"
                                                        onBlur={(e: any) => {
                                                            handleBlurItems(e);
                                                        }}
                                                        maxLength={134}
                                                        required
                                                        autoComplete='off'
                                                    />
                                                )}
                                            />
                                            <ErrorMessage
                                                errors={errors}
                                                name={`name[0].text`}
                                                render={({ message }) => <small style={{ color: "red" }}>{message}</small>}
                                            />
                                        </Form.Group>
                                        <Controller
                                            control={control}
                                            name={`name[0].lang`}
                                            defaultValue={1}
                                            render={({ field }: any) => (
                                                <Form.Control
                                                    {...field}
                                                    type="hidden"
                                                    variant="standard"
                                                    margin="normal"
                                                    autoComplete="off"
                                                />
                                            )}
                                        />

                                        <Form.Group as={Col} md="6" controlId="" className="mt-4">
                                            <Form.Label className="label-small">
                                                Nome em Inglês
                                            </Form.Label>
                                            <Controller
                                                control={control}
                                                name={`name[1].text`}
                                                defaultValue={reduxComboObject?.name && reduxComboObject.name[1].text}
                                                rules={{
                                                    required: {
                                                        value: true,
                                                        message:
                                                            'Por favor, informe o nome do combo',
                                                    },
                                                    minLength: {
                                                        value: 4,
                                                        message: `Por favor, insira ao menos 4 caracteres`,
                                                    },
                                                    maxLength: {
                                                        value: 134,
                                                        message: `Por favor, insira no máximo 134 caracteres`,
                                                    },
                                                }}
                                                render={({ field }: any) => (
                                                    <Form.Control
                                                        {...field}
                                                        type="text"
                                                        variant="standard"
                                                        margin="normal"
                                                        disabled={editName === true}
                                                        maxLength={134}
                                                        autoComplete='off'
                                                    />
                                                )}
                                            />
                                            <ErrorMessage
                                                errors={errors}
                                                name={`name[1].text`}
                                                render={({ message }) => <small style={{ color: "red" }}>{message}</small>}
                                            />
                                        </Form.Group>
                                        <Controller
                                            control={control}
                                            name={`name[1].lang`}
                                            defaultValue={2}
                                            render={({ field }: any) => (
                                                <Form.Control
                                                    {...field}
                                                    type="hidden"
                                                    variant="standard"
                                                    margin="normal"
                                                    autoComplete="off"
                                                />
                                            )}
                                        />

                                        <Form.Group as={Col} md="6" controlId="" className="mt-4">
                                            <Form.Label className="label-small">
                                                Nome em Espanhol
                                            </Form.Label>

                                            <Controller
                                                control={control}
                                                name={`name[2].text`}
                                                defaultValue={reduxComboObject?.name && reduxComboObject.name[2].text}
                                                rules={{
                                                    required: {
                                                        value: true,
                                                        message:
                                                            'Por favor, informe o nome do combo',
                                                    },
                                                    minLength: {
                                                        value: 4,
                                                        message: `Por favor, insira ao menos 4 caracteres`,
                                                    },
                                                    maxLength: {
                                                        value: 134,
                                                        message: `Por favor, insira no máximo 134 caracteres`,
                                                    },
                                                }}
                                                render={({ field }: any) => (
                                                    <Form.Control
                                                        {...field}
                                                        type="text"
                                                        variant="standard"
                                                        margin="normal"
                                                        disabled={editName === true}
                                                        maxLength={134}
                                                        autoComplete='off'
                                                    />
                                                )}
                                            />
                                            <ErrorMessage
                                                errors={errors}
                                                name={`name[2].text`}
                                                render={({ message }) => <small style={{ color: "red" }}>{message}</small>}
                                            />
                                        </Form.Group>
                                        <Controller
                                            control={control}
                                            name={`name[2].lang`}
                                            defaultValue={3}
                                            render={({ field }: any) => (
                                                <Form.Control
                                                    {...field}
                                                    type="hidden"
                                                    variant="standard"
                                                    margin="normal"
                                                    autoComplete="off"
                                                />
                                            )}
                                        />
                                    </Row>
                                </div>

                                <div className="pt-content-info pt-2" style={{ border: "none" }}>
                                    <Row>
                                        <h5 className="mt-3 mb-0 pt-subtitle">Subcategorias</h5>
                                        <Form.Group as={Col} md="6" controlId="" className="mt-4">
                                            <Form.Label className="label-small">
                                                Escolha as suas subcategorias onde seu produto se encaixa
                                            </Form.Label>
                                            <Controller
                                                control={control}
                                                name="subcategory"
                                                rules={{ required: { value: true, message: 'Por favor, informe as subcategorias' } }}
                                                render={({ field }: any) => (
                                                    <GetProductsListSubCategoryMult
                                                        propsField={field}
                                                        propsLabel={''}
                                                        propsErrors={errors}
                                                        setValue={setValue}
                                                        infoEdit={reduxComboObject?.subCategory && reduxComboObject.subCategory.map((elem: any) => { return { productCategoryId: elem.idSubCategory } })}
                                                    />
                                                )}
                                            />
                                            <ErrorMessage
                                                errors={errors}
                                                name="subcategory"
                                                render={({ message }) => <small style={{ color: "red" }}>{message}</small>}
                                            />
                                        </Form.Group>
                                    </Row>
                                </div>


                                <div className="h-100 d-flex justify-content-end align-items-end">

                                    <Row className="mx-0 mt-5">
                                        <Button
                                            type="submit"
                                            className="form-button"
                                        >
                                            {isEdit !== true ? "Continuar" : "Salvar"}
                                        </Button>
                                    </Row>

                                </div>
                            </Form>
                        </Card>
                    </Col >
                    :
                    <>
                        <Col md={9} lg={10}>
                            <Card className="pt-content h-100">
                                <div className="loading-modal h-100 d-flex flex-column justify-content-center align-items-center">
                                    <div className="load mb-4"></div>
                                    <div>Carregando dados do produto</div>
                                </div>
                            </Card>
                        </Col>
                    </>
            }
        </>
    )
});

export default DataTour;