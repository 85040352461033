import React, { useState, useEffect } from 'react';
import api from '../../services/api';

import DesktopDefault from '../../templates/DesktopDefault';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import Breadcrumb from '../../components/Breadcrumb/Breadcrumb';
import FilterBillsPay from '../BillsToPay/components/FilterBills/FilterBillsPay';
import TableSettleBills from './components/TableSettleBills/TableSettleBills';

import './SettleBillsToPay.scss';
import { useTranslation } from 'react-i18next';

function BillsToPay() {
    const { t } = useTranslation();

    const [billsPay, setBillsPay] = useState<any>(null);
    const [parameters, setParameters] = useState<any>({});
    const [error, setError] = useState<any>();
    const [loading, setLoading] = useState<any>(false);
    const [allowLoadFromFilter, setAllowLoadFromFilter] = useState<any>(false);

    /* Filtragem */
    let parcialObj: any = JSON.parse(localStorage.getItem('parcialObj') || '{}');

    const [actualLocal, setActualLocal] = useState<any>(parcialObj?.local || '');
    const [actualStatus, setActualStatus] = useState<any>(parcialObj?.status || null);
    const [actualType, setActualType] = useState<any>(parcialObj?.type || null);
    const [actualSupplier, setActualSupplier] = useState<any>(parcialObj?.supplier || '');
    const [actualSupplierType, setActualSupplierType] = useState<any>(parcialObj?.supplierType || '');
    const [actualTypeDate, setActualTypeDate] = useState<any>(parcialObj?.typeDate || 3);
    const [actualDate, setActualDate] = useState<any>(parcialObj?.date || []);
    const [actualEmpresa, setActualEmpresa] = useState<any>(parcialObj?.empresa || null);

    localStorage.removeItem('parcialObj');
    /* END - Filtragem */

    /* Pagination */
    const [totalRows, setTotalRows] = useState<any>();
    const [pageCount, setPageCount] = useState<any>(1);
    const [rowsPerPage, setRowsPerPage] = useState<any>(10);
    /* END - Pagination */

    /* useEffect(()=>{
        let parcialObj: any = JSON.parse(localStorage.getItem('parcialObj') || '{}');
        if(parcialObj !== '{}'){
            setActualLocal(parcialObj.local);
            setActualStatus(parcialObj.status);
            setActualType(parcialObj.type);
            setActualSupplier(parcialObj.supplier);
            setActualTypeDate(parcialObj.typeDate);
            setActualDate(parcialObj.date);
            
            console.log('parcialObj')
            buscar(parcialObj.local, parcialObj.status, parcialObj.type, parcialObj.supplier, parcialObj.typeDate, parcialObj.date);
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[]) */

    function clearActualValues() {
        setActualLocal("");
        setActualStatus(null);
        setActualType(null);
        setActualSupplier("");
        setActualSupplierType("");
        setActualTypeDate(3);
        setActualDate([]);
        setActualEmpresa(null);
    }

    useEffect(() => {
        
        let aa: any = JSON.parse(localStorage.getItem('parcialObj') || '{}');

        console.log('pato',aa);
        setLoading(true);

        let body: any = document.querySelector("body");
        if (body.className !== "modal-open") {
            setBillsPay(null);
        }

        let beginDate: any = []
        let endDate: any = []

        if (actualDate.length === 1) {
            beginDate = actualDate[0].split('/')
        } else if (actualDate.length === 2) {
            beginDate = actualDate[0].split('/')
            endDate = actualDate[1].split('/')
        }

        let today: any = new Date();

        async function billTopPay() {
            const token = localStorage.getItem('GroupId') || '{}';
            const config = {
                headers: { 'Authorization': `Bearer ${token}`, 'Content-Type': 'application/json' },
            };
            try {
                const { data } = await api.post('/BillsPayable/GetReport',
                    {
                        "page": pageCount,
                        "rowsPerPage": rowsPerPage,
                        "item": actualLocal,
                        "statusPagamento": actualStatus,
                        "fornecedor": actualSupplier,
                        "supplierType": actualSupplierType ? actualSupplierType : null,
                        "tipoConta": actualType,
                        "tipoData": actualTypeDate,
                        "empresa": actualEmpresa,
                        "dataInicial": (actualDate.length > 0) ? `${beginDate[2]}-${beginDate[1]}-${beginDate[0]}` : `${today.getFullYear()}-${today.getMonth() + 1}-${today.getDate()}`,
                        "dataFinal": (actualDate.length === 1) ? `${beginDate[2]}-${beginDate[1]}-${parseInt(beginDate[0]) + 1}` : (actualDate !== null && actualDate.length === 2) ? `${endDate[2]}-${endDate[1]}-${endDate[0]}` : `${today.getFullYear()}-${today.getMonth() + 1}-${today.getDate()}`
                    }, config
                );
                setLoading(false);
                if (data.status !== 400) {
                    setParameters({
                        "page": pageCount,
                        "rowsPerPage": rowsPerPage,
                        "item": actualLocal,
                        "statusPagamento": actualStatus,
                        "fornecedor": actualSupplier,
                        "supplierType": actualSupplierType ? actualSupplierType : null,
                        "tipoConta": actualType,
                        "tipoData": actualTypeDate,
                        "empresa": actualEmpresa,
                        "dataInicial": (actualDate.length > 0) ? `${beginDate[2]}-${beginDate[1]}-${beginDate[0]}` : `${today.getFullYear()}-${today.getMonth() + 1}-${today.getDate()}`,
                        "dataFinal": (actualDate.length === 1) ? `${beginDate[2]}-${beginDate[1]}-${parseInt(beginDate[0])}` : (actualDate !== null && actualDate.length === 2) ? `${endDate[2]}-${endDate[1]}-${endDate[0]}` : `${today.getFullYear()}-${today.getMonth() + 1}-${today.getDate()}`
                    });
                    setBillsPay(data.data.rows);
                    setTotalRows(data.data.rowsCount);
                }
            } catch (error: any) {
                if (error.response.status === 401) {
                    window.location.href = window.location.origin + '/';
                }
            }
        }
        billTopPay();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [pageCount, rowsPerPage/* , actualStatus */]);

    async function buscar(local: any, status: any, type: any, supplier: any, supplierType: any, typeDate: any, date: any, empresa: any) {
        setAllowLoadFromFilter(true);
        setActualLocal(local);
        setActualStatus(status);
        setActualType(type);
        setActualSupplier(supplier);
        setActualSupplierType(supplierType);
        setActualTypeDate(typeDate);
        setActualDate(date);
        setActualEmpresa(empresa);
        setPageCount(1);

        setLoading(true);

        let body: any = document.querySelector("body");
        if (body.className !== "modal-open") {
            setBillsPay(null);
        }

        let beginDate = []
        let endDate = []

        if (date.length === 1) {
            beginDate = date[0].split('/')
        } else if (date.length === 2) {
            beginDate = date[0].split('/')
            endDate = date[1].split('/')
        }

        let today: any = new Date();

        const token = localStorage.getItem('GroupId') || '{}';
        const config = {
            headers: { 'Authorization': `Bearer ${token}`, 'Content-Type': 'application/json' },
        };
        try {
            const res = await api.post('/BillsPayable/GetReport',
                {
                    "page": 1,
                    "rowsPerPage": rowsPerPage,
                    "item": local,
                    "statusPagamento": status,
                    "fornecedor": supplier,
                    "supplierType": supplierType ? supplierType : null,
                    "tipoConta": type,
                    "tipoData": typeDate,
                    "empresa": empresa,
                    "dataInicial": (date.length > 0) ? `${beginDate[2]}-${beginDate[1]}-${beginDate[0]}` : `${today.getFullYear()}-${today.getMonth() + 1}-${today.getDate()}`,
                    "dataFinal": (date.length === 1) ? `${beginDate[2]}-${beginDate[1]}-${parseInt(beginDate[0])}` : (date !== null && date.length === 2) ? `${endDate[2]}-${endDate[1]}-${endDate[0]}` : `${today.getFullYear()}-${today.getMonth() + 1}-${today.getDate()}`
                }, config
            );
            setLoading(false);
            if (res.status !== 400) {
                setAllowLoadFromFilter(false);
                setParameters({
                    "page": 1,
                    "rowsPerPage": rowsPerPage,
                    "item": actualLocal,
                    "statusPagamento": actualStatus,
                    "fornecedor": actualSupplier,
                    "supplierType": actualSupplierType ? actualSupplierType : null,
                    "tipoConta": actualType,
                    "tipoData": actualTypeDate,
                    "empresa": actualEmpresa,
                    "dataInicial": (date.length > 0) ? `${beginDate[2]}-${beginDate[1]}-${beginDate[0]}` : `${today.getFullYear()}-${today.getMonth() + 1}-${today.getDate()}`,
                    "dataFinal": (date.length === 1) ? `${beginDate[2]}-${beginDate[1]}-${parseInt(beginDate[0])}` : (date !== null && date.length === 2) ? `${endDate[2]}-${endDate[1]}-${endDate[0]}` : `${today.getFullYear()}-${today.getMonth() + 1}-${today.getDate()}`
                });
                setBillsPay(res.data.data.rows);
                setTotalRows(res.data.data.rowsCount);
            }
        } catch (error: any) {
            setAllowLoadFromFilter(false);
            setError(true)
            if (error?.response?.status === 401) {
                window.location.href = window.location.origin + '/';
            }
        }
    };

    async function exportBills() {

        let beginDate = []
        let endDate = []

        if (actualDate.length === 1) {
            beginDate = actualDate[0].split('/')
        } else if (actualDate.length === 2) {
            beginDate = actualDate[0].split('/')
            endDate = actualDate[1].split('/')
        }

        let today: any = new Date();

        const token = localStorage.getItem('GroupId') || '{}';
        const config: any = {
            headers: { 
                'Authorization': `Bearer ${token}`, 
                'Content-Type': 'application/json',
                "Accept": "*/*"
                        //"Access-Control-Allow-Origin": "*"
            },
            responseType: 'blob'
        };
        
        try {
            const exp = await api.post('/BillsPayable/ExportReport',
                {
                    "dataInicial": (actualDate.length > 0) ? `${beginDate[2]}-${beginDate[1]}-${beginDate[0]}` : `${today.getFullYear()}-${today.getMonth() + 1}-${today.getDate()}`,
                    "dataFinal": (actualDate.length === 1) ? `${beginDate[2]}-${beginDate[1]}-${parseInt(beginDate[0])}` : (actualDate !== null && actualDate.length === 2) ? `${endDate[2]}-${endDate[1]}-${endDate[0]}` : `${today.getFullYear()}-${today.getMonth() + 1}-${today.getDate()}`,
                    "fornecedor": actualSupplier,
                    "supplierType": actualSupplierType ? actualSupplierType : null,
                    "item": actualLocal,
                    "page": pageCount,
                    "rowsPerPage": 0,
                    "statusPagamento": actualStatus,
                    "tipoconta": actualType,
                    "tipodata": actualTypeDate,
                    "empresa": actualEmpresa
                }, config
            );
            if (exp.status !== 400) {
                const link = document.createElement('a');
                link.href = `data:${exp.headers["content-type"]};base64,${exp.data}`;
                link.setAttribute(
                    'download',
                    'contas_pagar.xlsx',
                );

                // Append to html link element page
                document.body.appendChild(link);

                // Start download
                link.click();

                // Buffer.from(encodeURIComponent(exp.data)).toString('base64');

                // // Clean up and remove the link
                link?.parentNode?.removeChild(link);


                //console.log((encodeURIComponent(exp.data)));
                //console.log(link.href);
                ///onsole.log(btoa(unescape(encodeURIComponent(exp.data))));

                //console.log(exp.request.response)
                //setExportFile(exp.data)
            }
        } catch (error: any) {
            setError(true)
            if (error?.response?.status === 401) {
                window.location.href = window.location.origin + '/';
            }
        }
    };

    async function printBills() {

        let beginDate = []
        let endDate = []

        if (actualDate.length === 1) {
            beginDate = actualDate[0].split('/')
        } else if (actualDate.length === 2) {
            beginDate = actualDate[0].split('/')
            endDate = actualDate[1].split('/')
        }

        let today: any = new Date();

        const token = localStorage.getItem('GroupId') || '{}';
        const config: any = {
            headers: { 
                'Authorization': `Bearer ${token}`, 
                'Content-Type': 'application/json',
                "Accept": "*/*"
                        //"Access-Control-Allow-Origin": "*"
            },
            responseType: 'blob'
        };
        

        try {
            const print = await api.post('/BillsPayable/PrintReport',
                {
                    "dataInicial": (actualDate.length > 0) ? `${beginDate[2]}-${beginDate[1]}-${beginDate[0]}` : `${today.getFullYear()}-${today.getMonth() + 1}-${today.getDate()}`,
                    "dataFinal": (actualDate.length === 1) ? `${beginDate[2]}-${beginDate[1]}-${parseInt(beginDate[0])}` : (actualDate !== null && actualDate.length === 2) ? `${endDate[2]}-${endDate[1]}-${endDate[0]}` : `${today.getFullYear()}-${today.getMonth() + 1}-${today.getDate()}`,
                    "fornecedor": actualSupplier,
                    "supplierType": actualSupplierType ? actualSupplierType : null,
                    "item": actualLocal,
                    "page": pageCount,
                    "rowsPerPage": 0,
                    "statusPagamento": actualStatus,
                    "tipoconta": actualType,
                    "tipodata": actualTypeDate,
                    "empresa": actualEmpresa
                }, config
            );
            if (print.status !== 400) {
                const link = document.createElement('a');
                link.href = `data:${print.headers["content-type"]};${JSON.stringify(print.data)}`;
                link.setAttribute(
                    'download',
                    'contas_pagar.pdf',
                );

                // Append to html link element page
                document.body.appendChild(link);

                // Start download
                link.click();

                // // Clean up and remove the link
                link?.parentNode?.removeChild(link);

                console.log(print)
                //setExportFile(exp.data)
            }
        } catch (error: any) {
            setError(true)
            if (error?.response?.status === 401) {
                window.location.href = window.location.origin + '/';
            }
        }
    };

    return (
        <>
            <DesktopDefault>
                <div className="container-fluid content-dashboard">
                    <Breadcrumb title={'Financeiro / Liquidar em Lote'} />
                    <div className="suppliers mt-4 d-flex justify-content-between align-items-center">
                        <div className="d-flex flex-column">
                            <span className='back' onClick={() => { window.location.href = '/bills-to-pay' }}>
                                <FontAwesomeIcon
                                    icon={["fal", "angle-double-left"]}
                                    style={{ margin: "0 5px 0 0", fontSize: '16px' }}
                                />
                                Voltar</span>
                            <h2 className="title" style={{ color: "#707070" }}>Liquidar em Lote</h2>
                        </div>
                    </div>
                    <FilterBillsPay
                        buscar={buscar}
                        billsPay={billsPay}
                        error={error}
                        clearActualValues={clearActualValues}
                        allowLoadFromFilter={allowLoadFromFilter}
                        t={t}
                    />
                    <TableSettleBills
                        billsPay={billsPay}
                        totalRows={totalRows}
                        pageCount={pageCount}
                        setPageCount={setPageCount}
                        rowsPerPage={rowsPerPage}
                        setRowsPerPage={setRowsPerPage}
                        exportBills={exportBills}
                        printBills={printBills}
                        parameters={parameters}
                        loading={loading}
                    />
                </div>
            </DesktopDefault>
        </>
    )
}

export default BillsToPay;