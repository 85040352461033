import { useState } from 'react';
import api from '../../services/api';

//COMPONENTS
import Breadcrumb from '../../components/Breadcrumb/Breadcrumb';
import DesktopDefault from '../../templates/DesktopDefault';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Modal } from 'react-bootstrap';
import AddZoneComponent from './components/AddZoneComponent/AddZoneComponent';

function AddZones() {
    const [modalShow, setModalShow] = useState<any>(false);
    const [modalMessage, setModalMessage] = useState<any>('Carregando');
    const [modalLog, setModalLog] = useState<any>(null);

    /* const reload = () => {
        if (modalLog === 0) {
            window.location.href = "/listar-categorias";
        }
    }; */

    const InsertZone = async (data: any) => {
        setModalShow(true);

         try {
             const res = await api.post(`/Zone/AddZones`,
                 {
                     "zona": data.zoneName,
                     "destino": data.placeName || data.zoneName,
                     "groupId": data.supplier,
                     "zone": data.zones
                 });
             if (res.status !== 400) {
                 if (res.data.statusCode === 200) {
                     setModalLog(0);
                     setModalMessage('Zona inserida com sucesso!');
                 }
             } else {
                 setModalLog(1);
                 setModalMessage('Erro ao processar operação');
             }
         } catch (error: any) {
             setModalLog(1);
             setModalMessage('Erro ao processar operação');
         }
    };


    return (
        <>
            <DesktopDefault>

                <div className="container-fluid content-dashboard add-zones">
                    <Breadcrumb title={'Zonas / Adicionar Zona'} />

                    <div className="mt-4">

                        <div className="d-flex align-items-center">
                            <span className='back' onClick={() => { window.location.href = '/zones/list-zones' }}>
                                <FontAwesomeIcon
                                    icon={["fal", "angle-double-left"]}
                                    style={{ margin: "0 5px 0 0", fontSize: '16px' }}
                                />
                                Voltar</span>
                        </div>
                        <h2 className="title" style={{ color: "#707070" }}>Adicionar Zona</h2>
                    </div>

                    {/*  */}

                    <AddZoneComponent actionFunc={InsertZone} />

                    {/*  */}

                    <Modal
                        className="modalAuth"
                        show={modalShow}
                        onHide={() => {
                            setModalLog(null);
                            setModalMessage("Carregando");
                            setModalShow(false);
                        }}
                        aria-labelledby="contained-modal-title-vcenter"
                    >
                        <>
                            <Modal.Body
                                className="modal-body text-center sucess-pos d-flex justify-content-evenly"
                                style={{ height: "600px" }}
                            >
                                {modalLog === null ? (
                                    <>
                                        <div className="loading-modal">
                                            <div className="load"></div>
                                        </div>
                                    </>
                                ) : (
                                    <div>
                                        {modalLog === 0 ? (
                                            <FontAwesomeIcon
                                                icon={["fal", "check-circle"]}
                                                size="5x"
                                                className="text-primary"
                                                style={{ fontSize: "7.5em" }}
                                            />
                                        ) : (
                                            <FontAwesomeIcon
                                                icon={["fal", "times-circle"]}
                                                size="5x"
                                                className="text-primary"
                                                style={{ fontSize: "7.5em" }}
                                            />
                                        )}
                                    </div>
                                )}
                                <div>{modalMessage}</div>
                                <div className="d-flex justify-content-center pt-3">
                                    <button
                                        onClick={() => {
                                            setModalLog(null);
                                            setModalMessage("Carregando");
                                            setModalShow(false);
                                        }}
                                        className="btn btn-primary mx-2 w-25"
                                    >
                                        Confirmar
                                    </button>
                                </div>
                            </Modal.Body>
                        </>
                    </Modal>

                </div>
            </DesktopDefault>
        </>

    );
}

export default AddZones;