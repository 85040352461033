import React, { useEffect, useState } from "react";
//import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button, Card, Col, Form, Row } from "react-bootstrap";
import { Controller, useForm } from "react-hook-form";
//import { ErrorMessage } from "@hookform/error-message";
import api from "../../../../services/api";

import { useAppSelector, useAppDispatch } from "../../../../store/hooks";
import { addToComboObject } from "../../../../store/combo";

import './Markup.scss';

export interface operation {
    action: any;
    isEdit: any;
    editFunction?: any;
}

const DescriptionAndHigh: React.FC<operation> = React.memo(({
    action, isEdit, editFunction = () => { }
}: operation) => {
    /* Controle Redux  */
    const dispatch = useAppDispatch();
    const reduxComboObject: any = JSON.parse(JSON.stringify(useAppSelector((state: any) => state.combo))).combo;
    /* Controle Redux  */

    const allTariffCodes: any = reduxComboObject?.productAttached.map((elem: any) => { return elem.tarUniqueCode }) || []
    const [registeredProducts, setRegisteredProducts] = useState<any>([])
    const [markupDistribution, setMarkupDistribution] = useState<any>(/* reduxComboObject?.productAttached.map((elem: any) => { return (elem.markup[0].markupDistribution)}) */new Array(reduxComboObject?.productAttached?.length || 1).fill('1'))
    const [valueType, setValueType] = useState<any>(reduxComboObject?.productAttached?.map((elem: any) => { return (elem.markup?.length > 0 ? elem.markup[0].valueType : 2) })/* new Array(reduxComboObject?.productAttached?.length || 1).fill('1') */)
    const [/* actualProduct */, setActualProduct] = useState<number>(0)
    const [totalDiscount, setTotalDiscount] = useState<number>(0)

    const rangeCommisions: any = [
        null,
        15, //OURO
        10, //PRATA
        5,  //BRONZE
        3,  //DEFAULT
        20, //PLATINA
        25, //DIAMANTE
    ]

    const {
        control,
        handleSubmit,
        setValue,
        getValues,
        formState: { errors },
    } = useForm({});

    // let values: any = getValues();
    // const watchMarkupDistribution = watch(`markupDistribution`, (reduxComboObject?.productAttached[0]?.markup && reduxComboObject.productAttached[0].markup[0].markupDistribution) || '1');
    // const watchValueType = watch(`valueType`, (reduxComboObject?.productAttached[0]?.markup && reduxComboObject.productAttached[0].markup[0].valueType) || '2');

    useEffect(() => {
        if (registeredProducts?.length > 0) {
            setValue(`markupPercent`, reduxComboObject?.markupPercent || '')

            //calcMarkupWithPercentage(reduxComboObject.markupPercent);

            /* registeredProducts.forEach((product: any, indexProduct: number) => {
                if (reduxComboObject.productAttached[indexProduct].markup?.length > 0) { //verifica se ja existe markup cadastrado
                    if (product?.daysTariff?.length > 0) {
                        product?.daysTariff.forEach((tariff: any, indexTariff: number) => {
                            //tariff.tariffPrices.forEach((rangeElem: any, rangeIndex: number) => {
                            //if (Number(reduxComboObject.productAttached[indexProduct].markup[indexTariff].markupDistribution) === 2) { //verifica se o markup cadastrado é por faixas
                            if (Number(reduxComboObject.productAttached[indexProduct].markup[indexTariff].valueType) === 2) { //verifica se o markup cadastrado é percentual (2)
                                calcMarkupWithPercentage(reduxComboObject.productAttached[indexProduct].markup[indexTariff][`markup${tariff.tariffPrices[0].rangeId}`])
                            } else { //Por valor
                                calcMarkupWithValue(reduxComboObject.productAttached[indexProduct].markup[indexTariff][`markup${tariff.tariffPrices[0].rangeId}`], tariff.tariffPrices[0], indexProduct)
                            }
                            //}
                            //});
                        })
                    }
                }
            }); */
        }

        calculateTotalDiscount();

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [registeredProducts])

    const fillMarkupDistributionAndValueType = () => {
        if (reduxComboObject?.productAttached.length > 0 && reduxComboObject?.productAttached[0]?.markup?.length > 0) {
            let auxMarkup: any = [];
            let auxValueType: any = [];
            reduxComboObject.productAttached.forEach((product: any, indexProduct: number) => {
                if (indexProduct < reduxComboObject?.productAttached.length && product.markup?.length > 0) {
                    auxMarkup.push(product.markup[0].markupDistribution);
                    auxValueType.push(product.markup[0].valueType);
                } else {
                    auxMarkup.push('1');
                    auxValueType.push('1');
                }
            });
            setMarkupDistribution(() => [...auxMarkup])
            setValueType(() => [...auxValueType])
        }
    }

    useEffect(() => {
        fillMarkupDistributionAndValueType();
        const token = localStorage.getItem('GroupId') || '{}';
        const config = {
            headers: { 'Authorization': `Bearer ${token}` },
        };

        const getTariffsInfo = async () => {
            try {
                const { data } = await api.post("/Combo/ComboDropDowm", allTariffCodes, config);
                if (data.statusCode !== 400) {
                    setRegisteredProducts(data.data.data);
                    calculateTotalDiscount();
                }
            } catch (error: any) { }
        }

        getTariffsInfo();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const calcMarkupWithValue = (value: any, rangeElem: any, indexProduct: number/* , indexTariff: number */) => {
        //let data: any = getValues();
        if (Number(value) > Math.ceil(Number(((rangeElem.cost / (1 - (rangeCommisions[reduxComboObject?.idComissionRange] / 100))) - rangeElem.cost)))) {
            value = Math.ceil(((rangeElem.cost / (1 - (rangeCommisions[reduxComboObject?.idComissionRange] / 100))) - rangeElem.cost))

            setValue(`markupTotal-${indexProduct}`, value)
            //setValue(`productAttached[${indexProduct}].markup[${indexTariff}].markup${rangeElem.rangeId}`, value)
        }

        /* if (data.productAttached[indexProduct].markup[indexTariff][`markup${rangeElem.rangeId}`] !== "" && data.productAttached[indexProduct].markup[indexTariff][`markup${rangeElem.rangeId}`] !== undefined) {
            let valorVenda: number = Number(data[`product-${indexProduct}-tariff-${indexTariff}-${rangeElem.rangeId}Cost`]) + Number(value);

            //setValue(`tariff${rangeElem.rangeId}Alterado`, Math.ceil(valorVenda));
        } */

        calculateTotalDiscount();
    }

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const calcMarkupWithPercentage = (value: any) => {
        //let data: any = getValues();

        if (value > rangeCommisions[reduxComboObject?.idComissionRange]) {
            value = rangeCommisions[reduxComboObject?.idComissionRange]
            setValue(`markupPercent`, value)
            //setValue(`productAttached[${indexProduct}].markup[${indexTariff}].markup${rangeElem.rangeId}`, value)
        }

        /* if (data.productAttached[indexProduct].markup[indexTariff][`markup${rangeElem.rangeId}`] !== "" && data.productAttached[indexProduct].markup[indexTariff][`markup${rangeElem.rangeId}`] !== undefined) {
            let valorVenda: number = data[`product-${indexProduct}-tariff-${indexTariff}-${rangeElem.rangeId}Cost`] / (1 - value / 100);

            setValue(`tariff${rangeElem.rangeId}Alterado`, Math.ceil(valorVenda));
        } */

        calculateTotalDiscount();
    };

    useEffect(() => {
        // let data: any = getValues();
        // let aux: string[] = ["Adult", "Child", "Infant", "Student", "Elder"];
        // let newValue: any = '';

        // if (data?.productAttached?.length > 0) {
        //     if (markupDistribution[actualProduct] === '1') { //VENDA TOTAL
        //         setValue(`markupTotal-${actualProduct}`, '')
        //     } else { //VENDA POR FAIXAS
        //         data?.productAttached[actualProduct]?.markup?.forEach((elem: any, indexTariff: number) => {
        //             for (const label in elem) {
        //                 if (aux.includes(label.replace('markup', ''))) { //Verifica se o label que eu estou olhando é um dos campos que preciso usar
        //                     if (data[`tariff${label.replace('markup', '')}Alterado`]) { //verifica se ja existe algum valor no campo "VENDA"
        //                         if (valueType[actualProduct] === '1') { //VALOR FIXO  //Verificar se eu alterei o Campo "Tipo de desconto" para "valor fixo" ou "porcentagem"
        //                             newValue = data[`tariff${label.replace('markup', '')}Alterado`] - data[`product-${actualProduct}-tariff-${indexTariff}-${label.replace('markup', '')}Cost`]
        //                         } else { //PORCENTAGEM
        //                             newValue = Math.floor(100 - ((data[`product-${actualProduct}-tariff-${indexTariff}-${label.replace('markup', '')}Cost`] * 100) / data[`tariff${label.replace('markup', '')}Alterado`]))
        //                         }
        //                         setValue(`productAttached[${actualProduct}].markup[${indexTariff}].${label}`, newValue)
        //                     }

        //                 }
        //             }
        //         });
        //     }
        // }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [valueType])

    const onSubmit = (data: any) => {
        let dataToInsert: any = {};

        dataToInsert.productAttached = data.productAttached;
        dataToInsert.markupPercent = data.markupPercent;

        dataToInsert.idComissionRange = reduxComboObject.idComissionRange;

        if (isEdit) {
            editFunction(dataToInsert);
        } else {
            dispatch(addToComboObject(JSON.stringify(dataToInsert)));
            action("photo");
        }
    };

    const calculateTotalDiscount = () => {
        const data: any = getValues();
        let sum: number = 0;
        data?.productAttached?.forEach((elem: any, index: number) => {
            // if (valueType[index] === 1) {
            //     sum += Number(data[`markupPercent`]);
            // } else {
            sum += Number(registeredProducts[index].daysTariff[0].tariffPrices[0].cost * (data[`markupPercent`] / 100));
            //}
        })

        setTotalDiscount(sum)
    }

    return (
        <>
            {
                (registeredProducts.length > 0) ?
                    <>
                        <Col md={9} lg={10} className="markup">
                            <Card className="pt-content summary">
                                <Form
                                    className="h-100"
                                    noValidate
                                    validated={false}
                                    onSubmit={handleSubmit(onSubmit)}
                                >
                                    <h3 className="text-primary pt-title mb-0">
                                        Markup
                                    </h3>

                                    <div className='registered-products mt-3'>
                                        <Row>
                                            <Form.Group as={Col} md="4" controlId="" className="mt-2 mb-4" style={{ position: 'relative' }}>
                                                <Form.Label className="label-small">
                                                    Markup %
                                                </Form.Label>

                                                <Controller
                                                    control={control}
                                                    name={`markupPercent`}
                                                    rules={{
                                                        required: { value: true, message: 'Por favor, informe o nome do combo' },
                                                        min: 12,
                                                        max: 100
                                                    }}
                                                    defaultValue={(reduxComboObject?.productAttached[0]?.markup ? reduxComboObject.productAttached[0].markup[0].markupAdult : '')}
                                                    render={({ field }: any) => (
                                                        <div className={errors?.markupPercent !== undefined ? "endpoint-error" : "endpoint"}>
                                                            <Form.Control
                                                                {...field}
                                                                type="number"
                                                                variant="standard"
                                                                margin="normal"
                                                                aria-invalid={errors?.markupPercent ? "true" : ""}
                                                                required
                                                                onBlur={() => { calculateTotalDiscount() }}
                                                                //onBlur={(e: any) => { calcMarkupWithPercentage(e.target.value.replace(',', '.')) }}
                                                                autoComplete='off'
                                                                style={{ border: 'none' }}
                                                            />
                                                        </div>
                                                    )}
                                                />
                                                <span className="max-markup">min: 12%</span>
                                                {errors?.markupPercent !== undefined && <small style={{ color: "red" }}>Favor digitar um markup válido (entre 12% e 100%)</small>}
                                            </Form.Group>
                                        </Row>

                                        {registeredProducts.map((product: any, indexProduct: number) => {
                                            return (
                                                <>
                                                    <Col md={12} className='new-card mb-4'>
                                                        <div className={`d-flex justify-content-between align-items-center w-100 mb-2`}>
                                                            <span>
                                                                <b>{`${product.productName}`}</b>
                                                            </span>
                                                            {/* <div>
                                                            <Dropdown drop="start">
                                                                <Dropdown.Toggle variant="light ">
                                                                    <FontAwesomeIcon
                                                                        size="lg"
                                                                        icon={[
                                                                            "fal",
                                                                            "ellipsis-h",
                                                                        ]}
                                                                    />
                                                                </Dropdown.Toggle>

                                                                <Dropdown.Menu>
                                                                    <Dropdown.Item
                                                                        onClick={() => {
                                                                            setRegisteredProducts((prev: any) => [...prev.slice(0, indexProduct), ...prev.slice(indexProduct + 1)])
                                                                        }}
                                                                        eventKey="3"
                                                                        className="text-danger"
                                                                    >
                                                                        <FontAwesomeIcon
                                                                            icon={[
                                                                                "fal",
                                                                                "trash-alt",
                                                                            ]}
                                                                        />
                                                                        Remover
                                                                    </Dropdown.Item>
                                                                </Dropdown.Menu>
                                                            </Dropdown>
                                                        </div> */}
                                                        </div>
                                                        <div className='d-flex w-100 info bordered mb-3'>
                                                            <Row className="w-100">
                                                                <Col md='2'>
                                                                    <span className='text-wrap-custom'>
                                                                        Código:{' '}{product.productCode}
                                                                    </span>
                                                                </Col>
                                                                <Col md='4'>
                                                                    <span className='text-wrap-custom'>
                                                                        Modalidade:{' '}{product.modalityName}
                                                                    </span>
                                                                </Col>
                                                                <Col md='4'>
                                                                    <span className='text-wrap-custom'>
                                                                        Tarifário:{' '}{product.tariffName}
                                                                    </span>
                                                                </Col>
                                                            </Row>
                                                        </div>

                                                        <div>
                                                            <Row className="w-100">
                                                                <Form.Group as={Col} md="3" controlId="" className="mt-2">
                                                                    <Form.Label className="label-small">
                                                                        Opções de Markup
                                                                    </Form.Label>

                                                                    <Controller
                                                                        control={control}
                                                                        name={`markupDistribution-${indexProduct}`}
                                                                        defaultValue={(reduxComboObject?.productAttached[indexProduct]?.markup && reduxComboObject.productAttached[indexProduct].markup[0].markupDistribution) || '1'}
                                                                        render={({ field }: any) => (
                                                                            <Form.Select
                                                                                {...field}
                                                                                as="select"
                                                                                variant="standard"
                                                                                margin="normal"
                                                                                disabled
                                                                                onChange={(e: any) => {
                                                                                    let aux: any = markupDistribution;
                                                                                    aux[indexProduct] = e.target.value;
                                                                                    setMarkupDistribution(() => [...aux])
                                                                                }}
                                                                                value={markupDistribution[indexProduct]}
                                                                                required
                                                                            >
                                                                                <option value='1'>Total</option>
                                                                                <option value='2'>Por faixas</option>
                                                                            </Form.Select>
                                                                        )}
                                                                    />
                                                                </Form.Group>
                                                                {/* <Form.Group as={Col} md="3" controlId="" className="mt-2">
                                                                    <Form.Label className="label-small">
                                                                        Tipo de Markup
                                                                    </Form.Label>

                                                                    <Controller
                                                                        control={control}
                                                                        name={`valueType-${indexProduct}`}
                                                                        defaultValue={(reduxComboObject?.productAttached[indexProduct]?.markup && reduxComboObject.productAttached[indexProduct].markup[0].valueType) || '2'}
                                                                        render={({ field }: any) => (
                                                                            <Form.Select
                                                                                {...field}
                                                                                as="select"
                                                                                variant="standard"
                                                                                margin="normal"
                                                                                disabled
                                                                                onChange={(e: any) => {
                                                                                    setActualProduct(indexProduct)
                                                                                    let aux: any = valueType;
                                                                                    aux[indexProduct] = Number(e.target.value);
                                                                                    setValueType(() => [...aux])
                                                                                }}
                                                                                value={valueType[indexProduct]}
                                                                                required
                                                                            >
                                                                                <option value='2'>Porcentagem</option>
                                                                            </Form.Select>
                                                                        )}
                                                                    />
                                                                </Form.Group> */}

                                                                {/*  {(markupDistribution[indexProduct] === '1') && */}
                                                                {/* <Form.Group as={Col} md="3" controlId="" className="mt-2" style={{ position: 'relative' }}>
                                                                    <Form.Label className="label-small">
                                                                        Markup {parseInt(valueType[indexProduct]) === 1 ? 'R$' : '%'}
                                                                    </Form.Label>

                                                                    <Controller
                                                                        control={control}
                                                                        name={`markupTotal-${indexProduct}`}
                                                                        rules={{ required: { value: true, message: 'Por favor, informe o nome do combo' } }}
                                                                        defaultValue={(reduxComboObject?.productAttached[indexProduct]?.markup && reduxComboObject.productAttached[indexProduct].markup[0].markupDistribution + '' === '1') ? reduxComboObject.productAttached[indexProduct].markup[0].markupAdult : ''}
                                                                        render={({ field }: any) => (
                                                                            <Form.Control
                                                                                {...field}
                                                                                type="number"
                                                                                variant="standard"
                                                                                margin="normal"
                                                                                required
                                                                                //onBlur={() => { calculateTotalDiscount() }}
                                                                                onBlur={(e: any) => {
                                                                                    if (valueType[indexProduct] === 1) {
                                                                                        calcMarkupWithValue(e.target.value.replace(',', '.'), product.daysTariff[0].tariffPrices[0], indexProduct)
                                                                                    } else {
                                                                                        calcMarkupWithPercentage(e.target.value.replace(',', '.'), product.daysTariff[0].tariffPrices[0], indexProduct)
                                                                                    }
                                                                                }}
                                                                                autoComplete='off'
                                                                                style={{ border: 'none' }}
                                                                            />
                                                                        )}
                                                                    />
                                                                    <span className="max-markup">max: {parseInt(valueType[indexProduct]) === 1 ? ` R$ ${Math.ceil(((product?.daysTariff[0].tariffPrices[0].cost / (1 - (rangeCommisions[reduxComboObject?.idComissionRange] / 100))) - product?.daysTariff[0].tariffPrices[0].cost))}` : `${rangeCommisions[reduxComboObject?.idComissionRange]} %`}</span>
                                                                </Form.Group> */}
                                                                {/*  } */}
                                                            </Row>
                                                            {product?.daysTariff.map((tariff: any, indexTariff: number) => {
                                                                let weekDaysAvailable: any = [];

                                                                for (const weekDayLabel in (tariff.daysOfWeek)) {
                                                                    if (weekDayLabel !== 'daysUniqueCode') {
                                                                        weekDaysAvailable.push({ "day": weekDayLabel, "status": tariff.daysOfWeek[weekDayLabel] });
                                                                    }
                                                                }

                                                                return (
                                                                    <>
                                                                        <div>
                                                                            <span className="d-flex weekDays mt-3 gap-2">
                                                                                {weekDaysAvailable.map(
                                                                                    (e: any, key: number) => {
                                                                                        return (
                                                                                            <>
                                                                                                <div className={key === 0 ? "m-0" : ''}>
                                                                                                    <div key={key} className={`btn label-small ${e.status ? 'btn-primary' : 'btn-disabled'}`}>
                                                                                                        <span className="text-capitalize">
                                                                                                            {e.day}
                                                                                                        </span>
                                                                                                    </div>
                                                                                                </div>
                                                                                                <Controller
                                                                                                    control={control}
                                                                                                    name={`productAttached[${indexProduct}].markup[${indexTariff}].daily[0].${e.day.toLowerCase()}`}
                                                                                                    defaultValue={(registeredProducts[indexProduct].daysTariff[indexTariff].daysOfWeek[`${e.day.toLowerCase()}`] ? '1' : '2')}
                                                                                                    render={({ field }: any) => (
                                                                                                        <Form.Control
                                                                                                            {...field}
                                                                                                            type="hidden"
                                                                                                            variant="standard"
                                                                                                            margin="normal"
                                                                                                            autoComplete="off"
                                                                                                        />
                                                                                                    )}
                                                                                                />
                                                                                            </>
                                                                                        );
                                                                                    }
                                                                                )}
                                                                            </span>
                                                                        </div>
                                                                        <div className="mt-2 info">
                                                                            <span className="label-small">
                                                                                Horários:&nbsp;
                                                                            </span>
                                                                            <span>
                                                                                {tariff.tariffHour.map((hour: any, index: number) => { return `${index > 0 ? " | " : ""}${hour.slice(0, 5)}` })}
                                                                            </span>
                                                                        </div>
                                                                        {/*  {(markupDistribution[indexProduct] === '1') ? */}
                                                                        <>
                                                                            <div className="mt-2 info">
                                                                                <span className="label-small">
                                                                                    Preço de custo:&nbsp;
                                                                                </span>
                                                                                <span className="my-1">
                                                                                    {tariff.tariffPrices.map((rangeElem: any, rangeIndex: number) => {
                                                                                        return (
                                                                                            <>
                                                                                                {rangeElem.rangeStatus === 1 && `${rangeElem.rangeLabel}: ${(rangeElem.cost || 0).toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })} ${rangeIndex !== tariff.tariffPrices.length - 1 ? " | " : " "}`}
                                                                                            </>
                                                                                        )
                                                                                    })}
                                                                                </span>
                                                                            </div>
                                                                        </>
                                                                        {/*  :
                                                                        <>
                                                                            <Row>
                                                                                <Col md={1} className="mt-4">
                                                                                    <Form.Label className="label-small">
                                                                                        Labels
                                                                                    </Form.Label>

                                                                                    {tariff.tariffPrices.map((rangeElem: any, rangeIndex: number) => {
                                                                                        return (
                                                                                            <>
                                                                                                <div key={rangeIndex} className="mt-4">
                                                                                                    <small className="d-flex align-items-center mb-4" style={{ height: "38px" }} >
                                                                                                        {rangeElem.rangeLabel}
                                                                                                    </small>
                                                                                                </div>
                                                                                            </>
                                                                                        )
                                                                                    })}
                                                                                </Col>
                                                                                <Col md={3} className="mt-4 text-center">
                                                                                    <Form.Label className="label-small">
                                                                                        Valor de custo
                                                                                    </Form.Label>

                                                                                    {tariff.tariffPrices.map((rangeElem: any, rangeIndex: number) => {
                                                                                        return (
                                                                                            <>
                                                                                                <div className="mt-4">
                                                                                                    <Controller
                                                                                                        control={control}
                                                                                                        name={`product-${indexProduct}-tariff-${indexTariff}-${rangeElem.rangeId}Cost`}
                                                                                                        //name={`productAttached[${indexProduct}].markup[${indexTariff}].markup${rangeElem.rangeId}`}
                                                                                                        defaultValue={rangeElem.cost}
                                                                                                        render={({ field }: any) => (
                                                                                                            <Form.Control
                                                                                                                {...field}
                                                                                                                type="number"
                                                                                                                variant="standard"
                                                                                                                disabled
                                                                                                                margin="normal"
                                                                                                                required
                                                                                                                autoComplete='off'
                                                                                                            />
                                                                                                        )}
                                                                                                    />
                                                                                                </div>
                                                                                            </>
                                                                                        )
                                                                                    })}
                                                                                </Col>

                                                                                <Col md={3} className="mt-4 text-center">
                                                                                    <Form.Label className="label-small">
                                                                                        Markup {valueType[indexProduct] === '1' ? 'R$' : '%'}
                                                                                    </Form.Label>

                                                                                    {tariff.tariffPrices.map((rangeElem: any, rangeIndex: number) => {
                                                                                        return (
                                                                                            <>
                                                                                                <div className="mt-4 percent">
                                                                                                    <Controller
                                                                                                        control={control}
                                                                                                        //name={`tariffPercent-${rangeIndex}`}
                                                                                                        name={`productAttached[${indexProduct}].markup[${indexTariff}].markup${rangeElem.rangeId}`}
                                                                                                        rules={{ required: { value: false, message: "Por favor, informe o tipo de operação", }, }}
                                                                                                        defaultValue={reduxComboObject?.productAttached && reduxComboObject.productAttached[indexProduct].markup && reduxComboObject.productAttached[indexProduct].markup[indexTariff][`markup${rangeElem.rangeId}`]}
                                                                                                        render={({ field }: any) => (
                                                                                                            <Form.Control
                                                                                                                {...field}
                                                                                                                type="text"
                                                                                                                variant="standard"
                                                                                                                margin="normal"
                                                                                                                required
                                                                                                                maxLength={valueType[indexProduct] === '1' ? -1 : 2}
                                                                                                                autoComplete='off'
                                                                                                                onKeyPress={(e) => !/[0-9]/.test(e.key) && e.preventDefault()}
                                                                                                                onBlur={(e: any) => {
                                                                                                                    if (valueType[indexProduct] === '1') {
                                                                                                                        calcMarkupWithValue(e.target.value.replace(',', '.'), rangeElem, indexProduct, indexTariff)
                                                                                                                    } else {
                                                                                                                        calcMarkupWithPercentage(e.target.value.replace(',', '.'), rangeElem, indexProduct, indexTariff)
                                                                                                                    }
                                                                                                                }}
                                                                                                            />
                                                                                                        )}
                                                                                                    />
                                                                                                    <span>max: {valueType[indexProduct] === '1' ? ` R$ ${Math.ceil(((rangeElem.cost / (1 - (rangeCommisions[reduxComboObject?.idComissionRange] / 100))) - rangeElem.cost))}` : `${rangeCommisions[reduxComboObject?.idComissionRange]} %`}</span>
                                                                                                </div>
                                                                                            </>
                                                                                        )
                                                                                    })}
                                                                                </Col>

                                                                                <Col md={3} className="mt-4 text-center">
                                                                                    <Form.Label className="label-small">
                                                                                        Venda
                                                                                    </Form.Label>

                                                                                    {tariff.tariffPrices.map((rangeElem: any, rangeIndex: number) => {
                                                                                        return (
                                                                                            <>
                                                                                                <div className="mt-4">
                                                                                                    <Controller
                                                                                                        control={control}
                                                                                                        name={`tariff${rangeElem.rangeId}Alterado`}
                                                                                                        rules={{ required: { value: false, message: "Por favor, informe o tipo de operação", }, }}
                                                                                                        render={({ field }: any) => (
                                                                                                            <Form.Control
                                                                                                                {...field}
                                                                                                                type="number"
                                                                                                                variant="standard"
                                                                                                                margin="normal"
                                                                                                                required
                                                                                                                disabled
                                                                                                                autoComplete='off'
                                                                                                                onKeyPress={(e) => !/^\d+/g.test(e.key) && e.preventDefault()}
                                                                                                            //onBlur={(e: any) => { calcTariffAlterado( e?.target?.value, rangeElem.rangeId, rangeIndex ); }}
                                                                                                            />
                                                                                                        )}
                                                                                                    />
                                                                                                </div>

                                                                                            </>
                                                                                        )
                                                                                    })}
                                                                                </Col>

                                                                            </Row>
                                                                        </> 
                                                                    } */}

                                                                        {/* HIDDEN DENTRO DO MARKUP */}
                                                                        <Controller
                                                                            control={control}
                                                                            name={`productAttached[${indexProduct}].markup[${indexTariff}].tarUniqueCode`}
                                                                            defaultValue={registeredProducts[indexProduct].tarUniqueCode}
                                                                            render={({ field }: any) => (
                                                                                <Form.Control
                                                                                    {...field}
                                                                                    type="hidden"
                                                                                    variant="standard"
                                                                                    margin="normal"
                                                                                    autoComplete="off"
                                                                                />
                                                                            )}
                                                                        />
                                                                        <Controller
                                                                            control={control}
                                                                            name={`productAttached[${indexProduct}].markup[${indexTariff}].startDate`}
                                                                            defaultValue={registeredProducts[indexProduct].endDate}
                                                                            render={({ field }: any) => (
                                                                                <Form.Control
                                                                                    {...field}
                                                                                    type="hidden"
                                                                                    variant="standard"
                                                                                    margin="normal"
                                                                                    autoComplete="off"
                                                                                />
                                                                            )}
                                                                        />
                                                                        <Controller
                                                                            control={control}
                                                                            name={`productAttached[${indexProduct}].markup[${indexTariff}].endDate`}
                                                                            defaultValue={registeredProducts[indexProduct].endDate}
                                                                            render={({ field }: any) => (
                                                                                <Form.Control
                                                                                    {...field}
                                                                                    type="hidden"
                                                                                    variant="standard"
                                                                                    margin="normal"
                                                                                    autoComplete="off"
                                                                                />
                                                                            )}
                                                                        />
                                                                        <Controller
                                                                            control={control}
                                                                            name={`productAttached[${indexProduct}].markup[${indexTariff}].daily[0].modCode`}
                                                                            defaultValue={registeredProducts[indexProduct].modUniqueCode}
                                                                            render={({ field }: any) => (
                                                                                <Form.Control
                                                                                    {...field}
                                                                                    type="hidden"
                                                                                    variant="standard"
                                                                                    margin="normal"
                                                                                    autoComplete="off"
                                                                                />
                                                                            )}
                                                                        />
                                                                        <Controller
                                                                            control={control}
                                                                            name={`productAttached[${indexProduct}].markup[${indexTariff}].daily[0].tarUniqueCode`}
                                                                            defaultValue={registeredProducts[indexProduct].tarUniqueCode}
                                                                            render={({ field }: any) => (
                                                                                <Form.Control
                                                                                    {...field}
                                                                                    type="hidden"
                                                                                    variant="standard"
                                                                                    margin="normal"
                                                                                    autoComplete="off"
                                                                                />
                                                                            )}
                                                                        />
                                                                        <Controller
                                                                            control={control}
                                                                            name={`productAttached[${indexProduct}].markup[${indexTariff}].daily[0].tarCode`}
                                                                            defaultValue={registeredProducts[indexProduct].tariffCode}
                                                                            render={({ field }: any) => (
                                                                                <Form.Control
                                                                                    {...field}
                                                                                    type="hidden"
                                                                                    variant="standard"
                                                                                    margin="normal"
                                                                                    autoComplete="off"
                                                                                />
                                                                            )}
                                                                        />
                                                                    </>
                                                                )
                                                            })}

                                                        </div>

                                                    </Col >
                                                    <Controller
                                                        control={control}
                                                        name={`productAttached[${indexProduct}].productCode`}
                                                        defaultValue={registeredProducts[indexProduct].productCode}
                                                        render={({ field }: any) => (
                                                            <Form.Control
                                                                {...field}
                                                                type="hidden"
                                                                variant="standard"
                                                                margin="normal"
                                                                autoComplete="off"
                                                            />
                                                        )}
                                                    />
                                                    <Controller
                                                        control={control}
                                                        name={`productAttached[${indexProduct}].productType`}
                                                        defaultValue={registeredProducts[indexProduct].productCode.includes('TKT-') ? '4' : '1'}
                                                        render={({ field }: any) => (
                                                            <Form.Control
                                                                {...field}
                                                                type="hidden"
                                                                variant="standard"
                                                                margin="normal"
                                                                autoComplete="off"
                                                            />
                                                        )}
                                                    />
                                                    <Controller
                                                        control={control}
                                                        name={`productAttached[${indexProduct}].productName`}
                                                        defaultValue={registeredProducts[indexProduct].productName}
                                                        render={({ field }: any) => (
                                                            <Form.Control
                                                                {...field}
                                                                type="hidden"
                                                                variant="standard"
                                                                margin="normal"
                                                                autoComplete="off"
                                                            />
                                                        )}
                                                    />
                                                    <Controller
                                                        control={control}
                                                        name={`productAttached[${indexProduct}].modUniqueCode`}
                                                        defaultValue={registeredProducts[indexProduct].modUniqueCode}
                                                        render={({ field }: any) => (
                                                            <Form.Control
                                                                {...field}
                                                                type="hidden"
                                                                variant="standard"
                                                                margin="normal"
                                                                autoComplete="off"
                                                            />
                                                        )}
                                                    />
                                                    <Controller
                                                        control={control}
                                                        name={`productAttached[${indexProduct}].modalityName`}
                                                        defaultValue={registeredProducts[indexProduct].modalityName}
                                                        render={({ field }: any) => (
                                                            <Form.Control
                                                                {...field}
                                                                type="hidden"
                                                                variant="standard"
                                                                margin="normal"
                                                                autoComplete="off"
                                                            />
                                                        )}
                                                    />
                                                    <Controller
                                                        control={control}
                                                        name={`productAttached[${indexProduct}].tarUniqueCode`}
                                                        defaultValue={registeredProducts[indexProduct].tarUniqueCode}
                                                        render={({ field }: any) => (
                                                            <Form.Control
                                                                {...field}
                                                                type="hidden"
                                                                variant="standard"
                                                                margin="normal"
                                                                autoComplete="off"
                                                            />
                                                        )}
                                                    />
                                                    <Controller
                                                        control={control}
                                                        name={`productAttached[${indexProduct}].tariffName`}
                                                        defaultValue={registeredProducts[indexProduct].tariffName}
                                                        render={({ field }: any) => (
                                                            <Form.Control
                                                                {...field}
                                                                type="hidden"
                                                                variant="standard"
                                                                margin="normal"
                                                                autoComplete="off"
                                                            />
                                                        )}
                                                    />
                                                </>
                                            )
                                        })}
                                    </div>
                                    <Row>
                                        <Col md='3'>
                                            <div className="subTotalControl bg-light border">
                                                <div className="d-flex flex-column">
                                                    <h5>Valor de custo total</h5>
                                                    {registeredProducts[0].daysTariff[0].tariffPrices.map((elem: any, tariffPricesIndex: number) => {
                                                        return (
                                                            <>
                                                                <div className="d-flex justify-content-between align-items-end">
                                                                    <span>{elem.rangeLabel}</span>
                                                                    <div style={{ border: '1px dashed black', height: '1px', flex: '1', margin: '0 5px 5px 5px' }}></div>
                                                                    <div className="d-flex">
                                                                        <small>R$</small>
                                                                        <p className="priceSubtotal m-0">{(registeredProducts.reduce((a: any, b: any) => a + b.daysTariff[0].tariffPrices[tariffPricesIndex].cost, 0)).toLocaleString('pt-br', { style: 'currency', currency: 'BRL' }).replace('R$', '')}</p>
                                                                    </div>
                                                                </div>
                                                            </>
                                                        )
                                                    })}
                                                </div>
                                            </div>
                                        </Col>
                                        <Col md='3'>
                                            <div className="subTotalControl bg-light border">
                                                <div className="d-flex flex-column">
                                                    <h5>Valor de venda total</h5>
                                                    {registeredProducts[0].daysTariff[0].tariffPrices.map((elem: any, tariffPricesIndex: number) => {
                                                        return (
                                                            <>
                                                                <div className="d-flex justify-content-between align-items-end">
                                                                    <span>{elem.rangeLabel}</span>
                                                                    <div style={{ border: '1px dashed black', height: '1px', flex: '1', margin: '0 5px 5px 5px' }}></div>
                                                                    <div className="d-flex">
                                                                        <small>R$</small>
                                                                        <p className="priceSubtotal m-0">{(registeredProducts.reduce((a: any, b: any) => a + b.daysTariff[0].tariffPrices[tariffPricesIndex].selling, 0)).toLocaleString('pt-br', { style: 'currency', currency: 'BRL' }).replace('R$', '')}</p>
                                                                    </div>
                                                                </div>
                                                            </>
                                                        )
                                                    })}
                                                </div>
                                            </div>
                                        </Col>
                                        <Col md='3'>
                                            <div className="subTotalControl bg-light border">
                                                <div className="d-flex flex-column">
                                                    <h5>Valor do markup</h5>
                                                    {registeredProducts[0].daysTariff[0].tariffPrices.map((elem: any, tariffPricesIndex: number) => {
                                                        return (
                                                            <>
                                                                <div className="d-flex justify-content-between align-items-end">
                                                                    <span>{elem.rangeLabel}</span>
                                                                    <div style={{ border: '1px dashed black', height: '1px', flex: '1', margin: '0 5px 5px 5px' }}></div>
                                                                    <div className="d-flex">
                                                                        <small>R$</small>
                                                                        <p className="priceSubtotal m-0">{(registeredProducts.reduce((a: any, b: any) => a + (b.daysTariff[0].tariffPrices[tariffPricesIndex].cost * (getValues()[`markupPercent`] / 100)), 0)).toLocaleString('pt-br', { style: 'currency', currency: 'BRL' }).replace('R$', '')}</p>
                                                                    </div>
                                                                </div>
                                                            </>
                                                        )
                                                    })}
                                                </div>
                                            </div>
                                        </Col>
                                        <Col md='3'>
                                            <div className="subTotalControl bg-light border">
                                                <div className="d-flex flex-column">
                                                    <h5>Desconto total</h5>
                                                    {registeredProducts[0].daysTariff[0].tariffPrices.map((elem: any, tariffPricesIndex: number) => {
                                                        return (
                                                            <>
                                                                <div className="d-flex justify-content-between align-items-end">
                                                                    <span>{elem.rangeLabel}</span>
                                                                    <div style={{ border: '1px dashed black', height: '1px', flex: '1', margin: '0 5px 5px 5px' }}></div>
                                                                    <div className="d-flex">
                                                                        <small>R$</small>
                                                                        <p className="priceSubtotal m-0">{
                                                                            (
                                                                                registeredProducts.reduce((a: any, b: any) => a + b.daysTariff[0].tariffPrices[tariffPricesIndex].selling, 0) - //valor total
                                                                                (
                                                                                    registeredProducts.reduce((a: any, b: any) => a + b.daysTariff[0].tariffPrices[tariffPricesIndex].cost, 0) + //custo total
                                                                                    registeredProducts.reduce((a: any, b: any) => a + (b.daysTariff[0].tariffPrices[tariffPricesIndex].cost * (getValues()[`markupPercent`] / 100)), 0) //markup
                                                                                )
                                                                            ).toLocaleString('pt-br', { style: 'currency', currency: 'BRL' }).replace('R$', '')}</p>
                                                                    </div>
                                                                </div>
                                                            </>
                                                        )
                                                    })}
                                                </div>
                                            </div>
                                        </Col>
                                    </Row>

                                    <div className="h-100 d-flex justify-content-end align-items-end">
                                        {isEdit !== true ? (
                                            <Row className="mx-0 mt-5">
                                                <Button
                                                    variant="outline-primary"
                                                    className="form-button mr-3"
                                                    onClick={() => action("product")}
                                                >
                                                    Voltar
                                                </Button>
                                                <Button type="submit" className="form-button">
                                                    Continuar
                                                </Button>
                                            </Row>
                                        ) : (
                                            <Row className=" mx-0 mt-5">
                                                <Button
                                                    type="submit"
                                                    className="form-button"
                                                >
                                                    Salvar
                                                </Button>
                                            </Row>
                                        )}
                                    </div>
                                </Form>
                            </Card>
                        </Col>
                    </>
                    :
                    <>
                        <Col md={9} lg={10}>
                            <Card className="pt-content h-100">
                                <div className="loading-modal h-100 d-flex flex-column justify-content-center align-items-center">
                                    <div className="load mb-4"></div>
                                    <div>Carregando dados dos tarifários</div>
                                </div>
                            </Card>
                        </Col>
                    </>
            }
        </>
    );
}
);

export default DescriptionAndHigh;