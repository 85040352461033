import React, { useState } from "react";

import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import Accordion from 'react-bootstrap/Accordion';
import InputGroup from 'react-bootstrap/InputGroup';

import { useTranslation } from "react-i18next";
import { Container, Row, Col } from 'react-bootstrap';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

//Components
import RangeCalendar from "../../../../components/Calendar/RangeCalendar";
import ModalQuestion from "../../../../components/Modal/ModalQuestion/ModalQuestion";

//import './FilterBillsPay.scss';

export interface propFilter {
    buscar: any,
    clearActualValues: any;
    allowLoadFromFilter?: any;
}

const FilterManifest: React.FC<propFilter> = ({
    buscar, clearActualValues, allowLoadFromFilter
}) => {

    const { t } = useTranslation();

    const [filterCod, setFilterCod] = useState<any>("");
    const [filterDate, setFilterDate] = useState<any>([]);
    const [filterType, setFilterType] = useState<any>(null);
    const [filterOperation, setFilterOperation] = useState<any>("");
    const [filterStatus, setFilterStatus] = useState<any>(null);

    const [allowOperation, setAllowOperation] = useState<any>(false);

    /* Date Interval Confirmation */
    const [dateIntervalConfirmModalShow, setDateIntervalConfirmModalShow] = useState<any>(false);
    /* END - Date Interval Confirmation */


    const handleFilterClick = (event: any) => {

        var dataIntervalLong = false;

        if (filterDate.length === 2) {
            let beginDate = filterDate[0].split('/')
            let endDate = filterDate[1].split('/')

            const dataIni = new Date(parseInt(beginDate[2]), parseInt(beginDate[1])-1, parseInt(beginDate[0]));
            const dataFim = new Date(parseInt(endDate[2]), parseInt(endDate[1])-1, parseInt(endDate[0]));

            const days = (dataFim.getTime() - dataIni.getTime()) / (1000*60*60*24);

            dataIntervalLong = (days > 90); // 3 meses
            
        }

        if(dataIntervalLong) {
            setDateIntervalConfirmModalShow(true);
        } else {
            handleActionBuscar();
        }
    }

    const handleActionBuscar = () => {
        setDateIntervalConfirmModalShow(false);
        buscar(filterCod, filterDate, filterType, filterOperation, filterStatus);
    };

    const incDate = (e: any, dias: number) => {
        e.preventDefault();

        const today = new Date();

        const newDate = (filterDate.length === 0) ? `${today.getDate()}/${today.getMonth()+1}/${today.getFullYear()}` : filterDate[0];

        const datao = newDate.split('/');
        
        const newDate1 = new Date(parseInt(datao[2]), parseInt(datao[1])-1, parseInt(datao[0])+dias);
        //console.log(parseInt(datao[0])+dias); return;
        const newDateStr = `${newDate1.getDate()}/${newDate1.getMonth()+1}/${newDate1.getFullYear()}`

        setFilterDate([newDateStr]);

        buscar(filterCod, [newDateStr], filterType, filterOperation, filterStatus);
    };

    const handleClearClick = () => {

        let inputValue = document.querySelectorAll('input');
        for (let i = 0; i < inputValue.length; i++) {
            inputValue[i].value = "";
        }

        let selectValue = document.querySelectorAll('select');
        for (let i = 0; i < selectValue.length; i++) {
            if (selectValue[i].id === "rowsPerPage") {
                selectValue[i].value = "10";
            } else {
                selectValue[i].value = "";
            }
        }

        setFilterCod("");
        setFilterStatus(null);
        setFilterType("");
        setFilterOperation("");
        setFilterDate([]);
        buscar("", [], null, null, null);
        clearActualValues();
    };

    const handleChangeCod = (e: React.ChangeEvent<HTMLInputElement>) => {
        const newValue = e.target.value;
        setFilterCod(newValue);
    }

    const handleChangeStatus = (e: React.ChangeEvent<HTMLInputElement>) => {
        const newValue = e.target.value;
        setFilterStatus(newValue);
    }

    const handleChangeType = (e: React.ChangeEvent<HTMLInputElement>) => {
        const newValue = e.target.value;
        setFilterType(newValue);

        setFilterOperation('');
        setFilterStatus('');
        
        if (newValue !== '2') {
            setAllowOperation(false);
        } else {
            setAllowOperation(true);
        }
    }

    const handleChangeOperation = (e: React.ChangeEvent<HTMLInputElement>) => {
        const newValue = e.target.value;
        setFilterOperation(newValue);
    }

    return (
        <div className="suppliers-filter d-flex justify-content-center bg-white mt-4" style={{ textAlign: "left" }}>
            <Accordion defaultActiveKey="1">
                <Accordion.Item eventKey="0">
                    <Accordion.Header><FontAwesomeIcon icon={['fal', 'sliders-h']} size="lg" className="mr-2" /><span className="h5 mb-0">Buscar Sessões</span></Accordion.Header>
                    <Accordion.Body className="pt-0">
                        <Container className="p-0" fluid>
                            <Row>
                                <div>
                                    <Form >
                                        <Row className="mb-3">
                                            <Form.Group as={Col} md="3">
                                                <Form.Label>Descrição ou Código</Form.Label>
                                                <Form.Control
                                                    type="text"
                                                    autoComplete='off'
                                                    onChange={handleChangeCod}
                                                />
                                            </Form.Group>
                                            <Form.Group as={Col} md="3">
                                                <Form.Label>Data</Form.Label>
                                                <div className="d-flex">
                                                    <button className="btn btn-default p-0 mx-2" style={{width:"25px"}} onClick={(e:any) => incDate(e, -1)}><FontAwesomeIcon
                                                        size="sm"
                                                        icon={["fal", "arrow-left"]}
                                                        style={{ color: "#707070", margin: "0px", padding: "0px"}}
                                                    /></button>
                                                    <InputGroup hasValidation className="ranger-calendar d-flex">
                                                    
                                                        <RangeCalendar date={filterDate} setDate={setFilterDate} />
                                                        
                                                    </InputGroup>
                                                    <button className="btn btn-default p-0 mx-2" style={{width:"25px"}} onClick={(e:any) => incDate(e, 1)}><FontAwesomeIcon
                                                        size="sm"
                                                        icon={["fal", "arrow-right"]}
                                                        style={{ color: "#707070", margin: "0px", padding: "0px" }}
                                                    /></button>
                                                </div>
                                            </Form.Group>
                                            <Form.Group as={Col} md="2">
                                                <Form.Label>Tipo</Form.Label>
                                                <div className="endpoint">
                                                    <InputGroup hasValidation>
                                                        <Form.Select
                                                            aria-describedby="inputGroupPrepend"
                                                            onChange={(e: any) => handleChangeType(e)}
                                                        >
                                                            <option value="">Todos</option>
                                                            <option value="3">Ticket</option>
                                                            <option value="2">Tour</option>
                                                            <option value="1">Transfer</option>
                                                        </Form.Select>
                                                    </InputGroup>
                                                </div>
                                            </Form.Group>
                                            {allowOperation === true
                                                ?
                                                <Form.Group as={Col} md="2">
                                                    <Form.Label>Operação</Form.Label>
                                                    <div className="endpoint">
                                                        <InputGroup hasValidation>
                                                            <Form.Select
                                                                aria-describedby="inputGroupPrepend"
                                                                onChange={(e: any) => handleChangeOperation(e)}
                                                            >
                                                                <option value="">Todos</option>
                                                                <option value="1">Própria</option>
                                                                <option value="2">Terceiros</option>
                                                            </Form.Select>
                                                        </InputGroup>
                                                    </div>
                                                </Form.Group>
                                                : <></>
                                            }
                                            {(filterType !== '' && filterType !== '2') || (filterType === '2' && filterOperation !== '')
                                                ?
                                                <Form.Group as={Col} md="2">
                                                    <Form.Label>Status</Form.Label>
                                                    <div className="endpoint">
                                                        <InputGroup hasValidation>
                                                            <Form.Select
                                                                aria-describedby="inputGroupPrepend"
                                                                onChange={(e: any) => handleChangeStatus(e)}
                                                            >
                                                                <option value="">Todos</option>
                                                                {
                                                                    filterType === "1"
                                                                        ?
                                                                        <>
                                                                            <option value="1">Pendente Escala</option>
                                                                            <option value="2">Pendente Aceite</option>
                                                                            <option value="3">Pendente Execução</option>
                                                                            <option value="4">Em Execução</option>
                                                                            <option value="5">Finalizado</option>
                                                                        </>

                                                                        : <></>
                                                                }
                                                                {
                                                                    filterType === "2" && filterOperation === "1"
                                                                        ?
                                                                        <>
                                                                            <option value="1">Pendente</option>
                                                                            <option value="2">Alocação Parcial</option>
                                                                            <option value="3">Alocação Total</option>
                                                                            <option value="4">Em Operação</option>
                                                                            <option value="5">Pendente Finalização</option>
                                                                            <option value="6">Finalizado</option>
                                                                        </>
                                                                        : <></>
                                                                }
                                                                {
                                                                    filterType === "2" && filterOperation === "2"
                                                                        ?
                                                                        <>
                                                                            <option value="1">Pendente</option>
                                                                            <option value="2">Solicitado</option>
                                                                            <option value="3">Confirmado</option>
                                                                        </>
                                                                        : <></>
                                                                }
                                                                {
                                                                    filterType === "3"
                                                                        ?
                                                                        <>
                                                                            <option value="1">Emitido</option>
                                                                            <option value="2">Cancelado</option>
                                                                            <option value="3">Utilizado</option>
                                                                        </>
                                                                        : <></>
                                                                }
                                                            </Form.Select>
                                                        </InputGroup>
                                                    </div>
                                                </Form.Group>
                                                : <></>
                                            }
                                        </Row>
                                        <Row as={Col} md="12" className="mt-5 justify-content-md-end">
                                            <Col xs lg="12" className="d-flex justify-content-md-end">
                                                <Button variant="outline-primary" className="btn-default mr-2 text-uppercase" onClick={handleClearClick}>{t('partners.partnersfilter.btnClear')}</Button>
                                                <Button className="btn-default text-uppercase" onClick={handleFilterClick}>{allowLoadFromFilter === true ? <p className="load"></p> : t('partners.partnersfilter.btnSearch')}</Button>
                                            </Col>
                                        </Row>
                                    </Form>
                                </div>
                            </Row>
                        </Container>
                    </Accordion.Body>
                </Accordion.Item>
            </Accordion>
            <ModalQuestion
                modalShow={dateIntervalConfirmModalShow}
                setModalShow={setDateIntervalConfirmModalShow}
                titulo=""
                mensagem="Intervalo de data excede 3 meses. Continua?"
                handleActionConfirmModal={handleActionBuscar}
            />
        </div>
    )
}

export default FilterManifest;