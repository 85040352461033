import React, { useState, useEffect, Key } from "react";
import { Card, Row, Col, Accordion, Container, ProgressBar } from "react-bootstrap";
import api from "../../../../services/api";
import { useForm } from "react-hook-form";

import "./TableStock.scss"

// import RequestResponseModal from "../../../components/Modal/RequestResponseModal/RequestResponseModal";
import Modal from "react-bootstrap/Modal";
import RequestResponseModal from "../../../../components/Modal/RequestResponseModal/RequestResponseModal";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import DateSlider from "../SliderDate/SliderDate";

const TableStock = () => {
    const [loading, setLoading] = useState<boolean>(true);
    const [loadingDates, setLoadingDates] = useState<boolean>(true);
    const [loadingHours, setLoadingHours] = useState<boolean>(true);
    const [tariffsInfo, setTariffsInfo] = useState<any>([])
    //const [tariffsInfoAtualizado, setTariffsInfoAtualizado] = useState<any>([])
    const [/* modalityInfo */, setModalityInfo] = useState<any>(null)
    const [modalResponse, setModalResponse] = useState<any>(null); /// ["loading","error","sucess"] para abrir o modal pós envio
    const [modalText, /* setModalText */] = useState<any>(null);
    const [log, /* setLog */] = useState<any>();
    const [stockGeneral, setStockGeneral] = useState<any>([]);
    const [capacidades, setCapacidades] = useState<any>([]);
    const [selectedRange, setSelectedRange] = useState<any>(null);

    const [productName, setProductName] = useState<any>([]);

    const [stockDetails, setStockDetails] = useState<null | number>(null);
    const [infoStock, setInfoStock] = useState<any>([])

    const [tarCode, setTarCode] = useState<string | null>(null)
    const [prodCode, setProdCode] = useState<string | null>(null)
    const [modCode, setModCode] = useState<string | null>(null)
    const [controll, setControll] = useState<boolean>(false)

    const productCode = window.location.href.split('productCode=')[1];

    // Obtenha a data atual
    const today = new Date();

    const ano = today.getFullYear();
    const mes = (today.getMonth() + 1).toString().padStart(2, '0'); // +1 porque janeiro é 0
    const dia = today.getDate().toString().padStart(2, '0');

    const [dataSearch, setDataSearch] = useState<any>(`${ano}-${mes}-${dia}`)

    // console.log(productCode)


    const getStockByTarCode = async (prodCode: any, prodModCode: any, tarifaId: any) => {
        setLoading(false);
        setLoadingDates(true);

        const token = localStorage.getItem('GroupId') || '{}';
        const config = {
            headers: { 'Authorization': `Bearer ${token}` },
        };

        const dados = {
            "productCode": prodCode,
            "prodModCodeUnique": prodModCode,
            "tarCodeUnique": tarifaId,
            "productType": "1"
        }

        try {
            const { data } = await api.post(`/Stock/GetStockGeneral`, dados, config);

            if (data.statusCode === 200) {



                /*  */
                if (data.data.item1) {
                    setStockGeneral((prev: any) => [...prev, { ...(tariffsInfo[tariffsInfo.findIndex((elem: any) => elem.id === tarifaId)]), errorText: '', item1: data.data.item1 }]);
                } else {
                    setStockGeneral((prev: any) => [...prev, { ...(tariffsInfo[tariffsInfo.findIndex((elem: any) => elem.id === tarifaId)]), errorText: data.data[0].texto, item1: data.data.item1 }]);
                }
                /*  */

                //setStockGeneral((prev: any) => [...prev, { ...(tariffsInfo[tariffsInfo.findIndex((elem: any) => elem.id === tarifaId)]), errorText: '', item1: data.data.item1 }]);
                //setStockGeneral(data.data.item1)



                setLoadingDates(false);
            } else {
                setLoadingDates(false);
                // setModalLog(1);
                // setModalMessage(data.errorMessage);
                // setModalShow(true);
            }
        } catch (error: any) {
            setLoadingDates(false);
            // setModalLog(1);
            // setModalMessage("Erro ao processar operação, favor tentar mais tarde");
            // setModalShow(true);
        }
    }

    //setLoading(true);

    const getStockByDate = async (prodCode: any, prodModCode: any, tarifaId: any, dataSearch: any = null, index: number = 0, load: boolean = true) => {
        setLoadingHours(true);
        setProdCode(prodCode);
        setModCode(prodModCode);
        setTarCode(tarifaId)
        setSelectedRange(() => stockGeneral[stockGeneral.findIndex((elem: any) => elem.id === tarifaId)].item1.stockGeneralOnSale[index].stockGeneralRangeDates);

        let firstStockDate: any;
        if (dataSearch === null) {
            firstStockDate = new Date(stockGeneral[stockGeneral.findIndex((elem: any) => elem.id === tarifaId)].item1.stockGeneralOnSale[index].stockGeneralRangeDates.startDate).toJSON().slice(0, 10)
        } else {
            firstStockDate = new Date(dataSearch)
        }

        const token = localStorage.getItem('GroupId') || '{}';
        const config = {
            headers: { 'Authorization': `Bearer ${token}` },
        };

        const dados = {
            "ProductCode": prodCode,
            "ProdModUniqueCode": prodModCode,
            "TarUniqueCode": tarifaId,
            "DateStartSearch": firstStockDate,
            "DateEndSearch": firstStockDate
        }

        setLoadingDates(load);

        try {
            const { data } = await api.post(`/Stock/DashBoardStockModalityOrTicketByRangeDates`, dados, config);

            if (data.statusCode === 200) {
                setLoadingDates(false);
                setControll(true);
                setStockDetails(stockGeneral.findIndex((elem: any) => elem.id === tarifaId))
                setInfoStock(data.data[0].dashBoardByDayByRangeDatesList)
                setLoadingHours(false);
            } else {
                setLoadingDates(false);
                setLoadingHours(false);
                // setModalLog(1);
                // setModalMessage(data.errorMessage);
                // setModalShow(true);
            }
        } catch (error: any) {
            setLoadingHours(false);
            // setModalLog(1);
            // setModalMessage("Erro ao processar operação, favor tentar mais tarde");
            // setModalShow(true);
        }
    }

    //setLoading(true);



    const getModalitys = async () => {

        const token = localStorage.getItem('GroupId') || '{}';
        const config = {
            headers: { 'Authorization': `Bearer ${token}` },
        };

        try {
            const { data } = await api.get(`/DropDown/GetActiveProductsModalityTour?ProductCode=${productCode}`, config);
            if (data.status !== 400) {
                // eslint-disable-next-line array-callback-return
                data.data.map((mod: any, index: any) => {
                    setModalityInfo(mod)
                    getTarifis(mod)
                })
            }
        } catch (error: any) {

        }

    }



    async function productsListCod() {

        const token = localStorage.getItem('GroupId') || '{}';
        const config = {
            headers: { 'Authorization': `Bearer ${token}` },
        };
        try {
            const { data } = await api.get(`/DropDown/GetActiveProductsTour`, config);
            if (data.status !== 400) {
                setLoading(false);
                // eslint-disable-next-line array-callback-return
                data.data.map((product: any, index: any) => {
                    if (product.id === productCode) {
                        setProductName(product.description)
                    }
                }
                )
            }
        } catch (error: any) {
            setTimeout(() => {
                productsListCod();
            }, 5000);
        }
    }


    const getTarifis = async (modalidade: any) => {

        const token = localStorage.getItem('GroupId') || '{}';
        const config = {
            headers: { 'Authorization': `Bearer ${token}` },
        };

        try {
            const { data } = await api.get(`/DropDown/GetActiveProductsTarCodeTour?ModCode=${modalidade.id}`, config);
            if (data.status !== 400) {
                // eslint-disable-next-line array-callback-return
                data.data.map((tariff: any, index: any) => {
                    setTariffsInfo((prev: any) => [...prev, { ...tariff, modalidade: modalidade.description, idModalidade: modalidade.id }])
                    //getStockByTarCode(productCode, modalidade.id, tariff.id)

                    // getStockByDate(productCode, modalidade.id, tariff.id )
                })

            }
        } catch (error: any) {

        }

    }

    useEffect(() => {
        //console.log(tariffsInfo[tariffsInfo.length - 1])
        if (tariffsInfo.length > 0) {
            getStockByTarCode(productCode, tariffsInfo[tariffsInfo.length - 1].idModalidade, tariffsInfo[tariffsInfo.length - 1].id)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [tariffsInfo])

    useEffect(() => {
        setTariffsInfo([]);
        getModalitys();
        productsListCod();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [productCode])

    useEffect(() => {
        if (controll === true) {
            getStockByDate(prodCode, modCode, tarCode, dataSearch, 0, false)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dataSearch])

    const {
        formState: { errors },
    } = useForm({});


    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const handleInputChange = (e: any, capac: any, hora: any) => {
        // setNumber(e.target.value);
        // setValue('number', e.target.value);
        let teste = capacidades;
        teste[hora] = { 'capacidade': e.target.value };
        setCapacidades(teste)
    }

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const handleInputChangeChanel = (e: any, canal: any, hora: any) => {

        // setNumber(e.target.value);
        // setValue('number', e.target.value);
        let teste = capacidades;
        // let testeCnal = `{${canal}: ${e.target.value}}`
        // teste[hora].push(testeCnal);
        setCapacidades(teste)
    }




    function convertDate(dataISO: Date) {
        // // Data no formato ISO
        // var dataISO = "2023-09-15T00:00:00";

        // Criar um objeto Date com a data ISO
        var data = new Date(dataISO);

        // Extrair o dia, mês e ano da data
        var dia = data.getDate();
        var mes = data.getMonth() + 1; // Os meses são baseados em zero, então somamos 1
        var ano = data.getFullYear();

        // Formatar a data no formato "dd/mm/yyyy"
        var dataFormatada = dia + '/' + (mes < 10 ? '0' : '') + mes + '/' + ano;

        // console.log(dataFormatada); // Saída: "15/09/2023"
        return (dataFormatada);

    }

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    function generateWeekdays(weeks: any) {

        const newWeekDays: any = [

            {
                state: weeks.dom === 1 ? 1 : 0,
                day: 'dom'
            },
            {
                state: weeks.seg === 1 ? 1 : 0,
                day: 'seg'
            },
            {
                state: weeks.ter === 1 ? 1 : 0,
                day: 'ter'
            },
            {
                state: weeks.qua === 1 ? 1 : 0,
                day: 'qua'
            },
            {
                state: weeks.qui === 1 ? 1 : 0,
                day: 'qui'
            },
            {
                state: weeks.sex === 1 ? 1 : 0,
                day: 'sex'
            },
            {
                state: weeks.sab === 1 ? 1 : 0,
                day: 'sab'
            }
        ];

        return newWeekDays;
    }


    function calcularFracaoConsumida(total: number, consumido: number) {
        const fracao = consumido / total;

        if (fracao >= 1 / 3) {
            console.log("success")
            return "success";
        } else if (fracao === 2 / 3) {
            console.log("warning")
            return "warning";
        } else if (fracao === 1) {
            console.log("danger")
            return "danger";
        } else {
            return "";
        }
    }

    return (
        <Row>
            <Col md={loadingDates === false && stockDetails === null ? 12 : 8}>
                <Card className="bg-white my-4">
                    {
                        loading ?
                            <>
                                <div className="py-5">
                                    <div className="load primary"></div>
                                </div>
                            </>
                            :
                            <>
                                <div className='py-4 px-4'>
                                    <h2>{productName}</h2>
                                    <p><b>Código de produto:</b>  {productCode}</p>

                                    {stockGeneral.length > 0 && stockGeneral?.map((elem: any, index: number) => {
                                        return (
                                            <>
                                                {index !== 0 && <hr />}
                                                <i>{elem?.description} - {elem?.modalidade}</i>

                                                <Row className="m-0 mt-3">
                                                    <Col md={4} className="border rounded-3 px-4 py-4" onClick={() => {
                                                        if (elem.item1 !== undefined) {
                                                            getStockByDate(productCode, elem.idModalidade, elem.id)
                                                        }
                                                    }}>
                                                        <div className="d-flex">
                                                            <FontAwesomeIcon className="text-primary mr-3" icon={['fal', 'box']} size="3x" />
                                                            <div>
                                                                <p className="mb-0">Período</p>

                                                                {elem.item1 !== undefined ?
                                                                    <>
                                                                        <p className="mb-0">{convertDate(elem.item1.stockGeneralOnSale[0].stockGeneralRangeDates.startDate)} - {convertDate(elem.item1.stockGeneralOnSale[0].stockGeneralRangeDates.endDate)}</p>
                                                                        <small>
                                                                            Sem informação sobre os canais
                                                                        </small>
                                                                    </>
                                                                    :
                                                                    <>
                                                                        <small>
                                                                            {elem.errorText}
                                                                        </small>
                                                                    </>
                                                                }
                                                            </div>
                                                        </div>

                                                    </Col>

                                                </Row>

                                            </>
                                        )
                                    })}



                                </div>
                            </>
                    }
                </Card>
                <Row className="d-flex justify-content-end mx-0 mt-5 mb-4">
                    <div className="btn-sucess">
                        <Modal
                            className="modal-custom modalAuth"
                            show={modalResponse !== null}
                            onHide={() => setModalResponse(null)}
                            size="lg"
                            aria-labelledby="contained-modal-title-vcenter"
                        >
                            <>
                                {/* <Modal.Header closeButton></Modal.Header> */}
                                <RequestResponseModal
                                    name={"Fornecedor"}
                                    modalResponse={modalResponse}
                                    setModalResponse={setModalResponse}
                                    errors={errors}
                                    modalText={modalText}
                                    log={log}
                                />
                            </>
                        </Modal>
                    </div>
                </Row>
            </Col>


            <Col md={4}>
                {
                    loadingDates ?
                        <>
                            <Card className="bg-white my-4 py-4 px-4">
                                <div className="py-5">
                                    <div className="load primary"></div>
                                </div>
                            </Card>
                        </>
                        :
                        <>
                            {stockDetails === null ?

                                false

                                :
                                <>
                               
                                    <Card className="bg-white my-4 py-4 px-4">
                                        <Row className="m-0">
                                            <Col md={12} className="border rounded-3 px-4 py-4" onClick={() => getStockByDate(productCode, stockGeneral[stockDetails].item1.prodModCode, stockGeneral[stockDetails].item1.tarCode)}>
                                                <div className="d-flex">
                                                    <FontAwesomeIcon className="text-primary mr-3" icon={['fal', 'box']} size="3x" />
                                                    <div>
                                                        <p className="mb-0">Período</p>
                                                        <p className="mb-0">{convertDate(stockGeneral[stockDetails]?.item1.stockGeneralOnSale[0].stockGeneralRangeDates.startDate)} - {convertDate(stockGeneral[stockDetails]?.item1.stockGeneralOnSale[0].stockGeneralRangeDates.endDate)}</p>
                                                        <small>
                                                            Sem informação sobre os canais
                                                            
                                                        </small>

                                                    </div>

                                                </div>

                                            </Col>

                                        </Row>

                                        <Row className="d-flex justify-content-center w-100 mt-5 mx-0">
                                            <DateSlider
                                                changeDate={setDataSearch}
                                                selectedRange={selectedRange}
                                            />
                                        </Row>

                                        {
                                            infoStock === false ?

                                                false

                                                :

                                                <Row className="m-0 mt-5">
                                                    {
                                                        loadingHours ?
                                                            <>
                                                                <div className="py-5">
                                                                    <div className="load primary"></div>
                                                                </div>
                                                            </>
                                                            :
                                                            <>
                                                                <Accordion defaultActiveKey="0" className="p-0" >
                                                                    {
                                                                        infoStock.map((info: any, index: Key) => (
                                                                            <Accordion.Item className="mb-4 " eventKey={String(index)}>
                                                                                <Accordion.Header className="font-accordion-header-stock"><span className="h5 mb-0">{info.hour.split(':')[0] + ':' + info.hour.split(':')[1]}</span></Accordion.Header>
                                                                                <Accordion.Body className="pt-0 mt-3 mb-2">
                                                                                    <Container className="p-0 pb-3" fluid>
                                                                                        <Row className="m-0 p-0">
                                                                                            {info.dashBoardBySalesChannelByRangeDatesList.map((chanels: any) => (
                                                                                                <>
                                                                                                    <div className="d-flex justify-content-between"> <span>{chanels.salesChannelName}</span> <span>{chanels.soldByChannel} / {chanels.amountOnSaleByChannel}</span></div>
                                                                                                    <ProgressBar variant={calcularFracaoConsumida(chanels.balanceAvailableByChannel, chanels.soldByChannel)} className="p-0" now={chanels.soldByChannel} max={chanels.amountOnSaleByChannel} />
                                                                                                </>

                                                                                            ))}

                                                                                        </Row>
                                                                                    </Container>
                                                                                </Accordion.Body>
                                                                            </Accordion.Item>

                                                                            // <span>{info.hour}</span>
                                                                        ))
                                                                    }
                                                                </Accordion>
                                                            </>
                                                    }
                                                </Row>
                                        }
                                    </Card>
                                </>
                            }
                        </>
                }

            </Col>


        </Row>
    );
}



export default TableStock;
