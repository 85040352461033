import React, { useState } from "react";
import { Container, Row, Col } from "react-bootstrap";

import "../../../assets/sass/modal.scss";

//import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import { Controller, useForm } from "react-hook-form";
import Modal from "react-bootstrap/Modal";

import { useTranslation } from 'react-i18next';

import InputMask from "react-input-mask";

//import IconSucess from "../../../assets/icons/modal-sucess.svg";

export interface propModal {
  action: any;
  setCloseModal: any;
  modalEdit: any;
  setModalEdit: any;
  handleEdit: any;
};

const AddContact: React.FC<propModal> = ({
  action, setCloseModal, modalEdit = [], setModalEdit, handleEdit
}: propModal, props: any) => {
  const { t } = useTranslation();
  const [id, setId] = useState<any>(1);
  
  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm({
    defaultValues: {
      name: modalEdit.length > 0 ? modalEdit[0].name : '',
      email: modalEdit.length > 0 ? modalEdit[0].email : '',
      phone: modalEdit.length > 0 ? modalEdit[0].phone : '',
      cellPhone: modalEdit.length > 0 ? modalEdit[0].cellPhone : '',
      isMain: modalEdit.length > 0 && modalEdit[0].isMain === 1 ? true : false,
      area: modalEdit.length > 0 ? modalEdit[0].area : '',
      id: id
    },
  });

  const onSubmitContact = (data: any) => {
    data.isMain === true ? data.isMain = 1 : data.isMain = 0;
    data.id = id;
    setId(id + 1);
    action(data);
    setCloseModal(true);

    if (modalEdit.length > 0) {   ///indica que está na sessão de editar
      data.index = modalEdit[0].index;
      handleEdit(data)
    }
  };

  return (
    <>
      <Modal.Body>
        <Container className="bg-modal-contact p-0" fluid>
          <Row>
            {/* <h5 className="contact-title mb-5">{t('accordionInfoCompany.modalContact.title')}</h5> */}
            <Form
              className="form-add-contact"
              noValidate
            >
              <div className="">
                {modalEdit.length > 0 && modalEdit[0].isMain === 1
                  ?
                  <></>
                  :
                  <Row className="mb-3">
                    <Form.Group as={Col} md="6" controlId="validationCustom03">
                      <Controller
                        control={control}
                        name="isMain"
                        render={({ field }: any) => (
                          <Form.Check.Input
                            type="checkbox"
                            {...field}
                            label={t('accordionInfoCompany.modalContact.main')}
                            variant="standard"
                            defaultChecked={modalEdit.length > 0 && modalEdit[0].isMain === 1 ? true : false}
                            margin="normal"
                            value="1"
                            className="mr-2"
                            required
                          />
                        )}
                      />
                      <Form.Label>{t('accordionInfoCompany.modalContact.main')}</Form.Label>
                    </Form.Group>
                  </Row>
                }
                <Row className="mb-3">
                  <Form.Group as={Col} md="6" controlId="validationCustom01">
                    <Form.Label>{t('accordionInfoCompany.modalContact.name')}</Form.Label>
                    <Controller
                      control={control}
                      name="name"
                      rules={{ required: { value: true, message: 'Por favor, informe o nome.' } }}
                      render={({ field }: any) => (
                        <Form.Control
                          {...field}
                          aria-invalid={errors?.name ? "true" : ""}
                          label={t('accordionInfoCompany.modalContact.name')}
                          variant="standard"
                          margin="normal"
                          required
                          autoComplete='off'
                        />
                      )}
                    />
                  </Form.Group>
                  <Form.Group as={Col} md="6" controlId="validationCustom02">
                    <Form.Label>{t('accordionInfoCompany.modalContact.email')}</Form.Label>
                    <Controller
                      control={control}
                      name="email"
                      rules={{ required: { value: true, message: 'Por favor, informe o email.' } }}
                      render={({ field }: any) => (
                        <Form.Control
                          {...field}
                          aria-invalid={errors?.email ? "true" : ""}
                          label={t('accordionInfoCompany.modalContact.email')}
                          variant="standard"
                          margin="normal"
                          required
                          autoComplete='off'
                        />
                      )}
                    />
                  </Form.Group>
                </Row>
                <Row className="mb-3">
                  <Form.Group as={Col} md="6" controlId="validationCustom03">
                    <Form.Label>{t('accordionInfoCompany.modalContact.phone')}</Form.Label>
                    <Controller
                      control={control}
                      name="phone"
                      rules={{ required: { value: true, message: 'Por favor, informe o telefone' } }}
                      render={({ field }: any) => (
                        <InputMask mask="(99) 9999-9999" value={field?.value} onChange={field?.onChange}>
                          {(inputProps: any) => (
                            <Form.Control
                              {...inputProps}
                              aria-invalid={errors?.phone ? "true" : ""}
                              label={t('accordionInfoCompany.modalContact.phone')}
                              variant="standard"
                              margin="normal"
                              required
                              autoComplete='off'
                            />
                          )}
                        </InputMask>

                      )}
                    />
                  </Form.Group>
                  <Form.Group as={Col} md="6" controlId="validationCustom04">
                    <Form.Label>{t('accordionInfoCompany.modalContact.cellphone')} </Form.Label>
                    <Controller
                      control={control}
                      name="cellPhone"
                      rules={{ required: { value: true, message: 'Por favor, informe o celular.' } }}
                      render={({ field }: any) => (
                        <InputMask mask="(99) 99999-9999" value={field?.value} onChange={field?.onChange}>
                          {(inputProps: any) => (
                            <Form.Control
                              {...inputProps}
                              aria-invalid={errors?.cellPhone ? "true" : ""}
                              label={t('accordionInfoCompany.modalContact.cellphone')}
                              variant="standard"
                              margin="normal"
                              required
                              autoComplete='off'
                            />
                          )}
                        </InputMask>
                      )}
                    />
                  </Form.Group>
                </Row>
                <Row className="mb-3">
                  <Form.Group as={Col} md="6" controlId="validationCustom04">
                    <Form.Label>{t('accordionInfoCompany.modalContact.area')}</Form.Label>
                    <Controller
                      control={control}
                      name="area"
                      rules={{ required: { value: true, message: 'Por favor, informe o área.' } }}
                      render={({ field }: any) => (
                        <Form.Control
                          {...field}
                          aria-invalid={errors?.area ? "true" : ""}
                          label={t('accordionInfoCompany.modalContact.area')}
                          variant="standard"
                          margin="normal"
                          required
                          autoComplete='off'
                        />
                      )}
                    />
                  </Form.Group>
                </Row>
              </div>
            </Form>
          </Row>
        </Container>
      </Modal.Body>

      <Modal.Footer>
        <Row className="buttons-modal d-flex justify-content-end mx-0">
          {/* <Button className="form-button mr-3" onClick={props.onHide}>
            Cancelar
          </Button> */}
          <Button
            type="button"
            className="form-button"
            onClick={handleSubmit(onSubmitContact)}
          >
            {t('accordionInfoCompany.modalContact.save')}
          </Button>
        </Row>
      </Modal.Footer>


    </>
  );
}

export default AddContact;
