import React from 'react';
//COMPONENTS
import Breadcrumb from '../../../components/Breadcrumb/Breadcrumb';


import MerchantFlagsForm from '../components/MerchantFlagsForm/MerchantFlagsForm';
import { useTranslation } from 'react-i18next';
import DesktopDefault from '../../../templates/DesktopDefault';


function AddMerchantFlags() {
  const { t } = useTranslation();
    return (
        <>
            <DesktopDefault>
                    <div className="container-fluid content-dashboard">

                        <Breadcrumb title={'Bandeiras Cartão / ' + t('addMerchantFlags.title')} />

                        <div className="register-users mt-4">
                            <h2 className="title">{t('addMerchantFlags.title')}</h2>
                        </div>

                        <MerchantFlagsForm action="1" info={null} />

                    </div>

            </DesktopDefault>
        </>

    );
}

export default AddMerchantFlags;