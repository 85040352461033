import React from 'react';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
//import api from '../../../../../services/api';
//import logo from '../../logo.svg';

export interface propInfo {
    info: any;
    setChangeEdit: any;
    showDocument: any;
};

const EditDocuments: React.FC<propInfo> = ({
    info, setChangeEdit, showDocument
}: propInfo) => {
    //var socialContractBase64:any = info.socialContractBase64;
    //var socialContractName:string = info.socialContractName;

    function textReduce(cell: any) {
      var aux = cell.split(" ");
      if (aux.length >= 4) {
        aux = aux[0] + " " + aux[1] + " " + aux[2] + " " + aux[3] + "...";
      } else {
        aux = cell;
      }

      return (
        <>
          <OverlayTrigger overlay={<Tooltip id="tooltip">{cell}</Tooltip>}>
            <span className="text-wrap-custom">{cell}</span>
          </OverlayTrigger>
        </>
      );
    }

    

    //download pdf
    function downloadBase64PDF(base64String:any, fileName:any) {
        console.log("aqui")
        fetch(base64String)
            .then(response => response.blob())
            .then(blob => {
                // Criar um URL temporário para o blob
                var url = URL.createObjectURL(blob);
    
                // Criar um elemento de link
                var link = document.createElement('a');
                link.href = url;
                link.download = fileName || 'arquivo.pdf'; // Nome do arquivo que será baixado
    
                // Adicionar o link ao corpo do documento
                document.body.appendChild(link);
    
                // Clicar no link para iniciar o download
                link.click();
    
                // Remover o link do corpo do documento
                document.body.removeChild(link);
    
                // Liberar o URL temporário
                URL.revokeObjectURL(url);
            })
            .catch(error => {
                console.error('Erro ao baixar o arquivo:', error);
            });
    }
    
    //download pdf
    

    return (
        <>
            <div id="documentsData" className="d-flex">
                <div className="border">
                    <div className="edit-title container-fluid">
                        <h3 className="">Documentos</h3>
                        <button onClick={() => { setChangeEdit(false) }}>EDITAR</button>
                    </div>
                    {info.compType === 1
                        ?
                        <div className="data">
                            <div className="data-unit">
                                {info.cpfBase64 !== null
                                    ?
                                    <div className="w-100 d-flex justify-content-between">
                                        <p className='text-primary'>CPF:</p>
                                        <span className='text-primary' style={{ cursor: 'pointer' }} onClick={() => { showDocument(info.cpfBase64, 'CPF') }}>Ver</span>
                                    </div>
                                    :
                                    <p>CPF:</p>
                                }
                                <p className='w-100'>{info.cpfName ? textReduce(info.cpfName) : "não definido"}</p>
                            </div>
                            <div className="data-unit">
                                {info.rgBase64 !== null
                                    ?
                                    <div className="w-100 d-flex justify-content-between">
                                        <p className='text-primary'>RG:</p>
                                        <span className='text-primary' style={{ cursor: 'pointer' }} onClick={() => { showDocument(info.rgBase64, 'RG') }}>Ver</span>
                                    </div>
                                    :
                                    <p>RG:</p>
                                }
                                <p className='w-100'>{info.rgName ? textReduce(info.rgName) : "não definido"}</p>
                            </div>
                            <div className="data-unit">
                                {info.compResBase64 !== null
                                    ?
                                    <div className="w-100 d-flex justify-content-between">
                                        <p className='text-primary'>COMPROVANTE DE RESIDÊNCIA:</p>
                                        <span className='text-primary' style={{ cursor: 'pointer' }} onClick={() => { showDocument(info.compResBase64, 'COMPROVANTE DE RESIDÊNCIA') }}>Ver</span>
                                    </div>
                                    :
                                    <p>COMPROVANTE DE RESIDÊNCIA:</p>
                                }
                                <p className='w-100'>{info.compResidFileName ? textReduce(info.compResidFileName) : "não definido"}</p>
                            </div>
                            <div className="data-unit">
                                {info.docSerasaBase64 !== null
                                    ?
                                    <div className="w-100 d-flex justify-content-between">
                                        <p className='text-primary'>DOCUMENTAÇÃO SERASA:</p>
                                        <span className='text-primary' style={{ cursor: 'pointer' }} onClick={() => { showDocument(info.docSerasaBase64, 'DOCUMENTAÇÃO SERASA') }}>Ver</span>
                                    </div>
                                    :
                                    <p>DOCUMENTAÇÃO SERASA:</p>
                                }
                                <p className='w-100'>{info.docSerasaFileName ? textReduce(info.docSerasaFileName) : "não definido"}</p>
                            </div>
                            <div className="data-unit">
                                {info.cartHabBase64 !== null
                                    ?
                                    <div className="w-100 d-flex justify-content-between">
                                        <p className='text-primary'>CARTEIRA DE HABILITAÇÃO:</p>
                                        <span className='text-primary' style={{ cursor: 'pointer' }} onClick={() => { showDocument(info.cartHabBase64, 'CARTEIRA DE HABILITAÇÃO') }}>Ver</span>
                                    </div>
                                    :
                                    <p>CARTEIRA DE HABILITAÇÃO:</p>
                                }
                                <p className='w-100'>{info.cnhFileName ? textReduce(info.cnhFileName) : "não definido"}</p>
                            </div>
                            <div className="data-unit data-unit-white-bg"></div>
                        </div>
                        :
                        <div className="data">
                            <div className="data-unit">
                                {info.socialContractBase64 !== null
                                    ?
                                    <div className="w-100 d-flex justify-content-between">
                                        <p className='text-primary'>CONTRATO SOCIAL:</p>
                                        <span className='text-primary' style={{ cursor: 'pointer' }} onClick={() => { downloadBase64PDF(info.socialContractBase64, info.socialContractName) }}>Baixar</span>
                                    </div>
                                    :
                                    <p>CONTRATO SOCIAL:</p>
                                }
                                <p className='w-100'>{info.socialContractName ? textReduce(info.socialContractName) : "não definido"}</p>
                            </div>
                            <div className="data-unit">
                                {info.rgReprBase64 !== null
                                    ?
                                    <div className="w-100 d-flex justify-content-between">
                                        <p className='text-primary'>RG DO REPRESENTANTE DA EMPRESA:</p>
                                        <span className='text-primary' style={{ cursor: 'pointer' }} onClick={() => { showDocument(info.rgReprBase64, 'RG DO REPRESENTANTE DA EMPRESA') }}>Ver</span>
                                    </div>
                                    :
                                    <p>RG DO REPRESENTANTE DA EMPRESA:</p>
                                }
                                <p className='w-100'>{info.rgReprName ? textReduce(info.rgReprName) : "não definido"}</p>
                            </div>
                            <div className="data-unit data-unit-white-bg"></div>
                        </div>
                    }
                </div>
            </div>
        </>
    );
}

export default EditDocuments;