/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useEffect, useState } from 'react';
import api from '../../services/api';

//COMPONENTS
import DesktopDefault from '../../templates/DesktopDefault';
import Breadcrumb from '../../components/Breadcrumb/Breadcrumb';
import ChartReportCashFlow from './components/ChartReportCashFlow/ChartReportCashFlow';
import FilterReportCashFlow from './components/FilterReportCashFlow/FilterReportCashFlow';
import TableReportCashFlow from './components/TableReportCashFlow/TableReportCashFlow';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

//Style
import './ReportCashFlow.scss';
import { Button, Modal } from 'react-bootstrap';

function ReportCashFlow() {
    const [info, setInfo] = useState<any>(null);
    const [infoChart, setInfoChart] = useState<any>(null);
    const [loading, setLoading] = useState<any>(false);

    const [modalShow, setModalShow] = useState<any>(false);
    const [modalResponse, setModalResponse] = useState<any>('');
    const [modalLog, setModalLog] = useState<any>(null);

    /* Pagination */
    const [totalRows, setTotalRows] = useState<any>();
    const [pageCount, setPageCount] = useState<any>(1);
    const [rowsPerPage, setRowsPerPage] = useState<any>(10);
    /* END - Pagination */

    const [defaultAccounts, setDefaultAccounts] = useState<any>(null);
    const [defaultDate, setDefaultDate] = useState<any>([]);

    const [actualAccounts, setActualAccounts] = useState<any>(null);
    const [actualDate, setActualDate] = useState<any>([]);

    const [minValue, setMinValue] = useState<any>(0);
    const [maxValue, setMaxValue] = useState<any>(10000);

    const [loadButton, setLoadButton] = useState<any>([false, false])

    useEffect(() => {
        async function listAccountingAccounts() {
            const hoje = new Date();

            const inicio = hoje.toISOString().substring(0,10).split('-').reverse().join('/');

            hoje.setDate(hoje.getDate()+30);

            const fim = hoje.toISOString().substring(0,10).split('-').reverse().join('/');

            setDefaultDate([inicio,fim]);
            // load default accounts
            const token = localStorage.getItem('GroupId') || '{}';
            const config = {
                headers: { 'Authorization': `Bearer ${token}` },
            };

            try {
                const { data } = await api.get(`/ReportFinancial/GetDefaultAccountsAsync`, config);
                if (data.status !== 400) {
                    const itens = [
                        -1,
                        -2,
                        ...data.data
                    ];
                    setDefaultAccounts(itens)
                    /* ; */
                }
            } catch (error: any) {
                if (error.response.status === 401) {
                    window.location.href = window.location.origin + '/';
                }
            }
        }
        if(!defaultAccounts) listAccountingAccounts();
        
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const formatDate = (data: any) => {
        if(!data) return '';
        
        //2022-05-05T00:00:00
        var aux = data.split('T');
        aux = aux[0].split('-');

        return (`${aux[2]}/${aux[1]}/${aux[0]}`);
    }
    // useEffect(() => {
    //     var today: any = new Date();

    //     /* buscar(0, 1, [`${today.getDate()}/${today.getMonth() + 1}/${today.getFullYear() - 1}`, `${today.getDate()}/${today.getMonth() + 1}/${today.getFullYear()}`], 0, 0, 1, 0, 0, 0, '', ''); */
    //     buscar(0, 1, [`${today.getDate()}/${today.getMonth() + 1}/${today.getFullYear()}`, `${today.getDate()}/${today.getMonth() + 1}/${today.getFullYear()}`], 1, 1, 0, 0, 0, 0, '', '');
    //     // eslint-disable-next-line react-hooks/exhaustive-deps
    // }, [])

    function updateData(dados: any) {
        const gg: any = []; //dados.items.map((i:any) => [formatDate(i.data), i.totalReceber, -i.totalPagar, i.saldoFinal]);

        var minV = 10000;
        var maxV = -10000;
        for(var i = 0; i < dados.items.length; i++) {
            const totalReceber = dados.items[i].totalReceber;
            const totalPagar = -dados.items[i].totalPagar;
            const saldoFinal = dados.items[i].saldoFinal;

            gg.push([formatDate(dados.items[i].data), totalReceber, totalPagar, saldoFinal]);

            if(totalPagar < minV) {
                minV = totalPagar;
            }
            if(saldoFinal < minV) {
                minV = saldoFinal;
            }
            if(totalReceber > maxV) {
                maxV = totalReceber;
            }
            if(saldoFinal > maxV) {
                maxV = saldoFinal;
            }
        }
        minV = Math.floor(minV / 100000) * 100000;
        maxV = Math.floor(maxV / 100000) * 100000;

        setMinValue(minV);
        setMaxValue(maxV);
        setLoading(false);
        setInfoChart(gg);
        setInfo(dados.items);
        setTotalRows(dados.items.length);
    }

    async function buscar(accounts: any, date: any) {
        setLoading(true);

        setActualAccounts(accounts);
        setActualDate(date);

        async function changingPage() {
            setLoading(true);
            let beginDate = []
            let endDate = []

            if (date?.length !== 0) {
                if (date?.length === 1) {
                    beginDate = date[0].split('/')
                } else if (date?.length === 2) {
                    beginDate = date[0].split('/')
                    endDate = date[1].split('/')
                }
            } else {
                if (defaultDate?.length === 1) {
                    beginDate = defaultDate[0].split('/')
                } else if (defaultDate?.length === 2) {
                    beginDate = defaultDate[0].split('/')
                    endDate = defaultDate[1].split('/')
                }
            }

            let today: any = new Date();

            const token = localStorage.getItem('GroupId') || '{}';
            const config = {
                headers: { 'Authorization': `Bearer ${token}`, 'Content-Type': 'application/json' },
            };
            try {
                const { data } = await api.post('/ReportCashFlow/GetReportAsync',
                    {
                        "page": pageCount,
                        "rowsPerPage": rowsPerPage,
                        "contas": accounts ? accounts : defaultAccounts,
                        "dataInicio": `${beginDate[2]}-${beginDate[1]}-${beginDate[0]}`,
                        "dataFim": (endDate.length === 0) ? `${beginDate[2]}-${beginDate[1]}-${parseInt(beginDate[0])}` : `${endDate[2]}-${endDate[1]}-${endDate[0]}`
                    }, config);

                if (data.status !== 400) {
                    const dados = data.data;

                    updateData(dados);
                }
            } catch (error: any) {
                setLoading(false);
            }
        }
        changingPage();
    }

    useEffect(() => {
        async function changingPage() {
            setLoading(true);
            let beginDate = []
            let endDate = []

            if (actualDate?.length !== 0) {
                if (actualDate?.length === 1) {
                    beginDate = actualDate[0].split('/')
                } else if (actualDate?.length === 2) {
                    beginDate = actualDate[0].split('/')
                    endDate = actualDate[1].split('/')
                }
            } else {
                if (defaultDate?.length === 1) {
                    beginDate = defaultDate[0].split('/')
                } else if (defaultDate?.length === 2) {
                    beginDate = defaultDate[0].split('/')
                    endDate = defaultDate[1].split('/')
                }
            }

            let today: any = new Date();

            const token = localStorage.getItem('GroupId') || '{}';
            const config = {
                headers: { 'Authorization': `Bearer ${token}`, 'Content-Type': 'application/json' },
            };
            try {
                const { data } = await api.post('/ReportCashFlow/GetReportAsync',
                    {
                        "page": pageCount,
                        "rowsPerPage": rowsPerPage,
                        "contas": actualAccounts ? actualAccounts : defaultAccounts,
                        "dataInicio": `${beginDate[2]}-${beginDate[1]}-${beginDate[0]}`,
                        "dataFim": (endDate.length === 0) ? `${beginDate[2]}-${beginDate[1]}-${parseInt(beginDate[0])}` : `${endDate[2]}-${endDate[1]}-${endDate[0]}`
                    }, config);

                if (data.status !== 400) {
                    const dados = data.data;

                    updateData(dados);
                }
            } catch (error: any) {
                setLoading(false);
            }
        }
        if (defaultAccounts !== null) {
            
            changingPage();
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [pageCount, rowsPerPage, defaultAccounts])

    const printReport = () => {
        setLoadButton([true, false]);
        async function printing() {
            let beginDate = []
            let endDate = []

            if (actualDate.length === 1) {
                beginDate = actualDate[0].split('/')
            } else if (actualDate.length === 2) {
                beginDate = actualDate[0].split('/')
                endDate = actualDate[1].split('/')
            }

            let today: any = new Date();

            const token = localStorage.getItem('GroupId') || '{}';

            try {
                const exp = await api.post('/ReportCashFlow/PrintReportAsync',
                    {
                        "page": 1,
                        "rowsPerPage": totalRows,
                        "contas": actualAccounts ? actualAccounts : defaultAccounts,
                        "dataInicio": (actualDate.length > 0) ? `${beginDate[2]}-${beginDate[1]}-${beginDate[0]}` : `${today.getFullYear()}-${today.getMonth() + 1}-${today.getDate()}`,
                        "dataFim": (actualDate.length === 1) ? `${beginDate[2]}-${beginDate[1]}-${parseInt(beginDate[0])}` : (actualDate !== null && actualDate.length === 2) ? `${endDate[2]}-${endDate[1]}-${endDate[0]}` : `${today.getFullYear()}-${today.getMonth() + 1}-${today.getDate()}`
                    },
                    {
                        headers: {
                            'Authorization': `Bearer ${token}`,
                            "Content-Type": "application/json",
                            "Accept": "*/*"
                            //"Access-Control-Allow-Origin": "*"

                        },
                        responseType: 'blob'
                    });

                if (exp.status !== 400) {
                    setLoadButton([false, false]);
                    const blob = new Blob([exp.data], { type: exp.headers["Content-Type"] });
                    const objectUrl = window.URL.createObjectURL(blob);

                    const link = document.createElement('a');
                    link.href = objectUrl;
                    link.setAttribute(
                        'download',
                        'relatório_de_vendas.pdf',
                    );

                    // Append to html link element page
                    document.body.appendChild(link);

                    // Start download
                    link.click();

                    // // Clean up and remove the link
                    link?.parentNode?.removeChild(link);
                    setModalResponse('Sucesso ao imprimir');
                    setModalLog(0);
                } else {
                    setModalResponse('Erro ao imprimir');
                    setModalLog(1);

                }
            } catch (error: any) {
                setModalResponse('Erro ao imprimir');
                setModalLog(1);

            }
        }
        printing();
    }


    const exportReport = () => {
        setLoadButton([false, true]);
        async function exporting() {
            let beginDate = []
            let endDate = []

            if (actualDate.length === 1) {
                beginDate = actualDate[0].split('/')
            } else if (actualDate.length === 2) {
                beginDate = actualDate[0].split('/')
                endDate = actualDate[1].split('/')
            }

            let today: any = new Date();

            const token = localStorage.getItem('GroupId') || '{}';

            try {
                const exp = await api.post('/ReportCashFlow/ExportReportAsync',
                    {
                        "page": 0,
                        "rowsPerPage": 0,
                        "contas": actualAccounts ? actualAccounts : defaultAccounts,
                        "dataInicio": (actualDate.length > 0) ? `${beginDate[2]}-${beginDate[1]}-${beginDate[0]}` : `${today.getFullYear()}-${today.getMonth() + 1}-${today.getDate()}`,
                        "dataFim": (actualDate.length === 1) ? `${beginDate[2]}-${beginDate[1]}-${parseInt(beginDate[0])}` : (actualDate !== null && actualDate.length === 2) ? `${endDate[2]}-${endDate[1]}-${endDate[0]}` : `${today.getFullYear()}-${today.getMonth() + 1}-${today.getDate()}`
                    },
                    {
                        headers: {
                            'Authorization': `Bearer ${token}`,
                            "Content-Type": "application/json",
                            "Accept": "*/*"
                            //"Access-Control-Allow-Origin": "*"

                        },
                        responseType: 'blob'
                    });

                if (exp.status !== 400) {
                    setLoadButton([false, false]);
                    setModalResponse('Sucesso ao Exportar');
                    setModalLog(0);
                    const blob = new Blob([exp.data], { type: exp.headers["Content-Type"] });
                    const objectUrl = window.URL.createObjectURL(blob);

                    const link = document.createElement('a');
                    link.href = objectUrl;
                    link.setAttribute(
                        'download',
                        'relatório_de_vendas.xlsx',
                    );

                    // Append to html link element page
                    document.body.appendChild(link);

                    // Start download
                    link.click();

                    // // Clean up and remove the link
                    link?.parentNode?.removeChild(link);
                } else {
                    setModalResponse('Erro ao Exportar');
                    setModalLog(1);
                }

            } catch (error: any) {
                setModalResponse('Erro ao Exportar');
                setModalLog(1);
            }
        }
        exporting();
    }

    if(defaultAccounts !== null) {
        return (
            <>
                <DesktopDefault>
                    <div className="container-fluid content-dashboard cashflow-report">
                        <Breadcrumb title={'Financeiro / Relatórios / Relatório de Fluxo de Caixa'} />
                        <div className="suppliers mt-4 d-flex justify-concolumntent-between align-items-center">
                            <h2 className="title" style={{ color: "#707070" }}>Relatório de Fluxo de Caixa</h2>
                        </div>

                        <FilterReportCashFlow buscar={buscar} defaultAccounts={defaultAccounts} defaultDate={defaultDate} />

                        {totalRows > 0
                            ?
                            <ChartReportCashFlow infoChart={infoChart} loading={loading} minValue={minValue} maxValue={maxValue} />
                            :
                            <></>
                        } 
                        {/* <ChartReportCashFlow infoChart={infoChart} loading={loading} /> */}

                        <TableReportCashFlow info={info} loading={loading} printReport={printReport} exportReport={exportReport} totalRows={totalRows} pageCount={pageCount} setPageCount={setPageCount} rowsPerPage={rowsPerPage} setRowsPerPage={setRowsPerPage} loadButton={loadButton} />

                        <Modal
                            className="modal-confirm"
                            show={modalShow === true}
                            onHide={() => {
                                setModalLog(null);
                                setModalShow(false);
                            }}
                            size="lg"
                            aria-labelledby="contained-modal-title-vcenter"
                        >
                            {modalLog !== null
                                ?
                                <div className='modal-body text-center'>
                                    <div>
                                        {modalLog === 0
                                            ?
                                            <FontAwesomeIcon
                                                icon={["fal", "check-circle"]}
                                                size="5x"
                                                className="text-primary"
                                                style={{ fontSize: "7.5em" }}
                                            />
                                            :
                                            <FontAwesomeIcon
                                                icon={["fal", "times-circle"]}
                                                size="5x"
                                                className="text-primary"
                                                style={{ fontSize: "7.5em"}}
                                            />
                                        }
                                    </div>

                                    <div className="px-md-5" style={{ padding: "0 3rem" }}>
                                        {modalResponse}
                                    </div>
                                    <div>
                                        <Button type="submit" className="btn btn-primary form-button px-4 py-2" onClick={() => setModalShow(false)}>Fechar</Button>
                                    </div>
                                </div>
                                :

                                <div className="modal-body loading-modal">
                                    <div className="text-center">
                                        <div className="load"></div>
                                    </div>
                                    <div className='d-flex justify-content-center'>
                                        <span>Processando</span>
                                    </div>
                                    <div></div>
                                </div>
                            }
                        </Modal>
                    </div>
                </DesktopDefault>
            </>

        );
    } else {
        return (<></>);
    }
}

export default ReportCashFlow;