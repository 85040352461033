import React, { useEffect } from "react";
import { Button, Col, Form, Row } from "react-bootstrap";
import { Controller, useForm } from "react-hook-form";
import { ErrorMessage } from "@hookform/error-message";
import ListModalityForSigXSig from "../../C2Points/ListModalityForSigXSig";

export interface propsInfo {
    setControlIntegrationModal: any;
    integrationSig: any;
    setIntegrationSig: any;
    supplierIdentity: any;
}

const IntegrationSig: React.FC<propsInfo> = ({
    setControlIntegrationModal, integrationSig, setIntegrationSig, supplierIdentity
}: propsInfo) => {

    const {
        control,
        handleSubmit,
        watch,
        setValue,
        formState: { errors },
    } = useForm({});

    const watchProductType = watch(`productTypeDestination`, '1');

    const onSubmit = (data: any) => {
        setIntegrationSig({
            "productIntegrationCode": data.productIntegrationCode.value,
            "productTypeDestination": data.productTypeDestination,
            "pickupDestination": data.pickupDestination
        })

        setControlIntegrationModal('');
    }

    useEffect(() => {
        if (integrationSig !== null) {
            setValue('productIntegrationCode', integrationSig.productIntegrationCode)
            setValue('productTypeDestination', integrationSig.productTypeDestination)
            setValue('pickupDestination', integrationSig.pickupDestination)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return (
        <>
            <div className="integration-type d-flex">
                <div className="ball outside"><div className='inside'></div></div>
                <span className="integration-name">Integração SIG</span>
            </div>
            <Form className="h-100" noValidate validated={false} onSubmit={handleSubmit(onSubmit)}>
                <Row>
                    <Form.Group as={Col} md="4" controlId="" className="mt-2">
                        <Form.Label className="label-small">
                            Código da integração do produto
                        </Form.Label>

                        <Controller
                            control={control}
                            name="productIntegrationCode"
                            rules={{ required: { value: true, message: "Por favor, informe esse campo" } }}
                            render={({ field }: any) => (
                                <ListModalityForSigXSig propsField={field} propsErrors={errors} supplierIdentity={supplierIdentity} setValue={setValue} defaultValue={integrationSig !== null ? integrationSig.productIntegrationCode: null}/>
                            )}
                        />

                        <ErrorMessage
                            errors={errors}
                            name="productIntegrationCode"
                            render={({ message }) => (
                                <small style={{ color: "red" }}>{message}</small>
                            )}
                        />
                    </Form.Group>

                    <Form.Group as={Col} md="4" className="mt-2">
                        <Form.Label className="label-small">
                            Tipo de destino
                        </Form.Label>

                        <Controller
                            control={control}
                            name={`productTypeDestination`}
                            defaultValue={'1'}
                            rules={{ required: { value: true, message: 'Por favor, informe o campo' } }}
                            render={({ field }: any) => (
                                <div className="endpoint">
                                    <Form.Select
                                        {...field}
                                        as="select"
                                        variant="standard"
                                        margin="normal"
                                        className="form-control"
                                        required
                                    >
                                        <option value='1'>Tour</option>
                                        <option value='4'>Ticket</option>
                                    </Form.Select>
                                </div>
                            )}
                        />
                        <ErrorMessage
                            errors={errors}
                            name={`productTypeDestination`}
                            render={({ message }) => <small style={{ color: "red" }}>{message}</small>}
                        />
                    </Form.Group>

                    {watchProductType === '1' &&
                        <Form.Group as={Col} md="4" className="mt-2">
                            <Form.Label className="label-small">
                                Pickup
                            </Form.Label>

                            <Controller
                                control={control}
                                name={`pickupDestination`}
                                rules={{ required: { value: true, message: 'Por favor, informe o campo' } }}
                                render={({ field }: any) => (
                                    <Form.Control
                                        {...field}
                                        type="text"
                                        aria-invalid={errors?.detailsPT ? "true" : ""}
                                        variant="standard"
                                        margin="normal"
                                        required
                                        autoComplete='off'
                                    />
                                )}
                            />
                            <ErrorMessage
                                errors={errors}
                                name={`pickupDestination`}
                                render={({ message }) => <small style={{ color: "red" }}>{message}</small>}
                            />
                        </Form.Group>
                    }
                </Row>

                <div className="h-100 d-flex justify-content-end align-items-end">
                    <Row className="mx-0 mt-5">
                        <Button
                            variant="outline-primary"
                            className="form-button mr-3"
                            type="button"
                            onClick={() => setControlIntegrationModal('')}
                        >
                            Cancelar
                        </Button>
                        <Button
                            type="submit"
                            className="form-button"
                        >
                            Cadastrar
                        </Button>
                    </Row>

                </div>

            </Form>

        </>
    )
}

export default IntegrationSig;