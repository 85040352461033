import React, { useEffect, useState } from 'react';
import Select from 'react-select';
import api from '../../services/api';

import { useTranslation } from "react-i18next";

import Form from 'react-bootstrap/Form';

export interface propPlus {
    propsField: any,
    propsLabel: any,
    propsErrors: any,
    actionCountrys: any
};


const GetCountrysCheckout: React.FC<propPlus> = ({
    propsField, propsLabel, propsErrors, actionCountrys
}: propPlus) => {
    const { t } = useTranslation();

    const [info, setInfo] = useState<any>(null);

    const [isMenuOpen, setIsMenuOpen] = useState<boolean>(false);

    const onMenuOpen = () => {
        if (isMenuOpen === false) {
            setIsMenuOpen(true);
        } else {
            setIsMenuOpen(false);
        }
    }

    useEffect(() => {
        const token = localStorage.getItem('GroupId') || '{}';
        const config = {
            headers: { 'Authorization': `Bearer ${token}` },
        };
        async function listCountrys() {
            try {
                const { data } = await api.get(`/DropDown/GetCountrysWithShortName`, config);
                if (data.status !== 400) {
                    setInfo(data.data)
                }
            } catch (error: any) {
                if (error?.response?.status === 401) {
                    window.location.href = window.location.origin + '/';
                }

            }
        }
        listCountrys();
    }, []);

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    function handleChange(options: any) {
        if (actionCountrys) {
            actionCountrys(options);
        }
    }

    if (info !== null) {

        return (
            <>
                <div className={propsErrors.country !== undefined ? "endpoint-error" : "endpoint"}>
                    <Select
                        aria-labelledby="aria-label"
                        inputId="aria-example-input"
                        name="aria-live-color"
                        onMenuOpen={onMenuOpen}
                        onMenuClose={onMenuOpen}
                        placeholder={t('accordionInfoCompany.endpoint')}
                        options={Array.from(info)}
                        getOptionLabel={(option: any) => `${option.description}`}
                        getOptionValue={(option: any) => option.id}                        
                        onChange={handleChange}
                    //value={info.description}
                    />
                </div>
            </>
        );
    } else {
        return (
            <>
                <Form.Select>
                    <option value='' disabled></option>
                </Form.Select>
            </>
        )

    }
}

export default GetCountrysCheckout;