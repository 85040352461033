import React, { useState } from "react";
import api from '../../../../../services/api';

import { useTranslation } from 'react-i18next';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Controller, useForm } from "react-hook-form";
import { ErrorMessage } from "@hookform/error-message";
import { Form, Modal, Col, Dropdown } from "react-bootstrap";
import BootstrapTable from "react-bootstrap-table-next";
import "../../../../../assets/sass/table.scss";
import "react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css";
import "react-bootstrap-table-next/dist/react-bootstrap-table2.css";

import Pagination from "../../../../../components/Pagination/Pagination";

import "./SuppliersTable.scss";

export interface propTable {
  buscar: any;
  suppliers: any;
  loading: any;
  totalRows: any;
  pageCount: any;
  setPageCount: any;
  rowsPerPage: any;
  setRowsPerPage: any;
}

const SuppliersTable: React.FC<propTable> = ({
  buscar, suppliers, loading, totalRows, pageCount, setPageCount, rowsPerPage, setRowsPerPage
}: propTable) => {
  const { t } = useTranslation();
  const [supplierId, setSupplierId] = useState<any>(null);
  const [modalUpdateStatus, setModalUpdateStatus] = useState<any>(false);

  const [modalConfirmStatus, setModalConfirmStatus] = useState<any>(false);
  const [modalConfirmStatusLog, setModalConfirmStatusLog] = useState<any>(0);
  const [modalConfirmStatusMessage, setModalConfirmStatusMessage] = useState<any>('');

  const handleClickStatus = (row: any) => {
    setSupplierId(row.id);
    setModalUpdateStatus(true)
  }

  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const onSubmit = (data: any) => {

    async function updateStatus() {
      const token = localStorage.getItem('GroupId') || '{}';
      const config = {
        headers: { 'Authorization': `Bearer ${token}`, 'Content-Type': 'application/json' },
      };
      try {
        const res = await api.post('/Supplier/UpdateStatusSupplierAsync',
          {
            "status": parseInt(data.updateStatus),
            "supplierId": parseInt(supplierId)
          }, config);

        if (res.status !== 400) {
          if (res.data.data.log === 0) {
            setModalConfirmStatusLog(0);
            setModalUpdateStatus(false);
            setModalConfirmStatus(true);
            setModalConfirmStatusMessage(res.data.data.texto)
            buscar();
          } else {
            setModalConfirmStatusLog(1);
            setModalConfirmStatus(true);
            setModalConfirmStatusMessage(res.data.data.texto || "Error ao processar operação");
          }
        }
      } catch (error: any) {

      }
    }
    updateStatus();
  }


  function addActionButton(cell: any, row: any) {
    return (
      <>
        <div className="d-flex justify-content-center">
          <Dropdown drop="start">
            <Dropdown.Toggle variant="light ">
              <FontAwesomeIcon size="lg" icon={["fal", "ellipsis-h"]} />
            </Dropdown.Toggle>

            <Dropdown.Menu>
              {/* <Dropdown.Item eventKey="1" disabled={true} ><FontAwesomeIcon icon={['fal', 'comment-alt-dollar']} />{t('suppliersTable.account')}</Dropdown.Item> */}
              <Dropdown.Item eventKey="1" href={"/suppliers/edit-suppliers?id=" + cell}><FontAwesomeIcon icon={['fal', 'edit']} />{t('suppliersTable.edit')}</Dropdown.Item>
              <Dropdown.Item eventKey="2" onClick={() => { handleClickStatus(row); }}><FontAwesomeIcon icon={['fal', 'circle']} />{t('suppliersTable.status')}</Dropdown.Item>
              {/* <Dropdown.Item eventKey="5" disabled={true}><FontAwesomeIcon icon={['fal', 'file-contract']} />{t('suppliersTable.contract')}</Dropdown.Item>
              <Dropdown.Item eventKey="4" disabled={true} className="text-danger remove"><FontAwesomeIcon icon={['fal', 'trash-alt']} />{t('suppliersTable.remove')}</Dropdown.Item> */}
            </Dropdown.Menu>
          </Dropdown>
        </div>
      </>
    );
  }

  /*converte a data UTC para a data local do usuário*/
  function convertUTCDateToLocalDate(date: any) {
    var newDate = new Date(date.getTime() + date.getTimezoneOffset() * 60 * 1000);

    var offset = date.getTimezoneOffset() / 60;
    var hours = date.getHours();

    newDate.setHours(hours - offset);

    return newDate.toLocaleString().split(' ')[0];
  }

  function convertDate(cell: any, row: any) {
    var date = convertUTCDateToLocalDate(new Date(cell));
    /* console.log(date) */
    return (
      <>
        {date}
      </>
    );
  }

  function addStatus(cell: any, row: any) {
    return (
      <div
        className={
          cell === "Em Análise"
            ? "bg-suppliers-analise"
            : cell === "Ativo"
              ? "bg-suppliers-ativo"
              : cell === "Inativo"
                ? "bg-suppliers-inativo"
                : ""
        }
      >
        {cell}
      </div>
    );
  }

  const columns = [
    { dataField: "nomeFantasia", text: `${t("listSuppliers.tableName")}`, sort: true },
    { dataField: "state", text: `${t("listSuppliers.tableLocation")}`, sort: true },
    /* { dataField: "compPhone", text: `${t("listSuppliers.tableTel")}`, sort: true }, */
    { dataField: "dataCadastro", text: `${t("listSuppliers.tableDate")}`, sort: true, formatter: convertDate, },
    { dataField: "typeDesc", text: `${t("listSuppliers.filterClientType")}`, sort: true },
    { dataField: "statusDesc", text: `${t("listSuppliers.tableStatus")}`, sort: true, formatter: addStatus, },
    { dataField: "id", text: `${t("listSuppliers.tableAction")}`, formatter: addActionButton },
  ];

  /* loading table */

  function loadingFunc() {
    return (
      <>
        <div className="animated-background row-loading"></div>
      </>
    )
  }

  const loadingColumns = [{ dataField: "contasd", text: "Carregando Fornecedores", formatter: loadingFunc }];

  const loadingProducts = [
    { contas: "" }, { contas: "" }, { contas: "" }, { contas: "" }, { contas: "" },
    { contas: "" }, { contas: "" }, { contas: "" }, { contas: "" }, { contas: "" }
  ];
  /* [END] loading table */


  /* In case of empty table*/
  const notFoundColumns = [{ dataField: "clientes", text: "Clientes" }];

  const notFoundProducts = [{ clientes: "Nenhum cliente cadastrado" }];
  /* [END] In case of empty table*/


  const rowClasses = (row: any, rowIndex: any) => {
    if (row.statusDesc === "Em Análise") {
      return 'border-analise';
    } else if (row.statusDesc === "Ativo") {
      return 'border-ativo';
    } else {
      return 'border-inativo';
    }
  };

  if (suppliers !== null) {
    return (
      <>
        <div className="suppliers-table table-default">
          <div className="table-container">
            <div className="table-title">
              <h3 className="">{t("listSuppliers.tableTitle")}</h3>
            </div>

            <BootstrapTable
              bootstrap4
              keyField="id"
              data={suppliers}
              columns={columns}
              rowClasses={rowClasses}
            />
            <Pagination
              totalRows={totalRows}
              pageCount={pageCount}
              setPageCount={setPageCount}
              rowsPerPage={rowsPerPage}
              setRowsPerPage={setRowsPerPage}
              selectNumberRows="yes"
            />
          </div>
        </div>
        <Modal
          className="modalAuth"
          show={modalUpdateStatus}
          onHide={() => { setSupplierId(null); setModalUpdateStatus(false); }}
          size="lg"
          aria-labelledby="contained-modal-title-vcenter"
        >
          <>
            <div className='status-modal'>
              <Form className="modal-content" noValidate validated={false} onSubmit={handleSubmit(onSubmit)} autoComplete="false">
                <Modal.Body>

                  <div className="modal-header rounded-0">
                    <div>
                      <h4 className="pt-1 text-primary title-reservation">Editar Status</h4>
                      <small>

                      </small>
                    </div>

                    <div className='buttons-options'>
                      <div>

                      </div>
                      <div>
                        <FontAwesomeIcon
                          icon={['fal', 'times']}
                          className="mx-3 text-info"
                          size="lg"
                          data-dismiss="modal"
                          aria-label="Close"
                          onClick={() => { setSupplierId(null); setModalUpdateStatus(false) }}
                        />
                      </div>
                    </div>
                  </div>

                  <hr className='w-100 my-1' style={{ borderBottom: '1px solid' }} />

                  <div>
                    <Form.Group as={Col} md="5" controlId="formInfoCompanySubscription">
                      <Form.Label>Tem certeza que deseja alterar o status?</Form.Label>
                      <Controller
                        control={control}
                        name="updateStatus"
                        rules={{ required: { value: true, message: 'Por favor, escolha uma opção.' } }}
                        defaultValue={'2'}
                        render={({ field }: any) => (
                          <Form.Select
                            {...field}
                            as="select"
                            variant="standard"
                            margin="normal"
                            style={{ border: "1px solid #ced4da" }}
                            required
                          >
                            <option value=''>Selecione</option>
                            <option value='1'>Em Análise</option>
                            <option value='2'>Ativar</option>
                            <option value='3'>Desativar</option>
                          </Form.Select>
                        )}
                      />
                      <ErrorMessage
                        errors={errors}
                        name="updateStatus"
                        render={({ message }) => <small style={{ color: "red" }}>{message}</small>}
                      />
                    </Form.Group>
                  </div>
                </Modal.Body>
                <Modal.Footer>
                  <div className="modal-footer rounded-0 border-0">
                    <button type="button" className="btn-default mr-2 text-uppercase btn btn-outline-primary" data-dismiss="modal" onClick={() => { setSupplierId(null); setModalUpdateStatus(false) }}><i className="fas fa-times fa-fw"></i> <span data-vouchercampo="close">Cancelar</span></button>
                    <button type="submit" className="btn-default text-uppercase btn btn-primary">
                      {loading === true ? <div className="load"></div> : "Confirmar"}
                    </button>
                  </div>
                </Modal.Footer>
              </Form>
            </div>
          </>
        </Modal>

        <Modal
          className="modalAuth"
          show={modalConfirmStatus}
          onHide={() => { setModalConfirmStatus(false); }}
          aria-labelledby="contained-modal-title-vcenter"
        >
          <>
            <Modal.Body className='modal-body text-center sucess-pos d-flex justify-content-evenly' style={{ height: "600px" }}>
              <div>
                {modalConfirmStatusLog === 0
                  ?
                  <FontAwesomeIcon
                    icon={["fal", "check-circle"]}
                    size="5x"
                    className="text-primary"
                    style={{ fontSize: "7.5em" }}
                  />
                  :
                  <FontAwesomeIcon
                    icon={["fal", "times-circle"]}
                    size="5x"
                    className="text-primary"
                    style={{ fontSize: "7.5em" }}
                  />
                }
              </div>
              <div>
                {modalConfirmStatusMessage}
              </div>
              <div className="d-flex justify-content-center pt-3">
                <button
                  onClick={() => { setModalConfirmStatus(false); }}
                  className="btn btn-primary mx-2 w-25"
                >
                  Confirmar
                </button>
              </div>

            </Modal.Body>
          </>
        </Modal>
      </>
    );
  } else if (suppliers === null && loading === true) {
    return (
      <>
        <div className="table-default suppliers-table loading not-found">
          <div className="table-container">
            <BootstrapTable
              bootstrap4
              keyField="id"
              data={loadingProducts}
              hover={true}
              columns={loadingColumns}
            />
            <Pagination
              totalRows={totalRows}
              pageCount={pageCount}
              setPageCount={setPageCount}
              rowsPerPage={rowsPerPage}
              setRowsPerPage={setRowsPerPage}
              selectNumberRows="yes"
            />
          </div>
        </div>
      </>
    )
  } else {
    return (
      <>
        <div className="table-default suppliers-table not-found">
          <div className="table-container">
            <div className="table-title">
              <h3 className="">{t("listSuppliers.tableTitle")}</h3>
            </div>
            <BootstrapTable
              bootstrap4
              keyField="id"
              data={notFoundProducts}
              hover={true}
              columns={notFoundColumns}
            />
            <Pagination
              totalRows={totalRows}
              pageCount={pageCount}
              setPageCount={setPageCount}
              rowsPerPage={rowsPerPage}
              setRowsPerPage={setRowsPerPage}
              selectNumberRows="yes"
            />
          </div>
        </div>
      </>
    );
  }
}

export default SuppliersTable;
