import React, { useState, useEffect, useRef } from "react";

// import { useTranslation } from "react-i18next";

import "../DropdownNumberPeopleIntegrated/DropdownNumberPeopleIntegrated.scss";

//import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
//import people from "../../assets/icons/majesticons_users-line.svg";
import iconArrow from '../../assets/icons/arrow_white.svg';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useTranslation } from "react-i18next";
import SimpleAlert from "../Alert/SimpleAlert/SimpleAlert";
//import SimpleAlert from "../Alert/SimpleAlert/SimpleAlert";

export interface propsR {
    tariffs?: any;
    actionPeople: any;
    info: any;
    dateTour: any;
    index: any;
    isIntegration?: any;
    ranges: any;
    defaultNumberPeople: any;
}

const DropdownNumberPeopleIntegrated: React.FC<propsR> = ({
    tariffs = null,
    actionPeople,
    info,
    dateTour,
    index,
    isIntegration,
    ranges,
    defaultNumberPeople
}: propsR) => {
    console.log(defaultNumberPeople)
    const [numberAdults, setNumberAdults] = useState<number>(defaultNumberPeople[index].adults || 0);
    const [numberChilds, setNumberChilds] = useState<number>(defaultNumberPeople[index].childs || 0);
    const [numberInfants, setNumberInfants] = useState<number>(defaultNumberPeople[index].infants || 0);
    const [numberStudent, setNumberStudent] = useState<number>(defaultNumberPeople[index].student || 0);
    const [numberElders, setNumberElders] = useState<number>(defaultNumberPeople[index].elders || 0);
    const [numberPeople, setNumberPeople] = useState<number>(1);
    const [numberStockTotal, setNumberStockTotal] = useState<number>(1);
    const [numberTotal, setNumberTotal] = useState<number>(1);
    const [numberTotalPeople, setNumberTotalPeople] = useState<number>(1);

    const [isOpen, setIsOpen] = useState<any>(false);

    const { t } = useTranslation();

    /* Detecta clique fora da div#wrapper para fechar*/

    const wrapperRef = useRef(null);
    useOutsideAlerter(wrapperRef);

    function useOutsideAlerter(ref: any) {
        useEffect(() => {
            function handleClickOutside(event: any) {
                if (ref.current && !ref.current.contains(event.target)) {
                    setIsOpen(false);
                }
            }
            document.addEventListener("mousedown", handleClickOutside);
            return () => {
                document.removeEventListener("mousedown", handleClickOutside);
            };
        }, [ref]);
    }

    /* Detecta clique fora da div#wrapper para fechar\\ */

    const handleClickPeoples = () => {
        isOpen === false ? setIsOpen(true) : setIsOpen(false);
    };

    const handleClickAdults = (controll: string) => {
        let newNumber = numberAdults;
        if (controll === "sub") {
            if (numberAdults === 0) {
                return false;
            } else {
                newNumber = numberAdults - ranges.incrementAdult;
                if (ranges.flagStockAdult === 1) {
                    setNumberStockTotal(numberStockTotal - ranges.incrementAdult);
                }
            }
        }
        if (controll === "plus") {
            if (ranges.flagStockAdult === 1) {
                if (numberStockTotal + ranges.incrementAdult <= dateTour[index].maxStockNumber) {
                    newNumber = numberAdults + ranges.incrementAdult;
                    setNumberStockTotal(numberStockTotal + ranges.incrementAdult);
                } else {
                    return false;
                }
            } else {
                newNumber = numberAdults + ranges.incrementAdult;
            }
        }
        return setNumberAdults(newNumber);
    };

    const handleClickChilds = (controll: string) => {
        let newNumber = numberChilds;
        if (controll === "sub") {
            if (numberChilds === 0) {
                return false;
            } else {
                newNumber = numberChilds - ranges.incrementChild;
                if (ranges.flagStockChild === 1) {
                    setNumberStockTotal(numberStockTotal - ranges.incrementChild);
                }
            }
        }
        if (controll === "plus") {
            if (ranges.flagStockChild === 1) {
                if (numberStockTotal + ranges.incrementChild <= dateTour[index].maxStockNumber) {
                    newNumber = numberChilds + ranges.incrementChild;
                    setNumberStockTotal(numberStockTotal + ranges.incrementChild);
                } else {
                    return false;
                }
            } else {
                newNumber = numberChilds + ranges.incrementChild;
            }
        }
        return setNumberChilds(newNumber);
    };

    const handleClickInfants = (controll: string) => {
        let newNumber = numberInfants;
        if (controll === "sub") {
            if (numberInfants === 0) {
                return false;
            } else {
                newNumber = numberInfants - ranges.incrementInfant;
                if (ranges.flagStockInfant === 1) {
                    setNumberStockTotal(numberStockTotal - ranges.incrementInfant);
                }
            }
        }
        if (controll === "plus") {
            if (ranges.flagStockInfant === 1) {
                if (numberStockTotal + ranges.incrementInfant <= dateTour[index].maxStockNumber) {
                    newNumber = numberInfants + ranges.incrementInfant;
                    setNumberStockTotal(numberStockTotal + ranges.incrementInfant);
                } else {
                    return false;
                }
            } else {
                newNumber = numberInfants + ranges.incrementInfant;
            }
        }
        return setNumberInfants(newNumber);
    };

    const handleClickStudent = (controll: string) => {
        let newNumber = numberStudent;
        if (controll === "sub") {
            if (numberStudent === 0) {
                return false;
            } else {
                newNumber = numberStudent - ranges.incrementStudent;
                setNumberStockTotal(numberStockTotal - ranges.incrementStudent);
            }
        }
        if (controll === "plus") {
            if (ranges.flagStockStudent === 1) {
                if (numberStockTotal + ranges.incrementStudent <= dateTour[index].maxStockNumber) {
                    newNumber = numberStudent + ranges.incrementStudent;
                    setNumberStockTotal(numberStockTotal + ranges.incrementStudent);
                } else {
                    return false;
                }
            } else {
                newNumber = numberStudent + ranges.incrementStudent;
            }
        }
        return setNumberStudent(newNumber);
    };

    const handleClickElders = (controll: string) => {
        let newNumber = numberElders;
        if (controll === "sub") {
            if (numberElders === 0) {
                return false;
            } else {
                newNumber = numberElders - ranges.incrementElders;
                setNumberStockTotal(numberStockTotal - ranges.incrementElders);
            }
        }
        if (controll === "plus") {
            if (ranges.flagStockElders === 1) {
                if (numberStockTotal + ranges.incrementElders <= dateTour[index].maxStockNumber) {
                    newNumber = numberElders + ranges.incrementElders;
                    setNumberStockTotal(numberStockTotal + ranges.incrementElders);
                } else {
                    return false;
                }
            } else {
                newNumber = numberElders + ranges.incrementElders;
            }
        }
        return setNumberElders(newNumber);
    };

    const handleClickPeople = (controll: string) => {
        let newNumber = numberPeople;
        if (controll === "sub") {
            if (numberPeople === 0) {
                return false;
            } else {
                newNumber = numberPeople - ranges.incrementPrivate;
                setNumberStockTotal(numberStockTotal - ranges.incrementPrivate);
            }
        }
        if (controll === "plus") {
            if (ranges.flagStockPrivate === 1) {
                if (numberStockTotal + ranges.incrementPrivate <= dateTour[index].maxStockNumber) {
                    newNumber = numberPeople + ranges.incrementPrivate;
                    setNumberStockTotal(numberStockTotal + ranges.incrementPrivate);
                } else {
                    return false;
                }
            } else {
                newNumber = numberPeople + ranges.incrementPrivate;
            }
        }
        return setNumberPeople(newNumber);
    };


    useEffect(() => {
        setNumberTotal(numberAdults + numberChilds + numberInfants + numberStudent + numberElders)
        setNumberTotalPeople(numberPeople)
        const numberSend = {
            adults: /* dateTour[index]?.idSellingType === 1 ? 0 :  */numberAdults,
            childs: numberChilds,
            infants: numberInfants,
            elders: numberElders,
            student: numberStudent,
            globalPeople: dateTour[index]?.idSellingType === 2 ? 0 : numberPeople,
        };
        console.log(numberSend)
        actionPeople(numberSend, index);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [numberAdults, numberChilds, numberInfants, numberPeople, numberStudent, numberElders]);

    useEffect(() => {
        setNumberAdults(defaultNumberPeople[index].adults || 0);
        setNumberChilds(defaultNumberPeople[index].childs || 0);
        setNumberInfants(defaultNumberPeople[index].infants || 0);
        setNumberStudent(defaultNumberPeople[index].student || 0);
        setNumberElders(defaultNumberPeople[index].elders || 0);
        setNumberPeople(0);
        setNumberStockTotal(0)
        setNumberTotal(0);
        setNumberTotalPeople(0);

        if (isIntegration === true) {
            dateTour[index].maxStockNumber = 9999999999;
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dateTour[index]?.time, dateTour[index]?.data])



    const RenderPeople = () => {
        return (
            <>
                {ranges.isActiveAdult === true ?
                    <div className="qtyButtons">
                        <label>
                            {ranges.labelAdult}
                            {/* {<small id="idade-adulto"> (11-100)</small>} */}
                            <span
                                className="price-dropdown text-dark font-weight-bold w-100 d-block"
                                id="valor-adulto-sm"
                            >
                                R$ {(dateTour[index]?.adult).toFixed(2).split('.').join(',')}
                            </span>
                        </label>
                        <div className="qtyDec" onClick={() => handleClickAdults("sub")}>
                            -
                        </div>
                        <input
                            type="text"
                            name="qtyInput"
                            value={numberAdults}
                            onChange={() => { }}
                            id="numero-adulto"
                            className="people-input"
                            autoComplete='off'
                        />
                        <div className="qtyInc" onClick={() => handleClickAdults("plus")}>
                            +
                        </div>
                    </div>
                    : <></>}
                {ranges.isActiveChild === true ?
                    <div className="qtyButtons">
                        <label>
                            {ranges.labelChild}
                            <span
                                className="price-dropdown text-dark font-weight-bold w-100 d-block"
                                id="valor-crianca-sm"
                            >
                                {" "}
                                R$ {(dateTour[index]?.child).toFixed(2).split('.').join(',')}
                            </span>
                        </label>
                        <div className="qtyDec" onClick={() => handleClickChilds("sub")}>
                            -
                        </div>
                        <input
                            type="text"
                            name="qtyInput"
                            value={numberChilds}
                            onChange={() => { }}
                            id="numero-crianca"
                            autoComplete='off'
                        />
                        <div className="qtyInc" onClick={() => handleClickChilds("plus")}>
                            +
                        </div>
                    </div>
                    : <></>}
                {ranges.isActiveInfant === true ?
                    <div className="qtyButtons">
                        <label>
                            {ranges.labelInfant}
                            <span
                                className="price-dropdown text-dark font-weight-bold w-100 d-block"
                                id="valor-infat-sm"
                            >
                                {" "}
                                R$ {(dateTour[index]?.infant).toFixed(2).split('.').join(',')}
                            </span>
                        </label>
                        <div className="qtyDec" onClick={() => handleClickInfants("sub")}>
                            -
                        </div>
                        <input
                            type="text"
                            name="qtyInput"
                            value={numberInfants}
                            onChange={() => { }}
                            id="numero-infantil"
                            autoComplete='off'
                        />
                        <div className="qtyInc" onClick={() => handleClickInfants("plus")}>
                            +
                        </div>
                    </div>
                    : <></>}
                {ranges.isActiveElders === true ?
                    <div className="qtyButtons">
                        <label>
                            {ranges.labelElders}
                            {/* {<small id="idade-crianca"> (+ 60 anos)</small>} */}
                            <small id="idade-crianca"></small>
                            <span
                                className="price-dropdown text-dark font-weight-bold w-100 d-block"
                                id="valor-infat-sm"
                            >
                                {" "}
                                R$ {(dateTour[index]?.elders).toFixed(2).split('.').join(',')}
                            </span>
                        </label>
                        <div className="qtyDec" onClick={() => handleClickElders("sub")}>
                            -
                        </div>
                        <input
                            type="text"
                            name="qtyInput"
                            value={numberElders}
                            id="numero-infantil"
                            autoComplete='off'
                        />
                        <div className="qtyInc" onClick={() => handleClickElders("plus")}>
                            +
                        </div>
                    </div>
                    : <></>}
                {ranges.isActiveStudent === true ?
                    <div className="qtyButtons">
                        <label>
                            {ranges.labelStudent}
                            <small id="estudante"> </small>
                            <span
                                className="price-dropdown text-dark font-weight-bold w-100 d-block"
                                id="valor-infat-sm"
                            >
                                {" "}
                                R$ {(dateTour[index]?.student).toFixed(2).split('.').join(',')}
                            </span>
                        </label>
                        <div className="qtyDec" onClick={() => handleClickStudent("sub")}>
                            -
                        </div>
                        <input
                            type="text"
                            name="qtyInput"
                            value={numberStudent}
                            id="numero-infantil"
                            autoComplete='off'
                        />
                        <div className="qtyInc" onClick={() => handleClickStudent("plus")}>
                            +
                        </div>
                    </div>
                    : <></>}

                {ranges.isActivePrivate === true ?
                    <div className="qtyButtons">
                        <label>
                            {ranges.labelPrivate}
                            <span
                                className="price-dropdown text-dark font-weight-bold w-100 d-block"
                                id="valor-people-sm"
                            >
                                R$ {dateTour[index]?.global}
                            </span>
                        </label>
                        <div className="qtyDec" onClick={() => handleClickPeople("sub")}>
                            -
                        </div>
                        <input
                            type="text"
                            name="qtyInput"
                            value={numberPeople}
                            onChange={() => { }}
                            id="numero-people"
                            autoComplete='off'
                        />
                        <div className="qtyInc" onClick={() => handleClickPeople("plus")}>
                            +
                        </div>
                    </div>
                    : <></>}


                {numberStockTotal >= dateTour[index].maxStockNumber
                    ?
                    <SimpleAlert alertType="danger text-center mt-2">Limite do estoque atingido</SimpleAlert>
                    :
                    <></>
                }
            </>
        );

    };

    /* useEffect(() => {
        //setNumberTotal(numberAdults + numberChilds + numberInfants);
        setNumberTotalPeople(numberPeople);
    }, [numberAdults, numberChilds, numberInfants, numberPeople]); */


    if (isOpen === true) {
        return (
            <div id="wrapper" ref={wrapperRef} className="input-people">
                <div style={{ cursor: "pointer" }} onClick={() => handleClickPeoples()}>
                    <FontAwesomeIcon
                        icon={["fal", "male"]}
                        size="1x"
                        className="mr-2"
                        style={{ marginLeft: "3.25px" }}
                    />
                    <span style={{ marginLeft: "20px" }}>
                        {t("sales.tourList.accordion.people")}{" "}
                    </span>
                    <span
                        className="qtyTotal"
                        data-min="2"
                        data-max="19"
                        style={{ marginLeft: "5px" }}
                    >
                        {dateTour[index]?.idSellingType === 2
                            ? numberTotal
                            : dateTour[index]?.idSellingType === 1
                                ? numberTotalPeople
                                : ""}
                    </span>
                    <span style={{ marginLeft: "5px", color: "#FFF" }}>
                        {dateTour[index]?.idSellingType === 2
                            ? t("sales.tourList.accordion.passengers")
                            : dateTour[index]?.idSellingType === 1
                                ? t("sales.tourList.accordion.people")
                                : t("sales.tourList.accordion.passengers")}
                    </span>
                    <span className="input-calendar-custom arrow py-0 py-md-2" id="basic-addon1">
                        <img src={iconArrow} alt="pesquisar" className="py-1" />
                    </span>
                </div>
                <div className="panel-dropdown-content right active">
                    <RenderPeople />
                    <p id="alertNumber"></p>
                </div>
            </div>
        );
    } else {
        return (
            <>
                <div className="input-people">
                    <div style={{ cursor: "pointer" }} onClick={() => handleClickPeoples()}>
                        <span className="input-calendar-custom icon py-0 py-md-2" id="basic-addon1">

                            <FontAwesomeIcon
                                icon={["fal", "male"]}
                                size="1x"
                                className="mr-2"
                                style={{ marginLeft: "3.25px" }}
                            />

                            <span style={{ marginLeft: "20px" }}>
                                {t("sales.tourList.accordion.people")}{" "}
                            </span>

                        </span>
                        <span
                            className="qtyTotal"
                            data-min="2"
                            data-max="19"
                            style={{ marginLeft: "5px" }}
                        >
                            {dateTour[index]?.idSellingType === 2
                                ? numberTotal
                                : dateTour[index]?.idSellingType === 1
                                    ? numberTotalPeople
                                    : ""}
                        </span>
                        <span style={{ marginLeft: "5px", color: "#FFF" }}>
                            {dateTour[index]?.idSellingType === 2
                                ? t("sales.tourList.accordion.passengers")
                                : dateTour[index]?.idSellingType === 1
                                    ? t("sales.tourList.accordion.people")
                                    : t("sales.tourList.accordion.passengers")}
                        </span>
                        <span className="input-calendar-custom arrow py-0 py-md-2" id="basic-addon1">
                            <img src={iconArrow} alt="pesquisar" className="py-1" />
                        </span>
                    </div>
                </div>
            </>
        );
    }


};


export default DropdownNumberPeopleIntegrated;
