import React, { useEffect, useState } from "react";

import "./Blackout.scss"
import { Button, Col, Dropdown, Form, InputGroup, Row } from "react-bootstrap";
import { Controller, useForm } from "react-hook-form";
import ModalDefault from "../../../../components/Modal/ModalDefault/ModalDefault";
import SingleCalendar from "../../../../components/Calendar/SingleCalendar";
import BootstrapTable from "react-bootstrap-table-next";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export interface productTransfer {
    action: any,
    receiveObject: any,
    transferObject: any,
    isEdit: any
};

const Blackout: React.FC<productTransfer> = React.memo(({ action, receiveObject, transferObject, isEdit
}: productTransfer) => {

    const [data, setData] = useState<any>([]);
    const [desde, setDesde] = useState<any>();
    const [ate, setAte] = useState<any>();
    const validated = false;
    const [blackoutTb, setBlackoutTb] = useState<any>(data);

    const [textError, ] = useState<string>('');
    
    const {
        control,
        handleSubmit,
    } = useForm({});

    useEffect(() => {
        if (transferObject.blackout !== undefined) {
            let aux: any = [];

            for (let i = 0; i < transferObject.blackout.length; i++) {
                aux.push(
                    {
                        "ate": transferObject.blackout[i].ate,
                        "desde": transferObject.blackout[i].desde,
                        "id": transferObject.blackout[i].idTransfer
                    }
                )
            }
            //console.log(aux)
            setData(aux);
            setBlackoutTb(aux);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const AddToTable = () => {
        var aux: any = {
            "desde": desde.split("/").reverse().join("-"),
            "ate": ate.split("/").reverse().join("-"),
            "id": data.length + 1
        }
        setBlackoutTb([...blackoutTb, aux]);
    }


    const removeBlackout = (cell: any) => {
        var aux: any = [];
        blackoutTb.forEach((element: any) => {
            if (element.id !== cell) {
                aux.push(element);
            }
        });
        setBlackoutTb(aux);
    }


    function closeModal() {
        var closeBtn: any = document.getElementsByClassName("btn-close")[0];
        if (closeBtn !== undefined) {
            closeBtn.click();
        }
    }

    /*converte a data UTC para a data local do usuário*/
    /*  function convertUTCDateToLocalDate(date: any) {
         var newDate = new Date(date.getTime() + date.getTimezoneOffset() * 60 * 1000);
 
         var offset = date.getTimezoneOffset() / 60;
         var hours = date.getHours();
 
         newDate.setHours(hours - offset);
 
         return newDate.toLocaleString().split(' ')[0];
     } */

    function convertDate(cell: any, row: any) {
        var date = cell.split("T")[0].split("-").reverse().join("/");
        /* console.log(date) */
        return (
            <>
                {date}
            </>
        );
    }

    const columns = [
        {
            dataField: "desde",
            text: "Desde",
            formatter: convertDate
        },
        {
            dataField: "ate",
            text: "Até",
            formatter: convertDate
        },
        {
            dataField: "id",
            text: "Ações",
            formatter: addActionButton
        }
    ]



    const onSubmit = (data: any) => {
        /* if (blackoutTb.length === 0) {
            setTextError("Adicione ao menos um blackout!");

            setTimeout(() => {
                setTextError('');
            }, 2000);
        } else { */
        var aux: any = [];
        blackoutTb.forEach((elem: any) => {
            aux.push({
                idTransfer: elem.id,
                desde: elem.desde,
                ate: elem.ate
            })
        })
        delete data.desde;
        delete data.ate;

        data.blackout = aux

        if (isEdit !== true) {
            action('administrative', data);
        } else {
            receiveObject(data);
        }
        /*  } */

    }

    function addActionButton(cell: any, row: any) {
        return (
            <>
                <div className="d-flex justify-content-center">
                    <Dropdown drop="start">
                        <Dropdown.Toggle variant="light ">
                            <FontAwesomeIcon
                                size="lg"
                                icon={["fal", "ellipsis-h"]}
                            />
                        </Dropdown.Toggle>
                        <Dropdown.Menu>
                            <Dropdown.Item
                                eventKey="1"
                                onClick={() => removeBlackout(cell)}
                            >
                                <FontAwesomeIcon
                                    icon={['fal', 'trash-alt']}
                                />
                                Remover
                            </Dropdown.Item>
                        </Dropdown.Menu>
                    </Dropdown>
                </div>
            </>
        );
    }

    return (
        <>
            {/* CONTENT */}
            <Col md={9} lg={10}>
                <Form className="h-100" noValidate validated={validated} onSubmit={handleSubmit(onSubmit)}>
                    <div className='card h-100 border-0 p-4'>
                        <div>
                            <h3 className="pt-1 text-primary title-content">{"Blackout"}</h3>
                        </div>

                        {/* <Row className="my-3">
                            <Col md={'12'}>
                                <div className="alert alert-primary background-primary p-3">                      
                                <small className="text-primary">Os cartazes que você utiliza para receber seus passageiros não poderão mais ter o logo da C2Rio. Lembre-se que você deve continuar utilizando o logo da sua empresa junto com o nome e o sobrenome do passageiro titular da reserva.</small>
                                </div>
                            </Col>
                        </Row> */}

                        <section className="my-3">
                            <Row className="column-chooice">
                                <Col md="12">
                                    <div className="label-small">
                                        Selecione os dias em que esta transferência deixará de funcionar. Desta forma, você estará desativado para venda.
                                    </div>
                                    <div className="d-flex align-items-center w-auto mt-2">
                                        <ModalDefault
                                            title=" Adicionar Novo Blackout"
                                            name=" + Adicionar Novo Blackout "
                                            icon="no"
                                            typeIcon="plus-circle"
                                            classBtn="btn btn-outline-primary"
                                            classModal="modal-large"
                                        >

                                            <Form className="h-100" noValidate validated={validated} onSubmit={handleSubmit(onSubmit)} autoComplete="false">
                                                <div className='d-flex flex-column justify-content-between h-100'>
                                                    <div>
                                                        <Row>
                                                            <Form.Group as={Col} className="mb-3" controlId="formGridDate">
                                                                <Form.Label>Data de início</Form.Label>
                                                                <Controller
                                                                    control={control}
                                                                    name="desde"
                                                                    rules={{ required: { value: false, message: "Por favor, selecione uma data" }, }}
                                                                    render={({ field }: any) => (
                                                                        <InputGroup
                                                                            hasValidation
                                                                            className=""
                                                                            size="sm"
                                                                            placeholder="Quando?"
                                                                        >
                                                                            <SingleCalendar setValue={setDesde} />
                                                                        </InputGroup>
                                                                    )}
                                                                />
                                                            </Form.Group>

                                                            <Form.Group as={Col} className="mb-3" controlId="formGridVenc">
                                                                <Form.Label>Data Fim</Form.Label>
                                                                <Controller
                                                                    control={control}
                                                                    name="ate"
                                                                    rules={{ required: { value: false, message: "Por favor, selecione uma data" }, }}
                                                                    render={({ field }: any) => (
                                                                        <InputGroup
                                                                            hasValidation
                                                                            className=""
                                                                            size="sm"
                                                                            placeholder="Quando?"
                                                                        >
                                                                            <SingleCalendar setValue={setAte} />
                                                                        </InputGroup>
                                                                    )}
                                                                />
                                                            </Form.Group>
                                                            <div className="d-flex justify-content-end align-items-end ">
                                                                <Row className=" mx-0 mt-5">
                                                                    <Button
                                                                        onClick={() => { AddToTable(); closeModal() }}
                                                                        type="button"
                                                                        className="form-button"
                                                                    >
                                                                        Salvar
                                                                    </Button>
                                                                </Row>
                                                            </div>
                                                        </Row>

                                                    </div>
                                                </div>
                                            </Form>
                                        </ModalDefault>

                                        <div className="d-flex mx-3">
                                            <small style={{ color: "red" }}>{textError}</small>
                                        </div>
                                    </div>

                                </Col>



                            </Row>
                        </section>

                        <section className="my-3">
                            <Row>
                                <Col md="12">
                                    <div className="label-small">
                                        Dias
                                    </div>

                                    <div className="mt-4">
                                        <div className="table-default blackout-table">
                                            <div className="table-container">
                                                <BootstrapTable
                                                    bootstrap4
                                                    keyField="id"
                                                    data={blackoutTb}
                                                    columns={columns}
                                                    rowClasses={'border-analise'}
                                                    striped={true}
                                                />
                                            </div>
                                        </div>
                                    </div>


                                </Col>


                            </Row>
                        </section>

                        <section className="h-100 d-flex justify-content-end align-items-end">
                            {
                                isEdit !== true
                                    ?
                                    <Row className="mx-0 mt-5">
                                        <Button
                                            variant="outline-primary"
                                            className="form-button mr-3"
                                            onClick={() => action('contact')}
                                        >
                                            Voltar
                                        </Button>
                                        <Button
                                            type="submit"
                                            className="form-button"
                                        >
                                            Continuar
                                        </Button>
                                    </Row>
                                    :
                                    <Row className=" mx-0 mt-5">
                                        <Button
                                            type="submit"
                                            className="form-button"
                                        //onClick={() => action('meetingPoint')}
                                        >
                                            Salvar
                                        </Button>
                                    </Row>
                            }
                        </section>
                    </div>
                </Form>
            </Col>

        </>
    )
});

export default Blackout;