import React, { useEffect, useState } from "react";
import Form from 'react-bootstrap/Form';
import { Container, Row, Col, Accordion } from 'react-bootstrap';
import { Controller, useForm } from "react-hook-form";
import { ErrorMessage } from "@hookform/error-message";
import Select from 'react-select';
import api from "../../../../services/api";
import ModalShow from "../../../../components/Modal/ModalShow/ModalShow";
import GetProductsList from "../ProductList/GetProductsList";

export interface propPlus {
    setTarCode: any,
    setProdCode: any,
    setModCode: any
};

const FilterStock: React.FC<propPlus> = ({
    setTarCode, setProdCode, setModCode
}: propPlus) => {

    const [productCode, setProductCode] = useState<string | null>(null)
    const [modalities, setModalities] = useState<any>(null)
    const [modality, setModality] = useState<any>(null)
    const [tariffs, setTariffs] = useState<any>(null)
    //const [tariffsInfo, setTariffsInfo] = useState<any>(null)

    /* ModalControl */
    const [modalShow, setModalShow] = useState<boolean>(false);
    const [modalMessage, setModalMessage] = useState<string>("Carregando");
    const [modalLog, setModalLog] = useState<any>(null);

    const {
        control,
        setValue,
        watch,
        formState: { errors },
    } = useForm({});

    const watchProducts = watch(`products`, null);
    const watchModalities = watch(`modalities`, null);
    const watchTariffs = watch(`tariffs`, null);

    useEffect(() => {
        if (watchProducts) {
            setProductCode(watchProducts.value);
        }
    }, [watchProducts])

    useEffect(() => {
        resetForm();
        const token = localStorage.getItem('GroupId') || '{}';
        const config = {
            headers: { 'Authorization': `Bearer ${token}` },
        };
        const GetNewProductTourByProductCode = async () => {
            try {
                const { data } = await api.get(`/DropDown/GetActiveProductsModalityTour?ProductCode=${productCode}`, config);
                if(data.statusCode === 200) {
                    transformObject(data);
                    setModalShow(false);
                }else{
                    setModalLog(1);
                    setModalMessage(data.errorMessage);
                    setModalShow(true);
                }
            } catch (error: any) {
                setModalLog(1);
                setModalMessage("Erro ao processar operação, favor tentar mais tarde");
                setModalShow(true);
            }
        }

        if (productCode !== null) {
            setModalShow(true);
            GetNewProductTourByProductCode();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [productCode])

    const transformObject = (data: any) => {
        let aux: any = [];

        aux = data.data.map((elem: any, index: number) => {
            return (
                {
                    "value": elem.id,
                    "label": elem.description,
                }
            )
        })
        setModalities(aux);
    }

    useEffect(() => {
        
        if (watchModalities) {
            setModality(watchModalities.value);
            GetTariffs(watchModalities.value)
            setTarCode(null);
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [watchModalities])


    const token = localStorage.getItem('GroupId') || '{}';
    const config = {
        headers: { 'Authorization': `Bearer ${token}` },
    };
    const GetTariffs = async (modeCode: any) => {
        try {
            const { data } = await api.get(`/DropDown/GetActiveProductsTarCodeTour?ModCode=${modeCode}`, config);
            if(data.statusCode === 200) {
                transformObjectTarif(data);
                setModalShow(false);
            }else{
                setModalLog(1);
                setModalMessage(data.errorMessage);
                setModalShow(true);
            }
        } catch (error: any) {
            setModalLog(1);
            setModalMessage("Erro ao processar operação, favor tentar mais tarde");
            setModalShow(true);
        }
    }

    const transformObjectTarif = (data: any) => {
        let aux: any = [];

        aux = data.data.map((elem: any, index: number) => {
            return (
                {
                    "value": elem.id,
                    "label": elem.description,
                }
            )
        })
        setTariffs(aux);
    }


    useEffect(() => {
        
        if (watchTariffs) {
            setTarCode(watchTariffs.value);
            setProdCode(productCode);
            setModCode(modality);
            // GetInfoTariffs(watchTariffs.value);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [watchTariffs])

    const resetForm = () => {
        setValue('modalities', null);
        setModalities(null);
        setValue('tariffs', null);
        setTariffs(null);
        setTarCode(null);
    }

    return (
        <>
            <div className="default-filter d-flex justify-content-center bg-white mt-4" style={{ textAlign: "left" }}>
                <Accordion defaultActiveKey="0">
                    <Accordion.Item eventKey="0">
                        <Accordion.Header>{/* <FontAwesomeIcon icon={['fal', 'sliders-h']} size="lg" className="mr-2" /> */}<span className="h5 mb-0">Informações do produto</span></Accordion.Header>
                        <Accordion.Body className="pt-0">
                            <Container className="p-0" fluid>
                                <Row>
                                    <div>
                                        <Form >
                                            <Row className="mb-3">
                                                <Form.Group as={Col} md="4">
                                                    <Form.Label>Produto:</Form.Label>
                                                    <Controller
                                                        control={control}
                                                        name="products"
                                                        rules={{ required: { value: true, message: 'Por favor, informe ao menos 1 produto' } }}
                                                        render={({ field }: any) => (
                                                            <div className="">
                                                                <GetProductsList
                                                                    propsField={field}
                                                                    propsErrors={errors}
                                                                />
                                                            </div>
                                                        )}
                                                    />
                                                    <ErrorMessage
                                                        errors={errors}
                                                        name="products"
                                                        render={({ message }) => <small style={{ color: "red" }}>{message}</small>}
                                                    />
                                                </Form.Group>
                                                {modalities !== null &&
                                                    <Form.Group as={Col} md="4">
                                                        <Form.Label>Modalidade:</Form.Label>
                                                        <Controller
                                                            control={control}
                                                            name="modalities"
                                                            rules={{ required: { value: true, message: 'Por favor, informe ao menos 1 modalidade' } }}
                                                            render={({ field }: any) => (
                                                                <div className="endpoint">
                                                                    <Select
                                                                        {...field}
                                                                        aria-labelledby="aria-label"
                                                                        inputId="aria-example-input"
                                                                        name="aria-live-color"
                                                                        className="basic-multi-select"
                                                                        classNamePrefix="select"
                                                                        as="select"
                                                                        variant="standard"
                                                                        margin="normal"
                                                                        placeholder={"Selecione"}
                                                                        options={modalities}
                                                                        getOptionLabel={(option: any) => `${option.label}`}
                                                                    />
                                                                </div>
                                                            )}
                                                        />
                                                    </Form.Group>
                                                }
                                                {tariffs !== null &&
                                                    <Form.Group as={Col} md="4">
                                                        <Form.Label>Tarifário:</Form.Label>
                                                        <Controller
                                                            control={control}
                                                            name="tariffs"
                                                            rules={{ required: { value: true, message: 'Por favor, informe ao menos 1 modalidade' } }}
                                                            render={({ field }: any) => (
                                                                <div className="endpoint">
                                                                    <Select
                                                                        {...field}
                                                                        aria-labelledby="aria-label"
                                                                        inputId="aria-example-input"
                                                                        name="aria-live-color"
                                                                        className="basic-multi-select"
                                                                        classNamePrefix="select"
                                                                        as="select"
                                                                        variant="standard"
                                                                        margin="normal"
                                                                        placeholder={"Selecione"}
                                                                        options={tariffs}
                                                                        getOptionLabel={(option: any) => `${option.label}`}
                                                                    />
                                                                </div>
                                                            )}
                                                        />
                                                    </Form.Group>
                                                }
                                            </Row>
                                        </Form>
                                    </div>
                                </Row>
                            </Container>
                        </Accordion.Body>
                    </Accordion.Item>
                </Accordion>

                
                <ModalShow
                    modalShow={modalShow}
                    setModalShow={setModalShow}
                    modalMessage={modalMessage}
                    setModalMessage={setModalMessage}
                    modalLog={modalLog}
                    setModalLog={setModalLog}
                />
            </div>
            
        </>
    );
}

export default FilterStock;
