import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import { parseCookies, destroyCookie } from 'nookies'
// import { arrayImages } from '../pages/Sales/components/ProductCarrousel/ProductCarrousel';
// import { faLevelDown } from '@fortawesome/pro-solid-svg-icons';

const PrivateRoute: React.FC<{
    component: React.FC;
    path: string;
    exact: boolean;
    isAuthenticated: boolean;
    level: any;
}> = (props) => {
    const cookies = parseCookies();

    if (props.isAuthenticated === true) {
        // const infoToken = JSON.parse(localStorage.getItem('c2tourAuth') || '{}');
        // const now = new Date()
        // let day: any = now.toISOString();

        /* console.log(infoToken.token.expireUTC > day); */
    }

    if (!cookies.authCookie) {
        alert("Tempo de login expirado, favor relogar");
        localStorage.removeItem('c2tourAuth');
        destroyCookie(null, 'authCookie');
        window.location.href = ('/');
    }

    // console.log()
    const level = atob(JSON.parse(localStorage.getItem('c2tourAuth') || '{}').user.perfil.nivel)
    const authLevel = props.level.find((element: any) => element === Number(level)); // base64 para string  -- atob(base64)
    
    return (
        <>
            <div>
                {
                    props.isAuthenticated && authLevel !== undefined ?
                        (<Route path={props.path} exact={props.exact} component={props.component} />) :
                        (
                            <>
                                {alert("Seu nível de usuário não tem permissão para acessar essa página!")}
                                <Redirect to={`/dashboard`} />
                            </>
                        )
                }
            </div>
        </>
    );
};

export default PrivateRoute;