/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useEffect, useState } from "react";
import { Container, Row, Col, Card } from 'react-bootstrap';

import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import Accordion from 'react-bootstrap/Accordion';
import { Controller, useForm } from "react-hook-form";
import { ErrorMessage } from '@hookform/error-message';

import { useTranslation } from "react-i18next";

import GetCurrency from "../../../../components/C2Points/GetCurrency";
import GetPaymentForms from "../../../../components/C2Points/GetPaymentForms";
import GetPayCicle from "../../../../components/C2Points/GetPayCicle";
import Get60DaysForBilling from "../../../../components/C2Points/Get60DaysForBilling";
import FinancialTable from './FinancialTable';

import '../../../../assets/sass/accordion.scss';
import GetDaysWeekForPayment from "../../../../components/C2Points/GetDaysWeekForPayment";
import GetPaymentBiweekly from "../../../../components/C2Points/GetPaymentBiweekly";
import GetTenth from "../../../../components/C2Points/GetTenth";
import GetBanks from "../../../../components/C2Points/GetBanks";
import GetYesOrNot from "../../../../components/C2Points/GetYesOrNot";
import GetMasterOrSon from "../../../../components/C2Points/GetMasterOrSon";
import GetTariffType from "../../../../components/C2Points/GetTariffType";
import GetTariffGrid from "../../../../components/C2Points/GetTariffGrid";
import GetApiRedzyList from "../../../../components/C2Points/GetApiRedzyList";
import GetAgents from "../../../../components/C2Points/GetAgents";
import GetSalesChannelAffiliate from "../../../../components/C2Points/GetSalesChannelAffiliate";

export interface propAccordion {
    action: any;
    ContextAwareToggle: any;
    setStepCtrl: any
    infoEdit?: any;
    isEdit?: any;
    editPartners?: any;
    hasSavedInputs?: any;
};

const AccordionFinancial: React.FC<propAccordion> = ({
    action, ContextAwareToggle, setStepCtrl, infoEdit, isEdit = false, editPartners, hasSavedInputs
}: propAccordion) => {
    const { t } = useTranslation();

    const [ctrlEventKey, setCtrlEventKey] = useState<boolean>(false);
    const [messageError, setMessageError] = useState<boolean>(false);
    const [costCenter, setCostCenter] = useState<any>('');
    const [disableAddContact, setDisableAddContact] = useState<any>(false);

    const idPartner = window.location.href.split('id=')[1];

    useEffect(() => {
        console.log(costCenter)
        var somatorioRateio: any = 0;
        for (var i: any = 0; i < costCenter?.length; i++) {
            somatorioRateio += parseInt(costCenter[i].rateio);
        }
        if (somatorioRateio === 100) {
            setDisableAddContact(true);
        } else {
            setDisableAddContact(false);
        }
    }, [costCenter])

    /* Funcionalidade Tarifário */
    /* const [tarifType, setTarifType] = useState<any>(""); */
    //const [isGroup, setIsGroup] = useState<any>("");
    //const [isMaster, setIsMaster] = useState<any>("");
    /* END - Funcionalidade Tarifário */

    /* function ScrollTop() {
        window.scrollTo(0, 0);
      } */

    const ControllerPayment = () => {
        if (watchPaymentType === 'Term') {
            return (
                <>
                    <Form.Group as={Col} md="2" controlId="validationCustomRG">
                        <Form.Label>{t('accordionFinancial.from')}:</Form.Label>
                        <Controller
                            control={control}
                            name="reserve"
                            rules={{ required: { value: true, message: 'Por favor, Por favor, selecione a reserva' } }}
                            render={({ field }: any) => (
                                <div className="endpoint">
                                    <Form.Select
                                        {...field}
                                        aria-invalid={errors?.reserve ? "true" : ""}
                                        label={t('accordionFinancial.from')}
                                        as="select"
                                        variant="standard"
                                        margin="normal"
                                        required
                                    >
                                        <option value=''>Selecione</option>
                                        <option value="1">Dia da reserva</option>
                                        <option value="2">Dia do passeio</option>
                                    </Form.Select>
                                </div>
                            )}
                        />
                        <ErrorMessage
                            errors={errors}
                            name="reserve"
                            render={({ message }) => <small style={{ color: "red" }}>{message}</small>}
                        />
                    </Form.Group>
                    <Form.Group as={Col} md="2" controlId="validationCustomRG">
                        <Form.Label>{t('accordionFinancial.cic')}:</Form.Label>
                        <Controller
                            control={control}
                            name="ciclo"
                            rules={{ required: { value: true, message: 'Por favor, selecione o ciclo' } }}
                            render={({ field }: any) => (
                                <GetPayCicle propsField={field} propsErrors={errors} propsLabel={t('accordionFinancial.cic')} />
                            )}
                        />
                        <ErrorMessage
                            errors={errors}
                            name="ciclo"
                            render={({ message }) => <small style={{ color: "red" }}>{message}</small>}
                        />
                    </Form.Group>
                    <ControllerCiclePayment />
                    <Form.Group as={Col} md="2" controlId="validationCustomOrganRG">
                        <Form.Label>{t('accordionFinancial.fat')}</Form.Label>
                        <Controller
                            control={control}
                            name="faturamento60Dias"
                            rules={{ required: { value: true, message: 'Por favor, preencher esse campo' } }}
                            render={({ field }: any) => (
                                <Get60DaysForBilling propsField={field} propsErrors={errors} propsLabel={t('accordionFinancial.cic')} />
                            )}
                        />
                        <ErrorMessage
                            errors={errors}
                            name="faturamento60Dias"
                            render={({ message }) => <small style={{ color: "red" }}>{message}</small>}
                        />
                    </Form.Group>
                </>
            )
        } else {
            return (
                <></>
            )
        }
    }

    const ControllerCiclePayment = () => {
        if (watchCicleType !== undefined) {
            if (watchCicleType !== 'Days' && watchCicleType !== 'Months') {
                return (
                    <Form.Group as={Col} md="2" controlId="validationCustomOrganRG">
                        <Form.Label>{t('accordionFinancial.cut') + watchCicleType}:</Form.Label>
                        <Controller
                            control={control}
                            name="diaDeCorte"
                            rules={{ required: { value: true, message: 'Por favor, preencher esse campo' } }}
                            render={({ field }: any) => {
                                if (watchCicleType === 'Week') {
                                    return (
                                        <GetDaysWeekForPayment propsField={field} propsErrors={errors} propsLabel={t('accordionFinancial.cic')} />
                                    )
                                } else if (watchCicleType === 'Daily') {
                                    return (
                                        <GetPaymentBiweekly propsField={field} propsErrors={errors} propsLabel={t('accordionFinancial.cic')} />
                                    )
                                } else if (watchCicleType === 'Dec') {
                                    return (
                                        <GetTenth propsField={field} propsErrors={errors} propsLabel={t('accordionFinancial.cic')} />
                                    )
                                } else {
                                    return <></>
                                }
                            }}
                        />
                        <ErrorMessage
                            errors={errors}
                            name="diaDeCorte"
                            render={({ message }) => <small style={{ color: "red" }}>{message}</small>}
                        />
                    </Form.Group>
                )
            } else {
                return <></>
            }

        } else {
            return <></>
        }
    }

    const {
        control,
        watch,
        handleSubmit,
        setValue,
        formState: { errors },
    } = useForm({
        defaultValues: {
            bankId: (isEdit === true || hasSavedInputs === true) ? { description: infoEdit.bancDesc, id: infoEdit.bankId } : '',
            agency: (isEdit === true || hasSavedInputs === true) ? infoEdit.agency : '',
            account: (isEdit === true || hasSavedInputs === true) ? infoEdit.account : '',
            verificadorDiv: (isEdit === true || hasSavedInputs === true) ? infoEdit.verificadorDiv : '',
            //filialDesc: (isEdit === true || hasSavedInputs === true) ? infoEdit.filialDesc : '',
            grupo: (isEdit === true || hasSavedInputs === true) ? infoEdit.grupo : '',
            masterFilho: (isEdit === true || hasSavedInputs === true) ? infoEdit.masterFilho : '',
            comissaoMasterId: (isEdit === true || hasSavedInputs === true) ? infoEdit.comissaoMasterId : '',
            tarTipo: (isEdit === true || hasSavedInputs === true) ? infoEdit.tarTipo : '',
            comissaoTour: (isEdit === true || hasSavedInputs === true) ? infoEdit.comissaoTour : '',
            comissaoTransfer: (isEdit === true || hasSavedInputs === true) ? infoEdit.comissaoTransfer : '',
            comissaoTicket: (isEdit === true || hasSavedInputs === true) ? infoEdit.comissaoTicket : '',
            gradeCode: (isEdit === true || hasSavedInputs === true) ? { description: infoEdit.gradeCodeDesc, id: infoEdit.gradeCode } : '',
            comissaoTourFilho: (isEdit === true || hasSavedInputs === true) ? infoEdit.comissaoTourFilho : '',
            comissaoTransferFilho: (isEdit === true || hasSavedInputs === true) ? infoEdit.comissaoTransferFilho : '',
            comissaoTicketFilho: (isEdit === true || hasSavedInputs === true) ? infoEdit.comissaoTicketFilho : '',
            tarApiRedzy: (isEdit === true || hasSavedInputs === true) ? infoEdit.tarApiRedzy : '',
            tarImportaPlan: (isEdit === true || hasSavedInputs === true) ? infoEdit.tarImportaPlan : '',
            braspag: (isEdit === true || hasSavedInputs === true) ? { description: infoEdit.braspagDesc, id: infoEdit.braspag } : '',
            feeBraspag: (isEdit === true || hasSavedInputs === true) ? infoEdit.feeBraspag : '',
            mdrBraspag: (isEdit === true || hasSavedInputs === true) ? infoEdit.mdrBraspag : '',
            status: (isEdit === true || hasSavedInputs === true) ? infoEdit.status : '',
            cashModePermission: (isEdit === true || hasSavedInputs === true) ? infoEdit.cashModePermission : '',
            conciliacaoAutomatica: (isEdit === true || hasSavedInputs === true) ? infoEdit.conciliacaoAutomatica : '',
            currency: (isEdit === true || hasSavedInputs === true) ? infoEdit.currency : '',
            paymentType: (isEdit === true || hasSavedInputs === true) ? infoEdit.paymentType : '',
            reserve: (isEdit === true || hasSavedInputs === true) ? infoEdit.reserve : '',
            ciclo: (isEdit === true || hasSavedInputs === true) ? infoEdit.ciclo : '',
            faturamento60Dias: (isEdit === true || hasSavedInputs === true) ? infoEdit.faturamento60Dias : '',
            diaDeCorte: (isEdit === true || hasSavedInputs === true) ? infoEdit.diaDeCorte : '',
            creditLimitSupplier: (isEdit === true || hasSavedInputs === true) ? infoEdit.creditLimitSupplier : '',
            diasParaFaturamentoAgent: (isEdit === true || hasSavedInputs === true) ? infoEdit.diasParaFaturamentoAgent : '',
            idCanal: (isEdit === true || hasSavedInputs === true) && infoEdit.descCanal !== null ? { salesChannelName: infoEdit.descCanal, idSalesChannel: infoEdit.idCanal } : ''
        }
    });

    console.log({ salesChannelName: infoEdit.descCanal, idSalesChannel: infoEdit.idCanal } )
    const watchPaymentType = watch("paymentType", false);
    const watchCicleType = watch("ciclo", false);

    const isGroup = watch("grupo", isEdit === true ? infoEdit.grupo : '');
    const isMaster = watch("masterFilho", isEdit === true ? infoEdit.masterFilho : '');
    const tarifType = watch("tarTipo", isEdit === true ? infoEdit.tarTipo : '');

    const isBraspag = watch("braspag", isEdit === true ? { description: infoEdit.braspagDesc, id: infoEdit.braspag } : '');
    console.log(Object(isBraspag).id)

    const onSubmit = (data: any) => {
        data.descCanal = data.idCanal.salesChannelName;
        data.idCanal = data.idCanal.idSalesChannel;

        /* Objeto do Tarifário */
        if (tarifType !== "1") {
            data.comissaoTicket = 0;
            data.comissaoTour = 0;
            data.comissaoTransfer = 0;
        }

        if (isMaster !== "1") {
            data.comissaoTicketFilho = 0;
            data.comissaoTourFilho = 0;
            data.comissaoTransferFilho = 0;
        } else {
            data.comissaoMasterId = 0;
        }

        if (tarifType !== "3") {
            data.tarApiRedzy = 0;
        } else {
            data.tarApiRedzy = data.tarApiRedzy.id;
        }

        if (tarifType !== "4") {
            data.tarImportaPlan = 0;
        } else {
            data.tarImportaPlan = 0;
        }

        if (isGroup === "1") {
            data.masterFilho = data.masterFilho === '' || data.masterFilho === null ? 0 : parseInt(isMaster);
        } else {
            data.masterFilho = 0;
        }
        /* END - Objeto do Tarifário */


        if(Number(data.creditLimitSupplier) < 0){
            alert('Não é possível adicionar limite de crédito negativo');
        }else if (isEdit === false) {
            /// ADD Partners PAGE
            data.costCenterSupplierModel = costCenter;
            data.costCenterSupplierDTO = costCenter;
            // eslint-disable-next-line no-lone-blocks
            {
                data.costCenterSupplierModel.map((cost: any) => (
                    cost.coastCenterId = cost.coastCenterId?.id ? cost.coastCenterId?.id : cost.coastCenterId
                ))
            }

            data.bancDesc = data.bankId.description;
            data.bankId = data.bankId.id;
            //data.filialDesc = parseInt(data.filialDesc);
            //data.comissaoFornecedor = data.comissaoFornecedor.id;

            data.creditLimitSupplier = Number(data.creditLimitSupplier);
            data.diasParaFaturamentoAgent = Number(data.diasParaFaturamentoAgent);

            data.braspagDesc = data.braspag.description;
            data.braspag = data.braspag.id;
            data.feeBraspag = data.feeBraspag === '' ? 0 : data.feeBraspag;
            data.mdrBraspag = data.mdrBraspag === '' ? 0 : data.mdrBraspag;
            data.ciclo = data.ciclo ? data.ciclo : '';
            data.grupo = isGroup;
            data.gradeCodeDesc = data.gradeCode.description;
            data.gradeCode = data.gradeCode.id;
            data.tarTipo = tarifType;
            //data.masterFilho = data.masterFilho.id;
            data.diaDeCorte = data.diaDeCorte ? data.diaDeCorte : '';
            data.vencimento = /* data.ciclo === "Week" ? data.faturamento60Dias : ''; */ 0;
            data.faturamento30Dias = /* data.ciclo === "Days" ? data.faturamento60Dias : ''; */ 0;
            data.faturamento15Dias = /* data.ciclo === "Daily" ? data.faturamento60Dias : ''; */ 0;
            data.faturamento60Dias = /* data.ciclo === "Months" ? data.faturamento60Dias : ''; */ 0;
            data.faturamento10Dias =/*  data.ciclo === "Dec" ? data.faturamento60Dias : ''; */ 0;

            // Não estão sendo usados
            data.payquinzenal = data.payquinzenal ? data.payquinzenal : '';
            data.payvencimento = data.payvencimento ? data.payvencimento : '';

            // Verifica se o rateio soma 100%
            var somatorioRateio: any = 0;
            data.costCenterSupplierModel = [];
            for (var i: any = 0; i < costCenter.length; i++) {
                somatorioRateio += parseInt(costCenter[i].rateio);
                data.costCenterSupplierModel[i] = { "coastCenterId": costCenter[i].coastCenterId?.id ? costCenter[i].coastCenterId?.id : costCenter[i].coastCenterId, "rateio": parseInt(costCenter[i].rateio) }
            }

            if (somatorioRateio !== 100) {
                setMessageError(true);
            } else {
                setMessageError(false);
                setCtrlEventKey(true);
                action(data);
            }
        } else {
            /// EDIT Partners PAGE
            let edit: any = { ...infoEdit, ...data };
            edit.affiliateId = parseInt(idPartner);

            edit.bankId = edit.bankId.id;
            //data.filialDesc = parseInt(data.filialDesc);
            edit.braspag = edit.braspag.id;
            edit.grupo = isGroup;
            edit.grupo = parseInt(edit.grupo);
            edit.gradeCode = edit.gradeCode.id;
            edit.tarTipo = tarifType;
            edit.tarTipo = parseInt(edit.tarTipo);

            edit.comissaoTour = parseInt(edit.comissaoTour);
            edit.comissaoTourFilho = parseInt(edit.comissaoTourFilho);
            edit.comissaoTransfer = parseInt(edit.comissaoTransfer);
            edit.comissaoTransferFilho = parseInt(edit.comissaoTransferFilho);
            edit.comissaoTicket = parseInt(edit.comissaoTicket);
            edit.comissaoTicketFilho = parseInt(edit.comissaoTicketFilho);
            //edit.grupo = edit.grupo.id;
            edit.status = parseInt(edit.status);

            edit.costCenterSupplierModel = costCenter;
            // eslint-disable-next-line no-lone-blocks
            {
                edit.costCenterSupplierModel.map((cost: any) => (
                    cost.coastCenterId = cost.coastCenterId.id ? parseInt(cost.coastCenterId.id) : parseInt(cost.coastCenterId)
                ))
            }

            edit.supplierContactModel = edit.affiliateContact;
            edit.usersSupplierModel = edit.usersSupplierDTO;

            if(edit.masterFilho === null){
                edit.masterFilho = '0';
            }

            edit.creditLimitSupplier = Number(edit.creditLimitSupplier);

            editPartners(edit);
        }
    };

    const getCosteCenter = (objCostCenter: any) => {
        setCostCenter(objCostCenter);
    }

    function handleCancel() {
        window.location.reload();
    }

    const addAgents = (objAgents: any) => {
        setValue(`comissaoMasterId`, objAgents.supplierIdentity);
    };

    return (
        <Card className="bg-white mt-4" id="editFinancialForms">
            <Card.Header id="accordionPersonal">
                <ContextAwareToggle eventKey="0" status={ctrlEventKey}>{t('accordionFinancial.title')}</ContextAwareToggle>
            </Card.Header>
            <Accordion.Collapse eventKey="0">
                <Card.Body>
                    <Container className="p-0 mt-3" fluid>
                        <Row>
                            <div>
                                <Form noValidate validated={false} onSubmit={handleSubmit(onSubmit)}>
                                    <Row className="mb-3">
                                        <Form.Group as={Col} md="4" controlId="validationCustom01" style={{ position: "relative" }}>
                                            <Form.Label>{t('accordionFinancial.bank')}</Form.Label>
                                            <Controller
                                                control={control}
                                                name="bankId"
                                                rules={{ required: { value: true, message: 'Por favor, selecione o banco.' } }}
                                                render={({ field }: any) => (
                                                    <GetBanks propsField={field} propsErrors={errors} propsLabel={''} />
                                                )}
                                            />
                                            <ErrorMessage
                                                errors={errors}
                                                name="bankId"
                                                render={({ message }) => <small style={{ color: "red" }}>{message}</small>}
                                            />
                                        </Form.Group>
                                        <Form.Group as={Col} md="4" controlId="validationCustom02">
                                            <Form.Label>{t('accordionFinancial.agency')}</Form.Label>
                                            <Controller
                                                control={control}
                                                name="agency"
                                                rules={{ required: { value: true, message: 'Por favor, informe a agência.' } }}
                                                render={({ field }: any) => (
                                                    <Form.Control type="text"
                                                        {...field}
                                                        aria-invalid={errors?.agency ? "true" : ""}
                                                        fullWidth
                                                        variant="standard"
                                                        margin="normal"
                                                        onKeyPress={(e) => !/[0-9]/.test(e.key) && e.preventDefault()}
                                                        autoComplete='off'
                                                        required />
                                                )}
                                            />
                                            <ErrorMessage
                                                errors={errors}
                                                name="agency"
                                                render={({ message }) => <small style={{ color: "red" }}>{message}</small>}
                                            />
                                        </Form.Group>
                                        <Form.Group as={Col} md="2" controlId="validationCustomUsername">
                                            <Form.Label>{t('accordionFinancial.account')}</Form.Label>
                                            <Controller
                                                control={control}
                                                name="account"
                                                rules={{ required: { value: true, message: 'Por favor, informe a conta.' } }}
                                                render={({ field }: any) => (
                                                    <Form.Control type="text"
                                                        {...field}
                                                        aria-invalid={errors?.account ? "true" : ""}
                                                        fullWidth
                                                        variant="standard"
                                                        margin="normal"
                                                        onKeyPress={(e) => !/[0-9]/.test(e.key) && e.preventDefault()}
                                                        autoComplete='off'
                                                        required />
                                                )}
                                            />
                                            <ErrorMessage
                                                errors={errors}
                                                name="account"
                                                render={({ message }) => <small style={{ color: "red" }}>{message}</small>}
                                            />
                                        </Form.Group>
                                        <Form.Group as={Col} md="2" controlId="validationCustomUsername">
                                            <Form.Label>{t('accordionFinancial.div')}</Form.Label>
                                            <Controller
                                                control={control}
                                                name="verificadorDiv"
                                                rules={{ required: { value: true, message: 'Por favor, informe a div.' } }}
                                                render={({ field }: any) => (
                                                    <Form.Control type="text"
                                                        {...field}
                                                        aria-invalid={errors?.verificadorDiv ? "true" : ""}
                                                        variant="standard"
                                                        margin="normal"
                                                        onKeyPress={(e) => !/[0-9]/.test(e.key) && e.preventDefault()}
                                                        maxlength="1"
                                                        autoComplete='off'
                                                        required />
                                                )}
                                            />
                                            <ErrorMessage
                                                errors={errors}
                                                name="verificadorDiv"
                                                render={({ message }) => <small style={{ color: "red" }}>{message}</small>}
                                            />
                                        </Form.Group>
                                    </Row>
                                    {/* <Row className="mb-3">
                                        <Form.Group as={Col} md="4" controlId="validationCustom01">
                                            <Form.Label>Filial</Form.Label>
                                            <Controller
                                                control={control}
                                                name="filialDesc"
                                                rules={{ required: { value: false, message: 'Por favor, informe a filial.' } }}
                                                render={({ field }: any) => (
                                                    <div className={"endpoint"}>
                                                        <Form.Select
                                                            {...field}
                                                            aria-invalid={errors?.filialDesc ? "true" : ""}
                                                            label="Filial"
                                                            as="select"
                                                            variant="standard"
                                                            margin="normal"
                                                            defaultValue={'1'}
                                                            defaultChecked={'1'}
                                                            required
                                                            autoComplete='off'
                                                        >
                                                            <option value='1'>Sim</option>
                                                            <option value='2'>Não</option>
                                                        </Form.Select>
                                                    </div>
                                                )}
                                            />
                                            <ErrorMessage
                                                errors={errors}
                                                name="filialDesc"
                                                render={({ message }) => <small style={{ color: "red" }}>{message}</small>}
                                            />
                                        </Form.Group>
                                    </Row> */}
                                    <hr className="my-5" />

                                    <h5>A Definir</h5>
                                    <Row className="mb-3">
                                        <Form.Group as={Col} md="4" controlId="validationCustom01" style={{ position: "relative" }}>
                                            <Form.Label>Limite de Crédito</Form.Label>
                                            <Controller
                                                control={control}
                                                name="creditLimitSupplier"
                                                rules={{ required: { value: true, message: 'Por favor, informe o límite de crédito.' } }}
                                                render={({ field }: any) => (
                                                    <Form.Control type="number"
                                                        {...field}
                                                        aria-invalid={errors?.creditLimitSupplier ? "true" : ""}
                                                        fullWidth
                                                        variant="standard"
                                                        margin="normal"
                                                        onKeyPress={(e) => !/[0-9]/.test(e.key) && e.preventDefault()}
                                                        autoComplete="off"
                                                        required={true}
                                                    />
                                                )}
                                            />
                                            <ErrorMessage
                                                errors={errors}
                                                name="creditLimitSupplier"
                                                render={({ message }) => <small style={{ color: "red" }}>{message}</small>}
                                            />
                                        </Form.Group>
                                        <Form.Group as={Col} md="4" controlId="validationCustom02">
                                            <Form.Label>Dias para Faturamento</Form.Label>
                                            <Controller
                                                control={control}
                                                name="diasParaFaturamentoAgent"
                                                rules={{ required: { value: true, message: 'Por favor, informe os dias para faturamento.' } }}
                                                render={({ field }: any) => (
                                                    <Form.Control type="text"
                                                        {...field}
                                                        aria-invalid={errors?.diasParaFaturamentoAgent ? "true" : ""}
                                                        fullWidth
                                                        variant="standard"
                                                        margin="normal"
                                                        onKeyPress={(e) => !/[0-9]/.test(e.key) && e.preventDefault()}
                                                        autoComplete="off"
                                                        required={true}
                                                    />
                                                )}
                                            />
                                            <ErrorMessage
                                                errors={errors}
                                                name="diasParaFaturamentoAgent"
                                                render={({ message }) => <small style={{ color: "red" }}>{message}</small>}
                                            />
                                        </Form.Group>
                                    </Row>

                                    <hr className="my-5" />

                                    <h5>GRUPO ECONÔMICO</h5>
                                    <Row className="mb-3">
                                        <Form.Group as={Col} md="4" controlId="validationCustom01">
                                            <Form.Label>É um grupo?</Form.Label>
                                            <Controller
                                                control={control}
                                                name="grupo"
                                                rules={{ required: { value: true, message: 'Por favor, preencher esse campo.' } }}
                                                render={({ field }: any) => (
                                                    <div className="endpoint">
                                                        <Form.Select
                                                        autoComplete='off'
                                                            {...field}
                                                            aria-invalid={errors?.grupo ? "true" : ""}
                                                            as="select"
                                                            variant="standard"
                                                            margin="normal"
                                                            required
                                                        >
                                                            <option value="" selected={isEdit === false} disabled>Selecione</option>
                                                            <option value="1" selected={isEdit === true && infoEdit.grupo === 1}>Sim</option>
                                                            <option value="2" selected={isEdit === true && infoEdit.grupo === 2}>Não</option>
                                                        </Form.Select>
                                                    </div>
                                                )}
                                            />
                                            <ErrorMessage
                                                errors={errors}
                                                name="grupo"
                                                render={({ message }) => <small style={{ color: "red" }}>{message}</small>}
                                            />
                                        </Form.Group>
                                        <Form.Group as={Col} md="4" controlId="validationCustom02">
                                            <Form.Label>Master ou filho?</Form.Label>
                                            {
                                                isGroup === "1" || (isEdit === true && infoEdit.grupo === 1)
                                                    ?
                                                    <>
                                                        <Controller
                                                            control={control}
                                                            name="masterFilho"
                                                            rules={{ required: { value: true, message: 'Por favor, preencher esse campo.' } }}
                                                            render={({ field }: any) => (
                                                                <GetMasterOrSon
                                                                    propsField={field}
                                                                    propsErrors={errors}
                                                                    propsLabel="Master ou Filho?"
                                                                    isGroup={isGroup}
                                                                //setIsMaster={setIsMaster}
                                                                />
                                                            )}
                                                        />
                                                        <ErrorMessage
                                                            errors={errors}
                                                            name="masterFilho"
                                                            render={({ message }) => <small style={{ color: "red" }}>{message}</small>
                                                            }
                                                        />
                                                    </>
                                                    :
                                                    <Controller
                                                        control={control}
                                                        name="masterFilho"
                                                        rules={{ required: { value: false, message: 'Por favor, preencher esse campo.' } }}
                                                        render={({ field }: any) => (
                                                            <div className="endpoint">
                                                                <Form.Select
                                                                    {...field}
                                                                    as="select"
                                                                    variant="standard"
                                                                    margin="normal"
                                                                    disabled={true}
                                                                    autoComplete='off'
                                                                >
                                                                    <option value="">Selecione</option>
                                                                </Form.Select>
                                                            </div>
                                                        )}
                                                    />
                                            }
                                        </Form.Group>
                                        <Form.Group as={Col} md="4" controlId="validationCustom02">
                                            <Form.Label>Master do Grupo:</Form.Label>
                                            {
                                                isGroup === "1" && isMaster === "2"
                                                    ?
                                                    <>
                                                            <GetAgents actionAgents={addAgents} />
                                                            <Controller
                                                                control={control}
                                                                name="comissaoMasterId"
                                                                render={({ field }) => {
                                                                    // sending integer instead of string.
                                                                    return <input {...field} type="hidden" />;
                                                                }}
                                                            />
                                                            <ErrorMessage
                                                                errors={errors}
                                                                name="agent"
                                                                render={({ message }) => <small style={{ color: "red" }}>{message}</small>}
                                                            />
                                                        {/* <Controller
                                                            control={control}
                                                            name="comissaoMasterId"
                                                            rules={{ required: { value: true, message: 'Por favor, informe o master do grupo.' } }}
                                                            render={({ field }: any) => (
                                                                <Form.Control
                                                                    type="text"
                                                                    {...field}
                                                                    aria-invalid={errors?.comissaoMasterId ? "true" : ""}
                                                                    fullWidth
                                                                    variant="standard"
                                                                    margin="normal"
                                                                    required={true}
                                                                    disabled={false}
                                                                    autoComplete='off'
                                                                />
                                                            )}
                                                        />
                                                        <ErrorMessage
                                                            errors={errors}
                                                            name="comissaoMasterId"
                                                            render={({ message }) => <small style={{ color: "red" }}>{message}</small>}
                                                        /> */}
                                                    </>
                                                    :
                                                    <Controller
                                                        control={control}
                                                        name="comissaoMasterId"
                                                        rules={{ required: { value: false, message: 'Por favor, preencher esse campo.' } }}
                                                        render={({ field }: any) => (
                                                            <Form.Control
                                                                type="text"
                                                                {...field}
                                                                //aria-invalid={errors?.comissaoMasterId ? "true" : ""}
                                                                fullWidth
                                                                variant="standard"
                                                                margin="normal"
                                                                value=""
                                                                required={false}
                                                                disabled={true}
                                                                autoComplete='off'
                                                            />
                                                        )}
                                                    />
                                            }
                                        </Form.Group>
                                    </Row>
                                    <hr className="my-5" />
                                    <h5>TARIFÁRIO E COMISSÕES</h5>
                                    <Row className="mb-3">
                                        <Form.Group as={Col} md="3" controlId="validationCustom01">
                                            <Form.Label>Canal</Form.Label>
                                            <Controller
                                                control={control}
                                                name="idCanal"
                                                rules={{ required: { value: true, message: 'Por favor, informe o canal.' } }}
                                                render={({ field }: any) => (
                                                    <GetSalesChannelAffiliate propsField={field} propsErrors={errors} propsLabel="Canal"/>
                                                )}
                                            />
                                            <ErrorMessage
                                                errors={errors}
                                                name="idCanal"
                                                render={({ message }) => (
                                                    <small style={{ color: "red" }}>{message}</small>
                                                )}
                                            />
                                        </Form.Group>
                                    </Row>
                                    <Row className="mb-3">
                                        <Form.Group as={Col} md="3" controlId="validationCustom01">
                                            <Form.Label>Tipo Tarifário:</Form.Label>
                                            <Controller
                                                control={control}
                                                name="tarTipo"
                                                rules={{ required: { value: true, message: 'Por favor, preencher esse campo.' } }}
                                                render={({ field }: any) => (
                                                    <GetTariffType
                                                        propsField={field}
                                                        propsErrors={errors}
                                                        propsLabel="Tipo Tarifário"
                                                    />
                                                )}
                                            />
                                            <ErrorMessage
                                                errors={errors}
                                                name="tarTipo"
                                                render={({ message }) => <small style={{ color: "red" }}>{message}</small>}
                                            />
                                        </Form.Group>
                                        <Form.Group as={Col} md="3" controlId="validationCustom02">
                                            <Form.Label>Comissão Tour:</Form.Label>
                                            {tarifType === "1"
                                                ?
                                                <>
                                                    <Controller
                                                        control={control}
                                                        name="comissaoTour"
                                                        rules={{ required: { value: true, message: 'Por favor, preencher esse campo.' } }}
                                                        render={({ field }: any) => (
                                                            <Form.Control type="text"
                                                                {...field}
                                                                aria-invalid={errors?.comissaoTour ? "true" : ""}
                                                                fullWidth
                                                                variant="standard"
                                                                margin="normal"
                                                                onKeyPress={(e) => !/[0-9]/.test(e.key) && e.preventDefault()}
                                                                required={true}
                                                                autoComplete='off'
                                                                disabled={tarifType === "1" ? false : true}
                                                            />
                                                        )}
                                                    />
                                                    <ErrorMessage
                                                        errors={errors}
                                                        name="comissaoTour"
                                                        render={({ message }) => <small style={{ color: "red" }}>{message}</small>}
                                                    />
                                                </>
                                                :
                                                <Controller
                                                    control={control}
                                                    name="comissaoTour"
                                                    rules={{ required: { value: false, message: 'Por favor, preencher esse campo.' } }}
                                                    render={({ field }: any) => (
                                                        <Form.Control
                                                            type="text"
                                                            {...field}
                                                            //aria-invalid={errors?.comissaoMasterId ? "true" : ""}
                                                            fullWidth
                                                            variant="standard"
                                                            margin="normal"
                                                            onKeyPress={(e) => !/[0-9]/.test(e.key) && e.preventDefault()}
                                                            value=""
                                                            disabled={true}
                                                            autoComplete='off'
                                                        />
                                                    )}
                                                />
                                            }
                                        </Form.Group>
                                        <Form.Group as={Col} md="3" controlId="validationCustom02">
                                            <Form.Label>Comissão Transfer:</Form.Label>
                                            {
                                                tarifType === "1"
                                                    ?
                                                    <>
                                                        <Controller
                                                            control={control}
                                                            name="comissaoTransfer"
                                                            rules={{ required: { value: true, message: 'Por favor, preencher esse campo.' } }}
                                                            render={({ field }: any) => (
                                                                <Form.Control type="text"
                                                                    {...field}
                                                                    aria-invalid={errors?.comissaoTransfer ? "true" : ""}
                                                                    fullWidth
                                                                    variant="standard"
                                                                    onKeyPress={(e) => !/[0-9]/.test(e.key) && e.preventDefault()}
                                                                    margin="normal"
                                                                    required={true}
                                                                    disabled={false}
                                                                    autoComplete='off'
                                                                />
                                                            )}
                                                        />
                                                        <ErrorMessage
                                                            errors={errors}
                                                            name="comissaoTransfer"
                                                            render={({ message }) => <small style={{ color: "red" }}>{message}</small>}
                                                        />
                                                    </>
                                                    :
                                                    <Controller
                                                        control={control}
                                                        name="comissaoTransfer"
                                                        rules={{ required: { value: false, message: 'Por favor, preencher esse campo.' } }}
                                                        render={({ field }: any) => (
                                                            <Form.Control
                                                                type="text"
                                                                {...field}
                                                                //aria-invalid={errors?.comissaoMasterId ? "true" : ""}
                                                                fullWidth
                                                                variant="standard"
                                                                margin="normal"
                                                                onKeyPress={(e) => !/[0-9]/.test(e.key) && e.preventDefault()}
                                                                value=""
                                                                disabled={true}
                                                                autoComplete='off'
                                                            />
                                                        )}
                                                    />
                                            }
                                        </Form.Group>
                                        <Form.Group as={Col} md="3" controlId="validationCustom02">
                                            <Form.Label>Comissão Tickets:</Form.Label>
                                            {
                                                tarifType === "1"
                                                    ?
                                                    <>
                                                        <Controller
                                                            control={control}
                                                            name="comissaoTicket"
                                                            rules={{ required: { value: true, message: 'Por favor, preencher esse campo.' } }}
                                                            render={({ field }: any) => (
                                                                <Form.Control type="text"
                                                                    {...field}
                                                                    aria-invalid={errors?.comissaoTicket ? "true" : ""}
                                                                    fullWidth
                                                                    variant="standard"
                                                                    margin="normal"
                                                                    onKeyPress={(e) => !/[0-9]/.test(e.key) && e.preventDefault()}
                                                                    required={true}
                                                                    disabled={false}
                                                                    autoComplete='off'
                                                                />
                                                            )}
                                                        />
                                                        <ErrorMessage
                                                            errors={errors}
                                                            name="comissaoTicket"
                                                            render={({ message }) => <small style={{ color: "red" }}>{message}</small>}
                                                        />
                                                    </>
                                                    :
                                                    <Controller
                                                        control={control}
                                                        name="comissaoTicket"
                                                        rules={{ required: { value: false, message: 'Por favor, preencher esse campo.' } }}
                                                        render={({ field }: any) => (
                                                            <Form.Control
                                                                type="text"
                                                                {...field}
                                                                //aria-invalid={errors?.comissaoMasterId ? "true" : ""}
                                                                fullWidth
                                                                onKeyPress={(e) => !/[0-9]/.test(e.key) && e.preventDefault()}
                                                                variant="standard"
                                                                margin="normal"
                                                                value=""
                                                                disabled={true}
                                                                autoComplete='off'
                                                            />
                                                        )}
                                                    />
                                            }
                                        </Form.Group>
                                    </Row>
                                    <Row className="mb-3">
                                        <Form.Group as={Col} md="3" controlId="validationCustom01">
                                            <Form.Label>Grade de Tarifário:</Form.Label>
                                            <Controller
                                                control={control}
                                                name="gradeCode"
                                                rules={{ required: { value: true, message: 'Por favor, preencher esse campo.' } }}
                                                render={({ field }: any) => (
                                                    <GetTariffGrid
                                                        propsField={field}
                                                        propsErrors={errors}
                                                        propsLabel="Grade de Tarifário"
                                                    />
                                                )}
                                            />
                                            <ErrorMessage
                                                errors={errors}
                                                name="gradeCode"
                                                render={({ message }) => <small style={{ color: "red" }}>{message}</small>}
                                            />
                                        </Form.Group>
                                        <Form.Group as={Col} md="3" controlId="validationCustom02">
                                            <Form.Label>Comissão Tour Filho:</Form.Label>
                                            {isMaster === "1" && isGroup === "1"
                                                ?
                                                <>
                                                    <Controller
                                                        control={control}
                                                        name="comissaoTourFilho"
                                                        rules={{ required: { value: true, message: 'Por favor, preencher esse campo.' } }}
                                                        render={({ field }: any) => (
                                                            <Form.Control type="text"
                                                                {...field}
                                                                aria-invalid={errors?.comissaoTourFilho ? "true" : ""}
                                                                fullWidth
                                                                onKeyPress={(e) => !/[0-9]/.test(e.key) && e.preventDefault()}
                                                                variant="standard"
                                                                margin="normal"
                                                                required={true}
                                                                disabled={false}
                                                                autoComplete='off'
                                                            />
                                                        )}
                                                    />
                                                    <ErrorMessage
                                                        errors={errors}
                                                        name="comissaoTourFilho"
                                                        render={({ message }) => <small style={{ color: "red" }}>{message}</small>}
                                                    />
                                                </>
                                                :
                                                <Controller
                                                    control={control}
                                                    name="comissaoTourFilho"
                                                    rules={{ required: { value: false, message: 'Por favor, preencher esse campo.' } }}
                                                    render={({ field }: any) => (
                                                        <Form.Control
                                                            type="text"
                                                            {...field}
                                                            //aria-invalid={errors?.comissaoMasterId ? "true" : ""}
                                                            fullWidth
                                                            onKeyPress={(e) => !/[0-9]/.test(e.key) && e.preventDefault()}
                                                            variant="standard"
                                                            margin="normal"
                                                            value=""
                                                            disabled={true}
                                                            autoComplete='off'
                                                        />
                                                    )}
                                                />
                                            }
                                        </Form.Group>
                                        <Form.Group as={Col} md="3" controlId="validationCustom02">
                                            <Form.Label>Comissão Transfer Filho:</Form.Label>
                                            {isMaster === "1" && isGroup === "1"
                                                ?
                                                <>
                                                    <Controller
                                                        control={control}
                                                        name="comissaoTransferFilho"
                                                        rules={{ required: { value: true, message: 'Por favor, preencher esse campo.' } }}
                                                        render={({ field }: any) => (
                                                            <Form.Control type="text"
                                                                {...field}
                                                                aria-invalid={errors?.comissaoTransferFilho ? "true" : ""}
                                                                fullWidth
                                                                variant="standard"
                                                                onKeyPress={(e) => !/[0-9]/.test(e.key) && e.preventDefault()}
                                                                margin="normal"
                                                                required={true}
                                                                disabled={false}
                                                                autoComplete='off'
                                                            />
                                                        )}
                                                    />
                                                    <ErrorMessage
                                                        errors={errors}
                                                        name="comissaoTransferFilho"
                                                        render={({ message }) => <small style={{ color: "red" }}>{message}</small>}
                                                    />
                                                </>
                                                :
                                                <Controller
                                                    control={control}
                                                    name="comissaoTransferFilho"
                                                    rules={{ required: { value: false, message: 'Por favor, preencher esse campo.' } }}
                                                    render={({ field }: any) => (
                                                        <Form.Control
                                                            type="text"
                                                            {...field}
                                                            //aria-invalid={errors?.comissaoMasterId ? "true" : ""}
                                                            fullWidth
                                                            onKeyPress={(e) => !/[0-9]/.test(e.key) && e.preventDefault()}
                                                            variant="standard"
                                                            margin="normal"
                                                            value=""
                                                            disabled={true}
                                                            autoComplete='off'
                                                        />
                                                    )}
                                                />
                                            }
                                        </Form.Group>
                                        <Form.Group as={Col} md="3" controlId="validationCustom02">
                                            <Form.Label>Comissão Tickets Filho:</Form.Label>
                                            {isMaster === "1" && isGroup === "1"
                                                ?
                                                <>
                                                    <Controller
                                                        control={control}
                                                        name="comissaoTicketFilho"
                                                        rules={{ required: { value: true, message: 'Por favor, preencher esse campo.' } }}
                                                        render={({ field }: any) => (
                                                            <Form.Control type="text"
                                                                {...field}
                                                                aria-invalid={errors?.comissaoTicketFilho ? "true" : ""}
                                                                fullWidth
                                                                variant="standard"
                                                                onKeyPress={(e) => !/[0-9]/.test(e.key) && e.preventDefault()}
                                                                margin="normal"
                                                                required={true}
                                                                disabled={false}
                                                                autoComplete='off'
                                                            />
                                                        )}
                                                    />
                                                    <ErrorMessage
                                                        errors={errors}
                                                        name="comissaoTicketFilho"
                                                        render={({ message }) => <small style={{ color: "red" }}>{message}</small>}
                                                    />
                                                </>
                                                :
                                                <Controller
                                                    control={control}
                                                    name="comissaoTicketFilho"
                                                    rules={{ required: { value: false, message: 'Por favor, preencher esse campo.' } }}
                                                    render={({ field }: any) => (
                                                        <Form.Control
                                                            type="text"
                                                            {...field}
                                                            //aria-invalid={errors?.comissaoMasterId ? "true" : ""}
                                                            fullWidth
                                                            variant="standard"
                                                            onKeyPress={(e) => !/[0-9]/.test(e.key) && e.preventDefault()}
                                                            margin="normal"
                                                            value=""
                                                            disabled={true}
                                                            autoComplete='off'
                                                        />
                                                    )}
                                                />
                                            }
                                        </Form.Group>
                                    </Row>
                                    <Row className="mb-3">
                                        <Form.Group as={Col} md="4" controlId="validationCustom01">
                                            <Form.Label>API Rezdy:</Form.Label>
                                            {tarifType === "3"
                                                ?
                                                <>
                                                    <Controller
                                                        control={control}
                                                        name="tarApiRedzy"
                                                        rules={{ required: { value: true, message: 'Por favor, preencher esse campo.' } }}
                                                        render={({ field }: any) => (
                                                            <GetApiRedzyList
                                                                propsField={field}
                                                                propsErrors={errors}
                                                                propsLabel="Api Rezdy"
                                                                tarifType={tarifType}
                                                            />
                                                        )}
                                                    />
                                                    <ErrorMessage
                                                        errors={errors}
                                                        name="tarApiRedzy"
                                                        render={({ message }) => <small style={{ color: "red" }}>{message}</small>}
                                                    />
                                                </>
                                                :
                                                <Controller
                                                    control={control}
                                                    name="tarApiRedzy"
                                                    rules={{ required: { value: false, message: 'Por favor, preencher esse campo.' } }}
                                                    render={({ field }: any) => (
                                                        <div className="endpoint">
                                                            <Form.Select
                                                                {...field}
                                                                as="select"
                                                                variant="standard"
                                                                margin="normal"
                                                                disabled={true}
                                                                autoComplete='off'
                                                            >
                                                                <option value="">Selecione</option>
                                                            </Form.Select>
                                                        </div>
                                                    )}
                                                />
                                            }
                                        </Form.Group>
                                        <Form.Group as={Col} md="4" controlId="validationCustom02">
                                            <Form.Label>Importação Planilha:</Form.Label>
                                            {tarifType === "4"
                                                ?
                                                <>
                                                    <Controller
                                                        control={control}
                                                        name="tarImportaPlan"
                                                        rules={{ required: { value: true, message: 'Por favor, preencher esse campo.' } }}
                                                        render={({ field }: any) => (
                                                            <div className={errors.tarImportaPlan !== undefined ? "endpoint-error" : "endpoint"}>
                                                                <Form.Select
                                                                    {...field}
                                                                    as="select"
                                                                    aria-invalid={errors?.tarImportaPlan ? "true" : ""}
                                                                    variant="standard"
                                                                    margin="normal"
                                                                    disabled={false}
                                                                    autoComplete='off'
                                                                >
                                                                    <option value="">Selecione</option>
                                                                </Form.Select>
                                                            </div>
                                                        )}
                                                    />
                                                    <ErrorMessage
                                                        errors={errors}
                                                        name="tarImportaPlan"
                                                        render={({ message }) => <small style={{ color: "red" }}>{message}</small>}
                                                    />
                                                </>
                                                :
                                                <Controller
                                                    control={control}
                                                    name="tarImportaPlan"
                                                    rules={{ required: { value: false, message: 'Por favor, preencher esse campo.' } }}
                                                    render={({ field }: any) => (
                                                        <div className="endpoint">
                                                            <Form.Select
                                                                {...field}
                                                                as="select"
                                                                variant="standard"
                                                                margin="normal"
                                                                placeholder="Selecione"
                                                                value=""
                                                                disabled={true}
                                                                autoComplete='off'
                                                            >
                                                                <option value="">Selecione</option>
                                                            </Form.Select>
                                                        </div>
                                                    )}
                                                />
                                            }
                                        </Form.Group>
                                    </Row>
                                    <hr className="my-5" />
                                    <h5>BRASPAG</h5>
                                    <Row className="mb-3">
                                        <Form.Group as={Col} md="4" controlId="validationCustom01">
                                            <Form.Label>{t('accordionFinancial.cadBras')}?</Form.Label>
                                            <Controller
                                                control={control}
                                                name="braspag"
                                                rules={{ required: { value: true, message: 'Por favor, selecione o cadastro na Braspag.' } }}
                                                render={({ field }: any) => (
                                                    <GetYesOrNot
                                                        propsField={field}
                                                        propsErrors={errors}
                                                        propsLabel="Braspag"
                                                    />
                                                )}
                                            />
                                            <ErrorMessage
                                                errors={errors}
                                                name="braspag"
                                                render={({ message }) => <small style={{ color: "red" }}>{message}</small>}
                                            />
                                        </Form.Group>
                                        <Form.Group as={Col} md="4" controlId="validationCustom02">
                                            <Form.Label>{t('accordionFinancial.freeBras')}</Form.Label>
                                            <Controller
                                                control={control}
                                                name="feeBraspag"
                                                rules={{ required: { value: Object(isBraspag).id === '1' ? true : false, message: 'Por favor, informe o Free Braspag' } }}
                                                render={({ field }: any) => (
                                                    <Form.Control type="text"
                                                        {...field}
                                                        aria-invalid={errors?.feeBraspag ? "true" : ""}
                                                        fullWidth
                                                        variant="standard"
                                                        margin="normal"
                                                        autoComplete='off'
                                                        disabled={Object(isBraspag).id === '1' ? false : true}
                                                        required />
                                                )}
                                            />
                                            <ErrorMessage
                                                errors={errors}
                                                name="feeBraspag"
                                                render={({ message }) => <small style={{ color: "red" }}>{message}</small>}
                                            />
                                        </Form.Group>
                                        <Form.Group as={Col} md="2" controlId="validationCustom02">
                                            <Form.Label>{t('accordionFinancial.mdrBras')} %</Form.Label>
                                            <Controller
                                                control={control}
                                                name="mdrBraspag"
                                                rules={{ required: { value: Object(isBraspag).id === '1' ? true : false, message: 'Por favor, informe o MDR Braspag %' } }}
                                                render={({ field }: any) => (
                                                    <Form.Control
                                                        type="text"
                                                        {...field}
                                                        aria-invalid={errors?.mdrBraspag ? "true" : ""}
                                                        fullWidth
                                                        variant="standard"
                                                        margin="normal"
                                                        autoComplete='off'
                                                        disabled={Object(isBraspag).id === '1' ? false : true}
                                                        required />
                                                )}
                                            />
                                            <ErrorMessage
                                                errors={errors}
                                                name="mdrBraspag"
                                                render={({ message }) => <small style={{ color: "red" }}>{message}</small>}
                                            />
                                        </Form.Group>
                                        <Form.Group as={Col} md="2" controlId="validationCustom02">
                                            <Form.Label>{t('accordionFinancial.status')}</Form.Label>
                                            <Controller
                                                control={control}
                                                name="status"
                                                /* rules={{ required: { value: true, message: 'Por favor, informe o status' } }} */
                                                render={({ field }: any) => (
                                                    <div className={errors.status !== undefined ? "endpoint-error" : "endpoint"}>
                                                        <Form.Select
                                                            {...field}
                                                            aria-invalid={errors?.status ? "true" : ""}
                                                            fullWidth
                                                            as="select"
                                                            variant="standard"
                                                            margin="normal"
                                                            required
                                                            disabled={Object(isBraspag).id === '1' ? false : true}
                                                            autoComplete='off'
                                                        >
                                                            <option value=''>Selecione</option>
                                                            <option value="1">Bloqueado</option>
                                                            <option value="2">Não bloqueado</option>
                                                        </Form.Select>
                                                    </div>
                                                )}
                                            />
                                            <ErrorMessage
                                                errors={errors}
                                                name="status"
                                                render={({ message }) => <small style={{ color: "red" }}>{message}</small>}
                                            />
                                        </Form.Group>
                                    </Row>
                                    <hr className="my-5" />

                                    <h5 className="text-uppercase mb-3">LIO</h5>

                                    <Row>
                                        <p>Pode vender faturado?</p>
                                        <label /* htmlFor="sell" */ className="col-md-1">
                                            <Form.Group>
                                                <Controller
                                                    control={control}
                                                    name="cashModePermission"
                                                    rules={{ required: { value: true, message: 'Por favor, selecione um dos campos.' } }}
                                                    render={({ field }: any) => (
                                                        <Form.Check
                                                            {...field}
                                                            type="radio"
                                                            id="radio1"
                                                            required
                                                            autoComplete='off'
                                                            value="1"
                                                            defaultChecked={infoEdit?.cashModePermission === 'Sim'}
                                                            label="Sim"
                                                        />
                                                    )}
                                                />
                                            </Form.Group>
                                        </label>
                                        <label /* htmlFor="sell" */ className="col-md-1">
                                            <Form.Group>
                                                <Controller
                                                    control={control}
                                                    name="cashModePermission"
                                                    //rules={{ required: { value: true, message: 'Por favor, selecione um dos campos.' } }}
                                                    render={({ field }: any) => (
                                                        <Form.Check
                                                            {...field}
                                                            type="radio"
                                                            id="radio2"
                                                            required
                                                            autoComplete='off'
                                                            value="2"
                                                            defaultChecked={infoEdit?.cashModePermission === 'Não'}
                                                            label="Não"
                                                        />
                                                    )}
                                                />
                                            </Form.Group>
                                        </label>
                                        <ErrorMessage
                                            errors={errors}
                                            name="sell"
                                            render={({ message }) => <small style={{ color: "red" }}>{message}</small>}
                                        />
                                    </Row>
                                    <hr className="my-5" />

                                    <h5 className="text-uppercase mb-3">{t('accordionFinancial.payment')}</h5>

                                    <Row>
                                        <p>{t('accordionFinancial.concAut?')}?</p>
                                        <label /* htmlFor="conciliacaoAutomatica" */ className="col-md-1">
                                            <Form.Group>
                                                <Controller
                                                    control={control}
                                                    name="conciliacaoAutomatica"
                                                    rules={{ required: { value: true, message: 'Por favor, selecione um dos campos.' } }}
                                                    render={({ field }: any) => (
                                                        <Form.Check
                                                            {...field}
                                                            type="radio"
                                                            id="radio3"
                                                            required
                                                            autoComplete='off'
                                                            value="1"
                                                            defaultChecked={infoEdit?.conciliacaoAutomatica === '1'}
                                                            label={t('accordionFinancial.yes')}
                                                        />
                                                    )}
                                                />
                                                {/*                                                 <ErrorMessage
                                                    errors={errors}
                                                    name="conciliacaoAutomatica"
                                                    render={({ message }) => <small style={{ color: "red" }}>{message}</small>}
                                                /> */}
                                            </Form.Group>
                                        </label>
                                        <label /* htmlFor="conciliacaoAutomatica" */ className="col-md-1">
                                            <Form.Group>
                                                <Controller
                                                    control={control}
                                                    name="conciliacaoAutomatica"
                                                    //rules={{ required: { value: true, message: 'Por favor, preencher esse campo' } }}
                                                    render={({ field }: any) => (
                                                        <Form.Check
                                                            {...field}
                                                            type="radio"
                                                            id="radio4"
                                                            required
                                                            autoComplete='off'
                                                            value="2"
                                                            defaultChecked={infoEdit?.conciliacaoAutomatica === '2'}
                                                            label={t('accordionFinancial.no')}
                                                        />
                                                    )}
                                                />
                                                {/*                                                 <ErrorMessage
                                                    errors={errors}
                                                    name="conciliacaoAutomatica"
                                                    render={({ message }) => <small style={{ color: "red" }}>{message}</small>}
                                                /> */}
                                            </Form.Group>
                                        </label>
                                        <ErrorMessage
                                            errors={errors}
                                            name="conciliacaoAutomatica"
                                            render={({ message }) => <small style={{ color: "red" }}>{message}</small>}
                                        />
                                    </Row>
                                    <p></p>
                                    <Row className="mb-3">
                                        <Form.Group as={Col} md="2" controlId="validationCustomRG">
                                            <Form.Label>{t('accordionFinancial.coin')}</Form.Label>
                                            <Controller
                                                control={control}
                                                name="currency"
                                                rules={{ required: { value: true, message: 'Por favor, preencher esse campo' } }}
                                                render={({ field }: any) => (
                                                    <GetCurrency propsField={field} propsErrors={errors} propsLabel={t('accordionFinancial.coin')} />
                                                )}
                                            />
                                            <ErrorMessage
                                                errors={errors}
                                                name="currency"
                                                render={({ message }) => <small style={{ color: "red" }}>{message}</small>}
                                            />
                                        </Form.Group>
                                        <Form.Group as={Col} md="2" controlId="validationCustomRG">
                                            <Form.Label>{t('accordionFinancial.payment')}:</Form.Label>
                                            <Controller
                                                control={control}
                                                name="paymentType"
                                                rules={{ required: { value: true, message: 'Por favor, preencher esse campo' } }}
                                                render={({ field }: any) => (
                                                    <GetPaymentForms propsField={field} propsErrors={errors} propsLabel={t('accordionFinancial.payment')} />
                                                )}
                                            />
                                            <ErrorMessage
                                                errors={errors}
                                                name="paymentType"
                                                render={({ message }) => <small style={{ color: "red" }}>{message}</small>}
                                            />
                                        </Form.Group>
                                        <ControllerPayment />
                                    </Row>
                                    <hr className="my-5" />
                                    <h5 className="text-uppercase mb-3">{t('accordionFinancial.coastCenter')}</h5>

                                    <Row>
                                        <FinancialTable
                                            isEdit={isEdit}
                                            hasSavedInputs={hasSavedInputs}
                                            setMessageError={setMessageError}
                                            messageError={messageError}
                                            action={getCosteCenter}
                                            infoEdit={infoEdit?.costCenterSupplierDTO}
                                            disableAddContact={false/* disableAddContact */}
                                        />
                                    </Row>
                                    <Row className="d-flex justify-content-end mx-0 mt-5">
                                        {
                                            isEdit === true
                                                ?
                                                <Button
                                                    className="form-button cancel"
                                                    onClick={handleCancel}
                                                >
                                                    Cancelar
                                                </Button>
                                                :
                                                <Button
                                                    type="button"
                                                    variant="outline"
                                                    className="form-button back-btn btn-outline-primary"
                                                    onClick={() => {
                                                        setStepCtrl(1)
                                                    }}
                                                >
                                                    voltar
                                                </Button>
                                        }
                                        <Button
                                            type="submit"
                                            className="form-button"
                                        >
                                            {t('accordionDocuments.buttonContinue')}
                                        </Button>
                                    </Row>
                                </Form>
                            </div>
                        </Row>
                    </Container>
                </Card.Body>
            </Accordion.Collapse>
        </Card>
    )
}

export default AccordionFinancial;