import React, { useEffect, useState } from "react";
import { Container, Row, Col, InputGroup } from "react-bootstrap";

import "../../../../assets/sass/modal.scss";

//import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import { Controller, useForm } from "react-hook-form";
import Modal from "react-bootstrap/Modal";
//import IconSucess from "../../../assets/icons/modal-sucess.svg";
import api from "../../../../services/api";

import SingleCalendarEditable from '../../../../components/Calendar/SingleCalendarEditable';
import GetResourcesByType from "../../../../components/C2Points/GroupFile/GetResourcesByType";
import GetSuppliersFilter from "../../../../components/C2Points/GetSuppliersByResource";
import SearchAllProduct from '../../../../components/C2Points/GroupFile/SearchAllProduct';
import SearchAllCustomized from "../../../../components/C2Points/GroupFile/SearchAllCustomized";
import SearchAllCity from "../../../../components/C2Points/GroupFile/SearchAllCity";
import DropdownNumberPeople from "../../../../components/DropdownNumberPeopleGroupFile/DropdownNumberPeopleGroupFile";

import AutoComplete from "react-google-autocomplete";
import { geocodeByAddress, getLatLng } from 'react-google-places-autocomplete';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import GetProductTransferFilter from "../../../../components/C2Points/GroupFile/GetProductTransferFilter";

export interface propModal {
  action: any,
  info: any,
  markup: any,
  fileTotalQuantity: any,
  fileTotalAdult: any,
  fileTotalChild: any,
  fileTotalInfant: any,
  t: any
};

const FormGroupFileServices: React.FC<propModal> = ({
  action, info, markup, fileTotalQuantity, fileTotalAdult, fileTotalChild, fileTotalInfant, t
}: propModal, props: any) => {

  //const [receiptDays, setReceiptDays] = useState<any>(Array.from({ length: 32 }, (_, i) => i));

  //const [installmentNumber, setInstallmentNumber] = useState<any>(info ? info.installmentNumber : parseInt(lastInstallment)+1);

  const [serviceType, setServiceType] = useState<any>(info ? `${info.type}` : '6');

  const [supplierId, setSupplierId] = useState<any>(info ? `${info.supplierId}` : '');
  const [supplierName, setSupplierName] = useState<any>(info ? `${info.supplierName}` : '');

  const [resourceId, setResourceId] = useState<any>(info ? `${info.resourceId}` : '');
  const [productCode, setProductCode] = useState<any>(info ? `${info.productCode}` : '');
  const [serviceName, setServiceName] = useState<any>(info ? `${info.serviceName}` : '');
  const [resourceCategId, setResourceCategId] = useState<any>(info && info.resourceCategId ? `${info.resourceCategId}` : '');

  const [productType, setProductType] = useState<any>(info && info.productType ? info.productType : 1);
  const [transferOrigin, setTransferOrigin] = useState<any>('');
  const [latOrigin, setLatOrigin] = useState<any>('');
  const [longOrigin, setLongOrigin] = useState<any>('');
  const [transferDestiny, setTransferDestiny] = useState<any>('');
  const [latDestiny, setLatDestiny] = useState<any>('');
  const [longDestiny, setLongDestiny] = useState<any>('');

  const [globalPrice, setGlobalPrice] = useState<any>(info ? `${info.globalPrice}` : 1);

  const [serviceDate, setServiceDate] = useState<any>(info ? info.serviceDate : '');

  const [totalQuantity, setTotalQuantity] = useState<any>(info ? info.totalQuantity : fileTotalQuantity);
  const [totalAdult, setTotalAdult] = useState<any>(info ? info.totalAdult : fileTotalAdult);
  const [totalChild, setTotalChild] = useState<any>(info ? info.totalChild : fileTotalChild);
  const [totalInfant, setTotalInfant] = useState<any>(info ? info.totalInfant : fileTotalInfant);

  const [controlServiceTime, setControlServiceTime] = useState<any>(0);

  const {
    control,
    watch,
    setValue,
    handleSubmit,
    formState: { errors },
  } = useForm({
    defaultValues: {
      type: info ? info.type : '6',
      resourceId: info ? info.resourceId ? info.resourceId : info.productCode : '',
      //productCode: info ? info.productCode : '',
      serviceObs: info ? info.serviceObs : '',
      supplierId: info ? info.supplierId : '',
      globalPrice: info ? info.globalPrice : '1',

      productType: info && info.productType ? info.productType : 1,

      totalQuantity: '',
      totalAdult: '',
      totalChild: '',
      totalInfant: '',

      netAmount: info ? formatValue(info.netAmount) : '',
      saleAmount: info ? formatValue(info.saleAmount) : '',
      serviceDate: info ? info.serviceDate : '',
      serviceTime: info ? info.serviceTime : '',
      city: info ? info.city : '',
      language: info ? info.language : '1',
      status: info ? info.status : '1',

      id: info ? info.id : -1
    },
  });

  //const watchServiceType = watch('type', '');
  const watchNetAmount = watch('netAmount', '');

  //const watchServiceDate = watch('serviceDate', '');

  //const watchTotalQuantity = watch('totalQuantity', '');
  //const watchTotalAdult = watch('totalAdult', '');
  //const watchTotalChild = watch('totalChild', '');
  //const watchTotalInfant = watch('totalInfant', '');

  const watchServiceTime = watch(`serviceTime`, false);

  useEffect(() => {
      setControlServiceTime(watchServiceTime?.length)
      if (watchServiceTime?.length === 2) {
          if (watchServiceTime?.length > controlServiceTime) {
              setValue('serviceTime', `${watchServiceTime}:`)
          }
      }


      if (watchServiceTime?.length > 5) {
          var controlLength: any = watchServiceTime;
          controlLength = controlLength.split('');
          controlLength.pop();
          controlLength = controlLength.join('');
          setValue('serviceTime', `${controlLength}`)
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [watchServiceTime])


  const onSubmitContact = (data: any) => {

    // save object
    data.type = serviceType;
    data.supplierId = supplierId;
    data.resourceId = resourceId;
    data.productCode = productCode;
    data.globalPrice = globalPrice;
    data.totalQuantity = totalQuantity;
    data.totalAdult = totalAdult;
    data.totalChild = totalChild;
    data.totalInfant = totalInfant;

    data.serviceDate = serviceDate;

    //data.serviceTypeName = getServiceTypeName(serviceType);
    data.supplierName = supplierName;
    data.serviceName = serviceName;

    data.netAmount = strToFloat(data.netAmount);
    data.saleAmount = strToFloat(data.saleAmount);

    data.productType = productType;

    if(productType === 2) {
      data.origin = transferOrigin;
      data.latOrigin = latOrigin;
      data.longOrigin = longOrigin;
      data.destiny = transferDestiny;
      data.latDestiny = latDestiny;
      data.longDestiny = longDestiny;
    }

    if (!info) {
      data.id = (Math.floor(Math.random() * 100000) + 1) * (-1);
    }

    //console.log(data);
    action(data);
  };

  async function updateNetValue(supplierId: any, resourceId: any, productCode: any, serviceDate: any, serviceTime: any, totalQuantity: any, totalAdult: any, totalChild: any, totalInfant: any, categId: any = 0) {
    // se campo valor net for vazio
    if( //watchNetAmount === "" && 
      supplierId !== "" && (resourceId !== "" || productCode !== "")) {
      // pegar valor net do produto/recurso
      var defNetAmount = 0;
      
      if(serviceType === '6') {
        // pega net customized
        defNetAmount = await getCustomizedNetAmount(productCode);

      } else if(serviceType === '7') {
        // pega net produto
        defNetAmount = await getProductNetAmount(productCode, serviceDate, serviceTime, totalAdult, totalChild, totalInfant);
      } else {
        // pega net recurso
        defNetAmount = await getResourceNetAmount(supplierId, serviceType, serviceDate, "", resourceId, categId, totalQuantity, totalAdult, totalChild, totalInfant);
      }
      if(defNetAmount) {
        // gravar no campo do valor net
        setValue('netAmount',  defNetAmount.toFixed(2).replace(".", ","));
        
        // calcular o valor de venda
        calcSaleAmount(defNetAmount);
      }
    }
  }

  function calcSaleAmount(netAmount: any) {
    if(watchNetAmount !== "") {
      const markupF = parseFloat(markup ? markup.replace(',', '.') : 0);

      //const defSaleAmount = netAmount * (1 + (markupF / 100));

      const defSaleAmount = netAmount / (1 - (markupF / 100));

      //arraySaleAmount[index] = defSaleAmount.toFixed(2).replace(".", ",");

      setValue('saleAmount', defSaleAmount.toFixed(2).replace(".", ","));
    }
  }

  async function getProductNetAmount(productCode: any, serviceDate: any, serviceTime: any, totalAdult: any, totalChild: any, totalInfant: any) {
    if(!productCode || !serviceDate || !serviceTime) return 0;

    const token = localStorage.getItem('GroupId') || '{}';
    const config = {
        headers: { 'Authorization': `Bearer ${token}`, },
    };

    try {
      const { data } = await api.post(`/Products/CalcProductTourTariff`, {
        "productCode": productCode,
        "serviceDate": serviceDate,
        "serviceTime": serviceTime,
        "adults": totalAdult,
        "childs": totalChild,
        "infants": totalInfant
      }, config);

      if (data.status !== 400) {
        const obj = data.data;
        const amount = obj.total;

        //setIdTariff(obj.id);
        //setIdModality(!!!!TODO!!!!);
        //setTarUniqueCode(obj.tarUniqueCode);
        //setModUniqueCode(obj.modUniqueCode);
        
        return amount;
      }
    } catch (error: any) {
        if (error?.response?.status === 401) { }
        if (error?.response?.status === 400) { }
    }
  }

  async function getCustomizedNetAmount(productCode: any) {
    const token = localStorage.getItem('GroupId') || '{}';
    const config = {
        headers: { 'Authorization': `Bearer ${token}`, },
    };

    try {
        const { data } = await api.get(`/GroupFile/GetCustomizedProduct/${productCode}`, config);
        if (data.status !== 400) {
            const obj = data.data;
            const amount = obj.netAmount;

            return amount;
        }
    } catch (error: any) {
        if (error?.response?.status === 401) { }
        if (error?.response?.status === 400) { }
    }
  }

  async function getResourceNetAmount(supplier: any, type : any, startTime: any, productCode: any, resourceId: any, categId: any, totalPax: any, totalAdult: any, totalChild: any, totalInfant: any) {
    const token = localStorage.getItem('GroupId') || '{}';
    const config = {
        headers: { 'Authorization': `Bearer ${token}`, },
    };

    try {
        const { data } = await api.post(`/Manifest/TryGetSupplierResourceTax`, {
            "supplierId": supplier,
            "resourceType": type,
            "tourDate": startTime,
            "productCode": productCode,
            "resourceId": resourceId,
            "categId": categId,
            "QtdePax": totalPax,
            "QtdeAdults": totalAdult,
            "QtdeChilds": totalChild,
            "QtdeInfants": totalInfant,
            "QtdeElders": 0
        }, config);
        if (data.status !== 400) {
            const obj = data.data;
            const amount = obj.data;

            return amount;
        }
    } catch (error: any) {
        if (error?.response?.status === 401) { }
        if (error?.response?.status === 400) { }
    }
  }

  function handleSupplierId(supplier: any) {
    setSupplierId(supplier.code); 
    setSupplierName(supplier.name); 
    updateNetValue(supplier.code, resourceId, productCode, serviceDate, watchServiceTime, totalQuantity, totalAdult, totalChild, totalInfant);
  }

  function handleProductCode(prod: any) {
    setResourceId('');
    setProductCode(prod.code); 
    setServiceName(prod.description); 

    updateNetValue(supplierId, resourceId, prod.code, serviceDate, watchServiceTime, totalQuantity, totalAdult, totalChild, totalInfant); 
    //refreshBlocks();
  }

  function handleResourceId(resource: any) {
    setResourceId(resource.code); 
    setProductCode(''); 
    setServiceName(resource.description); 
    setResourceCategId(resource.type);
    updateNetValue(supplierId, resource.code, productCode, serviceDate, watchServiceTime, totalQuantity, totalAdult, totalChild, totalInfant, resource.type);
  }

  function handleProductTransfer(transfer: any) {
    setResourceId('');
    setProductCode(transfer.code);
    setServiceName(transfer.description); 

    const defNetAmount = transfer.netAmount;

    if(defNetAmount) {
      // gravar no campo do valor net
      setValue('netAmount',  defNetAmount.toFixed(2).replace(".", ","));
      
      // calcular o valor de venda
      calcSaleAmount(defNetAmount);
    }
  }

  async function handleTotalPax(adults: any, childs: any, infants: any) {
    //setNumberPeople(peoples)
    //console.log(peoples);
    setTotalAdult(adults);
    setTotalChild(childs);
    setTotalInfant(infants);

    var adultInt = (adults) ? parseInt(adults) : 0;
    var childsInt = (childs) ? parseInt(childs) : 0;
    var infantsInt = (infants) ? parseInt(infants) : 0;

    const totalQ = adultInt + childsInt + infantsInt;

    setTotalQuantity(totalQ);

    await updateNetValue(supplierId, resourceId, productCode, serviceDate, watchServiceTime, totalQ, adults, childs, infants);
    //refreshBlocks();
  }

  function handleServiceType(e: any) {
    setServiceType(e.target.value); 
    if(e.target.value === '6') {
      setProductType(5); 
    } else {
      setProductType(1);
    }
  }

  function handleTransferCheck() {
    if(productType !== 2) {
      setProductType(2); 
      setGlobalPrice('2');
    } else {
      setProductType(1);
    }
  }

  const fixDate = (data: any) => {
    if (!data) return '';
    var aux = data.split('/');

    return (`${aux[2]}-${aux[1]}-${aux[0]}`);
  }

  function formatDate(cell: any) {
    if (!cell) return '';
    //var date = convertUTCDateToLocalDate(new Date(cell));
    var aux: any = cell.split("T")[0].split("-");
    var date: any = `${aux[2]}/${aux[1]}/${aux[0]}`;
    return date;
  }

  function formatValue(cell: any) {
    //console.log(Math.sign(cell))
    if(cell && cell !== '') {
        return cell.toFixed(2).replace(".", ",");
    } else {
        return '0,00';
    }
  }

  const strToFloat = (str: string): any => {
    if(!str || str === '') return 0.0;
    str = '' + str;
    return parseFloat(str.replace(/[^0-9,]*/g, '').replace(',', '.'));
  }

  useEffect(() => {
    if(!info && (serviceType === '4' || serviceType === '5')) setGlobalPrice(2);
  }, [serviceType]);

  return (
    <>
      <Container className="bg-modal-contact p-0" fluid>
        <Row>
          {/* <h5 className="contact-title mb-5">Taxa e Prazo</h5> */}
          <Form
            className="form-add-contact"
            noValidate
          >
            <div className="">
              <Row className="mb-3">
                <Form.Group as={Col} md="6" controlId="validationCustom03">
                  <Form.Label>{t("groupsAndCustomized.services.type")}</Form.Label>
                  <Controller
                    control={control}
                    name="type"
                    //rules={{ required: { value: true, message: 'Por favor, preencher esse campo' } }}
                    render={({ field }: any) => (
                      <Form.Select
                        {...field}
                        aria-labelledby="aria-label"
                        as="select"
                        onChange={(e: any) => {handleServiceType(e); field.onChange(e); }}
                        value={serviceType}
                        className="form-control py-1 py-md-2"
                      >
                        <option value={1}>{t("groupsAndCustomized.services.typeOpts.vehicle")}</option> 
                        <option value={2}>{t("groupsAndCustomized.services.typeOpts.driver")}</option> 
                        <option value={3}>{t("groupsAndCustomized.services.typeOpts.guide")}</option> 
                        <option value={4}>{t("groupsAndCustomized.services.typeOpts.ticket")}</option> 
                        <option value={5}>{t("groupsAndCustomized.services.typeOpts.restaurant")}</option> 
                        <option value={6}>{t("groupsAndCustomized.services.typeOpts.other")}</option> 
                        <option value={7}>{t("groupsAndCustomized.services.typeOpts.product")}</option> 
                      </Form.Select>
                    )}
                  />
                </Form.Group>
                <Form.Group as={Col} md="6" controlId="validationCustom03">
                  <Form.Label>{t("groupsAndCustomized.services.supplier")}</Form.Label>
                  {serviceType !== '7' ?
                    <Controller
                      control={control}
                      name="supplierId"
                      rules={{ required: { value: true, message: t("groupsAndCustomized.validationMsg.supplier") }, }}
                      //defaultValue={arraySupplierId[index]}
                      render={({ field }: any) => (
                        <GetSuppliersFilter
                          supplier={{code: supplierId, name: supplierName}}
                          setSupplier={(supplier: any) => { handleSupplierId(supplier); field.onChange(supplier); }}
                          errors={errors['supplierId'] ? true : false}
                          resourceType={serviceType} 
                        />
                      )}
                    />
                  :
                  <div className="d-flex">
                    <div className="col-md-10 pr-3">
                      <Controller
                        control={control}
                        name="supplierId"
                        rules={{ required: { value: true, message: t("groupsAndCustomized.validationMsg.supplier") }, }}
                        //defaultValue={arraySupplierId[index]}
                        render={({ field }: any) => (
                          <GetSuppliersFilter
                            supplier={{code: supplierId, name: supplierName}}
                            setSupplier={(supplier: any) => { handleSupplierId(supplier); field.onChange(supplier); }}
                            errors={errors['supplierId'] ? true : false}
                            resourceType={serviceType} 
                          />
                        )}
                      />
                    </div>
                    <div className="col-md-2 d-flex">
                      <Form.Label className="form-check-label d-flex m-0 align-items-center">
                          <Controller
                            control={control}
                            name="productType"
                            render={({ field }: any) => (
                              <Form.Check
                                  {...field}
                                  fullWidth
                                  variant="standard"
                                  margin="normal"
                                  type="checkbox"
                                  defaultChecked={productType === 2}
                                  value={productType}
                                  onClick={handleTransferCheck}
                              />
                            )}
                          />
                          <span className="ml-2">Transfer</span>
                      </Form.Label>
                    </div>
                  </div>
                  }
                </Form.Group>
              </Row>
              {productType === 2 ?
              <Row className="mb-3">
                <Form.Group as={Col} md="6" controlId="validationCustom03">
                  <Form.Label>{t("groupsAndCustomized.services.origin")}</Form.Label>
                  {/* <Form.Control
                    autoComplete="off"
                    onChange={(e: any) => { setTransferOrigin(e.target.value); }}
                    value={transferOrigin}
                    className="form-control py-1 py-md-2"
                  /> */}
                  <div className="col-12 col-md-6 col-lg-3 w-100">
                    <div className="input-group mb-2 mb-lg-0">
                      <div className="input-group-prepend">
                        <span
                          className="input-group-text rounded-left-lg spanIcon"
                          style={{height: '45px'}}
                          id="homeDestino"
                        >
                          <FontAwesomeIcon
                            icon={["fal", "map-marker-alt"]}
                            size="1x"
                            className="mr-2"
                          />
                        </span>
                      </div>
                      <AutoComplete
                        options={{
                          types: ["establishment"],
                          fields: ["name"]
                        }}
                        defaultValue={transferOrigin}
                        placeholder={t("sales.tabSearchs.origin")}
                        apiKey="AIzaSyBv2aZ2YO_aW4PIEmXoxHgxC8Ps8DB0o-s"
                        style={{height: '2.7rem'}}
                        onPlaceSelected={(place) => {
                          //console.log(place)
                          setTransferOrigin(place.name);
                          // eslint-disable-next-line no-lone-blocks
                          {
                            geocodeByAddress(`${place.name}`)
                              .then(results => getLatLng(results[0]))
                              .then(({ lat, lng }) => {
                                setLatOrigin(lat);
                                setLongOrigin(lng);
                                //console.log(`Origem: LAT - ${lat} - LONG - ${lng}`)
                              }
                              );
                          }
                        }}
                      />
                      {/* <div className="d-flex mt-1 mx-1">
                        <small style={{ color: "red" }}></small>
                      </div> */}
                    </div>
                  </div>
                </Form.Group>
                <Form.Group as={Col} md="6" controlId="validationCustom03">
                  <Form.Label>{t("groupsAndCustomized.services.destiny")}</Form.Label>
                  {/* <Form.Control
                    autoComplete="off"
                    onChange={(e: any) => { setTransferDestiny(e.target.value); }}
                    value={transferDestiny}
                    className="form-control py-1 py-md-2"
                  /> */}
                  <div className="col-12 col-md-6 col-lg-3 w-100">
                    <div className="input-group mb-2 mb-lg-0">
                      <div className="input-group-prepend">
                        <span
                          className="input-group-text rounded-left-lg spanIcon"
                          style={{height: '45px'}}
                          id="homeDestino"
                        >
                          <FontAwesomeIcon
                            icon={["fal", "map-marker-alt"]}
                            size="1x"
                            className="mr-2"
                          />
                        </span>
                      </div>

                      <AutoComplete
                        options={{
                          types: ["establishment"],
                          fields: ["name"]
                        }}
                        defaultValue={transferDestiny}
                        placeholder={t("sales.tabSearchs.destination")}
                        apiKey="AIzaSyBv2aZ2YO_aW4PIEmXoxHgxC8Ps8DB0o-s"
                        style={{height: '2.7rem'}}
                        onPlaceSelected={(place) => {
                          setTransferDestiny(place.name);
                          // eslint-disable-next-line no-lone-blocks
                          {
                            geocodeByAddress(`${place.name}`)
                              .then(results => getLatLng(results[0]))
                              .then(({ lat, lng }) => {
                                setLatDestiny(lat);
                                setLongDestiny(lng);
                                //console.log(`Destino: LAT - ${lat} - LONG - ${lng}`)
                              }
                              );
                          }
                        }}
                      />
                      {/* <div className="d-flex mt-1 mx-1">
                        <small style={{ color: "red" }}>{errorDestine}</small>
                      </div> */}
                    </div>
                  </div>
                </Form.Group>
              </Row>
              :<></>}
              {productType !== 2 ?
              <Row className="mb-3">
                <Form.Group as={Col} md="6" controlId="validationCustom03">
                  <Form.Label>{t("groupsAndCustomized.services.resourceProduct")}</Form.Label>
                  <Controller
                    control={control}
                    name="resourceId"
                    rules={{ required: { value: true, message: t("groupsAndCustomized.validationMsg.service") }, }}
                    render={({ field }: any) => (
                      (serviceType === '6'? // other
                      // <SeachAllCustomized product={{code: productCode, description: serviceName}} setProduct={(prod: any) => {handleProductCode(prod); field.onChange(prod);}} errors={errors['resourceId'] ? true : false} />
                      <Form.Control
                        {...field}
                        aria-invalid={errors['resourceId'] ? "true" : ""}
                        variant="standard"
                        margin="normal"
                        autoComplete="off"
                        onChange={(e: any) => { setResourceId(''); setProductCode(''); setServiceName(e.target.value); field.onChange(e); }}
                        value={serviceName}
                        className="form-control py-1 py-md-2"
                      />
                      : serviceType === '7' || serviceType === '3' ? // product, guide
                        <SearchAllProduct product={{code: productCode, description: serviceName}} setProduct={(prod: any) => {handleProductCode(prod); field.onChange(prod);}} errors={errors['resourceId'] ? true : false} />
                      : <GetResourcesByType resourceType={serviceType} resource={{code: resourceId, description: serviceName}} setResource={(resource: any) => {handleResourceId(resource); field.onChange(resource); }} errors={errors['resourceId'] ? true : false} />
                      ) 
                    )}
                  />
                </Form.Group>
                <Form.Group as={Col} md="6" controlId="validationCustom03">
                  <Form.Label>{t("groupsAndCustomized.services.serviceObs")}</Form.Label>
                  <Controller
                    control={control}
                    name="serviceObs"
                    render={({ field }: any) => (
                      <Form.Control
                        {...field}
                        aria-invalid={errors['serviceObs'] ? "true" : ""}
                        variant="standard"
                        margin="normal"
                        autoComplete="off"
                        className="form-control py-1 py-md-2"
                      />
                    )}
                  />
                </Form.Group>
              </Row>
              : <></>}
              <Row className="mb-3">
                <Form.Group as={Col} md="6" controlId="validationCustom03">
                  <Form.Label>{t("groupsAndCustomized.services.globalPrice")}</Form.Label>
                  <Controller
                    control={control}
                    name="globalPrice"
                    //rules={{ required: { value: true, message: t("groupsAndCustomized.validationMsg.netAmount") }, }}
                    //defaultValue={arrayNetAmount[index]}
                    render={({ field }: any) => (
                      <Form.Select
                        {...field}
                        aria-labelledby="aria-label"
                        as="select"
                        onChange={(e: any) => {setGlobalPrice(e.target.value); if(e.target.value !== '2') { setTotalQuantity(fileTotalQuantity); setTotalAdult(fileTotalAdult); setTotalChild(fileTotalChild); setTotalInfant(fileTotalInfant); } field.onChange(e); }}
                        value={globalPrice}
                        className="form-control py-1 py-md-2"
                      >
                        <option value={1}>{t("groupsAndCustomized.services.globalPriceOpts.singlePrice")}</option> 
                        <option value={2}>{t("groupsAndCustomized.services.globalPriceOpts.priceByPax")}</option> 
                      </Form.Select>
                    )}
                  />
                </Form.Group>
                <Form.Group as={Col} md="6" controlId="validationCustom03">
                  <Form.Label>{t("groupsAndCustomized.services.pax")}</Form.Label>
                  <Controller
                    control={control}
                    name="totalQuantity"
                    rules={{ required: { value: globalPrice === '2' && totalQuantity === 0, message: t("groupsAndCustomized.validationMsg.serviceDate") }, }}
                    //defaultValue={arrayServiceDate[index]}
                    render={({ field }: any) => (
                      <InputGroup
                        hasValidation
                        className={`${errors['totalQuantity'] ? "endpoint-error" : ""}`} 
                        size="sm"
                        placeholder="Pax"
                      >
                        <div className="panel-dropdown bg-white m-0 w-100"  style={{ lineHeight: "1.7rem" }}>
                          <DropdownNumberPeople
                            info={{adults: totalAdult, childs: totalChild, infants: totalInfant}}
                            actionPeople={(people: any) => handleTotalPax(people.adults, people.childs, people.infants)}
                            ranges={{isActiveAdult: true, isActiveChild: true, isActiveElders: false, isActiveInfant: true, isActiveStudent: false, labelAdult: 'Adulto', labelChild: 'Criança', labelInfant: 'Infante'}}
                            disabled={globalPrice !== '2'}
                          />
                        </div>
                      </InputGroup>
                    )}
                  />
                </Form.Group>
              </Row>
              <Row className="mb-3">  
                <Form.Group as={Col} md="6" controlId="validationCustom03">
                  <Form.Label>{t("groupsAndCustomized.services.serviceDate")}</Form.Label>
                  <Controller
                    control={control}
                    name="serviceDate"
                    rules={{ required: { value: true, message: t("groupsAndCustomized.validationMsg.serviceDate") }, }}
                    //defaultValue={arrayServiceDate[index]}
                    render={({ field }: any) => (
                      <InputGroup
                        hasValidation
                        className={` ${errors["serviceDate"] ? "endpoint-error groupfile-date" : "groupfile-date"}`} 
                        size="sm"
                        placeholder="Quando?"
                      >
                        <SingleCalendarEditable setValue={(date: any) => { setServiceDate(fixDate(date)); updateNetValue(supplierId, resourceId, productCode, fixDate(date), watchServiceTime, totalQuantity, totalAdult, totalChild, totalInfant); field.onChange(date); }} date={formatDate(serviceDate)} />
                      </InputGroup>
                    )}// updateNetValue(supplierId, resourceId, productCode, serviceDate, watchServiceTime, totalQuantity, totalAdult, totalChild, totalInfant);
                  />
                </Form.Group>
                <Form.Group as={Col} md="6" controlId="validationCustom03">
                  <Form.Label>{t("groupsAndCustomized.services.serviceTime")}</Form.Label>
                  <Controller
                    control={control}
                    name="serviceTime"
                    rules={{ required: { value: true, message: t("groupsAndCustomized.validationMsg.serviceTime") }, }}
                    //defaultValue={arrayNetAmount[index]}
                    render={({ field }: any) => (
                      <Form.Control
                        {...field}
                        aria-invalid={errors?.serviceTime ? "true" : ""}
                        variant="standard"
                        margin="normal"
                        autoComplete="off"
                        onKeyPress={(e) => !/[0-9]/.test(e.key) && e.preventDefault()}
                        required
                        onChange={(e: any) => { if(e.target.value.length === 5) updateNetValue(supplierId, resourceId, productCode, serviceDate, e.target.value, totalQuantity, totalAdult, totalChild, totalInfant); field.onChange(e); }}
                        //value={arrayNetAmount[index]}
                        className="py-1 py-md-2"
                      />
                    )}
                  />
                </Form.Group>
              </Row>
              {productType === 2 ?
              <Row className="mb-3">
                <Form.Group as={Col} md="6" controlId="validationCustom03">
                  <Form.Label>{t("groupsAndCustomized.services.resourceProduct")}</Form.Label>
                  <Controller
                    control={control}
                    name="resourceId"
                    rules={{ required: { value: true, message: t("groupsAndCustomized.validationMsg.service") }, }}
                    render={({ field }: any) => (
                      <GetProductTransferFilter propsField={field} propsLabel={"Transfer"} propsErrors={errors} 
                        lang={'BR'} dateTransfer={serviceDate} timeTransfer={watchServiceTime} numPeople={totalQuantity} origemLng={longOrigin} origemLat={latOrigin} destinoLng={longDestiny} destinoLat={latDestiny} supplierId={supplierId}
                        setTransfer={handleProductTransfer} />
                    )}
                  />
                </Form.Group>
                <Form.Group as={Col} md="6" controlId="validationCustom03">
                  <Form.Label>{t("groupsAndCustomized.services.serviceObs")}</Form.Label>
                  <Controller
                    control={control}
                    name="serviceObs"
                    render={({ field }: any) => (
                      <Form.Control
                        {...field}
                        aria-invalid={errors['serviceObs'] ? "true" : ""}
                        variant="standard"
                        margin="normal"
                        autoComplete="off"
                        className="form-control py-1 py-md-2"
                      />
                    )}
                  />
                </Form.Group>
              </Row>
              : <></>}
              <Row className="mb-3"> 
                <Form.Group as={Col} md="6" controlId="validationCustom03">
                  <Form.Label>{t("groupsAndCustomized.services.netAmount")}</Form.Label>
                  <Controller
                    control={control}
                    name="netAmount"
                    rules={{ required: { value: true, message: t("groupsAndCustomized.validationMsg.netAmount") }, }}
                    //defaultValue={arrayNetAmount[index]}
                    render={({ field }: any) => (
                      <Form.Control
                        {...field}
                        aria-invalid={errors["netAmount"] ? "true" : ""}
                        variant="standard"
                        margin="normal"
                        autoComplete="off"
                        onChange={(e: any) => { /*arrayNetAmount[index] = e.target.value;*/ field.onChange(e); calcSaleAmount(strToFloat(e.target.value)); }}
                        //value={arrayNetAmount[index]}
                        className="py-1 py-md-2"
                      />
                    )}
                  />
                </Form.Group>
                <Form.Group as={Col} md="6" controlId="validationCustom03">
                  <Form.Label>{t("groupsAndCustomized.services.saleAmount")}</Form.Label>
                  <Controller
                    control={control}
                    name="saleAmount"
                    rules={{ required: { value: true, message: t("groupsAndCustomized.validationMsg.saleAmount") }, }}
                    //defaultValue={arraySaleAmount[index]}
                    render={({ field }: any) => (
                      <Form.Control
                        {...field}
                        aria-invalid={errors["saleAmount"] ? "true" : ""}
                        variant="standard"
                        margin="normal"
                        autoComplete="off"
                        //onChange={(e: any) => { /*arraySaleAmount[index] = e.target.value; */ field.onChange(e); }}
                        //value={arraySaleAmount[index]}
                        className="py-1 py-md-2"
                      />
                    )}
                  />
                </Form.Group>
              </Row>
              <Row className="mb-3">  
                <Form.Group as={Col} md="6" controlId="validationCustom03">
                  <Form.Label>{t("groupsAndCustomized.services.city")}</Form.Label>
                  <Controller
                    control={control}
                    name="city"
                    rules={{ required: { value: true, message: t("groupsAndCustomized.validationMsg.city") }, }}
                    //defaultValue={arrayCity[index]}
                    render={({ field }: any) => (
                      <SearchAllCity city={field.value} setCity={(data: any) => { setValue('city', data); field.onChange(data); }} errors={errors["city"] ? true : false} />
                    )}
                  />
                </Form.Group>
                <Form.Group as={Col} md="6" controlId="validationCustom03">
                  <Form.Label>{t("groupsAndCustomized.services.language")}</Form.Label>
                  <Controller
                    control={control}
                    name="language"
                    //rules={{ required: { value: true, message: t("groupsAndCustomized.validationMsg.netAmount") }, }}
                    //defaultValue={arrayNetAmount[index]}
                    render={({ field }: any) => (
                      <Form.Select
                        {...field}
                        aria-labelledby="aria-label"
                        //name="language"
                        as="select"
                        //onChange={(e: any) => {arrayLanguage[index] = e.target.value; refreshBlocks(); }}
                        //value={arrayLanguage[index]}
                        className="form-control py-1 py-md-2"
                      >
                        <option value={1}>{t("groupsAndCustomized.services.languageOpts.portuguese")}</option> 
                        <option value={2}>{t("groupsAndCustomized.services.languageOpts.english")}</option> 
                        <option value={3}>{t("groupsAndCustomized.services.languageOpts.spanish")}</option> 
                        <option value={4}>{t("groupsAndCustomized.services.languageOpts.italian")}</option>  
                        <option value={5}>{t("groupsAndCustomized.services.languageOpts.french")}</option> 
                        <option value={6}>{t("groupsAndCustomized.services.languageOpts.deutsch")}</option>
                        <option value={7}>{t("groupsAndCustomized.services.languageOpts.corean")}</option>
                        <option value={8}>{t("groupsAndCustomized.services.languageOpts.mandarin")}</option>
                      </Form.Select>
                    )}
                  />
                </Form.Group>
              </Row>
              <Row className="mb-3">  
                <Form.Group as={Col} md="6" controlId="validationCustom03">
                  <Form.Label>{t("groupsAndCustomized.services.status")}</Form.Label>
                  <Controller
                    control={control}
                    name="status"
                    //rules={{ required: { value: true, message: t("groupsAndCustomized.validationMsg.netAmount") }, }}
                    //defaultValue={arrayNetAmount[index]}
                    render={({ field }: any) => (
                      <Form.Select
                        {...field}
                        aria-labelledby="aria-label"
                        name="status"
                        as="select"
                        //onChange={(e: any) => {arrayStatus[index] = e.target.value; refreshBlocks(); }}
                        //value={arrayStatus[index]}
                        className="form-control py-1 py-md-2"
                      >
                        <option value={1}>{t("groupsAndCustomized.services.statusOpts.pending")}</option> 
                        <option value={2}>{t("groupsAndCustomized.services.statusOpts.request")}</option> 
                        <option value={3}>{t("groupsAndCustomized.services.statusOpts.confirmed")}</option> 
                      </Form.Select>
                    )}
                  />
                </Form.Group>
              </Row>
            </div>
          </Form>
        </Row>
      </Container>

      <Modal.Footer>
        <Row className="buttons-modal d-flex justify-content-end mx-0">
          {/* <Button className="form-button mr-3" onClick={props.onHide}>
            Cancelar
          </Button> */}
          <Button
            type="button"
            className="form-button"
            onClick={handleSubmit(onSubmitContact)}
          >
            {t('groupsAndCustomized.buttonSave')}
          </Button>
        </Row>
      </Modal.Footer>


    </>
  );
}

export default FormGroupFileServices;
