import React, { useEffect, useState } from 'react';

import api from '../../../../../../services/api';

import "react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css";
import "react-bootstrap-table-next/dist/react-bootstrap-table2.css";
import BootstrapTable from "react-bootstrap-table-next";

import Pagination from "../../../../../../components/Pagination/Pagination";

import { Form } from "react-bootstrap";

//import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { Controller, useForm } from "react-hook-form";

import ModalConfirm from '../../../../../../components/Modal/ModalConfirm/ModalConfirm';

import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';

import "./ExpirationReceive.scss";

export interface propExp {
    billsReceive: any;
    totalRows: any;
    pageCount: any;
    setPageCount: any;
    rowsPerPage: any;
    setRowsPerPage: any;
    setExpiration: any;
    expiration: any;
    actualDate: any;
    parameters?: any;
}

const ExpirationReceive: React.FC<propExp> = ({
    billsReceive, totalRows, pageCount, setPageCount, rowsPerPage, setRowsPerPage, setExpiration, expiration, actualDate, parameters
}: propExp) => {
    //const [selectedSingleRow, setSelectedSingleRow] = useState<any>();
    //const [modalShow, setModalShow] = useState<any>(false);
    const [selectedCheck, setSelectedCheck] = useState<any>([]);
    const [itemsToSettle, setItemsToSettle] = useState<any>([]);
    const [responseText, setResponseText] = useState<any>();
    const [modalResponse, setModalResponse] = useState<any>();
    const [expirationBills, setExpirationBills] = useState<any>(billsReceive);
    const [expirationPageCount, setExpirationPageCount] = useState<any>(pageCount);
    const [expirationRowsPerPage, setExpirationRowsPerPage] = useState<any>(rowsPerPage);

    const tourAuth: any = JSON.parse(localStorage.getItem('c2tourAuth') || '{}');

    const userName: any = tourAuth.user.nome;
    const userLastName: any = tourAuth.user.lastName;
    const uId: any = tourAuth.user.uId;

    console.log(parameters);

    const {
        control,
        handleSubmit,
    } = useForm();

    const onSubmit = (data: any) => {
        let beginDate: any = []
        let endDate: any = []

        if (actualDate.length === 1) {
            beginDate = actualDate[0].split('/')
        } else if (actualDate.length === 2) {
            beginDate = actualDate[0].split('/')
            endDate = actualDate[1].split('/')
        }

        let today: any = new Date();

        data.dataVcto = expiration.split("/").reverse().join('-');
        data.dataInicial = (actualDate.length > 0) ? `${beginDate[0]}-${beginDate[1]}-${beginDate[2]}` : `${today.getFullYear()}-${today.getMonth() + 1}-${today.getDate()}`
        data.dataFinal = (actualDate.length === 1) ? `${beginDate[0]}-${beginDate[1]}-${parseInt(beginDate[2])}` : (actualDate !== null && actualDate.length === 2) ? `${endDate[0]}-${endDate[1]}-${endDate[2]}` : `${today.getFullYear()}-${today.getMonth() + 1}-${today.getDate()}`;

        let auxExp: any = [];

        parameters.statusPagamento = parameters.statusPagamento !== null ? parameters.statusPagamento : 0;

        for (let i: any = 0; i < itemsToSettle.length; i++) {
            auxExp.push(
                {
                    "id": (itemsToSettle[i].nParcela !== 1) ? parseInt(itemsToSettle[i].installmentsId) : parseInt(itemsToSettle[i].newPaymentsId),
                    "tipo": (itemsToSettle[i].nParcela !== 1) ? 2 : 1//itemsToSettle[i].tipo
                }
            )
        }

        data.items = auxExp;

        async function changeExpiration() {
            if (selectedCheck.length !== 0 && expiration !== "") {
                const token = localStorage.getItem('GroupId') || '{}';
                const config = {
                    headers: { 'Authorization': `Bearer ${token}`, 'Content-Type': 'application/json' },
                };

                try {
                    const res = await api.post('/BillsReceivable/SetDueDateInBatch',
                        {
                            "parametros": parameters,
                            "items": data.items,
                            "dataVcto": data.dataVcto,
                            "uid": uId,
                            "usuario": `${userName} ${userLastName}`
                        }, config
                    );
                    if (res.data.status !== 400) {
                        if (res.data.data.log === 0) {
                            setResponseText(res.data.data.texto)
                            setModalResponse('success');
                            /* setAllowReload(true); */
                        }
                        else if (res.data.data.log === 1) {
                            setResponseText(res.data.data.texto)
                            setModalResponse('error');
                        }
                    }
                } catch (error: any) {
                    
                    if (error?.response?.status === 401) {
                    }
                }
            } else {
                setResponseText("Preencha os campos necessários!");
                setModalResponse('error');
            }
        }
        changeExpiration();

    }

    /* FUNÇÕES */

    function convertValue(cell: any) {
        //console.log(Math.sign(cell))
        return (
            <>
                <span className={Math.sign(cell) === -1 ? "negative" : ""}>{cell}</span>
            </>
        )
    }


    /*converte a data UTC para a data local do usuário*/
    function convertDate(cell: any, row: any) {
        if (cell !== null) {
            //var date = convertUTCDateToLocalDate(new Date(cell));
            var aux: any = cell.split("T")[0].split("-");
            var date: any = `${aux[2]}/${aux[1]}/${aux[0]}`;
            return (
                <>
                    {date}
                </>
            );
        } else {
            return (
                <>
                </>
            );
        }
    }

    /* SOBRE A TABELA */

    function addStatus(cell: any, row: any) {
        return (
            <div
                className={
                    cell === "Conciliado"
                        ? "bg-analise w-100"
                        : cell === "Pago"
                            ? "bg-ativo w-100"
                            : cell === "Pendente"
                                ? "bg-inativo w-100"
                                : cell === "Recebido"
                                    ? "bg-ativo w-100"
                                    : ""
                }
            >
                {cell}
            </div>
        );
    }

    function handleClick(cell: any) {
        const getBills = expirationBills.find((element: { identity: any; }) => element.identity === cell);
        setSelectedCheck((prevState: any) => [...prevState, getBills]);
    }

    useEffect(() => {
        if (selectedCheck.length > 1) {
            var lastOne: any = selectedCheck[selectedCheck.length - 1];
            var aux: any = [];
            var add: boolean = true;
            for (var i: any = 0; i < itemsToSettle.length; i++) {
                if (itemsToSettle[i].identity !== lastOne.identity) {
                    aux.push(itemsToSettle[i]);
                } else {
                    add = false;
                }
            }

            if (add === true) {
                setItemsToSettle((prevState: any) => [...prevState, lastOne]);
            } else {
                setItemsToSettle(aux);
            }

        } else if (selectedCheck.length === 1) {
            setItemsToSettle(selectedCheck);
        }

    }, [itemsToSettle, /*  */selectedCheck])

    function confereChecked(cell: any) {
        for (var i: any = 0; i < itemsToSettle.length; i++) {
            if (itemsToSettle[i].identity === cell) {
                return true;
            }
        }
        return false;
    }

    function addCheckButton(cell: any, row: any) {
        return (
            <>
                <div className="center-table">
                    <Controller
                        control={control}
                        name={'' + cell}
                        render={({ field }: any) => (
                            <Form.Check
                                {...field}
                                fullWidth
                                variant="standard"
                                margin="normal"
                                type="checkbox"
                                defaultChecked={confereChecked(cell)}
                                value={cell}
                                onClick={() => { handleClick(cell) }}
                            />
                        )}
                    />
                </div>
            </>
        )
    }

    function textReduce(cell: any, row: any) {
        return (
            <>
                <OverlayTrigger
                    overlay={
                        <Tooltip id="tooltip">{cell}</Tooltip>
                    }>
                    <span className="text-wrap-custom">
                        {cell}
                    </span>
                </OverlayTrigger>
            </>
        )
    }


    const columns = [
        {
            dataField: 'identity',
            text: ``,
            formatter: addCheckButton
        },
        {
            dataField: "vencimento",
            text: "Vencimento",
            formatter: convertDate,
        },
        {
            dataField: "dataOperacao",
            text: "Data Operação",
            formatter: convertDate,
        },
        {
            dataField: "tour",
            text: "Tour",
            formatter: textReduce,
        },
        {
            dataField: "cliente",
            text: "Cliente"
        },
        {
            dataField: "valor",
            text: "Valor",
            formatter: convertValue,
        },
        {
            dataField: "statusPagamento",
            text: "Status Pagamento",
            formatter: addStatus,
        },
    ];

    /*--------------------------------------------------*/

    /* In case of empty table*/
    //const notFoundColumns = [{ dataField: "contas", text: "Contas" }];

    //const notFoundProducts = [{ contas: "Nenhuma conta encontrada" }];
    /* [END] In case of empty table*/

    const rowClasses = (row: any, rowIndex: any) => {
        /* console.log(row, rowIndex) */
        if (row.statusPagamento === "Conciliado") {
            return 'border-analise';
        } else if (row.statusPagamento === "Pago" || row.statusPagamento === "Recebido") {
            return 'border-ativo';
        } else {
            return 'border-inativo';
        }
    }

    useEffect(() => {
        /* let beginDate: any = []
        let endDate: any = []

        if (actualDate.length === 1) {
            beginDate = actualDate[0].split('/')
        } else if (actualDate.length === 2) {
            beginDate = actualDate[0].split('/')
            endDate = actualDate[1].split('/')
        }

        let today: any = new Date(); */

        async function billTopPay() {
            const token = localStorage.getItem('GroupId') || '{}';
            const config = {
                headers: { 'Authorization': `Bearer ${token}`, 'Content-Type': 'application/json' },
            };
            try {
                const { data } = await api.post('/BillsReceivable/GetReport',
                    {
                        "page": expirationPageCount,
                        "rowsPerPage": expirationRowsPerPage,
                        "item": parameters.item,
                        "idDecolarFenix": parameters.idDecolarFenix,
                        "tokenNsu": parameters.tokenNsu,
                        "agenteRef": parameters.agenteRef,
                        "statusReserva": parameters.statusReservastatusReserva,
                        "statusPagamento": parameters.statusPagamento,
                        "formaPagamento": parameters.formaPagamento,
                        "adquirente": parameters.adquirente,
                        "fornecedor": parameters.fornecedor,
                        "agenteIndividual": parameters.agenteIndividual,
                        "agente": parameters.agente,
                        "cliente": parameters.cliente,
                        "tipoData": parameters.tipoData,
                        "dataInicial": parameters.dataInicial,
                        "dataFinal": parameters.dataFinal
                    }, config
                );

                if (data.status !== 400) {
                    setExpirationBills(data.data.rows);
                }
            } catch (error: any) {
                if (error.response.status === 401) {
                    window.location.href = window.location.origin + '/';
                }
            }
        }
        billTopPay();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [expirationPageCount, expirationRowsPerPage])

    return (
        <>
            <div className="table-default bills-pay-table expiration-receive mt-0">
                <div className="table-container p-0">
                    <BootstrapTable
                        bootstrap4
                        keyField="id"
                        data={expirationBills}
                        columns={columns}
                        rowClasses={rowClasses}
                        striped={true}
                    />
                    <Pagination
                        totalRows={totalRows}
                        pageCount={expirationPageCount}
                        setPageCount={setExpirationPageCount}
                        rowsPerPage={expirationRowsPerPage}
                        setRowsPerPage={setExpirationRowsPerPage}
                        selectNumberRows="yes"
                    />
                    <ModalConfirm
                        handleSubmit={handleSubmit}
                        onSubmit={onSubmit}
                        expiration="yes"
                        confirm={responseText}
                        modalContent={modalResponse}
                    />
                </div>
            </div>
        </>
    )
}

export default ExpirationReceive;