import React, { useEffect, useState } from 'react';
import api from '../../services/api';

import DesktopDefault from '../../templates/DesktopDefault';

import './ExportERPSuppliers.scss'

//COMPONENTS
import Breadcrumb from '../../components/Breadcrumb/Breadcrumb';
import FilterExportERPSuppliers from './components/FilterExportERPSuppliers/FilterExportERPSuppliers';
import TableExportERPSuppliers from './components/TableBills/TableExportERPSuppliers';

function ExportERPSales() {
    const [actualName, setActualName] = useState<string>('');
    const [actualClientType, setActualClientType] = useState<number>(0);
    const [actualStatusErp, setActualStatusErp] = useState<number>(1);
    const [actualStatus, setActualStatus] = useState<number>(1);

    const [listERPSuppliers, setListERPSuppliers] = useState<any>(null);
    const [parameters, setParameters] = useState<any>(null);
    const [loading, setLoading] = useState<any>(false);

    /* Pagination */
    const [totalRows, setTotalRows] = useState<any>();
    const [pageCount, setPageCount] = useState<any>(1);
    const [rowsPerPage, setRowsPerPage] = useState<any>(10);
    /* END - Pagination */

    async function refreshData() {
        setLoading(true);

        let body: any = document.querySelector("body");
        if (body.className !== "modal-open") {
            setListERPSuppliers(null);
        }

        const token = localStorage.getItem('GroupId') || '{}';
        const config = {
            headers: { 'Authorization': `Bearer ${token}`, 'Content-Type': 'application/json' },
        };

        async function listBills() {
            try {
                const { data } = await api.post('Manifest/GetSuppliersRMByFilterAsync',
                    {
                        "page": pageCount,
                        "rowsPerPage": parseInt(rowsPerPage),
                        "name": "",
                        "state": "",
                        "status": 0,
                        "type": 0,
                        "country": 0,
                        "beginDate": "",
                        "endDate": "",
                        "orderByField": "",
                        "sendRm": 0,
                        "statusErp": 0
                    }, config
                );
                setLoading(false);
                if (data.status !== 400) {
                    setParameters({
                        "page": pageCount,
                        "rowsPerPage": parseInt(rowsPerPage),
                        "name": actualName,
                        "state": "",
                        "status": actualStatus,
                        "type": actualClientType,
                        "country": 0,
                        "beginDate": "",
                        "endDate": "",
                        "orderByField": "",
                        "sendRm": 0,
                        "statusErp": actualStatusErp
                    });
                    setListERPSuppliers(data.data.result.rows);
                    setTotalRows(data.data.result.rowsCount);
                }
            } catch (error: any) {
                setListERPSuppliers(null);
                setLoading(false);
                if (error?.response?.status === 401) {
                    window.location.href = window.location.origin + '/';
                }
            }
        }
        listBills();
    }

    useEffect(() => {
        //if(searchOk === true) 
        refreshData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [pageCount, rowsPerPage]);

    async function buscar(name: string, clientType: any, statusErp: any, status: any) {
        setActualName(name);
        setActualClientType(clientType);
        setActualStatusErp(statusErp);
        setActualStatus(status);
        setPageCount(1)

        setLoading(true);
        let body: any = document.querySelector("body");
        if (body.className !== "modal-open") {
            setListERPSuppliers(null);
        }

        const token = localStorage.getItem('GroupId') || '{}';
        const config = {
            headers: { 'Authorization': `Bearer ${token}` },
        };

        try {
            const res = await api.post('Manifest/GetSuppliersRMByFilterAsync',
                {
                    "page": 1,
                    "rowsPerPage": rowsPerPage,
                    "name": name,
                    "state": "",
                    "status": parseInt(status),
                    "type": parseInt(clientType),
                    "country": 0,
                    "beginDate": "",
                    "endDate": "",
                    "orderByField": "",
                    "sendRm": 0,
                    "statusErp": parseInt(statusErp)
                }, config
            );
            setLoading(false);
            if (res.status !== 400) {
                setParameters({
                    "page": 0,
                    "rowsPerPage": 0,
                    "name": name,
                    "state": "",
                    "status": status,
                    "type": clientType,
                    "country": 0,
                    "beginDate": "",
                    "endDate": "",
                    "orderByField": "",
                    "sendRm": 0,
                    "statusErp": statusErp
                });
                setListERPSuppliers(res.data.data.result.rows);
                setTotalRows(res.data.data.result.rowsCount);
            }
        } catch (error: any) {
            setListERPSuppliers(null);
            setLoading(false);
            if (error?.response?.status === 401) {
                window.location.href = window.location.origin + '/';
            }
        }
    };

    async function exportListERM(loc: string) {
        const token = localStorage.getItem('GroupId') || '{}';
        const config: any = {
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json',
                "Accept": "*/*"
                //"Access-Control-Allow-Origin": "*"
            },
            responseType: 'blob'
        };
        try {
            const exp = await api.get(`Manifest/CreateSendBillsToReceiveRmAsync?Loc=${loc}`, config);
            if (exp.status !== 400) {
                console.log(exp)
            }
        } catch (error: any) {
            if (error?.response?.status === 401) {
                window.location.href = window.location.origin + '/';
            }
        }

    };

    return (
        <>
            <DesktopDefault>
                <div className="container-fluid content-dashboard export-erp-suppliers">
                    <Breadcrumb title={'Financeiro / Fornecedores ERP'} />
                    <div className="suppliers mt-4 d-flex justify-content-between align-items-center">
                        <h2 className="title" style={{ color: "#707070" }}>Fornecedores ERP</h2>
                    </div>
                    <FilterExportERPSuppliers
                        buscar={buscar}
                    />
                    <TableExportERPSuppliers
                        listERPSuppliers={listERPSuppliers}
                        totalRows={totalRows}
                        pageCount={pageCount}
                        setPageCount={setPageCount}
                        rowsPerPage={rowsPerPage}
                        setRowsPerPage={setRowsPerPage}
                        exportListERM={exportListERM}
                        loading={loading}
                        parameters={parameters}
                        refreshData={refreshData}
                    />
                </div>
            </DesktopDefault>
        </>
    );

}

export default ExportERPSales;