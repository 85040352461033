import React, { useState, useEffect, Key } from "react";
import api from "../../../../services/api";

import { Container, Modal, Row } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import FilterTour from "../../../../components/Filter/FilterTour/FilterTour";

import "./TourList.scss";
import AccordionTour from "../Accordions/AccordionTour";
import AccordionTicket from "../Accordions/AccordionTicket";

import InfoProducts from "../InfoProducts/InfoProducts";
import InfoProductsTicket from "../InfoProducts/InfoProductsTicket";
import i18next from "i18next";
import { useTranslation } from "react-i18next";
import Pagination from "../../../../components/Pagination/Pagination";
import AccordionCombo from "../Accordions/AccordionCombo";
import InfoProductsCombo from "../InfoProducts/InfoProductsCombo";

export interface tourList {
  search: any;
  setIsLoading: any;
  eventDate: any;
  toggleAction: boolean;
}


const TourList = ({ search, setIsLoading, eventDate, toggleAction }: any) => {
  const [tourList, setTourList] = useState([]);
  const [tourDetails, setTourDetails] = useState<any>(null);
  const [modalShow, setModalShow] = React.useState(false);

  const [categories, setCategoories] = useState<any>(null);
  const [, setNoContent] = useState<any>(false);
  const [pagination, setPagination] = useState<any>(1);
  const [actualProductType, setActualProductType] = useState<any>(0);
  const [actualCategories, setActualCategories] = useState<any>([]);
  const [tourListSize, setTourListSize] = useState<any>(null);
  const [value, setValue] = useState<any>({ min: 0, max: 1000 });
  const [preLoad, setPreLoad] = useState<any>(false);
  const [lang, setLang] = useState<string>("BR");

  const { t } = useTranslation();

  const [, setSearchObject] = useState<any>(
    {
      "page": 0,
      "rowsPerPage": 0,
      "term": search,
      "channel": localStorage.getItem('idCanal') || 1,
      "lang": "BR",
      "ProductType": actualProductType,
      "categories": actualCategories,
      "stars": [
      ],
      "priceStart": 0,
      "priceEnd": 0
    }
  );

  useEffect(() => {
    if (i18next.language === "pt-BR" || i18next.language === "pt") {
      setLang("BR")
    } else if (i18next.language === "en") {
      setLang("EN")
    } else {
      setLang("ES")
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [i18next.language]);


  const getTourList = async (search: any, pag: any) => {
    setIsLoading(true);
    setPreLoad(true);

    const { data } = await api.post(`${process.env.REACT_APP_SERVER_URL_API}/Products/FetchByChannel`,
      {
        "page": pag,
        "rowsPerPage": 5,
        "term": search,
        "channel": localStorage.getItem('idCanal') || 1,
        "lang": lang,
        "ProductType": actualProductType,
        "categories": [
        ],
        "stars": [
        ],
        "priceStart": 0,
        "priceEnd": 0,
        "eventDate": eventDate.split('/').reverse().join('-') 
      });

    setTourList(data);
    if (data?.statusCode !== 400) {
      setIsLoading(false);
      setPreLoad(false);
      if (data.data !== null) {
        setTourList(data.data.rows);
        setTourListSize(data.data.rowsCount);
        setCategoories(data.data.categories);
        setValue({ min: Math.floor(data.data.minPrice), max: Math.ceil(data.data.maxPrice) });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  };

  useEffect(() => {
    if (search != null) {
      getTourList(search, pagination);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [search, pagination, lang, toggleAction]);

  const TourDetailRender = async (productCode: any) => {
    setModalShow(true);
    try {
      const data = await api.post(
        `/Products/FetchSingleTourAsync`,
        {
          "productCode": productCode,
          "lang": lang,
          "idCanal": localStorage.getItem('idCanal') || 1
        }
      );
      if (data.status !== 400) {
        setTourDetails(data.data.data);
        // setTourOptions(data.data.data.modalities);
        //setValue({ min: 0, max: Math.ceil(data.data.data.maxPrice) });
      }
    } catch (error) { }
  }

  const TicketDetailRender = async (productCode: any) => {
    setModalShow(true);
    try {
      const data = await api.post(
        `/Products/FetchSingleTicketAsync`,
        {
          productCode: productCode,
          lang: lang,
          idCanal: localStorage.getItem('idCanal') || 1
        }
      );
      if (data.status !== 400) {
        setTourDetails(data.data.data);
        // setTourOptions(data.data.data.modalities);
        //setValue({ min: 0, max: Math.ceil(data.data.data.maxPrice) });
      }
    } catch (error) { }
  }

  const ComboDetailRender = async (productCode: any) => {
    setModalShow(true);
    try {
      const data = await api.post(
        `/Products/FetchSingleComboAsync`,
        {
          productCode: productCode,
          lang: lang,
          idCanal: localStorage.getItem('idCanal') || 1
        }
      );
      if (data.status !== 400) {
        setTourDetails(data.data.data);
      }
    } catch (error) { }
  }

  // const paginateBack = () => {
  //   setPagination(pagination - 1);
  //   window.scroll(0, 500);
  // }

  // const paginateFoward = () => {
  //   setPagination(pagination + 1);
  //   window.scroll(0, 500);
  // }

  //Estrelas dos comentários
  const SetStar = (index: any) => {
    let rating: any = tourList[index.index]['stars'];
    let arrayStars: any = [];


    if (rating > 0 && rating <= 0.75) {
      rating = 0.5;
    } else if (rating > 0.76 && rating <= 1.25) {
      rating = 1;
    } else if (rating > 1.26 && rating <= 1.75) {
      rating = 1.5;
    } else if (rating > 1.76 && rating <= 2.25) {
      rating = 2;
    } else if (rating > 2.26 && rating <= 2.75) {
      rating = 2.5;
    } else if (rating > 2.76 && rating <= 3.25) {
      rating = 3;
    } else if (rating > 3.26 && rating <= 3.75) {
      rating = 3.5;
    } else if (rating > 3.76 && rating <= 4.25) {
      rating = 4;
    } else if (rating > 4.26 && rating <= 4.75) {
      rating = 4.5;
    } else if (rating > 4.76 && rating <= 5) {
      rating = 5;
    }

    for (var i: any = 0; i < 5; i = i + 0.5) {
      if (i < rating) {
        arrayStars.push(1);
      } else {
        arrayStars.push(0);
      }
    }

    let aux: any = [];

    for (var j: any = 0; j < 10; j = j + 2) {
      if (arrayStars[j] === 1 && arrayStars[j + 1] === 1) {
        aux.push(1);
      } else if (arrayStars[j] === 1 && arrayStars[j + 1] === 0) {
        aux.push(0.5);
      } else if (arrayStars[j] === 0 && arrayStars[j + 1] === 0) {
        aux.push(0);
      }
    }

    arrayStars = aux;

    if (rating !== null) {
      return (
        <>
          {arrayStars.length > 0
            ? arrayStars.map((array: any, index: Key) => {
              return (
                array === 1 || array === 0 ?
                  <FontAwesomeIcon
                    key={index}
                    icon={["fas", "star"]}
                    size="2x"
                    style={{
                      margin: "0px 5px 5px 0px",
                      color: array === 1 ? "#ffC60A" : "#707070",
                      fontSize: "1.15rem"
                    }}
                  />
                  :
                  <FontAwesomeIcon
                    key={index}
                    icon={["fad", "star-half"]}
                    size="1x"
                    style={{
                      margin: "0px 5px 5px 0px",
                      color: array === 0.5 ? "#ffC60A" : "#707070",
                      fontSize: "1.15rem"
                    }}
                  />
              );
            })
            : ""}
        </>
      );
    } else {
      return (
        <>
        </>
      )
    }
  };

  return (
    <Container className="" fluid>
      <Container className="my-3 py-0 px-2" fluid>
        <Row>
          <div className="col-12 col-md-2 d-none d-md-block">
            <div className="card card-filter mb-4 w-100">
              <FilterTour
                search={search}
                setTourList={setTourList}
                value={value}
                setNoContent={setNoContent}
                categories={categories}
                setTourListSize={setTourListSize}
                setSearchObject={setSearchObject}
                setPagination={setPagination}
                setActualProductType={setActualProductType}
                setActualCategories={setActualCategories}
              />
            </div>
          </div>
          <div className="col-12 col-md-10 side-list px-1 px-md-4">
            {preLoad === false
              ?
              <>
                {tourList?.length > 0
                  ? tourList.map((tour: any, index: Key) => (
                    <>
                      <div key={index} className="card card-filter-list w-100 mb-4">
                        <Row className="mx-0 px-0">
                          <div className="col-12 col-md-3 px-0">
                            <div style={{ height: "100%" }} className="card-img card-img-custom p-3">
                              <div
                                className="bg-tour-list"
                                style={{
                                  backgroundImage: `url('${tour.imagesBaseUrl + tour.productImg}')`,
                                  minHeight: '231px'

                                }}
                              >
                                <span className="badge rounded position-absolute badge-primary py-2 px-5 text-uppercase" style={{ display: "flex", alignItems: "center", margin: "5px" }}>
                                  {tour.typeProduct/*  === "TOUR" ? "Tours" : "Tickets" */}
                                </span>
                              </div>
                            </div>
                          </div>
                          <div className="col-12 col-md-7 text-custom px-3 px-md-0 py-3" style={{ display: "flex", flexDirection: "column", justifyContent: "space-between" }}>
                            <div>
                              <h5 className="tour-name mb-3">{tour.productName}</h5>
                              {
                                tour.typeProduct !== "COMBO" &&
                                <>
                                  <div className="d-flex flex-column flex-md-row">
                                    <div>
                                      <p className="small-custom text-muted">
                                        <strong>{t("sales.tourList.supplier")}</strong><br />
                                        <span> {tour.supplierFantasyName}</span>
                                      </p>
                                    </div>
                                    <span className="d-none d-md-block"> | </span>
                                    <div className="px-0 px-md-4">
                                      <p className="small-custom text-muted">
                                        <strong>{t("sales.tourList.service")}</strong><br />
                                        <span> {tour.type}</span>
                                      </p>
                                    </div>
                                    <span className="d-none d-md-block"> | </span>
                                    <div>
                                      <p className="small-custom text-muted">
                                        <strong>{t("sales.tourList.capacity")}</strong><br />
                                        <span> {tour.maxNumPeople} {t("sales.tourList.people")}</span>
                                      </p>
                                    </div>
                                  </div>
                                  <div className="stars-tour-list d-none d-md-block">
                                    <SetStar index={index} />
                                  </div>
                                  <div className="stars-tour-list"></div>
                                </>
                              }
                              <div
                                className="text-tour-info"
                                dangerouslySetInnerHTML={{ __html: tour.productInfo }}
                              ></div>
                            </div>
                            <div className="d-flex justify-content-start btn-details d-none d-md-flex" >
                              <div className="d-flex justify-content-end">
                                {tour.typeProduct === "TOUR" ?
                                  <button className="btn" type="button" onClick={() => TourDetailRender(tour.productCode)} >{t("sales.tourList.productDetails")}</button>
                                  : tour.typeProduct === "TICKET" ?
                                    <button className="btn" type="button" onClick={() => TicketDetailRender(tour.productCode)} >{t("sales.tourList.ticketDetails")}</button>
                                    : tour.typeProduct === "COMBO" ?
                                      <button className="btn" type="button" onClick={() => ComboDetailRender(tour.productCode)}>Detalhes do Combo</button>
                                      : <></>
                                }
                              </div>
                            </div>
                          </div>
                          <div className="col-12 col-md-2 text-end pb-3 pb-md-0 pt-3 px-3 px-md-4 d-flex d-md-block justify-content-between align-items-center">
                            <div className="btn-details d-block d-md-none" >
                              <div>
                                <div className="stars-tour-list d-flex d-md-none justify-content-start">
                                  <SetStar index={index} />
                                </div>

                                <div className="d-flex justify-content-end">
                                  {tour.typeProduct === "TOUR" ?
                                    <button className="btn" type="button" onClick={() => TourDetailRender(tour.productCode)} >{t("sales.tourList.productDetails")}</button>
                                    : tour.typeProduct === "TICKET" ?
                                      <button className="btn" type="button" onClick={() => TicketDetailRender(tour.productCode)} >{t("sales.tourList.ticketDetails")}</button>
                                      : <></>
                                  }
                                  asd
                                </div>
                              </div>
                            </div>
                            {
                              tour.typeProduct !== "COMBO" &&
                              <div>
                                <span className="small text-muted w-50">
                                  {t("sales.tourList.from")}{tour.percDesc > 0 ? <span> <strong style={{ textDecoration: 'line-through' }}>R$ {tour.price.toFixed(2).replace(".", ",")}</strong> {t("sales.tourList.for")}</span> : <>:</>}
                                </span>
                                <div className="price">
                                  <h5>
                                    {"R$ " +
                                      (tour.price - ((tour.price * tour.percDesc) / 100)).toFixed(2).replace(".", ",")}
                                  </h5>
                                </div>
                              </div>
                            }
                          </div>
                          <div className="col-12 col-md-12 px-0">
                            {
                              tour.typeProduct === "TOUR"
                                ?
                                <AccordionTour
                                  productCode={tour.productCode}
                                />
                                : tour.typeProduct === "TICKET" ?
                                  <AccordionTicket
                                    productCode={tour.productCode}
                                  />
                                  :
                                  <AccordionCombo
                                    productCode={tour.productCode}
                                  />
                            }
                          </div>
                        </Row>
                      </div>
                    </>
                  ))
                  :
                  <div className="d-flex justify-content-center mt-4 mb-5">
                    <p className="text-primary title-filter">
                      <strong>
                        Nenhum produto encontrado
                      </strong>
                    </p>
                  </div>
                }
                {tourList?.length > 0 &&
                  <Pagination
                    totalRows={tourListSize}
                    pageCount={pagination}
                    setPageCount={setPagination}
                    rowsPerPage={5}
                    setRowsPerPage={() => { }}
                    selectNumberRows="no"
                  />
                }
              </>
              :
              <>
                {[1, 2, 3, 4, 5].map((tour: any, index: Key) => (
                  <div key={index} className="col-12 col-md-10 side-list w-100">
                    <div className="card card-filter-list w-100 mb-4" style={{ height: "342px" }}>

                      <Row>
                        <div className="col-5 col-md-3 img-mobile"><div className="card-img card-img-custom my-3 ml-3 animated-background" style={{ maxWidth: "480px", height: "249px" }}></div></div>
                        <div className="col-7 col-md-7 text-custom pt-3">
                          <h5 className="tour-name animated-background" style={{ height: "24px" }}> </h5>
                          <div className="dates d-flex">
                            <div className="animated-background" style={{ height: "20px", width: "35px", marginRight: "5px" }}></div>
                            <div className="animated-background" style={{ height: "20px", width: "35px", marginRight: "5px" }}></div>
                            <div className="animated-background" style={{ height: "20px", width: "35px", marginRight: "5px" }}></div>
                            <div className="animated-background" style={{ height: "20px", width: "35px", marginRight: "5px" }}></div>
                            <div className="animated-background" style={{ height: "20px", width: "35px", marginRight: "5px" }}></div>
                            <div className="animated-background" style={{ height: "20px", width: "35px", marginRight: "5px" }}></div>
                            <div className="animated-background" style={{ height: "20px", width: "35px", marginRight: "5px" }}></div>
                          </div>
                          <p className="small text-muted animated-background" style={{ height: "20px", marginBottom: "0.5rem" }}></p>
                          <p className="small text-muted animated-background" style={{ height: "20px", marginBottom: "0.5rem" }}></p>
                          <p className="small text-muted animated-background" style={{ height: "20px", marginBottom: "0.5rem" }}></p>
                          <div className="animated-background" style={{ height: "42px" }}></div>
                        </div>
                        <div className="col-2 col-md-2 m-auto text-center animated-background" style={{ height: "144px", maxWidth: "190px" }}>
                          <span className="small text-muted w-50"></span>
                          <div className="price"></div>
                        </div>
                        <div className="col-12 col-md-"></div>
                      </Row>
                    </div>
                  </div>
                ))}
              </>
            }
          </div>
        </Row>
      </Container>
      <Modal
        className="modal-custom modal-products-info"
        show={modalShow}
        onHide={() => setModalShow(false)}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
      >
        {
          tourDetails?.typeProduct === "TOUR"
            ?
            <InfoProducts
              tourDetails={tourDetails}
              setModalShow={setModalShow}
            />
            : tourDetails?.typeProduct === "TICKET" ?
              <InfoProductsTicket
                tourDetails={tourDetails}
                setModalShow={setModalShow}
              />
              : tourDetails?.typeProduct === "COMBO" ?
                <InfoProductsCombo
                  tourDetails={tourDetails}
                  setModalShow={setModalShow}
                />
                : <></>
        }
      </Modal>
    </Container>
  );
}

export default TourList;
