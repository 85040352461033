import React from "react";
import { Container } from "react-bootstrap";

import HeaderNew from "../components/Header/HeaderNew";
import Menu from "../components/Menu/Menu";
import Warning from "../components/Warning/Warning";

import './DesktopDefault.scss';


function DesktopDefault(props: any) {
    document.querySelector('body')?.classList.add('template-sig');

    return (
        <div className="d-flex h-100">
            <Container className="h-100 w-100 m-0 p-0" fluid>
                <div className="bg-top-header">
                    {process.env.REACT_APP_PRODUCTION === 'FALSE' ?
                        <Warning />
                        :
                        <></>
                    }
                    <HeaderNew />
                </div>
                <div /* style={{ marginTop: "5.5rem"}} */>
                    <Menu />
                    <div className="contentSIG">
                        {props.children}
                    </div>
                </div>
            </Container>
        </div>
    )
}

export default DesktopDefault;

