import React from 'react';
import DatePicker from "react-multi-date-picker";
import { useTranslation } from "react-i18next";
import configData from "../../config/config.json";

import './Calendar.scss';

const CustomMultipleInput = ({ openCalendar, value, date, setValue, errors, startTimeLocal }: any) => {
  value = date

  /* function convertDate(cell: any) {
    if (cell !== null && cell !== undefined) {
      var aux: any = cell.split("T")[0].split("-");
      var date: any = `${aux[2]}/${aux[1]}/${aux[0]}`;
      return date;
    }
  } */

  setValue(value);


  return (
    <input
      className={errors?.dataPgto ? "form-control bg-white frm-sm w-100 rounded endpoint-error" : "form-control bg-white frm-sm w-100 rounded"}
      onFocus={() => { openCalendar(); }}
      value={value}
      readOnly
      id={'singleCalendarId'}
      autoComplete='off'
    //placeholder={startTimeLocal ? convertDate(startTimeLocal) : "Quando?"}
    />
  )
}

function handleChange(e: any, setValue: any) {
  setValue([e.day + "/" + e.month + "/" + e.year])

}

const SingleCalendarModalControl = ({ date, setValue = () => { }, field, errors = undefined, startTimeLocal = undefined, disableFutureDates = false, disablePastDates = false }: any) => {
  const { t } = useTranslation();
  //const [aux, setAux] = useState<any>(date);  
  /* useEffect(()=>{
    setValue('date',aux);
  },[aux]) */
  //console.log(date)

  const maxDate: any = new Date();

  return (
    <DatePicker
      {...field}
      //svalue={new Date(defaultDate)}
      //onChange={(e: any) => {setAux(e.year + "-" + e.month + "-" + e.day) }}
      weekDays={t('calendar.weekDays').split(',')}
      months={t('calendar.months').split(',')}
      format={configData.DATE.PT}
      calendarPosition={`top`}
      maxDate={disableFutureDates === true ? maxDate : ''}
      minDate={disablePastDates === true ? maxDate : ''}
      onChange={(e: any) => { handleChange(e, setValue) }}
      render={<CustomMultipleInput date={date} setValue={setValue} errors={errors} startTimeLocal={startTimeLocal} />}
    />
  )
}

export default SingleCalendarModalControl;