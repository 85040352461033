import React, { useEffect, useState } from 'react';
import Select from 'react-select';
//import api from '../../../services/api';

import { useTranslation } from "react-i18next";

import Form from 'react-bootstrap/Form';

export interface propPlus {
  propsField: any,
  propsLabel: any,
  propsErrors: any,
  value: any,
  setValue: any
};

const GetPaymentStatusFilter: React.FC<propPlus> = ({
  propsField, propsLabel, propsErrors, value, setValue 
}: propPlus) => {
  const { t } = useTranslation();

  const [info, setInfo] = useState<any>(null);
  const [isMenuOpen, setIsMenuOpen] = useState<boolean>(false);

  //const [value, setValue] = useState<any>(propsField.value);

  const onMenuOpen = () => {
    if (isMenuOpen === false) {
      setIsMenuOpen(true);
    } else {
      setIsMenuOpen(false);
    }
  }

  useEffect(() => {
    // const token = localStorage.getItem('GroupId') || '{}';
    // const config = {
    //   headers: { 'Authorization': `Bearer ${token}` },
    // };
    async function listAccountingAccounts() {
      try {
        // const { data } = await api.get(`/BillsReceivable/GetAccounts`, config);
        // if (data.status !== 400) {
          const itens = [
            {id:1,descricao:'Pendente'},
            {id:2,descricao:'Conciliado'},
            {id:3,descricao:'Faturado'},
            //{id:5,descricao:'Agendado'},
            //{id:6,descricao:'Autorizado'},
            {id:4,descricao:'Recebido/Pago'},
          ];
          setInfo(itens)

      } catch (error: any) {
        if (error.response.status === 401) {
          window.location.href = window.location.origin + '/';
        }
      }
    }
    listAccountingAccounts();
  }, []);

  function handleInputChange(a: any) {

    //info.filter((a:any) => propsField.value.includes(a.id));

    setValue(a.map((op:any) => op.id));
  }

  if (info !== null) {
    return (
      <>
        <div className={propsErrors[propsField.name] !== undefined ? "endpoint-error" : "endpoint"}>
          <Select
            aria-labelledby="aria-label"
            inputId="aria-example-input"
            name="aria-live-color"
            onMenuOpen={onMenuOpen}
            onMenuClose={onMenuOpen}
            {...propsField}
            aria-invalid={propsErrors[propsField.name] ? "true" : ""}
            label={propsLabel}
            as="select"
            variant="standard"
            margin="normal"
            placeholder={t("accordionFinancial.select.select")}
            options={Array.from(info)}
            getOptionValue={(option: any) => `${option.id}`}
            getOptionLabel={(option: any) => `${option.descricao}`}
            value={info.filter((a:any) => value.includes(a.id))}
            onChange={handleInputChange}
            isMulti={true}
          />
        </div>
      </>
    );
  } else {
    return (
      <>
        <Form.Select>
          <option value='' disabled></option>
        </Form.Select>
      </>
    )

  }
}

export default GetPaymentStatusFilter;