import React, { useState } from "react";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Dropdown from "react-bootstrap/Dropdown";

import "react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css";
import "react-bootstrap-table-next/dist/react-bootstrap-table2.css";
import BootstrapTable from "react-bootstrap-table-next";

//STYLES
import "./TableSessionClosure.scss";
import Pagination from "../../../../components/Pagination/Pagination";
import api from "../../../../services/api";
import ModalQuestion from "../../../../components/Modal/ModalQuestion/ModalQuestion";
import { Modal, OverlayTrigger, Tooltip } from "react-bootstrap";
import ModalErrorsList from "../../../../components/Modal/ModalErrorsList/ModalErrorsList";
import FormSessionObservationEdit from "../Forms/FormSessionObservationEdit/FormSessionObservationEdit";

export interface propTable {
  sessions: any;
  totalRows: any;
  pageCount: any;
  setPageCount: any;
  rowsPerPage: any;
  setRowsPerPage: any;
  exportReport: any;
  printReport: any;
  loading: any;
  refreshData: any;
  canExportErp?: any;
  sessionClosed?: any;
}

const TableSessionClosure: React.FC<propTable> = ({
  sessions, totalRows, pageCount, setPageCount, rowsPerPage, setRowsPerPage, exportReport, printReport, loading
  , refreshData
  , canExportErp = false
  , sessionClosed = false
}: propTable) => {
  //console.log(props.suppliers[0].dataCadastro.split('T')[0])

  const [exportERPModalShow, setExportERPModalShow] = useState<any>(false);
  const [conciliarSessionModalShow, setConciliarSessionModalShow] = useState<any>(false);
  const [updateSessionObservationShow, setUpdateSessionObservationShow] = useState<any>(false);
  const [selectedRow, setSelectedRow] = useState<any>(null);

  /* Success Modal (Inserir) */
  const [modalSuccess, setModalSuccess] = useState<any>(false);
  const [responseText, setResponseText] = useState<any>();
  const [modalContent, setModalContent] = useState<any>();
  const [log, setLog] = useState<any>();
  /* END - Success Modal (Inserir) */

  /* Errors Modal */
  const [errorsListShow, setErrorsListShow] = useState<any>(false);
  const [errorsList, setErrorsList] = useState<any>([]);
  //const [modalShow, setModalShow] = useState<any>(false);
  //const [modalMessage, setModalMessage] = useState<any>("Carregando");
  //const [modalLog, setModalLog] = useState<any>(null);
  /* END - Errors Modal */

  const handleExportClick = (inc: any) => {
    exportReport();
  }

  const handlePrintClick = (inc: any) => {
    printReport();
  }

  const handleRecebimentosClick = (session: any) => {
    //window.location.href = window.location.origin + '/session-closure/bills-to-receive?session=' + session;
    window.open(`/session-closure/bills-to-receive?session=${session}`, '_blank')
  }

  const handlePagamentosClick = (session: any) => {
    //window.location.href = window.location.origin + '/session-closure/bills-to-pay?session=' + session;
    window.open(`/session-closure/bills-to-pay?session=${session}`, '_blank')
  }

  const handleFaturamentoClick = (session: any) => {
    //window.location.href = window.location.origin + '/session-closure/billing?session=' + session;
    window.open(`/session-closure/billing?session=${session}`, '_blank')
  }

  function exportERP(row: any) {
      setExportERPModalShow(true);
      setSelectedRow(row);
  }

  async function exportERP1() {
      setExportERPModalShow(false);
      setModalContent("loading");
      setModalSuccess(true);

      const sessionId = selectedRow.id;
      const sessionType = (selectedRow.tipo === 'Tour') ? 1 : 2;
  
      let userData = JSON.parse(localStorage.getItem('c2tourAuth') || '{}');
      const user = userData.user;

      const uid = user.uId;
      const usuario = `${user.nome} ${user.lastName}`;

      const token = localStorage.getItem('GroupId') || '';

      const config = {
          headers: { 'Authorization': `Bearer ${token}` },
      };

      try {
          const res = await api.post('SessionClosure/ExportSessionToErpAsync',
              {
                  "sessionId": sessionId,
                  "sessionType": sessionType,
                  "uid": uid,
                  "usuario": usuario
              }, config
          );
          if (res.status !== 400) {
              const dados = res.data.data;

              //alert(dados.texto);
              setResponseText(dados.texto);
              setLog(dados.log);

              if(dados.log !== 1) {
                  //refreshData();
                  setModalContent("success");
              } else {
                  setModalContent("error");
              }
          }
      } catch (error: any) {
          setModalContent("error");
          setLog(1);
          //setError(true)
          if (error?.response?.status === 401) {
              window.location.href = window.location.origin + '/';
          }
      }
  }

  function alterarObservacao(row: any) {
    setUpdateSessionObservationShow(true);
    setSelectedRow(row);
  }

  function handleActionUpdateSessionObservationModal() {
    setSelectedRow(null);
    setUpdateSessionObservationShow(false);
    //refreshData();
  }


  function conciliarSession(row: any) {
    setConciliarSessionModalShow(true);
    setSelectedRow(row);
  }

  async function conciliarSession1() {
    setConciliarSessionModalShow(false);
    setModalContent("loading");
    setModalSuccess(true);

    const sessionId = selectedRow.id;
    const sessionType = (selectedRow.tipo === 'Tour') ? 1 : 2;

    let userData = JSON.parse(localStorage.getItem('c2tourAuth') || '{}');
    const user = userData.user;

    const uid = user.uId;
    const usuario = `${user.nome} ${user.lastName}`;

    const token = localStorage.getItem('GroupId') || '';

    const config = {
      headers: { 'Authorization': `Bearer ${token}` },
    };

    try {
      const res = await api.post('SessionClosure/ConciliateSessionAsync',
        {
          "sessionId": sessionId,
          "sessionType": sessionType,
          "uid": uid,
          "usuario": usuario
        }, config
      );
      if (res.status !== 400) {
        const dados = res.data.data;

        //alert(dados.texto);
        setResponseText(dados.texto);
        setLog(dados.log);

        if(dados.log !== 1) {
          //refreshData();
          setModalContent("success");
        } else {
          setModalContent("error");
        }
      }
    } catch (error: any) {
      setModalContent("error");
      setLog(1);
      //setError(true)
      if (error?.response?.status === 401) {
        window.location.href = window.location.origin + '/';
      }
    }
  }

  async function onErrorClick(sessionReloc: any) {
    //let userData = JSON.parse(localStorage.getItem('c2tourAuth') || '{}');
    //const user = userData.user;

    //const uid = user.uId;
    //const usuario = `${user.nome} ${user.lastName}`;

    const token = localStorage.getItem('GroupId') || '';

    const config = {
      headers: { 'Authorization': `Bearer ${token}` },
    };

    try {
      const res = await api.get(`SessionClosure/GetSendSessionClosureRmErrorsAsync/${sessionReloc}`, config);

      if (res.status !== 400) {
        const listOfErrors = res.data.data;

        setErrorsList(listOfErrors);
        //setErrorsList(["Reservas Decolar com Forma de pagamento \"Faturado\""]);
        setErrorsListShow(true);
        //setModalMessage([...listOfErrors, ""]);
        //setModalLog(1);
        //setModalShow(true);
      }
    } catch (error: any) {
      setModalContent("error");
      setLog(1);
      //setError(true)
      if (error?.response?.status === 401) {
        window.location.href = window.location.origin + '/';
      }
    }
  }

  function addActionButton(cell: any, row: any) {
    var session: any;
    if (row.tipo === 'Tour') {
      session = `S${cell}`;
    } else {
      session = `I${cell}`;
    }

    return (
      <>
        <div className="d-flex justify-content-center">
          {/* <button className="btn p-0 pb-1" onClick={(e: any) => handleRecebimentosClick(cell)}>
            <FontAwesomeIcon
              icon={["fal", "edit"]}
              size="1x"
              style={{ marginRight: "1px" }}
            />
          </button> */}
          <Dropdown drop="start">
            <Dropdown.Toggle variant="light ">
              <FontAwesomeIcon size="lg" icon={["fal", "ellipsis-h"]} />
            </Dropdown.Toggle>

            <Dropdown.Menu>
              <Dropdown.Item
                eventKey="1"
                onClick={(e: any) => handleRecebimentosClick(session)}
              >
                <FontAwesomeIcon
                  icon={['fal', 'receipt']}
                /* className="mr-2" */
                />
                Recebimentos
              </Dropdown.Item>
              <Dropdown.Item
                eventKey="2"
                onClick={(e: any) => handlePagamentosClick(session)}
              >
                <FontAwesomeIcon
                  icon={['fal', 'hand-holding-usd']}
                //className="mr-2"
                />
                Pagamentos
              </Dropdown.Item>
              <Dropdown.Item
                eventKey="3"
                onClick={(e: any) => handleFaturamentoClick(session)}
              >
                <FontAwesomeIcon
                  icon={['fal', 'file-invoice']}
                  //className="mr-2"
                />
                Faturamento
              </Dropdown.Item>
              {canExportErp === true ?
                (row.status === 'Conciliado' && row.statusErp !== 'Exportado' && (row.statusErp === 'Erro' || row.quantidadeErrors === 0) ?
                  <>
                    <Dropdown.Item
                      eventKey="4"
                      onClick={(e: any) => exportERP(row)}
                    >
                      <FontAwesomeIcon
                        icon={['fal', 'share']}
                        //className="mr-2"
                      />
                      Exportar ERP
                    </Dropdown.Item>
                  </>
                  : 
                  <>
                    <Dropdown.Item
                      eventKey="4"
                      disabled={true}
                    >
                      <FontAwesomeIcon
                        icon={['fal', 'share']}
                        //className="mr-2"
                      />
                      Exportar ERP
                    </Dropdown.Item>
                  </>
                ) : <></> }
                <Dropdown.Item
                  eventKey="5"
                  onClick={(e: any) => alterarObservacao(row)}
                  className="text-danger removeRow"
                >
                  <FontAwesomeIcon
                    icon={['fal', 'comment']}
                    //className="mr-2"
                  />
                  Observação
                </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
        </div>
      </>
    );
  }// exportERP


  /*converte a data UTC para a data local do usuário*/
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  function convertUTCDateToLocalDate(date: any) {
    var newDate = new Date(date.getTime() + date.getTimezoneOffset() * 60 * 1000);

    var offset = date.getTimezoneOffset() / 60;
    var hours = date.getHours();

    newDate.setHours(hours - offset);

    return newDate.toLocaleString().split(' ')[0];
  }

  // function convertDate(cell: any, row: any) {
  //   var date = convertUTCDateToLocalDate(new Date(cell));
  //   /* console.log(date) */
  //   return (
  //     <>
  //       {date}
  //     </>
  //   );
  // }
  function convertDate(cell: any, row: any) {
    if (!cell) return '';
    //var date = convertUTCDateToLocalDate(new Date(cell));
    var aux: any = cell.split("T")[0].split("-");
    var date: any = `${aux[2]}/${aux[1]}/${aux[0]}`;
    return (
      <>
        {date}
      </>
    );
  }

  function convertValue(cell: any) {
    //console.log(Math.sign(cell))
    return (
      <>
        <span className={Math.sign(cell) === -1 ? "negative" : ""}>{cell.toFixed(2).replace(".", ",")}</span>
      </>
    )
  }

  function addStatusRecebimentos(cell: any, row: any) {
    return (
      <div
        className={"w-100 " +
          (cell === 'Conciliado'
            ? "bg-blue"
            : cell === 'Faturado' || cell === 'Recebido'
              ? "bg-ativo"
              : cell === 'Pendente'
                ? "bg-inativo"
                : "")
        }
      >
        {cell}
      </div>
    );
  }

  function addTotalNotas(cell: any, row: any) {
    return (
      <div
        className={row.totalNotas === row.totalMargem ? 
                    "invoice-blue"
                  : row.totalNotas === 0 ?
                    "invoice-red"
                  : row.totalNotas < row.totalMargem ?
                    "invoice-orange"
                  : ""}
      >
        {cell.toFixed(2).replace(".", ",")}
      </div>
    );
  }

  function addStatusPagamentos(cell: any, row: any) {
    return (
      <div
        className={"w-100 " +
          (cell === 'Conciliado'
            ? "bg-blue"
            : cell === 'Agendado' || cell === 'Autorizado' || cell === 'Pago'
              ? "bg-ativo"
              : cell === 'Pendente' 
                ? "bg-yellow"
                : cell === 'Cancelado'
                ? 'bg-inativo'
                : '')
        }
      >
        {cell}
      </div>
    );
  }

  function addStatus(cell: any, row: any) {
    return (cell === 'Conciliado' ?
      <div className="w-100 bg-blue">
        {cell}
      </div>
      : cell === 'Pendente' ? 
      <OverlayTrigger
        overlay={
          <Tooltip id="tooltip">Clique para conciliar a session</Tooltip>
        }>
        <button className="w-100 bg-inativo" onClick={() => conciliarSession(row)}>
          {cell}
        </button>
      </OverlayTrigger>


      
      :
      <div className="w-100">
        {cell}
      </div>
    );
  }

  function addStatusErp(cell: any, row: any) {
    return (
      <div
        className={"w-100 " +
          (cell === 'Exportado'
            ? "bg-blue"
            : cell === 'Pendente'
              ? "bg-yellow"
              : cell === 'Erro' 
              ? 'bg-inativo'
              : '')
        }
      >
        {cell}
      </div>
    );
  }

  function addErrors(cell: any, row: any) {

    return (
      <div className="d-flex justify-content-center">
        {
          cell === 0
            ?
            <>
              <div></div>
            </>
            :
            <>
              <button onClick={() => onErrorClick(row.sessionReloc)} type='button' className="btn btn-pend"><FontAwesomeIcon
                icon={["fal", "exclamation-triangle"]}
                size="1x"
                title={"Verificar pendências"}
              /></button>
            </>
        }

      </div>
      
    );
  }

  const columns = (sessionClosed) ? [
    {
      dataField: "dataOperacao",
      text: "Data Operação",

      formatter: convertDate,
    },
    { dataField: "tipo", text: "Tipo", },
    { dataField: "sessionReloc", text: "Session / Reloc", },
    { dataField: "descricao", text: "Tour / Descrição", },
    {
      dataField: "totalRecebimentos",
      text: "Recebimentos R$",

      formatter: convertValue,
    },
    {
      dataField: "totalPagamentos",
      text: "Pagamentos R$",

      formatter: convertValue,
    },
    {
      dataField: "totalMargem",
      text: "Margem R$",

      formatter: convertValue,
    },
    {
      dataField: "taxaMargem",
      text: "Margem %",

      formatter: convertValue,
    },
    {
      dataField: "totalNotas",
      text: "Notas Emitidas",

      formatter: addTotalNotas,
    },
    {
      dataField: "totalPrejuizo",
      text: "NP",

      formatter: convertValue,
    },
    {
      dataField: "diferenca",
      text: "Diferença",

      formatter: convertValue,
    },
    {
      dataField: "statusRecebimentos",
      text: "Recebimentos",

      formatter: addStatusRecebimentos,
    },
    {
      dataField: "statusPagamentos",
      text: "Pagamentos",

      formatter: addStatusPagamentos,
    },
    {
      dataField: "status",
      text: "Status da Session",

      formatter: addStatus,
    },
    {
      dataField: "statusErp",
      text: "Status ERP",

      formatter: addStatusErp,
    },
    {
      dataField: "quantidadeErrors",
      text: "Erros",
      sort: true,
      formatter: addErrors,
    },
    { dataField: "id", text: " ", formatter: addActionButton },
  ] 
  : [
    {
      dataField: "dataOperacao",
      text: "Data Operação",

      formatter: convertDate,
    },
    { dataField: "tipo", text: "Tipo", },
    { dataField: "sessionReloc", text: "Session / Reloc", },
    { dataField: "descricao", text: "Tour / Descrição", },
    {
      dataField: "totalRecebimentos",
      text: "Recebimentos R$",

      formatter: convertValue,
    },
    {
      dataField: "totalPagamentos",
      text: "Pagamentos R$",

      formatter: convertValue,
    },
    {
      dataField: "totalMargem",
      text: "Margem R$",

      formatter: convertValue,
    },
    {
      dataField: "taxaMargem",
      text: "Margem %",

      formatter: convertValue,
    },
    {
      dataField: "totalNotas",
      text: "Notas Emitidas",

      formatter: addTotalNotas,
    },
    {
      dataField: "totalPrejuizo",
      text: "NP",

      formatter: convertValue,
    },
    {
      dataField: "diferenca",
      text: "Diferença",

      formatter: convertValue,
    },
    {
      dataField: "statusRecebimentos",
      text: "Recebimentos",

      formatter: addStatusRecebimentos,
    },
    {
      dataField: "statusPagamentos",
      text: "Pagamentos",

      formatter: addStatusPagamentos,
    },
    {
      dataField: "status",
      text: "Status da Session",

      formatter: addStatus,
    },
    { dataField: "id", text: " ", formatter: addActionButton },
  ];

  /* loading table */

  function loadingFunc() {
    return (
      <>
        <div className="animated-background row-loading"></div>
      </>
    )
  }

  const loadingColumns = [{ dataField: "contasd", text: "Carregando sessions", formatter: loadingFunc }];

  const loadingProducts = [
    { contas: "" }, { contas: "" }, { contas: "" }, { contas: "" }, { contas: "" },
    { contas: "" }, { contas: "" }, { contas: "" }, { contas: "" }, { contas: "" }
  ];
  /* [END] loading table */

  /*--------------------------------------------------*/

  /* In case of empty table (nothing found) */
  const notFoundColumns = [{ dataField: "sessions", text: "Sessions" }];

  const notFoundProducts = [{ sessions: "Nenhuma session encontrada" }];
  /* [END] In case of empty table (nothing found)  */

  const rowClasses = (row: any, rowIndex: any) => {
    /* console.log(row, rowIndex) */
    if (row.status === "Conciliado") {
      return 'border-analise';
    } else {
      return 'border-inativo';
    }
  };

  if (sessions !== null) {
    return (
      <>
        <div className={"table-default"+ ((sessionClosed) ? " session-closed-table" : " session-closure-table")}>
          <div className="table-container">
            <div>
              <div className="d-flex flex-wrap align-items-center" style={{ gap: "15px" }}>
                <div>
                  <button className="btn btn-bills" onClick={handleExportClick}>
                    <FontAwesomeIcon
                      icon={["fal", "share"]}
                      size="1x"
                      style={{ marginRight: "5px" }}
                    />
                    Exportar
                  </button>
                </div>
                <div>
                  <button className="btn btn-bills" onClick={handlePrintClick}>
                    <FontAwesomeIcon
                      file-invoice
                      icon={["fal", "print"]}
                      size="1x"
                      style={{ marginRight: "5px" }}
                    />
                    Imprimir
                  </button>
                </div>
              </div>
            </div>
            <BootstrapTable
              bootstrap4
              keyField="id"
              data={sessions}
              columns={columns}
              rowClasses={rowClasses}
              striped={true}
            />
            <Pagination
              totalRows={totalRows}
              pageCount={pageCount}
              setPageCount={setPageCount}
              rowsPerPage={rowsPerPage}
              setRowsPerPage={setRowsPerPage}
              selectNumberRows="yes"
            />
          </div>
          <ModalQuestion 
            modalShow={exportERPModalShow}
            setModalShow={setExportERPModalShow}
            titulo="Exportar para ERP"
            mensagem="Confirma que verificou todos os dados e que estão corretos ?"
            handleActionConfirmModal={(e: any) => exportERP1()}
            //handleActionCancelModal={handleActionCancelDeleteClick}
          />
          <ModalQuestion 
            modalShow={conciliarSessionModalShow}
            setModalShow={setConciliarSessionModalShow}
            titulo="Conciliar Session"
            mensagem="Confirma que verificou todos os dados e que estão corretos ?"
            handleActionConfirmModal={(e: any) => conciliarSession1()}
            //handleActionCancelModal={handleActionCancelDeleteClick}
          />
          {/* Modal de Success */}
          <Modal
              className={"modal-confirm loading-modal"}
              show={modalSuccess}
              onHide={() => setModalSuccess(false)}
              size="lg"
              aria-labelledby="contained-modal-title-vcenter"
            >
              <>
                <Modal.Body className='modal-body text-center sucess-pos'>
                  {
                    modalContent !== "loading"
                      ?
                      <>
                        <div>
                          {
                            log === 2 || log === "2"
                              ?
                              <FontAwesomeIcon
                                icon={["fal", "exclamation-circle"]}
                                size="5x"
                                className="text-primary"
                                style={{ fontSize: "7.5em"}}
                              />
                              :
                              log === 1 || log === "1"
                                ?
                                <FontAwesomeIcon
                                  icon={["fal", "times-circle"]}
                                  size="5x"
                                  className="text-primary"
                                  style={{ fontSize: "7.5em"}}
                                />
                                :
                                log === 0 || log === "0"
                                  ?
                                  <FontAwesomeIcon
                                    icon={["fal", "check-circle"]}
                                    size="5x"
                                    className="text-primary"
                                    style={{ fontSize: "7.5em"}}
                                  />
                                  :
                                  <FontAwesomeIcon
                                    icon={["fal", "question-circle"]}
                                    size="5x"
                                    className="text-primary"
                                    style={{ fontSize: "7.5em"}}
                                  />
                          }
                        </div>
                        <div>
                          {responseText}
                        </div>
                        <div className="d-flex justify-content-center">
                          {
                            modalContent === 'success'
                              ?
                              <button
                                onClick={() => {refreshData(); setModalSuccess(false);}}
                                className="btn btn-primary mx-2 w-25"
                              >
                                Confirmar
                              </button>
                              :
                              <button
                                onClick={() => setModalSuccess(false)}
                                className="btn btn-primary mx-2 w-25"
                              >
                                Confirmar
                              </button>
                          }
                        </div>
                      </>
                      :
                      <div className="modal-body">
                        <div className="text-center">
                          <div className="load"></div>
                        </div>
                        <div>
                          <span>Processando</span>
                        </div>
                        <div></div>
                      </div>
                  }
                </Modal.Body>
              </>
            </Modal>
            {selectedRow !== null ?
              <Modal
                className="modalAuth"
                show={updateSessionObservationShow}
                onHide={()=>handleActionUpdateSessionObservationModal()}
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
              >
                <>
                  <Modal.Header className="p-3" closeButton></Modal.Header>
                  <FormSessionObservationEdit 
                    action={handleActionUpdateSessionObservationModal} 
                    info={selectedRow} 
                    setModalSuccess={setModalSuccess}
                    setModalContent={setModalContent}
                    setResponseText={setResponseText}
                    setLog={setLog}
                  />
                </>
              </Modal> : <></>}

            <ModalErrorsList
              modalShow={errorsListShow}
              setModalShow={setErrorsListShow}
              errorsList={errorsList}
            />
            {/* END - Modal de Success */}
        </div>
      </>
    );
  } else if (sessions === null && loading === true) {
    return (
      <>
        <div className="table-default session-closure-table loading not-found">
          <div className="table-container">
            <div>
              <div className="d-flex flex-wrap align-items-center" style={{ gap: "15px" }}>
                <div>
                  <button className="btn btn-bills" >
                    <FontAwesomeIcon
                      icon={["fal", "share"]}
                      size="1x"
                      style={{ marginRight: "5px" }}
                    />
                    Exportar
                  </button>
                </div>
                <div>
                  <button className="btn btn-bills" >
                    <FontAwesomeIcon
                      file-invoice
                      icon={["fal", "print"]}
                      size="1x"
                      style={{ marginRight: "5px" }}
                    />
                    Imprimir
                  </button>
                </div>
              </div>
            </div>
            <BootstrapTable
              bootstrap4
              keyField="id"
              data={loadingProducts}
              hover={true}
              columns={loadingColumns}
              striped={true}
            />
          </div>
        </div>
      </>
    )
  } else {
    return (
      <>
        <div className="table-default session-closure-table not-found">
          <div className="table-container">
            <BootstrapTable
              bootstrap4
              keyField="id"
              data={notFoundProducts}
              hover={true}
              columns={notFoundColumns}
              striped={true}
            />
          </div>
        </div>
      </>
    );
  }
}

export default TableSessionClosure;
