import React, { useState } from 'react';
import api from '../../services/api';
import Breadcrumb from '../../components/Breadcrumb/Breadcrumb';
import DesktopDefault from '../../templates/DesktopDefault';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import AddVehicleComponent from './components/AddVehicleComponent/AddVehicleComponent';
import { Modal } from 'react-bootstrap';

function AddVehicle() {
    const [modalShow, setModalShow] = useState<any>(false);
    const [modalMessage, setModalMessage] = useState<any>('Carregando');
    const [modalLog, setModalLog] = useState<any>(null);

    const InsertVehicle = (data: any) => {
        setModalShow(true);
        let imgArrayAux: any = [0,0,0,0];
        let imgObject: any = {
            vehiclePicture: '',
            crlv: '',
            dpvat: '',
            apolice: '',
        };

        const token = localStorage.getItem('GroupId') || '{}';
        const config = {
            headers: { 'Authorization': `Bearer ${token}` },
        };

        const convertImageToToken = async () => {
            let imgArray: any = [];

            if (data.vehiclePicture) {
                imgArray.push(data.vehiclePicture);
                imgArrayAux[0] = 1;
            }
            
            if (data.crlv) {
                imgArray.push(data.crlv);
                imgArrayAux[1] = 1;
            }
            
            if (data.dpvat) {
                imgArray.push(data.dpvat);
                imgArrayAux[2] = 1;
            }
            
            if (data.apolice) {
                imgArray.push(data.apolice);
                imgArrayAux[3] = 1;
            }

            try {
                const res = await api.post(`File/UploadBase64?switchCaseParam=3`,
                    imgArray
                    , config);
                if (res.status !== 400) {
                    if (res.data.data.log === 0) {
                        let index: number = 0;
                        if(imgArrayAux[0] === 1){
                            imgObject.vehiclePicture = res.data.data.data[index];
                            index++;
                        }
                        if(imgArrayAux[1] === 1){
                            imgObject.crlv = res.data.data.data[index];
                            index++;
                        }
                        if(imgArrayAux[2] === 1){
                            imgObject.dpvat = res.data.data.data[index];
                            index++;
                        }
                        if(imgArrayAux[3] === 1){
                            imgObject.apolice = res.data.data.data[index];
                            index++;
                        }

                        createVehicle(imgObject);
                    }
                }
            } catch (error: any) {
                console.log(error)
                console.log(error?.response)
            }
        }

        convertImageToToken();

        

        const createVehicle = async (images: any) => {
            try {
                const res = await api.post(`Vehicles/CreateVehicleAsync`,
                    {
                        "vehicleSupplier": data.supplier, //-- -> supplier_id
                        "vehicleType": parseInt(data.vehiclesType),
                        "vehicleTypeString": data.vehicleTypeString,
                        "vehicleModel": data.model,
                        "vehicleArmored": data.vehicleArmored === '1' ? 'SIM' : 'NÃO',
                        "vehicleColor": data.color,
                        "vehicleLicensePlate": data.licensePlate,
                        "vehicleYear": data.year,
                        "vehicleSeats": data.passengers,
                        "vehicleBagsG": data.baggage,
                        "vehicleBagsM": data.handBaggage,
                        "vehiclePhotoToken": images.vehiclePicture,
                        "vehiclePhotoCrlvToken": images.crlv,
                        "vehiclePhotoDpvatToken": images.dpvat,
                        "vehiclePhotoSeguroToken": images.apolice
                    }, config);
                if (res.status !== 400) {
                    if (res.data.data.log === 0) {
                        setModalLog(0);
                        setModalMessage('Veículo inserido com sucesso!');
                    } else {
                        setModalLog(1);
                        setModalMessage(res.data.data.texto);
                    }
                } else {
                    setModalLog(1);
                    setModalMessage('Erro ao processar operação');
                }
            } catch (error: any) {
                setModalLog(1);
                setModalMessage('Erro ao processar operação');
            }
        }
    };

    return (
        <>
            <DesktopDefault>
                <div className="container-fluid content-dashboard">
                    <Breadcrumb title="Veículos / Adicionar Veículos" />
                    <div className="partners mt-4">
                        <div className="d-flex align-items-center">
                            <span className='back' onClick={() => { window.location.href = '/vehicles/list-vehicles' }}>
                                <FontAwesomeIcon
                                    icon={["fal", "angle-double-left"]}
                                    style={{ margin: "0 5px 0 0", fontSize: '16px' }}
                                />
                                Voltar</span>
                        </div>
                        <h2 className="title" style={{ color: "#707070" }}>Adicionar Veículos</h2>

                        {/* Content */}

                        <AddVehicleComponent actionFunc={InsertVehicle} />

                        {/* Content */}

                        <Modal
                            className="modalAuth"
                            show={modalShow}
                            onHide={() => {
                                if(modalLog === 0){
                                    window.location.href = '/vehicles/list-vehicles';
                                }
                                setModalLog(null);
                                setModalMessage("Carregando");
                                setModalShow(false);
                            }}
                            aria-labelledby="contained-modal-title-vcenter"
                        >
                            <>
                                <Modal.Body
                                    className="modal-body text-center sucess-pos d-flex justify-content-evenly"
                                    style={{ height: "600px" }}
                                >
                                    {modalLog === null ? (
                                        <>
                                            <div className="loading-modal">
                                                <div className="load"></div>
                                            </div>
                                        </>
                                    ) : (
                                        <div>
                                            {modalLog === 0 ? (
                                                <FontAwesomeIcon
                                                    icon={["fal", "check-circle"]}
                                                    size="5x"
                                                    className="text-primary"
                                                    style={{ fontSize: "7.5em" }}
                                                />
                                            ) : (
                                                <FontAwesomeIcon
                                                    icon={["fal", "times-circle"]}
                                                    size="5x"
                                                    className="text-primary"
                                                    style={{ fontSize: "7.5em" }}
                                                />
                                            )}
                                        </div>
                                    )}
                                    <div>{modalMessage}</div>
                                    <div className="d-flex justify-content-center pt-3">
                                        <button
                                            onClick={() => {
                                                if(modalLog === 0){
                                                    window.location.href = '/vehicles/list-vehicles';
                                                }
                                                setModalLog(null);
                                                setModalMessage("Carregando");
                                                setModalShow(false);
                                            }}
                                            className="btn btn-primary mx-2 w-25"
                                        >
                                            Confirmar
                                        </button>
                                    </div>
                                </Modal.Body>
                            </>
                        </Modal>

                    </div>
                </div>
            </DesktopDefault>

        </>
    );
}

export default AddVehicle;