import React, { Key, useEffect, useState } from 'react';
import Table from 'react-bootstrap/Table';
//import Button from 'react-bootstrap/Button';

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Dropdown from 'react-bootstrap/Dropdown';

//import { useTranslation } from 'react-i18next';

//STYLES
import './PartnersTableUsers.scss';

import ModalDefault from '../../../../../components/Modal/ModalDefault/ModalDefault';
import FormNewUsersModal from '../../../../../components/Forms/FormNewUsersModal/FormNewUsersModal';
import PartnersRegisterUsers from '../PartnersRegisterUsers/PartnersRegisterUsers';
import api from '../../../../../services/api';

//import objectSuppliers from '../SuppliersTableUsers/locales/suppliersUsers.json';

export interface propModal {
  actionTableUser?: any,
  infoEdit?: any;
  isEdit?: any;
  disableAddContact?: any;
  usersEdit?: any;
};

const PartnersTableUsers: React.FC<propModal> = ({
  actionTableUser, infoEdit, isEdit = false, disableAddContact = false, usersEdit
}: propModal) => {
  const [userObject, setUserObject] = useState<any>([]);
  const [closeModal, setCloseModal] = useState<any>(false);
  const [usersLevel, setUsersLevel] = useState<any>([]);

  function verificaId(arr: any, elem: any) {
    for (var i: any = 0; i < arr.length; i++) {
      if (arr[i].id === elem || arr[i].userId === elem) return true;
    }
    return false;
  }

  const handleAction = (objectUserModal: any) => {
    var aux: any = userObject;

    objectUserModal.forEach((element: any) => {
      if (!verificaId(userObject, element?.id)) {
        aux.push(element);
      }
    });
    setUserObject([...aux]);
  };

  function removeUser(id: any) {
    var aux: any = [];
    var tempId: any;
    userObject.forEach((element: any) => {
      tempId = element?.id ? element.id : element?.userId;
      if (!(tempId === id)) {
        aux.push(element);
      }
    });

    setUserObject(aux);
  }

  useEffect(() => {
    //if (isEdit === false) {
    actionTableUser(userObject);
    //}

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userObject])

  useEffect(() => {
    if (isEdit === true) {
      setUserObject(infoEdit.map((elem: any) => { return ({ ...elem, nivelInfo: elem.nivel }) }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  function handleSelectUsers(obj: any = {}) {
    let aux: any = [];
    userObject.forEach((elem: any) => {
      console.log(elem?.nivel)
      if (elem.userId === obj?.userId || elem.id === obj?.userId) {
        aux.push({ ...elem, nivel: obj?.nivel, nivelInfo: obj?.nivel })
      } else {
        aux.push(elem)
      }
    });

    setUserObject(() => [...aux])
  }

  useEffect(() => {
    //const arrAux: any = [];
    const token = localStorage.getItem('GroupId') || '{}';
    const config = {
      headers: { 'Authorization': `Bearer ${token}` },
    };

    userObject?.forEach(async (elem: any) => {
      const idUser: number = elem.id || elem.userId;

      try {
        const { data } = await api.post('/UserPermission/GetListUserPermissionById',
          {
            "IdUser": idUser,
          }, config
        );
        if (data.statusCode !== 400) {
          if (data.data.log === 0) {

            setUsersLevel((prev: any) => [...prev, data.data.data[0]?.supplierUserPermissionDTO.map((item: any) => {
              return {
                idUser: idUser,
                nivel: item.user_role_id,
                description: item.description
              }
            })])
          }
        }
        //setProfileImg(data.data.data[0].photoUrl)
      } catch (error: any) {
        if (error?.response?.status === 401) {

        }
      }

      // setUsersLevel(arrAux);
    })
  }, [userObject]);

  return (
    <>
      <div className="table-add-default partners-users-table">
        <div className="table-container">
          <Table striped bordered>
            <thead>
              <tr>
                <th>Perfil</th>
                <th>Nome</th>
                <th>Sobrenome</th>
                <th>Email</th>
                <th>Telefone</th>
                <th>CPF</th>
                <th>Nível</th>
                <th>Ações</th>
              </tr>
            </thead>
            <tbody>
              {userObject?.length > 0
                ? userObject?.map((info: any, index: any) => (
                  <tr key={index}>
                    <td>{info?.photoBase64}</td>
                    <td>{info?.nome}</td>
                    <td>{info?.lastName}</td>
                    <td>{info?.email}</td>
                    <td>{info?.telefone}</td>
                    <td>{info?.cpf}</td>
                    <td>{/* {info?.nivel} */}

                      {
                        usersEdit !== true
                          ?
                          // eslint-disable-next-line array-callback-return
                          usersLevel?.find((elem: any) => {
                            //if (elem?.length > 0) {
                            return elem[0]?.idUser === info.id || elem[0]?.idUser === info.userId
                            // }
                          }) === undefined
                            ?
                            <select className='form-control' onChange={(e: any) => handleSelectUsers({ userId: info.id || info.userId, id: e.target[1]?.id, nivel: 29 })}>
                              {Number(info.nivel) === 29 ?
                                <>
                                  <option value="1" disabled>Selecione</option>
                                  <option value="29" selected={Number(info.nivel) === 29} >Agente Não Comissionado</option>
                                </>
                                :
                                <>
                                  <option value="1" selected disabled>Selecione</option>
                                  <option value="29"  >Agente Não Comissionado</option>
                                </>
                              }
                            </select>
                            :
                            <select className='form-control' defaultValue={info.nivel} onChange={(e: any) => handleSelectUsers({ userId: info.id || info.userId, id: e.target[1]?.id, nivel: e.target.value })}>
                              <option value="29" /* selected  disabled*/>Selecione</option>
                              {
                                // eslint-disable-next-line array-callback-return
                                usersLevel?.find((elem: any) => {
                                  //if (elem?.length > 0) {
                                  return elem[0]?.idUser === info.id || elem[0]?.idUser === info.userId
                                  //}
                                })?.map((elem: any, index: Key) => {
                                  return <option key={index} value={elem.nivel} id={elem.idUser} selected={Number(elem.nivel) === Number(info.nivel)}>{elem.description}</option>
                                })
                              }
                            </select>
                          :
                          info?.nivelDesc
                      }
                    </td>
                    {/* <td>{info?.userId}</td> */}
                    <td>{
                      <div className="center-table options-table">
                        <Dropdown drop="down" >
                          <Dropdown.Toggle variant="light ">
                            <FontAwesomeIcon size="lg" icon={['fal', 'ellipsis-h']} />
                          </Dropdown.Toggle>

                          {disableAddContact === false
                            ?
                            <Dropdown.Menu>
                              <Dropdown.Item eventKey="1" className="text-danger removeRow" onClick={() => removeUser(info?.id ? info?.id : info?.userId)}><FontAwesomeIcon icon={['fal', 'trash-alt']} />{'Remover'}</Dropdown.Item>
                            </Dropdown.Menu>
                            :
                            <></>
                          }
                        </Dropdown>
                      </div>
                    }</td>
                  </tr>
                ))
                : ""
              }
              {disableAddContact === false
                ?
                <div className="add-contact">
                  <div className="d-flex pt-3 pb-4">
                    <ModalDefault
                      title="Vincular usuário"
                      name={'Vincular usuário'}
                      class="btn btn-outline-primary mr-2"
                      closeModal={closeModal}
                      setCloseModal={setCloseModal}
                      classModal="modal-pos-tax"
                    >
                      <FormNewUsersModal actionModalUser={handleAction} setCloseModal={setCloseModal} />
                    </ModalDefault>

                    <ModalDefault
                      title="Adicionar usuário"
                      name={'Adicionar usuário'}
                      class="btn btn-outline-primary"
                      closeModal={closeModal}
                      setCloseModal={setCloseModal}
                      classModal="modal-large"
                    >
                      <PartnersRegisterUsers actionModalUser={handleAction} />
                    </ModalDefault>
                  </div>
                </div>

                /*<p className="add-contact">
                  <td colSpan={12} className="text-center btn-add-contact">
                    <ModalDefault
                      title="Vincular usuário"
                      classModal="modal-pos-tax"
                      name={'+'}
                      class="btn-add"
                      closeModal={closeModal}
                      setCloseModal={setCloseModal}
                    >
                      
                    </ModalDefault>
                  </td>
                </p>*/
                :
                <></>
              }
            </tbody>
          </Table>


        </div>
      </div>
    </>
  );
}

export default PartnersTableUsers;