/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useEffect, useState } from "react";
import { Row, Col, Form, InputGroup, Badge } from "react-bootstrap";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import DoubleCalendarTicket from "../../../../components/Calendar/DoubleCalendarTicket5DaysBefore";
import DropdownNumberPeopleIntegrated from "../../../../components/DropdownNumberPeopleIntegrated/DropdownNumberPeopleIntegrated";
//import DropdownNumberPeople from "../../../../components/DropdownNumberPeople/DropdownNumberPeople";

import { useAppSelector, useAppDispatch } from "../../../../store/hooks";
import { addItemCart } from "../../../../store/cart";
import DropdownHours from "../../../../components/DropdownHours/DropdownHours";
import RefundRules from "../RefundRules/RefundRules";
import { useTranslation } from "react-i18next";
import DropdownHoursFree from "../../../../components/DropdownHoursFree/DropdownHoursFree";

export interface modalityInfo {
  modality: any;
  indexMod: any;
  tourDetails: any;
  setModalShow?: any;
  isModal?: any;
}

const TourOptions = ({
  modality,
  indexMod,
  tourDetails,
  setModalShow,
  isModal = false,
}: any) => {
  const [t] = useTranslation();

  const [numberPeople, setNumberPeople] = useState<any>(null);
  const [dateTour, setDateTour] = useState<any>([]);

  const [indexador, setIndexador] = useState<any>([]); ///array que me diz quais dos tourOptions vai habilitar o dropdown

  const [productHours, setProductHours] = useState<any>([]);
  const [hour, setHour] = useState<any>(null);

  const cartSIG = useAppSelector((state) => state.cart);

  const [loading, setLoading] = useState<any>(false);

  const [isIntegration, setIsIntegration] = useState<any>(false);
  const [preloader, setPreloader] = useState<boolean>(false);
  const [ranges, setRanges] = useState<any>(null);

  const [peopleDisponible, setPeopleDisponible] = useState<boolean>(false);

  const [, setTariffs] = useState<any>({
    maxNumPeople: '0',
    minNumPeople: '0'
  });

  function ScrollTop() {
    window.scrollTo(0, 0);
  }

  const dispatch = useAppDispatch();

  function changePriceDateTour(obj: any, hours: any) {
    setDateTour(obj);
    setProductHours(hours);
    setNumberPeople({
      adults: "1",
      childs: "0",
      infants: "0",
      elders: "0",
      student: "0",
      globalPeople: "1",
    });
  }

  const setNewNumberPeople = (peoples: any) => {
    setNumberPeople(peoples);
  };

  function addIndex(index: any) {
    var aux: any = indexador;
    if (aux.includes(index) === false) {
      aux.push(index);
    }
    setIndexador(aux);
  }

  useEffect(() => {
    var aux: any = [];
    for (var i = 0; i < tourDetails.modalities.length; i++) {
      aux.push({
        adult: 0,
        child: 0,
        infant: 0,
      });
    }
    setDateTour(aux);
  }, [tourDetails.modalities]);

  const selectHours = (e: any) => {
    setHour(e.target.value);
    setTariffs({
      maxNumPeople: e.target.options[e.target.selectedIndex].dataset.capacity,
      minNumPeople: 1,
    });

    setPeopleDisponible(true);
  };

  function keepDropdownOpen() {
    var elem: any = document.getElementById("authCartDropdown");

    if (elem && !elem.classList.contains("active")) {
      elem.classList.add("active");
    }
  }

  const addToCart = (itemOption: any, index: any) => {

    const item = {
      productName: itemOption.productName,
      productNameEN: itemOption.productNameEN,
      productNameES: itemOption.productNameES,
      modalityName: itemOption.modalityName,
      imgCart: tourDetails.images[0],
      imagesBaseUrl: tourDetails.imagesBaseUrl,
      typeProduct: tourDetails.typeProduct,
      price: itemOption?.tarif?.price,

      idTarif: itemOption.idTarif,
      idPickup: itemOption.idPickup,
      reservationSystem: tourDetails.reservationSystem,

      priceAdults: itemOption.priceAdults,
      priceChilds: itemOption.priceChilds,
      priceInfants: itemOption.priceInfants,
      priceElders: itemOption.priceElders,
      priceStudent: itemOption.priceStudent,
      priceGlobalPeople: itemOption.priceGlobalPeople,

      sellingType: itemOption.idSellingType,
      adults: itemOption.adults,
      childs: itemOption.childs,
      infants: itemOption.infants,
      elders: itemOption.elders,
      student: itemOption.student,

      globalPeople: itemOption.globalPeople,
      totalPeople: itemOption.totalPeople,

      productType: 4,
      productCode: tourDetails.productCode,
      time: itemOption.time === null ? "00:00:00" : itemOption.time,
      date: itemOption.date,
      supplier: tourDetails.supplier,
      supplierFantasyName: tourDetails.supplierFantasyName,
      typePickup: itemOption.embarkingType,
      meetingPoint:
        itemOption.embarkingType === "0" ? itemOption.meetingPoint : "",
      pickupListId:
        itemOption.embarkingType === "0" ? 0 : itemOption.pickupListId,
      pickup: 0,

      discount: 0,
      customValueNet: 0,
      customValueSell: 0,
      goingSource: "null",
      commingSource: "null",
      latOrigem: "null",
      lngOrigem: "null",
      latDestino: "null",
      lngDestino: "null",
      cia: "null",
      voo: "null",
      smallSuitcase: 0,
      bigSuitcase: 0,
      internalNotes: " ",
      idVeiculo: 0,
      passengers: [],
      labelsSorted: itemOption.ranges.labelsSorted
    };

    dispatch(addItemCart(item));
  };

  const auth = (option: any, index: any) => {
    console.log(numberPeople, dateTour)
    if ((numberPeople === null || numberPeople.adults === '1' || (numberPeople.adults + numberPeople.childs + numberPeople.infants + numberPeople.elders + numberPeople.student + numberPeople.globalPeople <= 0)) || dateTour.length < 1) {
      alert("Preencha todos os dados");
    } else {
      option.productName = tourDetails.productName;
      option.productNameEN = tourDetails.productNameEN;
      option.productNameES = tourDetails.productNameES;

      option.adults = numberPeople.adults;
      option.childs = numberPeople.childs;
      option.infants = numberPeople.infants;
      option.elders = numberPeople.elders;
      option.student = numberPeople.student;
      option.globalPeople = dateTour[index].idSellingType === 1 ? 1 : numberPeople.adults + numberPeople.childs + numberPeople.infants + numberPeople.elders + numberPeople.student + numberPeople.globalPeople;
      option.totalPeople = dateTour[index].idSellingType === 1 ? numberPeople.globalPeople : undefined;

      option.date = (
        document.getElementById(`date-${option.id}`) as HTMLInputElement
      ).value
        .split("/")
        .reverse()
        .join("-");
      /* option.date = isModal === true ? (document.getElementsByClassName(`date-${option.id}`)[1] as HTMLInputElement).value.split('/').reverse().join('-') : (document.getElementsByClassName(`date-${option.id}`)[0] as HTMLInputElement).value.split('/').reverse().join('-'); */

      option.time = dateTour[index].time;

      const selectedDate = JSON.parse(
        (
          document.getElementsByClassName(
            `date-${option.id}`
          )[0] as HTMLInputElement
        ).dataset.object || "{}"
      );
      /* const selectedDate = isModal === true ? JSON.parse((document.getElementsByClassName(`date-${option.id}`)[1] as HTMLInputElement).dataset.object || '{}') : JSON.parse((document.getElementsByClassName(`date-${option.id}`)[0] as HTMLInputElement).dataset.object || '{}'); */

      option.idTarif = dateTour[index].auxTarif[0].idTarifario || dateTour[index].idTarifario; //option.idTarif = dateTour[index].idTarifario;

      option.priceAdults = dateTour[index].adult || 0;
      option.priceChilds = dateTour[index].child || 0;
      option.priceInfants = dateTour[index].infant || 0;
      option.priceElders = dateTour[index].elders || 0;
      option.priceStudent = dateTour[index].student || 0;
      option.priceGlobalPeople = dateTour[index].global || 0;
      option.idSellingType = dateTour[index].idSellingType;

      const verifyEvery = cartSIG.dados.every((elem: any) => {
        if (
          elem.productName === option.productName &&
          elem.date === option.date
        ) {
          return false;
        } else {
          return true;
        }
      });

      if (option.date !== "") {
        var repeatedItem: any = false; //impede de adicionar produto repetido no carrinho

        cartSIG.dados.forEach((elem: any) => {
          if (elem.productCode === tourDetails.productCode && elem.date === tourDetails.modalities[0].date) {
            repeatedItem = true;
          }
        });

        if (repeatedItem === true && verifyEvery === false) {
          setModalShow(false);
          alert("Produto já existe no carrinho!");
        } else {
          addToCart(option, index);
          keepDropdownOpen();
          setModalShow(false);
          setTimeout(() => ScrollTop(), 200);
        }
      } else {
        alert("Preencha todos os dados");
      }
    }
  };

  return (
    <div className="bg-white mt-4">
      <Row className="m-0">
        <div className="col-12 col-md-9 py-3">
          <div className="d-flex flex-column justify-content-between h-100">
            <div>
              <h3>
                {modality.modalityName}
                {modality.rangeComissionLabel !== null &&
                  modality.rangeComissionLabel !== "DEFAULT" ? (
                  // eslint-disable-next-line react/jsx-no-undef
                  <span className="rangecomission">
                    <Badge
                      className={modality.rangeComissionLabel
                        .toString()
                        .toLowerCase()}
                    ></Badge>{" "}
                    {modality.rangeComissionLabel}
                  </span>
                ) : (
                  <></>
                )}
              </h3>
              <div className="sub-info">
                <div className="d-flex flex-wrap mt-2">
                  <div style={{ height: 'unset' }} className={modality.daysOfWeek.dom === 1 ? "weekDay enable" : "weekDay disable"}>Dom</div>
                  <div style={{ height: 'unset' }} className={modality.daysOfWeek.seg === 1 ? "weekDay enable" : "weekDay disable"}>Seg</div>
                  <div style={{ height: 'unset' }} className={modality.daysOfWeek.ter === 1 ? "weekDay enable" : "weekDay disable"}>Ter</div>
                  <div style={{ height: 'unset' }} className={modality.daysOfWeek.qua === 1 ? "weekDay enable" : "weekDay disable"}>Qua</div>
                  <div style={{ height: 'unset' }} className={modality.daysOfWeek.qui === 1 ? "weekDay enable" : "weekDay disable"}>Qui</div>
                  <div style={{ height: 'unset' }} className={modality.daysOfWeek.sex === 1 ? "weekDay enable" : "weekDay disable"}>Sex</div>
                  <div style={{ height: 'unset' }} className={modality.daysOfWeek.sab === 1 ? "weekDay enable" : "weekDay disable"}>Sab</div>
                </div>
              </div>
              <div className="sub-info">
                <small>
                  {
                    modality.activityStart === undefined || modality.activityStart === null
                      ?
                      ''
                      :
                      <span>
                        <FontAwesomeIcon
                          icon={["fal", "clock"]}
                          size="1x"
                          className="mr-2"
                        />
                        {`${t('sales.infoProducts.beginTour')}: ${modality.activityStart}`}
                      </span>
                  }
                  <span>
                    <FontAwesomeIcon
                      icon={["fal", "clock"]}
                      size="1x"
                      className="mr-2"
                    />
                    {`${t('sales.infoProducts.duration')}: ${modality.activityDurationHours >= 9 ? `${modality.activityDurationHour}` : `0${modality.activityDurationHours}`}:${modality.activityDurationMinutes >= 9 ? `${modality.activityDurationMinutes}` : `0${modality.activityDurationMinutes}`}`}
                  </span>
                  <span>
                    <FontAwesomeIcon
                      icon={["fal", "clock"]}
                      size="1x"
                      className="mr-2"
                    />
                    {`${t('sales.infoProducts.buyAdvance')} ${t('sales.infoProducts.until')} ${modality.tarif.buyingAdvance} ${t('sales.infoProducts.minutes')}`}
                  </span>
                </small>
              </div>
              <Row className="my-2">
                <div className="col-12 col-md-6">
                  <h4 className="tour-name text-info-products">{t('sales.infoProducts.include')}</h4>
                  <div className="session-not-yes yes">
                    <small>
                      {modality.includedItems.map((text: any, index: any) => {
                        return (
                          <p key={index}>
                            <FontAwesomeIcon icon={["fal", "check"]} size="1x" />
                            {text.replace("•	", "")}
                          </p>
                        );
                      })}
                    </small>
                  </div>
                </div>
                <div className="col-12 col-md-6">
                  <h4 className="tour-name text-info-products">{t('sales.infoProducts.notInclude')}</h4>
                  <div className="session-not-yes not">
                    <small>
                      {modality.excludedItems.map((text: any, index: any) => {
                        return (
                          <p key={index}>
                            <FontAwesomeIcon icon={["fal", "times"]} size="1x" />
                            {text.replace("•	", "")}
                          </p>
                        );
                      })}
                    </small>
                  </div>
                </div>
              </Row>
            </div>
            <div className="bg-cancel-rules">
              <RefundRules tourDetails={modality} />
            </div>
          </div>
        </div>
        <div className="col-12 col-md-3 text-center bg-price py-5">
          <div>
            <span className="small text-muted w-50">
              {t('sales.infoProducts.buyPerAdult')}
            </span>
            <div className="price pb-2">
              <span className="h4">
                <small>R$ </small>
                {modality.tarif === null
                  ? "??"
                  : modality.tarif.price.toFixed(2).replace(".", ",")}
              </span>
            </div>
            <div style={{ padding: "0 15px" }}>
              <Form>
                <Form.Group as={Col}>
                  <InputGroup
                    hasValidation
                    className=""
                    placeholder="Quando?"
                    id="date-"
                    onClick={() => {
                      addIndex(indexMod);
                    }}
                  >
                    <DoubleCalendarTicket
                      modalityID={modality.id}
                      product={modality}
                      productCode={tourDetails.productCode}
                      changePriceDateTour={changePriceDateTour}
                      dateTour={dateTour}
                      index={indexMod}
                      setLoading={setLoading}
                      setIsIntegration={setIsIntegration}
                      setPreloader={setPreloader}
                      setRanges={setRanges}
                      setPeopleDisponible={setPeopleDisponible}
                    ></DoubleCalendarTicket>
                  </InputGroup>
                </Form.Group>

                {
                  isIntegration !== false
                    ?
                    <div>
                      {numberPeople !== null && indexador.includes(indexMod) === true
                        ?
                        (preloader === false
                          ?
                          (
                            <select
                              className={
                                productHours.length > 0
                                  ? productHours[0].startTime === null
                                    ? "d-none"
                                    : "panel-dropdown bg-white w-100"
                                  : "d-none"
                              }
                              onChange={(e) => selectHours(e)}
                            >
                              <option value={"null"} disabled selected>
                                Selecione
                              </option>
                              {productHours.map((x: any, index: any) => {
                                return (
                                  <>
                                    {x.capacity === 0 ? (
                                      <></>
                                    ) : (
                                      <option
                                        key={index}
                                        value={x.startTime}
                                        data-capacity={x.capacity}
                                      >
                                        {`${x.startTime} - ${x.capacity} vagas`}
                                      </option>
                                    )}
                                  </>
                                );
                              })}
                            </select>
                          )
                          :
                          (
                            <div className="panel-dropdown bg-white d-flex">
                              <div style={{ height: "20px" }}>
                                <div className="load"></div>
                              </div>
                            </div>
                          )
                        )
                        :
                        (
                          <div className="panel-dropdown bg-white d-flex">
                            <div style={{ height: "20px" }}></div>
                          </div>
                        )}
                    </div>
                    :
                    <div>
                      {numberPeople !== null &&
                        indexador?.includes(indexMod) === true ? (
                        preloader === false ? (
                          <div className="panel-dropdown bg-white panel-dropdown-custom">
                            {
                              dateTour[indexMod]?.isFreeSale === 1
                                ?
                                <DropdownHoursFree
                                  changePriceDateTour={changePriceDateTour}
                                  dateTour={dateTour}
                                  index={indexMod}
                                  setPeopleDisponible={setPeopleDisponible}
                                  info={tourDetails}
                                />
                                :
                                <DropdownHours
                                  changePriceDateTour={changePriceDateTour}
                                  dateTour={dateTour}
                                  index={indexMod}
                                  setPeopleDisponible={setPeopleDisponible}
                                  info={tourDetails}
                                />
                            }
                          </div>
                        ) : (
                          <div className="panel-dropdown bg-white d-flex panel-dropdown-custom">
                            <div style={{ height: "20px" }}>
                              <div className="load"></div>
                            </div>
                          </div>
                        )
                      ) : (
                        <div className="panel-dropdown bg-white d-flex panel-dropdown-custom">
                          <div style={{ height: "20px" }}></div>
                        </div>
                      )}
                    </div>
                }

                <div className="col-6 col-sm-6 col-md-12 my-2">
                  <div className="panel-dropdown bg-white">
                    {
                      peopleDisponible === true || (isIntegration && hour !== null)
                        ?
                        <DropdownNumberPeopleIntegrated
                          actionPeople={setNewNumberPeople}
                          info={tourDetails}
                          dateTour={dateTour}
                          index={indexMod}
                          //ranges={ranges}
                          ranges={ranges[ranges.findIndex((elem: any) => elem.tarCode === modality?.tarif?.tarCode)]/* ranges[index] */}
                          isIntegration={isIntegration}
                        />
                        :
                        <></>
                    }
                  </div>
                </div>
                <span
                  className="btn btn-options btn-primary btn-block btn-add-cart link-add"
                  onClick={() => auth(modality, indexMod)}
                >
                  <FontAwesomeIcon
                    icon={["fal", "cart-arrow-down"]}
                    size="1x"
                    className="mr-2"
                  />
                  {t('sales.infoProducts.add')}
                </span>
              </Form>
            </div>
          </div>
        </div>
      </Row>
    </div>
  );
};

export default TourOptions;
