import React, { useState, useEffect, useRef } from "react";
//import api from "../../../services/api";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
//import i18next from "i18next";
import './DropdownSearchMulti.scss';
import { OverlayTrigger } from "react-bootstrap";
import Tooltip from 'react-bootstrap/Tooltip';

export interface propProduct {
    //setCode?: any;
    //propsField: any;
    //propsErrors: any;
    error: any;
    propsLabel: any;
    value: any;
    setValue: any;
    search: any;
}

const DropdownSearchMulti: React.FC<propProduct> = ({error,propsLabel,value,setValue,search}: propProduct) => {

    //const lng = i18next.language === "pt" ? "BR" : i18next.language === "en" ? "EN" : i18next.language === "es" ? "ES" : 'BR';

    const [searchTerm, setSearchTerm] = useState<string>("");
    const [itemsList, setItemsList] = useState<any>("");
    //const [locations, setLocations] = useState<any>("");
    const [isOpen, setIsOpen] = useState<any>(false);
    const [searchValue, setSearchValue] = useState<any>("");
    const [valueSelect, setValueSelect] = useState<any>(null);
    //const [productCode, setProductCode] = useState<any>(null);

    /* Detecta clique fora da div#wrapper para fechar*/
    const wrapperRef = useRef(null);
    useOutsideAlerter(wrapperRef);

    function useOutsideAlerter(ref: any) {
        useEffect(() => {
            function handleClickOutside(event: any) {
                if (ref.current && !ref.current.contains(event.target)) {
                    setIsOpen(false);
                }
            }
            document.addEventListener("mousedown", handleClickOutside);
            return () => {
                document.removeEventListener("mousedown", handleClickOutside);
            };
        }, [ref]);
    }

    /* DropdownSearchMulti */
    // function getLocations(data: any) {
    //     var aux: any = [];

    //     data.forEach((element: any) => {
    //         if (!aux.includes(element.eventLocation)) {
    //             aux.push(element.eventLocation);
    //         }
    //     });

    //     //setLocations(aux);
    // }

    const searchItems = async () => {
        var items = await search(searchTerm);
        
        if(items !== null) {
            setItemsList(items);
            setIsOpen(true);
        }

        // const response = await api.post(
        //     `${process.env.REACT_APP_SERVER_URL_API}/Products/GetAllToursFilter`,
        //     //`${process.env.REACT_APP_SERVER_URL_API}/Products/GetToursWithModalityFilter`,
        //     {
        //         term: searchTerm,
        //         // channel: 3,
        //         // lang: lng,
        //         // ProductType: 0,
        //     }
        // );

        // if (response.data.data !== null) {
        //     setTours(response.data.data);
        //     //getLocations(response.data.data);
        //     setIsOpen(true);
        //     // isOpen === false ? setIsOpen(true) : setIsOpen(false);
        // }
    };

    const handleClickItem = (event: React.MouseEvent<HTMLElement>) => {
        var element = (event.target as HTMLButtonElement).dataset.tour;
        //var modid = (event.target as HTMLButtonElement).dataset.modality;
        var codeE = (event.target as HTMLButtonElement).dataset.code;
        //var typeE = (event.target as HTMLButtonElement).dataset.busca;
        //var codeE =
        //    (event.target as HTMLButtonElement).dataset.busca === "tour"
        //        ? (event.target as HTMLButtonElement).dataset.code
        //        : null;
        setSearchValue("");
        const itemSelect = {
            //type: typeE,
            code: codeE,
            name: element,
            //modality: modid
        };
        setValueSelect(itemSelect);
        setIsOpen(false);

        var indexOld = -1;

        for(var i=0;i<value.length;i++) {
            if(value[i].code === itemSelect.code) {
                indexOld = i;
                break;
            }
        }

        if(indexOld > -1) {
            const newValue = [...value];
            newValue.splice(indexOld,1);
            setValue(newValue);
        } else {
            setValue([...value, itemSelect]);
        }
    };

    useEffect(() => {
        if ( searchValue.length > 2 && valueSelect === null) {
            searchItems();
        } else if (searchValue.length <= 2) {
            setIsOpen(false)
            setValueSelect(null)
        } else {
            ///momento do click
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [searchValue]);

    const handleFocus = () => {
        if (searchValue.length >= 2) {
            setIsOpen(true);
        }
    };

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const newValue = e.target.value;
        setSearchValue(newValue);
        setSearchTerm(newValue);
    };

    const handleKeyUp = (e: React.KeyboardEvent<HTMLInputElement>) => {
        //const newValue = e.target.value;
        //setSearchValue(newValue);
        //setSearchTerm(newValue);
        if(e.key === 'Backspace' && value.length > 0) {
            removeItem(value[value.length-1]);
        }
    };

    function removeItem(item:any) {
        const newValue = [...value];

        for(var i=0;i<value.length;i++) {
            if(value[i].code === item.code) {
                
                newValue.splice(i,1);
                setValue(newValue);
                break;
            }
        }
    }

    /* END - DropdownSearchMulti */

    if (itemsList !== null) {
        return (
            <>
                {/* DropdownSearchMulti */}
                <div
                    ref={wrapperRef}
                    className="search-product"
                    id="nav-1"
                    role="tabpanel"
                    aria-labelledby="nav-1-tab"
                >
                    <div className={`input-group${error === true ? " endpoint-error" : ""}`}>
                        <div
                            className="form-control py-1 py-md-2 d-flex" style={{flexWrap: 'wrap'}}
                        >
                            {value.length > 0
                                    ? value.map((it: any, index: any) => (
                                        <div className="multi-search-item">
                                            <div className="multi-search-item-title">
                                                {/* {it.name} */}
                                                <OverlayTrigger
                                                    overlay={
                                                    <Tooltip id="tooltip">{it.name}</Tooltip>
                                                }>
                                                    <span className="text-wrap-custom">
                                                        {it.name}
                                                    </span>
                                                </OverlayTrigger>
                                            </div>
                                            <div className="multi-search-item-btn" onClick={() => removeItem(it)}><FontAwesomeIcon icon={'times'} size="sm" className="multi-search-item-btnicon" style={{fontWeight: 'normal', width: '0.7rem', height: '0.7rem', color: 'gray'}} /></div>
                                        </div>
                                ))
                                : ""
                            }
                            <div className="multi-search-selected-inputbox">
                                <input
                                    type="text"
                                    value={searchValue}
                                    className="form-control m-1 p-0"
                                    style={{border: 'none'}}
                                    placeholder={value.length === 0 ? propsLabel : ''}
                                    data-input="search"
                                    aria-label="Destino"
                                    aria-describedby="tourDestino"
                                    autoComplete="off"
                                    id="tourDestino"
                                    onChange={handleChange}
                                    onFocus={handleFocus}
                                    onKeyUp={handleKeyUp}
                                />
                            </div>
                        </div>
                        <div
                            id="search-menu"
                            style={{
                                position: "absolute",
                                top: "100%",
                                left: "0px",
                                zIndex: 10,
                                backgroundColor: "#fff",
                                padding: "10px",
                                width: "100%",
                                color: "#000",
                            }}
                            className={isOpen === true ? "d-block" : "d-none"}
                        >
                            <div id="search-dataset-allTourNames">
                                {itemsList.length > 0
                                    ? itemsList.map((tours: any, index: any) => (
                                        !value.find((a:any) => a.code === `${tours.code}`) ?
                                        <p
                                            key={index}
                                            onClick={handleClickItem}
                                            style={{ cursor: "pointer" }}
                                            //className={`chooice tt-suggestion tt-selectable${value.find((a:any) => a.modality === `${tours.modalityId}`) ? ' multi-search-selected-item' : ''}`}
                                            className="chooice tt-suggestion tt-selectable"
                                            //data-busca="tour"
                                            data-input="search"
                                            data-code={tours.code}
                                            data-tour={tours.name}
                                            //data-modality={tours.modalityId}
                                        >
                                            <span>
                                                <span>
                                                    {tours.name}
                                                </span>
                                            </span>
                                        </p>
                                        : <></>
                                    ))
                                    : ""}
                            </div>
                        </div>
                    </div>
                </div>
                {/* END - DropdownSearchMulti */}
            </>
        )
    } else {
        return (
            <></>
        )
    }
}

export default DropdownSearchMulti;