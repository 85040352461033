/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useEffect, useState } from 'react';
import Select from 'react-select';
import api from '../../services/api';

import { useTranslation } from "react-i18next";

import Form from 'react-bootstrap/Form';

export interface propPlus {
    propsField: any,
    propsErrors: any,
    setValue: any;
    defaultValue?: any,
};

const GetFetchListLineDetails: React.FC<propPlus> = ({
    propsField, propsErrors, setValue, defaultValue
}: propPlus) => {
    const { t } = useTranslation();

    const [info, setInfo] = useState<any>(null);

    const [isMenuOpen, setIsMenuOpen] = useState<boolean>(false);
    const [defaultItems, setDefaultItems] = useState<any>([]);

    const onMenuOpen = () => {
        if (isMenuOpen === false) {
            setIsMenuOpen(true);
        } else {
            setIsMenuOpen(false);
        }
    }

    useEffect(() => {
        const token = localStorage.getItem('GroupId') || '{}';
        const config = {
            headers: { 'Authorization': `Bearer ${token}` },
        };
        async function listLineDetails() {
            try {
                const { data } = await api.get(`/Ticket/FetchListLineDetails`, config);
                if (data.status !== 400) {
                    setInfo(data.data);
                    if(defaultValue !== 0){
                        let aux: any = data.data.find((e: any) => e.id === Number(defaultValue))
                        setValue('lineDetail', { value: aux.id, label: aux.lineDetail })
                    }
                }
            } catch (error: any) {
                setTimeout(() => {
                    listLineDetails();
                }, 5000);
            }
        }
        listLineDetails();
        
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);


    if (info !== null) {
        const transformed = info.map(({ id, lineDetail }: any) => ({ value: id, label: lineDetail }));
        return (
            <>
                <div className={propsErrors.salesChannel !== undefined ? "endpoint-error" : "endpoint"}>
                    <Select
                        onMenuOpen={onMenuOpen}
                        onMenuClose={onMenuOpen}
                        {...propsField}
                        aria-invalid={propsErrors?.salesChannel ? "true" : ""}
                        as="select"
                        className="basic-multi-select"
                        classNamePrefix="select"
                        margin="normal"
                        placeholder={t('accordionFinancial.select.select')}
                        options={transformed}
                        getOptionLabel={(option: any) => `${option.label}`}
                        //defaultValue={transformed.find((e: any) => e.value === Number(defaultValue))}
                    />
                </div>
            </>
        );
    } else {
        return (
            <>
                <Form.Select>
                    <option value='' disabled></option>
                </Form.Select>
            </>
        )
    }
}

export default GetFetchListLineDetails;