import React, { useEffect, useState, Key } from 'react';
import api from '../../services/api';

import { useTranslation } from "react-i18next";

import Form from 'react-bootstrap/Form';

export interface propPlus {
  propsField: any,
  propsLabel: any,
  propsErrors: any,
  setFilterAccount: any
};


const GetAccountingAccountsBillFilter: React.FC<propPlus> = ({
  propsField, propsLabel, propsErrors, setFilterAccount
}: propPlus) => {
  const { t } = useTranslation();
  const [info, setInfo] = useState<any>(null);
  /* const [isMenuOpen, setIsMenuOpen] = useState<boolean>(false); */

  /* const onMenuOpen = () => {
    if (isMenuOpen === false) {
      setIsMenuOpen(true);
    } else {
      setIsMenuOpen(false);
    }
  } */

  useEffect(() => {
    const token = localStorage.getItem('GroupId') || '{}';
    const config = {
      headers: { 'Authorization': `Bearer ${token}` },
    };
    async function listSuppliersType() {
      try {
        const { data } = await api.get(`/BillsPayable/GetAccountingAccounts`, config);
        if (data.status !== 400) {
          setInfo(data.data)
        }
      } catch (error: any) {
        if (error?.response?.status === 401) {
          window.location.href = window.location.origin + '/';
        }
      }
    }
    listSuppliersType();
  }, []);

  const handleChangeType = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = e.target.value;
    setFilterAccount(newValue);
  }

  if (info !== null) {
    return (
      <>

      <div className={propsErrors[propsField.name] !== undefined ? "endpoint-error" : "endpoint"}>
        <Form.Select
          {...propsField}
          aria-invalid={propsErrors[propsField.name] ? "true" : ""}
          as="select"
          variant="standard"
          margin="normal"
          required
          onChange={handleChangeType}
          //defaultValue={propsField.value}
          //value={value}
        >
          <option value='' >{t !== undefined ? t('billsToPay.filter.all'): 'Todos'}</option>
          {info.length > 0 ? info.map((info: any, index: Key) => (
            <option key={index} value={info.id}>{`${info.conta} - ${info.nome}`}</option>
          )) : ''}
        </Form.Select>
      </div>
      </>
    );
  } else {
    return (
      <>
        <Form.Select>
          <option value='' disabled></option>
        </Form.Select>
      </>
    )

  }
}

export default GetAccountingAccountsBillFilter;