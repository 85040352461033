/* eslint-disable @typescript-eslint/no-unused-vars */
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ErrorMessage } from "@hookform/error-message";
import React, { useState, Key, useEffect, useCallback } from "react";
import { Button, Col, Container, Form, Row } from "react-bootstrap";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { Controller, useForm } from "react-hook-form";
import Breadcrumb from "../../../../components/Breadcrumb/Breadcrumb";
import api from "../../../../services/api";
import DesktopDefault from "../../../../templates/DesktopDefault";

import { Modal, OverlayTrigger, Dropdown, Tooltip } from "react-bootstrap";

import AutoComplete from "react-google-autocomplete";
import { geocodeByAddress, getLatLng } from "react-google-places-autocomplete";

import "./EditPickup.scss";

import GetSupplierSupplierIdentity from "../../../../components/C2Points/GetSupplierSupplierIdentity";
import GetSupplierGroupIdentity from "../../../../components/C2Points/GetSupplierGroupIdentity ";

export interface propEdit {
  //     (
  //      isEdit?: any;
  //      pickupInfo?: any;
  //      setIsEdit?: any;
  // )  
}

const EditPickup: React.FC<propEdit> = () => {
  const tourAuth: any = JSON.parse(localStorage.getItem("c2tourAuth") || "{}");

  const userName: any = tourAuth.user.nome;
  const userLastName: any = tourAuth.user.lastName;
  const groupId: any = tourAuth.user.perfil.groupId;

  const [pickups, setPickups] = useState<any>(null);
  const [compFantasiaAux, setCompFantasiaAux] = useState<any>(null);
  const [latitude, setLatitude] = useState<any>([]);
  const [longetude, setLongitude] = useState<any>([]);

  const [addLocation, setAddLocation] = useState<any>([]);
  const [checked, setChecked] = useState<any>(false);
  const [excludedItems, setExcludedItems] = useState<any>([]);
  const [local, setLocal] = useState<any>([]);

  const [modalShow, setModalShow] = useState(false);
  const [modalLog, setModalLog] = useState<any>(null);
  const [modalMessage, setModalMessage] = useState<any>("Carregando");

  const validated = false;

  const {
    control,
    handleSubmit,
    setValue,
    formState: { errors },
    watch,
  } = useForm();
 
  const onSubmit = (data: any) => {
    setModalShow(true);
    let auxLocation: any = []; 
       
    let controlPosition: any = 1;
    for (let i: any = 0; i < addLocation.length; i++) {
      if (!excludedItems.includes(addLocation[i].id)) {
        const pickupName = watch(`pickupName${addLocation[i].id}`, "");
        const pickupMinutes = watch(`search${addLocation[i].id}`, "");
        const pickupInstructions = watch(`instruction${addLocation[i].id}`, "");

        auxLocation.push({
          id: addLocation[i].id,
          name: pickupName === undefined ? "" : pickupName,
          group_id: 1,
          minutesPrior:
            pickupMinutes === undefined ? "" : Number(pickupMinutes),
          additionalInstructions:
            pickupInstructions === undefined ? "" : pickupInstructions,
          pickup_id_stranger: 10,
          address: local[addLocation[i].id] === undefined ? data[`address${i}`] : local[addLocation[i].id],
          latitude: latitude[addLocation[i].id] === undefined ? "" : latitude[addLocation[i].id],
          longitude: longetude[addLocation[i].id] === undefined ? "" : longetude[addLocation[i].id],
          status_edit: 0,
          posicao: controlPosition,
        });
        controlPosition += 1;
      }
    }

    data.compName = data.compName === undefined ? "" : data.compName;
    data.compFantasia =
      data.compFantasia === undefined ? "" : data.compFantasia;
    data.adcionalNotes =
      data.adcionalNotes === undefined ? "" : data.adcionalNotes;
    data.pickupClient = data.pickupClient === true ? 1 : 0;

    //console.log(data, auxLocation)
    //setLoading(true)

    //setModalContent("loading");

    const UpdatePickup = async () => {
      try {
        const res = await api.post(`Pickup/UpdatePickup`, {
          id: pickups.id,
          compName: data.compName,
          compFantasia: data.compFantasia.description,
          adcionalNotes: data.adcionalNotes,
          group_id: parseInt(data.compFantasia.id),
          pickupClient: data.pickupClient,
          user: `${userName} ${userLastName}`,
          list: auxLocation,
        });
        if (res.status !== 400) {
          setModalLog(res.data.data.log);
          setModalMessage(res.data.data.texto);
        }
      } catch (error: any) { }
    };

     UpdatePickup();    
  };

  useEffect(() => {
    const idEdit: any = window.location.href.split("?id=")[1];
    
    async function ListPickups() {
      try {
        const { data } = await api.post("Pickup/ListPickupLocation", {
          page: 0,
          rowsPerPage: 0,
          id: Number(idEdit),
        });

        if (data.status !== 400) {
          if (data.data !== null) {
            const arrAux: any = []
            setPickups(data.data.data);
            data.data.data.list.map((item:any)=>{
               arrAux.push(
                 item.name
              );  
              setLocal(arrAux)            
            })
          } else {   
             setModalShow(true);
             setModalMessage(data.texto);
             setInterval(()=>{
              setModalLog(1)
             }, 2000)    
            //window.location.href = '/pickup';           
          }
        }
      } catch (error: any) {

        if (error.response?.status === 401) {
          window.location.href = window.location.origin + "/";
        }
      }
    }
    ListPickups();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (pickups !== null) {
      let defineAddLocationSize: any = [];
      let defineDefaultLatitude: any = [];
      let defineDefaultLongitude: any = [];

      pickups?.list.forEach((elem: any, index: any) => {
        elem.id = index;
        elem.posicao = index;
        defineAddLocationSize.push(elem);
        defineDefaultLatitude.push(elem.latitude);
        defineDefaultLongitude.push(elem.longitude);
      });

      setAddLocation(defineAddLocationSize);
      setLatitude(defineDefaultLatitude);
      setLongitude(defineDefaultLongitude);

      setValue('compName', pickups?.compName);
      setValue('adcionalNotes', pickups?.adcionalNotes);
      setCompFantasiaAux(pickups?.compFantasia)

      pickups?.list.forEach((elem: any, index: any) => {
        setValue(`pickupName${index}`, elem.name);
        setValue(`search${index}`, elem.minutesPrior);
        setValue(`instruction${index}`, elem.additionalInstructions);
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pickups]);

  function Location(place: any, index: any) {
    local[index] = place;
     let aux: any = [...addLocation];
     aux[index].address = place;
     setAddLocation(aux)
  }

  function LocationLat(lat: any, index: any) {
    let aux:any = [...latitude] 
    latitude[index] = lat;
    aux.push(lat)
    setLatitude(aux)
    return latitude[index]
  }

  function LocationLng(lng: any, index: any) {
    let aux:any = [...longetude] 
    longetude[index] = lng;    
    aux.push(lng)
    setLongitude(aux)
    return longetude[index]
  }

  function pushLocation() {
    let aux: any = [
      {
        id: addLocation.length,
        name: "",
        group_id: groupId,
        minutesPrior: "",
        additionalInstructions: "",
        pickup_id_stranger: 10,
        address: "",
        latitude: 0,
        longitude: 0,
        status_edit: 0,
        posicao: addLocation.length,
      },
      ...addLocation,
    ];
    /* for (let i: any = 0; i < addLocation.length; i++) {
      const pickupName = watch(`pickupName${addLocation[i].id}`, "");
      const pickupMinutes = watch(`search${addLocation[i].id}`, "");
      const pickupInstructions = watch(`instruction${addLocation[i].id}`, "");
      
      aux.push({
        "id": addLocation[i].id,
        "name": pickupName === undefined ? "" : pickupName,
        "group_id": groupId,
        "minutesPrior": pickupMinutes === undefined ? "" : Number(pickupMinutes),
        "additionalInstructions": pickupInstructions === undefined ? "" : pickupInstructions,
        "pickup_id_stranger": 10,
        "address": local[i] === undefined ? "" : local[i],
        "latitude": latitude[i] === undefined ? "" : latitude[i],
        "longitude": longetude[i] === undefined ? "" : longetude[i],
        "status_edit": 0,
        "posicao": i
      });
    }

    aux.push({
      "id": addLocation.length,
      "name": "",
      "group_id": groupId,
      "minutesPrior": "",
      "additionalInstructions": "",
      "pickup_id_stranger": 10,
      "address": '',
      "latitude": 0,
      "longitude": 0,
      "status_edit": 0,
      "posicao": addLocation.length
    }); */

    setAddLocation(aux);
  }

  function handleChecked() {
    if (checked === false) {
      setChecked(true);
    } else {
      setChecked(false);
    }
  }

  /* const handleRemoveItem = useCallback(
      (index) => {
        setAddLocation(addLocation.slice(addLocation.indexOf(+1)));
      },
      [addLocation]
    ); */

  const handleRemoveItem = (index: any) => {
    setExcludedItems([...excludedItems, index]);
    let remove: any =
      document.querySelector(`#draggable-list-item-${index}`) || "";
    remove.style.display = "none";
  };

  function handleOnDragEnd(result: any) {
    if (!result.destination) return;

    const items = Array.from(addLocation);
    const [reorderedItem] = items.splice(result.source.index, 1);
    items.splice(result.destination.index, 0, reorderedItem);

    setAddLocation(items);
  }

  const reload = () => {
    if (modalLog === 0) {
      window.location.reload();
    }
  };

  if (pickups !== null) {
    
    return (
      <>
        <DesktopDefault>
          <div className="container-fluid content-edit-pickup">
            <Breadcrumb title={"Pickup / Editar Pickup"} />

            <div className="mt-4">

              <div className="d-flex align-items-center">
                <span className='back' onClick={() => { window.location.href = '/pickup' }}>
                  <FontAwesomeIcon
                    icon={["fal", "angle-double-left"]}
                    style={{ margin: "0 5px 0 0", fontSize: '16px' }}
                  />
                  Voltar</span>
              </div>
              <h2 className="title" style={{ color: "#707070" }}>Editar Pickup</h2>
            </div>

            <div className="accordion bg-white">
              <Container fluid>
                <Form
                  noValidate
                  validated={validated}
                  onSubmit={handleSubmit(onSubmit)}
                  autoComplete="false"
                >
                  <Row className="mb-3">
                    <Form.Group as={Col} lg="6" md="6">
                      <Form.Label>Nome do pickup:</Form.Label>
                      <Controller
                        control={control}
                        name="compName"
                        rules={{
                          required: {
                            value: true,
                            message: "Por favor, adicione o nome do pickup.",
                          },
                        }}
                        defaultValue={pickups?.compName}
                        render={({ field }: any) => (
                          <Form.Control
                            {...field}
                            type="text"
                            aria-invalid={errors?.compName ? "true" : ""}
                            variant="standard"
                            margin="normal"
                            autoComplete="off"
                          />
                        )}
                      />
                      <ErrorMessage
                        errors={errors}
                        name="compName"
                        render={({ message }) => (
                          <small style={{ color: "red" }}>{message}</small>
                        )}
                      />
                    </Form.Group>

                    <Form.Group as={Col} lg="6" md="6">
                      <Form.Label>Fornecedor responsável:</Form.Label>
                      <Controller
                        control={control}
                        name="compFantasia"
                        defaultValue={{
                          description: pickups.compFantasia,
                          id: pickups.pickupClient,
                        }}
                        rules={{
                          required: {
                            value: true,
                            message:
                              "Por favor, selecione o fornecedor responsável.",
                          },
                        }}
                        render={({ field }: any) => (
                          <div className="input-select-custom">
                            <GetSupplierGroupIdentity
                              propsField={field}
                              propsLabel={"supplier"}
                              propsErrors={errors}
                              compFantasiaAux={compFantasiaAux}
                              setValue={setValue}
                            //setFilterSupplier={setFilterSupplier}
                            />
                          </div>
                        )}
                      />
                      <ErrorMessage
                        errors={errors}
                        name="compFantasia"
                        render={({ message }) => (
                          <small style={{ color: "red" }}>{message}</small>
                        )}
                      />
                    </Form.Group>
                  </Row>

                  <Row className="mb-3">
                    <Form.Group as={Col} lg="6" md="6">
                      <Form.Label>Nota adicional (Opcional):</Form.Label>
                      <Controller
                        control={control}
                        name="adcionalNotes"
                        defaultValue={pickups?.adcionalNotes}
                        render={({ field }: any) => (
                          <Form.Control
                            {...field}
                            type="text"
                            variant="standard"
                            margin="normal"
                            autoComplete="off"
                          />
                        )}
                      />
                    </Form.Group>

                    {/* <Form.Group as={Col} lg="6" md="6">
                      <div className="d-flex">
                        <Controller
                          control={control}
                          name="pickupClient"
                          render={({ field }: any) => (
                            <Form.Check
                              {...field}
                              type="checkbox"
                              variant="standard"
                              margin="normal"
                              required
                              className="mr-2"
                              onClick={handleChecked}
                            />
                          )}
                        />
                        <Form.Label
                          onClick={() => {
                            console.log(addLocation);
                          }}
                        >
                          Permitir solicitações de pickup do cliente
                        </Form.Label>
                      </div>
                      {checked === true ? (
                        <Controller
                          control={control}
                          name="note"
                          render={({ field }: any) => (
                            <Form.Control
                              {...field}
                              type="text"
                              variant="standard"
                              margin="normal"
                              autoComplete="off"
                            />
                          )}
                        />
                      ) : (
                        <></>
                      )}
                    </Form.Group> */}
                  </Row>

                  <div className="border-bottom py-3"></div>

                  <Row>
                    <Form.Group
                      as={Col}
                      md="12"
                      lg="12"
                      className="d-flex justify-content-md-start mt-5 mb-3"
                    >
                      <Button className="btn-default" onClick={pushLocation}>
                        Adicionar Localização
                      </Button>
                    </Form.Group>
                  </Row>

                  <DragDropContext onDragEnd={handleOnDragEnd}>
                    <Droppable droppableId="addLocation">
                      {(provided: any) => (
                        <ul
                          className="addLocation react-draggable"
                          {...provided.droppableProps}
                          ref={provided.innerRef}
                        >
                          {addLocation
                            .filter((e: any) => !excludedItems.includes(e.id))
                            .map((elem: any, index: any) => {
                              return (
                                <Draggable
                                  key={elem.posicao}
                                  draggableId={elem.posicao.toString()}
                                  index={index}
                                >
                                  {(provided: any) => (
                                    <li
                                      ref={provided.innerRef}
                                      {...provided.draggableProps}
                                      {...provided.dragHandleProps}
                                      id={`draggable-list-item-${elem.posicao}`}
                                    >
                                      <Container className="px-0" fluid>
                                        <Row className='justify-content-evenly bg-white'>
                                          <Form.Group as={Col} lg="1" md="1" style={{ padding: '0px', maxWidth: '55px' }}>
                                            <div
                                              className="d-flex justify-content-center align-items-center h-100"
                                              style={{ backgroundColor: "#f2f2f2", cursor: "move", position: 'relative' }}
                                            >
                                              <span style={{ position: 'absolute', top: '25%' }}>{index + 1}</span>
                                              <FontAwesomeIcon icon={["fal", "bars"]} />
                                            </div>
                                          </Form.Group>

                                          <Form.Group as={Col} lg="10" md="10">
                                            <Row className="mb-3">
                                              <Form.Group
                                                as={Col}
                                                lg="4"
                                                md="4"
                                              >
                                                <Form.Label>
                                                  Local
                                                  <OverlayTrigger
                                                    overlay={
                                                      <Tooltip id="tooltip">
                                                        Digite o nome do hotel
                                                        ou da localização
                                                      </Tooltip>
                                                    }
                                                  >
                                                    <span>
                                                      <FontAwesomeIcon
                                                        className="ml-2"
                                                        icon={[
                                                          "fal",
                                                          "question-circle",
                                                        ]}
                                                      />
                                                    </span>
                                                  </OverlayTrigger>
                                                </Form.Label>
                                                <Controller
                                                  control={control}
                                                  name={`pickupName${elem.posicao}`}
                                                  render={({ field }: any) => (
                                                    <Form.Control
                                                      {...field}
                                                      type="text"
                                                      variant="standard"
                                                      margin="normal"
                                                      autoComplete="off"
                                                    />
                                                  )}
                                                />
                                              </Form.Group>

                                              <Form.Group
                                                as={Col}
                                                lg="4"
                                                md="4"
                                              >
                                                <Form.Label>
                                                  Endereço
                                                  <OverlayTrigger
                                                    overlay={
                                                      <Tooltip id="tooltip">
                                                        Pesquise e selecione
                                                        usando os resultados do
                                                        endereço do Google.
                                                      </Tooltip>
                                                    }
                                                  >
                                                    <span>
                                                      <FontAwesomeIcon
                                                        className="ml-2"
                                                        icon={[
                                                          "fal",
                                                          "question-circle",
                                                        ]}
                                                      />
                                                    </span>
                                                  </OverlayTrigger>
                                                </Form.Label>
                                                <Controller
                                                  control={control}
                                                  name={`address${elem.posicao}`}
                                                  render={({ field }: any) => (
                                                    <AutoComplete
                                                      options={{
                                                        types: [
                                                          "establishment",
                                                        ],
                                                        fields: ["name"],
                                                      }}
                                                      defaultValue={
                                                        elem.address
                                                      }
                                                      placeholder=""
                                                      apiKey="AIzaSyBv2aZ2YO_aW4PIEmXoxHgxC8Ps8DB0o-s"
                                                      onPlaceSelected={(
                                                        place
                                                      ) => {
                                                        Location(
                                                          place.name,
                                                          elem.posicao
                                                        );
                                                        /* handleChangePickupAddresses(place.name, elem.id) */
                                                        // eslint-disable-next-line no-lone-blocks
                                                        {
                                                          geocodeByAddress(
                                                            `${place.name}`
                                                          )
                                                            .then((results) =>
                                                              getLatLng(
                                                                results[0]
                                                              )
                                                            )
                                                            .then(
                                                              ({
                                                                lat,
                                                                lng,
                                                              }) => {
                                                                LocationLat(
                                                                  lat,
                                                                  elem.posicao
                                                                );
                                                                LocationLng(
                                                                  lng,
                                                                  elem.posicao
                                                                );
                                                              }
                                                            );
                                                        }
                                                      }}
                                                    />
                                                  )}
                                                />
                                              </Form.Group>

                                              <Form.Group
                                                as={Col}
                                                lg="4"
                                                md="4"
                                              >
                                                <Form.Label>
                                                  Antecedência de busca
                                                  <OverlayTrigger
                                                    overlay={
                                                      <Tooltip id="tooltip">
                                                        Digite quando seus
                                                        clientes serão
                                                        apanhados. Exemplo:30
                                                        minutos antes do horário
                                                        de início do tour.
                                                      </Tooltip>
                                                    }
                                                  >
                                                    <span>
                                                      <FontAwesomeIcon
                                                        className="ml-2"
                                                        icon={[
                                                          "fal",
                                                          "question-circle",
                                                        ]}
                                                      />
                                                    </span>
                                                  </OverlayTrigger>
                                                </Form.Label>
                                                <Controller
                                                  control={control}
                                                  name={`search${elem.posicao}`}
                                                  render={({ field }: any) => (
                                                    <Form.Control
                                                      {...field}
                                                      type="text"
                                                      variant="standard"
                                                      margin="normal"
                                                      autoComplete="off"
                                                      onKeyPress={(e) =>
                                                        !/[0-9]/.test(e.key) &&
                                                        e.preventDefault()
                                                      }
                                                    />
                                                  )}
                                                />
                                              </Form.Group>
                                            </Row>

                                            <Row>
                                              <Form.Group
                                                as={Col}
                                                lg="12"
                                                md="12"
                                              >
                                                <Form.Label>
                                                  Instruções Adicionais
                                                  <OverlayTrigger
                                                    overlay={
                                                      <Tooltip id="tooltip">
                                                        Adicione instruções do
                                                        cliente para este local
                                                        de retirada. Exemplo:
                                                        encontre-se no foyer do
                                                        hotel 15 minutos antes
                                                        do horário de embarque.
                                                      </Tooltip>
                                                    }
                                                  >
                                                    <span>
                                                      <FontAwesomeIcon
                                                        className="ml-2"
                                                        icon={[
                                                          "fal",
                                                          "question-circle",
                                                        ]}
                                                      />
                                                    </span>
                                                  </OverlayTrigger>
                                                </Form.Label>
                                                <Controller
                                                  control={control}
                                                  name={`instruction${elem.posicao}`}
                                                  render={({ field }: any) => (
                                                    <Form.Control
                                                      {...field}
                                                      type="text"
                                                      variant="standard"
                                                      margin="normal"
                                                      autoComplete="off"
                                                    />
                                                  )}
                                                />
                                              </Form.Group>
                                            </Row>
                                          </Form.Group>

                                          <Form.Group as={Col} lg="1" md="1">
                                            {index !== 0 ? (
                                              <div
                                                className="d-flex justify-content-center pt-2 h-100 btn bg-transparent border-0 text-primary"
                                                onClick={() =>
                                                  handleRemoveItem(elem.id)
                                                }
                                              >
                                                <FontAwesomeIcon
                                                  icon={["fal", "trash-alt"]}
                                                />
                                              </div>
                                            ) : (
                                              <></>
                                            )}
                                          </Form.Group>
                                        </Row>
                                      </Container>
                                    </li>
                                  )}
                                </Draggable>
                              );
                            })}
                          {provided.placeholder}
                        </ul>
                      )}
                    </Droppable>
                  </DragDropContext>

                  <Row>
                    <Form.Group
                      as={Col}
                      md="12"
                      lg="12"
                      className="d-flex justify-content-md-end mt-5 mb-3"
                    >
                      <Button
                        variant="outline-primary"
                        className="btn-default mr-2 text-uppercase"
                      >
                        Cancelar
                      </Button>
                      <Button
                        type="submit"
                        className="btn-default text-uppercase"
                      >
                        Salvar
                      </Button>
                    </Form.Group>
                  </Row>
                </Form>
              </Container>
            </div>
          </div>

          <Modal
            className="modalAuth"
            show={modalShow}
            onHide={() => {
              reload();
              setModalLog(null);
              setModalMessage("Carregando");
              setModalShow(false);
            }}
            aria-labelledby="contained-modal-title-vcenter"
          >
            <>
              <Modal.Body
                className="modal-body text-center sucess-pos d-flex justify-content-evenly"
                style={{ height: "600px" }}
              >
                {modalLog === null ? (
                  <>
                    <div className="loading-modal">
                      <div className="load"></div>
                    </div>
                  </>
                ) : (
                  <div>
                    {modalLog === 0 ? (
                      <FontAwesomeIcon
                        icon={["fal", "check-circle"]}
                        size="5x"
                        className="text-primary"
                        style={{ fontSize: "7.5em"}}
                      />
                    ) : (
                      <FontAwesomeIcon
                        icon={["fal", "times-circle"]}
                        size="5x"
                        className="text-primary"
                        style={{ fontSize: "7.5em"}}
                      />
                    )}
                  </div>
                )}
                <div>{modalMessage}</div>
                <div className="d-flex justify-content-center pt-3">
                  <button
                    onClick={() => {
                      reload();
                      setModalLog(null);
                      setModalMessage("Carregando");
                      setModalShow(false);
                    }}
                    className="btn btn-primary mx-2 w-25"
                  >
                    Confirmar
                  </button>
                </div>
              </Modal.Body>
            </>
          </Modal>
        </DesktopDefault>
      </>
    );
  } else {
    return (
      <>
        <DesktopDefault>
          <div className="container-fluid content-edit-pickup">
            <Breadcrumb title={"Pickup / Editar Pickup"} />

            <div className="mt-4">

              <div className="d-flex align-items-center">
                <span className='back' onClick={() => { window.location.href = '/pickup' }}>
                  <FontAwesomeIcon
                    icon={["fal", "angle-double-left"]}
                    style={{ margin: "0 5px 0 0", fontSize: '16px' }}
                  />
                  Voltar</span>
              </div>
              <h2 className="title" style={{ color: "#707070" }}>Editar Pickup</h2>
            </div>

            <div className="accordion bg-white">
              <Container fluid>
                <Form
                  noValidate
                  validated={validated}
                  onSubmit={handleSubmit(onSubmit)}
                  autoComplete="false"
                >
                  <Row className="mb-3">
                    <Form.Group as={Col} lg="6" md="6">
                      <Form.Label>Nome do pickup:</Form.Label>
                      <Controller
                        control={control}
                        name="compName"
                        rules={{
                          required: {
                            value: true,
                            message: "Por favor, adicione o nome do pickup.",
                          },
                        }}
                        defaultValue={pickups?.compName}
                        render={({ field }: any) => (
                          <Form.Control
                            {...field}                            
                            type="text"
                            aria-invalid={errors?.compName ? "true" : ""}
                            variant="standard"
                            margin="normal"
                            autoComplete="off"
                          />
                        )}
                      />
                      <ErrorMessage
                        errors={errors}
                        name="compName"
                        render={({ message }) => (
                          <small style={{ color: "red" }}>{message}</small>
                        )}
                      />
                    </Form.Group>

                    <Form.Group as={Col} lg="6" md="6">
                      <Form.Label>Fornecedor responsável:</Form.Label>
                      <Controller
                        control={control}
                        name="compFantasia"
                        defaultValue={{
                          description: pickups?.compFantasia,
                          id: pickups?.pickupClient,
                        }}
                        rules={{
                          required: {
                            value: true,
                            message:
                              "Por favor, selecione o fornecedor responsável.",
                          },
                        }}
                        render={({ field }: any) => (
                          <div className="input-select-custom">
                            <GetSupplierSupplierIdentity
                              propsField={field}
                              propsLabel={"supplier"}
                              propsErrors={errors}
                            //setFilterSupplier={setFilterSupplier}
                            />
                          </div>
                        )}
                      />
                      <ErrorMessage
                        errors={errors}
                        name="compFantasia"
                        render={({ message }) => (
                          <small style={{ color: "red" }}>{message}</small>
                        )}
                      />
                    </Form.Group>
                  </Row>

                  <Row className="mb-3">
                    <Form.Group as={Col} lg="6" md="6">
                      <Form.Label>Nota adicional (Opcional):</Form.Label>
                      <Controller
                        control={control}
                        name="adcionalNotes"
                        defaultValue={pickups?.adcionalNotes}
                        render={({ field }: any) => (
                          <Form.Control
                            {...field}
                            type="text"
                            variant="standard"
                            margin="normal"
                            autoComplete="off"
                          />
                        )}
                      />
                    </Form.Group>

                    <Form.Group as={Col} lg="6" md="6">
                      <div className="d-flex">
                        <Controller
                          control={control}
                          name="pickupClient"
                          render={({ field }: any) => (
                            <Form.Check
                              {...field}
                              type="checkbox"
                              variant="standard"
                              margin="normal"
                              required
                              className="mr-2"
                              onClick={handleChecked}
                            />
                          )}
                        />
                        <Form.Label
                          onClick={() => {
                            console.log(addLocation);
                          }}
                        >
                          Permitir solicitações de pickup do cliente
                        </Form.Label>
                      </div>
                      {checked === true ? (
                        <Controller
                          control={control}
                          name="note"
                          render={({ field }: any) => (
                            <Form.Control
                              {...field}
                              type="text"
                              variant="standard"
                              margin="normal"
                              autoComplete="off"
                            />
                          )}
                        />
                      ) : (
                        <></>
                      )}
                    </Form.Group>
                  </Row>

                  <div className="border-bottom py-3"></div>

                  <Row>
                    <Form.Group
                      as={Col}
                      md="12"
                      lg="12"
                      className="d-flex justify-content-md-start mt-5 mb-3"
                    >
                      <Button className="btn-default" onClick={pushLocation}>
                        Adicionar Localização
                      </Button>
                    </Form.Group>
                  </Row>

                  <DragDropContext onDragEnd={handleOnDragEnd}>
                    <Droppable droppableId="addLocation">
                      {(provided: any) => (
                        <ul
                          className="addLocation react-draggable"
                          {...provided.droppableProps}
                          ref={provided.innerRef}
                        >
                          {addLocation
                            .filter((e: any) => !excludedItems.includes(e.id))
                            .map((elem: any, index: any) => {
                              return (
                                <Draggable
                                  key={elem.posicao}
                                  draggableId={elem.posicao.toString()}
                                  index={index}
                                >
                                  {(provided: any) => (
                                    <li
                                      ref={provided.innerRef}
                                      {...provided.draggableProps}
                                      {...provided.dragHandleProps}
                                      id={`draggable-list-item-${elem.posicao}`}
                                    >
                                      <Container className="px-0" fluid>
                                        <Row className='justify-content-evenly bg-white'>
                                          <Form.Group as={Col} lg="1" md="1" style={{ padding: '0px', maxWidth: '55px' }}>
                                            <div
                                              className="d-flex justify-content-center align-items-center h-100"
                                              style={{ backgroundColor: "#f2f2f2", cursor: "move", position: 'relative' }}
                                            >
                                              <span style={{ position: 'absolute', top: '25%' }}>{index + 1}</span>
                                              <FontAwesomeIcon icon={["fal", "bars"]} />
                                            </div>
                                          </Form.Group>

                                          <Form.Group as={Col} lg="10" md="10">
                                            <Row className="mb-3">
                                              <Form.Group
                                                as={Col}
                                                lg="4"
                                                md="4"
                                              >
                                                <Form.Label>
                                                  Local
                                                  <OverlayTrigger
                                                    overlay={
                                                      <Tooltip id="tooltip">
                                                        Digite o nome do hotel
                                                        ou da localização
                                                      </Tooltip>
                                                    }
                                                  >
                                                    <span>
                                                      <FontAwesomeIcon
                                                        className="ml-2"
                                                        icon={[
                                                          "fal",
                                                          "question-circle",
                                                        ]}
                                                      />
                                                    </span>
                                                  </OverlayTrigger>
                                                </Form.Label>
                                                <Controller
                                                  control={control}
                                                  name={`pickupName${elem.posicao}`}
                                                  render={({ field }: any) => (
                                                    <Form.Control
                                                      {...field}
                                                      type="text"
                                                      variant="standard"
                                                      margin="normal"
                                                      autoComplete="off"
                                                    />
                                                  )}
                                                />
                                              </Form.Group>

                                              <Form.Group
                                                as={Col}
                                                lg="4"
                                                md="4"
                                              >
                                                <Form.Label>
                                                  Endereço
                                                  <OverlayTrigger
                                                    overlay={
                                                      <Tooltip id="tooltip">
                                                        Pesquise e selecione
                                                        usando os resultados do
                                                        endereço do Google.
                                                      </Tooltip>
                                                    }
                                                  >
                                                    <span>
                                                      <FontAwesomeIcon
                                                        className="ml-2"
                                                        icon={[
                                                          "fal",
                                                          "question-circle",
                                                        ]}
                                                      />
                                                    </span>
                                                  </OverlayTrigger>
                                                </Form.Label>
                                                <Controller
                                                  control={control}
                                                  name={`address${elem.posicao}`}
                                                  render={({ field }: any) => (
                                                    <AutoComplete
                                                      options={{
                                                        types: [
                                                          "establishment",
                                                        ],
                                                        fields: ["name"],
                                                      }}
                                                      defaultValue={
                                                        elem.address
                                                      }
                                                      placeholder=""
                                                      apiKey="AIzaSyBv2aZ2YO_aW4PIEmXoxHgxC8Ps8DB0o-s"
                                                      onPlaceSelected={(
                                                        place
                                                      ) => {
                                                        Location(
                                                          place.name,
                                                          elem.posicao
                                                        );
                                                        /* handleChangePickupAddresses(place.name, elem.id) */
                                                        // eslint-disable-next-line no-lone-blocks
                                                        {
                                                          geocodeByAddress(
                                                            `${place.name}`
                                                          )
                                                            .then((results) =>
                                                              getLatLng(
                                                                results[0]
                                                              )
                                                            )
                                                            .then(
                                                              ({
                                                                lat,
                                                                lng,
                                                              }) => {
                                                                LocationLat(
                                                                  lat,
                                                                  elem.posicao
                                                                );
                                                                LocationLng(
                                                                  lng,
                                                                  elem.posicao
                                                                );
                                                              }
                                                            );
                                                        }
                                                      }}
                                                    />
                                                  )}
                                                />
                                              </Form.Group>

                                              <Form.Group
                                                as={Col}
                                                lg="4"
                                                md="4"
                                              >
                                                <Form.Label>
                                                  Antecedência de busca
                                                  <OverlayTrigger
                                                    overlay={
                                                      <Tooltip id="tooltip">
                                                        Digite quando seus
                                                        clientes serão
                                                        apanhados. Exemplo:30
                                                        minutos antes do horário
                                                        de início do tour.
                                                      </Tooltip>
                                                    }
                                                  >
                                                    <span>
                                                      <FontAwesomeIcon
                                                        className="ml-2"
                                                        icon={[
                                                          "fal",
                                                          "question-circle",
                                                        ]}
                                                      />
                                                    </span>
                                                  </OverlayTrigger>
                                                </Form.Label>
                                                <Controller
                                                  control={control}
                                                  name={`search${elem.posicao}`}
                                                  render={({ field }: any) => (
                                                    <Form.Control
                                                      {...field}
                                                      type="text"
                                                      variant="standard"
                                                      margin="normal"
                                                      autoComplete="off"
                                                      onKeyPress={(e) =>
                                                        !/[0-9]/.test(e.key) &&
                                                        e.preventDefault()
                                                      }
                                                    />
                                                  )}
                                                />
                                              </Form.Group>
                                            </Row>

                                            <Row>
                                              <Form.Group
                                                as={Col}
                                                lg="12"
                                                md="12"
                                              >
                                                <Form.Label>
                                                  Instruções Adicionais
                                                  <OverlayTrigger
                                                    overlay={
                                                      <Tooltip id="tooltip">
                                                        Adicione instruções do
                                                        cliente para este local
                                                        de retirada. Exemplo:
                                                        encontre-se no foyer do
                                                        hotel 15 minutos antes
                                                        do horário de embarque.
                                                      </Tooltip>
                                                    }
                                                  >
                                                    <span>
                                                      <FontAwesomeIcon
                                                        className="ml-2"
                                                        icon={[
                                                          "fal",
                                                          "question-circle",
                                                        ]}
                                                      />
                                                    </span>
                                                  </OverlayTrigger>
                                                </Form.Label>
                                                <Controller
                                                  control={control}
                                                  name={`instruction${elem.posicao}`}
                                                  render={({ field }: any) => (
                                                    <Form.Control
                                                      {...field}
                                                      type="text"
                                                      variant="standard"
                                                      margin="normal"
                                                      autoComplete="off"
                                                    />
                                                  )}
                                                />
                                              </Form.Group>
                                            </Row>
                                          </Form.Group>

                                          <Form.Group as={Col} lg="1" md="1">
                                            {index !== 0 ? (
                                              <div
                                                className="d-flex justify-content-center pt-2 h-100 btn bg-transparent border-0 text-primary"
                                                onClick={() =>
                                                  handleRemoveItem(elem.id)
                                                }
                                              >
                                                <FontAwesomeIcon
                                                  icon={["fal", "trash-alt"]}
                                                />
                                              </div>
                                            ) : (
                                              <></>
                                            )}
                                          </Form.Group>
                                        </Row>
                                      </Container>
                                    </li>
                                  )}
                                </Draggable>
                              );
                            })}
                          {provided.placeholder}
                        </ul>
                      )}
                    </Droppable>
                  </DragDropContext>

                  <Row>
                    <Form.Group
                      as={Col}
                      md="12"
                      lg="12"
                      className="d-flex justify-content-md-end mt-5 mb-3"
                    >
                      <Button
                        variant="outline-primary"
                        className="btn-default mr-2 text-uppercase"
                      >
                        Cancelar
                      </Button>
                      <Button
                        type="submit"
                        className="btn-default text-uppercase"
                      >
                        Salvar
                      </Button>
                    </Form.Group>
                  </Row>
                </Form>
              </Container>
            </div>
          </div>

          <Modal
            className="modalAuth"
            show={modalShow}
            onHide={() => {
              reload();
              setModalLog(null);
              setModalMessage("Carregando");
              setModalShow(false);
              window.location.href = '/pickup';
            }}
            aria-labelledby="contained-modal-title-vcenter"
          >
            <>
              <Modal.Body
                className="modal-body text-center sucess-pos d-flex justify-content-evenly"
                style={{ height: "600px" }}
              >
                {modalLog === null ? (
                  <>
                    <div className="loading-modal">
                      <div className="load"></div>
                    </div>
                  </>
                ) : (
                  <div>
                    {modalLog === 0 ? (
                      <FontAwesomeIcon
                        icon={["fal", "check-circle"]}
                        size="5x"
                        className="text-primary"
                        style={{ fontSize: "7.5em"}}
                      />
                    ) : (
                      <FontAwesomeIcon
                        icon={["fal", "times-circle"]}
                        size="5x"
                        className="text-primary"
                        style={{ fontSize: "7.5em"}}
                      />
                    )}
                  </div>
                )}
                <div>{modalMessage}</div>
                <div className="d-flex justify-content-center pt-3">
                  <button
                    onClick={() => {
                      reload();
                      setModalLog(null);
                      setModalMessage("Carregando");
                      setModalShow(false);
                      window.location.href = '/pickup';
                    }}
                    className="btn btn-primary mx-2 w-25"
                  >
                    Confirmar
                  </button>
                </div>
              </Modal.Body>
            </>
          </Modal>
        </DesktopDefault>
      </>
    );
  }
};

export default EditPickup;
