import React from 'react';
import { Accordion, Container, Row, Col, Form, Button } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useTranslation } from 'react-i18next';
import { Controller } from 'react-hook-form';

export interface propsFilter {
    buscar: any;
    control: any;
    getValues: any;
    setValue: any;
    channels: any;
}

const SourceFilter: React.FC<propsFilter> = ({
    buscar, control, getValues, setValue, channels
}: propsFilter) => {
    const { t } = useTranslation();

    const handleClearClick = () => {
        setValue('sourceCode', '');
        setValue('idSalesChannel', 0);
        buscar(true);
    };

    const handleFilterSubmit = () => {
        let data: any = getValues();
        buscar(true, data);
    };

    return (
        <div
            className="suppliers-filter d-flex justify-content-center bg-white mt-4"
            style={{ textAlign: 'left' }}
        >
            <Accordion defaultActiveKey="1">
                <Accordion.Item eventKey="0">
                    <Accordion.Header>
                        <FontAwesomeIcon
                            icon={['fal', 'sliders-h']}
                            size="lg"
                            className="mr-2"
                        />
                        <span className="h5 mb-0">Buscar Sources</span>
                    </Accordion.Header>
                    <Accordion.Body className="pt-0">
                        <Container className="p-0" fluid>
                            <Form
                                noValidate
                                validated={false}
                            >
                                <Row>
                                    <div>
                                        <Form>
                                            <Row className="mb-3">
                                            <Form.Group as={Col} md="3">
                                                    <Form.Label>Nome do Source:</Form.Label>
                                                    <Controller
                                                        control={control}
                                                        name="sourceCode"
                                                        defaultValue={''}
                                                        render={({ field }: any) => (
                                                            <Form.Control
                                                                {...field}
                                                                type="text"
                                                                variant="standard"
                                                                margin="normal"
                                                                autoComplete="off"
                                                            />
                                                        )}
                                                    />
                                                </Form.Group>

                                                <Form.Group as={Col} md="3">
                                                    <Form.Label>Canal:</Form.Label>
                                                    <Controller
                                                        control={control}
                                                        name="idSalesChannel"
                                                        rules={{ required: { value: false, message: `Favor, preencher esse campo` } }}
                                                        defaultValue={''}
                                                        render={({ field }: any) => (
                                                            <div className='endpoint'>
                                                                <Form.Select
                                                                    {...field}
                                                                    as="select"
                                                                    variant="standard"
                                                                    margin="normal"
                                                                >
                                                                    <option value='0'>Todos</option>
                                                                    {channels?.map((elem: any) => {
                                                                        return (
                                                                            <option value={elem.idSalesChannel}>{elem.salesChannelName}</option>
                                                                        )
                                                                    })}
                                                                </Form.Select>
                                                            </div>
                                                        )}
                                                    />
                                                </Form.Group>
                                                
                                            </Row>
                                            <Row as={Col} md="12" className="mt-5 justify-content-md-end">
                                                <Col xs lg="12" className="d-flex justify-content-md-end">
                                                    <Button
                                                        variant="outline-primary"
                                                        className="btn-default mr-2 text-uppercase"
                                                        onClick={() => { handleClearClick() }}
                                                    >
                                                        {t('reservations.filter.btnClear')}
                                                    </Button>
                                                    <Button
                                                        className="btn-default text-uppercase"
                                                        type="button"
                                                        onClick={() => { handleFilterSubmit() }}
                                                    >
                                                        {t('reservations.filter.btnSearch')}
                                                    </Button>
                                                </Col>
                                            </Row>
                                        </Form>
                                    </div>
                                </Row>
                            </Form>
                        </Container>
                    </Accordion.Body>
                </Accordion.Item>
            </Accordion>
        </div>
    );
};

export default SourceFilter;
