import React from "react";
import { Container, Row, Col } from "react-bootstrap";

import "../../../../../assets/sass/modal.scss";
import "./FormSessionObservationEdit.scss";

//import Modal from "react-bootstrap/Modal";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import { Controller, useForm } from "react-hook-form";
import Modal from "react-bootstrap/Modal";

//import IconSucess from "../../../assets/icons/modal-sucess.svg";

import api from '../../../../../services/api';

export interface propModal {
  action: any,
  info: any,
  setModalSuccess: any, 
  setResponseText: any, 
  setModalContent: any, 
  setLog: any
};

const FormSessionObservationEdit: React.FC<propModal> = ({
  action, info
  , setModalSuccess, setResponseText, setModalContent, setLog
}: propModal, props: any) => {
  //const [tipo, setTipo] = useState<string>(tipo);

  const {
    control,
    handleSubmit,
  } = useForm({
    defaultValues: {
      observation: info?.observation ? info.observation : '',
    },
  });

  const handleSaveClick = (dataObj: any) => {


    const token = localStorage.getItem('GroupId') || '';

    const config = {
        headers: { 'Authorization': `Bearer ${token}` },
    };

    const sessionId = info.id;
    const sessionType = (info.tipo === 'Tour') ? 1 : 2;

    const salvar = async () => {
      setModalContent("loading");
      setModalSuccess(true);
        try {

            const { data } = await api.post("SessionClosure/UpdateSessionClosureObservation", {
              "sessionId": sessionId,
              "sessionType": sessionType,
              "observation": dataObj.observation
            }, config);
            /* console.log(data.data); */
            if (data.status !== 400) {
                var response = data.data;

                //alert(response.texto);
                setResponseText(response.texto);
                setLog(response.log);

                if(response.log !== 1) {
                  setModalContent("success");
                  action(dataObj);
                } else {
                  setModalContent("error");
                }
            }   
        }catch(error: any) {
          setModalContent("error");
          setLog(1);
            if (error?.response?.status === 401) {
                window.location.href = window.location.origin + '/';              
            }
            if (error?.response?.status === 400) {
                //window.location.href = window.location.origin + '/session-closure';              
            }
            //;
        }
    }
    salvar();
  };

  return (
    <>
      <Modal.Body>
        <Container className="bg-modal-contact p-0" fluid>
          <Row>
            <h5 className="contact-title mb-5">Alterar Observação</h5>
            <Form
              className="form-add-contact"
              noValidate
            >
              <div className="">
                <Row className="mb-3">
                    <Form.Group as={Col} md="12">
                      <Form.Label>Observação</Form.Label>
                      <Controller
                        control={control}
                        name="observation"
                        render={({ field }: any) => (
                          <Form.Control type="text"
                            {...field}
                            autoComplete='off'
                            variant="standard"
                            
                            margin="normal"
                            required />
                        )}
                      />
                    </Form.Group>
                </Row>
              </div>
            </Form>
          </Row>
        </Container>
      </Modal.Body>

      <Modal.Footer>
        <Row className="buttons-modal d-flex justify-content-end mx-0">
          {/* <Button className="form-button mr-3" onClick={props.onHide}>
            Cancelar
          </Button> */}
          <Button
            type="button"
            className="form-button"
            onClick={handleSubmit(handleSaveClick)}
          >
            <FontAwesomeIcon
              icon={["fal", "save"]}
              size="1x"
              style={{ marginRight: "5px" }}
            /> Salvar
          </Button>
        </Row>
      </Modal.Footer>


    </>
  );
}

export default FormSessionObservationEdit;
