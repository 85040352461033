import React, { useState } from 'react';

import { Container, Row, Col, Card } from 'react-bootstrap';
import Accordion from 'react-bootstrap/Accordion';
import Form from 'react-bootstrap/Form';
import { Controller, useForm } from "react-hook-form";
//import InputMask from "react-input-mask";
import Button from 'react-bootstrap/Button';

import { ErrorMessage } from '@hookform/error-message';
import { useTranslation } from 'react-i18next';

import '../../../../assets/sass/accordion.scss';
import GetSuppliersFilter from '../../../../components/C2Points/GetSuppliersInsert';

import api from '../../../../services/api';
import GetProductsAndGroupsList from '../../../../components/C2Points/GetProductsAndGroupsList';
import GetSupplierTariffCategories from '../../../../components/C2Points/GetSupplierTariffCategories';
import GetSupplierResources from '../../../../components/C2Points/GetSupplierResources';
import SupplierTariffBlocks from '../SupplierTariffBlocks/SupplierTariffBlocks';
import GetSupplierTariffVehicleType from '../../../../components/C2Points/GetSupplierTariffVehicleType';

export interface propAccountsForm {
    action: any,
    info: any,
};

function SuppliersTariffForm({
    action, info
}: propAccountsForm) {

    const { t } = useTranslation();

    const [type, setType] = useState<any>(info ? `${info.tipo}` : "1");
    const [product, setProduct] = useState<any>(info ? info.productCode : "");
    const [resource, setResource] = useState<any>(info ? info.resourceId : "");
    const [supplier, setSupplier] = useState<any>(info ? info.supplierId : "");
    const [prePos, setPrePos] = useState<any>(info ? `${info.prePos}` : "");
    const [pessoaRecurso, setPessoaRecurso] = useState<any>(info ? `${info.pessoaRecurso}` : "");
    const [guiaAltera, setGuiaAltera] = useState<any>(info ? `${info.guiaAltera}` : "");
    const [vehicleType, setVehicleType] = useState<any>(info ? info.idVeiculo : "");
    const [category, setCategory] = useState<any>(info ? info.idCategoria : "");

    const [blocks, setBlocks] = useState<any>(info ? info.blocks : []);
    const [blocksDeleted, setBlocksDeleted] = useState<any>([]);
    const getBlocks = (objPosDevices: any, deletedObjects: any) => {
        setBlocks(objPosDevices);
        setBlocksDeleted(deletedObjects);
    }
    const [itemsDeleted, setItemsDeleted] = useState<any>([]);

    const [blockBlocked,] = useState<boolean>(false);

    const {
        control,
        handleSubmit,
        formState: { errors },
    } = useForm();
    // {
    //     defaultValues: {
    //         id: info ? info.id : 0,
    //         nomeFantasia: info ? info.nomeFantasia : '',
    //         razaoSocial: info ? info.razaoSocial : '',
    //         cnpj: info && (info.cnpj !== null) ? info.cnpj : '',
    //         nomeGerente: info && (info.nomeGerente !== null) ? info.nomeGerente : '',
    //         numeroConta: info && (info.numeroConta !== null) ? info.numeroConta : '',
    //         agenciaConta: info && (info.agenciaConta !== null) ? info.agenciaConta : '',
    //         email: info && (info.email !== null) ? info.email : '',
    //         telefone: info && (info.telefone !== null) ? info.telefone : '',
    //         tipo: info ? info.tipo : '',
    //         status: info ? info.status : '1',
    //         observacao: info ? info.observacao : '',
    //     },
    // });

    const strToFloat = (str: string): any => {
        if(!str || str === '') return 0.0;
        str = '' + str;
        return parseFloat(str.replace(/[^0-9,]*/g, '').replace(',', '.'));
      }
      const strToInt = (str: string): any => {
        if(!str || str === '') return 0;
        return parseInt(str);
      }

    const onSubmit = (dataObj: any) => {
        var route = '';
        
        const blocksObj: any = [];

        for(let i = 0; i < blocks.length; i++) {
            const block: any = {
                id: blocks[i].id,
                validadeInicio: blocks[i].validadeInicio,
                validadeFim: blocks[i].validadeFim,
                unicoDiferenciado: strToInt(blocks[i].unicoDiferenciado),
                porDiaSemana: strToInt(blocks[i].porDiaSemana),
                porPax: strToInt(blocks[i].porPax),
                valor: blocks[i].valor ? strToFloat(blocks[i].valor) : 0
            };

            const items: any = [];

            for(let j = 0; j < blocks[i].items.length; j++) {
                const itemObj = {
                    id: blocks[i].items[j].id,
                    domingo: blocks[i].items[j].domingo,
                    segunda: blocks[i].items[j].segunda,
                    terca: blocks[i].items[j].terca,
                    quarta: blocks[i].items[j].quarta,
                    quinta: blocks[i].items[j].quinta,
                    sexta: blocks[i].items[j].sexta,
                    sabado: blocks[i].items[j].sabado,
                    faixaMin: strToInt(blocks[i].items[j].faixaMin),
                    faixaMax: strToInt(blocks[i].items[j].faixaMax),
                    valor: strToFloat(blocks[i].items[j].valor),
                }
                items.push(itemObj);
            }
            block.items = items;

            blocksObj.push(block);
        }

        var params = {
            id: info ? info.id : 0,
            tipo: strToInt(type),
            productCode: product ? product : '',
            resourceId: resource ? strToInt(resource) : 0,
            pessoaRecurso: strToInt(pessoaRecurso),
            prePos: strToInt(prePos),
            guiaAltera: strToInt(guiaAltera),
            supplierId: supplier ? strToInt(supplier) : 0,
            idVeiculo: vehicleType ? strToInt(vehicleType) : 0,
            idCategoria: category ? strToInt(category) : 0,
            blocks: blocksObj,
            blocksDeleted: blocksDeleted,
            itemsDeleted: itemsDeleted
        };

        //TODO
        console.log(params, "objeto da conta");
        //return;

        if (action === 1) {
            //console.log('insert');
            route = 'InsertSupplierTariffAsync';
        } else {
            //console.log('edit');
            route = 'UpdateSupplierTariffAsync';
        }

        const token = localStorage.getItem('GroupId') || '{}';
        const config = {
            headers: { 'Authorization': `Bearer ${token}` },
        };

        const EditAccounts = async () => {
            try {

                const { data } = await api.post(`SupplierTariff/${route}`, params, config);
                /* console.log(data.data); */
                if (data.status !== 400) {
                    var response = data.data;

                    alert(response.texto);

                    if (response.log !== 1) {
                        window.location.href = window.location.origin + '/supplier-tariff';
                    }
                }
            } catch (error: any) {
                if (error?.response?.status === 401) {
                    window.location.href = window.location.origin + '/';
                }
                if (error?.response?.status === 400) {
                    window.location.href = window.location.origin + '/supplier-tariff';
                }
            }
        }
        EditAccounts();
    };

    return (
        <>
            <Card className="bg-white mt-4">
                <Accordion >
                    <Card.Body>
                        <Container className="p-0 mt-3" fluid>
                            <Form noValidate validated={false} onSubmit={handleSubmit(onSubmit)}>
                                <Row className="mb-3">
                                    <Form.Group as={Col} md="3">
                                        <Form.Label>Tipo</Form.Label>
                                        <Controller
                                            control={control}
                                            name="tipo"
                                            rules={{ required: { value: type ? false : true, message: 'Por favor, informe o tipo' } }}
                                            render={({ field }: any) => (
                                                <div className={errors?.tipo !== undefined ? "endpoint-error" : "endpoint"}>
                                                    <Form.Select
                                                        aria-labelledby="aria-label"
                                                        inputId="aria-example-input"
                                                        name="aria-live-color"
                                                        {...field}
                                                        as="select"
                                                        variant="standard"
                                                        margin="normal"
                                                        onChange={(e: any) => {setType(e.target.value); field.onChange(e);}}
                                                        value={type}
                                                    >
                                                        {/* <option value="">Todos</option>  */}
                                                        <option value="1">Transporte</option> 
                                                        <option value="2">Guia</option> 
                                                        <option value="3">Driver Guide</option> 
                                                        <option value="4">Ticket</option> 
                                                        <option value="5">Restaurante</option> 
                                                        <option value="6">Serv Terceiros</option>
                                                    </Form.Select>
                                                </div>
                                            )} 
                                        />
                                        <ErrorMessage
                                            errors={errors}
                                            name="tipo"
                                            render={({ message }) => <small style={{ color: "red" }}>{message}</small>}
                                        />
                                    </Form.Group>
                                    <Form.Group as={Col} md="4">
                                        <Form.Label>Produto</Form.Label>
                                        <Controller
                                            control={control}
                                            name="produto"
                                            //rules={{ required: { value: true, message: 'Por favor, informe o nome' } }}
                                            defaultValue={product}
                                            render={({ field }: any) => (
                                                <GetProductsAndGroupsList propsField={field} propsErrors={errors} propsLabel="Produtos" setProduct={setProduct} />
                                            )} 
                                        />
                                        {/* <ErrorMessage
                                            errors={errors}
                                            name="produto"
                                            render={({ message }) => <small style={{ color: "red" }}>{message}</small>}
                                        /> */}
                                    </Form.Group>
                                    <Form.Group as={Col} md="4">
                                        <Form.Label>Recurso</Form.Label>
                                        <Controller
                                            control={control}
                                            name="recurso"
                                            //rules={{ required: { value: true, message: 'Por favor, informe o nome' } }}
                                            defaultValue={resource}
                                            render={({ field }: any) => (
                                                <GetSupplierResources 
                                                    propsField={field} 
                                                    propsErrors={errors} 
                                                    propsLabel="Produtos" 
                                                    setResource={setResource} 
                                                    type={type === '2'? 3 : type === '3' ? 2 : type}
                                                />
                                            )} 
                                        />
                                        {/* <ErrorMessage
                                            errors={errors}
                                            name="recurso"
                                            render={({ message }) => <small style={{ color: "red" }}>{message}</small>}
                                        /> */}
                                    </Form.Group>
                                </Row>
                                <Row className="mb-3">    
                                    <Form.Group as={Col} md="3">
                                        <Form.Label>Preço por Pessoa ou Recurso ?</Form.Label>
                                        <Controller
                                            control={control}
                                            name="pessoaRecurso"
                                            rules={{ required: { value: pessoaRecurso ? false : true, message: 'obrigatorio' } }}
                                            render={({ field }: any) => (
                                                <div className={errors?.pessoaRecurso !== undefined ? "endpoint-error" : "endpoint"}>
                                                    <Form.Select
                                                        aria-labelledby="aria-label"
                                                        inputId="aria-example-input"
                                                        name="aria-live-color"
                                                        {...field}
                                                        as="select"
                                                        variant="standard"
                                                        margin="normal"
                                                        onChange={(e: any) => {setPessoaRecurso(e.target.value); field.onChange(e);}}
                                                        value={pessoaRecurso}
                                                    >
                                                        <option value="">Selecione</option> 
                                                        <option value="1">Pessoa</option> 
                                                        <option value="2">Recurso</option> 
                                                    </Form.Select>
                                                </div>
                                            )} 
                                        />
                                        <ErrorMessage
                                            errors={errors}
                                            name="pessoaRecurso"
                                            render={({ message }) => <small style={{ color: "red" }}>{message}</small>}
                                        />
                                    </Form.Group>
                                    {type !== '2' && type !== '3' ? 
                                    <Form.Group as={Col} md="3">
                                        <Form.Label>Pre ou Pos Contratado ?</Form.Label>
                                        <Controller
                                            control={control}
                                            name="prePos"
                                            rules={{ required: { value: prePos ? false : true, message: 'obrigatorio' } }}
                                            render={({ field }: any) => (
                                                <div className={errors?.prePos !== undefined ? "endpoint-error" : "endpoint"}>
                                                    <Form.Select
                                                        aria-labelledby="aria-label"
                                                        inputId="aria-example-input"
                                                        name="aria-live-color"
                                                        {...field}
                                                        as="select"
                                                        variant="standard"
                                                        margin="normal"
                                                        onChange={(e: any) => {setPrePos(e.target.value); field.onChange(e);}}
                                                        value={prePos}
                                                    >
                                                        <option value="">Selecione</option> 
                                                        <option value="1">Pre</option> 
                                                        <option value="2">Pos</option> 
                                                    </Form.Select>
                                                </div>
                                            )} 
                                        />
                                        <ErrorMessage
                                            errors={errors}
                                            name="prePos"
                                            render={({ message }) => <small style={{ color: "red" }}>{message}</small>}
                                        />
                                    </Form.Group>
                                    :<></>}
                                    {type !== '2' && type !== '3' && prePos !== '1' ?  
                                    <Form.Group as={Col} md="3">
                                        <Form.Label>Permite alteração de preço pelo guia ?</Form.Label>
                                        <Controller
                                            control={control}
                                            name="guiaAltera"
                                            rules={{ required: { value: guiaAltera ? false : true, message: 'obrigatorio' } }}
                                            render={({ field }: any) => (
                                                <div className={errors?.guiaAltera !== undefined ? "endpoint-error" : "endpoint"}>
                                                    <Form.Select
                                                        aria-labelledby="aria-label"
                                                        inputId="aria-example-input"
                                                        name="aria-live-color"
                                                        {...field}
                                                        as="select"
                                                        variant="standard"
                                                        margin="normal"
                                                        //defaultValue={guiaAltera}
                                                        onChange={(e: any) => {setGuiaAltera(e.target.value); field.onChange(e);}}
                                                        value={guiaAltera}
                                                    >
                                                        <option value="">Selecione</option> 
                                                        <option value="1">Sim</option> 
                                                        <option value="2">Não</option> 
                                                    </Form.Select>
                                                </div>
                                            )} 
                                        />
                                        <ErrorMessage
                                            errors={errors}
                                            name="guiaAltera"
                                            render={({ message }) => <small style={{ color: "red" }}>{message}</small>}
                                        />
                                    </Form.Group>
                                    :
                                    <Form.Group as={Col} md="3">
                                        <Form.Label>Permite alteração de preço pelo guia ?</Form.Label>
                                            <div className={"endpoint"}>
                                                <Form.Select
                                                    aria-labelledby="aria-label"
                                                    name="aria-live-color"
                                                    as="select"
                                                    //onChange={(e: any) => setIdVehicles(e.target.value)}
                                                    //value={idVehicles}
                                                    disabled={true}
                                                >
                                                    <option value="2">Não</option> 
                                                </Form.Select>
                                            </div>
                                    </Form.Group>
                                    }
                                </Row>
                                <Row className="mb-3">
                                    <Form.Group as={Col} md={6} controlId="formGridSupplier">
                                        <Form.Label>Fornecedor</Form.Label>
                                        <Controller
                                            control={control}
                                            name="supplierId"
                                            //rules={{ required: { value: supplier ? false : true, message: "required" }, }}
                                            defaultValue={supplier}
                                            render={({ field }: any) => (
                                                <GetSuppliersFilter
                                                    propsField={field}
                                                    propsErrors={errors}
                                                    propsLabel="Fornecedor"
                                                    setSupplier={setSupplier}
                                                    //supplier={info ? info.fornecedor : "Selecione"}
                                                />
        
                                            )}
                                        />
                                        <ErrorMessage
                                            errors={errors}
                                            name="supplierId"
                                            render={({ message }) => <small style={{ color: "red" }}>{message}</small>}
                                        />
                                    </Form.Group>

                                    {type === '1' ? 
                                    <Form.Group as={Col} md={3} controlId="formGridSupplier">
                                        <Form.Label>Veículo</Form.Label>
                                        <Controller
                                            control={control}
                                            name="idVeiculo"
                                            //rules={{ required: { value: vehicleType ? false : true, message: "required" }, }}
                                            defaultValue={vehicleType}
                                            render={({ field }: any) => (
                                                <GetSupplierTariffVehicleType
                                                    propsField={field}
                                                    propsErrors={errors}
                                                    propsLabel="Veículo"
                                                    setVehicleType={setVehicleType}
                                                    //supplier={info ? info.fornecedor : "Selecione"}
                                                />
        
                                            )}
                                        />
                                        <ErrorMessage
                                            errors={errors}
                                            name="idVeiculo"
                                            render={({ message }) => <small style={{ color: "red" }}>{message}</small>}
                                        />
                                    </Form.Group>
                                    : type !== '1' ?
                                    <Form.Group as={Col} md={3} controlId="formGridSupplier">
                                        <Form.Label>Tipo/Categoria</Form.Label>
                                        <Controller
                                            control={control}
                                            name="idCategoria"
                                            //rules={{ required: { value: category ? false : true, message: "required" }, }}
                                            defaultValue={category}
                                            render={({ field }: any) => (
                                                <GetSupplierTariffCategories
                                                    propsField={field}
                                                    propsErrors={errors}
                                                    propsLabel="Categoria"
                                                    type={type === '2' || type === '3' ? 1 : type === '4' ? 2 : type === '5' ? 3 : 4}
                                                    setCategory={setCategory}
                                                    //supplier={info ? info.fornecedor : "Selecione"}
                                                />
                                            )}
                                        />
                                        <ErrorMessage
                                            errors={errors}
                                            name="idCategoria"
                                            render={({ message }) => <small style={{ color: "red" }}>{message}</small>}
                                        />
                                    </Form.Group>
                                    :<></>}
                                </Row>
                                <hr className="my-5" />
                                <h5 className="text-uppercase mb-3">Tabela de Valores</h5>
                                <Row>
                                    <SupplierTariffBlocks action={getBlocks} info={blocks} control={control} errors={errors} tariffType={type} blockBlocked={blockBlocked} setItemsDeleted={setItemsDeleted} />
                                </Row>
                                <Row className="d-flex justify-content-end mx-0 mt-5 mb-4">
                                    <Button type="submit" className="form-button">{t('accounts.accountsform.buttonSave')}</Button>
                                </Row>
                            </Form>
                        </Container>
                    </Card.Body>
                </Accordion>
            </Card>
        </>
    );
}

export default SuppliersTariffForm;