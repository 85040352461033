import React, { useEffect, useState } from 'react';

import api from '../../../services/api';

//COMPONENTS
import Breadcrumb from '../../../components/Breadcrumb/Breadcrumb';


import MerchantFlagsForm from '../components/MerchantFlagsForm/MerchantFlagsForm';
import { useTranslation } from 'react-i18next';
import DesktopDefault from '../../../templates/DesktopDefault';


function EditMerchantFlags() {
    const { t } = useTranslation();
    const [editMerchantFlags, setEditMerchantFlags] = useState(null);



    useEffect(() => {
        const idSup = window.location.href.split('id=')[1];

        const token = localStorage.getItem('GroupId') || '{}';
        const config = {
            headers: { 'Authorization': `Bearer ${token}` },
        };
        const EditMerchantFlags = async () => {
            try {
                const { data } = await api.get(`MerchantAccount/GetMerchantFlagByIdAsync/${idSup}`, config);
                /* console.log(data.data); */
                if (data.status !== 400) {
                    setEditMerchantFlags(data.data);
                }
            } catch (error: any) {
                if (error?.response?.status === 401) {
                    window.location.href = window.location.origin + '/';
                }
                if (error?.response?.status === 400) {
                    window.location.href = window.location.origin + '/list-merchantflags';
                }
            }
        }
        EditMerchantFlags();
    }, [])

    if (editMerchantFlags !== null) {
        return (
            <>
                <DesktopDefault>
                    <div className="container-fluid content-dashboard">

                        <Breadcrumb title={'Contas Correntes / ' + t('editMerchantFlags.title')} />

                        <div className="register-users mt-4">
                            <h2 className="title">{t('editMerchantFlags.title')}</h2>
                        </div>

                        <MerchantFlagsForm
                            action="2"
                            info={editMerchantFlags}
                        />

                    </div>

                </DesktopDefault>
            </>

        );
    } else {
        return (
            <></>
        )
    }
}

export default EditMerchantFlags;