import React from "react";
import { Container, Row, Col } from "react-bootstrap";

// import ProductOptions from "../ProductOptions/ProductOptions";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import ProductSlider from "../ProductCarrousel/ProductCarrousel";
import TourOptions from "../ProductOptions/TourOptions";

import "./InfoProducts.scss";
//import RefundRules from "../RefundRules/RefundRules";

import { useTranslation } from "react-i18next";

export interface tourList {
  tourDetails: any;
  setModalShow?: any;
}

const InfoProducts = ({
  tourDetails,
  setModalShow
}: any) => {
  const { t } = useTranslation();

  function scrollView(id: any) {
    var scrollElement = document.getElementById(id);
    scrollElement?.scrollIntoView({ behavior: "smooth" });
  }

  if (tourDetails !== null) {
    return (
      <div className="info-products pb-4">
        <div
          style={{
            backgroundImage: `url('${tourDetails.imagesBaseUrl + tourDetails.images[0]}')`,
            backgroundSize: "cover",
            backgroundPosition: "center",
          }}
        >
          <h1 style={{ color: "#FFF" }}>
            {tourDetails.productName.split("-", 1)}
          </h1>
          <div className="jumbotron">
            <Row>
              <div className="col-6 subtitle-product pb-2">
                <h3 className="m-0">
                  {tourDetails.modalities[0].activityDuration}
                </h3>
                <span className="m-0 pb-3">{t('sales.infoProducts.duration')}</span>
              </div>
              <div className="col-6 subtitle-product pb-2">
                <h3 className="m-0">{tourDetails.maxNumPeople}</h3>
                <span className="m-0">{t('sales.infoProducts.capacity')}</span>
              </div>
            </Row>
          </div>
        </div>
        <nav id="navbar-destino" className="navbar navbar-light bg-white">
          <div
            className="container text-center px-5"
            style={{ display: "block" }}
          >
            <ul className="nav-pills scroll-nav">
              <Row>
                <Col md={7} className="flex-column">
                  <Row>
                    <Col md={3}>
                      <li className="nav-item">
                        <span
                          className="nav-link link-tour nav-tour"
                          onClick={() => scrollView("session-experience")}
                        >
                          {" "}
                          <p className="d-md-inline d-lg-inline text-md-uppercase">
                            <FontAwesomeIcon
                              icon={["fal", "grin-wink"]}
                              size="1x"
                              style={{ marginRight: "5px" }}
                            />
                            <span style={{ verticalAlign: "text-bottom" }}>
                            {t('sales.infoProducts.exp')}
                            </span>
                          </p>
                        </span>
                      </li>
                    </Col>
                    <Col md={3}>
                      <li className="nav-item">
                        <span
                          className="nav-link link-tour nav-tour"
                          onClick={() => scrollView("session-options")}
                        >
                          {" "}
                          <p className="d-lg-inline text-md-uppercase">
                            <FontAwesomeIcon
                              icon={["fal", "list"]}
                              size="1x"
                              style={{ marginRight: "5px" }}
                            />
                            <span style={{ verticalAlign: "text-bottom" }}>
                            {t('sales.infoProducts.options')}
                            </span>
                          </p>
                        </span>
                      </li>
                    </Col>
                    <Col md={3}>
                      <li className="nav-item">
                        <span
                          className="nav-link link-tour nav-tour"
                          onClick={() => scrollView("session-info")}
                        >
                          {" "}
                          <p className="d-lg-inline text-md-uppercase">
                            <FontAwesomeIcon
                              icon={["fal", "info-circle"]}
                              size="1x"
                              style={{ marginRight: "5px" }}
                            />
                            <span style={{ verticalAlign: "text-bottom" }}>
                            {t('sales.infoProducts.info')}
                            </span>
                          </p>
                        </span>
                      </li>
                    </Col>
                    <Col md={3}>
                      <li className="nav-item">
                        <span
                          className="nav-link link-tour nav-tour"
                          onClick={() => scrollView("session-contact")}
                        >
                          {" "}
                          <p className="d-lg-inline text-md-uppercase">
                            <FontAwesomeIcon
                              icon={["fal", "phone-rotary"]}
                              size="1x"
                              style={{ marginRight: "5px" }}
                            />
                            <span style={{ verticalAlign: "text-bottom" }}>
                            {t('sales.infoProducts.contact')}
                            </span>
                          </p>
                        </span>
                      </li>
                    </Col>
                  </Row>
                </Col>
                <Col md={5}>
                  <li className="nav-item">
                    <span
                      className="bg-primary nav-link link-tour nav-tour"
                      onClick={() => scrollView("session-options")}
                      style={{
                        width: "135px",
                        marginLeft: "auto",
                      }}
                    >
                      {" "}
                      <p
                        className="d-lg-inline text-md-uppercase"
                        style={{ color: "#FFF" }}
                      >
                        {t('sales.infoProducts.seeOptions')}
                      </p>
                    </span>
                  </li>
                </Col>
              </Row>
            </ul>
          </div>
        </nav>
        <Container
          className="scroll-product"
          fluid
          style={{ backgroundColor: "#F2F2F2" }}
        >
          <Row>
            <div className="col-12 col-md-12 text-info-products px-0 py-4">
              <ProductSlider arrayImages={tourDetails} />
              <h4>{t('sales.infoProducts.other')}</h4>
              <div
                dangerouslySetInnerHTML={{
                  __html: tourDetails.productDescription,
                }}
              ></div>
              {/* <div
                id="session-experience"
                className="col-12 mt-4 text-info-products"
              >
                <h4>A Experiência</h4>
                <div className="experience">
                  <p>
                    Um passeio rápido no Rio de Janeiro visitando os dois lugares
                    mais famosos: a estátua do Cristo Redentor e a montanha do Pão
                    de Açúcar.
                  </p>
                  <p>
                    Seja buscado em seu hotel no Rio e vá até a estátua do Cristo
                    Redentor - uma das Novas Sete Maravilhas do Mundo - no topo do
                    Corcovado. O percurso é curto e bonito, percorrendo a Floresta
                    da Tijuca antes de se abrir para incríveis vistas de toda a
                    cidade.
                  </p>
                  <p>
                    Em seguida, vá ao Pão de Açúcar, localizado no bairro da Urca.
                    Pegue dois bondinhos a caminho do topo, primeiro chegando ao
                    Morro da Urca e continuando até o topo do Pão de Açúcar,
                    atingindo uma altura de 395m. Conclua o passeio com um
                    panorama lindíssimo do Rio de Janeiro.
                  </p>
                  <p>O melhor do Rio para quem tem pouco tempo.</p>
                </div>
              </div> */}
              <div
                id="session-highlights"
                className="col-12 text-info-products mt-4"
              >
                <h4>{t('sales.infoProducts.dest')}</h4>
                <ul className="mx-4">
                  {tourDetails.highlights.map((h: any, index: any) => {
                    return <li key={index}> {h} </li>;
                  })}
                </ul>
              </div>
            </div>
            <div className="col-12 mt-4 text-info-products">
              <h4>{t('sales.infoProducts.det')}</h4>
              <div className="experience mx-4">
                {tourDetails.details.map((d: any, index: any) => {
                  return <li key={index}> {d} </li>;
                })}
              </div>
            </div>
            <div id="session-options" className="col-12 mt-4 text-info-products">
              <h4>{t('sales.infoProducts.options')}</h4>
              <div>
                {/* <ProductOptions tourOptions={tourOptions} productCode={props.productCode} tourDetails={tourDetails}/> */}
                <div className="modal-prod-options">
                {tourDetails.modalities.map((tour: any, index: any) => {
                  return(
                    <TourOptions modality={tour} tourDetails={tourDetails} indexMod={index} setModalShow={setModalShow} isModal={true}/>
                    )
                  })
                  }
                </div>
              </div>
            </div>
            <div
              className="col-12 col-md-12 text-info-products mt-5"
              id="session-info"
            >
              <div>
                <h4>{t('sales.infoProducts.addInfo')}</h4>
                <div
                  dangerouslySetInnerHTML={{
                    __html: tourDetails.voucherInstructions,
                  }}
                ></div>
              </div>
            </div>
            <div className="row m-0 rounded overflow-hidden my-4">
              <div className="col-12 col-sm-6 col-md- col-lg- col-xl-8 align-items-center d-flex bg-white px-3 py-2">
                <span id="questoes" className="py-0 m-0">
                {t('sales.infoProducts.code')}?
                </span>
              </div>

              <div className="col-12 col-sm-6 col-xl-4 p-0 bg-primary text-white align-items-center justify-content-center d-flex">
                <span id="product-code">
                {t('sales.infoProducts.code')}: {tourDetails.productCode}
                </span>
              </div>
            </div>
            <div className="row text-center" id="session-contact">
              <div>
                <div className="col-12 text-center">
                  <p className="my-2">
                  {t('sales.infoProducts.textContact')}
                  </p>
                </div>
              </div>
              <div className="col-12 col-md-4">
                <strong className="d-none">{t('sales.infoProducts.phone')}:</strong>
                <span className="d-block py-2" id="tel-info">
                  {process.env.REACT_APP_INFO_PHONE}
                </span>
                <a
                  href="tel:+552135045730"
                  title=""
                  className="btn btn-sm btn-outline-primary w-50"
                >
                  {t('sales.infoProducts.phone')}
                </a>
              </div>
              <div className="col-12 col-md-4">
                <strong className="d-none">WhatsApp:</strong>
                <span className="d-block py-2" id="whats-info">
                  {process.env.REACT_APP_INFO_WHATSAPP}
                </span>
                <a
                  href={`https://api.whatsapp.com/send?phone=${process.env.REACT_APP_INFO_WHATSAPP}`}
                  title=""
                  className="btn btn-sm btn-outline-primary w-50"
                >
                  Whatsapp
                </a>
              </div>
              <div className="col-12 col-md-4">
                <strong className="d-none">Email:</strong>
                <span className="d-block py-2" id="mail-info">
                  {" "}
                  {process.env.REACT_APP_INFO_EMAIL}
                </span>
                <a
                  href={`mailto:${process.env.REACT_APP_INFO_EMAIL}?subject=Question%20to%20C2Rio&amp;body=Dear%20C2Rio,%0D%0A`}
                  title=""
                  className="btn btn-sm btn-outline-primary w-50"
                >
                  Email
                </a>
              </div>
            </div>
          </Row>
        </Container>
      </div>
    );
  } else {
    return (
      <>
        <div style={{ width: "100%", height: "150%" }}>
          <div className="animated-background" style={{ width: "100%", height: "361px", marginBottom: "10px" }}></div>
          <div className="d-flex justify-content-between" style={{ padding: "0px 42px" }}>
            <div className="d-flex">
              <div className="animated-background" style={{ width: "150px", height: "60px", marginRight: "10px" }}></div>
              <div className="animated-background" style={{ width: "150px", height: "60px", marginRight: "10px" }}></div>
              <div className="animated-background" style={{ width: "150px", height: "60px", marginRight: "10px" }}></div>
              <div className="animated-background" style={{ width: "150px", height: "60px", marginRight: "10px" }}></div>
            </div>
            <div className="animated-background" style={{ width: "150px", height: "60px" }}></div>
          </div>
          <div className="d-flex" style={{ padding: "24px 42px", backgroundColor: "#fff" }}>
            <div>
              <div className="animated-background" style={{ width: "300px", height: "198px", marginRight: "15px", marginBottom: "10px" }}></div>
              <div className="animated-background" style={{ width: "300px", height: "198px", marginRight: "15px", marginBottom: "10px" }}></div>
              <div className="animated-background" style={{ width: "300px", height: "198px", marginRight: "15px", marginBottom: "10px" }}></div>
            </div>
            <div>
              <div className="animated-background" style={{ width: "880px", height: "625px" }}></div>
            </div>
          </div>
          <div style={{ backgroundColor: "#fff", height: "300px" }}>
            <div className="animated-background" style={{ width: "50%", height: "20px", marginBottom: "5px" }}></div>
            <div className="animated-background" style={{ width: "100%", height: "100px", marginBottom: "15px" }}></div>
            <div className="animated-background" style={{ width: "50%", height: "20px", marginBottom: "5px" }}></div>
            <div className="animated-background" style={{ width: "100%", height: "100px", marginBottom: "15px" }}></div>
          </div>
        </div>
      </>
    )
  }
}

export default InfoProducts;
