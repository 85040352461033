/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useEffect, useState } from "react";
import { Button } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import Breadcrumb from "../../components/Breadcrumb/Breadcrumb";
import api from "../../services/api";
import DesktopDefault from "../../templates/DesktopDefault";
import PickupFilter from "./components/PickupFilter/PickupFilter";
import PickupTable from "./components/PickupTable/PickupTable";

function Pickup() {
  const [pickups, setPickups] = useState<any>(null);
  const [actualName, setActualName] = useState<any>('');
  const [loading, setLoading] = useState<any>(false);

  /* Pagination */
  const [totalRows, setTotalRows] = useState<any>();
  const [pageCount, setPageCount] = useState<any>(0);
  const [rowsPerPage, setRowsPerPage] = useState<any>(0);
  /* END - Pagination */

  const [t] = useTranslation();

  useEffect(() => {
    setLoading(true);
    setPickups(null);

    async function ListPickups() {
      try {
        const { data } = await api.post('Pickup/ListPickup',
          {
            "page": pageCount,
            "rowsPerPage": rowsPerPage,
            "locationId": ""
          }
        );

        if (data.status !== 400) {
          setLoading(false);
          setPickups(data.data.data.rows);
          setTotalRows(data.data.data.rowsCount);
        }
      } catch (error: any) {
        setLoading(false);
        if (error?.response?.status === 401) {
          window.location.href = window.location.origin + '/';
        }

      }
    }
    ListPickups();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  function clearActualValues() {
    setActualName("");
  };

  async function buscar(name: any) {
    setLoading(true);
    setPickups(null);
    setActualName(name);
    setPageCount(1);

    try {
      const res = await api.post('Pickup/ListPickup',
        {
          "page": pageCount,
          "rowsPerPage": rowsPerPage,
          "locationId": name
        }
      );

      if (res.status !== 400) {
        setLoading(false);
        setPickups(res.data.data.data.rows);
        setTotalRows(res.data.data.data.rowsCount);
      }
    } catch (error: any) {
      setLoading(false);
      console.log(error);
    }
  };

  return (
    <>
      <DesktopDefault>
        <div className="container-fluid content-dashboard">
          <Breadcrumb title={"Pickup"} />

          <div className="mt-4 d-flex justify-content-between align-items-center">
            <h2 className="title" style={{ color: "#707070" }}>
              Pickup
            </h2>
            <Link to={'/pickup/add-pickup'} className='btn btn-primary'>
              {'Adicionar Pickup'}
            </Link>
          </div>

          <PickupFilter
            buscar={buscar}
            pickups={pickups}
            clearActualValues={clearActualValues}
          />

          <PickupTable
            loading={loading}
            pickups={pickups}
            totalRows={totalRows}
            pageCount={pageCount}
            setPageCount={setPageCount}
            rowsPerPage={rowsPerPage}
            setRowsPerPage={setRowsPerPage}            
          />
        </div>
      </DesktopDefault>
    </>
  );
}

export default Pickup;
