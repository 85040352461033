import React, { useEffect, useState, Key } from 'react';
import api from '../../services/api';

import Form from 'react-bootstrap/Form';

export interface propPlus {
  propsField: any,
  propsLabel: any,
  propsErrors: any,
  setFilterStatus: any,
};

const GetStatusNewReservations: React.FC<propPlus> = ({
  propsField, propsLabel, propsErrors, setFilterStatus
}: propPlus) => {
  const [info, setInfo] = useState<any>(null);

  useEffect(() => {
    const token = localStorage.getItem('GroupId') || '{}';
    const config = {
      headers: { 'Authorization': `Bearer ${token}` },
    };
    async function listStates() {
      try {
        const { data } = await api.get(`/DropDown/GetStatusNewReservations`, config);
        if (data.status !== 400) {
          setInfo(data.data);
        }
      } catch (error: any) {
        if (error?.response?.status === 401) {
          /* window.location.href = window.location.origin + '/'; */
        }
      }
    }
    listStates();
  }, []);

  const handleChangeState = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = e.target.value;
    setFilterStatus(newValue);
  }

  if (info !== null) {
    return (
      <>
      <div className='endpoint'>
        <Form.Select
          {...propsField}
          label={propsLabel}
          as="select"
          variant="standard"
          margin="normal"
          onChange={(e: any) => handleChangeState(e)}
        >
          <option value='0' selected disabled>Selecione</option>
          {info.length > 0 ? info.map((info: any, index: Key) => {
            return (
              <option key={index} value={info.id}>
                {info.description}
              </option>
            )
          }) : ''}
        </Form.Select>
      </div>
      </>
    );
  } else {
    return (
      <>
        <Form.Select>
          <option value='' disabled></option>
        </Form.Select>
      </>
    )
  }
}

export default GetStatusNewReservations;