import React, { useState, useEffect } from "react";
import { Container, Row, Col } from 'react-bootstrap';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import Accordion from 'react-bootstrap/Accordion';
import { useTranslation } from "react-i18next";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { Controller, useForm } from "react-hook-form";

//Components
import GlobalAlert from "../../../../components/Alert/GlobalAlert/GlobalAlert";
import GetTranfersZones from "../../../../components/C2Points/GetTranfersZones";

function ZonesFilter(props: any) {

    const { t } = useTranslation();

    const [filterDestination, setFilterDestination] = useState<any>('');
    const [filterZone, setFilterZone] = useState<any>('');
    const [filterSupplier, setFilterSupplier] = useState<any>('');

    const [alert, setAlert] = useState<any>();

    const {
        control,
        formState: { errors },
    } = useForm({});

    const handleFilterClick = () => {
        props.buscar(filterDestination, filterZone, filterSupplier);
    };

    const handleClearClick = () => {

        let inputValue = document.querySelectorAll('input');
        for (let i = 0; i < inputValue.length; i++) {
            inputValue[i].value = "";
        }

        let selectValue = document.querySelectorAll('select');
        for (let i = 0; i < selectValue.length; i++) {
            selectValue[i].value = "0";
        }

        setFilterDestination("");
        setFilterZone("");
        setFilterSupplier("");
        props.buscar('', '', '');
    };


    const handleChangeDestination = (e: React.ChangeEvent<HTMLInputElement>) => {
        const newValue = e.target.value;
        setFilterDestination(newValue);
    }

    const handleChangeZone = (e: any) => {
        const newValue = e.target.value;
        setFilterZone(newValue);
    }

    const handleChangeSupplier = (e: React.ChangeEvent<HTMLInputElement>) => {
        const newValue = e.target.value;
        setFilterSupplier(newValue);
    }

    useEffect(() => {
        if (props.zones === 0 || props.error === true) {
            setAlert(
                <GlobalAlert alertType={"danger"}>
                    Não foi possível encontrar nenhuma zona.
                </GlobalAlert>
            )
            setTimeout(() => setAlert(null), 2000);
        } else {
            setAlert(null);
        }
    }, [props.zones, props.error])


    return (
        <div className="vehicles-filter partners-filter d-flex justify-content-center bg-white mt-4" style={{ textAlign: "left" }}>
            <Accordion defaultActiveKey="1">
                <Accordion.Item eventKey="0">
                    <Accordion.Header><FontAwesomeIcon icon={['fal', 'sliders-h']} size="lg" className="mr-2" /><span className="h5 mb-0">Buscar Zonas</span></Accordion.Header>
                    <Accordion.Body className="pt-0">
                        <Container className="p-0" fluid>
                            <Row>
                                <div>
                                    {alert}
                                    <Form >
                                        <Row className="mb-3">
                                            <Form.Group as={Col} md="5">
                                                <Form.Label>Destino:</Form.Label>
                                                <Controller
                                                    control={control}
                                                    name="destination"
                                                    render={({ field }: any) => (
                                                        <Form.Control
                                                            {...field}
                                                            type="text"
                                                            variant="standard"
                                                            margin="normal"
                                                            autoComplete="off"
                                                            onChange={handleChangeDestination}
                                                            value={filterDestination}
                                                        />
                                                    )}
                                                />
                                            </Form.Group>
                                            <Form.Group as={Col} md="3">
                                                <Form.Label>Zona:</Form.Label>
                                                <Controller
                                                    control={control}
                                                    name="zone"
                                                    render={({ field }: any) => (
                                                        <GetTranfersZones propsField={field} propsLabel={'Zona'} propsErrors={errors} handleChangeZone={handleChangeZone} />
                                                        )}
                                                />
                                            </Form.Group>
                                            <Form.Group as={Col} md="3">
                                                <Form.Label>Nome do Fornecedor:</Form.Label>
                                                <Controller
                                                    control={control}
                                                    name="supplier"
                                                    render={({ field }: any) => (
                                                        <Form.Control
                                                            {...field}
                                                            type="text"
                                                            variant="standard"
                                                            margin="normal"
                                                            autoComplete="off"
                                                            onChange={handleChangeSupplier}
                                                            value={filterSupplier}
                                                        />
                                                    )}
                                                />
                                            </Form.Group>
                                        </Row>

                                        <Row as={Col} md="12" className="mt-5 justify-content-md-end">
                                            <Col xs lg="12" className="d-flex justify-content-md-end">
                                                <Button variant="outline-primary" className="btn-default mr-2 text-uppercase" onClick={handleClearClick}>{t('partners.partnersfilter.btnClear')}</Button>
                                                <Button className="btn-default text-uppercase" onClick={handleFilterClick}>{t('partners.partnersfilter.btnSearch')}</Button>
                                            </Col>
                                        </Row>


                                    </Form>
                                </div>
                            </Row>
                        </Container>
                    </Accordion.Body>
                </Accordion.Item>
            </Accordion>
        </div>
    )
}

export default ZonesFilter;