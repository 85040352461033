import React from 'react';

//import logo from '../../logo.svg';

export interface propInfo {
    info: any;
    setChangeEdit: any;
    showDocument: any
};

const EditBankData: React.FC<propInfo> = ({
    info, setChangeEdit, showDocument
}: propInfo) => {

    function convertDate(cell: any) {
        if (cell !== null) {
            var aux: any = cell.split("T")[0].split("-");
            var date: any = `${aux[2]}/${aux[1]}/${aux[0]}`;
            return (
                <>
                    {date}
                </>
            );
        } else {
            return (
                <>
                </>
            );
        }
    }

    return (
        <>
            <div id="bankData" className="d-flex">
                <div className="border">
                    <div className="edit-title container-fluid">
                        <h3 className="">Dados Bancários</h3>
                        <button onClick={() => { setChangeEdit(false) }}>EDITAR</button>
                    </div><div className="data">
                        <div className="data-unit">
                            <p>CONTA CONTÁBIL:</p>
                            <p>{info.accountingAccountsDesc ? info.accountingAccountsDesc : "não definido"}</p>
                        </div>
                        <div className="data-unit">
                            <p>POSSUI CONTRATO?:</p>
                            <p>{info.haveContract === 1 ? "sim" : "não"}</p>
                        </div>
                        {info.haveContract === 1
                            ?
                            <>
                                <div className="data-unit col-20">
                                    <p>VALIDADE:</p>
                                    <p>{info.validade ? convertDate(info.validade) : "não definido"}</p>
                                </div>
                                <div className="data-unit col-10">
                                    {info.contractBase64 === null
                                        ?
                                        <p className='text-primary' style={{ cursor: 'pointer' }} onClick={() => { showDocument(info.contractBase64, 'CONTRATO') }}>CONTRATO:</p>
                                        :
                                        <p>CONTRATO:</p>
                                    }
                                    <p>{info.contractName ? info.contractName : "não definido"}</p>
                                </div>
                            </>
                            :
                            <>
                                <div className="data-unit col-20 data-unit-white-bg"></div>
                                <div className="data-unit col-10 data-unit-white-bg"></div>
                            </>
                        }
                        <div className="data-unit">
                            <p>CONTA MOVIMENTO:</p>
                            <p>{info.contaCorrenteDesc ? info.contaCorrenteDesc : "não definido"}</p>
                        </div>
                        <div className="data-unit">
                            <p>CENTRO DE CUSTO:</p>
                            <p>{info.centroCustoDesc ? info.centroCustoDesc : "não definido"}</p>
                        </div>
                        <div className="data-unit data-unit-white-bg">

                        </div>

                    </div>
                </div>
            </div>
        </>
    );
}

export default EditBankData;