import React, { useContext, useState, useEffect } from "react";
import api from '../../../services/api';
import {
    AccordionContext,
    Accordion,
    Container,
    Row,
    useAccordionButton,
} from "react-bootstrap";

import "./AddPartners.scss";

import { useTranslation } from "react-i18next";

import Breadcrumb from "../../../components/Breadcrumb/Breadcrumb";
import AccordionPersonal from "../components/AccordionPersonal/AccordionPersonal";
import AccordionDocuments from "../components/AccordionDocuments/AccordionDocuments";
import AccordionFinancial from "../components/AccordionFinancial/AccordionFinancial";
import AccordionAccount from "../components/AccordionAccount/AccordionAccount";
import AccordionUsers from "../components/AccordionUsers/AccordionUsers";

import RequestResponseModal from "../../../components/Modal/RequestResponseModal/RequestResponseModal";
import Modal from "react-bootstrap/Modal";
import DesktopDefault from "../../../templates/DesktopDefault";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const AddPartners = React.memo(() => {
    const { t } = useTranslation();
    const [stepCtrl, setStepCtrl] = useState<number>(0);
    const [partnersControlObject, setPartnersControlObject] = useState<any>();
    const [partnersObject, setPartnersObject] = useState<any>();
    //const [errors, setErrors] = useState({});
    const [modalResponse, setModalResponse] = useState<any>(null);  /// ["loading","error","sucess"] para abrir o modal pós envio
    const [allowRedirect, setAllowRedirect] = useState<boolean>(false);

    const [modalText, setModalText] = useState<any>(null);
    const [log, setLog] = useState<any>();

    const [savedSteps, setSavedSteps] = useState<any>(0);
    const [activeState, setActiveState] = useState<any>(false);

    function ScrollTop() {
        window.scrollTo(0, 0);
    }

    const handleNext = (newObject: any) => {
        if (stepCtrl === savedSteps) {
            setSavedSteps(savedSteps + 1);
        }
        setPartnersObject(newObject);
        if (stepCtrl >= 5) {
            setActiveState(!activeState);
        }
        setStepCtrl(stepCtrl + 1);
        ScrollTop();
    };

    const handleFinished = (objectFinished: object) => {
        sendPartner(objectFinished);
    }

    const sendPartner = async (objectSupplier: object) => {
        setModalResponse("loading");
        const token = localStorage.getItem('GroupId') || '{}';
        const config = {
            headers: { 'Authorization': `Bearer ${token}`, 'Content-Type': 'application/json' },
        };
        try {
            const str = JSON.stringify(objectSupplier);
            const { data } = await api.post('Affiliate/CreateAffiliateAsync', str, config);

            if (data.statusCode !== 400) {
                if (data.data.log === 0) {
                    setModalResponse("success");
                    setModalText(data.data.texto);
                    setLog(data.data.log);
                    setAllowRedirect(data.data.log === 0);
                } else {
                    setModalText(data.data.texto);
                    setModalResponse("error");
                    setLog(1);
                    setAllowRedirect(false);
                }
            } else {
                setModalText(data.errorMessage);
                setModalResponse("error");
                //setLog(data.data.log);
            }

        } catch (error: any) {
            if (error?.response?.status === 400) {
                setModalResponse("error");
                console.log(error.response)
                /*  if(error.response.data.data.length > 0 && typeof error.response.data?.data[0]?.errors[0] === typeof ''){
                     setModalText(error.response.data.data[0].errors[0])
                 }else{
                     setModalText(error.response.data.errorMessage)
                 } */
                //setErrors(error.response.data.errors)
            }
        }
    }

    useEffect(() => {
        setPartnersControlObject((prevState: any) => {
            return { ...prevState, ...partnersObject };
        });
    }, [partnersObject]);

    useEffect(() => {       
        if (stepCtrl >= 5) {
            handleFinished(partnersControlObject);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [partnersControlObject, activeState]);

    /* useEffect(() => {
        if (stepCtrl >= 5) {
            handleFinished(partnersControlObject);
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [stepCtrl]); */

    const ContextAwareToggle = ({
        children,
        eventKey,
        callback,
        status,
    }: any) => {
        const { activeEventKey } = useContext(AccordionContext);

        const decoratedOnClick = useAccordionButton(
            eventKey,
            () => callback && callback(eventKey)
        );

        const isCurrentEventKey = activeEventKey === eventKey;

        return (
            <h2 className="accordion-custom accordion-header">
                <button
                    type="button"
                    aria-expanded="false"
                    className={
                        isCurrentEventKey
                            ? "accordion-button"
                            : "accordion-button collapsed"
                    }
                    onClick={decoratedOnClick}
                    disabled={status}
                >
                    {children}
                </button>
            </h2>
        );
    };

    return (
        <>
            <DesktopDefault>
                <div className="container-fluid content-dashboard ">
                    <Breadcrumb title={"Clientes / " + t("addPartners.title")} />
                    <div className="partners mt-4">
                        <div className="d-flex align-items-center">
                            <span className='back' onClick={() => { window.location.href = '/partners' }}>
                                <FontAwesomeIcon
                                    icon={["fal", "angle-double-left"]}
                                    style={{ margin: "0 5px 0 0", fontSize: '16px' }}
                                />
                                Voltar</span>
                        </div>
                        <h2 className="title text-primary">{t("addPartners.title")}</h2>
                        <Container className="partners-guide">
                            <div className="row">
                                <div className="steps d-flex px-3 pb-1 px-md-0 col">
                                    <div className="step complete">
                                        1
                                        <br />
                                        <span className="complete">
                                            {t("addPartners.step01")}
                                        </span>
                                    </div>
                                    <div
                                        className={
                                            stepCtrl > 0 ? "stepline complete" : "stepline "
                                        }
                                    ></div>
                                    <div className={stepCtrl > 0 ? "step complete" : "step "}>
                                        2
                                        <br />
                                        <span className={stepCtrl > 0 ? "complete" : ""}>
                                            Documentos
                                        </span>
                                    </div>
                                    <div
                                        className={
                                            stepCtrl > 1 ? "stepline complete" : "stepline "
                                        }
                                    ></div>
                                    <div className={stepCtrl > 1 ? "step complete" : "step "}>
                                        3
                                        <br />
                                        <span className={stepCtrl > 1 ? "complete" : ""}>
                                            Financeiro
                                        </span>
                                    </div>
                                    <div
                                        className={
                                            stepCtrl > 2 ? "stepline complete" : "stepline "
                                        }
                                    ></div>
                                    <div className={stepCtrl > 2 ? "step complete" : "step "}>
                                        4
                                        <br />
                                        <span className={stepCtrl > 2 ? "complete" : ""}>
                                            Contábil
                                        </span>
                                    </div>
                                    <div
                                        className={
                                            stepCtrl > 3 ? "stepline complete" : "stepline "
                                        }
                                    ></div>
                                    <div className={stepCtrl > 3 ? "step complete" : "step "}>
                                        5
                                        <br />
                                        <span className={stepCtrl > 3 ? "complete" : ""}>
                                            Usuário
                                        </span>
                                    </div>

                                </div>
                            </div>
                        </Container>
                    </div>
                    <Accordion defaultActiveKey="0" flush>
                        {stepCtrl === 0 ? (
                            <AccordionPersonal
                                action={handleNext}
                                ContextAwareToggle={ContextAwareToggle}
                                hasSavedInputs={savedSteps > 0 ? true : false}
                                infoEdit={partnersControlObject}
                            />
                        ) : (
                            " "
                        )}
                        {stepCtrl === 1 ? (
                            <AccordionDocuments
                                compType={partnersControlObject.compType}
                                action={handleNext}
                                ContextAwareToggle={ContextAwareToggle}
                                setStepCtrl={setStepCtrl}
                                hasSavedInputs={savedSteps > 1 ? true : false}
                                infoEdit={partnersControlObject}
                            />
                        ) : (
                            " "
                        )}
                        {stepCtrl === 2 ? (
                            <AccordionFinancial
                                action={handleNext}
                                ContextAwareToggle={ContextAwareToggle}
                                setStepCtrl={setStepCtrl}
                                hasSavedInputs={savedSteps > 2 ? true : false}
                                infoEdit={partnersControlObject}
                            />
                        ) : (
                            " "
                        )}
                        {stepCtrl === 3 ? (
                            <AccordionAccount
                                setChangeEdit={false}
                                action={handleNext}
                                ContextAwareToggle={ContextAwareToggle}
                                setStepCtrl={setStepCtrl}
                                hasSavedInputs={savedSteps > 3 ? true : false}
                                infoEdit={partnersControlObject}
                            />
                        ) : (
                            " "
                        )}
                        {stepCtrl >= 4 ? (
                            <AccordionUsers
                                setChangeEdit={false}
                                action={handleNext}
                                ContextAwareToggle={ContextAwareToggle}
                                setStepCtrl={setStepCtrl}
                            />
                        ) : (
                            " "
                        )}
                    </Accordion>
                </div>
            </DesktopDefault>


            <Row className="d-flex justify-content-end mx-0 mt-5 mb-4">
                <div className="btn-sucess">
                    <Modal
                        className="modal-custom modalAuth"
                        show={modalResponse !== null}
                        onHide={() => {
                            if (allowRedirect) {
                                window.location.href = '/partners';
                            }
                            setModalResponse(null)
                        }}
                        size="lg"
                        aria-labelledby="contained-modal-title-vcenter"
                    >
                        <>
                            <Modal.Header></Modal.Header>
                            <RequestResponseModal name={"Cliente"} modalResponse={modalResponse} setModalResponse={setModalResponse} modalText={modalText} log={log} redirectOnSuccess={'/partners'} />
                        </>
                    </Modal>
                </div>
            </Row>
        </>
    );
})

export default AddPartners;
