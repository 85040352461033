import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { Dropdown, OverlayTrigger, Tooltip } from "react-bootstrap";
import BootstrapTable from "react-bootstrap-table-next";
import Pagination from '../../../../components/Pagination/Pagination';

import "./InvoicedSalesTable.scss";

export interface propInsert {
  invoicedSales: any;
  loading: any;
  totalRows: any;
  pageCount: any;
  setPageCount: any;
  rowsPerPage: any;
  setRowsPerPage: any;
  exportInvoicedSales: any;
  printInvoicedSales: any;
  loadButton: any;
  t?: any;
}

const InvoicedSalesTable: React.FC<propInsert> = ({
  invoicedSales,
  loading,
  totalRows,
  pageCount,
  setPageCount,
  rowsPerPage,
  setRowsPerPage,
  exportInvoicedSales,
  printInvoicedSales,
  loadButton,
  t
}: propInsert) => {

  const columns = [
    { dataField: "reserva", text: `${t("invoiced.table.reservationNumber")}`},
    { dataField: "produto", text: `${t("invoiced.table.product")}`, formatter: textReduce },
    { dataField: "status", text: `${t("invoiced.table.serviceStatus")}`, formatter: addStatus },
    { dataField: "dataReserva", text: `${t("invoiced.table.date")}`, formatter: addDate },
    { dataField: "paxPrincipal", text: `${t("invoiced.table.mainPax")}` },
    { dataField: "quantidade", text: `${t("invoiced.table.quantity")}`, formatter: addMargin },
    { dataField: "valor", text: `${t("invoiced.table.value")}`, formatter: addValor },
    { dataField: "valorNet", text: `${t("invoiced.table.commission")}`, formatter: addValor },
    { dataField: "vencimento", text: `${t("invoiced.table.expiration")}` },
    { dataField: "reserva", text: `${t("invoiced.table.actions")}`, formatter: addActionButton },
  ]; 

  /* const [editClick, setEditClick] = useState<any>(false);
  const [detailsModal, setDetailsModal] = useState<any>(false);
  const [currentSales, setCurrentSales] = useState<any>();

  function seeDetailsEdit(cell: any) {
    setEditClick(true);
    setCurrentSales(cell);
    setDetailsModal(true);
  } */

  /* loading table */
  function loadingFunc() {
    return (
      <>
        <div className="animated-background row-loading"></div>
      </>
    );
  }

  const loadingColumns = [
    { dataField: "vendasd", text: `${t("invoiced.table.loadingSales")}`, formatter: loadingFunc },
  ];

  const loadingProducts = [
    { vendas: "" },
    { vendas: "" },
    { vendas: "" },
    { vendas: "" },
    { vendas: "" },
    { vendas: "" },
    { vendas: "" },
    { vendas: "" },
    { vendas: "" },
    { vendas: "" },
  ];
  /* [END] loading table */

  /* In case of empty table*/
  const notFoundColumns = [{ dataField: "vendas", text: `${t("invoiced.table.sales")}` }];

  const notFoundProducts = [{ vendas: `${t("invoiced.table.noSalesFound")}` }];
  /* [END] In case of empty table*/

  //botao de ação
  function addActionButton(cell: any, row: any) {
    return (
      <>
        <div className="d-flex justify-content-center">
          <Dropdown drop="start">
            <Dropdown.Toggle variant="light ">
              <FontAwesomeIcon size="lg" icon={["fal", "ellipsis-h"]} />
            </Dropdown.Toggle>

            <Dropdown.Menu>
              <Dropdown.Item eventKey="1" disabled={true}>
                <FontAwesomeIcon
                  icon={["fal", "comment-alt-dollar"]}
                  className="mr-2"
                />{" "}
                {t("invoiced.table.dropdown.account")}
              </Dropdown.Item>

              <Dropdown.Item disabled={true}
                eventKey="2"
              /* onClick={() => {
                seeDetailsEdit(cell);
              }} */
              >
                <FontAwesomeIcon icon={["fal", "edit"]} className="mr-2" />{" "}
                {t("invoiced.table.dropdown.edit")}{" "}
              </Dropdown.Item>

              <Dropdown.Item eventKey="3" disabled={true}>
                <FontAwesomeIcon icon={["fal", "circle"]} className="mr-2" />{" "}
                {t("invoiced.table.dropdown.status")}
              </Dropdown.Item>
              <Dropdown.Item eventKey="5" disabled={true}>
                <FontAwesomeIcon
                  icon={["fal", "file-contract"]}
                  className="mr-2"
                />{" "}
                {t("invoiced.table.dropdown.contract")}
              </Dropdown.Item>
              <Dropdown.Item
                eventKey="4"
                disabled={true}
                className="text-danger remove"
              >
                <FontAwesomeIcon icon={["fal", "trash-alt"]} className="mr-2" />{" "}
                {t("invoiced.table.dropdown.remove")}
              </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
        </div>
      </>
    );
  }

  // formatter de status
  function addStatus(cell: any, row: any) {
    return (
      <div
        className={
          cell === "Em análise"
            ? "bg-analise"
            : cell === "Confirmado"
              ? "bg-ativo"
              : cell === "Cancelado"
                ? "bg-inativo"
                : ""
        }
        style={{ margin: 'auto' }}
      >
        {cell}
      </div>
    );
  }

  function addDate(cell: any, row: any) {
    var aux: any = cell.split(' ')[0]
    return (
      <>
        {aux}
      </>
    );
  }
  function addMargin(cell: any, row: any) {
    return (
      <div className="d-flex justify-content-center">
        {cell}
      </div>
    );
  }

  function addValor(cell: any, row: any) {
    return (
      <>
        {cell.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })}
      </>
    );
  }

  function textReduce(cell: any) {
    return (
      <>
        <OverlayTrigger
          placement="top-start"
          overlay={
            <Tooltip id="tooltip">{cell}</Tooltip>
          }>
          <div className="d-flex" style={{ fontSize: "14px" }}>
            <span className='text-short'>
              <span className="text-nowrap-custom">
                {cell}
              </span>
            </span>
          </div>
        </OverlayTrigger>
      </>
    )
  }

  const rowClasses = (row: any, rowIndex: any) => {
    if (row.status === "Em Análise") {
      return "border-analise";
    } else if (row.status === "Confirmado") {
      return "border-ativo";
    } else {
      return "border-inativo";
    }
  };

  //RENDER//  

  if (invoicedSales !== null) {
    return (
      <>
        <div className="table-default sales-table">
          <div className="table-container">
            <div>
              <div className="d-flex justify-content-between">
                <div className="d-flex flex-wrap align-items-center" style={{ gap: "15px" }}>
                  <div>
                    <span className={invoicedSales.length === 0 ? `d-flex align-items-center btn btn-bills disabled` : `d-flex align-items-center btn btn-bills`} onClick={printInvoicedSales}>
                      {
                        loadButton[0] === true
                          ?
                          <div className="load" style={{ marginRight: "5px" }}></div>
                          :
                          <FontAwesomeIcon
                            file-invoice
                            icon={["fal", "print"]}
                            size="1x"
                            style={{ marginRight: "5px" }}
                          />
                      }
                      {t("invoiced.table.print")}
                    </span>
                  </div>
                  <div>
                    <span className={invoicedSales.length === 0 ? `d-flex align-items-center btn btn-bills disabled` : `d-flex align-items-center btn btn-bills`} onClick={exportInvoicedSales}
                    >
                      {
                        loadButton[1] === true
                          ?
                          <div className="load" style={{ marginRight: "5px" }}></div>
                          :
                          <FontAwesomeIcon
                            icon={["fal", "share"]}
                            size="1x"
                            style={{ marginRight: "5px" }}
                          />
                      }
                      {t("invoiced.table.export")}
                    </span>
                  </div>
                  <div>
                    <span className="d-flex align-items-center btn btn-bills disabled"
                    >
                      {
                        loadButton[2] === true
                          ?
                          <div className="load" style={{ marginRight: "5px" }}></div>
                          :
                          <FontAwesomeIcon
                            icon={["fal", "file-alt"]}
                            size="1x"
                            style={{ marginRight: "5px" }}
                          />
                      }
                      {t("invoiced.table.billet")}
                    </span>
                  </div>
                </div>
              </div>

              <BootstrapTable
                bootstrap4
                keyField="id"
                data={invoicedSales}
                hover={true}
                columns={columns}
                striped={true}
                rowClasses={rowClasses}
              />

              <Pagination
                totalRows={totalRows}
                pageCount={pageCount}
                setPageCount={setPageCount}
                rowsPerPage={rowsPerPage}
                setRowsPerPage={setRowsPerPage}
                selectNumberRows="yes"
              />
            </div>
          </div>
        </div>
      </>
    );
  } else if (invoicedSales === null && loading === true) {
    return (
      <>
        <div className="table-default sales-table loading">
          <div className="table-container">
            <div>
              <BootstrapTable
                bootstrap4
                keyField="id"
                data={loadingProducts}
                hover={true}
                columns={loadingColumns}
                striped={true}
              />
            </div>
          </div>
        </div>
      </>
    );
  } else {
    return (
      <>
        <div className="table-default sales-table loading not-found">
          <div className="table-container">
            <div>
              <BootstrapTable
                bootstrap4
                keyField="id"
                data={notFoundProducts}
                hover={true}
                columns={notFoundColumns}
                striped={true}
              />
            </div>
          </div>
        </div>
      </>
    );
  }
};

export default InvoicedSalesTable;
