import React, { useEffect, useState } from "react";
import api from '../../../../services/api';
import { setCookie } from 'nookies';

import { useTranslation } from 'react-i18next';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button, Modal } from 'react-bootstrap';

function AuthLogin() {
  const { t } = useTranslation();
  const [modalShow, setModalShow] = useState<any>(false);
  const [errorMessage, setErrorMessage] = useState<any>(null);
  const [showPass, setShowPass] = useState<any>(false);
  const [loading, setLoading] = useState<any>(false);

  async function canSeeBills(newProfileObj: any) {
    const token = newProfileObj.user.perfil.token.token;
    const config = {
      headers: { Authorization: `Bearer ${token}` },
    };
    try {
      const res = await api.post(`/UserPermission/CanSeeBills`, {}, config);
      if (res.status !== 400) {
        newProfileObj.user.perfil.canSeeBills = res.data.data;

        newProfileObj.user.perfil.nivel = btoa(
          `${newProfileObj.user.perfil.nivel}`,
        );
        localStorage.setItem('c2tourAuth', JSON.stringify(newProfileObj));
        localStorage.setItem('GroupId', newProfileObj.user.perfil.token.token);
        window.location.href = window.location.origin + '/dashboard';
      }
    } catch (error: any) {
      console.log(error);
    }
  }

  const onSubmit = (e: any) => {
    setErrorMessage(null);
    e.preventDefault();
    const getAuthenticate = async () => {
      try {
        const res = await api.post('/Login/Authenticate', {
          email: e.target.email.value,
          password: e.target.cust_pass.value,
        });
        if (res.data.statusCode !== 400) {
          localStorage.setItem('c2tourAuth', JSON.stringify(res.data.data));
          const c2tourAuth: any = JSON.parse(
            localStorage.getItem('c2tourAuth') || '{}',
          );
          setCookie(null, 'authCookie', 'loggedInTrue', {
            maxAge: 60 * 60 * 60,
            path: '/',
          });
          setLoading(false);
          if (res.data.data.user.perfils.length > 1) {
            window.location.href = window.location.origin + '/profile';
          } else if (res.data.data.user.perfils.length === 1) {
            var newProfileObj: any = c2tourAuth;
            newProfileObj.user.perfils = undefined;
            newProfileObj.user.perfil = res.data.data.user.perfils[0];
            if ([24, 25, 26, 29].includes(newProfileObj.user.perfil.nivel)) {
              localStorage.setItem('idCanal', newProfileObj.user.perfil.idCanal)
              newProfileObj.user.perfil.isAgent = 1;
              canSeeBills(newProfileObj);
            } else {
              newProfileObj.user.perfil.nivel = btoa(
                `${newProfileObj.user.perfil.nivel}`,
              );
              localStorage.setItem('c2tourAuth', JSON.stringify(newProfileObj));
              localStorage.setItem(
                'GroupId',
                res.data.data.user.perfils[0].token.token,
              );
              window.location.href = window.location.origin + '/dashboard';
            }
          } else {
            setModalShow(true);
          }
        } else {
          setLoading(false);
          setErrorMessage(res.data.errorMessage);
        }
      } catch (error: any) {
        setLoading(false);
        console.log(error?.response?.data?.errorMessage);
        setErrorMessage(
          'Erro ao processar operação. Tente novamente mais tarde.',
        );
      }
    };

    getAuthenticate();
  };

  const GetError = () => {
    /* console.log(errorMessage === null); */
    if (errorMessage === null) {
      return <div className="form-group"></div>;
    } else {
      return (
        <div className="invalid form-group">
          <div className="invalid-feedback">{errorMessage}</div>
        </div>
      );
    }
  };

    useEffect(()=>{
        async function getIdChannel() {
            try {
              const { data } = await api.get(`${process.env.REACT_APP_SERVER_URL_API}/Products/GetChannelBySource/sig`);
              if (data.statusCode === 200) {
                localStorage.setItem('idCanal', data.data.data)
              }
            } catch (error) { }
          }
          getIdChannel();
    },[])

  return (
    <>
      <form className="user" name="login_form" onSubmit={(e) => onSubmit(e)}>
        <div className="form-group">
          <label htmlFor="exampleInputEmail">{t('login.email')}</label>
          <input
            autoComplete="off"
            type="email"
            name="email"
            className="form-control"
            id="exampleInputEmail"
            aria-describedby="emailHelp"
          />
        </div>
        <div className="form-group">
          <label htmlFor="exampleInputPassword">{t('login.password')}</label>
          <input
            autoComplete="off"
            type={showPass === true ? 'text' : 'password'}
            name="cust_pass"
            className="form-control"
            id="exampleInputPassword"
          />
          <div className="iconsPassword">
            {showPass === false ? (
              <FontAwesomeIcon
                icon={['fal', 'eye-slash']}
                size="1x"
                onClick={() => {
                  setShowPass(!showPass);
                }}
              />
            ) : (
              <FontAwesomeIcon
                icon={['fal', 'eye']}
                size="1x"
                onClick={() => {
                  setShowPass(!showPass);
                }}
              />
            )}
          </div>
        </div>
        <GetError />

        <div className="form-group">
          <div className="custom-control custom-checkbox small">
            <input
              autoComplete="off"
              type="checkbox"
              name="remember"
              className="custom-control-input"
              value="1"
              id="customCheck"
            />
            <label className="custom-control-label" htmlFor="customCheck">
              {t('login.remember')}
            </label>
          </div>
        </div>
        <button
          type="submit"
          className="form-button btn btn-primary btn-block btn-login"
          onClick={() => {
            setLoading(true);
          }}
        >
          {loading === true ? <div className="load"></div> : t('login.send')}
        </button>
      </form>

      <Modal
        className="modal-custom modalAuth"
        show={modalShow === true}
        onHide={() => setModalShow(false)}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
      >
        <>
          <div
            className="error-pos"
            id="wrapper"
            /* ref={wrapperRef} */ style={{
              display: 'flex',
              justifyContent: 'space-around',
              height: '545px',
            }}
          >
            <div className="text-center">
              <FontAwesomeIcon
                icon={['fal', 'times-circle']}
                size="5x"
                style={{ fontSize: '7.5em' }}
              />
            </div>
            <div className="text-center">
              <p>Não foi possível realizar essa operação</p>
            </div>
            <Button
              className="my-5 mx-auto"
              onClick={() => {
                setModalShow(false);
              }}
            >
              Fechar
            </Button>
          </div>
        </>
      </Modal>
    </>
  );
}

export default AuthLogin;
