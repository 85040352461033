import React, { useEffect, useState } from 'react';
import { Card, Row } from 'react-bootstrap';

import { Chart } from "react-google-charts";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export interface propInfo {
    infoChart: any;
    filter?: any;
    loading: any;
    t?:any
}

const ChartSalesReport: React.FC<propInfo> = ({
    infoChart, filter = 'Mês', loading, t
}) => {
    const color_primary: any = process.env.REACT_APP_COLOR_PRIMARY;
    const color_secondary: any = process.env.REACT_APP_COLOR_SECONDARY;

    const [salesInfo, setSalesInfo] = useState<any>(null);
    const [totalSales, setTotalSales] = useState<any>(0);

    const [reservationsInfo, setReservationsInfo] = useState<any>(null);
    const [passengersInfo, setPassengersInfo] = useState<any>(null);
    const [totalReservations, setTotalReservations] = useState<any>(0);
    //const [totalItens, setTotalItens] = useState<number>(0);

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const diffdata = {
        old: passengersInfo,
        new: reservationsInfo,
    };

    useEffect(() => {
        if (infoChart !== null && infoChart !== undefined) {
            let i: number;
            let countingSales: any = 0;
            let countingReservations: any = 0;
            //let countingItems: any = 0;

            const dataVendas = [
                [filter, `${t("salesReport.sales")} (R$)`]
            ]

            for (i = 0; i < infoChart.length; i++) {
                dataVendas.push([infoChart[i].date_label?.toString().split('-').reverse().join('-'), infoChart[i].totalAmount]);
                countingSales = countingSales + infoChart[i].totalAmount;
            }
            setSalesInfo(dataVendas);
            setTotalSales(countingSales);

            const dataReservas = [
                [filter, t("salesReport.reservationsNum"), t("salesReport.passengersNum")],
            ]
            const dataPassageiros = [
                [filter, t("salesReport.passengersNum")],
            ]

            for (i = 0; i < infoChart.length; i++) {
                dataReservas.push([infoChart[i].date_label?.toString().split('-').reverse().join('-'), infoChart[i].totalCount, infoChart[i].passAmount]);
                dataPassageiros.push([infoChart[i].date_label?.toString().split('-').reverse().join('-'), infoChart[i].passAmount]);
                countingReservations = countingReservations + infoChart[i].totalCount;
            }
            setReservationsInfo(dataReservas);
            setPassengersInfo(dataPassageiros)
            setTotalReservations(countingReservations);
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [filter, /*  */infoChart])


    const optionsVendas = {
        legend: {
            position: 'top',
        },
        chart: {
            title: "",
        },
        colors: [color_primary, color_secondary],
    };

    /* var options = {
        legend: {
            position: 'top',
        },
        colors: [color_primary],
        diff: {
            oldData: {
                opacity: 1,
                widthFactor: 1,
                tooltip: {
                    prefix: 'Passageiros'
                }
            },
            newData: {
                opacity: 1,
                color: color_primary,
                tooltip: {
                    prefix: 'Reservas'
                }
            }
        }
    } */
    
    return (
        <>
            <Row className="cards">
                <div className="col-md-2 card-unit">
                    <Card>
                        <div className="col-md-10">
                            <Row>
                                <span className='card-title'>
                                {t("salesReport.salesAmount")}
                                </span>
                            </Row>
                            <Row>
                                <span className='cart-content'>
                                    {totalSales.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })}
                                </span>
                            </Row>
                        </div>
                        <div className="col-md-2 d-flex justify-content-center align-items-center">
                            <FontAwesomeIcon
                                icon={["fad", "coins"]}
                                className="text-primary p-1"
                                size="3x"
                            />
                        </div>
                    </Card>
                </div>
                <div className="col-md-2 card-unit">
                    <Card>
                        <div className="col-md-10">
                            <Row>
                                <span className='card-title'>
                                {t("salesReport.reservationAmount")}
                                </span>
                            </Row>
                            <Row>
                                <span className='cart-content'>
                                    {totalReservations}
                                </span>
                            </Row>
                        </div>
                        <div className="col-md-2 d-flex justify-content-center align-items-center">
                            <FontAwesomeIcon
                                icon={["fad", "ballot-check"]}
                                className="text-primary p-1"
                                size="3x"
                            />
                        </div>
                    </Card>
                </div>
                <div className="col-md-2 card-unit">
                    <Card>
                        <div className="col-md-10">
                            <Row>
                                <span className='card-title'>
                                {t("salesReport.itemsAmount")}
                                </span>
                            </Row>
                            <Row>
                                <span className='cart-content'>
                                   {totalReservations} 
                                    {/* {totalItens} */}
                                </span>
                            </Row>
                        </div>
                        <div className="col-md-2 d-flex justify-content-center align-items-center">
                            <FontAwesomeIcon
                                icon={["fad", "ballot-check"]}
                                className="text-primary p-1"
                                size="3x"
                            />
                        </div>
                    </Card>
                </div>
            </Row>

            <Row>

                <div className="col-md-6">
                    <Card className='h-100'>
                        <div className='p-3'>
                            {salesInfo !== null && loading === false
                                ?
                                <>
                                    <Chart
                                        chartType="ColumnChart"
                                        width="100%"
                                        height="251px"
                                        data={salesInfo}
                                        options={optionsVendas}
                                    />
                                </>
                                :
                                <>
                                    <div className="animated-background row-loading" style={{ height: '251px' }}></div>
                                </>
                            }
                        </div>
                    </Card>
                </div>
                <div className="col-md-6">
                    <Card className='h-100'>
                        <div className='p-3'>
                            {reservationsInfo !== null && loading === false
                                ?
                                <>
                                    <Chart
                                        chartType="ColumnChart"
                                        width="100%"
                                        height="251px"
                                        data={reservationsInfo}
                                        options={optionsVendas}
                                    />
                                </>
                                :
                                <>
                                    <div className="animated-background row-loading" style={{ height: '251px' }}></div>
                                </>
                            }
                        </div>
                    </Card>
                </div>
            </Row>
        </>

    );
}

export default ChartSalesReport;