import React, { useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Accordion, Button, Col, Container, Form, InputGroup, Row } from "react-bootstrap";
import { Controller, useForm } from "react-hook-form";
import RangeCalendar from "../../../../components/Calendar/RangeCalendar";
import i18next from "i18next";

export interface propInsert {
  buscar: any;
  t?: any;
}

const InvoicedSalesFilter: React.FC<propInsert> = ({
  buscar, t
}) => {

  const [filterName, setFilterName] = useState<any>("");
  const [filterDate, setFilterDate] = useState<any>([]);
  const [filterStatus, setFilterStatus] = useState<any>(0);

  const {
    control
  } = useForm({});


  const handleFilterClick = () => {
    buscar(filterName, filterStatus, 1, filterDate);
  };

  const handleClearClick = () => {
    let inputValue = document.querySelectorAll("input");
    for (let i = 0; i < inputValue.length; i++) {
      inputValue[i].value = "";
    }

    let selectValue = document.querySelectorAll("select");
    for (let i = 0; i < selectValue.length; i++) {
      if (selectValue[i].id === "rowsPerPage") {
        selectValue[i].value = "10";
      } else {
        selectValue[i].value = "";
      }
    }

    var today: any = new Date();

    setFilterName("");
    setFilterDate([]);
    setFilterStatus(0);
    buscar('', 0, 1, [`${today.getDate()}/${today.getMonth() + 1}/${today.getFullYear()}`, `${today.getDate()}/${today.getMonth() + 1}/${today.getFullYear()}`]);
    //clearActualValues();
  };



  const handleChangeName = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = e.target.value;
    setFilterName(newValue);
  };

  /*   const handleChangeDate = (e: React.ChangeEvent<HTMLInputElement>) => {
      const newValue = e.target.value;
      setFilterDate(newValue);
    }; */

  const handleChangeStatus = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = e.target.value;
    setFilterStatus(newValue);
  };

  useEffect(() => {
    buscar(filterName, filterStatus, 1, filterDate);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [i18next.language])

  return (
    <div
      className="suppliers-filter d-flex justify-content-center bg-white mt-4"
      style={{ textAlign: "left" }}
    >
      <Accordion defaultActiveKey="1">
        <Accordion.Item eventKey="0">
          <Accordion.Header>
            <FontAwesomeIcon
              icon={["fal", "sliders-h"]}
              size="lg"
              className="mr-2"
            />
            <span className="h5 mb-0">{t("invoiced.filter.searchInvoicedSales")}</span>
          </Accordion.Header>
          <Accordion.Body className="pt-0">
            <Container className="p-0" fluid>
              <Row>
                <div>
                  <Form>
                    <Row className="mb-3">
                      <Form.Group as={Col} md="4">
                        <Form.Label>{t("invoiced.filter.searchFor")}</Form.Label>
                        <Controller
                          control={control}
                          name="name"
                          render={({ field }: any) => (
                            <Form.Control
                              {...field}
                              type="text"
                              onChange={handleChangeName}
                              value={filterName}
                              placeholder={t("invoiced.filter.searchForPlaceHolder")}
                              variant="standard"
                              margin="normal"
                              autoComplete="off"
                            />
                          )}
                        />
                      </Form.Group>

                      <Form.Group as={Col} md="3">
                        <Form.Label>{t("invoiced.filter.from")}</Form.Label>
                        <InputGroup
                          hasValidation
                          className=""
                          size="sm"
                          placeholder="Quando?"
                        >
                          <RangeCalendar
                            date={filterDate}
                            setDate={setFilterDate}
                          />
                        </InputGroup>
                      </Form.Group>

                      <Form.Group as={Col} md="2">
                        <Form.Label>{t("invoiced.filter.status")}</Form.Label>
                        <Controller
                          control={control}
                          name="status"
                          render={({ field }: any) => (
                            <div className="endpoint">
                              <Form.Select
                                {...field}
                                as="select"
                                variant="standard"
                                margin="normal"
                                onChange={(e: any) => handleChangeStatus(e)}
                                value={filterStatus}
                              >
                                <option value="0" selected>
                                  {t("invoiced.filter.statusSelect.all")}
                                </option>
                                <option value="2">{t("invoiced.filter.statusSelect.toWin")}</option>
                                <option value="3">{t("invoiced.filter.statusSelect.paid")}</option>
                              </Form.Select>
                            </div>
                          )}
                        />
                      </Form.Group>
                    </Row>
                    <Row
                      as={Col}
                      md="12"
                      className="mt-5 justify-content-md-end"
                    >
                      <Col
                        xs
                        lg="12"
                        className="d-flex justify-content-md-end"
                      >
                        <Button
                          variant="outline-primary"
                          className="btn-default mr-2 text-uppercase"
                          onClick={handleClearClick}
                        >
                          {t("invoiced.filter.clear")}
                        </Button>
                        <Button
                          className="btn-default text-uppercase"
                          onClick={handleFilterClick}
                        >
                          {t("invoiced.filter.search")}
                        </Button>
                      </Col>
                    </Row>
                  </Form>
                </div>
              </Row>
            </Container>
          </Accordion.Body>
        </Accordion.Item>
      </Accordion>
    </div>
  )
};

export default InvoicedSalesFilter;