import React, { useState } from "react";
import { Container, Row, Col } from 'react-bootstrap';
import Form from 'react-bootstrap/Form';
//import InputGroup from 'react-bootstrap/InputGroup';
import Button from 'react-bootstrap/Button';
import Accordion from 'react-bootstrap/Accordion';
import { useTranslation } from "react-i18next";

import InputGroup from 'react-bootstrap/InputGroup';

import './FilterReportSalesPend.scss';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

//Components
import RangeCalendar from "../../../../components/Calendar/RangeCalendar";
// import GetAgents from "../../../../components/C2Points/GetAgentsFilter";
// import GetSuppliersIdentity from "../../../../components/C2Points/GetSuppliersIdentity";
// import GetAccountsByTypeFilter from "../../../../components/C2Points/GetAccountsByTypeFilter";
import ModalQuestion from "../../../../components/Modal/ModalQuestion/ModalQuestion";
/* import GlobalAlert from "../../../../components/Alert/GlobalAlert/GlobalAlert"; */

export interface propFilter {
    buscar: any,
    billsReceive: any,
    error: any,
    clearActualValues?: any,
    defaultStartDate: any,
    defaultEndDate: any,
}

const FilterReportSalesPend: React.FC<propFilter> = ({
    buscar, billsReceive, error, clearActualValues, defaultStartDate, defaultEndDate
}) => {

    const { t } = useTranslation();

    //const [payment, setPayment] = useState<any>("");

    //const today = new Date();
    //const todayStr = `${today.getDate()}/${today.getMonth() + 1}/${today.getFullYear()}`;

    const [filterStatusFormaPagamento, setFilterStatusFormaPagamento] = useState<any>('101');
    const [filterTypeDate, setFilterTypeDate] = useState<any>(1);
    const [filterDate, setFilterDate] = useState<any>([defaultStartDate, defaultEndDate]);

    const [alert, ] = useState<any>();

    /* Date Interval Confirmation */
    const [dateIntervalConfirmModalShow, setDateIntervalConfirmModalShow] = useState<any>(false);
    /* END - Date Interval Confirmation */



    const handleFilterClick = (event: any) => {

        var dataIntervalLong = false;

        if (filterDate.length === 2) {
            let beginDate = filterDate[0].split('/')
            let endDate = filterDate[1].split('/')

            const dataIni = new Date(parseInt(beginDate[2]), parseInt(beginDate[1])-1, parseInt(beginDate[0]));
            const dataFim = new Date(parseInt(endDate[2]), parseInt(endDate[1])-1, parseInt(endDate[0]));

            const days = (dataFim.getTime() - dataIni.getTime()) / (1000*60*60*24);

            dataIntervalLong = (days > 90); // 3 meses
            
        }

        if(dataIntervalLong) {
            setDateIntervalConfirmModalShow(true);
        } else {
            handleActionBuscar();
        }
    }

    const handleActionBuscar = () => {
        setDateIntervalConfirmModalShow(false);
        buscar(filterStatusFormaPagamento, filterTypeDate, filterDate);
    };

    const handleClearClick = (event: any) => {

        let inputValue = document.querySelectorAll('input');
        for (let i = 0; i < inputValue.length; i++) {
            inputValue[i].value = "";
        }

        let selectValue = document.querySelectorAll('select');
        for (let i = 0; i < selectValue.length; i++) {
            selectValue[i].value = "0";
        }

        setFilterStatusFormaPagamento('101');
        setFilterTypeDate(1);
        setFilterDate([defaultStartDate, defaultEndDate]);
        buscar('101', 1, [defaultStartDate, defaultEndDate]);
    };

    const handleChangeStatusFormaPagamento = (e: React.ChangeEvent<HTMLInputElement>) => {
        const newValue = e.target.value;
        setFilterStatusFormaPagamento(newValue);
    }

    const handleChangeTypeDate = (e: React.ChangeEvent<HTMLInputElement>) => {
        const newValue = e.target.value;
        setFilterTypeDate(newValue);
    }

    

    return (
        <div className="suppliers-filter d-flex justify-content-center bg-white mt-4" style={{ textAlign: "left" }}>
            <Accordion defaultActiveKey="1">
                <Accordion.Item eventKey="0">
                    <Accordion.Header><FontAwesomeIcon icon={['fal', 'sliders-h']} size="lg" className="mr-2" /><span className="h5 mb-0">Buscar Contas</span></Accordion.Header>
                    <Accordion.Body className="pt-0">
                        <Container className="p-0" fluid>
                            <Row>
                                <div>
                                    <Form >
                                        <Row className="mb-3">
                                            {alert}
                                            <Form.Group as={Col} md="2">
                                                <Form.Label>Forma de Pagamento</Form.Label>
                                                <div className="endpoint">
                                                    <InputGroup hasValidation>
                                                        <Form.Select
                                                        autoComplete='off'
                                                            aria-describedby="inputGroupPrepend"
                                                            onChange={(e: any) => handleChangeStatusFormaPagamento(e)}
                                                            value={filterStatusFormaPagamento}
                                                        >
                                                            <option value="">Todos</option>
                                                            <option value="101">Cash, PIX, Transferência, Cartão e PicPay</option>
                                                            <option value="102">Faturado e Comissão a Receber</option>
                                                        </Form.Select>
                                                        {/* <Form.Control.Feedback type="invalid">
                                                        Please choose a username.
                                                    </Form.Control.Feedback> */}
                                                    </InputGroup>
                                                </div>
                                            </Form.Group>
                                            <Form.Group as={Col} md="2">
                                                <Form.Label>Tipo de Data</Form.Label>
                                                <div className="endpoint">
                                                    <InputGroup hasValidation>
                                                        <Form.Select
                                                        autoComplete='off'
                                                            aria-describedby="inputGroupPrepend"
                                                            onChange={(e: any) => handleChangeTypeDate(e)}
                                                            value={filterTypeDate}
                                                        >
                                                            <option value="2">Operação</option>
                                                            <option value="1">Reserva</option>
                                                        </Form.Select>
                                                    </InputGroup>
                                                </div>
                                            </Form.Group>
                                            <Form.Group as={Col} md="2">
                                                <Form.Label>Data</Form.Label>
                                                <InputGroup hasValidation className="ranger-calendar">
                                                    <RangeCalendar date={filterDate} setDate={setFilterDate} />
                                                </InputGroup>
                                            </Form.Group>
                                        </Row>
                                        
                                        <Row as={Col} md="12" className="mt-5 justify-content-md-end">
                                            <Col xs lg="12" className="d-flex justify-content-md-end">
                                                <Button variant="outline-primary" className="btn-default mr-2 text-uppercase" onClick={handleClearClick}>{t('partners.partnersfilter.btnClear')}</Button>
                                                <Button className="btn-default text-uppercase" onClick={handleFilterClick}>{t('partners.partnersfilter.btnSearch')}</Button>
                                            </Col>
                                        </Row>
                                    </Form>
                                </div>
                            </Row>
                        </Container>
                    </Accordion.Body>
                </Accordion.Item>
            </Accordion>
            <ModalQuestion
                modalShow={dateIntervalConfirmModalShow}
                setModalShow={setDateIntervalConfirmModalShow}
                titulo=""
                mensagem="Intervalo de data excede 3 meses. Continua?"
                handleActionConfirmModal={handleActionBuscar}
            />
        </div>
    )
}

export default FilterReportSalesPend;