/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useEffect, useState } from 'react';
import api from "../../../../services/api";
import { Accordion, Row, Col, Form, InputGroup, Badge } from "react-bootstrap";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import DoubleCalendarCombo from "../../../../components/Calendar/DoubleCalendarCombo";
import DropdownNumberPeopleCombo from '../../../../components/DropdownNumberPeopleCombo/DropdownNumberPeopleCombo';

import { useAppSelector, useAppDispatch } from "../../../../store/hooks";
import { addItemCart } from "../../../../store/cart";

import "./Accordion.scss"
import { useTranslation } from 'react-i18next';
import i18next from 'i18next';
import DropdownHours from '../../../../components/DropdownHours/DropdownHours';
import RefundRules from '../RefundRules/RefundRules';
import DropdownHoursFree from '../../../../components/DropdownHoursFree/DropdownHoursFree';
import DropdownHoursCombo from '../../../../components/DropdownHoursCombo/DropdownHoursCombo';

export interface tourDetails {
    productCode: any;
}

const AccordionCombo = ({ productCode }: any) => {
    const { t } = useTranslation();
    //const isIntegration = false;

    function ScrollTop() {
        window.scrollTo(0, 0);
    }

    const [accordionOpen, setAccordionOpen] = useState<boolean>(false);
    //const [allowCalendar, setAllowCalendar] = useState<any>(false);

    const [tourDetails, setTourDetails] = useState<any>(null);
    const [indexador, setIndexador] = useState<any>([]); ///array que me diz quais dos tourOptions vai habilitar o dropdown

    const [numberPeople, setNumberPeople] = useState<any>([]);
    const [dateTour, setDateTour] = useState<any>([]);

    const cartSIG = useAppSelector((state) => state.cart);

    const [ranges, setRanges] = useState<any>(null);
    const [infoCombo, setInfoCombo] = useState<any>([]);
    const [indexCombo, setIndexCombo] = useState<number>(0);
    const [totalPriceCombo, setTotalPriceCombo] = useState<number | null>(null);

    const [lang, setLang] = useState<string>("BR");

    const [preloader, setPreloader] = useState<boolean>(false);

    /* const [, setTariffs] = useState<any>({
        maxNumPeople: "0",
        minNumPeople: "0",
    }); */

    const [peopleDisponible, setPeopleDisponible] = useState<boolean>(false);

    useEffect(() => {
        if (i18next.language === "pt-BR" || i18next.language === "pt") {
            setLang("BR")
        } else if (i18next.language === "en") {
            setLang("EN")
        } else {
            setLang("ES")
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [i18next.language]);

    const dispatch = useAppDispatch();

    const getTourInfo = async (productCode: any) => {
        try {
            const data = await api.post(
                `/Products/FetchSingleComboAsync`,
                {
                    "productCode": productCode,
                    "lang": lang,
                    "idCanal": localStorage.getItem('idCanal') || 1
                }
            );
            if (data.status !== 400) {
                setTourDetails(data.data.data);
            }
        } catch (error) { }
    }

    function activeCalendar() {
        if (accordionOpen === false) {
            setAccordionOpen(true);
            getTourInfo(productCode);
        } else {
            setAccordionOpen(false);
        }
    }

    function changePriceDateTour(obj: any, index: number, isDateChange: boolean = false) {
        let newNumberPeople: any = numberPeople;
        newNumberPeople[index] = {
            "adults": 0,
            "childs": 0,
            "elders": 0,
            "globalPeople": 0,
            "infants": 0,
            "student": 0
        }
        setNumberPeople(newNumberPeople)

        if (isDateChange) {
            delete obj[index].time
            delete obj[index].adult
            delete obj[index].child
            delete obj[index].infant
            delete obj[index].student
            delete obj[index].tieldersme
            delete obj[index].global
            delete obj[index].maxStockNumber
        }

        setDateTour([...obj]);
    }

    function addIndex(index: any) {
        var aux: any = indexador;
        if (aux.includes(index) === false) {
            aux.push(index);
        }
        setIndexador(aux);
    }

    useEffect(() => {
        if (tourDetails !== null) {
            var aux: any = [];
            for (var i = 0; i < tourDetails.modalities.length; i++) {
                aux.push({
                    adult: 0,
                    child: 0,
                    infant: 0,
                })
            }
            setDateTour(aux);
        }

    }, [tourDetails])

    const setNewNumberPeople = (peoples: any, index: any) => {
        numberPeople[index] = peoples;
        setNumberPeople([...numberPeople]);
    };

    /*     const selectHours = (e: any) => {
            setHour(e.target.value);
            setTariffs({
                maxNumPeople: e.target.options[e.target.selectedIndex].dataset.capacity,
    
                minNumPeople: 1,
            });
        }; */

    function keepDropdownOpen() {
        var elem: any = document.getElementById("authCartDropdown");

        if (elem && !elem.classList.contains('active')) {
            elem.classList.add('active');
        }
    }

    /* const addToCart = (itemOption: any, index: any) => {
        const item = {
            productName: itemOption.productName,
            productNameEN: itemOption.productNameEN,
            productNameES: itemOption.productNameES,
            modalityName: itemOption.modalityName,
            imgCart: tourDetails.images[0],
            imagesBaseUrl: tourDetails.imagesBaseUrl,
            typeProduct: tourDetails.typeProduct,
            price: itemOption?.tarif?.price,

            idTarif: itemOption.idTarif,
            idPickup: itemOption.idPickup,
            reservationSystem: tourDetails.reservationSystem,

            priceAdults: itemOption.priceAdults,
            priceChilds: itemOption.priceChilds,
            priceInfants: itemOption.priceInfants,
            priceElders: itemOption.priceElders,
            priceStudent: itemOption.priceStudent,
            priceGlobalPeople: itemOption.priceGlobalPeople,


            sellingType: itemOption.idSellingType,
            adults: itemOption.adults,
            childs: itemOption.childs,
            infants: itemOption.infants,
            elders: itemOption.elders,
            student: itemOption.student,

            globalPeople: itemOption.globalPeople,
            totalPeople: itemOption.totalPeople,

            productType: 1,
            productCode: productCode,
            time: itemOption.time,
            date: itemOption.date,
            supplier: tourDetails.supplier,
            supplierFantasyName: tourDetails.supplierFantasyName,
            typePickup: itemOption.embarkingType,
            meetingPoint: itemOption.embarkingType === "0" ? itemOption.meetingPoint : '',
            pickupListId: itemOption.embarkingType === "0" ? 0 : itemOption.pickupListId,
            pickup: 0,

            discount: 0,
            customValueNet: 0,
            customValueSell: 0,
            goingSource: "null",
            commingSource: "null",
            latOrigem: "null",
            lngOrigem: "null",
            latDestino: "null",
            lngDestino: "null",
            cia: "null",
            voo: "null",
            smallSuitcase: 0,
            bigSuitcase: 0,
            internalNotes: " ",
            idVeiculo: 0,
            passengers: [],
            labelsSorted: itemOption.ranges.labelsSorted
        }
        dispatch(addItemCart(item));
    } */

    const addToCart = (itemOption: any) => {
        var repeatedItem: any = false; //impede de adicionar produto repetido no carrinho

        cartSIG.dados.forEach((elem: any) => {
            if (elem.comboCode === itemOption.productCode) {
                repeatedItem = true
            }
        });

        if (repeatedItem === true) {
            alert(`${t("sales.tourList.accordion.productExists")}`);
        } else {
            let comboObject: any = [];

            itemOption.modalities.forEach((elem: any, index: number) => {
                comboObject.push({
                    code: itemOption.productCode,
                    modalityName: elem.modalityName,
                    qtdAdulto: numberPeople[index].adults,
                    qtdChild: numberPeople[index].childs,
                    qtdInfant: numberPeople[index].infants,
                    qtdElders: numberPeople[index].elders,
                    qtdStudents: numberPeople[index].student,
                    qtdPrivativo: numberPeople[index].globalPeople,
                    productCode: elem.productCode,
                    idTarifario: dateTour[index].auxTarif[0].idTarifario,
                    date: dateTour[index].data,
                    time: dateTour[index].time,
                    productType: elem.productType,
                    pickupListId: elem.pickupListId,
                    //idPickup: 0,
                    supplierId: 414,
                    meetingPoint: elem.meetingPoint,
                    typePickup: elem.typePickup
                })
            });

            const item = {
                productName: itemOption.productName,
                productNameBR: itemOption.productNameBR,
                productNameEN: itemOption.productNameEN,
                productNameES: itemOption.productNameES,
                modalityName: "",
                imgCart: tourDetails.images[0],
                imagesBaseUrl: tourDetails.imagesBaseUrl,
                typeProduct: tourDetails.typeProduct,
                productType: 1,
                price: totalPriceCombo,

                idTarif: 0, //PENDENTE

                priceAdults: 112, //PENDENTE
                priceChilds: 112, //PENDENTE
                priceInfants: 112, //PENDENTE
                priceElders: 0, //PENDENTE
                priceStudent: 0, //PENDENTE
                priceGlobalPeople: 0, //PENDENTE
                reservationSystem: 1, //PENDENTE

                sellingType: 2, //PENDENTE
                adults: 1,
                childs: 2,
                infants: 0,
                elders: 0,
                student: 0,
                globalPeople: 0,

                comboObject, //add os treco do combo
                comboCode: itemOption.productCode,
                isCombo: true,
                productCode: "",
                time: dateTour[0].time,
                date: dateTour[0].data,
                supplier: itemOption.supplier,
                supplierFantasyName: itemOption.supplierFantasyName,
                typePickup: "0",
                meetingPoint: itemOption.eventLocation,
                pickupListId: 0,
                discount: 0,
                customValueNet: 0,
                customValueSell: 0,
                goingSource: "null",
                commingSource: "null",
                latOrigem: "null",
                lngOrigem: "null",
                latDestino: "null",
                lngDestino: "null",
                cia: "null",
                voo: "null",
                smallSuitcase: 0,
                bigSuitcase0: 0,
                idVeiculo: 0,
                passengers: [],
                labelsSorted: ["Adulto", "Criança", "Infante"]
            }

            dispatch(addItemCart(item));
            ScrollTop();
            keepDropdownOpen();
        }
    };

    const totalPriceComboCalculator = async () => {
        const obj = {
            "comboCode": tourDetails.productCode,
            "comboList": dateTour.map((elem: any, index: number) => {
                return ({
                    "qtdAdulto": numberPeople[index].adults,
                    "qtdChild": numberPeople[index].childs,
                    "qtdInfant": numberPeople[index].infants,
                    "qtdElders": numberPeople[index].elders,
                    "qtdStudents": numberPeople[index].student,
                    "idTarifario": elem.auxTarif[0].idTarifario,
                    "qtdPrivativo": numberPeople[index].globalPeople,
                    "productCode": tourDetails.modalities[tourDetails.modalities.findIndex((e: any) => e.tarCode === elem.tarUniqueCode)].productCode/* elem.productCode */,
                    "date": elem.data,
                    "time": elem.time,

                })
            })
        }

        const token = localStorage.getItem('GroupId') || '{}';
        const config = {
            headers: { 'Authorization': `Bearer ${token}`, 'Content-Type': 'application/json' },
        };

        try {
            const data = await api.post(`/Products/ComboCalculaPreco`, obj, config);
            if (data.status !== 400) {
                setTotalPriceCombo(data.data.data.data);
                /* setTourDetails(data.data.data); */
            }
        } catch (error) { }

        return <></>
    }

    useEffect(() => {
        if (tourDetails !== null && tourDetails?.modalities?.length === indexCombo) {
            totalPriceComboCalculator()
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [indexCombo])

    return (
        <div className="prod-options">
            <Accordion defaultActiveKey="1">
                <Accordion.Item eventKey="0">
                    <Accordion.Header onClick={activeCalendar}>Opções para esse combo</Accordion.Header>
                    {tourDetails !== null
                        ?
                        <>
                            {tourDetails.modalities.map((tour: any, index: any) => {
                                if (indexCombo === index) {
                                    return (
                                        <Accordion.Body key={index}>
                                            <Row>
                                                <div className="col-12 col-md-9">
                                                    <div className="d-flex flex-column justify-content-between h-100">
                                                        <div>
                                                            <h4 className="tour-name">
                                                                {`${tour.modalityName} - ${tour.productName}`}
                                                                {tour.rangeComissionLabel !== null && tour.rangeComissionLabel !== "DEFAULT"
                                                                    ?
                                                                    <span className='rangecomission'><Badge className={(tour.rangeComissionLabel).toString().toLowerCase()}></Badge> {tour.rangeComissionLabel}</span>
                                                                    :
                                                                    <></>
                                                                }
                                                            </h4>
                                                            <div className="sub-info">
                                                                <div className="d-flex flex-wrap mt-2">
                                                                    <div style={{ height: 'unset' }} className={tour.comboMarkup[0].markupDays[0].dom === 1 ? "weekDay enable" : "weekDay disable"}>{t("sales.tourList.accordion.weekDays.sun")}</div>
                                                                    <div style={{ height: 'unset' }} className={tour.comboMarkup[0].markupDays[0].seg === 1 ? "weekDay enable" : "weekDay disable"}>{t("sales.tourList.accordion.weekDays.mon")}</div>
                                                                    <div style={{ height: 'unset' }} className={tour.comboMarkup[0].markupDays[0].ter === 1 ? "weekDay enable" : "weekDay disable"}>{t("sales.tourList.accordion.weekDays.tue")}</div>
                                                                    <div style={{ height: 'unset' }} className={tour.comboMarkup[0].markupDays[0].qua === 1 ? "weekDay enable" : "weekDay disable"}>{t("sales.tourList.accordion.weekDays.wed")}</div>
                                                                    <div style={{ height: 'unset' }} className={tour.comboMarkup[0].markupDays[0].qui === 1 ? "weekDay enable" : "weekDay disable"}>{t("sales.tourList.accordion.weekDays.thu")}</div>
                                                                    <div style={{ height: 'unset' }} className={tour.comboMarkup[0].markupDays[0].sex === 1 ? "weekDay enable" : "weekDay disable"}>{t("sales.tourList.accordion.weekDays.fri")}</div>
                                                                    <div style={{ height: 'unset' }} className={tour.comboMarkup[0].markupDays[0].sab === 1 ? "weekDay enable" : "weekDay disable"}>{t("sales.tourList.accordion.weekDays.sat")}</div>
                                                                </div>
                                                            </div>
                                                            <div className="sub-info">
                                                                <small className='bg-sale-time'>
                                                                    {
                                                                        tour.activityStart === undefined || tour.activityStart === null
                                                                            ?
                                                                            ''
                                                                            :
                                                                            <span>
                                                                                <FontAwesomeIcon
                                                                                    icon={["fal", "clock"]}
                                                                                    size="1x"
                                                                                    className="mr-2"
                                                                                />
                                                                                {`${t("sales.tourList.accordion.tourStart")} ${tour.activityStart}`}
                                                                            </span>
                                                                    }
                                                                </small>
                                                            </div>
                                                            <Row className="my-2">
                                                                <div className="col-12 col-md-6">
                                                                    <h5 className="tour-name">{t("sales.tourList.accordion.includes")}</h5>
                                                                    <div className="session-not-yes yes">
                                                                        <small>
                                                                            {tour.includedItems?.map(
                                                                                (text: any, index: any) => {
                                                                                    return (
                                                                                        <p key={index}>
                                                                                            <FontAwesomeIcon
                                                                                                icon={["fal", "check"]}
                                                                                                size="1x"
                                                                                            />
                                                                                            {text.replace("•	", "")}
                                                                                        </p>
                                                                                    );
                                                                                }
                                                                            )}
                                                                        </small>
                                                                    </div>
                                                                </div>
                                                                <div className="col-12 col-md-6">
                                                                    <h5 className="tour-name">{t("sales.tourList.accordion.notIncludes")}</h5>
                                                                    <div className="session-not-yes not">
                                                                        <small>
                                                                            {tour.excludedItems?.map(
                                                                                (text: any, index: any) => {
                                                                                    return (
                                                                                        <p key={index}>
                                                                                            <FontAwesomeIcon
                                                                                                icon={["fal", "times"]}
                                                                                                size="1x"
                                                                                            />
                                                                                            {text.replace("•	", "")}
                                                                                        </p>
                                                                                    );
                                                                                }
                                                                            )}
                                                                        </small>
                                                                    </div>
                                                                </div>
                                                            </Row>
                                                        </div>

                                                        {/* <div className="bg-cancel-rules">
                                                        <RefundRules tourDetails={tour} />
                                                    </div> */}
                                                    </div>
                                                </div>
                                                <div className="col-12 col-md-3 m-auto text-center d-flex justify-content-center justify-content-md-end">
                                                    <div style={{ maxWidth: "300px", width: "100%" }}>
                                                        <div className="bg-accordion-options">
                                                            <Form>
                                                                <Form.Group as={Col}>
                                                                    <InputGroup
                                                                        hasValidation
                                                                        className=""
                                                                        placeholder="Quando?"
                                                                        id="date-"
                                                                        onClick={() => {
                                                                            addIndex(index);
                                                                        }}
                                                                    >
                                                                        <DoubleCalendarCombo
                                                                            modalityID={tour.id}
                                                                            product={tour}
                                                                            productCode={productCode}
                                                                            changePriceDateTour={changePriceDateTour}
                                                                            dateTour={dateTour}
                                                                            index={index}
                                                                            isTicket={false}
                                                                            infoCombo={infoCombo}
                                                                            setRanges={setRanges}
                                                                        />
                                                                    </InputGroup>
                                                                </Form.Group>
                                                                <div className="col-12 mb-2">
                                                                    {numberPeople !== null &&
                                                                        indexador?.includes(index) === true ? (
                                                                        preloader === false ? (
                                                                            <div className="panel-dropdown bg-white">
                                                                                {
                                                                                    dateTour[index]?.isFreeSale === 1
                                                                                        ?
                                                                                        <DropdownHoursFree
                                                                                            changePriceDateTour={changePriceDateTour}
                                                                                            dateTour={dateTour}
                                                                                            index={index}
                                                                                            setPeopleDisponible={setPeopleDisponible}
                                                                                            info={tourDetails}
                                                                                        />
                                                                                        :
                                                                                        <DropdownHoursCombo
                                                                                            changePriceDateTour={changePriceDateTour}
                                                                                            dateTour={dateTour}
                                                                                            index={index}
                                                                                            setPeopleDisponible={setPeopleDisponible}
                                                                                            info={tourDetails}
                                                                                        />
                                                                                }

                                                                            </div>
                                                                        ) : (
                                                                            <div className="panel-dropdown bg-white d-flex">
                                                                                <div style={{ height: "20px" }}>
                                                                                    <div className="load"></div>
                                                                                </div>
                                                                            </div>
                                                                        )
                                                                    ) : (
                                                                        <div className="panel-dropdown bg-white d-flex">
                                                                            <div style={{ height: "20px" }}></div>
                                                                        </div>
                                                                    )}
                                                                </div>
                                                                {/* <div className="col-12 my-2">
                                                                <div className="panel-dropdown bg-white">
                                                                    <DropdownNumberPeopleCombo
                                                                        actionPeople={setNewNumberPeople}
                                                                        dateTour={dateTour}
                                                                        index={index}
                                                                        infoCombo={infoCombo}
                                                                    />
                                                                </div>
                                                            </div> */}
                                                                <div className="col-12 my-2">
                                                                    <div className="panel-dropdown bg-white">
                                                                        {
                                                                            dateTour?.length > 0 && dateTour[index].data !== undefined && dateTour[index].time !== undefined
                                                                                ?
                                                                                <DropdownNumberPeopleCombo
                                                                                    actionPeople={setNewNumberPeople}
                                                                                    info={tourDetails}
                                                                                    dateTour={dateTour}
                                                                                    index={index}
                                                                                    defaultNumberPeople={numberPeople}
                                                                                    ranges={ranges[ranges.findIndex((elem: any) => elem.tarCode === tour?.tarCode)]}
                                                                                />
                                                                                :
                                                                                <></>
                                                                        }
                                                                    </div>
                                                                </div>
                                                                <span
                                                                    className="btn btn-options btn-primary btn-block btn-add-cart link-add"
                                                                    onClick={() => { setIndexCombo((prev: number) => { return (prev + 1) })/* auth(tour, index) */ }}
                                                                >

                                                                    {tourDetails.modalities.length !== indexCombo ?
                                                                        'Próximo'
                                                                        :
                                                                        <>
                                                                            <FontAwesomeIcon
                                                                                icon={["fal", "cart-arrow-down"]}
                                                                                size="1x"
                                                                                className="mr-2"
                                                                            />
                                                                            {t("sales.tourList.accordion.add")}
                                                                        </>
                                                                    }
                                                                </span>
                                                            </Form>
                                                        </div>
                                                    </div>
                                                </div>
                                            </Row>
                                        </Accordion.Body>
                                    )
                                } else {
                                    return (
                                        <>
                                            <Accordion.Body key={index}>
                                                <Row>
                                                    <div className="col-12 col-md-9">
                                                        <div className="d-flex flex-column h-100">
                                                            <div>
                                                                <h4 className="tour-name">
                                                                    {`${tour.modalityName} - ${tour.productName}`}
                                                                    {tour.rangeComissionLabel !== null && tour.rangeComissionLabel !== "DEFAULT"
                                                                        ?
                                                                        <span className='rangecomission'><Badge className={(tour.rangeComissionLabel).toString().toLowerCase()}></Badge> {tour.rangeComissionLabel}</span>
                                                                        :
                                                                        <></>
                                                                    }
                                                                </h4>
                                                            </div>
                                                            <div className="sub-info">
                                                                <div className="d-flex flex-wrap mt-2">
                                                                    <div style={{ height: 'unset' }} className={tour.comboMarkup[0].markupDays[0].dom === 1 ? "weekDay enable" : "weekDay disable"}>{t("sales.tourList.accordion.weekDays.sun")}</div>
                                                                    <div style={{ height: 'unset' }} className={tour.comboMarkup[0].markupDays[0].seg === 1 ? "weekDay enable" : "weekDay disable"}>{t("sales.tourList.accordion.weekDays.mon")}</div>
                                                                    <div style={{ height: 'unset' }} className={tour.comboMarkup[0].markupDays[0].ter === 1 ? "weekDay enable" : "weekDay disable"}>{t("sales.tourList.accordion.weekDays.tue")}</div>
                                                                    <div style={{ height: 'unset' }} className={tour.comboMarkup[0].markupDays[0].qua === 1 ? "weekDay enable" : "weekDay disable"}>{t("sales.tourList.accordion.weekDays.wed")}</div>
                                                                    <div style={{ height: 'unset' }} className={tour.comboMarkup[0].markupDays[0].qui === 1 ? "weekDay enable" : "weekDay disable"}>{t("sales.tourList.accordion.weekDays.thu")}</div>
                                                                    <div style={{ height: 'unset' }} className={tour.comboMarkup[0].markupDays[0].sex === 1 ? "weekDay enable" : "weekDay disable"}>{t("sales.tourList.accordion.weekDays.fri")}</div>
                                                                    <div style={{ height: 'unset' }} className={tour.comboMarkup[0].markupDays[0].sab === 1 ? "weekDay enable" : "weekDay disable"}>{t("sales.tourList.accordion.weekDays.sat")}</div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-12 col-md-3 m-auto text-center d-flex justify-content-center justify-content-md-end">
                                                        {
                                                            dateTour?.length > 0 && dateTour[index].data !== undefined && dateTour[index].time !== undefined &&
                                                            <>
                                                                <div className="d-flex flex-column w-100 align-items-end" style={{ maxWidth: "300px", padding: '5px 10px' }}>
                                                                    <div className="d-flex justify-content-between w-100">
                                                                        <div className="d-flex flex-column align-items-center">
                                                                            <FontAwesomeIcon
                                                                                icon={["fal", "calendar-alt"]}
                                                                                size="1x"
                                                                                style={{ color: "#000" }}
                                                                                className='text-primary'
                                                                            />
                                                                            {dateTour[index].data.split('-').reverse().join('/')}
                                                                        </div>
                                                                        <div className="d-flex flex-column align-items-center">
                                                                            <FontAwesomeIcon
                                                                                icon={["fal", "clock"]}
                                                                                className="size-icon-hours mr-2 text-primary"
                                                                                style={{ position: 'unset' }}
                                                                            />
                                                                            {dateTour[index].time}
                                                                        </div>
                                                                        <div className="d-flex flex-column align-items-center">
                                                                            <FontAwesomeIcon
                                                                                icon={["fal", "male"]}
                                                                                size="1x"
                                                                                className="mr-2 text-primary"
                                                                            />
                                                                            {`${numberPeople[index].adults + numberPeople[index].childs + numberPeople[index].infants + numberPeople[index].elders + numberPeople[index].student} Pessoas`}
                                                                        </div>
                                                                    </div>
                                                                    <div className='w-100'>
                                                                        <span
                                                                            className="btn btn-options btn-primary btn-block btn-add-cart link-add"
                                                                            onClick={() => { setIndexCombo(index) }}
                                                                            style={{ margin: '10px 0 0 0 ' }}
                                                                        >
                                                                            Voltar
                                                                        </span>
                                                                    </div>
                                                                </div>
                                                            </>
                                                        }
                                                    </div>
                                                </Row>
                                            </Accordion.Body>
                                        </>
                                    )
                                }
                            })}
                            {tourDetails.modalities.length === indexCombo &&
                                <>
                                    <Accordion.Body key={tourDetails.modalities.length}>
                                        <Row>
                                            <div className="col-12 col-md-12">
                                                <div className="d-flex flex-column justify-content-between h-100">
                                                    <div>
                                                        {totalPriceCombo !== null &&
                                                            <>
                                                                <span className="small text-muted w-100 d-flex justify-content-end">
                                                                    Total a pagar:
                                                                </span>
                                                                <div className="price price-add w-100 d-flex justify-content-end">
                                                                    <h5>
                                                                        R${" "}
                                                                        {(totalPriceCombo).toFixed(2).replace(".", ",")}
                                                                    </h5>
                                                                </div>
                                                                <div className='w-100 d-flex justify-content-end'>

                                                                    <span
                                                                        className="btn btn-options btn-primary btn-block btn-add-cart link-add"
                                                                        style={{ maxWidth: '300px' }}
                                                                        onClick={() => addToCart(tourDetails)}
                                                                    >
                                                                        <FontAwesomeIcon
                                                                            icon={["fal", "cart-arrow-down"]}
                                                                            size="1x"
                                                                            className="mr-2"
                                                                        />
                                                                        {t("sales.tourList.accordion.add")}
                                                                    </span>
                                                                </div>
                                                            </>
                                                        }
                                                    </div>
                                                </div>
                                            </div>
                                        </Row>
                                    </Accordion.Body>
                                </>
                            }
                        </>
                        :
                        <Accordion.Body>
                            <Row>
                                <div className="col-12 col-md-9">
                                    <div className="animated-background" style={{ height: "35px", width: "100%" }}></div>
                                    <div className="animated-background my-2" style={{ height: "25px", width: "100%" }}></div>
                                    <Row className="my-2">
                                        <div className="col-12 col-md-6">
                                            <div className="animated-background mt-2" style={{ height: "30px", width: "100%" }}></div>
                                            <div>
                                                <div className="animated-background my-2" style={{ height: "20px", width: "100%" }}></div>
                                                <div className="animated-background my-2" style={{ height: "20px", width: "100%" }}></div>
                                                <div className="animated-background my-2" style={{ height: "20px", width: "100%" }}></div>
                                                <div className="animated-background my-2" style={{ height: "20px", width: "100%" }}></div>
                                            </div>
                                        </div>
                                        <div className="col-12 col-md-6">
                                            <div className="animated-background mt-2" style={{ height: "30px", width: "100%" }}></div>
                                            <div>
                                                <div className="animated-background my-2" style={{ height: "20px", width: "100%" }}></div>
                                                <div className="animated-background my-2" style={{ height: "20px", width: "100%" }}></div>
                                                <div className="animated-background my-2" style={{ height: "20px", width: "100%" }}></div>
                                                <div className="animated-background my-2" style={{ height: "20px", width: "100%" }}></div>
                                            </div>
                                        </div>
                                    </Row>
                                </div>
                                <div className="col-12 col-md-3 m-auto text-center d-flex justify-content-end">
                                    <div style={{ maxWidth: "300px", width: "100%" }}>
                                        <div className="animated-background my-2" style={{ height: "40px", width: "100%" }}></div>
                                        <div className="animated-background my-2" style={{ height: "30px", width: "100%" }}></div>
                                        <div className="animated-background my-2" style={{ height: "30px", width: "100%" }}></div>
                                        <div className="animated-background my-2" style={{ height: "30px", width: "100%" }}></div>
                                        <div className="animated-background my-2" style={{ height: "30px", width: "100%" }}></div>
                                    </div>
                                </div>
                            </Row>
                        </Accordion.Body>
                    }
                </Accordion.Item>
            </Accordion>
        </div>

    );
}

export default AccordionCombo;