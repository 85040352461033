import React, { useState } from "react";
import api from '../../../../services/api';

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Modal, Dropdown } from "react-bootstrap";

import { useTranslation } from 'react-i18next';

import "react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css";
import "react-bootstrap-table-next/dist/react-bootstrap-table2.css";
import BootstrapTable from "react-bootstrap-table-next";

//STYLES
import "./TableSuppliersTariff.scss";
import Pagination from "../../../../components/Pagination/Pagination";
//import ModalDefault from "../../../../components/Modal/ModalDefault/ModalDefault";
import ModalQuestion from "../../../../components/Modal/ModalQuestion/ModalQuestion";

export interface propTable {
  billsPay: any;
  totalRows: any;
  pageCount: any;
  setPageCount: any;
  rowsPerPage: any;
  setRowsPerPage: any;
  exportBills: any;
  printBills: any;
  parameters: any;
  totalPay: any;
  loading: any;
  refreshData?: any;
}

const TableSuppliersTariff: React.FC<propTable> = ({
  billsPay, totalRows, pageCount, setPageCount, rowsPerPage, setRowsPerPage, exportBills, printBills, parameters, totalPay, loading, refreshData
}: propTable) => {
  /* VARIÁVEIS */
  const { t } = useTranslation();

  /* Success Modal (Inserir) */
  const [modalSuccess, setModalSuccess] = useState<any>(false);
  const [responseText, setResponseText] = useState<any>();
  const [modalContent, setModalContent] = useState<any>();
  const [log, setLog] = useState<any>();
  /* END - Success Modal (Inserir) */

  const [selectedSingleRow, setSelectedSingleRow] = useState<any>();

  const [deleteTariffConfirmModalShow, setDeleteTariffConfirmModalShow] = useState<any>(false);

  function deleteTariff(cell: any) {
    setSelectedSingleRow(cell);
    setDeleteTariffConfirmModalShow(true);
  }

  function handleActionDeleteTariff() {
    setDeleteTariffConfirmModalShow(false);

    const token = localStorage.getItem('GroupId') || '{}';
    const config = {
      headers: { 'Authorization': `Bearer ${token}` },
    };
    async function doDeleteTariff() {
      try {
        const { data } = await api.post(`/SupplierTariff/DeleteSupplierTariffAsync`, {
          tarifarioId: selectedSingleRow
        }, config);
        if (data.status !== 400) {
          
          if(data.data.log !== 1) {
            setResponseText(data.data.texto);
            setModalContent('success');
          } else {
            setResponseText(data.data.texto);
            setModalContent('error');
          }
          
          setLog(data.data.log);
          setModalSuccess(true);
          /* ; */
        }
      } catch (error: any) {
        if (error.response.status === 401) {
          window.location.href = window.location.origin + '/';
        }
      }
    }
    doDeleteTariff();
  }

  /* SOBRE A TABELA */

  function addActionButton(cell: any, row: any) {
    return (
      <>
        <div className="d-flex justify-content-center">
          <Dropdown drop="start">
            <Dropdown.Toggle variant="light ">
              <FontAwesomeIcon size="lg" icon={["fal", "ellipsis-h"]} />
            </Dropdown.Toggle>

            <Dropdown.Menu>
              
              <Dropdown.Item
                eventKey="1"
                href={"/supplier-tariff/edit-tariff?id=" + cell}
              >
                <FontAwesomeIcon
                  icon={['fal', 'edit']}
                  //className="mr-2"
                />
                {t('suppliersTable.edit')}
              </Dropdown.Item>
              <Dropdown.Item
                eventKey="1"
                onClick={() => deleteTariff(cell)}
              >
                <FontAwesomeIcon
                  icon={['fal', 'trash-alt']}
                  //className="mr-2"
                />
                {t('suppliersTable.remove')}
              </Dropdown.Item>
              <Dropdown.Item
                eventKey="1"
                href={"/supplier-tariff/copy-tariff?id=" + cell}
              >
                <FontAwesomeIcon
                  icon={['fal', 'copy']}
                  //className="mr-2"
                />
                Criar Novo com mesma tabela valores
              </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
        </div>
      </>
    );
  }

  const columns = [
    { dataField: "tipo", text: "Tipo", sort: true },
    { dataField: "produto", text: "Produto", sort: true  },
    { dataField: "fornecedor", text: "Fornecedor", sort: true  },
    { dataField: "pessoaRecurso", text: "Pessoa/Recurso?", sort: true  },
    { dataField: "categoria", text: "Veiculo/Categoria/Tipo", sort: true  },
    { dataField: "id", text: "Ação", formatter: addActionButton },
  ];

  /* loading table */

  function loadingFunc() {
    return (
      <>
        <div className="animated-background row-loading"></div>
      </>
    )
  }

  const loadingColumns = [{ dataField: "contasd", text: "Carregando Tarifários", formatter: loadingFunc }];

  const loadingProducts = [
    { contas: "" }, { contas: "" }, { contas: "" }, { contas: "" }, { contas: "" },
    { contas: "" }, { contas: "" }, { contas: "" }, { contas: "" }, { contas: "" }
  ];
  /* [END] loading table */

  /* In case of empty table*/
  const notFoundColumns = [{ dataField: "tarifarios", text: "Tarifários" }];

  const notFoundProducts = [{ contas: "Nenhum tarifario encontrado" }];
  /* [END] In case of empty table*/

  const rowClasses = (row: any, rowIndex: any) => {
    /* console.log(row, rowIndex) */
    //if (row.statusPagamento === "Conciliado") {
      return 'border-analise';
    //} else if (row.statusPagamento === "Pago") {
    //  return 'border-ativo';
    //} else {
    //  return 'border-inativo';
    //}
  };

  /* FORMULÁRIO */



  /* RENDER */
  if (billsPay !== null) {
    /* console.log(props.suppliers) */
    return (
      <>
        <div className="table-default table-suppliers-tariff">
          <div className="table-container">
            <div>
              <div className="d-flex justify-content-between">
                <div className="d-flex flex-wrap align-items-center" style={{ gap: "15px" }}>
                  {/* <div>
                    <div style={{ cursor: 'pointer' }} className="btn btn-bills" onClick={exportBills}>
                      <FontAwesomeIcon
                        icon={["fal", "share"]}
                        size="1x"
                        style={{ marginRight: "5px" }}
                      />
                      Exportar
                    </div>
                  </div>
                  <div>
                    <div style={{ cursor: 'pointer' }} className="btn btn-bills" onClick={printBills}>
                      <FontAwesomeIcon
                        file-invoice
                        icon={["fal", "print"]}
                        size="1x"
                        style={{ marginRight: "5px" }}
                      />
                      Imprimir
                    </div>
                  </div> */}
                </div>
              </div>
            </div>
            <BootstrapTable
              bootstrap4
              keyField="id"
              data={billsPay}
              columns={columns}
              rowClasses={rowClasses}
            />
            <Pagination
              totalRows={totalRows}
              pageCount={pageCount}
              setPageCount={setPageCount}
              rowsPerPage={rowsPerPage}
              setRowsPerPage={setRowsPerPage}
              selectNumberRows="yes"
            />
          </div>
        </div>
        <ModalQuestion
          modalShow={deleteTariffConfirmModalShow}
          setModalShow={setDeleteTariffConfirmModalShow}
          titulo=""
          mensagem="Deseja excluir o item?"
          handleActionConfirmModal={handleActionDeleteTariff}
        />
        {/* Modal de Success */}
        <Modal
          className={"modal-confirm loading-modal"}
          show={modalSuccess}
          onHide={() => setModalSuccess(false)}
          size="lg"
          aria-labelledby="contained-modal-title-vcenter"
        >
          <>
            <Modal.Body className='modal-body text-center sucess-pos'>
              {
                modalContent !== "loading"
                  ?
                  <>
                    <div>
                      {
                        log === 2 || log === "2"
                          ?
                          <FontAwesomeIcon
                            icon={["fal", "exclamation-circle"]}
                            size="5x"
                            className="text-primary"
                            style={{ fontSize: "7.5em" }}
                          />
                          :
                          log === 1 || log === "1"
                            ?
                            <FontAwesomeIcon
                              icon={["fal", "times-circle"]}
                              size="5x"
                              className="text-primary"
                              style={{ fontSize: "7.5em" }}
                            />
                            :
                            log === 0 || log === "0"
                              ?
                              <FontAwesomeIcon
                                icon={["fal", "check-circle"]}
                                size="5x"
                                className="text-primary"
                                style={{ fontSize: "7.5em" }}
                              />
                              :
                              <FontAwesomeIcon
                                icon={["fal", "question-circle"]}
                                size="5x"
                                className="text-primary"
                                style={{ fontSize: "7.5em" }}
                              />
                      }
                    </div>
                    <div>
                      {responseText}
                    </div>
                    <div className="d-flex justify-content-center">
                      {
                        modalContent === 'success'
                          ?
                          <button
                            onClick={() => {refreshData(); setModalSuccess(false);}}
                            className="btn btn-primary mx-2 w-25"
                          >
                            Confirmar
                          </button>
                          :
                          modalContent === 'warning'
                            ?
                            <button
                              onClick={() => setModalSuccess(false)}
                              className="btn btn-primary mx-2 w-25"
                            >
                              Confirmar
                            </button>
                            :
                            <button
                              onClick={() => setModalSuccess(false)}
                              className="btn btn-primary mx-2 w-25"
                            >
                              Confirmar
                            </button>
                      }
                    </div>
                  </>
                  :
                  <div className="modal-body">
                    <div className="text-center">
                      <div className="load"></div>
                    </div>
                    <div>
                      <span>Processando</span>
                    </div>
                    <div></div>
                  </div>
              }
            </Modal.Body>
          </>
        </Modal>
        {/* END - Modal de Success */}
      </>
    );
  } else if (billsPay === null && loading === true) {
    return (
      <>
        <div className="table-default bills-pay-table loading not-found">
          <div className="table-container">
            <div>
              <div className="d-flex justify-content-between">
                <div className="d-flex flex-wrap align-items-center" style={{ gap: "15px" }}>
                  {/* <div>
                    <div style={{ cursor: 'pointer' }} className="btn btn-bills" onClick={exportBills}>
                      <FontAwesomeIcon
                        icon={["fal", "share"]}
                        size="1x"
                        style={{ marginRight: "5px" }}
                      />
                      Exportar
                    </div>
                  </div>
                  <div>
                    <div style={{ cursor: 'pointer' }} className="btn btn-bills" onClick={printBills}>
                      <FontAwesomeIcon
                        file-invoice
                        icon={["fal", "print"]}
                        size="1x"
                        style={{ marginRight: "5px" }}
                      />
                      Imprimir
                    </div>
                  </div> */}
                </div>
              </div>
            </div>
            <BootstrapTable
              bootstrap4
              keyField="id"
              data={loadingProducts}
              hover={true}
              columns={loadingColumns}
            />
          </div>
        </div>
      </>
    )
  } else {
    return (
      <>
        <div className="table-default bills-pay-table not-found">
          <div className="table-container">
            {/*             <div className="table-title">
              <h3 className="">Fornecedores</h3>
            </div> */}

            <BootstrapTable
              bootstrap4
              keyField="id"
              data={notFoundProducts}
              hover={true}
              columns={notFoundColumns}
            />
          </div>
        </div>
      </>
    );
  }
}

export default TableSuppliersTariff;
