import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useState } from "react";
import { Calendar } from "react-multi-date-picker";
import Slider from "react-slick";

export interface propPlus {
  changeDate: any,
  selectedRange: any,
};

const DateSlider: React.FC<propPlus> = ({
  changeDate, selectedRange
}: propPlus) => {

  const [maxDate, setMaxDate] = useState<any>();
  const [minDate, setMinDate] = useState<any>();

  const [dates, setDates] = useState<Date[]>([]);

  // const [dates, setDates] = useState<any>([]);

  const [indexDates, setIndexDates] = useState<any>(0);

  const getDates = (firsDateClicked: any = new Date(selectedRange.startDate)) => {
    const firstStockDay: any = firsDateClicked;
    const lastStockDay: any = new Date(selectedRange.endDate);
    const datesArray: any = [];

    const diffInMs: any = Number(lastStockDay - firstStockDay)
    const diffInDays: any = diffInMs / (1000 * 60 * 60 * 24); //diferença de dias entre o inicio e fim do tarifário

    for (let i = 0; i < (diffInDays >= 5 ? 6 : diffInDays); i++) {
      const newDate = new Date(firstStockDay);
      newDate.setDate(firstStockDay.getDate() + i);
      datesArray.push(newDate);
    }

    setDates(datesArray);
    changeDia(firsDateClicked.toJSON().slice(0, 10) ,0);
  };

  useEffect(() => {
    setMaxDate(() => new Date(selectedRange.endDate))
    setMinDate(() => new Date(selectedRange.startDate))

    getDates();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedRange]);

  function changeDia(data: any, index: any) {
    setIndexDates(index);
    changeDate(() => data.split('/').reverse().join('-'))
  }

  const settings = {
    dots: false,
    arrows: false,
    infinite: false,
    speed: 500,
    slidesToShow: 5,
    slidesToScroll: 1,
  };


  return (
    <>
      {indexDates !== 'mais'
        ?
        <>
          <Slider {...settings}>
            {dates.map((date, index) => {
              return (
                <div key={date.getTime()} className="">
                  <div onClick={() => changeDia(date.toLocaleDateString('pt-BR'), index)} className={index === indexDates ? 'bg-primary rounded text-white' : ''} style={{ padding: '10px', margin: '10px', display: 'flex', flexDirection: 'column', alignItems: 'center' }} >
                    <p className="mb-0" style={{ fontSize: '32px', fontWeight: 700 }}>{date.toLocaleDateString('pt-BR').split('/')[0]}</p>
                    {date.toLocaleDateString('pt-BR', { weekday: 'short' }).split('.')[0]}
                  </div>
                </div>
              );
            })}

            <div className="">
              <div onClick={() => setIndexDates('mais')} className={indexDates === 'mais' ? 'bg-primary rounded text-white' : 'bg-secundary rounded'} style={{ padding: '10px', margin: '10px', display: 'flex', flexDirection: 'column', alignItems: 'center', height: '102px', justifyContent: 'center' }} >
                <FontAwesomeIcon className="" icon={['fal', 'plus']} size="2x" />
                <small>Ver mais</small>
              </div>
            </div>
          </Slider>
        </>
        :
        <>
          <Calendar
            maxDate={maxDate}
            minDate={minDate}
            onChange={(e: any) => { getDates(new Date(e)) }}
          />
        </>
      }
    </>
  );
};
export default DateSlider;