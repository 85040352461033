/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import api from '../../services/api';

import DesktopDefault from '../../templates/DesktopDefault';

import './GroupsAndCustomized.scss'

//COMPONENTS
import Breadcrumb from '../../components/Breadcrumb/Breadcrumb';
import FilterGroupFiles from './components/FilterGroupFiles/FilterGroupFiles';
import TableGroupFiles from './components/TableGroupFiles/TableGroupFiles';

function BillsToReceive() {
    const { t } = useTranslation();

    const today = new Date();
    //const todayStr = `${today.getDate()}/${today.getMonth() + 1}/${today.getFullYear()}`;

    const yesterday = new Date(today.getFullYear(), today.getMonth(), today.getDate() - 1); // hoje - 1 dia = ontem
    const defaultDate = `${yesterday.getDate()}/${yesterday.getMonth()+1}/${yesterday.getFullYear()}`;

    const [actualFileNo, setActualFileNo] = useState<any>("");
    const [actualClient, setActualClient] = useState<any>("");
    const [actualTypeDate, setActualTypeDate] = useState<any>(2);
    const [actualDate, setActualDate] = useState<any>([defaultDate]);
    const [actualOperationStatus, setActualOperationStatus] = useState<any>('');
    const [actualFinancialStatus, setActualFinancialStatus] = useState<any>('');
    const [actualGroupName, setActualGroupName] = useState<any>("");

    const [billsReceive, setBillsReceive] = useState<any>(null);
    const [parameters, setParameters] = useState<any>(null);
    //const [totalBillsReceive, setTotalBillsReceive] = useState<any>(0);
    const [error, setError] = useState<any>();
    const [loading, setLoading] = useState<any>(false);

    /* Pagination */
    const [totalRows, setTotalRows] = useState<any>();
    const [pageCount, setPageCount] = useState<any>(1);
    const [rowsPerPage, setRowsPerPage] = useState<any>(10);
    /* END - Pagination */

    const [searchOk,setSearchOk] = useState<any>(false);

    async function refreshData() {
        setSearchOk(true);
        setLoading(true);

        let body: any = document.querySelector("body");
        if (body.className !== "modal-open") {
            setBillsReceive(null);
        }

        let beginDate: any = []
        let endDate: any = []

        if (actualDate.length === 1) {
            beginDate = actualDate[0].split('/')
        } else if (actualDate.length === 2) {
            beginDate = actualDate[0].split('/')
            endDate = actualDate[1].split('/')
        }

        let today: any = new Date();

        const token = localStorage.getItem('GroupId') || '{}';
        const config = {
            headers: { 'Authorization': `Bearer ${token}`, 'Content-Type': 'application/json' },
        };

        async function listBills() {
            try {
                const { data } = await api.post('GroupFile/GetReport',
                    {
                        "page": pageCount,
                        "rowsPerPage": rowsPerPage,
                        "fileNo": actualFileNo ? actualFileNo : '',
                        "clientIdentity": actualClient ? actualClient : null,
                        "operationStatus": actualOperationStatus ? parseInt(actualOperationStatus) : 0,
                        "financialStatus": actualFinancialStatus ? parseInt(actualFinancialStatus) : 0,
                        "groupName": actualGroupName ? actualGroupName : '',
                        "dateType": actualTypeDate ? parseInt(actualTypeDate) : 2,
                        "startDate": (actualDate.length > 0) ? `${beginDate[2]}-${beginDate[1]}-${beginDate[0]}` : `${today.getFullYear()}-${today.getMonth() + 1}-${today.getDate()}`,
                        "endDate": (actualDate.length === 1) ? `${beginDate[2]}-${beginDate[1]}-${parseInt(beginDate[0])}` : (actualDate !== null && actualDate.length === 2) ? `${endDate[2]}-${endDate[1]}-${endDate[0]}` : `${today.getFullYear()}-${today.getMonth() + 1}-${today.getDate()}`
                    }, config
                );
                setLoading(false);
                if (data.status !== 400) {
                    setParameters({
                        "page": pageCount,
                        "rowsPerPage": rowsPerPage,
                        "fileNo": actualFileNo ? actualFileNo : '',
                        "clientIdentity": actualClient ? actualClient : null,
                        "operationStatus": actualOperationStatus ? parseInt(actualOperationStatus) : 0,
                        "financialStatus": actualFinancialStatus ? parseInt(actualFinancialStatus) : 0,
                        "groupName": actualGroupName ? actualGroupName : '',
                        "dateType": actualTypeDate ? parseInt(actualTypeDate) : 2,
                        "startDate": (actualDate.length > 0) ? `${beginDate[2]}-${beginDate[1]}-${beginDate[0]}` : `${today.getFullYear()}-${today.getMonth() + 1}-${today.getDate()}`,
                        "endDate": (actualDate.length === 1) ? `${beginDate[2]}-${beginDate[1]}-${parseInt(beginDate[0])}` : (actualDate !== null && actualDate.length === 2) ? `${endDate[2]}-${endDate[1]}-${endDate[0]}` : `${today.getFullYear()}-${today.getMonth() + 1}-${today.getDate()}`
                    });
                    setBillsReceive(data.data.rows);
                    setTotalRows(data.data.rowsCount);
                    //setTotalBillsReceive(data.data.valorTotal);
                }
            } catch (error: any) {
                setTimeout(() => {
                    listBills();
                }, 5000);
            }
        }
        listBills();
    }

    useEffect(() => {
        //if(searchOk === true) 
        refreshData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [pageCount, rowsPerPage]);

    async function buscar(
        fileNo: any,
        client: any,
        typeDate: any,
        date: any,
        operationStatus: any,
        financialStatus: any,
        groupName: any
    ) {
        setSearchOk(true);
        setActualFileNo(fileNo);
        setActualClient(client);
        setActualTypeDate(typeDate);
        setActualDate(date);
        setActualOperationStatus(operationStatus);
        setActualFinancialStatus(financialStatus);
        setActualGroupName(groupName);

        setPageCount(1)

        setLoading(true);
        let body: any = document.querySelector("body");
        if (body.className !== "modal-open") {
            setBillsReceive(null);
        }

        const token = localStorage.getItem('GroupId') || '{}';
        const config = {
            headers: { 'Authorization': `Bearer ${token}` },
        };

        let beginDate = []
        let endDate = []


        if (date.length === 1) {
            beginDate = date[0].split('/')
        } else if (date.length === 2) {
            beginDate = date[0].split('/')
            endDate = date[1].split('/')
        }

        let today: any = new Date();

        try {
            const res = await api.post('/GroupFile/GetReport',
                {
                    "page": 1,
                    "rowsPerPage": rowsPerPage,
                    "fileNo": fileNo ? fileNo : '',
                    "clientIdentity": client && client !== '0' ? client : null,
                    "operationStatus": operationStatus ? parseInt(operationStatus) : 0,
                    "financialStatus": financialStatus ? parseInt(financialStatus) : 0,
                    "groupName": groupName ? groupName : '',
                    "dateType": typeDate ? parseInt(typeDate) : 2,
                    "startDate": (date.length > 0) ? `${beginDate[2]}-${beginDate[1]}-${beginDate[0]}` : `${today.getFullYear()}-${today.getMonth() + 1}-${today.getDate()}`,
                    "endDate": (date.length === 1) ? `${beginDate[2]}-${beginDate[1]}-${parseInt(beginDate[0])}` : (date !== null && date.length === 2) ? `${endDate[2]}-${endDate[1]}-${endDate[0]}` : `${today.getFullYear()}-${today.getMonth() + 1}-${today.getDate()}`
                }, config
            );
            setLoading(false);
            if (res.status !== 400) {
                setParameters({
                    "page": 0,
                    "rowsPerPage": 0,
                    "fileNo": fileNo ? fileNo : '',
                    "clientIdentity": client && client !== '0' ? client : null,
                    "operationStatus": operationStatus ? parseInt(operationStatus) : 0,
                    "financialStatus": financialStatus ? parseInt(financialStatus) : 0,
                    "groupName": groupName ? groupName : '',
                    "dateType": typeDate ? parseInt(typeDate) : 2,
                    "startDate": (date.length > 0) ? `${beginDate[2]}-${beginDate[1]}-${beginDate[0]}` : `${today.getFullYear()}-${today.getMonth() + 1}-${today.getDate()}`,
                    "endDate": (date.length === 1) ? `${beginDate[2]}-${beginDate[1]}-${parseInt(beginDate[0])}` : (date !== null && date.length === 2) ? `${endDate[2]}-${endDate[1]}-${endDate[0]}` : `${today.getFullYear()}-${today.getMonth() + 1}-${today.getDate()}`
                });
                setBillsReceive(res.data.data.rows);
                setTotalRows(res.data.data.rowsCount);
                //setTotalBillsReceive(res.data.data.valorTotal);
            }
        } catch (error: any) {
            setError(true)
            if (error?.response?.status === 401) {
                window.location.href = window.location.origin + '/';
            }
        }
    };

    async function exportBills(layout: any) {
        //console.log(layout, actualStatusFormaPagamento)
        let allowRequest: boolean = true;

        let beginDate = []
        let endDate = []

        if (actualDate.length === 1) {
            beginDate = actualDate[0].split('/')
        } else if (actualDate.length === 2) {
            beginDate = actualDate[0].split('/')
            endDate = actualDate[1].split('/')
        }

        let today: any = new Date();

        //if (layout === 4 && parseInt(actualStatusFormaPagamento) !== 4) { ///quando for cartão de credito so deixa exportar quando for filtro forma pagamento igual a cartão
        //    allowRequest = false;
        //}

        if (allowRequest === true) {
            const token = localStorage.getItem('GroupId') || '{}';
            const config: any = {
                headers: { 
                    'Authorization': `Bearer ${token}`, 
                    'Content-Type': 'application/json',
                    "Accept": "*/*"
                    //"Access-Control-Allow-Origin": "*"
                },
                responseType: 'blob'
            };
            try {
                const exp = await api.post('/GroupFile/ExportReport',
                    {
                        "page": 1,
                        "rowsPerPage": 0, // pagina unica
                        "fileNo": actualFileNo ? actualFileNo : '',
                        "clientIdentity": actualClient ? actualClient : null,
                        "operationStatus": actualOperationStatus ? parseInt(actualOperationStatus) : 0,
                        "financialStatus": actualFinancialStatus ? parseInt(actualFinancialStatus) : 0,
                        "groupName": actualGroupName ? actualGroupName : '',
                        "dateType": actualTypeDate ? parseInt(actualTypeDate) : 2,
                        "startDate": (actualDate.length > 0) ? `${beginDate[2]}-${beginDate[1]}-${beginDate[0]}` : `${today.getFullYear()}-${today.getMonth() + 1}-${today.getDate()}`,
                        "endDate": (actualDate.length === 1) ? `${beginDate[2]}-${beginDate[1]}-${parseInt(beginDate[0])}` : (actualDate !== null && actualDate.length === 2) ? `${endDate[2]}-${endDate[1]}-${endDate[0]}` : `${today.getFullYear()}-${today.getMonth() + 1}-${today.getDate()}`
                    },
                    config

                );
                if (exp.status !== 400) {
                    var hoje: any;
                    var filename: any = 'Grupos e Customizados.xlsx';


                    const blob = new Blob([exp.data], { type: exp.headers["Content-Type"] });
                    const objectUrl = window.URL.createObjectURL(blob);

                    const link = document.createElement('a');
                    link.href = objectUrl;
                    link.setAttribute(
                        'download',
                        filename,
                    );

                    // Append to html link element page
                    document.body.appendChild(link);

                    // Start download
                    link.click();

                    // // Clean up and remove the link
                    link?.parentNode?.removeChild(link);
                }
            } catch (error: any) {
                setError(true)
                if (error?.response?.status === 401) {
                    window.location.href = window.location.origin + '/';
                }
            }
        }
    };

    async function printBills() {

        let beginDate = []
        let endDate = []

        if (actualDate.length === 1) {
            beginDate = actualDate[0].split('/')
        } else if (actualDate.length === 2) {
            beginDate = actualDate[0].split('/')
            endDate = actualDate[1].split('/')
        }

        let today: any = new Date();

        const token = localStorage.getItem('GroupId') || '{}';
        const config: any = {
            headers: { 
                'Authorization': `Bearer ${token}`, 
                'Content-Type': 'application/json',
                "Accept": "*/*"
                //"Access-Control-Allow-Origin": "*"
            },
            responseType: 'blob'
        };
        
        try {
            const print = await api.post('/GroupFile/PrintReport',
                {
                    "page": 1,
                    "rowsPerPage": 0, // pagina unica
                    "fileNo": actualFileNo ? actualFileNo : '',
                    "clientIdentity": actualClient ? actualClient : null,
                    "operationStatus": actualOperationStatus ? parseInt(actualOperationStatus) : 0,
                    "financialStatus": actualFinancialStatus ? parseInt(actualFinancialStatus) : 0,
                    "groupName": actualGroupName ? actualGroupName : '',
                    "dateType": actualTypeDate ? parseInt(actualTypeDate) : 2,
                    "startDate": (actualDate.length > 0) ? `${beginDate[2]}-${beginDate[1]}-${beginDate[0]}` : `${today.getFullYear()}-${today.getMonth() + 1}-${today.getDate()}`,
                    "endDate": (actualDate.length === 1) ? `${beginDate[2]}-${beginDate[1]}-${parseInt(beginDate[0])}` : (actualDate !== null && actualDate.length === 2) ? `${endDate[2]}-${endDate[1]}-${endDate[0]}` : `${today.getFullYear()}-${today.getMonth() + 1}-${today.getDate()}`
                },
                config

            );
            if (print.status !== 400) {
                const blob = new Blob([print.data], { type: print.headers["Content-Type"] });
                const objectUrl = window.URL.createObjectURL(blob);

                const link = document.createElement('a');
                link.href = objectUrl;
                link.setAttribute(
                    'download',
                    'grupos_e_customizados.pdf',
                );

                // Append to html link element page
                document.body.appendChild(link);

                // Start download
                link.click();

                // // Clean up and remove the link
                link?.parentNode?.removeChild(link);


            }
        } catch (error: any) {
            setError(true)
            if (error?.response?.status === 401) {
                window.location.href = window.location.origin + '/';
            }
        }
    };

    return (
        <>
            <DesktopDefault>
                <div className="container-fluid content-dashboard">
                    <Breadcrumb title={t("groupsAndCustomized.report.title")} />
                    <div className="suppliers mt-4 d-flex justify-content-between align-items-center">
                        <h2 className="title" style={{ color: "#707070" }}>{t("groupsAndCustomized.report.title")}</h2>
                    </div>
                    <FilterGroupFiles
                        buscar={buscar}
                        //billsReceive={billsReceive}
                        //error={error}
                        defaultDate={defaultDate}
                    />
                    <TableGroupFiles
                        billsReceive={billsReceive}
                        totalRows={totalRows}
                        pageCount={pageCount}
                        setPageCount={setPageCount}
                        rowsPerPage={rowsPerPage}
                        setRowsPerPage={setRowsPerPage}
                        exportBills={exportBills}
                        printBills={printBills}
                        loading={loading}
                        //totalBillsReceive={totalBillsReceive}
                        actualDate={actualDate}
                        parameters={parameters}
                        refreshData={refreshData}
                    />
                </div>
            </DesktopDefault>
        </>
    );

}

export default BillsToReceive;