/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useEffect, useState } from "react";

import "./Tariffs.scss"
import { Button, Col, Dropdown, Form, InputGroup, Row } from "react-bootstrap";
import { Controller, useForm } from "react-hook-form";
import { ErrorMessage } from "@hookform/error-message";
import ModalDefault from "../../../../components/Modal/ModalDefault/ModalDefault";
import SingleCalendar from "../../../../components/Calendar/SingleCalendar";
import BootstrapTable from "react-bootstrap-table-next";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export interface productTransfer {
    action: any,
    receiveObject: any,
    transferObject: any,
    isEdit: any
};

const Tariffs: React.FC<productTransfer> = React.memo(({ action, receiveObject, transferObject, isEdit
}: productTransfer) => {


    const [data, setData] = useState<any>([])
    const [date, setDate] = useState<any>();
    // const [costRate, setCostRate] = useState<any>(null);
    // const [sellingRate, setSellingRate] = useState<any>(null);
    // const [vehicle, setVehicle] = useState<any>()
    const [tariffsTb, setTariffsTb] = useState<any>([])
    const [vencimento, setVencimento] = useState<any>();

    const [textError, setTextError] = useState<any>('');

    const validated = false;    

    const vehiclesObj = [
        {
            id: 1,
            vehicle: "Carro",
            category: "Compacto",
            qtPax: "1-2"
        },
        {
            id: 2,
            vehicle: "Carro",
            category: "Luxo",
            qtPax: "1-3"
        },
        {
            id: 3,
            vehicle: "Carro",
            category: "Executivo",
            qtPax: "1-3"
        },
        {
            id: 4,
            vehicle: "Carro",
            category: "Compacto Bilingue",
            qtPax: "1-2"
        }
    ]

    // Seta valores defaults ao "VOLTAR"
    useEffect(() => {
        const macthIdVehicle = (id:any, atributo:string)=> {  
            let value:any = "";         
            vehiclesObj.find((item:any)=> {
                if(item.id === id){
                   value = item[atributo];
                } 
            })
            return value  
        }
       
        if (isEdit === true) {
            if (transferObject.tariff) {

            }
        }

        if (transferObject.tariff !== undefined) {
            let aux: any = [];
            
            for (let i = 0; i < transferObject.tariff.length; i++) {
                
                aux.push(
                    {
                        "vehicle": isEdit !== true ? vehiclesObj[transferObject.vehicleType - 1].vehicle : macthIdVehicle(transferObject.tariff[i].vehicleType, "vehicle"),                        
                        "category": isEdit !== true ? vehiclesObj[transferObject.vehicleType - 1].category : macthIdVehicle(transferObject.tariff[i].vehicleType, "category"),
                        "qtPax": isEdit!== true ? vehiclesObj[transferObject.vehicleType - 1].qtPax : macthIdVehicle(transferObject.tariff[i].vehicleType, "qtPax"),
                        "suitcase": "1",
                        "handbag": "1",
                        "inicialDate": transferObject.tariff[i].inicialDate,
                        "finalDate": transferObject.tariff[i].finalDate,
                        "idVehicle": isEdit !== true ? vehiclesObj[transferObject.vehicleType - 1].id : transferObject.tariff[i].vehicleType,
                        "tax": [
                            transferObject?.tariff[i]?.taxaNet,
                            transferObject?.tariff[i]?.taxaNetVenda
                        ],
                        "id": i + 1
                    }
                )
            }            
            setData(aux);
            setTariffsTb(aux);
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])
    // END - Seta valores defaults ao "VOLTAR"
    
    const addVehicle = () => {       
        
        //botão adicionar veículo
        const obj = getValues();
        const index:any = parseInt(obj.vehicleType);
        
        let aux: any = {
            "vehicle": vehiclesObj[index - 1].vehicle,
            "category": vehiclesObj[index - 1].category,
            "qtPax": vehiclesObj[index - 1].qtPax,
            "suitcase": "1",
            "handbag": "1",
            "inicialDate": date.split("/").reverse().join("-"),
            "finalDate": vencimento.split("/").reverse().join("-"),
            "idVehicle": vehiclesObj[index - 1].id,  
            "tax": [
                obj.costRate,
                obj.sellingRate
            ],
             "id": data.length + 1
        }        
        setData([...data, aux])
        //console.log(obj.id)
    }

    const addVehicleToTable = () => {
        setTariffsTb([...data])
        //console.log(tariffsTb)
    }

    const {
        control,
        handleSubmit,
        setValue,
        formState: { errors },
        getValues
    } = useForm({});

    const onSubmit = (data: any) => {
        if (tariffsTb.length === 0) {
            setTextError("Adicione ao menos uma tarifa!");

            setTimeout(() => {
                setTextError('');
            }, 2000);
        } else {
            var aux: any = [];
            var auxListFinal: any = [];
            tariffsTb.forEach((elem: any, index:number) => {               

                aux.push({
                    inicialDate: elem.inicialDate,
                    finalDate: elem.finalDate,
                    opacTarif: 0,
                    percentegeOpac: 0,
                    gradeId: 0,
                    gradeCode: "",
                    vehicle: elem.vehicle,
                    category: elem.category,
                    qtPax: elem.qtPax,
                    taxaNet: Number(elem.tax[0]),
                    taxaNetVenda: Number(elem.tax[1]),
                    taxaNetComissionado: 0,
                    idVeiculo: index + 1,
                    vehicleType: elem.idVehicle,
                });

                /* auxListFinal.push({
                    idTarif: 0,
                    idTransfer: elem.id,
                    status: 0,
                    gradeId: 0,
                    productCode: "",
                    gradeCode: ""
                }) */
            })

            data.tariff = aux;
            /* data.listFinalTarif = auxListFinal; */

            if (isEdit !== true) {
                action('dayshours', data);
            } else {
                receiveObject(data);                
            }
        }
    }

    const handleBlur = (event: any) => {
        setValue('sellingRate', event.target.value)
    }   

    const deleteItem = (array:any, itemToDelete:any) => {
        return array.filter((item:any) => item !== itemToDelete);
    }

    const handleDeleteItem = (info:any)=> {   
        setTariffsTb((prevItems:any) => deleteItem(prevItems, info));        
    }
 

   const columns = [
        {
            dataField: "vehicle",
            text: "Veículo",
        },
        {
            dataField: "category",
            text: "Categoria",
        },
        {
            dataField: "qtPax",
            text: "Passageiros",
        },
        {
            dataField: "suitcase",
            text: "Malas",
        },
        {
            dataField: "handbag",
            text: "Malas de Mão",
        },
        {
            dataField: "tax",
            text: "Taxa",
            formatter: addTags
        },
        {
            dataField: "id",
            text: "Ações",
            formatter: addActionButton
        }
    ]

    function addTags(cell: any, row: any) {
        // console.log(row)
        if (cell !== undefined) {
            return (
                <>
                    <p className="mb-0">{cell[0]} </p>
                    <p className="mb-0">{cell[1]} </p>
                </>
            )
        } else {
            return (
                <></>
            )
        }
    }

    function addActionButton(cell: any, row: any) {
        return (
            <>
                <div className="d-flex justify-content-center">
                    <Dropdown drop="start">
                        <Dropdown.Toggle variant="light ">
                            <FontAwesomeIcon
                                size="lg"
                                icon={["fal", "ellipsis-h"]}
                            />
                        </Dropdown.Toggle>
                        <Dropdown.Menu>
                            <Dropdown.Item
                                eventKey="1"
                                onClick={()=> handleDeleteItem(row)}
                            >
                                <FontAwesomeIcon
                                    icon={['fal', 'trash-alt']}
                                />
                                Remover
                            </Dropdown.Item>
                        </Dropdown.Menu>
                    </Dropdown>
                </div>
            </>
        );
    }

    function closeModal() {
        var closeBtn: any = document.getElementsByClassName("btn-close")[0];
        if (closeBtn !== undefined) {
            closeBtn.click();
        }
    }

    return (
        <>
            {/* CONTENT */}
            <Col md={9} lg={10}>
                <Form className="h-100" noValidate validated={validated} onSubmit={handleSubmit(onSubmit)}>

                    <div className='card h-100 border-0 p-4'>
                        <div>
                            <h3 className="pt-1 text-primary title-content">{"Tarifas"}</h3>
                        </div>

                        {/* <Row className="my-3">
                    <Col md={'12'}>
                        <div className="alert alert-primary background-primary p-3">                      
                        <small className="text-primary">Os cartazes que você utiliza para receber seus passageiros não poderão mais ter o logo da C2Rio. Lembre-se que você deve continuar utilizando o logo da sua empresa junto com o nome e o sobrenome do passageiro titular da reserva.</small>
                        </div>
                    </Col>
                </Row> */}

                        <section className="my-3">
                            <Row className="column-chooice">
                                <Col md="12">
                                    <div className="label-small">
                                        Defina as taxas para a transferência.
                                    </div>
                                    <div className="d-flex align-items-center w-auto mt-2">
                                        <ModalDefault
                                            title="Adicionar Tarifas"
                                            name=" + Adicionar Tarifas "
                                            icon="no"
                                            typeIcon="plus-circle"
                                            classBtn="btn btn-outline-primary"
                                            classModal="modal-large"

                                        >

                                            <Form className="h-100" noValidate validated={validated} onSubmit={handleSubmit(onSubmit)} autoComplete="false">
                                                <div className='d-flex flex-column justify-content-between h-100'>
                                                    <div>
                                                        <Row>
                                                            <Form.Group as={Col} className="mb-3" controlId="formGridDate">
                                                                <Form.Label>Data de início</Form.Label>
                                                                <Controller
                                                                    control={control}
                                                                    name="initialDate"
                                                                    rules={{ required: { value: false, message: "Por favor, selecione uma data" }, }}
                                                                    render={({ field }: any) => (
                                                                        <InputGroup
                                                                            hasValidation
                                                                            className=""
                                                                            size="sm"
                                                                            placeholder="Quando?"
                                                                        >
                                                                            <SingleCalendar setValue={setDate} />

                                                                        </InputGroup>
                                                                    )}
                                                                />
                                                                <ErrorMessage
                                                                    errors={errors}
                                                                    name="initialDate"
                                                                    render={({ message }) => <small style={{ color: "red" }}>{message}</small>}
                                                                />
                                                            </Form.Group>

                                                            <Form.Group as={Col} className="mb-3" controlId="formGridVenc">
                                                                <Form.Label>Data Fim</Form.Label>
                                                                <Controller
                                                                    control={control}
                                                                    name="finalDate"
                                                                    rules={{ required: { value: false, message: "Por favor, selecione uma data" }, }}
                                                                    render={({ field }: any) => (
                                                                        <InputGroup
                                                                            hasValidation
                                                                            className=""
                                                                            size="sm"
                                                                            placeholder="Quando?"
                                                                        >
                                                                            <SingleCalendar setValue={setVencimento} />
                                                                        </InputGroup>
                                                                    )}
                                                                />
                                                                <ErrorMessage
                                                                    errors={errors}
                                                                    name="finalDate"
                                                                    render={({ message }) => <small style={{ color: "red" }}>{message}</small>}
                                                                />
                                                            </Form.Group>
                                                        </Row>

                                                        <Row>
                                                            <Form.Group as={Col} className="mb-3" controlId="formGridDate">
                                                                <Form.Label>Tipo de Veículo</Form.Label>
                                                                <Controller
                                                                    control={control}
                                                                    name="vehicleType"
                                                                    rules={{ required: { value: true, message: "Por favor, selecione o tipo de veículo." }, }}
                                                                    render={({ field }: any) => (
                                                                        <div className={errors.vehicleType ? "endpoint-error" : "endpoint"}>
                                                                            <Form.Select
                                                                                {...field}
                                                                                as="select"
                                                                                aria-invalid={errors?.vehicleType ? "true" : ""}
                                                                                variant="standard"
                                                                                margin="normal"
                                                                            >
                                                                                <option value="0" selected disabled>Selecione uma Opção</option>
                                                                                {vehiclesObj.map((item:any)=> {
                                                                                    return <option id={item.id} value={item.id}>{`Particular - ${item.vehicle}(${item.category}) - ${item.qtPax}`}</option>
                                                                                })}
                                                                                
                                                                            </Form.Select>
                                                                        </div>
                                                                    )}
                                                                />
                                                                <ErrorMessage
                                                                    errors={errors}
                                                                    name="vehicleType"
                                                                    render={({ message }) => <small style={{ color: "red" }}>{message}</small>}
                                                                />
                                                            </Form.Group>

                                                            <Form.Group as={Col} className="mb-3" controlId="formGridDate">
                                                                <Form.Label>Taxa</Form.Label>
                                                                <Row>
                                                                    <Col md="6">
                                                                        <Controller
                                                                            control={control}
                                                                            name="costRate"
                                                                            rules={{ required: { value: true, message: "Por favor, informe o valor." }, }}
                                                                            render={({ field }: any) => (
                                                                                <Form.Control
                                                                                    {...field}
                                                                                    aria-invalid={errors?.costRate ? "true" : ""}
                                                                                    variant="standard"
                                                                                    margin="normal"
                                                                                    autoComplete="off"
                                                                                    onKeyPress={(e) => !/[0-9]/.test(e.key) && e.preventDefault()}
                                                                                    className="inputtaxx"
                                                                                    onBlur={handleBlur}
                                                                                />
                                                                            )}
                                                                        />
                                                                        <Form.Label style={{ fontSize: "12px", marginRight: "5px" }}>Custo</Form.Label>
                                                                        <ErrorMessage
                                                                            errors={errors}
                                                                            name="costRate"
                                                                            render={({ message }) => <small style={{ color: "red" }}>{message}</small>}
                                                                        />
                                                                    </Col>

                                                                    <Col md="6">
                                                                        <Controller
                                                                            control={control}
                                                                            name="sellingRate"
                                                                            //rules={{ required: { value: true, message: "Por favor, informe o valor." }, }}
                                                                            render={({ field }: any) => (
                                                                                <Form.Control
                                                                                    {...field}
                                                                                    aria-invalid={errors?.sellingRate ? "true" : ""}
                                                                                    variant="standard"
                                                                                    margin="normal"
                                                                                    autoComplete="off"
                                                                                    onKeyPress={(e) => !/[0-9]/.test(e.key) && e.preventDefault()}
                                                                                    className="inputtaxx"
                                                                                />
                                                                            )}
                                                                        />
                                                                        <ErrorMessage
                                                                            errors={errors}
                                                                            name="sellingRate"
                                                                            render={({ message }) => <small style={{ color: "red" }}>{message}</small>}
                                                                        />                                           <Form.Label style={{ fontSize: "12px" }}>Venda</Form.Label>
                                                                    </Col>
                                                                </Row>

                                                            </Form.Group>
                                                        </Row>
                                                        <Row className="d-flex justify-content-md-center mt-3">
                                                            <button onClick={addVehicle} type="button" className='btn btn-add-veiculos' >Adicionar Veículo</button>
                                                        </Row>

                                                        <hr style={{ margin: "30px 0" }} />

                                                        <Row>
                                                            <div className="table-default tariffs-table">
                                                                <div className="table-container">
                                                                    <BootstrapTable
                                                                        bootstrap4
                                                                        keyField="id"
                                                                        data={data}
                                                                        columns={columns}
                                                                        rowClasses={'border-analise'}
                                                                        striped={true}
                                                                    />
                                                                </div>
                                                            </div>
                                                        </Row>
                                                        <div className="d-flex justify-content-end align-items-end ">
                                                            <Row className=" mx-0 mt-5">

                                                                <Button
                                                                    type="button"
                                                                    className="form-button"
                                                                    onClick={() => { addVehicleToTable(); closeModal(); }}
                                                                >
                                                                    Salvar
                                                                </Button>
                                                            </Row>
                                                        </div>
                                                    </div>
                                                </div>

                                            </Form>
                                        </ModalDefault>

                                        <div className="d-flex mx-3">
                                            <small style={{ color: "red" }}>{textError}</small>
                                        </div>
                                    </div>
                                </Col>
                            </Row>
                        </section>

                        <section className="my-3">
                            <Row>
                                <Col md="12">
                                    <div className="label-small">
                                        Tarifas
                                    </div>

                                    <div className="mt-4">
                                        <h4>
                                            Title Tarif - 05/07/2022
                                        </h4>
                                        <div className="table-default tariffs-table">
                                            <div className="table-container">
                                                <BootstrapTable
                                                    bootstrap4
                                                    keyField="id"
                                                    data={tariffsTb}
                                                    columns={columns}
                                                    rowClasses={'border-analise'}
                                                    striped={true}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </Col>
                            </Row>
                        </section>

                        <section className="h-100 d-flex justify-content-end align-items-end">
                            {
                                isEdit !== true
                                    ?
                                    <Row className="mx-0 mt-5">
                                        <Button
                                            variant="outline-primary"
                                            className="form-button mr-3"
                                            onClick={() => action('meetingPoint')}
                                        >
                                            Voltar
                                        </Button>
                                        <Button
                                            type="submit"
                                            className="form-button"
                                        >
                                            Continuar
                                        </Button>
                                    </Row>
                                    :
                                    <Row className=" mx-0 mt-5">
                                        <Button
                                            type="submit"
                                            className="form-button"
                                        //onClick={() => action('meetingPoint')}
                                        >
                                            Salvar
                                        </Button>
                                    </Row>
                            }
                        </section>
                    </div>
                </Form>
            </Col>

        </>
    )
});

export default Tariffs;