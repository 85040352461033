import React from 'react';

import Breadcrumb from '../../../components/Breadcrumb/Breadcrumb';

import SuppliersTariffForm from '../components/SuppliersTariffForm/SuppliersTariffForm';
//import { useTranslation } from 'react-i18next';
import DesktopDefault from '../../../templates/DesktopDefault';


function AddSuppliersTariff() {
    //const { t } = useTranslation();
    return (
        <>
            <DesktopDefault>
                <div className="container-fluid content-dashboard">

                    <Breadcrumb title={'Fornecedores / Tarifário'} />

                    <div className="register-users mt-4">
                        <h2 className="title">Tarifário Fornecedor</h2>
                    </div>

                    <SuppliersTariffForm action={1} info={null} />

                </div>

            </DesktopDefault>
        </>

    );
}

export default AddSuppliersTariff;