/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { Key, useState } from "react";
import Table from "react-bootstrap/Table";
//import Button from "react-bootstrap/Button";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useTranslation } from 'react-i18next';

//STYLES
import "./GroupFileServices.scss";
import { Dropdown, Modal } from "react-bootstrap";

//JSON
// import objectSuppliers from "../SuppliersTableCompany/locales/suppliers.json";


import FormGroupFileServices from "../FormGroupFileServices/FormGroupFileServices";

export interface propModal {
  action: any
  info: any
  totalQuantity: any
  totalAdult: any
  totalChild: any
  totalInfant: any
  markup: any
  //control: any
  //errors: any
  //setValue: any
};

const GroupFileServices: React.FC<propModal> = ({
  action, info, markup
  //, control, errors, setValue
  , totalQuantity, totalAdult, totalChild, totalInfant

}: propModal) => {
  const { t } = useTranslation();

  /* function formatValue(cell: any) {
    //console.log(Math.sign(cell))
    if(cell && cell !== '') {
      return cell.toFixed(2).replace(".", ",");
    } else {
      return '0,00';
    }
} */

  // const suppliers = objectSuppliers.suppliers.default[0];

  // const [controlObject, setControlObject] = useState<any>(info ? info : [{
  //   id:0,name:'',passportId:'',email:'',phone:''
  // }]);

  const [controlObject, setControlObject] = useState<any>(info ? info : []);

  const [deletedObjects, setDeletedObjects] = useState<any>([]);

  const [serviceModalShow, setServiceModalShow] = useState<any>(false);
  const [serviceEdit, setServiceEdit] = useState<any>(null);


  const handleActionModal = (objectModal: any) => {
    var aux: any = [...controlObject, objectModal];
    setControlObject(aux);
    setServiceModalShow(false);

    refreshBlocks(aux);
  }

  const handleActionEditModal = (objectModal: any) => {
    setServiceEdit(null);
    setServiceModalShow(false);

    var aux: any = [...controlObject];

    //console.log(objectModal);
    for (var i: any = 0; i < aux.length; i++) {
      if (aux[i].id === objectModal.id) {

        aux.splice(i, 1, objectModal);

        break;
      }
    }
    setControlObject(aux);

    refreshBlocks(aux);
  }

  function removeRow(index: any) {

    //console.log(controlObject)

    var aux: any = [];

    for (var i: any = 0; i < controlObject.length; i++) {
      if (i !== index) {
        aux.push(controlObject[i]);
      } else {
        if (controlObject[i].id > 0) setDeletedObjects([...deletedObjects, controlObject[i].id]);
      }
    }

    setControlObject(aux);
  }

  function editRow(index: any) {
    var aux: any = controlObject[index];
    // chama modal
    setServiceEdit(aux);
    setServiceModalShow(true);
  }

  function refreshBlocks(blocks: any) {
     //const blocks: any = [];

    // for (let i: any = 0; i < controlObject.length; i++) {

    //   const block = {
    //     id: controlObject[i],
    //     type       : arrayType       [i],
    //     resourceId : arrayResourceId [i],
    //     productCode: arrayProductCode[i],
    //     supplierId : arraySupplierId [i],
    //     globalPrice: arrayGlobalPrice[i],
    //     netAmount  : arrayNetAmount  [i],
    //     saleAmount : arraySaleAmount [i],
    //     serviceDate: arrayServiceDate[i],
    //     city       : arrayCity       [i],
    //     language   : arrayLanguage   [i],
    //     status     : arrayStatus     [i],
    //   };
    //   blocks.push(block);
    // }

    action(blocks, deletedObjects);
  }

  const fixDate = (data: any) => {
    if (!data) return '';
    var aux = data.split('/');

    return (`${aux[2]}-${aux[1]}-${aux[0]}`);
  }

  function formatDate(cell: any) {
    if (!cell) return '';
    //var date = convertUTCDateToLocalDate(new Date(cell));
    var aux: any = cell.split("T")[0].split("-");
    var date: any = `${aux[2]}/${aux[1]}/${aux[0]}`;
    return date;
  }

  function formatValue(cell: any) {
    //console.log(Math.sign(cell))
    if(cell && cell !== '') {
        return cell.toFixed(2).replace(".", ",");
    } else {
        return '0,00';
    }
  }

  return (
    <>
      <div className="table-add-default groupfile-service-table">
        <div className="table-container">
          {/* <BootstrapTable rowEvents={ rowEvents } bootstrap4 keyField='email' data={controlObject} hover={true} columns={columns} pagination={pagination} striped={true} /> */}
          <Table striped bordered>
            <thead>
              <tr>
                <th>{t("groupsAndCustomized.services.type")}</th>
                <th>{t("groupsAndCustomized.services.supplier")}</th>
                <th>{t("groupsAndCustomized.services.resourceProduct")}</th>
                <th>{t("groupsAndCustomized.services.serviceObs")}</th>
                <th>{t("groupsAndCustomized.services.globalPrice")}</th>
                <th>{t("groupsAndCustomized.services.pax")}</th>
                <th>{t("groupsAndCustomized.services.serviceDate")}</th>
                <th>{t("groupsAndCustomized.services.serviceTime")}</th>
                <th>{t("groupsAndCustomized.services.netAmount")}</th>
                <th>{t("groupsAndCustomized.services.saleAmount")}</th>
                <th>{t("groupsAndCustomized.services.city")}</th>
                <th>{t("groupsAndCustomized.services.language")}</th>
                <th>{t("groupsAndCustomized.services.status")}</th>
                <th>{t("groupsAndCustomized.services.actions")}</th>
              </tr>
            </thead>
            <tbody>
              {controlObject.length > 0
                ? controlObject.map((item: any, index: Key) => (
                  <tr key={index}>
                    <td>
                      {/* type */}
                      {(item.type == '1') ? t("groupsAndCustomized.services.typeOpts.vehicle")
                      : (item.type === '2') ? t("groupsAndCustomized.services.typeOpts.driver")
                      : (item.type === '3') ? t("groupsAndCustomized.services.typeOpts.guide")
                      : (item.type === '4') ? t("groupsAndCustomized.services.typeOpts.ticket")
                      : (item.type === '5') ? t("groupsAndCustomized.services.typeOpts.restaurant")
                      : (item.type === '6') ? t("groupsAndCustomized.services.typeOpts.other")
                      : (item.type === '7') ? t("groupsAndCustomized.services.typeOpts.product")
                      : ''}
                    </td>
                    <td>
                      {/* supplier */}
                      {item.supplierName}
                    </td>
                    <td>
                      {/* resource/product */}
                      {item.serviceName}
                    </td>
                    <td>
                      {/* service/obs */}
                      {item.serviceObs}
                    </td>
                    <td>
                      {/* global price */}
                      {item.globalPrice === '2' ? t("groupsAndCustomized.services.globalPriceOpts.priceByPax")
                      : t("groupsAndCustomized.services.globalPriceOpts.singlePrice")}
                    </td>
                    <td>
                      {/* pax */}
                      {item.totalQuantity}
                      {/* <div className="panel-dropdown bg-white m-0"  style={{ lineHeight: "1.7rem" }}>
                         <DropdownNumberPeople
                          info={{adults: arrayTotalAdult[index], childs: arrayTotalChild[index], infants: arrayTotalInfant[index]}}
                          actionPeople={(people: any) => handleTotalPax(people, index)}
                          ranges={{isActiveAdult: true, isActiveChild: true, isActiveElders: false, isActiveInfant: true, isActiveStudent: false, labelAdult: 'Adulto', labelChild: 'Criança', labelInfant: 'Infante'}}
                        /> 
                      </div> */}
                    </td>
                    <td>
                      {/* service date */}
                      {formatDate(item.serviceDate)}
                    </td>
                    <td>
                      {/* service time */}
                      {item.serviceTime}
                    </td>
                    <td>
                      {/* net amount */}
                      {formatValue(item.netAmount)}
                    </td>
                    <td>
                      {/* sale amount */}
                      {formatValue(item.saleAmount)}
                    </td>
                    <td>
                      {/* city */}
                      {item.city}
                    </td>
                    <td>
                      {/* language */}
                      {(item.language === '1') ? t("groupsAndCustomized.services.languageOpts.portuguese")
                      : (item.language === '2') ? t("groupsAndCustomized.services.languageOpts.english")
                      : (item.language === '3') ? t("groupsAndCustomized.services.languageOpts.spanish")
                      : (item.language === '4') ? t("groupsAndCustomized.services.languageOpts.italian")
                      : (item.language === '5') ? t("groupsAndCustomized.services.languageOpts.french")
                      : (item.language === '6') ? t("groupsAndCustomized.services.languageOpts.deutsch")
                      : (item.language === '7') ? t("groupsAndCustomized.services.languageOpts.corean")
                      : (item.language === '8') ? t("groupsAndCustomized.services.languageOpts.mandarin")
                      : ''}
                    </td>
                    <td>
                      {/* status */}
                      { item.status === '2' ? t("groupsAndCustomized.services.statusOpts.request")
                      : item.status === '3' ? t("groupsAndCustomized.services.statusOpts.confirmed")
                      : t("groupsAndCustomized.services.statusOpts.pending")}
                    </td>
                    <td>{
                      <div className="center-table options-table">
                        <Dropdown drop="down" >
                          <Dropdown.Toggle variant="light ">
                            <FontAwesomeIcon
                              size="lg"
                              icon={['fal', 'ellipsis-h']}
                            />
                          </Dropdown.Toggle>

                          <Dropdown.Menu>
                            <Dropdown.Item
                              onClick={() => editRow(index)}
                              eventKey="1"
                            >
                              <FontAwesomeIcon
                                icon={['fal', 'edit']}
                              //className="mr-2"
                              />
                              {t('groupsAndCustomized.services.edit')}
                            </Dropdown.Item>
                            <Dropdown.Item
                              onClick={() => removeRow(index)}
                              eventKey="2"
                              className="text-danger removeRow">
                              <FontAwesomeIcon
                                icon={['fal', 'trash-alt']}
                                //className="mr-2"
                              />
                              {t('groupsAndCustomized.services.remove')}
                            </Dropdown.Item>
                          </Dropdown.Menu>
                        </Dropdown>
                        {/* <div className="px-2">
                          <div className="d-flex justify-content-end">
                            <span className='btn' style={{ height: "40px" }} onClick={(e: any) => removeRow(index)}>
                              <FontAwesomeIcon
                                size="lg"
                                icon={["fal", "trash-alt"]}
                                style={{ color: "#707070" }}
                              />
                            </span>
                          </div>
                        </div> */}
                      </div>
                    }</td>
                  </tr>
                ))
                : ""
              }
              <div className="add-contact">
                <div className="text-center btn-add-contact">
                  {/* <span className='btn' style={{ height: "40px" }} onClick={(e: any) => addRow()}>
                    <FontAwesomeIcon
                      size="lg"
                      icon={["fal", "plus"]}
                      style={{ color: "#707070", margin: "0 12px" }}
                    />
                  </span> */}
                  {/* <ModalDefault
                    title="Serviço"
                    classModal="modal-groupfile-service"
                    name={' '}
                    icon="yes"
                    typeIcon="plus"
                    iconSize="lg"
                    class="btn"
                  >
                    <div className="form-add-contact">
                      <FormGroupFileServices action={handleActionModal} info={null} markup={markup} fileTotalQuantity={totalQuantity} fileTotalAdult={totalAdult} fileTotalChild={totalChild} fileTotalInfant={totalInfant} t={t} />
                    </div>
                  </ModalDefault> */}
                  <div>
                    <Modal
                      className={"modal-custom modalAuth modal-groupfile-service"}
                      show={serviceModalShow && serviceEdit === null}
                      onHide={() => setServiceModalShow(false)}
                      size="lg"
                      aria-labelledby="contained-modal-title-vcenter"
                    >
                      <>
                        <Modal.Header closeButton>
                          <div className="d-flex justify-content-between w-100">
                            <h3>{t("groupsAndCustomized.services.addModalTitle")}</h3>
                          </div>
                        </Modal.Header>
                        <hr style={{ margin: "30px 0" }} />
                        <div className="form-add-contact">
                          <FormGroupFileServices action={handleActionModal} info={null} markup={markup} fileTotalQuantity={totalQuantity} fileTotalAdult={totalAdult} fileTotalChild={totalChild} fileTotalInfant={totalInfant} t={t} />
                        </div>
                      </>
                    </Modal>

                    <span className='btn' style={{ height: "40px" }} onClick={(e: any) => { setServiceEdit(null); setServiceModalShow(true); }}>
                      <FontAwesomeIcon
                        size="lg"
                        icon={["fal", "plus"]}
                        style={{ color: "#707070", margin: "0 12px" }}
                      />
                    </span>
                  </div>
                </div>
              </div>
            </tbody>
          </Table>

          {
            serviceEdit !== null
              ?
              <Modal
                className="modal-custom modalAuth modal-groupfile-service"
                show={serviceModalShow}
                onHide={() => {setServiceEdit(null); setServiceModalShow(false);}}
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
              >
                <>
                  <Modal.Header className="p-3" closeButton>
                    <h3>{t("groupsAndCustomized.services.editModalTitle")}</h3>
                  </Modal.Header>
                  <hr style={{ margin: "30px 0" }} />
                  <FormGroupFileServices action={handleActionEditModal} info={serviceEdit} markup={markup} fileTotalQuantity={totalQuantity} fileTotalAdult={totalAdult} fileTotalChild={totalChild} fileTotalInfant={totalInfant} t={t} />
                </>
              </Modal>
              :
              <></>
          }

        </div>
      </div>
    </>
  );
}

export default GroupFileServices;
