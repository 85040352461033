import React, { useState } from "react";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Dropdown from "react-bootstrap/Dropdown";

import "react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css";
import "react-bootstrap-table-next/dist/react-bootstrap-table2.css";
import BootstrapTable from "react-bootstrap-table-next";

import Modal from "react-bootstrap/Modal";

import FormSessionChange from "../../../../components/Forms/FormSessionChange/FormSessionChange";

//STYLES
import "./TableSessionlessOrders.scss";
import Pagination from "../../../../components/Pagination/Pagination";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import ModalQuestion from "../../../../components/Modal/ModalQuestion/ModalQuestion";
import api from "../../../../services/api";

export interface propTable {
  bills: any,
  totalRows: any,
  pageCount: any,
  setPageCount: any,
  rowsPerPage: any,
  setRowsPerPage: any,
  exportReport: any,
  printReport: any,
  refreshData: any,
  loading: any,
}

const TableSessionlessOrders: React.FC<propTable> = ({
  bills, totalRows, pageCount, setPageCount, rowsPerPage, setRowsPerPage, exportReport, printReport
  , refreshData, loading
}: propTable) => {
  //console.log(props.suppliers[0].dataCadastro.split('T')[0])

  const [sessionChangeModalShow, setSessionChangeModalShow] = useState<any>(false);
  const [putNewSessionModalShow, setPutNewSessionModalShow] = useState<any>(false);
  const [billSessionChange, setBillSessionChange] = useState<any>(null);
  const [sessionDate, setSessionDate] = useState<any>('');
  const [sessionOperationType, setSessionOperationType] = useState<any>(1);

  /* Success Modal (Inserir) */
  const [modalSuccess, setModalSuccess] = useState<any>(false);
  const [responseText, setResponseText] = useState<any>();
  const [modalContent, setModalContent] = useState<any>();
  const [log, setLog] = useState<any>();
  /* END - Success Modal (Inserir) */


  const handleExportClick = (inc: any) => {
    exportReport();
  }

  const handlePrintClick = (inc: any) => {
    printReport();
  }

  const handleSessionChangeClick = (row: any) => {
    // show modal
    setSessionDate(row.dataOperacao);
    setSessionOperationType(row.operationType);
    setBillSessionChange(row);
    setSessionChangeModalShow(true);
  }

  const handleActionSessionChangeModal = (data: any) => {
    refreshData();

    setBillSessionChange(null);
    // salvar mudança session
  }

  const handlePutNewSessionClick = (row: any) => {
    setBillSessionChange(row);
    setPutNewSessionModalShow(true);
  }

  const handleActionPutNewSessionModal = () => {
    setPutNewSessionModalShow(false);

    console.log(billSessionChange);
    const id = billSessionChange.newItemsId;
    setBillSessionChange(null);
     
    setModalContent("loading");
    setModalSuccess(true);

    let userData = JSON.parse(localStorage.getItem('c2tourAuth') || '{}');
    const user = userData.user;

    const uid = user.uId;
    const usuario = `${user.nome} ${user.lastName}`;

    const token = localStorage.getItem('GroupId') || '';

    const config = {
      headers: { 'Authorization': `Bearer ${token}` },
    };

    async function doPut() {
      try {
        const res = await api.post('Manifest/PutNewSession',
          {
            "idItem": id,
            "uid": uid,
            "usuario": usuario
          }, config
        );
        if (res.status !== 400) {
          const dados = res.data.data;

          //alert(dados.texto);
          setResponseText(dados.texto);
          setLog(dados.log);

          if (dados.log !== 1) {
            setModalContent("success");
            //refreshData();

            
          } else {
            setModalContent("error");
          }
        }
      } catch (error: any) {
        setModalContent("error");
        setLog(1);
        //setError(true)
        if (error?.response?.status === 401) {
          window.location.href = window.location.origin + '/';
        }
      }
    }

    doPut();
  }

  function addActionButton(cell: any, row: any) {
    return (
      <>
        <div className="d-flex justify-content-center">
          <Dropdown drop="start">
            <Dropdown.Toggle variant="light ">
              <FontAwesomeIcon size="lg" icon={["fal", "ellipsis-h"]} />
            </Dropdown.Toggle>

            <Dropdown.Menu>
              <Dropdown.Item eventKey="1" onClick={(e: any) => handlePutNewSessionClick(row)}>
                <FontAwesomeIcon
                  icon={["fal", "plus-circle"]}
                  size="1x"
                //style={{ marginRight: "1px" }}
                />
                Nova Session
              </Dropdown.Item>
              <Dropdown.Item eventKey="2" onClick={(e: any) => handleSessionChangeClick(row)}>
                <FontAwesomeIcon
                  icon={["fal", "exchange"]}
                  size="1x"
                //style={{ marginRight: "1px" }}
                />
                Trocar Session
              </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
        </div>
      </>
    );
  }

  /*converte a data UTC para a data local do usuário*/
  function convertUTCDateToLocalDate(date: any) {
    var newDate = new Date(date.getTime() + date.getTimezoneOffset() * 60 * 1000);

    //var offset = date.getTimezoneOffset() / 60;
    //var hours = date.getHours();

    //newDate.setHours(hours - offset);

    return newDate.toLocaleString().split(' ')[0];
  }

  function convertDate(cell: any, row: any) {
    var date = (cell) ? convertUTCDateToLocalDate(new Date(cell)) : '';
    /* console.log(date) */
    return (
      <>
        {date}
      </>
    );
  }

  function convertValue(cell: any) {
    //console.log(Math.sign(cell))
    return (
      <>
        <span className={Math.sign(cell) === -1 ? "negative" : ""}>{cell.toFixed(2).replace(".", ",")}</span>
      </>
    )
  }

  function addStatus(cell: any, row: any) {
    return (
      cell !== 1 ?
        <FontAwesomeIcon
          icon={["fal", "check"]}
          size="1x"
          style={{ marginRight: "1px" }}
        />
        : <></>
    );
  }

  function addNote(cell: any, row: any) {
    return (
      <>
        <OverlayTrigger
          overlay={
            <Tooltip id="tooltip">{cell}</Tooltip>
          }>
          <span className="text-wrap-custom">
            {cell}
          </span>
        </OverlayTrigger>
      </>

    )
  }

  /* ALTERAR OS DATAFIELDS QUANDO A API CHEGAR */

  const columns = [
    { dataField: "dataOperacao", text: "Data Operação", sort: true, formatter: convertDate },
    { dataField: "formaPagamento", text: "Forma de Pag.", sort: true },
    { dataField: "descricao", text: "Descrição", sort: true },
    { dataField: "agente", text: "Cliente", sort: true },
    { dataField: "totalPax", text: "QTD", sort: true },
    { dataField: "totalAdult", text: "ADT", sort: true },
    { dataField: "totalChild", text: "CHD", sort: true },
    { dataField: "totalInfant", text: "INF", sort: true },
    {
      dataField: "valor",
      text: "Valor",
      sort: true,
      formatter: convertValue,
    },
    {
      dataField: "vencimento",
      text: "Vencimento*",
      sort: true,
      formatter: convertDate,
    },
    { dataField: "observacao", text: "Observação*", sort: true, formatter: addNote },
    { dataField: "statusPagamento", text: " ", formatter: addStatus },
    { dataField: "localizador", text: "Ações", formatter: addActionButton },
  ];

  /* [END] ALTERAR OS DATAFIELDS QUANDO A API CHEGAR */

  /*--------------------------------------------------*/

  /* loading table */

  function loadingFunc() {
    return (
      <>
        <div className="animated-background row-loading"></div>
      </>
    )
  }

  const loadingColumns = [{ dataField: "contasd", text: "Carregando reservas", formatter: loadingFunc }];

  const loadingProducts = [
    { contas: "" }, { contas: "" }, { contas: "" }, { contas: "" }, { contas: "" }
    ,{ contas: "" }, { contas: "" }, { contas: "" }, { contas: "" }, { contas: "" }
  ];
  /* [END] loading table */


  /* In case of empty table*/
  const notFoundColumns = [{ dataField: "clientes", text: "Reservas" }];

  const notFoundProducts = [{ clientes: "Nenhuma reserva encontrada" }];
  /* [END] In case of empty table*/

  const rowClasses = (row: any, rowIndex: any) => {
    /* console.log(row, rowIndex) */
    if (row.statusPagamento === 2) {
      return 'border-analise';
    } else if (row.statusPagamento === 3 || row.statusPagamento === 4) {
      return 'border-ativo';
    } else {
      return 'border-inativo';
    }
  };

  if(loading === true) {
    return (
      <>
        <div className="table-default sessionless-orders-table loading not-found">
          <div className="table-container">
            <BootstrapTable
              bootstrap4
              keyField="id"
              data={loadingProducts}
              hover={true}
              columns={loadingColumns}
              striped={true}
            />
          </div>
        </div>
      </>
    );
  } else if (bills !== null) {
    return (
      <>
        <div className="table-default sessionless-orders-table">
          <div className="table-container">
            <div>
              <div className="d-flex flex-wrap align-items-center" style={{ gap: "15px" }}>
                <div>
                  <button className="btn btn-bills" onClick={handleExportClick}>
                    <FontAwesomeIcon
                      icon={["fal", "share"]}
                      size="1x"
                      style={{ marginRight: "5px" }}
                    />
                    Exportar
                  </button>
                </div>
                <div>
                  <button className="btn btn-bills" onClick={handlePrintClick}>
                    <FontAwesomeIcon
                      file-invoice
                      icon={["fal", "print"]}
                      size="1x"
                      style={{ marginRight: "5px" }}
                    />
                    Imprimir
                  </button>
                </div>
              </div>
            </div>
            <BootstrapTable
              bootstrap4
              keyField="id"
              data={bills}
              columns={columns}
              rowClasses={rowClasses}
              striped={true}
            />
            <Pagination
              totalRows={totalRows}
              pageCount={pageCount}
              setPageCount={setPageCount}
              rowsPerPage={rowsPerPage}
              setRowsPerPage={setRowsPerPage}
              selectNumberRows="yes"
            />

            {billSessionChange !== null ?
              <Modal
                className="modalAuth"
                show={sessionChangeModalShow}
                onHide={() => setSessionChangeModalShow(false)}
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
              >
                <>
                  <Modal.Header className="p-3" closeButton></Modal.Header>
                  <FormSessionChange action={handleActionSessionChangeModal} bill={billSessionChange} date={sessionDate} operationType={sessionOperationType} sessionRelocAtual={0} />
                </>
              </Modal> : <></>}

              <ModalQuestion
                modalShow={putNewSessionModalShow}
                setModalShow={setPutNewSessionModalShow}
                titulo="Alocar Nova Session"
                mensagem="Deseja alocar a reserva em uma nova session?"
                handleActionConfirmModal={(e: any) => handleActionPutNewSessionModal()}
              />

              {/* Modal de Success */}
              <Modal
                className={"modal-confirm loading-modal"}
                show={modalSuccess}
                onHide={() => setModalSuccess(false)}
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
              >
                <>
                  <Modal.Body className='modal-body text-center sucess-pos'>
                    {
                      modalContent !== "loading"
                        ?
                        <>
                          <div>
                            {
                              log === 2 || log === "2"
                                ?
                                <FontAwesomeIcon
                                  icon={["fal", "exclamation-circle"]}
                                  size="5x"
                                  className="text-primary"
                                  style={{ fontSize: "7.5em"}}
                                />
                                :
                                log === 1 || log === "1"
                                  ?
                                  <FontAwesomeIcon
                                    icon={["fal", "times-circle"]}
                                    size="5x"
                                    className="text-primary"
                                    style={{ fontSize: "7.5em"}}
                                  />
                                  :
                                  log === 0 || log === "0"
                                    ?
                                    <FontAwesomeIcon
                                      icon={["fal", "check-circle"]}
                                      size="5x"
                                      className="text-primary"
                                      style={{ fontSize: "7.5em"}}
                                    />
                                    :
                                    <FontAwesomeIcon
                                      icon={["fal", "question-circle"]}
                                      size="5x"
                                      className="text-primary"
                                      style={{ fontSize: "7.5em"}}
                                    />
                            }
                          </div>
                          <div>
                            {responseText}
                          </div>
                          <div className="d-flex justify-content-center">
                            {
                              modalContent === 'success'
                                ?
                                <button
                                  onClick={() => {refreshData(); setModalSuccess(false);}}
                                  className="btn btn-primary mx-2 w-25"
                                >
                                  Confirmar
                                </button>
                                :
                                <button
                                  onClick={() => setModalSuccess(false)}
                                  className="btn btn-primary mx-2 w-25"
                                >
                                  Confirmar
                                </button>
                            }
                          </div>
                        </>
                        :
                        <div className="modal-body">
                          <div className="text-center">
                            <div className="load"></div>
                          </div>
                          <div>
                            <span>Processando</span>
                          </div>
                          <div></div>
                        </div>
                    }
                  </Modal.Body>
                </>
              </Modal>
              {/* END - Modal de Success */}
          </div>
        </div>
      </>
    );
  } else {
    return (
      <>
        <div className="table-default sessionless-orders-table loading not-found">
          <div className="table-container">
            <BootstrapTable
              bootstrap4
              keyField="id"
              data={notFoundProducts}
              hover={true}
              columns={notFoundColumns}
              striped={true}
            />
          </div>
        </div>
      </>
    );
  }
}

export default TableSessionlessOrders;
