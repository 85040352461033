import React, { useState } from "react";
import { Container, Row, Col } from "react-bootstrap";

import "../../../assets/sass/modal.scss";
import "./ModalSessionMessages.scss";

//import Modal from "react-bootstrap/Modal";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Form from "react-bootstrap/Form";
import { Controller, useForm } from "react-hook-form";
import Modal from "react-bootstrap/Modal";

//import IconSucess from "../../../assets/icons/modal-sucess.svg";

import api from '../../../services/api';
import { ErrorMessage } from "@hookform/error-message";

export interface propModal {
  sessionId: any,
  show: any,
  setResponseText: any,
  setModalContent: any,
  setLog: any,
  messageList: any,
  getSessionMessages: any,
};

const ModalSessionMessages: React.FC<propModal> = ({
  sessionId, show, setResponseText, setModalContent, setLog, messageList, getSessionMessages
}: propModal, props: any) => {
  //const [messageList, setMessageList] = useState<any>(null);

  const [messageToSend, setMessageToSend] = useState<any>('');

  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const formatDate = (data: any) => {
    if(!data) return '';
    
    //2022-05-05T00:00:00
    var aux = data.split('T');
    aux = aux[0].split('-');

    return (`${aux[2]}/${aux[1]}/${aux[0]}`);
  }

  const handleSendMessageClick = (dataObj: any) => {
    show(true);
    setModalContent('loading');

    dataObj.sessionId = sessionId;

    let userData = JSON.parse(localStorage.getItem('c2tourAuth') || '{}');
    const user = userData.user;

    dataObj.uid = user.uId;
    dataObj.usuario = `${user.nome} ${user.lastName}`;

    const token = localStorage.getItem('GroupId') || '';

    const config = {
      headers: { 'Authorization': `Bearer ${token}` },
    };

    const salvar = async () => {
        try {

            const { data } = await api.post(`Manifest/SendSessionMessageAsync`, dataObj, config);
            /* console.log(data.data); */
            if (data.status !== 400) {
                var response = data.data;

                setResponseText(response.texto);
                setLog(response.log);
                setModalContent('ok');

                if(response.log !== 1) {
                  getSessionMessages(sessionId);
                  setMessageToSend('');
                }
            }   
        }catch(error: any) {
            if (error?.response?.status === 401) {
                window.location.href = window.location.origin + '/';              
            }
            if (error?.response?.status === 400) {
                //window.location.href = window.location.origin + '/session-closure';              
            }
            //;
        }
    }
    salvar();
  };

  // const getSessionMessages = async () => {
  //   const token = localStorage.getItem('GroupId') || '';

  //   const config = {
  //     headers: { 'Authorization': `Bearer ${token}` },
  //   };
  //   try {

  //       const { data } = await api.post(`Manifest/GetSessionMessagesAsync`, {
  //         "sessionId": sessionId
  //       }, config);
  //       /* console.log(data.data); */
  //       if (data.status !== 400) {
  //           var response = data.data;

  //           setMessageList(response);
  //       }   
  //   }catch(error: any) {
  //       if (error?.response?.status === 401) {
  //           window.location.href = window.location.origin + '/';              
  //       }
  //       if (error?.response?.status === 400) {
  //           //window.location.href = window.location.origin + '/session-closure';              
  //       }
  //       //;
  //   }
  // }

  // useEffect(() => {
    
  //   if(sessionId) getSessionMessages();
  // // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [sessionId]);

  if(sessionId !== null && messageList !== null) {
    return (
      <>
        <Modal.Body>
          <Container className="bg-modal-contact p-0" fluid>
            <Row className="h-100 flex-column">
              <h5 className="contact-title mb-5">Mensagens</h5>
              <div className="" style={{overflow: 'auto', maxHeight: '500px'}}>
                <div className="table-container">   
                  {messageList.length > 0 ? messageList.map((item: any, index: any) => (
                  <Row className="mb-2" key={index}>
                    <Form.Group as={Col} md="12">
                      <Form.Label><span style={{fontWeight: 'bold'}}>{index+1} - {item.userName} em {formatDate(item.timestamp)} as {item.timestamp.substring(11,19)}</span></Form.Label>
                      <Form.Group as={Col} md="12" className="p-2 message-border">{item.message}</Form.Group>
                    </Form.Group>
                  </Row>
                  ))
                  :
                  <Row className="mb-2 text-center"><span>Nenhum histórico de mensagens!</span></Row>}
                </div>
              </div> 
              <div className="mt-auto">
                <Form
                  className="form-add-contact"
                  noValidate
                >
                  <Row>
                    <Form.Group as={Col} md="8">
                      <Controller
                        control={control}
                        name="message"
                        rules={{ required: { value: true, message: 'Por favor, entre com a mensagem' } }}
                        render={({ field }: any) => (
                          <Form.Control type="text"
                            {...field}
                            aria-invalid={errors?.message ? "true" : ""}
                            
                            variant="standard"
                            autoComplete='off'
                            margin="normal"
                            required
                            placeholder="Digite a mensagem aqui..."
                            onChange={(e:any) => { setMessageToSend(e.target.value); field.onChange(e); }}
                            value={messageToSend}
                          />
                        )}
                      />
                      <ErrorMessage
                          errors={errors}
                          name="message"
                          render={({ message }) => <small style={{ color: "red" }}>{message}</small>}
                      />
                    </Form.Group>
                    <Form.Group as={Col} md="4">
                      <button
                        type="button"
                        className="btn btn-primary w-100"
                        onClick={handleSubmit(handleSendMessageClick)}
                      >
                        <FontAwesomeIcon
                          icon={["fal", "paper-plane"]}
                          size="1x"
                          style={{ marginRight: "5px" }}
                        /> Enviar
                      </button>
                    </Form.Group>
                  </Row>
                </Form>
              </div>
            </Row>
          </Container>
        </Modal.Body>
  
      </>
    );
  } else {
    return (<></>);
  }
  
}

export default ModalSessionMessages;
