import React, { Key } from "react";
import Table from "react-bootstrap/Table";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import "react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css";
import "react-bootstrap-table-next/dist/react-bootstrap-table2.css";
import BootstrapTable from "react-bootstrap-table-next";

//STYLES
import "./TablePictureOfTheDay.scss";

export interface propTable {
  sessions: any;
  totalRecebimentos: any;
  totalPagamentos: any;
  totalMargem: any;
  taxaMargem: any;
  exportReport: any;
  printReport: any;
  loading: any;
}

const TablePictureOfTheDay: React.FC<propTable> = ({
  sessions, totalRecebimentos, totalPagamentos, totalMargem, taxaMargem,
  exportReport, printReport, loading
}: propTable) => {
  //console.log(props.suppliers[0].dataCadastro.split('T')[0])

  const handleExportClick = (inc: any) => {
    exportReport();
  }

  const handlePrintClick = (inc: any) => {
    printReport();
  }

  function convertValue(cell: any, casasDecimais: any=2) {

    //console.log(Math.sign(cell))
    return (
      <>
        <span className={Math.sign(cell) === -1 ? "negative" : ""}>{cell.toFixed(casasDecimais).replace(".", ",")}</span>
      </>
    )
  }


  /* ALTERAR OS DATAFIELDS QUANDO A API CHEGAR */

  /* const columns = [
    { dataField: "descricao", text: "Tour / Descrição", sort: true },
    {
      dataField: "totalRecebimentos",
      text: "Recebimentos R$",
      sort: true,
      formatter: convertValue,
    },
    {
      dataField: "totalPagamentos",
      text: "Pagamentos R$",
      sort: true,
      formatter: convertValue,
    },
    {
      dataField: "totalMargem",
      text: "Margem R$",
      sort: true,
      formatter: convertValue,
    },
    {
      dataField: "taxaMargem",
      text: "Margem %",
      sort: true,
      formatter: convertValue,
    }
  ]; */

  /* [END] ALTERAR OS DATAFIELDS QUANDO A API CHEGAR */

  /*--------------------------------------------------*/

  /* In case of empty table*/
  const notFoundColumns = [{ dataField: "clientes", text: "Clientes" }];

  const notFoundProducts = [{ clientes: "Nenhuma session encontrada" }];
  /* [END] In case of empty table*/

  const rowClasses = (row: any, rowIndex: any) => {
    return 'border-analise';
  };

  if (sessions !== null) {
    return (
      <>
        <div className="table-default picture-of-the-day-table">
          <div className="table-container">
            <div>
              <div  className="d-flex flex-wrap align-items-center" style={{ gap: "15px" }}>
                <div>
                  <button className="btn btn-bills" onClick={handleExportClick} disabled={loading === true}>
                    <FontAwesomeIcon
                      icon={["fal", "share"]}
                      size="1x"
                      style={{ marginRight: "5px" }}
                    />
                    Exportar
                  </button>
                </div>
                <div>
                  <button className="btn btn-bills" onClick={handlePrintClick} disabled={loading === true}>
                    <FontAwesomeIcon
                      file-invoice
                      icon={["fal", "print"]}
                      size="1x"
                      style={{ marginRight: "5px" }}
                    />
                    Imprimir
                  </button>
                </div>
              </div>
            </div>
            {loading === false ?
            <Table striped bordered>
              <thead>
                <tr>
                  <th>Tour</th>
                  <th>Total Recebimentos</th>
                  <th>Total Pagamentos</th>
                  <th>Margem</th>
                  <th>Margem / Receita (%)</th>
                </tr>
              </thead>
              <tbody>
                {sessions.length > 0
                  ? sessions.map((info: any, index: Key) => (
                    <tr key={index} className={rowClasses(info, index)}>
                      <td>{info.descricao}</td>
                      <td>{convertValue(info.totalRecebimentos,0)}</td>
                      <td>{convertValue(info.totalPagamentos,0)}</td>
                      <td>{convertValue(info.totalMargem,0)}</td>
                      <td>{convertValue(info.taxaMargem)}</td>
                    </tr>
                  ))
                  : <tr style={{ border: "none" }}><td colSpan={5} className="text-center">Nenhum item encontrado</td></tr>}
              </tbody>
              <tfoot>
                <tr>
                  <th>TOTAL</th>
                  <th>R$ {convertValue(totalRecebimentos,0)}</th>
                  <th>R$ {convertValue(totalPagamentos,0)}</th>
                  <th>R$ {convertValue(totalMargem,0)}</th>
                  <th>{convertValue(taxaMargem)} %</th>
                </tr>
              </tfoot>
            </Table>
            :
            <Table striped bordered>
              <thead>
                <tr>
                  <th>Tour</th>
                  <th>Total Recebimentos</th>
                  <th>Total Pagamentos</th>
                  <th>Margem</th>
                  <th>Margem / Receita (%)</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td><div className="animated-background" style={{height: "1.5rem", width: "100%"}}></div></td>
                  <td><div className="animated-background" style={{height: "1.5rem", width: "100%"}}></div></td>
                  <td><div className="animated-background" style={{height: "1.5rem", width: "100%"}}></div></td>
                  <td><div className="animated-background" style={{height: "1.5rem", width: "100%"}}></div></td>
                  <td><div className="animated-background" style={{height: "1.5rem", width: "100%"}}></div></td>
                </tr>
              </tbody>
              <tfoot>
                <tr>
                  <th>TOTAL</th>
                  <th><div className="animated-background" style={{height: "1.5rem", width: "100%"}}></div></th>
                  <th><div className="animated-background" style={{height: "1.5rem", width: "100%"}}></div></th>
                  <th><div className="animated-background" style={{height: "1.5rem", width: "100%"}}></div></th>
                  <th><div className="animated-background" style={{height: "1.5rem", width: "100%"}}></div></th>
                </tr>
              </tfoot>
            </Table>
            }
            
            {/* <BootstrapTable
              bootstrap4
              keyField="id"
              data={sessions}
              columns={columns}
              rowClasses={rowClasses}
              striped={true}
            /> */}
            {/* <Pagination
              totalRows={totalRows}
              pageCount={pageCount}
              setPageCount={setPageCount}
              rowsPerPage={rowsPerPage}
              setRowsPerPage={setRowsPerPage}
              selectNumberRows="yes"
            /> */}
          </div>
        </div>
      </>
    );
  } else {
    return (
      <>
        <div className="table-default picture-of-the-day-table not-found">
          <div className="table-container">
            {/*             <div className="table-title">
              <h3 className="">Fornecedores</h3>
            </div> */}

            <BootstrapTable
              bootstrap4
              keyField="id"
              data={notFoundProducts}
              hover={true}
              columns={notFoundColumns}
              striped={true}
            />
          </div>
        </div>
      </>
    );
  }
}

export default TablePictureOfTheDay;
