import React from "react";

import { Row, Col, Form, Modal } from "react-bootstrap";
import { Controller } from "react-hook-form";
import { ErrorMessage } from '@hookform/error-message';

import GetSuppliersFilter from "../../C2Points/GetSuppliersFilter";
import GetAccounts from "../../C2Points/GetAccounts";
import RequestResponseModal from "../../Modal/RequestResponseModal/RequestResponseModal";

import "react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css";
import "react-bootstrap-table-next/dist/react-bootstrap-table2.css";
import SingleCalendarEditable from "../../Calendar/SingleCalendarEditable";
import { useTranslation } from "react-i18next";

export interface propTable {
    control: any;
    errors: any
    setDatapgto: any;
    datapgto: any; 
    children?: any;
    modalText?: any;
    modalResponse?: any;
    setModalResponse?: any;
    billsReceive?: any;
    validaFornLiquidacao?: any;
}

const FormBillsToPay: React.FC<propTable> = ({
    control,
    errors,
    setDatapgto,
    datapgto,
    children,
    modalText,
    modalResponse,
    setModalResponse,
    billsReceive,
    validaFornLiquidacao
}: propTable) => {
    const { t } = useTranslation();

    /* RENDER */
    return (
        <>
            <div className='d-flex flex-column justify-content-between h-100'>
                <div>
                    {billsReceive !== true
                        ?
                        <Row className="mb-3">
                            <Form.Group as={Col} md={6} /* controlId="formGridDesc" */>
                                <Form.Label>{t("billsToPay.table.paymentAndDebitNotes.payItTo")}</Form.Label>
                                <Controller
                                    control={control}
                                    name="fornLiquidacao"
                                    rules={{ required: { value: validaFornLiquidacao ? validaFornLiquidacao() : false, message: t("billsToPay.table.paymentAndDebitNotes.requiredField") } }}
                                    render={({ field }: any) => (
                                        <GetSuppliersFilter propsField={field} propsLabel={"Pagar a:"} propsErrors={errors} />
                                    )}
                                />
                                <ErrorMessage
                                    errors={errors}
                                    name="fornLiquidacao"
                                    render={({ message }) => <small style={{ color: "red" }}>{message}</small>}
                                />
                            </Form.Group>
                        </Row>
                        :
                        <>
                        </>
                    }
                    <Row className="mb-3">
                        <Form.Group as={Col} md={3} /* controlId="formGridDesc" */>
                            <Form.Label>{t("billsToPay.table.paymentAndDebitNotes.interestFine")}</Form.Label>
                            <Controller
                                control={control}
                                name="jurosMulta"
                                render={({ field }: any) => (
                                    <Form.Control
                                        {...field}
                                        aria-invalid={errors?.jurosMulta ? "true" : ""}
                                        variant="standard"
                                        margin="normal"
                                        id="juros"
                                        autoComplete="off"
                                    />
                                )}
                            />
                        </Form.Group>
                        <Form.Group as={Col} md={3} /* controlId="formGridDesc" */>
                            <Form.Label>{t("billsToPay.table.paymentAndDebitNotes.discount")}</Form.Label>
                            <Controller
                                control={control}
                                name="descTaxa"
                                render={({ field }: any) => (
                                    <Form.Control
                                        {...field}
                                        aria-invalid={errors?.descTaxa ? "true" : ""}
                                        variant="standard"
                                        margin="normal"
                                        id="desconto"
                                        autoComplete="off"
                                    />
                                )}
                            />
                        </Form.Group>
                        <Form.Group as={Col} md={3} /* controlId="formGridDesc" */>
                            <Form.Label>{t("billsToPay.table.paymentAndDebitNotes.paymentDate")}</Form.Label>
                            <Controller
                                control={control}
                                name="dataPgto"
                                rules={{ required: { value: datapgto ? false : true, message: t("billsToPay.table.paymentAndDebitNotes.requiredField") } }}
                                render={({ field }: any) => (
                                    // <SingleCalendar setValue={setDatapgto} field={field} errors={errors} />
                                    <SingleCalendarEditable setValue={setDatapgto} date={datapgto} errors={errors} />
                                )}
                            />
                            <ErrorMessage
                                errors={errors}
                                name="dataPgto"
                                render={({ message }) => <small style={{ color: "red" }}>{message}</small>}
                            />
                        </Form.Group>
                        <Form.Group as={Col} md={3} /* controlId="formGridDesc" */>
                            <Form.Label>{t("billsToPay.table.paymentAndDebitNotes.settlementBill")}</Form.Label>
                            <Controller
                                control={control}
                                name="contaLiquidacao"
                                rules={{ required: { value: true, message: t("billsToPay.table.paymentAndDebitNotes.requiredField") } }}
                                render={({ field }: any) => (
                                    <GetAccounts propsField={field} propsLabel={t("billsToPay.table.paymentAndDebitNotes.settlmentBill")} propsErrors={errors} />
                                )}
                            />
                            <ErrorMessage
                                errors={errors}
                                name="contaLiquidacao"
                                render={({ message }) => <small style={{ color: "red" }}>{message}</small>}
                            />
                        </Form.Group>
                    </Row>
                </div>
            </div>
            {children
                ?
                children
                :
                <div className="d-flex justify-content-end">
                    <button type="submit" className="btn-default text-uppercase btn btn-primary mt-3">{t("billsToPay.table.paymentAndDebitNotes.settlement")}</button>
                </div>
            }

            <Modal
                className={"modal-custom modalAuth"}
                show={modalResponse !== null}
                onHide={() => setModalResponse(null)}
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
            >
                <>
                    <RequestResponseModal name={t("billsToPay.table.paymentAndDebitNotes.settlement")} modalResponse={modalResponse} setModalResponse={setModalResponse} modalText={modalText} />
                </>
            </Modal>
        </>
    );
}


export default FormBillsToPay;
