import React, { useState } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';
import Button from 'react-bootstrap/Button';
import Accordion from 'react-bootstrap/Accordion';
import { useTranslation } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { Controller, useForm } from 'react-hook-form';

//endpoints//
import GetSuppliersFilter from '../../../../components/C2Points/GetSuppliersFilter';
import RangeCalendar from '../../../../components/Calendar/RangeCalendar';

export interface propFilter {
  buscar: any;
  coupons: any;
  clearActualValues: any;
}

const CouponsFilter: React.FC<propFilter> = ({
  buscar,
  coupons,
  clearActualValues,
}) => {
  const { t } = useTranslation();

  const [filterName, setFilterName] = useState<any>('');
  const [filterDate, setFilterDate] = useState<any>([]);
  const [filterTypeDiscount, setFilterTypeDiscount] = useState<any>(0);
  const [filterTypeCommission, setFilterTypeCommission] = useState<any>(0);
  const [filterSupplier, setFilterSupplier] = useState<any>(0);

  const {
    control,
    setValue,
    formState: { errors },
  } = useForm({});

  const handleFilterClick = () => {
    buscar(
      filterName,
      filterDate,
      filterTypeDiscount,
      filterTypeCommission,
      filterSupplier,
    );
  };

  const handleClearClick = () => {
    setValue('suppliers', '');

    let inputValue = document.querySelectorAll('input');
    for (let i = 0; i < inputValue.length; i++) {
      inputValue[i].value = '';
    }

    let selectValue = document.querySelectorAll('select');
    for (let i = 0; i < selectValue.length; i++) {
      if (selectValue[i].id === 'rowsPerPage') {
        selectValue[i].value = '10';
      } else {
        selectValue[i].value = '0';
      }
    }

    setFilterName('');
    setFilterDate([]);
    setFilterTypeDiscount(0);
    setFilterTypeCommission(0);
    setFilterSupplier(0);
    buscar('', [], 0, 0, 0);
    clearActualValues();
  };

  const handleChangeName = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = e.target.value;
    setFilterName(newValue);
  };

  const handleChangeTypeDiscount = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = e.target.value;
    setFilterTypeDiscount(newValue);
  };

  const handleChangeTypeCommission = (
    e: React.ChangeEvent<HTMLInputElement>,
  ) => {
    const newValue = e.target.value;
    setFilterTypeCommission(newValue);
  };

  /* const handleChangeSupplier = (e: React.ChangeEvent<HTMLInputElement>) => {
        const newValue = e.target.value;
        setFilterSupplier(newValue);
    } */

  return (
    <div
      className="suppliers-filter d-flex justify-content-center bg-white mt-4"
      style={{ textAlign: 'left' }}
    >
      <Accordion defaultActiveKey="1">
        <Accordion.Item eventKey="0">
          <Accordion.Header>
            <FontAwesomeIcon
              icon={['fal', 'sliders-h']}
              size="lg"
              className="mr-2"
            />
            <span className="h5 mb-0">Buscar cupons</span>
          </Accordion.Header>
          <Accordion.Body className="pt-0">
            <Container className="p-0" fluid>
              <Row>
                <div>
                  <Form>
                    <Row className="mb-3">
                      <Form.Group as={Col} md="3">
                        <Form.Label>Nome</Form.Label>
                        <Controller
                          control={control}
                          name="name"
                          render={({ field }: any) => (
                            <Form.Control
                              {...field}
                              type="text"
                              variant="standard"
                              margin="normal"
                              autoComplete="off"
                              onChange={handleChangeName}
                            />
                          )}
                        />
                      </Form.Group>
                      <Form.Group as={Col} md="2">
                        <Form.Label>Data</Form.Label>
                        <InputGroup
                          hasValidation
                          className=""
                          size="sm"
                          placeholder="Quando?"
                        >
                          <RangeCalendar
                            date={filterDate}
                            setDate={setFilterDate}
                          />
                        </InputGroup>
                      </Form.Group>

                      <Form.Group as={Col} md="2">
                        <Form.Label>Tipo de Desconto</Form.Label>
                        <Controller
                          control={control}
                          name="discount-type"
                          render={({ field }: any) => (
                            <div className="endpoint">
                              <Form.Select
                                {...field}
                                as="select"
                                variant="standard"
                                margin="normal"
                                onChange={(e: any) =>
                                  handleChangeTypeDiscount(e)
                                }
                              >
                                <option value="0" selected>
                                  Todos
                                </option>
                                <option value="1">N/A</option>
                                <option value="2">Valor Fixo no total</option>
                                <option value="3">Percentual do produto</option>
                                <option value="4">Percentual do total</option>
                              </Form.Select>
                            </div>
                          )}
                        />
                      </Form.Group>
                      <Form.Group as={Col} md="2">
                        <Form.Label>Tipo de Comissão</Form.Label>
                        <Controller
                          control={control}
                          name="commission-type"
                          render={({ field }: any) => (
                            <div className="endpoint">
                              <Form.Select
                                {...field}
                                as="select"
                                variant="standard"
                                margin="normal"
                                onChange={(e: any) =>
                                  handleChangeTypeCommission(e)
                                }
                              >
                                <option value="0" selected>
                                  Todos
                                </option>
                                <option value="1">N/A</option>
                                <option value="2">Valor Fixo no total</option>
                                <option value="3">Percentual do produto</option>
                                <option value="4">Percentual do total</option>
                              </Form.Select>
                            </div>
                          )}
                        />
                      </Form.Group>
                      <Form.Group as={Col} md="3">
                        <Form.Label>Cliente</Form.Label>
                        <Controller
                          control={control}
                          name="suppliers"
                          defaultValue={filterSupplier}
                          render={({ field }: any) => (
                            <div className="session-closure">
                              <div className="input-select-custom">
                                <GetSuppliersFilter
                                  propsField={field}
                                  propsErrors={errors}
                                  propsLabel="Fornecedor"
                                  setFilterSupplier={setFilterSupplier}
                                />
                              </div>
                            </div>
                          )}
                        />
                      </Form.Group>
                      {/* <Form.Label>{t('listSuppliers.filterStatus')}</Form.Label> */}
                      {/* <div className="endpoint">
                                                    <InputGroup hasValidation>
                                                        <Form.Select
                                                            aria-describedby="inputGroupPrepend"
                                                            onChange={(e: any) => handleChangeStatus(e)}
                                                        >
                                                            <option value="0">{t('listSuppliers.statusAll')}</option>
                                                            <option value="1">{t('listSuppliers.statusAnalysis')}</option>
                                                            <option value="2">{t('listSuppliers.statusActive')}</option>
                                                            <option value="3">{t('listSuppliers.statusInactive')}</option>
                                                        </Form.Select>
                                                    </InputGroup>
                                                </div> */}
                    </Row>
                    <Row
                      as={Col}
                      md="12"
                      className="mt-5 justify-content-md-end"
                    >
                      <Col xs lg="12" className="d-flex justify-content-md-end">
                        <Button
                          variant="outline-primary"
                          className="btn-default mr-2 text-uppercase"
                          onClick={handleClearClick}
                        >
                          {t('reservations.filter.btnClear')}
                        </Button>
                        <Button
                          className="btn-default text-uppercase"
                          onClick={handleFilterClick}
                        >
                          {t('reservations.filter.btnSearch')}
                        </Button>
                      </Col>
                    </Row>
                  </Form>
                </div>
              </Row>
            </Container>
          </Accordion.Body>
        </Accordion.Item>
      </Accordion>
    </div>
  );
};

export default CouponsFilter;
