import React, { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { ErrorMessage } from '@hookform/error-message';
import { Container, Row, Col, Card, Accordion, Form, Button } from 'react-bootstrap';
import ModalShow from "../../../components/Modal/ModalShow/ModalShow";
import api from "../../../services/api";
//import GetSalesChannel from "../../../components/C2Points/GetSalesChannel";
import GetAllSource from "../../../components/C2Points/GetAllSource";

export interface propChannel {
    channels: any,
    isEdit?: boolean
    selectedSource?: any
    setSelectedSource?: any
}

const ChannelForm: React.FC<propChannel> = ({
    channels, isEdit = false, selectedSource = null, setSelectedSource = () => { }
}: propChannel) => {
    const [infoEdit, setInfoEdit] = useState<any>(null);

    const [modalShow, setModalShow] = useState<any>(false);
    const [modalLog, setModalLog] = useState<any>(null);
    const [modalMessage, setModalMessage] = useState<any>("Carregando");
    const [allowCloseModal, setAllowCloseModal] = useState<boolean>(false);
    const [, setSource] = useState<any>([]);
    //const [salesChannel, setSalesChannel] = useState<any>([]);

    const {
        control,
        handleSubmit,
        setValue,
        formState: { errors },
    } = useForm()

    useEffect(() => {
        const token = localStorage.getItem('GroupId') || '{}';
        const config = {
            headers: { 'Authorization': `Bearer ${token}` },
        };
        const GetSalesChannel = async () => {
            try {
                const { data } = await api.post(`SourceChannel/GetSourceChannel`, {
                    sourceCode: selectedSource.sourceCode,
                    idSalesChannel: selectedSource.idSalesChannel
                }, config);

                if (data.statusCode === 200) {
                    setInfoEdit(data.data.data)
                }
            } catch (error: any) {
                setTimeout(() => {
                    GetSalesChannel();
                }, 5000);
            }
        }
        if (selectedSource !== null) {
            GetSalesChannel();
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedSource])

    useEffect(() => {
        if (infoEdit !== null) {
            console.log(infoEdit)
            setValue("idSourceSalesChannel", infoEdit.id);
            setValue("idSalesChannel", infoEdit.idSalesChannel);
            setValue("sourceCode", infoEdit.sourceCode);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [infoEdit])

    const onSubmit = (data: any) => {
        submitChannel(data)
    }

    const submitChannel = async (obj: any) => {
        setModalShow(true);

        const token = localStorage.getItem('GroupId') || '{}';
        const config = {
            headers: { 'Authorization': `Bearer ${token}`, 'Content-Type': 'application/json' },
        };
        try {
            const { data } = await api.post(isEdit ? 'SourceChannel/EditSourceChannel' : 'SourceChannel/InsertSourceChannel', {
                id: isEdit ? obj.idSourceSalesChannel : undefined,
                sourceCode: obj.sourceCode,
                idSalesChannel: obj.idSalesChannel, //obj.salesChannel.map((elem: any) => { return (elem.value) }),
                status: 1,
            }, config);

            if (data.statusCode === 200) {
                if (data.data?.length > 0) { //error
                    setModalLog(data.data[0].log);
                    setModalMessage(data.data[0].texto)
                } else { //sucesso
                    setAllowCloseModal(true);
                    setModalLog(data.data.log);
                    setModalMessage(data.data.texto);
                }
            } else {
                setModalLog(1);
                setModalMessage("Erro ao processar operação");
            }

        } catch (error: any) {
            setModalLog(1);
            setModalMessage("Algo deu errado! Favor entrar em contato com o suporte.");
        }
    }

    useEffect(() => {
        if (allowCloseModal) {
            setSelectedSource(null);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [modalShow])

    return (
        <>
            <Card className="bg-white" style={{ border: 'none' }}>
                <Accordion >
                    <Card.Body className="p-0">
                        <Container className="p-0" fluid>
                            <Form noValidate validated={false} onSubmit={handleSubmit(onSubmit)}>
                                {/* {
                                    infoEdit !== null &&
                                    <>
                                        <div className="h-100 d-flex justify-content-end align-items-end mb-2">
                                            Última alteração feita por:{'\u00A0'}<b>{`${infoEdit.createdBy}`}</b>{'\u00A0'}em{'\u00A0'}<b>{`${infoEdit.date?.split('T')[0].split('-').reverse().join('/')}`}</b>
                                        </div>
                                    </>
                                } */}
                                <Row className="mb-3">
                                    <Form.Group as={Col} md="6" controlId="">
                                        <Form.Label>
                                            Nome do Source:
                                        </Form.Label>
                                        <Controller
                                            control={control}
                                            name="sourceCode"
                                            // disabled
                                            rules={{ required: { value: true, message: `Favor, preencher esse campo` } }}
                                            render={({ field }: any) => (
                                                <GetAllSource
                                                    propsField={field}
                                                    propsErrors={errors}
                                                    setSource={setSource}
                                                    //defaultValue={''}
                                                    setValue={setValue}
                                                    name={'sourceCode'}
                                                />
                                            )}
                                        />
                                        <ErrorMessage
                                            errors={errors}
                                            name="sourceCode"
                                            render={({ message }) => <small style={{ color: "red" }}>{message}</small>}
                                        />
                                    </Form.Group>
                                    <Form.Group as={Col} md="6" controlId="">
                                        <Form.Label>
                                            Canal de venda:
                                        </Form.Label>
                                        <Controller
                                            control={control}
                                            name="idSalesChannel"
                                            rules={{ required: { value: false, message: `Favor, preencher esse campo` } }}
                                            defaultValue={''}
                                            render={({ field }: any) => (
                                                <div className='endpoint'>
                                                    <Form.Select
                                                        {...field}
                                                        as="select"
                                                        variant="standard"
                                                        margin="normal"
                                                    >
                                                        <option value=''>Selecione</option>
                                                        {channels?.filter((elem: any) => elem.statusSalesChannel === 1).map((elem: any) => {
                                                            return (
                                                                <option value={elem.idSalesChannel}>{elem.salesChannelName}</option>
                                                            )
                                                        })}
                                                    </Form.Select>
                                                </div>
                                            )}
                                        />
                                        <ErrorMessage
                                            errors={errors}
                                            name="idSalesChannel"
                                            render={({ message }) => <small style={{ color: "red" }}>{message}</small>}
                                        />
                                    </Form.Group>
                                    {/* <Form.Group as={Col} md="8" controlId="">
                                        <Form.Label className="label-small">
                                            Canais de venda
                                        </Form.Label>

                                        <Controller
                                            control={control}
                                            name="salesChannel"
                                            rules={{ required: { value: true, message: "Por favor, informe esse campo" } }}
                                            render={({ field }: any) => (
                                                <GetSalesChannel propsField={field} propsErrors={errors} setInfoChange={setSalesChannel} setValue={setValue} infoEdit={infoEdit?.idSalesChannel?.map((elem: any) => { return ({ idSalesChannel: elem }) })} />
                                            )}
                                        />

                                        <ErrorMessage
                                            errors={errors}
                                            name="salesChannel"
                                            render={({ message }) => (
                                                <small style={{ color: "red" }}>{message}</small>
                                            )}
                                        />
                                    </Form.Group> */}
                                </Row>

                                <div className="h-100 d-flex justify-content-end align-items-end">
                                    <Row className="mx-0 mt-3">
                                        <Button
                                            type="submit"
                                            className="form-button"
                                        >
                                            Cadastrar
                                        </Button>
                                    </Row>
                                </div>
                            </Form>
                        </Container>
                    </Card.Body>
                </Accordion>
            </Card>
            <ModalShow
                modalShow={modalShow}
                setModalShow={setModalShow}
                modalMessage={modalMessage}
                setModalMessage={setModalMessage}
                modalLog={modalLog}
                setModalLog={setModalLog}
            />
        </>
    )
}

export default ChannelForm;