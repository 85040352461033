import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Accordion, Button, Col, Container, Form, InputGroup, Row } from 'react-bootstrap';
import { Controller, useForm } from "react-hook-form";
import { useTranslation } from 'react-i18next';

import GetAccountsFilter from '../../../../components/C2Points/ReportFinancial/GetAccountsFilter';
import RangeCalendar from '../../../../components/Calendar/RangeCalendar';


export interface propFilter {
    buscar: any;
    defaultAccounts: any;
    defaultDate: any;
}

const FilterReportCashFlow: React.FC<propFilter> = ({
    buscar, defaultAccounts, defaultDate
}) => {
    const { t } = useTranslation();

    const [allowLoadFromFilter, setAllowLoadFromFilter] = useState<any>(false);

    const [filterAccounts, setFilterAccounts] = useState<any>(defaultAccounts);
    const [filterDate, setFilterDate] = useState<any>(defaultDate);


    const {
        control,
        handleSubmit,
        formState: { errors },
    } = useForm();

    const handleClearClick = () => {

        let inputValue = document.querySelectorAll('input');
        for (let i = 0; i < inputValue.length; i++) {
            inputValue[i].value = "";
        }

        let selectValue = document.querySelectorAll('select');
        for (let i = 0; i < selectValue.length; i++) {
            if (selectValue[i].id === "rowsPerPage") {
                selectValue[i].value = "10";
            } else {
                selectValue[i].value = "0";
            }
        }

        //var today: any = new Date();

        setFilterAccounts(defaultAccounts);
        setFilterDate(defaultDate); //[]/* [`${today.getDate()}/${today.getMonth() + 1}/${today.getFullYear() - 1}`, `${today.getDate()}/${today.getMonth() + 1}/${today.getFullYear()}`] */);


        buscar(defaultAccounts, defaultDate); //[`${today.getDate()}/${today.getMonth() + 1}/${today.getFullYear()}`, `${today.getDate()}/${today.getMonth() + 1}/${today.getFullYear()}`]);
        //clearActualValues();
    };

    const onSubmit = (data: any) => {
        
        /* data.promoter = data.promoter?.id || 0;
        data.affiliate = data.affiliate?.id || 0;
        data.channel = (data.channel?.id) || 1;
        data.product = data.product?.code || '';
        data.user = data.user || 0; */

        buscar(filterAccounts, filterDate);
    }

    return (
        <>
            <div className="suppliers-filter d-flex justify-content-center bg-white mt-4" style={{ textAlign: "left" }}>
                <Accordion defaultActiveKey="1">
                    <Accordion.Item eventKey="0">
                        <Accordion.Header><FontAwesomeIcon icon={['fal', 'sliders-h']} size="lg" className="mr-2" /><span className="h5 mb-0">Buscar Relatórios</span></Accordion.Header>
                        <Accordion.Body className="pt-0">
                            <Container className="p-0" fluid>
                                <Row>
                                    <div>
                                        <Form onSubmit={handleSubmit(onSubmit)}>
                                            <Row className="mb-3">
                                                <Form.Group as={Col} md="4">
                                                    <Form.Label>Contas</Form.Label>
                                                    <div className="endpoint">
                                                        <Controller
                                                            control={control}
                                                            name="accounts"
                                                            rules={{ required: { value: true, message: 'Por favor, selecione a(s) conta(s)' } }}
                                                            defaultValue={filterAccounts}
                                                            render={({ field }: any) => (
                                                                <GetAccountsFilter propsField={field} propsErrors={errors} propsLabel={"Contas"} value={filterAccounts} setValue={setFilterAccounts} />
                                                            )}
                                                        />
                                                    </div>
                                                </Form.Group>

                                                <Form.Group as={Col} md="3">
                                                    <Form.Label>Data</Form.Label>
                                                    <InputGroup hasValidation className="ranger-calendar">
                                                        <RangeCalendar date={filterDate} setDate={setFilterDate} />
                                                    </InputGroup>
                                                </Form.Group>
                                            </Row>
 
                                            <Row as={Col} md="12" className="mt-5 justify-content-md-end">
                                                <Col xs lg="12" className="d-flex justify-content-md-end">
                                                    <Button variant="outline-primary" className="btn-default mr-2 text-uppercase" onClick={() => { handleClearClick(); setAllowLoadFromFilter(false) }}>{t('partners.partnersfilter.btnClear')}</Button>
                                                    <Button type="submit" className="btn-default text-uppercase" onClick={() => { }}>{allowLoadFromFilter === true ? <p className="load"></p> : t('partners.partnersfilter.btnSearch')}</Button>
                                                </Col>
                                            </Row>
                                        </Form>
                                    </div>
                                </Row>
                            </Container>
                        </Accordion.Body>
                    </Accordion.Item>
                </Accordion>
            </div>
        </>
    );
}

export default FilterReportCashFlow;