import React, { useEffect, useState } from "react";
import { Card, Form, Row, Col, InputGroup, OverlayTrigger, Tooltip, Button, Modal } from "react-bootstrap";
import { Controller, useForm } from "react-hook-form";
import Breadcrumb from "../../components/Breadcrumb/Breadcrumb";
import DesktopDefault from "../../templates/DesktopDefault";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";

import "./ModalControl.scss";
import SingleCalendarModalControl from "../../components/Calendar/SingleCalendarModalControl";
import api from "../../services/api";
import { ErrorMessage } from "@hookform/error-message";
//import { AnyAction } from "redux";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import ModalAlert from "../../components/Modal/ModalAlert/ModalAlert";
///import Modalities from "../ProductTour/components/Modalities/Modalities";

export interface propEdit {
  hasSavedInputs?: any;
  infoEdit?: any;
  isEdit?: any;
}
const ModalControl: React.FC<propEdit> = ({
  hasSavedInputs,
  infoEdit,
  isEdit = false,
}: propEdit) => {
  const [modalList, setModalList] = useState<any>(null);
  const [url, setUrl] = useState<string>('');
  const [modalActivate, setModalActivate] = useState<boolean>(true);
  const [beginDate, setBeginDate] = useState<any>();
  const [endDate, setEndDate] = useState<any>();
  const [, setImgExt] = useState<any>(null);
  const validated = false;


  const [imageDesktop, setImageDesktop] = useState<any>(
    hasSavedInputs === true ? infoEdit.bannerBase64 : null
  );
  const [imageDesktopName, setImageDesktopName] = useState<any>(
    isEdit === true || hasSavedInputs === true ? infoEdit.imageDesktopName : ""
  );
  const [imageMobile, setImageMobile] = useState<any>(
    hasSavedInputs === true ? infoEdit.bannerBase64 : ""
  );
  const [imageMobileName, setImageMobileName] = useState<any>(
    isEdit === true || hasSavedInputs === true ? infoEdit.imageMobileName : ""
  );
  const [portugueseText, setPortugueseText] = useState<string>("");
  const [englishText, setEnglishText] = useState<string>("");
  const [spanishText, setSpanishText] = useState<string>("");

  const [modalVisualizationShow, setModalVisualizationShow] = useState<boolean>(false);
  const [modalShow, setModalShow] = useState<boolean>(false);
  const [modalLog, setModalLog] = useState<any>(null);
  const [modalMessage, setModalMessage] = useState<any>('Carregando');

  useEffect(() => {
    setValue("beginDate", beginDate);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [beginDate]);

  useEffect(() => {
    setValue("endDate", endDate);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [endDate]);

  // const handlePortugueseText = (e: any) => {
  //   let text: any = e.target.value;
  //   setValue("portugueseText", e.target.value);
  //   setPortugueseText(text);
  // };

  useEffect(() => {
    const token = localStorage.getItem('GroupId') || '{}';
    const config = {
      headers: { 'Authorization': `Bearer ${token}` },
    };
    const getModalInfo = async () => {
      try {
        const { data } = await api.get(`Modals/GetModalsAsync`, config);
        if (data.status !== 400) {
          setUrl(data.data.data.url);
          if (data.data.data.modalList.length > 0) {
            setModalList(data.data.data.modalList);
          }
        }
      } catch (error: any) {

      }
    }
    getModalInfo();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (modalList?.length > 0) {
      setValue('portugueseTitle', modalList[0]?.modalTitle);
      setValue('englishTitle', modalList[0]?.modalTitleEn);
      setValue('spanishTitle', modalList[0]?.modalTitleEs);
      setPortugueseText(modalList[0]?.modalTextPortugues);
      setEnglishText(modalList[0]?.modalTextEnglish);
      setSpanishText(modalList[0]?.modalTextSpanish);
      setModalActivate(modalList[0]?.modalActivate === 1 ? true : false);
      setImageDesktopName(modalList[0]?.modalImgDesktop);
      setImageMobileName(modalList[0]?.modalImgMobile);
      setBeginDate(new Date(modalList[0]?.modalDateStart).toLocaleDateString());
      setEndDate(new Date(modalList[0]?.modalDateEnd).toLocaleDateString());
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [modalList])

  ///////////////////////HANDLERS///////////////////////////

  const handleCheck = (e: any) => {
    setValue("modalActivate", e.target.checked === true ? 1 : 0);
    setModalActivate(e.target.checked);
  };

  const imageHandlerDesktop = (e: any) => {
    if( e.target.files[0].size <= 1048576){
      setImgExt(true);
      setValue("imageDesktopBase64", e.target.value);
      const reader = new FileReader();
      const img: any = new Image();
      reader.onload = () => {
        if (reader.readyState === 2) {
          img.onload = function (){
            if(this.width !== 1920 && this.heigth !== 500){
              alert('As medidas devem ser de 1920 x 500');
            }else{
              setImageDesktop(reader.result);

              setImageDesktopName(e.target.files[0].name);
            }
          };
          img.src = reader.result;
        }
      };
      //console.log(e.target.files[0])
      reader.readAsDataURL(e.target.files[0]);
    }else{
      setImgExt(false);
      alert("Tamanho de imagem não suportado!");
      setValue("bannerBase64PtDesk", '');
      setImageDesktopName('');
      setImageDesktop('');
    }
    };

  const imageHandlerMobile = (e: any) => {
    if( e.target.files[0].size <= 1048576){
      setImgExt(true);
      setValue("imageMobileBase64", e.target.value);
      const reader = new FileReader();
      const img: any = new Image();
      reader.onload = () => {
        if (reader.readyState === 2) {
          img.onload = function (){
            if(this.width !== 768 && this.heigth !== 480){
              alert('As medidas devem ser de 768 x 480');
            }else{
              setImageMobile(reader.result);

              setImageMobileName(e.target.files[0].name);
            }
          };
          img.src = reader.result;
        }
      };
      //console.log(e.target.files[0])
      reader.readAsDataURL(e.target.files[0]);
    }else{
      setImgExt(false);
      alert("Tamanho de imagem não suportado!");
      setValue("imageMobileBase64", '');
      setImageMobileName('');
      setImageMobile('');
    }
  };

  ///////////////////////HANDLERS///////////////////////////

  const modules: any = {
    toolbar: [["bold"], ["italic"]],
  };
  const formats = ["bold", "italic"];

  const onSubmit = (formData: any) => {
    setModalShow(true);
    setModalMessage('Carregando');

    const token = localStorage.getItem("GroupId") || "{}";
    const config = {
      headers: { Authorization: `Bearer ${token}`, "Content-Type": "application/json" },
    };
    formData.modalDateStart = beginDate;
    formData.modalDateEnd = endDate;
    formData.modalImageDesktopBase64 = imageDesktop === '' ? modalList[0]?.modalImgDesktop : imageDesktop;
    formData.modalImageMobileBase64 = imageMobile === '' ? modalList[0]?.modalImgMobile : imageMobile;
    formData.modalPortugueseText = portugueseText.replace("<p>", "").replace("</p>", "");
    formData.modalEnglishText = englishText.replace("<p>", "").replace("</p>", "");
    formData.modalSpanishText = spanishText.replace("<p>", "").replace("</p>", "");

    let imgArrayAux: any = [0, 0];
    let imgObject: any = {
      desktop: '',
      mobile: ''
    };


    const convertImageToToken = async () => {
      let imgArray: any = [];

      if (formData.modalImageDesktopBase64 && imageDesktop !== '') {
        imgArray.push(formData.modalImageDesktopBase64);
        imgArrayAux[0] = 1;
      }

      if (formData.modalImageMobileBase64 && imageMobile !== '') {
        imgArray.push(formData.modalImageMobileBase64);
        imgArrayAux[1] = 1;
      }

      try {
        const { data } = await api.post(
          `File/UploadBase64?switchCaseParam=${4}`,
          imgArray,
          config
        );
        if (data.statusCode !== 400) {
          let index: number = 0;
          if (data.data !== null) {
            if (imgArrayAux[0] === 1) {
              imgObject.desktop = data.data.data[index];
              index++;
            }
            if (imgArrayAux[1] === 1) {
              imgObject.mobile = data.data.data[index];
              index++;
            }
          }

          linkModalAsync(imgObject.desktop, imgObject.mobile);
        }
      } catch (error: any) {
        console.log(error);
        console.log(error?.response);
        setModalMessage(error?.response?.data?.errorMessage || "Erro ao processar operação");
      }
    };

    convertImageToToken();

    const linkModalAsync = async (imgTokenDesktop: string, imgTokenMobile: string) => {
      const token = localStorage.getItem("GroupId") || "{}";
      const config = {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      };
      try {
        const { data } = await api.post(`Modals/LinkModalsAsync`,
          {
            "modalList": [
              {
                "modalTitle": formData.portugueseTitle,
                "modalTitleEn": formData.englishTitle,
                "modalTitleEs": formData.spanishTitle,
                "modalDateStart": formData.modalDateStart.length === 1 ? formData.modalDateStart[0].split('/').reverse().join('-') : formData.modalDateStart.split('/').reverse().join('-'),
                "modalDateEnd": formData.modalDateEnd.length === 1 ? formData.modalDateEnd[0].split('/').reverse().join('-') : formData.modalDateEnd.split('/').reverse().join('-'),
                "modalActivate": modalActivate === true ? 1 : 2,
                "modalTextPortugues": formData.modalPortugueseText,
                "modalTextEnglish": formData.modalEnglishText,
                "modalTextSpanish": formData.modalSpanishText,
                "modalUrl": "",
                //"modalImgDesktop": "",
                "modalImgDesktopToken": imgTokenDesktop !== '' ? imgTokenDesktop : null,
                "modalImgDesktop": imgTokenDesktop === '' ? formData.modalImageDesktopBase64 : null,
                //"modalImgMobile": "",
                "modalImgMobileToken": imgTokenMobile !== '' ? imgTokenMobile : null,
                "modalImgMobile": imgTokenMobile === '' ? formData.modalImageMobileBase64 : null
              }
            ]
          }, config
        );
        if (data.statusCode !== 400) {
          setModalLog(data.data.log);
          setModalMessage(data.data.texto);
        }
      } catch (error: any) {
        console.log(error);
        console.log(error?.response);
      }
    };
  };

  const {
    control,
    setValue,
    handleSubmit,
    formState: { errors },
  } = useForm();

  return (
    <>
      <DesktopDefault>
        <div className="container-fluid container-opening-hours">
          <Breadcrumb title="Controle de Modais" />

          <div className="modal-control-title d-flex justify-content-start w-100">
            <h1>Controle de Modal</h1>
          </div>

          <Card className="card-modal-control">
            <Form
              noValidate
              validated={validated}
              onSubmit={handleSubmit(onSubmit)}
              autoComplete="false"
              className="form-modal-control"
            >
              <Row className="mb-5">
                <Form.Group as={Col} md="12" className="enable-modal">
                  <Form.Label className="m-0 mr-2">Habilitar Modal</Form.Label>
                  <Controller
                    control={control}
                    name="modalActivate"
                    render={({ field }: any) => (
                      <Form.Check
                        type="switch"
                        className="m-0"
                        id="custom-switch"
                        label={modalActivate === true ? "Sim" : "Não"}
                        checked={modalActivate}
                        onChange={(e: any) => handleCheck(e)}
                      />
                    )}
                  />
                </Form.Group>
              </Row>
              <Row className="mb-4">
                <Form.Group as={Col} md="4" sm="12" className="">
                  <Form.Label>Título em Português</Form.Label>
                  <Controller
                    control={control}
                    name="portugueseTitle"
                    rules={{
                      required: {
                        value: true,
                        message: "Escreva um título em português!",
                      },
                    }}
                    render={({ field }: any) => (
                      <Form.Control
                        {...field}
                        aria-invalid={
                          errors?.portugueseTitle ? "true" : "false"
                        }
                        variant="standard"
                        margin="normal"
                        autoComplete="off"

                      />
                    )}
                  />
                  <ErrorMessage
                    errors={errors}
                    name="portugueseTitle"
                    render={({ message }) => (
                      <small style={{ color: "red" }}>{message}</small>
                    )}
                  />
                </Form.Group>

                <Form.Group as={Col} md="4" sm="12" className="">
                  <Form.Label>Título em Inglês</Form.Label>
                  <Controller
                    control={control}
                    name="englishTitle"
                    defaultValue={modalList !== null ? modalList[0]?.modalTitleEn : ""}
                    rules={{
                      required: {
                        value: true,
                        message: "Escreva um título em inglês!",
                      },
                    }}
                    render={({ field }: any) => (
                      <Form.Control
                        {...field}
                        aria-invalid={errors?.englishTitle ? "true" : "false"}
                        variant="standard"
                        margin="normal"
                        autoComplete="off"
                      />
                    )}
                  />
                  <ErrorMessage
                    errors={errors}
                    name="englishTitle"
                    render={({ message }) => (
                      <small style={{ color: "red" }}>{message}</small>
                    )}
                  />
                </Form.Group>

                <Form.Group as={Col} md="4" sm="12" className="">
                  <Form.Label>Título em Espanhol</Form.Label>
                  <Controller
                    control={control}
                    name="spanishTitle"
                    defaultValue={modalList !== null ? modalList[0]?.modalTitleEs : ""}
                    rules={{
                      required: {
                        value: true,
                        message: "Escreva um título em espanhol!",
                      },
                    }}
                    render={({ field }: any) => (
                      <Form.Control
                        {...field}
                        aria-invalid={errors?.spanishTitle ? "true" : "false"}
                        variant="standard"
                        margin="normal"
                        autoComplete="off"
                      />
                    )}
                  />
                  <ErrorMessage
                    errors={errors}
                    name="spanishTitle"
                    render={({ message }) => (
                      <small style={{ color: "red" }}>{message}</small>
                    )}
                  />
                </Form.Group>
              </Row>

              <Row className="mb-5">
                <Form.Group as={Col} md="4" sm="12" className="">
                  <Form.Label>Texto em Português</Form.Label>
                  {/* <Controller
                        control={control}
                        name="portugueseText"
                        rules={{
                          required: {
                            value: true,
                            message: "Escreva um texto em português!",
                          },
                        }}
                        render={({ field }: any) => (                         */}
                  <div className="modal-control-quill mb-2 p-0 endpoint-error">
                    <ReactQuill
                      id="portugueseText"
                      theme="snow"
                      formats={formats}
                      modules={modules}
                      placeholder="Digite um texto..."
                      value={portugueseText}
                      onChange={setPortugueseText}
                    />
                  </div>
                  {/* )}
                      /> */}
                  {/* <ErrorMessage
                        errors={errors}
                        name="portugueseText"
                        render={({ message }) => (
                          <small style={{ color: "red" }}>{message}</small>
                        )}
                      /> */}
                </Form.Group>

                <Form.Group as={Col} md="4" sm="12" className="">
                  <Form.Label>Texto em Inglês</Form.Label>
                  <div className="modal-control-quill mb-2 p-0">
                    {/* <label className="label">Inglês</label> */}
                    <ReactQuill
                      id="englishText"
                      theme="snow"
                      formats={formats}
                      modules={modules}
                      placeholder="Digite um texto..."
                      value={englishText}
                      onChange={setEnglishText}
                    />
                  </div>
                </Form.Group>

                <Form.Group as={Col} md="4" sm="12" className="">
                  <Form.Label>Texto em Espanhol</Form.Label>
                  <div className="modal-control-quill mb-2 p-0">
                    {/* <label className="label">Espanhol</label> */}
                    <ReactQuill
                      id="spanishText"
                      theme="snow"
                      formats={formats}
                      modules={modules}
                      placeholder="Digite um texto..."
                      value={spanishText}
                      onChange={setSpanishText}
                    />
                  </div>
                </Form.Group>
              </Row>

              <Row className="mb-4">
                <Form.Group as={Col} md="4" sm="12" className="">
                  <Form.Label>Início</Form.Label>
                  <Controller
                    control={control}
                    name="beginDate"
                    rules={{
                      required: {
                        value: true,
                        message: "Por favor, insira uma data!",
                      },
                    }}
                    render={() => (
                      <InputGroup hasValidation className="" size="sm">
                        <SingleCalendarModalControl
                          date={beginDate}
                          setValue={setBeginDate}
                        //startTimeLocal ={beginDate}                                                
                        />
                      </InputGroup>
                    )}
                  />
                  <ErrorMessage
                    errors={errors}
                    name="beginDate"
                    render={({ message }) => (
                      <small style={{ color: "red" }}>{message}</small>
                    )}
                  />
                </Form.Group>

                <Form.Group as={Col} md="4" sm="12" className="">
                  <Form.Label>Fim</Form.Label>
                  <Controller
                    control={control}
                    name="endDate"
                    rules={{
                      required: {
                        value: true,
                        message: "Por favor, insira uma data!",
                      },
                    }}
                    render={() => (
                      <InputGroup hasValidation className="" size="sm">
                        <SingleCalendarModalControl date={endDate} setValue={setEndDate} />
                      </InputGroup>
                    )}
                  />
                  <ErrorMessage
                    errors={errors}
                    name="endDate"
                    render={({ message }) => (
                      <small style={{ color: "red" }}>{message}</small>
                    )}
                  />
                </Form.Group>

                <Col md='4'>
                  {console.log(modalList)}
                  {imageDesktop === null
                    ?
                    <div className="w-100"
                      style={{ backgroundImage: `url(${url}${modalList?.length > 0 ? modalList[0]?.modalImgDesktop : ''})`, backgroundRepeat: 'no-repeat', backgroundSize: 'contain', backgroundPosition: 'center', height: '150px' }}>
                    </div>
                    :
                    <div className="w-100"
                      style={{ backgroundImage: `url(${imageDesktop})`, backgroundRepeat: 'no-repeat', backgroundSize: 'contain', backgroundPosition: 'center', height: '150px' }}>
                    </div>
                  }
                </Col>
              </Row>

              <Row className="mb-5">
                <Form.Group
                  as={Col}
                  md="4"
                  className=" d-flex flex-column"

                >
                  <Form.Label className="mb-2">
                    Imagem Desktop - (1920x500)
                  </Form.Label>
                  <Controller
                    control={control}
                    name="imageDesktopBase64"
                    rules={{
                      required: {
                        value: false,
                        message: "Por favor, insira uma imagem!",
                      },
                    }}
                    render={({ field }: any) => (
                      <Form.Control
                        type="file"
                        {...field}
                        aria-invalid={errors?.imageDesktopBase64 ? "true" : ""}
                        fullWidth
                        variant="standard"
                        margin="normal"
                        accept=".jpg"
                        id="imageDesktop"
                        onChange={imageHandlerDesktop}
                        required
                        autoComplete='off'
                      />
                    )}
                  />
                  <div className="d-flex align-items-center">
                    <label
                      className="modal-img-btn mr-2"
                      htmlFor="imageDesktop"
                    >
                      Inserir Imagem
                    </label>
                    <OverlayTrigger
                      overlay={
                        <Tooltip id="tooltip">{imageDesktopName}</Tooltip>
                      }
                    >
                      <span className="modal-bg-wrap">{imageDesktopName}</span>
                    </OverlayTrigger>
                  </div>
                  <ErrorMessage
                    errors={errors}
                    name="imageDesktopBase64"
                    render={({ message }) => (
                      <small style={{ color: "red" }}>{message}</small>
                    )}
                  />
                </Form.Group>

                <Form.Group
                  as={Col}
                  md="4"
                  className=" d-flex flex-column"

                >
                  <Form.Label className="mb-2">
                    Imagem Mobile - (768x480)
                  </Form.Label>
                  <Controller
                    control={control}
                    name="imageMobileBase64"
                    rules={{
                      required: {
                        value: false,
                        message: "Por favor, insira uma imagem!",
                      },
                    }}
                    render={({ field }: any) => (
                      <Form.Control
                        type="file"
                        {...field}
                        aria-invalid={errors?.imageMobileBase64 ? "true" : ""}
                        fullWidth
                        variant="standard"
                        margin="normal"
                        accept=".jpg"
                        id="imageMobile"
                        onChange={imageHandlerMobile}
                        required
                        autoComplete='off'
                      />
                    )}
                  />
                  <div className="d-flex align-items-center">
                    <label className="modal-img-btn mr-2" htmlFor="imageMobile">
                      Inserir Imagem
                    </label>
                    <OverlayTrigger
                      overlay={
                        <Tooltip id="tooltip">{imageMobileName}</Tooltip>
                      }
                    >
                      <span className="modal-bg-wrap">{imageMobileName}</span>
                    </OverlayTrigger>
                  </div>
                  <ErrorMessage
                    errors={errors}
                    name="imageMobileBase64"
                    render={({ message }) => (
                      <small style={{ color: "red" }}>{message}</small>
                    )}
                  />
                </Form.Group>
              </Row>

              <Row>
                <Col className="d-flex justify-content-end align-items-end mb-3">
                  <Button
                    variant="outline-primary"
                    className="btn-default mr-2"
                    type="button"
                    onClick={() => { setModalVisualizationShow(true) }}
                  >
                  Visualizar
                </Button>
                <Button
                  variant="outline-primary"
                  className="btn-default mr-2 "
                  type="button"
                //onClick={handleClose}
                >
                  Cancelar
                </Button>
                <Button className="btn-default " type="submit">
                  Salvar
                </Button>
              </Col>
            </Row>
          </Form>
        </Card>
      </div>

      <ModalAlert modalShow={modalVisualizationShow} setModalShow={setModalVisualizationShow} info={modalList?.length > 0 ? modalList[0] : null} url={url} />

      <Modal
        className="modalAuth"
        show={modalShow}
        onHide={() => {
          if (modalLog === 0) {
            window.location.href = '/modal-control';
          }
          setModalMessage('');
          setModalLog(null);
          setModalShow(false);
        }}
        aria-labelledby="contained-modal-title-vcenter"
      >
        <>
          <div className="d-flex flex-column justify-content-evenly align-items-center p-4" style={{ height: '650px' }}>
            <div className="mb-4">

              {modalLog === 0
                ?
                <FontAwesomeIcon
                  icon={["fal", "check-circle"]}
                  size="5x"
                  className="text-primary"
                  style={{ fontSize: "7.5em" }}
                />
                : modalLog === 1
                  ?
                  <FontAwesomeIcon
                    icon={["fal", "times-circle"]}
                    size="5x"
                    className="text-primary"
                    style={{ fontSize: "7.5em" }}
                  />
                  :
                  <>
                    <div className="loading-modal">
                      <div className="load"></div>
                    </div>
                  </>
              }
            </div>
            <span className="text-center">
              {modalMessage}
            </span>
            <Row className="d-flex justify-content-end mx-0">
              <Button

                type="button"
                className="form-button"
                onClick={() => {
                  if (modalLog === 0) {
                    window.location.href = '/modal-control';
                  }
                  setModalLog(null);
                  setModalMessage('');
                  setModalShow(false);
                }}
              >
                Confirmar
              </Button>
            </Row>
          </div>
        </>
      </Modal>

    </DesktopDefault>
    </>
  );

};
export default ModalControl;