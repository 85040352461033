/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useState } from 'react';
import api from "../../../../services/api";

import { Row, Col, InputGroup } from "react-bootstrap";
import { Controller, useForm } from "react-hook-form";
import { ErrorMessage } from "@hookform/error-message";

import Form from "react-bootstrap/Form";
import SingleCalendar from '../../../../components/Calendar/SingleCalendar';
import GetCostCenter from '../../../../components/C2Points/GetCostCenter';
import GetAccountingAccountsFilter from '../../../../components/C2Points/GetAccountingAccountsFilter';
import GetAgentsExtract from '../../../../components/C2Points/GetAgentsExtract';
import GetPaymentMethodsFilter from '../../../../components/C2Points/GetPaymentMethodsFilter';
import GetAccountOperatorsFilter from '../../../../components/C2Points/GetAccountOperatorsFilter';
import GetAccounts from '../../../../components/C2Points/GetAccounts';

export interface propInsert {
    show?: any;
    setResponseText?: any,
    setModalContent?: any,
    setLog?: any,
    fixAccountId?: any,
    fixAccountName?: any
}

const InsertReceivable: React.FC<propInsert> = ({
    show, setResponseText, setModalContent, setLog, fixAccountId, fixAccountName
}: propInsert) => {

    const [date, setDate] = useState<any>('');
    const [vencimento, setVencimento] = useState<any>('');
    const [loading, setLoading] = useState<any>(false);
    const [erroData, setErroData] = useState<any>(false);
    const [erroVencimento, setErroVencimento] = useState<any>(false);

    const [pago, setPago] = useState<any>(false);
    const [dataPgto, setDataPgto] = useState<any>('');
    const [erroDataPgto, setErroDataPgto] = useState<any>(false);

    const [client, setClient] = useState<any>();
    const [account, setAccount] = useState<any>(fixAccountId ? fixAccountId : '');

    const [formaPagamento, setFormaPagamento] = useState<any>({
        id: 0,
        descricao: 'Cash',
        possuiMdr: 2,
        possuiOperador: 2,
        tipo: 2,
        alias: 'CASH'
    });

    const [file, setFile] = useState<any>('');
    const [fileName, setFileName] = useState<any>('');

    const validated = false;

    const tourAuth: any = JSON.parse(localStorage.getItem('c2tourAuth') || '{}');

    const userName: any = tourAuth.user.nome;
    const userLastName: any = tourAuth.user.lastName;
    const uId: any = tourAuth.user.uId;

    function formatValue(cell: any) {
        //console.log(Math.sign(cell))
        if(cell && cell !== '') {
          return cell.toFixed(2).replace(".", ",");
        } else {
          return '0,00';
        }
        
      }

    const fileHandler = (e: any) => {
        const reader = new FileReader();
        reader.onload = () => {
            if (reader.readyState === 2) {
                setFile(reader.result)
            }
        }
        reader.readAsDataURL(e.target.files[0]);
        setFileName(e.target.files[0].name);
    }

    function closeModal() {
        var closeBtn: any = document.getElementsByClassName("btn-close")[0];
        if (closeBtn !== undefined) {
            closeBtn.click();
        }
    }

    const {
        control,
        handleSubmit,
        formState: { errors },
    } = useForm();

    const fixDate = (data: any) => {
        var aux = data.split('/');

        return (`${aux[2]}-${aux[1]}-${aux[0]}`);
    }

    function verifyDate(now: any, due: any) {
        var nowArray: any = now.split('-');
        var dueArray: any = due.split('-');

        if (parseInt(dueArray[0]) < parseInt(nowArray[0])) {
            return false;
        }
        if (parseInt(dueArray[1]) < parseInt(nowArray[1])) {
            return false;
        }
        if (parseInt(dueArray[2]) < parseInt(nowArray[2])) {
            return false;
        }
        return true;
    }

    const onSubmit = (data: any) => {
        
        
        //

        if(date === '') {
            setErroData(true);
        } else {
            setErroData(false);
        }

        if(vencimento === '') {
            setErroVencimento(true);
        } else {
            setErroVencimento(false);
        }

        if(date === '' || vencimento === '') {
            return;
        }
        console.log("aqui");
        data.descricao = data.descricao ? data.descricao : '';
        data.contaContabil = data.categoria.id;
        data.formaPagamento = formaPagamento.id;
        data.data = fixDate(date);
        data.vencimento = fixDate(vencimento);
        data.valor = parseFloat( data.valor ?  data.valor.replace(',','.') : 0 );

        data.pago = pago === true ? 1 : 2;
        data.dataPgto = dataPgto ? fixDate(dataPgto) : data.vencimento;
        data.desconto = parseFloat( data.desconto ?  data.desconto.replace(',','.') : 0 );
        data.jurosMulta = parseFloat( data.juros ?  data.juros.replace(',','.') : 0 );
        data.valorPago = parseFloat( data.valorPago ?  data.valorPago.replace(',','.') : 0 );
        
        data.conta = account;

        data.supplier = client.supplierId;
        
        data.centroCusto = data.centroCusto ? data.centroCusto.id : null;

        data.observacao = data.observacao ? data.observacao : '';
        //
        setLoading(true)
        setModalContent("loading");
        //if (verifyDate(data.data, data.vencimento)) {
            const insertConta = async () => {
                const token = localStorage.getItem('GroupId') || '{}';
                const config = {
                    headers: { 'Authorization': `Bearer ${token}`, 'Content-Type': 'application/json' },
                };
                try {
                    const res = await api.post(`/Extract/InsertExtract`,
                        {
                            "receberPagar": 1, // 1 = Receber, 2 = Pagar
                            "descricao": data.descricao,
                            "contaContabil": data.contaContabil, // id da conta contabil
                            "formaPagamento": data.formaPagamento, // id da forma pagamento
                            "data": data.data,
                            "vencimento": data.vencimento, // data do vencimento
                            "valor": data.valor,
                            "pago": data.pago,
                            "dataPgto": data.dataPgto,
                            "desconto": data.desconto,
                            "jurosMulta": data.jurosMulta,
                            "valorPago": data.valorPago,
                            "conta": data.conta,
                            "supplier": data.supplier, // identity do fornecedor/cliente
                            "centroCusto":  data.centroCusto, // id do centro custo
                            "observacao": data.observacao,
                            "nomeAnexo": fileName,
                            "arquivoAnexo": file,
                            "uid": uId,
                            "usuario": `${userName} ${userLastName}`
                        }, config);
                    if (res.status !== 400) {
                        setResponseText(res.data.data.texto);
                        setLog(res.data.data.log);
                        setModalContent("success");
                        console.log(res)
                    } else {
                        setResponseText(res.data.data.texto);
                        setLog(res.data.data.log);
                        setModalContent("error");
                    }
                    setLoading(false);
                    closeModal();
                    show(true);
                } catch (error: any) {
                    setLoading(false);
                    setModalContent("error");
                    
                }
            };

            insertConta();
        // } else {
        //     setModalContent("error");
        //     setResponseText("Data de vencimento antes da data atual.");
        //     setLoading(false);
        // }
            
    }

    return (
        <>
            <Form className="h-100" noValidate validated={validated} onSubmit={handleSubmit(onSubmit)} autoComplete="false">
                <div className='d-flex flex-column justify-content-between h-100'>
                    <div>
                        <Row className="mb-3">
                            <Form.Group as={Col}>
                                <Form.Label>Descrição</Form.Label>
                                <Controller
                                    control={control}
                                    name="descricao"
                                    rules={{ required: { value: true, message: "required" }, }}
                                    render={({ field }: any) => (
                                        <Form.Control
                                            {...field}
                                            aria-invalid={errors?.descricao ? "true" : ""}
                                            variant="standard"
                                            margin="normal"
                                            autoComplete="off"
                                        />
                                    )}
                                />
                                <ErrorMessage
                                    errors={errors}
                                    name="descricao"
                                    render={({ message }) => <small style={{ color: "red" }}>{message}</small>}
                                />
                            </Form.Group>

                            <Form.Group as={Col}>
                                <Form.Label>Categoria</Form.Label>
                                <Controller
                                    control={control}
                                    name="categoria"
                                    rules={{ required: { value: true, message: "required" }, }}
                                    render={({ field }: any) => (
                                        <GetAccountingAccountsFilter propsField={field} propsErrors={errors} propsLabel="Categoria" />
                                    )}
                                />
                                <ErrorMessage
                                    errors={errors}
                                    name="categoria"
                                    render={({ message }) => <small style={{ color: "red" }}>{message}</small>}
                                />
                            </Form.Group>
                        </Row>
                        <Row>
                            <Form.Group as={Col} md={4}>
                                <Form.Label>Método Pagamento</Form.Label>
                                <Controller
                                    control={control}
                                    name="formaPagamento"
                                    rules={{ required: { value: !formaPagamento || formaPagamento.id === 0, message: "required" }, }}
                                    render={({ field }: any) => (
                                        <GetPaymentMethodsFilter propsField={field} propsErrors={errors} propsLabel="Método Pagamento" setPaymentMethod={setFormaPagamento} />
                                    )}
                                />
                                <ErrorMessage
                                    errors={errors}
                                    name="formaPagamento"
                                    render={({ message }) => <small style={{ color: "red" }}>{message}</small>}
                                />
                            </Form.Group>
                            <Form.Group as={Col} className="mb-3">
                                <Form.Label>Data Competência</Form.Label>
                                <InputGroup
                                    hasValidation
                                    className={` ${erroData === true ? "endpoint-error" : ""}`} 
                                    size="sm"
                                    placeholder="Quando?"
                                >
                                    <SingleCalendar setValue={setDate} />
                                </InputGroup>
                                {erroData === true ?
                                    <small style={{color: 'red'}}>required</small>
                                :<></>}
                            </Form.Group>
                            <Form.Group as={Col} className="mb-3">
                                <Form.Label>Vencimento</Form.Label>
                                <InputGroup
                                    hasValidation
                                    className={` ${erroVencimento === true ? "endpoint-error" : ""}`}
                                    size="sm"
                                    placeholder="Quando?"
                                >
                                    <SingleCalendar setValue={setVencimento} />
                                </InputGroup>
                                {erroVencimento === true ?
                                    <small style={{color: 'red'}}>required</small>
                                :<></>}
                            </Form.Group>

                            <Form.Group as={Col} md={2}>
                                <Form.Label>Valor</Form.Label>
                                {/* <Form.Control autoComplete='off' type="text" /> */}
                                <Controller
                                    control={control}
                                    name="valor"
                                    rules={{ required: { value: true, message: "required" }, }}
                                    render={({ field }: any) => (
                                        <Form.Control
                                            {...field}
                                            aria-invalid={errors?.valor ? "true" : ""}
                                            variant="standard"
                                            margin="normal"
                                            autoComplete="off"
                                            // onKeyPress={(e) => !/[0-9]/.test(e.key) && e.preventDefault()}
                                        />
                                    )}
                                />
                                <ErrorMessage
                                    errors={errors}
                                    name="valor"
                                    render={({ message }) => <small style={{ color: "red" }}>{message}</small>}
                                />
                            </Form.Group>
                        </Row>

                        <hr style={{ margin: "30px 0" }} />

                        <Row >
                            <Form.Group as={Col} md={2}>
                                <Controller
                                    control={control}
                                    name="pago"
                                    //rules={{ required: { value: true, message: "required" }, }}
                                    render={({ field }: any) => (
                                        <div className='d-flex align-items-center' style={{ height: "86px" }}>
                                            <Form.Check
                                                {...field}
                                                type="radio"
                                                label="Recebido"
                                                name="formHorizontalRadios"
                                                id="formHorizontalRadios3"
                                                onClick={() => { setPago(!pago) }}
                                                checked={pago}
                                            />
                                        </div>
                                    )}
                                />
                            </Form.Group>
                            {/* {
                                pago === true
                                    ?
                                    <> */}
                                        <Form.Group as={Col} className="mb-3">
                                            <Form.Label>Data Pagamento</Form.Label>
                                            <InputGroup
                                                hasValidation
                                                className={` ${erroDataPgto === true ? "endpoint-error" : ""}`}
                                                size="sm"
                                                placeholder="Quando?"
                                            >
                                                <SingleCalendar setValue={setDataPgto} />
                                            </InputGroup>
                                            {erroDataPgto === true ?
                                                <small style={{color: 'red'}}>required</small>
                                            :<></>}
                                        </Form.Group>
                                        <Form.Group as={Col}>
                                            <Form.Label>Descontos/Taxas</Form.Label>
                                            <Controller
                                                control={control}
                                                name="desconto"
                                                //rules={{ required: { value: true, message: "required" }, }}
                                                render={({ field }: any) => (
                                                    <Form.Control
                                                        {...field}
                                                        aria-invalid={errors?.desconto ? "true" : ""}
                                                        variant="standard"
                                                        margin="normal"
                                                        autoComplete="off"
                                                        // onKeyPress={(e) => !/[0-9]/.test(e.key) && e.preventDefault()}
                                                    />
                                                )}
                                            />
                                            <ErrorMessage
                                                errors={errors}
                                                name="desconto"
                                                render={({ message }) => <small style={{ color: "red" }}>{message}</small>}
                                            />
                                        </Form.Group>
                                        <Form.Group as={Col}>
                                            <Form.Label>Juros/Multas</Form.Label>
                                            <Controller
                                                control={control}
                                                name="juros"
                                                //rules={{ required: { value: true, message: "required" }, }}
                                                render={({ field }: any) => (
                                                    <Form.Control
                                                        {...field}
                                                        aria-invalid={errors?.juros ? "true" : ""}
                                                        variant="standard"
                                                        margin="normal"
                                                        autoComplete="off"
                                                        // onKeyPress={(e) => !/[0-9]/.test(e.key) && e.preventDefault()}
                                                    />
                                                )}
                                            />
                                            <ErrorMessage
                                                errors={errors}
                                                name="juros"
                                                render={({ message }) => <small style={{ color: "red" }}>{message}</small>}
                                            />
                                        </Form.Group>
                                        <Form.Group as={Col}>
                                            <Form.Label>Valor Pago</Form.Label>
                                            <Controller
                                                control={control}
                                                name="valorPago"
                                                //rules={{ required: { value: true, message: "required" }, }}
                                                render={({ field }: any) => (
                                                    <Form.Control
                                                        {...field}
                                                        aria-invalid={errors?.valorPago ? "true" : ""}
                                                        variant="standard"
                                                        margin="normal"
                                                        autoComplete="off"
                                                        // onKeyPress={(e) => !/[0-9]/.test(e.key) && e.preventDefault()}
                                                    />
                                                )}
                                            />
                                            <ErrorMessage
                                                errors={errors}
                                                name="valorPago"
                                                render={({ message }) => <small style={{ color: "red" }}>{message}</small>}
                                            />
                                        </Form.Group>
                                    {/* </>
                                    :
                                    <></>
                            } */}
                        </Row>

                        <Row>
                            
                            <Form.Group as={Col} md="6">
                                <Form.Label>Conta</Form.Label>
                                {!fixAccountId ?
                                <Controller
                                    control={control}
                                    name="account"
                                    rules={{ required: { value: account ? false : true, message: "required" }, }}
                                    //defaultValue={categoria?.id}
                                    defaultValue={account}
                                    render={({ field }: any) => (
                                        <GetAccounts propsField={field} propsErrors={errors} propsLabel="Conta" setAccount={setAccount} />
                                    )}
                                />
                                :<input
                                    type="text"
                                    className="form-control"
                                    disabled={true}
                                    value={fixAccountName}
                                    autoComplete='off'
                                />}
                                <ErrorMessage 
                                    errors={errors}
                                    name="account"
                                    render={({ message }) => <small style={{ color: "red" }}>{message}</small>}
                                />
                            </Form.Group>
                            
                            <Form.Group as={Col} md={3}>
                                <Form.Label>Anexar Documento</Form.Label>
                                <Controller
                                    control={control}
                                    name="arquivo"
                                    render={({ field }: any) => (
                                        <Form.Control type="file"
                                            {...field}
                                            label="arquivo"
                                            aria-invalid={errors?.arquivo ? "true" : ""}
                                            fullWidth
                                            variant="standard"
                                            margin="normal"
                                            id="arquivo"
                                            accept=".jpg"
                                            onChange={fileHandler}
                                            autoComplete='off'
                                            required />
                                    )}
                                />
                                <label className="btn-file w-100" htmlFor="arquivo">Escolha um arquivo</label>
                            </Form.Group>
                            <div className="col-12 col-md-3 d-flex">
                                <div className="w-100 mt-auto mb-2" style={{ wordBreak: "break-word", textAlign: "left", lineHeight: 1.5 }}>
                                    {fileName}
                                </div>
                            </div>
                        </Row>

                        <hr style={{ margin: "30px 0" }} />

                        <Row className="mb-3">
                            <Form.Group as={Col} md={6}>
                                <Form.Label>Cliente</Form.Label>
                                <Controller
                                    control={control}
                                    name="fornecedor"
                                    rules={{ required: { value: !client, message: "required" }, }}
                                    render={({ field }: any) => (
                                        <GetAgentsExtract
                                            propsField={field}
                                            propsErrors={errors}
                                            propsLabel="Cliente"
                                            actionAgents={setClient}
                                            showCorporateNames={true}
                                        />

                                    )}
                                />
                                <ErrorMessage
                                    errors={errors}
                                    name="fornecedor"
                                    render={({ message }) => <small style={{ color: "red" }}>{message}</small>}
                                />
                            </Form.Group>

                            <Form.Group as={Col} md={6} className="mb-3">
                                <Form.Label>Centro de Custo</Form.Label>
                                <Controller
                                    control={control}
                                    name="centroCusto"
                                    render={({ field }: any) => (
                                        <GetCostCenter propsField={field} propsErrors={errors} propsLabel="Centros de Custo" />
                                    )}
                                />
                                <ErrorMessage
                                    errors={errors}
                                    name="centroCusto"
                                    render={({ message }) => <small style={{ color: "red" }}>{message}</small>}
                                />
                            </Form.Group>
                        </Row>

                        <Row className="mb-3">
                            <Form.Group as={Col}>
                                <Form.Label>Observação</Form.Label>
                                <Controller
                                    control={control}
                                    name="observacao"

                                    render={({ field }: any) => (
                                        <Form.Control
                                            {...field}
                                            aria-invalid={errors?.observacao ? "true" : ""}
                                            variant="standard"
                                            margin="normal"
                                            autoComplete="off"
                                            as="textarea"
                                            rows={4}
                                        />
                                    )}
                                />
                                <ErrorMessage
                                    errors={errors}
                                    name="observacao"
                                    render={({ message }) => <small style={{ color: "red" }}>{message}</small>}
                                />
                            </Form.Group>
                        </Row>
                    </div>
                    <div className='mt-4 d-flex justify-content-end'>
                        <button
                            className="btn btn-default btn-outline-primary px-4 mr-3 text-uppercase"
                            onClick={closeModal}
                        >
                            Cancelar
                        </button>
                        <button
                            className="btn btn-default btn-primary px-4 text-uppercase"
                            type="submit"
/*                             onClick={() => {
                                setLoading(true)
                            }} */
                        >
                            {
                                loading === true
                                    ?
                                    <div className="load"></div>
                                    :
                                    "Cadastrar"
                            }
                        </button>
                    </div>
                </div>
            </Form>
        </>
    )
}

export default InsertReceivable;