import React from 'react';
import Breadcrumb from '../../../components/Breadcrumb/Breadcrumb';
import EditPartnersData from '../../Partners/components/EditPartners/EditPartnersData/EditPartnersData';
import DesktopDefault from '../../../templates/DesktopDefault';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

function EditPartners() {
  return (
    <>
      <DesktopDefault>
        <div className="container-fluid content-dashboard">
          <Breadcrumb title="Editar Clientes" />
          <div className="partners mt-4">
            <div className="d-flex align-items-center">
              <span className='back' onClick={() => { window.location.href = '/partners' }}>
                <FontAwesomeIcon
                  icon={["fal", "angle-double-left"]}
                  style={{ margin: "0 5px 0 0", fontSize: '16px' }}
                />
                Voltar</span>
            </div>
            <h2 className="title" style={{ color: "#707070" }}>Clientes</h2>

            {/* Content */}

            <EditPartnersData />

            {/* Content */}

          </div>
        </div>
      </DesktopDefault>

    </>
  );
}

export default EditPartners;