import React from "react";
import { Container, Row, Col } from "react-bootstrap";

import "../../../assets/sass/modal.scss";

//import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import { Controller, useForm } from "react-hook-form";
import Modal from "react-bootstrap/Modal";

//import IconSucess from "../../../assets/icons/modal-sucess.svg";

export interface propModal {
  action: any,
  info: any
};

const FormAddInstallmentsRange: React.FC<propModal> = ({
  action, info
}: propModal, props: any) => {

  //const [receiptDays, setReceiptDays] = useState<any>(Array.from({ length: 32 }, (_, i) => i));


  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm({
    defaultValues: {
      amountMin: info ? info.amountMin : '',
      amountMax: info ? info.amountMax : '',
      installmentsAllowed: info ? info.installmentsAllowed : '2',
      id: info ? info.id : -1
    },
  });

  const onSubmitContact = (data: any) => {
    //data.id = id;
    //setId(id - 1);

    if (!info) {
      data.id = (Math.floor(Math.random() * 100000) + 1) * (-1);
    }

    action(data);
  };

  return (
    <>
      <Container className="bg-modal-contact p-0" fluid>
        <Row>
          {/* <h5 className="contact-title mb-5">Taxa e Prazo</h5> */}
          <Form
            className="form-add-contact"
            noValidate
          >
            <div className="">
              <Row className="mb-3">
                <Form.Group as={Col} md="6" controlId="validationCustom03">
                  <Form.Label>Valor Minimo:</Form.Label>
                  <Controller
                    control={control}
                    name="amountMin"
                    rules={{ required: { value: true, message: 'Por favor, preencher esse campo' } }}
                    render={({ field }: any) => (
                      <Form.Control type="text"
                        {...field}
                        aria-invalid={errors?.amountMin ? "true" : ""}
                        autoComplete='off'
                        variant="standard"

                        margin="normal"
                        required />
                    )}
                  />
                </Form.Group>
                <Form.Group as={Col} md="6" controlId="validationCustom03">
                  <Form.Label>Valor Máximo:</Form.Label>
                  <Controller
                    control={control}
                    name="amountMax"
                    rules={{ required: { value: true, message: 'Por favor, preencher esse campo' } }}
                    render={({ field }: any) => (
                      <Form.Control type="text"
                        {...field}
                        aria-invalid={errors?.amountMax ? "true" : ""}
                        autoComplete='off'
                        variant="standard"

                        margin="normal"
                        required />
                    )}
                  />
                </Form.Group>
              </Row>
              <Row className="mb-3">
                <Form.Group as={Col} md="6" controlId="validationCustom03">
                  <Form.Label>Qtde Max Parcelas:</Form.Label>
                  <Controller
                    control={control}
                    name="installmentsAllowed"
                    rules={{ required: { value: true, message: 'Por favor, preencher esse campo' } }}
                    render={({ field }: any) => (
                      <Form.Control type="number"
                        {...field}
                        aria-invalid={errors?.installmentsAllowed ? "true" : ""}
                        autoComplete='off'
                        variant="standard"
                        min={1}
                        max={100}
                        margin="normal"
                        required />
                    )}
                  />
                </Form.Group>
              </Row>
            </div>
          </Form>
        </Row>
      </Container>

      <Modal.Footer>
        <Row className="buttons-modal d-flex justify-content-end mx-0">
          {/* <Button className="form-button mr-3" onClick={props.onHide}>
            Cancelar
          </Button> */}
          <Button
            type="button"
            className="form-button"
            onClick={handleSubmit(onSubmitContact)}
          >
            Salvar
          </Button>
        </Row>
      </Modal.Footer>


    </>
  );
}

export default FormAddInstallmentsRange;
