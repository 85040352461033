import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import './SessionInfo.scss'
import React, { useEffect, useState } from 'react';
import { Card, Modal, Row } from "react-bootstrap";
import ModalSessionMessages from '../../../../components/Modal/ModalSessionMessages/ModalSessionMessages';
import api from '../../../../services/api';
import SessionPassengers from '../SessionPassengers/SessionPassengers';
import SessionResources from '../SessionResources/SessionResources';


//COMPONENTS


export interface propInfo {
    sessionInfo: any,
    refreshData: any
};

const SessionInfo: React.FC<propInfo> = ({
    sessionInfo, refreshData
}: propInfo) => {
    const [insert, setInsert] = useState<any>(6);

    const [sessionMessagesShow, setSessionMessagesShow] = useState<any>(false);
    const [sessionMessageList, setSessionMessageList] = useState<any>([]);

    /* Success Modal (Criar Session) */
    const [modalSuccess, setModalSuccess] = useState<any>(false);
    const [responseText, setResponseText] = useState<any>();
    const [modalContent, setModalContent] = useState<any>();
    const [log, setLog] = useState<any>();
    /* END - Success Modal (Criar Session) */

    const [totalResources, setTotalResources] = useState<any>(0);
    const [totalProfit, setTotalProfit] = useState<any>(0);
    const [profitTax, setProfitTax] = useState<any>(0);
    
    const statusTour = window.location.href.split('status=')[1];
    //console.log(statusTour);

    const operationType = window?.location?.href?.split('operation=')[1]?.split('?')[0];
    //console.log(operationType);

    const getSessionMessages = async (sessionId: any) => {
        const token = localStorage.getItem('GroupId') || '';
    
        const config = {
          headers: { 'Authorization': `Bearer ${token}` },
        };
        try {
    
            const { data } = await api.post(`Manifest/GetSessionMessagesAsync`, {
              "sessionId": sessionId
            }, config);
            /* console.log(data.data); */
            if (data.status !== 400) {
                var response = data.data;
    
                setSessionMessageList(response);
                if(response.length > 0) setSessionMessagesShow(true);
            }   
        }catch(error: any) {
            if (error?.response?.status === 401) {
                window.location.href = window.location.origin + '/';              
            }
            if (error?.response?.status === 400) {
                //window.location.href = window.location.origin + '/session-closure';              
            }
            //;
        }
    }

    const exportSessionItems = async () => {

      const token = localStorage.getItem('GroupId') || '{}';
      const config = {
          headers: { 'Authorization': `Bearer ${token}`, 'Content-Type': 'application/json' },
      };
      
      try {
        const exp = await api.post('/Manifest/ExportSessionItems', {
            "sessionId": sessionInfo.sessionId
          },
          config
        );
        if (exp.status !== 400) {
          const dados = exp.data.data;

          const objectUrl = `data:${dados.fileType};base64,${dados.file}`;                

          const link = document.createElement('a');
          link.href = objectUrl;
          link.setAttribute(
            'download',
            dados.fileName,
          );

          // Append to html link element page
          document.body.appendChild(link);

          // Start download
          link.click();

          // // Clean up and remove the link
          link?.parentNode?.removeChild(link);
        }
      } catch (error: any) {
        //setError(true)
        if (error?.response?.status === 401) {
          window.location.href = window.location.origin + '/';
        }
      }
    };

    function convertValue(cell: any) {
      //console.log(Math.sign(cell))
      return (
        <>
          <span className={Math.sign(cell) === -1 ? "negative" : ""}>{cell.toFixed(2).replace(".", ",")}</span>
        </>
      )
    }

    function calcula(resources: any) {
      var recebe = sessionInfo.totalReceive;
      var paga = 0;

      // if(pBillsReceive) {
      //     pBillsReceive.forEach((e: any) => {
      //         recebe += e.valor;
      //     });
      // }
      
      if(resources) {
        resources.forEach((e: any) => {
          paga += e.valorNet;
        });
      }
      
      const margem = recebe - paga;
      const taxaMargem = recebe !== 0 ? margem / recebe : 0;

      setTotalResources(paga);
      setTotalProfit(margem);
      setProfitTax((taxaMargem) * 100);
  }

    useEffect(() => {

        if(sessionInfo) {
          getSessionMessages(sessionInfo.sessionId);

          calcula(sessionInfo.resources);
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [sessionInfo]);

    return (
        <>
            <Card className="bg-white mt-4 p-4">
                <div className="suppliers d-flex justify-content-between flex-column">
                    <h2 className="text-primary">{`Session - ${sessionInfo.sessionId}`}</h2>
                    <span>{sessionInfo.descricao}</span>
                </div>
                {/* <hr style={{ height: "1px" }} /> */}

                <div className="d-flex pax">
                    {
                        statusTour === "0"
                            ?
                            <>
                                <div className="bg-pendente" style={{ width: "120px", maxWidth: "200px" }}>
                                    Pendente
                                </div>
                            </>
                            :
                            statusTour === "1"
                                ?
                                <>
                                    <div className="bg-ativo" style={{ width: "150px", maxWidth: "200px" }}>
                                        Em Operação
                                    </div>
                                </>
                                :
                                <>
                                    <div className="bg-analise" style={{ width: "180px", maxWidth: "200px" }}>
                                        Alocação Parcial
                                    </div>
                                </>
                    }
                    <div className="total-pax">
                        <p>{`${sessionInfo.totalPax} Participantes`}</p>
                    </div>
                    <div className="pax-units">
                        <p>{`${sessionInfo.totalAdult} Adulto`}</p>
                    </div>
                    <div className="pax-units">
                        <p>{`${sessionInfo.totalChild} Child`}</p>
                    </div>
                    <div className="pax-units">
                        <p>{`${sessionInfo.totalInfant} Infanto`}</p>
                    </div>
                    <div className="cards d-flex w-50">
                      <div className="col-md-3 card-unit">
                        <Card>
                          <div className="col-md-10 d-flex align-items-center">
                            <span className="card-title m-0 mx-2">
                              Recebimentos
                            </span>
                            <span className="cart-content">
                              {sessionInfo.totalReceive.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })}
                            </span>
                          </div>
                          <div className="col-md-2 d-flex justify-content-center align-items-center">
                            <FontAwesomeIcon
                              icon={["fad", "receipt"]}
                              className="text-primary p-1"
                              size="2x"
                            />
                          </div>
                        </Card>
                      </div>
                      <div className="col-md-3 card-unit">
                        <Card>
                          <div className="col-md-10 d-flex align-items-center">
                              <span className="card-title m-0 mx-2">
                                Recursos
                              </span>
                              <span className="cart-content">
                                {totalResources.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })}
                              </span>
                          </div>
                          <div className="col-md-2 d-flex justify-content-center align-items-center">
                            <FontAwesomeIcon
                              icon={["fad", "hand-holding-usd"]}
                              className="text-primary p-1"
                              size="2x"
                            />
                          </div>
                        </Card>
                      </div>
                      <div className="col-md-3 card-unit">
                        <Card>
                          <div className="col-md-10 d-flex align-items-center">
                              <span className="card-title m-0 mx-2">
                                Margem
                              </span>
                              <span className="cart-content">
                                {totalProfit.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })}
                              </span>
                          </div>
                          <div className="col-md-2 d-flex justify-content-center align-items-center">
                            <FontAwesomeIcon
                              icon={["fad", "dollar-sign"]}
                              className="text-primary p-1"
                              size="2x"
                            />
                          </div>
                        </Card>
                      </div>
                      <div className="col-md-3 card-unit">
                        <Card>
                          <div className="col-md-10 d-flex align-items-center">
                              <span className="card-title m-0 mx-2">
                                Margem (%)
                              </span>
                              <span className="cart-content">
                                {convertValue(profitTax)}
                              </span>
                          </div>
                          <div className="col-md-2 d-flex justify-content-center align-items-center">
                            <FontAwesomeIcon
                              icon={["fad", "chart-line"]}
                              className="text-primary p-1"
                              size="2x"
                            />
                          </div>
                        </Card>
                      </div>
                    </div>
                </div>

                {operationType === "1" ?
                <div className="recursos">
                    <h6>Recursos</h6>

                    <SessionResources
                        resources={sessionInfo.resources}
                        sessionInfo={sessionInfo}
                        insert={insert}
                        UpdateTotals={calcula}
                    />

                    <div className="new-resource d-flex justify-content-center my-4">
                        {
                            insert === 6
                                ?
                                <>
                                    <button className='btn' onClick={() => setInsert(7)}>Novo Recurso</button>
                                </>
                                :
                                <>
                                    <button className='btn' onClick={() => setInsert(6)}>Remover</button>
                                </>
                        }
                    </div>
                </div>
                :<></>}

                <div className="passageiros">

                    <SessionPassengers items={sessionInfo.items} sessionInfo={sessionInfo} refreshData={refreshData} operationType={operationType} exportSessionItems={exportSessionItems} />

                </div>
            </Card>

            {/* modal mensagens session */}
            <Modal
                className={"modal-confirm loading-modal"}
                show={sessionMessagesShow}
                onHide={() => setSessionMessagesShow(false)}
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
            >
                <>
                    <Modal.Header className="p-3" closeButton></Modal.Header>
                    <ModalSessionMessages
                    sessionId={sessionInfo.sessionId}
                    show={setModalSuccess}
                    setResponseText={setResponseText}
                    setModalContent={setModalContent}
                    setLog={setLog}
                    messageList={sessionMessageList}
                    getSessionMessages={getSessionMessages}
                    />
                </>
            </Modal>    
            {/* END - modal mensagens session */}

            {/* Modal de Success */}
            <Modal
              className={"modal-confirm loading-modal"}
              show={modalSuccess}
              onHide={() => setModalSuccess(false)}
              size="lg"
              aria-labelledby="contained-modal-title-vcenter"
            >
              <>
                <Modal.Body className="modal-body text-center sucess-pos">
                  {modalContent !== "loading" ? (
                    <>
                      <div>
                        {log === 1 || log === "1" ? (
                          <FontAwesomeIcon
                            icon={["fal", "times-circle"]}
                            size="5x"
                            className="text-primary"
                            style={{ fontSize: "7.5em" }}
                          />
                        ) : log === 0 || log === "0" ? (
                          <FontAwesomeIcon
                            icon={["fal", "check-circle"]}
                            size="5x"
                            className="text-primary"
                            style={{ fontSize: "7.5em" }}
                          />
                        ) : (
                          <FontAwesomeIcon
                            icon={["fal", "question-circle"]}
                            size="5x"
                            className="text-primary"
                            style={{ fontSize: "7.5em" }}
                          />
                        )}
                      </div>
                      <div>{responseText}</div>
                      <div className="d-flex justify-content-center">
                        {modalContent === "success" ? (
                          <button
                            onClick={() => setModalSuccess(false)}
                            className="btn btn-primary mx-2 w-25"
                          >
                            Confirmar
                          </button>
                        ) : modalContent === "ok" ? (
                          <button
                            onClick={() => setModalSuccess(false)}
                            className="btn btn-primary mx-2 w-25"
                          >
                            Confirmar
                          </button>
                        ) : (
                          <button
                            onClick={() => setModalSuccess(false)}
                            className="btn btn-primary mx-2 w-25"
                          >
                            Confirmar
                          </button>
                        )}
                      </div>
                    </>
                  ) : (
                    <div className="modal-body">
                      <div className="text-center">
                        <div className="load"></div>
                      </div>
                      <div>
                        <span>Processando</span>
                      </div>
                      <div></div>
                    </div>
                  )}
                </Modal.Body>
              </>
            </Modal>
            {/* END - Modal de Success */}
        </>
    );

}

export default SessionInfo;