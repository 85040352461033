import React, { useEffect, useState, Key } from 'react';

import { useTranslation } from "react-i18next";
import api from '../../services/api';

import Form from 'react-bootstrap/Form';

export interface propPlus {
  propsField: any,
  propsLabel: any,
  propsErrors: any,
  setFilterState: any,
};

const GetStatesFilter: React.FC<propPlus> = ({
  propsField, propsLabel, propsErrors, setFilterState
}: propPlus) => {
  const { t } = useTranslation();
  const [info, setInfo] = useState<any>(null);

  //const [isMenuOpen, setIsMenuOpen] = useState<boolean>(false);

  /* const onMenuOpen = () => {
    if (isMenuOpen === false) {
      setIsMenuOpen(true);
    } else {
      setIsMenuOpen(false);
    }
  } */

  useEffect(() => {
    const token = localStorage.getItem('GroupId') || '{}';
    const config = {
      headers: { 'Authorization': `Bearer ${token}` },
    };
    async function listStates() {
      try {
        const { data } = await api.get(`/DropDown/GetStates`, config);
        if (data.status !== 400) {
          setInfo(data.data);
        }
      } catch (error: any) {
        if (error.response.status === 401) {
          window.location.href = window.location.origin + '/';
        }
      }
    }
    listStates();
  }, []);

  const handleChangeState = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = e.target.value;
    setFilterState(newValue);
  }

  if (info !== null) {
    return (
      <>
      <div className='endpoint'>
        <Form.Select
          {...propsField}
          label={propsLabel}
          as="select"
          variant="standard"
          margin="normal"
          onChange={(e: any) => handleChangeState(e)}
        >
          <option value='0' selected disabled>{t("listSuppliers.endpoint")}</option>
          {info.length > 0 ? info.map((info: any, index: Key) => {
            return (
              <option key={index} value={info.id}>
                {info.description}
              </option>
            )
          }) : ''}
        </Form.Select>
      </div>
      </>
    );
  } else {
    return (
      <>
        <Form.Select>
          <option value='' disabled></option>
        </Form.Select>
      </>
    )
  }
}

export default GetStatesFilter;