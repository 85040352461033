import React, {   useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  Accordion,
  Button,
  Col,
  Container,
  Form,
  InputGroup,
  Row,
} from "react-bootstrap";
import { Controller, useForm } from "react-hook-form";
import RangeCalendar from "../../../../components/Calendar/RangeCalendar";
import "./CommissionsFilter.scss";

export interface propFilter {
  commissions: any;
  buscar: any;
  clearActualValues: any;
  t?: any;
}

const CommissionsFilter: React.FC<propFilter> = ({
  commissions,
  buscar,
  clearActualValues,
  t
}) => {


  const [filterName, setFilterName] = useState<any>("");
  const [filterDate, setFilterDate] = useState<any>([]);
  const [filterStatus, setFilterStatus] = useState<any>(0);
  const [filterTourcodeOrNot, setFilterTourcodeOrNot] = useState<any>(0);

  const {
    control,
    //handleSubmit,
    //register,
  } = useForm({});

  const handleFilterClick = () => {
    buscar(filterName, filterStatus, filterTourcodeOrNot, filterDate);
  };

  const handleClearClick = () => {
    let inputValue = document.querySelectorAll("input");
    for (let i = 0; i < inputValue.length; i++) {
      inputValue[i].value = "";
    }

    let selectValue = document.querySelectorAll("select");
    for (let i = 0; i < selectValue.length; i++) {
      if (selectValue[i].id === "rowsPerPage") {
        selectValue[i].value = "10";
      } else {
        selectValue[i].value = "";
      }
    }

    setFilterName("");
    setFilterDate([]);
    setFilterStatus(0);
    setFilterTourcodeOrNot(1);
    buscar("", "", 1, []);
    clearActualValues();
  };

  const handleChangeName = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = e.target.value;
    setFilterName(newValue);
  };

  const handleChangeStatus = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = e.target.value;
    setFilterStatus(newValue);
  };

  const handleChangeTourCode = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = e.target.value;
    setFilterTourcodeOrNot(newValue);
  };

  return (
    <div
      className="suppliers-filter d-flex justify-content-center bg-white mt-4"
      style={{ textAlign: "left" }}
    >
      <Accordion defaultActiveKey="1">
        <Accordion.Item eventKey="0">
          <Accordion.Header>
            <FontAwesomeIcon
              icon={["fal", "sliders-h"]}
              size="lg"
              className="mr-2"
            />
            <span className="h5 mb-0">{t("commissions.filter.searchCommissions")}</span>
          </Accordion.Header>
          <Accordion.Body className="pt-0">
            <Container className="p-0" fluid>
              <Row>
                <div>
                  <Form>
                    <Row className="mb-3">
                      <Form.Group as={Col} md="4">
                        <Form.Label>{t("commissions.filter.searchFor")}</Form.Label>
                        <Controller
                          control={control}
                          name="name"
                          render={({ field }: any) => (
                            <Form.Control
                              {...field}
                              type="text"
                              onChange={handleChangeName}
                              value={filterName}
                              placeholder={t("commissions.filter.searchForPlaceHolder")}
                              variant="standard"
                              margin="normal"
                              autoComplete="off"
                            />
                          )}
                        />
                      </Form.Group>

                      <Form.Group as={Col} md="3">
                        <Form.Label>{t("commissions.filter.from")}</Form.Label>
                        <InputGroup
                          hasValidation
                          className=""
                          size="sm"
                          placeholder="Quando?"
                        >
                          <RangeCalendar
                            date={filterDate}
                            setDate={setFilterDate}
                          />
                        </InputGroup>
                      </Form.Group>

                      <Form.Group as={Col} md="2">
                        <Form.Label>{t("commissions.filter.status")}</Form.Label>
                        <Controller
                          control={control}
                          name="status"
                          render={({ field }: any) => (
                            <div className="endpoint">
                              <Form.Select
                                {...field}
                                as="select"
                                variant="standard"
                                margin="normal"
                                onChange={(e: any) => handleChangeStatus(e)}
                                value={filterStatus}
                              >
                                <option value="0">{t("commissions.filter.statusSelect.all")}</option>
                                <option value="2">{t("commissions.filter.statusSelect.confirmed")}</option>
                                <option value="1">{t("commissions.filter.statusSelect.canceled")}</option>
                              </Form.Select>
                            </div>
                          )}
                        />
                      </Form.Group>

                      <Form.Group as={Col} md="2">
                        <Form.Label>{t("commissions.filter.tourCode")}</Form.Label>
                        <Controller
                          control={control}
                          name="tourcodeOrNot"
                          render={({ field }: any) => (
                            <div className="endpoint">
                              <Form.Select
                                {...field}
                                as="select"
                                variant="standard"
                                margin="normal"
                                onChange={(e: any) => handleChangeTourCode(e)}
                                value={filterTourcodeOrNot}
                              >
                                <option value="0">{t("commissions.filter.tourCodeSelect.all")}</option>
                                <option value="1">{t("commissions.filter.tourCodeSelect.yes")}</option>
                                <option value="2">{t("commissions.filter.tourCodeSelect.not")}</option>
                              </Form.Select>
                            </div>
                          )}
                        />
                      </Form.Group>

                      <Row
                        as={Col}
                        md="12"
                        className="mt-5 justify-content-md-end"
                      >
                        <Col
                          xs
                          lg="12"
                          className="d-flex justify-content-md-end"
                        >
                          <Button
                            variant="outline-primary"
                            className="btn-default mr-2 text-uppercase"
                            onClick={handleClearClick}
                          >
                            {t("commissions.filter.clear")}
                          </Button>
                          <Button
                            className="btn-default text-uppercase"
                            onClick={handleFilterClick}
                          >
                            {t("commissions.filter.search")}
                          </Button>
                        </Col>
                      </Row>
                    </Row>
                  </Form>
                </div>
              </Row>
            </Container>
          </Accordion.Body>
        </Accordion.Item>
      </Accordion>
    </div>
  );
};
export default CommissionsFilter;
