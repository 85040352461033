import { createSlice } from '@reduxjs/toolkit'

const comboSlice = createSlice({
  name: 'combo',
  initialState: {},
  reducers: {

    addToComboObject: (state: any, action) => {
      state.combo = { ...state.combo, ...JSON.parse(action.payload) };
      saveToLocalStorage(state.combo);
    },

    resetCombo: (state: any, action) => {
      state.combo = {};

      saveToLocalStorage(state.combo);
    },
  }
})

export const {
  addToComboObject,
  resetCombo,
} = comboSlice.actions

function saveToLocalStorage(state: any) {
  try {
    const serialisedState = JSON.stringify(state);
    localStorage.setItem("combo", serialisedState);

  } catch (e) {
    console.warn(e);
  }
}



// const store2 = configureStore({
//   reducer: productSlice.reducer
// })

// Can still subscribe to the store
// store.subscribe(() => console.log(store.getState()))

// convert object to string and store in localStorage

// // Infer the `RootState` and `AppDispatch` types from the store itself
// export type RootState = ReturnType<typeof store2.getState>
// // Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
// export type AppDispatchProduct = typeof store2.dispatch

export default comboSlice;