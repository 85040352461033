import React, { useEffect, useState } from "react";
import api from "../../../../services/api";

import "./MeetingPoint.scss"
import { Button, Col, Form, Row } from "react-bootstrap";
import { Controller, useForm } from "react-hook-form";
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import { ErrorMessage } from "@hookform/error-message";

export interface productTransfer {
    action: any,
    receiveObject: any,
    transferObject: any,
    isEdit: any
};

const MeetingPoint: React.FC<productTransfer> = React.memo(({ action, receiveObject, transferObject, isEdit
}: productTransfer) => {

    //console.log(transferObject)

    const [translate, setTranslate] = useState(false);
    const [portText, setPortText] = useState<any>(transferObject.descPort === undefined ? "" : transferObject.descPort);
    const [engText, setEngText] = useState<any>(transferObject.descEng === undefined ? "" : transferObject.descEng);
    const [espText, setEspText] = useState<any>(transferObject.descEsp === undefined ? "" : transferObject.descEsp);

    const [meetingP, setMeetingP] = useState(transferObject.meetingPointType === undefined ? 0 : transferObject.meetingPointType);

    const [loading, setLoading] = useState<boolean>(false);

    /*     const [show, setShow] = useState<boolean>(false); */
    const [textErrorP, setTextErrorP] = useState<string>('');
    const [textErrorD, setTextErrorD] = useState<string>('');

    const getChoiceMeetingP = (num: number) => {
        setMeetingP(num)
    }

    const getChoiceTranslate = (num: boolean) => {
        //console.log(num)
        setTranslate(num)

        if (num === true) {
            TranslateTextEN();
            TranslateTextES();
        }
    };

    const TranslateTextEN = async () => {
        setLoading(true);

        try {
            const res = await api.post(`https://translation.googleapis.com/language/translate/v2?key=${process.env.REACT_APP_GOOGLE_CLOUD_TRANSLATE_API}`, {
                "q": portText.split("<p>")[1].split("</p>")[0],
                "source": "pt-BR",
                "target": "en",
                "format": "text"
            });
            //const res = await api.post(`https://api.mymemory.translated.net/get?q=${portText.split("<p>")[1].split("</p>")[0]}&langpair=pt-BR|en-GB`);
            if (res.status !== 400) {
                setLoading(false);
                setEngText(res?.data?.data?.translations[0]?.translatedText);
            }
        } catch (error: any) {

        }
    };

    const TranslateTextES = async () => {
        setLoading(true);

        try {
            const res = await api.post(`https://translation.googleapis.com/language/translate/v2?key=${process.env.REACT_APP_GOOGLE_CLOUD_TRANSLATE_API}`, {
                "q": portText.split("<p>")[1].split("</p>")[0],
                "source": "pt-BR",
                "target": "es",
                "format": "text"
            });
            //const res = await api.post(`https://api.mymemory.translated.net/get?q=${portText.split("<p>")[1].split("</p>")[0]}&langpair=pt-BR|es-ES`);
            if (res.status !== 400) {
                setLoading(false);
                setEspText(res?.data?.data?.translations[0]?.translatedText);
            }
        } catch (error: any) {

        }
    };

    useEffect(() => {
        if (translate === true) {
            setEngText(portText);
            setEspText(portText);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [translate]);

    const onSubmit = (data: any) => {

        if (meetingP === 0) {
            //setShow(true);
            setTextErrorP("Por favor, informe onde será o ponto de encontro.");

            setTimeout(() => {
                setTextErrorP('');
            }, 2000);
        } else {
            if ((portText === '' || engText === '' || espText === '')) {
                //setShow(true);
                setTextErrorD("Por favor, informe a descrição do ponto de encontro.");

                setTimeout(() => {
                    setTextErrorD('');
                }, 2000);
            } else {
                data.meetingPointType = meetingP;
                data.descPort = portText.split("<p>")[1].split("</p>")[0];
                data.descEng = engText.replace("<p>", "").replace("</p>", "");
                data.descEsp = espText.replace("<p>", "").replace("</p>", "");

                if (isEdit !== true) {
                    action('tariffs', data);
                } else {
                    receiveObject(data);
                }

            }
        }
    }

    const modules: any = {
        toolbar: [
            ['bold'],
        ],
    };

    const formats = ["bold"]
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const [value, setValue] = useState('');

    const {
        control,
        handleSubmit,
        formState: { errors }
    } = useForm({});

    return (
        <>
            {/* CONTENT */}
            <Col md={9} lg={10}>
                <Form className="h-100" noValidate validated={false} onSubmit={handleSubmit(onSubmit)}>


                    <div className='card h-100 border-0 p-4'>
                        <div>
                            <h3 className="pt-1 text-primary title-content">{"Ponto de Encontro"}</h3>
                        </div>

                        {/* <Row className="my-3">
                    <Col md={'12'}>
                        <div className="alert alert-primary background-primary p-3">                      
                        <small className="text-primary">Os cartazes que você utiliza para receber seus passageiros não poderão mais ter o logo da C2Rio. Lembre-se que você deve continuar utilizando o logo da sua empresa junto com o nome e o sobrenome do passageiro titular da reserva.</small>
                        </div>
                    </Col>
                </Row> */}

                        <section className="my-3">
                            <Row className="column-chooice">
                                <Col md="12">
                                    <div className="label-small">
                                        Lembre-se de adicionar todas as informações necessárias para que os passageiros possam encontrá-lo facilmente no aeroporto. Toda a informação cadastrada aqui aparecerá no voucher.
                                    </div>
                                    <div className="d-flex w-auto mt-2">
                                        <Controller
                                            control={control}
                                            name="meetingPointType"
                                            render={({ field }: any) => (
                                                <Form.Check
                                                    {...field}
                                                    type="radio"
                                                    variant="standard"
                                                    margin="normal"
                                                    required
                                                    defaultChecked={meetingP === 1 ? true : false}
                                                    className="mr-2"
                                                    onClick={() => getChoiceMeetingP(1)}
                                                />
                                            )}
                                        />
                                        <ErrorMessage
                                            errors={errors}
                                            name="meetingPointType"
                                            render={({ message }) => <small style={{ color: "red" }}>{message}</small>}
                                        />
                                        <Form.Label className="label-small">
                                            Área de desembarque
                                        </Form.Label>
                                    </div>

                                    <div className="d-flex w-auto">
                                        <Controller
                                            control={control}
                                            name="meetingPointType"
                                            render={({ field }: any) => (
                                                <Form.Check
                                                    {...field}
                                                    type="radio"
                                                    variant="standard"
                                                    margin="normal"
                                                    required
                                                    defaultChecked={meetingP === 2 ? true : false}
                                                    className="mr-2"
                                                    onClick={() => getChoiceMeetingP(2)}
                                                />
                                            )}
                                        />
                                        <Form.Label className="label-small">
                                            Guichê da companhia
                                        </Form.Label>
                                    </div>

                                    <div className="d-flex w-auto">
                                        <Controller
                                            control={control}
                                            name="meetingPointType"
                                            render={({ field }: any) => (
                                                <Form.Check
                                                    {...field}
                                                    type="radio"
                                                    variant="standard"
                                                    margin="normal"
                                                    required
                                                    defaultChecked={meetingP === 3 ? true : false}
                                                    className="mr-2"
                                                    onClick={() => getChoiceMeetingP(3)}
                                                />
                                            )}
                                        />
                                        <Form.Label className="label-small">
                                            Fora do aeroporto
                                        </Form.Label>
                                    </div>
                                </Col>

                            </Row>

                            <small style={{ color: "red" }}>{textErrorP}</small>
                        </section>

                        <section className="my-3">
                            <Row>
                                <Col md="12">
                                    <div className="label-small">
                                        Em que parte da área de chegada você irá esperar pelo passageiro?
                                    </div>
                                    <div className="transfer-meeting-point my-2">
                                        <label className="label">Portugês</label>
                                        <ReactQuill id="textPt" theme="snow" value={portText} onChange={setPortText} formats={formats} modules={modules} placeholder="Escreva aqui..." />
                                    </div>

                                    {/* <textarea className="w-100 mt-2" name="" id="" rows={5} placeholder="Português"></textarea> */}
                                </Col>

                                <Col md="12">
                                    <div className="d-flex w-auto mt-2">
                                        <Controller
                                            control={control}
                                            name="checkTranslation"
                                            render={({ field }: any) => (
                                                <Form.Check
                                                    {...field}
                                                    type="checkbox"
                                                    variant="standard"
                                                    margin="normal"
                                                    required
                                                    className="mr-2"
                                                    onClick={() => getChoiceTranslate(!translate)}
                                                />
                                            )}
                                        />
                                        <Form.Label className="d-flex align-items-center label-small mb-0">
                                            Traduzir automaticamente
                                            {
                                                loading === true
                                                    ?
                                                    <div className="load mx-3"></div>
                                                    :
                                                    <></>
                                            }
                                        </Form.Label>
                                    </div>
                                </Col>

                                <Col md="6">
                                    {translate === false ?
                                        <div className="transfer-meeting-point my-2">
                                            <label className="label">Inglês</label>
                                            <ReactQuill id="descEng" theme="snow" value={engText} onChange={setEngText} formats={formats} modules={modules} />
                                        </div>
                                        :
                                        <div>
                                            <div style={{ marginLeft: "3px", marginTop: "9px" }} ><span>Inglês</span></div>
                                            <Controller
                                                control={control}
                                                name="descEng"
                                                render={({ field }: any) => (
                                                    <Form.Text
                                                        {...field}
                                                        className="w-100 mt-2"
                                                        variant="standard"
                                                        margin="normal"
                                                        autoComplete="off"
                                                        style={{ position: 'relative', top: '11px', left: '3px', fontSize: '16px', fontWeight: '400' }}
                                                    > <div dangerouslySetInnerHTML={{ __html: engText }}></div></Form.Text>
                                                )}
                                            />
                                        </div>
                                    }
                                </Col>

                                <Col md="6">
                                    {translate === false ?
                                        <div className="transfer-meeting-point my-2">
                                            <label className="label">Espanhol</label>
                                            <ReactQuill id="descEsp" theme="snow" value={espText} onChange={setEspText} formats={formats} modules={modules} />
                                        </div>
                                        :
                                        <div>
                                            <div style={{ marginLeft: "3px", marginTop: "9px" }} ><span>Espanhol</span></div>
                                            <Controller
                                                control={control}
                                                name="descEsp"
                                                render={({ field }: any) => (
                                                    <Form.Text
                                                        {...field}
                                                        className="w-100 mt-2"
                                                        variant="standard"
                                                        margin="normal"
                                                        autoComplete="off"
                                                        style={{ position: 'relative', top: '11px', left: '3px', fontSize: '16px', fontWeight: '400' }}
                                                    > <div dangerouslySetInnerHTML={{ __html: espText }}></div></Form.Text>
                                                )}
                                            />
                                        </div>
                                    }
                                </Col>
                            </Row>

                            <small style={{ color: "red" }}>{textErrorD}</small>
                        </section>

                        <section className="h-100 d-flex justify-content-end align-items-end">
                            <Row className="mx-0 mt-5">
                                {
                                    isEdit !== true
                                        ?
                                        <Row className=" mx-0 mt-5">
                                            <Button
                                                variant="outline-primary"
                                                className="form-button mr-3"
                                                onClick={() => action('dataTransfer')}
                                            >
                                                Voltar
                                            </Button>
                                            <Button
                                                type="submit"
                                                className="form-button"
                                            >
                                                Continuar
                                            </Button>
                                        </Row>
                                        :
                                        <Row className=" mx-0 mt-5">
                                            <Button
                                                type="submit"
                                                className="form-button"
                                            //onClick={() => action('meetingPoint')}
                                            >
                                                Salvar
                                            </Button>
                                        </Row>
                                }
                            </Row>
                        </section>
                    </div>
                </Form>

                {/* <Modal
                    show={show}
                    onHide={setShow}
                    className="modal-confirm loading-modal"
                >
                    <Modal.Body className="text-center sucess-pos">
                        <div>
                            <FontAwesomeIcon
                                icon={["fal", "times-circle"]}
                                size="5x"
                                className="text-primary"
                                style={{ fontSize: "7.5em" }}
                            />
                        </div>
                        <div>
                            {textError}
                        </div>
                        <div className="d-flex justify-content-center">
                            <Button onClick={() => setShow(false)}>
                                Fechar
                            </Button>
                        </div>
                    </Modal.Body>
                </Modal> */}
            </Col>

        </>
    )
});

export default MeetingPoint;