import React, { useEffect, useState } from 'react';
import Select from 'react-select';
import api from '../../services/api';

import Form from 'react-bootstrap/Form';

export interface propPlus {
    propsField?: any,
    propsLabel?: any,
    propsErrors?: any,
    actionAgents?: any,
    setClient?: any
}

const GetAgentsEdit: React.FC<propPlus> = ({
    propsField, propsLabel, propsErrors, actionAgents, setClient
}: propPlus) => {

    const [info, setInfo] = useState<any>(null);

    const [isMenuOpen, setIsMenuOpen] = useState<boolean>(false);

    const [check, setCheck] = useState<any>(true);

    const onMenuOpen = () => {
        if (isMenuOpen === false) {
            setIsMenuOpen(true);
        } else {
            setIsMenuOpen(false);
        }
    }

    useEffect(() => {
        const token = localStorage.getItem('GroupId') || '{}';
    const config = {
      headers: { Authorization: `Bearer ${token}`  },
    };
        async function listAgents() {
            try {
                const { data } = await api.get(`/BillsReceivable/GetAgents`, config);
                if (data.status !== 400) {
                    setInfo(data.data);
                }
            } catch (error: any) {
                if (error.response.status === 401) {
                    window.location.href = window.location.origin + "/";
                }
            }
        }
        listAgents();
    }, []);

    const handleChange = (options: any) => {
        setClient(options)
        setCheck(false)
        propsField.onChange(options);
    };

    if (info !== null) {
        return (
            <>
                <Select
                    aria-labelledby="aria-label"
                    className="endpoint"
                    inputId="aria-example-input"
                    name="aria-live-color"
                    onMenuOpen={onMenuOpen}
                    onMenuClose={onMenuOpen}
                    onChange={handleChange}
                    options={Array.from(info)}
                    getOptionLabel={(option: any) => `${option.compName}`}
                    placeholder="Selecione"
                    getOptionValue={(option: any) => `${option.supplierIdentity}`}
                    value={info.find((a:any) => a.supplierIdentity === propsField.value)}
                />
                {
                    check === true
                        ?
                        alert
                        :
                        ""
                }
            </>
        );
    } else {
        return (
            <>
                <Form.Select>
                    <option value='' disabled></option>
                </Form.Select>
            </>
        )

    }
};

export default GetAgentsEdit;