import React, { useEffect, useState } from 'react';
import api from '../../services/api';

import DesktopDefault from '../../templates/DesktopDefault';

import './DecolarImport.scss'

//COMPONENTS
import Breadcrumb from '../../components/Breadcrumb/Breadcrumb';
import FilterDecolarImport from './components/FilterDecolarImport/FilterDecolarImport'; 
import TableDecolarImport from './components/TableDecolarImport/TableDecolarImport';

function DecolarImport() {

    const today = new Date();
    const todayStr = `${today.getDate()}/${today.getMonth() + 1}/${today.getFullYear()}`;


    const [actualDate, setActualDate] = useState<any>([todayStr]);

    const [billsReceive, setBillsReceive] = useState<any>(null);
    const [parameters, setParameters] = useState<any>(null);
    const [error, setError] = useState<any>();
    const [loading, setLoading] = useState<any>(false);

    /* Pagination */
    const [totalRows, setTotalRows] = useState<any>();
    const [pageCount, setPageCount] = useState<any>(1);
    const [rowsPerPage, setRowsPerPage] = useState<any>(10);
    /* END - Pagination */

    const [pickupPendCount, setPickupPendCount] = useState<any>('');

    async function refreshData() {
        setLoading(true);

        let body: any = document.querySelector("body");
        if (body.className !== "modal-open") {
            setBillsReceive(null);
        }

        let beginDate: any = []
        let endDate: any = []

        if (actualDate.length === 1) {
            beginDate = actualDate[0].split('/')
        } else if (actualDate.length === 2) {
            beginDate = actualDate[0].split('/')
            endDate = actualDate[1].split('/')
        }

        let today: any = new Date();

        async function listBills() {
            try {
                const { data } = await api.post('/Decolar/GetImportLogReportAsync',
                    {
                        "page": pageCount,
                        "rowsPerPage": rowsPerPage,
                        "dataInicial": (actualDate.length > 0) ? `${beginDate[2]}-${beginDate[1]}-${beginDate[0]}` : `${today.getFullYear()}-${today.getMonth() + 1}-${today.getDate()}`,
                        "dataFinal": (actualDate.length === 1) ? `${beginDate[2]}-${beginDate[1]}-${parseInt(beginDate[0])}` : (actualDate !== null && actualDate.length === 2) ? `${endDate[2]}-${endDate[1]}-${endDate[0]}` : `${today.getFullYear()}-${today.getMonth() + 1}-${today.getDate()}`
                    },
                );
                setLoading(false);
                if (data.status !== 400) {
                    setParameters({
                        "page": pageCount,
                        "rowsPerPage": rowsPerPage,
                        "dataInicial": (actualDate.length > 0) ? `${beginDate[2]}-${beginDate[1]}-${beginDate[0]}` : `${today.getFullYear()}-${today.getMonth() + 1}-${today.getDate()}`,
                        "dataFinal": (actualDate.length === 1) ? `${beginDate[2]}-${beginDate[1]}-${parseInt(beginDate[0])}` : (actualDate !== null && actualDate.length === 2) ? `${endDate[2]}-${endDate[1]}-${endDate[0]}` : `${today.getFullYear()}-${today.getMonth() + 1}-${today.getDate()}`
                    });
                    setBillsReceive(data.data.rows);
                    setTotalRows(data.data.rowsCount);
                }
            } catch (error: any) {
                if (error.response.status === 401) {
                    window.location.href = window.location.origin + '/';
                }
            }
        }
        listBills();
        updatePickupPendCounter();
    }

    useEffect(() => {
        refreshData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [pageCount, rowsPerPage]);

    async function buscar(
        date: any
    ) {

        setActualDate(date);
        setPageCount(1)

        setLoading(true);
        let body: any = document.querySelector("body");
        if (body.className !== "modal-open") {
            setBillsReceive(null);
        }

        const token = localStorage.getItem('GroupId') || '{}';
        const config = {
            headers: { 'Authorization': `Bearer ${token}` },
        };

        let beginDate = []
        let endDate = []


        if (date.length === 1) {
            beginDate = date[0].split('/')
        } else if (date.length === 2) {
            beginDate = date[0].split('/')
            endDate = date[1].split('/')
        }

        let today: any = new Date();

        try {
            const res = await api.post('/Decolar/GetImportLogReportAsync',
                {
                    "page": 1,
                    "rowsPerPage": rowsPerPage,
                    "dataInicial": (date.length > 0) ? `${beginDate[2]}-${beginDate[1]}-${beginDate[0]}` : `${today.getFullYear()}-${today.getMonth() + 1}-${today.getDate()}`,
                    "dataFinal": (date.length === 1) ? `${beginDate[2]}-${beginDate[1]}-${parseInt(beginDate[0])}` : (date !== null && date.length === 2) ? `${endDate[2]}-${endDate[1]}-${endDate[0]}` : `${today.getFullYear()}-${today.getMonth() + 1}-${today.getDate()}`
                }, config
            );
            setLoading(false);
            if (res.status !== 400) {
                setParameters({
                    "page": 0,
                    "rowsPerPage": 0,
                    "dataInicial": (date.length > 0) ? `${beginDate[2]}-${beginDate[1]}-${beginDate[0]}` : `${today.getFullYear()}-${today.getMonth() + 1}-${today.getDate()}`,
                    "dataFinal": (date.length === 1) ? `${beginDate[2]}-${beginDate[1]}-${parseInt(beginDate[0])}` : (date !== null && date.length === 2) ? `${endDate[2]}-${endDate[1]}-${endDate[0]}` : `${today.getFullYear()}-${today.getMonth() + 1}-${today.getDate()}`
                });
                setBillsReceive(res.data.data.rows);
                setTotalRows(res.data.data.rowsCount);
            }
        } catch (error: any) {
            setError(true)
            if (error?.response?.status === 401) {
                window.location.href = window.location.origin + '/';
            }
        }
        updatePickupPendCounter();
    };

    async function updatePickupPendCounter() {

        const token = localStorage.getItem('GroupId') || '{}';
        const config = {
          headers: { 'Authorization': `Bearer ${token}`, 'Content-Type': 'application/json' },
        };
        try {
    
          const res = await api.get('/Decolar/GetPickupDecolarCountAsync', config);
    
          if (res.status !== 400) {
            setPickupPendCount(res.data.data);
          }
        } catch (error: any) {
          
          if (error?.response?.status === 401) {
          }
        }
    }

    // async function exportBills(layout: any) {
    //     console.log(layout, actualStatusFormaPagamento)
    //     let allowRequest: boolean = true;

    //     let beginDate = []
    //     let endDate = []

    //     if (actualDate.length === 1) {
    //         beginDate = actualDate[0].split('/')
    //     } else if (actualDate.length === 2) {
    //         beginDate = actualDate[0].split('/')
    //         endDate = actualDate[1].split('/')
    //     }

    //     let today: any = new Date();

    //     if (layout === 4 && parseInt(actualStatusFormaPagamento) !== 4) { ///quando for cartão de credito so deixa exportar quando for filtro forma pagamento igual a cartão
    //         allowRequest = false;
    //     }

    //     if (allowRequest === true) {
    //         try {
    //             const exp = await api.post('/BillsReceivable/ExportReport',
    //                 {
    //                     "page": 1,
    //                     "rowsPerPage": 0, // pagina unica
    //                     "layout": layout,
    //                     "item": actualLocalizador ? actualLocalizador : '',
    //                     "idDecolarFenix": actualIdDecolarFenix ? actualIdDecolarFenix : null,
    //                     "tokenNsu": actualToken ? actualToken : '',
    //                     "agenteRef": actualRefAgent ? actualRefAgent : '',
    //                     "statusReserva": actualStatusReserve ? parseInt(actualStatusReserve) : null,
    //                     "statusPagamento": actualStatusPagamento ? parseInt(actualStatusPagamento) : null,
    //                     "formaPagamento": actualStatusFormaPagamento ? parseInt(actualStatusFormaPagamento) : null,
    //                     "adquirente": actualAdquirente ? actualAdquirente : null,
    //                     "fornecedor": actualSupplier ? actualSupplier : null,
    //                     "agenteIndividual": -1,
    //                     "agente": actualAgent && actualAgent !== '0' ? actualAgent : null,
    //                     "cliente": actualClient ? actualClient : '',
    //                     "tipoData": actualTypeDate ? parseInt(actualTypeDate) : 2,
    //                     "dataInicial": (actualDate.length > 0) ? `${beginDate[2]}-${beginDate[1]}-${beginDate[0]}` : `${today.getFullYear()}-${today.getMonth() + 1}-${today.getDate()}`,
    //                     "dataFinal": (actualDate.length === 1) ? `${beginDate[2]}-${beginDate[1]}-${parseInt(beginDate[0])}` : (actualDate !== null && actualDate.length === 2) ? `${endDate[2]}-${endDate[1]}-${endDate[0]}` : `${today.getFullYear()}-${today.getMonth() + 1}-${today.getDate()}`
    //                 },
    //                 {
    //                     headers: {
    //                         "Content-Type": "application/json",
    //                         "Accept": "*/*"
    //                         //"Access-Control-Allow-Origin": "*"

    //                     },
    //                     responseType: 'blob'
    //                 },

    //             );
    //             if (exp.status !== 400) {
    //                 if(layout == 2) {
    //                     var hoje = new Date();

    //                     const ano = (`${hoje.getFullYear()}`).slice(-2);
    //                     const mes = (`00${hoje.getMonth() + 1}`).slice(-2);
    //                     const dia = (`00${hoje.getDate()}`).slice(-2);
                    
    //                     const hoje_file = `${dia}.${mes}.${ano}`;
            
    //                     //if(this.agente != '') {
    //                     //    var agent_name = '_'+ this.getAgentDesc(this.agente);
    //                     //} else {
    //                         var agent_name = '';
    //                     //}
            
    //                     var filename = 'CAR'+agent_name+'_'+hoje_file+'.xlsx';
    //                 } else if(layout == 3) {
    //                     var hoje = new Date();
            
    //                     const ano = (`${hoje.getFullYear()}`).slice(-2);
    //                     const mes = (`00${hoje.getMonth() + 1}`).slice(-2);
    //                     const dia = (`00${hoje.getDate()}`).slice(-2);
                    
    //                     const hoje_file = `${ano}${dia}${mes}`;
                        
    //                     var filename = 'HB Ebilling'+hoje_file+'.csv';
    //                 } else {
    //                     var filename = 'Contas a Receber.xlsx';
    //                 }

    //                 const blob = new Blob([exp.data], { type: exp.headers["Content-Type"] });
    //                 const objectUrl = window.URL.createObjectURL(blob);

    //                 const link = document.createElement('a');
    //                 link.href = objectUrl;
    //                 link.setAttribute(
    //                     'download',
    //                     filename,
    //                 );

    //                 // Append to html link element page
    //                 document.body.appendChild(link);

    //                 // Start download
    //                 link.click();

    //                 // // Clean up and remove the link
    //                 link?.parentNode?.removeChild(link);
    //             }
    //         } catch (error: any) {
    //             setError(true)
    //             if (error?.response?.status === 401) {
    //                 window.location.href = window.location.origin + '/';
    //             }
    //         }
    //     }
    // };

    // async function printBills() {

    //     let beginDate = []
    //     let endDate = []

    //     if (actualDate.length === 1) {
    //         beginDate = actualDate[0].split('/')
    //     } else if (actualDate.length === 2) {
    //         beginDate = actualDate[0].split('/')
    //         endDate = actualDate[1].split('/')
    //     }

    //     let today: any = new Date();

    //     try {
    //         const print = await api.post('/BillsReceivable/PrintReport',
    //             {
    //                 "page": 1,
    //                 "rowsPerPage": 0, // pagina unica
    //                 "item": actualLocalizador ? actualLocalizador : '',
    //                 "idDecolarFenix": actualIdDecolarFenix ? actualIdDecolarFenix : null,
    //                 "tokenNsu": actualToken ? actualToken : '',
    //                 "agenteRef": actualRefAgent ? actualRefAgent : '',
    //                 "statusReserva": actualStatusReserve ? parseInt(actualStatusReserve) : null,
    //                 "statusPagamento": actualStatusPagamento ? parseInt(actualStatusPagamento) : null,
    //                 "formaPagamento": actualStatusFormaPagamento ? parseInt(actualStatusFormaPagamento) : null,
    //                 "adquirente": actualAdquirente ? actualAdquirente : null,
    //                 "fornecedor": actualSupplier ? actualSupplier : null,
    //                 "agenteIndividual": -1,
    //                 "agente": actualAgent && actualAgent !== '0' ? actualAgent : null,
    //                 "cliente": actualClient ? actualClient : '',
    //                 "tipoData": actualTypeDate ? parseInt(actualTypeDate) : 2,
    //                 "dataInicial": (actualDate.length > 0) ? `${beginDate[2]}-${beginDate[1]}-${beginDate[0]}` : `${today.getFullYear()}-${today.getMonth() + 1}-${today.getDate()}`,
    //                 "dataFinal": (actualDate.length === 1) ? `${beginDate[2]}-${beginDate[1]}-${parseInt(beginDate[0])}` : (actualDate !== null && actualDate.length === 2) ? `${endDate[2]}-${endDate[1]}-${endDate[0]}` : `${today.getFullYear()}-${today.getMonth() + 1}-${today.getDate()}`
    //             },
    //             {
    //                 headers: {
    //                     "Content-Type": "application/json",
    //                     "Accept": "*/*"
    //                     //"Access-Control-Allow-Origin": "*"

    //                 },
    //                 responseType: 'blob'
    //             },

    //         );
    //         if (print.status !== 400) {
    //             const blob = new Blob([print.data], { type: print.headers["Content-Type"] });
    //             const objectUrl = window.URL.createObjectURL(blob);

    //             const link = document.createElement('a');
    //             link.href = objectUrl;
    //             link.setAttribute(
    //                 'download',
    //                 'contas_receber.pdf',
    //             );

    //             // Append to html link element page
    //             document.body.appendChild(link);

    //             // Start download
    //             link.click();

    //             // // Clean up and remove the link
    //             link?.parentNode?.removeChild(link);


    //         }
    //     } catch (error: any) {
    //         setError(true)
    //         if (error?.response?.status === 401) {
    //             window.location.href = window.location.origin + '/';
    //         }
    //     }
    // };

    return (
        <>
            <DesktopDefault>
                <div className="container-fluid content-dashboard">
                    <Breadcrumb title={'Operações / Importar Decolar'} />
                    <div className="suppliers mt-4 d-flex justify-content-between align-items-center">
                        <h2 className="title" style={{ color: "#707070" }}>Importar Decolar</h2>
                    </div>
                    <FilterDecolarImport
                        buscar={buscar}
                        billsReceive={billsReceive}
                        error={error}
                    />
                    <TableDecolarImport
                        billsReceive={billsReceive}
                        totalRows={totalRows}
                        pageCount={pageCount}
                        setPageCount={setPageCount}
                        rowsPerPage={rowsPerPage}
                        setRowsPerPage={setRowsPerPage}
                        //exportBills={exportBills}
                        //printBills={printBills}
                        loading={loading}
                        //totalBillsReceive={totalBillsReceive}
                        actualDate={actualDate}
                        parameters={parameters}
                        refreshData={refreshData}
                        updatePickupPendCounter={updatePickupPendCounter}
                        pickupPendCount={pickupPendCount}
                    />
                </div>
            </DesktopDefault>
        </>
    );

}

export default DecolarImport;