import React, { useEffect, useState } from 'react';
import api from '../../../services/api';

//import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import './PictureOfTheDay.scss'
import { Row, Col, Modal } from 'react-bootstrap';
//import { useTranslation } from 'react-i18next';

//COMPONENTS

import Breadcrumb from '../../../components/Breadcrumb/Breadcrumb';
import FilterPictureOfTheDay from '../components/FilterPictureOfTheDay/FilterPictureOfTheDay';
import TablePictureOfTheDay from '../components/TablePictureOfTheDay/TablePictureOfTheDay';
import SumOfTheMonth from '../components/SumOfTheMonth/SumOfTheMonth';
import ModalQuestion from '../../../components/Modal/ModalQuestion/ModalQuestion';
import DesktopDefault from '../../../templates/DesktopDefault';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
//import { Link } from 'react-router-dom';
//import GlobalAlert from '../../../components/Alert/GlobalAlert/GlobalAlert';

//ICONS
/* import iconGlobe from '../../assets/icons/fa-globe-americas.svg'; */

//import objectPartners from '../../../components/Partners/PartnersTable/locales/partners.json'


function PictureOfTheDay() {
    //const { t } = useTranslation();
    const [sessions, setSessions] = useState<any>(null);
    const [totalRecebimentos, setTotalRecebimentos] = useState<any>(0);
    const [totalPagamentos, setTotalPagamentos] = useState<any>(0);
    const [totalMargem, setTotalMargem] = useState<any>(0);
    const [taxaMargem, setTaxaMargem] = useState<any>(0);
    const [loading, setLoading] = useState<any>(true);

    /* sum of the month */
    const [totalRecebimentosMes, setTotalRecebimentosMes] = useState<any>(0);
    const [totalPagamentosMes, setTotalPagamentosMes] = useState<any>(0);
    const [totalMargemMes, setTotalMargemMes] = useState<any>(0);
    const [taxaMargemMes, setTaxaMargemMes] = useState<any>(0);

    const [totalNotasEmitidasMes, setTotalNotasEmitidasMes] = useState<any>(0);
    const [totalNotasEmitidasDia, setTotalNotasEmitidasDia] = useState<any>(0);
    const [totalNotasEmitirDia, setTotalNotasEmitirDia] = useState<any>(0);
    const [loadingSum, setLoadingSum] = useState<any>(true);
    /* END - sum of the month */

    const [diaFechado, setDiaFechado] = useState<any>(false);
    const [mesFechado, setMesFechado] = useState<any>(false);


    const [issuePendInvoicesModalShow, setIssuePendInvoicesModalShow] = useState<any>(false);
    const [closeMonthModalShow, setCloseMonthModalShow] = useState<any>(false);

    /* Modal Success */
    const [modalSuccess, setModalSuccess] = useState<any>(false);
    const [modalContent, setModalContent] = useState<any>('');
    const [responseText, setResponseText] = useState<any>('');
    const [log, setLog] = useState<any>(0);
    /* END - Modal Success */

    const [error, setError] = useState<any>();

    /* Filter */
    const [filterDate, setFilterDate] = useState<any>([]);
    /* END - Filter */

    const hoje = new Date();
    //const ontem = new Date(hoje.getFullYear(), hoje.getMonth(), hoje.getDate()-1); // hoje - 1 dia = ontem
    const dataPadrao = hoje.toISOString().substring(0,10).split('-').reverse().join('/');
    
    function calcula(data: any) {
        var recebe = 0; 
        var paga = 0;

        for(var i = 0; i < data.length; i++) {
            recebe += data[i].totalRecebimentos;
            paga += data[i].totalPagamentos;
        }

        const margem = recebe - paga;

        setTotalRecebimentos(recebe);
        setTotalPagamentos(paga);
        setTotalMargem(margem);

        if(recebe === 0) {
            setTaxaMargem(0);
        } else {
            setTaxaMargem((margem / recebe) * 100);
        }
    }

    useEffect(() => {
        setFilterDate([dataPadrao]);
        buscar([dataPadrao]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [//pageCount, rowsPerPage
]);

    async function buscar(date: any) {
        //console.log(date);
        const token = localStorage.getItem('GroupId') || '{}';
        const config = {
            headers: { 'Authorization': `Bearer ${token}` },
        };
        setFilterDate(date);

        let beginDate = []
        let endDate = []

        if (date.length === 1) {
            beginDate = date[0].split('/')
        } else if (date.length === 2) {
            beginDate = date[0].split('/')
            endDate = date[1].split('/')
        }

        let today: any = new Date();

        setLoading(true);
        setLoadingSum(true);
        try {
            const res = await api.post('SessionClosure/GetPictureOfTheDayReportAsync',
                {
                    "date": (date.length > 0) ? `${beginDate[2]}-${beginDate[1]}-${beginDate[0]}` : `${today.getFullYear()}-${today.getMonth() + 1}-${today.getDate()}`,
                    "endDate": (date.length === 1) ? `${beginDate[2]}-${beginDate[1]}-${parseInt(beginDate[0])}` : (date !== null && date.length === 2) ? `${endDate[2]}-${endDate[1]}-${endDate[0]}` : `${today.getFullYear()}-${today.getMonth() + 1}-${today.getDate()}`
                    //"orderByField": ""
                }, config
            );
            if (res.status !== 400) {
                setSessions(res.data.data);

                calcula(res.data.data);
            
                setLoading(false);

                const res2 = await api.post('SessionClosure/GetSumOfTheMonthAsync',
                    {
                        "date": (date.length > 0) ? `${beginDate[2]}-${beginDate[1]}-${beginDate[0]}` : `${today.getFullYear()}-${today.getMonth() + 1}-${today.getDate()}`,
                        //"orderByField": ""
                    }, config
                );

                if (res2.status !== 400) {
                    const dados = res2.data.data;
                    
                    //console.log(dados.totalRecebimentos, "recebimentos");
                    setTotalRecebimentosMes(dados.totalRecebimentos);
                    setTotalPagamentosMes(dados.totalPagamentos);
                    setTotalMargemMes(dados.totalMargem);
                    setTaxaMargemMes(dados.taxaMargem);

                    setTotalNotasEmitidasMes(dados.totalNotasEmitidasMes);
                    setTotalNotasEmitidasDia(dados.totalNotasEmitidasDia);
                    setTotalNotasEmitirDia(dados.totalNotasEmitirDia);

                    setMesFechado(dados.mesEncerrado === 1);
                    setDiaFechado(dados.totalNotasEmitirDia === 0);

                    setLoadingSum(false);
                }
            }
        } catch (error: any) {
            setError(true)
            if (error?.response?.status === 401) {
                window.location.href = window.location.origin + '/';
            }
        }
    };

    async function refreshData() {
        await buscar(filterDate);
    }

    function download(file: any, filetype: any, filename: any) { // file: bytes/string, name: string
        //const blob = new Blob([file], { type: filetype });
        const blob = `data:${filetype};base64,${file}`;
//console.log(blob);
        //if(navigator.msSaveBlob){ // For ie and Edge
        //  return navigator.msSaveBlob(blob, filename);
        //}
        //else{
          let link = document.createElement('a');
          link.href = blob;//window.URL.createObjectURL(blob);
          link.download = filename;
          document.body.appendChild(link);
          link.dispatchEvent(new MouseEvent('click', {bubbles: true, cancelable: true, view: window}));
          link.remove();
          window.URL.revokeObjectURL(link.href);
        //}
    };

    async function exportReport() {

        let beginDate = []
        let endDate = []

        if (filterDate.length === 1) {
            beginDate = filterDate[0].split('/')
        } else if (filterDate.length === 2) {
            beginDate = filterDate[0].split('/')
            endDate = filterDate[1].split('/')
        }

        let today: any = new Date();

        const token = localStorage.getItem('GroupId') || '{}';
        const config = {
            headers: { 'Authorization': `Bearer ${token}` },
        };

        try {
            const res = await api.post('SessionClosure/ExportPictureOfTheDayReportAsync',
                {
                    "date": (filterDate.length > 0) ? `${beginDate[2]}-${beginDate[1]}-${beginDate[0]}` : `${today.getFullYear()}-${today.getMonth() + 1}-${today.getDate()}`,
                    "endDate": (filterDate.length === 1) ? `${beginDate[2]}-${beginDate[1]}-${parseInt(beginDate[0])}` : (filterDate !== null && filterDate.length === 2) ? `${endDate[2]}-${endDate[1]}-${endDate[0]}` : `${today.getFullYear()}-${today.getMonth() + 1}-${today.getDate()}`
                    //"orderByField": ""
                }, config
            );
            if (res.status !== 400) {
                //download file
                const dados = res.data.data;
                download(dados.file, dados.fileType, dados.fileName);
            }
        } catch (error: any) {
            setError(true)
            if (error?.response?.status === 401) {
                window.location.href = window.location.origin + '/';
            }
        }
    };

    async function printReport() {
        
        let beginDate = []
        let endDate = []

        if (filterDate.length === 1) {
            beginDate = filterDate[0].split('/')
        } else if (filterDate.length === 2) {
            beginDate = filterDate[0].split('/')
            endDate = filterDate[1].split('/')
        }

        let today: any = new Date();
        
        const token = localStorage.getItem('GroupId') || '{}';
        const config = {
            headers: { 'Authorization': `Bearer ${token}` },
        };

        try {
            const res = await api.post('SessionClosure/PrintPictureOfTheDayReportAsync',
                {
                    "date": (filterDate.length > 0) ? `${beginDate[2]}-${beginDate[1]}-${beginDate[0]}` : `${today.getFullYear()}-${today.getMonth() + 1}-${today.getDate()}`,
                    "endDate": (filterDate.length === 1) ? `${beginDate[2]}-${beginDate[1]}-${parseInt(beginDate[0])}` : (filterDate !== null && filterDate.length === 2) ? `${endDate[2]}-${endDate[1]}-${endDate[0]}` : `${today.getFullYear()}-${today.getMonth() + 1}-${today.getDate()}`
                    //"orderByField": ""
                }, config
            );
            if (res.status !== 400) {
                //download file
                //console.log(res.data.data);
                const dados = res.data.data;
                download(dados.file, dados.fileType, dados.fileName);
            }
        } catch (error: any) {
            setError(true)
            if (error?.response?.status === 401) {
                window.location.href = window.location.origin + '/';
            }
        }
    };

    function closeMonth() {
        setCloseMonthModalShow(true);
    }

    function issuePendInvoices() {
        setIssuePendInvoicesModalShow(true);
    }

    async function closeMonth1() {
        setCloseMonthModalShow(false);
        setModalContent('loading');
        setModalSuccess(true);

        let userData = JSON.parse(localStorage.getItem('c2tourAuth') || '{}');
        const user = userData.user;

        const uid = user.uId;
        const usuario = `${user.nome} ${user.lastName}`;

        const token = localStorage.getItem('GroupId') || '';

        const config = {
            headers: { 'Authorization': `Bearer ${token}` },
        };

        const month = filterDate.substring(5,7); // 0123-56  1234-67
        const year = filterDate.substring(0,4); // 0123-56  1234-67

        try {
            const res = await api.post('SessionClosure/CloseMonthAsync',
                {
                    "month": parseInt(month),
                    "year": parseInt(year),
                    "uid": uid,
                    "usuario": usuario
                }, config
            );
            if (res.status !== 400) {
                //download file
                //console.log(res.data.data);
                const dados = res.data.data;
                
                //alert(dados.texto);
                setResponseText(dados.texto);
                setLog(dados.log);

                if(dados.log !== 1) {
                    setModalContent('success');
                    //refreshData();
                } else {
                    setModalContent('error');
                }
            }
        } catch (error: any) {
            setError(true)
            if (error?.response?.status === 401) {
                window.location.href = window.location.origin + '/';
            }
        }
    }
    
    async function issuePendInvoices1() {
        setIssuePendInvoicesModalShow(false);
        setModalContent('loading');
        setModalSuccess(true);

        let userData = JSON.parse(localStorage.getItem('c2tourAuth') || '{}');
        const user = userData.user;

        const uid = user.uId;
        const usuario = `${user.nome} ${user.lastName}`;

        const token = localStorage.getItem('GroupId') || '';

        const config = {
            headers: { 'Authorization': `Bearer ${token}` },
        };

        try {
            const res = await api.post('SessionClosure/IssuePendInvoicesByDateAsync',
                {
                    "date": filterDate,
                    "uid": uid,
                    "usuario": usuario
                }, config
            );
            if (res.status !== 400) {
                //download file
                //console.log(res.data.data);
                const dados = res.data.data;
                
                //alert(dados.texto);
                setResponseText(dados.texto);
                setLog(dados.log);

                if(dados.log !== 1) {
                    setModalContent('success');
                    //refreshData();
                } else {
                    setModalContent('error');
                }
            }
        } catch (error: any) {
            setError(true)
            if (error?.response?.status === 401) {
                window.location.href = window.location.origin + '/';
            }
        }
    }

    if (sessions !== null) {
        return (
            <>
                <DesktopDefault>
                    <div className="container-fluid content-dashboard">
                        <Breadcrumb title={'Financeiro / Fechamento / Foto do Dia'} />
                        <div className="suppliers mt-4 d-flex justify-content-between align-items-center">
                            <h2 className="title" style={{ color: "#707070" }}>Foto do Dia</h2>
                        </div>
                        <FilterPictureOfTheDay
                            buscar={buscar}
                            sessions={sessions}
                            dataPadrao={dataPadrao}
                            error={error}
                            setFilterDate={setFilterDate}
                        />
                        <Row>
                            <Col md={8}>
                                <TablePictureOfTheDay
                                    sessions={sessions}
                                    totalRecebimentos={totalRecebimentos}
                                    totalPagamentos={totalPagamentos}
                                    totalMargem={totalMargem}
                                    taxaMargem={taxaMargem}
                                    exportReport={exportReport}
                                    printReport={printReport}
                                    loading={loading}
                                />
                            </Col>
                            <Col md={4}>
                                <SumOfTheMonth
                                    totalRecebimentos={totalRecebimentosMes}
                                    totalPagamentos={totalPagamentosMes}
                                    totalMargem={totalMargemMes}
                                    taxaMargem={taxaMargemMes}
                                    totalNotasEmitidasMes={totalNotasEmitidasMes}
                                    totalNotasEmitidasDia={totalNotasEmitidasDia}
                                    totalNotasEmitirDia={totalNotasEmitirDia}
                                    diaFechado={diaFechado}
                                    mesFechado={mesFechado}
                                    issuePendInvoices={issuePendInvoices}
                                    closeMonth={closeMonth}
                                    loading={loadingSum}
                                />
                            </Col>
                        </Row>
                    </div>
                    
                    {/* Modal de Success */}
                    <Modal
                        className={"modal-confirm loading-modal"}
                        show={modalSuccess}
                        onHide={() => setModalSuccess(false)}
                        size="lg"
                        aria-labelledby="contained-modal-title-vcenter"
                    >
                    <>
                        <Modal.Body className='modal-body text-center sucess-pos'>
                        {
                            modalContent !== "loading"
                            ?
                            <>
                                <div>
                                {
                                    log === 2 || log === "2"
                                    ?
                                    <FontAwesomeIcon
                                    icon={["fal", "exclamation-circle"]}
                                    size="5x"
                                    className="text-primary"
                                    style={{ fontSize: "7.5em"}}
                                    />
                                    :
                                    log === 1 || log === "1"
                                    ?
                                    <FontAwesomeIcon
                                        icon={["fal", "times-circle"]}
                                        size="5x"
                                        className="text-primary"
                                        style={{ fontSize: "7.5em"}}
                                    />
                                    :
                                    log === 0 || log === "0"
                                        ?
                                        <FontAwesomeIcon
                                        icon={["fal", "check-circle"]}
                                        size="5x"
                                        className="text-primary"
                                        style={{ fontSize: "7.5em"}}
                                        />
                                        :
                                        <FontAwesomeIcon
                                        icon={["fal", "question-circle"]}
                                        size="5x"
                                        className="text-primary"
                                        style={{ fontSize: "7.5em"}}
                                        />
                                }
                                </div>
                                <div>
                                {responseText}
                                </div>
                                <div className="d-flex justify-content-center">
                                {
                                    modalContent === 'success'
                                    ?
                                    <button
                                        onClick={() => {refreshData(); setModalSuccess(false);}}
                                        className="btn btn-primary mx-2 w-25"
                                    >
                                        Confirmar
                                    </button>
                                    :
                                    <button
                                        onClick={() => setModalSuccess(false)}
                                        className="btn btn-primary mx-2 w-25"
                                    >
                                        Confirmar
                                    </button>
                                }
                                </div>
                            </>
                            :
                            <div className="modal-body">
                                <div className="text-center">
                                <div className="load"></div>
                                </div>
                                <div>
                                <span>Processando</span>
                                </div>
                                <div></div>
                            </div>
                        }
                        </Modal.Body>
                    </>
                    </Modal>
                    {/* END - Modal de Success */}
                    <ModalQuestion 
                        modalShow={issuePendInvoicesModalShow}
                        setModalShow={setIssuePendInvoicesModalShow}
                        titulo="Emitir Nota Fiscal"
                        mensagem="Emitir Notas Pendentes e Encerrar o Faturamento do dia?"
                        handleActionConfirmModal={(e: any) => issuePendInvoices1()}
                    />
                    <ModalQuestion 
                        modalShow={closeMonthModalShow}
                        setModalShow={setCloseMonthModalShow}
                        titulo="Encerramento do Mês"
                        mensagem="Deseja encerrar o Mês?"
                        handleActionConfirmModal={(e: any) => closeMonth1()}
                    />
                </DesktopDefault>
            </>
        );
    } else {
        return (
            <>
            </>
        )
    }
}

export default PictureOfTheDay;