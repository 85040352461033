/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-redeclare */
import React, { useEffect, useState } from "react";
import { Container, Row, Col, Card } from 'react-bootstrap';

import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import Accordion from 'react-bootstrap/Accordion';
import { Controller, useForm } from "react-hook-form";
import { ErrorMessage } from '@hookform/error-message';

import { useTranslation } from "react-i18next";

import GetCurrency from "../../../../components/C2Points/GetCurrency";
import GetPaymentForms from "../../../../components/C2Points/GetPaymentForms";
import GetPayCicle from "../../../../components/C2Points/GetPayCicle";
import Get60DaysForBilling from "../../../../components/C2Points/Get60DaysForBilling";
import FinancialTable from './FinancialTable';

import '../../../../assets/sass/accordion.scss';
import GetDaysWeekForPayment from "../../../../components/C2Points/GetDaysWeekForPayment";
import GetPaymentBiweekly from "../../../../components/C2Points/GetPaymentBiweekly";
import GetTenth from "../../../../components/C2Points/GetTenth";
import GetBanks from "../../../../components/C2Points/GetBanks";
import GetYesOrNot from "../../../../components/C2Points/GetYesOrNot";
import GetPercentSupplierComission from "../../../../components/C2Points/GetPercentSupplierComission";

export interface propAccordion {
    setChangeEdit: any,
    action: any,
    ContextAwareToggle: any,
    supplierControlObject: any,
    infoEdit: any,
    setStepCtrl: any,
    editSuppliers: any,
    scrollTop?: any;
    isEdit?: any;
    hasSavedInputs?: any;
};

const AccordionFinancial: React.FC<propAccordion> = React.memo(({
    setChangeEdit, action, ContextAwareToggle, supplierControlObject, infoEdit, setStepCtrl, editSuppliers, scrollTop, isEdit = false, hasSavedInputs = false
}: propAccordion) => {
console.log(supplierControlObject)
    const [ctrlEventKey, setCtrlEventKey] = useState<boolean>(false);
    const [messageError, setMessageError] = useState<boolean>(false);
    const [costCenter, setCostCenter] = useState<any>('');
    const [disableAddContact, setDisableAddContact] = useState<any>(false);

    useEffect(() => {
        var somatorioRateio: any = 0;
        for (var i: any = 0; i < costCenter.length; i++) {
            somatorioRateio += parseInt(costCenter[i].rateio);
        }
        if(somatorioRateio === 100){
            setDisableAddContact(true);
        }else{
            setDisableAddContact(false);
        }
    }, [costCenter])

    const { t } = useTranslation();
    const validated = false;

    const idSup = window.location.href.split('id=')[1]?.replace(/#/g, '');
    /* const idSup = window.location.href.split('id=')[1]?.replace(/\#/g, ''); */

    const ControllerPayment = () => {
        if (watchPaymentType === 'Term') {
            return (
                <>
                    <Form.Group as={Col} md="2" controlId="validationCustomRG">
                        <Form.Label>{t('accordionFinancial.from')}:</Form.Label>
                        <Controller
                            control={control}
                            name="reserve"
                            rules={{ required: { value: true, message: t('accordionFinancial.validation.from') } }}
                            render={({ field }: any) => (
                                <div className={errors.reserve !== undefined ? "endpoint-error" : "endpoint"}>
                                    <Form.Select
                                        {...field}
                                        aria-invalid={errors?.reserve ? "true" : ""}
                                        label={t('accordionFinancial.from')}
                                        as="select"
                                        variant="standard"
                                        margin="normal"
                                        required
                                    >
                                        <option value=''>{t('accordionFinancial.select.select')}</option>
                                        <option value="1">{t('accordionFinancial.select.dayReserve')}</option>
                                        <option value="2">{t('accordionFinancial.select.dayTour')}</option>

                                    </Form.Select>
                                </div>
                            )}
                        />
                        <ErrorMessage
                            errors={errors}
                            name="reserve"
                            render={({ message }) => <small style={{ color: "red" }}>{message}</small>}
                        />
                    </Form.Group>
                    <Form.Group as={Col} md="2" controlId="validationCustomRG">
                        <Form.Label>{t('accordionFinancial.cic')}:</Form.Label>
                        <Controller
                            control={control}
                            name="ciclo"
                            rules={{ required: { value: true, message: t('accordionFinancial.validation.cic') } }}
                            render={({ field }: any) => (
                                <GetPayCicle propsField={field} propsErrors={errors} propsLabel={t('accordionFinancial.cic')} />
                            )}
                        />
                        <ErrorMessage
                            errors={errors}
                            name="ciclo"
                            render={({ message }) => <small style={{ color: "red" }}>{message}</small>}
                        />
                    </Form.Group>
                    <ControllerCiclePayment />
                    <Form.Group as={Col} md="2" controlId="validationCustomOrganRG">
                        <Form.Label>{t('accordionFinancial.fat')}</Form.Label>
                        <Controller
                            control={control}
                            name="faturamento60Dias"
                            rules={{ required: { value: true, message: t('accordionFinancial.validation.fat') } }}
                            render={({ field }: any) => (
                                <Get60DaysForBilling propsField={field} propsErrors={errors} propsLabel={t('accordionFinancial.fat')} />
                            )}
                        />
                        <ErrorMessage
                            errors={errors}
                            name="faturamento60Dias"
                            render={({ message }) => <small style={{ color: "red" }}>{message}</small>}
                        />
                    </Form.Group>
                </>
            )
        } else {
            return (
                <></>
            )
        }
    }

    const ControllerCiclePayment = () => {
        if (watchCicleType !== undefined) {
            if (watchCicleType !== 'Days' && watchCicleType !== 'Months') {
                return (
                    <Form.Group as={Col} md="2" controlId="validationCustomOrganRG">
                        <Form.Label>{t('accordionFinancial.cut') + watchCicleType}:</Form.Label>
                        <Controller
                            control={control}
                            name="diaDeCorte"
                            rules={{ required: { value: true, message: t('accordionFinancial.validation.cut') } }}
                            render={({ field }: any) => {
                                if (watchCicleType === 'Week') {
                                    return (
                                        <GetDaysWeekForPayment propsField={field} propsErrors={errors} propsLabel={t('accordionFinancial.cic')} />
                                    )
                                } else if (watchCicleType === 'Daily') {
                                    return (
                                        <GetPaymentBiweekly propsField={field} propsErrors={errors} propsLabel={t('accordionFinancial.cic')} />
                                    )
                                } else if (watchCicleType === 'Dec') {
                                    return (
                                        <GetTenth propsField={field} propsErrors={errors} propsLabel={t('accordionFinancial.cic')} />
                                    )
                                } else {
                                    return <></>
                                }
                            }}
                        />
                        <ErrorMessage
                            errors={errors}
                            name="diaDeCorte"
                            render={({ message }) => <small style={{ color: "red" }}>{message}</small>}
                        />
                    </Form.Group>
                )
            } else {
                return <></>
            }

        } else {
            return <></>
        }
    }

    const {
        control,
        watch,
        handleSubmit,
        formState: { errors },
    } = useForm({
        defaultValues: {
            bankId: (isEdit === true || hasSavedInputs === true ) ? { description: infoEdit.bancDesc, id: infoEdit.bankId } : '',
            agency: (isEdit === true || hasSavedInputs === true ) ? infoEdit.agency : supplierControlObject ? supplierControlObject.agency : '',
            account: (isEdit === true || hasSavedInputs === true ) ? infoEdit.account : supplierControlObject ? supplierControlObject.account : '',
            verificadorDiv: (isEdit === true || hasSavedInputs === true ) ? infoEdit.verificadorDiv : supplierControlObject ? supplierControlObject.verificadorDiv : '0',
            comissaoFornecedor: (isEdit === true || hasSavedInputs === true ) ? { description: `${infoEdit.comissaoFornecedor}%`, id: infoEdit.comissaoFornecedor } : '',
            creditLimitSupplier: (isEdit === true || hasSavedInputs === true ) ? infoEdit.creditLimitSupplier : supplierControlObject ? supplierControlObject.creditLimitSupplier : '',
            braspag: (isEdit === true || hasSavedInputs === true ) ? { description: infoEdit.braspagDesc, id: infoEdit.braspag } : '',
            feeBraspag: (isEdit === true || hasSavedInputs === true ) ? { description: infoEdit.feeBraspag === 1 ? "Sim" : "Não", id: infoEdit.feeBraspag } : '',
            mdrBraspag: (isEdit === true || hasSavedInputs === true ) ? infoEdit.mdrBraspag: supplierControlObject ? supplierControlObject.mdrBraspag : '',
            conciliacaoAutomatica: (isEdit === true || hasSavedInputs === true ) ? infoEdit.conciliacaoAutomatica : '',
            currency: (isEdit === true || hasSavedInputs === true ) ? infoEdit.currency : '',
            paymentType: (isEdit === true || hasSavedInputs === true ) ? infoEdit.paymentType : '',
            ciclo: (isEdit === true || hasSavedInputs === true ) ? infoEdit.ciclo : '',
            diaDeCorte: (isEdit === true || hasSavedInputs === true ) ? infoEdit.diaDeCorte : '',
            faturamento60Dias: (isEdit === true || hasSavedInputs === true ) ? infoEdit.faturamento60Dias : '',
            reserve: (isEdit === true || hasSavedInputs === true ) ? infoEdit.reserve : '',
        },
    });

    const watchPaymentType = watch("paymentType", '');
    const watchCicleType = watch("ciclo", '');


    const onSubmit = (data: any) => {
        if (isEdit === false) {
            /// ADD SUPPLIERS PAGE

            data.bancDesc = data.bankId.description;
            data.bankId = data.bankId.id;

            data.braspagDesc = data.braspag.description;
            data.braspag = data.braspag.id;

            data.comissaoFornecedor = data.comissaoFornecedor.id;
            data.feeBraspag = data.feeBraspag.id;
            data.ciclo = data.ciclo ? data.ciclo : '';
            data.diaDeCorte = data.diaDeCorte ? data.diaDeCorte : '';
            data.vencimento = data.ciclo === "Week" ? data.faturamento60Dias : '';
            data.faturamento30Dias = data.ciclo === "Days" ? data.faturamento60Dias : '';
            data.faturamento15Dias = data.ciclo === "Daily" ? data.faturamento60Dias : '';
            data.faturamento60Dias = data.ciclo === "Months" ? data.faturamento60Dias : '';
            data.faturamento10Dias = data.ciclo === "Dec" ? data.faturamento60Dias : '0';

            // Não estão sendo usados
            data.payquinzenal = data.payquinzenal ? data.payquinzenal : '';
            data.payvencimento = data.payvencimento ? data.payvencimento : '';

            // Verifica se o rateio soma 100%
            var somatorioRateio: any = 0;
            data.costCenterSupplierDTO = costCenter;
            data.costCenterSupplierModel = [];
            for (var i: any = 0; i < costCenter.length; i++) {
                somatorioRateio += parseInt(costCenter[i].rateio);
                data.costCenterSupplierModel[i] = { "coastCenterId": costCenter[i].coastCenterId.id, "rateio": parseInt(costCenter[i].rateio) }
            }


            if (somatorioRateio !== 100) {
                setMessageError(true);
            } else {
                setMessageError(false);
                setCtrlEventKey(true);
                action(data);
            }
        } else {
            /// EDIT SUPPLIERS PAGE
            let edit: any = {...infoEdit, ...data};

            edit.supplierId = parseInt(idSup);
            
            edit.comissaoFornecedor = edit.comissaoFornecedor.id;
            edit.braspag = edit.braspag.id;
            edit.bankId = edit.bankId.id;
            edit.feeBraspag = edit.feeBraspag.id;

            // Verifica se o rateio soma 100%
            // eslint-disable-next-line @typescript-eslint/no-redeclare
            var somatorioRateio: any = 0;
            edit.costCenterSupplierModel = [];
            for (var i: any = 0; i < costCenter.length; i++) {
                somatorioRateio += parseInt(costCenter[i].rateio);
                edit.costCenterSupplierModel[i] = { "coastCenterId": costCenter[i].coastCenterId.id, "rateio": parseInt(costCenter[i].rateio) }
            }

            edit.usersSupplierModel = edit.usersSupplierDTO?.map((elem: any) => { return { "userId": elem.userId } });
            edit.supplierContactModel = edit.supplierContact;
            edit.supplierByResourceModel = edit.supplierByResourceDTO;
            edit.supplierVehicleTypeModel = edit.supplierVehicleTypeDTO;

             //remover
             edit.tarTipo = edit.tarTipo === null ? 1 : edit.tarTipo
             edit.masterFilho = edit.masterFilho === null ? 1 : edit.masterFilho
             //[end] remover

            editSuppliers(edit)
        }
    };


    const getCosteCenter = (objCostCenter: any) => {
        setCostCenter(objCostCenter);
    }

    function handleCancel() {
        window.location.reload();
    }

    return (
        <Card className="bg-white mt-4" id="editFinancialForms">
            <Card.Header id="accordionPersonal">
                <ContextAwareToggle eventKey="0" status={ctrlEventKey}>{t('accordionFinancial.title')}</ContextAwareToggle>
            </Card.Header>
            <Accordion.Collapse eventKey="0">
                <Card.Body>
                    <Container className="p-0 mt-3" fluid>
                        <Row>
                            <div>
                                <Form noValidate validated={validated} onSubmit={handleSubmit(onSubmit)}>
                                    <Row className="mb-3">
                                        <Form.Group as={Col} md="4" controlId="validationCustom01" style={{ position: "relative" }}>
                                            <Form.Label>{t('accordionFinancial.bank')}</Form.Label>
                                            <Controller
                                                control={control}
                                                name="bankId"
                                                rules={{ required: { value: true, message: t('accordionFinancial.validation.bank') } }}
                                                render={({ field }: any) => (
                                                    <GetBanks propsField={field} propsErrors={errors} propsLabel={''} />
                                                )}
                                            />
                                            <ErrorMessage
                                                errors={errors}
                                                name="bankId"
                                                render={({ message }) => <small style={{ color: "red" }}>{message}</small>}
                                            />
                                        </Form.Group>
                                        <Form.Group as={Col} md="4" controlId="validationCustom02">
                                            <Form.Label>{t('accordionFinancial.agency')}</Form.Label>
                                            <Controller
                                                control={control}
                                                name="agency"
                                                rules={{ required: { value: true, message: t('accordionFinancial.validation.agency') } }}
                                                render={({ field }: any) => (
                                                    <Form.Control type="text"
                                                        {...field}
                                                        aria-invalid={errors?.agency ? "true" : ""}
                                                        fullWidth
                                                        variant="standard"
                                                        margin="normal"
                                                        onKeyPress={(e) => !/[0-9]/.test(e.key) && e.preventDefault()}
                                                        required 
                                                        autoComplete='off'/>
                                                )}
                                            />
                                            <ErrorMessage
                                                errors={errors}
                                                name="agency"
                                                render={({ message }) => <small style={{ color: "red" }}>{message}</small>}
                                            />
                                        </Form.Group>
                                        <Form.Group as={Col} md="2" controlId="validationCustomUsername">
                                            <Form.Label>{t('accordionFinancial.account')}</Form.Label>
                                            <Controller
                                                control={control}
                                                name="account"
                                                rules={{ required: { value: true, message: t('accordionFinancial.validation.account') } }}
                                                render={({ field }: any) => (
                                                    <Form.Control type="text"
                                                        {...field}
                                                        aria-invalid={errors?.account ? "true" : ""}
                                                        fullWidth
                                                        variant="standard"
                                                        margin="normal"
                                                        onKeyPress={(e) => !/[0-9]/.test(e.key) && e.preventDefault()}
                                                        required 
                                                        autoComplete='off'/>
                                                )}
                                            />
                                            <ErrorMessage
                                                errors={errors}
                                                name="account"
                                                render={({ message }) => <small style={{ color: "red" }}>{message}</small>}
                                            />
                                        </Form.Group>
                                        <Form.Group as={Col} md="2" controlId="validationCustomUsername">
                                            <Form.Label>{t('accordionFinancial.div')}</Form.Label>
                                            <Controller
                                                control={control}
                                                name="verificadorDiv"
                                                rules={{ required: { value: true, message: t('accordionFinancial.validation.div') } }}
                                                render={({ field }: any) => (
                                                    <Form.Control type="text"
                                                        {...field}
                                                        aria-invalid={errors?.verificadorDiv ? "true" : ""}
                                                        variant="standard"
                                                        margin="normal"
                                                        onKeyPress={(e) => !/[0-9]/.test(e.key) && e.preventDefault()}
                                                        maxlength="1"
                                                        required
                                                        autoComplete='off' />
                                                )}
                                            />
                                            <ErrorMessage
                                                errors={errors}
                                                name="verificadorDiv"
                                                render={({ message }) => <small style={{ color: "red" }}>{message}</small>}
                                            />
                                        </Form.Group>
                                    </Row>
                                    <Row className="mb-3">
                                        <Form.Group as={Col} md="4" controlId="validationCustom01">
                                            <Form.Label>{t('accordionFinancial.comm')}</Form.Label>
                                            <Controller
                                                control={control}
                                                name="comissaoFornecedor"
                                                rules={{ required: { value: true, message: t('accordionFinancial.validation.comm') } }}
                                                render={({ field }: any) => (
                                                    <GetPercentSupplierComission propsField={field} propsErrors={errors} propsLabel={t('accordionInfoCompany.comm')} />
                                                )}
                                            />
                                            <ErrorMessage
                                                errors={errors}
                                                name="comissaoFornecedor"
                                                render={({ message }) => <small style={{ color: "red" }}>{message}</small>}
                                            />
                                        </Form.Group>
                                        <Form.Group as={Col} md="4" controlId="validationCustom02">
                                            <Form.Label>{t('accordionFinancial.limit')}</Form.Label>
                                            <Controller
                                                control={control}
                                                name="creditLimitSupplier"
                                                rules={{ required: { value: true, message: t('accordionFinancial.validation.limit') } }}
                                                render={({ field }: any) => (
                                                    <Form.Control type="text"
                                                        {...field}
                                                        aria-invalid={errors?.creditLimitSupplier ? "true" : ""}
                                                        fullWidth
                                                        variant="standard"
                                                        margin="normal"
                                                        onKeyPress={(e) => !/[0-9]/.test(e.key) && e.preventDefault()}
                                                        required
                                                        autoComplete='off' 
                                                        maxLength={13}
                                                        />
                                                )}
                                            />
                                            <ErrorMessage
                                                errors={errors}
                                                name="creditLimitSupplier"
                                                render={({ message }) => <small style={{ color: "red" }}>{message}</small>}
                                            />
                                        </Form.Group>
                                    </Row>
                                    <hr className="my-5" />
                                    <h5>BRASPAG</h5>
                                    <Row className="mb-3">
                                        <Form.Group as={Col} md="4" controlId="validationCustom01">
                                            <Form.Label>{t('accordionFinancial.cadBras')}?</Form.Label>
                                            <Controller
                                                control={control}
                                                name="braspag"
                                                rules={{ required: { value: true, message: t('accordionFinancial.validation.cadBras') } }}
                                                render={({ field }: any) => (
                                                    <GetYesOrNot propsField={field} propsErrors={errors} propsLabel={t('accordionInfoCompany.cadBras')} />
                                                )}
                                            />
                                            <ErrorMessage
                                                errors={errors}
                                                name="braspag"
                                                render={({ message }) => <small style={{ color: "red" }}>{message}</small>}
                                            />
                                        </Form.Group>
                                        <Form.Group as={Col} md="4" controlId="validationCustom02">
                                            <Form.Label>{t('accordionFinancial.freeBras')}</Form.Label>
                                            <Controller
                                                control={control}
                                                name="feeBraspag"
                                                rules={{ required: { value: true, message: t('accordionFinancial.validation.freeBras') } }}
                                                render={({ field }: any) => (
                                                    <GetYesOrNot propsField={field} propsErrors={errors} propsLabel={t('accordionFinancial.freeBras')} />
                                                )}
                                            />
                                            <ErrorMessage
                                                errors={errors}
                                                name="feeBraspag"
                                                render={({ message }) => <small style={{ color: "red" }}>{message}</small>}
                                            />
                                        </Form.Group>
                                        <Form.Group as={Col} md="2" controlId="validationCustom02">
                                            <Form.Label>{t('accordionFinancial.mdrBras')} %</Form.Label>
                                            <Controller
                                                control={control}
                                                name="mdrBraspag"
                                                rules={{ required: { value: true, message: t('accordionFinancial.validation.mdrBras') } }}
                                                render={({ field }: any) => (
                                                    <Form.Control type="text"
                                                        {...field}
                                                        aria-invalid={errors?.mdrBraspag ? "true" : ""}
                                                        fullWidth
                                                        variant="standard"
                                                        margin="normal"
                                                        onKeyPress={(e) => !/[0-9]/.test(e.key) && e.preventDefault()}
                                                        required 
                                                        autoComplete='off'/>
                                                )}
                                            />
                                            <ErrorMessage
                                                errors={errors}
                                                name="mdrBraspag"
                                                render={({ message }) => <small style={{ color: "red" }}>{message}</small>}
                                            />
                                        </Form.Group>
                                    </Row>
                                    <hr className="my-5" />
                                    <h5 className="text-uppercase mb-3">{t('accordionFinancial.payment')}</h5>

                                    <Row>
                                        <p>{t('accordionFinancial.concAut?')}?</p>
                                        <label htmlFor="conciliacaoAutomatica" className="col-md-1">
                                            <Form.Group>
                                                <Controller
                                                    control={control}
                                                    name="conciliacaoAutomatica"
                                                    rules={{ required: { value: true, message: t('accordionFinancial.validation.concAut?') } }}
                                                    render={({ field }: any) => (
                                                        <Form.Check
                                                            {...field}
                                                            type="radio"
                                                            id="radio1"
                                                            required
                                                            defaultChecked={isEdit === true && infoEdit.conciliacaoAutomatica === 1}
                                                            value="1"
                                                            label={t('accordionFinancial.yes')}
                                                        />
                                                    )}
                                                />
                                            </Form.Group>
                                        </label>
                                        <label htmlFor="conciliacaoAutomatica" className="col-md-1">
                                            <Form.Group>
                                                <Controller
                                                    control={control}
                                                    name="conciliacaoAutomatica"
                                                    //rules={{ required: { value: true, message: 'Por favor, preencher esse campo' } }}
                                                    render={({ field }: any) => (
                                                        <Form.Check
                                                            {...field}
                                                            type="radio"
                                                            id="radio1"
                                                            required
                                                            value="2"
                                                            defaultChecked={isEdit === true && infoEdit.conciliacaoAutomatica === 2}
                                                            label={t('accordionFinancial.no')}
                                                        />
                                                    )}
                                                />
                                                {/* <ErrorMessage
                                                    errors={errors}
                                                    name="conciliacaoAutomatica"
                                                    render={({ message }) => <small style={{ color: "red" }}>{message}</small>}
                                                /> */}
                                            </Form.Group>
                                        </label>
                                        <ErrorMessage
                                            errors={errors}
                                            name="conciliacaoAutomatica"
                                            render={({ message }) => <small style={{ color: "red" }}>{message}</small>}
                                        />
                                    </Row>
                                    <p></p>
                                    <Row className="mb-3">
                                        <Form.Group as={Col} md="2" controlId="validationCustomRG">
                                            <Form.Label>{t('accordionFinancial.coin')}</Form.Label>
                                            <Controller
                                                control={control}
                                                name="currency"
                                                rules={{ required: { value: true, message: t('accordionFinancial.validation.coin') } }}
                                                render={({ field }: any) => (
                                                    <GetCurrency propsField={field} propsErrors={errors} propsLabel={t('accordionFinancial.coin')} />
                                                )}
                                            />
                                            <ErrorMessage
                                                errors={errors}
                                                name="currency"
                                                render={({ message }) => <small style={{ color: "red" }}>{message}</small>}
                                            />
                                        </Form.Group>
                                        <Form.Group as={Col} md="2" controlId="validationCustomRG">
                                            <Form.Label>{t('accordionFinancial.payment')}:</Form.Label>
                                            <Controller
                                                control={control}
                                                name="paymentType"
                                                rules={{ required: { value: true, message: t('accordionFinancial.validation.payment') } }}
                                                render={({ field }: any) => (
                                                    <GetPaymentForms
                                                        propsField={field}
                                                        propsErrors={errors}
                                                        propsLabel={t('accordionFinancial.payment')}
                                                    />
                                                )}
                                            />
                                            <ErrorMessage
                                                errors={errors}
                                                name="paymentType"
                                                render={({ message }) => <small style={{ color: "red" }}>{message}</small>}
                                            />
                                        </Form.Group>
                                        <ControllerPayment />
                                    </Row>
                                    <hr className="my-5" />
                                    <h5 className="text-uppercase mb-3">{t('accordionFinancial.coastCenter')}</h5>

                                    <Row>
                                        <FinancialTable
                                            isEdit={isEdit}
                                            hasSavedInputs={hasSavedInputs}
                                            setMessageError={setMessageError}
                                            messageError={messageError}
                                            action={getCosteCenter}
                                            infoEdit={infoEdit}
                                            disableAddContact={false/* disableAddContact */}
                                        />
                                    </Row>
                                    <Row className="d-flex justify-content-end mx-0 mt-5">
                                        {
                                            isEdit === false
                                                ?
                                                <Button
                                                    type="button"
                                                    className="form-button back-btn btn btn-outline-primary"
                                                    variant='outline'
                                                    onClick={() => {
                                                        setStepCtrl(0)
                                                        scrollTop()
                                                    }}
                                                >
                                                    voltar
                                                </Button>
                                                :
                                                <Button
                                                    className="form-button cancel"
                                                    onClick={handleCancel}
                                                >
                                                    Cancelar
                                                </Button>}
                                        <Button
                                            type="submit"
                                            className="form-button "
                                            //onClick={scrollTop}
                                        >
                                            {t('accordionInfoCompany.buttonContinue')}
                                        </Button>
                                    </Row>
                                </Form>
                            </div>
                        </Row>
                    </Container>
                </Card.Body>
            </Accordion.Collapse>
        </Card>
    )
});

export default AccordionFinancial;