/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useState, useEffect } from "react";
import { Container, Row, Col } from 'react-bootstrap';
import Form from 'react-bootstrap/Form';
//import InputGroup from 'react-bootstrap/InputGroup';
import Button from 'react-bootstrap/Button';
import Accordion from 'react-bootstrap/Accordion';
import { useTranslation } from "react-i18next";

import InputGroup from 'react-bootstrap/InputGroup';

import './FilterSubordinateExtract.scss';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

//Components
import SingleCalendarEditable from "../../../../components/Calendar/SingleCalendarEditable";
//import RangeCalendar from "../../../../components/Calendar/RangeCalendar";
import GlobalAlert from "../../../../components/Alert/GlobalAlert/GlobalAlert";
import { Controller, useForm } from "react-hook-form";
import GetSubordinates from "../../../../components/C2Points/GetSubordinates";
import RangeCalendar from "../../../../components/Calendar/RangeCalendar";

interface Props {
    buscar: any,
    sessions: any,
    error: boolean,
    adquirentePadrao: string,
    dataPadrao: any,
    isAgent: any,
    //supplierId: any,
}

function FilterSubordinateExtract(props: Props) {

    const { t } = useTranslation();

    //const hoje = new Date();
    //const ontem = new Date(hoje.getFullYear(), hoje.getMonth(), hoje.getDate() - 1); // hoje - 1 dia = ontem
    //const dataPadrao = `${ontem.getDate()}/${ontem.getMonth()+1}/${ontem.getFullYear()}`;


    const [filterLocalizador, setFilterLocalizador] = useState<any>("");
    const [filterRefAgent, setFilterRefAgent] = useState<any>("");
    const [filterStatusReserve, setFilterStatusReserve] = useState<any>(1);
    const [filterStatusPagamento, setFilterStatusPagamento] = useState<any>(2);
    const [filterFormaPagamento, setFilterFormaPagamento] = useState<any>(5);
    const [filterAdquirente, setFilterAdquirente] = useState<any>(props.adquirentePadrao);
    const [filterSupplier, setFilterSupplier] = useState<any>("");
    const [filterClient, setFilterClient] = useState<any>("");
    const [filterTypeDate, setFilterTypeDate] = useState<any>(2);
    const [filterDate, setFilterDate] = useState<any>(props.dataPadrao);



    const [, setAlert] = useState<any>();
    //const [checked, setChecked] = useState<boolean>(true);

    //const [info, setInfo] = useState<any>(null);
    const [isMenuOpen, setIsMenuOpen] = useState<boolean>(false);

    const onMenuOpen = () => {
        if (isMenuOpen === false) {
            setIsMenuOpen(true);
        } else {
            setIsMenuOpen(false);
        }
    }

    const {
        control,
        formState: { errors },
    } = useForm({});

    const handleFilterClick = () => {

        props.buscar(filterDate, filterTypeDate, filterLocalizador, filterRefAgent, filterStatusReserve, filterStatusPagamento, filterFormaPagamento, filterAdquirente, filterSupplier, filterClient);
        //console.log(filterName, filterState, filterType, filterStatus, filterDate)
    };

    const handleClearClick = () => {

        let inputValue = document.querySelectorAll('input');
        for (let i = 0; i < inputValue.length; i++) {
            inputValue[i].value = "";
        }

        let selectValue = document.querySelectorAll('select');
        for (let i = 0; i < selectValue.length; i++) {
            selectValue[i].value = "0";
        }

        setFilterLocalizador('');
        setFilterRefAgent('');
        setFilterStatusReserve(1);
        setFilterStatusPagamento(2);
        setFilterFormaPagamento(4);
        setFilterAdquirente(props.adquirentePadrao);
        setFilterSupplier('');
        setFilterClient('');
        setFilterTypeDate(2);
        setFilterDate(props.dataPadrao);

        props.buscar(props.dataPadrao, 2, '', '', 1, 2, 4, props.adquirentePadrao, '', '');
    };

    const handleChangeLocalizador = (e: React.ChangeEvent<HTMLInputElement>) => {
        const newValue = e.target.value;
        setFilterLocalizador(newValue);
    }

    const handleChangeRefAgent = (e: React.ChangeEvent<HTMLInputElement>) => {
        const newValue = e.target.value;
        setFilterRefAgent(newValue);
    }

    const handleChangeStatusReserve = (e: React.ChangeEvent<HTMLInputElement>) => {
        const newValue = e.target.value;
        setFilterStatusReserve(newValue);
    }

    const handleChangeStatusPagamento = (e: React.ChangeEvent<HTMLInputElement>) => {
        const newValue = e.target.value;
        setFilterStatusPagamento(newValue);
    }

    const handleChangeFormaPagamento = (e: React.ChangeEvent<HTMLInputElement>) => {
        const newValue = e.target.value;
        setFilterFormaPagamento(newValue);
        setFilterAdquirente(props.adquirentePadrao);
    }

    const handleChangeAdquirente = (e: React.ChangeEvent<HTMLInputElement>) => {
        const newValue = e.target.value;
        setFilterAdquirente(newValue);
    }

    const handleChangeClient = (e: React.ChangeEvent<HTMLInputElement>) => {
        const newValue = e.target.value;
        setFilterClient(newValue);
    }

    const handleChangeTypeDate = (e: React.ChangeEvent<HTMLInputElement>) => {
        const newValue = e.target.value;
        setFilterTypeDate(newValue);
    }

    const incDate = (e: any, dias: number) => {
        e.preventDefault();

        const today = new Date();

        const newDate = (filterDate.length === 0) ? `${today.getDate()}/${today.getMonth()+1}/${today.getFullYear()}` : filterDate[0];

        const datao = newDate.split('/');
        
        const newDate1 = new Date(parseInt(datao[2]), parseInt(datao[1])-1, parseInt(datao[0])+dias);
        //console.log(parseInt(datao[0])+dias); return;
        const newDateStr = `${newDate1.getDate()}/${newDate1.getMonth()+1}/${newDate1.getFullYear()}`

        setFilterDate([newDateStr]);

        props.buscar([newDateStr], filterTypeDate, filterLocalizador, filterRefAgent, filterStatusReserve, filterStatusPagamento, filterFormaPagamento, filterAdquirente, filterSupplier, filterClient);
    };

    useEffect(() => {
        if (props.sessions?.length === 0 || props.error === true) {
            setAlert(
                <GlobalAlert alertType={"danger"}>
                    {t("subordinateExtract.subordinateFilter.sessionNotFound")}
                </GlobalAlert>
            )
            setTimeout(() => setAlert(null), 2000);
        } else {
            setAlert(null);
        }

        //updateDropdowns(filterDate, filterType);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.sessions, props.error/*, filterDate, filterType*/])

    return (
        <div className="suppliers-filter d-flex justify-content-center bg-white mt-4" style={{ textAlign: "left" }}>
            <Accordion>
                <Accordion.Item eventKey="0">
                    <Accordion.Header><FontAwesomeIcon icon={['fal', 'sliders-h']} size="lg" className="mr-2" /><span className="h5 mb-0">{t("subordinateExtract.subordinateFilter.search")}</span></Accordion.Header>
                    <Accordion.Body className="pt-0">
                        <Container className="p-0" fluid>
                            <Row>
                                <div>
                                    <Form >
                                        <Row className="mb-3">
                                            <div className="col col-sm-12 col-md-3 d-flex p-0">
                                                <Form.Group as={Col} md="6" className="px-3">
                                                    <Form.Label>{t("subordinateExtract.subordinateFilter.locator")}</Form.Label>
                                                    <Form.Control
                                                        type="text"
                                                        autoComplete='off'
                                                        onChange={handleChangeLocalizador}
                                                    />
                                                    {/* <Form.Control.Feedback>Looks good!</Form.Control.Feedback> */}
                                                </Form.Group>
                                                <Form.Group as={Col} md="6"className="px-3">
                                                    <Form.Label>{t("subordinateExtract.subordinateFilter.ourRef")}</Form.Label>
                                                    <Form.Control
                                                        type="text"
                                                        autoComplete='off'
                                                        onChange={handleChangeRefAgent}
                                                    />
                                                </Form.Group>
                                            </div>
                                            <div className="col col-sm-12 col-md-3 d-flex p-0">
                                                <Form.Group as={Col} md="6" className="px-3">
                                                    <Form.Label>{t("subordinateExtract.subordinateFilter.reservationStatus")}</Form.Label>
                                                    <div className="endpoint">
                                                        <InputGroup hasValidation>
                                                            <Form.Select
                                                            autoComplete='off'
                                                                aria-describedby="inputGroupPrepend"
                                                                onChange={(e: any) => handleChangeStatusReserve(e)}
                                                                value={filterStatusReserve}
                                                            >
                                                                <option value="">{t("subordinateExtract.subordinateFilter.reservationSelect.all")}</option>
                                                                <option value="1">{t("subordinateExtract.subordinateFilter.reservationSelect.confirmed")}</option>
                                                                <option value="2">{t("subordinateExtract.subordinateFilter.reservationSelect.canceled")}</option>
                                                                <option value="7">{t("subordinateExtract.subordinateFilter.reservationSelect.pre")}</option>
                                                            </Form.Select>
                                                            {/* <Form.Control.Feedback type="invalid">
                                                            Please choose a username.
                                                        </Form.Control.Feedback> */}
                                                        </InputGroup>
                                                    </div>
                                                    {/* <Form.Control.Feedback>Looks good!</Form.Control.Feedback> */}
                                                </Form.Group>
                                                <Form.Group as={Col} md="6" className="px-3">
                                                    <Form.Label>{t("subordinateExtract.subordinateFilter.paymentStatus")}</Form.Label>
                                                    <div className="endpoint">
                                                        <InputGroup hasValidation>
                                                            <Form.Select
                                                            autoComplete='off'
                                                                aria-describedby="inputGroupPrepend"
                                                                onChange={(e: any) => handleChangeStatusPagamento(e)}
                                                                value={filterStatusPagamento}
                                                            >
                                                                <option value="">{t("subordinateExtract.subordinateFilter.paymentStatusSelect.all")}</option>
                                                                <option value="1">{t("subordinateExtract.subordinateFilter.paymentStatusSelect.pending")}</option>
                                                                <option value="2">{t("subordinateExtract.subordinateFilter.paymentStatusSelect.reconciled")}</option>
                                                                <option value="3">{t("subordinateExtract.subordinateFilter.paymentStatusSelect.invoiced")}</option>
                                                                <option value="4">{t("subordinateExtract.subordinateFilter.paymentStatusSelect.received")}</option>
                                                            </Form.Select>
                                                            {/* <Form.Control.Feedback type="invalid">
                                                            Please choose a username.
                                                        </Form.Control.Feedback> */}
                                                        </InputGroup>
                                                    </div>
                                                </Form.Group>
                                            </div>
                                            <div className="col col-sm-12 col-md-3 d-flex p-0">
                                                <Form.Group as={Col} md="6" className="px-3">
                                                    <Form.Label>{t("subordinateExtract.subordinateFilter.paymentForm")}</Form.Label>
                                                    <div className="endpoint">
                                                        <InputGroup hasValidation>
                                                            <Form.Select
                                                            autoComplete='off'
                                                                aria-describedby="inputGroupPrepend"
                                                                onChange={(e: any) => handleChangeFormaPagamento(e)}
                                                                value={filterFormaPagamento}
                                                            >
                                                                {/* <option value="">{t("subordinateExtract.subordinateFilter.paymentFormSelect.all")}</option>
                                                                <option value="1">{t("subordinateExtract.subordinateFilter.paymentFormSelect.cash")}</option>
                                                                <option value="2">{t("subordinateExtract.subordinateFilter.paymentFormSelect.pix")}</option>
                                                                <option value="3">{t("subordinateExtract.subordinateFilter.paymentFormSelect.tranference")}</option> */}
                                                                <option value="4">{t("subordinateExtract.subordinateFilter.paymentFormSelect.card")}</option>
                                                                <option value="5">{t("subordinateExtract.subordinateFilter.paymentFormSelect.invoiced")}</option>
                                                                {/* <option value="6">{t("subordinateExtract.subordinateFilter.paymentFormSelect.commissionToReceive")}</option>
                                                                <option value="7">{t("subordinateExtract.subordinateFilter.paymentFormSelect.picpay")}</option> */}
                                                            </Form.Select>
                                                            {/* <Form.Control.Feedback type="invalid">
                                                            Please choose a username.
                                                        </Form.Control.Feedback> */}
                                                        </InputGroup>
                                                    </div>
                                                </Form.Group>
                                                {filterFormaPagamento === '4' ?
                                                <Form.Group as={Col} md="6" className="px-3">
                                                    <Form.Label>{t("subordinateExtract.subordinateFilter.purchaser")}</Form.Label>
                                                    <div className="endpoint">
                                                        <InputGroup hasValidation>
                                                            <Form.Select
                                                            autoComplete='off'
                                                                aria-describedby="inputGroupPrepend"
                                                                //onChange={(e: any) => handleChangeAdquirente(e)}
                                                                value={filterAdquirente}
                                                                disabled={true}
                                                            >
                                                                <option value="16">{t("subordinateExtract.subordinateFilter.purchaserSelect.braspag")}</option>
                                                                {/* <option value="9">{t("subordinateExtract.subordinateFilter.purchaserSelect.cielo")}</option> */}
                                                            </Form.Select>
                                                            {/* <Form.Control.Feedback type="invalid">
                                                            Please choose a username.
                                                        </Form.Control.Feedback> */}
                                                        </InputGroup>
                                                    </div>
                                                </Form.Group>
                                                :<></>}
                                            </div>
                                            {props.isAgent !== 1 ?
                                            <Form.Group as={Col} md="3" className="px-3">
                                                <Form.Label>{t("subordinateExtract.subordinateFilter.subordinate")}</Form.Label>
                                                <Controller
                                                    control={control}
                                                    name="subordinado"
                                                    render={({ field }: any) => (
                                                        <GetSubordinates propsField={field} propsErrors={errors} propsLabel="Subordinado" setSupplier={setFilterSupplier} />
                                                    )}
                                                />
                                            </Form.Group>
                                            : <></>}
                                        </Row>
                                        <Row className="mb-3">
                                            <Form.Group as={Col} md="3" className="px-3">
                                                <Form.Label>{t("subordinateExtract.subordinateFilter.client")}</Form.Label>
                                                <Form.Control
                                                    type="text"
                                                    autoComplete='off'
                                                    onChange={handleChangeClient}
                                                />
                                            </Form.Group>
                                            <Form.Group as={Col} md="2" className="px-3">
                                                <Form.Label>{t("subordinateExtract.subordinateFilter.dateType")}</Form.Label>
                                                <div className="endpoint">
                                                    <InputGroup hasValidation>
                                                        <Form.Select
                                                        autoComplete='off'
                                                            aria-describedby="inputGroupPrepend"
                                                            onChange={(e: any) => handleChangeTypeDate(e)}
                                                            value={filterTypeDate}
                                                        >
                                                            
                                                            <option value="3">{t("subordinateExtract.subordinateFilter.dateTypeSelect.expiration")}</option>
                                                            <option value="2">{t("subordinateExtract.subordinateFilter.dateTypeSelect.operation")}</option>
                                                            <option value="1">{t("subordinateExtract.subordinateFilter.dateTypeSelect.reservation")}</option>
                                                        </Form.Select>
                                                    </InputGroup>
                                                </div>
                                            </Form.Group>
                                            {/* {alert} */}
                                            <Form.Group as={Col} md="3" className="px-3">
                                                <Form.Label>{t("subordinateExtract.subordinateFilter.receiptDate")}</Form.Label>
                                                <div className="d-flex">
                                                    <button className="btn btn-default p-0 mx-2" style={{width:"25px"}} onClick={(e:any) => incDate(e, -1)}><FontAwesomeIcon
                                                            size="sm"
                                                            icon={["fal", "arrow-left"]}
                                                            style={{ color: "#707070", margin: "0px", padding: "0px"}}
                                                        /></button>
                                                    <InputGroup hasValidation className="ranger-calendar">
                                                        <RangeCalendar date={filterDate} setDate={setFilterDate} />
                                                    </InputGroup>
                                                    <button className="btn btn-default p-0 mx-2" style={{width:"25px"}} onClick={(e:any) => incDate(e, 1)}><FontAwesomeIcon
                                                            size="sm"
                                                            icon={["fal", "arrow-right"]}
                                                            style={{ color: "#707070", margin: "0px", padding: "0px" }}
                                                        /></button>
                                                </div>
                                            </Form.Group>
                                        </Row>
                                        <Row as={Col} md="12" className="mt-5 justify-content-md-end">
                                            <Col xs lg="12" className="d-flex justify-content-md-end">
                                                <Button variant="outline-primary" className="btn-default mr-2 text-uppercase" onClick={handleClearClick}>{t('partners.partnersfilter.btnClear')}</Button>
                                                <Button className="btn-default text-uppercase" onClick={handleFilterClick}>{t('partners.partnersfilter.btnSearch')}</Button>
                                            </Col>
                                        </Row>
                                    </Form>
                                </div>
                            </Row>
                        </Container>
                    </Accordion.Body>
                </Accordion.Item>
            </Accordion>
        </div>
    )
}

export default FilterSubordinateExtract;