import React, { Key, useEffect, useState } from 'react';
import Table from 'react-bootstrap/Table';
//import Button from 'react-bootstrap/Button';

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Dropdown from 'react-bootstrap/Dropdown';

import { useTranslation } from 'react-i18next';

//STYLES
import './SuppliersTableUsers.scss';

import ModalDefault from '../../../../../components/Modal/ModalDefault/ModalDefault';
import FormNewUsersModal from '../../../../../components/Forms/FormNewUsersModal/FormNewUsersModal';
import SupplierRegisterUsers from '../SupplierRegisterUsers/SupplierRegisterUsers';
import api from '../../../../../services/api';

export interface propModal {
  actionTableUser: any,
  infoEdit: any,
  isEdit: any,
  usersEdit?: any,
  disableAddContact?: any;
};

const SuppliersTableUsers: React.FC<propModal> = ({
  actionTableUser, infoEdit, isEdit = false, disableAddContact = false, usersEdit
}: propModal) => {
  const { t } = useTranslation();

  const [userObject, setUserObject] = useState<any>(infoEdit?.usersSupplierDTO ? infoEdit.usersSupplierDTO : []);
  const [closeModal, setCloseModal] = useState<any>(false);
  const [usersLevel, setUsersLevel] = useState<any>([]);

  function verificaId(arr: any, elem: any) {
    for (var i: any = 0; i < arr.length; i++) {
      if (arr[i].id === elem || arr[i].userId === elem) return true;
    }
    return false;
  }

  const handleAction = (objectUserModal: any) => {

    var aux: any = userObject;

    objectUserModal.forEach((element: any) => {
      if (!verificaId(userObject, element?.id)) {
        aux.push(element);
      }
    });
    console.log(aux)
    setUserObject([...aux]);
  };

  function removeUser(id: any) {
    var aux: any = [];

    userObject.forEach((element: any) => {
      if (element.userId === undefined) {
        element.userId = element.id;
      }

      if (!(element.userId === id)) {
        aux.push(element);
      }
    });

    setUserObject(aux);
  }

  useEffect(() => {
    actionTableUser(userObject);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userObject])

  useEffect(() => {
    if (isEdit === true) {
      setUserObject(infoEdit.map((elem: any) => { return ({ ...elem, nivelInfo: elem.nivel }) }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  function handleSelectUsers(obj: any = {}) {
    console.log(obj)
    let aux: any = [];

    Array.from(userObject)?.forEach((elem: any) => {
      if (elem.userId === obj?.userId || elem.id === obj?.userId) {
        aux.push({ ...elem, nivel: obj?.nivel, nivelInfo: obj?.nivel })
      } else {
        aux.push(elem)
      }
    });

    setUserObject(() => [...aux])
  }

  useEffect(() => {
    //const arrAux: any = [];
    const token = localStorage.getItem('GroupId') || '{}';
    const config = {
      headers: { 'Authorization': `Bearer ${token}` },
    };

    Array.from(userObject)?.forEach(async (elem: any) => {
      const idUser: number = elem.id || elem.userId;

      try {
        const { data } = await api.post('/UserPermission/GetListUserPermissionById',
          {
            "IdUser": idUser,
          }, config
        );
        if (data.statusCode !== 400) {
          if (data.data.log === 0) {
            setUsersLevel((prev: any) => [...prev, data.data.data[0]?.supplierUserPermissionDTO.map((item: any) => {
              return {
                idUser: idUser,
                nivel: item.user_role_id,
                description: item.description
              }
            })])
          }
        }
      } catch (error: any) {
        if (error?.response?.status === 401) {
        }
      }
    })
  }, [userObject]);

  // useEffect(() => {
  //   Array.from(userObject)?.forEach((elem:any)=>{
  //     if(elem.hasOwnProperty("nivel")){            
  //       handleSelectUsers({ userId: infoEdit.id, id: elem?.userId, nivel:elem.nivel })
  //     }
  //   })
  // // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [])

  console.log(userObject)

  if (userObject) {
    return (
      <>
        <div className="table-add-default suppliers-users-table">
          <div className="table-container">

            <Table striped bordered>
              <thead>
                <tr>
                  <th>{t("accordionUsers.tableUsers.profile")}</th>
                  <th>{t("accordionUsers.tableUsers.name")}</th>
                  <th>{t("accordionUsers.tableUsers.lastname")}</th>
                  <th>{t("accordionUsers.tableUsers.email")}</th>
                  <th>{t("accordionUsers.tableUsers.phone")}</th>
                  <th>{t("accordionUsers.tableUsers.cpf")}</th>
                  <th>{t("accordionUsers.tableUsers.level")}</th>
                  <th>{t("accordionUsers.tableUsers.actions")}</th>
                </tr>
              </thead>
              <tbody>
                {userObject.length > 0
                  ? userObject.map((info: any, index: Key) => (
                    <tr key={index}>
                      <td>{info?.isMain}</td>
                      <td>{info?.nome ? info.nome : info.name}</td>
                      <td>{info?.lastName}</td>
                      <td>{info?.email}</td>
                      <td>{info?.telefone}</td>
                      <td>{info?.cpf}</td>
                      <td>{
                        usersEdit !== true
                          ?
                          // eslint-disable-next-line array-callback-return
                          usersLevel?.find((elem: any) => {
                            if (elem?.length > 0) {
                              return elem[0].idUser === info.id || elem[0].idUser === info.userId
                            }
                          }) === undefined
                            ?
                            <select className='form-control' onChange={(e: any) => handleSelectUsers({ userId: info.id || info.userId, id: e.target[1]?.id, nivel: 29 })}>
                              {Number(info.nivel) === 29 ?
                                <>
                                  <option value="1" disabled>Selecione</option>
                                  <option value="29" selected={Number(info.nivel) === 29} >Agente Não Comissionado</option>
                                </>
                                :
                                <>
                                  <option value="1" selected disabled>Selecione</option>
                                  <option value="29"  >Agente Não Comissionado</option>
                                </>
                              }
                            </select>
                            :

                            <select className='form-control' onChange={(e: any) => handleSelectUsers({ userId: info.id || info.userId, id: e.target[1]?.id, nivel: e.target.value })}>
                              <option value="29" disabled>Selecione</option>
                              {
                                // eslint-disable-next-line array-callback-return
                                usersLevel?.find((elem: any) => {
                                  if (elem?.length > 0) {
                                    return elem[0].idUser === info.id || elem[0].idUser === info.userId
                                  }
                                })?.map((elem: any) => {
                                  return <option key={index} value={elem.nivel} id={elem.idUser} selected={Number(elem.nivel) === Number(info.nivel)}>{elem.description}</option>
                                })
                              }
                            </select>
                          :
                          info?.nivelDesc
                      }</td>
                      <td>{
                        <div className="center-table options-table">
                          <Dropdown drop="down" >
                            <Dropdown.Toggle variant="light ">
                              <FontAwesomeIcon
                                size="lg"
                                icon={['fal', 'ellipsis-h']}
                              />
                            </Dropdown.Toggle>

                            {disableAddContact === false
                              ?
                              <Dropdown.Menu>
                                <Dropdown.Item disabled={isEdit} eventKey="1" className="text-danger removeRow" onClick={() => removeUser(info?.id ? info.id : info.userId)}><FontAwesomeIcon icon={['fal', 'trash-alt']} />{t("accordionFinancial.tableFinancial.remove")}</Dropdown.Item>
                              </Dropdown.Menu>
                              :
                              <></>
                            }
                          </Dropdown>
                        </div>
                      }</td>
                    </tr>
                  ))
                  : ""
                }
                {!isEdit
                  ?
                  <div className="add-contact">
                    <div className="d-flex pt-3 pb-4">
                      <ModalDefault
                        title="Vincular usuário"
                        name={'Vincular usuário'}
                        class="btn btn-outline-primary mr-2"
                        closeModal={closeModal}
                        setCloseModal={setCloseModal}
                        classModal="modal-pos-tax"
                      >
                        <FormNewUsersModal actionModalUser={handleAction} setCloseModal={setCloseModal} />
                      </ModalDefault>

                      <ModalDefault
                        title="Adicionar usuário"
                        name={'Adicionar usuário'}
                        class="btn btn-outline-primary"
                        closeModal={closeModal}
                        setCloseModal={setCloseModal}
                        classModal="modal-large"
                      >
                        <SupplierRegisterUsers actionModalUser={handleAction} />
                      </ModalDefault>
                    </div>
                  </div>
                  : ''
                }
                {/* <tr>
                <td colSpan={7} className="text-center btn-add-contact">
                <ModalDefault name={'+'} class="btn-add">
                <FormNewUsersModal actionModalUser={handleAction} />
                </ModalDefault>
                </td>
              </tr> */}
                {/* <p className="add-contact">
                <td colSpan={12} className="text-center btn-add-contact">
                  <ModalDefault name={'+'} class="btn-add">
                  <FormNewUsersModal actionModalUser={handleAction} />
                  </ModalDefault>
                </td>
              </p> */}
              </tbody>
            </Table>
          </div>
        </div>
      </>
    );
  } else {
    return (
      <>
        <div className="users-table d-flex w-100 justify-content-center">
          <div className="table-container">

            <Table striped bordered>
              <thead>
                <tr>
                  <th>{t("accordionUsers.tableUsers.profile")}</th>
                  <th>{t("accordionUsers.tableUsers.name")}</th>
                  <th>{t("accordionUsers.tableUsers.lastname")}</th>
                  <th>{t("accordionUsers.tableUsers.email")}</th>
                  <th>{t("accordionUsers.tableUsers.phone")}</th>
                  <th>{t("accordionUsers.tableUsers.cpf")}</th>
                  <th>{t("accordionUsers.tableUsers.level")}</th>
                  <th>{t("accordionUsers.tableUsers.actions")}</th>
                </tr>
              </thead>
              <tbody>

                {/* <tr>
                <td colSpan={7} className="text-center btn-add-contact">
                <ModalDefault name={'+'} class="btn-add">
                <FormNewUsersModal actionModalUser={handleAction} />
                </ModalDefault>
                </td>
              </tr> */}
                <p className="add-contact">
                  <td colSpan={12} className="text-center btn-add-contact">
                    <ModalDefault name={'+'} class="btn-add" closeModal={closeModal} setCloseModal={setCloseModal}>
                      <FormNewUsersModal actionModalUser={handleAction} setCloseModal={setCloseModal} />
                    </ModalDefault>
                  </td>
                </p>
              </tbody>
            </Table>


          </div>
        </div>
      </>
    )
  }
}

export default SuppliersTableUsers;