
function SetTransfersItem(item: any) {


  const getTransfers = localStorage.getItem("transferSIG");
  let transfer = JSON.parse(getTransfers || '{}');
  transfer = item;
  localStorage.setItem("transferSIG", JSON.stringify(transfer));  

  /* window.location.href="/transfers"; */
  return true;
}



export default SetTransfersItem;