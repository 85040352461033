import React, { useState } from "react";
import { Container, Row, Col } from 'react-bootstrap';
import Form from 'react-bootstrap/Form';
//import InputGroup from 'react-bootstrap/InputGroup';
import Button from 'react-bootstrap/Button';
import Accordion from 'react-bootstrap/Accordion';
import { useTranslation } from "react-i18next";

import { Controller, useForm } from "react-hook-form";

import InputGroup from 'react-bootstrap/InputGroup';

import './FilterBillsReceive.scss';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

//Components
import RangeCalendar from "../../../../components/Calendar/RangeCalendar";
import GetAgents from "../../../../components/C2Points/GetAgentsFilter";
import GetSuppliersIdentity from "../../../../components/C2Points/GetSuppliersIdentity";
import GetAccountsByTypeFilter from "../../../../components/C2Points/GetAccountsByTypeFilter";
import ModalQuestion from "../../../../components/Modal/ModalQuestion/ModalQuestion";
/* import GlobalAlert from "../../../../components/Alert/GlobalAlert/GlobalAlert"; */

export interface propFilter {
    buscar: any,
    billsReceive: any,
    error: any,
    clearActualValues?: any,
    defaultDate: any
}

const FilterBillsReceive: React.FC<propFilter> = ({
    buscar, billsReceive, error, clearActualValues, defaultDate
}) => {

    const { t } = useTranslation();

    //const [payment, setPayment] = useState<any>("");

    const today = new Date();
    const todayStr = `${today.getDate()}/${today.getMonth() + 1}/${today.getFullYear()}`;

    const [filterLocalizador, setFilterLocalizador] = useState<any>("");
    const [filterStatusReserve, setFilterStatusReserve] = useState<any>(1);
    const [filterStatusPagamento, setFilterStatusPagamento] = useState<any>(1);
    const [filterStatusFormaPagamento, setFilterStatusFormaPagamento] = useState<any>('');
    const [filterSupplier, setFilterSupplier] = useState<any>("");
    const [filterAdquirente, setFilterAdquirente] = useState<any>(null);
    const [filterToken, setFilterToken] = useState<any>("");
    const [filterAgent, setFilterAgent] = useState<any>("");
    const [filterRefAgent, setFilterRefAgent] = useState<any>("");
    const [filterClient, setFilterClient] = useState<any>("");
    const [filterType, setFilterType] = useState<any>(null);
    const [filterTypeDate, setFilterTypeDate] = useState<any>(3);
    const [filterDate, setFilterDate] = useState<any>([defaultDate]);
    const [filterIdDecolarFenix, setFilterIdDecolarFenix] = useState<any>("");
    const [filterStatusErp, setFilterStatusErp] = useState<any>(1);

    const [alert, ] = useState<any>();

    const level:string = atob(JSON.parse(localStorage.getItem('c2tourAuth') || '{}').user.perfil.nivel);
  

    /* Date Interval Confirmation */
    const [dateIntervalConfirmModalShow, setDateIntervalConfirmModalShow] = useState<any>(false);
    /* END - Date Interval Confirmation */

    const {
        control,
        formState: { errors },
    } = useForm({});

    const handleFilterClick = (event: any) => {

        var dataIntervalLong = false;

        if (filterDate.length === 2) {
            let beginDate = filterDate[0].split('/')
            let endDate = filterDate[1].split('/')

            const dataIni = new Date(parseInt(beginDate[2]), parseInt(beginDate[1])-1, parseInt(beginDate[0]));
            const dataFim = new Date(parseInt(endDate[2]), parseInt(endDate[1])-1, parseInt(endDate[0]));

            const days = (dataFim.getTime() - dataIni.getTime()) / (1000*60*60*24);

            dataIntervalLong = (days > 90); // 3 meses
            
        }

        if(dataIntervalLong) {
            setDateIntervalConfirmModalShow(true);
        } else {
            handleActionBuscar();
        }
    }

    const handleFilterErpClick = (event: any) => {
        setFilterLocalizador("");
        setFilterStatusReserve(1);
        setFilterStatusPagamento(2);
        setFilterStatusFormaPagamento('');
        setFilterSupplier('');
        setFilterAdquirente(null);
        setFilterToken('');
        setFilterAgent('');
        setFilterRefAgent('');
        setFilterClient('');
        setFilterType(null);
        setFilterTypeDate(1);

        const firstDate = new Date(today.getFullYear(), today.getMonth(), today.getDate()-3);
        const firstDateStr = `${firstDate.getDate()}/${firstDate.getMonth() + 1}/${firstDate.getFullYear()}`;

        setFilterDate([firstDateStr,todayStr]);
        setFilterIdDecolarFenix('');
        setFilterStatusErp(1);

        buscar(filterLocalizador, filterStatusReserve, filterStatusPagamento, filterStatusFormaPagamento, filterSupplier, filterAdquirente, filterToken, filterAgent, filterRefAgent, filterClient, filterType, filterTypeDate, filterDate, filterIdDecolarFenix, filterStatusErp, 1);
    }

    const handleActionBuscar = () => {
        setDateIntervalConfirmModalShow(false);
        buscar(filterLocalizador, filterStatusReserve, filterStatusPagamento, filterStatusFormaPagamento, filterSupplier, filterAdquirente, filterToken, filterAgent, filterRefAgent, filterClient, filterType, filterTypeDate, filterDate, filterIdDecolarFenix, filterStatusErp, 2);
    };

    const handleClearClick = (event: any) => {

        let inputValue = document.querySelectorAll('input');
        for (let i = 0; i < inputValue.length; i++) {
            inputValue[i].value = "";
        }

        let selectValue = document.querySelectorAll('select');
        for (let i = 0; i < selectValue.length; i++) {
            selectValue[i].value = "0";
        }

        setFilterLocalizador("");
        setFilterStatusReserve(1);
        setFilterStatusPagamento(2);
        setFilterStatusFormaPagamento('');
        setFilterSupplier('');
        setFilterAdquirente(null);
        setFilterToken('');
        setFilterAgent('');
        setFilterRefAgent('');
        setFilterClient('');
        setFilterType(null);
        setFilterTypeDate(3);
        setFilterDate([defaultDate]);
        setFilterIdDecolarFenix('');
        setFilterStatusErp(1);
        buscar("", 1, 2, null, '', null, '', '', '', '', null, 3, [defaultDate], null, 1);
    };

    const handleChangeLocalizador = (e: React.ChangeEvent<HTMLInputElement>) => {
        const newValue = e.target.value;
        setFilterLocalizador(newValue);
    }

    const handleChangeStatusReserve = (e: React.ChangeEvent<HTMLInputElement>) => {
        const newValue = e.target.value;
        setFilterStatusReserve(newValue);
    }

    const handleChangeStatusPagamento = (e: React.ChangeEvent<HTMLInputElement>) => {
        const newValue = e.target.value;
        setFilterStatusPagamento(newValue);
    }

    const handleChangeStatusFormaPagamento = (e: React.ChangeEvent<HTMLInputElement>) => {
        const newValue = e.target.value;
        setFilterStatusFormaPagamento(newValue);
        setFilterSupplier('');
        setFilterAdquirente(null);
        setFilterToken('');
    }

    // const handleChangeSupplier = (e: React.ChangeEvent<HTMLInputElement>) => {
    //     const newValue = e.target.value;
    //     setFilterSupplier(newValue);
    // }

    // const handleChangeAdquirente = (e: React.ChangeEvent<HTMLInputElement>) => {
    //     const newValue = e.target.value;
    //     setFilterAdquirente(newValue);
    // }

    const handleChangeToken = (e: React.ChangeEvent<HTMLInputElement>) => {
        const newValue = e.target.value;
        setFilterToken(newValue);
    }

    //const handleChangeAgent = (e: React.ChangeEvent<HTMLInputElement>) => {
    //    const newValue = e.target.value;
    //    setFilterAgent(newValue);
    //}

    const handleChangeAgent = (newValue: any) => {
        setFilterAgent(newValue);
        setFilterIdDecolarFenix('');
    }

    const handleChangeRefAgent = (e: React.ChangeEvent<HTMLInputElement>) => {
        const newValue = e.target.value;
        setFilterRefAgent(newValue);
    }

    const handleChangeClient = (e: React.ChangeEvent<HTMLInputElement>) => {
        const newValue = e.target.value;
        setFilterClient(newValue);
    }

    const handleChangeTypeDate = (e: React.ChangeEvent<HTMLInputElement>) => {
        const newValue = e.target.value;
        setFilterTypeDate(newValue);
    }

    const handleChangeIdDecolarFenix  = (e: React.ChangeEvent<HTMLInputElement>) => {
        const newValue = e.target.value;
        setFilterIdDecolarFenix(newValue);
    }

    const handleChangeStatusErp  = (e: React.ChangeEvent<HTMLInputElement>) => {
        const newValue = e.target.value;
        setFilterStatusErp(newValue);
    }

    

    return (
        <div className="suppliers-filter d-flex justify-content-center bg-white mt-4" style={{ textAlign: "left" }}>
            <Accordion defaultActiveKey="1">
                <Accordion.Item eventKey="0">
                    <Accordion.Header><FontAwesomeIcon icon={['fal', 'sliders-h']} size="lg" className="mr-2" /><span className="h5 mb-0">Buscar Contas</span></Accordion.Header>
                    <Accordion.Body className="pt-0">
                        <Container className="p-0" fluid>
                            <Row>
                                <div>
                                    <Form >
                                        <Row className="mb-3">
                                            {alert}
                                            <Form.Group as={Col} md="2">
                                                <Form.Label>Localizador</Form.Label>
                                                <Form.Control
                                                    type="text"
                                                    autoComplete='off'
                                                    onChange={handleChangeLocalizador}
                                                />
                                                {/* <Form.Control.Feedback>Looks good!</Form.Control.Feedback> */}
                                            </Form.Group>
                                            <Form.Group as={Col} md="2">
                                                <Form.Label>Status Reserva</Form.Label>
                                                <div className="endpoint">
                                                    <InputGroup hasValidation>
                                                        <Form.Select
                                                        autoComplete='off'
                                                            aria-describedby="inputGroupPrepend"
                                                            onChange={(e: any) => handleChangeStatusReserve(e)}
                                                            value={filterStatusReserve}
                                                        >
                                                            <option value="">Todos</option>
                                                            <option value="1">Confirmada</option>
                                                            <option value="2">Cancelada</option>
                                                            <option value="7">Pré Reserva</option>
                                                        </Form.Select>
                                                        {/* <Form.Control.Feedback type="invalid">
                                                        Please choose a username.
                                                    </Form.Control.Feedback> */}
                                                    </InputGroup>
                                                </div>
                                                {/* <Form.Control.Feedback>Looks good!</Form.Control.Feedback> */}
                                            </Form.Group>
                                            <Form.Group as={Col} md="2">
                                                <Form.Label>Status Pagamento</Form.Label>
                                                <div className="endpoint">
                                                    <InputGroup hasValidation>
                                                        <Form.Select
                                                        autoComplete='off'
                                                            aria-describedby="inputGroupPrepend"
                                                            onChange={(e: any) => handleChangeStatusPagamento(e)}
                                                            value={filterStatusPagamento}
                                                        >
                                                            <option value="">Todos</option>
                                                            <option value="1">Pendente</option>
                                                            <option value="2">Conciliado</option>
                                                            <option value="3">Faturado</option>
                                                            <option value="4">Recebido</option>
                                                        </Form.Select>
                                                        {/* <Form.Control.Feedback type="invalid">
                                                        Please choose a username.
                                                    </Form.Control.Feedback> */}
                                                    </InputGroup>
                                                </div>
                                            </Form.Group>
                                            <Form.Group as={Col} md="2">
                                                <Form.Label>Forma de Pagamento</Form.Label>
                                                <div className="endpoint">
                                                    <InputGroup hasValidation>
                                                        <Form.Select
                                                        autoComplete='off'
                                                            aria-describedby="inputGroupPrepend"
                                                            onChange={(e: any) => handleChangeStatusFormaPagamento(e)}
                                                            value={filterStatusFormaPagamento}
                                                        >
                                                            <option value="">Todos</option>
                                                            <option value="1">Cash</option>
                                                            <option value="2">PIX</option>
                                                            <option value="3">Transferência</option>
                                                            <option value="4">Cartão</option>
                                                            <option value="5">Faturado</option>
                                                            <option value="6">Comissão a Receber</option>
                                                            <option value="7">PicPay</option>
                                                        </Form.Select>
                                                        {/* <Form.Control.Feedback type="invalid">
                                                        Please choose a username.
                                                    </Form.Control.Feedback> */}
                                                    </InputGroup>
                                                </div>
                                            </Form.Group>
                                            {
                                                filterStatusFormaPagamento === "1" ||
                                                filterStatusFormaPagamento === "2" 
                                                    ?
                                                    <>
                                                        <Form.Group as={Col} md="2">
                                                            <Form.Label>Adquirente</Form.Label>
                                                            <Controller
                                                                control={control}
                                                                name="adquirente"
                                                                render={({ field }: any) => (
                                                                    <GetAccountsByTypeFilter propsField={field} propsErrors={errors} propsLabel="Adquirentes" contaAdquirente={1} tipoConta={2} setAccount={setFilterAdquirente} />
                                                                )}
                                                            />
                                                        </Form.Group>
                                                    </>
                                                    :
                                                    filterStatusFormaPagamento === "3"
                                                    ?
                                                    <>
                                                        <Form.Group as={Col} md="2">
                                                            <Form.Label>Adquirente</Form.Label>
                                                            <Controller
                                                                control={control}
                                                                name="adquirente"
                                                                render={({ field }: any) => (
                                                                    <GetAccountsByTypeFilter propsField={field} propsErrors={errors} propsLabel="Adquirentes" contaAdquirente={1} tipoConta={1} setAccount={setFilterAdquirente} />
                                                                )}
                                                            />
                                                        </Form.Group>
                                                    </>
                                                    :
                                                    filterStatusFormaPagamento === "4"
                                                    ?
                                                    <>
                                                        <Form.Group as={Col} md="2">
                                                            <Form.Label>Adquirente</Form.Label>
                                                            <Controller
                                                                control={control}
                                                                name="adquirente"
                                                                render={({ field }: any) => (
                                                                    <GetAccountsByTypeFilter propsField={field} propsErrors={errors} propsLabel="Adquirentes" contaAdquirente={2} tipoConta={0} setAccount={setFilterAdquirente} />
                                                                )}
                                                            />
                                                        </Form.Group>
                                                        <Form.Group as={Col} md="2">
                                                            <Form.Label>Token/NSU</Form.Label>
                                                            <Form.Control
                                                                type="text"
                                                                autoComplete='off'
                                                                onChange={handleChangeToken}
                                                            />
                                                        </Form.Group>
                                                    </>
                                                    :
                                                    filterStatusFormaPagamento === "5"
                                                    ?
                                                    <></>
                                                    :
                                                    filterStatusFormaPagamento === "6"
                                                    ?
                                                    <>
                                                        <Form.Group as={Col} md="2">
                                                            <Form.Label>Fornecedor</Form.Label>
                                                            <Controller
                                                                control={control}
                                                                name="fornecedor"
                                                                render={({ field }: any) => (
                                                                    <GetSuppliersIdentity propsField={field} propsErrors={errors} propsLabel="Fornecedores" setSupplier={setFilterSupplier} />
                                                                )}
                                                            />
                                                        </Form.Group>
                                                    </>
                                                    :
                                                    <></>
                                            }
                                        </Row>
                                        <Row className="mb-3">
                                            {alert}
                                            <Form.Group as={Col} md="2">
                                                <Form.Label>Agente</Form.Label>
                                                {level === "29" ?
                                                    <Form.Control
                                                    type="text"
                                                    autoComplete='off'
                                                    disabled
                                                />
                                                    :

                                                    <Controller
                                                        control={control}
                                                        name="agente"
                                                        render={({ field }: any) => (
                                                            <GetAgents propsField={field} propsErrors={errors} propsLabel="Agentes" actionAgents={handleChangeAgent} />
                                                        )}
                                                    />
                                                }
                                            </Form.Group>
                                            <Form.Group as={Col} md="2">
                                                <Form.Label>Referência do Agente</Form.Label>
                                                <Form.Control
                                                    type="text"
                                                    autoComplete='off'
                                                    onChange={handleChangeRefAgent}
                                                />
                                            </Form.Group>
                                            <Form.Group as={Col} md="2">
                                                <Form.Label>Cliente</Form.Label>
                                                <Form.Control
                                                    type="text"
                                                    autoComplete='off'
                                                    onChange={handleChangeClient}
                                                />
                                            </Form.Group>
                                            <Form.Group as={Col} md="2">
                                                <Form.Label>Tipo de Data</Form.Label>
                                                <div className="endpoint">
                                                    <InputGroup hasValidation>
                                                        <Form.Select
                                                        autoComplete='off'
                                                            aria-describedby="inputGroupPrepend"
                                                            onChange={(e: any) => handleChangeTypeDate(e)}
                                                            value={filterTypeDate}
                                                        >
                                                            
                                                            <option value="3">Vencimento</option>
                                                            <option value="2">Operação</option>
                                                            <option value="1">Reserva</option>
                                                        </Form.Select>
                                                    </InputGroup>
                                                </div>
                                            </Form.Group>
                                            <Form.Group as={Col} md="2">
                                                <Form.Label>Data</Form.Label>
                                                <InputGroup hasValidation className="ranger-calendar">
                                                    <RangeCalendar date={filterDate} setDate={setFilterDate} />
                                                </InputGroup>
                                            </Form.Group>
                                            <Form.Group as={Col} md="2">
                                                <Form.Label>Status ERP</Form.Label>
                                                <div className="endpoint">
                                                    <InputGroup hasValidation>
                                                        <Form.Select
                                                        autoComplete='off'
                                                            aria-describedby="inputGroupPrepend"
                                                            onChange={(e: any) => handleChangeStatusErp(e)}
                                                            value={filterStatusErp}
                                                        >
                                                            
                                                            <option value="">Todos</option>
                                                            <option value="1">Pendente</option>
                                                            <option value="2">Exportado</option>
                                                        </Form.Select>
                                                    </InputGroup>
                                                </div>
                                            </Form.Group>
                                        </Row>
                                        {filterAgent === 'CGS3683' ?
                                        <Row className="mb-3">
                                            <Form.Group as={Col} md="2">
                                                <Form.Label>Id Fênix Decolar</Form.Label>
                                                <Form.Control
                                                    type="text"
                                                    autoComplete='off'
                                                    onChange={handleChangeIdDecolarFenix}
                                                />
                                            </Form.Group>
                                        </Row>
                                        : <></> }
                                        <Row as={Col} md="12" className="mt-5 justify-content-md-end">
                                            <Col xs lg="12" className="d-flex justify-content-md-end">
                                                <Button className="btn-default mr-2 text-uppercase" onClick={handleFilterErpClick}>Filtro ERP</Button>
                                                <Button variant="outline-primary" className="btn-default mr-2 text-uppercase" onClick={handleClearClick}>{t('partners.partnersfilter.btnClear')}</Button>
                                                <Button className="btn-default text-uppercase" onClick={handleFilterClick}>{t('partners.partnersfilter.btnSearch')}</Button>
                                            </Col>
                                        </Row>
                                    </Form>
                                </div>
                            </Row>
                        </Container>
                    </Accordion.Body>
                </Accordion.Item>
            </Accordion>
            <ModalQuestion
                modalShow={dateIntervalConfirmModalShow}
                setModalShow={setDateIntervalConfirmModalShow}
                titulo=""
                mensagem="Intervalo de data excede 3 meses. Continua?"
                handleActionConfirmModal={handleActionBuscar}
            />
        </div>
    )
}

export default FilterBillsReceive;