/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useEffect, useState } from "react";
import { Button, Card, Col, Modal, Nav, Row, Tab } from "react-bootstrap";

import Breadcrumb from "../../components/Breadcrumb/Breadcrumb";
import DesktopDefault from "../../templates/DesktopDefault";
import AddBannerModal from "./components/AddBannerModal/AddBannerModal";
import "./AddBanners.scss";
import api from "../../services/api";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import BRFlag from "../../assets/icons/brazil.svg";
import ESFlag from "../../assets/icons/spain.svg";
import ENFlag from "../../assets/icons/united-kingdom.svg";
import SortableList, { SortableItem } from "react-easy-sort";
import arrayMove from "array-move";
import ModalQuestion from "../../components/Modal/ModalQuestion/ModalQuestion";
import ModalShow from "../../components/Modal/ModalShow/ModalShow";

export interface propEdit { }

const AddBanners: React.FC<propEdit> = (propEdit) => {
  const [loading, setLoading] = useState<any>(false);
  const [modalAddBannersShow, setModalAddBannersShow] = useState<any>(false);
  const [bannerObj, setBannerObj] = useState<any>([]);
  const [bannerObjEn, setBannerObjEn] = useState<any>([]);
  const [bannerObjEs, setBannerObjEs] = useState<any>([]);
  const [bannerObjDrag, setBannerObjDrag] = useState<any>([]);
  const [bannerListSize, setBannerListSize] = useState<number>(0);
  const [url, setUrl] = useState<string>('');
  const [bannerControl, setBannerControl] = useState<string>('pt');
  const [allowUpdate, setAllowUpdate] = useState<boolean>(false);
  const [selectedBanner, setSelectedBanner] = useState<any>(null);

  const [modalShow, setModalShow] = useState<boolean>(false);
  const [modalLog, setModalLog] = useState<any>(null);
  const [modalMessage, setModalMessage] = useState<string>("Carregando");
  const [allowCloseModal, setAllowCloseModal] = useState<boolean>(false);

  const [modalQuestionShow, setModalQuestionShow] = useState<boolean>(false);
  const [bannerIdToExclude, setBannerIdToExclude] = useState<any>(null);

  const token = localStorage.getItem("GroupId") || "{}";
  const config = {
    headers: { Authorization: `Bearer ${token}` },
  };

  const addToBanner = (obj: any) => {
    if (bannerControl === 'pt') {
      setBannerObj([...bannerObj, obj]);
    } else if (bannerControl === 'en') {
      setBannerObjEn([...bannerObjEn, obj]);
    } else if (bannerControl === 'es') {
      setBannerObjEs([...bannerObjEs, obj]);
    }
  };

  async function listBanners() {
    setLoading(true);

    try {
      const { data } = await api.get(`${process.env.REACT_APP_SERVER_URL_API_NODE}/banners/${bannerControl}`, config);
      setBannerListSize(data.data.length);

      if (bannerControl === 'pt') {
        setBannerObj(data.data);
      } else if (bannerControl === 'en') {
        setBannerObjEn(data.data);
      } else if (bannerControl === 'es') {
        setBannerObjEs(data.data);
      }
      setLoading(false);
      //setUrl(data.data.data.url);

    } catch (error: any) {
      setLoading(false);
    }
  }

  useEffect(() => {
    listBanners();
    setAllowUpdate(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [bannerControl]);

  useEffect(() => { //atualiza a página assim que o modal de insert/edit é fechado
    if (!modalAddBannersShow) {
      listBanners();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [modalAddBannersShow]);

  useEffect(() => { //atualiza a página assim que o modal de deletar é fechado
    if (!modalShow && allowCloseModal) {
      setAllowCloseModal(false);
      setModalQuestionShow(false);
      listBanners();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [modalShow]);

  const handlerTab = (lang: string) => {
    setBannerControl(lang);
  }

  const onSortEnd = (oldIndex: number, newIndex: number) => {
    setAllowUpdate(true);
    if (bannerControl === 'pt') {
      setBannerObj((array: any) => arrayMove(array, oldIndex, newIndex));
    } else if (bannerControl === 'en') {
      setBannerObjEn((array: any) => arrayMove(array, oldIndex, newIndex));
    } else if (bannerControl === 'es') {
      setBannerObjEs((array: any) => arrayMove(array, oldIndex, newIndex));
    }
  };

  const deletePhoto: any = (id: any) => {
    setModalShow(true);
    const token = localStorage.getItem("GroupId") || "{}";
    const config = {
      headers: { Authorization: `Bearer ${token}` },
    };
    async function deleteBanner() {
      try {
        const res = await api.delete(`${process.env.REACT_APP_SERVER_URL_API_NODE}/banner/${bannerControl}/${id}`, config);

        if (res.status === 200) {
          setModalShow(true);
          setModalLog(0);
          setModalMessage(res.data.message);
          setAllowCloseModal(true);
        } else {
          setModalShow(true);
          setModalLog(1);
          setModalMessage("Erro ao processar operação!")
        }
      } catch (error: any) {
        setModalShow(true);
        setModalLog(1);
        setModalMessage("Erro ao processar operação!")
      }
    }

    deleteBanner();
  }

  const submitChangeOrder = () => {
    setModalShow(true);
    let selectedBanner: any = (bannerControl === 'pt' ? bannerObj :
      bannerControl === 'en' ? bannerObjEn :
        bannerControl === 'es' ? bannerObjEs : []).map((elem: any, index: any) => { return ({ id: elem.id, order: index + 1 }) })

    const token = localStorage.getItem("GroupId") || "{}";
    const config = {
      headers: { Authorization: `Bearer ${token}` },
    };
    async function deleteBanner() {
      try {
        const res = await api.post(`${process.env.REACT_APP_SERVER_URL_API_NODE}/banners_order/${bannerControl}`, selectedBanner, config);

        if (res.status === 200) {

          setModalLog(0);
          setModalMessage(res.data.message);
          setAllowCloseModal(true);
          setAllowUpdate(false);
        } else {
          setModalLog(1);
          setModalMessage("Erro ao processar operação!")
        }
      } catch (error: any) {
        setModalLog(1);
        setModalMessage("Erro ao processar operação!")
      }
    }

    deleteBanner();
  }

  return (
    <>
      <DesktopDefault>
        <div className="container-fluid content-dashboard">
          <Breadcrumb title={"Banners / Adicionar Banners"} />

          <div>
            <div className="mt-4 d-flex justify-content-between">
              <h2 className="title" style={{ color: "#707070" }}>
                Adicionar Banners
              </h2>
            </div>

          </div>
          <div className="add-banners-tab">
            <Tab.Container id="left-tabs-example" activeKey={bannerControl} /* onSelect={bannerControl} */>
              <div className='bg-custom-primary'>
                <Nav variant="pills" className="d-flex align-items-center pt-4 pt-md-2 gap-1" style={{ flexWrap: "inherit" }}>
                  Escolha o idioma
                  <Nav.Item className=''>
                    <Nav.Link eventKey="pt" onClick={() => { handlerTab('pt') }}>
                      <img
                        loading='lazy'
                        id="port-lang"
                        alt="bandeira do brasil"
                        src={BRFlag}
                        style={{ width: "60px", height: "40px" }}
                      />
                    </Nav.Link>
                  </Nav.Item>
                  <Nav.Item className=''>
                    <Nav.Link eventKey="en" onClick={() => { handlerTab('en') }}>
                      <img
                        loading='lazy'
                        id="esp-lang"
                        alt="bandeira da espanha"
                        src={ENFlag}
                        style={{ width: "60px", height: "40px" }}
                      />
                    </Nav.Link>
                  </Nav.Item>
                  <Nav.Item className=''>
                    <Nav.Link eventKey="es" onClick={() => { handlerTab('es') }}>
                      <img
                        loading='lazy'
                        id="ingl-lang"
                        alt="bandeira do reino unido"
                        src={ESFlag}
                        style={{ width: "60px", height: "40px" }}
                      />
                    </Nav.Link>
                  </Nav.Item>
                </Nav>
              </div>
            </Tab.Container>
          </div>

          <Card className="bg-white mt-4">
            <Row className="all-banners m-0 p-2">
              {loading
                ?
                <>
                  <div className="text-center">
                    <div className="load-big"></div>
                  </div>
                </>
                :
                <>
                  <SortableList
                    onSortEnd={onSortEnd}
                    className="list d-flex col-12 p-0"
                    draggedItemClassName="dragged"
                  >
                    {(bannerControl === 'pt' ? bannerObj :
                      bannerControl === 'en' ? bannerObjEn :
                        bannerControl === 'es' ? bannerObjEs : []).map((bannerElem: any, bannerIndex: number) => {
                          return (
                            <>
                              <div className="col-image-sortable">
                                <SortableItem key={bannerIndex}>
                                  <Col className="image"
                                    onClick={() => { setSelectedBanner(bannerElem); setModalAddBannersShow(true) }}
                                    style={{ background: `url('${bannerElem.image}')` }}>
                                    <div className="d-flex justify-content-center align-items-center w-100 h-100 position-relative">
                                      <span className={`badge rounded position-absolute py-2 px-5 text-uppercase ${bannerElem.status ? "badge-ativo" : "badge-inativo"}`} style={{ top: '5px', left: '5px', cursor: 'default' }}>
                                        {bannerElem.status ? "Ativo" : "Inativo"}
                                      </span>
                                      <span className='btn trash' onClick={(e: any) => { e.stopPropagation(); setBannerIdToExclude(bannerElem.id); setModalQuestionShow(true); }}>
                                        <FontAwesomeIcon
                                          size="lg"
                                          icon={["fal", "trash-alt"]}
                                          style={{ color: "#707070" }}
                                          title="Excluir"
                                        />
                                      </span>
                                      <span className="badge rounded position-absolute badge-white py-2 px-3 text-uppercase" style={{ bottom: '5px', left: '5px', cursor: 'default' }}>
                                        {`${bannerElem.dateStart.split('T')[0].split('-').reverse().join('/')} - ${bannerElem.dateEnd.split('T')[0].split('-').reverse().join('/')}`}
                                      </span>
                                    </div>
                                  </Col>
                                </SortableItem>
                              </div>
                            </>
                          )
                        })}

                    <div className="col-image-sortable">
                      <Col className="plus d-flex justify-content-center align-items-center" onClick={() => { setSelectedBanner(null); setModalAddBannersShow(true) }}>
                        <FontAwesomeIcon
                          size="lg"
                          icon={["fal", "plus"]}
                          style={{ color: "#707070", margin: "0 12px" }}
                        />
                      </Col>
                    </div>
                  </SortableList>
                </>
              }

              <Modal
                className="modalAuth"
                show={modalAddBannersShow}
                onHide={() => {
                  setModalLog(null);
                  setModalMessage("Carregando");
                  setModalAddBannersShow(false);
                }}
                size="xl"
                aria-labelledby="contained-modal-title-vcenter"
                centered
              >
                <AddBannerModal
                  setModalShow={setModalAddBannersShow}
                  isEdit={selectedBanner !== null}
                  infoEdit={selectedBanner}
                  url={url}
                  language={bannerControl}
                  bannerListSize={bannerListSize}
                />
              </Modal>

              <ModalQuestion
                modalShow={modalQuestionShow}
                setModalShow={setModalQuestionShow}
                titulo="Deletar Banner"
                mensagem="Deseja deletar o banner selecionado?"
                handleActionConfirmModal={() => { deletePhoto(bannerIdToExclude); }}
                handleActionCancelModal={() => { setBannerIdToExclude(null); setModalQuestionShow(false); }}
              />

              <ModalShow
                modalShow={modalShow}
                setModalShow={setModalShow}
                modalMessage={modalMessage}
                setModalMessage={setModalMessage}
                modalLog={modalLog}
                setModalLog={setModalLog}
              />
            </Row>
          </Card>

          {allowUpdate &&
            <div className="h-100 d-flex justify-content-end align-items-end">
              <Row className="mx-0 mt-3">
                <Button
                  type="button"
                  onClick={() => { submitChangeOrder() }}
                  className="form-button"
                >
                  Salvar Ordem
                </Button>
              </Row>
            </div>
          }
        </div>
      </DesktopDefault>
    </>
  );
};
export default AddBanners;
