import React, { useState, useEffect } from 'react';
import api from "../../../../services/api";

import { useForm } from "react-hook-form";

import "react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css";
import "react-bootstrap-table-next/dist/react-bootstrap-table2.css";
import BootstrapTable from "react-bootstrap-table-next";

import Form from "react-bootstrap/Form";
import FormBillsToPay from '../../../Forms/FormBillsToPay/FormBillsToPay';

export interface propTableSettle {
    itemsToSettle: any;
    parameters: any;
}

const SettleBillsToPay: React.FC<propTableSettle> = ({
    itemsToSettle, parameters
}: propTableSettle) => {

    //const [modalShow, setModalShow] = useState<any>(false);
    const [modalMessage, setModalMessage] = useState<any>('');
    const [datapgto, setDatapgto] = useState<any>('');
    const [modalResponse, setModalResponse] = useState<any>(null);
    const [allowReload, setAllowReload] = useState<any>(false);

    const validated = false;

    const tourAuth: any = JSON.parse(localStorage.getItem('c2tourAuth') || '{}');

    const userName: any = tourAuth.user.nome;
    const userLastName: any = tourAuth.user.lastName;
    const uId: any = tourAuth.user.uId;

    function closeModal() {
        var closeBtn: any = document.getElementsByClassName("btn-close")[0];
        if (closeBtn !== undefined) {
            closeBtn.click();
        }
    }

    const {
        control,
        handleSubmit,
        formState: { errors },
    } = useForm();

    const rowClasses = (row: any, rowIndex: any) => {
        /* console.log(row, rowIndex) */
        if (row.statusPagamento === "Conciliado") {
            return 'border-analise';
        } else if (row.statusPagamento === "Pago") {
            return 'border-ativo';
        } else {
            return 'border-inativo';
        }
    };

    /*converte a data UTC para a data local do usuário*/
    /* function convertUTCDateToLocalDate(date: any) {
        var newDate = new Date(date.getTime() + date.getTimezoneOffset() * 60 * 1000);

        var offset = date.getTimezoneOffset() / 60;
        var hours = date.getHours();

        newDate.setHours(hours - offset);

        return newDate.toLocaleString().split(' ')[0];
    } */

    function convertDate(cell: any, row: any) {
        if (cell !== null) {
            //var date = convertUTCDateToLocalDate(new Date(cell));
            var aux: any = cell.split("T")[0].split("-");
            var date: any = `${aux[2]}/${aux[1]}/${aux[0]}`;


            return (
                <>
                    {date}
                </>
            );
        } else {
            return (
                <>
                </>
            );
        }
    }

    function convertValue(cell: any) {
        //console.log(Math.sign(cell))
        return (
            <>
                <span className={Math.sign(cell) === -1 ? "negative" : ""}>{cell.toFixed(2).replace(".", ",")}</span>
            </>
        )
    }

    function addStatus(cell: any, row: any) {
        return (
            <div
                className={
                    cell === "Conciliado"
                        ? "bg-analise"
                        : cell === "Pago"
                            ? "bg-ativo w-100"
                            : cell === "Pendente"
                                ? "bg-inativo"
                                : ""
                }
            >
                {cell}
            </div>
        );
    }

    const columns = [
        {
            dataField: "vencimento",
            text: "Vencimento",
            formatter: convertDate,
        },
        { dataField: "fornecedor", text: "Fornecedor" },
        {
            dataField: "dataOperacao",
            text: "Data Oper.",
            formatter: convertDate,
        },
        { dataField: "descricao", text: "Tour/Descrição" },
        { dataField: "contaContabil", text: "Categoria" },
        {
            dataField: "valor",
            text: "Valor",
            formatter: convertValue,
        },
        {
            dataField: "statusPagamento",
            text: "Status",
            formatter: addStatus,
        }
    ];

    const validaFornLiquidacao = () => {
        var fornecedorTmp = '';
        for (var i: any = 0; i < itemsToSettle.length; i++) {
            if(fornecedorTmp !== '' && itemsToSettle[i].fornecedor !== fornecedorTmp) {
                return true;
            }
            fornecedorTmp = itemsToSettle[i].fornecedor ;
        }
        return false;
    }

    const onSubmit = (data: any) => {
        var fixDate = datapgto.split('/');
        data.dataPgto = `${fixDate[2]}-${fixDate[1]}-${fixDate[0]}`;
        data.fornLiquidacao = data.fornLiquidacao ? data.fornLiquidacao.supplierId : 0;
        data.contaLiquidacao = data.contaLiquidacao ? data.contaLiquidacao.id : 0;

        var items: any = [];

        for (var i: any = 0; i < itemsToSettle.length; i++) {
            items.push(
                {
                    "contasaPagarId": itemsToSettle[i].contasaPagarId,
                    "id": itemsToSettle[i].manifestId,
                    "tipo": itemsToSettle[i].tipo
                }
            )
        }

        async function settleQuery() {
            const token = localStorage.getItem('GroupId') || '{}';
            const config = {
                headers: { 'Authorization': `Bearer ${token}`, 'Content-Type': 'application/json' },
            };

            try {
                const res = await api.post('/BillsPayable/SettleBills',
                    {
                        "parametros": parameters, // objeto com os parametros da consulta
                        "items": items, // array com os itens a serem liquidados
                        "fornLiquidacao": data.fornLiquidacao ? parseInt(data.fornLiquidacao) : 0, //
                        "descTaxa": data.descTaxa ? parseInt(data.descTaxa) : 0, // valor taxa desconto
                        "jurosMulta": data.jurosMulta ? parseInt(data.jurosMulta) : 0, // valor taxa juros
                        "contaLiquidacao": data.contaLiquidacao ? parseInt(data.contaLiquidacao) : 0, // conta liquidação
                        "dataPgto": data.dataPgto, // data do pagamento
                        "uid": uId,
                        "usuario": (userName !== null && userLastName !== null) ? `${userName} ${userLastName}` : userName
                    }, config
                );
                if (res.status !== 400) {
                    if (res.data.data.log === 0) {
                        setModalMessage(res.data.data.texto);
                        setModalResponse('success');
                        setAllowReload(true);
                    }
                    else if (res.data.data.log === 1) {
                        setModalMessage(res.data.data.texto);
                        setModalResponse('error');
                    }
                }
            } catch (error: any) {
                //
                
                if (error?.response?.status === 401) {
                    //window.location.href = window.location.origin + '/';
                }
            }
        }
        settleQuery();
    }

    useEffect(() => {
        if (modalResponse === null && allowReload === true) {
            window.location.reload();
        }
    }, [allowReload, /*  */modalResponse])

    return (
        <>
            <Form className="h-100" noValidate validated={validated} onSubmit={handleSubmit(onSubmit)} autoComplete="false">
                <FormBillsToPay control={control} errors={errors} datapgto={datapgto}  setDatapgto={setDatapgto} modalResponse={modalResponse} setModalResponse={setModalResponse} modalText={modalMessage} validaFornLiquidacao={validaFornLiquidacao} >
                    <div>
                        <BootstrapTable
                            bootstrap4
                            keyField="id"
                            data={itemsToSettle}
                            columns={columns}
                            rowClasses={rowClasses}
                            striped={true}
                        />
                    </div>
                    <div className='mt-4 d-flex justify-content-end'>
                        <p className="btn btn-default btn-outline-primary px-4 mr-3 text-uppercase my-0" onClick={closeModal}>
                            Cancelar
                        </p>
                        <button className="btn btn-default btn-primary px-4 text-uppercase" type="submit">
                            Confirmar
                        </button>
                    </div>
                </FormBillsToPay>
            </Form>

            {/* <Modal
                className="modal-custom modalAuth"
                show={modalShow}
                onHide={() => { setModalShow(false); setModalMessage('') }}
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
            >
                <>
                    <Modal.Header closeButton>

                    </Modal.Header>
                    <div className='d-flex w-100 h-100 justify-content-center py-5'>
                        <h4>{modalMessage}</h4>
                    </div>
                </>
            </Modal> */}
        </>
    )
}

export default SettleBillsToPay;