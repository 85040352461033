import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { GoogleMap, useJsApiLoader, Marker } from '@react-google-maps/api';
import React, { useState } from "react";
import { Button, Card, Col, Form, OverlayTrigger, Row, Tooltip } from "react-bootstrap";
import { Controller, useForm } from "react-hook-form";
import AutoComplete from "react-google-autocomplete";
import { geocodeByAddress, getLatLng } from 'react-google-places-autocomplete';

import { useAppSelector, useAppDispatch } from '../../../../store/hooks';
import { addToComboObject } from "../../../../store/combo";
import { ErrorMessage } from "@hookform/error-message";

export interface operation {
    action: any;
    isEdit: any;
    editFunction?: any;
};

const Location: React.FC<operation> = React.memo(({
    action, isEdit, editFunction = () => { }
}: operation) => {

    /* Controle Redux  */
    const dispatch = useAppDispatch();
    const reduxComboObject: any = JSON.parse(JSON.stringify(useAppSelector((state: any) => state.combo))).combo;
    /* Controle Redux  */
    //console.log(reduxComboObject)
    const [latitude, setLatitude] = useState<number>((reduxComboObject?.locationLat && Number(reduxComboObject.locationLat)) || -22.8238006);
    const [longetude, setLongetude] = useState<number>((reduxComboObject?.locationLong && Number(reduxComboObject.locationLong)) || -43.23319009999999);

    const [isValidLocal, setIsValidLocal] = useState<boolean>(reduxComboObject?.location !== undefined);
    const [isValidAddress, setIsValidAddress] = useState<boolean>(reduxComboObject?.address !== undefined);

    const { isLoaded } = useJsApiLoader({
        id: 'google-map-script',
        googleMapsApiKey: process.env.REACT_APP_GOOGLE_API || '',
        libraries: ['places']
    });

    const {
        control,
        handleSubmit,
        setValue,
        formState: { errors },
    } = useForm({});

    function LocationLat(lat: any) {
        setLatitude(lat);
    }

    function LocationLng(lng: any) {
        setLongetude(lng);
    }

    function handleLocation(e: any) {
        setIsValidLocal(false);
    }

    const onSubmit = (data: any) => {
        data.locationLat = latitude;
        data.locationLong = longetude;

        if ((isValidLocal === true && isValidAddress === true) || isEdit) {

            if(isEdit){
                editFunction(data);
            } else {
                dispatch(addToComboObject(JSON.stringify(data)));
                action("instructions");
            }
        }
    }

    return (
        <>
            {
                (isEdit === false || reduxComboObject?.name) ?
                    <Col md={9} lg={10}>
                        <Card className="pt-content h-100">
                            <Form className="d-flex flex-column justify-content-between h-100" noValidate validated={false} onSubmit={handleSubmit(onSubmit)}>
                                <div>
                                    <h3 className="text-primary pt-title mb-0">Localização</h3>

                                    <div className="pt-content-info" style={{ border: "none" }}>
                                        <Row>
                                            <Col md={6}>
                                                <div className="mb-4">
                                                    <p className="small mb-0">Descreva o produto de maneira clara e atraente.</p>
                                                    <p className="small mb-0">Acrescentar detalhes específicos, ajudando o viajante a entender o itinerário e ordem de atrações.</p>
                                                    <p className="small mb-0">Mais importante: faça-o divertido!</p>
                                                </div>
                                            </Col>
                                            <Col md={6}></Col>
                                            <Col md={6}>
                                                <Row>
                                                    {
                                                        isLoaded &&
                                                        <>
                                                            <Form.Group as={Col} md="12" controlId="" className="mt-4">
                                                                <Form.Label className="label-small">
                                                                    Cidade
                                                                    <OverlayTrigger
                                                                        overlay={
                                                                            <Tooltip id="tooltip">
                                                                                Pesquise e selecione usando os resultados da cidade do Google.
                                                                            </Tooltip>
                                                                        }>
                                                                        <span>
                                                                            <FontAwesomeIcon
                                                                                className="ml-2"
                                                                                icon={["fal", "question-circle"]}
                                                                            />
                                                                        </span>
                                                                    </OverlayTrigger>
                                                                </Form.Label>
                                                                <Controller
                                                                    control={control}
                                                                    name="location"
                                                                    rules={{ required: { value: false, message: 'Por favor, informe a cidade' } }}
                                                                    render={({ field }: any) => (
                                                                        <AutoComplete
                                                                            options={{
                                                                                types: ["(regions)"],
                                                                                fields: ["name"]
                                                                            }}
                                                                            defaultValue={reduxComboObject?.location || ''}
                                                                            //inputAutocompleteValue={pickupAddresses[elem.id]}
                                                                            placeholder=""
                                                                            apiKey={process.env.REACT_APP_GOOGLE_API}
                                                                            onChange={(e: any) => handleLocation(e.target.value)}
                                                                            onPlaceSelected={(place) => {
                                                                                setValue('location', place.name);
                                                                                setIsValidLocal(true);
                                                                                // eslint-disable-next-line no-lone-blocks
                                                                                {
                                                                                    geocodeByAddress(`${place.name}`)
                                                                                        .then(results => getLatLng(results[0]))
                                                                                        .then(({ lat, lng }) => {
                                                                                            LocationLat(lat);
                                                                                            LocationLng(lng);
                                                                                        }
                                                                                        );
                                                                                }
                                                                            }}
                                                                        />
                                                                    )}
                                                                />
                                                                {/* <ErrorMessage
                                                        errors={errors}
                                                        name="location"
                                                        render={({ message }) => <small style={{ color: "red" }}>{message}</small>}
                                                    /> */}
                                                                {
                                                                    isValidLocal === false
                                                                        ?
                                                                        <small style={{ color: "red" }}>Por favor, selecione uma cidade usando os resultados do Google.</small>
                                                                        :
                                                                        ""
                                                                }

                                                            </Form.Group>


                                                            <Form.Group as={Col} md="12" controlId="" className="mt-4">
                                                                <Form.Label className="label-small">
                                                                    Endereço
                                                                    <OverlayTrigger
                                                                        overlay={
                                                                            <Tooltip id="tooltip">
                                                                                Pesquise e selecione usando os resultados de endereços do Google.
                                                                            </Tooltip>
                                                                        }>
                                                                        <span>
                                                                            <FontAwesomeIcon
                                                                                className="ml-2"
                                                                                icon={["fal", "question-circle"]}
                                                                            />
                                                                        </span>
                                                                    </OverlayTrigger>
                                                                </Form.Label>
                                                                <Controller
                                                                    control={control}
                                                                    name="address"
                                                                    rules={{
                                                                        required: {
                                                                            value: false,
                                                                            message: "Por favor, informe esse campo",
                                                                        },
                                                                    }}
                                                                    render={({ field }: any) => (
                                                                        <>
                                                                            <AutoComplete
                                                                                {...field}
                                                                                options={{
                                                                                    types: ["address"],
                                                                                    fields: ["name"],
                                                                                }}
                                                                                defaultValue={reduxComboObject?.address || ''}
                                                                                placeholder=""
                                                                                apiKey={process.env.REACT_APP_GOOGLE_API}
                                                                                onPlaceSelected={(place: any) => {
                                                                                    setValue("address", place.name);
                                                                                    setIsValidAddress(true);
                                                                                }}
                                                                            />
                                                                            <ErrorMessage
                                                                                errors={errors}
                                                                                name="address"
                                                                                render={({ message }) => (
                                                                                    <small style={{ color: "red" }}>
                                                                                        {message}
                                                                                    </small>
                                                                                )}
                                                                            />
                                                                        </>
                                                                    )}
                                                                />
                                                                {/* <ErrorMessage
                                                        errors={errors}
                                                        name="location"
                                                        render={({ message }) => <small style={{ color: "red" }}>{message}</small>}
                                                    /> */}
                                                                {
                                                                    isValidAddress === false
                                                                        ?
                                                                        <small style={{ color: "red" }}>Por favor, selecione uma cidade usando os resultados do Google.</small>
                                                                        :
                                                                        ""
                                                                }

                                                            </Form.Group>


                                                        </>
                                                    }
                                                </Row>
                                            </Col>
                                            <Col md={6} className="pt-google-maps">
                                                {
                                                    isLoaded
                                                        ?
                                                        <GoogleMap
                                                            mapContainerStyle={{ width: "100%", height: "100%" }}
                                                            center={
                                                                {
                                                                    lat: latitude,
                                                                    lng: longetude
                                                                }
                                                            }
                                                            zoom={12}
                                                        >
                                                            <Marker
                                                                position={
                                                                    {
                                                                        lat: latitude,
                                                                        lng: longetude
                                                                    }
                                                                }
                                                            />
                                                        </GoogleMap>
                                                        :
                                                        <></>
                                                }
                                            </Col>
                                        </Row>
                                    </div>
                                </div>

                                <div className="d-flex justify-content-end align-items-end">
                                    {
                                        isEdit !== true
                                            ?
                                            <Row className="mx-0 mt-5">
                                                <Button
                                                    variant="outline-primary"
                                                    className="form-button mr-3"
                                                    onClick={() => action('photo')}
                                                >
                                                    Voltar
                                                </Button>
                                                <Button
                                                    type="submit"
                                                    className="form-button"
                                                >
                                                    Continuar
                                                </Button>
                                            </Row>
                                            :
                                            <Row className=" mx-0 mt-5">
                                                <Button
                                                    type="submit"
                                                    className="form-button"
                                                //onClick={() => action('meetingPoint')}
                                                >
                                                    Salvar
                                                </Button>
                                            </Row>
                                    }
                                </div>
                            </Form>
                        </Card>
                    </Col>
                    :
                    <>
                        <Col md={9} lg={10}>
                            <Card className="pt-content h-100">
                                <div className="loading-modal h-100 d-flex flex-column justify-content-center align-items-center">
                                    <div className="load mb-4"></div>
                                    <div>Carregando dados do produto</div>
                                </div>
                            </Card>
                        </Col>
                    </>
            }
        </>
    )
});

export default Location;