import React from 'react';
import DatePicker from "react-multi-date-picker";
import { useTranslation } from "react-i18next";
import configData from "../../../../config/config.json";

import '../../../../components/Calendar/Calendar.scss';

const CustomMultipleInput = ({ openCalendar, value, date, setValue, errors, startTimeLocal, nameForm }: any) => {
  //const { t } = useTranslation();
  // function convertDate(cell: any) {
  //   if (cell !== null && cell !== undefined) {
  //     var aux: any = cell.split("T")[0].split("-");
  //     var date: any = `${aux[2]}/${aux[1]}/${aux[0]}`;
  //     return date;
  //   }
  // }

  setValue(value);


  return (
    <input
      className={errors?.dataPgto ? "form-control bg-white frm-sm w-100 rounded endpoint-error" : "form-control bg-white frm-sm w-100 rounded"}
      onFocus={()=>{openCalendar();}}
      value={value}    
      name={nameForm === undefined ? '' : nameForm}  
      id={'singleCalendarId'}
      autoComplete='off'
    />
  )
}

const SingleCalendarStock = ({ date, setValue = () => { }, field, errors = undefined, startTimeLocal = undefined, startDate = undefined, endDate = undefined, nameForm = undefined }: any) => {
  const { t } = useTranslation();
  //const [aux, setAux] = useState<any>(date);  
  /* useEffect(()=>{
    setValue('date',aux);
  },[aux]) */


  return (
    <DatePicker
      {...field}
      value={new Date(startDate === undefined ? endDate : startDate)}
      //onChange={(e: any) => {setAux(e.year + "-" + e.month + "-" + e.day) }}
      readOnly={true}
      weekDays={t('calendar.weekDays').split(',')}
      months={t('calendar.months').split(',')}
      format={configData.DATE.PT}
      calendarPosition={`top`}
      
      minDate={startDate === undefined ? '' : startDate}
      maxDate={endDate === undefined ? '' : endDate}
      render={<CustomMultipleInput date={date} setValue={setValue} errors={errors} startTimeLocal={startTimeLocal} nameForm={nameForm} />}
    />
  )
}

export default SingleCalendarStock;