/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useState } from 'react';
import api from "../../../../services/api";

import { Row, Col, InputGroup } from "react-bootstrap";
import { Controller, useForm } from "react-hook-form";
import { ErrorMessage } from "@hookform/error-message";

import Form from "react-bootstrap/Form";

import ModalQuestion from '../../../../components/Modal/ModalQuestion/ModalQuestion';
import GetAccounts from '../../../../components/C2Points/GetAccounts';

export interface propInsert {
    show: any;
    setResponseText: any,
    setModalContent: any,
    setLog: any,
    totalReceber : any, 
    dataPgto: string, 
    apiSelect: number
}

const SettleInBatch: React.FC<propInsert> = ({
    show, setResponseText, setModalContent, setLog, totalReceber, dataPgto, apiSelect
}: propInsert) => {

    //const [date, setDate] = useState<any>('');
    const [changeValue, setChangeValue] = useState<boolean>(false);
    const [totalLiquidacao, setTotalLiquidacao] = useState<any>();
    const [loading, setLoading] = useState<any>(false);
    
    const [accountDestiny, setAccountDestiny] = useState<any>();

    const [settleConfirmationShow, setSettleConfirmationShow] = useState<boolean>(false);
    const [msgSettleConfirmation, setMsgSettleConfirmation] = useState<string>('');

    const validated = false;

    const tourAuth: any = JSON.parse(localStorage.getItem('c2tourAuth') || '{}');

    const userName: any = tourAuth.user.nome;
    const userLastName: any = tourAuth.user.lastName;
    const uId: any = tourAuth.user.uId;

    function formatValue(cell: any) {
        //console.log(Math.sign(cell))
        if(cell && cell !== '') {
          return cell.toFixed(2).replace(".", ",");
        } else {
          return '0,00';
        }
        
      }

    function closeModal() {
        var closeBtn: any = document.getElementsByClassName("btn-close")[0];
        if (closeBtn !== undefined) {
            closeBtn.click();
        }
    }

    const {
        control,
        handleSubmit,
        formState: { errors },
    } = useForm();

    const fixDate = (data: any) => {
        var aux = data.split('/');

        return (`${aux[2]}-${aux[1]}-${aux[0]}`);
    }

    function verifyDate(now: any, due: any) {
        var nowArray: any = now.split('-');
        var dueArray: any = due.split('-');

        if (parseInt(dueArray[0]) < parseInt(nowArray[0])) {
            return false;
        }
        if (parseInt(dueArray[1]) < parseInt(nowArray[1])) {
            return false;
        }
        if (parseInt(dueArray[2]) < parseInt(nowArray[2])) {
            return false;
        }
        return true;
    }

    const onSubmit = (data: any) => {
        
        // data.dataPgto = dataPgto;
        // data.api = apiSelect;
        // data.contaDestino = accountDestiny;
        const valorLiq = changeValue ? parseFloat( data.valor ?  data.valor.replace(',','.') : 0 ) : totalReceber;

        setTotalLiquidacao(valorLiq);

        setMsgSettleConfirmation(`Valor a ser liquidado é de ${valorLiq.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })}. Confirma?`);

        setSettleConfirmationShow(true);
    }

    const onSubmitOk = () => {
        setSettleConfirmationShow(false);
        setLoading(true);

        var fixDate = dataPgto.split('/');

        setModalContent("loading");
        show(true);
        //if (verifyDate(data.data, data.vencimento)) {
            const insertConta = async () => {
                const token = localStorage.getItem('GroupId') || '{}';
                const config = {
                    headers: { 'Authorization': `Bearer ${token}`, 'Content-Type': 'application/json' },
                };
                try {
                    const res = await api.post(`/CardConciliation/SettleConciliationInBatch`,
                        {
                            "dataPgto": `${fixDate[2]}-${fixDate[1]}-${fixDate[0]}`,
                            "totalLiquidacao": totalLiquidacao,
                            "contaLiquidacao": accountDestiny,
                            "api": apiSelect,
                            "uid": uId,
                            "usuario": `${userName} ${userLastName}`
                        }, config);
                    if (res.status !== 400) {
                        setResponseText(res.data.data.texto);
                        setLog(res.data.data.log);
                        setModalContent("success");
                        console.log(res)
                    } else {
                        setResponseText(res.data.data.texto);
                        setLog(res.data.data.log);
                        setModalContent("error");
                    }
                    setLoading(false);
                    closeModal();
                    show(true);
                } catch (error: any) {
                    setLoading(false);
                    setResponseText('Erro ao liquidar.');
                    setLog(1);
                    setModalContent("error");
                    
                }
            };

            insertConta();
        // } else {
        //     setModalContent("error");
        //     setResponseText("Data de vencimento antes da data atual.");
        //     setLoading(false);
        // }
            
    }

    return (
        <>
            <Form className="h-100" noValidate validated={validated} onSubmit={handleSubmit(onSubmit)} autoComplete="false">
                <div className='d-flex flex-column justify-content-between h-100'>
                    <div>
                        {/* <Row className="mb-3">
                            <Form.Group as={Col} md={6}>
                                
                            </Form.Group>
                        </Row> */}
                        <Row className="mb-3">
                            <Form.Group as={Col} md={4} controlId="formGridCat">
                                <Form.Label style={{ marginBottom: '0.4rem' }}>
                                    {/* Valor a ser liquidado */}
                                    <Controller
                                        control={control}
                                        name="changeValue"
                                        //rules={{ required: { value: true, message: "required" }, }}
                                        render={({ field }: any) => (
                                            <div className='d-flex align-items-center'>
                                                <Form.Check
                                                    {...field}
                                                    type="radio"
                                                    label="Alterar Valor a ser liquidado?"
                                                    name="formHorizontalRadios"
                                                    id="formHorizontalRadios3"
                                                    onClick={() => { setChangeValue(!changeValue) }}
                                                    checked={changeValue}
                                                />
                                            </div>
                                        )}
                                    />
                                </Form.Label>
                                {/* <Form.Control type="text" /> */}
                                {changeValue === true ?
                                <>
                                    <Controller
                                        control={control}
                                        name="valor"
                                        rules={{ required: { value: true, message: "required" }, }}
                                        defaultValue={formatValue(totalReceber)}
                                        render={({ field }: any) => (
                                            <Form.Control
                                                {...field}
                                                aria-invalid={errors?.valor ? "true" : ""}
                                                variant="standard"
                                                margin="normal"
                                                autoComplete="off"
                                                style={ { paddingTop: '0.25rem', paddingBottom: '0.25rem' } }
                                                // onKeyPress={(e) => !/[0-9]/.test(e.key) && e.preventDefault()}
                                            />
                                        )}
                                    />
                                    <ErrorMessage
                                        errors={errors}
                                        name="valor"
                                        render={({ message }) => <small style={{ color: "red" }}>{message}</small>}
                                    />
                                </>
                                :<input
                                    type="text"
                                    className="form-control"
                                    style={ { paddingTop: '0.25rem', paddingBottom: '0.25rem' } }
                                    disabled={true}
                                    value={formatValue(totalReceber)}
                                    autoComplete='off'
                                />}
                            </Form.Group>
                            <Form.Group as={Col} md="5">
                                <Form.Label>Conta Liquidação</Form.Label>
                                
                                <Controller
                                    control={control}
                                    name="accountDestiny"
                                    rules={{ required: { value: accountDestiny ? false : true, message: "required" }, }}
                                    //defaultValue={categoria?.id}
                                    defaultValue={accountDestiny}
                                    render={({ field }: any) => (
                                        <GetAccounts propsField={field} propsLabel={"Conta Liquidação"} propsErrors={errors} setAccount={setAccountDestiny} />
                                    )}
                                />
                                <ErrorMessage 
                                    errors={errors}
                                    name="accountDestiny"
                                    render={({ message }) => <small style={{ color: "red" }}>{message}</small>}
                                />
                            </Form.Group>
                        </Row>

                    </div>
                    <div className='mt-4 d-flex justify-content-end'>
                        <button
                            className="btn btn-default btn-outline-primary px-4 mr-3 text-uppercase"
                            type="button"
                            onClick={closeModal}
                        >
                            Cancelar
                        </button>
                        <button
                            className="btn btn-default btn-primary px-4 text-uppercase"
                            type="submit"
/*                             onClick={() => {
                                setLoading(true)
                            }} */
                        >
                            {
                                loading === true
                                    ?
                                    <div className="load"></div>
                                    :
                                    "Liquidar"
                            }
                        </button>
                    </div>
                </div>
            </Form>

            <ModalQuestion
              modalShow={settleConfirmationShow}
              setModalShow={setSettleConfirmationShow}
              titulo="Liquidar em Lote"
              mensagem={msgSettleConfirmation}
              handleActionConfirmModal={onSubmitOk}
            //handleActionCancelModal={handleActionCancelDeleteClick}
            /> 
        </>
    )
}

export default SettleInBatch;