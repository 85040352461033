import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Dropdown from "react-bootstrap/Dropdown";

import "react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css";
import "react-bootstrap-table-next/dist/react-bootstrap-table2.css";
import BootstrapTable from "react-bootstrap-table-next";
/* import paginationFactory from "react-bootstrap-table2-paginator";
import { Link } from "react-router-dom"; */

import Pagination from "../../../../components/Pagination/Pagination";

//STYLES
import "./MerchantsTable.scss";

export interface propTable {
  merchants: any;
  totalRows: any;
  pageCount: any;
  setPageCount: any;
  rowsPerPage: any;
  setRowsPerPage: any;
  loading: any;
}

const MerchantsTable: React.FC<propTable> = ({
  merchants, totalRows, pageCount, setPageCount, rowsPerPage, setRowsPerPage, loading
}: propTable) => {

  function addActionButton(cell: any, row: any) {
    return (
      <>
        <div className="d-flex justify-content-center">
          <Dropdown drop="start">
            <Dropdown.Toggle variant="light ">
              <FontAwesomeIcon
                size="lg"
                icon={["fal", "ellipsis-h"]}
              />
            </Dropdown.Toggle>

            <Dropdown.Menu>
              <Dropdown.Item
                eventKey="1"
                href={"/merchants/edit-merchants?id=" + cell}
              >
                <FontAwesomeIcon
                  icon={['fal', 'edit']}
                />
                Editar
              </Dropdown.Item>
              <Dropdown.Item
                eventKey="2"
                disabled={true}
              >
                <FontAwesomeIcon
                  icon={['fal', 'trash-alt']}
                />
                Remover
              </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
        </div>
      </>
    );
  }

  function addStatus(cell: any, row: any) {
    return (
      <div
        className={
          cell === "Ativo"
            ? "bg-ativo"
            : "bg-inativo"
        }
      >
        {cell}
      </div>
    );
  }

  /* ALTERAR OS DATAFIELDS QUANDO A API CHEGAR */

  const columns = [
    { dataField: "nomeFantasia", text: "Conta", sort: true },
    { dataField: "razaoSocial", text: "Razão Social", sort: true },
    { dataField: "cnpj", text: "CNPJ", sort: true },
    { dataField: "nomeGerente", text: "Gerente", sort: true },
    { dataField: "email", text: "E-Mail", sort: true },
    { dataField: "telefone", text: "Telefone", sort: true },
    {
      dataField: "status",
      text: "Status",
      sort: true,
      formatter: addStatus,
    },
    { dataField: "id", text: "Ação", formatter: addActionButton },
  ];

  /* [END] ALTERAR OS DATAFIELDS QUANDO A API CHEGAR */

  /* loading table */

  function loadingFunc() {
    return (
      <>
        <div className="animated-background row-loading"></div>
      </>
    )
  }

  const loadingColumns = [{ dataField: "contasd", text: "Carregando Adquirentes", formatter: loadingFunc }];

  const loadingProducts = [
    { contas: "" }, { contas: "" }, { contas: "" }, { contas: "" }, { contas: "" },
    { contas: "" }, { contas: "" }, { contas: "" }, { contas: "" }, { contas: "" }
  ];
  /* [END] loading table */

  /* In case of empty table*/
  const notFoundColumns = [{ dataField: "clientes", text: "Clientes" }];

  const notFoundProducts = [{ clientes: "Nenhuma conta cadastrada" }];
  /* [END] In case of empty table*/

  const rowClasses = (row: any, rowIndex: any) => {
    //console.log(row, rowIndex)
    if (row.status === "Ativo") {
      return 'border-ativo';
    } else {
      return 'border-inativo';
    }
  };

  if (merchants !== null) {
    return (
      <>
        <div className="table-default merchants-table">
          <div className="table-container">
            <div className="table-title">
              <h3 className="">Adquirentes</h3>
            </div>

            <BootstrapTable
              bootstrap4
              keyField="id"
              data={merchants}
              columns={columns}
              rowClasses={rowClasses}
              striped={true}
            />

            <Pagination
              totalRows={totalRows}
              pageCount={pageCount}
              setPageCount={setPageCount}
              rowsPerPage={rowsPerPage}
              setRowsPerPage={setRowsPerPage}
              selectNumberRows="yes"
            />
          </div>
        </div>
      </>
    );
  } else if (merchants === null && loading === true) {
    return (
      <>
        <div className="table-default merchants-table loading not-found">
          <div className="table-container">
            <BootstrapTable
              bootstrap4
              keyField="id"
              data={loadingProducts}
              hover={true}
              columns={loadingColumns}
              striped={true}
            />
          </div>
        </div>
      </>
    )
  } else {
    return (
      <>
        <div className="table-default merchants-table not-found">
          <div className="table-container">
            <div className="table-title">
              <h3 className="">Adquirentes</h3>
            </div>

            <BootstrapTable
              bootstrap4
              keyField="id"
              data={notFoundProducts}
              hover={true}
              columns={notFoundColumns}
              striped={true}
            />
          </div>
        </div>
      </>
    );
  }
}

export default MerchantsTable;
