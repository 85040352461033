import React, { useEffect, useState } from "react";

import "./Features.scss"
import { Button, Col, Form, InputGroup, Row } from "react-bootstrap";
import { Controller, useForm } from "react-hook-form";
import { ErrorMessage } from "@hookform/error-message";
import OnlyTime from "../../../../components/Calendar/OnlyTime";

export interface productTransfer {
    action: any,
    receiveObject: any,
    transferObject: any,
    isEdit: any
};

const Features: React.FC<productTransfer> = React.memo(({ action, receiveObject, transferObject, isEdit
}: productTransfer) => {

    const {
        control,
        handleSubmit,
        setValue,        
        formState: { errors },
    } = useForm({});
      
  
    const [validated,] = useState(false);
    const [advancePurchase, setAdvancePurchase] = useState<any>();
    const [maxDuration, setMaxDuration] = useState<any>();
    const [awaitLimit, setAwaitLimit] = useState<any>();

    const onSubmit = (data: any) => {

        if (isEdit !== true) {
            action('contact', data);
        } else {
            receiveObject(data);
        }
    }    


    useEffect(() => {
        setValue("advancePurchase", advancePurchase || transferObject.advancePurchase);
        setValue("maxDuration", maxDuration || transferObject.maxDuration);
        setValue("awaitLimit", awaitLimit || transferObject.awaitLimit);

    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [advancePurchase, maxDuration, awaitLimit, setValue])


   

    return (
        <>
            {/* CONTENT */}
            <Col md={9} lg={10}>
                <Form className="h-100" noValidate validated={validated} onSubmit={handleSubmit(onSubmit)}>
                    <div className='card h-100 border-0 p-4'>
                        <div>
                            <h3 className="pt-1 text-primary title-content">{"Caracteristícas"}</h3>
                        </div>

                        <section className="">
                            <Row className="mb-3 mt-3">
                                <Form.Group as={Col} md="6" controlId="">
                                    <Form.Label className="label-small">
                                        Antecedência para a compra
                                    </Form.Label>
                                    <Controller
                                        control={control}
                                        name="advancePurchase"
                                        rules={{ required: { value: true, message: 'Por favor, preencha o campo' } }}
                                        render={({ field }: any) => (
                                            <InputGroup
                                                hasValidation
                                                className=""
                                                size="sm"
                                            >
                                                <OnlyTime
                                                    setTime={setAdvancePurchase}                                                    
                                                    defaultTime={`2022-08-19T${transferObject?.advancePurchase || "00:00"}`}
                                                />
                                            </InputGroup>
                                        )}
                                    />
                                    <ErrorMessage
                                        errors={errors}
                                        name="advancePurchase"
                                        render={({ message }) => <small style={{ color: "red" }}>{message}</small>}
                                    />
                                </Form.Group>

                                <Form.Group as={Col} md="6" controlId="">
                                    <Form.Label className="label-small">
                                        Duração máxima do trajeto
                                    </Form.Label>
                                    <Controller
                                        control={control}
                                        name="maxDuration"
                                        rules={{ required: { value: true, message: 'Por favor, preencha o campo' } }}
                                        render={({ field }: any) => (
                                            <InputGroup
                                                hasValidation
                                                className=""
                                                size="sm"
                                            >
                                                <OnlyTime
                                                    setTime={setMaxDuration}
                                                    defaultValue={transferObject.maxDuration}
                                                    defaultTime={`2022-08-19T${transferObject.maxDuration || "00:00"}`}
                                                />
                                            </InputGroup>
                                        )}
                                    />
                                    <ErrorMessage
                                        errors={errors}
                                        name="maxDuration"
                                        render={({ message }) => <small style={{ color: "red" }}>{message}</small>}
                                    />
                                </Form.Group>
                            </Row>

                            <Row>
                                <Form.Group as={Col} md="6" controlId="" className="mt-3">
                                    <Form.Label className="label-small">
                                        Limite de espera do cliente
                                    </Form.Label>
                                    <Controller
                                        control={control}
                                        name="awaitLimit"
                                        rules={{ required: { value: true, message: 'Por favor, preencha o campo' } }}
                                        render={({ field }: any) => (
                                            <InputGroup
                                                hasValidation
                                                className=""
                                                size="sm"
                                            >
                                                <OnlyTime
                                                    setTime={setAwaitLimit}                                                    
                                                    defaultTime={`2022-08-19T${transferObject.awaitLimit || "00:00"}`}
                                                />
                                            </InputGroup>
                                        )}
                                    />
                                    <ErrorMessage
                                        errors={errors}
                                        name="awaitLimit"
                                        render={({ message }) => <small style={{ color: "red" }}>{message}</small>}
                                    />
                                </Form.Group>
                            </Row>
                        </section>

                        <section className="h-100 d-flex justify-content-end align-items-end">
                            {
                                isEdit !== true
                                    ?
                                    <Row className="mx-0 mt-5">
                                        <Button
                                            variant="outline-primary"
                                            className="form-button mr-3"
                                            onClick={() => action('dayshours')}
                                        >
                                            Voltar
                                        </Button>
                                        <Button
                                            type="submit"
                                            className="form-button"
                                        >
                                            Continuar
                                        </Button>
                                    </Row>
                                    :
                                    <Row className=" mx-0 mt-5">
                                        <Button
                                            type="submit"
                                            className="form-button"
                                        //onClick={() => action('meetingPoint')}
                                        >
                                            Salvar
                                        </Button>
                                    </Row>
                            }
                        </section>
                    </div>
                </Form>
            </Col>

        </>
    )
});

export default Features;