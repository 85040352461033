import React, { useEffect, useState } from 'react';
import { Row } from 'react-bootstrap';

import { Chart } from "react-google-charts";

import "react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css";
import "react-bootstrap-table-next/dist/react-bootstrap-table2.css";
import BootstrapTable from "react-bootstrap-table-next";

export interface propInfo {
    info: any;
    loading: any;
    title: any;
    //minValue: any;
    //maxValue: any;
}

const SalesReport: React.FC<propInfo> = ({
    info, loading, title //, minValue, maxValue
}) => {
    // const color_primary: any = process.env.REACT_APP_COLOR_PRIMARY;
    // const color_secondary: any = process.env.REACT_APP_COLOR_SECONDARY;

    const [salesInfo, setSalesInfo] = useState<any>(null);

    useEffect(() => {
        if(info !== null) {

            const infoChart = info.map((i: any) => [i.descricao, i.share]);

            const dataVendas: any = [
                ["Descrição", "Share (%)"],
                ...infoChart
            ]

            setSalesInfo(dataVendas);
        }
    }, [info])

    /* function formatValue(cell: any) {
        //console.log(Math.sign(cell))
        if(cell && cell !== '') {
          return cell.toFixed(2).replace(".", ",");
        } else {
          return '0,00';
        }
    } */

    const optionsVendas = {
        //title: "Vendas Por Cliente",
        title: "",
        vAxis: { title: "", textStyle: {
            fontSize: 12 
          } },
        hAxis: { title: "", textStyle: {
            fontSize: 12 
          } },
        legend: { position: 'right' },
        //colors: ['#4473C4', '#ED7C31', '#A5A5A5'], // azul, vermelho, cinza
        //colors: ['#4ABA58', '#B54848', '#395B7B'], // verde, vermelho, azul
        chartArea: {width: '90%', height: '80%', right: 150 },
    };

    /* dados e funções da coluna  */

    /*converte a data UTC para a data local do usuário*/
    /* function convertUTCDateToLocalDate(date: any) {
        var newDate = new Date(date.getTime() + date.getTimezoneOffset() * 60 * 1000);

        //var offset = date.getTimezoneOffset() / 60;
        //var hours = date.getHours();

        //newDate.setHours(hours - offset);

        return newDate.toLocaleString().split(' ')[0];
    } */

    /* function convertDate(cell: any, row: any) {
        var date = (cell) ? convertUTCDateToLocalDate(new Date(cell)) : '';
        return (
          <>
            {date}
          </>
        );
    } */
    
    function convertValue(cell: any) {
        //console.log(Math.sign(cell))
        return (
          <>
            <span className={Math.sign(cell) === -1 ? "negative" : ""}>{cell.toFixed(2).replace(".", ",")}</span>
          </>
        )
    }

    const columns = [
        { dataField: "descricao", text: "Descrição" },
        { dataField: "valor", text: "Receita de Venda (R$)", formatter: convertValue },
        { dataField: "share", text: "Share (%)", formatter: convertValue },
    ];

    /*--------------------------------------------------*/

    /* In case of empty table*/
    /* const notFoundColumns = [{ dataField: "contas", text: "Relatórios" }];

    const notFoundProducts = [{ contas: "Nenhum relatório encontrado" }]; */
    /* [END] In case of empty table*/

    /* loading table */

    function loadingFunc() {
        return (
            <>
                <div className="animated-background row-loading"></div>
            </>
        )
    }

    const loadingColumns = [{ dataField: "contasd", text: "Carregando relatórios", formatter: loadingFunc }];

    const loadingProducts = [
        { contas: "" }, { contas: "" }, { contas: "" }, { contas: "" }, { contas: "" },
        { contas: "" }, { contas: "" }, { contas: "" }, { contas: "" }, { contas: "" }
    ];
    /* [END] loading table */
    /*[END] dados da coluna */

    const rowClasses = (row: any, rowIndex: any) => {
        return 'border-normal'
    };

    return (
        <>
            <Row >
                {info !== null && loading === false
                ?
                    <div className="table-default dailyreceipt-report-sales-table">
                        <div className="table-container">
                            <div className="d-flex justify-content-between">
                                <h4 className="p-0 m-0 pb-2" style={{ color: "#707070" }}>{title}</h4>
                            </div>
                            <Chart
                                chartType="PieChart"
                                width="100%"
                                height="250px"
                                data={salesInfo}
                                options={optionsVendas}
                                formatters={[
                                    {
                                        type: "NumberFormat",
                                        column: 1,
                                        options: { fractionDigits: 2, decimalSymbol: ',', groupingSymbol: '' }
                                    },
                                    ]}
                            />
                            <BootstrapTable
                                bootstrap4
                                keyField="id"
                                data={info}
                                columns={columns}
                                striped={true}
                                //pagination={paginationFactory(paginationOption)}
                                rowClasses={rowClasses}
                            />
                        </div>
                    </div>
                :
                    <div className="table-default dailyreceipt-report-sales-table loading not-found">
                        <div className="table-container">
                            <div className="d-flex justify-content-between">
                                <h4 className="p-0 m-0" style={{ color: "#707070" }}>{title}</h4>
                            </div>
                            <div className="animated-background row-loading" style={{ height: '250px' }}></div>
                            <BootstrapTable
                                bootstrap4
                                keyField="id"
                                data={loadingProducts}
                                hover={true}
                                columns={loadingColumns}
                                striped={true}
                                rowClasses={rowClasses}
                            />
                        </div>
                    </div>
                }
            </Row>
        </>

    );
}

export default SalesReport;