import React, { useState, useEffect } from "react";
import { Card, Container, Row } from 'react-bootstrap';

import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import Accordion from 'react-bootstrap/Accordion';
import { useForm } from "react-hook-form";

import { useTranslation } from "react-i18next";

import PartnersTableUsers from './PartnersTableUsers/PartnersTableUsers';

import '../../../../assets/sass/accordion.scss';

export interface propAccordion {
    action: any;
    ContextAwareToggle: any;
    setStepCtrl: any;
    setChangeEdit?: any;
    infoEdit?: any;
    isEdit?: any;
    editPartners?: any;
};

const AccordionUsers: React.FC<propAccordion> = ({
    action, ContextAwareToggle, setStepCtrl, setChangeEdit, infoEdit, isEdit = false, editPartners
}: propAccordion) => {

    const [ctrlEventKey, setCtrlEventKey] = useState<boolean>(false);
    const [emptyUserTable, setEmptyUserTable] = useState<boolean>(false);
    const [userObject, setUserObject] = useState<any>(isEdit === true ? infoEdit.usersSupplierDTO : []);
    const [userControlObject, setUserControlObject] = useState<any>([]);

    const { t } = useTranslation();
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const [validated, setValidated] = useState(false);

    const idPartner = window.location.href.split('id=')[1];

    const onSubmit = (data: any) => {
        /*         const form = data.currentTarget;
                if (form.checkValidity() === false) {
                    data.preventDefault();
                    data.stopPropagation();
                } else { */
        if (isEdit === false) {
            /// ADD SUPPLIERS PAGE
            if (userObject.length > 0) {
                setEmptyUserTable(false);
                setCtrlEventKey(true);
                action(userControlObject);
            } else {
                setEmptyUserTable(true);
            }

        } else {
            /// EDIT SUPPLIERS PAGE
            let edit: any = { ...infoEdit, ...data };
            edit.affiliateId = parseInt(idPartner);

            let contactObg = [];

            for (var i: any = 0; i < userObject.length; i++) {
                if (userObject[i].userId) {
                    contactObg[i] = {
                        userId: userObject[i].userId,
                        nivel: userObject[i].nivelInfo
                    };
                } else {
                    contactObg[i] = {
                        userId: userObject[i].id,
                        nivel: userObject[i].nivelInfo
                    };
                }
            }

            edit.usersSupplierModel = contactObg;
            edit.usersSupplierDTO = contactObg;
            edit.supplierContactModel = edit.affiliateContact;
            edit.costCenterSupplierModel = edit.costCenterSupplierDTO;

            if (edit.masterFilho === null) {
                edit.masterFilho = '0';
            }

            editPartners(edit);
        }
        /* }
        setValidated(true); */
    };

    useEffect(() => {
        console.log(userObject);

        const ctrlUsersSupplierModel = {
            usersSupplierModel: userObject.map(({ id: userId, nivelInfo: nivel }: any) => ({ userId, nivel })),
            usersSupplierDTO: userObject.map(({ id: userId, nivelInfo: nivel }: any) => ({ userId, nivel }))
        }
        setUserControlObject(ctrlUsersSupplierModel);
    }, [userObject]);

    const handleObjectUser = (infoObject: any) => {
        if (infoObject.length > 0) {
            setEmptyUserTable(false);
        } else {
            infoObject.forEach((element: any) => {
                if (!element.userId) {
                    element.userId = element.id;
                }
            })
        }
        setUserObject(infoObject);
    }

    function handleCancel() {
        window.location.reload();
    }

    const {
        handleSubmit,
    } = useForm();

    return (
        <Card className="bg-white mt-4">
            <Card.Header id="accordionPersonal">
                <ContextAwareToggle eventKey="0" status={ctrlEventKey}>{t('accordionUsers.title')}</ContextAwareToggle>
            </Card.Header>
            <Accordion.Collapse eventKey="0">
                <Card.Body>
                    <Container className="p-0 mt-3" fluid>
                        <Row>
                            <div>
                                <Form noValidate validated={validated} onSubmit={handleSubmit(onSubmit)}>
                                    <Row>
                                        <PartnersTableUsers
                                            isEdit={isEdit}
                                            infoEdit={infoEdit?.usersSupplierDTO}
                                            actionTableUser={handleObjectUser}
                                        />
                                    </Row>
                                    {
                                        emptyUserTable === true
                                            ?
                                            <div style={{ color: "red", textAlign: "center" }}>
                                                Favor vincular algum usuário
                                            </div>
                                            :
                                            ''
                                    }
                                    <Row className="d-flex justify-content-end mx-0 mt-5">
                                        {
                                            isEdit === true
                                                ?
                                                <Button
                                                    className="form-button cancel"
                                                    onClick={handleCancel}
                                                >
                                                    Cancelar
                                                </Button>
                                                :
                                                <Button
                                                    type="button"
                                                    variant="outline"
                                                    className="form-button back-btn btn-outline-primary"
                                                    onClick={() => {
                                                        setStepCtrl(3)
                                                    }}
                                                >
                                                    voltar
                                                </Button>
                                        }
                                        <Button
                                            type="submit"
                                            className="form-button"
                                        >
                                            {t('accordionDocuments.buttonContinue')}
                                        </Button>
                                    </Row>
                                </Form>
                            </div>
                        </Row>
                    </Container>
                </Card.Body>
            </Accordion.Collapse>
        </Card>
    )
}

export default AccordionUsers;