/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useEffect, useState } from "react";

import { Link } from "react-router-dom";
import api from "../../../services/api";
import DesktopDefault from "../../../templates/DesktopDefault";
import Breadcrumb from "../../../components/Breadcrumb/Breadcrumb";
import TransferFilter from "../TransferFilter/TransferFilter";

import transfers from './locales/transfers.json';
import TransferTable from "../TransferTable/TransferTable";

//import Breadcrumb from "../../../../components/Breadcrumb/Breadcrumb";
//import DesktopDefault from "../../../../templates/DesktopDefault";
//import ProductFilter from "../ProductFilter/ProductFilter";
//import SingleProduct from "../SingleProduct/SingleProduct";

export interface propFilter { }

const TransferList: React.FC<propFilter> = () => {
    const [transferList, setTransferList] = useState(null);

    const [actualOrigem, setActualOrigem] = useState<any>("");
    const [actualDestino, setActualDestino] = useState<any>("");
    const [actualStatus, setActualStatus] = useState<any>(0);
    const [actualTypeService, setActualTypeService] = useState<any>(0);
    const [actualTypeVehicle, setActualTypeVeihicle] = useState<any>(0);


    /* Pagination */
    const [totalRows, setTotalRows] = useState<any>();
    const [pageCount, setPageCount] = useState<any>(1);
    const [rowsPerPage, setRowsPerPage] = useState<any>(10);
    /* END - Pagination */

    console.log(transfers)

    function clearActualValues() {

        setActualOrigem("");
        setActualDestino("");
        setActualStatus(0);
        setActualTypeService(0);
        setActualTypeVeihicle(0);
    }


    useEffect(() => {
        async function filter() {
            try {
                const res = await api.post(`/Transfer/GetTransferByFilter`,
                    {
                        "page": pageCount,
                        "rowsPerPage": rowsPerPage,
                        "status": actualStatus,
                        "origem": actualOrigem,
                        "destiny": actualDestino,
                        "vehicle": actualTypeVehicle,
                        "idSellingType": actualTypeService,
                        "groupId": 0
                    }
                );
                if (res.status !== 400) {
                    setTransferList(res.data.data.rows);
                    setTotalRows(res.data.data.rowsCount);
                }

            } catch (error) { }
        }
        filter();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [pageCount, rowsPerPage]);


    async function buscar(origem: any, destino: any, status: any, typeService: any, typeVehicle: any) {
        setTransferList(null);
        //setLoading(true);
        setActualOrigem(origem)
        setActualDestino(destino)
        setActualStatus(status)
        setActualTypeService(typeService)
        setActualTypeVeihicle(typeVehicle)
        setPageCount(1);

        try {
            const res = await api.post('/Transfer/GetTransferByFilter',
                {
                    "page": pageCount,
                    "rowsPerPage": rowsPerPage,
                    "status": status,
                    "origem": origem,
                    "destiny": destino,
                    "vehicle": typeVehicle,
                    "idSellingType": typeService,
                    "groupId": 0
                }
            );

            if (res.status !== 400) {
                //setLoading(false);
                setTransferList(res.data.data.rows);
                setTotalRows(res.data.data.rowsCount);
            }
        } catch (error: any) {
            //setLoading(false);
            if (error.response.status === 401) {
                window.location.href = window.location.origin + '/';
            }

        }
    };

    return (

        <>
            <DesktopDefault>
                <div className="container-fluid content-dashboard hello">

                    <Breadcrumb title={"Produtos / Listar Transfers"} />

                    <div className="mt-4 d-flex justify-content-between">
                        <h2 className="title" style={{ color: "#707070" }}>
                            Transfers
                        </h2>
                        <div>
                            {/* <Button
                                variant="outline-primary"
                                className="btn-default mr-2 "
                            >
                                Blackout
                            </Button> */}
                            <Link
                                to="/products/add-transfer"
                                className="btn btn-primary btn-default"
                            //onClick={handleAddTransfersClick}
                            >
                                Adicionar Transfer
                            </Link>
                        </div>
                    </div>
                    <div>
                        <TransferFilter
                        buscar={buscar}
                        products={transferList}
                        clearActualValues={clearActualValues}
                        />
                    </div>
                    <div>
                        <TransferTable
                            transfers={transferList}
                            totalRows={totalRows}
                            pageCount={pageCount}
                            setPageCount={setPageCount}
                            rowsPerPage={rowsPerPage}
                            setRowsPerPage={setRowsPerPage}
                        />
                    </div>
                </div>
            </DesktopDefault>
        </>
    );
};

export default TransferList;
