import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Dropdown from 'react-bootstrap/Dropdown';

import 'react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css';
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.css';
import BootstrapTable from 'react-bootstrap-table-next';
import Pagination from '../../../components/Pagination/Pagination';

//STYLES
/* import './SuppliersTable.scss';
 */ //import { faTrumpet } from '@fortawesome/pro-duotone-svg-icons';

export interface propChannel {
    channels: any
    totalRows: any;
    pageCount: number;
    setPageCount: any;
    rowsPerPage: any;
    setRowsPerPage: any;
    loading: any;
    setIdSalesChannel: any;
}

const ChannelTable: React.FC<propChannel> = ({
    channels, totalRows, pageCount, setPageCount, rowsPerPage, setRowsPerPage, loading, setIdSalesChannel
}: propChannel) => {

    function addLastUpdate(cell: any, row: any) {
        return (
            <>
                {row.createdBy && row.date &&
                    <>
                        <b>{`${row.createdBy}`}</b> em <b>{`${row.date?.split('T')[0].split('-').reverse().join('/')}`}</b>
                    </>
                }
            </>
        )
    }

    function addStatus(cell: any, row: any) {
        var status: string = (cell === 1 ? "Ativo" : "Inativo");

        return (
            <div
                className={status === "Ativo" ? "bg-reservation-ativo" : "bg-reservation-inativo"}
            >
                {status}
            </div>
        );
    }

    function addActionButton(cell: any, row: any) {

        return (
            <>
                <div className="center-table options-table">
                    <Dropdown drop="down" >
                        <Dropdown.Toggle variant="light ">
                            <FontAwesomeIcon size="lg" icon={['fal', 'ellipsis-h']} />
                        </Dropdown.Toggle>

                        <Dropdown.Menu>
                            <Dropdown.Item
                                eventKey="1"
                                //href={'/channel/edit-channel?idSalesChannel=' + cell}
                                onClick={() => {setIdSalesChannel(() => cell);}}
                            >
                                <FontAwesomeIcon icon={['fal', 'edit']} />
                                Editar
                            </Dropdown.Item>
                            {/* <Dropdown.Item
                                eventKey="2"
                            >
                                <FontAwesomeIcon icon={['fal', 'edit']} />
                                Alterar Status
                            </Dropdown.Item> */}
                        </Dropdown.Menu>
                    </Dropdown>
                </div>
            </>
        );
    }

    const columns = [
        {
            dataField: 'salesChannelName',
            text: `Nome`,
        },
        {
            dataField: 'description',
            text: `Descrição`,
        },
        {
            dataField: 'createdBy',
            text: `Última atualização feita por:`,
            formatter: addLastUpdate,
        },
        {
            dataField: 'statusSalesChannel',
            text: `Status`,
            formatter: addStatus,
        },
        {
            dataField: 'idSalesChannel',
            text: `Ações`,
            formatter: addActionButton,
        },
    ];

    /* loading table */

    function loadingFunc() {
        return (
            <>
                <div className="animated-background row-loading"></div>
            </>
        )
    }

    const loadingColumns = [{ dataField: "contasd", text: "Carregando Canais", formatter: loadingFunc }];

    const loadingProducts = [
        { contas: "" }, { contas: "" }, { contas: "" }, { contas: "" }, { contas: "" },
        { contas: "" }, { contas: "" }, { contas: "" }, { contas: "" }, { contas: "" }
    ];
    /* [END] loading table */

    /* In case of empty table*/
    const notFoundColumns = [{ dataField: "*", text: "Canais" }];

    const notFoundProducts = [{ clientes: "Nenhum Canal encontrado" }];
    /* [END] In case of empty table*/

    const rowClasses = (row: any) => {
        if (row.statusSalesChannel === 1) {
            return 'border-ativo';
        } else if (row.statusSalesChannel === 2) {
            return 'border-inativo';
        } else {
            return '';
        }
    };

    if (channels !== null) {
        return (
            <>
                <div className="table-default channels-table d-flex w-100 justify-content-center mt-4">
                    <div className="table-container">
                        <BootstrapTable
                            bootstrap4
                            keyField="idSalesChannel"
                            data={channels}
                            columns={columns}
                            rowClasses={rowClasses}
                        />

                        <Pagination
                            totalRows={totalRows}
                            pageCount={pageCount}
                            setPageCount={setPageCount}
                            rowsPerPage={rowsPerPage}
                            setRowsPerPage={setRowsPerPage}
                            selectNumberRows="yes"
                        />
                    </div>
                </div>
            </>
        );
    } else if (channels === null && loading === true) {
        return (
            <>
                <div className="table-default reservations-table loading not-found">
                    <div className="table-container">
                        <BootstrapTable
                            bootstrap4
                            keyField="idSalesChannel"
                            data={loadingProducts}
                            hover={true}
                            columns={loadingColumns}
                            striped={true}
                        />
                    </div>
                </div>
            </>
        )
    } else {
        return (
            <>
                <div className="table-default reservations-table not-found">
                    <div className="table-container">
                        <div className="table-title">
                            <h3 className="">Canais</h3>
                        </div>

                        <BootstrapTable
                            bootstrap4
                            keyField="idSalesChannel"
                            data={notFoundProducts}
                            columns={notFoundColumns}
                        />
                    </div>
                </div>
            </>
        );
    }
}

export default ChannelTable;
