import React, { useEffect, useState } from 'react';
import api from '../../../services/api';

import './ListReservations.scss';
import { useTranslation } from 'react-i18next';

//COMPONENTS
import ReservationsFilter from '../components/ReservationsFilter/ReservationsFilter';
//import ReservationsTable from '../components/ReservationsTable/ReservationsTable';
import Breadcrumb from '../../../components/Breadcrumb/Breadcrumb';
import DesktopDefault from '../../../templates/DesktopDefault';
import ReservationsTableResponsive from '../components/ReservationsTableResponsive/ReservationsTableResponsive';
import ModalQuestion from '../../../components/Modal/ModalQuestion/ModalQuestion';

function ListReservations() {
  const { t } = useTranslation();

  //const [cancelTextResponse, setCancelTextResponse] = useState<any>('');

  const [reservations, setReservations] = useState<any>(null);
  const [modalContent, setModalContent] = useState<any>();
  const [confirm, setConfirm] = useState<any>(
    `${t('reservations.sureAboutCanceling')}`,
  );
  const [log, setLog] = useState<any>();
  const [name, setName] = useState<any>('');
  const [actualDate, setActualDate] = useState<any>('');
  const [operationDate, setOperationDate] = useState<any>('');
  const [actualSource, setActualSource] = useState<any>('');
  const [status, setStatus] = useState<any>(0);
  const [loading, setLoading] = useState<any>(false);
  const [, setWidth] = useState<any>(window.innerWidth);

  /* Pagination */
  const [totalRows, setTotalRows] = useState<any>();
  const [pageCount, setPageCount] = useState<any>(1);
  const [rowsPerPage, setRowsPerPage] = useState<any>(10);
  /* END - Pagination */
  const [activeExportFunc, setActiveExportFunc] = useState<boolean>(false);

  const [reason, setReason] = useState<any>('0');
  const [valReason, setValReason] = useState<any>(false);

  useEffect(() => {
    setReservations(null);
    setLoading(true);
    let beginDate: any = [];
    let endDate: any = [];
    let opBeginDate: any = [];
    let opEndDate: any = [];
    //let startTimeLocal: any = ''

    if (actualDate.length === 1) {
      beginDate = actualDate[0].split('/');
    } else if (actualDate.length === 2) {
      beginDate = actualDate[0].split('/');
      endDate = actualDate[1].split('/');
    }

    if (operationDate.length === 1) {
      opBeginDate = operationDate[0].split('/');
    } else if (operationDate.length === 2) {
      opBeginDate = operationDate[0].split('/');
      opEndDate = operationDate[1].split('/');
    }

    const token = localStorage.getItem('GroupId') || '{}';
    const config = {
      headers: { Authorization: `Bearer ${token}` },
    };
    async function listarReservas() {
      try {
        const { data } = await api.post(
          '/NewReservations/GetNewReservationsByFilterAsync',
          {
            page: pageCount,
            rowsPerPage: rowsPerPage,
            loc: '',
            name: name,
            email: '',
            status: status,
            productType: 0,
            dataType: 0,
            beginDate:
              actualDate.length > 0
                ? `${beginDate[2]}-${beginDate[1]}-${beginDate[0]}`
                : '',
            endDate:
              actualDate.length === 1
                ? `${beginDate[2]}-${beginDate[1]}-${
                    parseInt(beginDate[0]) + 1
                  }`
                : actualDate !== null && actualDate.length === 2
                ? `${endDate[2]}-${endDate[1]}-${endDate[0]}`
                : '',
            opBeginDate:
              operationDate.length > 0
                ? `${opBeginDate[2]}-${opBeginDate[1]}-${opBeginDate[0]}`
                : '',
            opEndDate:
              operationDate.length === 1
                ? `${opBeginDate[2]}-${opBeginDate[1]}-${
                    parseInt(opBeginDate[0]) + 1
                  }`
                : operationDate !== null && operationDate.length === 2
                ? `${opEndDate[2]}-${opEndDate[1]}-${opEndDate[0]}`
                : '',
            orderByField: '',
            source: actualSource,
          },
          config,
        );

        if (data.status !== 400) {
          setLoading(false);
          setReservations(data.data.rows);
          setTotalRows(data.data.rowsCount);
          //console.log(data.data);
        }
      } catch (error: any) {
        setLoading(false);
        if (error?.response?.status === 401) {
          window.location.href = window.location.origin + '/';
        }
      }
    }
    listarReservas();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pageCount, rowsPerPage]);

  async function buscar(
    name: string,
    status: any,
    date: any,
    opDate: any,
    source: any,
    actualPage: number = 1,
  ) {
    setReservations(null);
    setLoading(true);
    setName(name);
    setStatus(status);
    setActualDate(date);
    setOperationDate(opDate);
    setActualSource(source);
    setPageCount(actualPage);

    const token = localStorage.getItem('GroupId') || '{}';
    const config = {
      headers: { Authorization: `Bearer ${token}` },
    };

    let beginDate = [];
    let endDate = [];
    let opBeginDate = [];
    let opEndDate = [];
    // let startTimeLocal = ''

    if (date.length === 1) {
      beginDate = date[0].split('/');
    } else if (date.length === 2) {
      beginDate = date[0].split('/');
      endDate = date[1].split('/');
    }

    if (opDate.length === 1) {
      opBeginDate = opDate[0].split('/');
    } else if (opDate.length === 2) {
      opBeginDate = opDate[0].split('/');
      opEndDate = opDate[1].split('/');
    }

    try {
      const res = await api.post(
        '/NewReservations/GetNewReservationsByFilterAsync',
        {
          page: actualPage,
          rowsPerPage: rowsPerPage,
          name: name,
          email: '',
          status: parseInt(status),
          productType: 0,
          dataType: 0,
          beginDate:
            date.length > 0
              ? `${beginDate[2]}-${beginDate[1]}-${beginDate[0]}`
              : '',
          endDate:
            date.length === 1
              ? `${beginDate[2]}-${beginDate[1]}-${parseInt(beginDate[0]) + 1}`
              : date.length === 2
              ? `${endDate[2]}-${endDate[1]}-${endDate[0]}`
              : '',
          opBeginDate:
            opDate.length > 0
              ? `${opBeginDate[2]}-${opBeginDate[1]}-${opBeginDate[0]}`
              : '',
          opEndDate:
            opDate.length === 1
              ? `${opBeginDate[2]}-${opBeginDate[1]}-${
                  parseInt(opBeginDate[0]) + 1
                }`
              : opDate.length === 2
              ? `${opEndDate[2]}-${opEndDate[1]}-${opEndDate[0]}`
              : ``,
          orderByField: '',
          source: source,
        },
        config,
      );
      if (res.status !== 400) {
        setLoading(false);
        setReservations(res.data.data.rows);
        setTotalRows(res.data.data.rowsCount);
      }
    } catch (error: any) {
      setLoading(false);
      if (error?.response?.status === 401) {
        /* window.location.href = window.location.origin + '/'; */
      }
    }
  }

  function cancelReservation(
    idItem: any,
    bookingLoc: any,
    cancelAllItems: boolean,
  ) {
    // setCancelamentoModal(true);

    if (reason !== '0' && reason !== undefined) {
      setModalContent('loading');
      const cancelamento = async () => {
        const token = localStorage.getItem('GroupId') || '{}';
        const config = {
          headers: { Authorization: `Bearer ${token}` },
        };
        try {
          const res = await api.post(
            `${process.env.REACT_APP_SERVER_URL_API}/Reservations/TryCancelItemSIG`,
            {
              idItem: idItem,
              bookingLoc: bookingLoc,
              cancelamentoTotal: cancelAllItems,
              motivo: reason,
            },
            config,
          );
          if (res.status !== 400) {
            if (res.data.data.log === 0 || res.data.data.log === '0') {
              setConfirm(res.data.data.texto);
              setLog(res.data.data.log);
              setModalContent('sucesso');
              setTimeout(() => window.location.reload(), 2000);
            } else {
              setConfirm(res.data.data.texto);
              setLog(res.data.data.log);
              setModalContent('error');
            }
            //setCancelTextResponse(res.data.data.texto);
          }
        } catch (error: any) {}
      };

      if (confirm) {
        cancelamento();
      } else {
        // alert("Action canceled");
      }
    } else {
      setValReason(true);
      setTimeout(() => {
        setValReason(false);
      }, 2000);
    }
  }

  function handleModal() {
    let modal = document.querySelector('.bg-modal-custom');
    modal?.classList.toggle('modal-close');
  }

  useEffect(() => {
    //tablesizing
    window.addEventListener('resize', function () {
      var newWidth = window.innerWidth;
      setWidth(newWidth);
    });
  }, []);

  const exportReservations = async () => {
    setActiveExportFunc(true);
  };
  const exportReservationsFunction = async () => {
    let beginDate = [];
    let endDate = [];
    let opBeginDate = [];
    let opEndDate = [];
    //let startTimeLocal = ''

    if (actualDate.length === 1) {
      beginDate = actualDate[0].split('/');
    } else if (actualDate.length === 2) {
      beginDate = actualDate[0].split('/');
      endDate = actualDate[1].split('/');
    }

    if (operationDate.length === 1) {
      opBeginDate = operationDate[0].split('/');
    } else if (operationDate.length === 2) {
      opBeginDate = operationDate[0].split('/');
      opEndDate = operationDate[1].split('/');
    }

    try {
      const token = localStorage.getItem('GroupId') || '{}';

      const exp = await api.post(
        '/NewReservations/GetNewReservationsByFilterExcelAsync',
        {
          page: 0,
          rowsPerPage: 0,
          name: name,
          email: '',
          status: parseInt(status),
          productType: 0,
          dataType: 0,
          beginDate:
            actualDate.length > 0
              ? `${beginDate[2]}-${beginDate[1]}-${beginDate[0]}`
              : '',
          endDate:
            actualDate.length === 1
              ? `${beginDate[2]}-${beginDate[1]}-${parseInt(beginDate[0]) + 1}`
              : actualDate.length === 2
              ? `${endDate[2]}-${endDate[1]}-${endDate[0]}`
              : '',
          opBeginDate:
            operationDate.length > 0
              ? `${opBeginDate[2]}-${opBeginDate[1]}-${opBeginDate[0]}`
              : '',
          opEndDate:
            operationDate.length === 1
              ? `${opBeginDate[2]}-${opBeginDate[1]}-${
                  parseInt(opBeginDate[0]) + 1
                }`
              : operationDate.length === 2
              ? `${opEndDate[2]}-${opEndDate[1]}-${opEndDate[0]}`
              : '',
          orderByField: '',
          source: actualSource,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'application/json',
            Accept: '*/*',
            //"Access-Control-Allow-Origin": "*"
          },
          responseType: 'blob',
        },
      );
      console.log('aqui');

      if (exp.status !== 400) {
        const blob = new Blob([exp.data], {
          type: exp.headers['Content-Type'],
        });
        const objectUrl = window.URL.createObjectURL(blob);

        const link = document.createElement('a');
        link.href = objectUrl;
        link.setAttribute('download', `reservas.xlsx`);

        // Append to html link element page
        document.body.appendChild(link);

        // Start download
        link.click();

        // // Clean up and remove the link
        link?.parentNode?.removeChild(link);
      }
      setActiveExportFunc(false);
    } catch (error: any) {}
  };

  function reloadTable() {
    buscar(name, status, actualDate, operationDate, actualSource, pageCount);
  }

  return (
    <>
      <DesktopDefault>
        <div className="container-fluid content-dashboard">
          <Breadcrumb title={t('reservations.path')} />

          <div className="mt-4 d-flex justify-content-between align-items-center">
            <h2 className="title" style={{ color: '#707070' }}>
              {t('reservations.title')}
            </h2>
          </div>

          <ReservationsFilter buscar={buscar} />

          {/* {
             width > 640 ?
            console.log("A viewport tem pelo menos 768 pixels de largura")
              :
              console.log("A viewport tem menos que 768 pixels de largura")          
            
          } */}

          <ReservationsTableResponsive
            totalRows={totalRows}
            pageCount={pageCount}
            setPageCount={setPageCount}
            rowsPerPage={rowsPerPage}
            setRowsPerPage={setRowsPerPage}
            reservations={reservations}
            confirm={confirm}
            log={log}
            cancelReservation={cancelReservation}
            modalContent={modalContent}
            setConfirm={setConfirm}
            setLog={setLog}
            handleModal={handleModal}
            loading={loading}
            setReason={setReason}
            valReason={valReason}
            exportReservations={exportReservations}
            reloadTable={reloadTable}
          />
        </div>

        <ModalQuestion
          modalShow={activeExportFunc}
          setModalShow={setActiveExportFunc}
          titulo="Exportar Reservas"
          mensagem="Tem certeza que deseja exportar essas reservas?"
          handleActionConfirmModal={exportReservationsFunction}
          //handleActionCancelModal={handleActionCancelDeleteClick}
        />
      </DesktopDefault>
    </>
  );
}

export default ListReservations;
