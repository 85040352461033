import React, { useState, useEffect, Key } from 'react';
import api from "../../../../../../services/api";

import { Row, Col, InputGroup } from "react-bootstrap";
import { Controller, useForm } from "react-hook-form";
import { Form } from "react-bootstrap";
//import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import SingleCalendar from '../../../../../../components/Calendar/SingleCalendar';
//import SearchProduct from "../../../../../../components/C2Points/SeachProduct";
import OnlyTime from '../../../../../../components/Calendar/OnlyTime';
import SeachAllProduct from '../../../../../../components/C2Points/SeachAllProduct';

//import ModalConfirm from '../../../../../../components/Modal/ModalConfirm/ModalConfirm';

export interface propTable {
    show: any;
    setResponseText: any;
    setModalContent: any;
    setLog: any;
}

const CreateSession: React.FC<propTable> = ({
    show, setResponseText, setModalContent, setLog
}: propTable) => {
    const [date, setDate] = useState<any>();
    const [time, setTime] = useState<any>();
    const [code, setCode] = useState<any>(null);
    const [modalities, setModalities] = useState<any>([]);
    const [choiceModalities, setChoiceModalities] = useState<any>();

    const [, setModalShow] = useState<any>(false);
    const [loading, setLoading] = useState<any>(false);

    const validated = false;

    function closeModal() {
        var closeBtn: any = document.getElementsByClassName("btn-close")[0];
        if (closeBtn !== undefined) {
            closeBtn.click();
        }
    }

    const {
        control,
        handleSubmit,
    } = useForm();

    useEffect(() => {

        

        async function getModalities() {

            let today: any = new Date();

            let dateTemp: any = today;

            if(date) {
                if(typeof date === 'string') {
                    dateTemp = new Date(parseInt(date.substring(6,10)), parseInt(date.substring(3,5))-1, parseInt(date.substring(0,2)));
                } else {
                    dateTemp = new Date(parseInt(date[0].substring(6,10)), parseInt(date[0].substring(3,5))-1, parseInt(date[0].substring(0,2)));
                }
            } 

            try {
                const { data } = await api.post('/Products/GetTourModalitiesByDateAsync',
                    {
                        "data": `${dateTemp.getFullYear()}-${dateTemp.getMonth() + 1}-${dateTemp.getDate()}`,
                        "productCode": code
                    }
                );
                if (data.status !== 400) {
                    setModalities(data.data);
                }
            } catch (error: any) {
                if (error.response.status === 401) {
                    window.location.href = window.location.origin + '/';
                }
            }
        }
        if(code) {
            getModalities()
        }

        
    }, [code,date])

        

        

    const onSubmit = (data: any) => {
        setLoading(true);
        data.productCode = code;
        data.dataHora = `${date.split("/").reverse().join("-")} ${time}`;
        data.idModalidade = choiceModalities;

        setModalContent('loading');

        const createSession = async () => {
            try {
                const token = localStorage.getItem('GroupId') || '{}';
                const config = {
                    headers: { 'Authorization': `Bearer ${token}` },
                };

                const res = await api.post(`/Manifest/CreateSession`,
                    {
                        "productCode": data.productCode,
                        "dataHora": data.dataHora,
                        "qtdePax": 0,
                        "idModalidade": data.idModalidade
                    }, config
                );
                if (res.status !== 400) {
                    if (res.status !== 400) {
                        setResponseText(res.data.data.texto);
                        setLog(res.data.data.log);
                        setModalContent("success");
                    } else {
                        setResponseText(res.data.data.texto);
                        setLog(res.data.data.log);
                        setModalContent("error");
                    }
                    setLoading(false);
                    closeModal();
                    show(true);
                }
            } catch (error: any) {
                setModalShow(true);
                alert("Preencha os campos!");
                setModalContent('error');
                setLog(1);
                setLoading(false)
            }
        };
        createSession();
    }

    return (
        <>
            <Form className="h-100 form-inserir" noValidate validated={validated} onSubmit={handleSubmit(onSubmit)} autoComplete="false">
                <div className='d-flex flex-column justify-content-between h-100'>
                    <div>
                        <Row className="mb-3">
                            <Form.Group as={Col} md={6} controlId="formGridCat">
                                <Form.Label>Produto</Form.Label>
                                <Controller
                                    control={control}
                                    name="categoria"
                                    render={({ field }: any) => (
                                        <SeachAllProduct
                                            setCode={setCode}
                                        />
                                    )}
                                />
                            </Form.Group>

                            <Form.Group as={Col} controlId="formGridDesc">
                                <Form.Label>Data Session</Form.Label>
                                <InputGroup
                                    hasValidation
                                    className=""
                                    size="sm"
                                    placeholder="Quando?"
                                >
                                    <SingleCalendar
                                        setValue={setDate}
                                    />
                                </InputGroup>
                            </Form.Group>
                            <Form.Group as={Col} className="mb-3" controlId="formGridDate">
                                <Form.Label>Hora Session</Form.Label>
                                <InputGroup
                                    hasValidation
                                    className=""
                                    size="sm"
                                    placeholder="Quando?"
                                >
                                    <OnlyTime
                                        setTime={setTime}
                                    />
                                </InputGroup>
                            </Form.Group>
                        </Row>

                        <Row>
                            <Form.Group as={Col} md={6} controlId="formGridCat">
                                <Form.Label>Modalidade</Form.Label>
                                <Controller
                                    control={control}
                                    name="idModalidade"
                                    //rules={{ required: { value: true, message: "required" }, }}
                                    render={({ field }: any) => (
                                        <div className="endpoint">
                                            <Form.Select
                                                {...field}
                                                as="select"
                                                variant="standard"
                                                margin="normal"
                                                onChange={(e: any) => setChoiceModalities(e.target.value)}
                                            >
                                                <option value='0' selected disabled>Selecione</option>
                                                {
                                                    modalities.map((modality: any, index: Key) => {
                                                        return (
                                                            <option value={modality.id}>{modality.modalityName}</option>
                                                        )
                                                    })
                                                }
                                            </Form.Select>
                                        </div>
                                    )}
                                />
                            </Form.Group>
                        </Row>
                    </div>
                    <div className='mt-4 d-flex justify-content-end'>
                        <span className="btn btn-default btn-outline-primary px-4 mr-3 text-uppercase" onClick={closeModal} >
                            Cancelar
                        </span>
                        <button className="btn btn-default btn-primary px-4 text-uppercase" type="submit">
                            {
                                loading === true
                                    ?
                                    <div className="load"></div>
                                    :
                                    "Criar"
                            }
                        </button>
                    </div>
                </div>
            </Form>
        </>
    )
}

export default CreateSession;