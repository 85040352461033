
import React, { useEffect, useState } from "react";
import { Container, Row, Col } from 'react-bootstrap';
import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';
import Button from 'react-bootstrap/Button';
import Accordion from 'react-bootstrap/Accordion';
import { useTranslation } from "react-i18next";


import { Controller, useForm } from "react-hook-form";

import './ReservationsFilter.scss';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

//Components
import RangeCalendar from "../../../../components/Calendar/RangeCalendar";
import GetStatusNewReservations from "../../../../components/C2Points/GetStatusNewReservations";
import GetAllSource from "../../../../components/C2Points/GetAllSource";
import i18next from "i18next";

function ReservationsFilter(props: any) {

    const { t } = useTranslation();

    const [filterName, setFilterName] = useState<any>('');
    const [filterDate, setFilterDate] = useState<any>('');
    const [filterOpDate, setFilterOpDate] = useState<any>('');
    const [filterStatus, setFilterStatus] = useState<any>(0);
    const [filterSource, setFilterSource] = useState<any>('');

    const {
        control,
        //handleSubmit,
        //register,
        formState: { errors },
    } = useForm({});

    const handleFilterClick = () => {
        props.buscar(filterName, filterStatus, filterDate, filterOpDate, filterSource === '0' ? '' : filterSource);
        //console.log(filterName, filterState, filterType, filterStatus, filterDate)
    };

    const handleClearClick = (event: any) => {

        let inputValue = document.querySelectorAll('input');
        for (let i = 0; i < inputValue.length; i++) {
            inputValue[i].value = "";
        }

        let selectValue = document.querySelectorAll('select');
        for (let i = 0; i < selectValue.length; i++) {
            selectValue[i].value = "0";
        }

        setFilterName("");
        setFilterDate("");
        setFilterOpDate("");
        setFilterStatus(0);
        setFilterSource('');
        props.buscar("", 0, [], '');
    };

    const handleChangeName = (e: React.ChangeEvent<HTMLInputElement>) => {
        const newValue = e.target.value;
        setFilterName(newValue);
    }

    useEffect(()=>{
        return  props.buscar(filterName, filterStatus, filterDate, filterOpDate, filterSource === '0' ? '' : filterSource);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[i18next.language])
    
    return (
        <div className="suppliers-filter d-flex justify-content-center bg-white mt-4" style={{ textAlign: "left" }}>
            <Accordion defaultActiveKey="1">
                <Accordion.Item eventKey="0">
                    <Accordion.Header><FontAwesomeIcon icon={['fal', 'sliders-h']} size="lg" className="mr-2" /><span className="h5 mb-0">{t("reservations.filter.title")}</span></Accordion.Header>
                    <Accordion.Body className="pt-0">
                        <Container className="p-0" fluid>
                            <Row>
                                <div>
                                    <Form >
                                        <Row className="mb-3">
                                            <Form.Group as={Col} md="4">
                                                <Form.Label>{t("reservations.filter.name")}</Form.Label>
                                                <Controller
                                                    control={control}
                                                    name="name"
                                                    render={({ field }: any) => (
                                                        <Form.Control
                                                            {...field}
                                                            type="text"
                                                            variant="standard"
                                                            margin="normal"
                                                            autoComplete="off"
                                                            onChange={handleChangeName}
                                                            value={filterName}
                                                            
                                                        />
                                                    )}
                                                />
                                            </Form.Group>
                                            
                                            <Form.Group as={Col} md="2">
                                                <Form.Label>{t("reservations.filter.date")}</Form.Label>
                                                <InputGroup hasValidation className="ranger-calendar">
                                                    <RangeCalendar date={filterDate} setDate={setFilterDate} />
                                                </InputGroup>
                                            </Form.Group>

                                            <Form.Group as={Col} md="2">
                                                <Form.Label>{t("reservations.filter.opDate")}</Form.Label>
                                                <InputGroup hasValidation className="ranger-calendar">
                                                    <RangeCalendar date={filterOpDate} setDate={setFilterOpDate} />
                                                </InputGroup>
                                            </Form.Group>

                                            <Form.Group as={Col} md="2">
                                                <Form.Label>{t('reservations.filter.status')}</Form.Label>
                                                {/* <Form.Control
                                                    type="text"
                                                    onChange={handleChangeState}
                                                    autoComplete='off'
                                                /> */}
                                                <Controller
                                                    control={control}
                                                    name="state"
                                                    render={({ field }: any) => (
                                                        <GetStatusNewReservations propsField={field} propsLabel={t('reservations.filter.status')} propsErrors={errors} setFilterStatus={setFilterStatus} />
                                                    )}
                                                />
                                            </Form.Group>
                                            {
                                                !process.env.REACT_APP_LEVEL_AGENTS?.split(',').includes(atob(JSON.parse(localStorage.getItem('c2tourAuth') || '{}').user.perfil.nivel)) &&
                                                <Form.Group as={Col} md="2">
                                                    <Form.Label>Canal</Form.Label>
                                                    <Controller
                                                        control={control}
                                                        name="name"
                                                        render={({ field }: any) => (
                                                            <GetAllSource propsField={field} propsErrors={errors} setSource={setFilterSource} />
                                                        )}
                                                    />
                                                </Form.Group>
                                            }

                                        </Row>
                                        <Row as={Col} md="12" className="mt-5 justify-content-md-end">
                                            <Col xs lg="12" className="d-flex justify-content-md-end">
                                                <Button variant="outline-primary" className="btn-default mr-2 text-uppercase" onClick={handleClearClick}>{t('reservations.filter.btnClear')}</Button>
                                                <Button className="btn-default text-uppercase" onClick={handleFilterClick}>{t('reservations.filter.btnSearch')}</Button>
                                            </Col>
                                        </Row>
                                    </Form>
                                </div>
                            </Row>
                        </Container>
                    </Accordion.Body>
                </Accordion.Item>
            </Accordion>
        </div>
    )
}

export default ReservationsFilter;