import React from 'react';

import Breadcrumb from '../../../components/Breadcrumb/Breadcrumb';

import GroupFileForm from '../components/GroupFileForm/GroupFileForm';
import { useTranslation } from 'react-i18next';
import DesktopDefault from '../../../templates/DesktopDefault';


function AddGroupFile() {
    const { t } = useTranslation();
    
    return (
        <>
            <DesktopDefault>
                <div className="container-fluid content-dashboard">

                    <Breadcrumb title={t("groupsAndCustomized.add.path")} />

                    <div className="register-users mt-4">
                        <h2 className="title">{t("groupsAndCustomized.add.title")}</h2>
                    </div>

                    <GroupFileForm action={1} info={null} />

                </div>

            </DesktopDefault>
        </>

    );
}

export default AddGroupFile;