/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useEffect, useState } from "react";
import api from '../../../../services/api';

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Form, Modal, Dropdown } from "react-bootstrap";

import { useTranslation } from 'react-i18next';
import { useForm } from "react-hook-form";

import "react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css";
import "react-bootstrap-table-next/dist/react-bootstrap-table2.css";
import BootstrapTable from "react-bootstrap-table-next";

//STYLES
import "./TableBillsPayERP.scss";
import Pagination from "../../../../components/Pagination/Pagination";
//import DebitNotes from "../../../../components/Modal/ModalDefault/DebitNotes/DebitNotes";
//import Payment from "../../../../components/Modal/ModalDefault/Payment/Payment";
import FormBillsToPay from "../../../../components/Forms/FormBillsToPay/FormBillsToPay";
//import Expiration from "../../../../components/Modal/ModalDefault/Expiration/Expiration";
import EditPayment from "../../../../components/Modal/ModalDefault/EditPayment/EditPayment";
import ModalQuestion from "../../../../components/Modal/ModalQuestion/ModalQuestion";
//import SetDueInBatch from "../../../../components/Modal/ModalDefault/SetDueInBatch/SetDueInBatch";

export interface propTable {
  billsPay: any;
  totalRows: any;
  pageCount: any;
  setPageCount: any;
  rowsPerPage: any;
  setRowsPerPage: any;
  exportBills: any;
  printBills: any;
  parameters: any;
  totalPay: any;
  actualDate: any;
  loading: any;
  goToParcial: any;
  refreshData?: any
}

const TableBillsPayERP: React.FC<propTable> = ({
  billsPay, totalRows, pageCount, setPageCount, rowsPerPage, setRowsPerPage, exportBills, printBills, parameters, totalPay, actualDate, loading, goToParcial, refreshData
}: propTable) => {
  /* VARIÁVEIS */
  const { t } = useTranslation();

  const [selectedSingleRow, setSelectedSingleRow] = useState<any>();
  const [modalShow, setModalShow] = useState<any>(false);
  const [modalResponse, setModalResponse] = useState<any>(null);
  const [datapgto, setDatapgto] = useState<any>(undefined);
  const [allowReload, setAllowReload] = useState<any>(false);
  const [editModalShow, setEditModalShow] = useState<any>(false);

  /* Success Modal (Inserir) */
  const [modalSuccess, setModalSuccess] = useState<any>(false);
  const [responseText, setResponseText] = useState<any>();
  const [modalContent, setModalContent] = useState<any>();
  const [log, setLog] = useState<any>();
  /* END - Success Modal (Inserir) */

  const [expiration, setExpiration] = useState<any>();

  const [undoSettlementModalShow, setUndoSettlementModalShow] = useState<any>(false);
  const [undoSettlementText, setUndoSettlementText] = useState<any>("");

  const [billDeleteModalShow, setBillDeleteModalShow] = useState<any>(false);

  const [setBillsDueModalShow, setSetBillsDueModalShow] = useState<any>(false);

  const tourAuth: any = JSON.parse(localStorage.getItem('c2tourAuth') || '{}');

  const userName: any = tourAuth.user.nome;
  const userLastName: any = tourAuth.user.lastName;
  const uId: any = tourAuth.user.uId;

  const level = atob(JSON.parse(localStorage.getItem('c2tourAuth') || '{}').user.perfil.nivel)

  const validated = false;


  /* FUNÇÕES */

  async function settleBills(rows: any) {
    setSelectedSingleRow(rows);  //recebe array vazio ([]) para liquidar consulta
    setModalShow(true);
  }

  function unsettleBill(row: any) {
    setSelectedSingleRow(row);

    if(row.idLote > 0) {
      setUndoSettlementText("Liquidação em lote, deseja mesmo desfazer a liquidação? A liquidação de todo o lote sera desfeita");
    } else {
      setUndoSettlementText("Deseja mesmo desfazer a liquidação do pagamento?");
    }

    setUndoSettlementModalShow(true);
  }

  async function editBill(row: any) {
    const token = localStorage.getItem('GroupId') || '{}';
    const config = {
      headers: { 'Authorization': `Bearer ${token}`, 'Content-Type': 'application/json' },
    };
    try {
      const res = await api.post(`/BillsPayable/GetBill`,
        {
          "id": row.manifestId,
          "tipo": row.tipo,

        }, config);
      if (res.status !== 400) {
        var dados = res.data.data;
        //console.log(dados.centrosCusto);
        setSelectedSingleRow(dados);
        setEditModalShow(true);
      }
    } catch (error: any) {
      
    }

  }

  function handleBillDeleteClick(row: any) {
    setSelectedSingleRow(row);
    setBillDeleteModalShow(true);
  }

  async function handleActionBillDeleteModal(e: any) {
    setBillDeleteModalShow(false);

    setModalContent("loading");
    setModalSuccess(true);

    const token = localStorage.getItem('GroupId') || '{}';
    const config = {
      headers: { 'Authorization': `Bearer ${token}`, 'Content-Type': 'application/json' },
    };

    var manifestId = selectedSingleRow.manifestId;
    var tipo = selectedSingleRow.tipo;

    setSelectedSingleRow(null);

    try {
      const res = await api.post(`/BillsPayable/DeleteBill`,
        {
          "id": manifestId,
          "tipo": tipo,
          "uid": uId,
          "usuario": (userName !== null && userLastName !== null) ? `${userName} ${userLastName}` : userName
        }, config);
      if (res.status !== 400) {
        var dados = res.data.data;
        //console.log(dados.centrosCusto);
        setResponseText(dados.texto);
        setLog(dados.log);

        if(dados.log !== 1) {
          setModalContent("success");
        } else {
          setModalContent("error");
        }
        
      } else {
        setModalContent("error");
        setResponseText("Erro, tente novamente mais tarde");
        setLog(1);
      }
    } catch (error: any) {
      console.log(error.response)
    }
  }

  function handleSetBillsDueClick(row: any) {
    //setSelectedSingleRow(row);
    setSetBillsDueModalShow(true);
  }

  async function handleActionSetBillsDueModal(e: any) {
    setSetBillsDueModalShow(false);

    setModalContent("loading");
    setModalSuccess(true);

    const token = localStorage.getItem('GroupId') || '{}';
    const config = {
      headers: { 'Authorization': `Bearer ${token}`, 'Content-Type': 'application/json' },
    };

    var manifestId = selectedSingleRow.manifestId;
    var tipo = selectedSingleRow.tipo;

    setSelectedSingleRow(null);

    try {
      const res = await api.post(`/BillsPayable/DeleteBill`,
        {
          "id": manifestId,
          "tipo": tipo,
          "uid": uId,
          "usuario": (userName !== null && userLastName !== null) ? `${userName} ${userLastName}` : userName
        }, config);
      if (res.status !== 400) {
        var dados = res.data.data;
        //console.log(dados.centrosCusto);
        setResponseText(dados.texto);
        setLog(dados.log);

        if(dados.log !== 1) {
          setModalContent("success");
        } else {
          setModalContent("error");
        }
        
      } else {
        setModalContent("error");
        setResponseText("Erro, tente novamente mais tarde");
        setLog(1);
      }
    } catch (error: any) {
      console.log(error.response)
    }
  }

  async function handleActionUndoSettlementModal() {
    setUndoSettlementModalShow(false);

    setModalContent("loading");
    setModalSuccess(true);

    const token = localStorage.getItem('GroupId') || '{}';
    const config = {
      headers: { 'Authorization': `Bearer ${token}`, 'Content-Type': 'application/json' },
    };

    var manifestId = selectedSingleRow.manifestId;
    var tipo = selectedSingleRow.tipo;

    setSelectedSingleRow(null);

    try {
      const res = await api.post(`/BillsPayable/UndoBillSettlement`,
        {
          "manifestId": manifestId,
          "tipo": tipo,
          "uid": uId,
          "usuario": (userName !== null && userLastName !== null) ? `${userName} ${userLastName}` : userName
        }, config);
      if (res.status !== 400) {
        var dados = res.data.data;
        //console.log(dados.centrosCusto);
        setResponseText(dados.texto);
        setLog(dados.log);

        if(dados.log !== 1) {
          setModalContent("success");
        } else {
          setModalContent("error");
        }
        
      } else {
        setModalContent("error");
        setResponseText("Erro, tente novamente mais tarde");
        setLog(1);
      }
    } catch (error: any) {
      console.log(error.response)
    }
  }

  function convertValue(cell: any) {
    //console.log(Math.sign(cell))
    return (
      <>
        <span className={Math.sign(cell) === -1 ? "negative" : ""}>{cell.toFixed(2).replace(".", ",")}</span>
      </>
    )
  }

  /*converte a data UTC para a data local do usuário*/
  /* function convertUTCDateToLocalDate(date: any) {
    var newDate = new Date(date.getTime() + date.getTimezoneOffset() * 60 * 1000);

    var offset = date.getTimezoneOffset() / 60;
    var hours = date.getHours();

    newDate.setHours(hours - offset);

    return newDate.toLocaleString().split(' ')[0];
  } */

  function convertDate(cell: any, row: any) {
    if (cell !== null) {
      //var date = convertUTCDateToLocalDate(new Date(cell));
      var aux: any = cell.split("T")[0].split("-");
      var date: any = `${aux[2]}/${aux[1]}/${aux[0]}`;
      return (
        <>
          {date}
        </>
      );
    } else {
      return (
        <>
        </>
      );
    }
  }

  /* SOBRE A TABELA */

  function addActionButton(cell: any, row: any) {
    return (
      <>
        <div className="d-flex justify-content-center">
          <Dropdown drop="start">
            <Dropdown.Toggle variant="light ">
              <FontAwesomeIcon size="lg" icon={["fal", "ellipsis-h"]} />
            </Dropdown.Toggle>

            <Dropdown.Menu>
              {/* <Dropdown.Item
                eventKey="1"
                onClick={() => { settleBills([row]) }}
              >
                <FontAwesomeIcon
                  icon={['fal', 'dollar-sign']}
                //className="mr-2"
                />
                Liquidar
              </Dropdown.Item> */}
              {row.statusPagamento === 'Pago' ?
                <Dropdown.Item
                  eventKey="1"
                  onClick={() => { unsettleBill(row) }}>
                  <FontAwesomeIcon
                    icon={['fal', 'strikethrough']}
                  //className="mr-2"
                  />
                  Desfazer Liquidação
                </Dropdown.Item>
                :
                <Dropdown.Item
                  eventKey="1"
                  onClick={() => { settleBills([row]) }}>
                  <FontAwesomeIcon
                    icon={['fal', 'dollar-sign']}
                  //className="mr-2"
                  />
                  Liquidar
                </Dropdown.Item>
              }
              <Dropdown.Item
                eventKey="3"
                disabled={row.statusPagamento === 'Pago'}
                onClick={() => editBill(row)}
              >
                <FontAwesomeIcon
                  icon={['fal', 'edit']}
                //className="mr-2"
                />
                {t('suppliersTable.edit')}
              </Dropdown.Item>            
              {level === '19' ?
              <>
                <Dropdown.Divider />
                <Dropdown.Item 
                  eventKey="4"
                  disabled={row.statusPagamento === 'Pago'}
                  onClick={() => handleBillDeleteClick(row)}
                  className="text-danger removeRow"
                >
                  <FontAwesomeIcon 
                    icon={['fal', 'trash-alt']} 
                    //className="mr-2" 
                  />
                  Excluir
                </Dropdown.Item>
              </>
              : <></>}

            </Dropdown.Menu>
          </Dropdown>
        </div>
      </>
    );
  }

  function addStatus(cell: any, row: any) {
    return (
      <div
        className={
          cell === "Conciliado"
            ? "bg-analise"
            : cell === "Pago"
              ? "bg-ativo"
              : cell === "Pendente"
                ? "bg-inativo"
                : ""
        }
      >
        {cell}
      </div>
    );
  }

  function addStatusErp(cell: any, row: any) {
    return (
      <div
        className={
          cell === "Exportado"
            ? "bg-analise"
              : cell === "Pendente"
                ? "bg-inativo"
                : ""
        }
      >
        {cell}
      </div>
    );
  }

  const columns = [
    {
      dataField: "vencimento",
      text: "Vencimento",
      formatter: convertDate,
    },
    { dataField: "fornecedor", text: "Fornecedor" },
    {
      dataField: "dataOperacao",
      text: "Data Operação",
      formatter: convertDate,
    },
    { dataField: "descricao", text: "Tour/Descrição" },
    { dataField: "contaContabil", text: "Categoria" },
    {
      dataField: "valor",
      text: "Valor",
      formatter: convertValue,
    },
    {
      dataField: "statusPagamento",
      text: "Status Pagamento",
      formatter: addStatus,
    },
    {
      dataField: "statusErp",
      text: "Status ERP",
      formatter: addStatusErp,
    },
    {
      dataField: "dataPagamento",
      text: "Data Pagamento",
      formatter: convertDate
    },
    { dataField: "contasaPagarId", text: "Ação", formatter: addActionButton },
  ];

  /* loading table */

  function loadingFunc() {
    return (
      <>
        <div className="animated-background row-loading"></div>
      </>
    )
  }

  const loadingColumns = [{ dataField: "contasd", text: "Carregando Contas", formatter: loadingFunc }];

  const loadingProducts = [
    { contas: "" }, { contas: "" }, { contas: "" }, { contas: "" }, { contas: "" },
    { contas: "" }, { contas: "" }, { contas: "" }, { contas: "" }, { contas: "" }
  ];
  /* [END] loading table */

  /* In case of empty table*/
  const notFoundColumns = [{ dataField: "contas", text: "Contas" }];

  const notFoundProducts = [{ contas: "Nenhuma conta encontrada" }];
  /* [END] In case of empty table*/

  const rowClasses = (row: any, rowIndex: any) => {
    /* console.log(row, rowIndex) */
    if (row.statusPagamento === "Conciliado") {
      return 'border-analise';
    } else if (row.statusPagamento === "Pago") {
      return 'border-ativo';
    } else {
      return 'border-inativo';
    }
  };

  /* FORMULÁRIO */

  const {
    control,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm();

  useEffect(() => {
    if (modalShow === false) {
      reset({
        data: undefined
      })
    }
  }, [reset, /*  */modalShow])

  const onSubmit = (data: any) => {
    setModalResponse('loading');

    var fixDate = datapgto.split('/');
    data.dataPgto = fixDate.length === 3 ? `${fixDate[2]}-${fixDate[1]}-${fixDate[0]}` : undefined;
    data.fornLiquidacao = data.fornLiquidacao ? data.fornLiquidacao.supplierId : 0;
    data.contaLiquidacao = data.contaLiquidacao ? data.contaLiquidacao.id : 0;

    //console.log(selectedSingleRow.length > 0);
    //return;

    async function settle() {
      const token = localStorage.getItem('GroupId') || '{}';
      const config = {
        headers: { 'Authorization': `Bearer ${token}`, 'Content-Type': 'application/json' },
      };
      try {

        const res = await api.post('/BillsPayable/SettleBills',
          {
            "parametros": selectedSingleRow.length > 0 ? null : parameters, // objeto com os parametros da consulta
            "items": selectedSingleRow.length > 0 ?
              [{
                "contasaPagarId": selectedSingleRow[0].contasaPagarId,
                "id": selectedSingleRow[0].manifestId,
                "tipo": selectedSingleRow[0].tipo
              }]
              : [], // array com os itens a serem liquidados
            "fornLiquidacao": data.fornLiquidacao ? parseInt(data.fornLiquidacao) : 0, //
            "descTaxa": data.descTaxa ? parseInt(data.descTaxa) : 0, // valor taxa desconto
            "jurosMulta": data.jurosMulta ? parseInt(data.jurosMulta) : 0, // valor taxa juros
            "contaLiquidacao": data.contaLiquidacao ? parseInt(data.contaLiquidacao) : 0, // conta liquidação
            "dataPgto": data.dataPgto, // data do pagamento
            "uid": uId,
            "usuario": (userName !== null && userLastName !== null) ? `${userName} ${userLastName}` : userName
          }, config
        );
        if (res.status !== 400) {
          if (res.data.data.log === 0) {
            setModalResponse('success');
            setAllowReload(true);
          }
          else if (res.data.data.log === 1) {
            setModalResponse('error');
          }
        }
      } catch (error: any) {
        
        if (error?.response?.status === 401) {
        }
      }
    }
    settle();
  }

  useEffect(() => {
    if (modalResponse === null && allowReload === true) {
      window.location.reload();
    }
  }, [allowReload, /*  */modalResponse])

  /* RENDER */
  if (billsPay !== null) {
    /* console.log(props.suppliers) */
    return (
      <>
        <div className="table-default bills-pay-erp-table">
          <div className="table-container">
            <div>
              <div className="d-flex justify-content-between">
                <div className="d-flex flex-wrap align-items-center" style={{ gap: "15px" }}>
                  <div>
                    <div style={{ cursor: 'pointer' }} className="btn btn-bills" onClick={exportBills}>
                      <FontAwesomeIcon
                        icon={["fal", "share"]}
                        size="1x"
                        style={{ marginRight: "5px" }}
                      />
                      Exportar
                    </div>
                  </div>
                  <div>
                    <div style={{ cursor: 'pointer' }} className="btn btn-bills" onClick={printBills}>
                      <FontAwesomeIcon
                        file-invoice
                        icon={["fal", "print"]}
                        size="1x"
                        style={{ marginRight: "5px" }}
                      />
                      Imprimir
                    </div>
                  </div>
                  {/* <div>
                    <button className="btn btn-bills">
                      <FontAwesomeIcon
                        icon={["fal", "share-all"]}
                        size="1x"
                        style={{ marginRight: "5px" }}
                      />
                      Exportar ERP em Lote
                    </button>
                  </div>  */}
                </div>
                <div className="d-flex align-items-center">
                  <h4 className="mb-0">Total: <span className={Math.sign(totalPay) === -1 ? "negative" : ""}>{totalPay.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })}</span></h4>
                </div>
              </div>
            </div>
            <BootstrapTable
              bootstrap4
              keyField="id"
              data={billsPay}
              columns={columns}
              rowClasses={rowClasses}
              striped={true}
            />
            <Pagination
              totalRows={totalRows}
              pageCount={pageCount}
              setPageCount={setPageCount}
              rowsPerPage={rowsPerPage}
              setRowsPerPage={setRowsPerPage}
              selectNumberRows="yes"
            />
          </div>
        </div>
        <Modal
          className={"modal-custom modalAuth modal-large"}
          show={modalShow}
          onHide={() => setModalShow(false)}
          size="lg"
          aria-labelledby="contained-modal-title-vcenter"
        >
          <>
            <Modal.Header closeButton>
              <h3>
                Liquidar
              </h3>
            </Modal.Header>
            <hr style={{ margin: "30px 0" }} />
            <Form className="h-100" noValidate validated={validated} onSubmit={handleSubmit(onSubmit)} autoComplete="false">
              <FormBillsToPay control={control} errors={errors} datapgto={datapgto} setDatapgto={setDatapgto} modalResponse={modalResponse} setModalResponse={setModalResponse} />
            </Form>
          </>
        </Modal>
        <Modal
          className={"modal-custom modalAuth modal-large"}
          show={editModalShow}
          onHide={() => setEditModalShow(false)}
          size="lg"
          aria-labelledby="contained-modal-title-vcenter"
        >
          <>
            <Modal.Header closeButton>
              <h3>
                Editar Pagamento
              </h3>
            </Modal.Header>
            <hr style={{ margin: "30px 0" }} />
            <EditPayment info={selectedSingleRow} onSuccess={() => { refreshData(); setEditModalShow(false); }} erp={1} />
          </>
        </Modal>

        {/* Modal de Success */}
        <Modal
          className={"modal-confirm loading-modal"}
          show={modalSuccess}
          onHide={() => setModalSuccess(false)}
          size="lg"
          aria-labelledby="contained-modal-title-vcenter"
        >
          <>
            <Modal.Body className='modal-body text-center sucess-pos'>
              {
                modalContent !== "loading"
                  ?
                  <>
                    <div>
                      {
                        log === 2 || log === "2"
                          ?
                          <FontAwesomeIcon
                            icon={["fal", "exclamation-circle"]}
                            size="5x"
                            className="text-primary"
                            style={{ fontSize: "7.5em"}}
                          />
                          :
                          log === 1 || log === "1"
                            ?
                            <FontAwesomeIcon
                              icon={["fal", "times-circle"]}
                              size="5x"
                              className="text-primary"
                              style={{ fontSize: "7.5em"}}
                            />
                            :
                            log === 0 || log === "0"
                              ?
                              <FontAwesomeIcon
                                icon={["fal", "check-circle"]}
                                size="5x"
                                className="text-primary"
                                style={{ fontSize: "7.5em"}}
                              />
                              :
                              <FontAwesomeIcon
                                icon={["fal", "question-circle"]}
                                size="5x"
                                className="text-primary"
                                style={{ fontSize: "7.5em"}}
                              />
                      }
                    </div>
                    <div>
                      {responseText}
                    </div>
                    <div className="d-flex justify-content-center">
                      {
                        modalContent === 'success'
                          ?
                          <button
                            onClick={() => {refreshData(); setModalSuccess(false);}}
                            className="btn btn-primary mx-2 w-25"
                          >
                            Confirmar
                          </button>
                          :
                          modalContent === 'warning'
                            ?
                            <button
                              onClick={() => setModalSuccess(false)}
                              className="btn btn-primary mx-2 w-25"
                            >
                              Confirmar
                            </button>
                            :
                            <button
                              onClick={() => setModalShow(false)}
                              className="btn btn-primary mx-2 w-25"
                            >
                              Confirmar
                            </button>
                      }
                    </div>
                  </>
                  :
                  <div className="modal-body">
                    <div className="text-center">
                      <div className="load"></div>
                    </div>
                    <div>
                      <span>Processando</span>
                    </div>
                    <div></div>
                  </div>
              }
            </Modal.Body>
          </>
        </Modal>
        {/* END - Modal de Success */}

        {/* modal undo settlement */}
        <ModalQuestion
            modalShow={undoSettlementModalShow}
            setModalShow={setUndoSettlementModalShow}
            titulo="Desfazer Liquidação"
            mensagem={undoSettlementText}
            handleActionConfirmModal={handleActionUndoSettlementModal}
        />
        {/* END - modal undo settlement */}

        {/* modal excluir conta */}
        <ModalQuestion
            modalShow={billDeleteModalShow}
            setModalShow={setBillDeleteModalShow}
            titulo="Excluir Pagamento"
            mensagem="Deseja excluir o Pagamento?"
            handleActionConfirmModal={handleActionBillDeleteModal}
            //handleActionCancelModal={handleActionCancelDeleteClick}
          />
        {/* END - modal excluir conta */}
      </>
    );
  } else if (billsPay === null && loading === true) {
    return (
      <>
        <div className="table-default bills-pay-erp-table loading not-found">
          <div className="table-container">
            <div>
              <div className="d-flex justify-content-between">
                <div className="d-flex flex-wrap align-items-center" style={{ gap: "15px" }}>
                  <div>
                    <div style={{ cursor: 'pointer' }} className="btn btn-bills" onClick={exportBills}>
                      <FontAwesomeIcon
                        icon={["fal", "share"]}
                        size="1x"
                        style={{ marginRight: "5px" }}
                      />
                      Exportar
                    </div>
                  </div>
                  <div>
                    <div style={{ cursor: 'pointer' }} className="btn btn-bills" onClick={printBills}>
                      <FontAwesomeIcon
                        file-invoice
                        icon={["fal", "print"]}
                        size="1x"
                        style={{ marginRight: "5px" }}
                      />
                      Imprimir
                    </div>
                  </div>
                  {/* <div>
                    <ModalDefault
                      title="Exportar ERP em Lote"
                      name="Exportar ERP em Lote"
                      icon="yes"
                      typeIcon="share-all"
                      classBtn="btn btn-bills"
                      classModal="modal-large modal-large-custom"
                    >
                      
                    </ModalDefault>
                  </div> */}
                </div>
                <div className="d-flex align-items-center">
                  <h4 className="mb-0">Total: <span className={Math.sign(totalPay) === -1 ? "negative" : ""}>{totalPay.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })}</span></h4>
                </div>
              </div>
            </div>
            <BootstrapTable
              bootstrap4
              keyField="id"
              data={loadingProducts}
              hover={true}
              columns={loadingColumns}
              striped={true}
            />
          </div>
        </div>
      </>
    )
  } else {
    return (
      <>
        <div className="table-default bills-pay-erp-table not-found">
          <div className="table-container">
            {/*             <div className="table-title">
              <h3 className="">Fornecedores</h3>
            </div> */}

            <BootstrapTable
              bootstrap4
              keyField="id"
              data={notFoundProducts}
              hover={true}
              columns={notFoundColumns}
              striped={true}
            />
          </div>
        </div>
      </>
    );
  }
}

export default TableBillsPayERP;
