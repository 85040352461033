import React, { useState, useEffect } from 'react';

import api from '../../../../services/api';

import "react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css";
import "react-bootstrap-table-next/dist/react-bootstrap-table2.css";
import BootstrapTable, { SelectRowProps } from "react-bootstrap-table-next";

import Pagination from "../../../../components/Pagination/Pagination";

import { useForm } from "react-hook-form";

import "./Expiration.scss";
import ModalConfirm from '../../ModalConfirm/ModalConfirm';

import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';

export interface propExp {
    billsPay: any;
    totalRows: any;
    pageCount: any;
    setPageCount: any;
    rowsPerPage: any;
    setRowsPerPage: any;
    setExpiration: any;
    expiration: any;
    actualDate: any;
    parameters: any;
    changeAll?: any;
    t?: any;
}

const Expiration: React.FC<propExp> = ({
    billsPay, totalRows, pageCount, rowsPerPage, expiration, actualDate, parameters, t, changeAll = false
}: propExp) => {
    //const [selectedSingleRow, setSelectedSingleRow] = useState<any>();
    //const [modalShow, setModalShow] = useState<any>(false);
    //const [selectedCheck, setSelectedCheck] = useState<any>([]);
    const [itemsToSettle, setItemsToSettle] = useState<any>([]);
    const [responseText, setResponseText] = useState<any>();
    const [modalResponse, setModalResponse] = useState<any>();
    const [expirationBills, setExpirationBills] = useState<any>(billsPay);
    const [expirationPageCount, setExpirationPageCount] = useState<any>(pageCount);
    const [expirationRowsPerPage, setExpirationRowsPerPage] = useState<any>(rowsPerPage);

    const [loading, setLoading] = useState<any>(false);

    const tourAuth: any = JSON.parse(localStorage.getItem('c2tourAuth') || '{}');

    const userName: any = tourAuth.user.nome;
    const userLastName: any = tourAuth.user.lastName;
    const uId: any = tourAuth.user.uId;

    const {
        handleSubmit,
    } = useForm();

    const onSubmit = (data: any) => {
        let beginDate: any = []
        let endDate: any = []

        if (actualDate.length === 1) {
            beginDate = actualDate[0].split('/')
        } else if (actualDate.length === 2) {
            beginDate = actualDate[0].split('/')
            endDate = actualDate[1].split('/')
        }

        let today: any = new Date();

        data.dataVcto = expiration.split("/").reverse().join('-');
        data.dataInicial = (actualDate.length > 0) ? `${beginDate[2]}-${beginDate[1]}-${beginDate[0]}` : `${today.getFullYear()}-${today.getMonth() + 1}-${today.getDate()}`
        data.dataFinal = (actualDate.length === 1) ? `${beginDate[2]}-${beginDate[1]}-${parseInt(beginDate[0])}` : (actualDate !== null && actualDate.length === 2) ? `${endDate[2]}-${endDate[1]}-${endDate[0]}` : `${today.getFullYear()}-${today.getMonth() + 1}-${today.getDate()}`;

        let auxExp: any = [];

        for (let i: any = 0; i < itemsToSettle.length; i++) {
            auxExp.push(
                {
                    "id": itemsToSettle[i].manifestId,
                    "tipo": itemsToSettle[i].tipo
                }
            )
        }

        data.items = auxExp;

        setModalResponse('loading');

        async function changeExpiration() {
            if (itemsToSettle.length !== 0 && expiration !== "") {
                try {
                    const token = localStorage.getItem('GroupId') || '{}';
                    const config = {
                        headers: { 'Authorization': `Bearer ${token}`, 'Content-Type': 'application/json' },
                    };
                    
                    const res = await api.post('/BillsPayable/SetDueDateInBatch',
                        {
                            "parametros": null
                            //"parametros": parameters
                            /* {
                                "page": 0,
                                "rowsPerPage": 0,
                                "item": "",
                                "statusPagamento": null,
                                "fornecedor": "",
                                "tipoconta": null,
                                "tipodata": 3,
                                "dataInicial": data.dataInicial,
                                "dataFinal": data.dataFinal
                            } */,
                            "items": data.items,
                            "dataVcto": data.dataVcto,
                            "uid": uId,
                            "usuario": `${userName} ${userLastName}`
                        }, config
                    );
                    if (res.data.status !== 400) {
                        if (res.data.data.log === 0) {
                            setResponseText(res.data.data.texto)
                            setModalResponse('success');
                            /* setAllowReload(true); */
                        }
                        else if (res.data.data.log === 1) {
                            setResponseText(res.data.data.texto)
                            setModalResponse('error');
                        }
                    }
                } catch (error: any) {
                    
                    if (error?.response?.status === 401) {
                    }
                }
            } else {
                setResponseText(t('billsToPay.table.expirationModal.fillUpTheFields'));
                setModalResponse('error');
            }
        }
        changeExpiration();

    }

    /* FUNÇÕES */

    /* async function settleBills(row: any) {
        setSelectedSingleRow(row);  //recebe array vazio ([]) para liquidar consulta
        setModalShow(true);
    } */

    function convertValue(cell: any) {
        //console.log(Math.sign(cell))
        return (
            <>
                <span className={Math.sign(cell) === -1 ? "negative" : ""}>{cell.toFixed(2).replace(".", ",")}</span>
            </>
        )
    }


    /*converte a data UTC para a data local do usuário*/
    /* function convertUTCDateToLocalDate(date: any) {
        var newDate = new Date(date.getTime() + date.getTimezoneOffset() * 60 * 1000);

        var offset = date.getTimezoneOffset() / 60;
        var hours = date.getHours();

        newDate.setHours(hours - offset);

        return newDate.toLocaleString().split(' ')[0];
    } */

    function convertDate(cell: any, row: any) {
        if (cell !== null) {
            //var date = convertUTCDateToLocalDate(new Date(cell));
            var aux: any = cell.split("T")[0].split("-");
            var date: any = `${aux[2]}/${aux[1]}/${aux[0]}`;
            return (
                <>
                    {date}
                </>
            );
        } else {
            return (
                <>
                </>
            );
        }
    }

    /* SOBRE A TABELA */

    function addStatus(cell: any, row: any) {
        return (
            <div
                className={
                    cell === "Conciliado"
                        ? "bg-analise w-100"
                        : cell === "Pago"
                            ? "bg-ativo w-100"
                            : cell === "Pendente"
                                ? "bg-inativo w-100"
                                : ""
                }
            >
                {cell}
            </div>
        );
    }

    // function handleClick(cell: any) {
    //     const getBills = expirationBills.find((element: { manifestId: number; }) => element.manifestId === parseInt(cell));
    //     setSelectedCheck((prevState: any) => [...prevState, getBills]);
    // }

    // useEffect(() => {
    //     if (selectedCheck.length > 1) {
    //         var lastOne: any = selectedCheck[selectedCheck.length - 1];
    //         var aux: any = [];
    //         var add: boolean = true;
    //         for (var i: any = 0; i < itemsToSettle.length; i++) {
    //             if (itemsToSettle[i].manifestId !== lastOne.manifestId) {
    //                 aux.push(itemsToSettle[i]);
    //             } else {
    //                 add = false;
    //             }
    //         }

    //         if (add === true) {
    //             setItemsToSettle((prevState: any) => [...prevState, lastOne]);
    //         } else {
    //             setItemsToSettle(aux);
    //         }

    //     } else if (selectedCheck.length === 1) {
    //         setItemsToSettle(selectedCheck);
    //     }

    //     // eslint-disable-next-line react-hooks/exhaustive-deps
    // }, [/* itemsToSettle, /*  */selectedCheck])

    // function confereChecked(cell: any) {
    //     for (var i: any = 0; i < itemsToSettle.length; i++) {
    //         if (itemsToSettle[i].manifestId === cell) {
    //             return true;
    //         }
    //     }
    //     return false;
    // }


    // function addCheckButton(cell: any, row: any) {
    //     return (
    //         <>
    //             <div className="center-table">
    //                 <Controller
    //                     control={control}
    //                     name={'' + cell}
    //                     render={({ field }: any) => (
    //                         <Form.Check
    //                             {...field}
    //                             fullWidth
    //                             variant="standard"
    //                             margin="normal"
    //                             type="checkbox"
    //                             defaultChecked={confereChecked(cell)}
    //                             value={cell}
    //                             onClick={() => { handleClick(cell) }}
    //                         />
    //                     )}
    //                 />
    //             </div>
    //         </>
    //     )
    // }

    function textReduce(cell: any, row: any) {
        return (
            <>
                <OverlayTrigger
                    overlay={
                        <Tooltip id="tooltip">{cell}</Tooltip>
                    }>
                    <span className="text-wrap-custom">
                        {cell}
                    </span>
                </OverlayTrigger>
            </>
        )
    }

    const columns = [
        /* {
            dataField: 'manifestId',
            text: ``,
            formatter: addCheckButton
        }, */
        {
            dataField: "vencimento",
            text: t('billsToPay.table.expirationModal.columns.expiration'),
            formatter: convertDate,
        },
        {
            dataField: "dataOperacao",
            text: t('billsToPay.table.expirationModal.columns.operationDate'),
            formatter: convertDate,
        },
        {
            dataField: "fornecedor",
            text: t('billsToPay.table.expirationModal.columns.supplier')
        },
        {
            dataField: "descricao",
            text: t('billsToPay.table.expirationModal.columns.description'),
            formatter: textReduce,
        },
        {
            dataField: "contaContabil",
            text: t('billsToPay.table.expirationModal.columns.category')
        },
        {
            dataField: "valor",
            text: t('billsToPay.table.expirationModal.columns.value'),
            formatter: convertValue,
        },
        {
            dataField: "statusPagamento",
            text: t('billsToPay.table.expirationModal.columns.paymentStatus'),
            formatter: addStatus,
        },
    ];

    /* loading table */

    function loadingFunc() {
        return (
        <>
            <div className="animated-background row-loading"></div>
        </>
        )
    }

    const loadingColumns = [{ dataField: "contasd", text: t('billsToPay.table.expirationModal.columns.loadingBills'), formatter: loadingFunc }];

    const loadingProducts = [
        { contas: "" }, { contas: "" }, { contas: "" }, { contas: "" }, { contas: "" },
        { contas: "" }, { contas: "" }, { contas: "" }, { contas: "" }, { contas: "" }
    ];
    /* [END] loading table */

    /*--------------------------------------------------*/

    /* In case of empty table*/
    /* const notFoundColumns = [{ dataField: "contas", text: "Contas" }];

    const notFoundProducts = [{ contas: "Nenhuma conta encontrada" }]; */
    /* [END] In case of empty table*/

    const rowClasses = (row: any, rowIndex: any) => {
        if (row.statusPagamento === "Conciliado") {
            return 'border-analise';
        } else if (row.statusPagamento === "Pago") {
            return 'border-ativo';
        } else {
            return 'border-inativo';
        }
    }

    useEffect(() => {
        let beginDate: any = []
        let endDate: any = []

        if (actualDate.length === 1) {
            beginDate = actualDate[0].split('/')
        } else if (actualDate.length === 2) {
            beginDate = actualDate[0].split('/')
            endDate = actualDate[1].split('/')
        }

        let today: any = new Date();

        async function billTopPay() {
            setLoading(true);

            const token = localStorage.getItem('GroupId') || '{}';
            const config = {
                headers: { 'Authorization': `Bearer ${token}`, 'Content-Type': 'application/json' },
            };

            try {
                const { data } = await api.post('/BillsPayable/GetReport',
                    {
                        "page": expirationPageCount,
                        "rowsPerPage": expirationRowsPerPage,
                        "item": parameters.item,
                        "statusPagamento": parameters.statusPagamento,
                        "fornecedor": parameters.fornecedor,
                        "tipoConta": parameters.tipoConta,
                        "tipoData": parameters.tipoData,
                        "empresa": parameters.empresa,
                        "dataInicial": (actualDate.length > 0) ? `${beginDate[2]}-${beginDate[1]}-${beginDate[0]}` : `${today.getFullYear()}-${today.getMonth() + 1}-${today.getDate()}`,
                        "dataFinal": (actualDate.length === 1) ? `${beginDate[2]}-${beginDate[1]}-${parseInt(beginDate[0])}` : (actualDate !== null && actualDate.length === 2) ? `${endDate[2]}-${endDate[1]}-${endDate[0]}` : `${today.getFullYear()}-${today.getMonth() + 1}-${today.getDate()}`
                    }, config
                );

                if (data.status !== 400) {
                    setExpirationBills(data.data.rows);
                }
            } catch (error: any) {
                if (error.response.status === 401) {
                    window.location.href = window.location.origin + '/';
                }
            }
            setLoading(false);
        }
        billTopPay();
        // eslint-disable-next-line react-hooks/exhaustive-deps

    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [expirationPageCount, expirationRowsPerPage])

    const selectRow: SelectRowProps<any> = {
        mode: 'checkbox',
        clickToSelect: true,
        onSelect: (row, isSelect, rowIndex, e) => {
            if (isSelect === true) {
                var add: any;
                add = [...itemsToSettle, row];
                setItemsToSettle(add);
            } else {
                var exclude: any = [];
                for (var i: any = 0; i < itemsToSettle.length; i++) {
                    if (itemsToSettle[i].manifestId !== row.manifestId) {
                        exclude.push(row);
                    }
                }

                setItemsToSettle(exclude);
            }
        },
        onSelectAll: (isSelect, rows, e) => {
            if (isSelect === true) {
                setItemsToSettle(rows);
            } else {
                setItemsToSettle([]);
            }
        }
    }

    if(loading !== true) {
        return (
            <>
                <div className="table-default bills-pay-table mt-0">
                    <div className="table-container p-0">
                        {changeAll === true
                            ?
                            <></>
                            :
                            <>
                                <BootstrapTable
                                    bootstrap4
                                    keyField="manifestId"
                                    data={expirationBills}
                                    columns={columns}
                                    //rowClasses={rowClasses}
                                    striped={true}
                                    selectRow={selectRow}
                                />
                                <Pagination
                                    totalRows={totalRows}
                                    pageCount={expirationPageCount}
                                    setPageCount={setExpirationPageCount}
                                    rowsPerPage={expirationRowsPerPage}
                                    setRowsPerPage={setExpirationRowsPerPage}
                                    selectNumberRows="yes"
                                />
                            </>}
                        <ModalConfirm
                            handleSubmit={handleSubmit}
                            onSubmit={onSubmit}
                            expiration="yes"
                            confirm={responseText}
                            modalContent={modalResponse}
                        />
                    </div>
                </div>
            </>
        )
    } else {
        return (
            <>
                <div className="table-default bills-pay-table mt-0 loading not-found">
                    <div className="table-container p-0">
                        {/* <BootstrapTable
                            bootstrap4
                            keyField="manifestId"
                            data={expirationBills}
                            columns={columns}
                            rowClasses={rowClasses}
                            striped={true}
                            selectRow={selectRow}
                        /> */}

                        <BootstrapTable
                            bootstrap4
                            keyField="id"
                            data={loadingProducts}
                            hover={true}
                            columns={loadingColumns}
                            rowClasses={rowClasses}
                            striped={true}
                        />
                        {/* <Pagination
                            totalRows={totalRows}
                            pageCount={expirationPageCount}
                            setPageCount={setExpirationPageCount}
                            rowsPerPage={expirationRowsPerPage}
                            setRowsPerPage={setExpirationRowsPerPage}
                            selectNumberRows="yes"
                        /> */}
                        <div className='mt-4 d-flex justify-content-end'>
                            <button className="btn btn-default btn-outline-primary px-4 mr-3 text-uppercase" disabled={true}>
                            {t('billsToPay.table.expirationModal.cancel')}
                            </button>
                            <button className="btn btn-default btn-primary px-4 text-uppercase" disabled={true}>
                                {t('billsToPay.table.expirationModal.confirm')}
                            </button>
                        </div>
                    </div>
                </div>
            </>
        )
    }
}

export default Expiration;