import { useState, useEffect } from 'react';
import api from '../../services/api';

//COMPONENTS
import Breadcrumb from '../../components/Breadcrumb/Breadcrumb';
import DesktopDefault from '../../templates/DesktopDefault';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Modal } from 'react-bootstrap';
import AddZoneComponent from './components/AddZoneComponent/AddZoneComponent';

function EditZone() {
    const [zoneObj, setZoneObj] = useState<any>(null);

    const [modalShow, setModalShow] = useState<any>(false);
    const [modalMessage, setModalMessage] = useState<any>('Carregando');
    const [modalLog, setModalLog] = useState<any>(null);

    const idZone = window.location.href.split('id=')[1];

    useEffect(() => {
        const token = localStorage.getItem('GroupId') || '{}';
        const config = {
            headers: { 'Authorization': `Bearer ${token}` },
        };
        const zoneById = async () => {
            try {
                const { data } = await api.post(`Zone/GetZoneById/${idZone}`, {}, config);
                if (data.status !== 400) {
                    setZoneObj(data.data)
                }
            } catch (error: any) {
                console.log(error.response)

            }
        }
        zoneById();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [idZone])

    const UpdateZone = async (data: any) => {
        setModalShow(true);

        try {
            const res = await api.post(`/Zone/EditZones`,
                {
                    "id": idZone,
                    "zona": data.zoneName,
                    "destino": data.placeName || data.zoneName,
                    "groupId": data.supplier,
                    "status": 1,
                    "zone": data.zones
                });
            if (res.status !== 400) {
                if (res.data.statusCode === 200) {
                    setModalLog(0);
                    setModalMessage('Zona editada com sucesso!');
                }
            } else {
                setModalLog(1);
                setModalMessage('Erro ao processar operação');
            }
        } catch (error: any) {
            setModalLog(1);
            setModalMessage('Erro ao processar operação');
        }
    };

    return (
        <>
            <DesktopDefault>
                <div className="container-fluid content-dashboard add-zones">
                    <Breadcrumb title="Zonas / Editar Zona" />
                    <div className="partners mt-4">
                        <div className="d-flex align-items-center">
                            <span className='back' onClick={() => { window.location.href = '/zones/list-zones' }}>
                                <FontAwesomeIcon
                                    icon={["fal", "angle-double-left"]}
                                    style={{ margin: "0 5px 0 0", fontSize: '16px' }}
                                />
                                Voltar</span>
                        </div>
                        <h2 className="title" style={{ color: "#707070" }}>Editar Zona</h2>

                        {/* Content */}

                        {zoneObj !== null
                            ?
                            <AddZoneComponent isEdit={true} infoEdit={zoneObj} actionFunc={UpdateZone} />
                            :
                            <></>
                        }

                        {/* Content */}

                        <Modal
                            className="modalAuth"
                            show={modalShow}
                            onHide={() => {
                                setModalLog(null);
                                setModalMessage("Carregando");
                                setModalShow(false);
                            }}
                            aria-labelledby="contained-modal-title-vcenter"
                        >
                            <>
                                <Modal.Body
                                    className="modal-body text-center sucess-pos d-flex justify-content-evenly"
                                    style={{ height: "600px" }}
                                >
                                    {modalLog === null ? (
                                        <>
                                            <div className="loading-modal">
                                                <div className="load"></div>
                                            </div>
                                        </>
                                    ) : (
                                        <div>
                                            {modalLog === 0 ? (
                                                <FontAwesomeIcon
                                                    icon={["fal", "check-circle"]}
                                                    size="5x"
                                                    className="text-primary"
                                                    style={{ fontSize: "7.5em"}}
                                                />
                                            ) : (
                                                <FontAwesomeIcon
                                                    icon={["fal", "times-circle"]}
                                                    size="5x"
                                                    className="text-primary"
                                                    style={{ fontSize: "7.5em"}}
                                                />
                                            )}
                                        </div>
                                    )}
                                    <div>{modalMessage}</div>
                                    <div className="d-flex justify-content-center pt-3">
                                        <button
                                            onClick={() => {
                                                setModalLog(null);
                                                setModalMessage("Carregando");
                                                setModalShow(false);
                                            }}
                                            className="btn btn-primary mx-2 w-25"
                                        >
                                            Confirmar
                                        </button>
                                    </div>
                                </Modal.Body>
                            </>
                        </Modal>

                    </div>
                </div>
            </DesktopDefault>
        </>

    );
}

export default EditZone;