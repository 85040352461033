import React, { useEffect, useState } from 'react';
import api from '../../services/api';

import Form from 'react-bootstrap/Form';

export interface propPlus {
  propsField: any,
  propsLabel: any,
  propsErrors: any,
  type: any,
  setResource: any,
  supplierId?: any
};

const GetSupplierResources: React.FC<propPlus> = ({
  propsField, propsLabel, propsErrors, type, setResource, supplierId
}: propPlus) => {
  const [info, setInfo] = useState<any>(null);

/*   const [isMenuOpen, setIsMenuOpen] = useState<boolean>(false);

  const onMenuOpen = () => {
    if (isMenuOpen === false) {
      setIsMenuOpen(true);
    } else {
      setIsMenuOpen(false);
    }
  } */

  useEffect(() => {
    const token = localStorage.getItem('GroupId') || '{}';
    const config = {
      headers: { 'Authorization': `Bearer ${token}` },
    };
    async function productsListCod() {
      try {
        const { data } = await api.post(`/Manifest/GetSupplierResources`, {
          tipo: type,
          supplierId: supplierId ? supplierId : 0
        }, config);
        if (data.status !== 400) {
          setInfo(data.data)
        }
      } catch (error: any) {
        if (error.response.status === 401) {
          window.location.href = window.location.origin + '/';
        }
      }
    }
    productsListCod();
  }, [type, supplierId]);

  const handleChangeMethod = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = e.target.value;
    if(setResource){
      setResource(newValue);
    }
    propsField.onChange(e);
  }

  if (info !== null) {
    return (
      <>
        <div className={propsErrors[propsField.name] !== undefined ? "endpoint-error" : "endpoint"}>
            <Form.Control
              as="select"
              {...propsField}
              required
              autoComplete='off'
              className="form-select"
              onChange={handleChangeMethod}
            >
              <option value='0'>Todos</option>
              {info.length > 0 ? info.map((info: any) => (
                <option value={info.id}>{info.descricao}</option>
              )) : ''}
            </Form.Control>
          {/* <Select
            aria-labelledby="aria-label"
            inputId="aria-example-input"
            name="aria-live-color"
            onMenuOpen={onMenuOpen}
            onMenuClose={onMenuOpen}
            {...propsField}
            //aria-invalid={propsErrors?.coastCenterId ? "true" : ""}
            label={propsLabel}
            as="select"
            variant="standard"
            margin="normal"
            placeholder={t("accordionFinancial.select.select")}
            options={Array.from(info)}
            getOptionLabel={(option: any) => `${option.name}`}
          /> */}
        </div>
      </>
    );
  } else {
    return (
      <>
        <Form.Select>
          <option value='' disabled></option>
        </Form.Select>
      </>
    )
  }
}

export default GetSupplierResources;