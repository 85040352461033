import React, { useState, useEffect } from 'react';
import api from '../../services/api';

//COMPONENTS
import Breadcrumb from '../../components/Breadcrumb/Breadcrumb';

import DesktopDefault from '../../templates/DesktopDefault';
import SingleUser from './components/SingleUser/SingleUser';
import RegisterUsers from './components/RegisterUsers/RegisterUsers';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Modal } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

function EditUser() {
    const { t } = useTranslation();
    const [userInfo, setUserInfo] = useState<any>(null);
    const [isEdit, setIsEdit] = useState<any>(false);
    const [errorMessage, setErrorMessage] = useState<any>('');
    const [error, setError] = useState<any>(false);
    const [userLevel, setUserLevel] = useState<string>("");

    useEffect(() => {
        const idUser: any = window.location.href.split('?id=')[1].split('&')[0];

        async function singleUser() {
            const token = localStorage.getItem('GroupId') || '{}';
            const config = {
                headers: { 'Authorization': `Bearer ${token}` },
            };

            try {
                const { data } = await api.post('/UserPermission/GetListUserPermissionById',
                    {
                        "IdUser": idUser,
                    }, config
                );
                if (data.statusCode !== 400) {
                    if (data.data.log === 0) {
                        setUserInfo(data.data.data[0]);
                    } else {
                        setErrorMessage(data.data.texto);
                        setError(true);
                    }
                    //setProfileImg(data.data.data[0].photoUrl)
                }
            } catch (error: any) {
                if (error?.response?.status === 401) {
                    //window.location.href = window.location.origin + '/';
                }
            }
        }
        singleUser();

    }, []);


    useEffect(()=>{
        const token = localStorage.getItem('GroupId') || '{}';
    const config = {
      headers: { 'Authorization': `Bearer ${token}` },
    };
    async function listStates() {
      try {
        const { data } = await api.get(`/DropDown/GetUserRolesIntern`, config);
        if (data.status !== 400) {
            data.data.forEach((elem:any)=> {
                if(elem.id === userInfo.level){
                    setUserLevel(elem);
                }
            });          
        }
      } catch (error: any) {
        if (error.response?.status === 401) {          
        }
      }
    }
    listStates();
    },[userInfo])

console.log(userLevel)
    return (
        <>
            <DesktopDefault>
                <div className="container-fluid content-dashboard">

                    <Breadcrumb title={`${t('editUser.editUser.path')}`} />

                    <div className="register-users mt-4">
                        <div className="d-flex align-items-center">
                            <span className='back' onClick={() => { window.location.href = '/users' }}>
                                <FontAwesomeIcon
                                    icon={["fal", "angle-double-left"]}
                                    style={{ margin: "0 5px 0 0", fontSize: '16px' }}
                                />
                                {t('editUser.editUser.back')}</span>
                        </div>
                        <h2 className="title">{t('editUser.editUser.edit')}</h2>
                    </div>

                    {isEdit === false
                        ?
                        <SingleUser userInfo={userInfo} setIsEdit={setIsEdit} userLevel={userLevel}/>
                        :
                        <RegisterUsers isEdit={isEdit} userInfo={userInfo} setIsEdit={setIsEdit} userLevel={userLevel}/>
                    }

                </div>
                <Modal
                    className=""
                    show={error === true}
                    onHide={() => window.location.href = '/users'}
                >
                    <div className="modal-body d-flex flex-column align-items-center justify-content-evenly" style={{height: '600px'}}>
                        <div className="text-center">
                            <FontAwesomeIcon
                                icon={["fal", "times-circle"]}
                                size="5x"
                                style={{ fontSize: "7.5em", color: "#076fa2" }}
                            />
                        </div>
                        <div>
                            <span>{errorMessage}</span>
                        </div>
                        <div>
                            <button
                                onClick={() => { window.location.href = '/users' }}
                                className="btn btn-primary mx-2"
                            >
                                {t('editUser.editUser.confirm')}
                            </button>
                        </div>
                    </div>
                </Modal>
            </DesktopDefault>
        </>

    );
}

export default EditUser;