import React, {  useState } from "react";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {  Modal, Dropdown } from "react-bootstrap";

//import { useTranslation } from "react-i18next";

import "react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css";
import "react-bootstrap-table-next/dist/react-bootstrap-table2.css";
import BootstrapTable from "react-bootstrap-table-next";

import Pagination from "../../../../components/Pagination/Pagination";

import "./CouponsTable.scss";

//STYLES
// import "./TableBillsPay.scss";
/* import Pagination from "../../../../../components/Pagination/Pagination"; */
//import ModalDefault from "../../../../../components/Modal/ModalDefault/ModalDefault";
/* import DebitNotes from "../../../../../components/Modal/ModalDefault/DebitNotes/DebitNotes";
import Payment from "../../../../../components/Modal/ModalDefault/Payment/Payment";
import FormBillsToPay from "../../../../../components/Forms/FormBillsToPay/FormBillsToPay"; */

/* import { Controller, useForm } from "react-hook-form"; */
//import InsertCoupom from "./InsertCoupom/InsertCoupom";
import ListCoupom from "../ListCoupom/ListCoupom";

export interface propTable {
  coupons: any;
  totalRows: any;
  pageCount: any;
  setPageCount: any;
  rowsPerPage: any;
  setRowsPerPage: any;
  actualDate: any;
  loading: any;
  setModalSuccess: any;
  setResponseText: any;
  setModalContent: any;
  setLog: any;
}

const CouponsTable: React.FC<propTable> = ({
  coupons,
  totalRows,
  pageCount,
  setPageCount,
  rowsPerPage,
  setRowsPerPage,
  actualDate,
  loading,
  setModalSuccess,
  setResponseText,
  setModalContent,
  setLog
}: propTable) => {
  //const { t } = useTranslation();

  //const [modalShow, setModalShow] = useState<any>(false);

  const [editClick, setEditClick] = useState<any>(false);
  const [detailsModal, setDetailsModal] = useState<any>(false);
  const [currentCoupom, setCurrentCoupom] = useState<any>();

  function seeDetailsEdit(cell: any, allowEdit: boolean) {
    setEditClick(allowEdit);
    setCurrentCoupom(cell);
    setDetailsModal(true);
  }

  const addIsCupom = (cell: any, row: any) => {
    return(
      <>
      {cell === 1 ? "Cupom" : "TourCode"}
      </>
    )
  }

  const columns = [
    { dataField: "code", text: "Código", sort: true },
    { dataField: "beginDate", text: "Data início", sort: true, formatter: convertDate },
    { dataField: "endDate", text: "Data fim", sort: true, formatter: convertDate },
    { dataField: "supplierName", text: "Afiliado", sort: true },
    { dataField: "isCupom", text: "Tipo", sort: true, formatter: addIsCupom },
    { dataField: "code", text: "Ações", formatter: addActionButton },
  ];

  /* loading table */

  function loadingFunc() {
    return (
      <>
        <div className="animated-background row-loading"></div>
      </>
    );
  }

  const loadingColumns = [
    { dataField: "cuponsd", text: "Carregando Cupons", formatter: loadingFunc },
  ];

  const loadingProducts = [
    { Cupons: "" },
    { Cupons: "" },
    { Cupons: "" },
    { Cupons: "" },
    { Cupons: "" },
    { Cupons: "" },
    { Cupons: "" },
    { Cupons: "" },
    { Cupons: "" },
    { Cupons: "" },
  ];
  /* [END] loading table */

  /* In case of empty table*/
  const notFoundColumns = [{ dataField: "cupons", text: "Cupons" }];

  const notFoundProducts = [{ contas: "Nenhuma conta encontrada" }];
  /* [END] In case of empty table*/

  function convertDate(cell: any, row: any) {
    if (cell !== null) {
      //var date = convertUTCDateToLocalDate(new Date(cell));
      var aux: any = cell.split("T")[0].split("-");
      var date: any = `${aux[2]}/${aux[1]}/${aux[0]}`;

      return <>{date}</>;
    } else {
      return <></>;
    }
  }

  //botao de ação
  function addActionButton(cell: any, row: any) {
    return (
      <>
        <div className="d-flex justify-content-center">
          <Dropdown drop="start">
            <Dropdown.Toggle variant="light ">
              <FontAwesomeIcon size="lg" icon={["fal", "ellipsis-h"]} />
            </Dropdown.Toggle>

            <Dropdown.Menu>
              <Dropdown.Item eventKey="1"
                onClick={() => {
                  seeDetailsEdit(cell, false);
                }}>
                <FontAwesomeIcon
                  icon={["fal", "file-alt"]}
                  //className="mr-2"
                />
                Detalhes
              </Dropdown.Item>
              {/* <Dropdown.Item eventKey="2">
                <ModalDefault
                  title="Editar Cupom"
                  name="Editar"
                  icon="yes"
                  typeIcon="edit"
                  classBtn="px-0"
                  classModal="modal-pos-tax modal-edit-coupom d-flex flex-row"
                  allowEdit={true}
                >
                  <ListCoupom />
                </ModalDefault>
              </Dropdown.Item> */}

              <Dropdown.Item
                eventKey="2"
                onClick={() => {
                  seeDetailsEdit(cell, true);
                }}
              >
                <FontAwesomeIcon
                  icon={["fal", "edit"]}
                  //className="mr-2"
                />
                Editar
              </Dropdown.Item>

              {/*               <Dropdown.Item eventKey="3" disabled={true}>
                <FontAwesomeIcon icon={["fal", "circle"]} className="mr-2" />{" "}
                {t("suppliersTable.status")}
              </Dropdown.Item>
              <Dropdown.Item eventKey="5" disabled={true}>
                <FontAwesomeIcon
                  icon={["fal", "file-contract"]}
                  className="mr-2"
                />{" "}
                {t("suppliersTable.contract")}
              </Dropdown.Item>
              <Dropdown.Item
                eventKey="4"
                disabled={true}
                className="text-danger remove"
              >
                <FontAwesomeIcon icon={["fal", "trash-alt"]} className="mr-2" />{" "}
                {t("suppliersTable.remove")}
              </Dropdown.Item> */}
            </Dropdown.Menu>
          </Dropdown>
        </div>
      </>
    );
  }
  /* RENDER */
  if (coupons !== null) {
    return (
      <>
        <div className="table-default coupons-table">
          <div className="table-container">
            <div>
              {/*               <div className="d-flex">
                <div>
                  <ModalDefault
                    title="Inserir Novo Cupom"
                    name="Inserir"
                    icon="yes"
                    typeIcon="plus-circle"
                    classBtn="btn btn-bills"
                    classModal="modal-large"
                  >
                    <InsertCoupom
                      show={setModalSuccess}
                      setResponseText={setResponseText}
                      setModalContent={setModalContent}
                      setLog={setLog}
                    />
                  </ModalDefault>
                </div>
              </div> */}
            </div>

            <BootstrapTable
              bootstrap4
              keyField="id"
              data={coupons}
              hover={true}
              columns={columns}
              striped={true}
            />

            <Pagination
              totalRows={totalRows}
              pageCount={pageCount}
              setPageCount={setPageCount}
              rowsPerPage={rowsPerPage}
              setRowsPerPage={setRowsPerPage}
              selectNumberRows="yes"
            />

            <Modal
              className="modal-custom edit-coupons"
              show={detailsModal !== false}
              onHide={() => { setDetailsModal(false); setCurrentCoupom(null); setEditClick(false); }}
              size="lg"
              aria-labelledby="contained-modal-title-vcenter"
            >
              <>
                <ListCoupom
                  currentCoupom={currentCoupom}
                  editClick={editClick}
                  show={setModalSuccess}
                  setResponseText={setResponseText}
                  setModalContent={setModalContent}
                  setLog={setLog}
                />
              </>
            </Modal>
          </div>
        </div>
      </>
    );
  } else if (coupons === null && loading === true) {
    return (
      <>
        <div className="table-default coupons-table loading not-found">
          <div className="table-container">
            <div>
              {/* <div className="d-flex">
                <div>
                  <ModalDefault
                    title="Inserir Novo Cupom"
                    name="Inserir"
                    icon="yes"
                    typeIcon="plus-circle"
                    classBtn="btn btn-bills"
                    classModal="modal-large"
                  >
                    <InsertCoupom
                      show={setModalSuccess}
                      setResponseText={setResponseText}
                      setModalContent={setModalContent}
                      setLog={setLog}
                    />
                  </ModalDefault>
                </div>
              </div> */}
            </div>

            <BootstrapTable
              bootstrap4
              keyField="id"
              data={loadingProducts}
              hover={true}
              columns={loadingColumns}
              striped={true}
            />

            <Pagination
              totalRows={totalRows}
              pageCount={pageCount}
              setPageCount={setPageCount}
              rowsPerPage={rowsPerPage}
              setRowsPerPage={setRowsPerPage}
              selectNumberRows="yes"
            />

            <Modal
              className="modal-custom reservation-details"
              show={detailsModal !== false}
              onHide={() => {
                setDetailsModal(false);
                setCurrentCoupom(null);
                setEditClick(false);
              }}
              size="lg"
              aria-labelledby="contained-modal-title-vcenter"
            >
              <>
                <ListCoupom
                  currentCoupom={currentCoupom}
                  editClick={editClick}
                />
              </>
            </Modal>
          </div>
        </div>
      </>
    );
  } else {
    return (
      <div className="table-default coupons-table not-found">
        <div className="table-container">
          <BootstrapTable
            bootstrap4
            keyField="id"
            data={notFoundProducts}
            hover={true}
            columns={notFoundColumns}
            striped={true}
          />
        </div>
      </div>
    );
  }
};

export default CouponsTable;
