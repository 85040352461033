/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { Key, useState } from "react";
import Table from "react-bootstrap/Table";
//import Button from "react-bootstrap/Button";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useTranslation } from 'react-i18next';

//STYLES
import "./SupplierTariffItems.scss";
import { Form } from "react-bootstrap";

//JSON
// import objectSuppliers from "../SuppliersTableCompany/locales/suppliers.json";
//import ModalDefault from "../../../../../components/Modal/ModalDefault/ModalDefault";
//import FormAddFeeTerm from "../../../../../components/Forms/FormAddFeeTerm/FormAddFeeTerm";


export interface propModal {
  action: any
  info: any
  blockIndex: any 
  byWeekDay: any
  limitMinTxt: any
  limitMaxTxt: any
  maxLimitVal: any
};

const SupplierTariffItems: React.FC<propModal> = ({
  action, info, blockIndex, byWeekDay, limitMinTxt, limitMaxTxt, maxLimitVal 
}: propModal) => {
  const { t } = useTranslation();

  /* function formatValue(cell: any) {
    //console.log(Math.sign(cell))
    if(cell && cell !== '') {
      return cell.toFixed(2).replace(".", ",");
    } else {
      return '0,00';
    }
} */

  // const suppliers = objectSuppliers.suppliers.default[0];

  const [controlObject, setControlObject] = useState<any>(info ? info : [{
    id:0,domingo:2,segunda:2,terca:2,quarta:2,quinta:2,sexta:2,sabado:2,faixaMin:'',faixaMax:'',valor:''
  }]);
  const [deletedObjects, setDeletedObjects] = useState<any>([]);

  const [merchantFlags, setMerchantFlags] = useState<any>([]);

  const handleActionModal = (objectModal: any) => {
    setControlObject([...controlObject, objectModal],);
  }



  const getMerchantFlagById = (id: any): any => {
    for (var i = 0; i < merchantFlags.length; i++) {
      if (parseInt(merchantFlags[i].id) === parseInt(id)) {
        return merchantFlags[i].description;
      }
    }
    return '';
  }

  function removeRow(index: any) {

    const aux: any = [];
    const newDeleted : any = deletedObjects;

    for (var i: any = 0; i < controlObject.length; i++) {
      if (i !== index) {
        aux.push(controlObject[i]);
      } else {
        if (controlObject[i].id > 0) {
          newDeleted.push(controlObject[i].id);
          //[...deletedObjects, controlObject[i].id]
          setDeletedObjects(newDeleted);
        }
      }
    }

    setControlObject(aux);
    action(aux, blockIndex, deletedObjects);
  }

  function addRow() {
    var aux: any = {id:0,domingo:2,segunda:2,terca:2,quarta:2,quinta:2,sexta:2,sabado:2,faixaMin:'',faixaMax:'',valor:''};
    controlObject.push(aux);
    action(aux, blockIndex, deletedObjects);
  }

  return (
    <>
      <div className="table-add-default merchants-tax-table">
        <div className="table-container">
          {/* <BootstrapTable rowEvents={ rowEvents } bootstrap4 keyField='email' data={controlObject} hover={true} columns={columns} pagination={pagination} striped={true} /> */}
          <Table striped bordered>
            <thead>
              <tr>
                <th>Domingo</th>
                <th>Segunda</th>
                <th>Terça</th>
                <th>Quarta</th>
                <th>Quinta</th>
                <th>Sexta</th>
                <th>Sabado</th>
                <th>{limitMinTxt}</th>
                <th>{limitMaxTxt}</th>
                <th>Valor</th>
                <th>Ações</th>
              </tr>
            </thead>
            <tbody>
              {controlObject.length > 0
                ? controlObject.map((info: any, index: Key) => (
                  <tr key={index}>
                    <td>
                      <Form.Check.Input
                        type="checkbox"
                        defaultChecked={info.domingo === 1}
                        value="1"
                        //className="mr-2"
                        //required
                        //checked={info.domingo === 1}
                        onChange={(e: any) => { info.domingo = e.target.checked ? 1 : 2; action(controlObject, blockIndex, deletedObjects); }}
                        disabled={byWeekDay !== '1'}
                      />
                    </td>
                    <td>
                      <Form.Check.Input
                        type="checkbox"
                        defaultChecked={info.segunda === 1}
                        value="1"
                        //className="mr-2"
                        //required
                        //checked={info.segunda === 1}
                        onChange={(e: any) => { info.segunda = e.target.checked ? 1 : 2; action(controlObject, blockIndex, deletedObjects); }}
                        disabled={byWeekDay !== '1'}
                      />
                    </td>
                    <td>
                      <Form.Check.Input
                        type="checkbox"
                        defaultChecked={info.terca === 1}
                        value="1"
                        //className="mr-2"
                        //required
                        //checked={info.terca === 1}
                        onChange={(e: any) => { info.terca = e.target.checked ? 1 : 2; action(controlObject, blockIndex, deletedObjects); }}
                        disabled={byWeekDay !== '1'}
                      />
                    </td>
                    <td>
                      <Form.Check.Input
                        type="checkbox"
                        defaultChecked={info.quarta === 1}
                        value="1"
                        //className="mr-2"
                        //required
                        //checked={info.quarta === 1}
                        onChange={(e: any) => { info.quarta = e.target.checked ? 1 : 2; action(controlObject, blockIndex, deletedObjects); }}
                        disabled={byWeekDay !== '1'}
                      />
                    </td>
                    <td>
                      <Form.Check.Input
                        type="checkbox"
                        defaultChecked={info.quinta === 1}
                        value="1"
                        //className="mr-2"
                        //required
                        //checked={info.quinta === 1}
                        onChange={(e: any) => { info.quinta = e.target.checked ? 1 : 2; action(controlObject, blockIndex, deletedObjects); }}
                        disabled={byWeekDay !== '1'}
                      />
                    </td>
                    <td>
                      <Form.Check.Input
                        type="checkbox"
                        defaultChecked={info.sexta === 1}
                        value="1"
                        //className="mr-2"
                        //required
                        //checked={info.sexta === 1}
                        onChange={(e: any) => { info.sexta = e.target.checked ? 1 : 2; action(controlObject, blockIndex, deletedObjects); }}
                        disabled={byWeekDay !== '1'}
                      />
                    </td>
                    <td>
                      <Form.Check.Input
                        type="checkbox"
                        defaultChecked={info.sabado === 1}
                        value="1"
                        //className="mr-2"
                        //required
                        //checked={info.sabado === 1}
                        onChange={(e: any) => { info.sabado = e.target.checked ? 1 : 2; action(controlObject, blockIndex, deletedObjects); }}
                        disabled={byWeekDay !== '1'}
                      />
                    </td>
                    <td>
                      {/* pax min */}
                      <Form.Control type="number"
                        min={1}
                        max={maxLimitVal}
                        required
                        autoComplete='off'
                        defaultValue={info.faixaMin}
                        //value={info.faixaMin}
                        onChange={(e: any) => { info.faixaMin = e.target.value; action(controlObject, blockIndex, deletedObjects); }}
                        value={info.faixaMin}
                      />
                    </td>
                    <td>
                      {/* pax max */}
                      <Form.Control type="number"
                        min={1}
                        max={maxLimitVal}
                        required
                        autoComplete='off'
                        defaultValue={info.faixaMax}
                        //value={info.faixaMax}
                        onChange={(e: any) => { info.faixaMax = e.target.value; action(controlObject, blockIndex, deletedObjects); }}
                        value={info.faixaMax}
                      />
                    </td>
                    <td>
                      {/* valor */}
                      <Form.Control type="text"
autoComplete='off'
                        required
                        defaultValue={info.valor}
                        onChange={(e: any) => { info.valor = e.target.value; action(controlObject, blockIndex, deletedObjects); }}
                        value={info.valor}
                      />
                    </td>
                    <td>{
                      <div className="center-table options-table">
                        <div className="px-2">
                          <div className="d-flex justify-content-end">
                            <span className='btn' style={{ height: "40px" }} onClick={(e: any) => removeRow(index)}>
                              <FontAwesomeIcon
                                size="lg"
                                icon={["fal", "trash-alt"]}
                                style={{ color: "#707070" }}
                              />
                            </span>
                          </div>
                        </div>
                        {/* <Dropdown drop="down" >
                          <Dropdown.Toggle variant="light ">
                            <FontAwesomeIcon
                              size="lg"
                              icon={['fal', 'ellipsis-h']}
                            />
                          </Dropdown.Toggle>

                          <Dropdown.Menu>
                            <Dropdown.Item
                              onClick={() => editRow(index)}
                              eventKey="1"
                            >
                              <FontAwesomeIcon
                                icon={['fal', 'edit']}
                              //className="mr-2"
                              />
                              {t('suppliersTable.edit')}
                            </Dropdown.Item>
                            <Dropdown.Item
                              onClick={() => removeRow(index)}
                              eventKey="2"
                              className="text-danger removeRow">
                              <FontAwesomeIcon
                                icon={['fal', 'trash-alt']}
                                //className="mr-2"
                              />
                              {t('suppliersTable.remove')}
                            </Dropdown.Item>
                          </Dropdown.Menu>
                        </Dropdown> */}
                      </div>
                    }</td>
                  </tr>
                ))
                : ""
              }
              <div className="add-contact">
                <div className="text-center btn-add-contact">
                  <span className='btn' style={{ height: "40px" }} onClick={(e: any) => addRow()}>
                    <FontAwesomeIcon
                      size="lg"
                      icon={["fal", "plus"]}
                      style={{ color: "#707070", margin: "0 12px" }}
                    />
                  </span>
                  {/* <ModalDefault
                    title="Taxa e Prazo"
                    classModal="modal-pos-tax"
                    name={'+'}
                    class="btn-add"
                  >
                    <div className="form-add-contact">
                      <FormAddFeeTerm action={handleActionModal} info={null} />
                    </div>
                  </ModalDefault> */}
                </div>
              </div>
            </tbody>
          </Table>

          {/* {
            feeTermEdit !== null
              ?
              <Modal
                className="modal-custom modalAuth modal-pos-tax"
                show={feeTermModalShow}
                onHide={() => setFeeTermModalShow(false)}
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
              >
                <>
                  <Modal.Header className="p-3" closeButton>
                    <h3>Taxa e Prazo</h3>
                  </Modal.Header>
                  <hr style={{ margin: "30px 0" }} />
                  <FormAddFeeTerm action={handleActionEditModal} info={feeTermEdit} />
                </>
              </Modal>
              :
              <></>
          } */}

        </div>
      </div>
    </>
  );
}

export default SupplierTariffItems;
