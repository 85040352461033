import React, { useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button, Card, Col, Dropdown, Form, Row } from "react-bootstrap";
import { Controller, useForm } from "react-hook-form";
import { ErrorMessage } from "@hookform/error-message";
import Select from 'react-select';
import api from "../../../../services/api";
import './Products.scss';

import { useAppSelector, useAppDispatch } from "../../../../store/hooks";
import { addToComboObject } from "../../../../store/combo";

import GetProductsList from "../../../Stock/components/ProductList/GetProductsList";
import ModalShow from "../../../../components/Modal/ModalShow/ModalShow";


export interface operation {
    action: any;
    isEdit: any;
    editFunction?: any;
}

const Products: React.FC<operation> = React.memo(({
    action, isEdit, editFunction = () => { }
}: operation) => {
    /* Controle Redux  */
    const dispatch = useAppDispatch();
    const reduxComboObject: any = JSON.parse(JSON.stringify(useAppSelector((state: any) => state.combo))).combo;
    /* Controle Redux  */

    const [productCode, setProductCode] = useState<string | null>(null)
    const [modalities, setModalities] = useState<any>(null)
    const [tariffs, setTariffs] = useState<any>(null)
    const [registeredProducts, setRegisteredProducts] = useState<any>(reduxComboObject?.productAttached || [])

    const [modalShow, setModalShow] = useState<boolean>(false);
    const [modalMessage, setModalMessage] = useState<string>("");
    const [modalLog, setModalLog] = useState<any>(0);

    const {
        control,
        handleSubmit,
        setValue,
        watch,
        formState: { errors },
    } = useForm({});

    const watchProducts = watch(`products`, null);
    const watchModalities = watch(`modalities`, null);
    const watchTariffs = watch(`tariffs`, null);

    useEffect(() => {
        if (watchProducts) {
            setProductCode(watchProducts.value);
        }
    }, [watchProducts])

    useEffect(() => {
        resetForm();
        const token = localStorage.getItem('GroupId') || '{}';
        const config = {
            headers: { 'Authorization': `Bearer ${token}` },
        };
        const GetNewProductTourByProductCode = async () => {
            try {
                const { data } = await api.get(`/DropDown/GetActiveProductsModalityTour?ProductCode=${productCode}`, config);
                if (data.statusCode === 200) {
                    transformObject(data);
                    //setModalShow(false);
                } else {
                    // setModalLog(1);
                    // setModalMessage(data.errorMessage);
                    // setModalShow(true);
                }
            } catch (error: any) {
                // setModalLog(1);
                // setModalMessage("Erro ao processar operação, favor tentar mais tarde");
                // setModalShow(true);
            }
        }

        if (productCode !== null) {
            //setModalShow(true);
            GetNewProductTourByProductCode();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [productCode])

    const transformObject = (data: any) => {
        let aux: any = [];

        aux = data.data.map((elem: any, index: number) => {
            return (
                {
                    "value": elem.id,
                    "label": elem.description,
                }
            )
        })
        setModalities(aux);
    }

    useEffect(() => {
        if (watchModalities) {
            GetTariffs(watchModalities.value)

            setValue('tariffs', null);
            setTariffs(null);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [watchModalities])

    const token = localStorage.getItem('GroupId') || '{}';
    const config = {
        headers: { 'Authorization': `Bearer ${token}` },
    };

    const GetTariffs = async (modeCode: any) => {
        try {
            const { data } = await api.get(`/DropDown/GetActiveProductsTarCodeTour?ModCode=${modeCode}`, config);
            if (data.statusCode === 200) {
                transformObjectTarif(data);
                //setModalShow(false);
            } else {
                // setModalLog(1);
                // setModalMessage(data.errorMessage);
                // setModalShow(true);
            }
        } catch (error: any) {
            // setModalLog(1);
            // setModalMessage("Erro ao processar operação, favor tentar mais tarde");
            // setModalShow(true);
        }
    }

    const transformObjectTarif = (data: any) => {
        let aux: any = [];

        aux = data.data.map((elem: any, index: number) => {
            return (
                {
                    "value": elem.id,
                    "label": elem.description,
                }
            )
        })
        setTariffs(aux);
    }

    const resetForm = () => {
        setValue('modalities', null);
        setModalities(null);
        setValue('tariffs', null);
        setTariffs(null);
    }

    const RegisterProduct = () => {
        if(registeredProducts.map((elem: any) => { return elem.productCode }).includes(watchProducts.value)){
            setModalLog(1);
            setModalMessage('Produto já cadastrado');
            setModalShow(true);
            
            return;
        }

        let product: any = {
            'productName': watchProducts.label,
            'productCode': watchProducts.value,
            'modalityName': watchModalities.label,
            'modalityCode': watchModalities.value,
            'tariffName': watchTariffs.label,
            'tarUniqueCode': watchTariffs.value,
        };

        setRegisteredProducts((prev: any) => [...prev, product]);
    }

    const onSubmit = (data: any) => {
        if (registeredProducts.length === 0) {
            setModalLog(1);
            setModalMessage('Favor registrar ao menos 1 produto.');
            setModalShow(true);
            return;
        }

        dispatch(addToComboObject(JSON.stringify({ productAttached: registeredProducts })));
        action("markup", isEdit);
    };


    return (
        <>
            {
                (isEdit === false || reduxComboObject?.name) ?
                    <Col md={9} lg={10}>
                        <Card className="pt-content summary">
                            <Form
                                className="h-100"
                                noValidate
                                validated={false}
                                onSubmit={handleSubmit(onSubmit)}
                            >
                                <h3 className="text-primary pt-title mb-0">
                                    Produtos
                                </h3>

                                <div className="pt-content-info" style={{ border: "none" }}>
                                    <Row className="mb-3">
                                        <Form.Group as={Col} md="6" className="mb-4">
                                            <Form.Label>Produto:</Form.Label>
                                            <Controller
                                                control={control}
                                                name="products"
                                                rules={{ required: { value: false, message: 'Por favor, informe ao menos 1 produto' } }}
                                                render={({ field }: any) => (
                                                    <div className="">
                                                        <GetProductsList
                                                            propsField={field}
                                                            propsErrors={errors}
                                                        />
                                                    </div>
                                                )}
                                            />
                                            <ErrorMessage
                                                errors={errors}
                                                name="products"
                                                render={({ message }) => <small style={{ color: "red" }}>{message}</small>}
                                            />
                                        </Form.Group>
                                        {modalities !== null &&
                                            <Form.Group as={Col} md="6" className="mb-4">
                                                <Form.Label>Modalidade:</Form.Label>
                                                <Controller
                                                    control={control}
                                                    name="modalities"
                                                    rules={{ required: { value: false, message: 'Por favor, informe ao menos 1 modalidade' } }}
                                                    render={({ field }: any) => (
                                                        <div className="endpoint">
                                                            <Select
                                                                {...field}
                                                                aria-labelledby="aria-label"
                                                                inputId="aria-example-input"
                                                                name="aria-live-color"
                                                                className="basic-multi-select"
                                                                classNamePrefix="select"
                                                                as="select"
                                                                variant="standard"
                                                                margin="normal"
                                                                placeholder={"Selecione"}
                                                                options={modalities}
                                                                getOptionLabel={(option: any) => `${option.label}`}
                                                            />
                                                        </div>
                                                    )}
                                                />
                                            </Form.Group>
                                        }
                                        {tariffs !== null &&
                                            <Form.Group as={Col} md="6" className="mb-4">
                                                <Form.Label>Tarifário:</Form.Label>
                                                <Controller
                                                    control={control}
                                                    name="tariffs"
                                                    rules={{ required: { value: false, message: 'Por favor, informe ao menos 1 modalidade' } }}
                                                    render={({ field }: any) => (
                                                        <div className="endpoint">
                                                            <Select
                                                                {...field}
                                                                aria-labelledby="aria-label"
                                                                inputId="aria-example-input"
                                                                name="aria-live-color"
                                                                className="basic-multi-select"
                                                                classNamePrefix="select"
                                                                as="select"
                                                                variant="standard"
                                                                margin="normal"
                                                                placeholder={"Selecione"}
                                                                options={tariffs}
                                                                getOptionLabel={(option: any) => `${option.label}`}
                                                            />
                                                        </div>
                                                    )}
                                                />
                                            </Form.Group>
                                        }
                                    </Row>
                                    {watchTariffs !== null &&
                                        <div className="d-flex justify-content-end">
                                            <div className="btn-add-details" onClick={() => { RegisterProduct() }}>
                                                Adicionar Tarifa
                                            </div>
                                        </div>
                                    }
                                </div>

                                <div className='registered-products'>
                                    {registeredProducts.map((elem: any, index: number) => {
                                        return (
                                            <>
                                                <Col md={12} className='new-card mb-4'>
                                                    <div className={`d-flex justify-content-between align-items-center w-100 mb-2`}>
                                                        <span>
                                                            <b>{`${elem.productName}`}</b>
                                                        </span>
                                                        <div>
                                                            <Dropdown drop="start">
                                                                <Dropdown.Toggle variant="light ">
                                                                    <FontAwesomeIcon
                                                                        size="lg"
                                                                        icon={[
                                                                            "fal",
                                                                            "ellipsis-h",
                                                                        ]}
                                                                    />
                                                                </Dropdown.Toggle>

                                                                <Dropdown.Menu>
                                                                    <Dropdown.Item
                                                                        onClick={() => {
                                                                            setRegisteredProducts((prev: any) => [...prev.slice(0, index), ...prev.slice(index + 1)])
                                                                        }}
                                                                        eventKey="3"
                                                                        className="text-danger"
                                                                    >
                                                                        <FontAwesomeIcon
                                                                            icon={[
                                                                                "fal",
                                                                                "trash-alt",
                                                                            ]}
                                                                        />
                                                                        Remover
                                                                    </Dropdown.Item>
                                                                </Dropdown.Menu>
                                                            </Dropdown>
                                                        </div>
                                                    </div>
                                                    <div className='d-flex w-100 info bordered mb-3'>
                                                        <Row className="w-100">
                                                            <Col md='2'>
                                                                <span>
                                                                    Código:{' '}{elem.productCode}
                                                                </span>
                                                            </Col>
                                                            <Col md='4'>
                                                                <span className='text-wrap-custom'>
                                                                    Modalidade:{' '}{elem.modalityName}
                                                                </span>
                                                            </Col>
                                                            <Col md='2'>
                                                                <span>
                                                                    Tarifário:{' '}{elem.tariffName}
                                                                </span>
                                                            </Col>
                                                        </Row>
                                                    </div>

                                                </Col>
                                            </>
                                        )
                                    })}
                                </div>

                                <div className="h-100 d-flex justify-content-end align-items-end">
                                    {isEdit !== true ? (
                                        <Row className="mx-0 mt-5">
                                            <Button
                                                variant="outline-primary"
                                                className="form-button mr-3"
                                                onClick={() => action("descriptionsAndHigh")}
                                            >
                                                Voltar
                                            </Button>
                                            <Button type="submit" className="form-button">
                                                Continuar
                                            </Button>
                                        </Row>
                                    ) : (
                                        <Row className=" mx-0 mt-5">
                                            <Button
                                                type="submit"
                                                className="form-button"
                                            >
                                                Salvar
                                            </Button>
                                        </Row>
                                    )}
                                </div>
                            </Form>

                            <ModalShow
                                modalShow={modalShow}
                                setModalShow={setModalShow}
                                modalMessage={modalMessage}
                                setModalMessage={setModalMessage}
                                modalLog={modalLog}
                                setModalLog={setModalLog}
                            />
                        </Card>
                    </Col>
                    :
                    <>
                        <Col md={9} lg={10}>
                            <Card className="pt-content h-100">
                                <div className="loading-modal h-100 d-flex flex-column justify-content-center align-items-center">
                                    <div className="load mb-4"></div>
                                    <div>Carregando dados do produto</div>
                                </div>
                            </Card>
                        </Col>
                    </>
            }
        </>
    );
}
);

export default Products;