import React, { useState, useEffect } from "react";
import { Container, Row, Col } from 'react-bootstrap';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import Accordion from 'react-bootstrap/Accordion';
import { useTranslation } from "react-i18next";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { Controller, useForm } from "react-hook-form";

//Components
import GlobalAlert from "../../../../components/Alert/GlobalAlert/GlobalAlert";

function VehiclesFilter(props: any) {

    const { t } = useTranslation();

    const [filterName, setFilterName] = useState<any>('');
    const [filterLicensePlate, setFilterLicensePlate] = useState<any>('');
    const [filterModel, setFilterModel] = useState<any>('');
    const [filterType, setFilterType] = useState<any>(0);

    const [alert, setAlert] = useState<any>();

    const {
        control,
    } = useForm({});

    const handleFilterClick = () => {
        props.buscar(filterName, filterLicensePlate, filterModel, filterType);
    };

    const handleClearClick = () => {

        let inputValue = document.querySelectorAll('input');
        for (let i = 0; i < inputValue.length; i++) {
            inputValue[i].value = "";
        }

        let selectValue = document.querySelectorAll('select');
        for (let i = 0; i < selectValue.length; i++) {
            selectValue[i].value = "0";
        }

        setFilterName("");
        setFilterLicensePlate("");
        setFilterModel("");
        setFilterType(0)
        props.buscar('', '', '', 0);
    };


    const handleChangeName = (e: React.ChangeEvent<HTMLInputElement>) => {
        const newValue = e.target.value;
        setFilterName(newValue);
    }

    const handleChangeLicense = (e: React.ChangeEvent<HTMLInputElement>) => {
        const newValue = e.target.value;
        setFilterLicensePlate(newValue);
    }

    const handleChangeModel = (e: React.ChangeEvent<HTMLInputElement>) => {
        const newValue = e.target.value;
        setFilterModel(newValue);
    }
    const handleChangeType = (e: React.ChangeEvent<HTMLInputElement>) => {
        const newValue = e.target.value;
        setFilterType(parseInt(newValue));
    }

    useEffect(() => {
        if (props.vehicles === 0 || props.error === true) {
            setAlert(
                <GlobalAlert alertType={"danger"}>
                    Não foi possível encontrar nenhum veículo.
                </GlobalAlert>
            )
            setTimeout(() => setAlert(null), 2000);
        } else {
            setAlert(null);
        }
    }, [props.vehicles, props.error])


    return (
        <div className="vehicles-filter partners-filter d-flex justify-content-center bg-white mt-4" style={{ textAlign: "left" }}>
            <Accordion defaultActiveKey="1">
                <Accordion.Item eventKey="0">
                    <Accordion.Header><FontAwesomeIcon icon={['fal', 'sliders-h']} size="lg" className="mr-2" /><span className="h5 mb-0">Buscar Veículos</span></Accordion.Header>
                    <Accordion.Body className="pt-0">
                        <Container className="p-0" fluid>
                            <Row>
                                <div>
                                    {alert}
                                    <Form >
                                        <Row className="mb-3">
                                            <Form.Group as={Col} md="6">
                                                <Form.Label>Nome do Fornecedor:</Form.Label>
                                                <Controller
                                                    control={control}
                                                    name="name"
                                                    render={({ field }: any) => (
                                                        <Form.Control
                                                            {...field}
                                                            type="text"
                                                            variant="standard"
                                                            margin="normal"
                                                            autoComplete="off"
                                                            onChange={handleChangeName}
                                                            value={filterName}
                                                            
                                                        />
                                                    )}
                                                />
                                                {/* <Form.Control
                                                    type="text"
                                                    autoComplete='off'
                                                    onChange={handleChangeName}
                                                /> */}
                                                {/* <Form.Control.Feedback>Looks good!</Form.Control.Feedback> */}
                                            </Form.Group>
                                            <Form.Group as={Col} md="2">
                                                <Form.Label>Placa:</Form.Label>
                                                <Controller
                                                    control={control}
                                                    name="licensePlate"
                                                    render={({ field }: any) => (
                                                        <Form.Control
                                                            {...field}
                                                            type="text"
                                                            variant="standard"
                                                            margin="normal"
                                                            autoComplete="off"
                                                            onChange={handleChangeLicense}
                                                            value={filterLicensePlate}
                                                        />
                                                    )}
                                                />
                                            </Form.Group>
                                            <Form.Group as={Col} md="2">
                                                <Form.Label>Modelo:</Form.Label>
                                                <Controller
                                                    control={control}
                                                    name="model"
                                                    render={({ field }: any) => (
                                                        <Form.Control
                                                            {...field}
                                                            type="text"
                                                            variant="standard"
                                                            margin="normal"
                                                            autoComplete="off"
                                                            onChange={handleChangeModel}
                                                            value={filterModel}
                                                        />
                                                    )}
                                                />
                                            </Form.Group>
                                            <Form.Group as={Col} md="2">
                                            <Form.Label>Tipo:</Form.Label>
                                            <Controller
                                                control={control}
                                                name="type"
                                                render={({ field }: any) => (
                                                    <div className={"endpoint"}>
                                                        <Form.Select
                                                            {...field}
                                                            label="Filial"
                                                            as="select"
                                                            variant="standard"
                                                            margin="normal"
                                                            onChange={handleChangeType}
                                                            required
                                                        >
                                                            <option value='0'>Todos</option>
                                                            <option value='1'>Ônibus</option>
                                                            <option value='2'>Micro-Ônibus</option>
                                                            <option value='3'>Van</option>
                                                            <option value='4'>Sedan</option>
                                                        </Form.Select>
                                                    </div>
                                                )}
                                            />
                                            </Form.Group>
                                        </Row>

                                        <Row as={Col} md="12" className="mt-5 justify-content-md-end">
                                            <Col xs lg="12" className="d-flex justify-content-md-end">
                                                <Button variant="outline-primary" className="btn-default mr-2 text-uppercase" onClick={handleClearClick}>{t('partners.partnersfilter.btnClear')}</Button>
                                                <Button className="btn-default text-uppercase" onClick={handleFilterClick}>{t('partners.partnersfilter.btnSearch')}</Button>
                                            </Col>
                                        </Row>


                                    </Form>
                                </div>
                            </Row>
                        </Container>
                    </Accordion.Body>
                </Accordion.Item>
            </Accordion>
        </div>
    )
}

export default VehiclesFilter;