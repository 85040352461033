import React, { useEffect, useState } from "react";
import Select from "react-select";

import { useTranslation } from "react-i18next";

import Form from "react-bootstrap/Form";

export interface propPlus {
    propsField: any;
    propsLabel: any;
    channel?: any;
    add15Interval?: boolean;
    isMult?: boolean;
}

const GetTime30MinInterval: React.FC<propPlus> = ({
    propsField,
    propsLabel,
    channel,
    add15Interval = false,
    isMult = true,
}: propPlus) => {
    const { t } = useTranslation();

    const [info, setInfo] = useState<any>([]);

    useEffect(() => {
        var aux: any = [];
        for (var i: any = 0; i < 24; i++) {
            if(add15Interval){
                aux.push({ id: `${i < 10 ? "0" + i : "" + i}:00`, name: `${i < 10 ? "0" + i : "" + i}:00` })
                aux.push({ id: `${i < 10 ? "0" + i : "" + i}:15`, name: `${i < 10 ? "0" + i : "" + i}:15` })
                aux.push({ id: `${i < 10 ? "0" + i : "" + i}:30`, name: `${i < 10 ? "0" + i : "" + i}:30` })
                aux.push({ id: `${i < 10 ? "0" + i : "" + i}:45`, name: `${i < 10 ? "0" + i : "" + i}:45` })
            }else{
                aux.push({ id: `${i < 10 ? "0" + i : "" + i}:00`, name: `${i < 10 ? "0" + i : "" + i}:00` })
                aux.push({ id: `${i < 10 ? "0" + i : "" + i}:30`, name: `${i < 10 ? "0" + i : "" + i}:30` })
            }
        }
        setInfo(aux);


    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const [cat,] = useState<any>([]);


    const [isMenuOpen, setIsMenuOpen] = useState<boolean>(false);

    const onMenuOpen = () => {
        if (isMenuOpen === false) {
            setIsMenuOpen(true);
        } else {
            setIsMenuOpen(false);
        }
    };

    if (info !== null) {
        const transformed = info.map(({ id: value, name: label }: any) => ({
            value,
            label
        }));
        return (
            <>
                <div
                >
                    <Select
                        {...propsField}
                        onMenuOpen={onMenuOpen}
                        onMenuClose={onMenuOpen}
                        as="select"
                        className="basic-multi-select"
                        classNamePrefix="select"
                        margin="normal"
                        placeholder={t("accordionFinancial.select.select")}
                        isMulti={isMult}
                        options={transformed}
                        getOptionLabel={(option: any) => `${option.label}`}
                        defaultValue={cat}
                    />
                </div>
            </>
        );
    } else {
        return (
            <>
                <Form.Select>
                    <option value="" disabled></option>
                </Form.Select>
            </>
        );
    }
};

export default GetTime30MinInterval;
