/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useState, useEffect } from 'react';
import api from '../../services/api';

import DesktopDefault from '../../templates/DesktopDefault';

import Breadcrumb from '../../components/Breadcrumb/Breadcrumb';
import FilterBillsReceive from '../BillsToReceive/components/FilterBills/FilterBillsReceive';
import TableSettleBillsReceive from './components/TableSettleBills/TableSettleBillsReceive';

/* import './SettleBillsToPay.scss'; */

function BillsToReceive() {

    const today = new Date();
    const yesterday = new Date(today.getFullYear(), today.getMonth(), today.getDate() - 1); // hoje - 1 dia = ontem
    const defaultDate = `${yesterday.getDate()}/${yesterday.getMonth()+1}/${yesterday.getFullYear()}`;

    const [billsReceive, setBillsReceive] = useState<any>(null);
    const [parameters, setParameters] = useState<any>({});
    const [error, setError] = useState<any>();
    const [loading, setLoading] = useState<any>(false);
    //const [allowLoadFromFilter, setAllowLoadFromFilter] = useState<any>(false);

    /* Filtragem */
    const [actualLocalizador, setActualLocalizador] = useState<any>("");
    const [actualStatusReserve, setActualStatusReserve] = useState<any>(1);
    const [actualStatusPagamento, setActualStatusPagamento] = useState<any>(1);
    const [actualStatusFormaPagamento, setActualStatusFormaPagamento] = useState<any>(null);
    const [actualSupplier, setActualSupplier] = useState<any>("");
    const [actualAdquirente, setActualAdquirente] = useState<any>(null);
    const [actualToken, setActualToken] = useState<any>("");
    const [actualAgent, setActualAgent] = useState<any>("");
    const [actualRefAgent, setActualRefAgent] = useState<any>("");
    const [actualClient, setActualClient] = useState<any>("");
    const [actualType, setActualType] = useState<any>(null);
    const [actualTypeDate, setActualTypeDate] = useState<any>(3);
    const [actualDate, setActualDate] = useState<any>([]);
    const [actualIdDecolarFenix, setActualIdDecolarFenix] = useState<any>("");

    /* END - Filtragem */

    /* Pagination */
    const [totalRows, setTotalRows] = useState<any>();
    const [pageCount, setPageCount] = useState<any>(1);
    const [rowsPerPage, setRowsPerPage] = useState<any>(10);
    /* END - Pagination */

    const [selectedCheck, setSelectedCheck] = useState<any>([]);
    const [itemsToSettle, setItemsToSettle] = useState<any>([]);

    function clearActualValues() {
        setActualLocalizador("");
        setActualStatusReserve(1);
        setActualStatusPagamento(2);
        setActualStatusFormaPagamento(null);
        setActualSupplier("");
        setActualAdquirente(null);
        setActualToken("");
        setActualAgent("");
        setActualRefAgent("");
        setActualClient("");
        //setActualType(null);
        setActualTypeDate(3);
        setActualDate([]);
        setActualIdDecolarFenix("");
        setPageCount(1);
        setSelectedCheck([]);
        setItemsToSettle([]);
    }

    useEffect(() => {
        setLoading(true);

        let body: any = document.querySelector("body");
        if (body.className !== "modal-open") {
            setBillsReceive(null);
        }

        let beginDate: any = []
        let endDate: any = []

        if (actualDate.length === 1) {
            beginDate = actualDate[0].split('/')
        } else if (actualDate.length === 2) {
            beginDate = actualDate[0].split('/')
            endDate = actualDate[1].split('/')
        } 

        let today: any = new Date(); 

        async function billTopPay() {
            const token = localStorage.getItem('GroupId') || '{}';
            const config = {
                headers: { 'Authorization': `Bearer ${token}`, 'Content-Type': 'application/json' },
            };
            try {
                const { data } = await api.post('/BillsReceivable/GetReportSingle',
                    {
                        "page": pageCount,
                        "rowsPerPage": rowsPerPage,
                        "item": actualLocalizador ? actualLocalizador : '',
                        "idDecolarFenix": actualIdDecolarFenix ? actualIdDecolarFenix : null,
                        "tokenNsu": actualToken ? actualToken : '',
                        "agenteRef": actualRefAgent ? actualRefAgent : '',
                        "statusReserva": actualStatusReserve ? parseInt(actualStatusReserve) : null,
                        "statusPagamento": actualStatusPagamento ? parseInt(actualStatusPagamento) : null,
                        "formaPagamento": actualStatusFormaPagamento ? parseInt(actualStatusFormaPagamento) : null,
                        "adquirente": actualAdquirente ? actualAdquirente : null,
                        "fornecedor": actualSupplier ? actualSupplier : '',
                        "agenteIndividual": -1,
                        "agente": actualAgent && actualAgent !== '0' ? actualAgent : '',
                        "cliente": actualClient ? actualClient : '',
                        "tipoData": actualTypeDate ? parseInt(actualTypeDate) : 3,
                        "dataInicial": (actualDate.length > 0) ? `${beginDate[2]}-${beginDate[1]}-${beginDate[0]}` : `${today.getFullYear()}-${today.getMonth() + 1}-${today.getDate()}`,
                        "dataFinal": (actualDate.length === 1) ? `${beginDate[2]}-${beginDate[1]}-${parseInt(beginDate[0])}` : (actualDate !== null && actualDate.length === 2) ? `${endDate[2]}-${endDate[1]}-${endDate[0]}` : `${today.getFullYear()}-${today.getMonth() + 1}-${today.getDate()}`
                    }, config
                );
                setLoading(false);
                if (data.status !== 400) {
                    setBillsReceive(data.data.rows);
                    setTotalRows(data.data.rowsCount);
                }
            } catch (error: any) {
                if (error.response.status === 401) {
                    window.location.href = window.location.origin + '/';
                }
            }
        }
        billTopPay();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [pageCount, rowsPerPage/* , actualStatus */]);

    async function buscar(
        localizador: any,
        statusReserva: any,
        statusPagamento: any,
        statusFormaPagamento: any,
        supplier: any,
        adquirente: any,
        tokenNsu: any,
        agent: any,
        refAgent: any,
        client: any,
        type: any,
        typeDate: any,
        date: any,
        idDecolarFenix: any
    ) {
        setActualLocalizador(localizador);
        setActualStatusReserve(statusReserva);
        setActualStatusPagamento(statusPagamento);
        setActualStatusFormaPagamento(statusFormaPagamento);
        setActualSupplier(supplier);
        setActualAdquirente(adquirente);
        setActualToken(tokenNsu);
        setActualAgent(agent);
        setActualRefAgent(refAgent);
        setActualClient(client);
        //setActualType(type);
        setActualTypeDate(typeDate);
        setActualDate(date);
        setActualIdDecolarFenix(idDecolarFenix);
        setPageCount(1);
        setSelectedCheck([]);
        setItemsToSettle([]);

        setLoading(true);

        let body: any = document.querySelector("body");
        if (body.className !== "modal-open") {
            setBillsReceive(null);
        }

        let beginDate = []
        let endDate = []

        if (date.length === 1) {
            beginDate = date[0].split('/')
        } else if (date.length === 2) {
            beginDate = date[0].split('/')
            endDate = date[1].split('/')
        }

        let today: any = new Date();

        const token = localStorage.getItem('GroupId') || '{}';
        const config = {
            headers: { 'Authorization': `Bearer ${token}`, 'Content-Type': 'application/json' },
        };
        try {
            const res = await api.post('/BillsReceivable/GetReportSingle',
                {
                    "page": 1,
                    "rowsPerPage": rowsPerPage,
                    "item": localizador ? localizador : '',
                    "idDecolarFenix": idDecolarFenix ? idDecolarFenix : null,
                    "tokenNsu": tokenNsu ? tokenNsu : '',
                    "agenteRef": refAgent ? refAgent : '',
                    "statusReserva": statusReserva ? parseInt(statusReserva) : null,
                    "statusPagamento": statusPagamento ? parseInt(statusPagamento) : null,
                    "formaPagamento": statusFormaPagamento ? parseInt(statusFormaPagamento) : null,
                    "adquirente": adquirente ? adquirente : null,
                    "fornecedor": supplier ? supplier : '',
                    "agenteIndividual": -1,
                    "agente": agent && agent !== '0' ? agent : '',
                    "cliente": client ? client : '',
                    "tipoData": typeDate ? parseInt(typeDate) : 3,
                    "dataInicial": (date.length > 0) ? `${beginDate[2]}-${beginDate[1]}-${beginDate[0]}` : `${today.getFullYear()}-${today.getMonth() + 1}-${today.getDate()}`,
                    "dataFinal": (date.length === 1) ? `${beginDate[2]}-${beginDate[1]}-${parseInt(beginDate[0])}` : (date !== null && date.length === 2) ? `${endDate[2]}-${endDate[1]}-${endDate[0]}` : `${today.getFullYear()}-${today.getMonth() + 1}-${today.getDate()}`
                }, config
            );
            setLoading(false);
            if (res.status !== 400) {
                //setAllowLoadFromFilter(false);
                setParameters({
                    "page": 1,
                    "rowsPerPage": rowsPerPage,
                    "item": localizador ? localizador : '',
                    "idDecolarFenix": idDecolarFenix ? idDecolarFenix : null,
                    "tokenNsu": tokenNsu ? tokenNsu : '',
                    "agenteRef": refAgent ? refAgent : '',
                    "statusReserva": statusReserva ? parseInt(statusReserva) : null,
                    "statusPagamento": statusPagamento ? parseInt(statusPagamento) : null,
                    "formaPagamento": statusFormaPagamento ? parseInt(statusFormaPagamento) : null,
                    "adquirente": adquirente ? adquirente : null,
                    "fornecedor": supplier ? supplier : '',
                    "agenteIndividual": -1,
                    "agente": agent && agent !== '0' ? agent : '',
                    "cliente": client ? client : '',
                    "tipoData": typeDate ? parseInt(typeDate) : 3,
                    "dataInicial": (date.length > 0) ? `${beginDate[2]}-${beginDate[1]}-${beginDate[0]}` : `${today.getFullYear()}-${today.getMonth() + 1}-${today.getDate()}`,
                    "dataFinal": (date.length === 1) ? `${beginDate[2]}-${beginDate[1]}-${parseInt(beginDate[0])}` : (date !== null && date.length === 2) ? `${endDate[2]}-${endDate[1]}-${endDate[0]}` : `${today.getFullYear()}-${today.getMonth() + 1}-${today.getDate()}`
                });
                setBillsReceive(res.data.data.rows);
                setTotalRows(res.data.data.rowsCount);
            }
        } catch (error: any) {
            //setAllowLoadFromFilter(false);
            setError(true)
            if (error?.response?.status === 401) {
                window.location.href = window.location.origin + '/';
            }
        }
    };

    async function exportBills(layout: any) {
        console.log(layout, actualStatusFormaPagamento)
        let allowRequest: boolean = true;

        let beginDate = []
        let endDate = []

        if (actualDate.length === 1) {
            beginDate = actualDate[0].split('/')
        } else if (actualDate.length === 2) {
            beginDate = actualDate[0].split('/')
            endDate = actualDate[1].split('/')
        }

        let today: any = new Date();

        if (layout === 4 && parseInt(actualStatusFormaPagamento) !== 4) { ///quando for cartão de credito so deixa exportar quando for filtro forma pagamento igual a cartão
            allowRequest = false;
        }

        if (allowRequest === true) {
            const token = localStorage.getItem('GroupId') || '{}';
            const config: any = {
                headers: { 
                    'Authorization': `Bearer ${token}`, 
                    'Content-Type': 'application/json',
                    "Accept": "*/*"
                            //"Access-Control-Allow-Origin": "*"
                },
                responseType: 'blob'
            };
            
            try {
                const exp = await api.post('/BillsReceivable/ExportReport',
                    {
                        "page": 1,
                        "rowsPerPage": rowsPerPage,
                        "layout": layout,
                        "item": actualLocalizador ? actualLocalizador : '',
                        "idDecolarFenix": actualIdDecolarFenix ? actualIdDecolarFenix : null,
                        "tokenNsu": actualToken ? actualToken : '',
                        "agenteRef": actualRefAgent ? actualRefAgent : '',
                        "statusReserva": actualStatusReserve ? parseInt(actualStatusReserve) : null,
                        "statusPagamento": actualStatusPagamento ? parseInt(actualStatusPagamento) : null,
                        "formaPagamento": actualStatusFormaPagamento ? parseInt(actualStatusFormaPagamento) : null,
                        "adquirente": actualAdquirente ? actualAdquirente : null,
                        "fornecedor": actualSupplier ? actualSupplier : '',
                        "agenteIndividual": -1,
                        "agente": actualAgent && actualAgent !== '0' ? actualAgent : '',
                        "cliente": actualClient ? actualClient : '',
                        "tipoData": actualTypeDate ? parseInt(actualTypeDate) : 3,
                        "dataInicial": (actualDate.length > 0) ? `${beginDate[2]}-${beginDate[1]}-${beginDate[0]}` : `${today.getFullYear()}-${today.getMonth() + 1}-${today.getDate()}`,
                        "dataFinal": (actualDate.length === 1) ? `${beginDate[2]}-${beginDate[1]}-${parseInt(beginDate[0])}` : (actualDate !== null && actualDate.length === 2) ? `${endDate[2]}-${endDate[1]}-${endDate[0]}` : `${today.getFullYear()}-${today.getMonth() + 1}-${today.getDate()}`
                    },
                    config

                );
                if (exp.status !== 400) {
                    const blob = new Blob([exp.data], { type: exp.headers["Content-Type"] });
                    const objectUrl = window.URL.createObjectURL(blob);

                    const link = document.createElement('a');
                    link.href = objectUrl;
                    link.setAttribute(
                        'download',
                        'contas_receber.xlsx',
                    );

                    // Append to html link element page
                    document.body.appendChild(link);

                    // Start download
                    link.click();

                    // // Clean up and remove the link
                    link?.parentNode?.removeChild(link);
                }
            } catch (error: any) {
                setError(true)
                if (error?.response?.status === 401) {
                    window.location.href = window.location.origin + '/';
                }
            }
        }
    };

    async function printBills() {

        let beginDate = []
        let endDate = []

        if (actualDate.length === 1) {
            beginDate = actualDate[0].split('/')
        } else if (actualDate.length === 2) {
            beginDate = actualDate[0].split('/')
            endDate = actualDate[1].split('/')
        }

        let today: any = new Date();

        const token = localStorage.getItem('GroupId') || '{}';
        const config: any = {
            headers: { 
                'Authorization': `Bearer ${token}`, 
                'Content-Type': 'application/json',
                "Accept": "*/*"
                        //"Access-Control-Allow-Origin": "*"
            },
            responseType: 'blob'
        };
        
        try {
            const print = await api.post('/BillsReceivable/PrintReport',
                {
                    "page": pageCount,
                    "rowsPerPage": rowsPerPage,
                    "item": actualLocalizador ? actualLocalizador : '',
                    "idDecolarFenix": actualIdDecolarFenix ? actualIdDecolarFenix : null,
                    "tokenNsu": actualToken ? actualToken : '',
                    "agenteRef": actualRefAgent ? actualRefAgent : '',
                    "statusReserva": actualStatusReserve ? parseInt(actualStatusReserve) : null,
                    "statusPagamento": actualStatusPagamento ? parseInt(actualStatusPagamento) : null,
                    "formaPagamento": actualStatusFormaPagamento ? parseInt(actualStatusFormaPagamento) : null,
                    "adquirente": actualAdquirente ? actualAdquirente : null,
                    "fornecedor": actualSupplier ? actualSupplier : null,
                    "agenteIndividual": -1,
                    "agente": actualAgent && actualAgent !== '0' ? actualAgent : '',
                    "cliente": actualClient ? actualClient : '',
                    "tipoData": actualTypeDate ? parseInt(actualTypeDate) : 3,
                    "dataInicial": (actualDate.length > 0) ? `${beginDate[2]}-${beginDate[1]}-${beginDate[0]}` : `${today.getFullYear()}-${today.getMonth() + 1}-${today.getDate()}`,
                    "dataFinal": (actualDate.length === 1) ? `${beginDate[2]}-${beginDate[1]}-${parseInt(beginDate[0])}` : (actualDate !== null && actualDate.length === 2) ? `${endDate[2]}-${endDate[1]}-${endDate[0]}` : `${today.getFullYear()}-${today.getMonth() + 1}-${today.getDate()}`
                },
                config

            );
            if (print.status !== 400) {
                const blob = new Blob([print.data], { type: print.headers["Content-Type"] });
                const objectUrl = window.URL.createObjectURL(blob);

                const link = document.createElement('a');
                link.href = objectUrl;
                link.setAttribute(
                    'download',
                    'contas_receber.pdf',
                );

                // Append to html link element page
                document.body.appendChild(link);

                // Start download
                link.click();

                // // Clean up and remove the link
                link?.parentNode?.removeChild(link);


            }
        } catch (error: any) {
            setError(true)
            if (error?.response?.status === 401) {
                window.location.href = window.location.origin + '/';
            }
        }
    };

    return (
        <>
            <DesktopDefault>
                <div className="container-fluid content-dashboard">
                    <Breadcrumb title={'Financeiro / Liquidar em Lote'} />
                    <div className="suppliers mt-4 d-flex justify-content-between align-items-center">
                        <h2 className="title" style={{ color: "#707070" }}>Liquidar em Lote</h2>
                    </div>
                    <FilterBillsReceive
                        buscar={buscar}
                        billsReceive={billsReceive}
                        error={error}
                        clearActualValues={clearActualValues}
                        defaultDate={defaultDate}
                    //allowLoadFromFilter={allowLoadFromFilter}
                    />
                    <TableSettleBillsReceive
                        billsReceive={billsReceive}
                        totalRows={totalRows}
                        pageCount={pageCount}
                        setPageCount={setPageCount}
                        rowsPerPage={rowsPerPage}
                        setRowsPerPage={setRowsPerPage}
                        exportBills={exportBills}
                        printBills={printBills}
                        parameters={parameters}
                        loading={loading}
                        selectedCheck={selectedCheck}
                        setSelectedCheck={setSelectedCheck}
                        itemsToSettle={itemsToSettle}
                        setItemsToSettle={setItemsToSettle}
                    />
                </div>
            </DesktopDefault>
        </>
    )
}

export default BillsToReceive;