import React, { useEffect, useState, Key } from 'react';
import api from "../../../../services/api";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { VerticalTimeline, VerticalTimelineElement } from 'react-vertical-timeline-component';
import 'react-vertical-timeline-component/style.min.css';
import './EditPartnersLog.scss';

export interface propInfo {
    setShowLog: any;
    info: any;
};

const EditPartnersLog: React.FC<propInfo> = ({
    setShowLog, info
}: propInfo) => {
    const [item, setItem] = useState<any>(null);

    useEffect(() => {
        const getDetails = async () => {
            const token = localStorage.getItem('GroupId') || '{}';
            const config = {
                headers: { 'Authorization': `Bearer ${token}`, 'Content-Type': 'application/json' },
            };
            try {
                const res = await api.post(`/Supplier/ConsultCreditLog/${info.supplierIdentity}`, {}, config);
                if (res.status !== 400) {
                    setItem(res.data.data)
                    /* setLogs(res.data.data.logs) */
                    /* setEmails(res.data.data.messageStatusSends) */
                }
            } catch (error: any) {

            }
        };

        getDetails();
    }, [info]);

    if (item !== null) {
        return (
            <>
                <VerticalTimeline
                    layout={'1-column-left'}
                    lineColor={'#00632D'}
                >
                    {item?.length > 0 ?
                        item.map((elem: any, index: Key) => {
                            console.log(item)
                            return (
                                <>
                                    <VerticalTimelineElement
                                        className="vertical-timeline-element--work mb-40px"
                                        iconStyle={{ background: '#00632D', color: '#fff', boxShadow: 'none' }}
                                        contentStyle={{ background: '#F2F2F2', borderRadius: '15px' }}
                                        contentArrowStyle={{ borderRight: '7px solid #F2F2F2' }}
                                        icon={<FontAwesomeIcon
                                            icon={["fal", "file-alt"]}
                                            className="w-100"
                                            style={{ marginLeft: '-20px' }}
                                        />}
                                        key={index}
                                    >
                                        <>
                                            <div className="row">
                                                <div className="col-4 mb-20px">
                                                    <p className="p-0 mb-10px text-primary"><strong>Usuário</strong></p>
                                                    <span className='m-0'>{elem.nomeUsuario || "-"}</span>
                                                </div>
                                                <div className="col-4">
                                                    <p className="p-0 mb-10px text-primary"><strong>Valor antigo</strong></p>
                                                    <span className='m-0'>{Number(elem.velhoValor.replace(',', '.')).toLocaleString('pt-br',{style: 'currency', currency: 'BRL'}) || "-"}</span>
                                                </div>
                                                <div className="col-4">
                                                    <p className="p-0 mb-10px text-primary"><strong>Novo valor</strong></p>
                                                    <span className='m-0'>{Number(elem.novoValor.replace(',', '.')).toLocaleString('pt-br',{style: 'currency', currency: 'BRL'}) || "-"}</span>
                                                </div>
                                            </div>
                                            <div className="row">                                           
                                                <div className="col-4">
                                                    <p className="p-0 mb-10px text-primary"><strong>Data da atualização</strong></p>
                                                    <span className='m-0'>{elem.data || "-"}</span>
                                                </div>
                                                <div className="col-4 mb-20px">
                                                    <p className="p-0 mb-10px text-primary"><strong>Grupo</strong></p>
                                                    <span className='m-0'>{elem.logGroup || "-"}</span>
                                                </div>
                                            </div>
                                        </>

                                    </VerticalTimelineElement>
                                </>
                            )
                        })
                        :
                        <>
                            <div className="d-flex justify-content-center text-center">
                                <h5 style={{ color: "#076fa2" }}>Nenhum log encontrado</h5>
                            </div>
                        </>
                    }
                </VerticalTimeline>

                {/* <hr className='w-100 my-4' style={{ borderBottom: '1px solid' }} />
                <div className="title"><h5 className="text-center">Emails Reminder</h5></div>

                <VerticalTimeline
                    layout={'1-column-left'}
                    lineColor={'#076fa2'}
                >
                    {emails?.length > 0 ?
                        emails.map((elem: any, index: Key) => {
                            return (
                                <>
                                    <VerticalTimelineElement
                                        className="vertical-timeline-element--work mb-40px"
                                        iconStyle={{ background: '#076fa2', color: '#fff', boxShadow: 'none' }}
                                        contentStyle={{ background: '#F2F2F2', borderRadius: '15px' }}
                                        contentArrowStyle={{ borderRight: '7px solid #F2F2F2' }}
                                        icon={<FontAwesomeIcon
                                            icon={["fal", "envelope"]}
                                            className="w-100"
                                            style={{ marginLeft: '-20px' }}
                                        />}
                                        key={index}
                                    >
                                        <>
                                            <div className="row">
                                                <div className="col-4  mb-20px">
                                                    <div className="d-flex align-items-center">
                                                        {
                                                            elem.statusSend === "DELIVERED"
                                                                ?
                                                                <h5 style={{ width: "120px" }}><Badge className="ml-2 mr-4 mt-2 px-4" bg={'success'} style={{ width: "120px" }}>Entregue</Badge></h5>
                                                                :
                                                                <></>
                                                        }
                                                        {
                                                            elem.statusSend === "SEND"
                                                                ?
                                                                <h5 style={{ width: "120px" }}><Badge className="ml-2 mr-4 mt-2 px-4 badge grey" style={{ width: "120px" }}>Enviado</Badge></h5>
                                                                :
                                                                <></>
                                                        }
                                                        {
                                                            elem.statusSend === "NOT_SEND"
                                                                ?
                                                                <h5 style={{ width: "120px" }}><Badge className="ml-2 mr-4 mt-2 px-4" bg={'danger'} style={{ width: "120px" }}>Não entregue</Badge></h5>
                                                                :
                                                                <></>
                                                        }
                                                    </div>
                                                </div>
                                                <div className="col-4  mb-20px"></div>
                                                <div className="col-4  mb-20px"></div>
                                            </div>
                                            <div className="row">
                                                <div className="col-4 mb-20px">
                                                    <p className="p-0 mb-10px text-primary"><strong>Data do Envio</strong></p>
                                                    <p className='m-0'>{elem.dateMessage.split('T').join(' - ')}</p>
                                                </div>
                                                <div className="col-4 mb-20px">
                                                    <p className="p-0 mb-10px text-primary"><strong>Canal</strong></p>
                                                    <p className='m-0'>{`${elem.channelName} foi enviado para:`}</p>
                                                    <p className='m-0'>{elem.recipient}</p>
                                                </div>
                                                <div className="col-4 mb-20px">
                                                    <p className="p-0 mb-10px text-primary"><strong>Mensagem de retorno</strong></p>
                                                    <p className='m-0'>{elem.messageType}</p>
                                                </div>
                                            </div>
                                        </>

                                    </VerticalTimelineElement>
                                </>
                            )
                        })
                        :
                        <>
                            <div className="d-flex justify-content-center text-center">
                                <h5>Nenhum email encontrado</h5>
                            </div>
                        </>
                    }

                </VerticalTimeline> */}
            </>
        );
    } else {
        return (
            <>
                <div className='animated-background' style={{ height: '196px', width: '100%', borderRadius: '15px', marginBottom: '40px' }}></div>
                <div className='animated-background' style={{ height: '196px', width: '100%', borderRadius: '15px', marginBottom: '40px' }}></div>
                <div className='animated-background' style={{ height: '196px', width: '100%', borderRadius: '15px', marginBottom: '40px' }}></div>
                <div className='animated-background' style={{ height: '196px', width: '100%', borderRadius: '15px', marginBottom: '40px' }}></div>
                <div className='animated-background' style={{ height: '196px', width: '100%', borderRadius: '15px', marginBottom: '40px' }}></div>
            </>
        );
    }

}

export default EditPartnersLog;