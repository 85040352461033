/* eslint-disable dot-location */
import React, { useState } from "react";

import "react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css";
import "react-bootstrap-table-next/dist/react-bootstrap-table2.css";
import BootstrapTable from "react-bootstrap-table-next";
//import paginationFactory from "react-bootstrap-table2-paginator";

//STYLES
import "./TableExportERPLogs.scss";
import Pagination from "../../../../components/Pagination/Pagination";
import ModalShow from "../../../../components/Modal/ModalShow/ModalShow";
import { Modal } from "react-bootstrap";
import DetailsLogERM from "./components/DetailsLogERM";

export interface propTable {
    logs: any;
    totalRows: any;
    pageCount: any;
    setPageCount: any;
    rowsPerPage: any;
    setRowsPerPage: any;
    loading: any;
}

const TableExportERPLogs: React.FC<propTable> = ({
    logs, totalRows, pageCount, setPageCount, rowsPerPage, setRowsPerPage, loading
}: propTable) => {

    const [modalShow, setModalShow] = useState<any>(false);
    const [modalMessage, setModalMessage] = useState<string[] | string>("Carregando");
    const [modalLog, setModalLog] = useState<any>(null);
    const [detailsModal, setDetailsModal] = useState<any>(false);
    const [currentLog, setCurrentLog] = useState<any>(null);

    function convertDate(cell: any, row: any) {
        if (!cell) return '';
        //var date = convertUTCDateToLocalDate(new Date(cell));
        var aux: any = cell.split("T")[0].split("-");
        var date: any = `${aux[2]}/${aux[1]}/${aux[0]}`;
        return (
            <>
                {date}
            </>
        );
    }

    function addXml(cell: any, row: any) {
        //let listOfErrors: any = row.erros.split(';');
        let aux: any;
        var regex: any = /(<\w+[^<]*?)\s+([\w-]+)="([^"]+)">/;
        let isValid: boolean = true;

        while (cell.match(regex)) cell = cell.replace(regex, '<$2>$3</$2>$1>'); // For attributes

        aux = cell.replace(/\s/g, ' ').  // Finds all the white space converts to single space
            replace(/< *\?[^>]*?\? *>/g, ''). //Finds the XML header and removes it
            replace(/< *!--[^>]*?-- *>/g, ''). //Finds and removes all comments
            replace(/< *(\/?) *(\w[\w-]+\b):(\w[\w-]+\b)/g, '<$1$2_$3').
            replace(/< *(\w[\w-]+\b)([^>]*?)\/ *>/g, '< $1$2>').
            replace(/(\w[\w-]+\b):(\w[\w-]+\b) *= *"([^>]*?)"/g, '$1_$2="$3"').
            replace(/< *(\w[\w-]+\b)((?: *\w[\w-]+ *= *" *[^"]*?")+ *)>( *[^< ]*?\b.*?)< *\/ *\1 *>/g, '< $1$2 value="$3">').
            //replace(/ *(\w[\w-]+\b) *= *"([^>]*?)" */g, '< $1>$2').
            replace(/< *(\w[\w-]+\b) *</g, '<$1>< ').
            replace(/> *>/g, '>').
            //replace(/< *\/ *(\w[\w-]+\b) *> *< *\1 *>/g, '').  // breaks the output?
            replace(/"/g, '\\"').
            replace(/< *(\w[\w-]+\b) *>([^<>]*?)< *\/ *\1 *>/g, '"$1":"$2",').
            replace(/< *(\w[\w-]+\b) *>([^<>]*?)< *\/ *\1 *>/g, '"$1":{$2},').
            replace(/< *(\w[\w-]+\b) *>(?=.*?< \/\1\},\{)/g, '"$1":[{').
            replace(/< *(\w[\w-]+\b) *>(?=("\w[\w-]+\b)":\{.*?\},\2)(.*?)< *\/ *\1 *>/, '"$1":[$3],').
            replace(/< \/(\w[\w-]+\b)\},\{\1>/g, '},{').
            replace(/< *(\w[\w-]+\b)[^>]*?>/g, '"$1":{').
            replace(/< *\/ *\w[\w-]+ *>/g, '},').
            replace(/\} *,(?= *(\}|\]))/g, '}').
            replace(/] *,(?= *(\}|\]))/g, ']').
            replace(/" *,(?= *(\}|\]))/g, '"').
            replace(/ *, *$/g, '');

        try {
            JSON.parse(`{${aux}}`)
        } catch {
            isValid = false;
        }

        /* 	SGX9DDN */

        return (
            <>
                {
                    isValid === true
                        ?
                        <>

                            <div className="d-flex">
                                <>
                                    <button onClick={() => { setDetailsModal(true); setCurrentLog(JSON.parse(`{${aux}}`)) }} type='button' className="btn btn-outline-primary errors-btn" style={{ border: '1px solid #076fa2' }}>Ver</button>
                                </>
                            </div>
                        </>
                        :
                        <>
                            <div className="d-flex ">
                                <button onClick={() => {
                                    setModalMessage(cell);
                                    setModalLog(0);
                                    setModalShow(true);
                                }} type='button' className="btn btn-outline-primary errors-btn" style={{ border: '1px solid #076fa2' }}>Ver</button>
                            </div>
                        </>
                }
            </>
        );
    }

    function addErrors(cell: any, row: any) {

        return (
            <>
                {
                    cell !== null && cell !== '' ?
                        <div className="d-flex ">
                            <button onClick={() => {
                                setModalMessage(cell);
                                setModalLog(1);
                                setModalShow(true);
                            }} type='button' className="btn btn-outline-primary errors-btn" style={{ border: '1px solid #076fa2' }}>Ver</button>
                        </div>
                        :
                        <></>
                }
            </>
        );
    }


    function addStatus(cell: any, row: any) {
        return (
            <div
                className={
                    cell === "Sucesso"
                        ? "bg-analise"
                        : cell === "Pendente"
                            ? "bg-yellow"
                            : cell === "Erro"
                                ? "bg-inativo"
                                : ""
                }
            >
                {cell}
            </div>
        );
    }

    const columns = [
        { dataField: "date", text: "Data de envio", formatter: convertDate },
        { dataField: "envelopeXml", text: "Envelope XML", formatter: addXml },
        { dataField: "erroMessage", text: "Erro", formatter: addErrors },
        { dataField: "reference", text: "Referência" },
        { dataField: "referenceAux", text: "Referência Auxiliar" },
        { dataField: "statusDesc", text: "Status", formatter: addStatus },
    ];

    /*--------------------------------------------------*/

    /* In case of empty table */
    const notFoundColumns = [{ dataField: "logs", text: "Logs" }];

    const notFoundProducts = [{ logs: "Nenhum log encontrado" }];
    /* [END] In case of empty table */

    /* loading table */

    function loadingFunc() {
        return (
            <>
                <div className="animated-background row-loading"></div>
            </>
        )
    }

    const loadingColumns = [{ dataField: "contasd", text: "Carregando", formatter: loadingFunc }];

    const loadingProducts = [
        { contas: "" }, { contas: "" }, { contas: "" }, { contas: "" }, { contas: "" },
        { contas: "" }, { contas: "" }, { contas: "" }, { contas: "" }, { contas: "" }
    ];
    /* [END] loading table */

    const rowClasses = (row: any, rowIndex: any) => {
        if (row.statusDesc === "Sucesso") {
            return 'border-analise';
        } else if (row.statusDesc === "Pendente") {
            return 'border-pendente-escala';
        } else {
            return 'border-inativo';
        }
    };

    if (logs !== null) {
        return (
            <>
                <div className="table-default bills-receive-table">
                    <div className="table-container">

                        <BootstrapTable
                            bootstrap4
                            keyField="id"
                            data={logs}
                            columns={columns}
                            rowClasses={rowClasses}
                            striped={true}
                        />
                        <Pagination
                            totalRows={totalRows}
                            pageCount={pageCount}
                            setPageCount={setPageCount}
                            rowsPerPage={rowsPerPage}
                            setRowsPerPage={setRowsPerPage}
                            selectNumberRows="yes"
                        />
                    </div>
                </div>

                {/* Modal de detalhes */}
                <Modal
                    className="modal-custom exportERM-details"
                    show={detailsModal !== false}
                    onHide={() => {
                        setCurrentLog(null);
                        setDetailsModal(false);
                    }}
                    size="lg"
                    aria-labelledby="contained-modal-title-vcenter"
                >
                    <>
                        <DetailsLogERM
                            currentLog={currentLog}
                            setDetailsModal={setDetailsModal}
                        />
                    </>
                </Modal>
                {/* END - Modal de detalhes */}

                <ModalShow
                    modalShow={modalShow}
                    setModalShow={setModalShow}
                    modalMessage={modalMessage}
                    setModalMessage={setModalMessage}
                    modalLog={modalLog}
                    setModalLog={setModalLog}
                />
            </>
        );
    } else if (logs === null && loading === true) {
        return (
            <>
                <div className="table-default bills-receive-table loading not-found">
                    <div className="table-container">
                        <BootstrapTable
                            bootstrap4
                            keyField="id"
                            data={loadingProducts}
                            hover={true}
                            columns={loadingColumns}
                            striped={true}
                        />
                    </div>
                </div>
            </>
        )
    } else {
        return (
            <>
                <div className="table-default bills-receive-table not-found">
                    <div className="table-container">
                        {/*             <div className="table-title">
              <h3 className="">Fornecedores</h3>
            </div> */}

                        <BootstrapTable
                            bootstrap4
                            keyField="id"
                            data={notFoundProducts}
                            hover={true}
                            columns={notFoundColumns}
                            striped={true}
                        />
                    </div>
                </div>
            </>
        );
    }
}

export default TableExportERPLogs;
