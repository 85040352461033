import React, { useState, useEffect, Key } from "react";
import Table from "react-bootstrap/Table";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import Dropdown from "react-bootstrap/Dropdown";

import { useTranslation } from "react-i18next";

//STYLES
import "./FinancialTable.scss";

//JSON
import ModalDefault from "../../../../components/Modal/ModalDefault/ModalDefault";
import FormFinancial from "../../../../components/Forms/FormFinancial/FormFinancial";

export interface propModal {
  action: any;
  setMessageError: any;
  messageError: any;
  infoEdit: any;
  isEdit: any;
  disableAddContact?: any;
  hasSavedInputs?: any;
}

const FinancialTable: React.FC<propModal> = ({
  action,
  setMessageError,
  messageError,
  infoEdit,
  isEdit,
  disableAddContact = false,
  hasSavedInputs = false
}: propModal) => {

  const [controlObject, setControlObject] = useState<any>((isEdit === true || hasSavedInputs === true) ? infoEdit.costCenterSupplierDTO : []);
  const [closeModal, setCloseModal] = useState<any>(false);
  const { t } = useTranslation();

  const handleActionModal = (objectModal: any) => {
    setControlObject([...controlObject, objectModal]);
  };

  function removeRow(index: any) {
    var aux: any = [];

    for (var i: any = 0; i < controlObject.length; i++) {
      if (i !== index) {
        aux.push(controlObject[i]);
      }
    }

    setControlObject(aux);
  }

  useEffect(() => {
    if (disableAddContact === false) { //evita erro no single do fornecedor
      if (controlObject?.length > 0) {
        setMessageError(false);
      } else {
      }
      action(controlObject);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [controlObject]);

  return (
    <>
      <div className="table-add-default suppliers-financial-table">
        <div className="table-container w-100" style={{ border: messageError === true ? '1px solid #dc3545' : '' }}>
          <Table striped bordered>
            <thead>
              <tr>
                <th>{t("accordionFinancial.tableFinancial.centerCoast")}</th>
                <th>{t("accordionFinancial.tableFinancial.rateio")}</th>
                <th>{t("accordionFinancial.tableFinancial.actions")}</th>
              </tr>
            </thead>
            <tbody>
              {controlObject?.map((obj: any, index: Key) => (
                <tr key={index}>
                  <td>{obj.coastCenterDesc || obj.coastCenterId?.description || obj.coastCenterIdDesc}</td>
                  <td>{obj.rateio}%</td>
                  <td>
                    {
                      <div className="center-table options-table">
                        <Dropdown drop="down">
                          <Dropdown.Toggle variant="light ">
                            <FontAwesomeIcon
                              size="lg"
                              icon={["fal", "ellipsis-h"]}
                            />
                          </Dropdown.Toggle>

                          {disableAddContact === false
                            ?
                            <Dropdown.Menu>
                              <Dropdown.Item
                                onClick={() => removeRow(index)}
                                eventKey="1"
                                className="text-danger removeRow"
                              >
                                <FontAwesomeIcon
                                  icon={["fal", "trash-alt"]}
                                //className="mr-2"
                                />
                                {t("accordionFinancial.tableFinancial.remove")}
                              </Dropdown.Item>
                            </Dropdown.Menu>
                            :
                            <></>
                          }
                        </Dropdown>
                      </div>
                    }
                  </td>
                </tr>
              ))}
              {disableAddContact === false
                ?
                <div className="add-contact">
                  <div className="text-center">
                    <ModalDefault
                      class="btn-add"
                      name="+"
                      title="Adicionar Financeiro"
                      classModal="modal-pos-tax"
                      closeModal={closeModal}
                      setCloseModal={setCloseModal}
                    >
                      <FormFinancial
                        action={handleActionModal}
                        setCloseModal={setCloseModal}
                        controlObject={controlObject}
                      />
                    </ModalDefault>
                    <p style={{ color: "#dc3545", marginTop: "10px", marginBottom: "0", fontSize: "14px" }}>
                      {
                        messageError === true
                          ?
                          'Por favor, adicione um centro de custo, rateios devem somar 100%'
                          :
                          ''
                      }
                    </p>
                  </div>
                </div>
                :
                <></>
              }
            </tbody>
          </Table>
        </div>
      </div>
    </>
  );
};

export default FinancialTable;
