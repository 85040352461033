import React, { useEffect, useState } from 'react';
import api from '../../services/api';

import './RPSIssuance.scss';

//COMPONENTS

import Breadcrumb from '../../components/Breadcrumb/Breadcrumb';
import FilterRPSIssuance from './components/FilterRPSIssuance/FilterRPSIssuance';
import TableRPSIssuance from './components/TableRPSIssuance/TableRPSIssuance';
import DesktopDefault from '../../templates/DesktopDefault';


function RPSIssuance() {
    //const { t } = useTranslation();
    const [sessions, setSessions] = useState<any>(null);
    const [error, setError] = useState<any>();
    const [loading, setLoading] = useState<any>(false);

    /* Pagination */
    const [totalRows, setTotalRows] = useState<any>();
    const [pageCount, setPageCount] = useState<any>(1);
    const [rowsPerPage, setRowsPerPage] = useState<any>(10);
    /* END - Pagination */

    const hoje = new Date();
    const ontem = new Date(hoje.getFullYear(), hoje.getMonth(), hoje.getDate() - 1); // hoje - 1 dia = ontem
    const dataPadrao = `${ontem.getDate()}/${ontem.getMonth()+1}/${ontem.getFullYear()}`;

    /* Filter */
    const [filterDate, setFilterDate] = useState<any>(dataPadrao);
    /* END - Filter */

    useEffect(() => {
        setSessions(null);
        setLoading(true);
        const token = localStorage.getItem('GroupId') || '{}';
        const config = {
            headers: { 'Authorization': `Bearer ${token}` },
        };

        let beginDate = filterDate.split('/');

        async function listSuppliers() {
            try {
                
                const { data } = await api.post('/RPSIssuance/GetReport',
                    {
                        "page": pageCount,
                        "rowsPerPage": rowsPerPage,
                        "dataInicial": filterDate ? `${beginDate[2]}-${beginDate[1]}-${beginDate[0]}` : `${ontem.getFullYear()}-${ontem.getMonth()+1}-${ontem.getDate()}`,
                        "dataFinal": filterDate ? `${beginDate[2]}-${beginDate[1]}-${beginDate[0]}` : `${ontem.getFullYear()}-${ontem.getMonth()+1}-${ontem.getDate()}`,
                        "session": "",
                        "status": null,                      
                        "tipo": null,
                    }, config
                );

                if (data.status !== 400) {
                    setLoading(false);
                    setSessions(data.data.rows);
                    setTotalRows(data.data.rowsCount);
                }
            } catch (error: any) {
                setLoading(false);
                if (error?.response?.status === 401) {
                    window.location.href = window.location.origin + '/';
                }
            }
        }
        listSuppliers();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [pageCount, rowsPerPage]);

    async function buscar(date: any) {
        setPageCount(1);
        setFilterDate(date);
        
        setSessions(null);
        setLoading(true);
        const token = localStorage.getItem('GroupId') || '{}';
        const config = {
            headers: { 'Authorization': `Bearer ${token}` },
        };

        let beginDate = []

        beginDate = date.split('/');

        try {
            const res = await api.post('/RPSIssuance/GetReport',
                {
                    "page": 1,
                    "rowsPerPage": rowsPerPage,
                    // "dataInicial": date.length > 0 ? `${beginDate[0]}-${beginDate[1]}-${beginDate[2]}` : '',
                    // "dataFinal": date.length === 1 ? `${beginDate[0]}-${beginDate[1]}-${parseInt(beginDate[2]) + 1}` : date.length === 2 ? `${endDate[0]}-${endDate[1]}-${endDate[2]}` : '',
                    "dataInicial": date ? `${beginDate[2]}-${beginDate[1]}-${beginDate[0]}` : `${ontem.getFullYear()}-${ontem.getMonth()+1}-${ontem.getDate()}`,
                    "dataFinal": date ? `${beginDate[2]}-${beginDate[1]}-${beginDate[0]}` : `${ontem.getFullYear()}-${ontem.getMonth()+1}-${ontem.getDate()}`,
                    //"orderByField": ""
                }, config
            );
            if (res.status !== 400) {
                setLoading(false);
                setSessions(res.data.data.rows);
                setTotalRows(res.data.data.rowsCount);
            }
        } catch (error: any) {
            setLoading(false);
            setError(true)
            if (error?.response?.status === 401) {
                window.location.href = window.location.origin + '/';
            }
        }
    };

    function download(file: any, filetype: any, filename: any) { // file: bytes/string, name: string
        //const blob = new Blob([file], { type: filetype });
        const blob = `data:${filetype};base64,${file}`;
        //console.log(blob);
        //if(navigator.msSaveBlob){ // For ie and Edge
        //  return navigator.msSaveBlob(blob, filename);
        //}
        //else{
        let link = document.createElement('a');
        link.href = blob;//window.URL.createObjectURL(blob);
        link.download = filename;
        document.body.appendChild(link);
        link.dispatchEvent(new MouseEvent('click', { bubbles: true, cancelable: true, view: window }));
        link.remove();
        window.URL.revokeObjectURL(link.href);
        //}
    };

    async function exportReport() {
        const token = localStorage.getItem('GroupId') || '{}';
        const config = {
            headers: { 'Authorization': `Bearer ${token}` },
        };

        let beginDate = []

        beginDate = filterDate.split('/')

        try {
            const res = await api.post('RPSIssuance/ExportReport',
                {
                    "page": 1,
                    "rowsPerPage": 0,
                    // "dataInicial": date.length > 0 ? `${beginDate[0]}-${beginDate[1]}-${beginDate[2]}` : '',
                    // "dataFinal": date.length === 1 ? `${beginDate[0]}-${beginDate[1]}-${parseInt(beginDate[2]) + 1}` : date.length === 2 ? `${endDate[0]}-${endDate[1]}-${endDate[2]}` : '',
                    "dataInicial": filterDate ? `${beginDate[2]}-${beginDate[1]}-${beginDate[0]}` : `${ontem.getFullYear()}-${ontem.getMonth()+1}-${ontem.getDate()}`,
                    "dataFinal": filterDate ? `${beginDate[2]}-${beginDate[1]}-${beginDate[0]}` : `${ontem.getFullYear()}-${ontem.getMonth()+1}-${ontem.getDate()}`,
                    //"orderByField": ""
                }, config
            );
            if (res.status !== 400) {
                //download file
                const dados = res.data.data;
                download(dados.file, dados.fileType, dados.fileName);
            }
        } catch (error: any) {
            setError(true)
            if (error?.response?.status === 401) {
                window.location.href = window.location.origin + '/';
            }
        }
    };

    async function printReport() {
        const token = localStorage.getItem('GroupId') || '{}';
        const config = {
            headers: { 'Authorization': `Bearer ${token}` },
        };

        let beginDate = []

        beginDate = filterDate.split('/')

        try {
            const res = await api.post('RPSIssuance/PrintReport',
                {
                    "page": 1,
                    "rowsPerPage": 0,
                    // "dataInicial": date.length > 0 ? `${beginDate[0]}-${beginDate[1]}-${beginDate[2]}` : '',
                    // "dataFinal": date.length === 1 ? `${beginDate[0]}-${beginDate[1]}-${parseInt(beginDate[2]) + 1}` : date.length === 2 ? `${endDate[0]}-${endDate[1]}-${endDate[2]}` : '',
                    "dataInicial": filterDate ? `${beginDate[2]}-${beginDate[1]}-${beginDate[0]}` : `${ontem.getFullYear()}-${ontem.getMonth()+1}-${ontem.getDate()}`,
                    "dataFinal": filterDate ? `${beginDate[2]}-${beginDate[1]}-${beginDate[0]}` : `${ontem.getFullYear()}-${ontem.getMonth()+1}-${ontem.getDate()}`,
                    //"orderByField": ""
                }, config
            );
            if (res.status !== 400) {
                //download file
                //console.log(res.data.data);
                const dados = res.data.data;
                download(dados.file, dados.fileType, dados.fileName);
            }
        } catch (error: any) {
            setError(true)
            if (error?.response?.status === 401) {
                window.location.href = window.location.origin + '/';
            }
        }
    };

    return (
        <>
            <DesktopDefault>
                <div className="container-fluid content-dashboard">
                    <Breadcrumb title={'Financeiro / Exportação ERP / Emissão de RPS'} />
                    <div className="suppliers mt-4 d-flex justify-content-between align-items-center">
                        <h2 className="title" style={{ color: "#707070" }}>Emissão e Verificação de RPS</h2>
                    </div>
                    <FilterRPSIssuance
                        buscar={buscar}
                        sessions={sessions}
                        error={error}
                        setFilterDate={setFilterDate}
                    />
                    <TableRPSIssuance
                        sessions={sessions}
                        totalRows={totalRows}
                        pageCount={pageCount}
                        setPageCount={setPageCount}
                        rowsPerPage={rowsPerPage}
                        setRowsPerPage={setRowsPerPage}
                        exportReport={exportReport}
                        printReport={printReport}
                        loading={loading}
                        dateSelect={filterDate}
                    />
                </div>
            </DesktopDefault>
        </>
    );
}

export default RPSIssuance;