/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button, Card, Col, Form, InputGroup, Modal, Row } from "react-bootstrap";
import BootstrapTable from "react-bootstrap-table-next";
import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";

import { Controller, useForm } from "react-hook-form";
import OnlyTime from "../../components/Calendar/OnlyTime";


import "./TariffPromoByHour.scss";
import SingleCalendarWithDefaultValue from "../../components/Calendar/SingleCalendarWithDefaultValue";
import { ErrorMessage } from "@hookform/error-message";
import ModalShow from "../../components/Modal/ModalShow/ModalShow";

export interface operation {
  promotionByHourModalShow?: any;
  setPromotionByHourModalShow: any;
  isEdit?: boolean;
  infoEdit?: any;
  action: any;
  actionEdit: any;
  getDateValues: any;
  promoObject: any;
  promoIdToEdit: any;
  isTicket?: boolean
}

const TariffPromoByHour: React.FC<operation> = React.memo(
  ({ promotionByHourModalShow, setPromotionByHourModalShow = false, isEdit = false, infoEdit, action, actionEdit, getDateValues, promoObject, promoIdToEdit, isTicket = false }: operation) => {
    const [beginDate, setBeginDate] = useState<any>("");
    const [endDate, setEndDate] = useState<any>("");
    const [purchaseBeginDate, setPurchaseBeginDate] = useState<any>("");
    const [purchaseEndDate, setPurchaseEndDate] = useState<any>("");
    const [purchaseTimeStart, setPurchaseTimeStart] = useState<any>("00:00");
    const [purchaseTimeEnd, setPurchaseTimeEnd] = useState<any>("23:59");
    const [timeStart, setTimeStart] = useState<any>("00:00");
    const [timeEnd, setTimeEnd] = useState<any>("00:00");
    const [promoDays, setPromoDays] = useState<any>([
      {
        day: "dom",
        value: false,
      },
      {
        day: "seg",
        value: false,
      },
      {
        day: "ter",
        value: false,
      },
      {
        day: "qua",
        value: false,
      },
      {
        day: "qui",
        value: false,
      },
      {
        day: "sex",
        value: false,
      },
      {
        day: "sab",
        value: false,
      },
    ]);

    const [modalShow, setModalShow] = useState<boolean>(false);
    const [modalMessage, setModalMessage] = useState<string>('');

    useEffect(() => {
      if (promotionByHourModalShow === true) {
        if (isEdit === true) {
          let aux: any = promoDays;

          if (isTicket) {
            aux.forEach((element: any) => {
              element.value = infoEdit?.daysOfWeek[element.day];
            });

            aux[0].value = infoEdit?.dom === 1;
            aux[1].value = infoEdit?.seg === 1;
            aux[2].value = infoEdit?.ter === 1;
            aux[3].value = infoEdit?.qua === 1;
            aux[4].value = infoEdit?.qui === 1;
            aux[5].value = infoEdit?.sex === 1;
            aux[6].value = infoEdit?.sab === 1;

            //setValue("hourStart", infoEdit?.hourStart);
            //setTimeStart(infoEdit?.hourStart);
            //setTimeEnd(infoEdit?.hourEnd);
            //setPurchaseTimeStart(infoEdit?.hourStart);
            //setPurchaseTimeEnd(infoEdit?.hourEnd);
            setBeginDate(new Date(infoEdit?.startTime + "T00:00:00").toLocaleDateString("pt-BR"));
            setEndDate(new Date(infoEdit?.endTime + "T00:00:00").toLocaleDateString("pt-BR"));
            //setPurchaseBeginDate(new Date(infoEdit?.purchaseStartDate + "T00:00:00").toLocaleDateString("pt-BR"));
            //setPurchaseEndDate(new Date(infoEdit?.purchaseEndDate + "T00:00:00").toLocaleDateString("pt-BR"));
            setValue("percentDiscount", infoEdit?.percentage);
          } else {
            aux.forEach((element: any) => {
              element.value = infoEdit?.daysOfWeek[element.day];
            });

            //setValue("hourStart", infoEdit?.hourStart);
            setTimeStart(infoEdit?.hourStart);
            setTimeEnd(infoEdit?.hourEnd);
            setPurchaseTimeStart(infoEdit?.hourStart);
            setPurchaseTimeEnd(infoEdit?.hourEnd);
            setBeginDate(new Date(infoEdit?.startDate + "T00:00:00").toLocaleDateString("pt-BR"));
            setEndDate(new Date(infoEdit?.endDate + "T00:00:00").toLocaleDateString("pt-BR"));
            setPurchaseBeginDate(new Date(infoEdit?.purchaseStartDate + "T00:00:00").toLocaleDateString("pt-BR"));
            setPurchaseEndDate(new Date(infoEdit?.purchaseEndDate + "T00:00:00").toLocaleDateString("pt-BR"));
            setValue("percentDiscount", infoEdit?.percentDiscount);
          }
        } else {
          resetModalInputs();
        }
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [promotionByHourModalShow])

    const resetModalInputs = () => {
      setTimeEnd("00:00");
      setTimeStart("00:00");
      setPurchaseTimeEnd("00:00");
      setPurchaseTimeStart("00:00");
      setBeginDate("");
      setEndDate("");
      setPurchaseBeginDate("");
      setPurchaseEndDate("");
      setValue("percentDiscount", "");
      promoDays.map((elem: any) => elem.value = false);
    }

    const validateInputs = () => {

      /* COMPRA */

      //data de compra vazia
      if (purchaseBeginDate === '' || purchaseEndDate === '') {
        setModalMessage('Favor inserir uma data de compra');
        setModalShow(true);

        return false;
      }

      //data de compra inicial na frente da data de compra final
      if (Number(purchaseBeginDate.split('/').reverse().join('')) > Number(purchaseEndDate.split('/').reverse().join(''))) {
        setModalMessage('Data de compra inválida');
        setModalShow(true);

        return false;
      }

      //hona da compra inicial maior que a hora de compra final
      if (purchaseTimeStart.replace(":", "") > purchaseTimeEnd.replace(":", "")) {
        setModalMessage('Horário de compra inválida');
        setModalShow(true);

        return false;
      }

      /* UTILIZAÇÃO */

      //datas de utilização vazias
      if (beginDate === '' || endDate === '') {
        setModalMessage('Favor inserir uma data para utilização da promoção');
        setModalShow(true);

        return false;
      }

      //data de utilização inicial na frente da data de utilização final
      if (Number(beginDate.split('/').reverse().join('')) > Number(endDate.split('/').reverse().join(''))) {
        setModalMessage('Data de utilização inválida');
        setModalShow(true);

        return false;
      }

      //hora da utilização inicial na frente da hora de utilização final
      if (timeStart.replace(":", "") > timeEnd.replace(":", "")) {
        setModalMessage('Horário de utilização inválida');
        setModalShow(true);

        return false;
      }

      //caso ainda n tenha nenhuma promoção cadastrada, pode passar
      if (promoObject?.length === 0) {
        return true;
      }

      if (isEdit === true) {
        return true;
      }

      let promoDate: any;
      let promoDate2: any;
      let begin: any;
      let end: any;
      let promoHour: any;
      let promoHour2: any;
      let beginHour: any;
      let endHour: any;
      let validator: boolean = true;

      //Valida com as outras promoções cadastradas para não ter promoção com datas sobrepostas
      promoObject?.forEach((elem: any) => {
        promoDate = parseInt(elem.startDate.replaceAll("-", ""));
        promoDate2 = parseInt(elem.endDate.replaceAll("-", ""));
        begin = parseInt(beginDate.split("/").reverse().join(""));
        end = parseInt(endDate.split("/").reverse().join(""));

        promoHour = parseInt(elem.hourStart.replaceAll(":", ""));
        promoHour2 = parseInt(elem.hourEnd.replaceAll(":", ""));
        beginHour = parseInt(timeStart.replaceAll(":", ""));
        endHour = parseInt(timeEnd.replaceAll(":", ""));

        if (promoHour <= endHour && promoDate2 >= begin) {
          if (promoDate <= end && promoHour2 >= beginHour) {

            validator = false;
          }
        }
      })

      if (!validator) {
        setModalMessage('Já existe uma promoção com esses dias/horários cadastrados');
        setModalShow(true);

        return false;
      }

      return true;
    }

    //modal de promoção
    const handleRegisterPromotion = () => {
      if (!validateInputs()) {
        return;
      }

      let id: number = 0;
      let aux: any = {};
      let arrDays: any = [];

      promoDays.map((elem: any) => {
        if (elem.value === true) {
          arrDays.push("" + elem.day)
        }
        return arrDays;
      });

      if (isTicket) {
        //aux.purchaseHourStart = purchaseTimeStart.toString();
        //aux.purchaseHourEnd = purchaseTimeEnd.toString();
        //aux.hourStart = timeStart.toString();
        //aux.hourEnd = timeEnd.toString();
        aux.typeDiscount = getValues("typeDiscount");
        aux.percentage = aux.typeDiscount === "percent" ? (Number(getValues("percentDiscount"))/*  + "%" */ || 0) : ("R$ " + getValues("percentDiscount") + ",00" || 0);
        //aux.purchaseStartDate = purchaseBeginDate.split('/').reverse().join('-');
        //aux.purchaseEndDate = purchaseEndDate.split('/').reverse().join('-');
        aux.startTime = beginDate.split('/').reverse().join('-');
        aux.endTime = endDate.split('/').reverse().join('-');

        aux.dom = arrDays.includes('dom') ? 1 : 2;
        aux.seg = arrDays.includes('seg') ? 1 : 2;
        aux.ter = arrDays.includes('ter') ? 1 : 2;
        aux.qua = arrDays.includes('qua') ? 1 : 2;
        aux.qui = arrDays.includes('qui') ? 1 : 2;
        aux.sex = arrDays.includes('sex') ? 1 : 2;
        aux.sab = arrDays.includes('sab') ? 1 : 2;

        promoObject?.forEach((element: any) => {
          if (element.id > id) {
            id = element.id;
          }
        });
        aux.id = isEdit === true ? promoIdToEdit.id : id + 1;

      } else {
        aux.purchaseHourStart = purchaseTimeStart.toString();
        aux.purchaseHourEnd = purchaseTimeEnd.toString();
        aux.hourStart = timeStart.toString();
        aux.hourEnd = timeEnd.toString();
        aux.typeDiscount = getValues("typeDiscount");
        aux.percentDiscount = aux.typeDiscount === "percent" ? (Number(getValues("percentDiscount"))/*  + "%" */ || 0) : ("R$ " + getValues("percentDiscount") + ",00" || 0);
        aux.purchaseStartDate = purchaseBeginDate.split('/').reverse().join('-');
        aux.purchaseEndDate = purchaseEndDate.split('/').reverse().join('-');
        aux.startDate = beginDate.split('/').reverse().join('-');
        aux.endDate = endDate.split('/').reverse().join('-');

        aux.status = 1;
        aux.statusDesc = "Ativo";

        aux.daysOfWeek = {
          "dom": arrDays.includes('dom'),
          "seg": arrDays.includes('seg'),
          "ter": arrDays.includes('ter'),
          "qua": arrDays.includes('qua'),
          "qui": arrDays.includes('qui'),
          "sex": arrDays.includes('sex'),
          "sab": arrDays.includes('sab'),
        }

        promoObject?.forEach((element: any) => {
          if (element.id > id) {
            id = element.id;
          }
        });
        aux.id = isEdit === true ? promoIdToEdit.id : id + 1;
      }



      if (isEdit === true) {
        actionEdit(aux);
      } else {
        action(aux);
      }

      resetModalInputs();

      setPromotionByHourModalShow(false);
    }

    const selectAll = () => {
      let aux: any = promoDays;
      let isTrue: any = getValues("allDays");
      aux.forEach((elem: any) => { elem.value = !isTrue });

      setPromoDays(() => [...aux])
    }

    function changePromoDays(index: number) {
      let aux: any = promoDays;
      aux[index].value = !aux[index].value;

      setPromoDays(() => [...aux])
    }

    const {
      control,
      getValues,
      setValue,
      watch,
      formState: { errors },
    } = useForm({});
    const watchDiscout: any = watch("typeDiscount");
    const watchPercent: any = watch("percentDiscount");

    return (
      <>
        <Form>
          <Col md={9} lg={10}>
            <Card className="pt-content">
              <Modal
                size="lg"
                className="modalAuth modal-tariff-promo"
                show={promotionByHourModalShow}
                onHide={() => {
                  setPromotionByHourModalShow(false);
                }}
                aria-labelledby="contained-modal-title-vcenter"
              >
                <div className="modal-promo-content">
                  <Modal.Header className="w-100 p-0 position-static d-flex justify-content-start text-primary" closeButton>
                    <h3>Ativar Promoção por Horário</h3>
                  </Modal.Header>
                  <hr style={{ margin: "30px 0px" }}></hr>
                  <Modal.Body className="text-center d-flex flex-column p-0">
                    <Row className="">
                      <h5 className="text-start text-primary">Período de compra</h5>
                      <div className="pt-content-info"></div>
                      <Form.Group as={Col} md="3" className="mt-4 text-start">
                        <Form.Label>Data Início</Form.Label>
                        <Controller
                          control={control}
                          name="purchaseBeginDate"
                          rules={{
                            required: {
                              value: true,
                              message: "Por favor, informe a data",
                            },
                          }}
                          render={({ field }: any) => (
                            <InputGroup hasValidation className="" size="sm">
                              <SingleCalendarWithDefaultValue
                                date={purchaseBeginDate}
                                setValue={setPurchaseBeginDate}
                              />
                            </InputGroup>
                          )}
                        />
                        <ErrorMessage
                          errors={errors}
                          name="purchaseBeginDate"
                          render={({ message }) => (
                            <small style={{ color: "red" }}>{message}</small>
                          )}
                        />
                      </Form.Group>
                      <Form.Group as={Col} md="3" className="mt-4 text-start">
                        <Form.Label className="label-small">Data Fim</Form.Label>
                        <Controller
                          control={control}
                          name="purchaseEndDate"
                          rules={{
                            required: {
                              value: true,
                              message: "Por favor, informe a data",
                            },
                          }}
                          render={({ field }: any) => (
                            <InputGroup hasValidation className="" size="sm">
                              <SingleCalendarWithDefaultValue
                                {...field}
                                date={purchaseEndDate}
                                setValue={setPurchaseEndDate}
                              />
                            </InputGroup>
                          )}
                        />
                        <ErrorMessage
                          errors={errors}
                          name="purchaseEndDate"
                          render={({ message }) => (
                            <small style={{ color: "red" }}>{message}</small>
                          )}
                        />
                      </Form.Group>
                      <Form.Group
                        as={Col}
                        md="3"
                        className="mt-4 text-start"
                      >
                        <Form.Label className="label-small">
                          Horário inicial
                        </Form.Label>
                        <Controller
                          control={control}
                          name="purchaseHourStart"
                          render={({ field }: any) => (
                            <InputGroup hasValidation className="" size="sm">
                              <OnlyTime defaultTime={`2022-08-19T${infoEdit?.purchaseHourStart || "00:00"}:00`} setTime={setPurchaseTimeStart} />
                            </InputGroup>
                          )}
                        />
                      </Form.Group>
                      <Form.Group
                        as={Col}
                        md="3"
                        className="mt-4 text-start"
                      >
                        <Form.Label className="label-small">
                          Horário final
                        </Form.Label>
                        <Controller
                          control={control}
                          name="purchaseHourEnd"
                          render={({ field }: any) => (
                            <InputGroup hasValidation className="" size="sm">
                              <OnlyTime defaultTime={`2022-08-19T${infoEdit?.purchaseHourEnd || "00:00"}:00`} setTime={setPurchaseTimeEnd} />
                            </InputGroup>
                          )}
                        />
                      </Form.Group>
                    </Row>
                    <hr style={{ margin: "30px 0px" }}></hr>
                    <Row className="mb-4">
                      <h5 className="text-start text-primary">Período de utilização</h5>
                      <Col md={12}>
                        <Row className="mb-4 mt-4">
                          <Col md="6" className="d-flex justify-content-start">
                            <Form.Label className="label-small">
                              Dias da promoção
                            </Form.Label>
                          </Col>
                          <Col md="6">
                            <div className="d-flex justify-content-end">
                              <Controller
                                control={control}
                                name="allDays"
                                render={({ field }: any) => (
                                  <Form.Check
                                    {...field}
                                    id={`allDays`}
                                    type="checkbox"
                                    variant="standard"
                                    margin="normal"
                                    onClick={() => selectAll()}
                                    required
                                    className="mx-2"
                                  />
                                )}
                              />
                              <Form.Label
                                className="label-small"
                                htmlFor="allDays"
                              >
                                Selecionar tudo
                              </Form.Label>
                            </div>
                          </Col>
                          <div className="d-flex justify-content-between col-md-8">
                            {promoDays.map((elem: any, index: any) => {
                              return (
                                <>
                                  <div key={elem.day}>
                                    <div className="d-flex w-auto">
                                      <Controller
                                        control={control}
                                        name={elem.day}
                                        render={({
                                          field,
                                        }: any) => (
                                          <Form.Check
                                            {...field}
                                            id={elem.day}
                                            type="checkbox"
                                            variant="standard"
                                            margin="normal"
                                            onChange={() => changePromoDays(index)}
                                            required
                                            className="d-none mx-2"
                                          />
                                        )}
                                      />
                                      <div>
                                        <Form.Label
                                          className={elem.value === false ?
                                            "btn btn-outline-primary label-small"
                                            :
                                            "btn btn-primary label-small"}
                                          htmlFor={elem.day}
                                        >
                                          <span className="text-capitalize">
                                            {elem.day}
                                          </span>
                                        </Form.Label>
                                      </div>
                                    </div>
                                  </div>
                                </>
                              );
                            })}
                          </div>
                        </Row>
                      </Col>
                      <Form.Group as={Col} md="3" className="mt-4 text-start">
                        <Form.Label>Data Início</Form.Label>
                        <Controller
                          control={control}
                          name="beginDate"
                          rules={{
                            required: {
                              value: true,
                              message: "Por favor, informe a data",
                            },
                          }}
                          render={({ field }: any) => (
                            <InputGroup hasValidation className="" size="sm">
                              <SingleCalendarWithDefaultValue
                                date={beginDate}
                                setValue={setBeginDate}
                                disablePastDates={true}
                                disableFutureDates={true}
                                pastDateToDisable={getDateValues().beginDate + "T00:00:00"}
                                futureDateToDisable={getDateValues().endDate + "T00:00:00"}
                              />
                            </InputGroup>
                          )}
                        />
                        <ErrorMessage
                          errors={errors}
                          name="beginDate"
                          render={({ message }) => (
                            <small style={{ color: "red" }}>{message}</small>
                          )}
                        />
                      </Form.Group>
                      <Form.Group as={Col} md="3" className="mt-4 text-start">
                        <Form.Label className="label-small">Data Fim</Form.Label>
                        <Controller
                          control={control}
                          name="endDate"
                          rules={{
                            required: {
                              value: true,
                              message: "Por favor, informe a data",
                            },
                          }}
                          render={({ field }: any) => (
                            <InputGroup hasValidation className="" size="sm">
                              <SingleCalendarWithDefaultValue
                                {...field}
                                date={endDate}
                                setValue={setEndDate}
                                disablePastDates={true}
                                disableFutureDates={true}
                                pastDateToDisable={getDateValues().beginDate + "T00:00:00"}
                                futureDateToDisable={getDateValues().endDate + "T00:00:00"}
                              />
                            </InputGroup>
                          )}
                        />
                        <ErrorMessage
                          errors={errors}
                          name="endDate"
                          render={({ message }) => (
                            <small style={{ color: "red" }}>{message}</small>
                          )}
                        />
                      </Form.Group>
                      <Form.Group
                        as={Col}
                        md="3"
                        className="mt-4 text-start"
                      >
                        <Form.Label className="label-small">
                          Horário inicial
                        </Form.Label>
                        <Controller
                          control={control}
                          name="hourStart"
                          render={({ field }: any) => (
                            <InputGroup hasValidation className="" size="sm">
                              <OnlyTime defaultTime={`2022-08-19T${infoEdit?.hourStart || "00:00"}:00`} setTime={setTimeStart} />
                            </InputGroup>
                          )}
                        />
                      </Form.Group>
                      <Form.Group
                        as={Col}
                        md="3"
                        className="mt-4 text-start"
                      >
                        <Form.Label className="label-small">
                          Horário final
                        </Form.Label>
                        <Controller
                          control={control}
                          name="hourEnd"
                          render={({ field }: any) => (
                            <InputGroup hasValidation className="" size="sm">
                              <OnlyTime defaultTime={`2022-08-19T${infoEdit?.hourEnd || "00:00"}:00`} setTime={setTimeEnd} />
                            </InputGroup>
                          )}
                        />
                      </Form.Group>
                    </Row>
                    <Row>
                      <Form.Group as={Col} md="3" className="mt-4 text-start">
                        <Form.Label className="label-small">Tipo de desconto</Form.Label>
                        <Controller
                          control={control}
                          name="typeDiscount"
                          rules={{
                            required: {
                              value: false,
                              message: "Por favor, informe a modalidade",
                            },
                          }}
                          defaultValue="percent"
                          render={({ field }: any) => (
                            <div className="endpoint">
                              <Form.Select
                                {...field}
                                as="select"
                                variant="standard"
                                margin="normal"
                                required
                                onMouseOut={() => {
                                  if (watchDiscout === "percent" && watchPercent > 50) {
                                    setValue('percentDiscount', 50);
                                  }
                                }}
                              >
                                <option value="percent">Percentual</option>
                                <option value="value">Valor</option>
                              </Form.Select>
                            </div>
                          )}
                        />
                        <ErrorMessage
                          errors={errors}
                          name="typeDiscount"
                          render={({ message }) => (
                            <small style={{ color: "red" }}>{message}</small>
                          )}
                        />
                      </Form.Group>
                      <Col md={6}></Col>
                      <Form.Group
                        as={Col}
                        md="3"
                        className="mt-4 text-start"
                      >
                        <Form.Label className="label-small d-flex justify-content-between">
                          <span>Desconto</span>
                          <span style={{ fontSize: "12px", lineHeight: 2 }}>{watchDiscout === "percent" ? `(max. 50%)` : ''}</span>
                        </Form.Label>
                        <Controller
                          control={control}
                          name="percentDiscount"
                          rules={{
                            required: {
                              value: true,
                              message: "Por favor, informe o valor.",
                            },
                          }}
                          render={({ field }: any) => (
                            <Form.Control
                              {...field}
                              aria-invalid={errors?.costRate ? "true" : ""}
                              maxLength={watchDiscout === "percent" ? 2 : null}
                              variant="standard"
                              margin="normal"
                              placeholder="0"
                              autoComplete="off"
                              onKeyPress={e => !/[0-9]/.test(e.key) && e.preventDefault()}
                              onMouseOut={() => {
                                if (watchDiscout === "percent" && watchPercent > 50) {
                                  setValue('percentDiscount', 50);
                                }
                              }}
                              className="inputtaxx"
                            />
                          )}
                        />
                      </Form.Group>
                    </Row>
                  </Modal.Body>
                  <Modal.Footer className="p-0 mt-4">
                    <Button
                      variant="outline-primary"
                      className="mx-2"
                      onClick={() => {
                        setPromotionByHourModalShow(false);
                      }}
                    >
                      Cancelar
                    </Button>
                    <Button
                      variant="primary"
                      className="mx-2"
                      onClick={() => {
                        handleRegisterPromotion();
                      }}
                    >
                      Salvar Promoção
                    </Button>
                  </Modal.Footer>
                </div>
              </Modal>
            </Card>
          </Col>
        </Form>
        <ModalShow
          modalShow={modalShow}
          setModalShow={setModalShow}
          modalMessage={modalMessage}
          setModalMessage={setModalMessage}
          modalLog={1}
          setModalLog={() => { }}
        />
      </>
    );
  }
);

export default TariffPromoByHour;
