import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Dropdown } from "react-bootstrap";

import BootstrapTable from "react-bootstrap-table-next";
import Pagination from "../../../../components/Pagination/Pagination";

import { useTranslation } from "react-i18next";

import "react-bootstrap-table-next/dist/react-bootstrap-table2.css";
import "./ResourceTypeTable.scss";


export interface propTable {
  resourceType: any;
  exportResourceType: any;
  loading: any;
  totalRows: any;
  pageCount: any;
  setPageCount: any;
  rowsPerPage: any;
  setRowsPerPage: any;
  
}

const ResourceTypeTable: React.FC<propTable> = ({
  resourceType,
  exportResourceType,
  loading,
  totalRows,
  pageCount,
  setPageCount,
  rowsPerPage,
  setRowsPerPage,
  
}) => {
  const { t } = useTranslation();

  const columns = [
    { dataField: "resourceTypeName", text: "Descrição" },
    { dataField: "vehicle", text: "Veículo" },
    { dataField: "driver", text: "Motorista" },
    { dataField: "guide", text: "Guia" },
    { dataField: "ticket", text: "Ticket" },
    { dataField: "restaurant", text: "Restaurante"},
    { dataField: "people", text: "Pessoa"},
    { dataField: "chd", text: "Cobra CHD"},
    { dataField: "resourceTypeId", text: "Ações", formatter: addActionButton },
  ];

  // const [, setEditClick] = useState<any>(false);
  // const [, setDetailsModal] = useState<any>(false);
  // const [, setCurrentResourceType] = useState<any>();

  /* function seeDetailsEdit(cell: any) {
    setEditClick(true);
    setCurrentResourceType(cell);
    setDetailsModal(true);
  } */

  /* loading table */

  function loadingFunc() {
    return (
      <>
        <div className="animated-background row-loading"></div>
      </>
    );
  }

  const loadingColumns = [
    {
      dataField: "resourceType",
      text: "Carregando tipo de recurso",
      formatter: loadingFunc,
    },
  ];

  const loadingResourceType = [
    { resourceType: "" },
    { resourceType: "" },
    { resourceType: "" },
    { resourceType: "" },
    { resourceType: "" },
  ];
  /* [END] loading table */

  /* In case of empty table*/
  const notFoundColumns = [{ dataField: "resourceType", text: "Tipo de Resource" }];

  const notFoundResourceType = [{ resources: "Nenhum tipo de resource encontrado" }];
  /* [END] In case of empty table*/
 

  //botao de ação
  function addActionButton(cell: any, row: any) {
    return (
      <>
        <div className="d-flex justify-content-center">
          <Dropdown drop="start">
            <Dropdown.Toggle variant="light ">
              <FontAwesomeIcon size="lg" icon={["fal", "ellipsis-h"]} />
            </Dropdown.Toggle>

            <Dropdown.Menu>   
              <Dropdown.Item
                eventKey="1"
                href={"/edit-resource-type?id=" + cell
                }
              >                
                <FontAwesomeIcon icon={["fal", "edit"]} className="mr-2" />{" "}
                {t("reservations.table.dropdown.edit")}{" "}                
              </Dropdown.Item>
              {/* <Dropdown.Item
                eventKey="4"
                disabled={true}
                className="text-danger remove"
              >
                <FontAwesomeIcon icon={["fal", "trash-alt"]} className="mr-2" />{" "}
                {t("suppliersTable.remove")}
              </Dropdown.Item> */}
            </Dropdown.Menu>
          </Dropdown>
        </div>
      </>
    );
  }

  if (resourceType !== null) {
    return (
      <>
        <div className="table-default resourceType-table ">
          <div className="table-container">
          <div className="table-title">
              {/* <h3 className="">{t("Resources")}</h3> */}

              <div>
                    <div style={{ cursor: 'pointer' }} className="btn btn-bills" onClick={exportResourceType}>
                      <FontAwesomeIcon
                        icon={["fal", "share"]}
                        size="1x"
                        style={{ marginRight: "5px" }}
                      />
                      Exportar
                    </div>
                  </div>
            </div>
              <BootstrapTable
                bootstrap4
                keyField="id"
                data={resourceType}
                hover={true}
                columns={columns}
                striped={true}                
              />

              <Pagination
                totalRows={totalRows}
                pageCount={pageCount}
                setPageCount={setPageCount}
                rowsPerPage={rowsPerPage}
                setRowsPerPage={setRowsPerPage}
                selectNumberRows="yes"
              />
            
          </div>
        </div>
      </>
    );
  } else if (resourceType === null && loading === true) {
    return (
      <>
        <div className="table-default resourceType-table loading">
          <div className="table-container">
            
              <BootstrapTable
                bootstrap4
                keyField="id"
                data={loadingResourceType}
                hover={true}
                columns={loadingColumns}
                striped={true}
              />

              <Pagination
                totalRows={totalRows}
                pageCount={pageCount}
                setPageCount={setPageCount}
                rowsPerPage={rowsPerPage}
                setRowsPerPage={setRowsPerPage}
                selectNumberRows="yes"
              />
            
          </div>
        </div>
      </>
    );
  } else {
    return (
      <>
        <div className="table-default resourceType-table loading not-found">
          <div className="table-container">
           
              <BootstrapTable
                bootstrap4
                keyField="id"
                data={notFoundResourceType}
                hover={true}
                columns={notFoundColumns}
                striped={true}
              />
           
          </div>
        </div>
      </>
    );
  }
};

export default ResourceTypeTable;
