import React, { useEffect, useState } from "react";
import api from "../../services/api";
import configData from "../../config/config.json";

import DatePicker from "react-multi-date-picker";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import "react-multi-date-picker/styles/layouts/mobile.css"

import "./Calendar.scss";
import i18next from "i18next";
import { useTranslation } from "react-i18next";

const weekDays = ["Dom", "Seg", "Ter", "Qua", "Qui", "Sex", "Sab"];
const months = [
    "Janeiro",
    "Fevereiro",
    "Março",
    "Abril",
    "Maio",
    "Junho",
    "Julho",
    "Agosto",
    "Setembro",
    "Outubro",
    "Novembro",
    "Dezembro",
];

const numberMonths = window.innerWidth < 768 ? 1 : 2;

export interface productInfo {
    modalityID: any;
    product: any;
    productCode: any;
    changePriceDateTour: any;
    index: any;
    dateTour: any;
    startTimeLocal?: any;
    setLoading?: any;
    setIsIntegration?: any;
    setPreloader?: any;
    setRanges: any;
    setPeopleDisponible?: any;
}

let auxRanges: any = [];

const CustomMultipleInput = ({ openCalendar, value, modalityID, valueCalendar, startTimeLocal }: any) => {
    const { t } = useTranslation();

    const [arrow, setArrow] = useState<any>(false);

    if (startTimeLocal) {
        if (valueCalendar.data === undefined) {
            var teste: any = document.getElementById(`date-${modalityID}`);
            if (teste !== null) {
                value = startTimeLocal.props.children;
            }
        }
    }


    useEffect(() => {
        let arrowBody = document.querySelector('body');
        if (arrowBody?.className === "modal-open") {
            setArrow(true);
        } else {
            setArrow(false);
        }
    }, []);

    const onChangeInput = () => {
        return true;
    }

    return (
        <div className="d-flex">
            <FontAwesomeIcon
                icon={["fal", "calendar-alt"]}
                size="1x"
                style={{ color: "#000", position: "absolute", transform: "translate(10px, 10px)" }}
            />
            <input
                className={`form-control rounded-0 bg-white py-2 rounded calendar-input date-${modalityID}`}
                onFocus={openCalendar}
                value={value}
                data-object={JSON.stringify(valueCalendar)}
                onChange={onChangeInput}
                readOnly
                id={`date-${modalityID}`}
                placeholder={t("sales.tourList.accordion.when")}
                autoComplete='off'
            />
            {
                arrow === true
                    ?
                    <FontAwesomeIcon icon={["fal", "chevron-down"]} size="1x" style={{ marginRight: "10px", color: "#707070" }} />
                    :
                    ""
            }
        </div>
    );
};

const DoubleCalendarTicket5DaysBefore: React.FC<productInfo> = ({
    modalityID,
    product,
    productCode,
    changePriceDateTour,
    dateTour,
    index,
    startTimeLocal = undefined,
    setLoading,
    setIsIntegration,
    setPreloader,
    setRanges,
    setPeopleDisponible
}: productInfo) => {
    const lng = i18next.language === "pt" ? "BR" : i18next.language === "en" ? "EN" : i18next.language === "es" ? "ES" : "BR";

    const [dayProduct, setDayProduct] = React.useState<any>({});
    const [hoursProduct, setHoursProduct] = React.useState<any>();
    const [valueCalendar, setValueCalendar] = useState<any>({});
    const [valueIntegrate, setValueIntegrate] = useState<any>(false);

    const [width, setWidth] = useState<any>(window.innerWidth);

    const [auxTarif, setAuxTarif] = useState<any>(null);

    useEffect(() => {
        window.addEventListener("resize", function () {
            var newWidth = window.innerWidth;
            setWidth(newWidth);
        });
    }, []);

    //Função que controla se vamos fazer a integração externa ou não
    const optionGo = (objCalendar: any) => {
        if (valueIntegrate === true) {
            integrateRequest(objCalendar);
        } else {
            handleDateTour(objCalendar, hoursProduct, false)
        }

    }

    //Função que faz a integração
    const integrateRequest = async (objCalendar: any) => {
        setLoading(true);
        setPreloader(true);

        try {
            const { data } = await api.post(
                `${process.env.REACT_APP_SERVER_URL_API}/Products/GetTicketActivitiesIntegrationAsync`,
                {
                    dateStart: objCalendar.data,
                    productCode: `${productCode}`,
                    ProdModCode: `${product.prodModCode}`,
                }
            );
            if (data.status !== 400) {
                setIsIntegration(true);
                handleDateTour(objCalendar, data.data, true)
                setLoading(true);
                setPreloader(false);
            }
        } catch (error) { }
    }

    //Função que roda no clique
    function handleDateTour(objCalendar: any, hours: any, integrate: boolean) {
        let auxTar: any = [];

        for (let i = 0; i < auxTarif.length; i++) {
            if(auxTarif[i].selectedDate.split("T")[0] === objCalendar.data) {
                auxTar.push(auxTarif[i])
            }          
        }

        var aux: any = dateTour;
        aux[index].data = objCalendar.data;
        aux[index].idSellingType = product.sellingType;
        aux[index].daysUniqueTarCode = objCalendar.daysUniqueTarCode;
        aux[index].prodModUniqueCode = objCalendar.prodModUniqueCode;
        aux[index].tarUniqueCode = objCalendar.tarUniqueCode;
        aux[index].isFreeSale = objCalendar.isFreeSale
        aux[index].auxTarif = auxTar;
        aux[index].adultText = objCalendar.adultText;
        aux[index].childText = objCalendar.childText;
        aux[index].infantText = objCalendar.infantText;
        aux[index].eldersText = objCalendar.eldersText;
        aux[index].studentText = objCalendar.studentText;
        aux[index].globalText = objCalendar.globalText;

        if (integrate === true || objCalendar.isFreeSale === 1) {
            aux[index].adult = objCalendar.priceAdultFinal;
            aux[index].child = objCalendar.priceChildFinal;
            aux[index].infant = objCalendar.priceInfantFinal;
            aux[index].student = objCalendar.priceStudentFinal;
            aux[index].elders = objCalendar.priceEldersFinal;
            aux[index].global = objCalendar.priceGlobalFinal;
            changePriceDateTour(aux, hours);
            
            if(hours.length === 0) {
                setPeopleDisponible(true)
            }
        } else {
            changePriceDateTour(aux, hoursProduct);
        }
    }

    useEffect(() => {
        let aux: any = [];

        async function getDays(info:any) {
            //var today: any = new Date('Thu Jan 04 2022 14:32:14 GMT-0300 (Horário Padrão de Brasília)');
            var today: any = new Date();
            var todayDay: any;
            var todayMonth: any;
            var todayYear: any;
            var endMonth: any;
            var endYear: any;

                if (today.getDate() /* - 5 */ >= 1) { ///Mesmo mês
                    todayDay = today.getDate() /* - 5 */;
                    todayMonth = (today.getMonth() + 1);
                    todayYear = today.getFullYear();
                    endMonth = (today.getMonth() + 3);
                    endYear = today.getFullYear();

                } else { ///Volta 1 mês
                    if (today.getMonth() + 1 !== 1) { ///Mesmo ano

                        if ([4, 6, 9, 11].includes(today.getMonth() + 1)) {
                            todayDay = 30 + (today.getDate() /* - 5 */);
                        } else if ([1, 3, 5, 7, 8, 10, 12].includes(today.getMonth() + 1)) {
                            todayDay = 31 + (today.getDate() /* - 5 */);
                        } else if (today.getMonth() + 1 === 2) {
                            todayDay = 28 + (today.getDate() /* - 5 */);
                        }

                        todayMonth = (today.getMonth());
                        todayYear = today.getFullYear();
                        endMonth = todayMonth + 3;
                        endYear = today.getFullYear();
                    } else { ///Volta 1 ano
                        todayDay = 31 + (today.getDate() /* - 5 */);
                        todayMonth = 12;
                        todayYear = today.getFullYear() - 1;
                        endMonth = 3;
                        endYear = today.getFullYear();
                    }
                }


                if (endMonth > 11) {
                    endMonth = endMonth - 11;
                    endYear = parseInt(endYear) + 1;
                }

                todayDay = todayDay >= 28 ? 28 : todayDay;
            
                
            const token = localStorage.getItem('GroupId') || '{}';
            const config = {
                headers: { 'Authorization': `Bearer ${token}`, 'Content-Type': 'application/json' },
            };

            try {
                const { data } = await api.post(
                    `${process.env.REACT_APP_SERVER_URL_API}/Products/GetCalendarTicketAuthSigAsync `,
                    {
                        dateStart: todayYear + '-' + todayMonth + '-' + todayDay,
                        dateEnd: endYear + '-' + endMonth + '-' + todayDay,
                        productCode: `${productCode}`,
                        tarCode: `${info.tarCode}`,
                        ProdModCode: `${product.prodModCode}`,
                        lang: lng,
                        idCanal: localStorage.getItem('idCanal') || 1
                    }, config
                );
                if (data.status !== 400) {
                    //setRanges(data.data.ranges);
                    auxRanges.push({...data.data.ranges, tarCode: product.tarif.tarCode});
                    
                    if (data.data.isIntegrationSystem === true) {
                        setValueIntegrate(true);
                    } else {
                        setHoursProduct(data.data.activities);
                    }

                    aux.push(...data.data.dates);

                    data.data.dates.forEach((dayProduct: any, index: any) => {
                        let year = dayProduct.selectedDate.split("-")[0];
                        let month = dayProduct.selectedDate.split("-")[1];
                        let day = dayProduct.selectedDate.split("-")[2];

                        let controlObject: { [x: number]: { price: any; data: any, idTarif: any, priceAdultFinal: any, priceChildFinal: any, priceEldersFinal: any, priceStudentFinal: any, priceGlobalFinal: any, priceInfantFinal: any, idSellingType: any, daysUniqueTarCode: any, prodModUniqueCode: any, tarUniqueCode: any, isFreeSale: any, adultText: string, childText: string, infantText: string, eldersText: string, studentText: string, globalText: string  } };
                        let sControl: any = year + "-" + month + "-" + day.split("T")[0];
                        controlObject = {
                            [sControl]: {
                                price:
                                    dayProduct.priceAdultFinal === 0.0
                                        ? dayProduct.priceGlobalFinal
                                        : dayProduct.priceAdultFinal,
                                idTarif: dayProduct.idTarifario,
                                priceAdultFinal: dayProduct.priceAdultFinal,
                                priceChildFinal: dayProduct.priceChildFinal,
                                priceInfantFinal: dayProduct.priceInfantFinal,
                                priceStudentFinal: dayProduct.priceStudentFinal,
                                priceEldersFinal: dayProduct.priceEldersFinal,
                                priceGlobalFinal: dayProduct.priceGlobalFinal,
                                // Essa loucura é por causa de butcha e havier
                                // Muda o selling type do ticket por está invertido
                                idSellingType: dayProduct.idSellingType,
                                daysUniqueTarCode: dayProduct.daysUniqueTarCode,
                                prodModUniqueCode: dayProduct.prodModUniqueCode,
                                tarUniqueCode: dayProduct.tarUniqueCode,
                                data: year + "-" + month + "-" + day.split("T")[0],
                                isFreeSale: dayProduct.isFreeSale,
                                adultText: data.data.dates[0][`tariffAgeAdultText${lng !== "BR" ? lng : ''}`],
                                childText: data.data.dates[0][`tariffAgeChildText${lng !== "BR" ? lng : ''}`],
                                infantText: data.data.dates[0][`tariffAgeInfantText${lng !== "BR" ? lng : ''}`],
                                eldersText: data.data.dates[0][`tariffAgeElderText${lng !== "BR" ? lng : ''}`],
                                studentText: data.data.dates[0][`tariffAgeStudentText${lng !== "BR" ? lng : ''}`],
                                globalText: data.data.dates[0][`tariffAgeGlobalText${lng !== "BR" ? lng : ''}`],
                            },
                        };

                        setDayProduct((curObjDeal: any) => ({
                            ...curObjDeal,
                            ...controlObject,
                        }));

                        setAuxTarif(aux);
                        setRanges(auxRanges);
                    });
                    
                }
            } catch (error) { }
        }

        for (let i = 0; i < product.allTarif.length; i++) {
            getDays(product.allTarif[i]); 
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [product.prodModCode, product.tarif.tarCode, productCode /*  */]);



    if (dayProduct !== null) {
        return (
            <DatePicker
                weekDays={weekDays}
                numberOfMonths={numberMonths}
                disableMonthPicker
                disableYearPicker
                months={months}
                format={configData.DATE.PT}
                // plugins={[<MyPlugin  hours={hoursProduct} />]}
                render={<CustomMultipleInput modalityID={modalityID} valueCalendar={valueCalendar} startTimeLocal={startTimeLocal} />}
                className={width > 767 ? "multi-locale-days" : "rmdp-mobile"}
                mapDays={({ date }): any => {
                    let controlNumber =
                        JSON.stringify(date.day).length === 1 ? "0" + date.day : date.day;
                    let controlMonth =
                        JSON.stringify(date.month.number).length === 1
                            ? "0" + date.month.number
                            : date.month.number;
                    let controlYear = date.year;

                    const objCalendar =
                        dayProduct[controlYear + "-" + controlMonth + "-" + controlNumber];
                        console.log(dayProduct)
                    if (objCalendar !== undefined && objCalendar.price !== "null") {
                        if (
                            controlYear + "-" + controlMonth + "-" + controlNumber ===
                            objCalendar.data
                        ) {
                            return {
                                children: (
                                    <div
                                        style={{
                                            display: "flex",
                                            flexDirection: "column",
                                            fontSize: "14px",
                                        }}
                                        onClick={() => { setValueCalendar(objCalendar); optionGo(objCalendar) }}
                                    >
                                        <div style={{ textAlign: "center" }}>
                                            {date.format("D")}
                                        </div>
                                        <div
                                            style={{
                                                textAlign: "center",
                                                fontSize: "9px",
                                                minHeight: "14px",
                                            }}
                                        >
                                            {" "}
                                            {objCalendar !== undefined
                                                ? objCalendar.price !== "null"
                                                    ? `R$ ${objCalendar.price
                                                        .toFixed(2)
                                                        .replace(".", ",")}`
                                                    : ""
                                                : ""}
                                        </div>
                                    </div>
                                ),
                            };
                        } else {
                            return {
                                disabled: true,
                            };
                        }
                    } else {
                        return {
                            disabled: true,
                        };
                    }
                }}
            ></DatePicker>
        );
    } else {
        return <></>;
    }
};

export default DoubleCalendarTicket5DaysBefore;
