import React, { useState } from "react";
import { Container, Row, Col } from "react-bootstrap";

import "../../../../../assets/sass/modal.scss";
import "./FormBillPayEdit.scss";

//import Modal from "react-bootstrap/Modal";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import { Controller, useForm } from "react-hook-form";
import Modal from "react-bootstrap/Modal";

import BootstrapTable from "react-bootstrap-table-next";

import GetAccountingAccountsFilter from "../../../../../components/C2Points/GetAccountingAccountsFilter";
import GetSuppliersFilter from "../../../../../components/C2Points/GetSuppliersInsert";

//import IconSucess from "../../../assets/icons/modal-sucess.svg";

import api from '../../../../../services/api';
import FormBillPayableLog from "../../../../../components/Forms/FormBillPayableLog/FormBillPayableLog";
import GetAgents from "../../../../../components/C2Points/GetAgentsById";

export interface propModal {
  action: any,
  info: any, 
  sessionId: any,
  sessionDate: any,
  sessionType: any,
  show?: any;
  setResponseText?: any,
  setModalContent?: any,
  setLog?: any,
  sessionOperationType: any
};

const FormBillPayEdit: React.FC<propModal> = ({
  action, info, sessionId, sessionDate, sessionType,
  show, setResponseText, setModalContent, setLog, sessionOperationType
}: propModal, props: any) => {
  //const [tipo, setTipo] = useState<string>(tipo);

  const [logModalShow, setLogModalShow] = useState<any>(false);
  const [supplierType, /* setSupplierType */] = useState<any>(info.supplierType ? `${info.supplierType}` : "1");

  const [fornecedor, setFornecedor] = useState<any>(info.fornecedor ? info.fornecedorId : null);

  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm({
    defaultValues: {
      contasaPagarId: info.contasaPagarId ? info.contasaPagarId : 0,
      manifestId: info.manifestId ? info.manifestId : 0,
      tipo: info.tipo ? info.tipo : 3,
      sessionId: sessionId,
      sessionType: sessionOperationType !== 2 ? sessionType : 3,
      contaContabil: info.contaContabilId ? info.contaContabilId : '',
      descricao: info.descricao ? info.descricao : '',
      data: sessionDate.substring(0,10),
      vencimento: info.vencimento ? info.vencimento.substring(0,10) : '',
      valor: info.valor ? info.valor.toFixed(2).replace('.',',') : '',
      fornecedor: info.fornecedor ? info.fornecedorId : null,
      supplierType: info.supplierType ? `${info.supplierType}` : "1",
      observacao: info.observacao ? info.observacao : '',
      totalPax: info.totalPax ? info.totalPax : '',
      totalAdult: info.totalAdult ? info.totalAdult : '',
      totalChild: info.totalChild ? info.totalChild : '',
      totalInfant: info.totalInfant ? info.totalInfant : ''
    },
  });

  const handleSaveClick = (dataObj: any) => {
    var route = '';
//console.log(dataObj.fornecedor, "fornecedor");
    //if(dataObj.fornecedor && dataObj.fornecedor.supplierId) dataObj.fornecedor = dataObj.fornecedor.supplierId;
    dataObj.fornecedor = fornecedor;
    if(dataObj.contaContabil && dataObj.contaContabil.id) dataObj.contaContabil = dataObj.contaContabil.id;

    if(dataObj.valor) dataObj.valor = parseFloat(dataObj.valor.replace(",", "."));
    
    dataObj.totalPax = (dataObj.totalPax) ? parseInt(dataObj.totalPax) : null;
    dataObj.totalAdult = (dataObj.totalAdult) ? parseInt(dataObj.totalAdult) : null;
    dataObj.totalChild = (dataObj.totalChild) ? parseInt(dataObj.totalChild) : null;
    dataObj.totalInfant = (dataObj.totalInfant) ? parseInt(dataObj.totalInfant) : null;

    dataObj.recorrente = 2;
    dataObj.notaDebito = 2;
    dataObj.arquivo = null;

    dataObj.supplierType = supplierType;

    if(info.manifestId <= 0) {
        //console.log('insert');
        route = 'InsertBill';
    } else {
        //console.log('edit');
        route = 'UpdateBill';
    }

    let userData = JSON.parse(localStorage.getItem('c2tourAuth') || '{}');
    const user = userData.user;

    dataObj.uid = user.uId;
    dataObj.usuario = `${user.nome} ${user.lastName}`;

    const token = localStorage.getItem('GroupId') || '';

    const config = {
      headers: { 'Authorization': `Bearer ${token}` },
    };

    const salvar = async () => {
      setModalContent("loading");
      show(true);
        try {

            const { data } = await api.post(`BillsPayable/${route}`, dataObj, config);
            /* console.log(data.data); */
            if (data.status !== 400) {
                var response = data.data;

                //alert(response.texto);
                setResponseText(response.texto);
                setLog(response.log);

                if(response.log !== 1) {
                  setModalContent("success");
                  action(dataObj);
                } else {
                  setModalContent("error");
                }
            }   
        }catch(error: any) {
            if (error?.response?.status === 401) {
                window.location.href = window.location.origin + '/';              
            }
            if (error?.response?.status === 400) {
                //window.location.href = window.location.origin + '/session-closure';              
            }
            //;
        }
    }
    salvar();
  };

  /* const handleChangeSupplierType = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = e.target.value;
    setSupplierType(newValue);

    setFornecedor(null);
    control.unregister('fornecedor');
  } */

  function convertValue(cell: any) {
    return (
      <>
        <span className={Math.sign(cell) === -1 ? "negative" : ""}>{cell.toFixed(2).replace(".", ",")}</span>
      </>
    )
  }

  const columnsQuantities = [
    {
      dataField: "description",
      text: "Descrição",
    },
    {
      dataField: "quantity",
      text: "Qtde.",
    },
    {
      dataField: "value",
      text: "Valor",
      formatter: convertValue,
    },
  ];

  return (
    <>
      <Modal.Body>
        <Container className="bg-modal-contact p-0" fluid>
          <Row>
            <h5 className="contact-title mb-5">Altera Conta Pagar</h5>
            <Form
              className="form-add-contact"
              noValidate
            >
              <div className="">
                <Row className="">
                  <Form.Group as={Col} md="8" className="border-right">
                    <Row className="mb-3">
                      <Form.Group as={Col} md="5">
                        <Form.Label>Categoria</Form.Label>
                        {info.tipo === 3 ?
                        <Controller
                          control={control}
                          name="contaContabil"
                          rules={{ required: { value: true, message: 'Por favor, informe o tipo.' } }}
                          render={({ field }: any) => (
                            <GetAccountingAccountsFilter propsField={field} propsErrors={errors} propsLabel='Categoria' />
                          )}
                        />
                        : <Form.Group as={Col} md="12"><span className="p-2">{info.contaContabil}</span></Form.Group>}
                      </Form.Group>
                      <Form.Group as={Col} md="7">
                        <Form.Label>Descrição</Form.Label>
                        <Controller
                          control={control}
                          name="descricao"
                          rules={{ required: { value: true, message: 'Por favor, preencher esse campo' } }}
                          render={({ field }: any) => (
                            <Form.Control type="text"
                              {...field}
                              aria-invalid={errors?.descricao ? "true" : ""}
                              autoComplete='off'
                              variant="standard"
                              
                              margin="normal"
                              disabled={info.tipo !== 3} />
                          )}
                        />
                      </Form.Group>
                    </Row>
                    <Row className="mb-3">
                      {sessionId === '' ?
                      <Form.Group as={Col} md="3">
                        <Form.Label>Data Operação</Form.Label>
                        <Controller
                          control={control}
                          name="data"
                          rules={{ required: { value: true, message: 'Por favor, preencher esse campo' } }}
                          render={({ field }: any) => (
                            <Form.Control type="date"
                              {...field}
                              aria-invalid={errors?.data ? "true" : ""}
                              autoComplete='off'
                              variant="standard"
                              
                              margin="normal"
                              required />
                          )}
                        />
                      </Form.Group>
                      : <></>}
                      <Form.Group as={Col} md="3">
                        <Form.Label>Vencimento</Form.Label>
                        <Controller
                          control={control}
                          name="vencimento"
                          rules={{ required: { value: true, message: 'Por favor, preencher esse campo' } }}
                          render={({ field }: any) => (
                            <Form.Control type="date"
                              {...field}
                              aria-invalid={errors?.vencimento ? "true" : ""}
                              autoComplete='off'
                              variant="standard"
                              
                              margin="normal"
                              required />
                          )}
                        />
                      </Form.Group>
                      <Form.Group as={Col} md="3">
                        <Form.Label>Valor Total</Form.Label>
                        <Controller
                          control={control}
                          name="valor"
                          rules={{ required: { value: true, message: 'Por favor, preencher esse campo' } }}
                          render={({ field }: any) => (
                            <Form.Control type="text"
                              {...field}
                              aria-invalid={errors?.valor ? "true" : ""}
                              autoComplete='off'
                              variant="standard"
                              
                              margin="normal"
                              required />
                          )}
                        />
                      </Form.Group>
                      {sessionId !== '' ?
                      <Form.Group as={Col} md="2">
                        <Form.Label>QTD</Form.Label>
                        <Controller
                          control={control}
                          name="totalPax"
                          render={({ field }: any) => (
                            <Form.Control type="text"
                              {...field}
                              aria-invalid={errors?.totalPax ? "true" : ""}
                              autoComplete='off'
                              variant="standard"
                              
                              margin="normal"
                              disabled={info.tipo !== 3} />
                          )}
                        />
                      </Form.Group>
                      : <></>}
                    </Row>
                    {sessionId !== '' ?
                    <Row className="mb-3">
                      
                        <div className="quantities-cap-container">
                          <div>Quantidades</div>
                          <div className="">
                            <BootstrapTable
                              bootstrap4
                              keyField="id"
                              data={info.quantities ? info.quantities : [{description: 'Adulto', quantity: 10, value: 100}, {description: 'Criança', quantity: 2, value: 50}]}
                              columns={columnsQuantities}
                              //rowClasses={rowClasses}
                              striped={true}
                            />
                          </div>
                        </div>

                      {/* <Form.Group as={Col} md="2">
                        <Form.Label>QTD</Form.Label>
                        <Controller
                          control={control}
                          name="totalPax"
                          render={({ field }: any) => (
                            <Form.Control type="text"
                              {...field}
                              aria-invalid={errors?.totalPax ? "true" : ""}
                              autoComplete='off'
                              variant="standard"
                              
                              margin="normal"
                              disabled={info.tipo !== 3} />
                          )}
                        />
                      </Form.Group>
                      <Form.Group as={Col} md="2">
                        <Form.Label>ADT</Form.Label>
                        <Controller
                          control={control}
                          name="totalAdult"
                          render={({ field }: any) => (
                            <Form.Control type="text"
                              {...field}
                              aria-invalid={errors?.totalAdult ? "true" : ""}
                              autoComplete='off'
                              variant="standard"
                              
                              margin="normal"
                              disabled={info.tipo !== 3} />
                          )}
                        />
                      </Form.Group>
                      <Form.Group as={Col} md="2">
                        <Form.Label>CHD</Form.Label>
                        <Controller
                          control={control}
                          name="totalChild"
                          render={({ field }: any) => (
                            <Form.Control type="text"
                              {...field}
                              aria-invalid={errors?.totalChild ? "true" : ""}
                              autoComplete='off'
                              variant="standard"
                              
                              margin="normal"
                              disabled={info.tipo !== 3} />
                          )}
                        />
                      </Form.Group>
                      <Form.Group as={Col} md="2">
                        <Form.Label>INF</Form.Label>
                        <Controller
                          control={control}
                          name="totalInfant"
                          render={({ field }: any) => (
                            <Form.Control type="text"
                              {...field}
                              aria-invalid={errors?.totalInfant ? "true" : ""}
                              autoComplete='off'
                              variant="standard"
                              
                              margin="normal"
                              disabled={info.tipo !== 3} />
                          )}
                        />
                      </Form.Group> */}
                    </Row>
                    : <></>}
                  </Form.Group>
                  <Form.Group as={Col} md="4">
                    {/* <Row className="mb-3">
                      <Form.Group as={Col} md="12">
                        <Form.Label>Fornecedor/Agente</Form.Label>
                          <div className="endpoint">
                            <InputGroup hasValidation>
                              <Form.Select
                                autoComplete='off'
                                aria-describedby="inputGroupPrepend"
                                onChange={(e: any) => handleChangeSupplierType(e)}
                                value={supplierType}
                              >
                                <option value="1">Fornecedor</option>
                                <option value="2">Agente</option>
                              </Form.Select>
                            </InputGroup>
                          </div>
                      </Form.Group>
                    </Row> */}
                    <Row className="mb-3">
                      <Form.Group as={Col} md="12">
                        <Form.Label>{supplierType === "1" ? 'Fornecedor' : 'Agente'}</Form.Label>
                        {info.tipo === 3 ?
                          supplierType === "1" ? 
                          <Controller
                            control={control}
                            name="fornecedor"
                            rules={{ required: { value: true, message: 'Por favor, informe o fornecedor.' } }}
                            render={({ field }: any) => (
                              <GetSuppliersFilter propsField={field} propsErrors={errors} propsLabel='Fornecedor' setSupplier={setFornecedor} /> 
                            )}
                          />
                          :
                          <Controller
                            control={control}
                            name="fornecedor"
                            rules={{ required: { value: true, message: 'Por favor, informe o agente.' } }}
                            render={({ field }: any) => (
                              <GetAgents propsField={field} propsErrors={errors} propsLabel='Agente' setSupplier={setFornecedor} />
                            )}
                          />
                        : <Form.Group as={Col} md="12"><span className="p-2">{info.fornecedor}</span></Form.Group>}
                      </Form.Group>
                    </Row>
                    {/* <Row className="mb-3">
                      <Form.Group as={Col} md="12">
                        <Form.Label>Centro Custo:</Form.Label>
                        
                      </Form.Group>
                    </Row> */}
                    <Row className="mb-3">
                      <Form.Group as={Col} md="12">
                        <Form.Label>Observação</Form.Label>
                        <Controller
                          control={control}
                          name="observacao"
                          render={({ field }: any) => (
                            <Form.Control type="text"
                              {...field}
                              aria-invalid={errors?.observacao ? "true" : ""}
                              autoComplete='off'
                              variant="standard"
                              
                              margin="normal"
                              required />
                          )}
                        />
                      </Form.Group>
                    </Row>
                  </Form.Group>
                </Row>
              </div>
            </Form>
          </Row>
        </Container>
        <div className="buttons-modal d-flex justify-content-between">
            <div className="mt-4">
            {info.contasaPagarId ?
              <button className="btn btn-default btn-primary px-4 text-uppercase" onClick={() => setLogModalShow(true)}>
                <FontAwesomeIcon
                  icon={["fal", "history"]}
                  size="1x"
                  style={{ marginRight: "5px" }}
                /> Ver Log
              </button>
              :<></>}
            </div>
            <div className='mt-4 d-flex justify-content-end'>
              <div>
                <Button
                  type="button"
                  className="form-button"
                  onClick={handleSubmit(handleSaveClick)}
                >
                  <FontAwesomeIcon
                    icon={["fal", "save"]}
                    size="1x"
                    style={{ marginRight: "5px" }}
                  /> Salvar
                </Button>
              </div>
            </div>
        </div>
      </Modal.Body>

      {/* <Modal.Footer>
        
      </Modal.Footer> */}

      <Modal
            className="modalReceber"
            show={logModalShow}
            onHide={() => setLogModalShow(false)}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
          >
            <>
              <Modal.Header className="p-3" closeButton></Modal.Header>
              <FormBillPayableLog contasaPagarId={info.contasaPagarId ? info.contasaPagarId : 0} />
            </>
          </Modal>

    </>
  );
}

export default FormBillPayEdit;
