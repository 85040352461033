import React, { useEffect, useState } from 'react';
import Select from 'react-select';
import api from '../../../services/api';

import { useTranslation } from "react-i18next";

import Form from 'react-bootstrap/Form';

export interface propPlus {
  propsField: any,
  propsLabel: any,
  propsErrors: any,
  lang: any, dateTransfer: any, timeTransfer: any, numPeople: any, origemLng: any, origemLat: any, destinoLng: any, destinoLat: any, supplierId: any,
  defaultValue?:any,
  setTransfer?: any
};

const GetProductTransferFilter: React.FC<propPlus> = ({
  propsField, propsLabel, propsErrors, lang, dateTransfer, timeTransfer, numPeople, origemLng, origemLat, destinoLng, destinoLat, supplierId, defaultValue, setTransfer
}: propPlus) => {
  const { t } = useTranslation();

  const [info, setInfo] = useState<any>(null);
  const [isMenuOpen, setIsMenuOpen] = useState<boolean>(false);

  const onMenuOpen = () => {
    if (isMenuOpen === false) {
      setIsMenuOpen(true);
    } else {
      setIsMenuOpen(false);
    }
  }


  useEffect(() => {
    const token = localStorage.getItem('GroupId') || '{}';
    const config = {
      headers: { 'Authorization': `Bearer ${token}` },
    };
    async function listAccountingAccounts() {
      if(lang && dateTransfer && timeTransfer && numPeople && origemLng && origemLat && destinoLng && destinoLat && supplierId) {
        try {
          const { data } = await api.post(`/Products/GetProductTransferTariff`, {
            lang: lang, 
            dateTransfer: dateTransfer, 
            timeTransfer: timeTransfer, 
            numPeople: numPeople, 
            origemLng: origemLng, 
            origemLat: origemLat, 
            destinoLng: destinoLng, 
            destinoLat: destinoLat, 
            supplierId: supplierId
          } , config);
          if (data.status !== 400) {
            setInfo(data.data)
          }
        } catch (error: any) {
          if (error.response.status === 401) {
            window.location.href = window.location.origin + '/';
          }
        }
      }
    }
    listAccountingAccounts();
  }, [lang, dateTransfer, timeTransfer, numPeople, origemLng, origemLat, destinoLng, destinoLat, supplierId]);

  const handleChangeMethod = (data: any) => {

    if(setTransfer) {
      //const newValue = parseInt(e.target.value);
      //setFilterAccount(info.find((item: any) => item.id === newValue));
      setTransfer({code: `${data.productCode}`, description: `${data.transferVehicleType} ${data.category} ${data.transferServiceType}`, netAmount: data.netAmount});
    }
      

    propsField.onChange(data);
  }

  if (info !== null) {
    return (
      <>
        <div className={propsErrors[propsField.name] !== undefined ? "endpoint-error" : "endpoint"}>
          <Select
            aria-labelledby="aria-label"
            inputId="aria-example-input"
            name="aria-live-color"
            onMenuOpen={onMenuOpen}
            onMenuClose={onMenuOpen}
            {...propsField}
            aria-invalid={propsErrors[propsField.name] ? "true" : ""}
            label={propsLabel}
            as="select"
            variant="standard"
            margin="normal"
            placeholder={t("accordionFinancial.select.select")}
            options={Array.from(info)}
            getOptionValue={(option: any) => `${option.productCode}`}
            getOptionLabel={(option: any) => `${option.transferVehicleType} ${option.category} ${option.transferServiceType}`}
            value={info.find((a:any) => a.productCode === propsField.value)}
            onChange={handleChangeMethod}
          />
        </div>
      </>
    );
  } else {
    return (
      <>
        <Form.Select>
          <option value='' disabled></option>
        </Form.Select>
      </>
    )

  }
}

export default GetProductTransferFilter;