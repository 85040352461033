import React, { useEffect, useState } from 'react';
import Select from 'react-select';
import api from '../../services/api';

import { useTranslation } from "react-i18next";

import Form from 'react-bootstrap/Form';

export interface propPlus {
  propsField: any,
  propsLabel: any,
  propsErrors: any,
  setFilterVehicleType: any,
  isEdit?: any,
  infoEdit?: any
};

const GetResourceVehicleTypeFilter: React.FC<propPlus> = ({
  propsField, propsLabel, propsErrors, isEdit = false, infoEdit, setFilterVehicleType
}: propPlus) => {
  const { t } = useTranslation();

  const [info, setInfo] = useState<any>(null);
  const [defaultItems, setDefaultItems] = useState<any>([]);


  const [isMenuOpen, setIsMenuOpen] = useState<boolean>(false);

  const onMenuOpen = () => {
    if (isMenuOpen === false) {
      setIsMenuOpen(true);
    } else {
      setIsMenuOpen(false);
    }
  }

  useEffect(() => {
    const token = localStorage.getItem('GroupId') || '{}';
    const config = {
      headers: { 'Authorization': `Bearer ${token}` },
    };
    async function listResourceVehicleType() {
      try {
        const { data } = await api.get(`/DropDown/GetResourceVehicleType`, config);
        if (data.status !== 400) {
          setInfo([{id: '0', description: ''}, ...data.data])
        }
      } catch (error: any) {
        if (error?.response?.status === 401) {
          window.location.href = window.location.origin + '/';
        }
      }
    }
    listResourceVehicleType();
  }, []);

  useEffect(() => {
    if (isEdit === true) {
      let aux: any = [];

      for (let i: any = 0; i < infoEdit.length; i++) {
        aux.push(
          {
            "label": infoEdit[i].typeName,
            "value": infoEdit[i].typeId
          }
        );
      }
      setDefaultItems(aux)
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [infoEdit])

  if (info !== null) {
    const transformed = info.map(({ id: value, description: label }: any) => ({ value, label }));
    if(setFilterVehicleType){
      setFilterVehicleType(propsField.value?.value)
    }
    return (
      <>
        <div className={propsErrors.supplierVehicleTypeModel !== undefined ? "endpoint-error" : "endpoint"}>
          <Select
            onMenuOpen={onMenuOpen}
            onMenuClose={onMenuOpen}
            {...propsField}
            aria-invalid={propsErrors?.supplierVehicleTypeModel ? "true" : ""}
            label={propsLabel}
            as="select"
            className="basic-multi-select"
            classNamePrefix="select"
            margin="normal"
            placeholder={t('accordionFinancial.select.select')}
            options={transformed}
            getOptionLabel={(option: any) => `${option.label}`}
            defaultValue={defaultItems}
          />
        </div>
      </>
    );
  } else {
    return (
      <>
        <Form.Select>
          <option value='' disabled></option>
        </Form.Select>
      </>
    )
  }
}

export default GetResourceVehicleTypeFilter;