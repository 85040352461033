import React from 'react'
import { Container } from "react-bootstrap";

import { Link } from 'react-router-dom';

//import { useTranslation } from 'react-i18next';

import './NotFound.scss'

function NotFound() {
    return (
        <Container className="body-not-found" fluid>
            <Container className="not-found">
                <div className="text-center">
                    <img src={process.env.REACT_APP_SERVER_LOGO_WHITE} alt="Logo C2Tours" width={'280px'}></img>
                    <h3>Página não encontrada.</h3>
                    <Link to="/dashboard" className="link-home">Voltar</Link>
                </div>
            </Container>
        </Container>
    )
}

export default NotFound;