/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useEffect, useState } from 'react';
import api from '../../services/api';

import DesktopDefault from '../../templates/DesktopDefault';

import './BillsToReceiveERP.scss'

//COMPONENTS
import Breadcrumb from '../../components/Breadcrumb/Breadcrumb';
import FilterBillsReceiveERP from './components/FilterBills/FilterBillsReceiveERP';
import TableBillsReceiveERP from './components/TableBills/TableBillsReceiveERP';

function BillsToReceiveERP() {

    const today = new Date();
    const todayStr = `${today.getDate()}/${today.getMonth() + 1}/${today.getFullYear()}`;

    const defaultStartDate = new Date(today.getFullYear(), today.getMonth(), today.getDate() - 3);
    const defaultStartDateStr = `${defaultStartDate.getDate()}/${defaultStartDate.getMonth() + 1}/${defaultStartDate.getFullYear()}`;

    const [actualStatusReserve, setActualStatusReserve] = useState<any>(null);
    const [actualStatusPagamento, setActualStatusPagamento] = useState<any>(1);
    const [actualStatusFormaPagamento, setActualStatusFormaPagamento] = useState<any>(null);
    const [actualSupplier, setActualSupplier] = useState<any>("");
    const [actualAdquirente, setActualAdquirente] = useState<any>(null);
    const [actualToken, setActualToken] = useState<any>("");
    const [actualTypeDate, setActualTypeDate] = useState<any>(1);
    const [actualDate, setActualDate] = useState<any>([defaultStartDateStr,todayStr]);

    const [billsReceive, setBillsReceive] = useState<any>(null);
    const [parameters, setParameters] = useState<any>(null);
    const [totalBillsReceive, setTotalBillsReceive] = useState<any>(0);
    const [error, setError] = useState<any>();
    const [loading, setLoading] = useState<any>(false);

    /* Pagination */
    const [totalRows, setTotalRows] = useState<any>();
    const [pageCount, setPageCount] = useState<any>(1);
    const [rowsPerPage, setRowsPerPage] = useState<any>(10);
    /* END - Pagination */

    async function refreshData() {
        setLoading(true);

        let body: any = document.querySelector("body");
        if (body.className !== "modal-open") {
            setBillsReceive(null);
        }

        let beginDate: any = []
        let endDate: any = []

        if (actualDate.length === 1) {
            beginDate = actualDate[0].split('/')
        } else if (actualDate.length === 2) {
            beginDate = actualDate[0].split('/')
            endDate = actualDate[1].split('/')
        }

        let today: any = new Date();

        const token = localStorage.getItem('GroupId') || '{}';
        const config = {
            headers: { 'Authorization': `Bearer ${token}`, 'Content-Type': 'application/json' },
        };

        async function listBills() {
            try {
                const { data } = await api.post('BillsReceivable/GetReport',
                    {
                        "page": pageCount,
                        "rowsPerPage": rowsPerPage,
                        "item": '',
                        "erp": 1,
                        "idDecolarFenix": null, 
                        "tokenNsu": actualToken ? actualToken : '',
                        "agenteRef": '',
                        "statusReserva": actualStatusReserve ? parseInt(actualStatusReserve) : null,
                        "statusPagamento": actualStatusPagamento ? parseInt(actualStatusPagamento) : null,
                        "formaPagamento": actualStatusFormaPagamento ? parseInt(actualStatusFormaPagamento) : null,
                        "adquirente": actualAdquirente ? actualAdquirente : null,
                        "fornecedor": actualSupplier ? actualSupplier : null,
                        "agenteIndividual": -1,
                        "agente": null,
                        "cliente": '',
                        "tipoData": actualTypeDate ? parseInt(actualTypeDate) : 2,
                        "dataInicial": (actualDate.length > 0) ? `${beginDate[2]}-${beginDate[1]}-${beginDate[0]}` : `${today.getFullYear()}-${today.getMonth() + 1}-${today.getDate()}`,
                        "dataFinal": (actualDate.length === 1) ? `${beginDate[2]}-${beginDate[1]}-${parseInt(beginDate[0])}` : (actualDate !== null && actualDate.length === 2) ? `${endDate[2]}-${endDate[1]}-${endDate[0]}` : `${today.getFullYear()}-${today.getMonth() + 1}-${today.getDate()}`
                    }, config
                );
                setLoading(false);
                if (data.status !== 400) {
                    setParameters({
                        "page": pageCount,
                        "rowsPerPage": rowsPerPage,
                        "item": '',
                        "erp": 1,
                        "idDecolarFenix": null, 
                        "tokenNsu": actualToken ? actualToken : '',
                        "agenteRef": '',
                        "statusReserva": actualStatusReserve ? parseInt(actualStatusReserve) : null,
                        "statusPagamento": actualStatusPagamento ? parseInt(actualStatusPagamento) : null,
                        "formaPagamento": actualStatusFormaPagamento ? parseInt(actualStatusFormaPagamento) : null,
                        "adquirente": actualAdquirente ? actualAdquirente : null,
                        "fornecedor": actualSupplier ? actualSupplier : null,
                        "agenteIndividual": -1,
                        "agente": null,
                        "cliente": '',
                        "tipoData": actualTypeDate ? parseInt(actualTypeDate) : 2,
                        "dataInicial": (actualDate.length > 0) ? `${beginDate[2]}-${beginDate[1]}-${beginDate[0]}` : `${today.getFullYear()}-${today.getMonth() + 1}-${today.getDate()}`,
                        "dataFinal": (actualDate.length === 1) ? `${beginDate[2]}-${beginDate[1]}-${parseInt(beginDate[0])}` : (actualDate !== null && actualDate.length === 2) ? `${endDate[2]}-${endDate[1]}-${endDate[0]}` : `${today.getFullYear()}-${today.getMonth() + 1}-${today.getDate()}`
                    });
                    setBillsReceive(data.data.rows);
                    setTotalRows(data.data.rowsCount);
                    setTotalBillsReceive(data.data.valorTotal);
                }
            } catch (error: any) {
                if (error.response.status === 401) {
                    window.location.href = window.location.origin + '/';
                }
            }
        }
        listBills();
    }

    useEffect(() => {
        refreshData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [pageCount, rowsPerPage]);

    async function buscar(
        statusReserva: any,
        statusPagamento: any,
        statusFormaPagamento: any,
        supplier: any,
        adquirente: any,
        tokenNsu: any,
        typeDate: any,
        date: any,
        idDecolarFenix: any
    ) {
        setActualStatusReserve(statusReserva);
        setActualStatusPagamento(statusPagamento);
        setActualStatusFormaPagamento(statusFormaPagamento);
        setActualSupplier(supplier);
        setActualAdquirente(adquirente);
        setActualToken(tokenNsu);
        setActualTypeDate(typeDate);
        setActualDate(date);
        setPageCount(1)

        setLoading(true);
        let body: any = document.querySelector("body");
        if (body.className !== "modal-open") {
            setBillsReceive(null);
        }

        const token = localStorage.getItem('GroupId') || '{}';
        const config = {
            headers: { 'Authorization': `Bearer ${token}` },
        };

        let beginDate = []
        let endDate = []


        if (date.length === 1) {
            beginDate = date[0].split('/')
        } else if (date.length === 2) {
            beginDate = date[0].split('/')
            endDate = date[1].split('/')
        }

        let today: any = new Date();

        try {
            const res = await api.post('/BillsReceivable/GetReport',
                {
                    "page": 1,
                    "rowsPerPage": rowsPerPage,
                    "item": '',
                    "erp": 1,
                    "idDecolarFenix": idDecolarFenix ? idDecolarFenix : null, 
                    "tokenNsu": tokenNsu ? tokenNsu : '',
                    "agenteRef": '',
                    "statusReserva": statusReserva ? parseInt(statusReserva) : null,
                    "statusPagamento": statusPagamento ? parseInt(statusPagamento) : null,
                    "formaPagamento": statusFormaPagamento ? parseInt(statusFormaPagamento) : null,
                    "adquirente": adquirente ? adquirente : null,
                    "fornecedor": supplier ? supplier : null,
                    "agenteIndividual": -1,
                    "agente": null,
                    "cliente": '',
                    "tipoData": typeDate ? parseInt(typeDate) : 2,
                    "dataInicial": (date.length > 0) ? `${beginDate[2]}-${beginDate[1]}-${beginDate[0]}` : `${today.getFullYear()}-${today.getMonth() + 1}-${today.getDate()}`,
                    "dataFinal": (date.length === 1) ? `${beginDate[2]}-${beginDate[1]}-${parseInt(beginDate[0])}` : (date !== null && date.length === 2) ? `${endDate[2]}-${endDate[1]}-${endDate[0]}` : `${today.getFullYear()}-${today.getMonth() + 1}-${today.getDate()}`
                }, config
            );
            setLoading(false);
            if (res.status !== 400) {
                setParameters({
                    "page": 0,
                    "rowsPerPage": 0,
                    "item": '',
                    "erp": 1,
                    "idDecolarFenix": idDecolarFenix ? idDecolarFenix : null,
                    "tokenNsu": tokenNsu ? tokenNsu : '',
                    "agenteRef": '',
                    "statusReserva": statusReserva ? parseInt(statusReserva) : null,
                    "statusPagamento": statusPagamento ? parseInt(statusPagamento) : null,
                    "formaPagamento": statusFormaPagamento ? parseInt(statusFormaPagamento) : null,
                    "adquirente": adquirente ? adquirente : null,
                    "fornecedor": supplier ? supplier : null,
                    "agenteIndividual": -1,
                    "agente": null,
                    "cliente": '',
                    "tipoData": typeDate ? parseInt(typeDate) : 2,
                    "dataInicial": (date.length > 0) ? `${beginDate[2]}-${beginDate[1]}-${beginDate[0]}` : `${today.getFullYear()}-${today.getMonth() + 1}-${today.getDate()}`,
                    "dataFinal": (date.length === 1) ? `${beginDate[2]}-${beginDate[1]}-${parseInt(beginDate[0])}` : (date !== null && date.length === 2) ? `${endDate[2]}-${endDate[1]}-${endDate[0]}` : `${today.getFullYear()}-${today.getMonth() + 1}-${today.getDate()}`
                });
                setBillsReceive(res.data.data.rows);
                setTotalRows(res.data.data.rowsCount);
                setTotalBillsReceive(res.data.data.valorTotal);
            }
        } catch (error: any) {
            setError(true)
            if (error?.response?.status === 401) {
                window.location.href = window.location.origin + '/';
            }
        }
    };

    async function exportBills(layout: any) {
        console.log(layout, actualStatusFormaPagamento)
        let allowRequest: boolean = true;

        let beginDate = []
        let endDate = []

        if (actualDate.length === 1) {
            beginDate = actualDate[0].split('/')
        } else if (actualDate.length === 2) {
            beginDate = actualDate[0].split('/')
            endDate = actualDate[1].split('/')
        }

        let today: any = new Date();

        if (layout === 4 && parseInt(actualStatusFormaPagamento) !== 4) { ///quando for cartão de credito so deixa exportar quando for filtro forma pagamento igual a cartão
            allowRequest = false;
        }

        if (allowRequest === true) {
            const token = localStorage.getItem('GroupId') || '{}';
            const config: any = {
                headers: { 
                    'Authorization': `Bearer ${token}`, 
                    'Content-Type': 'application/json',
                    "Accept": "*/*"
                    //"Access-Control-Allow-Origin": "*"
                },
                responseType: 'blob'
            };
            try {
                const exp = await api.post('/BillsReceivable/ExportReport',
                    {
                        "page": 1,
                        "rowsPerPage": 0, // pagina unica
                        "layout": layout,
                        "erp": 1,
                        "item": '',
                        "idDecolarFenix": null,
                        "tokenNsu": actualToken ? actualToken : '',
                        "agenteRef": '',
                        "statusReserva": actualStatusReserve ? parseInt(actualStatusReserve) : null,
                        "statusPagamento": actualStatusPagamento ? parseInt(actualStatusPagamento) : null,
                        "formaPagamento": actualStatusFormaPagamento ? parseInt(actualStatusFormaPagamento) : null,
                        "adquirente": actualAdquirente ? actualAdquirente : null,
                        "fornecedor": actualSupplier ? actualSupplier : null,
                        "agenteIndividual": -1,
                        "agente": null,
                        "cliente": '',
                        "tipoData": actualTypeDate ? parseInt(actualTypeDate) : 2,
                        "dataInicial": (actualDate.length > 0) ? `${beginDate[2]}-${beginDate[1]}-${beginDate[0]}` : `${today.getFullYear()}-${today.getMonth() + 1}-${today.getDate()}`,
                        "dataFinal": (actualDate.length === 1) ? `${beginDate[2]}-${beginDate[1]}-${parseInt(beginDate[0])}` : (actualDate !== null && actualDate.length === 2) ? `${endDate[2]}-${endDate[1]}-${endDate[0]}` : `${today.getFullYear()}-${today.getMonth() + 1}-${today.getDate()}`
                    },
                    config

                );
                if (exp.status !== 400) {
                    var hoje: any;
                    var filename: any;
                    if(layout === 2) {
                        hoje = new Date();

                        const ano = (`${hoje.getFullYear()}`).slice(-2);
                        const mes = (`00${hoje.getMonth() + 1}`).slice(-2);
                        const dia = (`00${hoje.getDate()}`).slice(-2);
                    
                        const hoje_file = `${dia}.${mes}.${ano}`;
            
                        //if(this.agente != '') {
                        //    var agent_name = '_'+ this.getAgentDesc(this.agente);
                        //} else {
                            var agent_name = '';
                        //}
            
                        filename = 'CAR'+agent_name+'_'+hoje_file+'.xlsx';
                    } else if(layout === 3) {
                        hoje = new Date();
            
                        const ano = (`${hoje.getFullYear()}`).slice(-2);
                        const mes = (`00${hoje.getMonth() + 1}`).slice(-2);
                        const dia = (`00${hoje.getDate()}`).slice(-2);
                    
                        const hoje_file = `${ano}${dia}${mes}`;
                        
                        filename = 'HB Ebilling'+hoje_file+'.csv';
                    } else {
                        filename = 'Contas a Receber.xlsx';
                    }

                    const blob = new Blob([exp.data], { type: exp.headers["Content-Type"] });
                    const objectUrl = window.URL.createObjectURL(blob);

                    const link = document.createElement('a');
                    link.href = objectUrl;
                    link.setAttribute(
                        'download',
                        filename,
                    );

                    // Append to html link element page
                    document.body.appendChild(link);

                    // Start download
                    link.click();

                    // // Clean up and remove the link
                    link?.parentNode?.removeChild(link);
                }
            } catch (error: any) {
                setError(true)
                if (error?.response?.status === 401) {
                    window.location.href = window.location.origin + '/';
                }
            }
        }
    };

    async function printBills() {

        let beginDate = []
        let endDate = []

        if (actualDate.length === 1) {
            beginDate = actualDate[0].split('/')
        } else if (actualDate.length === 2) {
            beginDate = actualDate[0].split('/')
            endDate = actualDate[1].split('/')
        }

        let today: any = new Date();

        const token = localStorage.getItem('GroupId') || '{}';
        const config: any = {
            headers: { 
                'Authorization': `Bearer ${token}`, 
                'Content-Type': 'application/json',
                "Accept": "*/*"
                //"Access-Control-Allow-Origin": "*"
            },
            responseType: 'blob'
        };

        try {
            const print = await api.post('/BillsReceivable/PrintReport',
                {
                    "page": 1,
                    "rowsPerPage": 0, // pagina unica
                    "erp": 1,
                    "item": '',
                    "idDecolarFenix": null,
                    "tokenNsu": actualToken ? actualToken : '',
                    "agenteRef": '',
                    "statusReserva": actualStatusReserve ? parseInt(actualStatusReserve) : null,
                    "statusPagamento": actualStatusPagamento ? parseInt(actualStatusPagamento) : null,
                    "formaPagamento": actualStatusFormaPagamento ? parseInt(actualStatusFormaPagamento) : null,
                    "adquirente": actualAdquirente ? actualAdquirente : null,
                    "fornecedor": actualSupplier ? actualSupplier : null,
                    "agenteIndividual": -1,
                    "agente": null,
                    "cliente": '',
                    "tipoData": actualTypeDate ? parseInt(actualTypeDate) : 2,
                    "dataInicial": (actualDate.length > 0) ? `${beginDate[2]}-${beginDate[1]}-${beginDate[0]}` : `${today.getFullYear()}-${today.getMonth() + 1}-${today.getDate()}`,
                    "dataFinal": (actualDate.length === 1) ? `${beginDate[2]}-${beginDate[1]}-${parseInt(beginDate[0])}` : (actualDate !== null && actualDate.length === 2) ? `${endDate[2]}-${endDate[1]}-${endDate[0]}` : `${today.getFullYear()}-${today.getMonth() + 1}-${today.getDate()}`
                },
                config

            );
            if (print.status !== 400) {
                const blob = new Blob([print.data], { type: print.headers["Content-Type"] });
                const objectUrl = window.URL.createObjectURL(blob);

                const link = document.createElement('a');
                link.href = objectUrl;
                link.setAttribute(
                    'download',
                    'contas_receber.pdf',
                );

                // Append to html link element page
                document.body.appendChild(link);

                // Start download
                link.click();

                // // Clean up and remove the link
                link?.parentNode?.removeChild(link);


            }
        } catch (error: any) {
            setError(true)
            if (error?.response?.status === 401) {
                window.location.href = window.location.origin + '/';
            }
        }
    };

    return (
        <>
            <DesktopDefault>
                <div className="container-fluid content-dashboard">
                    <Breadcrumb title={'Financeiro / Exportação ERP / Contas a Receber'} />
                    <div className="suppliers mt-4 d-flex justify-content-between align-items-center">
                        <h2 className="title" style={{ color: "#707070" }}>Conciliação de Contas a Receber</h2>
                    </div>
                    <FilterBillsReceiveERP
                        buscar={buscar}
                        defaultStartDate={defaultStartDateStr}
                    />
                    <TableBillsReceiveERP
                        billsReceive={billsReceive}
                        totalRows={totalRows}
                        pageCount={pageCount}
                        setPageCount={setPageCount}
                        rowsPerPage={rowsPerPage}
                        setRowsPerPage={setRowsPerPage}
                        exportBills={exportBills}
                        printBills={printBills}
                        loading={loading}
                        totalBillsReceive={totalBillsReceive}
                        actualDate={actualDate}
                        parameters={parameters}
                        refreshData={refreshData}
                    />
                </div>
            </DesktopDefault>
        </>
    );

}

export default BillsToReceiveERP;