import React, { useState } from "react";
import Accordion from "react-bootstrap/Accordion";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button, Col, Container, Form, Row } from "react-bootstrap";
import { Controller, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";


export interface propFilter {
  buscar: any;
  categories: any;
  clearActualValues: any;  
}

const CategoriesFilter: React.FC<propFilter> = ({
  buscar,
  categories,
  clearActualValues
}) => {
  const [t] = useTranslation();
  const [filterName, setFilterName] = useState<any>("");
  const [filterCode, setFilterCode] = useState<any>("");
  const [filterStatus, setFilterStatus] = useState<any>(0);

  const {
    control,
    setValue,
  } = useForm({});

  const handleFilterClick = () => {
    buscar(filterName, filterCode, filterStatus);
  };

  const handleClearClick = () => {
    setValue("categories", "");

    let inputValue = document.querySelectorAll("input");
    for (let i = 0; i < inputValue.length; i++) {
      inputValue[i].value = "";
    }

    let selectValue = document.querySelectorAll("select");
    for (let i = 0; i < selectValue.length; i++) {
      if (selectValue[i].id === "rowsPerPage") {
        selectValue[i].value = "10";
      } else {
        selectValue[i].value = "";
      }
    };

    setFilterName("");
    setFilterCode("");
    setFilterStatus(0);
    buscar("", "", 0);
    clearActualValues();
  };

  const handleChangeName = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = e.target.value;
    setFilterName(newValue);
  };

  const handleChangeCode = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = e.target.value;
    setFilterCode(newValue);
  };

  const handleChangeStatus = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = e.target.value;
    setFilterStatus(newValue);
  };

  return (
    <div
      className="suppliers-filter d-flex justify-content-center bg-white mt-4"
      style={{ textAlign: "left" }}
    >
      <Accordion defaultActiveKey="1">
        <Accordion.Item eventKey="0">
          <Accordion.Header>
            <FontAwesomeIcon
              icon={["fal", "sliders-h"]}
              size="lg"
              className="mr-2"
            />
            <span className="h5 mb-0">Buscar categoria</span>
          </Accordion.Header>
          <Accordion.Body className="pt-0">
            <Container className="p-0" fluid>
              <Row>
                <div>
                  <Form>
                    <Row className="mb-4">
                      <Form.Group as={Col} lg="4" mb="4" sm="4">
                        <Form.Label>
                          Buscar por nome
                        </Form.Label>
                        <Controller
                          control={control}
                          name="name"
                          render={({ field }: any) => (
                            <Form.Control
                              {...field}
                              type="text"
                              variant="standard"
                              margin="normal"
                              autoComplete="off"
                              onChange={handleChangeName}
                            />
                          )}
                        />
                      </Form.Group>

                      <Form.Group as={Col} lg="4" mb="4" sm="4">
                        <Form.Label>Buscar por código</Form.Label>
                        <Controller
                          control={control}
                          name="code"
                          render={({ field }: any) => (
                            <Form.Control
                              {...field}
                              type="text"
                              variant="standard"
                              margin="normal"
                              autoComplete="off"
                              onChange={handleChangeCode}
                            />
                          )}
                        />
                      </Form.Group>

                      <Form.Group as={Col} lg="4" mb="4" sm="4">
                        <Form.Label>Buscar por status</Form.Label>
                        <Controller
                          control={control}
                          name="status"
                          render={({ field }: any) => (
                            <div className="endpoint">
                              <Form.Select
                                {...field}
                                as="select"
                                variant="standard"
                                margin="normal"
                                onChange={(e: any) => handleChangeStatus(e)}
                              >
                                <option value="0" selected>
                                  Status
                                </option>                                
                                <option value="1">Ativo</option>
                                <option value="2">Inativo</option>                                
                              </Form.Select>
                            </div>
                          )}
                        />
                      </Form.Group>
                    </Row>
                  

                    <Row
                      as={Col}
                      md="12"
                      className="mt-5 justify-content-md-end"
                    >
                      <Col xs lg="12" className="d-flex justify-content-md-end">
                        <Button
                          variant="outline-primary"
                          className="btn-default mr-2 text-uppercase"
                          onClick={handleClearClick}
                        >
                          {t("reservations.filter.btnClear")}
                        </Button>
                        <Button
                          className="btn-default text-uppercase"
                          onClick={handleFilterClick}
                        >
                          {t("reservations.filter.btnSearch")}
                        </Button>
                      </Col>
                    </Row>
                  </Form>
                </div>
              </Row>
            </Container>
          </Accordion.Body>
        </Accordion.Item>
      </Accordion>
    </div>
  );
};

export default CategoriesFilter;
