import React, { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Dropdown, Form, Modal, Col, OverlayTrigger, Tooltip } from "react-bootstrap";
import { Controller, useForm } from "react-hook-form";
import { ErrorMessage } from "@hookform/error-message";

import { useTranslation } from 'react-i18next';

import "react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css";
import "react-bootstrap-table-next/dist/react-bootstrap-table2.css";
import BootstrapTable from "react-bootstrap-table-next";
//import { Link } from "react-router-dom";

//STYLES
//import "./PartnersTable.scss";
import api from "../../../services/api";
//import Pagination from "../../../../../components/Pagination/Pagination";

import './TransferTable.scss';
import Pagination from "../../../components/Pagination/Pagination";

export interface propTable {
    transfers: any;
    totalRows: any;
    pageCount: any;
    setPageCount: any;
    rowsPerPage: any;
    setRowsPerPage: any;
}

const TransferTable: React.FC<propTable> = ({
    transfers, totalRows, pageCount, setPageCount, rowsPerPage, setRowsPerPage
}: propTable) => {
    const { t } = useTranslation();
    const [modalUpdateStatus, setModalUpdateStatus] = useState<any>(false);
    const [modalConfirmStatus, setModalConfirmStatus] = useState<any>(false);
    const [modalConfirmStatusLog, setModalConfirmStatusLog] = useState<any>(0);
    const [modalConfirmStatusMessage, setModalConfirmStatusMessage] = useState<any>('');
    const [partnerId, setPartnerId] = useState<any>(null);
    //const [supplierId, setSupplierId] = useState<any>(null);

    const {
        control,
        handleSubmit,
        formState: { errors },
    } = useForm();

    // const handleClickStatus = (row: any) => {
    //     setPartnerId(row.id);
    //     setModalUpdateStatus(true);
    // }

    const onSubmit = (obj: any) => {
        const token = localStorage.getItem('GroupId') || '{}';
        const config = {
            headers: { 'Authorization': `Bearer ${token}` },
        };

        async function changeStatus() {
            try {
                const { data } = await api.post('/Supplier/UpdateStatusSupplierAsync',
                    {
                        "supplierId": partnerId,
                        "status": parseInt(obj.updateStatus),
                    }, config
                );
                if (data.data.log === 0) {
                    setModalConfirmStatusLog(0);
                    setModalUpdateStatus(false);
                    setModalConfirmStatus(true);
                    setModalConfirmStatusMessage(data.data.texto)
                    //reloadSerch();
                } else {
                    setModalConfirmStatusLog(1);
                    setModalConfirmStatus(true);
                    setModalConfirmStatusMessage(data.data.texto)
                }

            } catch (error: any) {
                if (error?.response?.status === 401) {
                    window.location.href = window.location.origin + '/';
                }

            }
        }
        changeStatus();

    }

    function addActionButton(cell: any, row: any) {
        return (
            <>
                <div className="d-flex justify-content-center">
                    <Dropdown drop="start">
                        <Dropdown.Toggle variant="light ">
                            <FontAwesomeIcon size="lg" icon={["fal", "ellipsis-h"]} />
                        </Dropdown.Toggle>

                        <Dropdown.Menu>
                            {/* <Dropdown.Item eventKey="1" disabled={true} ><FontAwesomeIcon icon={['fal', 'comment-alt-dollar']} />{t('suppliersTable.account')}</Dropdown.Item> */}
                            <Dropdown.Item eventKey="2" href={"/products/edit-transfer?id=" + cell}><FontAwesomeIcon icon={['fal', 'edit']} />{t('suppliersTable.edit')}</Dropdown.Item>
                            {/* <Dropdown.Item eventKey="3" onClick={() => { handleClickStatus(row); }}><FontAwesomeIcon icon={['fal', 'circle']} />{t('suppliersTable.status')}</Dropdown.Item> */}
                            {/* <Dropdown.Item eventKey="5" disabled={true}><FontAwesomeIcon icon={['fal', 'file-contract']} />{t('suppliersTable.contract')}</Dropdown.Item> */}
                            {/* <Dropdown.Item eventKey="4" disabled={false} className="text-danger remove"><FontAwesomeIcon icon={['fal', 'trash-alt']} />{t('suppliersTable.remove')}</Dropdown.Item> */}
                        </Dropdown.Menu>
                    </Dropdown>
                </div>
            </>
        );
    }

    function addStatus(cell: any, row: any) {
        if (window.innerWidth > 767) {
            return (
                <div
                    className={
                        cell === 3
                            ? "bg-yellow mx-auto"
                            : cell === 1
                                ? "bg-ativo mx-auto"
                                : cell === 2
                                    ? "bg-inativo mx-auto"
                                    : ""
                    }
                >
                    {cell === 1 ? "Ativo" : cell === 2 ? "Inativo" : "Rascunho"}
                </div>
            );
        } else {
            return (
                <div
                style={{ width: "10px", height: "10px" }}
                    className={
                        cell === 3
                            ? "bg-yellow mx-auto"
                            : cell === 1
                                ? "bg-ativo mx-auto"
                                : cell === 2
                                    ? "bg-inativo mx-auto"
                                    : ""
                    }
                >
                    {/* {cell === 1 ? "Ativo" : cell === 2 ? "Inativo" : "Rascunho"} */}
                </div>
            );
        }
    }

    function textReduce(cell: any) {
        return (
            <>
                <OverlayTrigger
                    overlay={
                        <Tooltip id="tooltip">{cell}</Tooltip>
                    }>
                    <span className="text-wrap-custom">
                        {cell}
                    </span>
                </OverlayTrigger>
            </>
        )
    }

    function textService(cell: any) {
        return (
            <>
                <span >
                    {cell === 1 ? "Privativo" : "Compartilhada"}
                </span>
            </>
        )
    }

    /* ALTERAR OS DATAFIELDS QUANDO A API CHEGAR */
    let columns: any = [];

    if (window.innerWidth > 767) {
        columns = [
            { dataField: "origem", text: "Origem", formatter: textReduce },
            { dataField: "desdeCompartilhado", text: "Desde", formatter: textReduce },
            { dataField: "destino", text: "Destino", formatter: textReduce },
            { dataField: "ateCompartilhado", text: "Até", formatter: textReduce },
            { dataField: "idSellingType", text: "Serviço", formatter: textService },
            { dataField: "supplierId", text: "Fornecedor" },
            { dataField: "status", text: "Status", formatter: addStatus },
            { dataField: "idTransfer", text: "Ações", formatter: addActionButton },
        ];
    } else {
        columns = [
            { dataField: "origem", text: "Origem", formatter: textReduce },
            { dataField: "destino", text: "Destino", formatter: textReduce },
            { dataField: "status", text: "Status", formatter: addStatus },
            { dataField: "idTransfer", text: "Ações", formatter: addActionButton },
        ];
    }



    /* [END] ALTERAR OS DATAFIELDS QUANDO A API CHEGAR */

    /* loading table */

    function loadingFunc() {
        return (
            <>
                <div className="animated-background row-loading"></div>
            </>
        )
    }

    const loadingColumns = [{ dataField: "contasd", text: "Carregando Clientes", formatter: loadingFunc }];

    const loadingProducts = [
        { contas: "" }, { contas: "" }, { contas: "" }, { contas: "" }, { contas: "" },
        { contas: "" }, { contas: "" }, { contas: "" }, { contas: "" }, { contas: "" }
    ];
    /* [END] loading table */

    /* In case of empty table*/
    const notFoundColumns = [{ dataField: "clientes", text: "Clientes" }];

    const notFoundProducts = [{ clientes: "Nenhum cliente cadastrado" }];
    /* [END] In case of empty table*/

    const rowClasses = (row: any, rowIndex: any) => {
        if (row.status === 3) {
            return 'border-pendente-escala';
        } else if (row.status === 1) {
            return 'border-ativo';
        } else {
            return 'border-inativo';
        }
    };

    if (transfers !== null) {
        /* console.log(transfers) */
        return (
            <>
                <div className="table-default transfers-table">
                    <div className="table-container">
                        <div className="table-title">
                            <h3 className="">Transfers</h3>
                        </div>

                        <BootstrapTable
                            bootstrap4
                            keyField="id"
                            data={transfers}
                            columns={columns}
                            //pagination={pagination}
                            striped={true}
                            rowClasses={rowClasses}
                        />
                        <Pagination
                            totalRows={totalRows}
                            pageCount={pageCount}
                            setPageCount={setPageCount}
                            rowsPerPage={rowsPerPage}
                            setRowsPerPage={setRowsPerPage}
                            selectNumberRows="yes"
                        />
                    </div>
                </div>

                <Modal
                    className="modalAuth"
                    show={modalUpdateStatus}
                    onHide={() => { setPartnerId(null); setModalUpdateStatus(false); }}
                    size="lg"
                    aria-labelledby="contained-modal-title-vcenter"
                >
                    <>
                        <div className='status-modal'>
                            <Form className="modal-content" noValidate validated={false} onSubmit={handleSubmit(onSubmit)} autoComplete="false">
                                <Modal.Body>

                                    <div className="modal-header rounded-0">
                                        <div>
                                            <h4 className="pt-1 text-primary title-reservation">Editar Status</h4>
                                            <small>

                                            </small>
                                        </div>

                                        <div className='buttons-options'>
                                            <div>

                                            </div>
                                            <div>
                                                <FontAwesomeIcon
                                                    icon={['fal', 'times']}
                                                    className="mx-3 text-info"
                                                    size="lg"
                                                    data-dismiss="modal"
                                                    aria-label="Close"
                                                    onClick={() => { setPartnerId(null); setModalUpdateStatus(false) }}
                                                />
                                            </div>
                                        </div>
                                    </div>

                                    <hr className='w-100 my-1' style={{ borderBottom: '1px solid' }} />

                                    <div>
                                        <Form.Group as={Col} md="5" controlId="formInfoCompanySubscription">
                                            <Form.Label>Tem certeza que deseja alterar o status?</Form.Label>
                                            <Controller
                                                control={control}
                                                name="updateStatus"
                                                rules={{ required: { value: true, message: 'Por favor, escolha uma opção.' } }}
                                                defaultValue={'2'}
                                                render={({ field }: any) => (
                                                    <Form.Select
                                                        {...field}
                                                        as="select"
                                                        variant="standard"
                                                        margin="normal"
                                                        style={{ border: "1px solid #ced4da" }}
                                                        required
                                                    >
                                                        <option value=''  >Selecione</option>
                                                        <option value='1'>Em Análise</option>
                                                        <option value='2'>Ativo</option>
                                                        <option value='3'>Inativo</option>
                                                        <option value='4'>Bloqueado</option>
                                                        <option value='5'>Pendente Aprovação</option>
                                                    </Form.Select>
                                                )}
                                            />
                                            <ErrorMessage
                                                errors={errors}
                                                name="updateStatus"
                                                render={({ message }) => <small style={{ color: "red" }}>{message}</small>}
                                            />
                                        </Form.Group>
                                    </div>
                                </Modal.Body>
                                <Modal.Footer>
                                    <div className="modal-footer rounded-0 border-0">
                                        <button type="button" className="btn-default mr-2 text-uppercase btn btn-outline-primary" data-dismiss="modal" onClick={() => { setPartnerId(null); setModalUpdateStatus(false) }}><i className="fas fa-times fa-fw"></i> <span data-vouchercampo="close">Cancelar</span></button>
                                        {/* <button type="submit" className="btn-default text-uppercase btn btn-primary">
                                            {loading === true ? <div className="load"></div> : "Confirmar"}
                                        </button> */}
                                    </div>
                                </Modal.Footer>
                            </Form>
                        </div>
                    </>
                </Modal>

                <Modal
                    className="modalAuth"
                    show={modalConfirmStatus}
                    onHide={() => { setModalConfirmStatus(false); }}
                    aria-labelledby="contained-modal-title-vcenter"
                >
                    <>
                        <Modal.Body className='modal-body text-center sucess-pos d-flex justify-content-evenly' style={{ height: "600px" }}>
                            <div>
                                {modalConfirmStatusLog === 0
                                    ?
                                    <FontAwesomeIcon
                                        icon={["fal", "check-circle"]}
                                        size="5x"
                                        className="text-primary"
                                        style={{ fontSize: "7.5em"}}
                                    />
                                    :
                                    <FontAwesomeIcon
                                        icon={["fal", "times-circle"]}
                                        size="5x"
                                        className="text-primary"
                                        style={{ fontSize: "7.5em"}}
                                    />
                                }
                            </div>
                            <div>
                                {modalConfirmStatusMessage}
                            </div>
                            <div className="d-flex justify-content-center pt-3">
                                <button
                                    onClick={() => { setModalConfirmStatus(false); }}
                                    className="btn btn-primary mx-2 w-25"
                                >
                                    Confirmar
                                </button>
                            </div>

                        </Modal.Body>
                    </>
                </Modal>

            </>
        );
    } else if (transfers === null/*  && loading === true */) {
        return (
            <>
                <div className="table-default partners-table loading not-found">
                    <div className="table-container">
                        <BootstrapTable
                            bootstrap4
                            keyField="id"
                            data={loadingProducts}
                            hover={true}
                            columns={loadingColumns}
                            striped={true}
                        />
                    </div>
                </div>
            </>
        )
    } else {
        return (
            <>
                <div className="table-default partners-table not-found">
                    <div className="table-container">
                        <div className="table-title">
                            <h3 className="">Clientes</h3>
                        </div>

                        <BootstrapTable
                            bootstrap4
                            keyField="id"
                            data={notFoundProducts}
                            hover={true}
                            columns={notFoundColumns}
                            striped={true}
                        />
                    </div>
                </div>
            </>
        );
    }
}

export default TransferTable;
