import React, { useState} from 'react';
import api from "../../../../services/api";

import { Row, Col, InputGroup } from "react-bootstrap";

import { Form, } from "react-bootstrap";

import SingleCalendar from '../../../Calendar/SingleCalendar';

import './SetDueInBatch.scss';

export interface propResource {
    parameters: any,
    setResponseText?: any,
    setModalResponse?: any,
    setLog?: any;
    setModalSuccess?: any
    t?: any
};

const SetDueInBatch: React.FC<propResource> = ({
    parameters, setResponseText, setModalResponse, setLog, setModalSuccess, t
}: propResource) => {
//function SetDueInBatch(props: any) {
    // const [responseText, setResponseText] = useState<any>();
    // const [modalResponse, setModalResponse] = useState<any>();
    // const [log, setLog] = useState<any>();

    //const [modalShow, setModalShow] = useState<any>(false);
    //const [modalMessage, setModalMessage] = useState<any>('');
    
    const [vencimento, setVencimento] = useState<any>();

    const [dueError, setDueError] = useState<any>();
    
    const tourAuth: any = JSON.parse(localStorage.getItem('c2tourAuth') || '{}');

    const userName: any = tourAuth.user.nome;
    const userLastName: any = tourAuth.user.lastName;
    const uId: any = tourAuth.user.uId;


    function closeModal() {
        var closeBtn: any = document.getElementsByClassName("btn-close")[0];
        if (closeBtn !== undefined) {
            closeBtn.click();
        }
    }

    // const {
    //     control,
    //     handleSubmit,
    //     formState: { errors },
    // } = useForm();

    const fixDate = (data: any) => {
        var aux = data.split('/');

        return (`${aux[2]}-${aux[1]}-${aux[0]}`);
    }

    // function verifyDate(now: any, due: any) {
    //     var nowArray: any = now.split('-');
    //     var dueArray: any = due.split('-');

    //     if (parseInt(dueArray[0]) < parseInt(nowArray[0])) {
    //         return false;
    //     }
    //     if (parseInt(dueArray[1]) < parseInt(nowArray[1])) {
    //         return false;
    //     }
    //     if (parseInt(dueArray[2]) < parseInt(nowArray[2])) {
    //         return false;
    //     }
    //     return true;
    // }

    const onSubmit = (data: any) => {

        if(!vencimento) {
            setDueError(true);
            return;
        }
        setDueError(false);
        
        const vencimentoOk = fixDate(vencimento);

        setModalResponse('loading');
        setModalSuccess(true);

        //if (verifyDate(data.data, data.vencimento)) {
            const insertConta = async () => {
                const token = localStorage.getItem('GroupId') || '{}';
                const config = {
                    headers: { 'Authorization': `Bearer ${token}`, 'Content-Type': 'application/json' },
                };
                try {
                    const res = await api.post(`/BillsPayable/SetDueDateInBatch`,
                        {
                            "parametros": parameters,
                            "items": [],
                            "dataVcto": vencimentoOk,
                            "uid": uId,
                            "usuario": (userName !== null && userLastName !== null) ? `${userName} ${userLastName}` : userName
                        }, config);
                    if (res.status !== 400) {
                        setResponseText(res.data.data.texto);
                        setModalResponse('success');
                        setLog(res.data.data.log)
                    } else {
                        setResponseText(res.data.data.texto);
                        setModalResponse('error');
                        setLog(res.data.data.log);
                    }
                } catch (error: any) {
                    setResponseText(t('billsToPay.table.setDueInBatch.error'));
                    setModalResponse('error');
                    setLog(1);
                }
            };

            insertConta();
        //} else {
            //setModalShow(true);
            //setModalMessage("Data de vencimento antes da data atual.");
        //}
    }

    // useEffect(() => {
        
    //     if (errors) {
    //         setResponseText("Preencha os campos!");
    //         setModalResponse('error');
    //         setLog(1);
    //     }
    // }, [errors])

    return (
        <>
            <Form className="h-100 form-inserir" noValidate  autoComplete="false">
                <div className='d-flex flex-column justify-content-between h-100'>
                    <div className="my-auto">
                        <Row>
                            <Form.Group as={Col} className="mt-3 text-center" controlId="formGridVenc">
                                <h5>{t('billsToPay.table.setDueInBatch.batchExpiration')}</h5>
                                <p>{t('billsToPay.table.setDueInBatch.batchExpirationWarning')}</p>
                            </Form.Group>
                        </Row>
                        <Row>
                            <Form.Group as={Col} md={12} className="mt-3" controlId="formGridVenc">
                                <Form.Group as={Col} md={5} className="mt-3 mx-auto" controlId="formGridVenc">
                                    <Form.Label>{t('billsToPay.table.setDueInBatch.expiration')}</Form.Label>
                                    <InputGroup
                                        hasValidation
                                        className={` ${dueError === true ? "endpoint-error" : ""}`}
                                        size="sm"
                                        placeholder={t('billsToPay.table.setDueInBatch.expiration')}
                                    >
                                        <SingleCalendar setValue={setVencimento} />
                                    </InputGroup>
                                    {dueError === true ?
                                        <small style={{ color: 'red' }}>{t('billsToPay.table.setDueInBatch.expirationError')}</small>
                                        : <></>}
                                </Form.Group>
                            </Form.Group>
                        </Row>
                    </div>
                    <div className='mt-4 d-flex justify-content-end'>
                        <button className="btn btn-default btn-outline-primary px-4 mr-3 text-uppercase" onClick={closeModal}>
                        {t('billsToPay.table.setDueInBatch.cancel')}
                        </button>
                        <button className="btn btn-default btn-primary px-4 text-uppercase" onClick={onSubmit}>
                        {t('billsToPay.table.setDueInBatch.confirm')}
                        </button>
                    </div>
                </div>
            </Form>
        </>
    )
}

export default SetDueInBatch;