import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useState } from "react";
import { Container } from 'react-bootstrap'

import './Warning.scss';
import i18next from "i18next";

function Warning() {
    const [lang, setLang] = useState<string>("pt-BR");
    
    const msg: any = {
        "pt-BR": {
            attention: "Atenção:",
            development: "Você está no ambiente de desenvolvimento"
        },
        "pt": {
            attention: "Atenção:",
            development: "Você está no ambiente de desenvolvimento"
        },
        "en": {
            attention: "Attention:",
            development: "You are in the development environment"
        },
        "es": {
            attention: "Atención:",
            development: "Estás en el entorno de desarrollo"
        }
    }

    useEffect(()=>{
        setLang(i18next.language);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[i18next.language]);
    
    
    return (
        <>
            <Container className="bg-top-warning py-2 warning-dev" style={{ backgroundColor: "#dc3545" }} fluid>
                <span><FontAwesomeIcon icon={["fad", "mug-hot"]} size="lg" style={{ marginRight: "15px" }} /><b>{msg[lang].attention} </b>{msg[lang].development}</span>                
            </Container>
        </>
    )
}

export default Warning;