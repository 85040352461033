import React, { Key, useState, useEffect } from 'react';
import { Row, Col, Card } from "react-bootstrap";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import Form from "react-bootstrap/Form";
import { Controller } from "react-hook-form";
import { ErrorMessage } from "@hookform/error-message";
//import Select from "react-select";
//import GetPickups from '../../../../components/C2Points/GetPickups';
import deleteIcon from '../../../assets/icons/icon-garbage.png';

import { useAppDispatch } from "../../../store/hooks";
import { removeItemCart } from "../../../store/cart";
//import GetDDI from '../../C2Points/GetDDI';

export interface propCart {
    info: any,
    control: any,
    errors: any,
    setValue: any,
    data: any;
    mainContactObj?: any;
    t?:any;
};

const CartCheckoutTickets: React.FC<propCart> = ({
    info, control, errors, setValue, data, mainContactObj, t
}: propCart) => {
    const [numAdultos, setNumAdultos] = useState<any>([]);
    const [allowOthers, setAllowOthers] = useState<boolean>(false);
    //const [pickupSelect, setPickupSelect] = useState<any>(null);
    /* function getParticipants() {
      return;
    } */
    const dispatch = useAppDispatch();

    useEffect(() => {
        var aux: any = [];
        for (var i: any = 0; i < info.adults; i++) {
            aux.push(i)
        }
        setNumAdultos(aux)
    }, [info])

    useEffect(() => {
        setValue(`dados[${data}].passengers.0.firstName`, mainContactObj?.name);
        setValue(`dados[${data}].passengers.0.lastName`, mainContactObj?.lastName);
        setValue(`dados[${data}].passengers.0.email`, mainContactObj?.email);
        setValue(`dados[${data}].passengers.0.phone`, mainContactObj?.phone);
    }, [data, setValue,/*  */ mainContactObj])

    const handleClickRemove = (productE: any) => {
        dispatch(removeItemCart(productE))
    };

    return (


        <>
            <Card className="cart-card-unit card-unit bg-white mt-4">
                <Card.Body>
                    <div className="card-title">
                        <div className="logo-button">
                            <FontAwesomeIcon
                                className='icon-primary'
                                icon={["fad", "ticket"]}
                                size="1x"
                                style={{ marginBottom: "5px", width: "25px", fontSize: "1.5rem" }}
                            />
                            <button onClick={() => { handleClickRemove(info) }}><img src={deleteIcon} alt="delete" /></button>
                        </div>
                        <div className="mb-2" style={{ borderBottom: "1px solid rgb(112 112 112 / 19%)", paddingBottom: ".875rem" }}>
                            <h4 className='mb-0'>{info.productName}</h4>
                            <p>{info.modalityName}</p>
                        </div>
                        <p>{t("checkout.cartCheckout.supplier")} <span>{info.supplierFantasyName}</span></p>
                    </div>

                    {/* <Form noValidate onSubmit={handleSubmit(onSubmit)} autoComplete="false"> */}
                    <Row className="mb-3">
                        <Form.Group as={Col} md="4" controlId="formCheckoutDate">
                            <Form.Label>{t("checkout.cartCheckout.date")}</Form.Label>
                            <p>{info.date.split('-').reverse().join('/')}</p>
                            {/* <Controller
                  control={control}
                  name="date"
                  render={({ field }: any) => (
                    <SingleCalendar date={info.date} setValue={setValue} />
  
                  )}
                /> */}
                            <ErrorMessage
                                errors={errors}
                                name="date"
                                render={({ message }) => <small style={{ color: "red" }}>{message}</small>}
                            />
                        </Form.Group>
                        <Form.Group as={Col} md="4" controlId="formCheckoutHour">
                            <Form.Label>{t("checkout.cartCheckout.hour")}</Form.Label>
                            <p>{info.time}</p>
                            {/* <Controller
                  control={control}
                  name="hour"
                  render={({ field }: any) => (
                    <Form.Control
                      {...field}
                      aria-invalid={errors?.hour ? "true" : ""}
                      variant="standard"
                      margin="normal"
                      autoComplete="off"
                      required
                    />
                  )}
                /> */}
                            <ErrorMessage
                                errors={errors}
                                name="hour"
                                render={({ message }) => <small style={{ color: "red" }}>{message}</small>}
                            />
                        </Form.Group>
                        <Form.Group as={Col} md="4" controlId="formCheckoutNumberOfPeople">
                            <Form.Label>{t("checkout.cartCheckout.people")}</Form.Label>
                            {info.sellingType === 2 ?
                                <p> <small>{t("checkout.cartCheckout.adults")} {info.adults}</small></p>
                                :
                                <p> <small>{t("checkout.cartCheckout.total")} {info.globalPeople}</small></p>
                            }
                            {/* <Controller
                  control={control}
                  name="numberOfPeople"
                  render={({ field }: any) => (
                    <div className="col-6 col-sm-6 col-md-12">
                      <div className="panel-dropdown bg-white">
                        <DropdownNumberPeople
                          actual={1}
                          info={{ idSellingType: "2" }}
                          actionPeople={setNewNumberPeople}
                        ></DropdownNumberPeople>
  
                      </div>
                    </div>
                  )}
                /> */}
                            <ErrorMessage
                                errors={errors}
                                name="numberOfPeople"
                                render={({ message }) => <small style={{ color: "red" }}>{message}</small>}
                            />
                        </Form.Group>
                    </Row>

                    <hr />

                    {/* {info.typePickup === '0'
              ?
              <>
                <Row className="mb-3">
                  <div><h3 className='mt-0'>Ponto de encontro</h3></div>
                  <div ><span>{info.meetingPoint}</span></div>
                </Row>
                <hr />
              </>
              :
              <>
  
              <Row className="mb-3">
                <Form.Group as={Col} md="8" controlId="formCheckoutPickup">
                  <Form.Label>Pickup:</Form.Label>
                  <GetPickups
                    propsId={info.pickupListId}
                    actionPickup={addPickup}
                    alert={alert}
                  />
                  <Controller
                    control={control}
                    name="pickup"
                    render={({ field }) => {
                      // sending integer instead of string.
                      return <input {...field} value={pickupSelect} type="hidden" autoComplete='off'/>;
                    }}
                  />
                  <ErrorMessage
                    errors={errors}
                    name="pickup"
                    render={({ message }) => <small style={{ color: "red" }}>{message}</small>}
                  />
                </Form.Group>
              </Row>
  
                    
              </>
            }
  
            <hr /> */}

                    <div className="participants">
                        <h3>{t("checkout.cartCheckout.attractionParticipants")}</h3>

                        {numAdultos.length > 0
                            ? numAdultos.slice(0, allowOthers === false ? 1 : numAdultos.length).map((person: any, index: Key) => {
                                return (
                                    <>
                                        <div className='person-form' key={index}>
                                            <h4>{t("checkout.cartCheckout.adultParticipant")}</h4>

                                            <Row className="mb-3">
                                                <Form.Group as={Col} md="4" controlId="formCheckoutPersonName">
                                                    <Form.Label>{t("checkout.cartCheckout.name")}</Form.Label>
                                                    <Controller
                                                        control={control}
                                                        name={`dados[${data}].passengers.${index}.firstName`}
                                                        rules={{ required: { value: index === 0, message: t("checkout.cartCheckout.nameError") } }}
                                                        render={({ field }: any) => (
                                                            <Form.Control
                                                                {...field}
                                                                aria-invalid={errors?.pickup ? "true" : ""}
                                                                variant="standard"
                                                                margin="normal"
                                                                autoComplete="off"
                                                                required
                                                            />
                                                        )}
                                                    />
                                                    <ErrorMessage
                                                        errors={errors}
                                                        name={`dados[${data}].passengers.${index}.firstName`}
                                                        render={({ message }) => <small style={{ color: "red" }}>{message}</small>}
                                                    />
                                                </Form.Group>
                                                <Form.Group as={Col} md="4" controlId="formCheckoutPersonLastname">
                                                    <Form.Label>{t("checkout.cartCheckout.lastname")}</Form.Label>
                                                    <Controller
                                                        control={control}
                                                        name={`dados[${data}].passengers.${index}.lastName`}
                                                        rules={{ required: { value: index === 0, message: t("checkout.cartCheckout.lastnameError") } }}
                                                        render={({ field }: any) => (
                                                            <Form.Control
                                                                {...field}
                                                                aria-invalid={errors?.pickup ? "true" : ""}
                                                                variant="standard"
                                                                margin="normal"
                                                                autoComplete="off"
                                                                required
                                                            />
                                                        )}
                                                    />
                                                    <ErrorMessage
                                                        errors={errors}
                                                        name={`dados[${data}].passengers.${index}.lastName`}
                                                        render={({ message }) => <small style={{ color: "red" }}>{message}</small>}
                                                    />
                                                </Form.Group>
                                                <Form.Group as={Col} md="4" controlId="formCheckoutPersonEmail">
                                                    <Form.Label>{t("checkout.cartCheckout.email")}</Form.Label>
                                                    <Controller
                                                        control={control}
                                                        name={`dados[${data}].passengers.${index}.email`}
                                                        rules={{
                                                            required: {
                                                                value: true,
                                                                message: t("checkout.cartCheckout.emailError"),
                                                            },
                                                            pattern: {
                                                                value: /\S+@\S+.\S+/,
                                                                message: t("checkout.cartCheckout.validEmail"),
                                                           },
                                                        }}
                                                        render={({ field }: any) => (
                                                            <Form.Control
                                                                {...field}
                                                                aria-invalid={errors?.pickup ? "true" : ""}
                                                                variant="standard"
                                                                margin="normal"
                                                                autoComplete="off"
                                                                required
                                                            />
                                                        )}
                                                    />
                                                    <ErrorMessage
                                                        errors={errors}
                                                        name={`dados[${data}].passengers.${index}.email`}
                                                        render={({ message }) => <small style={{ color: "red" }}>{message}</small>}
                                                    />
                                                </Form.Group>
                                            </Row>
                                            <Row className="mb-3">
                                                <Form.Group as={Col} md="4" controlId="formCheckoutPersonPhone">
                                                    <Form.Label>{t("checkout.cartCheckout.telephone")}</Form.Label>
                                                    <Controller
                                                        control={control}
                                                        name={`dados[${data}].passengers.${index}.phone`}
                                                        rules={{ 
                                                            required: { 
                                                                value: true, 
                                                                message: t("checkout.cartCheckout.telephoneError") 
                                                            }, 
                                                            pattern: {
                                                                value: /^[+]*[0-9]{2,3}[-\s]{0,1}[(]{0,1}[0-9]{1,3}[)]{0,1}[-\s./0-9]{8,15}$/g,
                                                                message: "Por favor, informe um telefone válido",
                                                           },
                                                        }}
                                                        render={({ field }: any) => (
                                                            <Form.Control
                                                                {...field}
                                                                aria-invalid={errors?.pickup ? "true" : ""}
                                                                variant="standard"
                                                                margin="normal"
                                                                autoComplete="off"
                                                                placeholder='+55 99 999999999'
                                                                required
                                                            />
                                                        )}
                                                    />
                                                    <ErrorMessage
                                                        errors={errors}
                                                        name={`dados[${data}].passengers.${index}.phone`}
                                                        render={({ message }) => <small style={{ color: "red" }}>{message}</small>}
                                                    />
                                                </Form.Group>                                              
                                            </Row>

                                        </div>
                                    </>
                                )
                            })
                            : ''}
                        <div>
                            <span className='text-primary' style={{ cursor: 'pointer' }} onClick={() => { setAllowOthers(!allowOthers) }}>
                                {allowOthers === false ? t("checkout.cartCheckout.addInfo") : t("checkout.cartCheckout.hideInfo")}
                            </span>
                        </div>
                    </div>

                    <hr />

                    <Row className="mb-3">
                        <Form.Group as={Col} md="8" className="form-textarea" controlId="formCheckoutNotes">
                            <Form.Label>{t("checkout.cartCheckout.internalNotes")}</Form.Label>
                            <Controller
                                control={control}
                                name={`dados[${data}].internalNotes`}
                                // rules={{ required: { value: true, message: 'Por favor, informe esse campo.' } }}
                                render={({ field }: any) => (
                                    <textarea
                                        {...field}
                                        className='form-control'
                                        aria-invalid={errors?.pickup ? "true" : ""}
                                        variant="standard"
                                        margin="normal"
                                        autoComplete="off"
                                        required
                                    />
                                )}
                            />
                            <ErrorMessage
                                errors={errors}
                                name={`dados[${data}].internalNotes`}
                                render={({ message }) => <small style={{ color: "red" }}>{message}</small>}
                            />
                        </Form.Group>
                    </Row>
                </Card.Body>
            </Card>
        </>
    );
}

export default CartCheckoutTickets;