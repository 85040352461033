import React, { useState } from "react"
import Accordion from "react-bootstrap/Accordion";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button, Col, Container, Form, Row } from "react-bootstrap";
import { Controller, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";



export interface propFilter {
    buscar: any, resourceToAssociate: any, clearActualValues: any
};

const ResourceToAssociatedFilter: React.FC<propFilter> = ({
    buscar, resourceToAssociate, clearActualValues
}) => {

    const [t] = useTranslation();
    const [filterName, setFilterName] = useState<any>("");
    const [filterState, setFilterState] = useState<any>("");
    const [filterSupplier, setFilterSupplier] = useState<any>(0);
    const [filterAssoStatus, setFilterAssoStatus] = useState<any>(0);

    const { control, setValue } = useForm({});

    const handleFilterClick = () => {
        buscar(filterName, filterState, filterSupplier, filterAssoStatus)
    };

    const handleClearClick = () => {
        setValue("resourceAssociate", "");

        let inputValue = document.querySelectorAll('input');
        for (let i = 0; i < inputValue.length; i++) {
            inputValue[i].value = "";
        }

        let selectValue = document.querySelectorAll('select');
        for (let i = 0; i < selectValue.length; i++) {
            if (selectValue[i].id === "rowsPerPage") {
                selectValue[i].value = "10";
            } else {
                selectValue[i].value = "";
            }
        }

        setFilterName("");
        setFilterState("");
        setFilterSupplier(0);
        setFilterAssoStatus(0);
        buscar("", "", 0, 0);
        clearActualValues();
    };


    const handleChangeName = (e: React.ChangeEvent<HTMLInputElement>) => {
        const newValue = e.target.value;
        setFilterName(newValue);
    };

    const handleChangeState = (e: React.ChangeEvent<HTMLInputElement>) => {
        const newValue = e.target.value;
        setFilterState(newValue);
    };

    const handleChangeSupplier = (e: React.ChangeEvent<HTMLInputElement>) => {
        const newValue = e.target.value;
        setFilterSupplier(newValue);
    };

    const handleChangeAssoStatus = (e: React.ChangeEvent<HTMLInputElement>) => {
        const newValue = e.target.value;
        setFilterAssoStatus(newValue);
    };







    return (
        <div className="suppliers-filter d-flex justify-content-center bg-white mt-4" style={{ textAlign: "left" }}>
            <Accordion defaultActiveKey="1">
                <Accordion.Item eventKey="0">
                    <Accordion.Header>
                        <FontAwesomeIcon icon={['fal', 'sliders-h']} size="lg" className="mr-2" /><span className="h5 mb-0">Buscar</span>
                    </Accordion.Header>
                    <Accordion.Body className="pt-0">
                        <Container className="p-0" fluid>
                            <Row>
                                <div>
                                    <Form>
                                        <Row className="mb-3">
                                            <Form.Group as={Col} lg="4" mb="4" sm="4">
                                                <Form.Label>Nome do fornecedor ou código</Form.Label>
                                                <Controller
                                                    control={control}
                                                    name="name"
                                                    render={({ field }: any) => (
                                                        <Form.Control
                                                            {...field}
                                                            type="text"
                                                            variant="standard"
                                                            margin="normal"
                                                            autoComplete="off"
                                                            onChange={handleChangeName}
                                                        />
                                                    )}
                                                />
                                            </Form.Group>

                                            <Form.Group as={Col} lg="4" mb="4" sm="4">
                                                <Form.Label>Estado</Form.Label>
                                                <Controller
                                                    control={control}
                                                    name="state"
                                                    render={({ field }: any) => (
                                                        <Form.Control
                                                            {...field}
                                                            type="text"
                                                            variant="standard"
                                                            margin="normal"
                                                            autoComplete="off"
                                                            onChange={handleChangeState}
                                                        />
                                                    )}
                                                />
                                            </Form.Group>

                                            <Form.Group as={Col} lg="4" mb="4" sm="4">
                                                <Form.Label>Tipo de Fornecedor</Form.Label>
                                                <Controller
                                                    control={control}
                                                    name="supplier"
                                                    render={({ field }: any) => (
                                                        <Form.Control
                                                            {...field}
                                                            type="text"
                                                            variant="standard"
                                                            margin="normal"
                                                            autoComplete="off"
                                                            onChange={handleChangeSupplier}
                                                        />
                                                    )}
                                                />
                                            </Form.Group>
                                        </Row>
                                        <Form.Group as={Col} lg="4" mb="4" sm="4">
                                                <Form.Label>Status</Form.Label>
                                                <Controller
                                                    control={control}
                                                    name="status"
                                                    render={({ field }: any) => (
                                                        <Form.Control
                                                            {...field}
                                                            type="text"
                                                            variant="standard"
                                                            margin="normal"
                                                            autoComplete="off"
                                                            onChange={handleChangeAssoStatus}
                                                        />
                                                    )}
                                                />
                                            </Form.Group>
                                        <Row className="mb-3">

                                        </Row>

                                        <Row as={Col} md="12" className="mt-5 justify-content-md-end">
                                            <Col xs lg="12" className="d-flex justify-content-md-end">
                                                <Button variant="outline-primary" className="btn-default mr-2 text-uppercase" onClick={handleClearClick}>{t('reservations.filter.btnClear')}</Button>
                                                <Button className="btn-default text-uppercase" onClick={handleFilterClick}>{t('reservations.filter.btnSearch')}</Button>
                                            </Col>
                                        </Row>
                                    </Form>
                                </div>
                            </Row>
                        </Container>
                    </Accordion.Body>
                </Accordion.Item>
            </Accordion>
        </div>
    )
};

export default ResourceToAssociatedFilter;