import React, { useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Row, Col, Modal } from "react-bootstrap";

import Breadcrumb from "../../components/Breadcrumb/Breadcrumb";
import DesktopDefault from "../../templates/DesktopDefault";
import ProductTransfer from "./ProductTransfer";
import api from "../../services/api";

function EditTransfer() {
    const [transferObject, setTransferObject] = useState({}); //objetão
    const [modalShow, setModalShow] = useState<any>(false);
    const [modalLog, setModalLog] = useState<any>(null);
    const [modalMessage, setModalMessage] = useState<any>("Carregando");

    const [transfer, setTransfer] = useState<boolean>(false);

    const transferId: any = window.location.href.split("?id=")[1];

    const isEdit = true;

    const [management, setManagement] = useState(1);

    const handleNext = (newStep: any, newObject: any = {}) => {
        switch (newStep) {
            case 'dataTransfer':
                setManagement(1);
                break;
            case 'meetingPoint':
                setManagement(2);
                break;
            case 'tariffs':
                setManagement(3);
                break;
            case 'dayshours':
                setManagement(4);
                break;
            case 'features':
                setManagement(5);
                break;
            case 'contact':
                setManagement(6);
                break;
            case 'blackout':
                setManagement(7);
                break;
            case 'administrative':
                setManagement(8);
                break;
            default:
                break;
        }

        setTransferObject({ ...transferObject, ...newObject })
    };


    const reload = () => {
        if (modalLog === 0) {
            window.location.href = "/products/list-transfer";
        }
    };

    const receiveObject = async (data: any) => {
        setModalShow(true);
        setTransferObject({ ...transferObject, ...data });
        setTransfer(true);
    }

    useEffect(() => {

        async function TransferById() {
            try {
                const res = await api.get(`Transfer/GetTransferByIdAsync/${transferId}`);
                if (res.status !== 400) {
                    //console.log(res.data.data)
                    setTransferObject(res.data.data.data);
                    //setTotalRows(res.data.data.rowsCount);
                }

            } catch (error) { }
        }
        TransferById();

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (transfer === true) {

            const insertTransfer = async () => {
                const token = localStorage.getItem('GroupId') || '{}';
                const config = {
                    headers: { 'Authorization': `Bearer ${token}`, 'Content-Type': 'application/json' },
                };
                try {
                    const { data } = await api.post('Transfer/UpdateTransfer', transferObject, config);
                    if (data.statusCode !== 400) {
                        console.log(data)
                        setModalLog(data.data.log);
                        setModalMessage(data.data.texto);
                    } else {

                    }

                } catch (error: any) {
                    if (error?.response?.status === 400) {

                    }
                }
            }

            insertTransfer();
            setTransfer(false);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [transfer]);

    return (
        <>
            <DesktopDefault>
                <div className="container-fluid content-dashboard">
                    <Breadcrumb title={"Produtos / Editar Transfer"} />

                    <div className="d-flex align-items-center my-3">
                        <span
                            className="back"
                            onClick={() => {
                                window.location.href = "/products/list-transfer";
                            }}
                        >
                            <FontAwesomeIcon
                                icon={["fal", "angle-double-left"]}
                                style={{ margin: "0 5px 0 0", fontSize: '16px' }}
                            />
                            Voltar
                        </span>
                    </div>

                    <Row className="mt-4 add-product">
                        {/* MENU */}
                        <Col md={3} lg={2}>
                            <div className='card menu h-100 border-0'>
                                <div className="list-menu">
                                    <div className="sub-list-menu">
                                        <span className="list-menu-item item-title">
                                            Informações Básicas
                                        </span>
                                        <span className={management === 1 ? "list-menu-item bg-edit-transfer item-action active" : "list-menu-item bg-edit-transfer item-action success d-flex justify-content-between align-items-center"} onClick={() => handleNext('dataTransfer')}>
                                            <p>Dados do translado</p>
                                            {management !== 1 ?
                                                <FontAwesomeIcon className="ml-2" size="lg" color="green" icon={["fal", "check"]} />
                                                :
                                                <></>
                                            }
                                            {/*ICONE DE CHECKED PARA QUANDO PASSAR PRO PROXIMO STEP <FontAwesomeIcon className="ml-2" size="lg" color="green" icon={["fal", "check"]}/> */}
                                        </span>
                                        <span className={management === 2 ? "list-menu-item bg-edit-transfer item-action active" : "list-menu-item bg-edit-transfer item-action success d-flex justify-content-between align-items-center"} onClick={() => handleNext('meetingPoint')}>
                                            <p>Ponto de Encontro</p>
                                            {management !== 2 ?
                                                <FontAwesomeIcon className="ml-2" size="lg" color="green" icon={["fal", "check"]} />
                                                :
                                                <></>
                                            }
                                        </span>
                                    </div>

                                    <div className="sub-list-menu">
                                        <span className="list-menu-item bg-edit-transfer item-title">
                                            Cronograma e Preços
                                        </span>
                                        <span className={management === 3 ? "list-menu-item bg-edit-transfer item-action active" : "list-menu-item bg-edit-transfer item-action success d-flex justify-content-between align-items-center"} onClick={() => handleNext('tariffs')}>
                                            <p>Tarifas</p>
                                            {management !== 3 ?
                                                <FontAwesomeIcon className="ml-2" size="lg" color="green" icon={["fal", "check"]} />
                                                :
                                                <></>
                                            }
                                        </span>
                                        <span className={management === 4 ? "list-menu-item bg-edit-transfer item-action active" : "list-menu-item bg-edit-transfer item-action success d-flex justify-content-between align-items-center"} onClick={() => handleNext('dayshours')}>
                                            <p>Dias e Horas</p>
                                            {management !== 4 ?
                                                <FontAwesomeIcon className="ml-2" size="lg" color="green" icon={["fal", "check"]} />
                                                :
                                                <></>
                                            }
                                        </span>
                                    </div>

                                    <div className="sub-list-menu">
                                        <span className="list-menu-item bg-edit-transfer item-title">
                                            Conteúdo
                                        </span>
                                        <span className={management === 5 ? "list-menu-item bg-edit-transfer item-action active" : "list-menu-item bg-edit-transfer item-action success d-flex justify-content-between align-items-center"} onClick={() => handleNext('features')}>
                                            <p>Características</p>
                                            {management !== 5 ?
                                                <FontAwesomeIcon className="ml-2" size="lg" color="green" icon={["fal", "check"]} />
                                                :
                                                <></>
                                            }
                                        </span>
                                        <span className={management === 6 ? "list-menu-item bg-edit-transfer item-action active" : "list-menu-item bg-edit-transfer item-action success d-flex justify-content-between align-items-center"} onClick={() => handleNext('contact')}>
                                            <p>Intruções e Contato </p>
                                            {management !== 6 ?
                                                <FontAwesomeIcon className="ml-2" size="lg" color="green" icon={["fal", "check"]} />
                                                :
                                                <></>
                                            }
                                        </span>
                                    </div>

                                    <div className="sub-list-menu">
                                        <span className="list-menu-item bg-edit-transfer item-title">
                                            Configurações
                                        </span>
                                        <span className={management === 7 ? "list-menu-item bg-edit-transfer item-action active" : "list-menu-item bg-edit-transfer item-action success d-flex justify-content-between align-items-center"} onClick={() => handleNext('blackout')}>
                                            <p>Blackout</p>
                                            {management !== 7 ?
                                                <FontAwesomeIcon className="ml-2" size="lg" color="green" icon={["fal", "check"]} />
                                                :
                                                <></>
                                            }
                                        </span>
                                        <span className={management === 8 ? "list-menu-item bg-edit-transfer item-action active" : "list-menu-item bg-edit-transfer item-action success d-flex justify-content-between align-items-center"} onClick={() => handleNext('administrative')}>
                                            <p>Administrativo</p>
                                            {management !== 8 ?
                                                <FontAwesomeIcon className="ml-2" size="lg" color="green" icon={["fal", "check"]} />
                                                :
                                                <></>
                                            }
                                        </span>
                                    </div>


                                </div>
                            </div>

                        </Col>

                        <ProductTransfer receiveObject={receiveObject} setTransferObject={setTransferObject} transferObject={transferObject} handleNext={handleNext} management={management} isEdit={isEdit} />
                    </Row>

                </div>

                <Modal
                    className="modalAuth"
                    show={modalShow}
                    onHide={() => {
                        reload();
                        setModalLog(null);
                        setModalMessage("Carregando");
                        setModalShow(false);
                    }}
                    aria-labelledby="contained-modal-title-vcenter"
                >
                    <>
                        <Modal.Body
                            className="modal-body text-center sucess-pos d-flex justify-content-evenly"
                            style={{ height: "600px" }}
                        >
                            {modalLog === null ? (
                                <>
                                    <div className="loading-modal">
                                        <div className="load"></div>
                                    </div>
                                </>
                            ) : (
                                <div>
                                    {modalLog === 0 ? (
                                        <FontAwesomeIcon
                                            icon={["fal", "check-circle"]}
                                            size="5x"
                                            className="text-primary"
                                            style={{ fontSize: "7.5em" }}
                                        />
                                    ) : (
                                        <FontAwesomeIcon
                                            icon={["fal", "times-circle"]}
                                            size="5x"
                                            className="text-primary"
                                            style={{ fontSize: "7.5em" }}
                                        />
                                    )}
                                </div>
                            )}
                            <div>{modalMessage}</div>
                            <div className="d-flex justify-content-center pt-3">
                                <button
                                    onClick={() => {
                                        reload();
                                        setModalLog(null);
                                        setModalMessage("Carregando");
                                        setModalShow(false);
                                    }}
                                    className="btn btn-primary mx-2 w-25"
                                >
                                    Confirmar
                                </button>
                            </div>
                        </Modal.Body>
                    </>
                </Modal>

            </DesktopDefault>
        </>
    )
}

export default EditTransfer;