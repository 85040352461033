import React from 'react';

import Breadcrumb from '../../../components/Breadcrumb/Breadcrumb';

import AccountsForm from '../components/AccountsForm/AccountsForm';
import { useTranslation } from 'react-i18next';
import DesktopDefault from '../../../templates/DesktopDefault';


function AddAccounts() {
    const { t } = useTranslation();
    return (
        <>
            <DesktopDefault>
                <div className="container-fluid content-dashboard">

                    <Breadcrumb title={'Contas Correntes / ' + t('addAccounts.title')} />

                    <div className="register-users mt-4">
                        <h2 className="title">{t('addAccounts.title')}</h2>
                    </div>

                    <AccountsForm action={1} info={null} />

                </div>

            </DesktopDefault>
        </>

    );
}

export default AddAccounts;