import React, { Key, useState, useEffect } from "react";
import api from '../../../services/api';

import Table from "react-bootstrap/Table";
import Modal from "react-bootstrap/Modal";
import { Container, Row } from "react-bootstrap";

import "../../../assets/sass/modal.scss";

//import IconSucess from "../../../assets/icons/modal-sucess.svg";

import "./FormBillPayableLog.scss";
import { useTranslation } from "react-i18next";

export interface propModal {
  //action: any,
  contasaPagarId: any
};

const FormBillPayableLog: React.FC<propModal> = ({
  //action,
  contasaPagarId
}: propModal, props: any) => {
  const { t } = useTranslation();

  const [controlObject, setControlObject] = useState<any>([]);

  /* function formatValue(cell: any) {
    if(cell && cell != '') {
      return cell.toFixed(2).replace(".", ",");
    } else {
      return '0,00';
    }
    
  } */

  /*converte a data UTC para a data local do usuário*/
  function convertUTCDateToLocalDate(date: any) {
    var newDate = new Date(date.getTime() + date.getTimezoneOffset() * 60 * 1000);

    //var offset = date.getTimezoneOffset() / 60;
    //var hours = date.getHours();

    //newDate.setHours(hours - offset);

    return newDate.toLocaleString().split(' ')[0];
  }

  function convertDate(cell: any) {
    var date = (cell) ? convertUTCDateToLocalDate(new Date(cell)) : '';
    return (
      <>
        {date}
      </>
    );
  }

  useEffect(() => {
    const token = localStorage.getItem('GroupId') || '{}';
    const config = {
      headers: { 'Authorization': `Bearer ${token}` },
    };
    async function listAccountingAccounts() {
      try {
        const { data } = await api.post(`/BillsPayable/GetBillLogAsync`,
        { 
          contasaPagarId: contasaPagarId,
          page: 1,
          rowsPerPage: 0
        }, config);
        if (data.status !== 400) {
          const dados = data.data;

          setControlObject(dados.rows);
        }
      } catch (error: any) {
        if (error.response.status === 401) {
          window.location.href = window.location.origin + '/';
        }
      }
    }
    listAccountingAccounts();
  }, [contasaPagarId /*  */]);

  return (
    <>
      <Modal.Body>
        <Container className="bg-modal-contact p-0" fluid>
          <Row>
            <h5 className="contact-title mb-5">{t("billsToPay.table.paymentAndDebitNotes.changeLog")}</h5>
            <div className="table-default bill-pay-log-table">
              <div className="table-container">
                <Table striped bordered>
                  <thead>
                    <tr>
                      <th>{t("billsToPay.table.paymentAndDebitNotes.text")}</th>
                      <th>{t("billsToPay.table.paymentAndDebitNotes.user")}</th>
                      <th>{t("billsToPay.table.paymentAndDebitNotes.date")}</th>
                    </tr>
                  </thead>
                  <tbody>
                    {controlObject.length > 0
                      ? controlObject.map((info: any, index: Key) => (
                        <tr key={index}>
                          <td>{info.texto}</td>
                          <td>{info.usuario}</td>
                          <td>{convertDate(info.data)}</td>
                        </tr>
                      ))
                      : <tr style={{ border: "none" }}><td colSpan={6} className="text-center">{t("billsToPay.table.paymentAndDebitNotes.itemsNotFound")}</td></tr>}
                  </tbody>
                </Table>
              </div>
            </div>
          </Row>
        </Container>
      </Modal.Body>

      {/* <Modal.Footer>
        <Row className="buttons-modal d-flex justify-content-end mx-0">
          <Button
            type="button"
            className="form-button"
            onClick={onSubmitForm}
          >
            <FontAwesomeIcon
              icon={["fal", "save"]}
              size="1x"
              style={{ marginRight: "5px" }}
            /> Fechar 
          </Button>
        </Row>
      </Modal.Footer> */}


    </>
  );
}

export default FormBillPayableLog;
