import React, { Key, useEffect, useState } from "react";
import api from "../../../services/api";
import i18next from "i18next";

import { Container } from "react-bootstrap";

import InputRange from 'react-input-range';
import StarSolidYellow from "../../../assets/icons/star-solid-yellow.svg";
import StarSolidGray from "../../../assets/icons/star-solid-gray.svg";

import "./FilterTour.scss";
import 'react-input-range/lib/css/index.css';
import { useTranslation } from "react-i18next";

export interface propFilter {
  search: any;
  setTourList: any;
  value: any;
  setNoContent: any;
  categories: any;
  setTourListSize?: any;
  setSearchObject?: any;
  setPagination?: any;
  setActualProductType?: any;
  setActualCategories?: any;
}

const FilterTour: React.FC<propFilter> = ({
  search, setTourList, value, setNoContent, categories, setTourListSize, setSearchObject, setPagination, setActualCategories, setActualProductType
}: propFilter) => {

  const [arrTour, setArrTour] = useState<any>([]);
  const [arrStars, setArrStars] = useState<any>([]);
  const [arrTypes, setArrTypes] = useState<any>([]);

  const [tourFilterPriceRange, setTourFilterPriceRange] = useState<any>({ min: 0, max: 1000 });
  const [tourFilterType, setTourFilterType] = useState<any>([]);
  const [tourFilterCategorie, setTourFilterCategorie] = useState<any>([]);
  const [tourFilterStars, setTourFilterStars] = useState<any>([]);
  const [lng, setLng] = useState<string>("BR");
  //const [cats, setCats] = useState<any>({})

  const { t } = useTranslation();

  enum TourCategories {
    "CATVX8164" = `${t("sales.filterTour.categories.CATVX8164")}` as any,
    "CATBK0826" = `${t("sales.filterTour.categories.CATBK0826")}` as any,
    "CATGG6755" = `${t("sales.filterTour.categories.CATGG6755")}` as any,
    "CATES9338" = `${t("sales.filterTour.categories.CATES9338")}` as any,
    "CATUH7312" = `${t("sales.filterTour.categories.CATUH7312")}` as any,
    "CATLU5571" = `${t("sales.filterTour.categories.CATLU5571")}` as any,
    "CATQR9923" = `${t("sales.filterTour.categories.CATQR9923")}` as any,
    "CATBJ3647" = `${t("sales.filterTour.categories.CATBJ3647")}` as any,
    "CATPO0133" = `${t("sales.filterTour.categories.CATPO0133")}` as any,
    "CATFR0970" = `${t("sales.filterTour.categories.CATFR0970")}` as any,
  }

  useEffect(()=>{
    setActualProductType(tourFilterType.length > 1 || tourFilterType.length === 0 ? 0 : tourFilterType[0]);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[tourFilterType])

  useEffect(()=>{
    setActualCategories(tourFilterCategorie);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[tourFilterCategorie])

  useEffect(()=>{
    if(i18next.language === "pt-BR" || i18next.language === "pt"){
      setLng("BR")
    }else if(i18next.language === "en"){
      setLng("EN")
    }else{
      setLng("ES")
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[i18next.language]);


  useEffect(() => {
    async function filter() {
      try {
        const res = await api.post(
          `/Products/FetchByChannel`,
          {
            "page": 0,
            "rowsPerPage": 0,
            "term": search ? search : 'rio de janeiro',
            "channel": localStorage.getItem('idCanal') || 1,
            "lang": lng,
            "ProductType": tourFilterType.length > 1 || tourFilterType.length === 0 ? 0 : tourFilterType[0],
            "categories": tourFilterCategorie,
            "stars": tourFilterStars !== null ? tourFilterStars : [],
            "priceStart": tourFilterPriceRange.min !== 0 ? tourFilterPriceRange.min : 1,
            "priceEnd": tourFilterPriceRange.max
          }
        );
        if (res.status !== 400) {
          if (res?.data?.statusCode === 204) {  ///Tratando o caso de "Nenhum resultado encontrado" na pesquisa
            setNoContent(true);
            setTourList(null);
          } else {
            if (res.data.data !== null) {
              setTourList(res.data.data.rows.slice(0, 5));
              setTourListSize(res.data.data.rows.length);
              setSearchObject({
                "page": 0,
                "rowsPerPage": 0,
                "term": search ? search : 'rio de janeiro',
                "channel": 1,
                "lang": lng,
                "ProductType": tourFilterType.length > 1 || tourFilterType.length === 0 ? 0 : tourFilterType[0],
                "categories": tourFilterCategorie,
                "stars": tourFilterStars !== null ? tourFilterStars : [],
                "priceStart": tourFilterPriceRange.min !== 0 ? tourFilterPriceRange.min : 1,
                "priceEnd": tourFilterPriceRange.max
              });
              setPagination(1);


              //setValue({ min: Math.floor(res.data.data.minPrice), max: Math.ceil(res.data.data.maxPrice) });
              setNoContent(false);
            } else {
              setTourList(null);
              setNoContent(false);
            }
          }
        }
      } catch (error) { }
    }
    filter();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [search, tourFilterCategorie, tourFilterType, tourFilterStars, lng])

  function handleRangeChange(e: any) {
    //setValue(e);

    setTourFilterPriceRange(e);
  }

  /* Botão Filtrar Preço */
  async function handlePrice() {
    //const lng = i18next.language === "pt" ? "BR" : i18next.language === "en" ? "EN" : i18next.language === "es" ? "ES" : 'BR';

    try {
      const price = await api.post(
        `${process.env.REACT_APP_SERVER_URL_API}/Products/FetchByChannel`,
        {
          "page": 0,
          "rowsPerPage": 0,
          "term": search ? search : 'rio de janeiro',
          "channel": localStorage.getItem('idCanal') || 1,
          "lang": lng,
          "ProductType": tourFilterType.length > 1 || tourFilterType.length === 0 ? 0 : tourFilterType[0],
          "categories": tourFilterCategorie,
          "stars": tourFilterStars !== null ? tourFilterStars : [],
          "priceStart": tourFilterPriceRange.min !== 0 ? tourFilterPriceRange.min : 1,
          "priceEnd": tourFilterPriceRange.max
        }
      );
      if (price.status !== 400) {
        if (price.data.statusCode === 204) {  ///Tratando o caso de "Nenhum resultado encontrado" na pesquisa
          setNoContent(true);
          setTourList(null);
        } else {
          if (price.data.data !== null) {
            setTourList(price.data.data.rows.slice(0, 5));
            setTourListSize(price.data.data.rows.length);
            setPagination(1);

            setSearchObject({
              "page": 0,
              "rowsPerPage": 0,
              "term": search ? search : 'rio de janeiro',
              "channel": 1,
              "lang": lng,
              "ProductType": tourFilterType.length > 1 || tourFilterType.length === 0 ? 0 : tourFilterType[0],
              "categories": tourFilterCategorie,
              "stars": tourFilterStars !== null ? tourFilterStars : [],
              "priceStart": tourFilterPriceRange.min !== 0 ? tourFilterPriceRange.min : 1,
              "priceEnd": tourFilterPriceRange.max
            });

            //setValue({ min: Math.floor(res.data.data.minPrice), max: Math.ceil(res.data.data.maxPrice) });
            setNoContent(false);
          } else {
            setTourList(null);
            setNoContent(false);
          }
        }
      }
    } catch (error) { }
  }
  /* END - Botão Filtrar Preço */

  function handleClickType(event: any, filterValue: any = null) {
    var value: any = event.target.value;

    if (filterValue !== null) { /// somente quando o click é feito no "x" da lista de filtros selecionados
      value = filterValue;
    }

    let types = [];

    if (arrTypes.includes(value) === true) {
      for (let i = 0; i < arrTypes.length; i++) {
        if (arrTypes[i] !== value) {
          types.push(arrTypes[i]);
        }
      }

      setTourFilterType(types);
      setArrTypes(types);
    } else {
      setTourFilterType([...arrTypes, value]);
      setArrTypes([...arrTypes, value]);
    }
  }

  function handleClickTour(event: any, filterValue: any = null) {
    var value: any = event.target.value;

    if (filterValue !== null) { /// somente quando o click é feito no "x" da lista de filtros selecionados
      value = filterValue;
    }

    let tour: any = [];

    if (arrTour.includes(value) === true) {                    ///remove da lista caso ja exista
      for (let i = 0; i < arrTour.length; i++) {
        if (arrTour[i] !== value) {
          tour.push(arrTour[i]);
        }
      }

      setTourFilterCategorie(tour);
      setArrTour(tour);
    } else {                                                   ///adiciona na lista
      setTourFilterCategorie([...arrTour, value]);
      setArrTour([...arrTour, value]);
    }
  }

  function handleClickStars(event: any, filterValue: any = null) {
    var value: any = event.target.value;

    if (filterValue !== null) { /// somente quando o click é feito no "x" da lista de filtros selecionados
      value = filterValue;
    }

    let stars = [];

    if (arrStars.includes(value) === true) {
      for (let i = 0; i < arrStars.length; i++) {
        if (arrStars[i] !== value) {
          stars.push(arrStars[i]);
        }
      }

      setTourFilterStars(stars);
      setArrStars(stars);
    } else {
      setTourFilterStars([...arrStars, value]);
      setArrStars([...arrStars, value]);
    }
  }

  function clearFilters() {
    setTourFilterType([]);
    setTourFilterCategorie([]);
    setTourFilterStars([]);
    setArrTour([]);
    setArrStars([]);

    setSearchObject({
      "page": 0,
      "rowsPerPage": 0,
      "term": "rio de janeiro",
      "channel": 3,
      "lang": "BR",
      "ProductType": 0,
      "categories": [
      ],
      "stars": [
      ],
      "priceStart": 0,
      "priceEnd": 0
    });

    setPagination(1);


  }

  //let auxValue: any = [];

  useEffect(() => {
    setTourFilterPriceRange(value)
  }, [value])

  return (
    <>
      <Container className="pt-1 p-0" fluid>
        <div className="mt-4 mb-5">
          <h5
            className="mb-4 title-filter"
          >
            {t("sales.filterTour.selectedFilters")}
          </h5>

          {(tourFilterType.length > 0)
            ? tourFilterType.map((filter: any, index: Key) => (
              <div className="d-flex justify-content-between">
                <p style={{ color: '#707070' }}>{filter === "1" ? "Tour" : filter === "4" ? "Tickets" : ''}</p>
                <p onClick={(e) => handleClickType(e, filter)} style={{ cursor: "pointer", color: '#707070' }}>×</p>
              </div>
            ))
            : ''
          }

          {tourFilterCategorie.length > 0
            ? tourFilterCategorie.map((filter: any, index: Key) => (
              <div key={index} className="d-flex justify-content-between">
                <p style={{ color: '#707070' }}>{TourCategories[filter]}</p>
                <p onClick={(e) => handleClickTour(e, filter)} style={{ cursor: "pointer", color: '#707070' }}>×</p>
              </div>
            ))
            : ''
          }

          {tourFilterStars.length > 0
            ? tourFilterStars.map((filter: any, index: Key) => (
              <div key={index} className="d-flex justify-content-between">
                <p style={{ color: '#707070' }}>{filter} {t("sales.filterTour.stars")}</p>
                <p onClick={(e) => handleClickStars(e, filter)} style={{ cursor: "pointer", color: '#707070' }}>×</p>
              </div>
            ))
            : ''
          }

          {tourFilterCategorie.length > 0 || tourFilterStars.length > 0 || tourFilterType.length > 0
            ? <span className="clear-filter" style={{ color: '#707070' }} onClick={clearFilters}>{t("sales.filterTour.clearFilters")}</span>
            : ''
          }


        </div>
        <div className="mt-4 mb-5">
          <h5
            className="mb-4 title-filter"
          >
            {t("sales.filterTour.prices")}
          </h5>
          <InputRange
            maxValue={value.max}
            minValue={value.min}
            value={tourFilterPriceRange}
            step={1}
            onChange={handleRangeChange}
            formatLabel={value => `R$${value}`}
          />
          <div style={{ marginTop: "30px" }}>
            <button onClick={handlePrice} className="btn btn-primary" style={{ borderRadius: "0", width: "100%" }}>{t("sales.filterTour.filter")}</button>
          </div>
        </div>
        <div className="mb-5">
          <h5
            className="mb-4 title-filter"
          >
            {t("sales.filterTour.activitiesTypes")}
          </h5>
          <div className="d-flex flex-column">
            <div className="check-cat">
              <input
                checked={tourFilterType.includes("1")}
                type="checkbox"
                name="type1"
                id="type-1"
                value={1}
                onChange={handleClickType}
                autoComplete='off'
              />
              <label htmlFor="type-1">{t("sales.filterTour.tour")}</label>
            </div>
            <div className="check-cat">
              <input
                checked={tourFilterType.includes("4")}
                type="checkbox"
                name="type2"
                id="type-2"
                value={4}
                onChange={handleClickType}
                autoComplete='off'
              />
              <label htmlFor="type-2">{t("sales.filterTour.tickets")}</label>
            </div>
          </div>
        </div>
        <div className="mb-5">
          <h5
            className="mb-4 title-filter"
          >
            {t("sales.filterTour.tours")}
          </h5>
          <div className="d-flex flex-column">
            {(categories !== null && categories !== undefined && categories.length > 0)
              ? categories.map((cat: any, index: Key) => (
                <div className="check-cat" key={index}>
                  <input
                    checked={tourFilterCategorie.includes(cat.categorieCode)}
                    type="checkbox"
                    name={cat.categorieName}
                    id={cat.categorieName}
                    value={cat.categorieCode}
                    onChange={handleClickTour}
                    autoComplete='off'
                  />
                  <label htmlFor={cat.categorieName}>{cat.categorieName}</label>
                </div>
              ))
              : ''
            }
            {/* <div className="check-cat">
              <input
                checked={tourFilterCategorie.includes("CATBK0826")}
                type="checkbox"
                name="Arte"
                id="cat-1"
                value="CATBK0826"
                onChange={handleClickTour}
                autoComplete='off'
              />
              <label htmlFor="cat-1">Arte, Design e Moda</label>
            </div>
            <div className="check-cat">
              <input
                checked={tourFilterCategorie.includes("CATLU5571")}
                type="checkbox"
                name="História"
                id="cat-2"
                value="CATLU5571"
                onChange={handleClickTour}
                autoComplete='off'
              />
              <label htmlFor="cat-2">História e Cultura</label>
            </div>
            <div className="check-cat">
              <input
                checked={tourFilterCategorie.includes("CATUH7312")}
                type="checkbox"
                name="Estilo de Vida e Celebrações"
                id="cat-3"
                value="CATUH7312"
                onChange={handleClickTour}
                autoComplete='off'
              />
              <label htmlFor="cat-3">Estilo de Vida e Celebrações</label>
            </div>
            <div className="check-cat">
              <input
                checked={tourFilterCategorie.includes("CATQR9923")}
autoComplete='off'
                type="checkbox"
                name="Natureza e Impacto Social"
                id="cat-4"
                value="CATQR9923"
                onChange={handleClickTour}
              />
              <label htmlFor="cat-4">Natureza e Impacto Social</label>
            </div>
            <div className="check-cat">
              <input
                checked={tourFilterCategorie.includes("CATFR0970")}
                type="checkbox"
                name="Viagem"
                id="cat-5"
                value="CATFR0970"
                onChange={handleClickTour}
                autoComplete='off'
              />
              <label htmlFor="cat-5">Viagem</label>
            </div>
            <div className="check-cat">
              <input
                checked={tourFilterCategorie.includes("CATVX8164")}
                type="checkbox"
                name="Ar Livre e Aventura"
                id="cat-6"
                value="CATVX8164"
                autoComplete='off'
                onChange={handleClickTour}
              />
              <label htmlFor="cat-6">Ar Livre e Aventura</label>
            </div> */}
          </div>
          <div className="mt-4 mb-5">
            <h5
              className="mb-4 title-filter"
            >
              {t("sales.filterTour.rating")}
            </h5>
            <div className="d-flex flex-column">
              <div className="check-cat">
                <input
                  checked={tourFilterStars.includes("5")}
                  onChange={handleClickStars}
                  type="checkbox"
                  name="aval-1"
                  id="aval-1"
                  autoComplete='off'
                  value={5}
                /* onClick={handleClickStars} */
                />
                <label htmlFor="aval-1">
                  <img src={StarSolidYellow} alt="" />
                  <img src={StarSolidYellow} alt="" />
                  <img src={StarSolidYellow} alt="" />
                  <img src={StarSolidYellow} alt="" />
                  <img src={StarSolidYellow} alt="" />
                </label>
              </div>
              <div className="check-cat">
                <input
                  checked={tourFilterStars.includes("4")}
                  type="checkbox"
                  name="aval-2"
                  id="aval-2"
                  value={4}
                  autoComplete='off'
                  onChange={handleClickStars}
                />
                <label htmlFor="aval-2">
                  <img src={StarSolidYellow} alt="" />
                  <img src={StarSolidYellow} alt="" />
                  <img src={StarSolidYellow} alt="" />
                  <img src={StarSolidYellow} alt="" />
                  <img src={StarSolidGray} alt="" />
                </label>
              </div>
              <div className="check-cat">
                <input
                  checked={tourFilterStars.includes("3")}
                  type="checkbox"
                  name="aval-3"
                  id="aval-3"
                  value={3}
                  autoComplete='off'
                  onChange={handleClickStars}
                />
                <label htmlFor="aval-3">
                  <img src={StarSolidYellow} alt="" />
                  <img src={StarSolidYellow} alt="" />
                  <img src={StarSolidYellow} alt="" />
                  <img src={StarSolidGray} alt="" />
                  <img src={StarSolidGray} alt="" />
                </label>
              </div>
              <div className="check-cat">
                <input
                  checked={tourFilterStars.includes("2")}
                  type="checkbox"
                  name="aval-4"
                  id="aval-4"
                  value={2}
                  autoComplete='off'
                  onChange={handleClickStars}
                />
                <label htmlFor="aval-4">
                  <img src={StarSolidYellow} alt="" />
                  <img src={StarSolidYellow} alt="" />
                  <img src={StarSolidGray} alt="" />
                  <img src={StarSolidGray} alt="" />
                  <img src={StarSolidGray} alt="" />
                </label>
              </div>
              <div className="check-cat">
                <input
                  checked={tourFilterStars.includes("1")}
                  type="checkbox"
                  name="aval-5"
                  id="aval-5"
                  value={1}
                  autoComplete='off'
                  onChange={handleClickStars}
                />
                <label htmlFor="aval-5">
                  <img src={StarSolidYellow} alt="" />
                  <img src={StarSolidGray} alt="" />
                  <img src={StarSolidGray} alt="" />
                  <img src={StarSolidGray} alt="" />
                  <img src={StarSolidGray} alt="" />
                </label>
              </div>
            </div>
          </div>
        </div>
      </Container>
    </>
  );
}

export default FilterTour;