import React, { useEffect, useState } from "react";

import "./DataTransfer.scss"
import { Button, Col, Form, Row } from "react-bootstrap";
import { Controller, useForm } from "react-hook-form";
import { ErrorMessage } from "@hookform/error-message";
import AutoComplete from "react-google-autocomplete";
import { geocodeByAddress, getLatLng } from 'react-google-places-autocomplete';
import GetTransfersPickup from "../../../../components/C2Points/GetTranfersPickup";
import GetTransfersZones from "../../../../components/C2Points/GetTranfersZones";

export interface productTransfer {
    action: any,
    receiveObject: any,
    transferObject: any,
    isEdit: any
};

const DataTransfer: React.FC<productTransfer> = React.memo(({ action, receiveObject, transferObject, isEdit
}: productTransfer) => {

    
    const {
        control,
        handleSubmit,
        setValue,
        watch,
        formState: { errors },
    } = useForm({

    });

    //console.log(transferObject);

    const [service, setService] = useState(transferObject.sellingType === undefined ? 1 : transferObject.sellingType);

    const getChoiceService = (num: number) => {
        setService(num)
    }

    const [serviceOrigin, setServiceOrigin] = useState<any>(transferObject.serviceOrigin === undefined ? 3 : transferObject.serviceOrigin);
    const [serviceDest, setServiceDest] = useState<any>(transferObject.serviceDest === undefined ? 3 : transferObject.serviceDest);
    const [originName, setOriginName] = useState<any>();
    const [originLat, setOriginLat] = useState<any>();
    const [originLong, setOriginLong] = useState<any>();
    const [destinyName, setDestinyName] = useState<any>();
    const [destinyLat, setDestinyLat] = useState<any>();
    const [destinyLong, setDestinyLong] = useState<any>();

    const [textError, setTextError] = useState<any>('');

    const getChoiceServiceOrigin = (num: number) => {
        setServiceOrigin(num);
        setValue("desdeCompartilhado", "");
    }

    useEffect(()=> {
        setValue("desdeCompartilhado", transferObject.desdeCompartilhado);        
        setValue("ateCompartilhado", transferObject.ateCompartilhado);
    },[transferObject])

    

    const getChoiceServiceDest = (num: number) => {
        setServiceDest(num);
        setValue("ateCompartilhado", "");
    }

    useEffect(() => {
        if (service === 2 && serviceOrigin === 2) {
            setServiceOrigin(3);
        }

        if (service === 2 && serviceDest === 2) {
            setServiceDest(3);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [service]);

    
    const onSubmit = (data: any) => {

        if ((originName !== undefined && destinyName !== undefined) || (transferObject.origem !== undefined && transferObject.destino !== undefined)) {
            data.origem = originName !== undefined ? originName : transferObject.origem;
            data.origemLat = originLat?.toString() !== undefined ? originLat.toString() : transferObject.origemLat;
            data.origemLong = originLong?.toString() !== undefined ? originLong.toString() : transferObject.origemLong;
            data.destino = destinyName !== undefined ? destinyName : transferObject.destino;
            data.destinoLat = destinyLat?.toString() !== undefined ? destinyLat.toString() : transferObject.destinoLat;
            data.destinoLong = destinyLong?.toString() !== undefined ? destinyLong.toString() : transferObject.destinoLong;
            data.sellingType = service;
            data.idZonaAte = serviceDest === 1 || serviceDest === 2 ? JSON.parse(data.ateCompartilhado).id : 0;
            data.idZonaDesde = serviceOrigin === 1 || serviceOrigin === 2 ? JSON.parse(data.desdeCompartilhado).id : 0;
            data.ateCompartilhado = serviceDest === 1 || serviceDest === 2 ? JSON.parse(data.ateCompartilhado).description : data.ateCompartilhado;
            data.desdeCompartilhado = serviceOrigin === 1 || serviceOrigin === 2 ? JSON.parse(data.desdeCompartilhado).description : data.desdeCompartilhado;
            data.direcao = `De ${data.desdeCompartilhado} até ${data.ateCompartilhado}`;


            data.serviceOrigin = isEdit !== true ? serviceOrigin : undefined;
            data.serviceDest = isEdit !== true ? serviceDest : undefined;

            if (isEdit !== true) {
                action("meetingPoint", data);
            } else {
                receiveObject(data);
            }

        } else {

            setTextError("Informe a Origem e Destino");

            setTimeout(() => {
                setTextError('');
            }, 2000);
        }
    }


    return (
        <>
            {/* CONTENT */}
            <Col md={9} lg={10}>
                <Form className="h-100" noValidate validated={false} onSubmit={handleSubmit(onSubmit)}>
                    <div className='card bg-data-transfer h-100 border-0 p-4'>
                        <div>
                            <h3 className="pt-1 text-primary title-content">{"Dados do Translado"}</h3>
                        </div>

                        <Row className="mt-3 mb-3">
                            <div className="label-small">
                                Tipo de Serviço
                            </div>

                            <label htmlFor={"radio-2"} className={service === 1 ? "col-md-2 radioPersonal my-3 mx-3 p-3 active" : "col-md-2 radioPersonal my-3 mx-3 p-3"}>
                                <Form.Check
                                    type="radio"
                                    name="service"
                                    id={"radio-2"}
                                    value="regular"
                                    required
                                    defaultChecked={service === 1 ? true : false}
                                    label={"Regular"}
                                    onClick={() => getChoiceService(1)}
                                />
                                <small>{"Pequena explicação do regular"}</small>
                            </label>


                            <label htmlFor={"radio"} className={service === 2 ? "col-md-2 radioPersonal my-3 mx-3 p-3 active" : "col-md-2 radioPersonal my-3 mx-3 p-3"}>
                                <Form.Check
                                    type="radio"
                                    name="service"
                                    id={"radio"}
                                    value="privativo"
                                    required
                                    defaultChecked={service === 2 ? true : false}
                                    label={"Privativo"}
                                    onClick={() => getChoiceService(2)}
                                />
                                <small>{"Pequena explicação do privativo"}</small>
                            </label>
                        </Row>

                        <section>
                            <Row className="line-chooice">
                                <div className="d-flex w-auto">
                                    <Controller
                                        control={control}
                                        name="origem"
                                        render={({ field }: any) => (
                                            <Form.Check
                                                {...field}
                                                defaultChecked={serviceOrigin === 1 ? true : false}
                                                type="radio"
                                                variant="standard"
                                                margin="normal"
                                                required
                                                checked={serviceOrigin === 1}
                                                className="mx-2"
                                                onClick={() => getChoiceServiceOrigin(1)}
                                            />
                                        )}
                                    />
                                    <Form.Label className="label-small">
                                        Lista de Pickups
                                    </Form.Label>
                                </div>
                                <div className="d-flex w-auto">
                                    <Controller
                                        control={control}
                                        name="origem"
                                        render={({ field }: any) => (
                                            <Form.Check
                                                {...field}
                                                defaultChecked={serviceOrigin === 2 ? true : false}
                                                type="radio"
                                                variant="standard"
                                                margin="normal"
                                                required
                                                checked={serviceOrigin === 2}
                                                className="mx-2"
                                                onClick={() => getChoiceServiceOrigin(2)}
                                            />
                                        )}
                                    />
                                    <Form.Label className="label-small">
                                        Por Zonas
                                    </Form.Label>
                                </div>
                                <div className="d-flex w-auto">
                                    <Controller
                                        control={control}
                                        name="origem"
                                        render={({ field }: any) => (
                                            <Form.Check
                                                {...field}
                                                defaultChecked={serviceOrigin === 3 ? true : false}
                                                type="radio"
                                                variant="standard"
                                                margin="normal"
                                                required
                                                checked={serviceOrigin === 3}
                                                className="mx-2"
                                                onClick={() => getChoiceServiceOrigin(3)}
                                            />
                                        )}
                                    />
                                    <Form.Label className="label-small">
                                        Endereço
                                    </Form.Label>
                                </div>
                            </Row>

                            <Row className="mb-3 mt-3">
                                <Form.Group as={Col} md="6" controlId="">
                                    {serviceOrigin === 1 ?
                                        <Form.Label className="label-small">
                                            Cidade
                                        </Form.Label>
                                        :
                                        <Form.Label className="label-small">
                                            Origem
                                        </Form.Label>
                                    }
                                    <Controller
                                        control={control}
                                        name="origem"
                                        //rules={{ required: { value: true, message: 'Por favor, preencha o campo' } }}
                                        render={({ field }: any) => (
                                            <AutoComplete
                                                options={{
                                                    types: ["(regions)"],
                                                    fields: ["name"]
                                                }}
                                                //inputAutocompleteValue={pickupAddresses[elem.id]}
                                                defaultValue={transferObject.origem}
                                                placeholder=""
                                                apiKey="AIzaSyBv2aZ2YO_aW4PIEmXoxHgxC8Ps8DB0o-s"
                                                onPlaceSelected={(place) => {                                                    
                                                    setOriginName(place.name);
                                                    setValue("origem", place.name)
                                                    /* handleChangePickupAddresses(place.name, elem.id) */
                                                    // eslint-disable-next-line no-lone-blocks
                                                    {
                                                        geocodeByAddress(`${place.name}`)
                                                            .then(results => getLatLng(results[0]))
                                                            .then(({ lat, lng }) => {
                                                                setOriginLat(lat);
                                                                setOriginLong(lng);
                                                            }
                                                            );
                                                    }
                                                }}
                                            />
                                        )}
                                    />
                                    <ErrorMessage
                                        errors={errors}
                                        name="origem"
                                        render={({ message }) => <small style={{ color: "red" }}>{message}</small>}
                                    />

                                </Form.Group>

                                <Form.Group as={Col} md="6" controlId="">
                                    <Form.Label className="label-small">
                                        Desde
                                    </Form.Label>
                                    <Controller
                                        control={control}
                                        name="desdeCompartilhado"
                                        //defaultValue={transferObject.desdeCompartilhado}
                                        rules={{ required: { value: true, message: 'Por favor, preencha o campo' } }}
                                        render={({ field }: any) => (
                                            serviceOrigin === 1 ?

                                                <div className="input-select-custom">
                                                    <GetTransfersPickup
                                                        propsField={field}
                                                        propsLabel={"label"}
                                                        propsErrors={errors} />
                                                </div>
                                                :

                                                serviceOrigin === 2 ?
                                                    <div className="input-select-custom">
                                                        <GetTransfersZones
                                                            propsField={field}
                                                            propsLabel={"label"}
                                                            propsErrors={errors} />
                                                    </div>
                                                    :

                                                    <Form.Control
                                                        {...field}
                                                        variant="standard"
                                                        margin="normal"
                                                        required
                                                        autoComplete='off'
                                                        aria-invalid={errors?.desdeCompartilhado ? "true" : ""}
                                                    />
                                        )}
                                    />
                                    <ErrorMessage
                                        errors={errors}
                                        name="desdeCompartilhado"
                                        render={({ message }) => <small style={{ color: "red" }}>{message}</small>}
                                    />
                                </Form.Group>
                            </Row>

                        </section>

                        <section className="mt-5">
                            <Row className="line-chooice">
                                <div className="d-flex w-auto">
                                    <Controller
                                        control={control}
                                        name="destino"
                                        render={({ field }: any) => (
                                            <Form.Check
                                                {...field}
                                                defaultChecked={serviceDest === 1 ? true : false}
                                                type="radio"
                                                variant="standard"
                                                margin="normal"
                                                required
                                                checked={serviceDest === 1}
                                                className="mx-2"
                                                onClick={() => getChoiceServiceDest(1)}
                                            />
                                        )}
                                    />
                                    <Form.Label className="label-small">
                                        Lista de Pickups
                                    </Form.Label>
                                </div>
                                <div className="d-flex w-auto">
                                    <Controller
                                        control={control}
                                        name="destino"
                                        render={({ field }: any) => (
                                            <Form.Check
                                                {...field}
                                                defaultChecked={serviceDest === 2 ? true : false}
                                                type="radio"
                                                variant="standard"
                                                margin="normal"
                                                required
                                                checked={serviceDest === 2}
                                                className="mx-2"
                                                onClick={() => getChoiceServiceDest(2)}
                                            />
                                        )}
                                    />
                                    <Form.Label className="label-small">
                                        Por Zonas
                                    </Form.Label>
                                </div>
                                <div className="d-flex w-auto">
                                    <Controller
                                        control={control}
                                        name="destino"
                                        render={({ field }: any) => (
                                            <Form.Check
                                                {...field}
                                                defaultChecked={serviceDest === 3 ? true : false}
                                                type="radio"
                                                variant="standard"
                                                margin="normal"
                                                required
                                                checked={serviceDest === 3}
                                                className="mx-2"
                                                onClick={() => getChoiceServiceDest(3)}
                                            />
                                        )}
                                    />
                                    <Form.Label className="label-small">
                                        Endereço
                                    </Form.Label>
                                </div>
                            </Row>
                            <Row className="mb-3 mt-3">
                                <Form.Group as={Col} md="6" controlId="">
                                    {serviceDest === 1 ?
                                        <Form.Label className="label-small">
                                            Cidade
                                        </Form.Label>
                                        :
                                        <Form.Label className="label-small">
                                            Destino
                                        </Form.Label>
                                    }

                                    <Controller
                                        control={control}
                                        name="destino"
                                        //rules={{ required: { value: true, message: 'Por favor, preencha o campo' } }}
                                        render={({ field }: any) => (
                                            <AutoComplete
                                                options={{
                                                    types: ["(regions)"],
                                                    fields: ["name"]
                                                }}
                                                //inputAutocompleteValue={pickupAddresses[elem.id]}
                                                defaultValue={transferObject.destino}
                                                placeholder=""
                                                apiKey="AIzaSyBv2aZ2YO_aW4PIEmXoxHgxC8Ps8DB0o-s"
                                                onPlaceSelected={(place) => {                                                    
                                                    setDestinyName(place.name);
                                                    setValue("destino", place.name)
                                                    /* handleChangePickupAddresses(place.name, elem.id) */
                                                    // eslint-disable-next-line no-lone-blocks
                                                    {
                                                        geocodeByAddress(`${place.name}`)
                                                            .then(results => getLatLng(results[0]))
                                                            .then(({ lat, lng }) => {
                                                                setDestinyLat(lat);
                                                                setDestinyLong(lng);
                                                            }
                                                            );
                                                    }
                                                }}
                                            />
                                        )}
                                    />
                                    <ErrorMessage
                                        errors={errors}
                                        name="destino"
                                        render={({ message }) => <small style={{ color: "red" }}>{message}</small>}
                                    />
                                </Form.Group>

                                <Form.Group as={Col} md="6" controlId="">
                                    <Form.Label className="label-small">
                                        Até
                                    </Form.Label>
                                    <Controller
                                        control={control}
                                        name="ateCompartilhado"
                                        //defaultValue={transferObject.ateCompartilhado}
                                        rules={{ required: { value: true, message: 'Por favor, preencha o campo' } }}
                                        render={({ field }: any) => (
                                            serviceDest === 1 ?
                                                <div className="input-select-custom">
                                                    <GetTransfersPickup
                                                        propsField={field}
                                                        propsLabel={"label"}
                                                        propsErrors={errors} />
                                                </div>
                                                :

                                                serviceDest === 2 ?
                                                    <div className="input-select-custom">
                                                        <GetTransfersZones
                                                            propsField={field}
                                                            propsLabel={"label"}
                                                            propsErrors={errors} />
                                                    </div>
                                                    :

                                                    <Form.Control
                                                        {...field}
                                                        variant="standard"
                                                        margin="normal"
                                                        required
                                                        autoComplete='off'
                                                        aria-invalid={errors?.ateCompartilhado ? "true" : ""}

                                                    />
                                        )}
                                    />
                                    <ErrorMessage
                                        errors={errors}
                                        name="AteCompartilhado"
                                        render={({ message }) => <small style={{ color: "red" }}>{message}</small>}
                                    />
                                </Form.Group>

                                <div className="d-flex mt-2">
                                    <small style={{ color: "red" }}>{textError}</small>
                                </div>

                            </Row>
                        </section>

                        <section className="h-100 d-flex justify-content-end align-items-end">
                            {
                                isEdit !== true
                                    ?
                                    <Row className=" mx-0 mt-5">
                                        <Button
                                            type="submit"
                                            className="form-button"
                                        //onClick={() => action('meetingPoint')}
                                        >
                                            Continuar
                                        </Button>
                                    </Row>
                                    :
                                    <Row className=" mx-0 mt-5">
                                        <Button
                                            type="submit"
                                            className="form-button"
                                            //onClick={() => action('meetingPoint')}
                                        >
                                            Salvar
                                        </Button>
                                    </Row>
                            }
                        </section>
                    </div>
                </Form>
            </Col>

        </>
    )
});

export default DataTransfer;