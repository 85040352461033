import React, { useEffect, useState } from 'react';
import api from '../../services/api';

import Select from 'react-select';
import { useTranslation } from 'react-i18next';

export interface propPlus {
  propsField: any,
  propsLabel: any,
  propsErrors: any,
  setAccount?: any
};

const GetAccounts: React.FC<propPlus> = ({
  propsField, propsLabel, propsErrors, setAccount
}: propPlus) => {
  const { t } = useTranslation();

  const [info, setInfo] = useState<any>(null);
  const [isMenuOpen, setIsMenuOpen] = useState<boolean>(false);

  const onMenuOpen = () => {
    if (isMenuOpen === false) {
      setIsMenuOpen(true);
    } else {
      setIsMenuOpen(false);
    }
  }

  const handleChangeMethod = (data: any) => {
    if(setAccount){
      setAccount(data.id);
    }

    propsField.onChange(data);
  }

  useEffect(() => {
    const token = localStorage.getItem('GroupId') || '{}';
    const config = {
      headers: { 'Authorization': `Bearer ${token}` },
    };
    async function listSuppliers() {
      try {
        const { data } = await api.get(`/BillsPayable/GetAccounts`, config);
        if (data.status !== 400) {
            setInfo(data.data)
        }
      } catch (error: any) {
        if (error.response.status === 401) {
          window.location.href = window.location.origin + '/';
        }
      }
    }
    listSuppliers();
  }, []);

  if (info !== null) {
    return (
      <>
        <div className={propsErrors[propsField.name] !== undefined ? "endpoint-error input-select-custom w-100" : "endpoint input-select-custom w-100"}>
          <Select
            aria-labelledby="aria-label"
            inputId="aria-example-input"
            name="aria-live-color"
            onMenuOpen={onMenuOpen}
            onMenuClose={onMenuOpen}
            {...propsField}
            aria-invalid={propsErrors[propsField.name] ? "true" : ""}
            label={propsLabel}
            as="select"
            variant="standard"
            margin="normal"
            placeholder={t("billsToPay.table.paymentAndDebitNotes.select")}
            options={Array.from(info)}
            getOptionValue={(option: any) => `${option.id}`}
            getOptionLabel={(option: any) => `${option.descricao}`}
            value={info.find((a:any) => a.id === propsField.value)}
            onChange={handleChangeMethod}
          />
        </div>
      </>
    );
  } else {
    return <>

    </>
  }
}

export default GetAccounts;