/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useEffect, useState } from "react";
import api from "../../../../services/api";
import { Button } from "react-bootstrap";
import Breadcrumb from "../../../../components/Breadcrumb/Breadcrumb";
import DesktopDefault from "../../../../templates/DesktopDefault";
import ProductFilter from "../ProductFilter/ProductFilter";
import SingleProduct from "../SingleProduct/SingleProduct";
import { Link } from "react-router-dom";

export interface propFilter { }

const ProductList: React.FC<propFilter> = () => {
  const [tourList, setTourList] = useState(null);

  const [imagesBaseUrl, setImagesBaseUrl] = useState<string>("");
  const [actualCodProd, setActualCodProd] = useState<any>("");
  const [actualCity, setActualCity] = useState<any>("");
  const [actualSupplier, setActualSupplier] = useState<any>(0);
  const [actualCadProd, setActualCadProd] = useState<any>(0);
  const [actualStatProd, setActualStatProd] = useState<any>(0);
  const [actualTypeProd, setActualTypeProd] = useState<any>(0);

  /* Pagination */
  const [totalRows, setTotalRows] = useState<any>();
  const [pageCount, setPageCount] = useState<any>(1);
  const [rowsPerPage, setRowsPerPage] = useState<any>(10);
  /* END - Pagination */

  function clearActualValues() {
    setActualCodProd("");
    setActualCity("");
    setActualSupplier(0);
    setActualCadProd(0);
    setActualStatProd(0);
    setActualTypeProd(0);
  }

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pageCount])

  useEffect(() => {
    async function filter() {
      try {
        const res = await api.post(`/Products/GetTourTicketProductAsync`,
          {
            "page": pageCount,
            "rowsPerPage": rowsPerPage,
            "codProd": actualCodProd,
            "cadProd": actualCadProd,
            "statProd": actualCadProd,
            "typeProd": actualTypeProd,
            "nomeCidade": actualCity,
            "fornecedor": actualSupplier,
            "idioma": 1
          }
        );
        if (res.status !== 400) {
          setImagesBaseUrl(res.data.data.imagesBaseUrl);
          setTourList(res.data.data.rows);
          setTotalRows(res.data.data.rowsCount);
        }

      } catch (error) { }
    }
    filter();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pageCount, rowsPerPage]);


  async function buscar(codProd: any, city: any, supplier: any, cadProd: any, statProd: any, type: any) {
    setTourList(null);
    //setLoading(true);
    setActualCodProd(codProd)
    setActualCity(city)
    setActualSupplier(supplier)
    setActualCadProd(cadProd)
    setActualStatProd(statProd)
    setActualTypeProd(type)
    setPageCount(1);


    const token = localStorage.getItem('GroupId') || '{}';
    const config = {
      headers: { 'Authorization': `Bearer ${token}` },
    };
    try {
      const res = await api.post('/Products/GetTourTicketProductAsync',
        {
          "page": pageCount,
          "rowsPerPage": rowsPerPage,
          "codProd": codProd,
          "cadProd": cadProd,
          "statProd": statProd,
          "typeProd": type,
          "nomeCidade": city,
          "fornecedor": supplier,
          "idioma": 1
        }
        , config
      );

      if (res.status !== 400) {
        //setLoading(false);
        setImagesBaseUrl(res.data.data.imagesBaseUrl);
        setTourList(res.data.data.rows);
        setTotalRows(res.data.data.rowsCount);
      }
    } catch (error: any) {
      //setLoading(false);
      /* if (error.response.status === 401) {
        window.location.href = window.location.origin + '/';
      } */

    }
  };

  function handleUpdate() {
    buscar(actualCodProd, actualCity, actualSupplier, actualCadProd, actualStatProd, actualTypeProd);
  }

  return (
    <>
      <DesktopDefault>
        <div className="container-fluid content-dashboard hello">
          <Breadcrumb title={"Produtos / Listar Produtos"} />
          <div className="mt-4 d-flex justify-content-between">
            <h2 className="title" style={{ color: "#707070" }}>
              Produtos
            </h2>
            <div>
              <Link to="/blackout/add-blackout">
                <Button
                  variant="outline-primary"
                  className="btn-default mr-2 "
                >
                  Blackout
                </Button>
              </Link>

              <Link to="/products/add-tour">
                <Button
                  className="btn-default mr-2 "
                >
                  Adicionar Tour
                </Button>
              </Link>
              <Link to="/products/add-ticket">
                <Button
                  className="btn-default "
                >
                  Adicionar Ticket
                </Button>
              </Link>
            </div>
          </div>
          <div>
            <ProductFilter
              buscar={buscar}
              products={tourList}
              clearActualValues={clearActualValues}
            />
          </div>
          <div>
            <SingleProduct
              totalRows={totalRows}
              pageCount={pageCount}
              setPageCount={setPageCount}
              rowsPerPage={rowsPerPage}
              setRowsPerPage={setRowsPerPage}
              tourList={tourList}
              imagesBaseUrl={imagesBaseUrl}
              handleUpdate={handleUpdate}
            />
          </div>
        </div>
      </DesktopDefault>
    </>
  );
};

export default ProductList;
