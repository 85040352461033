import React, { useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button, Card, Col, Form, Row } from "react-bootstrap";
import { useForm } from "react-hook-form";
import api from "../../../services/api";
import "./SummaryAndDetails.scss";

import { useAppSelector, useAppDispatch } from "../../../store/hooks";
import { addSummaryAndDetails } from "../../../store/product";
import DraggableProductImputs from "../../DraggableProductImputs/DraggableProductImputs";
import ReactQuillComponent from "../../ReactQuillComponent/ReactQuillComponent";

export interface operation {
  action: any;
  receiveObject: any;
  tourObject: any;
  isEdit: any;
  isTicket?: boolean;
  editFunction?: any;
}

const SummaryAndDetails: React.FC<operation> = React.memo(({
  action, receiveObject, tourObject, isEdit, isTicket = false, editFunction = () => { }
}: operation) => {
  /* Controle Redux  */
  const dispatch = useAppDispatch();
  const tourSIG: any = JSON.parse(JSON.stringify(useAppSelector((state: any) => state.tour)));
  /* Controle Redux  */

  const [summaryPT, setSummaryPT] = useState<string>("");
  const [summaryEN, setSummaryEN] = useState<string>("");
  const [summaryES, setSummaryES] = useState<string>("");

  const [valNamePt, setValNamePt] = useState<boolean>(false);
  const [valNameEs, setValNameEs] = useState<boolean>(false);
  const [valNameEn, setValNameEn] = useState<boolean>(false);

  const [allowEditSummary, setAllowEditSummary] = useState<boolean>(false);
  const [allowEditDetails, setAllowEditDetails] = useState<boolean>(false);

  const [detailsArray, setDetailsArray] = useState<any>([
    {
      PT: "",
      EN: "",
      ES: "",
    },
  ]);

  const {
    control,
    handleSubmit,
    setValue,
    getValues,
    setError,
    clearErrors,
    formState: { errors },
  } = useForm({});

  useEffect(() => {
    window.scrollTo(0, 0);
    if (tourSIG.tour.summary !== undefined) {

      setSummaryPT(tourSIG.tour.summary[0].text);
      setValue("summaryPT", tourSIG.tour.summary[0].text.replaceAll("<p>", "").replaceAll("</p>", "").replaceAll("<br>", "").replaceAll("<strong>", "").replaceAll("</strong>", ""));
      setSummaryEN(tourSIG.tour.summary[1].text);
      setValue("summaryEN", tourSIG.tour.summary[1].text.replaceAll("<p>", "").replaceAll("</p>", "").replaceAll("<br>", "").replaceAll("<strong>", "").replaceAll("</strong>", ""));
      setSummaryES(tourSIG.tour.summary[2].text);
      setValue("summaryES", tourSIG.tour.summary[2].text.replaceAll("<p>", "").replaceAll("</p>", "").replaceAll("<br>", "").replaceAll("<strong>", "").replaceAll("</strong>", ""));
    }

    if (tourSIG.tour.ticketInfo !== undefined) {
      setSummaryPT(tourSIG.tour.ticketInfo[0].text);
      setValue("summaryPT", tourSIG.tour.ticketInfo[0].text.replaceAll("<p>", "").replaceAll("</p>", "").replaceAll("<br>", "").replaceAll("<strong>", "").replaceAll("</strong>", ""));
      setSummaryEN(tourSIG.tour.ticketInfo[1].text);
      setValue("summaryEN", tourSIG.tour.ticketInfo[1].text.replaceAll("<p>", "").replaceAll("</p>", "").replaceAll("<br>", "").replaceAll("<strong>", "").replaceAll("</strong>", ""));
      setSummaryES(tourSIG.tour.ticketInfo[2].text);
      setValue("summaryES", tourSIG.tour.ticketInfo[2].text.replaceAll("<p>", "").replaceAll("</p>", "").replaceAll("<br>", "").replaceAll("<strong>", "").replaceAll("</strong>", ""));
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const createTicketObject = (data: any) => {
    data.isTicket = true;

    data.ticketInfo = [
      {
        text: summaryPT,
        lang: 1,
      },
      {
        text: summaryEN,
        lang: 2,
      },
      {
        text: summaryES,
        lang: 3,
      },
    ];

    return data;
  }

  const createTourObject = (data: any) => {
    data.summary = [
      {
        text: summaryPT,
        lang: 1,
      },
      {
        text: summaryEN,
        lang: 2,
      },
      {
        text: summaryES,
        lang: 3,
      },
    ];

    for (let i: number = 0; i < detailsArray.length; i++) {
      delete data[`detailsPT-${i}`];
      delete data[`detailsEN-${i}`];
      delete data[`detailsES-${i}`];
    }

    data.details = detailsArray.filter((e: any) => e !== null).map((e: any) => [
      { text: e.PT, lang: 1 },
      { text: e.EN, lang: 2 },
      { text: e.ES, lang: 3 },
    ]);

    delete data.summaryPT;
    delete data.summaryEN;
    delete data.summaryES;

    return data;
  }

  const onSubmit = (data: any) => {
    let newTourObject: any = { ...tourObject, ...data };
    let dataToInsert: any;

    if (isTicket) {
      dataToInsert = createTicketObject(data);
    } else {
      dataToInsert = createTourObject(data);
    }

    if (isEdit !== true) {
      if (valNamePt === true && valNameEn === true && valNameEs === true) {
        dispatch(addSummaryAndDetails(dataToInsert));
        action("descriptionsAndHigh", newTourObject);
      }
    } else {
      editFunction(dataToInsert);
      //receiveObject(data);
    }
  };

  const handleBlurSummary = (e: any) => {
    if (tourObject.isTranslate === 1 && allowEditSummary === false) {
      let ptText: any = getValues().summaryPT;
      TranslateTextEN(ptText);
      TranslateTextES(ptText);
    }
  };

  const TranslateTextEN = async (name: string) => {
    if (name !== undefined) {
      try {
        const res = await api.post(`https://translation.googleapis.com/language/translate/v2?key=${process.env.REACT_APP_GOOGLE_CLOUD_TRANSLATE_API}`, {
          "q": name,
          "source": "pt-BR",
          "target": "en",
          "format": "text"
        });
        if (res.status !== 400) {
          setSummaryEN(res?.data?.data?.translations[0]?.translatedText);
          setValue("summaryEN", res?.data?.data?.translations[0]?.translatedText);
        }
      } catch (error: any) { }
    }
  };

  const TranslateTextES = async (name: string) => {
    if (name !== undefined) {
      try {
        const res = await api.post(`https://translation.googleapis.com/language/translate/v2?key=${process.env.REACT_APP_GOOGLE_CLOUD_TRANSLATE_API}`, {
          "q": name,
          "source": "pt-BR",
          "target": "es",
          "format": "text"
        });
        if (res.status !== 400) {
          setSummaryES(res?.data?.data?.translations[0]?.translatedText);
          setValue("summaryES", res?.data?.data?.translations[0]?.translatedText);
        }
      } catch (error: any) { }
    }
  };

  let numberPT: any = summaryPT?.replaceAll("<p>", "").replaceAll("</p>", "").replaceAll("<br>", "").replaceAll("<strong>", "").replaceAll("</strong>", "").length;
  let numberES: any = summaryES?.replaceAll("<p>", "").replaceAll("</p>", "").replaceAll("<br>", "").replaceAll("<strong>", "").replaceAll("</strong>", "").length;
  let numberEN: any = summaryEN?.replaceAll("<p>", "").replaceAll("</p>", "").replaceAll("<br>", "").replaceAll("<strong>", "").replaceAll("</strong>", "").length;

  useEffect(() => {
    if (summaryPT !== undefined) {
      if (numberPT < 100) {
        setValNamePt(false);
      } else {
        setValNamePt(true);
      }
    }

    if (summaryES !== undefined) {
      if (numberES < 100) {
        setValNameEs(false);
      } else {
        setValNameEs(true);
      }
    }

    if (summaryEN !== undefined) {
      if (numberEN < 100) {
        setValNameEn(false);
      } else {
        setValNameEn(true);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [summaryPT, summaryES, summaryEN]);

  return (
    <>
      {
        (isEdit === false || tourSIG?.tour?.productName) ?
          <Col md={9} lg={10}>
            <Card className="pt-content summary">
              <Form
                className="h-100"
                noValidate
                validated={false}
                onSubmit={handleSubmit(onSubmit)}
              >
                <h3 className="text-primary pt-title mb-0">{isTicket ? "Resumo" : "Resumo e Detalhes"}</h3>
                <div className="pt-content-info">
                  <Row>
                    <div>
                      <div className="d-flex justify-content-between">
                        <h5 className="mb-2 pt-subtitle">Resumo</h5>
                        {tourObject.isTranslate === 1 && (
                          <>
                            <div
                              className="btn-edit ml-2"
                              style={{ position: "unset" }}
                              onClick={() =>
                                setAllowEditSummary(!allowEditSummary)
                              }
                            >
                              <FontAwesomeIcon
                                icon={["fal", "edit"]}
                                size="1x"
                                className="mr-2"
                              />
                              Editar
                            </div>
                          </>
                        )}
                      </div>
                      <p className="small mb-0">
                        Crie um texto curto para contar a atividade de forma clara
                        e atrativa.
                      </p>
                      <p className="small mb-0">
                        Sugerimos que adicione as seguintes palavras mais buscadas
                        pelos passageiros no Google: ingresso, excursão, passeio e
                        atração.
                      </p>
                    </div>

                    <ReactQuillComponent
                      setValue={setValue}
                      control={control}
                      handleBlur={handleBlurSummary}
                      name="summaryPT"
                      title="Resumo do Tour (Português)"
                      errors={errors}
                      setState={setSummaryPT}
                      state={summaryPT}
                      isEdit={isEdit}
                      size={12}
                      numberOfCaracters={numberPT}
                      maxLength={240}
                      minLength={100}
                      id="portugueseText"
                    />

                    <ReactQuillComponent
                      setValue={setValue}
                      control={control}
                      name="summaryEN"
                      title="Resumo do Tour (Inglês)"
                      errors={errors}
                      setState={setSummaryEN}
                      state={summaryEN}
                      isEdit={isEdit}
                      size={6}
                      numberOfCaracters={numberEN}
                      maxLength={240}
                      minLength={100}
                      id="englishText"
                      style={
                        tourObject.isTranslate === 1 &&
                          allowEditSummary === false
                          ? { backgroundColor: "#e9ecef", opacity: "1" }
                          : {}
                      }
                      disabled={!allowEditSummary}
                    />

                    <ReactQuillComponent
                      setValue={setValue}
                      control={control}
                      name="summaryES"
                      title="Resumo do Tour (Espanhol)"
                      errors={errors}
                      setState={setSummaryES}
                      state={summaryES}
                      isEdit={isEdit}
                      size={6}
                      numberOfCaracters={numberES}
                      maxLength={240}
                      minLength={100}
                      id="spanishText"
                      style={
                        tourObject.isTranslate === 1 &&
                          allowEditSummary === false
                          ? { backgroundColor: "#e9ecef", opacity: "1" }
                          : {}
                      }
                      disabled={!allowEditSummary}
                    />
                  </Row>
                </div>

                {!isTicket &&
                  <div className="pt-content-info" style={{ border: "none" }}>
                    <Row>
                      <div>
                        <div className="d-flex justify-content-between">
                          <h5 className="mb-2 pt-subtitle">Detalhes</h5>
                          {tourObject.isTranslate === 1 && (
                            <>
                              <div
                                className="btn-edit ml-2"
                                style={{ position: "unset" }}
                                onClick={() =>
                                  setAllowEditDetails(!allowEditDetails)
                                }
                              >
                                <FontAwesomeIcon
                                  icon={["fal", "edit"]}
                                  size="1x"
                                  className="mr-2"
                                />
                                Editar
                              </div>
                            </>
                          )}
                        </div>

                        <p className="small mb-0">
                          Insira tudo que um viajante precisa saber antes de comprar
                          seu produto.
                        </p>
                        <p className="small mb-0">
                          Pense em logística, restições e requisitos.
                        </p>
                      </div>

                      <DraggableProductImputs
                        items={detailsArray}
                        setItems={setDetailsArray}
                        tourObject={tourObject}
                        allowEditItems={allowEditDetails}
                        portuguese={`detailsPT`}
                        english={"detailsEN"}
                        spanish={"detailsES"}
                        address={"details"}
                        control={control}
                        setValue={setValue}
                        errors={errors}
                        minLength={10}
                        maxLength={500}
                        setError={setError}
                        clearErrors={clearErrors}
                      />
                    </Row>
                  </div>
                }
                <div className="h-100 d-flex justify-content-end align-items-end">
                  {isEdit !== true ? (
                    <Row className="mx-0 mt-5">
                      <Button
                        variant="outline-primary"
                        className="form-button mr-3"
                        onClick={() => action("operation")}
                      >
                        Voltar
                      </Button>
                      <Button type="submit" className="form-button">
                        Continuar
                      </Button>
                    </Row>
                  ) : (
                    <Row className=" mx-0 mt-5">
                      <Button type="submit" className="form-button">
                        Salvar
                      </Button>
                    </Row>
                  )}
                </div>
              </Form>
            </Card>
          </Col>
          :
          <>
            <Col md={9} lg={10}>
              <Card className="pt-content h-100">
                <div className="loading-modal h-100 d-flex flex-column justify-content-center align-items-center">
                  <div className="load mb-4"></div>
                  <div>Carregando dados do produto</div>
                </div>
              </Card>
            </Col>
          </>
      }
    </>
  );
}
);

export default SummaryAndDetails;
