import React, { useEffect, useState } from 'react';
import api from '../../services/api';

import Form from 'react-bootstrap/Form';

export interface propPlus {
  propsField: any,
  propsErrors: any,
};

const GetBranchOffice: React.FC<propPlus> = ({
  propsField, propsErrors
}: propPlus) => {
  const [info, setInfo] = useState<any>(null);

  useEffect(() => {
    const token = localStorage.getItem('GroupId') || '{}';
    const config = {
      headers: { 'Authorization': `Bearer ${token}` },
    };
    async function listSuppliers() {
      try {
        const { data } = await api.get(`/DropDown/GetBranchOffice`, config);
        if (data.status !== 400) {
            setInfo(data.data)
        }
      } catch (error: any) {
        if (error.response.status === 401) {
          window.location.href = window.location.origin + '/';
        }
      }
    }
    listSuppliers();
  }, []);

  if (info !== null) {
    return (
      <>
        <div className={"endpoint"}>
          <Form.Control
            as="select"
            required
            autoComplete='off'
            aria-invalid={propsErrors?.filial ? "true" : ""}
            {...propsField}
          >
            <option value='0'>Selecione</option>
            {info.length > 0 ? info.map((info: any) => (
              <option value={info.id} >{info.description}</option>
            )) : ''}
          </Form.Control>
         </div>
      </>
    );
  } else {
    return <>

    </>
  }
}

export default GetBranchOffice;