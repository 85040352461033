import React, { useState } from 'react';

import DatePicker, { DateObject } from "react-multi-date-picker";
import TimePicker from "react-multi-date-picker/plugins/time_picker";

//import { useTranslation } from "react-i18next";

import './Calendar.scss';

const CustomTime = ({ openCalendar, value, setTime, index }: any) => {

  if (typeof value === typeof '') {
    setTime(value, index);    
  } else {
    setTime(value[0], index);
  }

  return (
    <input
      id="time"
      className="form-control bg-white rounded"
      onFocus={openCalendar}
      value={value}
      readOnly
      placeholder="Escolha um horário"
      autoComplete='off'
    />
  )
}

const OnlyTime = ({ date, setValue = () => { }, field, setTime, defaultValue, index = null, defaultTime }: any) => {
  var aux = new Date(defaultTime)
  
  const [values,] = useState(
    new DateObject().set({
      hour: aux.getHours(),
      minute: aux.getMinutes(),
    })
    )

  return (
    <DatePicker
      value={values}
      //onChange={setValues}
      disableDayPicker
      format="HH:mm"
      render={<CustomTime index={index} setTime={setTime} field={field}/>}
      plugins={[
        <TimePicker hideSeconds />
      ]}
    />
  )
}

export default OnlyTime;



