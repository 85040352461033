/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useEffect, useState } from 'react';
import api from '../../services/api';

//COMPONENTS
import DesktopDefault from '../../templates/DesktopDefault';
import Breadcrumb from '../../components/Breadcrumb/Breadcrumb';

import FilterReportSessionPend from './components/FilterReportSessionPend/FilterReportSessionPend';

//Style
import './ReportSessionPend.scss';
import { Button, Col, Form, Modal, Row } from 'react-bootstrap';
import TableReportSessionPend from './components/TableReportSessionPend/TableReportSessionPend';

function ReportSessionPend() {

    const today = new Date();
    //const todayStr = `${today.getDate()}/${today.getMonth() + 1}/${today.getFullYear()}`;

    const ini = new Date(today.getFullYear(), today.getMonth(), 1);
    const fini = new Date(today.getFullYear(), today.getMonth()+1, 0);

    const iniStr = `${ini.getDate()}/${ini.getMonth() + 1}/${ini.getFullYear()}`;
    const finiStr = `${fini.getDate()}/${fini.getMonth() + 1}/${fini.getFullYear()}`;


    const [info, setInfo] = useState<any>(null);
    const [loading, setLoading] = useState<any>(false);

    const [modalShow, setModalShow] = useState<any>(false);
    //const [modalResponse, setModalResponse] = useState<any>('');
    const [modalLog, setModalLog] = useState<any>(null);

    const defaultDate = [iniStr,finiStr];
    const defaultTypeDate = 3; // 1 = Período, 2 = Hoje, 3 = Este Mês, 4 = Este Ano

    const [actualDate, setActualDate] = useState<any>(defaultDate);
    const [actualTypeDate, setActualTypeDate] = useState<any>(defaultTypeDate);

    // useEffect(() => {

    // }, []);

    const formatDate = (data: any) => {
        if(!data) return '';
        
        //2022-05-05T00:00:00
        var aux = data.split('T');
        aux = aux[0].split('-');

        return (`${aux[2]}/${aux[1]}/${aux[0]}`);
    }
    // useEffect(() => {
    //     var today: any = new Date();

    //     /* buscar(0, 1, [`${today.getDate()}/${today.getMonth() + 1}/${today.getFullYear() - 1}`, `${today.getDate()}/${today.getMonth() + 1}/${today.getFullYear()}`], 0, 0, 1, 0, 0, 0, '', ''); */
    //     buscar(0, 1, [`${today.getDate()}/${today.getMonth() + 1}/${today.getFullYear()}`, `${today.getDate()}/${today.getMonth() + 1}/${today.getFullYear()}`], 1, 1, 0, 0, 0, 0, '', '');
    //     // eslint-disable-next-line react-hooks/exhaustive-deps
    // }, [])

    async function buscar(typeDate: any, date: any) {
        setActualTypeDate(typeDate);
        setActualDate(date);

        async function changingPage() {
            setLoading(true);

            let beginDate = []
            let endDate = []

            if (date?.length !== 0) {
                if (date?.length === 1) {
                    beginDate = date[0].split('/')
                } else if (date?.length === 2) {
                    beginDate = date[0].split('/')
                    endDate = date[1].split('/')
                }
            } else {
                if (defaultDate?.length === 1) {
                    beginDate = defaultDate[0].split('/')
                } else if (defaultDate?.length === 2) {
                    beginDate = defaultDate[0].split('/')
                    endDate = defaultDate[1].split('/')
                }
            }

            let today: any = new Date();

            const token = localStorage.getItem('GroupId') || '{}';
            const config = {
                headers: { 'Authorization': `Bearer ${token}`, 'Content-Type': 'application/json' },
            };
            try {
                // main data
                const res = await api.post('/SessionClosure/GetReportSessionPendAsync',
                    {
                        "dataInicio": `${beginDate[2]}-${beginDate[1]}-${beginDate[0]}`,
                        "dataFim": (endDate.length === 0) ? `${beginDate[2]}-${beginDate[1]}-${parseInt(beginDate[0])}` : `${endDate[2]}-${endDate[1]}-${endDate[0]}`
                    }, config);

                if (res.data.status !== 400) {
                    const dados = res.data.data;

                    setInfo(dados);
                    setLoading(false);
                }

            } catch (error: any) {
                setLoading(false);
            }
        }
        changingPage();
    }

    useEffect(() => {
        // async function changingPage() {
        //     setLoading(true);

        //     let beginDate = []
        //     let endDate = []

        //     if (actualDate?.length !== 0) {
        //         if (actualDate?.length === 1) {
        //             beginDate = actualDate[0].split('/')
        //         } else if (actualDate?.length === 2) {
        //             beginDate = actualDate[0].split('/')
        //             endDate = actualDate[1].split('/')
        //         }
        //     } else {
        //         if (defaultDate?.length === 1) {
        //             beginDate = defaultDate[0].split('/')
        //         } else if (defaultDate?.length === 2) {
        //             beginDate = defaultDate[0].split('/')
        //             endDate = defaultDate[1].split('/')
        //         }
        //     }

        //     let today: any = new Date();

        //     const token = localStorage.getItem('GroupId') || '{}';
        //     const config = {
        //         headers: { 'Authorization': `Bearer ${token}`, 'Content-Type': 'application/json' },
        //     };

            
        //     try {
        //         // main data
        //         const res = await api.post('/SessionClosure/GetReportSessionPendAsync',
        //             {
        //                 "dataInicio": `${beginDate[2]}-${beginDate[1]}-${beginDate[0]}`,
        //                 "dataFim": (endDate.length === 0) ? `${beginDate[2]}-${beginDate[1]}-${parseInt(beginDate[0])}` : `${endDate[2]}-${endDate[1]}-${endDate[0]}`
        //             }, config);

        //         if (res.data.status !== 400) {
        //             const dados = res.data.data;

        //             setInfo(dados);
        //             setLoading(false);
        //         }

        //     } catch (error: any) {
        //         setLoading(false);
        //     }
        // }
        // changingPage();

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    async function exportReport(typeDate: any, date: any) {
        setLoading(true);
        //setActualTypeDate(typeDate);
        setActualDate(date);

        let beginDate = []
        let endDate = []

        if (date.length === 1) {
            beginDate = date[0].split('/')
        } else if (date.length === 2) {
            beginDate = date[0].split('/')
            endDate = date[1].split('/')
        }

        let today: any = new Date();

        try {
            const token = localStorage.getItem('GroupId') || '{}';
            const config = {
                headers: { 'Authorization': `Bearer ${token}`, 'Content-Type': 'application/json' },
            };
            
            const res = await api.post('/SessionClosure/ExportReportClosedSessionsSepStatusErp',
                {
                    "dataFinal": (date.length === 1) ? `${beginDate[2]}-${beginDate[1]}-${parseInt(beginDate[0])}` : (date !== null && date.length === 2) ? `${endDate[2]}-${endDate[1]}-${endDate[0]}` : `${today.getFullYear()}-${today.getMonth() + 1}-${today.getDate()}`,
                    "dataInicial": (date.length > 0) ? `${beginDate[2]}-${beginDate[1]}-${beginDate[0]}` : `${today.getFullYear()}-${today.getMonth() + 1}-${today.getDate()}`,
                    //"tipoData": typeDate
                }, config
            );
            if (res.status !== 400) {
                setLoading(false);

                const dados = res.data.data;

                const objectUrl = `data:${dados.fileType};base64,${dados.file}`;                

                const link = document.createElement('a');
                link.href = objectUrl;
                link.setAttribute(
                    'download',
                    dados.fileName,
                );

                // Append to html link element page
                document.body.appendChild(link);

                // Start download
                link.click();

                // // Clean up and remove the link
                link?.parentNode?.removeChild(link);
            }
        } catch (error: any) {
            setLoading(false);
            //setError(true)
            if (error?.response?.status === 401) {
                window.location.href = window.location.origin + '/';
            }
        }
    };
    
    return (
        <>
            <DesktopDefault>
                <div className="container-fluid content-dashboard dailyreceipt-report">
                    <Breadcrumb title={'Financeiro / Relatórios / Relatório de Session Pendente'} />
                    <div className="suppliers mt-4 d-flex justify-concolumntent-between align-items-center">
                        <h2 className="title" style={{ color: "#707070" }}>Relatório de Session Pendente</h2>
                    </div>

                    <FilterReportSessionPend buscar={buscar} defaultDate={defaultDate} defaultTypeDate={defaultTypeDate} exportReport={exportReport} />

                    <Row>
                        <Form.Group as={Col} md="12">
                            <div>
                                {/* tabela totais */}
                                <TableReportSessionPend info={info} loading={loading} />
                            </div>
                        </Form.Group>
                    </Row>
                    
                    {/* <Modal
                        className="modal-confirm"
                        show={modalShow === true}
                        onHide={() => {
                            setModalLog(null);
                            setModalShow(false);
                        }}
                        size="lg"
                        aria-labelledby="contained-modal-title-vcenter"
                    >
                        {modalLog !== null
                            ?
                            <div className='modal-body text-center'>
                                <div>
                                    {modalLog === 0
                                        ?
                                        <FontAwesomeIcon
                                            icon={["fal", "check-circle"]}
                                            size="5x"
                                            className="text-primary"
                                            style={{ fontSize: "7.5em" }}
                                        />
                                        :
                                        <FontAwesomeIcon
                                            icon={["fal", "times-circle"]}
                                            size="5x"
                                            className="text-primary"
                                            style={{ fontSize: "7.5em"}}
                                        />
                                    }
                                </div>

                                <div className="px-md-5" style={{ padding: "0 3rem" }}>
                                    {modalResponse}
                                </div>
                                <div>
                                    <Button type="submit" className="btn btn-primary form-button px-4 py-2" onClick={() => setModalShow(false)}>Fechar</Button>
                                </div>
                            </div>
                            :

                            <div className="modal-body loading-modal">
                                <div className="text-center">
                                    <div className="load"></div>
                                </div>
                                <div className='d-flex justify-content-center'>
                                    <span>Processando</span>
                                </div>
                                <div></div>
                            </div>
                        }
                    </Modal> */}
                </div>
            </DesktopDefault>
        </>

    );
    
}

export default ReportSessionPend;